<script>
import ui from '/~/core/ui'
import DefaultGiftcardsCatalog from '/~/extensions/giftcards/templates/default/views/giftcards-catalog.vue'
import GcFilterMobile from '/~/extensions/giftcards/components/gc-filter.mobile.vue'
import GcFilterDesktop from '/~/extensions/giftcards/components/gc-filter.desktop.vue'
import GcCatalog from '../components/gc-catalog.vue'

export default {
  name: 'giftcards-catalog',
  components: {
    GcCatalog,
    GcFilterMobile,
    GcFilterDesktop,
  },
  extends: DefaultGiftcardsCatalog,
  setup() {
    return {
      ...DefaultGiftcardsCatalog.setup?.(...arguments),
      ui,
    }
  },
}
</script>

<template>
  <div class="mx-auto h-full w-full max-w-7xl">
    <portal to="rewards-header">
      <gc-filter-desktop
        class="mx-auto max-w-7xl py-2.5 px-2.5 sm:px-0 sm:py-3"
      />
    </portal>

    <gc-filter-mobile v-if="ui.mobile" />

    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />

    <gc-catalog class="p-5 sm:p-0" />
  </div>
</template>
