<script>
import emitter from '/~/core/emitter'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { pluralize } from '/~/utils/format/string'
import { RecroomComment } from '/~rec/core/comment'

export default {
  name: 'rec-flash-posts',
  components: {
    BaseLoader,
  },
  props: {
    feed: {
      type: Object,
      default: () => ({}),
    },
    eventId: {
      type: String,
      default: null,
    },
    groupId: {
      type: String,
      default: null,
    },
    feedType: {
      type: String,
      default: null,
      validator: (v) => /announcements|group|event|feed/.test(v),
    },
  },
  data() {
    return {
      newPostsIds: [],
      loading: false,
    }
  },
  computed: {
    currentHitsIds() {
      return this.feed.hits.map((h) => h.id)
    },
    btnText() {
      const count = this.newPostsIds.length

      return `Click to load ${count} new ${pluralize(count, 'post')}`
    },
  },
  watch: {
    feedType() {
      this.newPostsIds = []
      this.loading = false
    },
  },
  created() {
    emitter.on('notifications:post_create', this.onPostCreated)

    // When somebody comments a post
    emitter.on('notifications:post_like_create', this.onPostLiked)

    // When somebody comments a post
    emitter.on('notifications:post_comment_create', this.onPostCommented)

    // When somebody adds/removes post from saved posts
    emitter.on('notifications:saved_post_create', this.onPostSaverAdd)
    emitter.on('notifications:saved_post_delete', this.onPostSaverRemove)

    // When somebody likes post comment
    emitter.on(
      'notifications:post_comment_like_create',
      this.onPostCommentLiked
    )
  },
  beforeDestroy() {
    emitter.off('notifications:post_create', this.onPostCreated)
    emitter.off('notifications:post_like_create', this.onPostLiked)
    emitter.off('notifications:post_comment_create', this.onPostCommented)
    emitter.off('notifications:saved_post_create', this.onPostSaverAdd)
    emitter.off('notifications:saved_post_delete', this.onPostSaverRemove)
    emitter.off(
      'notifications:post_comment_like_create',
      this.onPostCommentLiked
    )
  },
  methods: {
    async onPostCreated(data) {
      const { feedType } = this
      const postId = data.body?.metadata?.post_id
      const postGroupId = data.body?.metadata?.groups[0]
      const postEventId = data.body?.metadata?.events[0]
      const isAnnouncement = data.body?.metadata?.announcement

      // Avoid occasional duplications
      if (
        this.newPostsIds.includes(postId) ||
        this.currentHitsIds.includes(postId)
      )
        return

      // If post belongs to announcements
      if (feedType === 'announcements' && isAnnouncement) {
        this.newPostsIds.push(postId)
        return
      }

      // If post belongs to group
      if (feedType === 'group' && postGroupId === this.groupId) {
        this.newPostsIds.push(postId)
        return
      }

      // If post belongs to event
      if (feedType === 'event' && postEventId === this.eventId) {
        this.newPostsIds.push(postId)
        return
      }

      // If belongs to feed.
      // Feed may have regular posts, announcements and group posts, but not event posts
      if (feedType === 'feed' && !postEventId) {
        this.newPostsIds.push(postId)
      }
    },
    onPostLiked(data) {
      if (data.action) return
      const { postId, userId } = this.getPostParams(data)
      const post = this.findPost(postId)

      if (post && !post.raw.likes.includes(userId)) {
        post.raw.likes.push(userId)
      }
    },
    onPostCommented(data) {
      if (!data.action) return
      const { postId, commentId, comment, parentCommentId } =
        this.getPostParams(data)
      const post = this.findPost(postId)
      // Comment target might be post itself or inner comment (works as reply to a comment)
      const target = parentCommentId
        ? post?.comments.hits?.find((c) => c.id === parentCommentId)
        : post

      if (target && !target.comments.hits.includes(commentId)) {
        target.comments.addHit(new RecroomComment(comment), true)

        if (parentCommentId) target.totalComments++
      }

      if (post && !post.comments.hits.includes(commentId)) {
        post.totalComments++
      }
    },
    onPostSaverAdd(data) {
      const { postId, userId } = this.getPostParams(data)
      const post = this.findPost(postId)

      if (post && !post.savers.includes(userId)) {
        post.totalSaved++
        post.raw.savers_uuids.push(userId)
      }
    },
    onPostSaverRemove(data) {
      const { postId, userId } = this.getPostParams(data)
      const post = this.findPost(postId)

      if (post?.savers.includes(userId)) {
        const idx = post?.savers.indexOf(userId)

        post?.raw.savers_uuids.splice(idx, 1)
        post.totalSaved--
      }
    },
    onPostCommentLiked(data) {
      if (!data.action) return
      const { postId, commentId, userId } = this.getPostParams(data)
      const post = this.findPost(postId)
      let postComment

      post?.comments.hits?.forEach((c) => {
        if (postComment) return
        postComment =
          c.id === commentId
            ? c
            : c?.comments.hits?.find((h) => h.id === commentId)
      })

      if (postComment && userId) {
        postComment.raw.likes.push(userId)
        postComment.totalLikes++
      }
    },
    getPostParams(data) {
      const metadata = data?.body?.metadata

      return {
        postId: metadata?.post_id,
        userId: metadata?.user_id,
        likeId: metadata?.lik_id,
        commentId: metadata?.comment_id,
        parentCommentId: metadata?.comment?.parent_comment_id,
        comment: metadata?.comment,
      }
    },
    postIsShown(postId) {
      return this.currentHitsIds.includes(postId)
    },
    findPost(postId) {
      return this.feed.hits.find((h) => h.id === postId)
    },
    async onClick() {
      this.loading = true
      await this.feed.refresh()
      this.loading = false
      this.$emit('loaded')
      this.newPostsIds = []
    },
  },
}
</script>

<template>
  <div
    v-show="newPostsIds.length"
    class="mx-auto mt-[15px] flex h-[30px] w-64 cursor-pointer items-center justify-center rounded bg-white p-0.5 text-center text-sm font-semibold text-primary shadow-md hover:bg-zinc-50"
    @click="onClick"
  >
    <base-loader v-if="loading" size="xs" />
    <span v-else class="py-0.5">
      {{ btnText }}
    </span>
  </div>
</template>
