<script>
import DefaultCartHome from '/~/components/cart-menu/views/home/cart-home.vue'
import CartItem from '/~/templates/australiapost/components/cart-menu/cart-item.vue'

export default {
  components: {
    CartItem,
  },
  extends: DefaultCartHome,
}
</script>
