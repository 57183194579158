<script>
import PointsTransferForm from '../components/points-transfer-form.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'

export default {
  name: 'fly-points-transfer-mobile',
  components: {
    PointsTransferForm,
    BaseAsidePage,
  },
}
</script>

<template>
  <base-aside-page
    title="Transfer points"
    shadow
    :back="{ name: 'fly-business', query: { type: $route.query.type } }"
  >
    <div class="mt-1 bg-eonx-neutral-50">
      <points-transfer-form v-bind="$attrs" />
    </div>
  </base-aside-page>
</template>
