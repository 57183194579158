<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import { cardCode } from '/~/utils/cards'
import { formatDollar } from '/~/utils/format/money'
import { roundFigure } from '/~/utils/format/numeric'
import { formatPoints } from '/~/utils/points'
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import {
  usePurchaseCheckout,
  useQuickBuyCheckout,
  usePayeeCheckout,
} from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useEwallet } from '/~/composables/ewallet'
import { useLocalization } from '/~/composables/localization'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'payments-methods-split-item-v2',
  components: {
    BaseCurrencyInput,
    BaseIcon,
    BaseLoader,
  },
  props: {
    method: {
      type: Object,
      default: () => ({}),
    },
    isSummary: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    flowType: {
      type: String,
      default: FlowType.purchase,
      validator: (v) => !v || Object.values(FlowType).includes(v),
    },
  },
  setup(props) {
    const router = useRouter()
    const { initiatingAccountsIds } = usePaymentMethods()
    const {
      isSplitEnabled,
      isBankAccountsNavEnabled,
      isCreditCardsNavEnabled,
    } = useProvider()
    const {
      burnPointsRateOrder,
      burnPointsRatePaymentOrder,
      burnPointsRateBatchOrder,
      burnPointsRateStatementOrder,
      pointsBalance,
    } = usePoints()
    const { translate } = useLocalization()
    const { ewalletBalance } = useEwallet()

    const paymentComposable = computed(() => {
      switch (props.flowType) {
        case FlowType.payment:
          return usePayeeCheckout
      }

      return router.currentRoute.meta.quickBuy
        ? useQuickBuyCheckout
        : usePurchaseCheckout
    })

    const {
      total,
      subTotalWithProgramFees,
      readyToPay,
      payWithPoints,
      payWithEwallet,
      payWithBankAccount,
      payWithCard,
      selectedPoints,
      selectedEwallet,
      selectedCard,
      selectedBankAccount,
      selectedAmount,
      getTransactionFees,
      transactionFees,
      isEnoughPointsPaymentOrder,
      loadingFees,
    } = paymentComposable.value()

    const burnPointsRate = computed(() => {
      switch (props.flowType) {
        case FlowType.payment:
          return burnPointsRatePaymentOrder.value
        case FlowType.batch:
          return burnPointsRateBatchOrder.value
        case FlowType.statement:
          return burnPointsRateStatementOrder.value
      }

      return burnPointsRateOrder.value
    })

    const remainingAmount = computed(
      () => subTotalWithProgramFees.value - selectedAmount.value
    )
    const fulfilledAmount = computed(() =>
      Math.min(
        subTotalWithProgramFees.value,
        payWithPoints.value +
          payWithEwallet.value +
          payWithCard.value +
          payWithBankAccount.value
      )
    )
    const amountToPay = computed(() =>
      roundFigure(subTotalWithProgramFees.value - fulfilledAmount.value)
    )

    return {
      bottomSheet,
      total,
      subTotalWithProgramFees,
      readyToPay,
      payWithPoints,
      payWithEwallet,
      payWithBankAccount,
      payWithCard,
      selectedPoints,
      selectedEwallet,
      selectedCard,
      selectedBankAccount,
      selectedAmount,
      isBankAccountsNavEnabled,
      isCreditCardsNavEnabled,
      formatDollar,
      formatPoints,
      getTransactionFees,
      transactionFees,
      isEnoughPointsPaymentOrder,
      loadingFees,
      burnPointsRate,
      initiatingAccountsIds,
      remainingAmount,
      fulfilledAmount,
      amountToPay,
      isSplitEnabled,
      PaymentMethodType,
      translate,
      pointsBalance,
      ewalletBalance,
    }
  },
  computed: {
    initializing() {
      return this.initiatingAccountsIds.includes(this.method.id)
    },
    isPoints() {
      return this.method.type === this.PaymentMethodType.points
    },
    isCard() {
      return this.method.type === this.PaymentMethodType.creditCard
    },
    isBankAccount() {
      return this.method.type === this.PaymentMethodType.bankAccount
    },
    isEwallet() {
      return this.method.type === this.PaymentMethodType.eWallet
    },
    status() {
      return (this.method?.status ?? '').toLowerCase()
    },
    state() {
      return (this.method?.state ?? '').toLowerCase()
    },
    isVerified() {
      return (
        this.status === 'verified' ||
        this.isPoints ||
        this.isEwallet ||
        (this.isCard && !this.isCreditCardsNavEnabled) ||
        (this.isBankAccount && !this.isBankAccountsNavEnabled)
      )
    },
    isNotAllowed() {
      return this.state === 'restricted'
    },
    isBlocked() {
      return this.status === 'blocked' || this.status === 'locked'
    },
    icon() {
      if (this.isPoints) {
        return 'v2/custom/points-token'
      } else if (this.isMastercard) {
        return 'cards/mastercard'
      } else if (this.isVisa) {
        return 'v2/custom/visa'
      } else if (this.isAmex) {
        return 'v2/custom/amex'
      } else if (this.isBankAccount) {
        return 'v2/custom/bank'
      } else if (this.isEwallet) {
        return 'plain/ewallet'
      }
      return null
    },
    iconSize() {
      if (this.isBankAccount) {
        return 24
      } else if (this.isMastercard) {
        return 32
      } else if (this.isVisa) {
        return 50
      } else if (this.isAmex) {
        return 70
      }

      return 32
    },
    firstRow() {
      const { type, number, accountNumber, bsb } = this.method

      switch (type) {
        case this.PaymentMethodType.points:
          return `${formatDollar(this.payWithPoints)}`
        case this.PaymentMethodType.creditCard:
          return `**** **** **** ${number.slice(-4)}`
        case this.PaymentMethodType.bankAccount:
          return this.translate('bankAccount.details', {
            acc: `*** ${accountNumber.slice(-2)}`,
            bsb: `*** ${bsb.slice(-3)}`,
          })
        case this.PaymentMethodType.eWallet:
          return `${formatDollar(this.ewalletBalance)}`
        default:
          return ''
      }
    },
    secondRow() {
      const { name, accountName } = this.method
      const {
        isPoints,
        isCard,
        isBankAccount,
        isSelected,
        formatPoints,
        isSummary,
      } = this

      if (isPoints) {
        return isSelected || isSummary
          ? `Using ${formatPoints(this.selectedPoints?.usePoints)} PTS`
          : `Points balance ${formatPoints(this.pointsBalance)}`
      } else if (isCard) {
        return `${name}`
      } else if (isBankAccount) {
        return accountName
      }

      return null
    },
    methodFees() {
      if (this.loadingFees) {
        return 0
      } else {
        return this.amountModel.fee ?? 0
      }
    },
    methodFeesText() {
      const feesAmount = Number(
        (this.isCard ? this.amountModel.percentageFeeRate : this.methodFees) ??
          0
      ).toFixed(2)

      return `-${
        this.isCard ? `${feesAmount}%` : `$${feesAmount}`
      } processing fee`
    },
    isSelected() {
      if (!this.method.id || this.isSummary) return false

      return (
        (this.isPoints && this.selectedPoints) ||
        this.selectedCard?.id === this.method.id ||
        this.selectedBankAccount?.id === this.method.id ||
        this.selectedEwallet?.id === this.method.id
      )
    },
    amountModel() {
      if (this.isCard) {
        return this.selectedCard
      } else if (this.isBankAccount) {
        return this.selectedBankAccount
      } else if (this.isEwallet) {
        return this.selectedEwallet
      }

      return {}
    },
    maxInputAmount() {
      if (this.isCard) {
        return Math.min(
          this.subTotalWithProgramFees,
          this.remainingAmount + this.payWithCard
        )
      } else if (this.isBankAccount) {
        return Math.min(
          this.subTotalWithProgramFees,
          this.remainingAmount + this.payWithBankAccount
        )
      } else if (this.isEwallet) {
        return Math.min(
          this.ewalletBalance,
          this.remainingAmount + this.payWithEwallet
        )
      } else {
        return 0
      }
    },
    isUnselectable() {
      return (
        this.isNotAllowed ||
        !this.isVerified ||
        (this.isPoints && !this.pointsBalance) ||
        (this.isEwallet && this.ewalletBalance <= 0)
      )
    },
    cardCode() {
      return cardCode(this.method)
    },
    isMastercard() {
      return this.cardCode === 'mastercard'
    },
    isVisa() {
      return this.cardCode === 'visa'
    },
    isAmex() {
      return this.cardCode === 'amex'
    },
  },
  methods: {
    onAmountInput(value) {
      if (value > this.maxInputAmount) {
        this.amountModel.calculatedAmount = this.maxInputAmount
        this.$refs.amountInputRef.blur()
      } else {
        this.amountModel.calculatedAmount = value
      }

      if (this.amountToPay === 0 && !this.loadingFees) {
        this.getTransactionFees()
      }
    },
    async onClick() {
      if (this.isBlocked || this.initializing) {
        return
      }

      if (!this.isVerified) {
        await this.$router.push({
          hash: '#profile-payment-methods',
          query: this.$route.query,
        })
        bottomSheet.show('method-verify', {
          to: 'menu-modal',
          props: {
            method: this.method,
          },
        })
        return
      }

      if (this.isSummary || this.isUnselectable || this.isDisabled) {
        return
      }

      if (this.isSelected) {
        this.$emit('unselect')
        return
      }

      this.$emit('select', this.method)

      if (this.isPoints) {
        this.openSplitModal()
      }
    },
    onConfirm({ pointsToUse, pointsAmount }) {
      const { type, id } = this.method

      if (pointsToUse > 0) {
        this.selectedPoints = {
          ...this.selectedPoints,
          ...(type === this.PaymentMethodType.points &&
            id !== this.selectedPoints?.id &&
            this.method),
          usePoints: pointsToUse,
          calculatedAmount: pointsAmount,
        }
      } else {
        this.selectedPoints = null
      }

      this.$emit('confirm')

      this.getTransactionFees()
    },
    openSplitModal() {
      this.bottomSheet.show('payments-split-v2', {
        to: this.to,
        props: {
          currentItem: this.method,
          burnPointsRate: this.burnPointsRate,
          selectedPoints: this.selectedPoints,
          selectedEwallet: this.selectedEwallet,
          selectedCard: this.selectedCard,
          selectedBankAccount: this.selectedBankAccount,
          selectedAmount: this.selectedAmount,
          total: this.subTotalWithProgramFees,
          onConfirm: this.onConfirm,
          onCancel: () => {
            this.$emit('cancel')
          },
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="flex items-center" @click="onClick">
      <div
        v-if="!isUnselectable"
        class="mr-5 flex h-6 w-6 shrink-0 items-center justify-center rounded-md border"
        :class="{
          'border-gray-300': !isSelected,
        }"
        :style="{
          'border-color': isSelected ? '#6FCF97' : null,
          'background-color': isSelected ? '#6FCF97' : null,
        }"
      >
        <base-icon v-if="isSelected" svg="v2/custom/check" :size="15" />
      </div>
      <div class="relative w-full">
        <div
          class="relative w-full overflow-hidden rounded-3xl"
          :class="{
            'bg-gray-300': !isSelected,
            'bg-orange-700': isSelected && isEwallet,
            'bg-gray-800': isSelected && isMastercard,
            'bg-blue-900': isSelected && isVisa,
            'bg-gray-600': isSelected && isBankAccount,
            'opacity-40': isUnselectable || (!isSelected && isDisabled),
          }"
          :style="{
            'background-color':
              isSelected && isAmex
                ? '#0c66a8'
                : isSelected && isPoints
                ? '#F59E0B'
                : null,
          }"
        >
          <div
            class="absolute rounded-full"
            :class="{
              'bg-orange-600': isSelected && isEwallet,
              'bg-gray-700': isSelected && isMastercard,
              'bg-blue-800': isSelected && isVisa,
              'bg-gray-500': isSelected && isBankAccount,
            }"
            :style="{
              top: '-167px',
              left: '-100px',
              width: '258px',
              height: '258px',
              'background-color':
                isSelected && isAmex
                  ? '#0e75bf'
                  : isSelected && isPoints
                  ? '#fdae39'
                  : null,
            }"
          />
          <div
            class="absolute rounded-full"
            :class="{
              'bg-orange-800': isSelected && isEwallet,
              'bg-gray-900': isSelected && isMastercard,
              'bg-gray-700': isSelected && isBankAccount,
            }"
            :style="{
              top: '6px',
              right: '-55px',
              width: '160px',
              height: '160px',
              'background-color':
                isSelected && isVisa
                  ? '#182e6e'
                  : isSelected && isAmex
                  ? '#0a588f'
                  : isSelected && isPoints
                  ? '#e38b06'
                  : null,
            }"
          />
          <div
            class="relative flex h-20 w-full items-center pl-5 pr-4 text-white"
          >
            <div class="flex min-w-16 shrink-0 justify-center pr-4">
              <base-icon
                :svg="icon"
                :size="iconSize"
                :style="{
                  filter:
                    isUnselectable || (!isSelected && isDisabled)
                      ? 'grayscale(1)'
                      : null,
                }"
              />
            </div>
            <div>
              <div v-if="firstRow" class="text-xl font-black">
                {{ firstRow }}
              </div>
              <div
                v-if="secondRow"
                class="font-bold"
                :class="{
                  'text-xs': !isPoints,
                  'text-sm': isPoints,
                }"
              >
                {{ secondRow }}
              </div>
            </div>
            <button
              v-if="isSelected && isPoints && isSplitEnabled"
              class="ml-auto -mr-4 p-4"
              type="button"
              @click.stop="openSplitModal"
            >
              <span
                class="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-white"
              >
                <base-icon
                  svg="v2/custom/pencil"
                  :size="16"
                  class="text-primary"
                />
              </span>
            </button>
          </div>
        </div>
        <template v-if="!isVerified">
          <base-loader
            v-if="initializing"
            class="absolute top-0 right-0 mt-2.5 mr-2.5"
            size="xs"
          />
          <div
            v-else
            class="absolute top-0 right-0 mt-[5px] -mr-[5px] whitespace-nowrap rounded-l-md rounded-tr-md bg-orange-400 px-[15px] text-xs font-bold uppercase leading-5 text-white sm:mt-[15px]"
          >
            <span
              class="absolute right-0 inline-block h-0 w-0 border-solid border-orange-400 text-amber-600"
              :style="{
                top: '100%',
                'border-width': '5px 5px 0 0',
                'border-color':
                  'currentColor transparent transparent transparent',
              }"
            />
            Unverified
          </div>
        </template>
      </div>
    </div>
    <div v-if="isSelected && !isPoints" class="mt-4 flex items-center">
      <div class="w-auto rounded-md border border-gray-300 p-2.5">
        <base-currency-input
          ref="amountInputRef"
          :value="amountModel.calculatedAmount"
          :allow-negative="false"
          :value-range="{
            min: 0,
            max: maxInputAmount,
          }"
          :disabled="!isSplitEnabled"
          input-class="w-32"
          @input="onAmountInput"
        />
      </div>
      <div class="ml-2.5 text-sm text-eonx-neutral-600">
        <base-loader v-if="loadingFees" size="xs" />
        <template v-else>
          {{ methodFeesText }}
        </template>
      </div>
    </div>
  </div>
</template>
