<script>
import RecBaseState from '/~rec/components/state/base-state.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import CelebrationsCard from './celebrations-card.vue'
import api from '/~rec/core/api'
import { RecroomCelebration } from '/~rec/core/celebration'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'celebrations-section',
  components: {
    BaseIcon,
    BaseCollapse,
    RecBaseState,
    CelebrationsCard,
  },
  props: {
    minItems: {
      type: Number,
      default: 3,
    },
    maxItems: {
      type: Number,
      default: 5,
    },
  },
  setup() {
    const { orgId } = useRecProfile()

    return { orgId }
  },
  data() {
    return {
      isExpanded: false,
      celebrations: [],
      fetching: false,
    }
  },
  computed: {
    showIcon() {
      return this.celebrations.length > 3
    },
  },
  async mounted() {
    await this.getCelebrations()
  },
  methods: {
    onCollapseClick() {
      this.isExpanded = !this.isExpanded
    },
    async getCelebrations() {
      this.fetching = true
      const { data } = await api.get(`organisations/${this.orgId}/celebrations`)

      this.celebrations =
        data?.items.map((c) => new RecroomCelebration(c)) || []
      this.fetching = false
    },
  },
}
</script>

<template>
  <div>
    <base-collapse :autoscroll="false" @change="onCollapseClick">
      <template #trigger>
        <div class="flex items-center justify-between text-eonx-neutral-800">
          <div class="flex items-center">
            <base-icon svg="rec/star-2" size="md" />
            <div class="ml-2.5 font-bold leading-6">
              <span>Celebrations</span>
              <span v-if="!fetching" class="text-eonx-neutral-600">
                ({{ celebrations.length }})
              </span>
            </div>
          </div>
          <div
            v-if="showIcon"
            class="transform text-eonx-neutral-800 transition duration-200"
            :class="{
              'rotate-180': isExpanded,
            }"
          >
            <base-icon svg="plain/chevron-bottom" size="xs" />
          </div>
        </div>
      </template>

      <template #beforeCollapse>
        <div>
          <div v-if="fetching" class="mt-[15px] flex justify-center">
            <base-icon svg="spinner" size="2xl" />
          </div>

          <div v-else-if="celebrations.length" class="mt-2.5 space-y-2.5">
            <celebrations-card
              v-for="(celebration, idx) in celebrations.slice(0, 3)"
              :key="idx"
              :post="celebration"
            />
          </div>

          <rec-base-state
            v-else
            class="mt-6 mb-[30px]"
            wide
            image="recroom/state/directory-celebrations-empty.svg"
            :bg="false"
          />
        </div>
      </template>

      <div v-if="celebrations.length > 3" class="space-y-2.5 pt-2.5">
        <celebrations-card
          v-for="(celebration, idx) in celebrations.slice(3)"
          :key="idx"
          :post="celebration"
        />
      </div>
    </base-collapse>
  </div>
</template>
