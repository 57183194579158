<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

const routes = useRouter()

function goToNewPayees() {
  modal.hide()
}
</script>

<template>
  <base-mdl title="New payees detected" width="sme" layout="v2" padding="xs">
    <div class="-mx-5 border-y border-eonx-neutral-400 p-5">
      <p>
        Your ABA file contains bank account details for payees that haven’t been
        added to the platform. To continue with the batch payment, follow these
        steps for each new payee:
      </p>
      <ol class="ml-5 mt-8 list-decimal">
        <li>Enter the payee ABN.</li>
        <li>
          Click the “Check ABN” button. The payee’s business name will appear
          below the ABN.
        </li>
        <li>
          If the business name is correct, click the “Add payee” button to add
          the payee to the platform.
        </li>
        <li>
          After adding all new payees, click the “Continue” button to proceed
          with your batch payment.
        </li>
      </ol>
      <p class="mt-8">
        Note: You can cancel at any time, and any added payees will still be
        saved to the platform.
      </p>
      <p class="mt-8">
        Note: If you decide not to add a payee, you will have to cancel the
        batch payment, remove them from your ABA file and upload the file again.
      </p>
    </div>
    <div class="w-full pt-5">
      <base-button full-width class="!rounded-sm" @click="goToNewPayees">
        Start adding payees
      </base-button>
    </div>
  </base-mdl>
</template>
