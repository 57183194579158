<script setup lang="ts">
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { computed } from 'vue'
import { OrderPaymentMethod } from '/~/types/api'
import { formatDollar } from '/~/utils/format/money'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

const props = defineProps<{
  paymentMethod: OrderPaymentMethod
}>()

const isPoints = computed(
  () => props.paymentMethod.type === PaymentMethodType.points
)
const isCard = computed(
  () => props.paymentMethod.type === PaymentMethodType.creditCard
)
const isBankAccount = computed(
  () => props.paymentMethod.type === PaymentMethodType.bankAccount
)
const isCoupon = computed(
  () => props.paymentMethod.type === PaymentMethodType.couponCode
)

const issuingNetwork = computed(() =>
  getCardTypeByNumber(props.paymentMethod.number)
)

const label = computed(() => {
  const { number = '' } = props.paymentMethod

  if (isPoints.value) return 'Points'
  if (isCard.value)
    return `${issuingNetwork.value?.label} **** **** **** ${number.slice(-4)}`
  if (isBankAccount.value) {
    return `Bank Transfer *** *** *${number.slice(-2)}`
  }
  if (isCoupon.value) return number

  return null
})

const amountToPay = computed(() => {
  return Number(
    props.paymentMethod.finalAmount ?? props.paymentMethod.amount ?? 0
  )
})

const formattedAmount = computed(() => {
  return isPoints.value
    ? `${props.paymentMethod.points} PTS`
    : formatDollar(amountToPay.value)
})
</script>

<template>
  <div>
    <div class="flex justify-between">
      <div class="text-eonx-neutral-600">
        {{ label }}
        <!-- <template v-if="paymentMethod.feePayer">(Total fees)</template> -->
      </div>
      <div>{{ formattedAmount }}</div>
    </div>
  </div>
</template>
