<script setup lang="ts">
import { ref, computed } from 'vue'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import PostHeader from './post-header.vue'
import PostContent from './post-content.vue'
import PostAction from './post-action.vue'
import { RecroomPost } from '/~rec/core/post'

const props = defineProps<{
  postBoxData: RecroomPost
}>()

const earnValue = computed(
  () => props.postBoxData?.postLikeCreate?.earn_value || 0
)

const options = {
  props: {
    points: earnValue,
  },
}

const isActionLoading = ref(false)

async function onLike() {
  if (!props.postBoxData.isLikedByMe) {
    isActionLoading.value = true
    const response = await props.postBoxData.like()

    isActionLoading.value = false

    if (response) {
      modal.show('earned-points', options)
    }
  }
}
</script>

<template>
  <div>
    <div
      class="w-full bg-white pb-5"
      :class="{
        'rounded-xl border border-eonx-neutral-200': !ui.mobile,
      }"
      :data-id="postBoxData.id"
    >
      <post-header
        :author-name="postBoxData.author.firstName"
        :post-date="postBoxData.createdAt"
        :author-initials="postBoxData.author.initials"
        :avatar-url="postBoxData.author.avatarUrl"
      />

      <post-content
        :attachments="postBoxData.attachments"
        :content="postBoxData.content"
        :loading="postBoxData.loading"
        :mentions="postBoxData.mentions"
      />

      <post-action
        v-if="earnValue"
        :id="postBoxData.id"
        :loading="isActionLoading"
        :is-liked-by-me="postBoxData.isLikedByMe"
        :action="onLike"
        :earn-value="earnValue"
        :disabled="!postBoxData.canVote"
        class="mx-5 mt-5 border-t border-gray-200 pt-5"
      />
    </div>
  </div>
</template>
