<script>
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import BatchPayeeTable from '/~/extensions/bank-file-upload/components/transactions/payee-table/payee-table.vue'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { formatBsb } from '/~/utils/format/string'
import PaymentStatusTracker from '/~/components/activity/details/payment-status-tracker/payment-status-tracker.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PointsPanel from '/~/components/points/points-panel.v2.vue'
import { StatusTracker } from '/~/composables/activity'
import { useBatchOrderPayeesTable } from '/~/composables/batch-order'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import {
  sortPaymentMethodsByType,
  PaymentMethodType,
  usePayId,
} from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'
import ConfirmationAddresses from '/~/views/checkout.v2/views/checkout/components/confirmation/confirmation-addresses.vue'
import ConfirmationPayTo from '/~/views/checkout.v2/views/checkout/components/confirmation/confirmation-pay-to.vue'
import ConfirmationPayWith from '/~/views/checkout.v2/views/checkout/components/confirmation/confirmation-pay-with.vue'
import ConfirmationTotal from '/~/views/checkout.v2/views/checkout/components/confirmation/confirmation-total.vue'

export default {
  name: 'checkout-confirmation-desktop',
  components: {
    BaseButton,
    BaseIcon,
    ConfirmationAddresses,
    ConfirmationPayTo,
    ConfirmationPayWith,
    ConfirmationTotal,
    BaseLoader,
    PointsPanel,
    BatchPayeeTable,
    PaymentStatusTracker,
  },
  props: {
    orderId: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    flowType: {
      type: String,
      default: FlowType.payment,
    },
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const {
      isPurchaseGroupCurrentFlowType,
      isBatchOrderCurrentFlowType,
      payment,
      currentFlowType,
    } = useCheckoutReactive()
    const { purchases, selectedPurchaseCard, fetchCurrentPurchases } =
      usePurchases()
    const { formatBusinessNumber } = useLocalization()
    const { batchOrderPayeesTable, initBatchOrderPayeesTable } =
      useBatchOrderPayeesTable()
    const { cancelDescription } = usePayId()
    const {
      calculatePointsEarnedForPayment,
      calculatePointsEarnedForPurchase,
    } = usePoints()
    const { isUserAddressEnabled } = useProvider()

    const isReady = ref(false)
    const failed = ref(false)

    const confirmation = computed(() => payment.value.confirmation)
    const paymentId = computed(() => props.orderId || props.id)
    const order = computed(() => {
      const order = confirmation.value?.order ?? {}

      return {
        ...order,
        paymentMethods: sortPaymentMethodsByType(
          (order.status === 'scheduled'
            ? order.scheduledPaymentMethods
            : order.paymentMethods) ?? []
        ),
      }
    })
    const status = computed(() => {
      const { status = '' } = order.value

      return status.toLowerCase()
    })

    const statusLabel = computed(() => {
      switch (status.value) {
        case 'scheduled':
          return {
            label: 'Scheduled',
            color: 'text-warning-700',
          }
        case 'payees_synced':
        case 'completed':
          return {
            label: 'Completed',
            color: 'text-success-700',
          }
        case 'processing':
        case 'pending':
          return {
            label: 'Pending',
            color: 'text-warning-700',
          }
        case 'awaiting_funds_pi':
          return {
            label: 'Awaiting funds',
            color: 'text-warning-700',
          }
        case 'remittance_pending':
          return {
            label: 'Remittance pending',
            color: 'text-warning-700',
          }
        case 'completed_with_remittance_failures':
          return {
            label: 'Completed with remittance failure',
            color: 'text-error-700',
          }
        case 'cancelled':
        case 'expired':
          return {
            label: 'Cancelled',
            color: 'text-error-700',
          }
        default:
          return {
            label: 'Failed',
            color: 'text-error-700',
          }
      }
    })

    const financialStatus = computed(() => {
      const { financialStatus = '' } = order.value

      return financialStatus.toLowerCase()
    })

    const isRemittanceCompleted = computed(
      () => status.value === 'completed_with_remittance_failures'
    )

    const isCompleted = computed(() => {
      if (isPurchaseGroupCurrentFlowType.value) {
        return (
          financialStatus.value === 'paid' &&
          ['processing', 'completed'].includes(status.value)
        )
      } else {
        return ['completed', 'payees_synced'].includes(status.value)
      }
    })

    const isRemittancePending = computed(
      () => status.value === 'remittance_pending'
    )

    const isPending = computed(() => {
      if (isPurchaseGroupCurrentFlowType.value) {
        return !isCompleted.value && status.value === 'processing'
      } else {
        return ['processing', 'pending', 'awaiting_funds_pi'].includes(
          status.value
        )
      }
    })

    const isStatusGroupPending = computed(
      () => isPending.value || isRemittancePending.value
    )

    const isCancelled = computed(() =>
      ['cancelled', 'expired'].includes(status.value)
    )

    const payToLabel = computed(() =>
      isStatusGroupPending.value || isScheduled.value ? 'Paying to' : 'Paid to'
    )
    const payWithLabel = computed(() =>
      isStatusGroupPending.value || isScheduled.value
        ? 'Paying with'
        : 'Paid with'
    )

    const statusIcon = computed(() => {
      if (isCompleted.value) {
        return 'heroicons/solid/check-circle'
      } else if (isStatusGroupPending.value) {
        return 'heroicons/solid/clock'
      } else if (isScheduled.value) {
        return 'heroicons/mini/calendar-days'
      } else {
        return 'heroicons/solid/x-circle'
      }
    })

    const isScheduled = computed(() => {
      return status.value === 'scheduled'
    })
    const shouldBeDeliveredToEwallet = computed(() => {
      if (!firstItem.value) {
        return false
      }

      return (
        ['giftcard', 'voucher'].includes(firstItem.value.type) &&
        firstItem.value.physical === false
      )
    })
    const isFailed = computed(() => {
      return (
        !isCompleted.value &&
        !isStatusGroupPending.value &&
        !isScheduled.value &&
        !isRemittanceCompleted.value
      )
    })
    const items = computed(() => {
      return order.value.items ?? []
    })
    const firstItem = computed(() => {
      return items.value[0]
    })
    const hasPurchaseItem = computed(() => {
      return (
        purchases.active.hits?.find(
          (purchase) => purchase.orderNumber === order.value.number
        ) ||
        purchases.pending.hits?.find(
          (purchase) => purchase.orderNumber === order.value.number
        )
      )
    })
    const viewButtonTitle = computed(() => {
      if (isScheduled.value) {
        return 'View scheduled'
      }

      if (hasPurchaseItem.value) {
        return 'View eGift Cards'
      }

      return 'Make another payment'
    })
    const statusColor = computed(() => {
      if (isCompleted.value) {
        return 'text-emerald-700'
      } else if (isStatusGroupPending.value) {
        return 'text-warning-700'
      } else if (isScheduled.value) {
        return 'text-white p-2.5 bg-orange-700 rounded-full'
      } else {
        return 'text-error-700'
      }
    })
    const label = computed(() => {
      if (!payment.value.isConfirmationLoaded) {
        return 'Payment is loading'
      } else if (isRemittanceCompleted.value) {
        return 'Completed with remittance failure'
      } else if (isCompleted.value) {
        return 'Payment successful'
      } else if (isRemittancePending.value) {
        return 'Remittance pending'
      } else if (isPending.value) {
        return 'Payment processing'
      } else if (isScheduled.value) {
        return 'Payment scheduled'
      } else if (isCancelled.value) {
        return 'Payment cancelled'
      } else {
        return 'Payment failed'
      }
    })

    const pointsEarned = computed(() => {
      const pointsTransactions = order.value.pointsTransactions || []
      const earnTransaction = pointsTransactions.filter((transaction) => {
        return transaction.type === 'earn'
      })

      if (earnTransaction.length > 0) {
        return earnTransaction.reduce((pointsEarned, transaction) => {
          return pointsEarned + transaction.allocatedValue
        }, 0)
      }

      if (isPurchaseGroupCurrentFlowType.value && order.value.items) {
        return calculatePointsEarnedForPurchase(order.value.items)
      } else {
        return calculatePointsEarnedForPayment(order.value.subtotal)
      }
    })
    const formattedDate = computed(() => {
      const date =
        order.value.placedAt ??
        order.value.paidAt ??
        order.value.updatedAt ??
        order.value.completedAt ??
        order.value.createdAt

      if (date) {
        return formatDate('daymonthyearlongtime', date)
      } else {
        return null
      }
    })
    const payWithPayId = computed(() => {
      return order.value.paymentMethods.some(
        (paymentMethod) => paymentMethod.type === PaymentMethodType.payId
      )
    })
    const backRoute = computed(() => route.meta?.back ?? false)

    const showViewButton = computed(
      () =>
        !isBatchOrderCurrentFlowType.value &&
        (isScheduled.value ||
          hasPurchaseItem.value ||
          (!isPurchaseGroupCurrentFlowType.value &&
            Boolean(payment.value.rootRoute)))
    )

    const statusTracker = computed(
      () =>
        order.value.statusTracker?.map((item) => new StatusTracker(item)) || []
    )

    function onView() {
      if (isScheduled.value) {
        router.push({
          name: 'scheduled-payments-list',
          params: {
            type: 'payments',
          },
        })
      } else if (hasPurchaseItem.value) {
        const purchaseRoute = {
          hash: '#profile-e-purchases',
        }

        router.push(purchaseRoute)
      } else if (payment.value.rootRoute) {
        router.push(payment.value.rootRoute)
      } else {
        router.push({
          name: 'home',
        })
      }
    }

    function onLeave() {
      router.push(payment.value.doneRoute)
    }

    async function syncDispatchedStatus() {
      await fetchCurrentPurchases()

      firstItem.value.dispatched = true
    }

    onBeforeMount(async () => {
      try {
        currentFlowType.value = props.flowType
        await payment.value.getOrder(paymentId.value)

        if (isBatchOrderCurrentFlowType.value) {
          initBatchOrderPayeesTable()
        }

        if (shouldBeDeliveredToEwallet.value) {
          emitter.on(
            'notifications:order_item_dispatched',
            syncDispatchedStatus
          )
        }
      } catch (error) {
        console.error(error)
        failed.value = true
      } finally {
        isReady.value = true
      }
    })

    onBeforeUnmount(() => {
      if (!isFailed.value) {
        payment.value.reset()
      }

      emitter.off('notifications:order_item_dispatched', syncDispatchedStatus)
    })

    return {
      shouldBeDeliveredToEwallet,
      confirmation,
      formatBsb,
      formatBusinessNumber,
      formatDate,
      formatDollar,
      sortPaymentMethodsByType,
      purchases,
      isReady,
      failed,
      payment,
      paymentId,
      order,
      status,
      financialStatus,
      isCompleted,
      isStatusGroupPending,
      isScheduled,
      isFailed,
      isPending,
      items,
      firstItem,
      hasPurchaseItem,
      viewButtonTitle,
      statusColor,
      label,
      pointsEarned,
      formattedDate,
      payWithPayId,
      isPurchaseGroupCurrentFlowType,
      onView,
      onLeave,
      backRoute,
      selectedPurchaseCard,
      payToLabel,
      payWithLabel,
      statusIcon,
      showViewButton,
      currentFlowType,
      isBatchOrderCurrentFlowType,
      batchOrderPayeesTable,
      statusTracker,
      cancelDescription,
      statusLabel,
      isUserAddressEnabled,
    }
  },
}
</script>

<template>
  <div
    v-analytics:mount="{
      pageGroup: 'Make a payment',
      page: label.value,
      label: 'Result screen is displayed',
    }"
    class="flex h-full w-full grow overflow-y-auto"
  >
    <div class="mx-6 flex h-full w-full flex-col pt-[30px]">
      <div class="relative mx-auto flex h-full w-full max-w-lg flex-col">
        <div
          class="flex grow flex-col bg-white px-5 pb-[15px] sm:rounded-t-3xl sm:pt-5"
        >
          <base-loader v-if="!isReady" fullwidth />
          <div
            v-else-if="failed"
            class="py-[30px] text-center text-2xl font-bold"
          >
            Order information not found

            <div class="mt-[30px]">
              <base-button full-width @click="$router.push({ name: 'home' })">
                Back to Dashboard
              </base-button>
            </div>
          </div>

          <template v-else>
            <div class="grow">
              <div class="flex items-center justify-center px-5 pb-5 sm:px-10">
                <div class="flex flex-col items-center">
                  <div class="flex items-center justify-center rounded-full">
                    <base-icon
                      :svg="statusIcon"
                      :size="64"
                      :class="statusColor"
                    />
                  </div>

                  <span class="mt-4 text-2xl font-bold text-eonx-neutral-800">
                    {{ label }}
                  </span>
                </div>
              </div>

              <div class="text-center text-sm font-bold text-eonx-neutral-800">
                <div v-if="formattedDate" data-testid="transaction-date">
                  {{ formattedDate }}
                </div>
                <div>Transaction No. #{{ paymentId }}</div>
                <div>
                  Status:
                  <span :class="statusLabel.color">
                    {{ statusLabel.label }}
                  </span>
                </div>
              </div>

              <div
                v-if="
                  payWithPayId &&
                  cancelDescription(order.status, order.subStatus)
                "
                class="mt-3 text-center"
                v-html="cancelDescription(order.status, order.subStatus)"
              ></div>

              <payment-status-tracker
                v-if="statusTracker.length"
                class="mt-6 mb-8"
                :items="statusTracker"
              />
              <points-panel
                v-if="!isFailed && payment.isOrderPoints"
                :total="pointsEarned"
                :completed="!isFailed && !isStatusGroupPending && !isScheduled"
                class="mt-[15px] rounded-lg"
              />

              <confirmation-addresses
                v-if="
                  isUserAddressEnabled &&
                  (order.billingAddress || order.shippingAddress)
                "
                class="mt-6 border-b border-eonx-neutral-200 pb-5"
                :order="order"
              />

              <div class="mt-6">
                <div class="mb-5 font-bold">{{ payToLabel }}</div>
                <confirmation-pay-to :order="order" />
              </div>

              <div class="mt-5 border-t border-eonx-neutral-200 pt-5">
                <div class="mb-5 font-bold">{{ payWithLabel }}</div>
                <confirmation-pay-with :order="order" />
              </div>
            </div>

            <confirmation-total :order="order" />
            <template v-if="isBatchOrderCurrentFlowType">
              <div
                class="sticky top-9 z-10 my-6 h-px border-t border-neutral-400"
              ></div>
              <batch-payee-table
                :pagination="batchOrderPayeesTable.pagination"
                :payees="batchOrderPayeesTable.transactionList.list"
                :tabs="batchOrderPayeesTable.tabs.list"
                :loading="batchOrderPayeesTable.loader.loading"
                class="py-6 text-eonx-neutral-800"
                @select-tab="batchOrderPayeesTable.setActiveTab($event)"
                @select-page="batchOrderPayeesTable.load($event)"
                @select-per-page="batchOrderPayeesTable.setPerPage($event)"
              />
            </template>
          </template>
        </div>

        <div
          class="sticky bottom-0 z-10 border-t border-eonx-neutral-200 bg-white p-5"
        >
          <div
            class="grid gap-x-2"
            :class="{
              'grid-cols-1': !showViewButton,
              'grid-cols-2': showViewButton,
            }"
          >
            <base-button
              v-if="showViewButton"
              v-analytics:click="{
                pageGroup: 'Make a payment',
                page: label.value,
                cta: viewButtonTitle.value,
              }"
              data-vitest="view-button"
              look="outlined-color"
              :loading="!isReady"
              @click="onView"
            >
              {{ viewButtonTitle }}
            </base-button>
            <base-button
              v-analytics:click="{
                pageGroup: 'Make a payment',
                page: label.value,
                cta: 'Done',
              }"
              @click="onLeave"
            >
              Done
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
