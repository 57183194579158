<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { Activity, useActivityListItem } from '/~/composables/activity'

const props = defineProps<{ item: Activity }>()

const {
  amount,
  canSeeDetails,
  icon,
  iconColor,
  label,
  logo,
  pointsTransactions,
  subtitle,
  title,
} = useActivityListItem(props)
</script>

<template>
  <base-link
    :to="{ name: 'activity-details', params: { id: item.id } }"
    :active="canSeeDetails"
    :aria-label="label"
    class="block w-full"
    :class="{
      'text-neutral-400': !canSeeDetails,
    }"
  >
    <div class="flex items-center justify-evenly space-x-[15px]">
      <img v-if="logo" class="h-12 w-12" :src="logo" alt="" />
      <div
        v-else
        class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full"
        :class="iconColor"
      >
        <base-icon :svg="icon" />
      </div>
      <div class="min-w-0 shrink truncate" data-testid="title-column">
        <p class="truncate font-bold text-eonx-neutral-800">
          {{ title }}
        </p>
        <p class="truncate text-sm text-eonx-neutral-600">
          <span
            v-if="item.isStatusCompleted"
            class="text-sm font-semibold text-success-700"
          >
            Completed
          </span>
          <span
            v-else-if="item.isStatusPending"
            class="text-sm font-semibold text-warning-700"
          >
            Pending
          </span>
          <span
            v-else-if="item.isStatusIncomplete"
            class="text-sm font-semibold text-error-700"
          >
            Incomplete
          </span>
          <span
            v-else-if="item.isStatusFailed"
            class="text-sm font-semibold text-error-700"
          >
            Failed
          </span>
          <span v-if="subtitle" class="ml-[5px] text-eonx-neutral-600">
            #{{ subtitle }}
          </span>
          <span
            v-if="item.orderNumber"
            class="ml-[5px] truncate text-eonx-neutral-600"
          >
            #{{ item.orderNumber }}
          </span>
          <span
            v-if="item.isTypeUserCommunication"
            class="text-sm text-eonx-neutral-600"
          >
            {{ subtitle }}
          </span>
        </p>
      </div>

      <div
        v-if="item.isTypePointsTransferRequest"
        class="flex-1 shrink-0"
      ></div>

      <div
        v-else-if="!item.isTypeUserCommunication"
        class="flex-1 shrink-0 whitespace-nowrap text-right text-eonx-neutral-600"
      >
        <div
          v-if="item.isTypeProgramOrder || item.isTypePointsTransaction"
          class="flex flex-col"
        >
          <span
            v-for="(pointsTransaction, key) in pointsTransactions"
            :key="key"
          >
            {{ pointsTransaction }}
          </span>
        </div>
        <template v-else>
          {{ amount }}
        </template>
      </div>
    </div>
  </base-link>
</template>
