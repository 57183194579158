<script>
import debounce from 'lodash-es/debounce'
import { ref, computed, nextTick } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import DrawerScroll from '/~/components/drawer/components/drawer-scroll.vue'
import { useLoyaltyCards } from '/~/composables/loyalty-cards'

export default {
  name: 'drawer-loyalty-brands',
  components: {
    DrawerScroll,
    BaseInput,
    BaseIcon,
    BaseAsidePage,
    BaseImage,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup(_, { emit }) {
    const { isCatalogCardsLoading, catalogCardsByLetters, getCatalogCards } =
      useLoyaltyCards()

    const searchQuery = ref('')

    const filteredCardsByLetters = computed(() => {
      const searchValue = searchQuery.value.toLowerCase()

      return catalogCardsByLetters.value
        .map((group) => {
          return {
            letter: group.letter,
            cards: group.cards.filter((card) => {
              const cardName = card.name.trim().toLowerCase()

              return cardName.includes(searchValue)
            }),
          }
        })
        .filter((group) => {
          return group.cards.length > 0
        })
    })

    const setSearchQuery = debounce((value) => {
      searchQuery.value = value
    }, 400)

    const letters = computed(() => {
      return filteredCardsByLetters.value.map((group) => group.letter)
    })

    getCatalogCards()

    function selectCatalogCard(card) {
      emit('select', card.slug)
    }

    function selectCustomCard() {
      emit('select', 'other')
    }

    return {
      filteredCardsByLetters,
      isCatalogCardsLoading,
      letters,
      searchQuery,
      selectCustomCard,
      selectCatalogCard,
      setSearchQuery,
    }
  },
  methods: {
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    title="Add Loyalty Cards"
    class="flex flex-col"
    no-padding
  >
    <template v-if="!isCatalogCardsLoading" #header>
      <div class="px-5 pb-5">
        <base-input
          :value="searchQuery"
          look="rounded"
          icon="plain/search"
          nolabel
          placeholder="Search Brand"
          @input="setSearchQuery"
        />
      </div>
    </template>

    <div v-if="isCatalogCardsLoading" class="space-y-[15px] p-5">
      <div class="h-5 animate-pulse rounded-xl bg-divider" />
      <div class="h-5 animate-pulse rounded-xl bg-divider" />
      <div class="h-5 animate-pulse rounded-xl bg-divider" />
    </div>
    <template v-else>
      <div class="border-b p-2.5">
        <button
          class="w-full cursor-pointer rounded-lg p-2.5 text-left hover:bg-light"
          @click="selectCustomCard()"
        >
          <span class="flex items-center justify-between">
            <span
              class="mr-5 flex h-12 w-12 shrink-0 items-center justify-center overflow-hidden rounded-full bg-primary text-white"
            >
              <base-icon svg="plus" size="md" />
            </span>
            <span class="min-w-0 grow truncate font-bold leading-7">Other</span>
          </span>
        </button>
      </div>

      <div
        v-if="filteredCardsByLetters.length === 0"
        class="py-5 text-center font-bold"
      >
        No Brands
      </div>

      <drawer-scroll
        :letters="letters"
        id-prefix="sel-letter-"
        class="overflow-y-auto"
      >
        <div
          v-for="group in filteredCardsByLetters"
          :id="`sel-letter-${group.letter}`"
          :key="group.letter"
          class="pl-2.5 pr-[45px]"
        >
          <div class="mt-5 px-5 pb-2.5 font-bold">
            {{ group.letter }}
          </div>

          <button
            v-for="card in group.cards"
            :key="card.id"
            class="w-full cursor-pointer rounded-lg p-2.5 text-left hover:bg-light"
            @click="selectCatalogCard(card)"
          >
            <span class="flex items-center justify-between">
              <span
                class="mr-5 flex h-12 w-12 shrink-0 items-center justify-center overflow-hidden rounded-full"
              >
                <base-image v-bind="card.image" :ratio="1" :rounded="false" />
              </span>
              <span class="min-w-0 grow truncate font-bold leading-7">
                {{ card.name }}
              </span>
            </span>
          </button>
        </div>
      </drawer-scroll>
    </template>
  </base-aside-page>
</template>
