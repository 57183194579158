<script setup lang="ts">
import Loading from '/~/views/loading.vue'
import { useUser } from '/~/composables/user'

const { user } = useUser()
</script>

<template>
  <div class="h-full">
    <loading v-if="user.authorized" />
    <router-view v-else />
  </div>
</template>
