<script>
import DefaultMajorSummary from '/~/extensions/gift-cards-cinema/components/major-summary.vue'
import ProductHeader from '/~/extensions/giftcards/templates/bill-payments/components/product/product-header.vue'
import ProductTotal from '/~/extensions/giftcards/templates/bill-payments/components/product/product-total.vue'

export default {
  components: {
    ProductTotal,
    ProductHeader,
  },
  extends: DefaultMajorSummary,
  props: {
    showProductPrice: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <div class="relative w-full">
    <product-header :retailer="gcCinemaMajorRetailer" />
    <h3 class="pb-[5px] text-center sm:hidden">
      {{ gcCinemaMajorRetailer.attributes.name }}
    </h3>
    <div
      class="mx-5 mt-5 flex flex-col justify-around rounded border border-eonx-neutral-200 bg-light bg-white px-[30px] py-[15px] md:flex-row md:justify-between md:px-5"
    >
      <div v-if="gcCinemaMajorProducts" class="flex flex-col space-y-[15px]">
        <div
          v-for="(product, idx) in gcCinemaMajorProducts"
          :key="idx"
          class="flex items-center justify-evenly md:justify-start"
        >
          <div class="mr-2.5 flex w-[120px] items-center">
            <div class="capitalize">
              {{ product.display_label }}
            </div>
            <template v-if="showProductPrice">
              ({{ formatDollar(product.price) }})
            </template>
          </div>
          <base-counter
            v-model="product.count"
            :disabled="!product.in_stock || isGcCinemaMajorProcessing"
            :min="product.min"
            :max="product.max || 10"
          />
        </div>
      </div>
      <product-total
        :amount="gcCinemaMajorTotal"
        class="mt-2.5 md:mt-0 md:ml-5"
      />
    </div>

    <base-alert
      v-if="disclaimer"
      title="Disclaimer"
      type="warning"
      look="light-filled"
      class="mt-5"
    >
      <span class="markup text-justify" v-html="disclaimer" />
    </base-alert>

    <retailer-gift
      v-if="isGiftingEnabled"
      ref="retailerGift"
      v-model="recipient"
      :visible.sync="gift.visible"
      :filled.sync="gift.filled"
      :disabled="isGcCinemaMajorProcessing"
      :product-labels="productLabels"
      :backend-errors="backendErrors"
      class="my-6 px-5 sm:p-0"
    />

    <product-add
      :is-add-button-show="isAddToCartEnabled"
      :is-quick-buy-button-show="isQuickBuyEnabled"
      :is-quick-buy-disabled="isQuickBuyButtonDisabled"
      :is-add-button-disabled="isSubmitDisabled"
      :adding="isGcCinemaMajorProcessing"
      buttons-fixed
      @click-add-to-quick-buy="onBuyNow"
      @click-add-to-cart="onSubmit"
    />
  </div>
</template>
