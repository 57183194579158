<script setup lang="ts">
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import { useUI } from '/~/composables/ui'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useSwiper } from '/~/composables/base/use-swiper'

type GalleryItem = {
  url: string
}

const props = withDefaults(
  defineProps<{
    gallery?: GalleryItem[]
    defaultSrc?: string
  }>(),
  {
    gallery: () => [],
  }
)

const { isIOS } = useUI()
const {
  swiper,
  swiperContainer,
  initSwiper,
  destroySwiper,
  selectSlide: selectSwiperSlide,
} = useSwiper(
  computed(() => ({
    spaceBetween: 10,
    on: {
      slideChange: onSwiperClick,
    },
  }))
)
const {
  swiper: thumbs,
  swiperContainer: thumbsContainer,
  initSwiper: initThumbs,
  destroySwiper: destroyThumbs,
  selectSlide: selectThumb,
} = useSwiper(
  computed(() => ({
    spaceBetween: 10,
    slidesPerView: 5,
    on: {
      tap: onThumbClick,
    },
  }))
)

const currentImageIndex = ref(0)
const currentGalleryIndex = ref(null)

const imageSrc = computed(() =>
  props.gallery.length ? props.gallery[0]?.url : ''
)
const images = computed(() =>
  props.gallery.map((item) => ({
    cdn: item.url,
    type: 'image',
  }))
)

watch(currentGalleryIndex, (value) => {
  if (typeof value === 'number') {
    ui.lockViewport()
  } else {
    ui.unlockViewport()
  }
})

onBeforeUnmount(() => {
  destroySwiper()
  destroyThumbs()
})

watch(swiperContainer, initSwiper)
watch(thumbsContainer, initThumbs)

function onSwiperClick() {
  if (swiper.value) {
    selectSlide(swiper.value.activeIndex)
  }
}

function onThumbClick() {
  if (thumbs.value) {
    selectSlide(thumbs.value.clickedIndex)
  }
}

function onImageClick(_: Event, index: number) {
  modal.show('rec-media-gallery', {
    props: {
      sources: images.value,
      initialItemIndex: index,
    },
  })
}

function selectSlide(index: number) {
  if (
    typeof index === 'undefined' ||
    index === -1 ||
    index === currentImageIndex.value
  ) {
    return
  }

  selectThumb(index - 1) // to center selection
  selectSwiperSlide(index)
  currentImageIndex.value = index
}

function prevSlide() {
  if (!swiper.value) return

  const newIndex = swiper.value.activeIndex - 1

  if (newIndex >= 0) {
    selectSlide(newIndex)
  }
}

function nextSlide() {
  if (!swiper.value) return

  const newIndex = swiper.value.activeIndex + 1

  if (newIndex < props.gallery.length) {
    selectSlide(newIndex)
  }
}

function getActiveIndex() {
  if (swiper.value) {
    return swiper.value.activeIndex
  }
}
</script>

<template>
  <div class="mx-auto max-w-[500px]">
    <div v-if="gallery.length > 1">
      <div
        ref="swiperContainer"
        class="relative mt-2.5 overflow-hidden rounded-lg"
        :class="[
          'swiper-container',
          isIOS && 'overflow-auto' /*ios z-index fix for gallery arrows*/,
        ]"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in gallery"
            ref="slide"
            :key="index"
            :class="['swiper-slide', !ui.mobile && 'cursor-pointer']"
            @click="ui.mobile ? null : onImageClick($event, index)"
          >
            <base-image :src="item.url" :default-src="defaultSrc" :ratio="1" />
          </div>
        </div>
        <div
          class="absolute top-0 right-0 z-1 flex h-full w-11 cursor-pointer items-center justify-center text-white opacity-50 transition duration-100 hover:bg-overlay hover:text-white"
          :class="['', getActiveIndex() === gallery.length - 1 && 'opacity-0']"
          @click="nextSlide"
        >
          <div class="rounded-full bg-gray-800 p-2.5">
            <base-icon svg="plain/chevron-right" size="sm" />
          </div>
        </div>
        <div
          class="absolute top-0 left-0 z-1 flex h-full w-11 cursor-pointer items-center justify-center text-white opacity-50 transition duration-100 hover:bg-overlay hover:text-white"
          :class="[getActiveIndex() === 0 && 'opacity-0']"
          @click="prevSlide"
        >
          <div class="rounded-full bg-gray-800 p-2.5">
            <base-icon svg="plain/chevron-left" size="sm" />
          </div>
        </div>
      </div>
      <div
        ref="thumbsContainer"
        class="swiper-container mt-2.5 overflow-hidden"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in gallery"
            ref="slide"
            :key="index"
            class="swiper-slide cursor-pointer"
            :class="{
              'opacity-50': currentImageIndex !== index,
            }"
          >
            <base-image
              :src="item.url"
              :default-src="defaultSrc"
              rounded-size="normal"
            />
          </div>
        </div>
      </div>
    </div>
    <base-image
      v-else
      class="shadow-md"
      :src="imageSrc"
      :default-src="defaultSrc"
      :ratio="1"
    />
  </div>
</template>
