<script>
import get from 'lodash-es/get'
import BaseAlert from '/~/components/base/alert/base-alert.vue'
import BaseLink from '/~/components/base/link/base-link.vue'

export default {
  name: 'app-alerts',
  components: {
    BaseLink,
    BaseAlert,
  },
  methods: { get },
}
</script>

<template>
  <notifications
    :width="null"
    position="top right"
    class="w-full max-w-full overflow-visible p-[5px] sm:m-2.5 sm:w-xs sm:p-0"
  >
    <template #body="{ item, close }">
      <base-alert
        :type="item.type || 'info'"
        :look="get(item, 'data.look')"
        :closable="!get(item, 'data.persistent')"
        class="shadow-lg"
        @close="close"
      >
        <p
          data-test="single-notification"
          class="text-base"
          v-html="item.text"
        />
        <div v-if="item.data && item.data.notification" class="mt-2.5">
          <template v-for="(action, idx) in item.data.notification.actions">
            <component
              :is="action.component || 'base-link'"
              :key="action.label + idx"
              :to="{
                path: action.link,
              }"
              v-bind="action.params"
              class="font-bold text-white"
            >
              {{ action.label }}
            </component>
          </template>
        </div>
      </base-alert>
    </template>
  </notifications>
</template>
