<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseHeader from '/~/components/base/header/base-header'
import RenderBanner from '/~rec/components/render/render-banner.vue'

export default {
  name: 'rec-header-mobile',
  components: {
    BaseIcon,
    BaseHeader,
    RenderBanner,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <base-header type="mobile-plain">
    <div class="z-40 w-full px-5 py-6">
      <div class="flex items-center justify-between">
        <div class="w-12">
          <div
            v-if="showBack"
            class="w-12 cursor-pointer pr-5 text-eonx-neutral-800"
            @click="$emit('back')"
          >
            <base-icon svg="rec/back" size="3xl" />
          </div>
        </div>
        <div class="flex min-w-0 items-center justify-start">
          <render-banner
            v-if="image"
            :image="image"
            :ratio="false"
            class="mr-2.5 h-12 w-12 min-w-12 max-w-12 cursor-pointer"
            @click="$emit('img-click')"
          />
          <div v-else-if="$slots.image" class="mr-2.5 cursor-pointer">
            <slot name="image" />
          </div>

          <slot v-if="$slots.title" name="title" />
          <h1
            v-else
            class="cursor-pointer truncate text-xl leading-6 text-eonx-neutral-800"
            @click="$emit('title-click')"
          >
            {{ title }}
          </h1>

          <slot name="icons" />
        </div>
        <div class="flex w-12 min-w-12 items-center justify-end pl-5">
          <slot name="right" />
        </div>
      </div>
    </div>
    <slot name="default" />
  </base-header>
</template>
