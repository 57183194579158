<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useGcCinema } from '/~/extensions/gift-cards-cinema/composables'
import GcCatalog from '/~/extensions/gift-cards-cinema/templates/bupa/components/gc-catalog.vue'
import BaseBack from '/~/components/base/back/base-back.vue'
import EwDetailsHeader from '/~/components/ewallet/ew-details-header.vue'
import { useEditablePage } from '/~/composables/cms'

const { gcCinemaGroupBy, gcCinemaSelectedGroup } = useGcCinema()

const { page } = useEditablePage()
const route = useRoute()
const router = useRouter()

if (!gcCinemaGroupBy.value?.values.includes(gcCinemaSelectedGroup.value)) {
  router.push({ name: 'cinema' })
}

const currentPage = computed(() => {
  if (!route.params?.groupSlug) {
    // TODO: add allowed routes configuration in CMS
    console.debug('extension only allowed for gift-cards-cinema route')
    return null
  }

  return page.value?.settings.groups?.find(
    (item) => item.slug.toLowerCase() === route.params.groupSlug.toLowerCase()
  )
})
</script>

<template>
  <div class="mx-auto w-full max-w-7xl">
    <ew-details-header :title="currentPage?.title" :back="{ name: 'cinema' }" />

    <div class="hidden items-center py-8 px-2.5 sm:flex sm:px-5">
      <base-back :to="{ name: 'cinema' }" />
    </div>

    <div class="p-2.5 sm:p-5">
      <template v-if="currentPage">
        <div
          class="mb-2.5 hidden text-2xl sm:block"
          v-html="currentPage.title"
        />
        <div
          class="[&>p>a]:text-primary [&>p>a]:underline"
          v-html="currentPage.description"
        />
      </template>

      <gc-catalog class="mt-4" />
    </div>
  </div>
</template>
