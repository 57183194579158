<script>
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'drawer-avatar-v2',
  components: {
    BaseIcon,
    BaseAvatar,
  },
  props: {
    avatarUrl: {
      type: null,
      default: '',
    },
    action: {
      type: Function,
      default: null,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isAvatarEditable } = useProvider()

    return {
      isAvatarEditable,
    }
  },
  computed: {
    isAction() {
      return this.isAvatarEditable && !!this.action
    },
  },
  methods: {
    onClick() {
      if (this.isAction) {
        this.action()
      }
    },
  },
}
</script>

<template>
  <div
    :class="[
      `relative shrink-0`,
      'text-neutral-800',
      !(large || action) && 'mr-[15px]',
      (large || action) && 'mx-auto mt-[30px] block h-40 w-40',
      isAction && 'cursor-pointer',
    ]"
    @click="onClick"
  >
    <base-avatar
      v-if="avatarUrl"
      :size="!(large || action) ? 'md' : null"
      :class="[
        !(large || action) && 'border-3',
        (large || action) && 'h-full w-full',
        'rounded-full border-white',
      ]"
      :src="avatarUrl"
    />
    <div
      v-else
      class="flex h-full w-full items-center justify-center rounded-full bg-eonx-neutral-200"
    >
      <base-icon
        class="[&>svg]:stroke-[1]"
        :size="90"
        svg="heroicons/outline/user"
      />
    </div>

    <div
      v-if="isAction"
      class="absolute -bottom-2.5 -right-2.5 ml-auto flex h-[60px] w-[60px] items-center justify-center rounded-full border-7 border-neutral-50 bg-primary text-3xl text-white"
    >
      <base-icon svg="points-only/camera" size="sme" />
    </div>
  </div>
</template>
