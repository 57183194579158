<script>
import EpurchaseTile from '/~/templates/australiapost/components/drawer/components/tiles/drawer-e-purchases-tile.vue'
import DefaultCurrentPurchases from '/~/components/drawer/views/e-purchases/drawer-e-purchases-current.vue'
import { defineComponent } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import { usePurchases } from '/~/composables/purchases'

export default defineComponent({
  components: {
    EpurchaseTile,
  },
  extends: DefaultCurrentPurchases,
  setup(props) {
    const { purchases, fetchCurrentPurchases } = usePurchases()

    if (!purchases.active.processing) {
      fetchCurrentPurchases()
    }

    function showUnlockModal() {
      bottomSheet.show('epurchase-unlock-v3', {
        to: props.to,
      })
    }

    return {
      ...DefaultCurrentPurchases.setup?.(...arguments),
      purchases,
      showUnlockModal,
    }
  },
})
</script>
