<script setup lang="ts">
import dayjs from 'dayjs/esm'
import {
  nextTick,
  onBeforeUnmount,
  ref,
  computed,
  watch,
  onMounted,
  ComputedRef,
} from 'vue'
import ui from '/~/core/ui'
import { formatDate, createDate } from '/~/utils/format/date'
import BaseButtonRound from '/~/components/base/button-round/base-button-round.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import { useSwiper, SwiperOptions } from '/~/composables/base/use-swiper'
import { useLocalization } from '/~/composables/localization'

interface Day {
  raw: dayjs.Dayjs
  month: string | number | Date | dayjs.Dayjs | null | undefined
  formatted: string | number | Date | dayjs.Dayjs | null | undefined
}

const props = withDefaults(
  defineProps<{
    date?: string
    slideSpeed?: number
    disabled?: boolean
  }>(),
  {
    date: '',
    slideSpeed: 300,
    disabled: false,
  }
)

const emit = defineEmits<{
  (event: 'change', day: ReturnType<typeof formatDate>): void
}>()

const today = ref(formatDate('daymonthyearnumeric', createDate()))
const days = ref<Day[]>([])
const isPrevButtonDisabled = ref(false)
const isNextButtonDisabled = ref(false)
const { getDatePickerFormat } = useLocalization()

const { swiper, swiperContainer, initSwiper, destroySwiper } = useSwiper(
  computed(() => ({
    slidesPerView: 'auto',
    navigation: {
      prevEl: !ui.mobile && '.button-prev',
      nextEl: !ui.mobile && '.button-next',
    },
    on: {
      slideChange: (swiper) => {
        nextTick(() => {
          isPrevButtonDisabled.value = swiper.isBeginning
          isNextButtonDisabled.value = swiper.isEnd
        })
      },
    },
  })) as ComputedRef<SwiperOptions>
)

function setDays() {
  destroySwiper()
  initSwiperAndSlide()
  /*
   * Set the first day of the month for the date of the model
   */
  const firstMonthDate = createDate(
    props.date,
    getDatePickerFormat('daymonthyearnumeric')
  ).date(1)
  const daysInMonth = firstMonthDate.daysInMonth()
  const daysArray = []

  for (let idx = 0; idx < daysInMonth; idx++) {
    const day = firstMonthDate.add(idx, 'day')

    daysArray.push({
      raw: day,
      month: formatDate('monthyear', day),
      formatted: formatDate('daymonthyearnumeric', day),
    })
  }

  days.value = daysArray
}

async function slideToActiveDate(noAnimation?: boolean) {
  await nextTick()

  if (props.date && swiper.value) {
    const day = createDate(
      props.date,
      getDatePickerFormat('daymonthyearnumeric')
    ).date()
    const slideSpeed = noAnimation ? 0 : props.slideSpeed

    swiper.value.slideTo(day - 1, slideSpeed)
  }
}

function initSwiperAndSlide() {
  nextTick(() => {
    initSwiper()
    slideToActiveDate(true)
  })
}

function onDayClick(day: Day) {
  emit('change', day.formatted)
}

watch(
  () => props.date,
  () => {
    /*
     * Update days if month or year has changed
     */
    const monthOfCurentDate = createDate(
      days.value[0].raw,
      getDatePickerFormat('daymonthyearnumeric')
    ).month()
    const monthOfNewDate = createDate(
      props.date,
      getDatePickerFormat('daymonthyearnumeric')
    ).month()

    if (monthOfCurentDate !== monthOfNewDate) {
      setDays()
    }
    slideToActiveDate()
  }
)

onBeforeUnmount(destroySwiper)
onMounted(setDays)

defineExpose({
  slideToActiveDate,
  initSwiperAndSlide,
})
</script>

<template>
  <div class="flex sm:px-3.5">
    <base-button-round
      v-if="ui.desktop"
      icon="heroicons/mini/chevron-left"
      class="button-prev mr-5"
      icon-color="primary"
      color="white"
      :disabled="isPrevButtonDisabled || disabled"
    />
    <div ref="swiperContainer" class="swiper-container overflow-hidden">
      <div class="swiper-wrapper">
        <div v-for="(day, idx) in days" :key="idx" class="swiper-slide !w-auto">
          <base-date
            :date="day.raw"
            :active="today === day.formatted"
            :selected="date === day.formatted"
            look="card"
            class="cursor-pointer"
            @click="onDayClick(day)"
          />
        </div>
      </div>
    </div>
    <base-button-round
      v-if="ui.desktop"
      icon="heroicons/mini/chevron-right"
      class="button-next ml-5"
      icon-color="primary"
      color="white"
      :disabled="isNextButtonDisabled || disabled"
    />
  </div>
</template>
