<script>
import { computed } from 'vue'
import { useUser } from '/~/composables/user'
import WidgetAustraliapostHomeBanner from './widget-australiapost-home-banner.vue'

export default {
  name: 'widget-australiapost-welcome-banner',
  components: {
    WidgetAustraliapostHomeBanner,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { user } = useUser()
    const welcomeContent = computed(() => {
      return {
        ...props.content,
        title: `Welcome ${user.value.firstName},`,
      }
    })

    return {
      user,
      welcomeContent,
    }
  },
}
</script>

<template>
  <widget-australiapost-home-banner v-bind="$attrs" :content="welcomeContent" />
</template>
