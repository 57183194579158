import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomGroup } from '/~rec/core/group'
import { reactive, computed } from 'vue'
import { useRecProfile } from './profile'

const { defaultGroupId, myRecId } = useRecProfile()

const defaultFilters = {
  recent: {
    'order[updatedAt]': 'desc',
  },
  popular: {
    'order[totalMembers]': 'desc',
  },
}

const state = reactive({
  defaultGroup: null,
  all: new EntityProcessor({
    entity: 'groups',
    perPage: 500,
    mapping: (record) => new RecroomGroup(record),
  }),
  my: new EntityProcessor({
    entity: 'groups',
    mapping: (record) => new RecroomGroup(record),
    filters: defaultFilters,
    filterForHits: {
      condition: (hit) => hit.id === defaultGroupId.value,
    },
  }),
  other: new EntityProcessor({
    entity: 'groups',
    mapping: (record) => new RecroomGroup(record),
    filters: defaultFilters,
    filterForHits: {
      condition: (hit) => hit.id === defaultGroupId.value,
    },
  }),
})

const allMembers = computed(() => state.defaultGroup?.members?.hits)

function getMember(id, key = 'id') {
  return allMembers.value.find((m) => m[key] === id)
}

async function initGroups() {
  return Promise.all([
    state.all.getData(),
    state.my.getData({ query: { user: myRecId.value, isMember: true } }),
    fetchDefaultGroup(),
  ])
}

async function fetchDefaultGroup() {
  if (defaultGroupId.value) {
    const group = await RecroomGroup.fetchById(defaultGroupId.value)

    if (group) {
      state.defaultGroup = group
      await group.members.getData({ perPage: 1000 })
    }
  }
}

export const useGroups = () => ({
  groups: state,
  getMember,
  allMembers,
  initGroups,
  fetchDefaultGroup,
})
