<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import Hi5SendTo from '/~rec/views/recognition/hi5/hi5-send-to.vue'

export default {
  name: 'hi5-send-to-root',
  components: {
    Hi5SendTo,
  },
  props: {
    profile: {
      type: Object,
      default: null,
    },
    sendToId: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  created() {
    if (!ui.desktop) {
      this.openMobileForm()
    }
  },
  methods: {
    openMobileForm() {
      modal.show('rec-hi5-send-to-mobile', {
        props: {
          profile: this.profile,
          sendToId: this.sendToId,
        },
      })
    },
  },
}
</script>

<template>
  <hi5-send-to
    v-if="ui.desktop"
    class="p-[30px] pt-10"
    :user="profile"
    :send-to-id="sendToId"
  />
</template>
