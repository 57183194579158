<script>
import DrawerHelpConfirmation from '/~/components/drawer/views/settings/help/drawer-help-confirmation.vue'

export default {
  name: 'profile-help-confirmation',
  components: {
    DrawerHelpConfirmation,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    onDone() {
      this.$router.replace({ hash: '#profile-settings' })
    },
  },
}
</script>

<template>
  <drawer-help-confirmation :id="id" @done="onDone" />
</template>
