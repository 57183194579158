export const extensions = {
  DOC_EXTENSION: 'doc',
  DOCX_EXTENSION: 'docx',
  ODT_EXTENSION: 'odt',
  SXW_EXTENSION: 'sxw',
  PUB_EXTENSION: 'pub',
  MYO_EXTENSION: 'myo',
  MYOB_EXTENSION: 'myob',
  GDOC_EXTENSION: 'gdoc',
  TXT_EXTENSION: 'txt',
  XML_EXTENSION: 'xml',
  ZIP_EXTENSION: 'zip',
  XLS_EXTENSION: 'xls',
  XLSX_EXTENSION: 'xlsx',
  CSV_EXTENSION: 'csv',
  PDF_EXTENSION: 'pdf',
  PPT_EXTENSION: 'ppt',
  PPTX_EXTENSION: 'pptx',
  BMP_EXTENSION: 'bmp',
  EXIF_EXTENSION: 'exif',
  GIF_EXTENSION: 'gif',
  JPEG_EXTENSION: 'jpeg',
  JPG_EXTENSION: 'jpg',
  PNG_EXTENSION: 'png',
  PSD_EXTENSION: 'psd',
  PDD_EXTENSION: 'pdd',
  TIF_EXTENSION: 'tif',
  TIFF_EXTENSION: 'tiff',
  GP3_EXTENSION: '3gp',
  AVI_EXTENSION: 'avi',
  MOV_EXTENSION: 'mov',
  MPEG_EXTENSION: 'mpeg',
  MPG_EXTENSION: 'mpg',
  MPE_EXTENSION: 'mpe',
  MKV_EXTENSION: 'mkv',
  MP4_EXTENSION: 'mp4',
  WMA_EXTENSION: 'wma',
  MP3_EXTENSION: 'mp3',
  SWA_EXTENSION: 'swa',
  SKETCH_EXTENSION: 'sketch',
  AI_EXTENSION: 'ai',
  INDD_EXTENSION: 'indd',
  PS_EXTENSION: 'ps',
}

export const fileTypeRestriction = (acceptedExtensions) => (fileInfo) => {
  if (fileInfo.name !== null) {
    const extension = fileInfo.name.split('.').pop()

    if (acceptedExtensions.indexOf(extension.toLowerCase()) === -1) {
      throw new Error('fileType')
    }
  }
}
