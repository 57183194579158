<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerBankTile from '/~/components/drawer/components/tiles/drawer-bank-tile.v3.vue'
import DrawerCardTile from '/~/components/drawer/components/tiles/drawer-card-tile.v3.vue'
import EmptyState from '/~/components/empty-state/empty-state.v2.vue'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

export default {
  name: 'drawer-payment-methods',
  components: {
    DrawerCardTile,
    DrawerBankTile,
    BaseButton,
    BaseAsidePage,
    BaseLoader,
    EmptyState,
    BaseIcon,
  },
  props: {
    back: {
      type: Object,
      default: null,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
  },
  setup() {
    const {
      fetchingList: fetching,
      creditCards,
      bankAccounts,
      noCreditCards,
      noBankAccounts,
      methodToVerify,
      isMethodAvailable,
      canAddPaymentMethods,
    } = usePaymentMethods()

    return {
      fetching,
      creditCards,
      bankAccounts,
      noCreditCards,
      noBankAccounts,
      methodToVerify,
      isMethodAvailable,
      canAddPaymentMethods,
      PaymentMethodType,
    }
  },
  computed: {
    isEmpty() {
      return !this.fetching && this.noCreditCards && this.noBankAccounts
    },
    pageAction() {
      if (this.fetching) {
        return null
      }

      return this.addPaymentMethod
    },
  },
  beforeDestroy() {
    this.methodToVerify = null
  },
  methods: {
    addPaymentMethod() {
      this.$emit('add')
    },
  },
}
</script>

<template>
  <base-aside-page
    title="Payment methods"
    :action="pageAction"
    :back="back"
    body-class="!bg-eonx-neutral-50"
  >
    <empty-state v-if="isEmpty" class="flex h-full flex-col pt-[72px]">
      <template #icon>
        <div
          class="mr-5 flex h-16 w-16 items-center justify-center rounded-full bg-primary"
        >
          <base-icon
            svg="heroicons/solid/credit-card"
            :size="40"
            class="text-white"
          />
        </div>
      </template>
      <template #title>No payment methods added</template>
      <template #description>
        <base-button
          v-if="canAddPaymentMethods"
          v-analytics:click="{
            pageGroup: 'Drawer',
            page: 'Payment methods',
            cta: 'Add Payment method',
          }"
          class="mt-4 w-full"
          @click="addPaymentMethod"
        >
          + Add Payment Method
        </base-button>
      </template>
    </empty-state>

    <div v-else class="space-y-5">
      <section v-if="isMethodAvailable(PaymentMethodType.creditCard)">
        <div class="mb-5 font-bold text-eonx-neutral-800">
          Credit/Debit Cards
        </div>
        <div
          v-if="fetching"
          class="flex w-full items-center justify-center py-[15px]"
        >
          <base-loader />
        </div>
        <div v-else-if="noCreditCards" class="flex justify-center">
          <div
            class="w-[300px] rounded-md border border-eonx-neutral-300 py-2.5 text-center text-eonx-neutral-600"
          >
            No cards added as yet
          </div>
        </div>

        <div v-else class="space-y-5">
          <drawer-card-tile
            v-for="card in creditCards"
            :key="card.id"
            :card="card"
          />
        </div>
      </section>

      <section v-if="isMethodAvailable(PaymentMethodType.bankAccount)">
        <div class="mb-5 font-bold text-eonx-neutral-800">Bank Accounts</div>
        <div
          v-if="fetching"
          class="flex w-full items-center justify-center py-[15px]"
        >
          <base-loader />
        </div>
        <div v-else-if="noBankAccounts" class="flex justify-center">
          <div
            class="w-[300px] rounded-md border border-eonx-neutral-300 py-2.5 text-center text-eonx-neutral-600"
          >
            No bank accounts added as yet
          </div>
        </div>

        <div v-else class="space-y-5">
          <drawer-bank-tile
            v-for="account in bankAccounts"
            :key="account.id"
            :account="account"
          />
        </div>
      </section>
    </div>
  </base-aside-page>
</template>
