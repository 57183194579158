<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import { RecroomHi5 } from '/~rec/core/hi5'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import RecognitionForm from '/~rec/components/recognition/recognition-form.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { useRecProfile } from '/~rec/composables/profile'
import { useGroups } from '/~rec/composables/groups'
import { useBackendValidation } from '/~rec/composables/backend-validation'

export default {
  name: 'rec-hi5s-available',
  components: {
    BaseIcon,
    BaseBack,
    BaseAvatar,
    RecognitionForm,
    HeaderMobile,
  },
  props: {
    hi5Id: {
      type: String,
      required: true,
    },
    nominee: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { currency } = useRecProfile()
    const { getMember } = useGroups()
    const { backendErrors, processBackendErrors } = useBackendValidation()

    return {
      currency,
      getMember,
      backendErrors,
      processBackendErrors,
      ui,
    }
  },
  data() {
    return {
      success: false,
      submitting: false,
      profile: null,
      userId: null,
      hi5: null,
      hi5loading: false,
      profileLoading: false,
    }
  },
  computed: {
    headerTitle() {
      return !this.success
        ? 'Hi-5 someone awesome!'
        : 'Your Hi-5 has been sent!'
    },
    headerLabel() {
      return 'Show appreciation with a Hi-5 and gift Points to really make their day.'
    },
    backRoute() {
      const name = this.userId ? 'rec-hi5-send-to' : 'rec-hi5'
      const params = this.userId
        ? { ...this.$route.params, sendToId: this.userId }
        : this.$route.params

      return {
        name,
        params,
      }
    },
    loading() {
      return this.hi5loading || this.profileLoading
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.userId = this.$route.query.sendTo
      const hi5Id = this.$route.params.hi5Id
      const nominee = this.$route.params.nominee || this.nominee
      const hi5Param = this.$route.params.hi5

      try {
        this.hi5loading = true

        if (hi5Param) {
          this.hi5 = hi5Param
        }

        this.hi5 = await RecroomHi5.fetchAvailable(hi5Id)
      } catch (error) {
        console.error(error)
      } finally {
        this.hi5loading = false
      }

      if (nominee) {
        this.profile = nominee
      } else if (this.userId) {
        this.profile = this.getMember(this.userId)
      }
    },
    async nominate(formData) {
      let form = {}

      try {
        if (!this.hi5 || !formData.nominee || !formData.reason) return

        this.submitting = true

        form = {
          recipient: formData.nominee?.orgMemberId || formData.nominee?.id,
          message: formData.reason,
          reason: this.hi5?.reason?.id,
        }

        if (formData.selectedPoints) {
          const { walletType, points } = formData.selectedPoints

          form = points
            ? {
                ...form,
                earn_configs: [
                  {
                    wallet_type: walletType,
                    value: points,
                    currency: this.currency,
                  },
                ],
              }
            : form
        }

        await RecroomHi5.create(form)

        this.success = true
        delete this.$route.params.showConfirm
      } catch (error) {
        this.processBackendErrors(error, { data: formData })
      } finally {
        this.submitting = false
      }
    },
    closeNominate() {
      modal.hideAll()
      this.$router.push(this.backRoute)
    },
    back() {
      if (this.$route.params.showConfirm) {
        modal.show('rec-leaving-confirm', {
          on: {
            'stay-here': () => {
              modal.hide()
            },
            'leave-page': () => {
              delete this.$route.params.showConfirm
              modal.hide()
              this.closeModal()
            },
          },
        })
      } else {
        this.closeModal()
      }
    },
    closeModal() {
      if (this.$route.query.sendTo) {
        this.closeNominate()
      } else {
        modal.hide()
      }
    },
  },
}
</script>

<template>
  <div class="mx-auto flex w-full max-w-screen-md flex-col md:py-[30px]">
    <header-mobile
      v-if="!ui.desktop"
      title="Send Hi-5"
      :show-back="!success && !submitting"
      @back="back()"
    />
    <div v-if="ui.desktop && !success" class="px-[30px]">
      <base-back :to="backRoute" class="mb-6" />
    </div>
    <div class="relative px-5 pb-16 md:pb-0">
      <recognition-form
        :submitting="submitting"
        :success="success"
        :loading="loading"
        :nominee-label="'Send to'"
        :nominee="profile"
        :header-title="headerTitle"
        :header-label="headerLabel"
        :gift-label="'Hi-5'"
        type="hi5"
        :user-id="userId"
        :submit-label="'Send Hi-5'"
        :backend-errors="backendErrors"
        @close="closeNominate()"
        @nominate="nominate($event)"
      >
        <template #gift-icon>
          <base-icon
            class="mr-2.5 text-eonx-neutral-800"
            svg="rec/award"
            :size="24"
          />
        </template>
        <template #gift-img>
          <base-avatar
            v-if="hi5 && hi5.image && !loading"
            class="h-full w-full"
            :src="hi5.image"
          />
        </template>
        <template #gift-title>
          {{ hi5 ? hi5.title : '' }}
        </template>
        <template #nominee-label>Send to</template>
      </recognition-form>
    </div>
  </div>
</template>
