<script>
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import WgtBgText from '../wgt/wgt-bg-text.vue'

export default {
  name: 'widget-racq-home-banner',
  components: {
    BaseAction,
    WgtBgText,
  },
  props: {
    fullHeightContentBackground: {
      type: Boolean,
      default: false,
    },
    bannerTitle: {
      type: Object,
      default: () => ({}),
    },
    titleColor: {
      type: String,
      default: '',
    },
    titleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerSubtitle: {
      type: Object,
      default: () => ({}),
    },
    subtitleColor: {
      type: String,
      default: '',
    },
    subtitleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerContent: {
      type: Object,
      default: () => ({}),
    },
    contentColor: {
      type: String,
      default: '',
    },
    contentBackgroundColor: {
      type: String,
      default: '',
    },
    bannerButton: {
      type: Object,
      default: () => ({}),
    },
    rightImage: {
      type: Object,
      default: () => ({}),
    },
    leftImage: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    isMobile() {
      return ui.width < 896
    },
    rightImageStyle() {
      return {
        backgroundPosition: 'center center',
        backgroundImage: `url(${cdn(this.rightImage.desktop).preview('1176x674').format('auto').quality('smart').url()})`,
        backgroundSize: 'cover',
      }
    },
    infoStyle() {
      return {
        backgroundImage: this.leftImage.desktop
          ? `url(${cdn(this.leftImage.desktop).preview('1176x674').format('auto').quality('smart').url()})`
          : this.backgroundColor,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }
    },
    buttonStyle() {
      return {
        backgroundColor: this.buttonColor,
        color: this.buttonTextColor,
      }
    },
  },
}
</script>
<template>
  <section class="rounded-lg shadow">
    <base-action v-bind="bannerButton" origin="banner" appearance="link">
      <template #entry>
        <div class="flex flex-col sm:flex-row sm:items-stretch">
          <div
            class="w-full rounded-tl-lg p-5 pb-12 xs:px-10 xs:pt-10 xs:pb-[120px] sm:w-1/2"
            :class="{
              'rounded-tr-lg': isMobile,
              'rounded-bl-lg': !isMobile,
            }"
            :style="infoStyle"
          >
            <div
              v-if="bannerTitle.text"
              class="mb-2.5 text-3xl font-bold leading-tight xs:text-4xl"
            >
              <wgt-bg-text
                :content="bannerTitle.text"
                :text-color="titleColor"
                :background-color="titleBackgroundColor"
                :full-height-background="fullHeightContentBackground"
              />
            </div>
            <div
              v-if="bannerSubtitle.text"
              class="mb-[15px] text-base leading-tight xs:text-xl sm:mr-32"
            >
              <wgt-bg-text
                :content="bannerSubtitle.text"
                :text-color="subtitleColor"
                :background-color="subtitleBackgroundColor"
                :full-height-background="fullHeightContentBackground"
              />
            </div>
            <div
              v-if="bannerContent.text"
              class="text-base xs:text-xl sm:mr-[120px]"
            >
              <wgt-bg-text
                :content="bannerContent.text"
                :text-color="contentColor"
                :background-color="contentBackgroundColor"
                :full-height-background="fullHeightContentBackground"
              />
            </div>
          </div>
          <div
            class="h-56 w-full rounded-br-lg xs:h-96 sm:h-auto sm:w-1/2"
            :class="{
              'rounded-bl-lg': isMobile,
              'rounded-tr-lg': !isMobile,
            }"
            :style="rightImageStyle"
          />
        </div>
      </template>
    </base-action>
  </section>
</template>
