import { formatNumber } from '/~/utils/format/numeric'

export default function (id, start, end, duration) {
  const element = document.getElementById(id)

  if (element) {
    let current = start
    const range = end - start
    const increment = end > start ? 1 : -1
    const step = Math.abs(Math.floor(duration / range))
    const timer = setInterval(() => {
      current += increment
      element.textContent = formatNumber(current)
      if (current === end) {
        clearInterval(timer)
      }
    }, step)
  }
}
