<script>
import emitter from '/~/core/emitter'
import { formatDollar } from '/~/utils/format/money'
import BaseButton from '/~/components/base/button/base-button'
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerPaymentFrom from '/~/components/drawer/components/rows/drawer-payment-from.vue'
import { useAddresses } from '/~/composables/addresses'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { useEwallet } from '/~/composables/ewallet'
import { useExtensions } from '/~/composables/extensions'
import {
  usePaymentMethods,
  useVerifyCard,
} from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

const getEmptyForm = () => ({
  from: null,
  amount: 0,
  fees: 0,
})

export default {
  name: 'profile-add-cash-payment-methods-v2',
  components: {
    DrawerPaymentFrom,
    BaseButton,
    BaseIcon,
    BaseCurrencyInput,
  },
  setup() {
    const { eWallets, fetchPaymentMethods, calculateFees, ewalletTopUp } =
      usePaymentMethods()
    const { ewalletBalance } = useEwallet()
    const { getManifestByName } = useExtensions()
    const { verifyCard } = useVerifyCard()
    const { firstAddress } = useAddresses()
    const { isDarkThemeForEwallet } = useCms()

    return {
      eWallets,
      fetchPaymentMethods,
      formatDollar,
      getManifestByName,
      calculateFees,
      ewalletTopUp,
      verifyCard,
      firstAddress,
      isDarkThemeForEwallet,
      PaymentMethodType,
      ewalletBalance,
    }
  },
  data() {
    return {
      tab: 'paymentMethods',
      form: getEmptyForm(),

      isProcessing: false,
      feesLoading: false,
      getFeePromise: Promise.resolve(),
      securityToken: null,
      cvv: null,

      response: null,
      backendErrors: {},
    }
  },
  computed: {
    addCashConfig() {
      const module = this.getManifestByName('addcash')

      return module?.config?.topUpSources ?? {}
    },
    limits() {
      return (
        this.addCashConfig?.paymentMethods?.limits ?? {
          min: 1,
          max: 500,
        }
      )
    },
    isCardSelected() {
      return this.form?.from?.type === this.PaymentMethodType.creditCard
    },
    isWrongAmount() {
      const { amount } = this.form
      const { min, max } = this.limits

      return this.tab === 'paymentMethods' && (amount < min || amount > max)
    },
    showBottomText() {
      return !this.isCardSelected || this.isWrongAmount
    },
    localError() {
      const { from, amount } = this.form

      if (
        from &&
        from.type === this.PaymentMethodType.eWallet &&
        from.balance < amount
      ) {
        return 'Insufficient funds'
      }

      return ''
    },
    isSubmitDisabled() {
      const { from, amount } = this.form
      const condition =
        !from ||
        !amount ||
        this.isWrongAmount ||
        Boolean(this.localError) ||
        this.feesLoading

      return condition
    },
    cardBrand() {
      return this.form?.from?.brand
    },
  },
  watch: {
    'form.from'() {
      this.getFeePromise = this.getFeePromise.then(this.getFee)
    },
    'form.amount'(newAmount, oldAmount) {
      this.backendErrors.amount = null

      if (newAmount !== oldAmount) {
        this.getFeePromise = this.getFeePromise.then(this.getFee)
      }
    },
  },
  created() {
    emitter.on('menu:pay-anyone:select-from', this.setFormFrom)
  },
  beforeDestroy() {
    emitter.off('menu:pay-anyone:select-from', this.setFormFrom)
  },
  methods: {
    setFormFrom(from) {
      this.form.from = from
    },
    redirectToNewAddress() {
      this.$router.push({
        hash: '#profile-add-address',
      })
    },
    async onSubmit() {
      if (this.isCardSelected && !this.firstAddress) {
        this.redirectToNewAddress()
        return
      }

      const { from, amount } = this.form

      this.isProcessing = true

      try {
        const { fee = 0 } = await this.calculateFees(amount, from)

        this.form.fees = parseFloat(fee)
      } catch (error) {
        this.isProcessing = false
        console.error(error)
        return
      }

      try {
        if (this.isCardSelected) {
          const { securityToken, cvv } = await this.verifyCard({
            card: from,
            amount: amount + (this.form.fees || 0),
            subTotal: amount,
            address: this.firstAddress,
            modalsConfig: {
              mobile: true,
              to: 'menu-modal',
            },
          })

          this.securityToken = securityToken
          this.cvv = cvv
        }
        this.proceed()
      } catch (error) {
        this.$notify({
          text: error,
          type: 'error',
          duration: 5000,
        })
        this.isProcessing = false
      }
    },
    proceed() {
      const { from, amount } = this.form

      this.ewalletTopUp({
        ewalletId: this.eWallets[0].id,
        cvv: this.cvv,
        securityToken: this.securityToken,
        amount,
        paymentMethod: from,
      })
        .then((response) => {
          this.fetchPaymentMethods()

          // Add small delay before update activity, as item might not be included into response yet
          setTimeout(() => emitter.emit('activity:refresh'), 2000)

          this.form = getEmptyForm()
          this.isProcessing = false

          const details = {
            // Temporary add missing fields for Top Up
            createdAt: new Date(),
            number: response.id,
            ...response,
            amount: response.total,
            from,
            to: this.eWallets[0],
          }

          this.$router.push({
            hash: '#profile-add-cash-confirmation',
            params: {
              details,
              type: 'top-up',
              backRoute: {
                hash: '#profile-home',
              },
            },
          })
        })
        .catch((error) => {
          this.processBackendErrors(error)
          this.isProcessing = false
        })
    },
    processBackendErrors(error) {
      const { validationErrors } = error
      const backendErrors = {}

      if (validationErrors) {
        for (const key in validationErrors) {
          const firstError = validationErrors[key][0]

          backendErrors[key] = firstError || ''
        }
        this.backendErrors = backendErrors
      }
    },
    onSelectClick() {
      if (this.isProcessing) return

      this.$router.push({
        hash: '#profile-add-cash-from',
        params: { source: FlowType.topUpOrder },
      })
    },
  },
}
</script>

<template>
  <div class="flex h-full flex-col">
    <div
      class="mb-2.5 flex items-center justify-between text-sm font-bold leading-4"
      :class="{
        'text-eonx-neutral-600': !isDarkThemeForEwallet,
      }"
    >
      From
    </div>
    <div
      class="mb-6 flex h-[72px] cursor-pointer items-center justify-between rounded border border-zinc-100 p-6 no-underline"
      :class="[isProcessing && 'cursor-default']"
      tabindex="0"
      @click="onSelectClick"
      @keyup.space="onSelectClick"
    >
      <drawer-payment-from v-if="form.from" :from="form.from" class="w-full">
        <template #action>
          <div
            v-show="!isProcessing"
            class="flex h-full w-full items-center justify-center"
          >
            <base-icon
              svg="plain/chevronright"
              class="h-[15px] w-[15px]"
              size="sm"
            />
          </div>
        </template>
      </drawer-payment-from>

      <div
        v-else
        class="flex w-full items-center justify-between no-underline transition-transform duration-300 last:mb-0"
      >
        <div class="mr-[15px] flex h-6 w-6 flex-shrink-0 items-center">
          <base-icon svg="v2/custom/credit-card-outline" :size="24" />
        </div>

        <div
          class="w-full min-w-0 max-w-full truncate py-[5px] font-bold leading-none"
        >
          Select or Add Payment Method
        </div>

        <div class="ml-5 flex flex-shrink-0 items-center justify-center">
          <base-icon svg="v2/custom/chevron-right-outline" :size="14" />
        </div>
      </div>
    </div>
    <form class="flex h-full flex-col justify-end" @submit.prevent>
      <div class="mb-auto w-full">
        <div
          class="mb-2.5 text-sm font-bold leading-4"
          :class="{
            'text-eonx-neutral-600': !isDarkThemeForEwallet,
          }"
        >
          Amount
        </div>
        <div class="rounded-md border border-gray-300 py-2.5 px-4">
          <base-currency-input
            v-model="form.amount"
            :allow-negative="false"
            :disabled="isProcessing"
          />
        </div>
      </div>
      <div class="my-[30px] text-sm leading-5 text-eonx-neutral-600">
        <div>
          The top-up amount cannot be less than
          {{ formatDollar(limits.min) }} and greater than
          {{ formatDollar(limits.max) }}
        </div>
        <div v-if="!isCardSelected" class="mt-5">
          *May take up to 3 business days for funds to be available
        </div>
      </div>
      <base-button
        :disabled="isSubmitDisabled"
        :loading="isProcessing"
        class="w-full disabled:bg-disabled disabled:text-white"
        @click="onSubmit"
      >
        Confirm
      </base-button>
    </form>
  </div>
</template>
