<script>
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { getMenuActiveItem } from '/~/utils/menu'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'
import { useUI } from '/~/composables/ui'

export default {
  name: 'desktop-toolbar-v2',
  components: {
    BaseIcon,
    BaseAction,
  },
  setup() {
    const { isLeftMenuAppearanceSidebar } = useCms()
    const { leftMenu, settingsMenu, uiReady } = useUI()

    return {
      isLeftMenuAppearanceSidebar,
      leftMenu,
      settingsMenu,
      uiReady,
      ui,
    }
  },
  computed: {
    activeTopMenuItem() {
      if (!this.uiReady || !this.leftMenu) return

      return getMenuActiveItem(this.$route, this.leftMenu)
    },
    activeId() {
      return this.activeTopMenuItem?.id ?? null
    },
    isHome() {
      return this.$route.name === 'home'
    },
    isBorder() {
      return !this.isHome && ui.desktop && this.isLeftMenuAppearanceSidebar
    },
  },
  watch: {
    activeId() {
      setTimeout(() => {
        this.$refs.activeItemElement?.[0]?.$el.focus()
      }, 300)
    },
  },
  methods: {
    openMenu() {
      modal.show('side-menu-modal')
    },
  },
}
</script>

<template>
  <div
    class="flex flex-col content-between"
    :class="{
      'border-r': isBorder,
    }"
  >
    <div class="flex grow flex-col space-y-4 pr-4 pt-5">
      <template v-for="{ id, action, icon, label } in leftMenu">
        <base-action
          v-if="uiReady && action && action.enabled"
          :ref="activeId === id ? 'activeItemElement' : null"
          :key="id"
          v-bind="action"
          :label="label"
          color=""
          class="flex h-14 w-20 items-center justify-center rounded-r-lg pl-4 transition-all duration-75"
          :class="{
            'bg-primary text-white': activeId === id,
            'text-default hover:bg-eonx-neutral-50 hover:text-eonx-neutral-600':
              activeId !== id,
          }"
        >
          <base-icon :svg="icon" :size="32" :alt="label" />
        </base-action>
        <div
          v-else
          :key="id"
          class="flex h-14 w-20 items-center justify-center pl-4 text-eonx-neutral-600"
        >
          <base-icon :svg="icon" :size="32" :alt="label" />
        </div>
      </template>
    </div>
    <base-action
      v-if="settingsMenu.length > 0"
      type="none"
      color=""
      look="filled"
      :class="[
        'mb-2.5 flex h-10 w-10 items-center justify-center rounded-lg text-default transition-all hover:bg-divider-light',
        leftMenu.length > 0 ? 'mx-auto' : 'mx-6',
      ]"
      @click="openMenu"
    >
      <base-icon svg="v2/box-icons/bx-cog" :size="32" alt="settings" />
    </base-action>
  </div>
</template>
