<script setup lang="ts">
import { computed } from 'vue'
import { useCms } from '/~/composables/cms'
import { ImageOrString } from '/~/composables/cms/use-cms'

const props = defineProps<{
  title?: string
  text?: string
  image?: ImageOrString
}>()

const { isDarkThemeForEwallet } = useCms()

const src = computed(() => {
  if (typeof props.image === 'string') {
    return props.image
  } else if (props.image) {
    return props.image.desktop
  }

  return null
})
</script>

<template>
  <div
    class="swiper-slide text-center"
    :class="{
      'text-eonx-neutral-800': !isDarkThemeForEwallet,
    }"
  >
    <img
      v-if="src"
      class="inline-block h-[120px]"
      :src="src"
      alt="complete profile"
    />
    <div v-if="title" class="mt-4 font-bold">
      {{ title }}
    </div>
    <div v-if="text" class="mt-4 text-center" v-html="text" />
  </div>
</template>
