<script setup lang="ts">
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { usePayId } from '/~/composables/payment-methods'
import MessagePanel from '/~/templates/bill-payments/components/message-panel.vue'

const { payIdOrdersManager } = usePayId()

payIdOrdersManager.load()

const link = {
  title: 'View',
  enabled: true,
  to: {
    name: 'activity',
    query: { category: 'awaiting_funds' },
  },
}
</script>

<template>
  <div>
    <base-loader v-if="payIdOrdersManager.loader.loading" fullwidth />
    <message-panel
      v-else-if="payIdOrdersManager.orderList.length"
      type="warning"
      icon="plain/clock-v3"
      :link="link"
      dont-close
    >
      <p>You have PayID payment(s) pending action.</p>
    </message-panel>
  </div>
</template>
