<script>
import modal from '/~/core/mdl'
import BaseFab from '/~/components/base/fab/base-fab.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-post-creator-fab',
  components: {
    BaseFab,
    BaseIcon,
  },
  props: {
    source: {
      type: Object,
      default: null,
    },
    actions: {
      type: Array,
      default: () => ['post', 'poll', 'quiz', 'nominate', 'hi'],
    },
  },
  data() {
    return {
      defaultActions: [
        {
          id: 'post',
          title: 'Post',
          icon: 'rec/pen',
          action: () => this.onActionClick(),
        },
        {
          id: 'poll',
          title: 'Poll',
          icon: 'rec/check',
          action: () => this.onActionClick('poll'),
        },
        {
          id: 'quiz',
          title: 'Quiz',
          icon: 'rec/question',
          action: () => this.onActionClick('quiz'),
        },
      ],
    }
  },
  computed: {
    visibleActions() {
      return this.defaultActions.filter((tab) => {
        return !this.actions || this.actions.includes(tab.id)
      })
    },
  },
  methods: {
    onActionClick(type) {
      modal.show('rec-post-creator-mobile', {
        props: {
          source: this.source,
          type,
        },
        on: {
          created: () => this.$emit('created'),
        },
      })
    },
  },
}
</script>

<template>
  <base-fab icon="rec/line-pen" :actions="visibleActions">
    <template #action="{ action }">
      <div class="flex w-40 items-center justify-center">
        <base-icon :svg="action.icon" :size="19" class="block" />
        <b class="ml-2.5">
          {{ action.title }}
        </b>
      </div>
    </template>
  </base-fab>
</template>
