<script>
import ui from '/~/core/ui'
import CheckoutPointsDesktop from './checkout-points.desktop.vue'
import CheckoutPointsMobile from './checkout-points.mobile.vue'

export default {
  name: 'checkout-points',
  functional: true,
  render(h, ctx) {
    let component

    if (ui.mobile) {
      component = CheckoutPointsMobile
    } else {
      component = CheckoutPointsDesktop
    }

    return h(component, ctx.data, ctx.children)
  },
}
</script>
