<script>
import DefaultGiftcardsRetailer from '/~/extensions/giftcards/templates/default/views/giftcards-retailer.vue'
import RetailerProduct from '../components/product/retailer-product.vue'

export default {
  components: {
    RetailerProduct,
  },
  extends: DefaultGiftcardsRetailer,
}
</script>

<template>
  <retailer-empty v-if="empty" class="p-5" />

  <ew-details-layout
    v-else
    :loading="loading"
    :ready="ready"
    :title="`${name} eGift Card`"
    data-cy="giftcard-title"
    class="mb-0 pt-5 md:pt-0"
    :class="{ 'px-5': loading }"
    :back="backRoute"
  >
    <template #gallery>
      <retailer-logo
        :image="image"
        :alt="name"
        class="pt-6 sm:pt-0"
        :default-src="defaultImage"
      />
      <h3 class="mt-6 text-center text-2xl sm:hidden sm:text-base">
        {{ name }}
      </h3>
    </template>

    <template #details>
      <retailer-details
        :offer="offer"
        :retailer="retailer"
        :url="logoUrl"
        :loading="loading"
        :tabs-props="{ look: 'v4' }"
        class="px-5 sm:px-0"
      />
    </template>

    <template #summary>
      <retailer-product
        type="giftcard"
        :offer="offer"
        :retailer="retailer"
        :loading="loading"
        buttons-fixed
      />
    </template>
  </ew-details-layout>
</template>
