<script setup lang="ts">
import ActivityDetailsItem from './components/activity-details-item.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { useActivityDetails } from '/~/composables/activity'

withDefaults(
  defineProps<{
    id?: string | number
  }>(),
  {
    id: '',
  }
)

const { title } = useActivityDetails()
</script>

<template>
  <base-aside-page
    :title="title"
    :back="{ name: 'activity' }"
    no-padding
    shadow
  >
    <div class="flex-1 bg-light pb-5">
      <activity-details-item :id="id" />
    </div>
  </base-aside-page>
</template>
