<script>
import { formatDate } from '/~/utils/format/date'
import { usePointsExpiry } from '/~/composables/points'

export default {
  name: 'points-expiry-warning',
  setup(props, { emit }) {
    const { pointsExpiryDate, pointsExpiryInMonths } = usePointsExpiry()

    function onGotItClick() {
      emit('got-it-clicked')
    }

    return {
      pointsExpiryDate,
      pointsExpiryInMonths,
      formatDate,
      onGotItClick,
    }
  },
}
</script>

<template>
  <div
    class="absolute top-full right-0 mt-16 w-[458px] rounded-lg bg-primary py-6 px-[30px] text-white shadow"
  >
    <div
      class="absolute top-0 right-0 -mt-4 mr-[35px] h-[35px] w-[35px] rotate-45 transform rounded-sm bg-primary"
    />
    <div class="font-bold">Your points will expire soon</div>
    <div>
      Complete a transaction before the
      <b class="whitespace-nowrap">
        {{ formatDate('daymonthyearnumeric', pointsExpiryDate) }}
      </b>
      to ensure you don't lose your points!
    </div>
    <div class="mt-5 flex items-center justify-between font-bold">
      <button
        type="button"
        class="ml-auto rounded bg-yellow-700 py-1.5 px-4 text-sm leading-6 text-white transition-colors"
        @click="onGotItClick"
      >
        Got it
      </button>
    </div>
  </div>
</template>
