<script>
import get from 'lodash-es/get'
import WgtImage from '../../wgt/wgt-image.vue'
import WgtContent from '../../wgt/wgt-content.vue'
import BaseAction from '/~/components/base/action/base-action.vue'

export default {
  name: 'widget-tiles-new',
  components: {
    WgtImage,
    WgtContent,
    BaseAction,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    link(tile) {
      return get(tile, 'link', {})
    },
    useImageAsBackground(tile) {
      return get(tile, 'useImageAsBackground', false)
    },
  },
}
</script>

<template>
  <div class="relative">
    <wgt-image :image="tile.image" />
    <wgt-content
      :content="tile.content"
      :class="[
        useImageAsBackground(tile) && 'absolute bottom-0 left-0 leading-7',
      ]"
    >
      <div v-if="link(tile).enabled" class="mt-[30px]">
        <base-action v-bind="link(tile)" origin="banner" />
      </div>
    </wgt-content>
  </div>
</template>
