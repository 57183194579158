<script>
import debounce from 'lodash-es/debounce'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import EwSorting from '/~/components/ewallet/controls/ew-sorting.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useCompetitions } from '/~/extensions/competitions/composables'
import { useProvider } from '/~/composables/provider'
import { ref, watch } from 'vue'

export default {
  name: 'competitions-filter-desk',
  components: {
    EwFilters,
    EwSorting,
    BaseInput,
  },
  setup() {
    const {
      searchQuery,
      sortValues,
      selectedSorting,
      searchGroup,
      isFiltersSelected,
      resetFilters,
    } = useCompetitions()
    const { isBupaTemplate } = useProvider()

    const filters = ref(null)

    const onSearchInput = debounce(function (value) {
      searchQuery.value = value
    }, 350)

    function onSort(value) {
      selectedSorting.value = value
    }

    watch(selectedSorting, () => {
      if (!isBupaTemplate.value) {
        filters.value?.close?.()
      }
    })

    return {
      filters,
      selectedSorting,
      searchQuery,
      sortValues,
      onSearchInput,
      searchGroup,
      onSort,
      isFiltersSelected,
      resetFilters,
    }
  },
}
</script>

<template>
  <div class="relative flex w-auto space-x-5 sm:flex-nowrap">
    <div class="relative hidden grow sm:block">
      <base-input
        :value="searchQuery"
        :placeholder="`Search ${searchGroup}`"
        :icon-plain="true"
        look="rounded"
        icon="plain/search"
        :icon-size="14"
        clearable
        nolabel
        @input="onSearchInput"
      />
    </div>

    <ew-filters
      ref="filters"
      :is-filters-selected="isFiltersSelected"
      @clear="resetFilters"
    >
      <ew-sorting
        :value="selectedSorting"
        :sorting="sortValues"
        @input="onSort"
      />
    </ew-filters>
  </div>
</template>
