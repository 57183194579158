<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseState from '/~/components/base/state/base-state.vue'
import MenuScroll from '/~/components/ewallet-menu/components/menu-scroll.vue'
import MenuPayToRow from '/~/components/ewallet-menu/components/to/menu-pay-to-row.vue'
import { usePayeeCheckout } from '/~/composables/checkout'
import { useCms } from '/~/composables/cms'
import { usePayees } from '/~/composables/payees'

export default {
  name: 'profile-pay-to',
  components: {
    MenuPayToRow,
    MenuScroll,
    BaseIcon,
    BaseInput,
    BaseButton,
    BaseState,
    BaseAsidePage,
    BaseLoader,
  },
  setup() {
    const router = useRouter()
    const { isDarkThemeForEwallet } = useCms()
    const { payment } = usePayeeCheckout()
    const { payees, fetchPayees } = usePayees()

    const drawerPayeeActionsRef = ref(null)
    const loading = ref(false)
    const searchString = ref('')
    const searchFields = ref(['name', 'email', 'accountName'])

    const filteredPayees = computed(() => {
      const searchText = searchString.value.trim().toLowerCase()
      const enabledPayees = payees.items

      if (!searchText) {
        return enabledPayees
      }

      return enabledPayees.filter((payee) => {
        const searchStrings = []

        // search by multiple fields at a time
        searchFields.value.forEach((fieldName) => {
          if (fieldName in payee) {
            searchStrings.push(payee[fieldName].toLowerCase())
          }
        })

        return searchStrings.some((str) => str.indexOf(searchText) !== -1)
      })
    })

    const payeesGroups = computed(() => {
      const sortedPayees = filteredPayees.value
        .concat([])
        .sort((a, b) => getSortingName(a).localeCompare(getSortingName(b)))
      const groupsList = [
        {
          letter: 'Primary',
          payees: [],
          isPrimaryPayeesGroup: true,
        },
      ]

      sortedPayees.forEach((p) => {
        const groupLetter = getSortingName(p).charAt(0).toUpperCase()
        let group = p.isProviderPayee
          ? groupsList[0]
          : groupsList.find((g) => g.letter === groupLetter)

        if (!group) {
          group = {
            letter: groupLetter,
            payees: [],
          }

          groupsList.push(group)
        }

        group.payees.push(p)
      })

      return groupsList
    })

    const letters = computed(() => {
      return loading.value
        ? []
        : payeesGroups.value
            .filter((pg) => !pg.isPrimaryPayeesGroup)
            .map((pg) => pg.letter)
    })

    const isEmpty = computed(() => {
      return payeesGroups.value.length === 0
    })

    const showAddButton = computed(() => {
      return !loading.value
    })

    const pageAction = computed(() => {
      return showAddButton.value ? openAddPayeeView : null
    })

    function getSortingName(item) {
      return item.name || item.accountName
    }

    function openAddPayeeView() {
      router.push({ hash: '#profile-payee-add' })
    }

    function onPayeeClick(payee) {
      emitter.emit('menu:pay-anyone:select-to', payee)

      payment.reference = ''
      payment.amount = 0
      payment.payee = payee

      router.push({
        hash: '#profile-pay-details',
      })
    }

    function onPayeeActionClicked(payee) {
      // drawerPayeeActionsRef.value.show(payee)
      modal.show('menu-pay-payee-actions', {
        props: {
          payee,
        },
      })
    }

    payment.payee = null

    if (payees.items.length === 0) {
      loading.value = true

      fetchPayees().then(() => {
        loading.value = false
      })
    }

    return {
      isDarkThemeForEwallet,
      drawerPayeeActionsRef,
      payees,
      fetchPayees,
      payment,
      loading,
      searchString,
      searchFields,
      filteredPayees,
      payeesGroups,
      letters,
      isEmpty,
      showAddButton,
      pageAction,
      getSortingName,
      openAddPayeeView,
      onPayeeClick,
      onPayeeActionClicked,
    }
  },
}
</script>

<template>
  <base-aside-page title="Select Payee" no-padding :action="pageAction">
    <div v-if="loading" class="mt-10 flex items-center justify-center">
      <base-loader />
    </div>
    <template v-else>
      <div class="relative flex h-full flex-col">
        <div class="px-5 pb-5">
          <base-input
            v-model="searchString"
            :disabled="loading"
            look="rounded"
            :icon-plain="true"
            nolabel
            placeholder="Search payee name"
          >
            <template #icon>
              <base-icon
                svg="plain/search"
                :size="16"
                class="ml-6 mr-2.5 text-eonx-neutral-600"
              />
            </template>
          </base-input>
        </div>
        <div class="grow overflow-auto">
          <base-state v-if="isEmpty" class="px-5 pt-10">
            <base-icon
              svg="v2/custom/user-circle"
              :size="92"
              class="text-gray-300"
            />
            <div class="mt-5 text-xl font-bold">Payee not available</div>
            <base-button
              class="mt-5 max-w-48"
              :full-width="true"
              @click="openAddPayeeView"
            >
              Add a new Payee
            </base-button>
          </base-state>
          <menu-scroll v-else :letters="letters" id-prefix="sel-letter-">
            <transition-group
              name="fade"
              tag="div"
              class="relative mt-2.5 min-h-full space-y-6 overflow-x-hidden pl-2.5 pr-5"
            >
              <div
                v-for="group in payeesGroups"
                :id="`sel-letter-${group.letter}`"
                :key="group.letter"
              >
                <div
                  class="mb-2.5 px-2.5 font-bold"
                  :class="{
                    'text-eonx-neutral-600':
                      !isDarkThemeForEwallet && !group.isPrimaryPayeesGroup,
                    'text-eonx-neutral-600':
                      !isDarkThemeForEwallet && group.isPrimaryPayeesGroup,
                  }"
                >
                  {{ group.letter }}
                </div>

                <div class="space-y-2.5">
                  <menu-pay-to-row
                    v-for="payee in group.payees"
                    :key="payee.id"
                    :payee="payee"
                    :is-selected="payee === payment.payee"
                    class="cursor-pointer"
                    @click="onPayeeClick(payee)"
                    @action-clicked="onPayeeActionClicked(payee)"
                  />
                </div>
                <div v-if="group.isPrimaryPayeesGroup" class="pt-4 pl-2.5 pr-4">
                  <div class="border-b border-divider" />
                </div>
              </div>
            </transition-group>
          </menu-scroll>
        </div>
      </div>
    </template>
  </base-aside-page>
</template>
