<script>
import { useEditablePage } from '/~/composables/cms'
import CommunityHubCatalogDefault from '/~/extensions/community-hub/templates/default/views/catalog/community-hub-catalog.vue'
import CommunityHubItems from './components/community-hub-items.vue'

export default {
  name: 'community-hub-catalog',
  components: {
    CommunityHubItems,
  },
  extends: CommunityHubCatalogDefault,
  setup() {
    const { page } = useEditablePage()

    return {
      ...CommunityHubCatalogDefault.setup?.(...arguments),
      page,
    }
  },
}
</script>
