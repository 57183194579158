<script setup lang="ts">
import { computed } from 'vue'
import { Order, OrderFeesItem } from '/~/types/api'
import { ActivityItemMetadataStatus } from '/~/types/api/activity-enums'
import ui from '/~/core/ui'
import { formatDate, createDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { pluralize } from '/~/utils/format/string'
import PaymentStatusTracker from '/~/components/activity/details/payment-status-tracker/payment-status-tracker.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { StatusTracker, type Activity } from '/~/composables/activity'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import PaymentPayWithItem from '/~/templates/bill-payments-v1_5/views/payments/make/views/successful/components/payment-pay-with-item.vue'

export type PaymentTransactionDetailsProps = {
  flowType: FlowType
  order: Order
  activity?: Activity
  viewButtonEnabled?: boolean
  viewButtonTitle?: string
  doneButtonEnabled?: boolean
  doneButtonTitle?: string
  printButtonEnabled?: boolean
  printButtonTitle?: string
  status?: ActivityItemMetadataStatus
}

const props = withDefaults(defineProps<PaymentTransactionDetailsProps>(), {
  viewButtonEnabled: true,
  viewButtonTitle: 'Activity',
  doneButtonEnabled: true,
  doneButtonTitle: 'Done',
  printButtonEnabled: true,
  printButtonTitle: 'View Tax Invoice',
})

const emit = defineEmits<{
  (event: 'view'): void
  (event: 'done'): void
  (event: 'print'): void
  (event: 'back'): void
}>()

const payee = computed(() =>
  'payee' in props.order ? props.order.payee : undefined
)
const parsedBsb = computed(() => {
  const { parseBsb } = useLocalization()

  return parseBsb(payee.value?.bsb, { showLabel: true })
})
const metadata = computed(
  () => 'metadata' in props.order && props.order.metadata
)
const status = computed(() => props.order.status.toLowerCase())
const statusTracker = computed(() => {
  if ('statusTracker' in props.order && !isShowScheduled.value) {
    return props.order.statusTracker.map((item) => new StatusTracker(item))
  }

  return []
})

const hasProcessingFee = computed(() =>
  props.order.fees.items.find(
    (item) => parseFloat(item.amount) > 0 && item.label === 'Processing Fee'
  )
)

const payeeCount = computed(() =>
  'transactionsStatusCount' in props.order
    ? props.order.transactionsStatusCount.reduce((acc, item) => {
        return acc + item.count
      }, 0)
    : 0
)

const label = computed(() =>
  status.value === 'scheduled' ? 'Payment scheduled' : 'Payment receipt'
)

const sortedPaymentMethods = computed(() =>
  props.order.paymentMethods.toSorted((a) =>
    a.type === PaymentMethodType.points ? -1 : 1
  )
)

function getFeeLabel(fee: OrderFeesItem) {
  const percentage = parseFloat(fee.percentageFee)

  let label = `${fee.label.toLowerCase()}`

  if (fee.label === 'Program Fee') {
    if (percentage > 0) {
      label += `(${percentage}% inc. GST)`
    } else {
      label += '(inc. GST)'
    }
  } else if (fee.label === 'Processing Fee') {
    label += '*'

    if (percentage > 0) {
      label += `(${percentage}%)`
    }
  }

  return label
}

const referenceBankAccount = computed(() =>
  'payee' in props.order &&
  props.order.payee.type === 'bankAccount' &&
  props.order.reference
    ? props.order.reference
    : null
)

const referenceBPay = computed(() =>
  'payee' in props.order &&
  props.order.payee.type === 'bpay' &&
  props.order.payee.reference
    ? props.order.payee.reference
    : null
)

const isShowScheduled = computed(
  () => props.order.scheduledAt && status.value === 'scheduled'
)

const paymentDescription = computed(() => {
  if (isShowScheduled.value) return

  switch (props.status) {
    case 'completed':
    case 'completed_with_remittance_failures':
      return 'Payment successful'
    case 'pending':
    case 'remittance_pending':
      return 'Payment pending'
    case 'cancelled':
      return 'Payment cancelled'
    default:
      return 'Payment failed'
  }
})
const statusLabel = computed(() => {
  if (isShowScheduled.value) return

  switch (props.status) {
    case 'completed':
    case 'completed_with_remittance_failures':
      return 'Completed'
    case 'pending':
      return 'Processing'
    case 'remittance_pending':
      return 'Pending'
    case 'cancelled':
      return 'Cancelled'
    default:
      return 'Failed'
  }
})
const statusColor = computed(() => {
  if (isShowScheduled.value) return

  switch (props.status) {
    case 'completed':
    case 'completed_with_remittance_failures':
      return 'text-success-700'
    case 'pending':
    case 'remittance_pending':
      return 'text-warning-700'
    default:
      return 'text-error-700'
  }
})

const icon = computed(() => {
  if (props.activity) {
    return null
  }

  switch (props.status) {
    case 'completed':
    case 'completed_with_remittance_failures':
      return 'heroicons/solid/check-circle'
    case 'pending':
    case 'remittance_pending':
      return 'heroicons/solid/clock'
    default:
      return null
  }
})

const showFees = computed(() => {
  return props.order.fees && props.order.fees.total > 0
})
</script>

<template>
  <div class="flex flex-col">
    <div v-if="activity && !ui.mobile" class="mt-8">
      <base-button look="link" @click="emit('back')">
        <base-icon svg="chevron-left" size="xl" class="-ml-1.5" />
        <span class="text-base">Back</span>
      </base-button>
    </div>

    <div
      class="hidden justify-end sm:flex"
      :class="{
        'mt-5 mb-8': activity,
        'mb-8': !activity,
      }"
    >
      <base-button
        v-if="activity && printButtonEnabled"
        size="sm"
        @click="emit('print')"
      >
        {{ printButtonTitle }}
      </base-button>
    </div>

    <div
      class="flex flex-grow flex-col border-eonx-neutral-400 bg-white pt-8 sm:rounded-lg sm:border"
    >
      <div>
        <div class="mb-3 text-center text-2xl font-bold">
          <template v-if="isShowScheduled">
            Scheduled for:
            {{ formatDate('daymonthyearnumeric', order.scheduledAt) }}
          </template>
          <template v-else>
            <div class="flex flex-col items-center gap-y-4">
              <base-icon
                v-if="icon"
                :svg="icon"
                :class="statusColor"
                :size="56"
              />
              <div v-if="ui.desktop">
                {{ paymentDescription }}
              </div>
            </div>
          </template>
        </div>
        <div class="mt-1 text-center text-sm font-bold text-eonx-neutral-800">
          Transaction ID #{{ order.number }}
        </div>
        <div
          v-if="statusLabel"
          class="mt-1 text-center text-sm font-bold text-eonx-neutral-800"
        >
          Status:
          <span
            class="inline-block first-letter:uppercase"
            :class="statusColor"
          >
            {{ statusLabel }}
          </span>
        </div>
        <div
          v-if="activity?.metadata.performedBy"
          class="mt-1 text-center text-sm font-bold text-eonx-neutral-800"
        >
          Performed by: {{ activity?.metadata.performedBy?.fullName }}
        </div>
        <payment-status-tracker
          v-if="statusTracker.length"
          class="mt-6 mb-8"
          :items="statusTracker"
        />
        <slot name="points-panel" />
      </div>

      <div class="h-full px-6 pb-6">
        <div
          class="space-y-2 border-b border-eonx-neutral-400 py-5 font-normal"
        >
          <div class="font-bold text-eonx-neutral-800">Paying to</div>
          <div
            v-if="'fileName' in order"
            class="space-y-2 text-eonx-neutral-600"
          >
            <div class="flex flex-wrap">
              <div class="truncate">{{ order.fileName }}&ensp;</div>
              <div>({{ payeeCount }} {{ pluralize(payeeCount, 'Payee') }})</div>
            </div>
            <div v-if="order.reference">Description: {{ order.reference }}</div>
          </div>

          <div v-if="payee" class="space-y-2 text-eonx-neutral-600">
            <div v-if="payee.name">
              {{ payee.name }}
            </div>
            <div v-for="(item, key) in parsedBsb" :key="key">{{ item }}</div>
            <div v-if="payee.accountNumber">
              Account No: {{ payee.accountNumber }}
            </div>
            <div v-if="payee.billerCode">
              Biller Code: {{ payee.billerCode }}
            </div>
            <div v-if="referenceBPay">Reference: {{ referenceBPay }}</div>

            <template v-if="'userStatementAccount' in order">
              <div>Account Name: {{ order.userStatementAccount.name }}</div>
              <div>Account No: {{ order.userStatementAccount.number }}</div>
              <div>
                Description: {{ payee.name }}
                {{ createDate(order.processingDueDate).format('MMMM') }}
              </div>
            </template>
          </div>
          <div
            v-if="metadata && metadata.payloadStatementDescriptor"
            class="text-eonx-neutral-600"
          >
            Description: {{ metadata.payloadStatementDescriptor }}
          </div>
          <div v-if="referenceBankAccount" class="text-eonx-neutral-600">
            Reference: {{ referenceBankAccount }}
          </div>
          <div class="flex justify-between font-bold">
            <div>Amount</div>
            <div>{{ formatDollar(order.subtotal) }}</div>
          </div>
        </div>

        <div
          v-if="showFees"
          class="space-y-2 border-b border-eonx-neutral-400 py-5 font-normal"
        >
          <div class="font-bold text-eonx-neutral-800">Fees</div>
          <div
            v-for="fee in order.fees.items"
            :key="fee.label"
            class="flex justify-between"
          >
            <div class="first-letter:uppercase">
              {{ getFeeLabel(fee) }}
            </div>
            <div class="text-sm">{{ formatDollar(fee.amount) }}</div>
          </div>
          <div class="flex items-center justify-between font-bold">
            <div>Total fees</div>
            <div>{{ formatDollar(order.fees.total) }}</div>
          </div>
          <div v-if="hasProcessingFee" class="text-sm text-eonx-neutral-600">
            *Processing fee is charged on amount plus program fee.
          </div>
        </div>

        <div
          class="flex items-center justify-between border-b border-eonx-neutral-400 py-5 font-bold text-eonx-neutral-800"
        >
          <div>Total</div>
          <div class="text-lg">{{ formatDollar(order.total) }}</div>
        </div>

        <div class="space-y-2 pt-5 font-normal">
          <div class="font-bold text-eonx-neutral-800">Paying from</div>
          <payment-pay-with-item
            v-for="paymentMethod in sortedPaymentMethods"
            :key="paymentMethod.id"
            :payment-method="paymentMethod"
          />
        </div>
      </div>

      <div
        v-if="viewButtonEnabled || doneButtonEnabled"
        class="border-t border-eonx-neutral-400 py-5 px-5"
      >
        <div
          class="grid grid-cols-1 gap-2"
          :class="{
            'xs:grid-cols-2': viewButtonEnabled && doneButtonEnabled,
          }"
        >
          <base-button
            v-if="viewButtonEnabled"
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: label,
              cta: viewButtonTitle,
            }"
            data-vitest="view-button"
            look="outlined-color"
            @click="emit('view')"
          >
            {{ viewButtonTitle }}
          </base-button>

          <base-button
            v-if="doneButtonEnabled"
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: label,
              cta: doneButtonTitle,
            }"
            @click="emit('done')"
          >
            {{ doneButtonTitle }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
