<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import ui from '/~/core/ui'
import { getMenuActiveItem } from '/~/utils/menu'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { MenuItem } from '/~/composables/cms/use-cms'

const props = withDefaults(
  defineProps<{
    items?: MenuItem[]
  }>(),
  {
    items: () => [],
  }
)

const route = useRoute()

const activeMenuItem = computed(() => getMenuActiveItem(route, props.items))
const activeId = computed(() => activeMenuItem.value?.id ?? null)
const hideLabels = computed(() => ui.tablet2024) // TODO revert once 1.5 is ready and merged
</script>

<template>
  <nav role="navigation" aria-label="Benefits" data-testid="desktop-navigation">
    <template v-for="item in items">
      <div v-if="item.divider" :key="item.id" class="my-2 border-t" />
      <base-action
        v-else
        :key="item.id"
        v-bind="item.action"
        :current="activeId === item.id"
        color=""
        class="relative flex h-12 w-full items-center rounded-lg px-4 transition-[transform,color] duration-150 hover:translate-x-2.5 hover:text-eonx-neutral-800 focus:z-1"
        :class="{
          'bg-primary-lightest text-primary': activeId === item.id,
          'text-eonx-neutral-800': activeId !== item.id,
          'justify-center': hideLabels,
        }"
        :title="item.label"
      >
        <base-icon
          v-if="item.icon"
          class="shrink-0"
          :class="{
            'mr-2': !hideLabels,
          }"
          :size="24"
          color=""
          :svg="item.icon"
        />
        <b
          class="truncate"
          :class="{
            hidden: hideLabels,
          }"
        >
          {{ item.label }}
        </b>
      </base-action>
    </template>
  </nav>
</template>
