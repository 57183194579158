<script>
import { ref, computed, watch } from 'vue'
import { formatAmount } from '/~/utils/format/money'
import { floorFigure, roundFigure } from '/~/utils/format/numeric'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import PaymentsSplitFormBankAccount from './payments-split-form-bank-account.vue'
import PaymentsSplitFormCard from './payments-split-form-card.vue'
import PaymentsSplitFormEwallet from './payments-split-form-ewallet.vue'
import PaymentsSplitFormPoints from './payments-split-form-points.vue'

export default {
  name: 'payments-split-form',
  components: {
    BaseButton,
    BaseIcon,
    PaymentsSplitFormPoints,
    PaymentsSplitFormCard,
    PaymentsSplitFormBankAccount,
    PaymentsSplitFormEwallet,
  },
  props: {
    currentItem: {
      type: Object,
      required: true,
    },
    selectedPoints: {
      type: Object,
      default: null,
    },
    burnPointsRate: {
      type: Number,
      default: 1,
    },
    selectedEwallet: {
      type: Object,
      default: null,
    },
    selectedCard: {
      type: Object,
      default: null,
    },
    selectedBankAccount: {
      type: Object,
      default: null,
    },
    selectedAmount: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  emits: ['confirm', 'discard'],
  setup(props, { emit }) {
    const isPoints = ref(props.currentItem.type === PaymentMethodType.points)
    const pointsToUse = ref(props.selectedPoints?.usePoints ?? 0)
    const pointsAmount = computed(() =>
      floorFigure(pointsToUse.value * props.burnPointsRate)
    )
    const isShowPoints = ref(pointsToUse.value > 0 || isPoints.value)

    const isEwallet = ref(props.currentItem.type === PaymentMethodType.eWallet)
    const ewalletAmount = ref(props.selectedEwallet?.calculatedAmount ?? 0)
    const isShowEwallet = ref(ewalletAmount.value > 0 || isEwallet.value)

    const isCard = ref(props.currentItem.type === PaymentMethodType.creditCard)
    const isNewCard = ref(
      isCard.value &&
        (!props.selectedCard || props.selectedCard.id !== props.currentItem.id)
    )
    const card = ref(isNewCard.value ? props.currentItem : props.selectedCard)
    const cardAmount = ref(card.value?.calculatedAmount ?? 0)
    const isShowCard = ref(cardAmount.value > 0 || isCard.value)

    const isBankAccount = ref(
      props.currentItem.type === PaymentMethodType.bankAccount
    )
    const isNewBankAccount = ref(
      isBankAccount.value &&
        (!props.selectedBankAccount ||
          props.selectedBankAccount.id !== props.currentItem.id)
    )
    const bankAccount = ref(
      isNewBankAccount.value ? props.currentItem : props.selectedBankAccount
    )
    const bankAccountAmount = ref(bankAccount.value?.calculatedAmount ?? 0)
    const isShowBankAccount = ref(
      bankAccountAmount.value > 0 || isBankAccount.value
    )

    const remainingAmount = computed(() => props.total - props.selectedAmount)
    const fulfilledAmount = computed(() =>
      Math.min(
        props.total,
        pointsAmount.value +
          ewalletAmount.value +
          cardAmount.value +
          bankAccountAmount.value
      )
    )
    const amountToPay = computed(() =>
      roundFigure(props.total - fulfilledAmount.value)
    )

    const canConfirm = computed(() => {
      return (
        amountToPay.value === 0 ||
        (isPoints.value && !isShowCard.value && !isShowBankAccount.value) ||
        (isEwallet.value && !isShowCard.value && !isShowBankAccount.value)
      )
    })

    const isShowPointsOnly = computed(
      () =>
        isShowPoints.value &&
        !isShowEwallet.value &&
        !isShowCard.value &&
        !isShowBankAccount.value
    )

    function onDiscard() {
      emit('discard')
    }

    function onConfirm() {
      emit('confirm', {
        pointsToUse: pointsToUse.value,
        pointsAmount: pointsAmount.value,
        ewalletAmount: ewalletAmount.value,
        cardAmount: cardAmount.value,
        bankAccountAmount: bankAccountAmount.value,
      })
    }

    function onRemainingClick() {
      if (isShowBankAccount.value && bankAccountAmount.value === 0) {
        bankAccountAmount.value += amountToPay.value
      } else if (isShowCard.value && cardAmount.value === 0) {
        cardAmount.value += amountToPay.value
      } else if (isShowBankAccount.value) {
        bankAccountAmount.value += amountToPay.value
      } else if (isShowCard.value) {
        cardAmount.value += amountToPay.value
      }
    }

    function onInputFocus(type) {
      if (isShowCard.value && isShowBankAccount.value) {
        if (amountToPay.value > 0) {
          if (
            type === PaymentMethodType.creditCard &&
            cardAmount.value === 0 &&
            bankAccountAmount.value > 0
          ) {
            cardAmount.value += amountToPay.value
          } else if (
            type === PaymentMethodType.bankAccount &&
            bankAccountAmount.value === 0 &&
            cardAmount.value > 0
          ) {
            bankAccountAmount.value += amountToPay.value
          }
        } else {
          cardAmount.value = 0
          bankAccountAmount.value = 0
        }
      } else if (isShowEwallet.value) {
        if (amountToPay.value > 0) {
          if (type === PaymentMethodType.creditCard) {
            cardAmount.value += amountToPay.value
          } else if (type === PaymentMethodType.bankAccount) {
            bankAccountAmount.value += amountToPay.value
          }
        } else {
          cardAmount.value = 0
          bankAccountAmount.value = 0
        }
      }
    }

    watch(pointsAmount, () => {
      ewalletAmount.value = 0
      cardAmount.value = 0
      bankAccountAmount.value = 0
    })

    watch(ewalletAmount, () => {
      cardAmount.value = 0
      bankAccountAmount.value = 0
    })

    if (!isPoints.value && !isEwallet.value) {
      if (
        (isCard.value && props.selectedBankAccount) ||
        (isBankAccount.value && props.selectedCard)
      ) {
        cardAmount.value = 0
        bankAccountAmount.value = 0
      } else if (isCard.value) {
        cardAmount.value += amountToPay.value
      } else if (isBankAccount.value) {
        bankAccountAmount.value += amountToPay.value
      }
    }

    return {
      pointsToUse,
      pointsAmount,
      isPoints,
      isShowPoints,
      isShowPointsOnly,
      isShowEwallet,
      ewalletAmount,
      card,
      cardAmount,
      isShowCard,
      bankAccount,
      bankAccountAmount,
      isShowBankAccount,
      amountToPay,
      remainingAmount,
      formatAmount,
      canConfirm,
      onDiscard,
      onConfirm,
      onRemainingClick,
      onInputFocus,
      PaymentMethodType,
    }
  },
}
</script>

<template>
  <div class="mt-5 flex w-full flex-col items-center justify-center md:mt-0">
    <div class="flex flex-col items-center">
      <base-icon svg="symbion/fork" :size="57" class="text-primary" />
      <span class="mt-6 text-2xl font-bold">Payment information</span>
    </div>

    <span class="mt-[30px] text-eonx-neutral-600">
      <span v-if="isShowPointsOnly">
        Allocate the amount of points you would like to use for this transaction
      </span>
      <span v-else>Enter the amount for each payment method</span>
    </span>

    <payments-split-form-points
      v-if="isShowPoints"
      v-model="pointsToUse"
      :burn-points-rate="burnPointsRate"
      :amount-to-pay="amountToPay"
      :total="total"
    />

    <payments-split-form-ewallet
      v-if="isShowEwallet"
      v-model="ewalletAmount"
      :amount-to-pay="amountToPay"
    />

    <payments-split-form-card
      v-if="isShowCard"
      v-model="cardAmount"
      :card="card"
      :amount-to-pay="amountToPay"
      @focus="onInputFocus(PaymentMethodType.creditCard)"
    />

    <payments-split-form-bank-account
      v-if="isShowBankAccount"
      v-model="bankAccountAmount"
      :bank-account="bankAccount"
      :amount-to-pay="amountToPay"
      @focus="onInputFocus(PaymentMethodType.bankAccount)"
    />

    <span class="mt-6 font-bold text-eonx-neutral-600">Remaining to pay</span>

    <div
      class="mt-2.5 flex items-start font-bold"
      :class="{
        'cursor-pointer': amountToPay > 0 && (isShowBankAccount || isShowCard),
      }"
      @click="onRemainingClick"
    >
      <span class="mr-[5px] text-2xl leading-tight">$</span>
      <span class="text-5xl leading-10">
        {{ formatAmount(amountToPay) }}
      </span>
    </div>

    <div class="mt-10 grid w-full grid-cols-2 gap-[15px]">
      <base-button
        class="md:w-40"
        look="outlined-color"
        full-width
        @click="onDiscard"
      >
        <span class="text-sm">Cancel</span>
      </base-button>

      <base-button
        class="md:w-40"
        full-width
        :disabled="!canConfirm"
        @click="onConfirm"
      >
        <span class="text-sm">Confirm</span>
      </base-button>
    </div>
  </div>
</template>
