import { ValidationObserver } from 'vee-validate'
import { ref, onMounted } from 'vue'

export function useForm() {
  const validationObserverRef = ref<InstanceType<typeof ValidationObserver> | null>(null)

  onMounted(() => {
    validationObserverRef.value?.validate({ silent: true })
  })

  return {
    validationObserverRef,
  }
}
