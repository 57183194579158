export default {
  extensions: {
    'online-offers': {
      templateName: 'bupa',
    },
    travel: {
      templateName: 'bupa',
    },
    'community-hub': {
      templateName: 'bupa',
    },
    'gift-cards-cinema': {
      templateName: 'bupa',
    },
  },
}
