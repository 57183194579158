<script>
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'widget-tiles-epworth',
  components: {
    BaseAction,
    BaseIcon,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.tile.buttonColor,
        color: this.tile.buttonTextColor,
      }
    },
  },
}
</script>

<template>
  <div class="relative text-black">
    <img
      :src="
        cdn(tile.image.desktop)
          .preview('752x472')
          .format('auto')
          .quality('smart')
          .url()
      "
      :alt="tile.image.alt"
      class="block h-full w-full object-cover"
    />
    <div
      class="border-t py-[30px]"
      :class="{
        'text-left': tile.content.contentAlignmentX === 'left',
        'text-right': tile.content.contentAlignmentX === 'right',
        'text-center': tile.content.contentAlignmentX === 'center',
        'px-5 md:px-[30px]': tile.content.padding === 'sm',
        'px-[30px] md:px-14 md:pb-[30px]': tile.content.padding === 'md',
        'px-10 md:px-20 md:pb-10': tile.content.padding === 'lg',
      }"
    >
      <h2
        v-if="tile.content.title"
        class="leading-tight"
        v-html="tile.content.title"
      />
      <p
        v-if="tile.content.text"
        class="mt-5 font-bold text-eonx-neutral-600"
        v-html="tile.content.text"
      />
      <base-action
        v-if="tile.tileLink && tile.tileLink.enabled"
        v-bind="tile.tileLink"
        class="mx-2.5 mt-[30px] inline-block"
        origin="banner"
        appearance="link"
      >
        <button
          type="button"
          class="button flex h-12 items-center rounded px-5 font-bold"
          :style="buttonStyle"
        >
          <span>{{ tile.tileLink.label }}</span>
          <base-icon class="ml-5" svg="plain/chevron-right" size="sm" />
        </button>
      </base-action>
    </div>
  </div>
</template>
