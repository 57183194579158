<script>
import { nextTick } from 'vue'
export default {
  name: 'rec-audio-waveform',
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
    samples: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      default: 0,
    },
    chat: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'text-primary',
    },
  },
  data() {
    return {
      drag: false,
      waveform: new Array(this.samples).fill(1),
    }
  },
  computed: {
    progressPercent() {
      return (this.progress / this.duration) * 100
    },
    backgroundColor() {
      return 'bg-' + this.color.split('-')[1]
    },
  },
  mounted() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext
    const audioContext = new AudioContext()

    fetch(this.attachment.cdn)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
      .then((audioBuffer) => {
        this.waveform = this.filterData(audioBuffer)
      })

    window.addEventListener('mouseup', this.onMouseUp)
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.onMouseUp)
  },
  methods: {
    filterData(audioBuffer) {
      const rawData = audioBuffer.getChannelData(0)
      const blockSize = Math.floor(rawData.length / this.samples)
      const filteredData = []

      for (let i = 0; i < this.samples; i++) {
        const blockStart = blockSize * i
        let sum = 0

        for (let j = 0; j < blockSize; j++) {
          sum = sum + Math.abs(rawData[blockStart + j])
        }
        filteredData.push(sum / blockSize)
      }

      const max = Math.max(...filteredData)

      for (let i = 0; i < this.samples; i++) {
        filteredData[i] = (filteredData[i] / max) * 100
      }

      return filteredData
    },
    onMouseMove(e) {
      if (!this.drag) return

      const rect = e.target.getBoundingClientRect()
      const x = e.clientX - rect.left

      this.$emit('set-progress', (x / rect.width) * 100)
    },
    onMouseDown(e) {
      this.drag = true
      nextTick(() => {
        this.onMouseMove(e)
      })
    },
    onMouseUp() {
      this.drag = false
    },
  },
}
</script>

<template>
  <div
    class="mt-[5px] flex h-5 w-full cursor-pointer select-none items-end"
    @mousedown="onMouseDown"
    @mousemove="onMouseMove"
  >
    <span
      v-for="(cell, i) in waveform"
      :key="i"
      class="flex-1 rounded"
      :class="{
        [backgroundColor]: true,
        'opacity-50': progressPercent <= (i / samples) * 100,
      }"
      :style="{
        height: cell + '%',
        transition: '0.2s',
        margin: '0 1px',
        'pointer-events': 'none',
      }"
    />
  </div>
</template>
