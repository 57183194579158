<script>
import Root from '/~/extensions/bank-file-upload/root.vue'

export default {
  name: 'payroll-root',
  extends: Root,
  props: {
    batchOrderFlowType: {
      type: String,
      default: 'individual',
    },
  },
}
</script>
