<script>
import DrawerPrivacyPolicy from '/~/components/drawer/views/settings/drawer-privacy-policy.vue'
import { useUserEvents } from '/~/composables/user'

export default {
  name: 'profile-privacy-policy',
  components: {
    DrawerPrivacyPolicy,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { privacyPolicyVisited } = useUserEvents()

    privacyPolicyVisited({ type: 'page', name: 'profile privacy policy' })
  },
}
</script>

<template>
  <drawer-privacy-policy :id="id" />
</template>
