<script setup lang="ts">
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PaymentMethodsList from '/~/components/payments/payments-methods-list.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useQuickBuyPaymentMethodsComponent } from '/~/views/quick-buy/views/payment-methods/payment-methods.component'

const {
  isShowAddButton,
  addButtonDisabled,
  addPaymentMethod,
  loading,
  backRoute,
} = useQuickBuyPaymentMethodsComponent()
</script>

<template>
  <div>
    <base-back :to="backRoute" />

    <div class="my-5 flex items-center justify-between">
      <h2 class="text-xl">Payment Methods</h2>
      <base-button
        v-if="isShowAddButton"
        look="link"
        :disabled="addButtonDisabled"
        @click="addPaymentMethod"
      >
        + Add Payment Method
      </base-button>
    </div>
    <base-loader v-if="loading" class="mt-[30px] w-full" />

    <payment-methods-list
      v-else
      :flow-type="FlowType.quickBuy"
      class="mt-[5px] space-y-6"
    />
  </div>
</template>
