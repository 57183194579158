<script>
export default {
  name: 'widget-text',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <div
    class="text-center text-2xl font-normal leading-tight sm:text-3xl"
    data-cy="widget-text"
    v-html="content.text"
  />
</template>
