<script>
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'rec-post-group',
  components: {
    RecognitionHeader,
    BaseLink,
    BaseButton,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div>
    <recognition-header :image="post.group.image" />

    <div class="mx-6 mb-2.5 xs:mx-20 md:mx-10 lg:mx-20">
      <h2 class="break-words text-center leading-tight">
        {{ post.group.name }}
      </h2>

      <div class="mt-2.5 text-center text-sm font-bold text-eonx-neutral-600">
        You have been invited by
        <span class="text-default">
          {{ post.author.fullName }}
        </span>
        to this group
      </div>

      <p class="mt-[30px] break-words text-sm leading-4">
        {{ post.group.description }}
      </p>

      <!-- TODO: render group members' avatars -->

      <div class="mt-[30px] text-center">
        <base-link
          v-if="post.group.id"
          :to="{
            name: 'rec-group',
            params: {
              groupId: post.group.id,
            },
          }"
        >
          <base-button class="w-64">View Group</base-button>
        </base-link>
      </div>
    </div>
  </div>
</template>
