<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import Hi5SendTo from '/~rec/views/recognition/hi5/hi5-send-to.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'

export default {
  name: 'rec-hi5-send-to-mobile',
  components: {
    BaseMdl,
    Hi5SendTo,
    HeaderMobile,
  },
  props: {
    profile: {
      type: Object,
      default: null,
    },
    sendToId: {
      type: String,
      default: null,
    },
  },
  methods: {
    close() {
      modal.hide()
      this.$router.push({ name: 'rec-hi5' })
    },
  },
}
</script>

<template>
  <base-mdl transition="slide-bottom" fullscreen width="screen" :layout="false">
    <header-mobile title="Hi-5s" @back="close" />
    <hi5-send-to class="p-5 pt-0" :user="profile" :send-to-id="sendToId" />
  </base-mdl>
</template>
