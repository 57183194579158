const MEDIA_CLASSES = {
  base: {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
  },
  xxs: {
    1: 'xxs:grid-cols-1',
    2: 'xxs:grid-cols-2',
    3: 'xxs:grid-cols-3',
    4: 'xxs:grid-cols-4',
    5: 'xxs:grid-cols-5',
    6: 'xxs:grid-cols-6',
  },
  xs: {
    1: 'xs:grid-cols-1',
    2: 'xs:grid-cols-2',
    3: 'xs:grid-cols-3',
    4: 'xs:grid-cols-4',
    5: 'xs:grid-cols-5',
    6: 'xs:grid-cols-6',
  },
  sm: {
    1: 'sm:grid-cols-1',
    2: 'sm:grid-cols-2',
    3: 'sm:grid-cols-3',
    4: 'sm:grid-cols-4',
    5: 'sm:grid-cols-5',
    6: 'sm:grid-cols-6',
  },
  md: {
    1: 'md:grid-cols-1',
    2: 'md:grid-cols-2',
    3: 'md:grid-cols-3',
    4: 'md:grid-cols-4',
    5: 'md:grid-cols-5',
    6: 'md:grid-cols-6',
  },
  lg: {
    1: 'lg:grid-cols-1',
    2: 'lg:grid-cols-2',
    3: 'lg:grid-cols-3',
    4: 'lg:grid-cols-4',
    5: 'lg:grid-cols-5',
    6: 'lg:grid-cols-6',
  },
  xl: {
    1: 'xl:grid-cols-1',
    2: 'xl:grid-cols-2',
    3: 'xl:grid-cols-3',
    4: 'xl:grid-cols-4',
    5: 'xl:grid-cols-5',
    6: 'xl:grid-cols-6',
  },
}

type ScreenSize = keyof typeof MEDIA_CLASSES
type ItemsCount = keyof (typeof MEDIA_CLASSES)[ScreenSize]

export type Columns = { [C in ScreenSize]?: ItemsCount }

export function getTailwindWidthByColumns(columns: ItemsCount | Columns) {
  if (typeof columns === 'number') {
    return MEDIA_CLASSES.base[columns]
  }

  if (columns && typeof columns === 'object') {
    const classes = []
    const keys = Object.keys(columns) as ScreenSize[]

    for (const key of keys) {
      classes.push(MEDIA_CLASSES[key][columns[key] ?? 1])
    }

    if (!Object.prototype.hasOwnProperty.call(columns, 'base')) {
      classes.push(MEDIA_CLASSES.base[1])
    }

    return classes.join(' ')
  }

  return ''
}
