<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'drawer-icon-header-v2',
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    action: {
      type: Function,
      default: null,
    },
  },
}
</script>

<template>
  <div>
    <div
      class="relative mx-auto my-2.5 flex h-64 max-h-full w-64 max-w-full items-center justify-center px-2.5 pt-2.5 text-primary"
    >
      <base-icon class="h-full w-full" size="auto" svg="v3/empty" />

      <div
        v-if="icon || $slots.icon"
        class="absolute flex h-[120px] w-[120px] items-center justify-center p-1.5"
      >
        <slot name="icon">
          <base-icon size="auto" :svg="icon" />
        </slot>
      </div>
    </div>

    <div class="py-2.5 text-center font-inter text-2xl font-bold">
      <slot v-if="$slots.title" name="title" />
      <template v-else>
        {{ title }}
      </template>
    </div>
  </div>
</template>
