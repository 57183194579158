<script>
import cloneDeep from 'lodash-es/cloneDeep'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { useForm } from '/~/composables/base/use-form'

export default {
  name: 'rec-post-quiz-qa-modal',
  components: {
    HeaderMobile,
    BaseButton,
    BaseMdl,
    BaseTextarea,
    BaseRadio,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    qa: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { validationObserverRef } = useForm()

    return {
      ui,
      validationObserverRef,
    }
  },
  data() {
    const form = this.qa
      ? cloneDeep(this.qa)
      : {
          content: '',
          options: [
            { content: '', correct: false },
            { content: '', correct: false },
          ],
        }

    return {
      form,
    }
  },
  computed: {
    title() {
      return this.isEdit ? 'Edit Q&A' : 'Add Q&A'
    },
  },
  created() {
    extend('correctOptionSelected', {
      message: () => 'Choose the correct answer',
      validate: () => {
        return this.form.options.some((o) => o.correct)
      },
    })
  },
  methods: {
    close() {
      this.$emit('back')
      modal.hide()
    },
    onAddClick() {
      this.form.options.push({ content: '', correct: false })
    },
    async onSaveClick() {
      this.$emit(this.qa ? 'update' : 'add', this.form)
      this.close()
    },
    onOptionSelect(idx) {
      this.form.options.forEach((option, index) => {
        option.correct = index === idx
      })
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ errors, handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <base-mdl
      :title="title"
      fullscreen="mobile"
      :width="ui.desktop ? 'sm' : 'screen'"
      transition="slide-right"
      class="h-full"
    >
      <template #header>
        <header-mobile v-if="ui.mobile" :title="title" @back="close" />
      </template>
      <div class="flex h-full flex-col">
        <div>
          <base-textarea
            v-model="form.content"
            :validation="{
              rules: 'required',
              name: 'Question',
            }"
            :maxlength="200"
            label="Question"
            required
            name="content"
            look="simple"
          />
        </div>

        <div class="mt-11 mb-2.5 flex justify-between">
          <b>Add Answers then select the right one</b>
          <a
            v-if="form.options.length < 4"
            class="font-primary cursor-pointer font-bold"
            @click="onAddClick"
          >
            + Add
          </a>
        </div>

        <div
          v-for="(option, idx) in form.options"
          :key="idx"
          class="flex flex-row items-center"
        >
          <validation-provider rules="correctOptionSelected" vid="answer" slim>
            <base-radio
              v-model="option.correct"
              :checked="option.correct"
              name="answer"
              @input="onOptionSelect(idx)"
            />
          </validation-provider>
          <base-textarea
            v-model="option.content"
            :validation="{
              rules: 'required',
              name: 'Answer',
              vid: `answer${idx + 1}`,
            }"
            :label="`Answer ${idx + 1} *`"
            :name="`Answer ${idx + 1}`"
            :maxlength="60"
            class="mb-5"
            look="simple"
          />
        </div>
        <div
          v-if="errors['answer']"
          class="mt-[5px] text-xs font-bold text-error-700"
        >
          {{ errors['answer'][0] }}
        </div>

        <div class="mt-auto flex pt-5">
          <base-button
            look="light-filled"
            class="mr-[15px] flex-1"
            @click="close"
          >
            Cancel
          </base-button>
          <base-button
            class="ml-[15px] flex-1"
            @click="handleSubmit(onSaveClick)"
          >
            Save
          </base-button>
        </div>
      </div>
    </base-mdl>
  </validation-observer>
</template>
