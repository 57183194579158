<script>
import get from 'lodash-es/get'
import { computed } from 'vue'
import EstoreSummary from '/~/extensions/estore/components/estore-summary.vue'
import EstoreDetails from '/~/extensions/estore/components/product-details.vue'
import EstoreLogo from '/~/extensions/estore/components/product-logo.vue'
import { useEstore } from '/~/extensions/estore/composables'
import { getProviderDefaultUrl } from '/~/utils/cdn'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'sumbion-estore-product-view',
  components: {
    EstoreLogo,
    EstoreSummary,
    EstoreDetails,
    EwDetailsLayout,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    tabsProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { estoreItem, routeState, getEstoreItem } = useEstore()
    const { isBupaTemplate } = useProvider()

    const defaultImage = computed(() => {
      if (isBupaTemplate.value) {
        return getProviderDefaultUrl('bupa')
      }

      return undefined
    })
    const backRoute = computed(() => ({
      name: 'estore',
      ...routeState.value,
    }))

    return {
      estoreItem,
      routeState,
      getEstoreItem,
      defaultImage,
      backRoute,
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    data() {
      return get(this.estoreItem, 'payload')
    },
    retailerLogoProps() {
      const { data } = this

      return {
        alt: data?.name,
        image: data?.images?.[0],
      }
    },
    retailerProductProps() {
      const { data } = this

      if (!data) return {}

      return {
        id: data.id,
        name: data.name,
        originalPrice: Number(data.pricing.rrp_after_tax),
        newPrice: Number(data.pricing.price_after_tax),
        shippingTax: Number(data.shipping?.shipping_after_tax),
        loading: this.loading,
      }
    },
    retailerDetailsProps() {
      const { data } = this

      if (!data) return {}

      return {
        description: data.description,
        delivery:
          'For products purchased throughout the eStore please note that the standard delivery is 5 – 15 business days, subject to stock levels. Parcels will require a signature upon delivery. Deliveries cannot be made to a PO Box. Deliveries may not be made to gated communities. If you see that your item has been damaged in transit, or upon arrival please refuse delivery so that items may be returned to us immediately. If your item is faulty please contact us directly for assistance. Please chose your purchases with care as we are unable to accept returns due to change of mind. Items can only be returned in circumstances where an item is damaged or faulty. If a return is required, please ensure you notify us within 14 days of receipt of your item. When you choose to pay by Direct Deposit, your items will be shipped once payment has been cleared, which can take up to 3 business days. We recommend that payment be made as soon as possible to avoid any delays in shipping. If payment is not received within 14 days, your order will be cancelled. Please remember to use your order number as your payment reference.',
        terms: data.terms,
        loading: this.loading,
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.hash || from.hash) return

      this.getItem(to.params.id)
    },
  },
  async created() {
    await this.getItem(this.id)

    if (this.data) {
      this.$emit('set-title', this.data.name)
    }
  },
  methods: {
    async getItem(id) {
      this.loading = true

      try {
        await this.getEstoreItem(id)
      } catch (error) {
        console.error('estore', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<template>
  <div class="min-h-full px-5 pt-5 pb-[5px]">
    <ew-details-layout
      :loading="loading"
      :ready="!loading"
      :title="retailerProductProps && retailerProductProps.name"
      :back="backRoute"
      :class="loading && 'px-5'"
      :placeholder-ratio="1.4"
      :version="2"
    >
      <template #gallery>
        <estore-logo
          v-bind="retailerLogoProps"
          data-test="gallery"
          :default-src="defaultImage"
        />
      </template>

      <template #details>
        <estore-details
          v-bind="retailerDetailsProps"
          :tabs-props="tabsProps"
          data-test="details"
        />
      </template>

      <template #summary>
        <estore-summary v-bind="retailerProductProps" data-test="summary" />
      </template>
    </ew-details-layout>
  </div>
</template>
