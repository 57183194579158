<script>
import DrawerEPurchases from '/~/templates/australiapost/components/drawer/views/e-purchases/drawer-e-purchases.vue'

export default {
  name: 'profile-e-purchases',
  components: {
    DrawerEPurchases,
  },
}
</script>

<template>
  <drawer-e-purchases to="menu-modal" />
</template>
