<script>
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'nab-verification-cancel-form',
  components: {
    BaseButton,
  },
}
</script>

<template>
  <div class="flex w-full justify-between space-x-[30px]">
    <base-button look="outlined" class="flex-1" @click="$emit('cancel')">
      Keep waiting
    </base-button>
    <base-button color="error" class="flex-1" @click="$emit('submit')">
      Cancel
    </base-button>
  </div>
</template>
