<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'gallery-zoombar',
  components: {
    BaseIcon,
  },
  props: {
    viewPercentage: {
      type: String,
      default: '0%',
    },
    disableZoomOut: {
      type: Boolean,
      default: false,
    },
    disableZoomIn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    zoomIn() {
      if (this.disableZoomIn) return
      this.$emit('on-zoom-in')
    },
    zoomOut() {
      if (this.disableZoomOut) return
      this.$emit('on-zoom-out')
    },
    zoomReset() {
      if (this.disableZoomOut) return
      this.$emit('on-zoom-reset')
    },
  },
}
</script>

<template>
  <div
    class="absolute top-0 z-10 mt-[15px] flex w-full items-center justify-center space-x-2.5 md:right-0 md:mt-[30px] md:mr-32 md:w-auto"
  >
    <button
      class="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-black text-white"
      :class="[disableZoomOut && 'cursor-default opacity-50']"
      @click="zoomReset"
    >
      <base-icon svg="plain/reload" size="sm" />
    </button>

    <button
      class="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-black text-white"
      :class="[disableZoomOut && 'cursor-default opacity-50']"
      @click="zoomOut"
    >
      <base-icon svg="rec/minus" size="sm" />
    </button>
    <span class="w-11 text-center text-white">
      {{ viewPercentage }}
    </span>
    <button
      class="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-black text-white"
      :class="[disableZoomIn && 'cursor-default opacity-50']"
      @click="zoomIn"
    >
      <base-icon svg="rec/plus" size="sm" />
    </button>
  </div>
</template>
