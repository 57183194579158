<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import SummaryTotalFees from '/~/views/checkout.v2/components/checkout/summary/summary-total-fees.vue'

withDefaults(
  defineProps<{
    isAmountVisible?: boolean
  }>(),
  {
    isAmountVisible: true,
  }
)

const { isStatementCurrentFlowType, payment } = useCheckoutReactive()

const notices = computed(() => {
  const notices = []

  if (
    isStatementCurrentFlowType.value &&
    payment.value.totalFees > 0 &&
    payment.value.selectedBankAccount
  ) {
    notices.push('*Fees will be charged as a separate transaction.')
  }
  if (payment.value.transactionFees) {
    notices.push('*Processing Fee is charged on Subtotal plus Program Fee')
  }
  return notices
})
</script>

<template>
  <div class="relative mt-auto rounded-md bg-transparent py-8 sm:rounded-none">
    <div
      v-if="payment.loadingFees"
      class="absolute inset-0 h-full opacity-75 transition-opacity"
    >
      <base-loader class="absolute inset-1/2" />
    </div>
    <summary-total-fees />
    <div v-if="isAmountVisible" class="border-t py-4">
      <div class="grid leading-tight" style="grid-template-columns: auto auto">
        <span class="text-xl font-bold sm:text-2xl">Total</span>
        <span class="text-right text-xl font-bold sm:text-2xl">
          {{ formatDollar(payment.total) }}
        </span>
      </div>
      <div v-if="notices.length" class="mt-3 text-sm text-eonx-neutral-600">
        <div v-for="(notice, index) in notices" :key="index">
          {{ notice }}
        </div>
      </div>
    </div>
  </div>
</template>
