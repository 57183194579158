<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import modal from '/~/core/mdl'
import { useVerifications } from '/~/extensions/otp/composables'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLogger } from '/~/composables/logger'
import {
  useStatementModals,
  useStatementAutoPayments,
  useStatementAccounts,
} from '/~/composables/statements'
import { useUser } from '/~/composables/user'
import ItemDate from '/~/templates/bill-payments/views/payments/make/components/select-statement/content/item/date.vue'

const logger = useLogger('payment-statements-list-item-desk')

export default {
  name: 'payment-statements-list-item-desk',
  components: {
    BaseIcon,
    PayeeIcon,
    ItemDate,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    parentPage: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const router = useRouter()
    const { payment, currentFlowType } = useCheckoutReactive()
    const { enabledAccountIdForReminderModal, checkAutoPayRemindMeAgain } =
      useUser()
    const { showExistingScheduledPaymentModal } = useStatementModals()
    const { withVerificationsCheck } = useVerifications()

    const loading = ref(false)
    const component = computed(() => {
      return (props.item.active && props.item.isBlocked) ||
        props.item.subtotal === 0
        ? 'div'
        : 'router-link'
    })

    const statementAccount = ref(null)
    const { statementAccounts, fetchStatementAccounts } = useStatementAccounts()
    const { selectedStatementAutoPayment, hasDirectDebitOrScheduled } =
      useStatementAutoPayments()

    const clientId = computed(() => props.item?.client?.id)

    async function enableDirectDebit() {
      modal.show('enable-direct-debit', {
        props: {
          onConfirm: async () => {
            checkAutoPayRemindMeAgain(clientId.value)
            await fetchStatementAccounts({
              includeAutoPayments: true,
              includeNextDueStatementOrders: true,
            })
            statementAccount.value = statementAccounts.value.find(
              (item) => item.id === clientId.value
            )
            logger.debug('set selectedStatementAutoPayment', {
              statementAccount: statementAccount.value,
              statementAccountAutoPayment: statementAccount.value.autoPayment,
            })
            selectedStatementAutoPayment.value =
              statementAccount.value.autoPayment
            selectedStatementAutoPayment.value.statement = props.item

            setTimeout(() => {
              modal.show('auto-pay')
            })
          },
          onCancel: async () => {
            checkAutoPayRemindMeAgain(clientId.value)

            if (props.item.isScheduled) {
              setTimeout(() => {
                showExistingScheduledPaymentModal(props.item)
              })
            } else {
              await onPayment()
            }
          },
        },
      })
    }

    async function onPayment() {
      currentFlowType.value = FlowType.statement

      await payment.value.initPayment({
        statement: props.item,
      })

      router.push({
        name: 'statement-checkout',
        params: {
          id: props.item.id,
        },
      })
    }

    async function onClick() {
      if (
        loading.value ||
        !props.item.active ||
        props.item.isBlocked ||
        props.item.subtotal === 0
      ) {
        return
      }
      loading.value = true

      const accountId = props.item?.statementAccount?.id
      const isDirectDebit = await hasDirectDebitOrScheduled(
        accountId,
        undefined
      )

      if (!isDirectDebit && enabledAccountIdForReminderModal(accountId)) {
        enableDirectDebit()
      } else {
        if (props.item.isScheduled) {
          showExistingScheduledPaymentModal(props.item)
        } else {
          onPayment()
        }
      }

      loading.value = false
    }

    return {
      component,
      formatDollar,
      onClick,
      withVerificationsCheck,
    }
  },
  computed: {
    date() {
      return formatDate('daymonthyearnumeric', this.item?.processingDueDate)
    },
    title() {
      return this.item?.payeeName ?? ''
    },
    subtitle() {
      return this.item?.statementDateMonthName + ' statement'
    },
    isCompleted() {
      return (
        this.item.status === 'completed' ||
        this.item.status === 1 ||
        this.item?.metadata?.status === 'completed'
      )
    },
    paidAmount() {
      const amount = this.isCompleted || this.isPending ? this.item.total : 0

      return this.formatDollar(amount)
    },
    isPending() {
      return this.item.status === 'pending'
    },
    clientName() {
      return this.item?.client?.name ?? ''
    },
  },
}
</script>

<template>
  <div
    :active="item.canSeeDetails && !item.isBlocked"
    class="block rounded-lg bg-white px-5 py-4"
    :class="{
      'hover:bg-blue-100': !item.isBlocked,
      'text-neutral-400': !item.canSeeDetails,
      'cursor-pointer': item.active && !item.isBlocked,
    }"
    @click="withVerificationsCheck(onClick)"
  >
    <div
      class="flex justify-between space-x-5 overflow-hidden transition-colors duration-150 ease-in-out"
    >
      <div class="flex space-x-5">
        <payee-icon class="shrink-0" :payee="item.payee" />
        <div class="w-56">
          <h3
            class="truncate text-base font-bold text-eonx-neutral-800"
            :title="clientName"
          >
            {{ clientName }}
          </h3>
          <div
            class="flex flex-wrap items-center text-sm text-eonx-neutral-600"
          >
            {{ subtitle }}
          </div>
        </div>
      </div>
      <div class="w-28 justify-self-start">
        <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
          Account No.
        </p>
        <p class="truncate text-eonx-neutral-800">
          {{ item.clientNumber || '-' }}
        </p>
      </div>
      <item-date :statement="item" class="w-28" />
      <div class="w-28">
        <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
          Amount
        </p>
        <p class="text-eonx-neutral-800">
          {{ formatDollar(item.subtotal) }}
        </p>
      </div>
      <div class="ml-2.5 w-4 shrink-0 self-center">
        <base-icon
          v-if="item.canSeeDetails"
          :size="14"
          svg="v2/custom/chevron-right-outline"
          class="m-auto justify-self-center text-sm"
          :class="{
            'text-eonx-neutral-800': !item.isBlocked,
            'text-gray-200': item.isBlocked,
          }"
        />
      </div>
    </div>
  </div>
</template>
