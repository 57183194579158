<script>
export default {
  name: 'base-tabs',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    look: {
      type: String,
      validator: (v) => !v || /recroom|v2|v3|v3-light|v4/.test(v),
      default: null,
    },
  },
  data() {
    return {
      rawTabs: [],
    }
  },
  computed: {
    tabs() {
      return this.rawTabs
    },
    isRecroomLook() {
      return this.look === 'recroom'
    },
    isV2Look() {
      return this.look === 'v2'
    },
    isV3Look() {
      return this.look === 'v3'
    },
    isV4Look() {
      return this.look === 'v4'
    },
    isV3LightLook() {
      return this.look === 'v3-light'
    },
  },
  watch: {
    value() {
      this.updateTabs()
    },
  },
  mounted() {
    this.updateTabs()
  },
  methods: {
    updateTabs() {
      let activeTab = null

      this.tabs.forEach((tab) => {
        tab.isActive = false

        if (this.value && tab.value === this.value) {
          activeTab = tab
        }
      })

      if (activeTab) {
        activeTab.isActive = true
      } else {
        if (!this.value) {
          this.changeTab(this.rawTabs[0])
        }
      }

      if (this.$refs.body) {
        this.$refs.body.scrollTop = 0
      }
    },
    changeTab(selectedTab) {
      if (!selectedTab) return

      this.tabs.forEach((tab) => {
        tab.isActive = tab.href
          ? tab.href === selectedTab.href
          : tab.name === selectedTab.name
      })
      this.$emit('change', selectedTab.name)
      this.$emit('input', selectedTab.value)
    },
    registerTab(tab) {
      this.rawTabs.push(tab)
      this.updateTabs()
    },
  },
}
</script>

<template>
  <div
    class="flex flex-col"
    :class="[disabled && 'pointer-events-none grayscale']"
    :disabled="disabled"
  >
    <ul class="flex shrink-0 items-center overflow-hidden">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{
          'mx-[15px]': isRecroomLook,
          'flex-1': !isRecroomLook,
        }"
        class="relative h-full"
      >
        <a
          :href="tab.href"
          class="flex h-full max-w-full flex-col justify-center overflow-hidden truncate border-b-2 border-divider px-[5px] text-center font-bold !outline-none"
          :class="{
            'base-tab-active': tab.isActive,
            'py-2.5': !isV2Look && !isV4Look,
            'text-primary': tab.isActive && !isV3Look && !isV3LightLook,
            'text-eonx-neutral-800': !isV4Look,
            'border-transparent px-0': isRecroomLook,
            'py-[15px]': isV2Look,
            'text-sm': isV3Look,
            'text-white': isV3LightLook && tab.isActive,
            'text-eonx-neutral-600 hover:border-primary-darken hover:text-primary-darken':
              (isV3Look || isV3LightLook || isV4Look) && !tab.isActive,
            '!px-6 py-4 sm:pb-[5px]': isV4Look,
            'text-primary': isV4Look && tab.isActive,
            capitalize: capitalize,
          }"
          @click.prevent="changeTab(tab)"
        >
          {{ tab.name }}
        </a>
        <div
          class="absolute bottom-0 left-0 w-full transform border-b-2 border-primary transition duration-200"
          :class="[tab.isActive ? 'scale-x-100' : 'scale-x-0']"
        />
      </li>
    </ul>
    <div
      v-if="$slots.default"
      ref="body"
      class="flex grow flex-col overflow-y-auto"
    >
      <slot />
    </div>
  </div>
</template>
