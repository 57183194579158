<script setup lang="ts">
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import PaymentMethodsList from '/~/components/payments/list/default.v4.vue'
import PaymentMethodBlockItem from '/~/components/payments/payments-methods-block-item.v3.vue'
import PaymentMethodBlock from '/~/components/payments/payments-methods-block.v3.vue'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useDirectDebitComponent } from '/~/templates/bill-payments/composables/statements/use-direct-debit-component'
import EarnPointsPanel from '/~/templates/bill-payments/views/statements/direct-debit/components/earn-points-panel.vue'
import PayeeInfo from '/~/templates/bill-payments/views/statements/direct-debit/components/payee-info.vue'
import PaymentDatePicker from '/~/templates/bill-payments/views/statements/direct-debit/components/payment-date-picker.vue'

interface DirectDebitComponentEmits {
  (event: 'hidden'): void
  (event: 'confirm'): void
  (event: 'hide'): void
  (event: 'cancel'): void
}

const emit = defineEmits<DirectDebitComponentEmits>()
const { translate } = useLocalization()

const {
  showTermsAndConditionsModal,
  itemProcessingDueDay,
  processingActivePeriod,
  selectedStatementAutoPayment,
  tacChecked,
  tacToggle,
  onAddPaymentMethodClicked,
  handleProcessingDateSelect,
  isShowPaymentMethods,
  showPaymentMethods,
  isPaymentMethodsEmpty,
  showBankAccountNotices,
  hasSelectedPaymentMethods,
  isBankAccountSelected,
  isMethodSelected,
  isPointsEnabled,
  methodIcon,
  methodFirstRow,
  methodSecondRow,
  isProcessing,
  onCancel,
  onConfirm,
  title,
  subtitle,
  handleBack,
  handlePaymentMethodSelected,
  fetchingPaymentMethods,
  transactionFees,
} = useDirectDebitComponent(emit)

const { taxationLabel } = useProvider()
</script>

<template>
  <base-mdl width="sme" height="lg" border-radius="2xl" :closable="false">
    <template #header>
      <div class="relative py-6 text-center">
        <div class="absolute ml-6">
          <base-button
            v-if="isShowPaymentMethods"
            icon="heroicons/outline/chevron-left"
            :size="32"
            alt="back"
            @click="handleBack"
          />
        </div>
        <span class="text-primary">
          <base-icon svg="billpay/auto-pay" :size="80" class="mx-auto" />
        </span>
        <span class="absolute top-0 right-0 mr-6 mt-6">
          <base-button
            icon="heroicons/outline/x-mark"
            :size="32"
            @click="onCancel"
          />
        </span>
        <h2 class="mt-4 text-center text-2xl leading-7 text-eonx-neutral-800">
          Enable {{ translate('directDebit.text') }}
        </h2>
      </div>
    </template>

    <!-- Select Date, Method -->
    <div v-if="!isShowPaymentMethods" class="flex flex-col">
      <earn-points-panel v-if="isPointsEnabled" />
      <payee-info
        :payee="selectedStatementAutoPayment?.statement?.payee"
        :title="title"
        :subtitle="subtitle"
        class="mt-4"
      />

      <payment-date-picker
        v-model="itemProcessingDueDay"
        v-analytics:select.custom="{
          label: 'Payment date set',
          pageGroup: 'Make a payment',
          component1: 'Direct Debit Request',
        }"
        :active-period="processingActivePeriod"
        class="mt-8"
        :is-disabled="isProcessing"
        hide-icon
        @select="handleProcessingDateSelect"
      />

      <payment-method-block title="Payment method" class="mt-8">
        <payment-method-block-item
          v-analytics:click.custom="{
            cta: 'Select or add payment method',
            pageGroup: 'Make a payment',
            component1: 'Direct Debit Request',
          }"
          :is-disabled="isProcessing"
          small
          @click="showPaymentMethods"
        >
          <template v-if="isMethodSelected">{{ methodFirstRow }}</template>
          <template v-else>Select or add payment method</template>

          <template v-if="isMethodSelected" #subtitle>
            {{ methodSecondRow }}
          </template>

          <template #icon>
            <div
              class="flex h-full w-full items-center justify-center rounded-full"
              :class="{
                'bg-eonx-neutral-600 text-white': isBankAccountSelected,
                'text-primary': !isMethodSelected,
              }"
            >
              <base-icon
                :svg="
                  isMethodSelected ? methodIcon : 'heroicons/solid/credit-card'
                "
                :size="isBankAccountSelected ? 'md' : 40"
              />
            </div>
          </template>
        </payment-method-block-item>
      </payment-method-block>

      <base-loader v-if="selectedStatementAutoPayment.isLoadingFees" />
      <div
        v-else-if="
          selectedStatementAutoPayment.programFeesPercentage > 0 ||
          transactionFees
        "
        class="mb-6 text-eonx-neutral-600"
      >
        <div class="mb-3 font-bold text-eonx-neutral-800">Fees</div>
        <div
          v-if="selectedStatementAutoPayment.programFeesPercentage > 0"
          class="mb-1"
          data-testid="program-fee"
        >
          Program Fee ({{ selectedStatementAutoPayment.programFeesPercentage }}%
          inc. {{ taxationLabel }})
        </div>
        <div v-if="transactionFees" data-testid="processing-fee">
          Processing Fee ({{ transactionFees }}% inc. {{ taxationLabel }})
        </div>
      </div>
    </div>
    <!-- Payment Methods -->
    <base-loader v-else-if="fetchingPaymentMethods" class="w-full" />
    <div v-else>
      <div v-if="!isPaymentMethodsEmpty" class="flex justify-between">
        <h3>Select payment method</h3>
        <button
          v-analytics:click="{
            cta: 'Add new payment method',
            pageGroup: 'Make a payment',
            component1: 'Direct Debit Request',
          }"
          type="button"
          class="h-9 whitespace-nowrap rounded px-4 font-bold text-primary hover:opacity-80"
          @click="onAddPaymentMethodClicked"
        >
          + Add new
        </button>
      </div>
      <payment-methods-list
        v-analytics:selected.custom="{
          label: 'Payment method set',
          pageGroup: 'Make a payment',
          component1: 'Direct Debit Request',
        }"
        class="my-8 w-full"
        @selected="handlePaymentMethodSelected"
      />
      <div
        v-if="showBankAccountNotices"
        class="mb-6 flex items-center bg-eonx-neutral-200 py-2.5 text-eonx-neutral-800"
      >
        <div class="mx-8">
          <base-icon svg="plain/alert-filled" :size="24" />
        </div>
        <div class="text-sm text-eonx-neutral-600">
          <ul class="list-disc">
            <li>
              Payments from bank accounts may take up to 3 business days to
              clear.
            </li>
            <li>
              Please ensure you have available funds to avoid any bank dishonour
              fees.
            </li>
            <li>
              Your points will be allocated once this payment has cleared.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <template #footer>
      <div class="-mx-8 border-t px-8">
        <div class="mb-4 flex items-center gap-1 pt-8 hover:opacity-80">
          <button
            v-analytics:click="
              () => ({
                label: `${
                  tacChecked ? 'Tick' : 'Untick'
                } I agree to Terms and Conditions`,
                pageGroup: 'Make a payment',
                component1: 'Direct Debit Request',
              })
            "
            class="inline-flex items-center space-x-2.5 text-eonx-neutral-600"
            @click="tacToggle"
          >
            <span
              class="relative flex h-5 w-5 items-center justify-center rounded-sm border border-gray-300"
              :class="{ 'border-primary bg-primary': tacChecked }"
            >
              <base-icon
                svg="heroicons/outline/check"
                :size="14"
                class="text-white"
              />
            </span>
            <span>I agree to</span>
          </button>
          <b
            v-analytics:click="{
              cta: 'Terms & Conditions',
              pageGroup: 'Make a payment',
              component1: 'Direct Debit Request',
            }"
            class="cursor-pointer text-primary"
            @click="showTermsAndConditionsModal"
          >
            Terms & Conditions
          </b>
        </div>
        <div class="mt-2.5 grid grid-cols-2 gap-x-2">
          <base-button
            v-analytics:click="{
              cta: 'Cancel',
              pageGroup: 'Make a payment',
              component1: 'Direct Debit Request',
            }"
            class="!h-12 flex-auto"
            look="outlined-color"
            size="lge"
            @click="onCancel"
          >
            Cancel
          </base-button>
          <base-button
            v-analytics:click="{
              cta: 'Confirm',
              pageGroup: 'Make a payment',
              component1: 'Direct Debit Request',
            }"
            class="!h-12 flex-auto"
            size="lge"
            :disabled="
              !tacChecked || !hasSelectedPaymentMethods || isProcessing
            "
            @click="onConfirm"
          >
            <span v-if="isProcessing">Processing...</span>
            <span v-else>Confirm</span>
          </base-button>
        </div>
      </div>
    </template>
  </base-mdl>
</template>
