<script setup lang="ts">
import pick from 'lodash-es/pick'
import { ValidationObserver } from 'vee-validate'
import { computed, getCurrentInstance, ref, watch } from 'vue'
import { RecroomEvent } from '/~/extensions/rec-room/core/event'
import { RecroomPostQuiz } from '/~/extensions/rec-room/core/post-quiz'
import { createDate, formatDate } from '/~/utils/format/date'
import { useGroups } from '/~rec/composables/groups'
import { usePostCreator } from '/~rec/composables/post-creator'
import { useBackendValidation } from '/~rec/composables/backend-validation'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PostCreatorFooter from '../post-creator-footer.vue'
import RichInput from '/~rec/components/rich-input/rich-input.vue'

const props = withDefaults(
  defineProps<{
    source?: RecroomEvent
    post?: RecroomPostQuiz
    announcement?: boolean
    sourceHidden?: boolean
  }>(),
  {
    sourceHidden: false,
    announcement: false,
  }
)

const emit = defineEmits<{
  (event: 'success'): void
  (event: 'update:loading', flag: boolean): void
  (event: 'navigate', id: string, props: any, param?: boolean): void
}>()

const internalInstance = getCurrentInstance()
const { backendErrors, processBackendErrors } = useBackendValidation()
const { allMembers } = useGroups()

const startsAt = ref(
  props.post?.startsAt
    ? props.post.startsAt.format()
    : createDate().startOf('day').utc().format()
)
const endsAt = ref(
  props.post?.endsAt
    ? formatDate('daymonthyear', props.post.endsAt.utc())
    : null
)
const quizEndDate = ref<any>(!!endsAt.value)
const quiz = ref(
  props.post?.raw.questions.map(
    (q: {
      id: string
      content: any
      options: { id: string; content: any; correct: any }[]
    }) => ({
      ...pick(q, ['id', 'content']),
      options: q.options.map((o: any) => pick(o, ['id', 'content', 'correct'])),
    })
  ) ?? []
)

function getPayload() {
  return {
    // TODO: starts_at, ends_at and pass_rate are missing in the design
    quiz: quiz.value,
    pass_rate: 0.75,
    starts_at: startsAt.value,
    ends_at: endsAt.value,
    announcement: props.announcement,
  }
}

const {
  validationObserverRef,
  isEdit,
  content,
  attachments,
  mentioned,
  linksPreviews,
  loading,
  linkPreviewsProcessing,
  isAndriod,
  input,
  onEmojiSelect,
  onInput,
  removePreview,
  updateMentioned,
  onSend,
} = usePostCreator({
  post: props.post,
  source: props.source,
  getPayload,
  emit,
  internalInstance,
  processBackendErrors,
})

const hasDuplicatesQuestions = computed(() => {
  return quiz.value.some(({ content }: any, i: any) => {
    return quiz.value.findIndex((item: any) => item.content === content) !== i
  })
})

const isSendDisabled = computed(() => {
  return (
    !content.value.trim() ||
    quiz.value.length === 0 ||
    hasDuplicatesQuestions.value ||
    (quizEndDate.value && !endsAt.value)
  )
})

watch(quizEndDate, (val) => {
  if (!val) {
    endsAt.value = null
  }
})

function onAddClick() {
  emit(
    'navigate',
    'rec-post-quiz-qa',
    {
      on: {
        add: (qa: any) => quiz.value.push(qa),
      },
    },
    true
  )
}

function onQaEdit(idx: number) {
  emit('navigate', 'rec-post-quiz-qa', {
    props: {
      isEdit: isEdit.value,
      qa: quiz.value[idx],
    },
    on: {
      update: (qa: any) => {
        quiz.value.splice(idx, 1, qa)
      },
    },
  })
}

function onQaDelete(idx: number) {
  quiz.value.splice(idx, 1)
}
</script>

<template>
  <validation-observer ref="validationObserverRef" slim>
    <div class="flex flex-col">
      <div class="flex-1 p-5 pt-0 md:pt-5">
        <div
          v-if="!isEdit && !sourceHidden"
          class="mb-2.5 truncate text-xs font-bold"
        >
          <span class="text-disabled">To News Feed:</span>
          <span v-if="source">
            {{ source.name }}
          </span>
        </div>

        <rich-input
          v-bind="$attrs"
          ref="input"
          :validation="{
            rules: 'offensive|max:1200',
            name: 'Question',
            mode: 'aggressive',
          }"
          :error="backendErrors.content"
          :content.sync="content"
          :mention-users="allMembers"
          :mentioned="mentioned"
          :loading="loading"
          :disable-send="isSendDisabled"
          placeholder="Ask your question"
          name="content"
          focused
          plain
          :max-length="1200"
          :is-post="true"
          :is-andriod="isAndriod"
          v-on="$listeners"
          @send="onSend"
          @input="onInput"
          @update:mentioned="updateMentioned"
        />

        <div v-if="linkPreviewsProcessing" class="mb-5 flex">
          <base-loader size="sm" class="mx-auto" />
        </div>

        <div class="mt-10 mb-2.5 flex justify-between">
          <b>Quiz Questions</b>
          <a
            v-if="quiz.length"
            class="font-primary cursor-pointer font-bold"
            @click="onAddClick"
          >
            + Add
          </a>
        </div>

        <base-button
          v-if="!quiz.length"
          class="h-20 w-full"
          @click="onAddClick"
        >
          + Add a Question
        </base-button>

        <template v-else>
          <div
            v-for="(qa, idx) in quiz"
            :key="idx"
            class="mb-[15px] flex flex-wrap items-center justify-between rounded-xl bg-divider-light px-5 py-2.5 md:flex-nowrap"
          >
            <div
              class="order-1 flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-white text-xl font-bold text-primary md:order-none"
            >
              {{ idx + 1 }}
            </div>
            <span
              class="flex-0 order-2 mx-2.5 grid grid-cols-1 break-words font-bold md:order-none md:flex-1"
            >
              {{ qa.content }}:
            </span>
            <div class="order-3 md:order-none">
              <base-button
                class="text-primary"
                icon="rec/edit"
                size="md"
                @click="onQaEdit(idx)"
              />
              <base-button
                class="ml-[15px] text-primary"
                icon="rec/bin"
                size="md"
                @click="onQaDelete(idx)"
              />
            </div>
          </div>
        </template>

        <div
          class="mt-[15px] flex items-center justify-start md:justify-between"
        >
          <base-checkbox
            v-model="quizEndDate"
            class="mr-[15px] flex h-10 flex-none items-center pt-2.5 md:mr-0"
          >
            <div class="mr-2.5 text-sm font-bold">Quiz End Date</div>
          </base-checkbox>
          <div>
            <base-datepicker
              v-if="quizEndDate"
              v-model="endsAt"
              :validation="{
                rules: 'required',
                name: 'End Date',
                mode: 'aggressive',
              }"
              :error="backendErrors.endsAt"
              class="flex-auto sm:flex-none"
              :disabled-dates="{
                to: createDate(),
              }"
              :popup-style="{
                zIndex: 999,
              }"
              format="daymonthyear"
              nolabel
              name="end_date"
              :is-edit="isEdit"
              :min-date-mobile="new Date().toISOString().split('T')[0]"
            />
          </div>
        </div>
      </div>
      <div class="px-5">
        <div class="flex w-full flex-col space-y-5">
          <div
            v-for="(preview, idx) in linksPreviews"
            :key="idx + preview.url"
            class="relative flex w-full flex-col overflow-hidden rounded-lg border border-divider"
          >
            <div
              class="absolute right-0 mr-2.5 mt-2.5 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-300 text-eonx-neutral-800 hover:bg-gray-400"
              @click="removePreview(idx)"
            >
              <base-icon svg="close" :size="22" />
            </div>
            <div class="h-full w-full">
              <img :src="preview.image" class="w-full" />
            </div>
            <div
              class="flex flex-col space-y-[5px] bg-gray-50 py-[15px] px-6 text-eonx-neutral-800"
            >
              <span class="text-lg font-semibold">
                {{ preview.title }}
              </span>
              <span class="text-eonx-neutral-600">
                {{ preview.hostname }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <post-creator-footer
        :attachments.sync="attachments"
        :post="post"
        :disabled="isSendDisabled"
        type="quiz"
        :is-andriod="isAndriod"
        @emoji="onEmojiSelect"
        @send="onSend"
      />
    </div>
  </validation-observer>
</template>
