import { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import { useExtensions } from '/~/composables/extensions'

const moduleName = 'qrconnect' // Name from /2.0/me/modules, it may be changed.
const { getManifestByName, getConfigByName } = useExtensions()

const extension = getManifestByName(moduleName)
const config = getConfigByName(moduleName) || {}
const { scanQr, showSecurity, remoteChecking, reserveWorkspace } = config as any

export default [
  {
    path: '/qr-connect',
    component: () => import('./root.vue'),
    children: [
      {
        path: '',
        name: 'qr-connect',
        component: () => import('./views/main/qr-main.vue'),
        meta: {
          title: 'QR Connect',
          moduleName,
        },
        beforeEnter(to, from, next) {
          if (extension) {
            next()
          } else {
            next('/')
          }
        },
      },
      {
        path: 'scan',
        name: 'qr-scan',
        component: () => import('./views/scan/qr-scan.vue'),
        meta: {
          title: 'Scan QR',
        },
        beforeEnter(to, from, next) {
          if (scanQr?.enabled) {
            next()
          } else {
            next('/qr-connect')
          }
        },
      },
      {
        path: 'security',
        name: 'qr-security',
        component: () => import('./views/security/qr-security.vue'),
        meta: {
          title: 'Show Security',
        },
        beforeEnter(to, from, next) {
          if (showSecurity?.enabled) {
            next()
          } else {
            next('/qr-connect')
          }
        },
      },
      {
        path: 'remote-check-in',
        name: 'qr-check-in',
        component: () => import('./views/check-in/qr-check-in.vue'),
        meta: {
          title: 'Remote Check-In',
        },
        beforeEnter(to, from, next) {
          if (remoteChecking?.enabled) {
            next()
          } else {
            next('/qr-connect')
          }
        },
      },
      {
        path: 'reserve-workspace',
        name: 'qr-reserve-workspace',
        component: () =>
          import('./views/reserve-workspace/qr-reserve-workspace.vue'),
        meta: {
          title: 'Reserve Workspace',
        },
        props: true,
        beforeEnter(to, from, next) {
          if (reserveWorkspace?.enabled) {
            next()
          } else {
            next('/qr-connect')
          }
        },
      },
      {
        path: 'building-location',
        name: 'qr-building-location',
        component: () =>
          import('./views/reserve-workspace/qr-building-location.vue'),
        meta: {
          title: 'Select Building Location',
          back: 'qr-reserve-workspace',
        },
        beforeEnter: (_, from, next) =>
          reserveWorkspace && reserveWorkspace.enabled
            ? redirectWorkspace(from, next, ['dates'])
            : '/qr-connect',
        props: true,
      },
      {
        path: 'building-level',
        name: 'qr-building-level',
        component: () =>
          import('./views/reserve-workspace/qr-building-level.vue'),
        meta: {
          title: 'Select Building Level',
          back: 'qr-reserve-workspace',
        },
        beforeEnter: (_, from, next) =>
          reserveWorkspace && reserveWorkspace.enabled
            ? redirectWorkspace(from, next, ['dates', 'building'])
            : '/qr-connect',
        props: true,
      },
    ] as RouteConfig[],
  },
]

function redirectWorkspace(
  { name, params: { data } }: Route,
  next: NavigationGuardNext,
  keys: string[]
) {
  if (
    name !== 'qr-reserve-workspace' &&
    (!data ||
      keys.reduce((value, item) => {
        return value && !(data as any)[item]
      }, false))
  ) {
    next({ name: 'qr-reserve-workspace', params: { withoutAnimation: 'true' } })
  }
  next()
}
