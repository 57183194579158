import get from 'lodash-es/get'
import isNil from 'lodash-es/isNil'
import { RecroomHi5Reason } from './hi5-reason'
import { RecroomPost } from './post'
import { RecroomUser } from './user'

export class RecroomPostHi5 extends RecroomPost {
  constructor(raw = {}) {
    super(raw)

    this.reason = !isNil(raw.reason) && new RecroomHi5Reason(raw.reason)
    this.recipient = raw.recipient && new RecroomUser(raw.recipient)
    this.sender = raw.sender && new RecroomUser(raw.sender)
  }

  get message() {
    return get(this.raw, 'highFive.message')
  }
}
