<script>
import DrawerHelp from '/~/templates/australiapost/components/drawer/views/settings/help/drawer-help.vue'

export default {
  name: 'profile-help',
  components: {
    DrawerHelp,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    onConfirmation() {
      this.$router.push({ hash: '#profile-help-confirmation' })
    },
  },
}
</script>

<template>
  <drawer-help :id="id" @confirmation="onConfirmation" />
</template>
