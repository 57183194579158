<script setup lang="ts">
import ui from '/~/core/ui'
import { useCheckoutReactive } from '/~/composables/checkout'
import SummaryPayWithItem from './summary-pay-with-item.vue'

const { payment } = useCheckoutReactive()
</script>

<template>
  <div>
    <hr v-if="!ui.mobile" class="mb-5 h-px w-full bg-gray-50" />
    <div class="mb-4 font-bold">Paying with</div>
    <div
      class="mt-[5px] space-y-6 rounded-md bg-white py-2.5 sm:mt-5 sm:rounded-none sm:bg-transparent"
    >
      <summary-pay-with-item
        v-if="payment.selectedPoints"
        :method="payment.selectedPoints"
      />

      <summary-pay-with-item
        v-if="payment.selectedCard && payment.payWithCard"
        :method="payment.selectedCard"
      />

      <summary-pay-with-item
        v-if="payment.selectedBankAccount && payment.payWithBankAccount"
        :method="payment.selectedBankAccount"
      />
    </div>
  </div>
</template>
