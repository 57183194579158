<script>
import { computed } from 'vue'
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'

export default {
  name: 'base-hero',
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const imageData = computed(() => {
      const desktopImage = props.image?.desktop
      const mobileImage = props.image?.mobile || desktopImage

      let src

      if (ui.mobile) {
        src = cdn(mobileImage)
          .preview('1574x600')
          .format('auto')
          .quality('smart')
          .url()
      } else {
        src = cdn(desktopImage)
          .preview('2352x600')
          .format('auto')
          .quality('smart')
          .url()
      }

      return {
        src,
        alt: props.image?.alt ?? 'Hero Image',
      }
    })

    return {
      imageData,
    }
  },
}
</script>

<template>
  <section
    class="h-64 min-w-full bg-cover bg-right bg-no-repeat sm:h-[300px]"
    :style="{ backgroundImage: `url(${imageData.src})` }"
    data-cy="hero"
  >
    <div
      class="relative flex h-full w-full flex-col content-center justify-center"
    >
      <div
        class="relative mx-auto flex h-full w-full max-w-7xl flex-col items-center justify-center sm:items-start"
      >
        <slot />
      </div>
    </div>
  </section>
</template>
