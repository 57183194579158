import '/~/configurator/components'
import 'swiper/css/bundle'
import '/~/boot/packages'
import '/~/boot/mixins'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { useExtensions } from '/~/composables/extensions'
import { useNotifications } from '/~/composables/notifications'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePurchases } from '/~/composables/purchases'
import modals from './modals'

modal.register(modals)

async function bootstrap() {
  const { fetchPaymentMethods, getPaymentMethodsConfig } = usePaymentMethods()
  const { initNotifications } = useNotifications()
  const { initExtensions } = useExtensions()
  const { initPurchases } = usePurchases()

  initExtensions().then(() => {
    initNotifications()
  })

  initPurchases()

  getPaymentMethodsConfig()
  fetchPaymentMethods()

  import('/~/boot/zendesk')
  import('/~/boot/google-analytics')
}

if (eonx.user) {
  bootstrap()
} else {
  emitter.once('auth:success', bootstrap)
}
