<script>
import AppLogo from '/~/components/app/app-logo.vue'
import { useCms } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'auth-logo',
  components: {
    AppLogo,
  },
  setup() {
    const { cmsConfig } = useCms()
    const { isSymbioneliteProvider } = useProvider()

    return {
      cmsConfig,
      isSymbioneliteProvider,
    }
  },
  computed: {
    logoWidth() {
      return this.cmsConfig['auth-logo-max-width']
    },
  },
}
</script>

<template>
  <div class="flex shrink-0">
    <!-- optimize this later -->
    <div
      class="mx-auto mt-8 flex min-h-20 w-full max-w-screen-xs shrink-0 items-center px-5 md:mt-20"
    >
      <app-logo
        class="m-auto w-full"
        :max-width="logoWidth"
        :min-height="isSymbioneliteProvider && 80"
      />
    </div>
  </div>
</template>
