<script>
import cdn from '/~/utils/cdn'

export default {
  name: 'widget-tiles-sda',
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <div class="relative text-black">
    <img
      :src="
        cdn(tile.image.desktop)
          .preview('752x472')
          .format('auto')
          .quality('smart')
          .url()
      "
      :alt="tile.image.alt"
      class="block h-full w-full object-cover"
    />
    <div
      class="border-t py-5"
      :class="{
        'text-left': tile.content.contentAlignmentX === 'left',
        'text-right': tile.content.contentAlignmentX === 'right',
        'text-center': tile.content.contentAlignmentX === 'center',
        'px-5 md:px-[30px]': tile.content.padding === 'sm',
        'px-[30px] md:px-14 md:pb-[30px]': tile.content.padding === 'md',
        'px-10 md:px-20 md:pb-10': tile.content.padding === 'lg',
      }"
    >
      <h2 v-if="tile.content.title" v-html="tile.content.title" />
      <div
        v-if="tile.content.text"
        class="mt-2.5 text-lg leading-tight text-eonx-neutral-600"
        v-html="tile.content.text"
      />
    </div>
  </div>
</template>
