<script>
import get from 'lodash-es/get'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import RetailerOffers from '/~/extensions/online-offers/templates/default/views/retailer/offers/retailer-offers.vue'
import BaseClampLine from '/~/components/base/clamp-line/base-clamp-line.vue'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import { useAddresses } from '/~/composables/addresses'
import RetailerLogo from './logo/retailer-logo.vue'

export default {
  name: 'online-offers-retailer',
  components: {
    EwDetailsLayout,
    RetailerOffers,
    RetailerLogo,
    BaseClampLine,
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { getAddresses } = useAddresses()
    const { routeState, getMerchantById } = useOnlineOffers()

    return {
      getAddresses,
      routeState,
      getMerchantById,
    }
  },
  data() {
    return {
      loading: true,
      retailer: null,
    }
  },
  computed: {
    backRoute() {
      return {
        name: 'online-offers',
        ...this.routeState,
      }
    },
    merchant() {
      return get(this.retailer, 'merchant.payload', {})
    },
    coupons() {
      return get(this.retailer, 'coupons', []).map((item) => item.payload)
    },
    image() {
      return this.cdn(this.merchant?.logo).resize('400x').url()
    },
    alt() {
      return this.merchant?.name
    },
  },
  watch: {
    $route(to, from) {
      if (to.hash || from.hash) return

      this.getData()
    },
  },
  created() {
    this.getAddresses()
    this.getData()
  },
  methods: {
    async getData() {
      this.retailer = await this.getMerchantById(this.slug)
      this.loading = false
    },
  },
}
</script>

<template>
  <ew-details-layout
    :loading="loading"
    :ready="Boolean(retailer)"
    :title="merchant.name"
    :back="backRoute"
    class="p-5"
    :placeholder-ratio="1"
  >
    <template #gallery>
      <retailer-logo data-test="retailer-logo" :src="image" :alt="alt" />
    </template>
    <template #summary>
      <retailer-offers
        data-test="retailer-offers"
        :merchant="merchant"
        :coupons="coupons"
      />
    </template>
    <template v-if="merchant.summary" #details>
      <base-clamp-line
        data-test="clamp-line"
        :rows="5"
        class="hidden leading-normal text-eonx-neutral-600 sm:block"
      >
        {{ merchant.summary }}
      </base-clamp-line>
    </template>
  </ew-details-layout>
</template>
