<script lang="ts" setup>
import { formatDollar } from '/~/utils/format/money'
import { useCms } from '/~/composables/cms'
import { useUser } from '/~/composables/user'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

const { user } = useUser()
const { showSavedToDate } = useCms()
</script>

<template>
  <router-link
    v-if="showSavedToDate"
    class="flex items-center"
    :to="{ hash: '#profile-saved' }"
  >
    <span
      class="mr-2.5 flex h-[30px] w-[30px] flex-none items-center justify-center rounded-lg bg-primary text-white"
    >
      <base-icon svg="v2/custom/trending-up" :size="21" />
    </span>
    Saved to date
    <b class="ml-[5px]">
      {{ formatDollar(user.totalSaving) }}
    </b>
  </router-link>
</template>
