import { RewardsExtension } from '/~/core/extension'
import { useOtp } from '/~/extensions/otp/composables'
import { lazyEwalletRoute } from '/~/utils/lazy'
import { useDrawerMenu } from '/~/components/drawer/use-drawer-menu'
import router from '/~/router'

class OtpExtension extends RewardsExtension {
  async setEwalletRoutes() {
    const { isEnrolmentOtpEnabled } = useOtp()

    if (!isEnrolmentOtpEnabled.value) {
      return []
    }

    const { ewalletMenuRoutes } = useDrawerMenu()

    ewalletMenuRoutes.value.push(
      {
        path: '#profile-verification',
        name: 'profile-verification',
        component: lazyEwalletRoute(
          import(
            '/~/extensions/otp/views/ewallet-menu/views/verification/verification-home-container.vue'
          )
        ),
        meta: {
          appear: 'right',
        },
      },
      {
        path: '#profile-verification-email',
        name: 'profile-verification-email',
        component: lazyEwalletRoute(
          import(
            '/~/extensions/otp/views/ewallet-menu/views/verification/verification-code.vue'
          )
        ),
        meta: {
          appear: 'right',
        },
        props: {
          type: 'email',
        },
      },
      {
        path: '#profile-verification-mobile',
        name: 'profile-verification-mobile',
        component: lazyEwalletRoute(
          import(
            '/~/extensions/otp/views/ewallet-menu/views/verification/verification-code.vue'
          )
        ),
        meta: {
          appear: 'right',
        },
        props: {
          type: 'mobile',
        },
      }
    )

    router.beforeEach((to, from, next) => {
      if (to.meta) {
        to.meta.previousHash = router.currentRoute.hash
      } else {
        to.meta = { previousHash: router.currentRoute.hash }
      }

      next()
    })

    return ewalletMenuRoutes.value
  }
}

export default OtpExtension
