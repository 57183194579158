<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-users-selected-mobile',
  components: {
    BaseAvatar,
    BaseIcon,
  },
  props: {
    hiddenUsers: {
      type: Array,
      default: () => [],
    },
    selectedUsers: {
      type: Array,
      default: () => [],
    },
    excludeMe: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { myRecId } = useRecProfile()

    return { myRecId }
  },
}
</script>

<template>
  <div class="flex w-full flex-wrap items-center">
    <div
      v-for="u in selectedUsers"
      :key="u.id"
      class="flex shrink-0 flex-col items-center px-[5px] py-[5px] text-center"
      :class="{
        'pointer-events-none opacity-50': excludeMe && u.id === myRecId,
        hidden: hiddenUsers.includes(u.id),
      }"
    >
      <div class="relative">
        <div
          v-if="!(excludeMe && u.id === myRecId)"
          class="absolute bottom-0 right-0 z-1 -mb-0.5 -mr-2.5 cursor-pointer rounded-full border-white text-white"
          style="border: 3px solid"
          @click="$emit('unselect', u)"
        >
          <base-icon
            svg="plain/no"
            class="rounded-full bg-gray-400 p-1.5"
            :size="24"
          />
        </div>
        <base-avatar size="lg" :src="u.avatarUrl" :abbr="u.initials" />
      </div>
    </div>
  </div>
</template>
