<script>
import { computed } from 'vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import cdn from '/~/utils/cdn'

export default {
  name: 'presales-tile',
  components: {
    BaseImage,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
    thumbWidth: {
      type: Number,
      default: 300,
    },
    thumbHeight: {
      type: Number,
      default: 300,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
      validator: (v) => !v || /pre-sale/.test(v),
    },
  },
  setup(props) {
    const image = computed(() =>
      cdn(props.tile.image).preview('900x600').quality('best').url()
    )

    return {
      image,
    }
  },
}
</script>

<template>
  <div class="relative h-full">
    <base-image
      :src="image"
      :ratio="4 / 3"
      :shadow="false"
      :rounded="false"
      top-rounded
    />
    <h3 class="p-[15px] text-center text-base font-bold text-eonx-neutral-800">
      {{ tile.description }}
    </h3>
  </div>
</template>
