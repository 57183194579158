<script>
import debounce from 'lodash-es/debounce'
import { ValidationProvider } from 'vee-validate'
import { nextTick } from 'vue'
import { mixin as clickaway } from 'vue-clickaway'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import InputMixin from '/~/components/base/input/input.mixin'

export default {
  name: 'base-select-async',
  components: {
    BaseInput,
    BaseIcon,
    ValidationProvider,
  },
  mixins: [clickaway, InputMixin],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    fetch: {
      type: Function,
      default: null,
    },
    look: {
      type: String,
      default: '',
    },
    nolabel: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'plain/search',
    },
    iconPlain: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
    valueAsObject: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [Object, String],
      default: null,
    },
    defaultOptions: {
      type: Array,
      default: null,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    fetchOnFocus: {
      type: Boolean,
      default: false,
    },
    entryClass: {
      type: String,
      default: 'rounded h-10',
    },
    entryClassExpand: {
      type: String,
      default: 'rounded py-1.5',
    },
    entryClassSelected: {
      type: String,
      default:
        'rounded-sm h-12 text-eonx-neutral-800 border w-full flex items-center p-3',
    },
    inputClass: {
      type: [String, Array],
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    isStaticData: {
      type: Boolean,
      default: false,
    },
    isFetchingSelectedItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value || '',
      options: this.defaultOptions,
      isFocused: false,
      selectedValue: null,
    }
  },
  computed: {
    isExpanded() {
      return (
        this.isFocused &&
        (this.inputValue.length > 1 || this.isStaticData) &&
        this.options
      )
    },
    isLoading() {
      return this.isFocused && this.inputValue.length > 0 && !this.options
    },
    isEmpty() {
      return this.options && this.options.length === 0
    },
    debounceTimeout() {
      return this.isStaticData ? 0 : 400
    },
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue || ''
    },
  },
  created() {
    this.selectedValue = this.inputValue
  },
  mounted() {
    this.syncValue(this.inputValue)
    this.validateSilent()
  },
  methods: {
    onClickSelected() {
      this.clear()
      this.selectedValue = null
      this.$emit('change', null)
      this.inputValue = ''
      nextTick(() => {
        this.$refs.baseInput.focus()
      })
    },
    onSearchFocus() {
      this.isFocused = true

      if ((this.fetchOnFocus && this.inputValue) || this.isStaticData) {
        this.fetchOptions(this.inputValue)
      }
    },
    onSearchBlur(event) {
      if (this.$refs.list.contains(event?.relatedTarget)) {
        return
      }

      this.validateOnBlur(this.inputValue)
      this.$emit('blur', this)
      this.isFocused = false
    },
    onSearchCleared() {
      this.clear()
      this.$emit('clear')
    },
    onSearchInput(inputValue) {
      this.$emit('input', inputValue)

      if (inputValue.length > 1) {
        this.fetchOptions(inputValue)
        this.isFocused = true
      } else {
        this.clear()
        if (this.isStaticData) {
          this.fetchOptions(inputValue)
        }
      }
    },
    onSearchUnmasked(value) {
      this.$emit('unmasked', value)
    },
    fetchOptions: function (inputValue) {
      debounce(() => {
        if (this.fetch instanceof Function) {
          this.fetch(inputValue)
            .then((data) => {
              this.options = data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      }, this.debounceTimeout)()
    },
    onSelectItem(item) {
      this.$emit('change', item, this)
      this.inputValue = this.valueAsObject ? item : item.label
      this.selectedValue = item

      if (this.validation?.mode !== 'passive') {
        this.validate(this.inputValue)
      }

      this.close()
    },
    clear() {
      this.$emit('clear', this.inputValue ? null : this)
      this.options = null
      this.reset()
    },
    close() {
      if (this.isFocused) {
        this.isFocused = false
        this.onSearchBlur()
      }
    },
  },
}
</script>

<template>
  <div v-on-clickaway="close" class="relative">
    <validation-provider
      v-slot="{ errors }"
      v-bind="validation"
      ref="validationProviderRef"
      mode="passive"
      :name="validation.name ? validation.name : label"
      slim
      :detect-input="false"
    >
      <button
        v-if="$scopedSlots.inputValueSlot && selectedValue"
        :class="entryClassSelected"
        @click="onClickSelected"
      >
        <slot name="inputValueSlot" :value="selectedValue"></slot>
        <base-icon
          class="absolute right-3 z-10"
          svg="plain/chevron-bottom"
          size="sm"
        />
      </button>
      <base-input
        v-else
        ref="baseInput"
        v-model="inputValue"
        clearable
        :error="isFetchingSelectedItem ? '' : errors[0] || error"
        :icon="icon"
        :icon-plain="iconPlain"
        :look="look"
        :label="label"
        :nolabel="nolabel"
        :placeholder="placeholder"
        :disabled="!fetch || isFetchingSelectedItem || disabled"
        :floated="floated"
        :required="required"
        :loading="isLoading || isFetchingSelectedItem"
        :entry-class="entryClass"
        :input-class="inputClass"
        :label-class="labelClass"
        :mask="mask"
        :name="name"
        :validation="{
          vid: `${name} input`,
          disabled: true,
        }"
        @input="onSearchInput"
        @focus="onSearchFocus"
        @cleared="onSearchCleared"
        @unmasked="onSearchUnmasked"
      />
    </validation-provider>

    <transition name="fade">
      <div
        v-show="isExpanded"
        ref="list"
        class="absolute top-full left-0 z-10 max-h-80 w-full overflow-y-auto rounded border bg-default"
        :class="entryClassExpand"
      >
        <slot v-if="isEmpty" name="noOptionsFound">
          <div class="py-1.5 px-3">
            <span class="text-sm">No records were found</span>
          </div>
        </slot>
        <ul v-else class="divide-y divide-eonx-neutral-300">
          <li
            v-for="(item, idx) in options"
            :key="'suburb-' + idx"
            :tabindex="0"
            class="cursor-pointer truncate hover:bg-primary-lighten"
            @click="onSelectItem(item)"
          >
            <slot
              v-if="$scopedSlots.option"
              name="option"
              :option="item"
              :idx="idx"
            ></slot>
            <div v-else class="py-2 px-3.5">
              {{ item.label }}
            </div>
          </li>
        </ul>
        <slot name="afterOptions" />
      </div>
    </transition>
  </div>
</template>
