<script>
import SiderbarTopBanner from './top-banner.vue'
import SiderbarBottomBanner from './bottom-banner.vue'

export default {
  name: 'rec-sidebar-banners-root',
  components: {
    SiderbarTopBanner,
    SiderbarBottomBanner,
  },
}
</script>

<template>
  <div
    class="mx-auto w-full max-w-2xl shrink-0 overflow-y-auto px-6 md:max-w-[376px]"
  >
    <siderbar-top-banner />
    <siderbar-bottom-banner class="my-5" />
  </div>
</template>
