<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useUserEvents } from '/~/composables/user'

export default {
  name: 'special-terms-modal',
  components: {
    BaseMdl,
  },
  props: {
    terms: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { termsAndConditionsVisited } = useUserEvents()

    termsAndConditionsVisited({ type: 'modal', name: 'special terms modal' })
  },
}
</script>

<template>
  <base-mdl width="sm" title="Special Terms">
    <div v-html="terms" />
  </base-mdl>
</template>
