export class RecroomUser {
  constructor(raw) {
    this.raw = raw || {}
  }

  get id() {
    return this.raw.id
  }

  get uuid() {
    return this.id
  }

  get orgMemberId() {
    return this.raw.org_member_id
  }

  get avatarUrl() {
    return this.raw.avatar_url
  }

  get email() {
    return this.raw.email
  }

  get firstName() {
    return this.raw.first_name || ''
  }

  get lastName() {
    return this.raw.last_name || ''
  }

  get role() {
    return this.raw.role
  }

  get status() {
    return this.raw.status
  }

  get isAdmin() {
    return this.raw.is_admin || this.raw.roles.some((v) => /rr:admin/.test(v))
  }

  get isMeSuperAdmin() {
    return this.raw.roles.some((v) => /rr:super_admin|rr:eonx_admin/.test(v))
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get isLimitedUser() {
    return this.raw.roles.some((v) => /rr:user_limited/.test(v))
  }

  get initials() {
    const first = this.firstName.charAt(0)
    const last = this.lastName.charAt(0) || this.firstName.charAt(1)

    return `${first}${last}`
  }

  get displayPointsPopup() {
    return this.raw.display_points_popup
  }

  set displayPointsPopup(value) {
    this.raw.display_points_popup = value
  }
}
