<script setup lang="ts">
import ui from '/~/core/ui'
import { useVerifications } from '/~/extensions/otp/composables'
import HomeDesk from './home.desk.vue'
import HomeMobile from './home.mobile.vue'

const props = withDefaults(
  defineProps<{
    hasUnfinishedVerifications?: string
  }>(),
  {
    hasUnfinishedVerifications: undefined,
  }
)

const { showVerificationModal } = useVerifications()

if (props.hasUnfinishedVerifications) {
  showVerificationModal()
}
</script>

<template>
  <home-mobile v-if="ui.mobile" />
  <home-desk v-else />
</template>
