<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { OnlineOffersAlgoliaHitPayload } from '/~/types/api/algolia/online-offers'
import ui from '/~/core/ui'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import cdn, { getProviderDefaultUrl } from '/~/utils/cdn'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EwDetailsHeader from '/~/components/ewallet/ew-details-header.vue'
import { useCms } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'

const props = defineProps<{
  slug: string
  offer?: any
  merchant?: OnlineOffersAlgoliaHitPayload
}>()

const router = useRouter()
const { getOutboundLink, isCashbackReturn, isPointsReturn } = useOnlineOffers()
const { isBupaTemplate } = useProvider()
const { ewalletLabels } = useCms()

const loading = ref(true)
const outboundLink = ref(null)

const name = computed(() => props.merchant?.name ?? '')
const logo = computed(() => ({
  src: props.merchant?.logo && cdn(props.merchant?.logo).resize('400x').url(),
  alt: props.merchant?.name,
}))
const defaultImage = computed(() =>
  isBupaTemplate.value ? getProviderDefaultUrl('bupa') : undefined
)

const externalId = computed(() => props.merchant?.external_id ?? '')
const feedMerchantId = computed(() => props.offer?.feed_merchant_id)
const feedOfferId = computed(() => props.offer?.feed_offer_id)

function redirectToOffer() {
  router.push({
    name: 'online-offers-retailer',
    params: { slug: props.slug },
  })
}

if (!props.merchant) {
  redirectToOffer()
} else {
  getOutboundLink({
    merchantId: feedMerchantId.value || externalId.value,
    offerId: feedOfferId.value,
  })
    .then((link) => {
      if (link) {
        outboundLink.value = link
      } else {
        redirectToOffer()
      }
    })
    .finally(() => {
      loading.value = false
    })
}
</script>

<template>
  <div class="h-full p-5 pb-11 sm:p-[30px] sm:pt-5">
    <base-back v-if="!ui.mobile" />

    <ew-details-header :title="name" :loading="loading" />

    <div class="m-auto space-y-[30px] text-center">
      <div class="mx-auto my-11 max-h-40 max-w-40 shrink-0">
        <base-image
          :src="logo.src"
          :alt="logo.alt"
          :default-src="defaultImage"
          fit="contain"
        />
      </div>
      <h2 class="mb-5 text-2xl font-bold sm:text-3xl">
        You will now be redirected to retailer's website
      </h2>
      <p class="mx-auto w-xse max-w-full">
        <template v-if="isCashbackReturn">
          By clicking below, your transaction will be tracked in order to
          facilitate {{ ewalletLabels.yourEwallet }} Cashback which may take up
          to 120 days.
        </template>
        <template v-else-if="isPointsReturn">
          By clicking below, your transaction will be tracked in order to
          facilitate your points offer which may take up to 120 days.
        </template>
      </p>
      <p class="mx-auto w-xse max-w-full text-sm text-eonx-neutral-600">
        Do not close this browser session. If you close this browser session
        prior to making a purchase with the retailer, you will then need to
        return to this page to activate the connection.
      </p>

      <div class="flex items-center justify-center">
        <base-loader v-if="loading" />
        <base-link
          v-else-if="outboundLink"
          class="relative flex cursor-pointer items-center rounded-md bg-primary px-6 py-[15px] text-sm font-bold text-white hover:opacity-60"
          :href="outboundLink"
          :ignore-local-warning="true"
        >
          Go to website
        </base-link>
      </div>
    </div>
  </div>
</template>
