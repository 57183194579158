import { computed, ref } from 'vue'
import api from '/~/core/api'
import { useUser } from '/~/composables/user'

interface EmptyServicesOption {
  text: string
  value: null
}

export interface ServiceItem {
  text: string
  value: string
  group: string
  icon: string
}

interface ServicesGroup {
  label: ServiceItem['group']
  icon: ServiceItem['icon']
  services: Array<ServiceItem>
}

const selectedServiceValue = ref<ServiceItem['value'] | null>(null)
const services = ref<Array<EmptyServicesOption | ServiceItem>>([
  {
    text: 'Select a service',
    value: null,
  },
  {
    text: 'Electricity & Gas',
    value: 'electricity-and-gas',
    group: 'Energy and Gas',
    icon: 'plain/lightbulb',
  },
  {
    text: 'Electricity only',
    value: 'electricity-only',
    group: 'Energy and Gas',
    icon: 'plain/lightbulb',
  },
  {
    text: 'Gas only',
    value: 'gas-only',
    group: 'Energy and Gas',
    icon: 'plain/lightbulb',
  },

  {
    text: 'ADSL2+',
    value: 'adsl-2',
    group: 'Telecoms',
    icon: 'plain/wifi',
  },
  {
    text: 'Landline',
    value: 'landline',
    group: 'Telecoms',
    icon: 'plain/wifi',
  },
  {
    text: 'Mobile',
    value: 'mobile',
    group: 'Telecoms',
    icon: 'plain/wifi',
  },
  {
    text: 'Mobile Broadband',
    value: 'mobile-broadband',
    group: 'Telecoms',
    icon: 'plain/wifi',
  },
  {
    text: 'Home Loan',
    value: 'home-loan',
    group: 'Money',
    icon: 'plain/moneybag',
  },
  {
    text: 'Personal Loan',
    value: 'personal-loan',
    group: 'Money',
    icon: 'plain/moneybag',
  },
  {
    text: 'Vehicle',
    value: 'vehicle',
    group: 'Money',
    icon: 'plain/moneybag',
  },
  {
    text: 'Equipment',
    value: 'equipment',
    group: 'Money',
    icon: 'plain/moneybag',
  },

  {
    text: 'Health',
    value: 'health',
    group: 'Insurance',
    icon: 'plain/checklist',
  },
  {
    text: 'Home & Contents',
    value: 'home-and-contents',
    group: 'Insurance',
    icon: 'plain/checklist',
  },
  {
    text: 'Landlord',
    value: 'landlord',
    group: 'Insurance',
    icon: 'plain/checklist',
  },
  {
    text: 'Business',
    value: 'business',
    group: 'Insurance',
    icon: 'plain/checklist',
  },
])

export function useServices() {
  const selectedService = computed(() => {
    return services.value.find(
      (service) => service.value === selectedServiceValue.value
    )
  })

  const servicesByGroups = computed(() => {
    const groups: Record<ServiceItem['group'], ServicesGroup> = {}

    services.value.forEach((service) => {
      if (service.value === null) return

      const { group } = service
      const target = groups[group]

      if (!target) {
        groups[group] = {
          label: group,
          icon: service.icon,
          services: [],
        }
      }

      groups[group].services.push(service)
    })

    return groups
  })

  function selectService(service: ServiceItem['value']) {
    selectedServiceValue.value = service
  }

  function clearState() {
    selectedServiceValue.value = null
  }

  function sendLead(data: { contact_time: string; mobile: string }) {
    if (!selectedService.value) return
    if (!('group' in selectedService.value)) return

    const { text, group } = selectedService.value
    const { user } = useUser()

    return api
      .post('/services/lead', {
        contact_time: data.contact_time,
        phone: data.mobile,
        product: text,
        service_name: group,
        user_id: user.value.externalId,
      })
      .then(() => {
        clearState()
      })
  }

  return {
    services,
    selectedServiceValue,
    selectedService,
    servicesByGroups,
    clearState,
    selectService,
    sendLead,
  }
}
