<script>
import DrawerPhotoIdDetails from '/~/drawer/photo-ids/photo-id-details.vue'

export default {
  name: 'profile-photo-id-details',
  components: {
    DrawerPhotoIdDetails,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    toMain() {
      this.$router.replace({ hash: '#profile-photo-ids' })
    },
  },
}
</script>

<template>
  <drawer-photo-id-details :id="id" @empty="toMain" />
</template>
