<script>
import emitter from '/~/core/emitter'
import BaseBox from '/~/components/base/box/base-box.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useAddresses } from '/~/composables/addresses'
import { useCart } from '/~/composables/cart'
import { useQuickBuyCheckout } from '/~/composables/checkout'
import AddressItem from './quick-buy-address-item.vue'

export default {
  name: 'quick-buy-addresses',
  components: {
    AddressItem,
    BaseCheckbox,
    BaseIcon,
    BaseBox,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { addCartOrderAddress, cartOrderAddresses } = useCart()
    const { payment } = useQuickBuyCheckout()
    const { addresses, defaultAddress, firstAddress } = useAddresses()

    return {
      addCartOrderAddress,
      cartOrderAddresses,
      payment,
      addresses,
      defaultAddress,
      firstAddress,
    }
  },
  data: () => ({
    differentAddress: false,
  }),
  computed: {
    hasAddresses() {
      return Boolean(this.addresses && this.addresses.length)
    },
    isDelivery() {
      return this.payment.hasDeliverableItems && this.hasAddresses
    },
    billingAddress() {
      const {
        addresses = [],
        cartOrderAddresses: { billing },
      } = this

      return addresses.find((address) => address.id === billing?.id)
    },
    shippingAddress() {
      const {
        addresses = [],
        cartOrderAddresses: { shipping },
      } = this

      return addresses.find((address) => address.id === shipping?.id)
    },
  },
  watch: {
    isLoading() {
      if (!this.isLoading) {
        this.initAddresses()
      }
    },
    differentAddress(val) {
      if (!val) {
        this.addCartOrderAddress({
          address: this.cartOrderAddresses.billing,
          type: 'shipping',
        })
      }
    },
  },
  created() {
    emitter.on('addresses:add', this.onAddressAdd)
  },
  mounted() {
    this.initAddresses()
  },
  beforeDestroy() {
    emitter.off('addresses:add', this.onAddressAdd)
  },
  methods: {
    async initAddresses() {
      if (!this.isLoading) {
        await this.setDefaultAddress()
        this.checkDifferentAddress()
      }
    },
    onAddressAdd() {
      this.$router.replace({
        name: 'quick-buy-checkout',
        query: this.$router.currentRoute.query,
      })
      this.initAddresses()
    },
    checkDifferentAddress() {
      const { billing, shipping } = this.cartOrderAddresses

      if (shipping && billing?.id !== shipping.id) {
        this.differentAddress = true
      }
    },
    setDefaultAddress() {
      const fallbackAddress = this.defaultAddress || this.firstAddress

      if (fallbackAddress) {
        if (!this.cartOrderAddresses.billing) {
          this.addCartOrderAddress({
            address: fallbackAddress,
            type: 'billing',
          })
        }

        if (this.isDelivery && !this.cartOrderAddresses.shipping) {
          this.addCartOrderAddress({
            address: fallbackAddress,
            type: 'shipping',
          })
        }
      }
    },
  },
}
</script>

<template>
  <div>
    <base-box title="Billing" new-look>
      <address-item
        :loading="isLoading"
        :item="billingAddress"
        @click="
          $router.push({
            name: 'quick-buy-billing',
            query: $router.currentRoute.query,
          })
        "
      >
        <template #menu>
          <base-icon svg="plain/chevron-right" size="sm" />
        </template>
      </address-item>
    </base-box>

    <template v-if="!isLoading && isDelivery">
      <div class="mt-5">
        <base-checkbox v-model="differentAddress" look="v2">
          Ship to different address
        </base-checkbox>
      </div>

      <base-box
        v-if="differentAddress && shippingAddress"
        title="Shipping"
        class="mt-[15px]"
        new-look
      >
        <address-item
          :loading="isLoading"
          :item="shippingAddress"
          @click="
            $router.push({
              name: 'quick-buy-shipping',
              query: $router.currentRoute.query,
            })
          "
        >
          <template #menu>
            <base-icon svg="plain/chevron-right" size="sm" />
          </template>
        </address-item>
      </base-box>
    </template>
  </div>
</template>
