<script setup lang="ts">
import CheckoutSummaryItem from '/~/components/checkout/summary/checkout-summary-item.v3.vue'
import { useCart } from '/~/composables/cart'

const { cartItemsFiltered } = useCart()
</script>

<template>
  <div v-if="cartItemsFiltered.length > 0" class="space-y-4 sm:space-y-6">
    <div
      v-for="(item, index) in cartItemsFiltered"
      :key="index"
      class="w-full"
      @click="$emit('item', item)"
    >
      <checkout-summary-item v-if="item" :item="item" />
    </div>
  </div>
</template>
