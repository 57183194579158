<script>
import isEqual from 'lodash-es/isEqual'
import { ValidationObserver } from 'vee-validate'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import { useAddressForm, useAddresses } from '/~/composables/addresses'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

const defaultData = {
  companyName: '',
  default: false,
  email: '',
  firstName: '',
  id: -1,
  lastName: '',
  mobile: '',
  postcode: '',
  state: '',
  streetAddress: '',
  subpremise: '',
  suburb: '',
}

export default {
  name: 'cart-address-form',
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseAsidePage,
    // BaseSelectAsync,
    BaseSelect,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    close: {
      type: Function,
      default: null,
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { addCartOrderAddress } = useCart()
    const { isQuickBuyCurrentFlowType } = useCheckout()
    const { addresses, addAddress, updateAddress } = useAddresses()
    const {
      countriesOptions,
      postcodeBindings,
      stateBindings,
      suburbBindings,
    } = useAddressForm()
    const { user } = useUser()
    const { isBillPaymentsTemplate } = useProvider()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { states, translate } = useLocalization()
    const { validationObserverRef } = useForm()

    return {
      addCartOrderAddress,
      isQuickBuyCurrentFlowType,
      addresses,
      addAddress,
      updateAddress,
      user,
      isBillPaymentsTemplate,
      countriesOptions,
      postcodeBindings,
      stateBindings,
      suburbBindings,
      ui,
      backendErrors,
      processBackendErrors,
      states,
      validationObserverRef,
      translate,
    }
  },
  data() {
    return {
      form: {
        ...defaultData,
      },
      disabledSetDefault: false,
      submitting: false,
      initialData: {},
      buttonShadow: false,
    }
  },
  computed: {
    address() {
      return (
        (this.addresses || []).find(
          (address) => `${address.id}` === this.id
        ) || {
          ...defaultData,
        }
      )
    },
    type() {
      return this.id && this.address.id !== -1 ? 'edit' : 'create'
    },
    stateOptions() {
      return this.states.map((state) => ({
        text: state.label,
        value: state.label,
      }))
    },
    selectedStateId() {
      const state = this.states.find((state) => {
        return state.label === this.form.state
      })

      return state ? state.id : ''
    },
    disableSaveBtn() {
      return (
        this.submitting ||
        (this.type === 'edit' && isEqual(this.initialData, this.form))
      )
    },
  },
  created() {
    const { email, firstName, lastName, mobile } = this.user
    const userDetails =
      this.type === 'create' ? { email, firstName, lastName, mobile } : {}

    const form = {
      ...this.form,
      ...this.address,
      ...userDetails,
    }

    /*
     * State selector based on label, because we need to fill values from autocomplete
     */
    if (form.state) {
      const dictState = this.states.find((state) => {
        return state.id === form.state
      })

      form.state = dictState ? dictState.label : form.state
    }

    this.form = form
    this.disabledSetDefault = this.type === 'edit' && form.default

    this.initialData = { ...form }
  },
  mounted() {
    this.checkShadow()
  },
  methods: {
    async onSubmitForm() {
      this.submitting = true
      this.backendErrors = {}

      const isCreate = this.type === 'create'
      const action = isCreate ? this.addAddress : this.updateAddress
      const params = {
        ...this.form,
        mobile: (this.form.mobile || '')
          .replace(/\s/g, '')
          .replace(/^\+61/g, '0'),
        state: this.selectedStateId || this.form.state,
      }

      return action(params)
        .then((response) => {
          this.submitting = false

          this.$notify({
            text: `Address successfully ${isCreate ? 'created' : 'updated'}`,
            type: 'success',
            duration: 1000,
          })

          if (isCreate) {
            this.addCartOrderAddress({
              address: response,
              type: this.meta.type,
            })
            if (this.isQuickBuyCurrentFlowType) {
              this.$router.push({
                name: 'quick-buy-checkout',
                query: this.$route.query,
              })
            } else {
              if (this.isBillPaymentsTemplate) {
                this.$router.replace({ name: 'purchase-checkout' })
              } else {
                this.$router.replace({ hash: '#cart-checkout' })
              }
            }
          } else {
            this.$router.back()
          }
        })
        .catch((exception) => {
          this.submitting = false
          this.processBackendErrors({
            errors: exception,
          })
        })
    },
    checkShadow() {
      const { form } = this.$refs

      if (!form) {
        return false
      }

      const scrollAmount = form.scrollHeight - form.offsetHeight

      if (scrollAmount && scrollAmount !== form.scrollTop) {
        this.buttonShadow = true
      } else {
        this.buttonShadow = false
      }
    },
  },
}
</script>

<template>
  <base-aside-page
    :title="type === 'create' ? 'Add new address' : 'Edit address'"
  >
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="relative flex grow flex-col"
        @submit.prevent="handleSubmit(onSubmitForm)"
      >
        <div ref="form" @scroll="checkShadow">
          <base-input
            v-model="form.state"
            label="State"
            class="hidden"
            required
          />
          <base-input
            v-model="form.companyName"
            :error="backendErrors.companyName"
            label="Company name (if applicable)"
            name="companyName"
            :disabled="submitting"
          />
          <div class="flex">
            <base-input
              v-model="form.firstName"
              :validation="{
                rules: 'required',
                name: 'First name',
              }"
              :disabled="submitting"
              :error="backendErrors.firstName"
              label="First name"
              name="firstName"
              required
              class="mr-[5px]"
            />
            <base-input
              v-model="form.lastName"
              :validation="{
                rules: 'required',
                name: 'Last name',
              }"
              :disabled="submitting"
              :error="backendErrors.lastName"
              label="Last name"
              name="lastName"
              required
              class="ml-[5px]"
            />
          </div>
          <base-input
            ref="email"
            v-model="form.email"
            :validation="{
              rules: 'required|email',
              name: 'Email',
            }"
            :disabled="submitting"
            :error="backendErrors.email"
            data-vv-as="Email"
            label="Email"
            name="email"
            type="email"
            required
          />
          <base-input
            v-model="form.mobile"
            :validation="{
              rules: 'required|mobile',
              name: 'Mobile',
            }"
            :disabled="submitting"
            :error="backendErrors.mobile"
            type="tel"
            mask="mobile"
            label="Mobile"
            required
            name="mobile"
          />
          <div>
            <base-input
              v-model="form.streetAddress"
              :validation="{
                rules: 'required',
                name: 'Street address',
              }"
              :disabled="submitting"
              :error="backendErrors.streetAddress"
              label="Street address"
              required
              name="streetAddress"
              :maxlength="100"
            />

            <base-input
              v-model="form.suburb"
              v-bind="suburbBindings"
              :disabled="submitting"
              :error="backendErrors.suburb"
              required
              name="suburb"
              :validation="{
                ...suburbBindings.validation,
                rules: 'required',
              }"
            />

            <div class="flex items-start gap-x-4">
              <base-input
                v-model="form.postcode"
                v-bind="postcodeBindings"
                :disabled="submitting"
                :error="backendErrors.postcode"
                required
                name="postcode"
                :label="translate('address.postcode')"
                class="w-1/2"
                :validation="{
                  ...postcodeBindings.validation,
                  rules: 'required|postcode',
                }"
              />

              <base-select
                v-model="form.state"
                v-bind="stateBindings"
                :disabled="submitting"
                :error="backendErrors.state"
                :options="stateOptions"
                name="state"
                :validation="{
                  ...stateBindings.validation,
                }"
              />
            </div>
          </div>
          <base-checkbox
            v-model="form.default"
            :disabled="submitting || disabledSetDefault"
            class="p-0"
          >
            Set as default
          </base-checkbox>
        </div>

        <div class="relative mt-auto shrink-0 pt-[30px]">
          <base-button
            type="submit"
            :disabled="submitting || disableSaveBtn"
            full-width
          >
            {{ submitting ? 'Saving...' : 'Save' }}
          </base-button>
        </div>
      </form>
    </validation-observer>
  </base-aside-page>
</template>
