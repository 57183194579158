<script>
import UsersList from '/~rec/components/users/users-list.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseBack from '/~/components/base/back/base-back.vue'
import debounce from 'lodash-es/debounce'

export default {
  name: 'members-manager',
  components: {
    BaseBack,
    BaseInput,
    UsersList,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    currentUsers: {
      type: Array,
      default: () => [],
    },
    multi: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    removeHandler: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      searchValue: '',
    }
  },
  computed: {
    backRoute() {
      return {}
    },
    usersMap() {
      return this.currentUsers.map((u) => u.id)
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onSearchInput: debounce(function (searchValue) {
      this.searchValue = searchValue
    }, 150),
    onUserSelect(users) {
      this.$emit('users-select', users)
    },
    filterUsers(allUsers) {
      return allUsers.filter((user) => this.usersMap.includes(user.id))
    },
  },
}
</script>

<template>
  <div class="mx-auto flex w-full max-w-screen-md flex-col px-[30px] pb-[30px]">
    <div @click="close">
      <base-back class="mb-6 flex items-center" :to="{}" />
    </div>

    <h2 class="text-eonx-neutral-800">
      {{ label }}
    </h2>

    <div class="text-base text-eonx-neutral-600">
      <span>
        {{ currentUsers.length + ' members in' }}
      </span>
      <span class="cursor-pointer break-words text-primary" @click="close">
        {{ name }}
      </span>
    </div>

    <div class="flex items-center">
      <base-input
        :value="searchValue"
        style="padding: 0.5rem 0 !important; margin: 0 !important"
        look="rounded"
        icon="plain/search"
        placeholder="Search member"
        icon-plain
        clearable
        @input="onSearchInput"
      />
    </div>

    <users-list
      :current-users="currentUsers"
      :multi="multi"
      :filter-users="filterUsers"
      :search-value="searchValue"
      :removable="removable"
      :selectable="selectable"
      :remove-handler="removeHandler"
      exclude-me
      @user-select="onUserSelect"
    >
      <template #user="{ user }">
        <slot :user="user" :index="user.id" name="user" />
      </template>
    </users-list>
  </div>
</template>
