<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useUserEvents } from '/~/composables/user'

export default {
  name: 'offer-terms-modal',
  components: {
    BaseMdl,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { termsAndConditionsVisited } = useUserEvents()

    termsAndConditionsVisited({
      type: 'modal',
      name: 'offer-terms modal',
      offer: props.offer,
    })
  },
}
</script>

<template>
  <base-mdl width="sm" title="Offer Terms">
    <div v-html="offer.terms" />
  </base-mdl>
</template>
