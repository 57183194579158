<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useUser, useUserEvents } from '/~/composables/user'
import TermsConditions from '/~/views/terms/terms-conditions.vue'

export default {
  name: 'terms-conditions-modal',
  components: {
    TermsConditions,
    BaseMdl,
    BaseButton,
  },
  props: {
    forceConfirmation: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { updateTermsAcceptance } = useUser()
    const { termsAndConditionsVisited } = useUserEvents()

    termsAndConditionsVisited({ type: 'modal' })
    return {
      updateTermsAcceptance,
    }
  },
  data() {
    return {
      confirming: false,
    }
  },
  methods: {
    async confirmTerms() {
      this.confirming = true

      try {
        await this.updateTermsAcceptance(true)
        modal.hide()
      } catch (error) {
        this.$notify({
          text: error.message ?? 'Something went wrong, please try again',
          type: 'error',
        })
      } finally {
        this.confirming = false
      }
    },
    close() {
      modal.hide()
      this.$emit('hide')
    },
  },
}
</script>

<template>
  <base-mdl
    width="lg"
    title="Terms and Conditions"
    fullscreen="mobile"
    height="lg"
    :closable="!forceConfirmation"
    layout="v2"
  >
    <terms-conditions />

    <template #footer>
      <div v-if="forceConfirmation" class="flex justify-center border-t py-4">
        <base-button :loading="confirming" @click="confirmTerms">
          Accept
        </base-button>
      </div>
    </template>
  </base-mdl>
</template>
