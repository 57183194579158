<script>
import WidgetImage from '../widget-image.vue'

export default {
  name: 'widget-slide',
  components: {
    WidgetImage,
  },
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    image: {
      type: Object,
      required: true,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <div class="swiper-slide">
    <widget-image :image="image" :link="link || {}" :content="content" />
  </div>
</template>
