<script>
import { nextTick } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import CartItem from '/~/components/cart-menu/cart-item.v2.vue'
import CartHomeV1 from '/~/components/cart-menu/views/home/cart-home.vue'
import { useCart } from '/~/composables/cart'
import { usePurchaseCheckout } from '/~/composables/checkout'

export default {
  name: 'cart-menu-home-v2',
  components: {
    CartItem,
  },
  extends: CartHomeV1,
  setup() {
    const { isCartLoading } = useCart()
    const { cartAmount } = usePurchaseCheckout()

    return {
      ...CartHomeV1.setup?.(...arguments),
      isCartLoading,
      cartAmount,
      formatDollar,
    }
  },
  mounted() {
    nextTick(() => {
      this.$refs.closeButton.$el.focus()
    })
  },
}
</script>

<template>
  <div class="flex h-full w-full flex-col bg-white" @keyup.esc="close">
    <div
      :class="[
        'flex h-full w-full flex-shrink-0 flex-grow flex-col',
        isStandalone && 'footer-extra',
      ]"
    >
      <div class="px-5 py-6">
        <div class="flex items-center text-eonx-neutral-800">
          <div class="flex flex-grow items-center truncate">
            <h2 class="mr-2.5 text-xl leading-7 sm:leading-6">
              Shopping Cart {{ cartQuantity > 0 ? `(${cartQuantity})` : null }}
            </h2>
          </div>
          <base-button
            ref="closeButton"
            icon="base/close"
            :size="28"
            :padding="5"
            alt="close"
            title="Shopping Cart"
            @click="close"
          />
        </div>
      </div>

      <div
        v-if="cartIsEmpty"
        class="flex flex-1 flex-col items-center justify-center px-5 text-center"
      >
        <div
          class="flex w-full max-w-64 items-center rounded-md border border-zinc-100 py-4 pl-5 pr-[35px]"
        >
          <div
            class="mr-2.5 flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-primary text-white"
          >
            <base-icon svg="v2/custom/cart" :size="22" />
          </div>
          <div class="flex-grow">
            <div class="h-[15px] w-1/2 rounded bg-gray-300" />
            <div class="mt-2.5 h-[15px] w-full rounded bg-eonx-neutral-50" />
          </div>
        </div>
        <div class="mt-14 text-xl font-bold">Your cart is currently empty</div>
      </div>
      <div v-else class="h-full space-y-4 overflow-y-auto px-5 pt-[5px]">
        <cart-item
          v-for="(item, idx) in cartItemsFiltered"
          :key="idx"
          :item="item"
          data-test="cart-item"
          :data-test-id="item.rowId"
        />
      </div>
      <div v-if="!cartIsEmpty" class="left-0 mt-auto w-full px-5 py-5">
        <base-button
          :disabled="isCartLoading"
          full-width
          @click="withVerificationsCheck(onCheckout)"
        >
          <span class="flex items-center">
            <span class="text-sm">Checkout</span>
            <span class="ml-2.5 text-xl">
              {{ formatDollar(cartAmount) }}
            </span>
          </span>
        </base-button>
      </div>
    </div>
  </div>
</template>
