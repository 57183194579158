<script setup lang="ts">
import { computed } from 'vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import ConfirmationAddressesItem from './confirmation-addresses-item.vue'

const { payment } = useCheckoutReactive()

const billingAddress = computed(() => {
  return payment.value?.confirmation?.order?.billingAddress
})
const shippingAddress = computed(() => {
  return payment.value?.confirmation?.order?.shippingAddress
})
</script>

<template>
  <div>
    <confirmation-addresses-item
      v-if="billingAddress"
      :address="billingAddress"
      title="Billing"
    />
    <confirmation-addresses-item
      v-if="shippingAddress"
      :address="shippingAddress"
      title="Shipping"
      class="mt-4"
    />
  </div>
</template>
