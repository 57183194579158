<script>
export default {
  name: 'rec-message-entry-image',
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    hiddenAttachmentsCount: {
      type: Number,
      default: 0,
    },
    dimension: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['click'],
  computed: {
    hasMoreAttachments() {
      return this.hiddenAttachmentsCount > 0
    },
  },
}
</script>

<template>
  <div class="relative flex" @click="$emit('click', $event)">
    <span
      v-if="hasMoreAttachments"
      class="text absolute inset-0 z-10 flex items-center justify-center bg-overlay text-3xl font-bold text-white"
    >
      +{{ hiddenAttachmentsCount }}
    </span>
    <img
      class="w-full object-cover"
      :src="`${attachment.cdn}-/scale_crop/${dimension['normal']}/center/`"
      :srcset="`${attachment.cdn}-/scale_crop/${dimension['normal']}/center/ 1x, ${attachment.cdn}-/scale_crop/${dimension['retina']}/center/ 2x`"
    />
  </div>
</template>
