<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import NabVerificationForm from '../form/nab-verification-form.vue'

export default {
  name: 'nab-verification-mdl',
  components: {
    BaseMdl,
    NabVerificationForm,
  },
  methods: {
    onComplete(data) {
      modal.hide('nab-verification')
      this.$emit('done', data)
    },
    onFailed(data) {
      modal.hide('nab-verification')
      this.$emit('fail', data)
    },
    onCancel() {
      modal.show('nab-verification-cancel', {
        on: {
          submit: this.closeModal,
        },
      })
    },
    closeModal() {
      this.$emit('cancel')
      modal.hide('nab-verification')
    },
  },
}
</script>

<template>
  <base-mdl width="sm" :closable="false" :layout="false" fullscreen="mobile">
    <div class="px-[30px] pt-6 text-center text-2xl font-bold text-primary">
      Processing
    </div>

    <div class="my-5 text-center text-sm">
      May take up to 30 seconds. Please wait.
    </div>

    <nab-verification-form
      v-bind="$attrs"
      @done="onComplete"
      @fail="onFailed"
      @cancel="onCancel"
    />
  </base-mdl>
</template>
