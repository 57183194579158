<script>
import debounce from 'lodash-es/debounce'
import omitBy from 'lodash-es/omitBy'
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import FeedControls from '/~/extensions/health/components/feed/feed-controls.vue'
import FeedView from '/~/extensions/health/components/feed/feed-view.vue'
import FeedTile from '/~/extensions/health/components/feed/tile/feed-tile'
import Tabs from '/~/extensions/health/components/tabs.vue'
import { useServices } from '/~/extensions/health/composables'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { useEditablePage } from '/~/composables/cms'

export default {
  name: 'health-professional-net-work-feed',
  components: {
    FeedView,
    FeedControls,
    EwCatalogProcessor,
    FeedTile,
    Tabs,
    BaseLoader,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { feed, saved, indexes, queryState, initServices } = useServices()
    const { page } = useEditablePage()

    const feedControlsElement = ref({})
    const loading = ref(true)
    const searching = ref(false)

    const tabs = ref([
      {
        label: 'Providers',
        route: { path: 'professional-network', query: { type: 'services' } },
      },
      {
        label: 'Saved Providers',
        route: { path: 'professional-network', query: { type: 'saved' } },
      },
    ])

    const type = computed(() => route.query?.type ?? 'services')
    const sortOptions = computed(() =>
      indexes.value.map((data) => {
        return { text: data.label, value: data.index_id }
      })
    )

    const sort = ref(route.query?.sort ?? sortOptions.value[0]?.value)
    const search = ref(route.query?.search ?? '')
    const params = computed(() => {
      return { search: search.value, sort: sort.value }
    })

    initServices().then(() => {
      loading.value = false
      feedControlsElement.value.search = search.value
      feed.value.setFilter({ query: search.value })
      if (sort.value) {
        feed.value.setIndex(sort.value)
      }
      fetchData()
    })

    async function fetchData() {
      if (feed.value) {
        feed.value.processing = true
        feed.value.setFilter({
          filters:
            type.value === 'saved'
              ? saved.value.map((id) => 'objectID:' + id).join(' OR ') ||
                'objectID:null'
              : '',
        })
        await feed.value.getData()
        feed.value.processing = false
        setRoute()
      }
    }

    const onSearch = debounce(function (query) {
      feed.value.setFilter({ query })
      search.value = query
      fetchData()
    }, 300)

    function onSearchInput(value) {
      if (search.value !== value) {
        searching.value = true
        onSearch(value)
      }
    }

    function onSort(value) {
      feed.value.setIndex(value)
      fetchData()
      sort.value = value
    }

    function onTileClick(tile) {
      router.push({
        name: 'hlth-professional-network-detail',
        params: {
          type: type.value,
          id: tile.id,
        },
      })
    }

    function setRoute() {
      const query = omitBy(
        { search: search.value, type: type.value, sort: sort.value },
        (v) => !v
      )

      router.replace({ query })
      queryState.value = query
    }

    function reset() {
      feedControlsElement.value.search = ''
      sort.value = sortOptions.value[0]?.value
      if (feed.value) {
        feed.value.reset()
        feed.value.setFilter({ query: search.value })
        feed.value.setIndex(sort.value)
        fetchData()
      }
    }

    watch(
      type,
      () => {
        reset()
      },
      { deep: true }
    )

    return {
      page,
      searching,
      loading,
      feedControlsElement,
      type,
      feed,
      saved,
      sortOptions,
      tabs,
      params,
      onSearchInput,
      onSort,
      onTileClick,
    }
  },
}
</script>

<template>
  <feed-view v-if="loading">
    <div class="flex w-full items-center justify-center p-32">
      <base-loader size="xl" />
    </div>
  </feed-view>
  <feed-view v-else>
    <template #hero>
      <tabs :tabs="tabs" :type="type" class="sm:hidden" />

      <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
    </template>

    <template #tabs>
      <tabs :tabs="tabs" :type="type" class="hidden sm:block" />
    </template>

    <template #controls>
      <feed-controls
        ref="feedControlsElement"
        without-category
        without-filters
        :sort-options="sortOptions"
        :params="params"
        placeholder="Search for a professional..."
        @input="onSearchInput"
        @sort-update="onSort"
      />
    </template>

    <ew-catalog-processor
      :processor="feed"
      :processing="searching"
      :columns="{
        lg: 2,
      }"
    >
      <template #tile="{ tile }">
        <feed-tile :tile="tile" type="service" @tile-click="onTileClick" />
      </template>
      <template #empty>
        <div class="h-64">No providers were found</div>
      </template>
    </ew-catalog-processor>
  </feed-view>
</template>
