<script>
import ui from '/~/core/ui'
import { useVerifications } from '/~/extensions/otp/composables'
import { pluralize } from '/~/utils/format/string'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import CartItem from '/~/components/cart-menu/cart-item.vue'
import CartHomeCheckout from '/~/components/cart-menu/views/home/cart-home-checkout.vue'
import { useCart } from '/~/composables/cart'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'

export default {
  name: 'cart-menu-home',
  components: {
    CartItem,
    CartHomeCheckout,
    BaseButton,
    BaseIcon,
    BaseImage,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const { cartIsEmpty, cartItemsFiltered, cartQuantity } = useCart()
    const { isStandalone } = useUI()
    const { isBillPaymentsTemplate } = useProvider()
    const { withVerificationsCheck } = useVerifications()

    return {
      cartIsEmpty,
      cartItemsFiltered,
      cartQuantity,
      isStandalone,
      isBillPaymentsTemplate,
      ui,
      withVerificationsCheck,
    }
  },
  computed: {
    itemsCount() {
      return `${this.cartQuantity} ${pluralize(this.cartQuantity, 'item')}`
    },
  },
  methods: {
    onCheckout() {
      if (ui.mobile && !this.isBillPaymentsTemplate) {
        this.$router.push({ hash: '#cart-checkout' })
      } else {
        this.$router.push({ name: 'purchase-checkout' })
        setTimeout(() => close, 100)
      }
    },
  },
}
</script>

<template>
  <div class="flex h-full w-full flex-col overflow-y-auto">
    <div
      :class="[
        'flex h-full w-full shrink-0 grow flex-col',
        isStandalone && 'footer-extra',
      ]"
    >
      <div class="bg-primary px-[15px] py-5">
        <div class="flex items-center text-white">
          <base-icon
            :size="ui.mobile ? 24 : 28"
            class="mr-[15px] text-white"
            svg="plain/cart"
          />
          <div class="flex grow items-center truncate">
            <h2 class="mr-2.5 text-xl leading-7 sm:leading-6">
              {{ ui.mobile ? 'Shopping Cart' : 'Cart' }}
            </h2>
            <span v-if="!cartIsEmpty" class="text-sm">
              {{ ui.mobile ? `(${itemsCount})` : itemsCount }}
            </span>
          </div>

          <base-button
            icon="base/close"
            :padding="5"
            alt="close"
            title="Shopping Cart"
            @click="close"
          />
        </div>
      </div>

      <div
        v-if="cartIsEmpty"
        class="flex flex-1 flex-col items-center justify-center px-10"
      >
        <base-image
          src="cart-empty-illustration.svg"
          class="mb-[15px] w-9/12"
          :rounded="false"
        />
        <h5 class="font-bold text-eonx-neutral-600">
          Your cart is currently empty
        </h5>
      </div>
      <div v-else class="h-full overflow-y-auto pt-[5px]">
        <cart-item
          v-for="(item, idx) in cartItemsFiltered"
          :key="idx"
          :item="item"
          data-test="cart-item"
          :data-test-id="item.rowId"
        />
      </div>
      <div v-if="!cartIsEmpty" class="left-0 mt-auto w-full px-5 py-5">
        <cart-home-checkout
          @checkout-button-clicked="withVerificationsCheck(onCheckout)"
        />
      </div>
    </div>
  </div>
</template>
