<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import animateCounter from '/~rec/utils/animate-counter'
import { formatPoints } from '/~/utils/points'
import { useRecProfile } from '/~rec/composables/profile'
import { useRecroom } from '/~rec/composables/recroom'

export default {
  name: 'app-header-recroom-points',
  components: {
    BaseIcon,
  },
  props: {
    showManagement: {
      type: Boolean,
      defaut: true,
    },
    showPersonal: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const {
      isMeAdmin,
      isMeSuperAdmin,
      currency,
      managementWalletBalance,
      features,
      profile,
    } = useRecProfile()
    const { initialized } = useRecroom()

    return {
      formatPoints,
      isMeAdmin,
      isMeSuperAdmin,
      currency,
      managementWalletBalance,
      features,
      profile,
      initialized,
      ui,
    }
  },
  computed: {
    managementPointsAreShown() {
      return (
        (this.isMeAdmin || this.isMeSuperAdmin) &&
        this.features.points &&
        this.showManagement
      )
    },
    personalPointsAreShown() {
      return this.features.points && this.showPersonal
    },
  },
  watch: {
    'profile.tokenBalance'(newVal, oldVal) {
      if (this.showPersonal) {
        this.updatePoints('rec-personal-points', oldVal, newVal)
      }
    },
    managementWalletBalance(newVal, oldVal) {
      if (this.showManagement) {
        this.updatePoints('rec-management-points', oldVal, newVal)
      }
    },
  },
  methods: {
    showTokenActivities() {
      const modalName = ui.mobile
        ? 'rec-token-transactions-mobile'
        : 'rec-token-transactions'

      modal.show(modalName, {
        props: {
          tokenBalance: this.profile.tokenBalance,
          currency: this.currency,
        },
      })
    },
    showManagementWallet() {
      modal.show('rec-user-info')
    },
    updatePoints(id, start, end) {
      if (Number.isInteger(start)) {
        animateCounter(id, start, end, 2500)
      }
    },
  },
}
</script>

<template>
  <div v-if="initialized" class="items-center md:gap-[30px] md:px-[30px]">
    <div
      v-if="managementPointsAreShown"
      class="inline-flex h-12 cursor-pointer items-center justify-center rounded-full bg-violet-100 px-6 py-[5px] font-bold"
      @click="showManagementWallet"
    >
      <div class="mr-2.5 text-violet-400">
        <base-icon svg="v2/custom/points-token" :size="ui.desktop ? 26 : 20" />
      </div>
      <span class="hidden text-xl font-bold uppercase md:block">
        Management
      </span>
      <div
        class="mx-2.5 hidden h-6 border border-solid border-violet-400 md:block"
      />
      <div>
        <span id="rec-management-points" class="text-xl font-bold">
          {{ formatPoints(managementWalletBalance || 0) }}
        </span>
        <span class="text-xs">PTS</span>
      </div>
    </div>
    <div
      v-if="personalPointsAreShown"
      class="group inline-flex h-12 cursor-pointer items-center justify-center rounded-full bg-eonx-neutral-50 px-6 font-bold hover:bg-primary hover:text-white"
      @click="showTokenActivities"
    >
      <div class="mr-2.5 text-primary group-hover:text-white">
        <base-icon svg="v2/custom/points-token" :size="ui.desktop ? 26 : 20" />
      </div>
      <div>
        <span id="rec-personal-points" class="text-xl font-bold">
          {{ formatPoints(profile.tokenBalance || 0) }}
        </span>
        <span class="text-sm">PTS</span>
      </div>
    </div>
  </div>
</template>
