<script>
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'

export default {
  name: 'payments-method-placeholder',
  components: {
    BasePlaceholder,
  },
}
</script>

<template>
  <div
    class="relative flex min-h-20 items-center justify-between rounded-lg bg-white p-[15px] text-default sm:px-6"
  >
    <div class="w-full">
      <div class="w-3.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
      <div class="w-1.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
    </div>
  </div>
</template>
