import { AlgoliaWorker } from '/~/core/algolia'
import HeathService from './service'

export default class HeathServiceProcessor extends AlgoliaWorker {
  constructor(params) {
    super({
      mapping: ({ payload, objectID, retailer_id }) =>
        new HeathService(payload, objectID, retailer_id),
      ...params,
    })
  }

  setIndex(index) {
    this.params.index = index
  }

  setFilter(filters) {
    this.params.search = {
      ...this.params.search,
      ...filters,
    }
  }
}
