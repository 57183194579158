<script>
import BaseBack from '/~/components/base/back/base-back.vue'
import { useEditablePage } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'
import CardFindOutMore from '/~/templates/bill-payments/components/card-find-out-more.vue'
import PointsTransferForm from '../components/points-transfer-form.vue'

export default {
  name: 'fly-points-transfer-desktop',
  components: {
    PointsTransferForm,
    BaseBack,
    CardFindOutMore,
  },
  setup() {
    const { providerTitle } = useProvider()
    const { page } = useEditablePage()

    return {
      page,
      providerTitle,
    }
  },
}
</script>

<template>
  <div class="flex min-h-full flex-col sm:flex-row">
    <div
      class="w-full grow rounded-b-3xl rounded-t-3xl bg-eonx-neutral-50 sm:w-2/3 sm:rounded-b-none"
    >
      <div
        class="w-full rounded-b-3xl rounded-t-3xl bg-eonx-neutral-50 px-12 py-8 sm:rounded-b-none"
      >
        <base-back
          :to="{
            name: 'fly-business',
            query: { type: $route.query.type },
          }"
          class="mb-8 mr-auto"
        />
        <div class="w-full">
          <div class="mx-auto max-w-xl text-2xl font-bold">
            Transfer my {{ providerTitle }} Points
          </div>

          <points-transfer-form v-bind="$attrs" class="mt-8" />
        </div>
      </div>
    </div>
    <div
      v-if="page && page.widgets.length > 0"
      class="w-max-96 hidden md:block"
      :class="{ 'px-[30px]': page && page.widgets }"
    >
      <widgets-builder
        :widgets="page.widgets"
        side="right"
        class="mb-10 !space-y-5"
      />
    </div>
    <div
      v-else
      class="my-10 flex w-full items-start justify-center space-x-5 px-2.5 sm:my-0 sm:ml-5 sm:w-1/3 sm:min-w-64 sm:max-w-64 sm:space-x-0 sm:px-0 lg:ml-10 lg:min-w-[352px] lg:max-w-[352px]"
    >
      <card-find-out-more
        class="mb-[30px] mr-5 flex min-h-56 flex-col items-start rounded-3xl pb-5 sm:mb-0 sm:min-h-[400px] sm:pt-5 sm:pl-5 sm:pr-5 lg:pt-10 lg:pb-10 lg:pl-10 lg:pr-10"
        :show-button="false"
      >
        <div class="text-xl font-bold leading-6 text-white">
          Redeem {{ providerTitle }} Points your way!
        </div>
        <div class="mt-[15px] leading-6 text-white">
          Use your {{ providerTitle }} Points to make personal Bill Payments or
          access rewards to spoil yourself or your loved ones.
        </div>
      </card-find-out-more>
    </div>
  </div>
</template>
