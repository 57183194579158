<script>
import { formatDate } from '/~/utils/format/date'
import ui from '/~/core/ui'

export default {
  name: 'rec-events-separator',
  props: {
    month: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      ui,
      formatDate,
    }
  },
}
</script>

<template>
  <div
    :class="{
      'pb-5 pt-0': ui.mobile,
      'py-2.5 px-2.5': !ui.mobile,
    }"
  >
    <div
      class="relative mx-auto flex w-full max-w-3xl items-center justify-center"
    >
      <div class="absolute top-1/2 z-1 h-px w-full bg-gray-400" />
      <hr class="absolute2" />
      <div class="z-10 bg-gray-50 px-10 text-center">
        <span class="text-base font-bold text-eonx-neutral-600">
          {{ formatDate('monthyear', month.date) }}
        </span>
      </div>
    </div>
  </div>
</template>
