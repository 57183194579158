<script>
import ui from '/~/core/ui'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import PointsPrograms from './points-programs.vue'
export default {
  name: 'init-tier-options-modal',
  components: {
    BaseMdl,
    PointsPrograms,
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>
<template>
  <base-mdl width="md" fullscreen="mobile">
    <points-programs :is-drawer="ui.mobile" />
  </base-mdl>
</template>
