<script>
import { formatDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import CheckoutSummaryItems from '/~/components/checkout/summary/checkout-summary-items.vue'
import CheckoutSummaryPayWith from '/~/components/checkout/summary/checkout-summary-pay-with.vue'
import CheckoutConfirmationStatus from '/~/components/checkout/summary/checkout-summary-status.vue'
import CheckoutSummaryTotal from '/~/components/checkout/summary/checkout-summary-total.vue'
import { useCms } from '/~/composables/cms'
import DefaultCheckoutComponent from '/~/views/checkout/views/confirmation/checkout-confirmation.vue'

export default {
  name: 'purchase-checkout-confirmation',
  components: {
    BaseButton,
    BaseLoader,
    CheckoutConfirmationStatus,
    CheckoutSummaryItems,
    CheckoutSummaryPayWith,
    CheckoutSummaryTotal,
  },
  extends: DefaultCheckoutComponent,
  setup() {
    const { ewalletLabels } = useCms()

    return {
      ...DefaultCheckoutComponent.setup?.(...arguments),
      formatDate,
      ewalletLabels,
    }
  },
  computed: {
    hasPhysicalCards() {
      return this.orderFilteredItems
        .filter((i) => i.type === 'giftcard')
        .some((item) => item.physical)
    },
  },
}
</script>

<template>
  <div class="flex min-h-full w-full">
    <div
      class="flex min-h-full w-full flex-col rounded-t-3xl bg-eonx-neutral-50 sm:mx-6 sm:pt-[30px]"
    >
      <div
        class="relative mx-auto flex h-full w-full max-w-lg flex-col bg-white sm:rounded-t-3xl sm:pt-[35px]"
      >
        <div class="bg-white px-10 pt-10 sm:pt-0">
          <base-loader v-if="!isReady" fullwidth />

          <div
            v-else-if="failed"
            class="py-[30px] text-center text-2xl font-bold"
          >
            Order information not found

            <div class="mt-[30px]">
              <base-button full-width @click="$router.push({ name: 'home' })">
                Back to Dashboard
              </base-button>
            </div>
          </div>

          <div v-else class="flex h-full grow flex-col">
            <checkout-confirmation-status :order="order" />

            <div class="mb-6 text-center">
              <div class="font-bold leading-relaxed text-eonx-neutral-600">
                Order No. #{{ orderId }}
              </div>
              <div
                class="mt-[5px] font-bold leading-relaxed text-eonx-neutral-600"
              >
                {{ formatDate('daymonthyeartime', order.placedAt) }}
              </div>
            </div>

            <div class="mb-6 space-y-6">
              <p v-if="hasDigital" class="w-full text-center text-sm leading-6">
                <template v-if="hasBankAccountMethod">
                  By selecting your bank account as the payment method, your
                  order will not be fulfilled until the funds have cleared in
                  our system.
                  <b>This can take up to 3 business days.</b>
                </template>
                <template v-else>
                  ePurchases will be delivered to your email and available in
                  {{ ewalletLabels.yourEwallet }} instantly, however on occasion
                  this may take up to 72 hours.
                </template>
              </p>

              <p
                v-if="hasPhysicalCards"
                class="w-full text-center text-sm leading-6"
              >
                Physical Gift Cards will be mailed to your delivery address
                within 5 business days.
              </p>

              <p v-if="hasESore" class="w-full text-center text-sm leading-6">
                eStore Products will be sent to your shipping address within 5
                to 15 business days subject to stock levels. If you have any
                questions about your order, contact us.
              </p>
            </div>

            <div class="border-b border-divider py-5">
              <div class="mb-2.5 font-bold text-eonx-neutral-600">Summary</div>
              <div class="divide-y">
                <checkout-summary-items :items="items" is-confirmation />
              </div>
            </div>
            <checkout-summary-pay-with
              :credit-card="creditCard"
              :points="points"
              :ewallet="ewallet"
              :bank-account="bankAccount"
              :coupons="coupons"
              class="pt-5"
            />
            <checkout-summary-total
              :transaction-fees="transactionFeeAmount"
              :shipping-fees="shippingFee"
              :amount="+order.subtotal"
              :total="+order.total"
              class="pt-5"
            />

            <div class="sticky bottom-0 -mx-10 mt-auto bg-white px-10 py-6">
              <div class="flex w-full space-x-6">
                <base-button
                  v-if="paid && hasDigital && !isGift"
                  class="flex-1"
                  look="outlined-color"
                  @click="$router.push(ePurchasesRoute)"
                >
                  <template v-if="singleDigital">
                    <template v-if="hasCinema">View eVoucher</template>
                    <template v-else>View eGift Card</template>
                  </template>
                  <template v-else>View ePurchases</template>
                </base-button>

                <base-button
                  class="flex-1"
                  @click="$router.push({ name: $route.meta.home })"
                >
                  Done
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
