export interface Redemption {
  available: number
  redeemed: number
}

export interface Retailer {
  id?: string
  name?: string
}

export interface OfferGroup {
  id: string
}

export interface RawOffer {
  id?: string
  name?: string
  status?: string
  couponCode?: string
  couponType?: string
  description?: string
  promotionUrl?: string
  redemptionImage?: string
  type?: string
  terms?: string
  redemption?: Redemption
  retailer?: Partial<Retailer>
  offerGroups?: Partial<OfferGroup>[]
  [key: string]: any // for any additional properties
}

export class CommunityHubOffer {
  raw: RawOffer

  id?: string
  name?: string
  status?: string
  couponCode?: string
  description?: string
  promotionUrl?: string
  redemptionImage?: string
  type?: string
  terms?: string
  redemption: Redemption | Record<string, never>
  retailer: Partial<Retailer>
  offerGroups: Partial<OfferGroup>[]

  constructor(raw: RawOffer = {}) {
    this.raw = raw

    this.id = raw.id
    this.name = raw.name
    this.status = raw.status
    this.couponCode = raw.couponCode
    this.description = raw.description
    this.promotionUrl = raw.promotionUrl
    this.redemptionImage = raw.redemptionImage

    this.type = raw.type

    this.terms = raw.terms

    this.redemption = raw.redemption ?? {}
    this.retailer = raw.retailer ?? {}
    this.offerGroups = raw.offerGroups ?? []
  }

  get offerGroupId() {
    return this.offerGroups[0]?.id ?? null
  }

  get isActive() {
    return this.status === 'active'
  }

  get isExpired() {
    return this.status === 'expired'
  }

  get canBeRedeemed() {
    return (this.redemption.available ?? 0) > 0 && this.isActive
  }

  get isRedeemed() {
    return (this.redemption.redeemed ?? 0) > 0
  }
}

export default CommunityHubOffer
