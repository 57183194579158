<script>
import modal from '/~/core/mdl'
export default {
  name: 'rec-media-comment-gallery',
  props: {
    attachments: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previewCount: 6,
    }
  },
  computed: {
    limitAttachments() {
      return this.attachments.previewAttachments.slice(0, this.previewCount)
    },
    hiddenAttachmentsCount() {
      const count = this.attachments.previewAttachmentsCount

      return count > this.previewCount ? count - this.previewCount : 0
    },
  },
  methods: {
    openGallery(index) {
      this.initialItemIndex = index
      modal.show('rec-media-gallery', {
        props: {
          sources: this.attachments.previewAttachments,
          initialItemIndex: this.initialItemIndex,
        },
      })
    },
    bgImage(attachment) {
      return attachment.type === 'image/gif'
        ? attachment.cdn
        : `${attachment.cdn}-/scale_crop/160x143/center/`
    },
  },
}
</script>

<template>
  <div class="flex flex-wrap">
    <div
      v-for="(attachment, index) in limitAttachments"
      :key="attachment.id"
      class="relative w-2/12 p-[5px]"
    >
      <div
        v-if="attachment.type === 'video'"
        class="relative inset-0 cursor-pointer overflow-hidden rounded-xl bg-light bg-cover bg-center"
        style="padding-bottom: 100%"
        @click="openGallery(index)"
      >
        <video
          :src="attachment.cdn"
          class="absolute top-0 left-0 h-full max-h-full w-full max-w-full"
        />
      </div>
      <div
        v-else
        class="relative inset-0 cursor-pointer overflow-hidden rounded-xl bg-light bg-cover bg-center"
        style="padding-bottom: 100%"
        :style="{ backgroundImage: `url(${bgImage(attachment)})` }"
        @click="openGallery(index)"
      >
        <span
          v-if="hiddenAttachmentsCount > 0 && index === previewCount - 1"
          class="text absolute inset-0 z-10 flex items-center justify-center bg-overlay text-xl font-bold text-white"
        >
          +{{ hiddenAttachmentsCount }}
        </span>
      </div>
    </div>
  </div>
</template>
