<script setup lang="ts">
import { useCms } from '/~/composables/cms'
import { useUser, useUserEvents } from '/~/composables/user'
import ThreeDsTroubleshootingGuide from './3ds-troubleshooting-guide.vue'

const { user } = useUser()
const { threeDsFaqVisited } = useUserEvents()
const { threeDsFaqPageTitle } = useCms()

threeDsFaqVisited({ type: 'page' })
</script>

<template>
  <div
    class="mx-auto w-full max-w-5xl bg-light px-5 pb-11 sm:px-[30px]"
    :class="{
      'overflow-y-auto': !user.authorized,
    }"
  >
    <h1
      v-if="threeDsFaqPageTitle"
      class="my-[30px] text-2xl sm:mb-11 sm:text-3xl"
    >
      {{ threeDsFaqPageTitle }}
    </h1>
    <three-ds-troubleshooting-guide />
  </div>
</template>
