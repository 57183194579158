<script>
import { watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import TravelTile from '/~/extensions/travel/components/travel-tile.vue'
import { useTravel } from '/~/extensions/travel/composables'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'

export default {
  name: 'travel-catalogue',
  components: {
    TravelTile,
    EwCatalogProcessor,
    BaseLoader,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { algolia, loading, routeState, syncState, getContent } = useTravel()

    watch(routeState, (value) => {
      router.replace(value)
    })

    watch(
      () => ({ ...route }),
      (to, from) => {
        if (
          to.fullPath.replace(to.hash, '') !==
          from.fullPath.replace(from.hash, '')
        ) {
          syncState({ to, from })
          getContent()
        }
      }
    )

    getContent()

    return {
      algolia,
      loading,
      routeState,
    }
  },
}
</script>

<template>
  <div>
    <div v-if="loading" class="flex w-full items-center justify-center p-32">
      <base-loader size="xl" />
    </div>
    <ew-catalog-processor
      v-else
      :processor="algolia"
      :columns="{
        xs: 2,
        lg: 3,
      }"
    >
      <template #empty>
        <p>No Result found</p>
        <p>Please try another category</p>
      </template>

      <template #tile="{ tile }">
        <travel-tile :offer="tile" />
      </template>
    </ew-catalog-processor>
  </div>
</template>
