<script>
import DrawerAction from './drawer-action.vue'

export default {
  name: 'drawer-actions',
  components: {
    DrawerAction,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visibleActions() {
      return this.actions.filter((action) => action.hidden !== true)
    },
    hasActions() {
      return this.visibleActions.length > 0
    },
  },
}
</script>

<template>
  <div v-if="hasActions" class="my-5 rounded-xl bg-white p-2.5">
    <template v-for="action in visibleActions">
      <drawer-action
        :key="action.label"
        :disabled="disabled"
        :action="action"
      />
    </template>
  </div>
</template>
