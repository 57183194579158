<script>
import { mixin as clickaway } from 'vue-clickaway'
import { formatDate, createDate } from '/~/utils/format/date'
import BaseButtonRound from '/~/components/base/button-round/base-button-round.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'

export default {
  name: 'month-text-date-picker',
  components: {
    BaseButtonRound,
    BaseIcon,
    BaseDate,
  },
  mixins: [clickaway],
  props: {
    value: {
      type: [Date, String],
      default: '',
    },
    format: {
      type: String,
      default: 'daymonthyearnumeric',
    },
    emitInitialValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { getDatePickerFormat } = useLocalization()

    return {
      ...clickaway.setup?.(...arguments),
      createDate,
      formatDate,
      getDatePickerFormat,
    }
  },
  data() {
    return {
      dropdown: false,
      selectedYear: null,
      selectedMonth: null,
    }
  },
  computed: {
    date() {
      return this.value || this.formatDate(this.format, new Date().setDate(1))
    },
    initYear() {
      return +this.formatDate(
        'year',
        createDate(this.date, this.getDatePickerFormat('daymonthyearnumeric'))
      )
    },
    months() {
      const monthList = []

      for (let i = 0; i <= 11; i++) {
        const date = new Date(this.selectedYear, i, 1)
        const month = this.formatDate('monthshort', date)

        monthList.push(month)
      }
      return monthList
    },
    isMonthSelected() {
      return typeof this.selectedMonth === 'number' && this.selectedMonth >= 0
    },
  },
  watch: {
    dropdown(val, oldVal) {
      if (val && !oldVal) {
        this.selectedYear = +this.formatDate(
          'year',
          createDate(this.date, this.getDatePickerFormat('daymonthyearnumeric'))
        )
        // -1 to process 0-based index month select
        this.selectedMonth =
          +this.formatDate(
            'monthnumeric',
            createDate(
              this.date,
              this.getDatePickerFormat('daymonthyearnumeric')
            )
          ) - 1
      }
    },
  },
  created() {
    this.initSelectedDate()

    if (this.emitInitialValue) {
      this.$emit('input', this.date)
    }
  },
  methods: {
    toggleDropdown() {
      this.dropdown = !this.dropdown
    },
    hideDropdown() {
      this.dropdown = false
    },
    initSelectedDate() {
      const dayjsDate = this.createDate(
        this.date,
        this.getDatePickerFormat(this.format)
      )

      this.selectedYear = +this.formatDate('year', dayjsDate)
      this.selectedMonth = +this.formatDate('monthnumeric', dayjsDate) - 1
    },
    handlePrevMonth() {
      if (!this.selectedYear || !this.isMonthSelected) {
        this.initSelectedDate()
      }
      if (this.selectedMonth === 0) {
        this.selectedMonth = 11
        this.handlePrevYear()
      } else {
        this.selectedMonth--
      }
      this.handleMonth(this.selectedMonth)
    },
    handleNextMonth() {
      if (!this.selectedYear || !this.isMonthSelected) {
        this.initSelectedDate()
      }
      if (this.selectedMonth === 11) {
        this.selectedMonth = 0
        this.handleNextYear()
      } else {
        this.selectedMonth++
      }
      this.handleMonth(this.selectedMonth)
    },
    handlePrevYear() {
      this.selectedYear--
    },
    handleNextYear() {
      this.selectedYear++
    },
    handleMonth(idx) {
      this.selectedMonth = idx
      const date = new Date()

      date.setMonth(this.selectedMonth)
      date.setYear(this.selectedYear)
      date.setDate(1)
      this.$emit('input', this.formatDate(this.format, date))
      this.hideDropdown()
    },
  },
}
</script>

<template>
  <div
    v-on-clickaway="hideDropdown"
    class="relative w-full transition-opacity sm:w-auto"
    :class="{
      'pointer-events-none opacity-50': disabled,
    }"
  >
    <div class="flex items-center justify-center py-2.5 sm:py-0">
      <span
        class="flex h-[30px] w-[30px] items-center justify-center text-primary sm:hidden"
        @click="handlePrevMonth"
      >
        <base-icon svg="base/chevron-bold" color="primary" size="xs" />
      </span>

      <div
        class="w-48 cursor-pointer px-[5px] text-center text-primary sm:w-auto sm:px-0 sm:text-left"
        @click="toggleDropdown"
      >
        <base-date
          :date="date"
          input-type="daymonthyearnumeric"
          type="month-year"
          class="text-base font-bold leading-6 text-primary"
        />
        <base-icon
          class="ml-[5px] hidden max-h-[15px] max-w-[15px] sm:inline-block"
          :svg="dropdown ? 'plain/chevron-top' : 'plain/chevron-bottom'"
          size="md"
          color="primary"
        />
      </div>

      <span
        class="flex h-[30px] w-[30px] items-center justify-center text-primary sm:hidden"
        @click="handleNextMonth"
      >
        <base-icon
          svg="base/chevron-bold"
          class="rotate-180"
          color="primary"
          size="xs"
        />
      </span>
    </div>
    <div
      v-if="dropdown"
      class="absolute top-full left-1/2 z-10 mt-2.5 -translate-x-1/2 transform overflow-hidden rounded-xl bg-white shadow-md sm:right-0 sm:left-auto sm:transform-none"
    >
      <div class="flex items-center justify-between px-5 pt-6 pb-5">
        <base-button-round
          ref="left"
          icon="chevron-left"
          look="outlined"
          icon-color="primary"
          @click="handlePrevYear"
        />

        <span class="text-xl font-bold leading-6 text-primary">
          {{ selectedYear }}
        </span>
        <base-button-round
          ref="right"
          icon="chevron-right"
          look="outlined"
          icon-color="primary"
          @click="handleNextYear"
        />
      </div>

      <div class="flex w-64 flex-wrap border-b-0">
        <span
          v-for="(month, idx) in months"
          :key="`month${idx}`"
          class="w-1/3 border-t px-5 py-[15px] text-center font-bold leading-4"
          :class="[
            (idx + 1) % 3 !== 0 && 'border-r',
            idx === selectedMonth && selectedYear === initYear
              ? 'cursor-default bg-primary text-white'
              : 'cursor-pointer text-primary transition-colors duration-200 hover:bg-primary hover:text-white',
          ]"
          @click="handleMonth(idx)"
        >
          {{ month }}
        </span>
      </div>
    </div>
  </div>
</template>
