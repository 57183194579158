<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { formatPhone } from '/~/utils/format/string'

export default {
  name: 'travel-enquire-confirm-modal',
  components: {
    BaseButton,
    BaseMdl,
  },
  props: {
    packageDetails: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: '',
    },
    trackingId: {
      type: String,
      default: '',
    },
    contacts: {
      type: Object,
      default: () => ({
        name: 'Rewards Travel',
        phone: '1300538292',
      }),
    },
  },
  setup() {
    return {
      formatPhone,
      modal,
      ui,
    }
  },
}
</script>

<template>
  <base-mdl title="Thank you for your enquiry" fullscreen="mobile">
    <div class="text-center">
      <img
        src="/images/travel/travel-enquiry-confirm.png"
        alt="Travel enquiry confirmation"
        class="mx-auto mb-5 max-w-full"
      />
      <div class="mb-5">
        <div class="mb-2.5 font-bold">Hi {{ name }}</div>
        <div class="mb-2.5">
          Thank you for submitting your booking enquiry to
          <span class="font-bold">{{ packageDetails.destination }}.</span>
        </div>
        <div class="space-y-2.5 font-bold">
          <p>
            A member of our support team will be in contact within 24 hours
            during business days.
          </p>
          <p>
            For any questions in relation to your enquiry, please contact
            {{ contacts.name }} on
            <a
              v-if="ui.mobile"
              class="text-primary no-underline"
              :href="`tel:${contacts.phone}`"
            >
              {{ formatPhone(contacts.phone) }}
            </a>
            <template v-else>{{ formatPhone(contacts.phone) }}</template>
            .
          </p>
        </div>
      </div>
      <p class="mb-5">
        If your booking enquiry was submitted over the weekend or on a public
        holiday, you'll be contacted the next business day.
      </p>
      <div class="rounded-lg bg-dark py-2.5">
        <span class="text-eonx-neutral-600">Tracking ID</span>
        <div class="font-bold">
          {{ trackingId }}
        </div>
      </div>
      <div class="mt-[30px]">
        <base-button class="mx-auto w-full sm:w-auto" @click="modal.hide()">
          Got it
        </base-button>
      </div>
    </div>
  </base-mdl>
</template>
