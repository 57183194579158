<script>
import ui from '/~/core/ui'
import { getMenuActiveItem } from '/~/utils/menu'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useUI } from '/~/composables/ui'

export default {
  name: 'bill-payments-desktop-toolbar-v1',
  components: {
    BaseIcon,
    BaseAction,
  },
  setup() {
    const { leftMenu, uiReady } = useUI()

    return {
      leftMenu,
      uiReady,
      ui,
    }
  },
  computed: {
    activeTopMenuItem() {
      if (!this.uiReady || !this.leftMenu) return

      return getMenuActiveItem(this.$route, this.leftMenu)
    },
    activeId() {
      return this.activeTopMenuItem?.id ?? null
    },
    isHome() {
      return this.$route.name === 'home'
    },
    isBorder() {
      return !this.isHome && ui.desktop
    },
  },
}
</script>

<template>
  <div
    class="flex flex-col content-between"
    :class="{
      'border-r': isBorder,
    }"
  >
    <div class="flex grow flex-col space-y-[15px] px-[15px] pt-6">
      <template v-for="{ id, action, icon, label } in leftMenu">
        <base-action
          v-if="uiReady && action && action.enabled"
          :key="id"
          v-bind="action"
          :label="label"
          color=""
          class="flex h-10 w-10 items-center justify-center rounded-lg transition-all"
          :class="
            activeId === id
              ? 'hover:lightest bg-blue-50 text-primary'
              : 'text-eonx-neutral-600 hover:bg-divider-light'
          "
        >
          <base-icon :svg="icon" class="h-6 w-6" />
        </base-action>
        <div
          v-else
          :key="id"
          class="flex h-10 w-10 items-center justify-center text-eonx-neutral-600"
        >
          <base-icon :svg="icon" class="h-6 w-6" />
        </div>
      </template>
    </div>
  </div>
</template>
