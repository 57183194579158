<script setup lang="ts">
import { computed, onBeforeUnmount, watch } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useSwiper } from '/~/composables/base/use-swiper'
import DrawerFaqSlide from './drawer-faq-slide.vue'

defineProps<{
  slides: InstanceType<typeof DrawerFaqSlide>['$props'][]
}>()

const { swiperContainer, initSwiper, destroySwiper } = useSwiper(
  computed(() => ({
    pagination: {
      el: '[data-id="menu-faq-slider"][data-button="pagination"]',
      clickable: true,
    },
    navigation: {
      nextEl: '[data-id="menu-faq-slider"][data-button="next"]',
      prevEl: '[data-id="menu-faq-slider"][data-button="prev"]',
      disabledClass: 'opacity-50 cursor-default',
    },
  }))
)

watch(swiperContainer, initSwiper)

onBeforeUnmount(() => {
  setTimeout(
    destroySwiper,
    400 // /src/styles/transitions.css - .menu-slide-leave-active - transition-duration: 0.4s
  )
})
</script>

<template>
  <div ref="swiperContainer" class="swiper-container relative overflow-hidden">
    <div class="swiper-wrapper">
      <drawer-faq-slide
        v-for="(slide, index) of slides"
        :key="index"
        v-bind="slide"
      />
    </div>
    <div
      v-if="slides.length > 1"
      data-id="menu-faq-slider"
      data-button="pagination"
      class="swiper-pagination !relative pt-[5px]"
    />
    <div
      v-if="slides.length > 1"
      data-id="menu-faq-slider"
      data-button="prev"
      class="absolute top-0 z-10 mt-[140px] block transform"
    >
      <base-icon svg="chevron-left" size="md" />
    </div>
    <div
      v-if="slides.length > 1"
      data-id="menu-faq-slider"
      data-button="next"
      class="absolute right-0 top-0 z-10 mt-[140px] block transform"
    >
      <base-icon svg="chevron-right" size="md" />
    </div>
  </div>
</template>
