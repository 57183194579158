<script>
import EwProductType from '/~/components/ewallet/product/ew-product-type.vue'
import get from 'lodash-es/get'
import { formatDollar } from '/~/utils/format/money'
import { useGiftRecipients } from '/~/composables/gift-recipients'

export default {
  name: 'drawer-activity-detail-order-item',
  components: {
    EwProductType,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getGiftRecipient } = useGiftRecipients()

    return {
      getGiftRecipient,
      formatDollar,
    }
  },
  data() {
    return {
      gift: undefined,
      loading: false,
    }
  },
  async mounted() {
    if (this.item.giftRecipientId) {
      this.loading = true
      this.gift = await this.getGiftRecipient(this.item.giftRecipientId)
      this.loading = false
    }
  },
  methods: {
    getType(item) {
      return get(item, 'type')
    },
    price(item) {
      if (this.getType(item) === 'estore') {
        return item.priceGst
      } else {
        return item.price
      }
    },
    product(item) {
      const type = this.getType(item)

      switch (type) {
        case 'coupon':
          return `${item.name} ${type}`
        default:
          return `${item.quantity} x ${item.name}`
      }
    },
    retailerName(item) {
      if (get(item, 'retailer.slug') === 'dining-retailer') {
        return item.name
      } else if (get(item, 'retailer.slug') === 'estore-retailer') {
        return 'eStore Product'
      }

      return get(item, 'retailerName')
    },
    hasLogo(item) {
      return get(item, 'retailerLogo', '')
    },
    showProductType(item) {
      return !['coupon'].includes(this.getType(item))
    },
    getLogoStyle(item) {
      const logo = this.hasLogo(item)

      if (!logo) {
        return ''
      }

      return { 'background-image': `url('${this.cdn(logo).url()}')` }
    },
  },
}
</script>

<template>
  <div class="flex flex-1 items-center justify-between">
    <div
      v-if="hasLogo(item)"
      :class="$style.cardImage"
      :style="getLogoStyle(item)"
    />
    <div :class="$style.cardDetails">
      <div :class="$style.cardItemRow">
        <b>{{ retailerName(item) }}</b>
      </div>
      <div :class="$style.cardItemRow">
        {{ product(item) }}
      </div>
      <template v-if="showProductType(item)">
        <template v-if="loading">
          <div
            class="h-5 w-1/2 animate-pulse rounded-md bg-eonx-neutral-50 sm:mt-[5px]"
          />
        </template>
        <template v-else>
          <ew-product-type
            v-if="getType(item) === 'estore'"
            class="text-eonx-neutral-600"
            :class="$style.productType"
            physical
          >
            <template #physical>Delivered by supplier</template>
          </ew-product-type>
          <ew-product-type
            v-else
            class="text-eonx-neutral-600"
            :class="$style.productType"
            :gift="!!gift"
            :gift-card="!!item.retailer"
            :physical="item.physical"
            mobile-short
          >
            <template v-if="gift" #physical>
              <div>Gift to {{ gift.name }}</div>
            </template>
            <template v-if="gift" #digital>
              <div>Gift to {{ gift.email }}</div>
            </template>
          </ew-product-type>
        </template>
      </template>
    </div>
    <div :class="$style.cardPrice">
      {{ formatDollar(price(item) * item.quantity) }}
    </div>
  </div>
</template>

<style lang="scss" module>
.cardImage {
  margin-right: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @apply h-12 w-12;
}

.cardDetails {
  flex-grow: 1;
  width: 100%;
  min-width: 0;
}

.cardPrice {
  @apply ml-6 flex items-center justify-end;

  &Cashback {
    @apply ml-0 block min-w-36;

    &Total,
    &Rate {
      font-weight: bold;
    }

    &Total {
      &:before {
        font-weight: normal;
        content: 'Total: ';
      }
    }
    &Rate {
      &:before {
        font-weight: normal;
        content: 'Cashback: ';
      }
    }
  }
}

.cardItemTotal {
  flex-direction: column;
  border-bottom: none;
}

.cardItemRow {
  @apply mb-0.5 truncate text-sm;
}

.cardTotalRow {
  @apply mb-2.5 flex w-full justify-between;

  &:last-of-type {
    font-size: 18px;

    @apply mt-2.5;
  }
}

.cardITotalCell {
  @apply grow text-sm;

  &:last-of-type {
    @apply grow-0;
  }
}

.productType {
  color: #c0ccda !important;
}
</style>
