<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useUserEvents } from '/~/composables/user'

export default {
  name: 'gift-cards-cinema-location-terms',
  components: {
    BaseMdl,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    terms: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { termsAndConditionsVisited } = useUserEvents()

    termsAndConditionsVisited({
      type: 'modal',
      name: 'gift-cards-cinema location terms',
      title: props.title,
    })
  },
  computed: {
    upgradedTerms() {
      return this.terms.replace(/href="/g, 'target="_blank" href="')
    },
  },
}
</script>

<template>
  <base-mdl :title="title" fullscreen="mobile" width="md">
    <p v-if="terms" class="markup" v-html="upgradedTerms" />
    <p v-else>
      To obtain a cinema ticket you must pay for your chosen movie ticket via
      the platform to receive a QR Code — which is your cinema ticket. You can
      purchase a single ticket or buy multiple tickets for friends and family.
      You can either print the ticket or use the ticket in electronic format
      from your smart phone. You must present your ticket to the cinema for
      admission. You can only use your ticket for your chosen cinema venue,
      session and movie, and each ticket expires after one use.
    </p>
  </base-mdl>
</template>
