<script setup lang="ts">
import { computed } from 'vue'
import AppLogo from '/~/components/app/app-logo.vue'
import BaseAction from '/~/components/base/action/base-action.vue'

interface Button {
  appearance: string
  color: string
  enabled: boolean
  label: string
  look: string
  params: Record<string, unknown>
  query: Record<string, unknown>
  queryString: string
  type: string
  value: string
}

interface Image {
  alt: string
  desktop: string
  link: string
  mobile: string
  ratio: number
  tablet: string
}

export interface SignOutPageSettings {
  background?: Image
  logo?: Image
  bannerButton1?: Button
  bannerButton2?: Button
}

export interface SignOutProps {
  buttons?: Record<string, Button>
  logo?: string
  logoAlt?: string
  background?: string
}

const props = withDefaults(defineProps<SignOutProps>(), {
  buttons: () => ({}),
})

const backgroundStyles = computed(() => ({
  backgroundImage: props.background ? `url("${props.background}")` : '',
}))
</script>

<template>
  <div
    class="relative mx-auto flex h-[100dvh] min-h-[100dvh] w-full max-w-[1980px] flex-col items-center justify-between"
  >
    <div
      class="fixed top-0 bottom-0 left-0 right-0 h-screen w-screen"
      :class="[
        !background &&
          'bg-gradient-to-b from-blue-500 via-blue-300 to-blue-300',
        background &&
          'bg-cover bg-top xs:bg-[35%_top] md:bg-center xl:bg-left-top',
      ]"
      :style="backgroundStyles"
    />

    <div class="box-content h-12 p-4 xs:h-16" />

    <div
      class="relative my-auto max-h-max w-full max-w-[672px] px-4 lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2"
    >
      <div
        class="rounded-lg bg-white p-6 text-center shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1)]"
      >
        <app-logo
          class="mx-auto sm:max-h-24"
          :max-width="295"
          :min-height="true"
        />

        <h3 class="my-2 text-2xl font-bold text-gray-800 xs:my-10 xs:text-3xl">
          You’ve successfully logged out
        </h3>

        <template v-for="(button, key) in buttons">
          <base-action
            v-if="button.enabled"
            :key="key"
            v-bind="button"
            :classes="'w-full'"
            class="mt-2 flex w-full flex-grow xs:h-14"
          >
            <span v-html="button.label" />
          </base-action>
        </template>
      </div>
    </div>

    <div
      class="relative box-content h-12 w-auto self-start p-4 xs:h-16 md:bottom-8 md:left-8 lg:fixed lg:left-14 lg:bottom-14"
    >
      <img v-if="logo" :src="logo" :alt="logoAlt" class="h-full" />
    </div>
  </div>
</template>
