<script>
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { formatNumber } from '/~/utils/format/numeric'
import { computed } from 'vue'
import { useUser } from '/~/composables/user'
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'

export default {
  name: 'bupa-home-banner-slide',
  components: {
    BaseAction,
    BaseIcon,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    image: {
      type: Object,
      default: () => ({}),
    },
    bannerButton: {
      type: Object,
      default: () => ({}),
    },
    isNameGreeting: {
      type: Boolean,
      default: false,
    },
    isTextBottom: {
      type: Boolean,
      default: false,
    },
    responsiveSize: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: null,
    },
    buttonTextColor: {
      type: String,
      default: null,
    },
    buttonBackgroundColor: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { user } = useUser()
    const isTextBottomInMobile = computed(() => ui.mobile && props.isTextBottom)
    const infoTitle = computed(() =>
      props.isNameGreeting
        ? props.info.title.replace(
            /<\s*([a-zA-Z0-9]+)(.*?)>/,
            `<$1 class="text-3xl font-semibold sm:leading-12 sm:pt-14 sm:text-4xl">Hi ${user.value.firstName}, `
          )
        : props.info.title.replace(
            /<\s*([a-zA-Z0-9]+)(.*?)>/,
            `<$1 class="text-3xl font-semibold sm:leading-12 sm:pt-14 sm:text-4xl">`
          )
    )
    const buttonLabel = computed(() => props.bannerButton.label)

    const responsiveImage = computed(() => {
      if (ui.width < ui.breakpoints.xs) {
        return props.image.mobile || props.image.tablet || props.image.desktop
      }
      if (ui.width < ui.breakpoints.md) {
        return props.image.tablet || props.image.desktop
      }
      return props.image.desktop
    })

    const imageWidth = {
      mobile: 1070,
      tablet: 1768,
      desktop: 2352,
    }

    const backgroundImage = computed(() => {
      const width = ui.mobile
        ? imageWidth.mobile
        : ui.tablet
        ? imageWidth.tablet
        : imageWidth.desktop
      const height = 688

      return cdn(responsiveImage.value)
        .preview(`${width}x${height}`)
        .format()
        .quality('smart')
        .url()
    })

    const bannerStyle = computed(() => ({
      backgroundColor: props.backgroundColor,
      textAlign: props.info.contentAlignmentX,
      backgroundImage: !props.responsiveSize
        ? `url(${backgroundImage.value})`
        : null,
    }))

    const bannerButtonBinding = computed(() => ({
      ...props.bannerButton,
      appearance: 'link',
    }))

    return {
      formatNumber,
      infoTitle,
      buttonLabel,
      user,
      bannerStyle,
      isTextBottomInMobile,
      bannerButtonBinding,
      backgroundImage,
    }
  },
}
</script>

<template>
  <li class="swiper-slide">
    <component
      :is="
        bannerButton.enabled && bannerButton.look === 'banner'
          ? 'base-action'
          : 'article'
      "
      v-bind="
        bannerButton.enabled && bannerButton.look === 'banner'
          ? bannerButtonBinding
          : null
      "
    >
      <div
        class="relative flex items-center overflow-hidden bg-cover bg-bottom bg-no-repeat font-montserrat md:shadow"
        :class="{ 'min-h-[344px]': !responsiveSize }"
        :style="bannerStyle"
      >
        <div
          v-if="!isTextBottomInMobile"
          class="p-5 sm:py-12 sm:px-14 md:w-1/2"
          :class="{ absolute: responsiveSize }"
          :style="{ color: textColor }"
        >
          <div v-if="infoTitle" v-html="infoTitle" />
          <div
            v-if="info.text"
            class="mt-[15px] text-xl leading-8"
            v-html="info.text"
          />

          <base-action
            v-if="bannerButton.enabled && bannerButton.look !== 'banner'"
            class="mt-[30px] inline-block hover:opacity-60"
            v-bind="bannerButton"
            :label="image.alt"
            origin="banner"
            appearance="link"
          >
            <span
              class="button flex h-11 w-full items-center justify-center rounded p-6 font-bold xs:w-auto"
              :style="{
                border: `solid 2px ${buttonTextColor}`,
                color: buttonTextColor,
              }"
            >
              {{ bannerButton.label }}
              <base-icon class="ml-5" svg="plain/chevron-right" size="sm" />
            </span>
          </base-action>
        </div>
        <img v-if="responsiveSize" :src="backgroundImage" class="w-full" />
      </div>
      <div
        v-if="isTextBottomInMobile"
        class="flex min-h-56 flex-col justify-start p-5 sm:w-1/2 sm:p-10 sm:pt-12 sm:pl-14"
        :style="{
          backgroundColor,
          textAlign: info.contentAlignmentX,
        }"
      >
        <div
          v-if="infoTitle"
          :style="{ color: textColor }"
          v-html="infoTitle"
        />
        <div
          v-if="info.text"
          class="mt-[15px] text-xl leading-8"
          :style="{ color: textColor }"
          v-html="info.text"
        />
        <div class="mt-auto">
          <base-action
            v-if="bannerButton.enabled && bannerButton.look !== 'banner'"
            class="mt-[30px] inline-block hover:opacity-60"
            v-bind="bannerButton"
            :label="image.alt"
            origin="banner"
            appearance="link"
          >
            <span
              class="button flex h-11 w-full items-center justify-center rounded p-6 font-bold xs:w-auto"
              :style="{
                border: `solid 2px ${buttonTextColor}`,
                color: buttonTextColor,
              }"
            >
              {{ bannerButton.label }}
              <base-icon class="ml-5" svg="plain/chevron-right" size="sm" />
            </span>
          </base-action>
        </div>
      </div>
    </component>
  </li>
</template>
