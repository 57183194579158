<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import CommentInput from './comment-input.vue'
import Comment from './comment.vue'

export default {
  name: 'rec-comments',
  components: {
    CommentInput,
    Comment,
    BaseButton,
    BaseLoader,
  },
  inject: ['post'],
  provide() {
    return {
      processor: this.processor,
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    processor: {
      type: Object,
      required: true,
    },
    replies: {
      type: Boolean,
      default: false,
    },
    reply: {
      type: Object,
      default: null,
    },
    showReply: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  async created() {
    if (this.processor.total || this.post.totalComments) {
      await this.loadData()
      this.post.updateTotalComments()
    }
  },
  methods: {
    async loadData() {
      try {
        this.loading = true
        await this.processor.getData({ perPage: 5 })
      } catch (error) {
        console.error('rec-room', error)
      } finally {
        this.loading = false
      }
    },
    async onCommentCreate() {
      this.$emit('reply')
      const total = await this.processor.total

      if (total) {
        this.processor.refresh()
      } else {
        this.loadData()
      }
      this.post.totalComments++
    },
    scrollToInput() {
      this.$refs.input?.$el?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    },
  },
}
</script>

<template>
  <div>
    <base-loader v-if="loading" class="flex w-full justify-center py-5 pr-5" />

    <div v-else-if="processor.message" class="px-11 text-center">
      {{ processor.message }}
    </div>

    <div
      v-else-if="!processor.isEmpty"
      class="ml-[5px] md:ml-5"
      :class="{
        'border-l pl-[5px]': replies,
      }"
    >
      <comment
        v-for="(tile, i) in processor.hits"
        :key="tile.objectID || `tile-${i}`"
        :reply="replies"
        :comment="tile"
        :show-reply="showReply"
        class="mt-5"
        v-on="$listeners"
      >
        <slot name="replies" :processor="tile.comments" />
      </comment>

      <base-loader
        v-if="processor.processing && processor.hits.length"
        class="flex w-full justify-center py-5 pr-5"
      />
    </div>

    <div
      v-if="
        processor.hits.length &&
        !processor.processing &&
        !processor.allRecordsLoaded
      "
      class="flex items-center justify-between px-[30px]"
      :class="{
        'mb-[5px]': reply,
      }"
    >
      <base-button look="link" size="md" @click="() => processor.next()">
        View more comments
      </base-button>

      <b v-if="!replies" class="block text-right text-sm text-eonx-neutral-600">
        {{ processor.length }} out of {{ processor.total }}
      </b>
    </div>

    <comment-input
      v-if="reply && reply.entity === processor.entity"
      ref="input"
      :reply="reply.comment"
      class="mt-5 pl-[15px] pr-[35px] md:px-[30px]"
      avatar-size="xxs"
      show-avatar
      @success="onCommentCreate"
      @keydown.esc="$emit('reply')"
    />
  </div>
</template>
