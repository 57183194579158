<script setup lang="ts">
import { Payee } from '/~/types/api'
import bottomSheet from '/~/core/bottom-sheet'
import { formatBsb } from '/~/utils/format/string'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { useLocalization } from '/~/composables/localization'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import PayToRowStatus from './pay-to-row-status.vue'
import { usePayToRowComponent } from './use-pay-to-row-component'

const props = withDefaults(
  defineProps<{
    payee: Payee
    enableContextMenu?: boolean
  }>(),
  {
    enableContextMenu: true,
  }
)

const emit = defineEmits<(event: 'click', payload: Payee) => void>()

const { translate } = useLocalization()
const { accountPresentation } = useBankAccountForm()

const uiHandlers = {
  showEdit: () => {
    bottomSheet.show('edit-payee', {
      props: { payee: props.payee },
    })
  },
  showDelete: () => {
    bottomSheet.show('delete-payee', {
      props: { payee: props.payee },
    })
  },
}

const { menuItems, formatBusinessNumber, isPayeeActionsDisabled } =
  usePayToRowComponent(props.payee, uiHandlers)
</script>

<template>
  <div
    class="relative mb-2.5 space-y-[5px] rounded-md border border-neutral-200 bg-white p-[15px]"
    :class="[enableContextMenu ? '' : 'cursor-pointer']"
    @click="isPayeeActionsDisabled && $event.stopImmediatePropagation()"
  >
    <div
      :class="[isPayeeActionsDisabled ? 'pointer-events-none opacity-50' : '']"
      @click="emit('click', payee)"
    >
      <div class="flex items-center space-x-2.5">
        <payee-icon :payee="payee" :size="8" />
        <div class="truncate font-bold">
          {{ payee.name }}
        </div>
      </div>
      <div class="flex-1 truncate">
        <div
          v-if="payee.type === 'bpay'"
          class="space-y-[5px] truncate text-sm"
        >
          <div class="flex justify-between">
            <span class="text-eonx-neutral-600">Biller:</span>
            <span class="text-eonx-neutral-800">
              {{ payee.billerCode }}
            </span>
          </div>
          <div class="flex justify-between">
            <span class="text-eonx-neutral-600">Ref:</span>
            <span class="text-eonx-neutral-800">
              {{ payee.reference }}
            </span>
          </div>
        </div>
        <div
          v-else-if="payee.type === 'bankAccount'"
          class="space-y-[5px] text-sm"
        >
          <div class="flex justify-between">
            <span class="text-eonx-neutral-600">
              {{ translate('bankAccount.detailsTitle') }}:
            </span>
            <span class="text-eonx-neutral-800">
              <template v-if="accountPresentation.bsb">
                {{ formatBsb(payee.bsb) }},
              </template>
              {{ payee.accountNumber }}
            </span>
          </div>
          <div class="flex justify-between">
            <span class="text-eonx-neutral-600">
              {{ translate('payment.businessNumber') }}:
            </span>
            <span class="text-eonx-neutral-800">
              {{ formatBusinessNumber(payee.abn) }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="enableContextMenu && !payee.isProviderPayee"
        class="absolute -right-[5px] -top-2.5"
      >
        <base-menu
          data-test="pay-to-row-context-menu"
          :items="menuItems"
          position="right"
        >
          <template #trigger>
            <div>
              <div
                class="flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white"
              >
                <base-icon svg="plain/more-v2" :size="15" />
              </div>
            </div>
          </template>
        </base-menu>
      </div>
    </div>
    <pay-to-row-status :payee="payee" @click-remove="uiHandlers.showDelete" />
  </div>
</template>
