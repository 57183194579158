import { computed, reactive } from 'vue'
import Pusher from 'pusher-js'
import api from '/~rec/core/api'
import { RecroomUser } from '/~rec/core/user'
import { RecroomOrganization } from '/~rec/core/organization'

const notificationFormat = (notifications) => ({
  announcements: notifications.announcements,
  feed: notifications.feeds,
  'reported-posts': notifications.reported_posts,
})

const state = reactive({
  me: null,
  organization: null,
  pusher: null,
  chatConfig: null,
  tokenBalance: null,
  managementWallet: null,
  notifications: null,
})
const myRecId = computed(() => state.me?.uuid)
const orgMemberId = computed(() => state.me?.orgMemberId)
const orgId = computed(() => state.organization?.id)
const currency = computed(() => state.organization?.currency)
const defaultGroupId = computed(() => state.organization?.defaultGroupId)

const managementWalletBalance = computed(
  () => state.managementWallet?.remaining_allowance
)
const isMeAdmin = computed(() => state.me?.isMeAdmin)
const isMeSuperAdmin = computed(() => state.me?.isMeSuperAdmin)
const isMeLimitedUser = computed(() => state.me?.isLimitedUser)
const features = computed(() => ({
  points: isFeatureEnabled('points'),
  awards: isFeatureEnabled('points') && isFeatureEnabled('awards'),
  chat: isFeatureEnabled('chat'),
  hi5: isFeatureEnabled('high_five'),
  perks: isFeatureEnabled('points') && isFeatureEnabled('perk_store'),
  groups: isFeatureEnabled('groups'),
  comments: isFeatureEnabled('comments'),
  announcements: isFeatureEnabled('announcements'),
  celebrations: isFeatureEnabled('celebrations'),
  events: isFeatureEnabled('events'),
  calendar: isFeatureEnabled('events') || isFeatureEnabled('celebrations'),
  directory: isFeatureEnabled('directory_profile'),
  recognition:
    isFeatureEnabled('awards') ||
    isFeatureEnabled('perks') ||
    isFeatureEnabled('high_five'),
  sidebarBanners: isFeatureEnabled('sidebar_banners'),
}))
const sidebarBannerImage = computed(
  () => state.organization?.sidebarBannerImage
)
const displayPointsPopup = computed(() => state.me?.displayPointsPopup)

function isFeatureEnabled(name) {
  return state.organization?.enabledFeatures?.includes(name)
}

async function initProfile(key) {
  const { data: profile } = await api.post('/loginWithApiKey', { key })
  const orgId = profile?.organisation.id
  const chat = profile?.chat_config || {}
  const organization = await RecroomOrganization.fetchById(orgId)

  state.me = new RecroomUser({
    ...profile.member,
    org_member_id: profile.org_member_id,
    roles: profile.roles,
    display_points_popup: profile.org_member.display_points_popup,
  })
  state.chatConfig = chat
  state.organization = organization
  state.pusher = new Pusher(chat.key || '', {
    app_id: chat.id,
    key: chat.key,
    cluster: chat.cluster,
    auth: {
      headers: {
        Authorization: `Basic ${btoa(key + ':')}`,
      },
    },
  })

  if (features.value.points) {
    getTokenBalance()

    if (isMeAdmin.value || isMeSuperAdmin.value) {
      getManagementPoints()
    }
  }

  return profile
}

async function getTokenBalance() {
  const response = await api.get(`/me/${currency.value}`)

  state.tokenBalance = response?.data?.available_balance
  return state.tokenBalance
}

async function getManagementPoints() {
  const response = await api.get(
    `/gamification/management-points-wallets?organisationMember=${orgMemberId.value}`
  )

  state.managementWallet = response?.data?.items[0]
  return state.managementWallet
}

async function getNotifications() {
  const notifications = await api.get('/activity/notification-count')

  notifications.value = notificationFormat(notifications?.data)
  return notifications.value
}

function updateProfile(avatarUrl) {
  if (!state.me) {
    return
  }

  state.me.avatar_url = avatarUrl
}

async function setDisplayPointsPopup(payload) {
  if (displayPointsPopup.value === !payload) return
  await api.put(`/organisations/${orgId.value}/members/${myRecId.value}`, {
    display_points_popup: !payload,
  })

  state.me.displayPointsPopup = !payload
  return displayPointsPopup.value
}

export const useRecProfile = () => ({
  profile: state,
  myRecId,
  orgMemberId,
  orgId,
  defaultGroupId,
  currency,
  managementWalletBalance,
  isMeAdmin,
  isMeSuperAdmin,
  isMeLimitedUser,
  features,
  sidebarBannerImage,
  displayPointsPopup,
  initProfile,
  getTokenBalance,
  getManagementPoints,
  getNotifications,
  updateProfile,
  setDisplayPointsPopup,
})
