<script>
import DefaultGcCatalog from '/~/extensions/giftcards/components/gc-catalog.vue'
import GcTile from '../components/gc-tile.vue'
import { useGcCinema } from '/~/extensions/gift-cards-cinema/composables'
import { watch, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

export default {
  name: 'gift-cards-cinema-catalog',
  components: {
    GcTile,
  },
  extends: DefaultGcCatalog,
  setup() {
    const route = useRoute()
    const router = useRouter()
    const {
      gcCinemaAlgoliaWorker,
      gcCinemaRouteState,
      getGcCinemaContent,
      syncGcCinemaState,
    } = useGcCinema()

    watch(gcCinemaRouteState, (value) => {
      router.replace(value)
    })

    watch(
      () => ({ ...route }),
      (to, from) => {
        if (
          to.fullPath.replace(to.hash, '') !==
          from.fullPath.replace(from.hash, '')
        ) {
          syncGcCinemaState({ to, from })
          getGcCinemaContent()
        }
      }
    )
    onBeforeMount(getGcCinemaContent)

    return {
      ...DefaultGcCatalog.setup?.(...arguments),
      algolia: gcCinemaAlgoliaWorker,
    }
  },
}
</script>
