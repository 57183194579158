<script>
import LayoutMain from './layouts/main/layout-main'

export default {
  name: 'health',
  components: {
    LayoutMain,
  },
}
</script>

<template>
  <div id="health" class="flex h-full w-full">
    <layout-main class="h-full">
      <router-view />
    </layout-main>
  </div>
</template>
