<script setup lang="ts">
import Daypicker from '/~/components/base/datepicker/daypicker/daypicker.vue'

const props = defineProps<{
  value: number
  activePeriod?: {
    from: number | null
    to: number | null
  }
  hint?: string
  onConfirm?: (day: number) => void
}>()

const emit = defineEmits<{
  (event: 'hide'): void
}>()

function select(day: number) {
  props.onConfirm?.(day)
  emit('hide')
}
</script>

<template>
  <div>
    <daypicker v-bind="props" class="p-5" @select="select" />
    <div
      v-if="hint"
      class="p-5 pt-0 text-sm font-normal leading-tight text-gray-800"
    >
      {{ hint }}
    </div>
  </div>
</template>
