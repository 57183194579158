<script>
export default {
  name: 'rec-chat-message-bubble',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div
    class="relative max-w-full overflow-hidden rounded-xl text-sm leading-tight"
    :class="{
      'rounded-br-none text-primary-content text-white': message.isMeAuthor,
      'rounded-bl-none bg-dark': !message.isMeAuthor,
      'bg-primary-lighten': message.sending,
      'bg-primary': !message.sending,
    }"
  >
    <slot />
  </div>
</template>
