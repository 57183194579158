<script setup lang="ts">
import { nanoid } from 'nanoid'
import { ref, computed, onBeforeUnmount, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import cdn, { getProviderDefaultUrl } from '/~/utils/cdn'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import { useSwiper } from '/~/composables/base/use-swiper'
import { useProvider } from '/~/composables/provider'

const props = withDefaults(
  defineProps<{
    autoplay?: string
    title?: string
    enableTitle?: boolean
  }>(),
  {
    title: '',
    enableTitle: false,
  }
)

const router = useRouter()
const {
  fetchOfferGroups,
  fetchOfferGroupOffers,
  groupOffers: _groupOffers,
} = useCommunityHub()
const { isBupaTemplate } = useProvider()

const fetching = ref(false)

const groupOffers = computed(
  () =>
    _groupOffers.value as {
      id: string
      name: string
      isActive: boolean
      isExpired: boolean
      isRedeemed: boolean
      retailer: {
        name: string
        logo: string
      }
    }[]
)

const swiperId = nanoid()
const enableSwipe = computed(() => groupOffers.value?.length > 1)
const autoplayDelay = computed(() => Number(props.autoplay))

const { swiperContainer, initSwiper, destroySwiper } = useSwiper(
  computed(() => ({
    navigation: {
      nextEl: `[data-id="${swiperId}"][data-button="next"]`,
      prevEl: `[data-id="${swiperId}"][data-button="prev"]`,
      disabledClass: 'opacity-20 cursor-default',
    },
    pagination: {
      clickable: true,
      el: `[data-id="${swiperId}"][data-button="pagination"]`,
      bulletClass:
        'ease h-3 w-3 rounded-md bg-eonx-neutral-200 transition-width duration-200',
      bulletActiveClass: 'bg-eonx-neutral-300',
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    loopedSlides: 5,
    spaceBetween: 10,
    autoplay: autoplayDelay.value
      ? {
          delay: autoplayDelay.value,
          disableOnInteraction: false,
        }
      : undefined,
    allowTouchMove: enableSwipe.value,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 15,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 15,
      },
    },
  }))
)

watch(swiperContainer, initSwiper)

const defaultImage = computed(() => {
  if (isBupaTemplate.value) {
    return getProviderDefaultUrl('bupa')
  }

  return 'https://i.ewalletcdn.com/45d490c5-96ca-43bc-99c6-621a63646755/'
})

function onClickSlide(index: number) {
  const offer = groupOffers.value[index]

  if (offer.isActive) {
    router.push({
      name: 'groups-community-hub-offer',
      params: { id: offer.id },
    })
  }
}

onMounted(async () => {
  fetching.value = true

  if (!groupOffers.value.length) {
    const groups = await fetchOfferGroups()

    await fetchOfferGroupOffers(groups[0].id)
  }

  fetching.value = false
})

onBeforeUnmount(destroySwiper)
</script>

<template>
  <section class="relative w-full overflow-hidden rounded-lg">
    <h2 class="font-semibold">{{ title }}</h2>
    <div v-if="fetching" class="my-5 flex space-x-[15px]">
      <div v-for="key in 5" :key="key" class="w-64 min-w-40 border">
        <div class="aspect-[3/2] w-full animate-pulse bg-zinc-300" />
        <div class="flex items-center justify-center px-10">
          <div class="my-10 h-5 w-full animate-pulse bg-zinc-300" />
        </div>
      </div>
    </div>
    <div v-else ref="swiperContainer" class="swiper-container">
      <ul class="swiper-wrapper mt-2.5 py-5 px-[5px]">
        <li
          v-for="(offer, index) in groupOffers"
          :key="index"
          class="swiper-slide relative bg-white shadow-md"
          :class="{
            'opacity-40': !offer.isActive,
            'cursor-pointer hover:opacity-80': offer.isActive,
          }"
          @click="onClickSlide(index)"
        >
          <base-image
            :src="
              cdn(offer.retailer.logo)
                .preview('446x298')
                .format('auto')
                .quality('smart')
                .url()
            "
            :default-src="defaultImage"
            :alt="offer.retailer.name"
            :ratio="3 / 2"
            :rounded="false"
            shadow
          />
          <div
            v-if="offer.isExpired"
            class="badge absolute top-0 right-0 -mt-2.5 -mr-[5px] rounded-full bg-red-700 px-2 pb-0.5 pt-[3px] text-xs font-semibold uppercase leading-4 text-white"
          >
            Expired
          </div>
          <div
            v-if="offer.isRedeemed"
            class="badge absolute top-0 right-0 -mt-2.5 -mr-[5px] rounded-full bg-emerald-700 px-2 pb-0.5 pt-[3px] text-xs font-semibold uppercase leading-4 text-white"
          >
            REDEEMED
          </div>
          <div
            class="min-h-20 p-2.5 text-center font-semibold sm:p-5 lg:text-lg"
          >
            {{ offer.name }}
          </div>
        </li>
      </ul>
      <template v-if="enableSwipe">
        <div class="flex w-full items-center justify-center space-x-2.5">
          <button
            :data-id="swiperId"
            data-button="prev"
            tabindex="-1"
            class="h-10 w-10 text-zinc-800 hover:text-zinc-600"
          >
            <base-icon
              svg="plain/chevron-right.v2"
              class="rotate-180"
              :size="34"
            />
          </button>
          <div
            class="swiper-pagination !relative !bottom-0 flex h-10 items-center justify-center space-x-2.5"
            :data-id="swiperId"
            data-button="pagination"
          />
          <button
            :data-id="swiperId"
            data-button="next"
            tabindex="-1"
            class="flex h-10 w-10 items-center justify-end text-zinc-800 hover:text-zinc-600"
          >
            <base-icon svg="plain/chevron-right.v2" :size="34" />
          </button>
        </div>
      </template>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.swiper-slide {
  @apply h-auto;
}
</style>
