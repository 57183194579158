<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'post-creator-tabs',
  components: {
    BaseIcon,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    tabs: {
      type: Array,
      default: () => ['post', 'poll', 'quiz'],
    },
  },
  data() {
    return {
      defaultTabs: [
        {
          id: 'post',
          title: 'Write a post',
          tooltip: 'Write a Post',
          icon: 'rec/pen',
        },
        {
          id: 'poll',
          title: 'Create a poll',
          tooltip: 'Make a Poll',
          icon: 'rec/check',
        },
        {
          id: 'quiz',
          title: 'Share a quiz',
          tooltip: 'Make a Quiz',
          icon: 'rec/question',
        },
      ],
    }
  },
  computed: {
    visibleTabs() {
      return this.defaultTabs.filter((tab) => {
        return !this.tabs || this.tabs.includes(tab.id)
      })
    },
    selectedTab() {
      return this.defaultTabs.find((tab) => tab.id === this.value)
    },
  },
}
</script>

<template>
  <div
    class="relative flex w-full flex-wrap items-center justify-between bg-white"
  >
    <div
      v-for="tab in visibleTabs"
      :key="tab.id"
      class="flex flex-1 items-center justify-center border-b-2 px-2.5 py-2.5 font-bold text-primary"
      :class="{
        'border-sepator': selectedTab,
        'border-transparent': !selectedTab,
        'cursor-default border-primary': value === tab.id,
        'cursor-pointer': value !== tab.id,
      }"
      :title="tab.tooltip"
      @click="$emit('input', tab.id)"
    >
      <base-icon v-if="tab.icon" :svg="tab.icon" class="mr-2.5" size="md" />
      <span>{{ tab.title }}</span>
    </div>
  </div>
</template>
