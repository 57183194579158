<script>
import DefaultGcFilterMobile from '/~/extensions/giftcards/components/gc-filter.mobile.vue'
import { useGcCinema } from '/~/extensions/gift-cards-cinema/composables'
import { computed, ref, watch } from 'vue'

export default {
  name: 'gift-cards-cinema-filter-mobile-v2',
  extends: DefaultGcFilterMobile,
  setup() {
    const {
      gcCinemaCategories,
      gcCinemaSearchQuery,
      gcCinemaSelectedCardType,
      gcCinemaSelectedSorting,
      gcCinemaSelectedCategory,
      gcCinemaCardTypes,
      gcCinemaSortValues,
      gcCinemaDefaultSortValues,
      isGcCinemaFiltersSelected,
      resetGcCinemaFilters,
      gcCinemaSearchGroup,
    } = useGcCinema()

    const localSearchQuery = ref(gcCinemaSearchQuery.value)
    const localSorting = ref(gcCinemaSelectedSorting.value)
    const localCategory = ref(gcCinemaSelectedCategory.value)

    const searchPlaceholder = computed(
      () => `Search ${gcCinemaSearchGroup.value}`
    )

    watch(gcCinemaSelectedCardType, () => {
      resetGcCinemaFilters()
    })

    function resetLocalFilters() {
      localSearchQuery.value = gcCinemaSearchQuery.value
      localSorting.value = gcCinemaSelectedSorting.value
      localCategory.value = gcCinemaSelectedCategory.value
    }

    function applyFilters() {
      gcCinemaSearchQuery.value = localSearchQuery.value
      gcCinemaSelectedSorting.value = localSorting.value
      gcCinemaSelectedCategory.value = localCategory.value
    }

    function clearFilters() {
      resetGcCinemaFilters()
      resetLocalFilters()
    }

    return {
      ...DefaultGcFilterMobile.setup?.(...arguments),

      cardTypes: gcCinemaCardTypes,
      categories: gcCinemaCategories,
      sortValues: gcCinemaSortValues,
      defaultSortValue: gcCinemaDefaultSortValues,
      selectedCardType: gcCinemaSelectedCardType,
      searchGroup: gcCinemaSearchGroup,
      searchPlaceholder,

      localSearchQuery,
      localSorting,
      localCategory,

      isFiltersSelected: isGcCinemaFiltersSelected,
      resetFilters: resetGcCinemaFilters,
      clearFilters,
      resetLocalFilters,
      applyFilters,
    }
  },
}
</script>
