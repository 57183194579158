<script>
import BaseBox from '/~/components/base/box/base-box.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { computed } from 'vue'

export default {
  name: 'community-hub-retailer-offers',
  components: {
    BaseBox,
    BaseLink,
  },
  props: {
    offer: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const orderType = computed(() => {
      switch (props.offer.type) {
        case 'url':
          return 'Third-Party Offer'
        case 'coupon':
          return 'Unique Coupon Code'
        case 'show':
          return 'Check-out Codes'
      }
      return ''
    })

    return {
      orderType,
    }
  },
}
</script>

<template>
  <base-box hide-header class="h-full">
    <div class="mx-5 mt-[5px] h-full sm:mx-0">
      <div class="font-bold text-neutral-500">{{ orderType }}</div>
      <h2
        class="mb-[15px] block text-3xl font-semibold leading-10 text-zinc-800"
      >
        {{ offer.retailer.name }}
      </h2>
      <div class="text-zinc-800">
        {{ offer.retailer.description }}
      </div>
      <div class="bottom-0 sm:absolute">
        <base-link
          v-if="offer.retailer.website"
          :href="offer.retailer.website"
          class="block overflow-hidden truncate font-bold leading-8"
          look="underline"
        >
          {{ offer.retailer.website.replace(/^https?:\/\/|\/$/g, '') }}
        </base-link>
      </div>
    </div>
  </base-box>
</template>
