<script>
import EwDetailsPlaceholder from './ew-details-placeholder.vue'
import EwDetailsHeader from '../ew-details-header.vue'
import BaseBack from '/~/components/base/back/base-back.vue'

export default {
  name: 'ew-details-layout-v1',
  components: {
    EwDetailsPlaceholder,
    EwDetailsHeader,
    BaseBack,
  },
  props: {
    ready: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    back: {
      type: Object,
      default: null,
    },
    placeholderRatio: {
      type: [Boolean, Number],
      default: 16 / 10,
    },
  },
}
</script>

<template>
  <div
    class="mx-auto mb-20 w-full sm:max-w-full sm:px-5 sm:pb-8 md:px-12 lg:w-lg"
  >
    <div class="hidden items-center py-8 sm:flex">
      <base-back :to="back" />
    </div>

    <ew-details-header :title="title" :loading="loading" :back="back" />

    <ew-details-placeholder v-if="loading" :ratio="placeholderRatio" />

    <template v-else-if="ready">
      <div class="w-full grid-cols-1 gap-6 sm:grid sm:grid-cols-3 sm:gap-10">
        <div class="w-full max-w-full">
          <slot name="gallery" />
        </div>
        <div class="sm:col-span-2 sm:row-span-6">
          <slot name="summary" />
        </div>
        <div>
          <slot name="details" />
        </div>
      </div>
    </template>
  </div>
</template>
