<script>
import { computed } from 'vue'
import RetailerProduct from '/~/extensions/giftcards/components/product/retailer-product.vue'
import { useGiftcards, useRetailer } from '/~/extensions/giftcards/composables'
import { getProviderDefaultUrl } from '/~/utils/cdn'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import RetailerDetails from '/~/components/retailer/retailer-details.vue'
import RetailerEmpty from '/~/components/retailer/retailer-empty.vue'
import RetailerLogo from '/~/components/retailer/retailer-logo.vue'
import { useAddresses } from '/~/composables/addresses'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'giftcatds-retailer',
  components: {
    RetailerLogo,
    RetailerEmpty,
    RetailerDetails,
    RetailerProduct,
    EwDetailsLayout,
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    tabsProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { getAddresses } = useAddresses()
    const { routeState } = useGiftcards()
    const { getRetailer, getRetailerOffers } = useRetailer()
    const { isBupaTemplate } = useProvider()

    const defaultImage = computed(() => {
      if (isBupaTemplate.value) {
        return getProviderDefaultUrl('bupa')
      }

      return undefined
    })

    return {
      getAddresses,
      routeState,
      getRetailer,
      getRetailerOffers,
      defaultImage,
    }
  },
  data() {
    return {
      offer: null,
      loading: true,
      empty: false,
      retailer: null,
    }
  },
  computed: {
    backRoute() {
      return {
        name: 'giftcards',
        ...this.routeState,
      }
    },
    name() {
      return this.retailer?.attributes?.name ?? ''
    },
    image() {
      return this.retailer?.attributes?.images?.opt_logo
    },
    logoUrl() {
      return this.retailer?.attributes?.website ?? ''
    },
    ready() {
      return this.retailer !== null
    },
  },
  watch: {
    $route(to, from) {
      if (to.hash || from.hash) return

      this.fetchOffer(to.params.slug, to.params.id)
    },
  },
  async created() {
    this.getAddresses()
    await this.fetchOffer(this.slug, this.id)

    if (this.name) {
      this.$emit('set-title', this.name)
    }
  },
  methods: {
    async fetchOffer(slug, id) {
      this.loading = true
      const [error, retailer] = await this.getRetailer(slug)

      if (error) {
        this.empty = true
        return
      }

      const retailerOffer = retailer?.relationships?.offers?.data?.[0]

      this.retailer = retailer

      if (retailerOffer) {
        this.offer = await this.getRetailerOffers({
          slug,
          offer: id || retailerOffer.id,
        })
      }
      this.loading = false
    },
  },
}
</script>

<template>
  <div class="min-h-full pb-[5px]">
    <retailer-empty v-if="empty" class="px-5" />
    <ew-details-layout
      v-else
      :loading="loading"
      :ready="ready"
      :title="name"
      data-cy="giftcard-title"
      :class="['mb-0 pt-5 md:pt-0', loading && 'px-5']"
      :back="backRoute"
    >
      <template #gallery>
        <retailer-logo :image="image" :alt="name" :default-src="defaultImage" />
      </template>

      <template #details>
        <retailer-details
          :offer="offer"
          :retailer="retailer"
          :url="logoUrl"
          :loading="loading"
          :tabs-props="tabsProps"
          class="px-5 sm:px-0"
        />
      </template>

      <template #summary>
        <retailer-product
          type="giftcard"
          :offer="offer"
          :retailer="retailer"
          :loading="loading"
        />
      </template>
    </ew-details-layout>
  </div>
</template>
