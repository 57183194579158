<script>
import DrawerAction from './drawer-action.v4.vue'

export default {
  name: 'drawer-actions',
  components: {
    DrawerAction,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visibleActions() {
      return this.actions.filter((action) => action.hidden !== true)
    },
    hasActions() {
      return this.visibleActions.length > 0
    },
  },
}
</script>

<template>
  <div v-if="hasActions" class="space-y-2.5 py-8">
    <drawer-action
      v-for="action in visibleActions"
      :key="action.label"
      :disabled="disabled"
      :action="action"
    />
  </div>
</template>
