<script setup lang="ts">
import { useProvider } from '/~/composables/provider'
import SummaryAddresses from './summary/summary-addresses.vue'
import SummaryPayTo from './summary/summary-pay-to.vue'
import SummaryPayWith from './summary/summary-pay-with.vue'
import SummaryPointsEarned from './summary/summary-points-earned.vue'
import SummaryTotal from './summary/summary-total.vue'

const { isUserAddressEnabled } = useProvider()
</script>

<template>
  <div class="flex h-full w-full max-w-xl flex-col gap-y-5 overflow-y-auto p-5">
    <summary-points-earned />
    <summary-addresses v-if="isUserAddressEnabled" />
    <summary-pay-to />
    <hr />
    <summary-pay-with />
    <summary-total />
  </div>
</template>
