<script>
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'

export default {
  name: 'rec-placeholder-feed',
  components: {
    BasePlaceholder,
  },
}
</script>

<template>
  <div class="rounded-md bg-white p-5 pb-[15px]">
    <div class="mb-[15px] flex w-full">
      <div class="mr-2.5">
        <base-placeholder :width="48" :height="48" rounded-full theme="dark" />
      </div>
      <div class="flex max-w-48 grow flex-col">
        <div class="mb-[5px]">
          <base-placeholder :height="22" theme="dark" />
        </div>
        <div>
          <base-placeholder :height="22" theme="dark" />
        </div>
      </div>
      <div class="ml-auto mt-[5px] pl-5">
        <base-placeholder :width="20" :height="30" rounded-full theme="dark" />
      </div>
    </div>
    <div class="mb-2.5">
      <base-placeholder :height="22" theme="dark" />
    </div>
    <div class="flex">
      <base-placeholder :width="150" :height="24" theme="dark" />
    </div>
  </div>
</template>
