<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import Hi5Tile from '/~rec/components/hi5/hi5-tile.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'
import RecognitionLayout from '/~rec/layouts/view/recognition-layout.vue'
import RecognitionHowEarnPoints from '/~rec/components/recognition/recognition-how-earn-points.vue'
import HowToEarnMobile from '/~rec/modals/earn/components/earn-how-to-earn-mobile.vue'
import { useHi5 } from '/~rec/composables/hi5'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-hi5-view',
  components: {
    EwCatalogProcessor,
    Hi5Tile,
    RecBaseState,
    RecognitionLayout,
    RecognitionHowEarnPoints,
    HowToEarnMobile,
  },
  props: {
    perkId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { hi5 } = useHi5()
    const { displayPointsPopup, currency, orgMemberId } = useRecProfile()

    return {
      hi5,
      displayPointsPopup,
      currency,
      orgMemberId,
      ui,
    }
  },
  data() {
    return {
      selectedFilter: 'recent',
      availableFilterOptions: [
        { text: 'Most recent', value: 'recent' },
        { text: 'Most popular', value: 'popular' },
        { text: 'A-Z', value: 'asc' },
      ],
      restFilterOptions: [
        { text: 'Most recent', value: 'recent' },
        { text: 'A-Z', value: 'asc' },
      ],
    }
  },
  computed: {
    isDesktop() {
      return ui.desktop
    },
    processor() {
      switch (this.activeTab) {
        case 'sent':
          return this.hi5.sent
        case 'received':
          return this.hi5.received
        default:
          return this.hi5.available
      }
    },
    activeTab() {
      const { params, query } = this.$route

      return params.type || query.type || 'available'
    },
    payload() {
      if (this.activeTab !== 'available') {
        return {
          query: {
            [this.activeTab === 'received' ? 'recipient' : 'sender']:
              this.orgMemberId,
          },
        }
      }
      return {}
    },
    filterOptions() {
      return this.activeTab === 'available'
        ? this.availableFilterOptions
        : this.restFilterOptions
    },
  },
  watch: {
    activeTab: {
      handler() {
        this.getHits()

        if (!this.filterOptions.find((v) => v.value === this.selectedFilter)) {
          this.selectedFilter = 'recent'
        }
      },
      deep: true,
    },
  },
  async created() {
    await Promise.all([
      this.hi5.available.getData({
        filter: this.selectedFilter,
      }),
      this.hi5.sent.getData({
        query: {
          sender: this.orgMemberId,
        },
        filter: this.selectedFilter,
      }),
      this.hi5.received.getData({
        query: {
          recipient: this.orgMemberId,
        },
        filter: this.selectedFilter,
      }),
    ])

    if (this.displayPointsPopup) this.openEarnInfoModal()
  },
  methods: {
    onSelectFilter(filter) {
      this.selectedFilter = filter
      this.getHits({
        ...this.payload,
        filter: this.selectedFilter,
      })
    },
    getHits() {
      this.processor.getData({
        ...this.payload,
        filter: this.selectedFilter,
      })
    },
    onHisClick(hi5) {
      this.$router.push({
        name:
          this.activeTab === 'available'
            ? 'rec-hi5s-available'
            : 'rec-hi5-details',
        params: {
          hi5Id: hi5.id,
          hi5Status: this.activeTab,
        },
      })
    },
    openEarnInfoModal() {
      modal.show('rec-earn-info', {
        props: {
          mode: 'hi5s',
        },
      })
    },
  },
}
</script>

<template>
  <div class="w-full">
    <recognition-layout
      title="Hi-5s"
      :filter-options="filterOptions"
      :selected-filter="selectedFilter"
      :loading="processor.processing"
      @filter-selected="onSelectFilter"
    >
      <template #headerActions>
        <recognition-how-earn-points @open-earn-info="openEarnInfoModal" />
      </template>
      <ew-catalog-processor
        :processor="processor"
        :columns="{
          xxs: 1,
          xs: 2,
          lg: 3,
          xl: 4,
        }"
        tile-class="rounded-lg shadow-xs cursor-pointer hover:shadow-lg hover:-translate-y-[5px] bg-light"
      >
        <template #empty>
          <rec-base-state
            v-if="activeTab === 'sent' || activeTab === 'available'"
            class="mb-0 mt-14 md:mt-[120px]"
            image="recroom/state/hi5-sent-empty-state.svg"
            title="You have not sent a Hi-5 yet"
            subtitle="Send people a Hi-5 to let them know how awesome they are"
          />

          <rec-base-state
            v-if="activeTab === 'received'"
            class="mb-0 mt-14 md:mt-[120px]"
            image="recroom/state/hi5-received-empty-state.svg"
            title="You have not received a Hi-5 yet"
            subtitle="Hi-5s that you have received will be displayed here"
          />
        </template>

        <template #tile="{ tile: hi }">
          <hi5-tile
            v-if="hi"
            :hi="hi"
            :type="activeTab"
            :currency="currency"
            @click="onHisClick(hi)"
          />
        </template>
      </ew-catalog-processor>
    </recognition-layout>
    <how-to-earn-mobile
      v-if="!isDesktop"
      @show-how-to-earn="openEarnInfoModal"
    />
  </div>
</template>
