import { RewardsExtension } from '/~/core/extension'

class ThirdPartyLoginExtension extends RewardsExtension {
  setRoutes() {
    return [
      {
        path: '/third-party-login/agreement',
        name: 'third-party-agreement',
        component: () => import('./views/agreement.vue'),
      },
    ]
  }
}

export default ThirdPartyLoginExtension
