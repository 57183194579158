export const urlPattern =
  /(\b(https?):\/\/|\b(www\.))([-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])+($|\b|\B)/gim

function parseUrl(data: string) {
  const url = new URL(data)
  const host = url.host.replace(/^(www\.)/, '')

  if (url.pathname === '/') {
    return host
  }

  return host + url.pathname
}

function makeLink(link: string, url: string) {
  return `<a class="text-primary" href="${link}" target="_blank">${url}</a>`
}

function processTextForUrls(text: string) {
  if (!text) {
    return text
  }
  const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim

  return text
    .replace(urlPattern, (match: string, p1: string) => {
      if (p1 === 'www.') {
        match = 'http://' + match
      }

      return makeLink(match, parseUrl(match))
    })
    .replace(
      emailAddressPattern,
      '<a class="underline text-primary" href="mailto:$&" target="_blank">$&</a>'
    )
}

export default processTextForUrls
