export default {
  extensions: {
    dining: {
      parentRoute: 'rewards',
      templateName: 'bill-payments',
    },
    estore: {
      parentRoute: 'rewards',
      templateName: 'bill-payments',
    },
    'gift-cards': {
      parentRoute: 'rewards',
      templateName: 'bill-payments',
    },
    'gift-cards-cinema': {
      parentRoute: 'rewards',
      templateName: 'bill-payments',
    },
    'community-hub': {
      parentRoute: 'rewards',
      templateName: 'default',
    },
    competitions: {
      parentRoute: 'rewards',
      templateName: 'default',
    },
  },
}
