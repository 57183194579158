<script setup lang="ts">
import { formatDollar } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'

defineProps<{
  balance: string
}>()

const { ewalletLabels } = useCms()
</script>

<template>
  <div>
    <div
      class="relative rounded-lg border border-eonx-neutral-200 bg-white px-4 py-2"
    >
      <div class="flex items-center justify-between">
        <div
          class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-black"
        >
          <base-icon :svg="'ewallet'" size="sme" class="text-white" />
        </div>
        <div class="mx-4 flex w-full flex-col gap-y-0.5">
          <span class="font-bold text-eonx-neutral-800">
            {{ ewalletLabels.ewalletCash }}
          </span>
          <span class="text-sm text-eonx-neutral-800">
            Balance {{ formatDollar(balance) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
