<script>
import ui from '/~/core/ui'
import CheckoutMethodDesktop from './checkout-method.desktop.vue'
import CheckoutMethodMobile from './checkout-method.mobile.vue'

export default {
  name: 'checkout-method',
  functional: true,
  render(h, ctx) {
    let component

    if (ui.mobile) {
      component = CheckoutMethodMobile
    } else {
      component = CheckoutMethodDesktop
    }

    return h(component, ctx.data, ctx.children)
  },
}
</script>
