<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import debounce from 'lodash-es/debounce'
import BaseInput from '/~/components/base/input/base-input.vue'
import UsersList from '/~rec/components/users/users-list.vue'
import UsersSelectedMobile from '/~rec/components/users/users-selected-mobile.vue'
import BaseFab from '/~/components/base/fab/base-fab.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import BaseButton from '/~/components/base/button/base-button'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'users-manager-modal',
  components: {
    BaseMdl,
    HeaderMobile,
    UsersList,
    BaseInput,
    UsersSelectedMobile,
    BaseFab,
    BaseButton,
  },
  props: {
    title: {
      type: [String, Function],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabledLabel: {
      type: String,
      default: '',
    },
    hideCurrent: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: null,
    },
    filter: {
      type: Function,
      default: (v) => v,
    },
    multi: {
      type: Boolean,
      default: false,
    },
    getCurrentUsers: {
      type: Function,
      default: () => [],
    },
    removable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    removeHandler: {
      type: Function,
      default: () => ({}),
    },
    disableUser: {
      type: Function,
      default: () => false,
    },
    showAddList: {
      type: Boolean,
      default: false,
    },
    fromGroup: {
      type: Boolean,
      defaul: false,
    },
    slotData: {
      type: Object,
      default: () => ({
        component: null,
        params: {},
      }),
    },
  },
  setup() {
    const { myRecId, isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return {
      myRecId,
      isMeAdmin,
      isMeSuperAdmin,
      ui,
    }
  },
  data() {
    return {
      searchValue: '',
      selectedUsers: [...this.getCurrentUsers()],
      showAddUsersList: this.showAddList,
    }
  },
  computed: {
    showFab() {
      return (
        this.selectable &&
        this.multi &&
        !!this.selectedUsers.length &&
        !ui.desktop
      )
    },
    finalTitle() {
      return this.title instanceof Function ? this.title() : this.title
    },
    hiddenUsers() {
      return this.hideCurrent ? this.getCurrentUsers().map((u) => u.id) : []
    },
    disableNextBtn() {
      const initialUsersIds = this.getCurrentUsers().map((v) => v.id)
      const currentUsersIdx = this.selectedUsers.map((v) => v.id)

      return (
        this.showAddUsersList &&
        initialUsersIds.sort().toString() === currentUsersIdx.sort().toString()
      )
    },
  },
  watch: {
    currentUser(value) {
      if (value) {
        this.onUserSelect(value)
      }
    },
  },
  methods: {
    onUserSelect(u) {
      if (this.multi) {
        this.selectedUsers = u
      } else {
        this.$emit('user-select', u)
      }
    },
    onUserUnselect(user) {
      const idx = this.selectedUsers.indexOf(user)

      this.selectedUsers.splice(idx, 1)
    },
    back() {
      modal.hide()
      this.$emit('back')
    },
    onSearchInput: debounce(function (searchValue) {
      this.searchValue = searchValue
    }, 150),
    onNext() {
      if (this.showAddUsersList) {
        modal.hide()
        this.$emit('user-select', this.selectedUsers)
      } else {
        this.showAddUsersList = true
      }
    },
    async onRemove(data) {
      await this.removeHandler(data)

      this.selectedUsers = this.getCurrentUsers()
    },
    onGroupAdd() {
      modal.show('rec-groups-manager', {
        props: {
          title: 'Add all members from',
          features: 'members',
          onlyMyGroups: !this.isMeAdmin && !this.isMeSuperAdmin,
        },
        on: {
          save: (users) => {
            users.forEach((user) => {
              if (
                this.selectedUsers.map((u) => u.id).includes(user.id) ||
                this.myRecId === user.id
              )
                return
              this.selectedUsers.push(user)
            })
          },
        },
      })
    },
    onSave() {
      modal.hide()
      this.$emit('user-select', this.selectedUsers)
    },
  },
}
</script>

<template>
  <base-mdl
    transition="slide-right"
    fullscreen
    :padding="false"
    :width="ui.desktop ? 'sm' : null"
    :height="ui.desktop ? 'lg' : null"
    modal-class="md:pb-[88px] md:relative"
  >
    <template #header>
      <div>
        <header-mobile :title="finalTitle" @back="back" />

        <div class="px-5">
          <h2
            v-if="label"
            class="mt-[15px] mb-[5px] text-base leading-6 text-eonx-neutral-800"
          >
            {{ label }}
          </h2>

          <div class="mb-[15px] flex w-full items-center">
            <span class="pr-[30px] text-base leading-6 text-eonx-neutral-600">
              You can either use the search bar or simple select from the list
              below
            </span>

            <base-button
              v-if="fromGroup"
              size="sm"
              class="ml-auto"
              @click="onGroupAdd"
            >
              <span class="text-sm">Add a group</span>
            </base-button>
          </div>
        </div>

        <users-selected-mobile
          v-if="showAddUsersList"
          exclude-me
          :hidden-users="hiddenUsers"
          :selected-users="selectedUsers"
          class="px-5"
          @unselect="onUserUnselect"
        />

        <div class="px-5">
          <base-input
            :value="searchValue"
            style="padding: 0.5rem 0 !important; margin: 0 !important"
            look="recroom"
            icon="plain/search"
            placeholder="Search member"
            icon-plain
            clearable
            @input="onSearchInput"
          />
        </div>
      </div>
    </template>
    <div class="px-5">
      <users-list
        v-if="showAddUsersList"
        :multi="multi"
        :current-users="selectedUsers"
        :search-value="searchValue"
        :removable="false"
        :disabled-label="disabledLabel"
        :remove-handler="onRemove"
        :disable-user="disableUser"
        exclude-me
        selectable
        @user-select="onUserSelect"
      />
      <users-list
        v-else
        :multi="multi"
        :filter-users="filter"
        :current-users="selectedUsers"
        :search-value="searchValue"
        :removable="removable"
        :remove-handler="onRemove"
        :selectable="selectable"
        exclude-me
        @user-select="onUserSelect"
      >
        <template #user="{ user }">
          <component
            :is="slotData.component"
            v-if="slotData.component"
            v-bind="slotData.params"
            :user="user"
          />
        </template>
      </users-list>
    </div>
    <base-fab
      v-if="showFab"
      margin="m-[15px] mr-6"
      :disabled="disableNextBtn"
      :icon="showAddUsersList ? 'rec/check-mark' : 'plus'"
      @click="onNext"
    />
    <div
      v-if="ui.desktop"
      class="absolute bottom-0 flex w-full justify-center p-5"
    >
      <base-button
        class="ml-0"
        :disabled="!selectedUsers.length"
        @click="onSave"
      >
        Save
      </base-button>
    </div>
  </base-mdl>
</template>
