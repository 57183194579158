<script setup lang="ts">
import { useCms } from '/~/composables/cms'
import SummaryTotal from '/~/views/checkout.v2/components/checkout/summary/summary-total.vue'
import SummaryPayTo from './summary/summary-pay-to-quick-buy.vue'
import SummaryPayWith from './summary/summary-pay-with.vue'

const { ewalletLabels } = useCms()
</script>

<template>
  <div class="flex h-full w-full max-w-md flex-col">
    <div class="flex h-full flex-col gap-y-4 overflow-y-auto p-5">
      <div class="font-bold">Items</div>
      <summary-pay-to />
      <hr />
      <summary-pay-with />
      <hr />
      <div class="text-eonx-neutral-600">
        *Your transaction will be tracked in order to facilitate
        {{ ewalletLabels.yourEwallet }}
        Cashback which may take up to 120 days.
      </div>
    </div>

    <summary-total :is-amount-visible="false" />
  </div>
</template>
