<script>
import { nextTick } from 'vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import { useRecUi } from '/~rec/composables/ui'

export default {
  name: 'rec-section-tabs',
  components: {
    BaseTabs,
    BaseTab,
  },
  props: {},
  setup() {
    const { allMobileSections } = useRecUi()

    return { allMobileSections }
  },
  computed: {
    currentSection() {
      return this.allMobileSections.find((s) => s.route === this.$route.name)
    },
    currentSectionTabs() {
      return this.currentSection?.tabs
    },
    sectionRouteParams() {
      return this.currentSection?.routeParams || {}
    },
    activeTab() {
      const { type, name } = this.sectionRouteParams

      return this.$route[type]?.[name] || this.currentSectionTabs?.[0]?.value
    },
    tabsWidth() {
      return `minWidth: ${
        (this.currentSectionTabs?.length || 0) *
          this.currentSection?.tabWidth || 100
      }px`
    },
  },
  watch: {
    activeTab() {
      this.scrollToActiveMenuItem()
    },
  },
  mounted() {
    this.scrollToActiveMenuItem()
  },
  methods: {
    onTabChange(tab) {
      const { type, name } = this.sectionRouteParams
      const payload = { [type]: { [name]: tab } }

      this.$router.replace(payload)
    },
    scrollToActiveMenuItem() {
      nextTick(() => {
        const activeMenuItem = this.$el.querySelector('.base-tab-active')

        if (activeMenuItem) {
          activeMenuItem.scrollIntoView({ block: 'start', inline: 'center' })
        }
      })
    },
  },
}
</script>
<template>
  <div class="overflow-x-auto">
    <base-tabs
      :key="$route.name"
      v-bind="$attrs"
      class="text-eonx-neutral-800"
      :value="activeTab"
      :style="tabsWidth"
      @input="onTabChange"
    >
      <base-tab
        v-for="tab in currentSectionTabs"
        :key="tab.value"
        :name="tab.name"
        :value="tab.value"
      />
    </base-tabs>
  </div>
</template>
