import Vue from 'vue'
import panZoom from 'vue-panzoom'
import mdl from '/~/core/mdl'
import router from '/~/router'
import modals from './modals'
import routes from './routes'

mdl.register(modals)

Vue.use(panZoom)

routes.forEach((route) => {
  router.addRoute(route)
})
