<script>
import DefaultProductAdd from '/~/extensions/giftcards/components/product/add/product-add.vue'

export default {
  extends: DefaultProductAdd,
  props: {
    justify: {
      type: String,
      default: 'end',
    },
  },
}
</script>
