<script>
import { ValidationObserver } from 'vee-validate'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import { useAddressForm } from '/~/composables/addresses'
import { useForm } from '/~/composables/base/use-form'
import { useLocalization } from '/~/composables/localization'

export default {
  name: 'retailer-gift-physical',
  components: {
    BaseInput,
    BaseSelect,
    BaseTextarea,
    ValidationObserver,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    backendErrors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      countriesOptions,
      postcodeBindings,
      stateBindings,
      suburbBindings,
    } = useAddressForm()
    const { states, translate } = useLocalization()
    const { validationObserverRef } = useForm()

    return {
      countriesOptions,
      postcodeBindings,
      stateBindings,
      suburbBindings,
      states,
      translate,
      validationObserverRef,
    }
  },
  computed: {
    mappedStates() {
      return this.states.map((item) => ({
        value: item.label,
        text: item.label,
      }))
    },
  },
}
</script>

<template>
  <validation-observer ref="validationObserverRef" slim>
    <form class="grid grid-cols-1 gap-x-5 sm:grid-cols-2" data-cy="form">
      <base-input
        :validation="{
          rules: 'required',
          name: `Recipient's Name`,
          mode: 'aggressive',
          vid: 'name',
        }"
        :value="form.name"
        :error="backendErrors.name"
        label="Recipient's Name"
        required
        name="name"
        placeholder="John Smith"
        data-cy="gift-physical-input"
        :disabled="disabled"
        @input="$emit('name', $event)"
      />

      <base-input
        :validation="{
          rules: 'required|email',
          name: 'Recipient Email',
          mode: 'aggressive',
          vid: 'email',
        }"
        :value="form.email"
        :error="backendErrors.email"
        name="email"
        label="Recipient Email"
        required
        placeholder="name@example.com"
        data-cy="gift-physical-input"
        :disabled="disabled"
        @input="$emit('email', $event)"
      />

      <base-input
        :validation="{
          rules: 'required|mobile',
          name: 'Mobile',
          mode: 'aggressive',
          vid: 'mobile',
        }"
        :value="form.mobile"
        :error="backendErrors.mobile"
        mask="mobile"
        label="Mobile"
        required
        name="mobile"
        placeholder="0400 000 000"
        data-cy="gift-physical-input"
        :disabled="disabled"
        @input="$emit('mobile', $event)"
      />

      <base-input
        :validation="{
          rules: 'required',
          name: 'Street Address',
          mode: 'aggressive',
          vid: 'streetAddress',
        }"
        :value="form.streetAddress"
        :error="backendErrors.streetAddress"
        label="Street Address"
        required
        name="streetAddress"
        data-cy="gift-physical-input"
        :disabled="disabled"
        :maxlength="100"
        @input="$emit('streetAddress', $event)"
      />

      <base-input
        v-bind="suburbBindings"
        :validation="{
          ...suburbBindings.validation,
          rules: 'required',
          mode: 'aggressive',
          vid: 'suburb',
        }"
        :value="form.suburb"
        :error="backendErrors.suburb"
        required
        name="suburb"
        data-cy="gift-physical-input"
        :disabled="disabled"
        @input="$emit('suburb', $event)"
      />

      <div>
        <!-- input helps autofill following select -->
        <!-- hidden from user, cannot be invisible or hidden by code -->
        <div class="absolute h-0 w-0 opacity-0">
          <base-input :value="form.state" tabindex="-1" :disabled="disabled" />
        </div>
        <base-select
          v-bind="stateBindings"
          :validation="{
            ...stateBindings.validation,
            mode: 'aggressive',
            vid: 'state,',
          }"
          :value="form.state"
          :options="mappedStates"
          :error="backendErrors.state"
          name="state"
          :disabled="disabled"
          @input="$emit('state', $event)"
        />
      </div>

      <base-input
        v-bind="postcodeBindings"
        :validation="{
          ...postcodeBindings.validation,
          rules: 'required|postcode',
          name: translate('address.postcode'),
          mode: 'aggressive',
          vid: 'postcode',
        }"
        :value="form.postcode"
        :error="backendErrors.postcode"
        required
        name="postcode"
        :label="translate('address.postcode')"
        data-cy="gift-physical-input"
        :disabled="disabled"
        @input="$emit('postcode', $event)"
      />

      <div class="sm:col-span-2">
        <base-textarea
          :value="form.message"
          :error="backendErrors.message"
          name="message"
          label="Message"
          placeholder="Message..."
          :rows="3"
          data-cy="gift-digital-input"
          :disabled="disabled"
          @input="$emit('message', $event)"
        />
      </div>
    </form>
  </validation-observer>
</template>
