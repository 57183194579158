<script>
export default {
  name: 'health-layout-mobile',
}
</script>

<template>
  <div class="w-full">
    <slot />
  </div>
</template>
