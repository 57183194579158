<script setup lang="ts">
import { computed, ref, onBeforeMount } from 'vue'
import { pluralize } from '/~/utils/format/string'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useStatements } from '/~/composables/statements'
import MessagePanel from '/~/templates/bill-payments/components/message-panel.vue'

const {
  activeItems,
  fetchActiveStatements,
  isStatementsEnabled,
  loadingActiveSatements,
} = useStatements()

const closeNotify = ref(
  Boolean(sessionStorage?.getItem('activeStatementNotify'))
)

const currentActiveStatement = computed<any>(() =>
  activeItems.value[0]?.dueInDays < 11 ? activeItems.value[0] : null
)
const showStatementsNotification = computed(
  () => currentActiveStatement.value && !closeNotify.value
)

function closeStatementNotify() {
  closeNotify.value = true
  sessionStorage?.setItem('activeStatementNotify', 'true')
}

onBeforeMount(async () => {
  if (isStatementsEnabled.value) {
    await fetchActiveStatements({
      'order[dueDate]': 'asc',
    })
  }
})
</script>

<template>
  <div v-if="showStatementsNotification && isStatementsEnabled">
    <base-loader v-if="loadingActiveSatements" fullwidth />
    <message-panel
      v-else
      type="warning"
      icon="plain/clock-v3"
      @close="closeStatementNotify"
    >
      <p>
        You have unpaid
        {{ currentActiveStatement.statementDateMonthName }}

        <span v-if="currentActiveStatement.dueInDays > 0">
          <span>statements due in</span>
          <b>
            {{ currentActiveStatement.dueInDays }}
            {{ pluralize(currentActiveStatement.dueInDays, 'day') }}.
          </b>
        </span>
        <span v-else>statements.</span>
      </p>
    </message-panel>
  </div>
</template>
