<script>
import Vue, { computed, nextTick } from 'vue'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.v3.vue'
import { useCms } from '/~/composables/cms'
import { useLoyaltyCards } from '/~/composables/loyalty-cards'
import LoyaltyDetailsV1 from '/~/drawer/loyalty-cards/loyalty-details.vue'

export default {
  name: 'loyalty-memberships-details',
  components: {
    BaseAsidePage,
    DrawerActions,
  },
  extends: LoyaltyDetailsV1,
  setup(props) {
    const router = useRouter()
    const { isDarkThemeForEwallet } = useCms()
    const { deleteLoyaltyCard } = useLoyaltyCards()

    const actions = computed(() => {
      return [
        {
          label: 'Edit',
          description: 'Edit the details or images of your card',
          icon: 'v2/heroic/pencil-alt',
          click: () => {
            router.push({
              hash: `#profile-loyalty-card-edit/${props.id}`,
            })
          },
        },
        {
          label: 'Delete',
          description: 'Delete this loyalty or member card',
          icon: 'v2/heroic/trash',
          backgroundColor: 'bg-red-700',
          click: () => {
            bottomSheet.show('confirmation-modal', {
              to: 'menu-modal',
              props: {
                icon: 'points-only/trash',
                title: 'Are you sure you want to delete this Loyalty Card?',
                text: 'By deleting this Loyalty Card you will remove the card from your Loyalty Card permanently.',
                confirmButtonText: 'Delete Loyalty Card',
                onConfirm: onDeleteConfirmationModalConfirm,
              },
            })
          },
        },
      ]
    })

    async function onDeleteConfirmationModalConfirm() {
      try {
        await deleteLoyaltyCard(props.id)

        Vue.notify({
          text: 'Card Deleted',
          type: 'success',
          duration: 1000,
        })

        router.replace({ hash: '#profile-loyalty-memberships' })
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong while deleting. Please try again.',
        })
      }
    }

    return {
      ...LoyaltyDetailsV1.setup?.(...arguments),
      isDarkThemeForEwallet,
      actions,
    }
  },
  methods: {
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    :title="loyaltyCard ? loyaltyCard.name : 'Loyalty Card'"
    no-padding
  >
    <div v-if="isViewReady" class="overflow-hidden pt-[30px]">
      <div
        v-if="loyaltyCard.hasManyImages"
        ref="swiperContainer"
        class="swiper-container relative my-5 w-full"
      >
        <div class="swiper-wrapper flex items-center">
          <loyalty-card
            class="swiper-slide max-w-xs overflow-hidden rounded-xl bg-divider"
            :card="loyaltyCard"
          />
          <base-card
            v-if="loyaltyCard.frontImage.src"
            class="swiper-slide max-w-xs overflow-hidden rounded-xl bg-divider"
          >
            <base-image
              v-bind="loyaltyCard.frontImage"
              :alt="loyaltyCard.name"
            />
          </base-card>
          <base-card
            v-if="loyaltyCard.backImage.src"
            class="swiper-slide max-w-xs overflow-hidden rounded-xl bg-divider"
          >
            <base-image
              v-bind="loyaltyCard.backImage"
              :alt="loyaltyCard.name"
            />
          </base-card>
        </div>
        <div
          :data-id="swiperId"
          data-button="prev"
          role="button"
          class="absolute top-1/2 z-10 ml-5 -translate-y-1/2 transform rounded-full bg-white p-[5px] md:flex"
        >
          <base-icon svg="chevron-left" size="md" />
        </div>
        <div
          :data-id="swiperId"
          data-button="next"
          role="button"
          class="absolute right-0 top-1/2 z-10 mr-5 -translate-y-1/2 transform rounded-full bg-white p-[5px] md:flex"
        >
          <base-icon svg="chevron-right" size="md" />
        </div>
      </div>

      <div v-else class="text-md relative mx-auto w-full max-w-xs">
        <loyalty-card :card="loyaltyCard" />
      </div>
      <div class="px-5">
        <div
          class="mt-[30px] p-2.5"
          :class="{
            'rounded-md bg-white': isDarkThemeForEwallet,
          }"
        >
          <render-barcode
            v-if="loyaltyCard.barcode"
            class=""
            :barcode="loyaltyCard.barcode"
          />
        </div>
        <drawer-actions
          :actions="actions"
          :disabled="isDisabled"
          class="mt-6 space-y-4"
        />
      </div>
    </div>
    <div v-else class="pt-[30px]">
      <div class="text-md relative mx-auto w-full max-w-xs">
        <base-card class="animate-pulse rounded-xl bg-divider" />
      </div>
    </div>
  </base-aside-page>
</template>
