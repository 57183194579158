<script>
import { useProvider } from '/~/composables/provider'
import DesktopToolbarV2 from './desktop-toolbar.v2.vue'
import DesktopToolbarV3 from './desktop-toolbar.v3.vue'

export default {
  name: 'desktop-toolbar',
  functional: true,
  render(h, ctx) {
    const { isBupaTemplate } = useProvider()

    let component

    if (isBupaTemplate.value) {
      component = DesktopToolbarV3
    } else {
      component = DesktopToolbarV2
    }

    return h(component, ctx)
  },
}
</script>
