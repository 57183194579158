<script setup lang="ts">
import RecMenuBanner from '/~rec/components/recognition/recognition-menu-banner.vue'
import SectionMenu from '/~/components/section-menu/section-menu.vue'
import { getMenuActiveItem } from '/~/utils/menu'
import { useUI } from '/~/composables/ui'
import { useRecProfile } from '/~rec/composables/profile'
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'

const route = useRoute()
const { leftMenu, uiReady } = useUI()
const { isMeAdmin, isMeSuperAdmin, isMeLimitedUser, features } = useRecProfile()

const activeTopMenuItem = computed(() => {
  if (!uiReady.value || !leftMenu.value) return

  return getMenuActiveItem(route, leftMenu.value)
})
const description = computed(() => activeTopMenuItem.value?.description)
const showDescription = computed(() =>
  description.value?.replace(/<\/?p>/gim, '').trim()
)
const label = computed(() => activeTopMenuItem.value?.label)
const availableMenuItems = computed(() => {
  return activeTopMenuItem.value?.children.filter((item: any) => {
    switch (item.label) {
      case 'Announcements':
        return features.value.announcements
      case 'Groups':
        return features.value.groups
      case 'Events':
        return features.value.events || features.value.celebrations
      case 'Awards':
        return features.value.awards
      case 'Perks':
        return features.value.perks
      case 'Hi-5':
        return features.value.hi5
      case 'My Posts':
        return !isMeLimitedUser.value
      case 'Reported Posts':
        return isMeAdmin.value || isMeSuperAdmin.value
      default:
        return true
    }
  })
})
</script>

<template>
  <div class="flex flex-col content-between text-eonx-neutral-800">
    <div class="flex w-72 grow flex-col overflow-y-auto px-4 pt-[30px]">
      <div class="flex h-[30px] items-center text-2xl font-bold">
        {{ label }}
      </div>
      <div v-if="showDescription" class="mt-4" v-html="description" />
      <section-menu :items="availableMenuItems" class="mt-6" />
      <rec-menu-banner />
    </div>
  </div>
</template>
