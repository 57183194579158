<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { pluralize } from '/~/utils/format/string'
import GroupBox from '/~rec/components/group/group-box.vue'
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'rec-group-tile',
  components: {
    BaseIcon,
    GroupBox,
    BaseButton,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    isOtherGroup: {
      type: Boolean,
      required: true,
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    pluralize,
  },
}
</script>

<template>
  <group-box
    title="Review this group"
    :image="group.image"
    alt-text="Group"
    class="flex h-full flex-col"
    v-on="$listeners"
  >
    <div
      class="flex grow flex-col justify-between truncate bg-white p-[15px] pt-2.5 text-eonx-neutral-800"
    >
      <h3 v-if="group.name" class="w-full shrink grow truncate text-base">
        {{ group.name }}
      </h3>
      <div
        class="mt-[5px] flex items-center font-semibold text-eonx-neutral-600"
      >
        <div class="mr-2.5 shrink-0">
          <base-icon
            :svg="group.isPrivate ? 'rec/lock-solid' : 'rec/planet'"
            :size="16"
            class="shrink-0"
          />
        </div>
        <span class="text-sm leading-4">
          {{ group.isPrivate ? 'Private' : 'Public' }}
        </span>
      </div>
      <div
        class="mt-[5px] flex items-center font-semibold text-eonx-neutral-600"
      >
        <div class="mr-2.5 shrink-0">
          <base-icon svg="rec/users" :size="16" class="shrink-0" />
        </div>
        <span class="text-sm leading-4">
          {{ group.totalMembers || 0 }}
          {{ pluralize(group.totalMembers, 'Member') }}
        </span>
      </div>
    </div>
    <template v-if="isOtherGroup" #join>
      <base-button
        class="z-2 w-full max-w-full rounded-t-none"
        :disabled="processing"
        @click.stop="$emit('join-group', group)"
      >
        Join Group
      </base-button>
    </template>
  </group-box>
</template>
