<script>
import { nextTick, onBeforeUnmount, ref, watch } from 'vue'
import { useUI } from '/~/composables/ui'
import { nanoid } from 'nanoid'

export default {
  name: 'drawer-modal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    fullheight: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
  },
  setup(props) {
    const { lockKeyboardAccessArea, unlockKeyboardAccessArea } = useUI()

    const rootElement = ref(null)
    const keyboardAccessAreaId = nanoid()

    watch(
      () => props.visible,
      (visible) => {
        if (visible) {
          nextTick(() => {
            lockKeyboardAccessArea({
              id: keyboardAccessAreaId,
              rootElement: rootElement.value,
              delay: 200,
            })
          })
        } else {
          unlockKeyboardAccessArea(keyboardAccessAreaId)
        }
      },
      {
        immediate: true,
      }
    )

    onBeforeUnmount(() => {
      unlockKeyboardAccessArea(keyboardAccessAreaId)
    })

    return {
      rootElement,
    }
  },
}
</script>

<template>
  <portal :to="to">
    <transition name="drawer-modal" appear>
      <div
        v-show="visible"
        ref="rootElement"
        class="absolute top-0 left-0 z-modal flex h-full w-full justify-center bg-black/30"
        @click.self="$emit('hide')"
        @keyup.esc="$emit('hide')"
      >
        <transition duration="400">
          <div
            v-if="visible"
            class="drawer-modal absolute z-10 flex max-h-full w-full flex-col overflow-y-auto overflow-x-hidden"
            :class="{
              'top-1/3 bottom-auto left-5 rounded-3xl': centered,
              'bottom-0 left-0': !centered,
              'h-full rounded-none': fullheight,
              'h-auto rounded-t-3xl': !fullheight,
            }"
            :style="centered && 'width: calc(100% - 40px)'"
          >
            <slot name="iconHeader" />
            <div
              class="flex max-h-full grow flex-col overflow-y-auto bg-light"
              :class="{
                'px-5': padding,
              }"
            >
              <slot />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </portal>
</template>
