<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'confirm-pay-from-bank',
  components: {
    BaseMdl,
    BaseButton,
    BaseIcon,
  },
  props: {
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const { isBillPaymentsTemplate } = useProvider()

    return {
      isBillPaymentsTemplate,
    }
  },
  data() {
    return {
      paymentClearMsg: {
        show: true,
        message: 'The payment may take up to 3 business days to clear.',
      },
      sufficientFundsMsg: {
        show: true,
        message:
          'Please ensure you have sufficient funds in your account before processing bank transfers. A $50 dishonour fee may be charged on dishonoured bank transfers',
      },
    }
  },
  methods: {
    close() {
      this.onCancel?.()
      modal.hide()
    },
    confirm() {
      this.onConfirm?.()
      modal.hide()
    },
  },
}
</script>
<template>
  <base-mdl width="xs">
    <template #header>
      <div class="relative text-center">
        <span class="text-warning-700">
          <base-icon svg="symbion/warning" :size="70" class="mx-auto my-6" />
        </span>
        <span class="absolute top-0 right-0 mr-5 mt-[35px]">
          <base-button icon="plain/close" @click="close()" />
        </span>
      </div>
    </template>

    <div class="text-center">
      <h2 class="text-eonx-neutral-800">Confirm payment</h2>
      <div class="mt-5 mb-10 text-eonx-neutral-600">
        <span>The payment may take up to 3 business days to clear.</span>
        <span v-if="isBillPaymentsTemplate">
          Please ensure you have sufficient funds in your account before
          processing bank transfers. A $50 dishonour fee may be charged on
          dishonoured bank transfers.
        </span>
      </div>
    </div>

    <div class="text-center">
      <base-button full-width @click="confirm">Confirm</base-button>
    </div>
  </base-mdl>
</template>
