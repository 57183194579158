import { nanoid } from 'nanoid'

export default {
  label: 'Default',
  value: 'default',
  preset: {
    items: [
      {
        id: nanoid(),
        enabled: true,
        label: 'Privacy Policy',
        action: {
          type: 'page',
          value: 'privacy-policy',
        },
        'mobile-action': {
          type: 'page',
          value: 'privacy-policy',
        },
        icon: 'v2/heroic/shield-exclamation',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Terms & Conditions',
        action: {
          type: 'page',
          value: 'terms-conditions',
        },
        'mobile-action': {
          type: 'page',
          value: 'terms-conditions',
        },
        icon: 'v2/heroic/document-text',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Help',
        action: {
          type: 'page',
          value: 'help',
        },
        'mobile-action': {
          type: 'page',
          value: 'help',
        },
        icon: 'v2/box-icons/bx-envelope',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'How it works',
        action: {
          type: 'action',
          value: 'show-faq',
        },
        'mobile-action': {
          type: 'action',
          value: 'show-faq',
        },
        icon: 'v2/box-icons/bx-info-circle',
      },
    ],
  },
}
