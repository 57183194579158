<script>
import { computed } from 'vue'
import GcBadge from '/~/extensions/giftcards/components/gc-badge.vue'
import { useGiftcards } from '/~/extensions/giftcards/composables'
import { formatNumber } from '/~/utils/format'
import { pluralize } from '/~/utils/format/string'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useUI } from '/~/composables/ui'
import RetailerProductMixin from '../retailer-product.mixin'

export default {
  name: 'retailer-product-header',
  components: {
    BaseIcon,
    GcBadge,
  },
  mixins: [RetailerProductMixin],
  props: {
    showType: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { isPurchaseOrderPointsAvailable } = usePaymentMethods()
    const { isPurchaseOrderPoints, dollarsForOnePoint } = usePoints()
    const { isPhysicalEnabled } = useGiftcards()
    const { titleRef } = useUI()

    const isGiftCard = computed(() => !!props.retailer)
    const isPointsEarnShow = computed(
      () =>
        isGiftCard.value &&
        isPurchaseOrderPointsAvailable.value &&
        isPurchaseOrderPoints.value &&
        dollarsForOnePoint.value.purchase > 0
    )

    return {
      ...RetailerProductMixin.setup?.(...arguments),
      titleRef,
      isPointsEarnShow,
      dollarsForOnePoint,
      pluralize,
      isPhysicalEnabled,
      formatNumber,
    }
  },
}
</script>

<template>
  <div class="my-4 flex flex-col gap-4 text-center sm:mt-0 sm:text-left">
    <h2
      v-if="name"
      ref="titleRef"
      class="text-2xl font-normal leading-tight text-eonx-neutral-800 !outline-none md:text-3xl"
      tabindex="-1"
    >
      {{ name }}
    </h2>
    <div
      class="flex items-center justify-center gap-x-5 text-2xl font-normal sm:justify-start md:text-3xl"
    >
      <template v-if="!percentage || percentage === '0.00'">
        <h3>{{ offerName }}</h3>
      </template>
      <template v-else>
        <h3>{{ Number(percentage) }}% OFF</h3>
        <gc-badge
          v-if="isPhysicalEnabled"
          :is-physical="physical"
          digital-label="Digital Gift Card"
          physical-label="Physical Gift Card"
        />
      </template>
    </div>
    <div v-if="isPointsEarnShow">
      <span
        class="mx-5 flex items-center justify-center border-t border-b bg-primary-lightest px-2.5 py-2.5 text-center text-sm sm:mx-0 sm:inline-flex sm:rounded sm:border-t-0 sm:border-b-0 md:px-6 md:py-[5px]"
      >
        <span class="h-5 leading-none text-primary">
          <base-icon class="mr-2.5" svg="points/token" :size="21" />
        </span>
        <span
          class="flex flex-wrap items-center justify-center gap-x-2.5 md:flex-row"
        >
          <span class="inline-block whitespace-nowrap uppercase">
            EARN Bonus Points:
          </span>
          <span
            class="flex items-center whitespace-nowrap font-bold leading-none"
          >
            <span class="mr-[5px] text-xl leading-5">1</span>
            <span>
              PT per {{ formatNumber(dollarsForOnePoint.purchase) }}
              {{ pluralize(dollarsForOnePoint.purchase, 'dollar') }} spent
            </span>
          </span>
        </span>
      </span>
    </div>
  </div>
</template>
