<script setup lang="ts">
import { ref } from 'vue'
import ui from '/~/core/ui'
import Notification from '/~/extensions/bank-file-upload/components/common/notification.vue'
import Pagination from '/~/extensions/bank-file-upload/components/common/pagination.vue'
import UploadedFile from '/~/extensions/bank-file-upload/components/common/uploaded-file.vue'
import UnsupportedPayeesTable from '/~/extensions/bank-file-upload/components/unsupported-payees/table/unsupported-payees-table.vue'
import LayoutMain from '/~/extensions/bank-file-upload/layouts/main.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import {
  useBatchOrder,
  useBatchOrderUnsupportedPayees,
} from '/~/composables/batch-order'

const { unsupportedPayees } = useBatchOrderUnsupportedPayees()

unsupportedPayees.load(1)

const { cancelOrder } = useBatchOrder()

const tableLabels = [
  { name: 'Account name', class: 'px-5 w-72' },
  { name: 'BSB / Account', class: 'ml-auto w-80 px-5' },
]
</script>

<template>
  <layout-main>
    <component
      :is="ui.mobile ? BaseAsidePage : 'div'"
      :title="ui.mobile && 'Upload file details'"
      :no-padding="ui.mobile && true"
      :back="ui.mobile && { name: 'home' }"
      class="h-full"
    >
      <div class="flex h-full flex-col p-5 lg:px-12 lg:py-8">
        <notification
          color="red"
          class="mb-8"
          icon="heroicons/solid/exclamation-circle"
        >
          Batch payment cannot proceed with ineligible payees.
        </notification>

        <div class="mb-8">
          <h1
            v-if="!ui.mobile"
            class="mb-8 text-2xl leading-none text-eonx-neutral-800"
          >
            Ineligible payees detected
          </h1>
          <div class="mt-4 space-y-4">
            <p>
              The following payees cannot be paid via this platform. To proceed
              with the batch payment, please remove them from your ABA file and
              upload the file again. For queries, contact support.
            </p>
          </div>
          <div class="mt-8 flex space-x-5 divide-x">
            <uploaded-file />
            <div class="space-x-1 pl-5 font-bold">
              <span class="text-eonx-neutral-800">Ineligible payees:</span>
              <span class="text-eonx-neutral-800">
                {{ unsupportedPayees.pagination.totalItems }}
              </span>
            </div>
          </div>
        </div>

        <unsupported-payees-table
          :labels="tableLabels"
          :rows="unsupportedPayees.payeeList.list"
          :loading="unsupportedPayees.loader.loading"
        />

        <pagination
          v-if="unsupportedPayees.pagination.totalPages > 1"
          class="mt-8"
          :current-page="unsupportedPayees.pagination.currentPage"
          :total-pages="unsupportedPayees.pagination.totalPages"
          @selectPage="unsupportedPayees.load($event)"
        />
        <div class="mt-8 flex justify-end">
          <base-button
            :full-width="ui.mobile"
            class="w-32 !rounded"
            @click="cancelOrder"
          >
            Done
          </base-button>
        </div>
      </div>
    </component>
  </layout-main>
</template>
