<script setup lang="ts">
import ui from '/~/core/ui'
import { useChat } from '/~/extensions/rec-room/composables/chat'
import AppNotifications from '/~/components/app/app-notifications.vue'
import { useHeaderTopMenu } from '/~/components/app/header/components/header-top-menu/use-header-top-menu'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerAvatar from '/~/components/drawer/components/drawer-avatar/drawer-avatar.v4.vue'
import { useCart } from '/~/composables/cart'
import { MenuItem } from '/~/composables/cms/use-cms'
import { useUI } from '/~/composables/ui'
import { useUser } from '/~/composables/user'

defineProps<{ activeItem?: MenuItem }>()

const { cartQuantity } = useCart()
const { uiReady, topMenu } = useUI()
const { hasNewMessages } = useChat()
const { isDisabled, isToggleProfileItem } = useHeaderTopMenu()
const { user } = useUser()
</script>

<template>
  <ul class="flex shrink-0 items-center space-x-2.5 sm:space-x-5">
    <template v-for="(item, idx) in topMenu">
      <li
        v-if="!isDisabled(item)"
        :key="item.id || idx"
        class="relative sm:block"
        :class="[item === activeItem && 'text-primary']"
      >
        <template v-if="uiReady && item.action">
          <app-notifications
            v-if="item.action.value === 'show-notifications'"
            :icon="item.icon"
          />
          <template v-else>
            <base-action
              v-bind="item.action || {}"
              class="flex h-10 w-10 items-center justify-center rounded-full text-eonx-neutral-800 transition duration-100 hover:text-eonx-neutral-600 md:h-12 md:w-12"
              :class="{
                'bg-dark': isToggleProfileItem(item),
              }"
            >
              <drawer-avatar
                v-if="user.avatarUrl && isToggleProfileItem(item)"
                :avatar-url="user.avatarUrl"
                class="h-full w-full"
              />
              <base-icon
                v-else
                :svg="item.icon"
                :size="ui.mobile ? 26 : 32"
                :alt="item.label"
              />
            </base-action>
            <span
              v-if="item.action.value === 'rec-room' && hasNewMessages"
              class="absolute top-0 right-0 h-2.5 w-2.5 min-w-2.5 rounded-full bg-error-700 px-0.5 text-center text-xs font-bold text-white"
            />
            <span
              v-if="item.action.value === 'show-cart' && cartQuantity > 0"
              class="absolute top-0 right-0 -mt-0.5 -mr-0.5 min-w-2.5 rounded-full bg-error-700 px-[5px] text-center text-xs font-bold text-white"
            >
              {{ cartQuantity }}
            </span>
          </template>
        </template>
      </li>
    </template>
  </ul>
</template>
