<script setup lang="ts">
import { formatDate } from '/~/utils/format/date'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useProvider } from '/~/composables/provider'
import { StatementAccount } from '/~/composables/statements/core/StatementAccount'

defineProps<{ account?: StatementAccount }>()
const { providerClientName } = useProvider()
</script>

<template>
  <div
    v-if="account && account.isVerifyPending && account.isEmpty"
    class="flex items-start space-x-2 rounded-md bg-orange-50 py-1 px-2 text-sm text-orange-700 sm:items-center"
  >
    <span><base-icon svg="heroicons/mini/clock" :size="16" /></span>
    <span>
      Account pending {{ providerClientName }} approval. Thank you for your
      patience.
    </span>
  </div>
  <div
    v-else-if="account && account.isVerifyRejected && account.isEmpty"
    class="flex items-start space-x-2 rounded-md bg-red-50 py-1 px-2 text-sm text-red-700 sm:items-center"
  >
    <span>
      <base-icon svg="heroicons/mini/exclamation-triangle" :size="16" />
    </span>
    <span>
      This account has been declined. Please contact
      {{ providerClientName }} for more details.
    </span>
  </div>
  <div
    v-else-if="account && account.isVerifyApproved && account.isEmpty"
    class="flex items-start space-x-2 rounded-md bg-blue-50 py-1 px-2 text-sm text-blue-700 sm:items-center"
  >
    <span>
      <base-icon svg="heroicons/mini/information-circle" :size="16" />
    </span>
    <span>
      Statement data will be made available on your next statement cycle.
    </span>
  </div>
  <div
    v-else-if="
      account.statementOrders[0] && account.statementOrders[0].isScheduled
    "
    class="flex items-start space-x-2 rounded-md bg-emerald-50 py-1 px-2 text-sm text-emerald-700 sm:items-center"
  >
    <span><base-icon svg="heroicons/mini/clock" :size="16" /></span>
    <span>
      Once-off payment has been scheduled for
      {{
        formatDate(
          'daymonthyearnumeric',
          account.statementOrders[0].scheduledAt
        )
      }}.
    </span>
  </div>
  <div
    v-else-if="account && account.isVerifyApproved && account.isOverdue"
    class="flex items-start space-x-2 rounded-md bg-orange-50 py-1 px-2 text-sm text-orange-700 sm:items-center"
  >
    <span>
      <base-icon svg="heroicons/mini/clock" :size="16" />
    </span>

    <span>
      Payment is overdue. Pay now using a bank account to avoid any settlement
      delays.
    </span>
  </div>
  <div
    v-else-if="account && account.isVerifyApproved && account.isNotRequired"
    class="flex items-start space-x-2 rounded-md bg-blue-50 py-1 px-2 text-sm text-blue-700 sm:items-center"
  >
    <span>
      <base-icon svg="heroicons/mini/information-circle" :size="16" />
    </span>
    <span>This account does not require payment this month.</span>
  </div>
  <div
    v-else-if="account && account.isVerifyApproved && account.isDueSoon"
    class="flex items-start space-x-2 rounded-md bg-orange-50 py-1 px-2 text-sm text-orange-700 sm:items-center"
  >
    <span><base-icon svg="heroicons/mini/clock" :size="16" /></span>
    <span>
      Payment is due soon. Please allow one business day for the payment to
      process. Processing times are between 9-5:30pm AEST.
    </span>
  </div>
  <div
    v-else-if="account && account.isVerifyApproved && account.isDueToday"
    class="flex items-start space-x-2 rounded-md bg-orange-50 py-1 px-2 text-sm text-orange-700 sm:items-center"
  >
    <span><base-icon svg="heroicons/mini/clock" :size="16" /></span>
    <span>
      Payment is due today. Please allow one business day for the payment to
      process. Processing times are between 9-5:30pm AEST.
    </span>
  </div>
  <div
    v-else-if="account && account.isVerifyApproved && account.isProcessing"
    class="flex items-start space-x-2 rounded-md bg-emerald-50 py-1 px-2 text-sm text-emerald-700 sm:items-center"
  >
    <span><base-icon svg="heroicons/mini/clock" :size="16" /></span>
    <span>Payment is being processed.</span>
  </div>
  <div
    v-else-if="account && account.isVerifyApproved && account.isSettled"
    class="flex items-start space-x-2 rounded-md bg-emerald-50 py-1 px-2 text-sm text-emerald-700 sm:items-center"
  >
    <span>
      <base-icon svg="heroicons/mini/information-circle" :size="16" />
    </span>
    <span>This account is paid.</span>
  </div>
  <div
    v-else-if="account && account.isVerifyApproved && account.isElapsed"
    class="flex items-start space-x-2 rounded-md bg-red-50 py-1 px-2 text-sm text-red-700 sm:items-center"
  >
    <span>
      <base-icon svg="heroicons/mini/exclamation-triangle" :size="16" />
    </span>
    <span>
      The last date for payment via the platform has passed. Please contact
      {{ providerClientName }} directly to pay the statement.
    </span>
  </div>
  <div
    v-else-if="account && account.isVerifyApproved && account.isNotResolved"
    class="flex items-start space-x-2 rounded-md bg-red-50 py-1 px-2 text-sm text-red-700 sm:items-center"
  >
    <span>
      <base-icon svg="heroicons/mini/exclamation-triangle" :size="16" />
    </span>
    <span>
      This account is unavailable at the moment. The support team has already
      been notified and working on it.
    </span>
  </div>
</template>
