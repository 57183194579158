import { ref, computed } from 'vue'
import api from '/~/core/api'
import { useExtensions } from '/~/composables/extensions'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { VirtualCard } from '/~/composables/virtual-cards/core/VirtualCard'

const { getConfigByName } = useExtensions()
const { eWallets } = usePaymentMethods()

const virtualCardsConfig = computed(() => {
  return getConfigByName('virtual-card')
})

const isVirtualCardsEnabled = computed(() => Boolean(virtualCardsConfig.value))
const isCreatingVirtualCard = ref(false)
const isFetchingVirtualCards = ref(false)
const virtualCards = ref<VirtualCard[]>([])
const firstVirtualCard = computed(() => virtualCards.value[0])

async function createVirtualCard(registrationData) {
  const ewalletId = eWallets.value?.[0]?.reference

  if (!ewalletId) {
    throw new Error('User needs an existing ewallet to add a virtual card')
  }

  isCreatingVirtualCard.value = true

  try {
    const response = await api.post('/v3/virtual-cards/', {
      ewalletId,
      registrationData,
    })

    virtualCards.value.push(new VirtualCard(response.data))
  } catch (error) {
    console.error('virtual-cards', error)
    throw error
  } finally {
    isCreatingVirtualCard.value = false
  }
}

async function fetchVirtualCards() {
  isFetchingVirtualCards.value = true

  try {
    const response = await api.get('/v3/virtual-cards/')

    virtualCards.value.splice(0)

    for (const virtualCardData of response.data.items) {
      virtualCards.value.push(new VirtualCard(virtualCardData))
    }
  } catch (error) {
    console.error('virtual-cards', error)
  } finally {
    isFetchingVirtualCards.value = false
  }
}

async function fetchVirtualCardSensitiveData(virtualCardId) {
  const virtualCard = virtualCards.value.find(
    (virtualCard) => virtualCard.id === virtualCardId
  )

  if (!virtualCard || virtualCard.sensitiveData.isFetching) {
    return
  }

  virtualCard.sensitiveData.isFetching = true

  try {
    const response = await api.get(
      `/v3/virtual-cards/${virtualCard.id}/details/`
    )

    Object.assign(virtualCard.sensitiveData, response.data.details ?? {})

    virtualCard.sensitiveData.number = virtualCard.sensitiveData.number
      .match(/.{1,4}/g)
      .join(' ')
    virtualCard.sensitiveData.isFetched = true
  } catch (error) {
    console.error('virtual-cards', error)
  } finally {
    virtualCard.sensitiveData.isFetching = false
  }
}

async function updateVirtualCard(virtualCardId, payload) {
  const virtualCard = virtualCards.value.find(
    (virtualCard) => virtualCard.id === virtualCardId
  )

  if (!virtualCard || virtualCard.isUpdating) {
    return
  }

  virtualCard.isUpdating = true

  try {
    await api.put(`/v3/virtual-cards/${virtualCard.id}`, payload)

    Object.assign(virtualCard, payload)
  } catch (error) {
    console.error('virtual-cards', error)
  } finally {
    virtualCard.isUpdating = false
  }
}

async function lockVirtualCard(virtualCardId) {
  return updateVirtualCard(virtualCardId, {
    status: 'inactive',
  })
}

async function unlockVirtualCard(virtualCardId) {
  return updateVirtualCard(virtualCardId, {
    status: 'active',
  })
}

async function deleteVirtualCard(virtualCardId) {
  const virtualCardIndex = virtualCards.value.findIndex(
    (virtualCard) => virtualCard.id === virtualCardId
  )

  if (virtualCardIndex < 0) {
    return
  }

  const virtualCard = virtualCards.value[virtualCardIndex]

  if (virtualCard.isUpdating) {
    return
  }

  virtualCard.isUpdating = true

  try {
    await api.delete(`/v3/virtual-cards/${virtualCard.id}`)

    virtualCards.value.splice(virtualCardIndex, 1)
  } catch (error) {
    console.error('virtual-cards', error)
  } finally {
    virtualCard.isUpdating = false
  }
}

export function useVirtualCards() {
  return {
    isVirtualCardsEnabled,
    isCreatingVirtualCard,
    isFetchingVirtualCards,
    virtualCards,
    firstVirtualCard,
    createVirtualCard,
    fetchVirtualCards,
    fetchVirtualCardSensitiveData,
    lockVirtualCard,
    unlockVirtualCard,
    deleteVirtualCard,
  }
}
