<script>
import { useProvider } from '/~/composables/provider'
import PointsPartnerPlaceholder from '../../components/points-partner-placeholder.vue'
import PartnersAvailableList from '../components/partners-list.vue'

export default {
  name: 'fly-partners-available-desktop',
  components: {
    PartnersAvailableList,
    PointsPartnerPlaceholder,
  },
  props: {
    partners: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { providerTitle } = useProvider()

    return {
      providerTitle,
    }
  },
}
</script>

<template>
  <div class="mx-auto flex max-w-xl flex-col">
    <div class="mb-8">
      <h2 class="mb-2 text-left text-2xl text-eonx-neutral-800">
        Transfer your {{ providerTitle }} Points
      </h2>
      <p class="max-w-2xl pr-8 leading-6 text-eonx-neutral-600">
        Choose your preferred rewards program from participating airline
        partners and transfer your {{ providerTitle }} Points with ease
      </p>
    </div>
    <points-partner-placeholder v-if="loading" :rows="3" />
    <partners-available-list v-else :partners="partners" />
  </div>
</template>
