import dayjs from 'dayjs/esm'
import { type ApiResponseData, type ApiResponseList } from '/~/types/api'
import api from '/~/core/api'
import Storage from '/~/utils/localStorage'

export abstract class KycService {
  #expirationDay: number
  #cachedSessionKey: string
  verificationSession: null | Kyc.Session
  syncing: boolean
  alert?(): Promise<void>

  constructor(config?: Kyc.ServiceFactoryConfig) {
    this.#expirationDay = config?.expirationDay ?? 1
    this.#cachedSessionKey = `kyc-session-${config?.userId ?? 'default'}`
    this.verificationSession = null
    this.syncing = false
  }

  get validDays() {
    return this.#expirationDay
  }

  get cachedSessionKey() {
    return this.#cachedSessionKey
  }

  async fetchVerificationSessions() {
    return api.get<ApiResponseList<Kyc.IdentityVerification>>(
      '/v3/identity-verifications'
    )
  }

  async fetchVerificationSession(externalId: string) {
    return api.get<ApiResponseData<Kyc.IdentityVerification>>(
      `/v3/identity-verifications/${externalId}`,
      {
        notify: false,
      }
    )
  }

  async cancelVerificationSession(externalId: string) {
    return api.delete(`/v3/identity-verifications/${externalId}`, {
      notify: false,
    })
  }

  getCachedSession() {
    return Storage.get<Kyc.PostIdentityVerifications>(this.cachedSessionKey)
  }

  cacheSession(session: Kyc.PostIdentityVerifications) {
    Storage.set(this.cachedSessionKey, JSON.stringify(session))
  }

  clearCachedSession() {
    Storage.remove(this.cachedSessionKey)
  }

  abstract isValidSessionResponse(data: Kyc.PostIdentityVerifications): boolean

  abstract startVerification(): Promise<unknown>

  clearVerificationSession() {
    this.clearCachedSession()
    this.verificationSession = null
  }

  async cancelRemoteSessions() {
    const { data } = await this.fetchVerificationSessions()

    for (const i of data.items) {
      if (i.status !== 'verified') {
        await this.cancelVerificationSession(i.externalId)
      }
    }
  }

  async createVerificationSession(withAlert = true) {
    if (withAlert && this.alert) {
      try {
        await this.alert()
      } catch (e) {
        return
      }
    }

    try {
      if (!this.verificationSession) {
        const { data } = await api.post<
          ApiResponseData<Kyc.PostIdentityVerifications>
        >('/v3/identity-verifications', {
          notify: false,
        })

        if (!data) {
          throw new Error('Failed to create verification session')
        }

        if (this.isValidSessionResponse(data)) {
          this.cacheSession(data)
          this.verificationSession = data
        }
      }

      return await this.startVerification()
    } catch (error: any) {
      console.error(error, { error })
      // Handling 504 error. fetch doesn't return any response/error code in this case.
      if (error.message === 'Failed to fetch') {
        await this.createVerificationSession(false)
      }
    }
  }

  async syncVerificationSessions() {
    this.syncing = true

    const cachedSession = this.getCachedSession()

    if (cachedSession) {
      const validUntil = dayjs(cachedSession.createdAt).add(
        this.validDays,
        'day'
      )
      const isValid = dayjs().isBefore(validUntil)

      if (isValid) {
        try {
          const { data: session } = await this.fetchVerificationSession(
            cachedSession.externalId
          )

          this.verificationSession = { ...cachedSession, ...session }
          return
        } catch (error) {
          console.error(error)
          this.clearVerificationSession()
        } finally {
          this.syncing = false
        }
      } else {
        this.clearVerificationSession()
      }
    } else {
      await this.cancelRemoteSessions()
    }

    this.syncing = false
  }
}
