<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'base-state',
  components: {
    BaseIcon,
    BaseImage,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    svg: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'center',
      validator: (v) => /left|center|right/.test(v),
    },
  },
}
</script>

<template>
  <div
    class="flex flex-col items-start justify-center text-left"
    :class="{
      'items-start text-left': align === 'left',
      'items-center text-center': align === 'center',
      'items-end text-right': align === 'right',
    }"
  >
    <slot name="header" />
    <div v-if="$slots.image || image || svg" class="mb-[30px] w-full md:mb-10">
      <div v-if="image" class="flex max-h-48 sm:max-h-[300px]">
        <base-image ref="image" :src="image" />
      </div>
      <div
        v-else-if="svg"
        class="w-full sm:max-h-[300px]"
        style="padding-top: 100%"
      >
        <base-icon class="absolute inset-0" size="auto" :svg="svg" />
      </div>
      <slot name="image" />
    </div>
    <div
      v-if="title"
      class="mb-[5px] w-full truncate text-2xl font-bold md:text-3xl"
    >
      {{ title }}
    </div>

    <slot />
    <div v-if="$slots.footer" class="mt-10 flex flex-col">
      <slot name="footer" />
    </div>
  </div>
</template>
