<script setup lang="ts">
import { useFeeds } from '/~rec/composables/feeds'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { watch } from 'vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useRecroom } from '/~/extensions/rec-room/composables/recroom'
import PostBox from './post-box.vue'

const { feeds, selectedFeed } = useFeeds()
const { initialized } = useRecroom()

watch(
  initialized,
  (value) => {
    if (value) {
      useFeed()
    }
  },
  {
    immediate: true,
  }
)

function useFeed() {
  feeds.selectedFeedId = 'announcements'

  selectedFeed.value?.getData()
}
</script>

<template>
  <ew-catalog-processor
    id="rec-feed-desktop"
    :processor="selectedFeed"
    :columns="1"
    :tile-class="false"
    :catalog-class="'!pt-0'"
    class="h-full w-[376px] grow sm:overflow-y-auto"
  >
    <template #empty>
      <slot name="empty" />
    </template>

    <template #placeholder>
      <base-placeholder theme="dark" height="100%" type="fill" />
    </template>

    <template #tile="{ tile }">
      <post-box :key="`post-${tile.id}`" :post-box-data="tile" />
    </template>
  </ew-catalog-processor>
</template>
