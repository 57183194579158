<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import FaqContent from '/~/views/faq/faq.vue'

export default {
  name: 'faq-modal',
  components: {
    BaseMdl,
    FaqContent,
  },
}
</script>

<template>
  <base-mdl title="FAQ" width="lg" fullscreen="mobile" class="modal-faq">
    <faq-content />
  </base-mdl>
</template>
