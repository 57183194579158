import {
  GiftRecipientsRawData,
  ApiResponseData,
  GiftRecipientsPayload,
} from '/~/types/api'
import api from '/~/core/api'

interface GiftRecipientResponse {
  message: string
  id: string
}

async function getGiftRecipient(externalId: string) {
  const { data } = await api.get<ApiResponseData<GiftRecipientsRawData>>(
    `/v3/gift-recipients/${externalId}`
  )

  return data
}

async function createGiftRecipient(payload: GiftRecipientsPayload) {
  const { data } = await api.post<ApiResponseData<GiftRecipientsRawData>>(
    '/v3/gift-recipients',
    payload,
    {
      notify: false,
    }
  )

  return data
}

async function listGiftRecipients() {
  const { data } = await api.get<ApiResponseData<GiftRecipientsRawData[]>>(
    '/v3/gift-recipients'
  )

  return data
}

async function findRecipientByEmail(email: string) {
  const recipients = await listGiftRecipients()

  return recipients.find((item) => item.email === email)
}

async function addGiftRecipient(payload: GiftRecipientsPayload) {
  const response: GiftRecipientResponse = {
    message: '',
    id: '',
  }

  try {
    if (payload) {
      const data = await createGiftRecipient(payload)

      response.message = payload.message
      response.id = data.id
    }
  } catch (error) {
    if (error?.data?.status === 409) {
      const existingRecipient = await findRecipientByEmail(payload.email)

      response.message = payload.message
      if (existingRecipient) {
        response.id = existingRecipient.id
      }
    } else {
      console.error(error)
      this.$notify({
        text: error.data?.message ?? error.message,
        type: 'error',
        duration: 10000,
      })
    }
  }

  return response
}

export const useGiftRecipients = () => ({
  getGiftRecipient,
  createGiftRecipient,
  listGiftRecipients,
  addGiftRecipient,
})
