<script>
import PaymentsAccounts from '/~/components/payments/bank-accounts/payments-bank-accounts.vue'
import PaymentsCreditCards from '/~/components/payments/credit-cards/payments-credit-cards.vue'
import PaymentsEwallet from '/~/components/payments/ewallet/payments-ewallet.vue'
import PaymentsPba from '/~/components/payments/pba/payments-pba.vue'
import PaymentsPoints from '/~/components/payments/points/payments-points.vue'

export default {
  name: 'payments-methods-default-list',
  components: {
    PaymentsCreditCards,
    PaymentsAccounts,
    PaymentsEwallet,
    PaymentsPoints,
    PaymentsPba,
  },
  props: {
    splitEnabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div>
    <payments-points :split-enabled="splitEnabled" />
    <payments-ewallet :split-enabled="splitEnabled" />
    <payments-credit-cards :split-enabled="splitEnabled" />
    <payments-accounts :split-enabled="splitEnabled" />
    <payments-pba />
  </div>
</template>
