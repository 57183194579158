<script>
import { ValidationObserver } from 'vee-validate'
import { defineComponent, nextTick } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import { useAddressForm, useAddresses } from '/~/composables/addresses'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCart } from '/~/composables/cart'
import { useCheckoutRoutes } from '/~/composables/checkout'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'
import { useUser } from '/~/composables/user'

const defaultData = {
  companyName: '',
  default: false,
  email: '',
  firstName: '',
  id: -1,
  lastName: '',
  mobile: '',
  postcode: '',
  state: '',
  streetAddress: '',
  subpremise: '',
  suburb: '',
  fullAddress: '',
}

export default defineComponent({
  name: 'profile-add-address-v2',
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseAsidePage,
    BaseMetafield,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const { addCartOrderAddress } = useCart()
    const { providerCountry } = useProvider()
    const { addresses, addAddress, updateAddress } = useAddresses()
    const { addressSchema } = useAddressForm()
    const { user } = useUser()
    const { isDarkThemeForEwallet } = useCms()
    const { getCurrentFlowRouteName } = useCheckoutRoutes()
    const { isRequiredAsteriskVisible } = useUI()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { states, translate } = useLocalization()
    const { validationObserverRef } = useForm()

    return {
      addCartOrderAddress,
      addresses,
      addAddress,
      updateAddress,
      user,
      isDarkThemeForEwallet,
      isRequiredAsteriskVisible,
      validationObserverRef,
      getCurrentFlowRouteName,
      backendErrors,
      processBackendErrors,
      states,
      addressSchema,
      providerCountry,
      translate,
    }
  },
  data() {
    return {
      form: {
        ...defaultData,
      },
      disabledSetDefault: false,
      submitting: false,
      initialData: {},
      buttonShadow: false,
    }
  },
  computed: {
    address() {
      return (
        (this.addresses || []).find(
          (address) => `${address.id}` === this.id
        ) || {
          ...defaultData,
        }
      )
    },
    addressValue() {
      return {
        address: {
          fullAddress: this.form.fullAddress,
          address: this.form.streetAddress,
          suburb: this.form.suburb,
          postcode: this.form.postcode,
          state: this.form.state,
        },
      }
    },
    type() {
      return this.id && this.address.id !== -1 ? 'edit' : 'create'
    },
    isCreateType() {
      return this.type !== 'edit'
    },
    selectedStateId() {
      const state = this.states.find((state) => {
        return state.label === this.form.state
      })

      return state ? state.id : ''
    },
    disableSaveBtn() {
      return this.submitting || !this.isFormValid
    },
    isFormValid() {
      return (
        Object.entries(this.form).find((value) => {
          return this.validationObserverRef?.errors[value[0]]?.length > 0
        }) === undefined
      )
    },
    errorsList() {
      return Object.entries(this.validationObserverRef?.errors ?? {})
        .map(([_, errors]) => errors[0])
        .join('. ')
    },
  },
  created() {
    const { email, firstName, lastName, mobile } = this.user
    const userDetails = this.isCreateType
      ? { email, firstName, lastName, mobile }
      : {}

    const { streetAddress, suburb, state, postcode } = this.address

    const form = {
      ...this.form,
      ...this.address,
      fullAddress: Object.values({ streetAddress, suburb, state, postcode })
        .filter(Boolean)
        .join(' '),
      ...userDetails,
    }

    this.form = form
    this.initialData = { ...form }
  },
  mounted() {
    this.checkShadow()
  },
  methods: {
    onAddressChanged(value) {
      Object.assign(this.form, value.address)
      this.form.streetAddress = value.address.address
    },
    async onSubmitForm() {
      if (this.disableSaveBtn) return

      this.submitting = true
      this.backendErrors = {}

      const action = this.isCreateType ? this.addAddress : this.updateAddress
      const params = {
        ...this.form,
        mobile: (this.form.mobile || '')
          .replace(/\s/g, '')
          .replace(/^\+61/g, '0'),
        state: this.selectedStateId || this.form.state,
      }

      return action(params)
        .then((response) => {
          this.submitting = false

          this.$notify({
            text: `Address successfully ${
              this.isCreateType ? 'created' : 'updated'
            }`,
            type: 'success',
            duration: 1000,
          })

          if (
            this.isCreateType &&
            [
              this.$route.name,
              this.$route.meta?.back?.name,
              this.$route.params?.replacedFrom?.name,
            ].includes(this.getCurrentFlowRouteName('checkout-billing'))
          ) {
            this.addCartOrderAddress({ address: response, type: 'billing' })
          }

          const presetBack =
            this.$route.params?.replacedFrom || this.$route.meta?.back

          if (presetBack) {
            this.$router.push(presetBack)
          } else {
            this.$router.back()
          }
        })
        .catch((exception) => {
          this.submitting = false
          this.processBackendErrors({
            errors: exception,
          })
        })
    },
    checkShadow() {
      const { form } = this.$refs

      if (!form) {
        return false
      }

      const scrollAmount = form.scrollHeight - form.offsetHeight

      if (scrollAmount && scrollAmount !== form.scrollTop) {
        this.buttonShadow = true
      } else {
        this.buttonShadow = false
      }
    },
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
})
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    :title="isCreateType ? 'Add new address' : 'Edit address'"
    :back="$route.meta.back"
  >
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="relative flex h-full flex-col"
        @submit.prevent="handleSubmit(onSubmitForm)"
      >
        <div
          ref="form"
          class="relative flex-grow overflow-y-auto px-5"
          @scroll="checkShadow"
        >
          <base-input
            v-model="form.state"
            label="State"
            required
            class="hidden"
          />
          <base-input
            v-model="form.companyName"
            :error="backendErrors.companyName"
            label="Company name (if applicable)"
            name="companyName"
            :disabled="submitting"
            :required-asterisk="isRequiredAsteriskVisible"
            entry-class="h-12 rounded-md"
            :style="{
              '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
            }"
          />
          <div class="flex">
            <base-input
              v-model="form.firstName"
              :validation="{
                rules: 'required',
                name: 'First name',
                vid: 'firstName',
              }"
              :disabled="submitting"
              :error="backendErrors.firstName"
              label="First name"
              required
              name="firstName"
              class="mr-[5px]"
              :required-asterisk="isRequiredAsteriskVisible"
              entry-class="h-12 rounded-md"
              :style="{
                '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
              }"
            />
            <base-input
              v-model="form.lastName"
              :validation="{
                rules: 'required',
                name: 'Last name',
                vid: 'lastName',
              }"
              :disabled="submitting"
              :error="backendErrors.lastName"
              label="Last name"
              required
              name="lastName"
              class="ml-[5px]"
              :required-asterisk="isRequiredAsteriskVisible"
              entry-class="h-12 rounded-md"
              :style="{
                '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
              }"
            />
          </div>
          <base-input
            ref="email"
            v-model="form.email"
            :validation="{
              rules: 'required|email',
              name: 'Email',
              vid: 'email',
            }"
            :disabled="submitting"
            :error="backendErrors.email"
            label="Email"
            required
            name="email"
            type="email"
            :required-asterisk="isRequiredAsteriskVisible"
            entry-class="h-12 rounded-md"
            :style="{
              '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
            }"
          />
          <base-input
            v-model="form.mobile"
            :validation="{
              rules: 'required|mobile',
              name: 'Mobile',
              vid: 'mobile',
            }"
            :disabled="submitting"
            :error="backendErrors.mobile"
            type="tel"
            mask="mobile"
            label="Mobile"
            required
            name="mobile"
            :required-asterisk="isRequiredAsteriskVisible"
            entry-class="h-12 rounded-md"
            :style="{
              '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
            }"
          />

          <base-metafield
            :value="addressValue"
            :schema="addressSchema"
            :backend-errors="backendErrors"
            name-prefix="address"
            @input="onAddressChanged"
          />

          <base-checkbox
            v-model="form.default"
            :disabled="submitting || disabledSetDefault"
            class="p-0"
            look="v3"
          >
            Set as default
          </base-checkbox>
        </div>

        <div class="relative flex-shrink-0 p-5">
          <base-button
            type="submit"
            :blocked="submitting || disableSaveBtn"
            blocked-reason="profile-add-address-errors-list"
            full-width
          >
            {{ submitting ? 'Saving...' : 'Save' }}
          </base-button>
          <div id="profile-add-address-errors-list" class="hidden">
            {{ errorsList }}
          </div>
        </div>
      </form>
    </validation-observer>
  </base-aside-page>
</template>
