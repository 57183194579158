<script>
import ui from '/~/core/ui'
import DefaultProductHeader from '/~/extensions/giftcards/components/product/header/product-header.vue'
import EwProductType from './ew-product-type.vue'

export default {
  components: {
    EwProductType,
  },
  extends: DefaultProductHeader,
  setup() {
    return {
      ...DefaultProductHeader.setup?.(...arguments),
      ui,
    }
  },
}
</script>

<template>
  <div class="text-center sm:text-left">
    <h2
      v-if="!ui.mobile && name"
      class="text-2xl font-normal leading-tight text-eonx-neutral-800 md:text-3xl"
    >
      {{ name }}
    </h2>
    <ew-product-type
      v-if="!ui.mobile"
      class="mt-[5px]"
      :physical="physical"
      :gift-card="false"
      :show-delivery="true"
    />
  </div>
</template>
