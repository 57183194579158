<script>
import modal from '/~/core/mdl'
import PostCreatorTabs from '/~rec/components/post/creator/post-creator-tabs.vue'
import PostCreatorForm from '/~rec/components/post/creator/post-creator-form.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'rec-post-creator-modal',
  components: {
    PostCreatorTabs,
    PostCreatorForm,
    BaseMdl,
  },
  props: {
    tabs: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      type: this.$attrs.type,
    }
  },
  methods: {
    navigate(name, options) {
      modal.show(name, options)
    },
    close() {
      modal.hide()
    },
    success() {
      this.close()
      this.$emit('created')
    },
  },
}
</script>

<template>
  <base-mdl
    modal-class="absolute top-0 bottom-auto mx-auto mt-11"
    :padding="false"
  >
    <template #header>
      <div />
    </template>
    <div class="relative">
      <post-creator-tabs v-model="type" :tabs="tabs" />
      <post-creator-form
        v-bind="$attrs"
        :type="type"
        @navigate="navigate"
        @success="success"
        @close="close"
      />
    </div>

    <template #footer>
      <div />
    </template>
  </base-mdl>
</template>
