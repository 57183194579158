<script setup lang="ts">
import { computed } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import ConfirmationPayScheduled from './confirmation-pay-scheduled.vue'

type Order = {
  name: string
  type: string
  accountName: string
  status: string
}

const props = defineProps<{
  order: Order
}>()

const { payment } = useCheckoutReactive()

const isScheduled = computed(() => {
  return props.order.status === 'scheduled'
})
</script>

<template>
  <div>
    <div class="mb-2.5 flex w-full items-center justify-between">
      <div class="flex w-full items-center gap-x-4">
        <div class="shrink-0">
          <payee-icon :payee="payment.statement.payee" />
        </div>
        <div
          class="flex w-full flex-col gap-y-[5px] text-sm text-eonx-neutral-600"
        >
          <div class="flex w-full justify-between">
            <div>
              <div class="text-base font-bold text-eonx-neutral-800">
                {{ payment.statement.payeeName }}
              </div>
              <div class="mt-[5px]">
                {{ payment.statement.statementDateMonthName }} statement
              </div>
              <div class="mt-[5px]">{{ payment.statement.client.name }}</div>
              <div>Account no. {{ payment.statement.clientNumber }}</div>
            </div>
            <div class="self-end text-sm text-eonx-neutral-600">
              {{ formatDollar(order.subtotal) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirmation-pay-scheduled :order="order" />
  </div>
</template>
