<script>
import modal from '/~/core/mdl'
import RenderAvatars from '/~rec/components/render/render-avatars.vue'
import processTextForUrls from '/~/utils/process-text-for-urls'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseDate from '/~/components/base/date/base-date.vue'

export default {
  name: 'rec-post-event',
  components: {
    RenderAvatars,
    BaseLink,
    BaseButton,
    BaseDate,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isInvited() {
      return this.post.type === 'event_member'
    },
  },
  methods: {
    processTextForUrls,
    async onShowAllMembers() {
      modal.show('rec-members-manager', {
        props: {
          source: this.post.event,
          features: 'status',
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="mx-6">
      <div class="flex flex-col flex-wrap xs:flex-row xs:items-center">
        <div class="my-[5px] flex items-center pr-5">
          <base-date
            class="mr-2.5 shrink-0"
            :date="post.event.startsAt"
            look="card"
            selected
          />
          <div class="leading-4">
            <div class="font-bold">
              <base-date :date="post.event.startsAt" type="time" />
              -
              <base-date :date="post.event.endsAt" type="time" />
            </div>

            <span
              class="mt-[5px] block text-sm font-semibold text-eonx-neutral-600"
            >
              {{ post.event.location }}
            </span>
          </div>
        </div>

        <render-avatars
          :users="post.event.members.hits"
          :total-users="post.event.totalMembers"
          size="xse"
          class="my-[5px] mt-6 sm:mt-0"
          @click="onShowAllMembers()"
        />
      </div>

      <div class="mt-6">
        <p
          class="break-words leading-4"
          v-html="processTextForUrls(post.event.description)"
        />
      </div>

      <div class="mt-6 text-center">
        <base-link
          v-if="post.event.id"
          :to="{
            name: 'rec-event',
            params: {
              eventId: post.event.id,
            },
          }"
        >
          <base-button class="w-[174px]">View Event</base-button>
        </base-link>
      </div>
    </div>
  </div>
</template>
