import { computed, ref, reactive } from 'vue'
import { Route } from 'vue-router'
import { ApiResponseData } from '/~/types/api'
import { Algolia } from '/~/types/api/algolia/algolia'
import { OnlineOffersModuleConfig } from '/~/types/api/modules/online-offers'
import {
  AlgoliaWorker,
  getDefaultIndex,
  processAlgoliaConfig,
} from '/~/core/algolia'
import api from '/~/core/api'
import { useExtensions } from '/~/composables/extensions'
import { useSearch } from '/~/composables/search'
import { useUser } from '/~/composables/user'

const { getConfigByName, getManifestByName } = useExtensions()
const { registerAlgoliaSearch } = useSearch()

const ONLINE_OFFERS_MODULE_NAME = 'online-offers'

const algolia = reactive(new AlgoliaWorker())
const categories = ref<any>(null)
const searchQuery = ref<string>('')

const config = computed(() => {
  const { user } = useUser()
  const rawConfig = getConfigByName(ONLINE_OFFERS_MODULE_NAME)

  if (!rawConfig) {
    return
  }

  const result: OnlineOffersModuleConfig & Partial<Algolia> = {
    ...rawConfig,
  }

  if (result.catalogues) {
    const defaultCatalog = result.catalogues.find(
      (item: any) => item.membershipLabel === ''
    )
    const userCatalog = result.catalogues.find(
      (item: any) => item.membershipLabel === user.value.membershipLabel
    )
    const userConfig = userCatalog?.algolia ?? defaultCatalog?.algolia

    if (userConfig) {
      result.indexes = userConfig.indexes
      result.index = getDefaultIndex(userConfig)
      result.algolia_app_id = userConfig.algolia_app_id
      result.algolia_search_key = userConfig.algolia_search_key
    }
  }

  return processAlgoliaConfig(result)
})
const module = computed(() => getManifestByName(ONLINE_OFFERS_MODULE_NAME))
const label = computed(() => module.value?.label ?? 'Online Offers')
const searchConfig = computed(() => config.value?.search ?? null)
const searchGroup = computed(() => searchConfig.value?.group ?? label.value)

const isOnlineOffersEnabled = computed(() => Boolean(module.value))
const isUsingNewIndexes = computed(() =>
  config.value?.indexes?.some((index: any) => index.new)
)
const algoliaIndexes = computed(() => config.value?.indexes || [])
const defaultIndex = computed(
  () => config.value && getDefaultIndex(config.value)
)
const sortValues = computed(() => {
  return algoliaIndexes.value?.map((index: any) => {
    return {
      text: index.label,
      value: index.index_id,
      default: index.default,
    }
  })
})
const defaultSortValue = computed(
  () => sortValues.value.find((v: any) => v.default) || sortValues.value[0]
)

const returnAllocatedAs = computed(() => config.value?.allocated_as)
const isCashbackReturn = computed(() => returnAllocatedAs.value === 'cashback')
const isPointsReturn = computed(() => returnAllocatedAs.value === 'points')

const selectedSorting = ref(defaultIndex.value)
const selectedCategory = ref<string | null>(null)

const routeState = computed(() => {
  const routeState = {
    params: {
      category: selectedCategory.value,
    },
    query: {} as any,
  }

  if (searchQuery.value) {
    routeState.query.search = searchQuery.value
  }

  if (selectedSorting.value && selectedSorting.value !== defaultIndex.value) {
    routeState.query.sort = selectedSorting.value
  }

  return routeState
})

const isFiltersSelected = computed(() => {
  return Boolean(
    searchQuery.value !== '' ||
      (selectedSorting.value !== defaultSortValue.value?.value &&
        selectedSorting.value !== null) ||
      (selectedCategory.value !== categories.value?.[0]?.id &&
        selectedCategory.value !== null)
  )
})

const defaultFacetFilters = computed(() => [
  'type:' + (isUsingNewIndexes.value ? 'merchant' : ''),
])

function resetFilters() {
  searchQuery.value = ''
  selectedSorting.value = defaultIndex.value
  selectedCategory.value = null
}

function initOnlineOffers() {
  algolia.setParams(config.value)

  registerAlgoliaSearch({
    group: config.value?.search?.group ?? 'Online Offers',
    order: 3,
    config: config.value,
    algolia: {
      params: {
        distinct: true,
        facetFilters: defaultFacetFilters.value,
        attributes: [
          'retailer_name',
          'retailer_slug',
          'retailer_alt_logo',
          'retailer_logo',
        ],
      },
    },
    mapping: (item: any) => {
      const image = item?._highlightResult.payload.logo?.value || ''
      const isUcareCdn = image.indexOf('ucarecdn') > -1

      return {
        id: item.objectID,
        image: isUcareCdn ? null : image,
        uploadcare: isUcareCdn ? image : null,
        label: item?._highlightResult?.payload.name?.value || '',
        cashback_rate: parseFloat(
          item?._highlightResult?.payload?.cashback_rate?.value
        ),
        target: {
          name: 'online-offers-retailer',
          params: {
            slug: item?._highlightResult?.merchant_id?.value || '',
          },
        },
      }
    },
  })
}

function syncState({ to }: { to: Route }) {
  selectedCategory.value = to.params?.category || null
  selectedSorting.value = (to.query?.sort as string) || defaultIndex.value
  searchQuery.value = (to.query?.search || '') as any
}

async function getContent() {
  if (!algolia.isSet) {
    initOnlineOffers()
  }

  // NOTE: reset items to trigger processing in core/algolia
  algolia.reset()

  await algolia.getDataMultiple({
    multiple: [
      {
        indexName: selectedSorting.value,
        query: (searchQuery.value || '').trim(),
        params: {
          facetFilters: [
            defaultFacetFilters.value,
            `payload.categories:${algolia.decodePath(selectedCategory.value)}`,
          ],
        },
      },
      {
        indexName: defaultIndex.value,
        params: {
          page: 0,
          hitsPerPage: 1,
          attributesToRetrieve: [],
          facets: ['payload.categories'],
        },
      },
    ],
  })

  const [categoriesResult] = (algolia as any).multiple

  const categoriesFacets = algolia.parseFacets(categoriesResult)

  if (categoriesFacets) {
    const newCategories = (categoriesFacets as any).get('payload.categories')

    if (newCategories) {
      newCategories.values.unshift({
        label: 'All categories',
        id: null,
        count: newCategories.total,
      })
      categories.value = newCategories.values
    } else {
      categories.value = [
        {
          label: 'All categories',
          id: null,
        },
      ]
    }
  }
}

interface MerchantPayload {
  alt_logo: string | null
  categories: string[]
  cashback_rate: number
  eonx_rate: number
  feed_merchant_id: string
  logo: string | null
  logo_white_bg: boolean
  name: string
  provider_rate: number
  summary: string | null
  terms: string
  website: string | null
}

interface Merchant {
  catalogue_id: string
  created_at: string
  hash: string
  id: string
  merchant_id: string
  objectID: string
  payload: MerchantPayload
  platform_url: string
  updated_at: string
  version: number
}

interface OnlineOffersMerchantRawData {
  coupons: any[]
  merchant: Merchant
  promotions: any[]
}

async function getMerchantById(id: string) {
  try {
    const { data } = await api.get<
      ApiResponseData<OnlineOffersMerchantRawData>
    >(`/v3/online-offers/merchants/${id}`)

    return data
  } catch (error) {
    console.warn(error)
    return null
  }
}

async function getOutboundLink({
  merchantId,
  offerId = '',
}: {
  merchantId: string
  offerId?: string
}) {
  try {
    const { data } = await api.post<ApiResponseData<{ url: string }>>(
      `/v3/online-offers/outbound-links/merchants/${merchantId}/offers${
        offerId ? `/${offerId}` : ''
      }`
    )

    return data?.url ?? null
  } catch (error) {
    console.warn(error)
    return null
  }
}

export function useOnlineOffers() {
  return {
    algolia,
    routeState,
    selectedCategory,
    selectedSorting,
    searchQuery,
    sortValues,
    defaultSortValue,
    categories,
    isFiltersSelected,
    isOnlineOffersEnabled,
    searchGroup,
    isCashbackReturn,
    isPointsReturn,

    getMerchantById,
    resetFilters,
    syncState,
    initOnlineOffers,
    getContent,
    getOutboundLink,
  }
}
