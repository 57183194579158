<script setup lang="ts">
import { computed } from 'vue'
import { formatBsb, formatDollar } from '/~/utils/format'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { BatchOrderTransaction } from '/~/composables/batch-order'

const props = defineProps<{
  payee: BatchOrderTransaction
  loading: boolean
}>()

const status = computed(() => {
  if (
    ['new', 'payees_syncing', 'processing', 'file_processing'].includes(
      props.payee.status
    )
  ) {
    return {
      color: 'text-orange-700',
      icon: 'heroicons/mini/clock',
    }
  }
  if (
    ['completed', 'payees_synced', 'file_processed'].includes(
      props.payee.status
    )
  ) {
    return {
      color: 'text-emerald-700',
      icon: 'heroicons/solid/check-circle',
    }
  }
  return {
    color: 'text-red-700',
    icon: 'heroicons/solid/exclamation-circle',
  }
})
</script>

<template>
  <div class="flex text-eonx-neutral-600">
    <div
      class="flex flex-1 items-center border-b border-eonx-neutral-400"
      :class="loading ? 'py-1.5' : 'py-2'"
    >
      <div
        class="flex w-16 flex-none items-center justify-center"
        :class="status.color"
      >
        <div
          v-if="loading"
          class="h-5 w-5 animate-pulse rounded-full bg-eonx-neutral-100"
        />
        <base-icon v-else :svg="status.icon" :size="20" class="inline-block" />
      </div>
      <div v-if="loading" class="min-w-24 flex-1 space-y-1 px-2">
        <div class="h-5 animate-pulse bg-eonx-neutral-100 text-transparent" />
        <div class="flex space-x-2">
          <div
            class="h-5 w-16 animate-pulse bg-eonx-neutral-100 text-transparent"
          />
          <div
            class="h-5 w-full animate-pulse bg-eonx-neutral-100 text-transparent"
          />
        </div>
      </div>
      <div v-else class="min-w-24 flex-1 px-2">
        <div>{{ payee.accountName }}</div>
        <div>{{ formatBsb(payee.bsb) }} {{ payee.accountNumber }}</div>
      </div>
      <div class="w-24 flex-none overflow-hidden text-ellipsis px-2">
        <div v-if="loading" class="h-5 animate-pulse bg-eonx-neutral-100" />
        <template v-else>
          {{ payee.reference }}
        </template>
      </div>
      <div class="w-24 px-2">
        <div v-if="loading" class="h-5 animate-pulse bg-eonx-neutral-100" />
        <template v-else>
          {{ formatDollar(payee.amount) }}
        </template>
      </div>
    </div>
  </div>
</template>
