<script>
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'rec-base-state',
  components: {
    BaseImage,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: 'recroom/state/simple-state.svg',
    },
    wide: {
      type: Boolean,
      default: false,
    },
    bg: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <div class="flex flex-col items-center justify-center text-center">
    <div
      class="flex w-full grow items-center justify-center bg-contain bg-center bg-no-repeat"
      :class="{
        'mb-5 py-11': bg,
      }"
      :style="
        bg ? `background-image: url('/images/recroom/state/bg-state.svg');` : ''
      "
    >
      <div
        v-if="image"
        :class="{
          'w-3/4': wide,
          'w-3/6': !wide,
        }"
      >
        <div v-if="image" class="flex max-h-48 sm:max-h-[300px]">
          <base-image ref="image" :src="image" />
        </div>
      </div>
    </div>

    <main>
      <h3 v-if="title" class="mb-[5px] w-full text-xl font-bold md:text-xl">
        {{ title }}
      </h3>

      <p v-if="subtitle" class="max-w-lg text-eonx-neutral-600">
        {{ subtitle }}
      </p>
    </main>
  </div>
</template>
