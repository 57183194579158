<script>
import Vue, { computed } from 'vue'
import Analytics from '/~/core/analytics'
import bottomSheet from '/~/core/bottom-sheet'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useScheduledPayments } from '/~/composables/scheduled-payments'
import { useStatementAccounts } from '/~/composables/statements'
import DrawerBankTileV2 from './drawer-bank-tile.v2.vue'

export default {
  name: 'drawer-bank-tile-v3',
  extends: DrawerBankTileV2,
  setup(props) {
    const { deletePaymentMethod } = usePaymentMethods()
    const { isScheduledPaymentsEnabled } = useScheduledPayments()
    const { isStatementAccountsEnabled } = useStatementAccounts()

    const removePaymentMethodText = computed(() => {
      if (
        isScheduledPaymentsEnabled.value ||
        isStatementAccountsEnabled.value
      ) {
        return `Are you sure you want to delete the selected payment method? This action cannot be undone.

          Please note that any direct debit or scheduled payments associated with this payment method will also be disabled/removed.`
      } else {
        return 'Are you sure you want to delete the selected payment method? This action cannot be undone.'
      }
    })

    function onRemoveClick() {
      bottomSheet.show('confirmation-modal-v2', {
        to: 'menu-modal',
        props: {
          icon: 'heroicons/solid/trash',
          iconSize: 64,
          title: 'Delete payment method',
          text: removePaymentMethodText.value,
          analytics: {
            confirm: {
              pageGroup: 'Drawer',
              page: 'Payment Methods',
              component1: 'Bank account',
              label: 'Click on Confirm bank account removal',
            },
            cancel: {
              pageGroup: 'Drawer',
              page: 'Payment Methods',
              component1: 'Bank account',
              label: 'Click on Cancel bank account removal',
            },
          },
          onConfirm: onDeleteConfirmationModalConfirm,
        },
      })
    }

    async function onDeleteConfirmationModalConfirm() {
      try {
        await deletePaymentMethod(props.account.id)

        Analytics.sendEvent({
          pageGroup: 'Drawer',
          page: 'Payment Methods',
          component1: 'Bank account',
          label: 'Bank account removed',
        })
      } catch (error) {
        if (!error.data?.message) {
          Vue.notify({
            type: 'error',
            text: 'Something went wrong while deleting. Please try again.',
          })
        }
      }
    }

    return {
      ...DrawerBankTileV2.setup?.(...arguments),
      onRemoveClick,
    }
  },
}
</script>

<template>
  <div class="relative mx-auto max-w-72">
    <drawer-method-tile
      :method="presentation"
      :class="[
        !isVerified && !inForm && !initializing
          ? 'cursor-pointer'
          : 'cursor-default',
      ]"
      @click="verify"
    />
    <div
      class="absolute top-0 right-0 -mt-2.5 -mr-2.5 flex items-center gap-x-[5px]"
    >
      <button
        v-if="!isVerified"
        type="button"
        class="flex cursor-pointer items-center rounded-full py-[5px] px-2.5 text-white"
        :style="{
          backgroundColor: '#F59E0B',
        }"
        @click="verify"
      >
        <base-loader v-if="initializing" size="sm" />
        <template v-else>
          <base-icon svg="v2/heroic/exclamation" :size="24" class="mr-[5px]" />
          <span class="whitespace-no-wrap font-bold uppercase">
            Verify account
          </span>
        </template>
      </button>
      <button
        v-analytics:click="{
          pageGroup: 'Drawer',
          page: 'Payment Methods',
          component1: 'Bank account',
          label: 'Click on remove bank account',
        }"
        type="button"
        class="flex h-10 w-10 items-center justify-center rounded-full border-4 bg-red-700 text-white disabled:bg-gray-300"
        :class="{
          'border-white': !isDarkThemeForEwallet,
          'border-gray-900': isDarkThemeForEwallet,
        }"
        :disabled="deleting"
        :aria-label="removeButtonLabel"
        @click="onRemoveClick()"
      >
        <base-loader v-if="deleting" size="sm" />
        <base-icon v-else svg="v2/heroic/trash" :size="16" />
      </button>
    </div>
  </div>
</template>
