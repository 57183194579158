<script>
import DefaultDiningDetails from '/~/extensions/dining/templates/default/views/details/dining-details.vue'
import RetailerProduct from '/~/extensions/giftcards/templates/australiapost/components/product/retailer-product.vue'

export default {
  components: {
    RetailerProduct,
  },
  extends: DefaultDiningDetails,
}
</script>
