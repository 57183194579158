<script>
import modal from '/~/core/mdl'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import EarnBlock from './components/earn-block.vue'
import BaseHeader from '/~/components/base/header/base-header'
import BaseButton from '/~/components/base/button/base-button'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-earn-modal',
  components: {
    RecognitionHeader,
    EarnBlock,
    EwCatalogProcessor,
    BaseHeader,
    BaseButton,
    BaseMdl,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: null,
      validator: (v) => /award|hi5/.test(v),
    },
  },
  setup() {
    const { profile } = useRecProfile()

    return {
      currecy: profile.currecy,
      modal,
    }
  },
  computed: {
    isAward() {
      return this.type === 'award'
    },
    note() {
      const text = this.isAward ? 'nominations' : 'Hi-5s'

      return `Please note once the points limit for ${text} is reached, you will not earn more ${this.currency} for ${text}. The limit resets at midnight daily.`
    },
  },
}
</script>

<template>
  <base-mdl width="xse" fullscreen="mobile">
    <template #header>
      <base-header type="closable" class="text-white">
        <recognition-header icon="rec/round/token" color="warning" animated />
      </base-header>
    </template>
    <h3 class="mb-5 text-center text-2xl">
      Earn up to {{ data.getMaxEarn() }}
    </h3>
    <ew-catalog-processor :processor="data" :columns="1" :tile-class="false">
      <template #empty>
        <div>No Earns Config found.</div>
      </template>
      <template #tile="{ tile: earn }">
        <earn-block :earn="earn" />
      </template>
    </ew-catalog-processor>

    <div class="w-full">
      <div
        class="text-center text-xs font-bold text-eonx-neutral-600 sm:-mt-[15px]"
      >
        {{ note }}
      </div>
      <base-button
        type="primary"
        full-width
        class="mt-[15px] hidden sm:block"
        @click="modal.hide()"
      >
        Got it
      </base-button>
    </div>
  </base-mdl>
</template>
