<script>
import BaseButton from '/~/components/base/button/base-button'
import { useCheckout } from '/~/composables/checkout'
import { formatDollar } from '/~/utils/format/money'

export default {
  components: {
    BaseButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const { total } = useCheckout()

    return {
      total,
      formatDollar,
    }
  },
  methods: {
    onSubmit() {
      this.$emit('onSubmit')
    },
  },
}
</script>

<template>
  <base-button
    :loading="isLoading"
    :disabled="isDisabled"
    full-width
    @click="onSubmit"
  >
    <span class="flex items-end">
      <span class="text-sm leading-5">Pay</span>
      <span class="ml-2.5 text-xl leading-6">
        {{ formatDollar(total) }}
      </span>
    </span>
  </base-button>
</template>
