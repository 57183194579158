<script>
import get from 'lodash-es/get'
import { ref, nextTick, onUnmounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import { useQrConnect } from '/~/extensions/qr-connect/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useQrPay } from '/~/composables/use-qr-pay'
import MenuPayScanner from './scan-scanner.vue'
import MenuQrCodeManuallyModal from '../../modals/menu-qr-code-manually-modal.vue'

export default {
  name: 'scan-home',
  components: {
    MenuPayScanner,
    MenuQrCodeManuallyModal,
    BaseButton,
    BaseIcon,
    BaseAsidePage,
  },
  setup() {
    const router = useRouter()
    const {
      isQrConnectEnabled,
      isQrPayEnabled,
      qrPayBegin,
      getCashInFee,
      qrCashInCheckout,
      qrPayConfirm,
      getQrPayOrder,
      qrPayFail,
    } = useQrPay()
    const { defaultEwallet, fetchPaymentMethods } = usePaymentMethods()
    const { processQRCode } = useQrConnect()

    const errorMessage = ref('')
    const isProcessing = ref(false)
    const isReaderActivated = ref(false)
    const isManualInputVisible = ref(false)
    const cashInPayload = ref(null)

    function paymentFailed(error) {
      errorMessage.value = error
    }

    function onQrManually() {
      isReaderActivated.value = false
      isManualInputVisible.value = true
    }

    async function onDecode(qrString) {
      const isUrl = qrString.indexOf('http') === 0

      isProcessing.value = true
      isReaderActivated.value = false

      if (isUrl) {
        await this.processQRConnect(qrString)
      } else {
        let qrData

        try {
          qrData = await qrPayBegin(qrString)
          const { ewmpTransaction, merchantCurrencyAmount } = qrData ?? {}
          const eTransactionType = ewmpTransaction?.type
          const eTransactionId = ewmpTransaction?.id

          // add timeout handler to cancel the transaction due to timeout
          this.addCancelTransactionBecauseOfTimeoutHandler(qrData)
          // remove timeout handler if the transaction was completed or canceled
          emitter.once(
            'cash-in:cancel',
            this.removeCancelTransactionBecauseOfTimeoutHandler
          )
          emitter.once(
            'cash-in:proceed',
            this.removeCancelTransactionBecauseOfTimeoutHandler
          )
          emitter.once(
            'cash-in:failed',
            this.removeCancelTransactionBecauseOfTimeoutHandler
          )
          emitter.once(
            'payment:cancel',
            this.removeCancelTransactionBecauseOfTimeoutHandler
          )
          emitter.once(
            'activity:refresh',
            this.removeCancelTransactionBecauseOfTimeoutHandler
          )
          emitter.once(
            'payment:failed',
            this.removeCancelTransactionBecauseOfTimeoutHandler
          )

          if (eTransactionType === 'ewallet_pay') {
            this.$router.push({
              hash: '#profile-pay-qr',
              params: {
                qrData,
              },
            })
          } else if (
            eTransactionType === 'cash_in' &&
            defaultEwallet.value &&
            eTransactionId &&
            merchantCurrencyAmount
          ) {
            cashInPayload.value = {
              paymentDestinations: [
                {
                  paymentMethodId: defaultEwallet.value.id,
                  amount: merchantCurrencyAmount,
                },
              ],
              transactionId: eTransactionId,
              amount: merchantCurrencyAmount,
              merchantCurrencyAmount,
              merchantTemporaryTransactionId:
                qrData.merchantTemporaryTransactionId,
            }

            const { data } = await getCashInFee(cashInPayload.value)

            router.push({
              hash: '#profile-pay-cash-in',
            })

            nextTick(() => {
              emitter.emit('menu-pay-cash-in:details', {
                qrData,
                details: {
                  fee: data.fee,
                  subtotal: data.subtotal,
                  total: data.total,
                },
              })
            })
          } else {
            errorMessage.value = 'Unsupported transaction type'
          }
        } catch (error) {
          onPayFail(qrData)
          errorMessage.value =
            error.data?.message ||
            error.message ||
            'Something went wrong. Please, try again.'
        }
      }
    }

    async function onPayFail(qrData) {
      if (!qrData) {
        return
      }

      try {
        await qrPayFail({
          customerCurrencyAmount: String(qrData.merchantCurrencyAmount),
          merchantCurrencyAmount: qrData.merchantCurrencyAmount,
          merchantTemporaryTransactionId: qrData.merchantTemporaryTransactionId,
        })

        emitter.emit('cash-in:failed')
      } catch (error) {
        console.error(error)
        this.$notify({
          text: 'Something went wrong while uploading. Please try again.',
          type: 'error',
          duration: 5000,
        })
      }
    }

    async function cashInCancel({ qrData, details }) {
      try {
        if (!qrData) {
          throw new Error()
        }
        emitter.emit('menu-pay-cash-in:status', 'cancelling')
        await qrPayConfirm({
          customerCurrencyAmount: String(details.total),
          customerWalletId: defaultEwallet.value.id,
          merchantCurrencyAmount: qrData.merchantCurrencyAmount,
          merchantTemporaryTransactionId: qrData.merchantTemporaryTransactionId,
          technicalSwitchTransactionId: null,
          status: 'CANCELLED',
        })
        this.$notify({
          text: 'Transaction cancelled by the user',
          type: 'warning',
          duration: 5000,
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          text: 'Something went wrong while uploading. Please try again.',
          type: 'error',
          duration: 5000,
        })
      } finally {
        emitter.emit('menu-pay-cash-in:status', null)
        router.push({
          hash: '#profile-home',
        })
      }
    }

    async function cashInProceed({ qrData, details }) {
      try {
        emitter.emit('menu-pay-cash-in:status', 'processing')
        const order = await qrCashInCheckout(cashInPayload.value)

        await qrPayConfirm({
          customerCurrencyAmount: String(details.total),
          customerWalletId: defaultEwallet.value.id,
          customerWalletTransactionId: order.data.number,
          merchantCurrencyAmount: qrData.merchantCurrencyAmount,
          merchantTemporaryTransactionId: qrData.merchantTemporaryTransactionId,
          technicalSwitchTransactionId: null,
        })

        const qrPayOrder = await getQrPayOrder(order.data.number)

        await fetchPaymentMethods()
        emitter.emit('menu-pay-cash-in:status', null)

        router.push({
          hash: '#profile-pay-confirmation',
        })

        nextTick(() => {
          emitter.emit('menu-pay-confirmation', {
            details: {
              payee: qrPayOrder.data.payee,
              payer: qrPayOrder.data.payer,
              number: order.data.number,
              createdAt: order.data.completedAt,
              status: order.data.status,
              amount: order.data.total,
            },
            type: 'cash-in',
            backRoute: { hash: '#profile-home' },
          })
        })
      } catch (error) {
        emitter.emit('menu-pay-cash-in:status', null)
        errorMessage.value =
          error.data?.message ||
          error.message ||
          'Something went wrong. Please, try again.'
        onPayFail(qrData)
        router.push({
          hash: '#profile-scan',
        })
      }
    }

    function onManualSubmit(qrString) {
      onDecode(qrString)
    }

    function onTryAgain() {
      errorMessage.value = false
      isProcessing.value = false
    }

    emitter.on('cash-in:cancel', cashInCancel)
    emitter.on('cash-in:proceed', cashInProceed)
    emitter.on('payment:failed', paymentFailed)

    onUnmounted(() => {
      emitter.off('cash-in:cancel', cashInCancel)
      emitter.off('cash-in:proceed', cashInProceed)
      emitter.off('payment:failed', paymentFailed)
    })

    return {
      errorMessage,
      isManualInputVisible,
      isProcessing,
      isReaderActivated,
      isQrConnectEnabled,
      isQrPayEnabled,
      onQrManually,
      onDecode,
      onManualSubmit,
      onTryAgain,
      processQRCode,
      router,
    }
  },
  methods: {
    async processQRConnect(code) {
      const url = new URL(code)
      const qrCode = url.searchParams.get('qrConnectCode')

      this.isProcessing = true

      try {
        const [error, success] = await this.processQRCode({ qrCode })

        if (success) {
          this.$router.replace({ hash: '#profile-home' })
        } else {
          this.errorMessage = get(
            error,
            'message',
            'Something went wrong, please try again'
          )
        }
      } catch (error) {
        console.error('ewallet-menu', error)
      } finally {
        this.isProcessing = false
      }
    },
    addCancelTransactionBecauseOfTimeoutHandler(qrData) {
      const {
        technicalSwitchTransactionId,
        merchantTemporaryTransactionId,
        merchantCurrencyAmount,
        ewmpTransaction,
      } = qrData
      const { qrPayConfirm } = useQrPay()

      const timeoutInMS = Date.parse(ewmpTransaction.timeoutAt) - Date.now()

      window.cancelTransactionTimeout = setTimeout(() => {
        qrPayConfirm({
          customerCurrencyAmount: merchantCurrencyAmount,
          technicalSwitchTransactionId,
          merchantTemporaryTransactionId,
          status: 'TIMED-OUT',
        })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e ?? 'Cancel transaction because of timeout failed')
          })
          .finally(() => {
            this.errorMessage =
              'Transaction taking too long and has been aborted, please try again.'
            this.router.push({
              hash: '#profile-scan',
            })
          })
      }, timeoutInMS)
    },
    removeCancelTransactionBecauseOfTimeoutHandler() {
      clearTimeout(window.cancelTransactionTimeout)
    },
  },
}
</script>
<template>
  <base-aside-page title="Scan" :back="{ hash: '#profile-home' }">
    <div class="mb-5 text-center font-bold">
      Scan to perform any of the following actions:
    </div>
    <div class="mb-2.5 flex justify-center">
      <div v-if="isQrConnectEnabled" class="flex flex-col items-center">
        <div
          class="mx-[15px] flex h-20 w-20 items-center justify-center rounded-full bg-eonx-neutral-50 text-eonx-neutral-600"
        >
          <base-icon svg="v2/box-icons/bx-log-in-circle" :size="40" />
        </div>
        <div class="mt-[5px] font-bold">Gain Access</div>
      </div>
      <div v-if="isQrPayEnabled" class="flex flex-col items-center">
        <div
          class="mx-[15px] flex h-20 w-20 items-center justify-center rounded-full bg-eonx-neutral-50 text-eonx-neutral-600"
        >
          <base-icon svg="v2/heroic/credit-card" :size="40" />
        </div>
        <div class="mt-[5px] font-bold">Pay</div>
      </div>
    </div>
    <menu-pay-scanner
      v-show="!errorMessage"
      :activated.sync="isReaderActivated"
      :processing.sync="isProcessing"
      @qr-decoded="onDecode"
    />
    <div v-if="errorMessage" class="mt-11 flex flex-col">
      <div
        class="mx-auto flex items-center rounded-md border border-current py-2.5 px-4 text-red-700"
      >
        <base-icon
          svg="v2/custom/exclamation-circle-outline"
          :size="24"
          class="mr-2.5 flex-shrink-0"
        />
        <div class="font-bold">
          {{ errorMessage }}
        </div>
      </div>
      <base-button class="w-30 mx-auto mt-11" @click="onTryAgain">
        Try again
      </base-button>
    </div>
    <p
      v-if="!isProcessing && !errorMessage"
      class="mt-10 text-center font-bold"
    >
      Having problem scanning?
      <base-button look="link" @click="onQrManually">
        Enter QR code manually
      </base-button>
    </p>
    <menu-qr-code-manually-modal
      v-show="isManualInputVisible"
      :visible.sync="isManualInputVisible"
      @submit="onManualSubmit"
    />
  </base-aside-page>
</template>
