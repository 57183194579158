<script>
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'

export default {
  name: 'ew-product-type',
  components: {
    BaseIcon,
  },
  props: {
    physical: {
      type: Boolean,
      default: false,
    },
    giftCard: {
      type: Boolean,
      default: false,
    },
    gift: {
      type: Boolean,
      default: false,
    },
    mobileShort: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: [Number, String],
      default: null,
    },
    giftIcon: {
      type: String,
      default: 'heroicons/outline/gift',
    },
    digitalIcon: {
      type: String,
      default: 'plain/ewallet',
    },
    physicalIcon: {
      type: String,
      default: 'estoreDelivery',
    },
    physicalByLabel: {
      type: String,
      default: 'supplier',
    },
    showDelivery: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { ewalletLabels } = useCms()

    return {
      ui,
      ewalletLabels,
    }
  },
  computed: {
    hasGift() {
      return this.gift && this.giftCard
    },
    title() {
      let type
      let postfix
      const isMobile = this.mobileShort && ui.mobile

      if (this.physical) {
        type = 'Physical'

        if (this.giftCard) {
          postfix = 'delivered by AusPost'
        } else {
          postfix = `delivered by ${this.physicalByLabel}`
        }
      } else {
        type = 'Digital'
        postfix = `delivered to ${this.ewalletLabels.ewallet}`
      }

      if (isMobile) {
        return postfix[0].toUpperCase() + postfix.slice(1)
      }

      return `${type} product ${postfix}`
    },
  },
}
</script>

<template>
  <div class="flex items-center text-sm font-bold leading-tight">
    <template v-if="physical">
      <base-icon
        v-if="hasGift"
        :size="iconSize || 'md'"
        :svg="giftIcon"
        class="mr-[5px]"
      />
      <base-icon
        v-else-if="showDelivery"
        :size="iconSize || 'md'"
        :svg="giftCard ? 'auspost' : physicalIcon"
        class="mr-2.5"
      />

      <slot name="physical">
        <span>
          {{ title }}
        </span>
      </slot>
    </template>
    <template v-else>
      <base-icon
        v-if="hasGift"
        :size="iconSize || 'md'"
        :svg="giftIcon"
        class="mr-[5px]"
      />
      <base-icon
        v-else-if="showDelivery"
        :size="iconSize || 'md'"
        :svg="digitalIcon"
        class="mr-2.5"
      />

      <slot name="digital">
        <span>
          {{ title }}
        </span>
      </slot>
    </template>
  </div>
</template>
