<script>
import DefaultGcFilterDesktop from '/~/extensions/giftcards/components/gc-filter.desktop.vue'
import { useGcCinema } from '/~/extensions/gift-cards-cinema/composables'
import { computed, ref, watch } from 'vue'
import debounce from 'lodash-es/debounce'

export default {
  name: 'gift-cards-cinema-filter',
  extends: DefaultGcFilterDesktop,
  setup() {
    const {
      gcCinemaCategories,
      gcCinemaSearchQuery,
      gcCinemaSelectedCardType,
      gcCinemaSelectedSorting,
      gcCinemaSelectedCategory,
      isGcCinemaEnabledForPhysical,
      gcCinemaCardTypes,
      gcCinemaSortValues,
      getGcCinemaCategories,
      isGcCinemaFiltersSelected,
      resetGcCinemaFilters,
      gcCinemaSearchGroup,
    } = useGcCinema()

    const localSearchQuery = ref(gcCinemaSearchQuery.value)
    const searchFocused = ref(false)

    watch(gcCinemaSearchQuery, (value) => {
      // NOTE: do not update local search value if input have focus
      if (!searchFocused.value) {
        localSearchQuery.value = value
      }
    })

    const onSearchInput = debounce(function (value) {
      gcCinemaSearchQuery.value = value
    }, 350)

    function onSearchBlur() {
      searchFocused.value = false
      gcCinemaSearchQuery.value = localSearchQuery.value
    }

    function onSearchEnter() {
      gcCinemaSearchQuery.value = localSearchQuery.value
    }

    const searchPlaceholder = computed(
      () => `Search ${gcCinemaSearchGroup.value}`
    )

    return {
      ...DefaultGcFilterDesktop.setup?.(...arguments),
      categories: gcCinemaCategories,
      searchQuery: gcCinemaSearchQuery,
      selectedCardType: gcCinemaSelectedCardType,
      selectedSorting: gcCinemaSelectedSorting,
      selectedCategory: gcCinemaSelectedCategory,
      isPhysicalEnabled: isGcCinemaEnabledForPhysical,
      cardTypes: gcCinemaCardTypes,
      sortValues: gcCinemaSortValues,
      getGcCinemaCategories,
      isFiltersSelected: isGcCinemaFiltersSelected,
      resetFilters: resetGcCinemaFilters,
      localSearchQuery,
      onSearchInput,
      onSearchBlur,
      onSearchEnter,
      searchPlaceholder,
    }
  },
}
</script>
