import api from '/~/core/api'

export class S3Driver {
  async upload(uploadableFile) {
    uploadableFile.uploading = true

    let signedUrlOptions

    try {
      signedUrlOptions = await this.fetchSignedUrl(uploadableFile)
    } catch (error) {
      uploadableFile.error = `There was an error fetching signed url: ${error.message}`
      uploadableFile.uploading = false
      throw error
    }

    await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      uploadableFile.cancel = () => xhr.abort()

      xhr.open('PUT', signedUrlOptions.url)

      for (const k in signedUrlOptions.headers ?? {}) {
        if (k === 'Host') {
          continue
        }

        xhr.setRequestHeader(k, signedUrlOptions.headers[k][0])
      }

      xhr.onload = () => {
        if (xhr.status === 200) {
          uploadableFile.id = signedUrlOptions.id
          resolve()
        } else {
          uploadableFile.uploading = false
          uploadableFile.error = `There was an error uploading ${uploadableFile.file.name} file to server: ${xhr.responseText}`
          reject(new Error(uploadableFile.error))
        }
      }

      xhr.onerror = () => {
        uploadableFile.uploading = false
        uploadableFile.error = `There was a network error uploading ${uploadableFile.file.name} file to server`
        reject(new Error(uploadableFile.error))
      }

      if (xhr.upload) {
        xhr.upload.onprogress = (event) => {
          uploadableFile.loaded = event.loaded
          uploadableFile.loadedPercents = Math.floor(
            (event.loaded * 100) / event.total
          )
          uploadableFile.total = event.total
        }
      }

      xhr.send(uploadableFile.file)
    })

    uploadableFile.uploading = false
    uploadableFile.uploaded = true
  }

  async fetchSignedUrl(uploadableFile) {
    const response = await api.post('/v3/file-upload-url', {
      name: uploadableFile.file.name,
      fileType: uploadableFile.fileType,
    })

    return response.data
  }
}

export default S3Driver
