<script>
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import WgtBgText from '../wgt/wgt-bg-text.vue'

export default {
  name: 'widget-home-banner',
  components: {
    BaseAction,
    BaseIcon,
    WgtBgText,
  },
  props: {
    fullHeightContentBackground: {
      type: Boolean,
      default: false,
    },
    bannerTitle: {
      type: Object,
      default: () => ({}),
    },
    titleDesktopSize: {
      type: String,
      default: '',
    },
    titleMobileSize: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: '',
    },
    titleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerSubtitle: {
      type: Object,
      default: () => ({}),
    },
    subtitleDesktopSize: {
      type: String,
      default: '',
    },
    subtitleMobileSize: {
      type: String,
      default: '',
    },
    subtitleColor: {
      type: String,
      default: '',
    },
    subtitleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerContent: {
      type: Object,
      default: () => ({}),
    },
    contentDesktopSize: {
      type: String,
      default: '',
    },
    contentMobileSize: {
      type: String,
      default: '',
    },
    contentRightPadding: {
      type: String,
      default: '',
    },
    contentColor: {
      type: String,
      default: '',
    },
    contentBackgroundColor: {
      type: String,
      default: '',
    },
    bannerButton: {
      type: Object,
      default: () => ({}),
    },
    buttonIcon: {
      type: Boolean,
      default: true,
    },
    buttonColor: {
      type: String,
      default: '',
    },
    buttonTextColor: {
      type: String,
      default: '',
    },
    rightImage: {
      type: Object,
      default: () => ({}),
    },
    leftImage: {
      type: Object,
      default: () => ({}),
    },
    fullScreenImage: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    sectionStyle() {
      return {
        backgroundImage:
          ui.mobile && this.fullScreenImage.mobile
            ? ''
            : `url(${cdn(this.fullScreenImage.desktop).preview('2352x692').format('auto').quality('smart').url()})`,
        backgroundPosition: 'top center',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    },
    reverseBlocks() {
      return this.leftImage.desktop && ui.mobile
    },
    isTopImage() {
      return !!this.rightImage.mobile || !!this.fullScreenImage.mobile
    },
    topImageStyle() {
      return {
        backgroundImage: this.rightImage.mobile
          ? `url(${cdn(this.rightImage.mobile).preview('1710x440').format('auto').quality('smart').url()})`
          : this.fullScreenImage.mobile
          ? `url(${cdn(this.fullScreenImage.mobile).preview('1710x972').format('auto').quality('smart').url()})`
          : '',
        height: '13.75rem',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    },
    rightImageStyle() {
      return {
        backgroundImage: `url(${cdn(this.rightImage.desktop).preview('1176x644').format('auto').quality('smart').url()}`,
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    },
    infoStyle() {
      return {
        backgroundColor:
          !this.fullScreenImage.desktop || this.leftImage.desktop
            ? this.backgroundColor
            : 'transparent',
        backgroundImage: this.leftImage.desktop
          ? `url(${cdn(this.leftImage.desktop).preview('1176x644').format('auto').quality('smart').url()})`
          : this.leftImage.mobile
          ? `url(${cdn(this.leftImage.mobile).preview('1176x644').format('auto').quality('smart').url()})`
          : 'none',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    },
    buttonStyle() {
      return {
        backgroundColor: this.buttonColor,
        color: this.buttonTextColor,
      }
    },
  },
}
</script>
<template>
  <section
    class="rounded-lg shadow"
    :class="leftImage.desktop && ui.mobile && 'flex flex-col-reverse'"
  >
    <div
      v-if="ui.mobile && isTopImage"
      :class="{
        'rounded-t-lg': !reverseBlocks,
        'rounded-b-lg': reverseBlocks,
      }"
      :style="topImageStyle"
    />
    <div class="flex items-stretch">
      <div
        class="p-5 md:p-11"
        :class="{
          'text-left': bannerContent.contentAlignmentX === 'left',
          'text-right': bannerContent.contentAlignmentX === 'right',
          'text-center': bannerContent.contentAlignmentX === 'center',
          'w-1/2': !ui.mobile && rightImage.desktop,
          'w-full': ui.mobile || !rightImage.desktop,
          'md:p-12': bannerContent.padding === 'md',
          'md:px-12 md:py-16': bannerContent.padding === 'lg',
          'py-12': fullScreenImage.desktop,
          'rounded-tl-lg': !ui.mobile || !rightImage.mobile || reverseBlocks,
          'rounded-tr-lg': ui.mobile
            ? !rightImage.mobile || reverseBlocks
            : !rightImage.desktop,
          'rounded-bl-lg': !reverseBlocks,
          'rounded-br-lg': ui.mobile ? !reverseBlocks : !rightImage.desktop,
        }"
        :style="infoStyle"
      >
        <div
          v-if="bannerTitle.text"
          class="pb-[5px] font-bold leading-tight"
          :class="[
            titleDesktopSize,
            titleMobileSize,
            bannerTitle.padding === 'md' && 'xs:pb-5',
            bannerTitle.padding === 'lg' && 'xs:pb-[30px]',
          ]"
        >
          <wgt-bg-text
            :content="bannerTitle.text"
            :text-color="titleColor"
            :background-color="titleBackgroundColor"
            :full-height-background="fullHeightContentBackground"
          />
        </div>
        <div
          v-if="bannerSubtitle.text"
          class="pb-[5px] font-bold leading-tight"
          :class="[
            subtitleDesktopSize,
            subtitleMobileSize,
            contentRightPadding === 'md' && 'xs:pr-32',
            contentRightPadding === 'lg' && 'xs:pr-56',
            bannerSubtitle.padding === 'md' && 'xs:pb-5',
            bannerSubtitle.padding === 'lg' && 'xs:pb-[30px]',
          ]"
        >
          <wgt-bg-text
            :content="bannerSubtitle.text"
            :text-color="subtitleColor"
            :background-color="subtitleBackgroundColor"
            :full-height-background="fullHeightContentBackground"
          />
        </div>
        <div
          v-if="bannerContent.text"
          :class="[
            contentDesktopSize,
            contentMobileSize,
            bannerContent.padding === 'md' && 'xs:pb-5',
            bannerContent.padding === 'lg' && 'xs:pb-[30px]',
            contentRightPadding === 'md' && 'xs:pr-40',
            contentRightPadding === 'lg' && 'xs:pr-56',
            fullScreenImage.desktop ? 'xs:w-1/2' : 'xs:w-2/3 md:w-full',
          ]"
        >
          <wgt-bg-text
            :content="bannerContent.text"
            :text-color="contentColor"
            :background-color="contentBackgroundColor"
            :full-height-background="fullHeightContentBackground"
          />
        </div>
        <div>
          <base-action
            v-if="bannerButton.enabled"
            v-bind="bannerButton"
            class="inline-block"
            origin="banner"
            appearance="link"
          >
            <button
              type="button"
              class="button mt-[30px] flex h-12 items-center rounded px-5 font-bold"
              :style="buttonStyle"
            >
              <span>
                {{ bannerButton.label }}
              </span>
              <base-icon
                v-if="buttonIcon"
                class="ml-5"
                svg="plain/chevron-right"
                size="sm"
              />
            </button>
          </base-action>
        </div>
      </div>
      <div
        v-if="!ui.mobile && rightImage.desktop"
        class="w-1/2 rounded-r-lg"
        :style="rightImageStyle"
      />
    </div>
  </section>
</template>
