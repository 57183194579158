<script>
import emitter from '/~/core/emitter'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { useCms } from '/~/composables/cms'
import PointsPartnerItem from '../components/menu-points-partner-item.vue'

export default {
  name: 'profile-points-partners-added',
  components: {
    PointsPartnerItem,
    BaseAsidePage,
  },
  setup() {
    const { ewalletLabels } = useCms()

    return {
      ewalletLabels,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    addedPartnersList() {
      return [
        // {
        //   firstName: 'Emery',
        //   lastName: 'Schleifer',
        //   accountNumber: '2375 1256',
        //   type: 'velocity',
        //   id: '1',
        // },
        // {
        //   firstName: 'Ruben',
        //   lastName: 'Curtis',
        //   accountNumber: '2311 7576',
        //   type: 'velocity',
        //   id: '2',
        // },
      ]
    },
    empty() {
      return !this.addedPartnersList.length
    },
  },
  methods: {
    addPointsPartner() {
      this.$router.push({ hash: '#profile-points-partners-all' })
    },
    selectPartner(partner) {
      emitter.emit('menu:points-partners:select-partner', partner)
      this.$router.back()
    },
  },
}
</script>

<template>
  <base-aside-page
    :title="`Transfer my ${ewalletLabels.ewalletPoints}`"
    :action="addPointsPartner"
  >
    <div v-if="empty">No Partners found</div>
    <template v-else>
      <points-partner-item
        v-for="partner in addedPartnersList"
        :key="partner.id"
        :partner="partner"
        @click="selectPartner(partner)"
      />
    </template>
  </base-aside-page>
</template>
