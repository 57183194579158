<script>
import { PostsProcessor } from '/~rec/core/posts-processor'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import AnnouncementCard from './announcement-card.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'

export default {
  name: 'announcements-section',
  components: {
    BaseIcon,
    BaseCollapse,
    RecBaseState,
    AnnouncementCard,
  },
  data() {
    return {
      fetching: false,
      news: new PostsProcessor({
        entity: 'feed?announcement=true',
        perPage: 5,
      }),
      isExpanded: false,
    }
  },
  computed: {
    hits() {
      return this.news?.hits || []
    },
    hitsMinusFirst() {
      const hits = [...this.hits]
      const hitsMinusFirst = hits.slice(1)

      return hitsMinusFirst
    },
    chevronIcon() {
      return this.isExpanded ? 'plain/chevron-top' : 'plain/chevron-bottom'
    },
    firstPost() {
      return this.hits[0]
    },
    showIcon() {
      return this.hits.length > 1
    },
  },
  async mounted() {
    this.fetching = true
    await this.news.getData()
    this.fetching = false
  },
  methods: {
    onCollapseClick() {
      this.isExpanded = !this.isExpanded
    },
  },
}
</script>

<template>
  <div>
    <base-collapse :autoscroll="false" @change="onCollapseClick">
      <template #trigger>
        <div class="flex items-center justify-between text-eonx-neutral-800">
          <div class="flex items-center">
            <div class="flex items-center">
              <base-icon svg="rec/speakerphone-outlined" :size="20" />
            </div>
            <div class="ml-2.5 font-bold leading-6">
              <span>Announcements</span>
              <span v-if="!fetching" class="text-eonx-neutral-600">
                ({{ hits.length }})
              </span>
            </div>
          </div>
          <div
            v-if="showIcon"
            class="transform text-eonx-neutral-800 transition duration-200"
            :class="{
              'rotate-180': isExpanded,
            }"
          >
            <base-icon svg="plain/chevron-bottom" size="xs" />
          </div>
        </div>
      </template>

      <template #beforeCollapse>
        <div>
          <div v-if="fetching" class="mt-[15px] flex justify-center">
            <base-icon svg="spinner" size="2xl" />
          </div>

          <announcement-card
            v-else-if="firstPost"
            class="mt-2.5"
            :post="firstPost"
          />

          <rec-base-state
            v-else-if="!fetching && !hits.length"
            class="mt-6 mb-[30px]"
            image="recroom/state/directory-announcements-empty.svg"
            wide
            :bg="false"
          />
        </div>
      </template>

      <div v-if="hitsMinusFirst.length > 1" class="space-y-2.5 pt-2.5">
        <announcement-card
          v-for="(post, idx) in hitsMinusFirst"
          :key="idx"
          :post="post"
        />
      </div>
    </base-collapse>
  </div>
</template>
