export enum ActivityItemType {
  Membership = 'membership',
  PointsProgramOrder = 'pointsprogramorder',
  PointsTransaction = 'pointstransaction',
  UserCashbackOrder = 'usercashbackorder',
  CashbackReconciliationFeed = 'cashbackreconciliationfeed',
  PaymentOrder = 'paymentorder',
  BatchOrder = 'batchorder',
  PurchaseOrder = 'purchaseorder',
  Refund = 'refund',
  StatementOrder = 'statementorder',
  UserCommunications = 'usercommunications',
  Campaign = 'campaign',
  TopupOrder = 'topuporder',
  QrcScan = 'qrcscan',
  QrPayOrder = 'qrpayorder',
  TopUpTransaction = 'topuptransaction',
  CashTransaction = 'cashtransaction',
  MigratedHistory = 'migratedhistory',
  WithdrawOrder = 'withdraworder',
  PointsTransferRequest = 'pointstransferrequest',
}

export enum ActivityItemMetadataStatus {
  Completed = 'completed',
  Approved = 'approved',
  Funded = 'funded',
  Pending = 'pending',
  Processing = 'processing',
  RemittancePending = 'remittance_pending',
  AwaitingFundsPi = 'awaiting_funds_pi',
  Failed = 'failed',
  Declined = 'declined',
  Refunded = 'refunded',
  CompletedWithRemittanceFailures = 'completed_with_remittance_failures',
  DestinationFailed = 'destination_failed',
  Incomplete = 'incomplete',
  Cancelled = 'cancelled',
  Expired = 'expired',
}

export enum ActivityItemPaymentMethodBrand {
  BankAccount = 'Bank Account',
  PayID = 'PayID',
  Points = 'Points',
  Visa = 'Visa',
}
