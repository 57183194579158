<script setup lang="ts">
import { onMounted } from 'vue'
import ui from '/~/core/ui'
import './bootstrap'
import { useThirdPartyLogin } from '/~/extensions/third-party-login/composables/use-third-party-login'
import AppAlerts from '/~/components/app/app-alerts.vue'
import AppLoading from '/~/components/app/app-loading.vue'
import AppHeaderMobile from '/~/components/app/header/app-header-mobile.vue'
import AppHeader from '/~/components/app/header/app-header.vue'
import BottomSheetKeeper from '/~/components/bottom-sheet/bottom-sheet-keeper.vue'
import MdlKeeper from '/~/components/mdl/mdl-keeper.vue'
import { useAuth } from '/~/composables/auth'
import { useUI } from '/~/composables/ui'
import { useUser } from '/~/composables/user'
import LayoutMain from './layouts/main/layout-main'

const CartMenu = () => import('/~/components/cart-menu/cart-menu.vue')
const ProfileMenu = () => import('/~/components/profile-menu/profile-menu.vue')

const { user } = useUser()
const { uiReady } = useUI()
const { isLoggingOut } = useAuth()
const { isThirdPartyLoginRequired, initThirdPartyLogin } = useThirdPartyLogin()

onMounted(async () => {
  await initThirdPartyLogin()
  eonx.ready()
})
</script>

<template>
  <div id="app" class="flex h-full flex-1 flex-col print:block print:h-auto">
    <template v-if="user.authorized && !isThirdPartyLoginRequired">
      <app-header-mobile v-if="ui.mobile" />
      <app-header v-else />
      <layout-main>
        <router-view />
      </layout-main>
      <template v-if="uiReady">
        <profile-menu />
        <cart-menu />
      </template>
    </template>
    <app-loading v-else-if="isLoggingOut" />
    <router-view v-else />

    <div id="print-area">
      <portal-target name="print-destination" />
    </div>

    <mdl-keeper />
    <portal-target name="modals-portal" multiple />
    <bottom-sheet-keeper />
    <app-alerts />
  </div>
</template>
