<script setup lang="ts">
import dayjs from 'dayjs'
import { computed } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { useLocalization } from '/~/composables/localization'
import { type ThirdPartyUser } from '/~/composables/third-party-users/'

export type Action = 'edit' | 'invite' | 'reactivate' | 'deactivate' | 'delete'
type ActionListItem = { action: Action; label: string }

interface Props {
  user: ThirdPartyUser
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (event: 'action', payload: { action: Action; user: ThirdPartyUser }): void
}>()

const localization = useLocalization()

const format = localization.getDatePickerFormat('daymonthyearnumeric')

const actionsList: Array<ActionListItem> = [
  {
    action: 'edit',
    label: 'Edit details',
  },
  {
    action: 'invite',
    label: 'Resend invite',
  },
  {
    action: 'reactivate',
    label: 'Reactivate',
  },
  {
    action: 'deactivate',
    label: 'Deactivate',
  },
  {
    action: 'delete',
    label: 'Delete Authorised User',
  },
]

const isActive = computed(() => props.user.status === 'activated')
const isPending = computed(() => props.user.status === 'pending')
const status = computed(() => {
  if (isActive.value) return 'Active'
  if (isPending.value) return 'Pending'
  return 'Deactivated'
})
const statusColor = computed(() => {
  if (isActive.value) return 'text-success-700'
  if (isPending.value) return 'text-warning-700'
  return 'text-error-700'
})

const lastActive = computed(() =>
  props.user.lastLoginDate
    ? dayjs(props.user.lastLoginDate).format(format)
    : '-'
)
const actions = computed(() => {
  let list: Array<Action> = ['edit', 'reactivate', 'delete']

  if (isActive.value) list = ['edit', 'invite', 'deactivate', 'delete']
  if (isPending.value) list = ['edit', 'invite', 'delete']

  return list
    .map((action) => actionsList.find((item) => action === item.action))
    .filter(Boolean) as ActionListItem[]
})
</script>

<template>
  <div
    class="flex justify-between rounded border border-stone-400 bg-white px-2.5 py-2 text-sm leading-6"
  >
    <div class="text-eonx-neutral-600">
      <div>{{ user.firstName }} {{ user.lastName }}</div>
      <div>Nickname: {{ user.nickname ?? '-' }}</div>
      <div>Mobile: {{ user.mobile }}</div>
      <div>Email: {{ user.email }}</div>
      <div>Last active: {{ lastActive }}</div>
      <div class="font-semibold" :class="[statusColor]">{{ status }}</div>
    </div>

    <div class="flex items-center px-2">
      <base-menu position="right">
        <base-button
          slot="trigger"
          data-testid="user-action-button"
          icon="heroicons/outline/ellipsis-vertical"
          class="text-stone-400 hover:text-stone-600"
        />
        <ul class="w-40 divide-y">
          <li
            v-for="action in actions"
            :key="action.action"
            class="cursor-pointer px-3 py-[15px] text-base leading-none hover:bg-slate-100"
            :role="`action-menu-item-${action.action}`"
            @click="emit('action', { action: action.action, user })"
          >
            {{ action?.label }}
          </li>
        </ul>
      </base-menu>
    </div>
  </div>
</template>
