import { reactive } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { CelebrationsProcessor } from '/~rec/core/celebrations-processor'

const state = reactive({
  feed: new CelebrationsProcessor({ feedId: 'celebrations' }),
  selectedDate: formatDate('daymonthyearnumeric', Date.now()),
})

export const useCelebrations = () => ({
  celebrations: state,
})
