<script>
import ActivityListMobileLayout from '/~/components/activity/layouts/activity-list-mobile-layout.vue'
import { useEditablePage } from '/~/composables/cms'
import ActivityList from './components/activity-list.vue'

export default {
  name: 'activity-mobile',
  components: {
    ActivityList,
    ActivityListMobileLayout,
  },
  setup() {
    const { pageTitle } = useEditablePage()

    return {
      pageTitle,
    }
  },
}
</script>

<template>
  <activity-list-mobile-layout
    :title="pageTitle || 'Transaction history'"
    :back="{
      name: 'home',
    }"
  >
    <activity-list />
  </activity-list-mobile-layout>
</template>
