<script setup lang="ts">
import dayjs from 'dayjs/esm'
import { computed, ref } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import formatDate, { createDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import { useActivity } from '/~/composables/activity'

const emit = defineEmits<(event: 'hide') => void>()

const {
  categories,
  selectedCategory,
  selectedStartDate,
  selectedEndDate,
  datepickerFormatType,
  datepickerFormat,
  resetFilters,
  getCategories,
} = useActivity()

const startDate = ref(
  selectedStartDate.value
    ? formatDate(datepickerFormatType.value, selectedStartDate.value)
    : ''
)
const endDate = ref(
  selectedEndDate.value
    ? formatDate(datepickerFormatType.value, selectedEndDate.value)
    : ''
)

const filterFrom = computed(() => {
  const dayjsDate = createDate(startDate.value, datepickerFormat.value)

  return dayjsDate.isValid() ? dayjsDate : null
})

const filterTo = computed(() => {
  const dayjsDate = createDate(endDate.value, datepickerFormat.value)

  return dayjsDate.isValid() ? dayjsDate : createDate()
})

function hideModal() {
  emit('hide')
}

function reset() {
  resetFilters()
  emit('hide')
}

function showStartDatepicker() {
  bottomSheet.show('filter-datepicker', {
    props: {
      value: filterFrom.value,
      activePeriod: {
        from: dayjs().add(-10, 'year'),
        to: filterTo.value,
      },
    },
    on: {
      select: (value: dayjs.Dayjs) => {
        selectedStartDate.value = +value
        startDate.value = formatDate(datepickerFormatType.value, value)
      },
    },
  })
}

function showEndDatepicker() {
  bottomSheet.show('filter-datepicker', {
    props: {
      value: filterTo.value,
      activePeriod: {
        from: filterFrom.value || dayjs().add(-10, 'year'),
        to: dayjs(),
      },
    },
    on: {
      select: (value: dayjs.Dayjs) => {
        selectedEndDate.value = +value
        endDate.value = formatDate(datepickerFormatType.value, value)
      },
    },
  })
}

function clearSelectedStartDate() {
  selectedStartDate.value = null
}

function clearSelectedEndDate() {
  selectedEndDate.value = null
}

getCategories()
</script>

<template>
  <div>
    <div class="space-y-4 p-5 pt-20 pb-24 text-eonx-neutral-800">
      <div class="font-bold">Dates</div>
      <div>
        <base-input
          v-model="startDate"
          :placeholder="datepickerFormat.toLowerCase()"
          label-class="!px-0 font-bold"
          entry-class="!h-11"
          class="!mb-3"
          label="From"
          name="from"
          clearable
          readonly
          @focus="showStartDatepicker"
          @cleared="clearSelectedStartDate"
        />
        <base-input
          v-model="endDate"
          :placeholder="datepickerFormat.toLowerCase()"
          label-class="!px-0 font-bold"
          entry-class="!h-11"
          class="!mb-0"
          label="To"
          name="to"
          clearable
          readonly
          @focus="showEndDatepicker"
          @cleared="clearSelectedEndDate"
        />
      </div>
      <fieldset class="py-2.5">
        <legend class="mt-2 mb-2 font-bold">Categories</legend>
        <base-radio
          v-for="option in categories"
          :key="option.id"
          v-model="selectedCategory"
          class="ml-0 mb-3 h-11 border pl-3"
          name="filter"
          :value="option.id"
          :checked="selectedCategory === option.id"
          @click="selectedCategory = option.id"
        >
          {{ option.label }}
        </base-radio>
      </fieldset>
    </div>
    <div
      class="absolute top-0 w-full border-b bg-white px-5 py-[15px] text-center"
    >
      <h2 class="text-center text-xl text-eonx-neutral-800">Filter activity</h2>
      <span class="absolute top-0 right-[5px] p-[15px]">
        <base-button
          icon="heroicons/outline/x-mark"
          :size="32"
          @click="hideModal()"
        />
      </span>
    </div>
    <div class="absolute bottom-0 w-full bg-white shadow-md">
      <div class="flex space-x-2.5 p-2.5 xxs:p-5">
        <base-button class="flex-1" look="outlined-color" @click="reset">
          Reset
        </base-button>
        <base-button class="flex-1" @click="hideModal">Apply</base-button>
      </div>
    </div>
  </div>
</template>
