<script>
import { nextTick } from 'vue'
import { headroom } from 'vue-headroom'
import ui from '/~/core/ui'
import { useUI } from '/~/composables/ui'
import AppHeader from './app-header.vue'

export default {
  name: 'app-header-wrapper',
  components: {
    AppHeader,
    headroom,
  },
  props: {
    headroomDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isHeadroomActive, headerHeight } = useUI()

    return {
      isHeadroomActive,
      ui,
      headerHeight,
    }
  },
  data() {
    return {
      showShadow: false,
      headroomMainHeight: 0,
      headroomBottomHeight: 0,
      resizeObserver: null,
    }
  },
  computed: {
    isHeadroomEnabled() {
      return ui.mobile || ui.tablet
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.id === 'headroomMain') {
          this.headroomMainHeight = entry.target.offsetHeight
        }
        if (entry.target.id === 'headroomBottom') {
          this.headroomBottomHeight = entry.target.offsetHeight
        }
      }
      this.headerHeight = this.headroomMainHeight + this.headroomBottomHeight
    })

    nextTick(() => {
      const headroomMain = this.$refs.headroomMain
      const headroomBottom = this.$refs.headroomBottom

      if (headroomMain instanceof Element) {
        this.resizeObserver.observe(headroomMain)
      }

      if (headroomBottom instanceof Element) {
        this.resizeObserver.observe(headroomBottom)
      }
    })
  },
  beforeDestroy() {
    this.resizeObserver?.disconnect()
  },
  methods: {
    scroller() {
      if (ui.mobile) {
        return document.querySelector('main') ?? window
      } else {
        return window
      }
    },
    pin() {
      this.showShadow = true
    },
    unpin() {
      this.showShadow = false
    },
  },
}
</script>

<template>
  <headroom
    :offset="
      isHeadroomActive
        ? 130
        : Infinity /* disable headroom and reset its position */
    "
    :scroller="scroller"
    :disabled="headroomDisabled || !isHeadroomEnabled"
    :z-index="99"
    :up-tolerance="20"
    class="absolute z-50 w-full"
    @unpin="unpin"
    @pin="pin"
  >
    <div id="headroomMain" ref="headroomMain">
      <app-header :shadow="showShadow || !isHeadroomEnabled" />
    </div>
    <div
      id="headroomBottom"
      ref="headroomBottom"
      class="absolute w-full bg-default"
    >
      <portal-target name="headroom-bottom" />
    </div>
  </headroom>
</template>
