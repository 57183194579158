<script>
import { formatDollar } from '/~/utils/format/money'
import { pluralize } from '/~/utils/format/string'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useBatchOrder } from '/~/composables/batch-order'
import ConfirmationPayScheduled from './confirmation-pay-scheduled.vue'

export default {
  name: 'summary-pay-to-batch-file',
  components: {
    BaseIcon,
    ConfirmationPayScheduled,
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { batchOrder } = useBatchOrder()

    return {
      batchOrder,
      pluralize,
      formatDollar,
    }
  },
}
</script>

<template>
  <div v-if="batchOrder.number" class="flex shrink-0 flex-col">
    <div class="flex w-full gap-x-4">
      <div
        class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-eonx-neutral-600 text-white"
      >
        <base-icon :size="32" svg="v2/custom/bank" />
      </div>
      <div class="flex w-full flex-col truncate text-sm text-eonx-neutral-600">
        <div class="truncate text-base font-bold text-eonx-neutral-800">
          {{ batchOrder.fileName }}
        </div>
        <div class="flex justify-between">
          <div v-if="order.reference" class="mt-[5px]">
            Description: {{ order.reference }}
          </div>
          <div class="ml-auto self-end text-sm">
            {{ formatDollar(order.subtotal) }}
          </div>
        </div>
        <confirmation-pay-scheduled :order="order" />
      </div>
    </div>
  </div>
</template>
