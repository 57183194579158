<script>
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseHero from '/~/components/base/hero/base-hero.vue'
import WgtText from '../wgt/wgt-text.vue'

export default {
  name: 'widget-hero-banner',
  components: {
    WgtText,
    BaseHero,
    BaseAction,
  },
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    image: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <base-hero class="overflow-hidden rounded-lg shadow" :image="image">
    <wgt-text
      v-if="content"
      class="widget-hero-banner__text"
      :content="content"
    >
      <base-action
        v-if="link && link.enabled"
        v-bind="link"
        :class="{
          'mt-[30px] md:mt-10': content.contentAlignmentY === 'bottom',
        }"
        origin="banner"
      />
    </wgt-text>
  </base-hero>
</template>
