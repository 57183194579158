<script>
import bottomSheet from '/~/core/bottom-sheet'
import BaseState from '/~/components/base/state/base-state.vue'
import EpurchaseTile from '/~/components/drawer/components/tiles/drawer-e-purchases-tile.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { usePurchases } from '/~/composables/purchases'

export default {
  name: 'drawer-e-purchases-current',
  components: {
    EpurchaseTile,
    EwCatalogProcessor,
    BaseState,
  },
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { purchases, fetchCurrentPurchases } = usePurchases()

    if (!purchases.active.processing) {
      fetchCurrentPurchases()
    }

    function showUnlockModal() {
      bottomSheet.show('epurchase-unlock-v2', {
        style: {
          centered: true,
        },
        to: props.to,
      })
    }

    return {
      purchases,
      showUnlockModal,
    }
  },
  data() {
    return {
      tab: 'current',
    }
  },
}
</script>

<template>
  <ew-catalog-processor
    :processor="purchases.active"
    :columns="{ base: 2 }"
    catalog-class="gap-5"
    class="px-5"
    no-padding
    tile-class="hover:-translate-y-[5px]"
  >
    <template #defaultItems>
      <epurchase-tile
        v-for="(tile, index) of purchases.pending.hits"
        :key="`current-${index}`"
        :card="{
          ...tile,
          pending: true,
        }"
        extended
        @showUnlockModal="showUnlockModal"
      />
    </template>
    <template #empty>
      <base-state
        title="No current ePurchases"
        class="min-h-full py-5"
        image="/empty/empty-epurchases.svg"
      >
        <p>
          This is where you can easily access and manage your purchased eGift
          Cards and eVouchers.
        </p>
      </base-state>
    </template>

    <template #tile="{ tile }">
      <epurchase-tile
        :key="tile.orderNumber"
        :card="tile"
        extended
        @showUnlockModal="showUnlockModal"
      />
    </template>
  </ew-catalog-processor>
</template>
