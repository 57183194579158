<script setup lang="ts">
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

withDefaults(
  defineProps<{
    icon?: string | null
    animated: boolean
    rounded: boolean
    action?: (() => void) | null
    title: string
    simpleMobileView: boolean
    colorClasses: string
  }>(),
  {
    icon: null,
    animated: false,
    rounded: false,
    action: null,
    title: '',
    simpleMobileView: false,
    colorClasses: '',
  }
)

function rand(max: number) {
  const minCeiled = Math.ceil(1)
  const maxFloored = Math.floor(max)

  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled)
}

function translatePercentage(a: number, b: number, c: number, k: number) {
  return `${rand(a) - (rand(b) * k - rand(c))}%`
}

function getCssTranslateVars() {
  return {
    '--translate-20-x': translatePercentage(300, 150, 190, 4),
    '--translate-20-y': translatePercentage(145, 70, 140, 4),
    '--translate-40-x': translatePercentage(300, 150, 190, 6),
    '--translate-40-y': translatePercentage(145, 70, 140, 6),
    '--translate-80-x': translatePercentage(300, 150, 190, 2),
    '--translate-80-y': translatePercentage(145, 70, 140, 2),
  }
}
</script>

<template>
  <div class="relative">
    <div
      :class="['flex flex-col overflow-hidden', { [$style.rounded]: rounded }]"
    >
      <div
        class="bg-primary"
        :class="[$style.box, simpleMobileView && $style.boxSimple]"
      >
        <div
          class="absolute bottom-0 -translate-x-1/2 translate-y-1/2 transform"
          :class="[$style.iconWrapper, simpleMobileView && $style.mobileHide]"
        >
          <slot name="icon">
            <base-icon
              v-if="icon"
              :class="[animated && $style.animatedIcon, colorClasses]"
              :svg="icon"
              size="4xl"
            />
          </slot>
        </div>
        <div class="relative" :class="simpleMobileView && $style.mobileHide">
          <div
            v-for="idx in 14"
            :key="idx"
            :style="getCssTranslateVars()"
            :class="[$style.dot, animated && $style.animatedDot]"
          />
        </div>
        <div
          v-if="simpleMobileView"
          class="pt-6 text-white"
          :class="[$style.title, simpleMobileView && $style.mobileShow]"
        >
          <slot v-if="$slots.title" name="title" />
          <template v-else>
            {{ title }}
          </template>
        </div>
      </div>

      <base-button
        v-if="action"
        :class="$style.action"
        icon="close"
        @click.native="action"
      />
      <div
        class="bg-white pt-[72px]"
        :class="[$style.title, simpleMobileView && $style.mobileHide]"
      >
        <slot v-if="$slots.title" name="title" />
        <template v-else>
          {{ title }}
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.box {
  @apply relative flex min-h-36 items-center justify-center;
}

.boxSimple {
  @include mq($until: mobileLandscape, $and: '(orientation: landscape)') {
    @apply min-h-0;
  }
}

.iconWrapper {
  @apply left-1/2 z-1 flex h-[120px] w-[120px] items-center justify-center rounded-full bg-light;

  :global {
    .base-icon {
      @apply text-7xl;
    }
  }

  &:before,
  &:after {
    @apply absolute block rounded-full border-solid;

    content: '';
    border-color: rgba(#fff, 0.3);
    border-bottom: none;
  }

  &:not(.animatedIcon) {
    &:before,
    &:after {
      @apply -translate-x-1/2 -translate-y-1/2 transform;

      top: 50%;
      left: 50%;
    }

    &:before {
      @apply h-48 w-48 border-2;
    }
    &:after {
      @apply h-[156px] w-[156px] border-4;
    }
  }
}

.rounded {
  @apply rounded-t-3xl;
}

button.action {
  position: absolute;
  top: 24px;
  right: 18px;

  @apply text-white;
}

.title {
  @apply w-full px-5 pb-6 text-center text-2xl font-bold leading-7;
}

.animatedIcon {
  @keyframes circle-animation {
    0% {
      @apply scale-100;
    }
    75% {
      @apply opacity-100;

      transform: scale(1.45);
    }
    100% {
      @apply opacity-0;

      transform: scale(1.6);
    }
  }

  &:before,
  &:after {
    @apply inset-0 border;

    border-width: 1.5px;
  }

  &:before {
    animation: circle-animation 1.6s linear infinite;
  }
  &:after {
    animation: circle-animation 1.6s linear 0.8s infinite;
  }
}

@mixin dot($top, $left, $size) {
  top: #{$top}px;
  left: #{$left}px;
  width: #{$size}px;
  height: #{$size}px;
}

.dot {
  @apply absolute rounded-full;

  background: rgba(#fff, 0.5);

  &:nth-child(1) {
    @include dot(27, -121, 8);
  }

  &:nth-child(2) {
    @include dot(-45, 2, 8);
  }

  &:nth-child(3) {
    @include dot(-25, -135, 8);
  }

  &:nth-child(4) {
    @include dot(23, 109, 8);
  }

  &:nth-child(5) {
    @include dot(-15, -97, 6);
  }

  &:nth-child(6) {
    @include dot(-17, 71, 6);
  }

  &:nth-child(7) {
    @include dot(-4, 113, 6);
  }

  &:nth-child(8) {
    @include dot(-43, -57, 6);
  }

  &:nth-child(9) {
    @include dot(-63, 10, 4);
  }

  &:nth-child(10) {
    @include dot(5, -150, 4);
  }

  &:nth-child(11) {
    @include dot(-35, 111, 4);
  }

  &:nth-child(12) {
    @include dot(-51, -107, 4);
  }

  &:nth-child(13) {
    @include dot(13, 141, 4);
  }

  &:nth-child(14) {
    @include dot(-51, 50, 4);
  }
}

@keyframes dots-animation {
  20% {
    transform: translate3d(var(--translate-20-x), var(--translate-20-y), 0);
  }
  40% {
    transform: translate3d(var(--translate-40-x), var(--translate-40-y), 0);
  }
  80% {
    transform: translate3d(var(--translate-80-x), var(--translate-80-y), 0);
  }
}

@keyframes firework {
  0% {
    @apply top-[30px] left-[30px];
  }
  40% {
    transform: translateY(-60px);
  }
  100% {
    transform: translateY(0);
  }
}

@for $i from 1 through 14 {
  $dots-animation-delay: null;

  @if $i % 2 > 0 {
    $dots-animation-delay: $i % 10 * 0.3 + 1.3;
  } @else {
    $dots-animation-delay: $i % 10 * 0.1 + 1.3;
  }

  .animatedDot:nth-child(#{$i}) {
    transform: translate3d(0, 0, 0);
    animation: firework 1.3s ease-in-out,
      dots-animation 20s #{$dots-animation-delay}s infinite;
  }
}

.mobileHide {
  @include mq($until: mobileLandscape, $and: '(orientation: landscape)') {
    @apply hidden;
  }
}

.mobileShow {
  @apply hidden;

  @include mq($until: mobileLandscape, $and: '(orientation: landscape)') {
    @apply block;
  }
}
</style>
