<script>
import ui from '/~/core/ui'
import { pluralize } from '/~/utils/format/string'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PointsPanelV1 from '/~/components/points/points-panel.vue'

export default {
  name: 'points-panel',
  components: {
    BaseLoader,
  },
  extends: PointsPanelV1,
  setup() {
    return {
      ...PointsPanelV1.setup?.(...arguments),
      pluralize,
      ui,
    }
  },
}
</script>

<template>
  <div
    v-if="isShow"
    class="flex flex-1 items-center justify-center gap-x-2.5 bg-primary-lightest bg-cover bg-no-repeat p-1.5 sm:p-6"
  >
    <div class="flex items-center justify-end">
      <base-icon
        class="h-7 w-7 text-primary sm:h-8 sm:w-8"
        svg="points/token"
      />
    </div>
    <div class="font-bold sm:text-lg">
      {{ completed ? 'You earned' : 'You will earn' }}
      <span class="mx-[5px]">
        <base-loader v-if="loading" size="xs" />
        <span v-else class="text-2xl sm:text-3xl">
          {{ formatPoints(totalNumber) }}
        </span>
      </span>
      {{ pluralize(totalNumber, 'point') }}
    </div>
  </div>
</template>
