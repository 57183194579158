<script>
import RenderAvatars from '/~rec/components/render/render-avatars.vue'
import RenderMessage from '/~rec/components/render/render-message.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'rec-chat-rooms-box',
  components: {
    RenderAvatars,
    RenderMessage,
    BaseIcon,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { allMembers } = useGroups()

    return {
      allMembers,
    }
  },
  computed: {
    messageText() {
      const message = this.room.lastMessage

      if (message) {
        if (message.attachments.hasAttachments) {
          return 'Attachment sent'
        } else if (message.isDeleted) {
          return 'Message deleted'
        }

        return message.body
      }

      return '-'
    },

    mentions() {
      if (this.messageText !== '-') {
        const allMentions = this.messageText.match(
          /@\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/g
        )

        return allMentions
          ? this.allMembers
              .reduce((acc, member) => {
                allMentions.forEach((item) => {
                  if (item.slice(1) === member.id) {
                    acc.push(member)
                  }
                })
                return acc
              }, [])
              .map((item) => ({
                ...item.raw,
              }))
          : []
      }
      return []
    },

    members() {
      if (this.room.activeMembersWithoutMe.length === 0) {
        return this.room.membersWithoutMe
      }

      return this.room.activeMembersWithoutMe
    },
  },
}
</script>

<template>
  <div class="flex">
    <render-avatars
      class="hover:border-dark mr-5 border-white"
      look="col"
      :users="members"
    />
    <div class="grow truncate">
      <b class="truncate">
        {{ room.title }}
      </b>

      <render-message
        class="pointer-events-none truncate px-5 text-sm leading-none"
        :content="messageText.replace(/<\/?[^>]+(>|$)/g, '')"
        styleless
        :mentions="mentions"
      />
    </div>
    <div v-if="room.hasUnreadMessages" class="ml-5 self-center text-error-700">
      <base-icon class="block" svg="dot" size="xs" />
    </div>
  </div>
</template>
