<script>
import DefaultEditProfile from '/~/components/ewallet-menu/views/profile-settings/profile-edit-profile-view.vue'
import BaseAsidePage from '/~/templates/australiapost/components/base/aside-page/base-aside-page.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    BaseAsidePage,
  },
  extends: DefaultEditProfile,
})
</script>
