<script>
import { usePaymentMethods } from '/~/composables/payment-methods'
import PaymentsPbaItem from '/~/components/payments/pba/payments-pba-item.vue'

export default {
  name: 'payment-checkout-points',
  components: {
    PaymentsPbaItem,
  },
  setup() {
    const { isPbaEnabled } = usePaymentMethods()

    return {
      isAvailable: isPbaEnabled,
    }
  },
}
</script>

<template>
  <div v-if="isAvailable">
    <payments-pba-item />
  </div>
</template>
