<script>
import DefaultRetailerProduct from '/~/extensions/giftcards/components/product/retailer-product.vue'
import ProductTotal from './product-total.vue'
import ProductHeader from './product-header.vue'

export default {
  components: {
    ProductTotal,
    ProductHeader,
  },
  extends: DefaultRetailerProduct,
}
</script>
