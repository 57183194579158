<script>
import DefaultEstoreCatalog from '/~/extensions/estore/templates/default/views/estore-catalog.vue'
import EstoreTile from '/~/extensions/estore/templates/australiapost/components/estore-tile.vue'

export default {
  components: {
    EstoreTile,
  },
  extends: DefaultEstoreCatalog,
}
</script>
