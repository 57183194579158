<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import EventCard from './event-card.vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'
import { useDirectory } from '/~rec/composables/directory'

export default {
  name: 'events-section',
  components: {
    BaseIcon,
    EventCard,
    RecBaseState,
    BaseCollapse,
  },
  setup() {
    const { todayEvents, getTodayEvents, fetchingEvents } = useDirectory()

    return {
      todayEvents,
      getTodayEvents,
      fetching: fetchingEvents,
    }
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    showIcon() {
      return this.todayEvents.length > 3
    },
  },
  mounted() {
    this.getTodayEvents()
  },
  methods: {
    onCollapseClick() {
      this.isExpanded = !this.isExpanded
    },
  },
}
</script>

<template>
  <base-collapse :autoscroll="false" @change="onCollapseClick">
    <template #trigger>
      <div class="flex items-center justify-between text-eonx-neutral-800">
        <div class="flex">
          <div class="flex items-center">
            <base-icon svg="rec/event-outlined" size="md" />
          </div>
          <div class="ml-2.5 font-bold leading-6">
            <span>Events</span>
            <span v-if="!fetching" class="text-eonx-neutral-600">
              ({{ todayEvents.length }})
            </span>
          </div>
        </div>
        <div
          v-if="showIcon"
          class="transform text-eonx-neutral-800 transition duration-200"
          :class="{
            'rotate-180': isExpanded,
          }"
        >
          <base-icon svg="plain/chevron-bottom" size="xs" />
        </div>
      </div>
    </template>

    <template #beforeCollapse>
      <div>
        <div v-if="fetching" class="mt-[15px] flex justify-center">
          <base-icon svg="spinner" size="2xl" />
        </div>

        <div v-if="todayEvents.length" class="space-y-2.5 pt-2.5">
          <event-card
            v-for="event in todayEvents.slice(0, 3)"
            :key="event.id"
            :event="event"
          />
        </div>

        <rec-base-state
          v-if="!fetching && !todayEvents.length"
          class="mt-6"
          image="recroom/state/directory-events-empty.svg"
          wide
          :bg="false"
        />
      </div>
    </template>

    <div v-if="todayEvents.length > 3" class="space-y-2.5 pt-2.5">
      <event-card
        v-for="(event, idx) in todayEvents.slice(3)"
        :key="idx"
        :event="event"
      />
    </div>
  </base-collapse>
</template>
