<script>
import { nextTick } from 'vue'
import ui from '/~/core/ui'
import FeedPosts from '/~rec/components/feed/feed-posts.vue'
import NewsEmpty from './news-empty.vue'
import { useFeeds } from '/~rec/composables/feeds'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-news-view',
  components: {
    NewsEmpty,
    FeedPosts,
  },
  props: {
    feedId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { feeds, selectedFeed, isNewPostProcessing } = useFeeds()
    const { getNotifications, isMeSuperAdmin, isMeAdmin } = useRecProfile()

    return {
      feeds,
      selectedFeed,
      getNotifications,
      isMeSuperAdmin,
      isMeAdmin,
      isNewPostProcessing,
      ui,
    }
  },
  computed: {
    useFlashPosts() {
      return this.feedId === 'feed' || this.feedId === 'announcements'
    },
  },
  watch: {
    feedId(feedId) {
      if (feedId !== this.feeds.selectedFeedId) {
        this.useFeed()
        this.selectedFeed.hits = []
      }
    },
    '$route.query.postId'() {
      this.scrollToPost()
    },
  },
  async created() {
    await this.useFeed()
  },
  methods: {
    async useFeed() {
      this.isNewPostProcessing = false
      this.feeds.selectedFeedId = this.feedId || 'feed'

      this.selectedFeed?.getData().then(() => {
        this.scrollToPost()
      })

      this.getNotifications()
    },
    async scrollToPost() {
      await nextTick()
      const post = document.querySelector(
        `[data-id='${this.$route.query.postId}']`
      )

      post?.scrollIntoView({
        block: ui.desktop ? 'start' : 'center',
        behavior: 'smooth',
      })
    },
    onNewPostsLoaded() {
      const feedElem = document.getElementById(
        `${ui.desktop ? 'rec-feed-desktop' : 'rec-feed-mobile'}`
      )

      feedElem?.scrollIntoView({
        block: 'start',
      })
    },
  },
}
</script>

<template>
  <div
    id="news-wrapper"
    class="w-full bg-zinc-50 p-5 sm:py-0 md:px-2.5"
    :class="{
      'h-full': ui.desktop,
      'overflow-y-auto': selectedFeed.total,
      'overflow-y-hidden': !selectedFeed.total,
    }"
  >
    <div class="mx-auto h-full max-w-2xl">
      <feed-posts
        v-if="selectedFeed"
        :feed="selectedFeed"
        :feed-type="feedId"
        :flash-posts="useFlashPosts"
        @posts-loaded="onNewPostsLoaded"
      >
        <template #empty>
          <news-empty />
        </template>
      </feed-posts>
    </div>
  </div>
</template>
