import {
  configure,
  extend,
  validate,
  localize,
  setInteractionMode,
} from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import {
  alpha_num as alphaNum,
  email,
  length,
  min,
  max,
  numeric,
  regex,
} from 'vee-validate/dist/rules'
import { computed } from 'vue'
import { ApiResponseData } from '/~/types/api'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { useLocalization } from '/~/composables/localization'
import { BusinessNumber } from '/~/composables/payees'
import { useProvider } from '/~/composables/provider'
import { badWords } from './bad-words'

const { locale } = useLocalization()
const { translate } = useLocalization()

// Install English locale
localize({
  en,
})

setInteractionMode('eager')

extend('alpha_num', {
  ...alphaNum,
  message: (fieldName) => {
    return `The ${formatFieldName(
      fieldName
    )} field may only contain alpha-numeric characters`
  },
})

extend('email', {
  ...email,
  message: (fieldName) => {
    return `The ${formatFieldName(fieldName)} field must be a valid email`
  },
})

extend('length', {
  ...length,
  message: (fieldName, values) => {
    return `The ${formatFieldName(fieldName)} field must be ${
      values.length
    } long`
  },
})

extend('min', {
  ...min,
  message: (fieldName, values) => {
    return `The ${formatFieldName(fieldName)} field must be at least ${
      values.length
    } characters`
  },
})

extend('max', {
  ...max,
  message: (fieldName, values) => {
    return `The ${formatFieldName(fieldName)} field may not be greater than ${
      values.length
    } characters`
  },
})

extend('numeric', {
  ...numeric,
  message: (fieldName) => {
    return `The ${formatFieldName(
      fieldName
    )} field may only contain numeric characters`
  },
})

extend('regex', {
  ...regex,
  message: (fieldName) => {
    return `The ${formatFieldName(fieldName)} field format is invalid`
  },
})

extend('required', {
  validate(value) {
    if (typeof value === 'string' || value instanceof String) {
      value = value.trim()
    }
    return {
      required: true,
      valid: ['', null, undefined, [], false].indexOf(value) === -1,
    }
  },
  computesRequired: true,
  message: (fieldName) => {
    return `The ${formatFieldName(fieldName)} field is required`
  },
})

const { isUsProvider, isGbProvider, isCaProvider, providerName } = useProvider()

const postcodeRegex = computed(() => {
  if (isUsProvider.value) {
    return /^\d{5}(-\d{4})?$/
  } else if (isCaProvider.value) {
    return /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/
  } else if (isGbProvider.value) {
    return /^[a-zA-Z\d]{7}$/
  }

  return /^\d{4}$/
})

extend('postcode', {
  validate: (value) => {
    return postcodeRegex.value.test(value.replace(/\s/g, ''))
  },
})

const phoneRegex = computed(() => {
  if (isUsProvider.value || isCaProvider.value) {
    return /^(\+1\d{10}|\d{10})$/
  } else if (isGbProvider.value) {
    return /^(0\d{10}|\+44\d{10})$/
  }

  return /^(0|\+61)4\d{8}$/
})

extend('mobile', {
  validate: (value) => {
    const testedValue = String(value).replace(/[\s-)(]/g, '')

    return phoneRegex.value.test(testedValue)
  },
  message: (fieldName) => {
    return `The ${formatFieldName(fieldName)} field must be a valid number`
  },
})

extend('offensive', {
  validate: (value) => {
    const valid = !badWords.some((w) =>
      value.match(new RegExp('\\b' + w + '\\b', 'i'))
    )

    return valid ? true : 'Avoid offensive words...'
  },
})

window.BUSINESS_NUMBERS_DICT = {}

async function validateAbn(value) {
  return new Promise((resolve) => {
    let result = {
      valid: false,
      data: {
        failedEntityName: '',
        message: 'The ABN field is invalid or not found',
      },
    }

    if (value === null || value.length !== 11) {
      resolve(result)
      return
    }

    const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
    let sum = 0
    let weight
    let digit

    for (let index = 0; index <= weights.length - 1; index++) {
      weight = weights[index]
      digit = value[index] - (index ? 0 : 1)
      sum += weight * digit
    }
    if (sum % 89 !== 0) {
      resolve(result)
      return
    }

    if (
      Object.prototype.hasOwnProperty.call(window.BUSINESS_NUMBERS_DICT, value)
    ) {
      resolve(window.BUSINESS_NUMBERS_DICT[value])
      return
    }

    return api
      .get<ApiResponseData<BusinessNumber>>(`/v3/business-number/${value}`, {
        requireAuth: false,
        notify: false,
        headers: {
          'x-provider': providerName.value,
        },
      })
      .then(({ data }) => {
        const { abnStatus = '' } = data ?? {}
        const valid = abnStatus.toLowerCase() === 'active'

        result = {
          valid,
          data: {
            message: `ABN is ${abnStatus.toLowerCase()}`,
            response: data,
          },
        }
      })
      .catch((error) => {
        result = {
          valid: false,
          data: {
            failedEntityName: error.data?.context?.businessName,
            message:
              error.data?.message ||
              error.data?.title ||
              'Something went wrong, please try again later',
          },
        }
      })
      .finally(() => {
        window.BUSINESS_NUMBERS_DICT[value] = result
        resolve(result)
      })
  }).then((result) => {
    return result?.valid
      ? {
          valid: true,
          data: result?.data?.response,
        }
      : result?.data?.message
  })
}

async function validateCbn(value) {
  return new Promise((resolve) => {
    let result = {
      valid: false,
      data: {
        message: 'CBN invalid',
      },
    }

    if (value === null || value.length !== 15) {
      resolve(result)
      return
    }

    return api
      .get<ApiResponseData<BusinessNumber>>(`/v3/business-number/${value}`, {
        requireAuth: false,
        notify: false,
        headers: {
          'x-provider': providerName.value,
        },
      })
      .then(({ data }) => {
        const { abnStatus = '' } = data ?? {}
        const valid = abnStatus.toLowerCase() === 'active'

        result = {
          valid,
          data: {
            message: `CBN is ${abnStatus.toLowerCase()}`,
            response: data,
          },
        }
      })
      .catch((error) => {
        result = {
          valid: false,
          data: {
            message:
              error.data?.message ||
              error.data?.title ||
              'Something went wrong, please try again later',
          },
        }
      })
      .finally(() => {
        window.BUSINESS_NUMBERS_DICT[value] = result
        resolve(result)
      })
  }).then((result) => {
    return result?.valid
      ? {
          valid: true,
          data: result?.data?.response,
        }
      : result?.data?.message
  })
}

extend('businessNumber', {
  validate: (value) => {
    if (locale.value === 'en-AU') {
      return validateAbn(value)
    }
    if (locale.value === 'en-CA') {
      return validateCbn(value)
    }
  },
})

window.BSB_DICT = {}

function bsbValidation(value, length) {
  if (window.BSB_DICT[value]?.request instanceof Promise) {
    return window.BSB_DICT[value].request
  }

  if (!window.BSB_DICT[value]) {
    window.BSB_DICT[value] = {
      request: null,
      result: null,
    }
  }

  window.BSB_DICT[value].request = new Promise((resolve) => {
    let result = {
      valid: false,
      data: {},
    }

    if (value.length !== length) {
      resolve(result)
      return
    }

    if (window.BSB_DICT[value]?.result) {
      resolve(window.BSB_DICT[value].result)
      return
    }

    api
      .post(
        '/v3/validation/bsb',
        { bsb: value },
        {
          notify: false,
        }
      )
      .then(({ data }) => {
        result = {
          valid: true,
          data: {
            message: 'BSB is valid',
            response: data,
          },
        }
      })
      .catch((error) => {
        result = {
          valid: false,
          data: {
            message:
              error.data?.message ||
              error.data?.title ||
              'Something went wrong, please try again later',
          },
        }
      })
      .finally(() => {
        window.BSB_DICT[value].result = result
        resolve(result)
      })
  }).then((result) => {
    emitter.emit('validation:bsb', result)
    return result.valid ? true : result?.data?.message
  })

  return window.BSB_DICT[value].request
}

extend('canadian_bsb', {
  validate: function (value, { crossFieldValue, crossFieldKey }) {
    const bsb =
      crossFieldKey === 'institutionNumber'
        ? crossFieldValue + value
        : value + crossFieldValue

    return bsbValidation(bsb, 8)
  },
  params: ['crossFieldValue', 'crossFieldKey'],
})

extend('bsb', {
  validate: (value) => {
    return bsbValidation(value, 6)
  },
})

extend('alpha_dash_spaces', {
  validate: (value) => {
    return /^[a-zA-Z0-9\s\-_]+$/.test(value)
      ? true
      : 'May contain alphabetic characters, numbers, dashes, underscores or spaces.'
  },
})

const validatorMessages = {
  en: {
    fields: {
      verifySum: {
        required: 'Pre-Auth Amount can not be empty',
        min_value: 'Pre-Auth Amount must be $0.01 or more',
        max_value: 'Pre-Auth Amount must be $2.99 or less',
      },
      selectedValue: {
        decimal:
          'The amount value must be numeric and may contain two decimal points',
      },
    },
    attributes: {
      start_timezone: 'timezone',
      location: 'location',
      description: 'description',
      startDate: 'start date',
      endDate: 'end date',
      startTime: 'start time',
      endTime: 'end time',
      name: 'name',
      purpose: 'purpose',
      title: 'title',
    },
    messages: {
      endTimeAfterStartTime: () => 'The end time must be after start time.',
      endDateAfterEqualStartDate: () =>
        'The end date must be after or equal to start date.',
      startTimeAfterCurrentTime: () =>
        'The start time must be after your current time.',
    },
  },
}

extend('max_value', {
  validate: (value, { max }) => {
    const maxValue = parseFloat(max)
    const parsedValue = parseFloat(String(value).replace(' ', ''))

    return parsedValue <= maxValue
      ? true
      : `The {_field_} field cannot be more than $${max}`
  },
  params: ['max'],
})

extend('withdrawal_balance', {
  message: () => {
    return 'The withdrawal amount exceeds your current available balance'
  },
  validate: (value, { max }) => {
    return validate(value, `max_value:${max}`)
  },
  params: ['max'],
})

extend('min_value', {
  validate: (value, { min }) => {
    const minValue = parseFloat(min)
    const parsedValue = parseFloat(String(value).replace(' ', ''))

    return parsedValue >= minValue
  },
  params: ['min'],
  message: (fieldName, values) => {
    return `The ${formatFieldName(fieldName)} field must be $${
      values.min
    } or more`
  },
  message: (_, values) => {
    const field = values._field_.toLowerCase()

    return `The ${field} field must be $${values.min} or more`
  },
})

extend('confirmed', {
  validate(value, { otherValue, otherLabel }) {
    return value === otherValue
      ? true
      : `{_field_} and ${otherLabel} must match`
  },
  params: ['otherValue', 'otherLabel'],
})

extend('googleaddress', {
  validate(value, { address = {} } = {}) {
    for (const key of ['address', 'suburb', 'postcode', 'state']) {
      if (!address[key]) {
        return `The {_field_} field is missing ${translate(`address.${key}`)}`
      }
    }

    return true
  },
  params: ['address'],
})

extend('password', {
  validate: (value) => {
    return new Promise((resolve) => {
      const result = {
        valid: true,
        data: {
          message: '',
        },
      }

      if (value.length < 10) {
        result.data.message += 'Must be at least 10 characters in length<br/>'
      }
      if (!value.match(/[a-z]/)) {
        result.data.message += 'Must contain lowercase characters (a-z)<br/>'
      }
      if (!value.match(/[A-Z]/)) {
        result.data.message += 'Must contain uppercase characters (A-Z)<br/>'
      }
      if (!value.match(/[0-9]/)) {
        result.data.message += 'Must contain digits (0-9)<br/>'
      }
      if (!value.match(/[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]/)) {
        result.data.message +=
          'Must contain non-alphanumeric characters (!#$%)<br/>'
      }

      if (result.data.message) {
        result.valid = false
      }

      resolve(result.valid ? true : result.data.message)
    })
  },
})

extend('unique', {
  validate(value, { list, key } = {}) {
    return (list ?? []).filter((item) => item[key] === value)?.length <= 1
  },
  params: ['key', 'list'],
  message: (fieldName) => {
    return `The ${formatFieldName(fieldName)} field is duplicated`
  },
})

extend('account_number', {
  validate: (value) => {
    const testedValue = String(value).trim()
    const valid = testedValue.length > 9 ? !testedValue.startsWith('0') : true

    return valid
  },
  message: (fieldName) => {
    return `The ${formatFieldName(
      fieldName
    )} field cannot exceed 9 digits. Please remove any leading 0's.`
  },
})

extend('points_max_value', {
  validate: (value, params) => {
    const { max } = params as { max: string }
    const DEFAULT_MESSAGE = `Amount of points cannot be more than ${max} PTS`
    const maxValue = parseFloat(max)

    if (maxValue === 0) return DEFAULT_MESSAGE

    const parsedValue = parseFloat(String(value).replace(' ', ''))

    if (isNaN(parsedValue)) return 'Enter a valid amount of points'

    return parsedValue <= maxValue ? true : DEFAULT_MESSAGE
  },
  params: ['max'],
})

extend('points_balance', {
  validate: (value, params) => {
    const DEFAULT_MESSAGE = "You don't have enough points"
    const { balance } = params as { balance: string }
    const parsedBalance = parseFloat(balance)

    if (parsedBalance === 0) return DEFAULT_MESSAGE

    const parsedValue = parseFloat(String(value).replace(' ', ''))

    if (isNaN(parsedValue)) return 'Enter a valid amount of points'

    return parsedValue <= parsedBalance ? true : DEFAULT_MESSAGE
  },
  params: ['balance'],
})

configure({
  messages: validatorMessages,
})

function formatFieldName(fieldName: string) {
  return fieldName.toLowerCase()
}
