<script>
import DrawerPhotoIdsAdd from '/~/drawer/photo-ids/photo-ids-add.vue'

export default {
  name: 'profile-photo-ids-add',
  components: {
    DrawerPhotoIdsAdd,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <drawer-photo-ids-add :id="id" />
</template>
