<script>
import DrawerPaymentMethods from '/~/templates/bill-payments/components/drawer/views/payment-methods/drawer-payment-methods.vue'

export default {
  name: 'profile-payment-methods',
  components: {
    DrawerPaymentMethods,
  },
  methods: {
    onAdd() {
      this.$router.push({ hash: '#profile-payment-methods-add' })
    },
  },
}
</script>

<template>
  <drawer-payment-methods
    :back="{ hash: '#profile-home' }"
    to="menu-modal"
    @add="onAdd"
  />
</template>
