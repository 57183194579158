<script>
import RecognitionTileBox from '/~rec/components/recognition/recognition-tile-box.vue'

export default {
  name: 'rec-perk-tile',
  components: {
    RecognitionTileBox,
  },
  props: {
    perk: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <recognition-tile-box
    :nominal="perk.cost"
    title="Review this Perk"
    color="gold"
    :image="perk.logo"
    alt-text="perk"
    v-on="$listeners"
  >
    <div
      class="grid h-full grid-cols-1 break-words bg-white p-[15px] pt-2.5 leading-4"
    >
      <div class="text-base font-bold text-eonx-neutral-800 line-clamp-2">
        {{ perk.name }}
      </div>
      <div class="mt-[5px] font-semibold text-eonx-neutral-600 line-clamp-3">
        {{ perk.description }}
      </div>
    </div>
  </recognition-tile-box>
</template>
