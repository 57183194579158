import type { RouteConfig } from 'vue-router'
import type { CMSPageId } from '/~/types/cms'
import { RewardsExtension } from '/~/core/extension'
import { useEstore } from '/~/extensions/estore/composables'
import { transformQueriesBack, defaultEstoreSettings } from './helpers'

const { initEstore, setCatalog, getCatalog, ready, isEnabled } = useEstore()

class EstoreExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  async setState() {
    return initEstore()
  }

  setRoutes() {
    if (!isEnabled.value) {
      return []
    }

    return [
      {
        path: 'estore',
        component: () => import(`./templates/${this.templateName}/estore.vue`),
        children: [
          {
            path: 'product/:id',
            name: 'estore-product',
            props: true,
            meta: {
              title: 'eStore Product',
              theme: 'white',
              hideMenu: true,
              ewDetailsV2: true,
            },
            component: () =>
              import(`./templates/${this.templateName}/views/product.vue`),
          },
          {
            path: ':category?',
            name: 'estore',
            meta: {
              title: 'eStore',
              page: 'estore' as CMSPageId,
            },
            component: () =>
              import(`./templates/${this.templateName}/views/estore.vue`),
            beforeEnter(to, from, next) {
              if (from.name !== 'estore-product' || !ready.value) {
                const { minRange, maxRange } = defaultEstoreSettings

                // reset previous state of catalog
                setCatalog({ data: [], page: 0, total: 0, loading: false })
                // get filters from query/params
                const selectedCategory = to.params.category
                const selectedItems = transformQueriesBack(to, 'selected-items')
                const selectedBrands = transformQueriesBack(
                  to,
                  'selected-brands'
                )
                const searchString = to.query.search || ''
                const priceRange = {
                  min: to.query.min || minRange,
                  max: to.query.max || maxRange,
                }

                // get data by filters
                getCatalog({
                  selectedCategory,
                  selectedItems,
                  selectedBrands,
                  searchString,
                  priceRange,
                  provider: eonx.provider || {},
                })
              }

              next()
            },
          },
        ] as RouteConfig[],
      },
    ]
  }
}

export default EstoreExtension
