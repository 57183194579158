<script>
import BaseButton from '/~/components/base/button/base-button'
import CompetitionTerms from './competition-terms.vue'
import { useUserEvents } from '/~/composables/user'

export default {
  name: 'competition-terms-bottom-sheet',
  components: {
    BaseButton,
    CompetitionTerms,
  },
  props: {
    competition: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const { termsAndConditionsVisited } = useUserEvents()

    termsAndConditionsVisited({ type: 'modal', name: 'Competition terms' })

    function close() {
      emit('hide')
    }

    function print() {
      window.print()
    }

    return {
      close,
      print,
    }
  },
}
</script>

<template>
  <div class="relative flex h-full flex-col overflow-hidden">
    <div class="flex items-center justify-between p-5">
      <h2 class="text-xl sm:text-2xl">Terms & Conditions</h2>

      <div class="-mr-[5px] flex items-center space-x-5">
        <base-button icon="plain/printer" @click="print" />
        <base-button icon="plain/close" @click="close" />
      </div>
    </div>

    <div class="overflow-y-auto p-5">
      <competition-terms :competition="competition" />
    </div>

    <portal to="print-destination">
      <competition-terms :competition="competition" />
    </portal>
  </div>
</template>
