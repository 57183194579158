<script>
import { ref, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import { useVerifications } from '/~/extensions/otp/composables'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLogger } from '/~/composables/logger'
import {
  useStatementAutoPayments,
  useStatementAccounts,
  useStatementModals,
} from '/~/composables/statements'
import { useUser } from '/~/composables/user'
import StatementsListItemDesk from './statements-list-item.desk.vue'

const logger = useLogger('payment-statements-list-item-mobile')

export default {
  name: 'payment-statements-list-item-mobile',
  extends: StatementsListItemDesk,
  setup(props) {
    const router = useRouter()
    const { payment, currentFlowType } = useCheckoutReactive()
    const { enabledAccountIdForReminderModal, checkAutoPayRemindMeAgain } =
      useUser()
    const { statementAccounts, fetchStatementAccounts } = useStatementAccounts()

    const { selectedStatementAutoPayment, hasDirectDebitOrScheduled } =
      useStatementAutoPayments()
    const { showExistingScheduledPaymentModal } = useStatementModals()
    const { withVerificationsCheck } = useVerifications()

    const statementAccount = ref(null)
    const loading = ref(false)
    const clientId = computed(() => props.item?.client?.id)

    async function enableDirectDebit() {
      bottomSheet.show('enable-direct-debit', {
        props: {
          onConfirm: async () => {
            checkAutoPayRemindMeAgain(clientId.value)
            await fetchStatementAccounts({
              includeAutoPayments: true,
              includeNextDueStatementOrders: true,
            })
            statementAccount.value = statementAccounts.value.find(
              (item) => item.id === clientId.value
            )
            logger.debug('set selectedStatementAutoPayment', {
              statementAccount: statementAccount.value,
              statementAccountAutoPayment: statementAccount.value.autoPayment,
            })
            selectedStatementAutoPayment.value =
              statementAccount.value.autoPayment
            selectedStatementAutoPayment.value.statement = props.item

            router.push({
              name: 'statement-direct-debit',
              params: {
                id: props.item.id,
              },
            })
          },
          onCancel: async () => {
            checkAutoPayRemindMeAgain(clientId.value)
            if (props.item.isScheduled) {
              nextTick(() => {
                showExistingScheduledPaymentModal(props.item)
              })
            } else {
              await onPayment()
            }
          },
        },
        on: {
          hide: () => {
            bottomSheet.hide()
          },
        },
      })
    }

    async function onPayment() {
      currentFlowType.value = FlowType.statement
      await payment.value.initPayment({
        statement: props.item,
      })
      router.push({
        name: 'statement-checkout',
        params: {
          id: props.item.id,
        },
      })
    }

    async function onClick() {
      if (
        loading.value ||
        !props.item.active ||
        props.item.isBlocked ||
        props.item.subtotal === 0
      ) {
        return
      }
      loading.value = true

      const accountId = props.item?.statementAccount?.id
      const isDirectDebit = await hasDirectDebitOrScheduled(
        accountId,
        undefined
      )

      if (!isDirectDebit && enabledAccountIdForReminderModal(accountId)) {
        enableDirectDebit()
      } else {
        if (props.item.isScheduled) {
          showExistingScheduledPaymentModal(props.item)
        } else {
          onPayment()
        }
      }

      loading.value = false
    }

    return {
      ...StatementsListItemDesk.setup?.(...arguments),
      onClick,
      withVerificationsCheck,
    }
  },
}
</script>
<template>
  <div
    class="mb-5 flex flex-col gap-y-2.5 rounded-xl bg-white p-[15px] text-eonx-neutral-400"
  >
    <div
      class="flex cursor-pointer items-center justify-start gap-x-2.5"
      :disabled="item.isBlocked"
      @click="withVerificationsCheck(onClick)"
    >
      <payee-icon class="shrink-0" :payee="item.payee" />
      <div class="w-56">
        <h3
          class="truncate text-base font-bold text-eonx-neutral-800"
          :title="clientName"
        >
          {{ clientName }}
        </h3>
        <div class="flex flex-wrap items-center text-sm text-eonx-neutral-600">
          {{ subtitle }}
        </div>
      </div>
      <div class="ml-auto">
        <base-icon
          v-if="item.canSeeDetails"
          :size="14"
          svg="v2/custom/chevron-right-outline"
          class="m-auto justify-self-center text-sm"
          :class="{
            'text-eonx-neutral-800': !item.isBlocked,
            'text-eonx-neutral-200': item.isBlocked,
          }"
        />
      </div>
    </div>
    <div class="flex items-center justify-between">
      <p class="text-sm leading-none text-eonx-neutral-600">Account No.</p>
      <p class="w-28 truncate text-right text-eonx-neutral-800">
        {{ item.clientNumber || '-' }}
      </p>
    </div>
    <item-date :statement="item" />
    <div class="flex items-center justify-between">
      <p class="text-sm leading-none text-eonx-neutral-600">Amount</p>
      <p class="text-eonx-neutral-800">
        {{ formatDollar(item.subtotal) }}
      </p>
    </div>
  </div>
</template>
