import { nanoid } from 'nanoid'

export default {
  label: 'Default',
  value: 'default',
  preset: {
    items: [
      {
        id: nanoid(),
        enabled: true,
        label: 'Privacy Policy',
        action: {
          type: 'action',
          value: 'show-privacy-modal',
        },
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Terms & Conditions',
        action: {
          type: 'action',
          value: 'show-terms-modal',
        },
      },
    ],
  },
}
