<script>
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { useCms } from '/~/composables/cms'
import LoyaltyBrandsV1 from '/~/drawer/loyalty-cards/loyalty-brands.vue'

export default {
  name: 'loyalty-memberships-brands',
  components: {
    BaseAsidePage,
  },
  extends: LoyaltyBrandsV1,
  setup() {
    const router = useRouter()
    const { isDarkThemeForEwallet } = useCms()

    function selectCatalogCard(card) {
      router.push({ hash: `#profile-loyalty-card-add/${card.slug}` })
    }

    function selectCustomCard() {
      router.push({ hash: '#profile-loyalty-card-add/other' })
    }

    return {
      ...LoyaltyBrandsV1.setup?.(...arguments),
      isDarkThemeForEwallet,
      selectCatalogCard,
      selectCustomCard,
    }
  },
}
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    title="Add Loyalty Card"
    class="flex flex-col"
    no-padding
  >
    <div v-if="isCatalogCardsLoading" class="space-y-[15px] p-5">
      <div class="h-5 animate-pulse rounded-xl bg-divider" />
      <div class="h-5 animate-pulse rounded-xl bg-divider" />
      <div class="h-5 animate-pulse rounded-xl bg-divider" />
    </div>
    <template v-else>
      <div slot="header" class="px-5 pb-5">
        <base-input
          :value="searchQuery"
          look="rounded"
          nolabel
          placeholder="Search Brand"
          entry-class="h-12"
          @input="setSearchQuery"
        >
          <template #icon>
            <base-icon
              svg="v2/custom/search"
              :size="24"
              class="ml-[15px] text-eonx-neutral-600"
            />
          </template>
        </base-input>
      </div>

      <div class="px-5">
        <div class="border-b py-2.5">
          <button
            class="w-full cursor-pointer rounded-lg py-2.5 text-left"
            :class="{
              'hover:bg-light': !isDarkThemeForEwallet,
              'hover:bg-gray-800': isDarkThemeForEwallet,
            }"
            @click="selectCustomCard()"
          >
            <span class="flex items-center justify-between">
              <span
                class="mr-5 flex h-12 w-12 flex-shrink-0 items-center justify-center overflow-hidden rounded-full bg-primary text-white"
              >
                <base-icon svg="v2/heroic/plus" :size="32" />
              </span>
              <span class="min-w-0 flex-grow truncate font-bold leading-7">
                Add a custom Loyalty Card
              </span>
            </span>
          </button>
        </div>
      </div>

      <div
        v-if="filteredCardsByLetters.length === 0"
        class="py-5 text-center font-bold"
      >
        No Brands
      </div>

      <div class="overflow-y-auto">
        <div
          v-for="group in filteredCardsByLetters"
          :id="`sel-letter-${group.letter}`"
          :key="group.letter"
          class="pl-2.5 pr-[45px]"
        >
          <div
            class="mt-5 px-2.5 pb-2.5 font-bold"
            :class="{
              'text-eonx-neutral-600': !isDarkThemeForEwallet,
              'text-white': isDarkThemeForEwallet,
            }"
          >
            {{ group.letter }}
          </div>

          <div
            v-for="card in group.cards"
            :key="card.id"
            class="flex w-full cursor-pointer items-center justify-between rounded-lg p-2.5 text-left"
            :class="{
              'hover:bg-eonx-neutral-50': !isDarkThemeForEwallet,
              'hover:bg-gray-800': isDarkThemeForEwallet,
            }"
            @click="selectCatalogCard(card)"
          >
            <button
              class="mr-5 flex h-12 w-12 flex-shrink-0 items-center justify-center overflow-hidden rounded-full"
              :aria-label="card.name"
            >
              <base-image v-bind="card.image" :ratio="1" :rounded="false" />
            </button>
            <h3 class="min-w-0 flex-grow truncate font-bold leading-7">
              {{ card.name }}
            </h3>
          </div>
        </div>
      </div>
    </template>
  </base-aside-page>
</template>
