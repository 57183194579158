<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import MenuPayeeAddAccount from '/~/components/ewallet-menu/components/to/menu-payee-add-account.vue'
import MenuPayeeAddBpay from '/~/components/ewallet-menu/components/to/menu-payee-add-bpay.vue'
import MenuPayeeAddEwallet from '/~/components/ewallet-menu/components/to/menu-payee-add-ewallet.vue'
import { useCms } from '/~/composables/cms'

export default {
  name: 'profile-payee-add',
  components: {
    BaseAsidePage,
    BaseTabs,
    BaseTab,
    MenuPayeeAddAccount,
    MenuPayeeAddBpay,
    MenuPayeeAddEwallet,
  },
  setup() {
    const router = useRouter()
    const { isDarkThemeForEwallet, ewalletLabels } = useCms()

    const tab = ref('card')

    function onPayeeAdded() {
      router.back()
    }

    return {
      isDarkThemeForEwallet,
      ewalletLabels,
      tab,
      onPayeeAdded,
    }
  },
}
</script>

<template>
  <base-aside-page
    title="Add a Payee"
    no-padding
    :style="{
      '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
    }"
  >
    <base-tabs v-model="tab" class="h-full px-5" look="v2">
      <base-tab name="Bank Account" value="bankAccount">
        <menu-payee-add-account class="pt-5" @submit="onPayeeAdded" />
      </base-tab>
      <base-tab name="BPAY" value="bpay">
        <menu-payee-add-bpay class="pt-5" @submit="onPayeeAdded" />
      </base-tab>
      <base-tab :name="`${ewalletLabels.ewallet} User`" value="ewalletUser">
        <menu-payee-add-ewallet class="pt-5" @submit="onPayeeAdded" />
      </base-tab>
    </base-tabs>
  </base-aside-page>
</template>
