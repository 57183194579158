import type { RouteConfig } from 'vue-router'
import type { CMSPageId } from '/~/types/cms'
import { RewardsExtension } from '/~/core/extension'
import mdl from '/~/core/mdl'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import modals from './modals'

mdl.register(modals)

const { syncState, initOnlineOffers } = useOnlineOffers()

class OnlineOffersExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  async setState() {
    return initOnlineOffers()
  }

  setRoutes() {
    return [
      {
        path: '/online-offers',
        meta: {
          title: 'Online Offers',
        },
        component: () => import('./online-offers.vue'),
        beforeEnter(to, from, next) {
          syncState({ to })
          next()
        },
        children: [
          {
            path: 'retailer/:slug',
            name: 'online-offers-retailer',
            props: true,
            meta: {
              title: 'Online Offer',
              theme: 'white',
              hideMenu: true,
            },
            component: () =>
              import(
                `./templates/${this.templateName}/views/retailer/oo-retailer.vue`
              ),
          },
          {
            path: 'retailer/:slug/redirect',
            name: 'online-offers-redirect',
            props: true,
            meta: {
              title: 'Online Offer Redirect',
              hideMenu: true,
            },

            component: () =>
              import('./templates/default/views/oo-redirect.vue'),
          },
          {
            path: ':category?',
            name: 'online-offers',
            meta: {
              title: 'Online Offers',
              page: 'online-offers' as CMSPageId,
            },
            component: () => import('./templates/default/views/oo-catalog.vue'),
          },
        ],
      },
    ] as RouteConfig[]
  }
}

export default OnlineOffersExtension
