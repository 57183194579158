import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import { usePayees } from '/~/composables/payees'
import { useScheduledPayments } from '/~/composables/scheduled-payments'
import { useStatementAccounts } from '/~/composables/statements'

type ScheduledTypeProps = {
  type: 'statements' | 'payments'
}

export function useScheduledPaymentsList(props: ScheduledTypeProps) {
  const router = useRouter()
  const { payees, fetchPayees } = usePayees()
  const {
    scheduledPayments,
    isScheduledPaymentsLoading,
    fetchScheduledPayments,
    resetScheduledPayments,
  } = useScheduledPayments()
  const { fetchStatementAccounts, statementAccounts } = useStatementAccounts()

  const loading = ref(false)
  const selectedTabKey = ref(props.type)

  const isStatementsTabActive = computed(() => {
    return selectedTabKey.value === 'statements'
  })
  const isPaymentsTabActive = computed(() => {
    return selectedTabKey.value === 'payments'
  })
  const scheduledPaymentOrdersByDate = computed<any>(() => {
    return groupScheduledPaymentsByDate(scheduledPayments.value)
  })

  const scheduledStatementAccounts = computed<any>(() =>
    statementAccounts.value.filter((account) => account.autoPayment?.isEnabled)
  )

  function groupScheduledPaymentsByDate(scheduledPayments: any) {
    const scheduledPaymentsByDate: any = {}

    scheduledPayments.forEach((scheduledPayment: any) => {
      // convert to YYYY/MM/DD format
      const date = new Date(scheduledPayment.scheduledAt)
        .toISOString()
        .slice(0, 10)

      if (!scheduledPaymentsByDate[date]) {
        scheduledPaymentsByDate[date] = []
      }

      scheduledPaymentsByDate[date].push(scheduledPayment)
    })

    return Object.fromEntries(
      Object.entries(scheduledPaymentsByDate).sort().reverse()
    )
  }

  async function initStatements() {
    loading.value = true

    await fetchStatementAccounts({
      includeAutoPayments: true,
      includeNextDueStatementOrders: false,
    })

    loading.value = false
  }

  async function initScheduledPayments() {
    resetScheduledPayments()

    await fetchScheduledPayments({
      useScheduledPaymentsForStatements: true,
      setTotalItems: true,
      queryParams: {
        page: 1,
        perPage: 9999,
        sortBy: 'scheduled_at',
        orderBy: 'desc',
      },
    })

    if (payees.items.length === 0) {
      fetchPayees()
    }
  }

  function onMakePaymentClicked() {
    router.push({ name: 'payments' })
  }

  function onEnableDirectDebitClicked() {
    router.push({ name: 'statements' })
  }

  watch(
    selectedTabKey,
    (value) => {
      router.replace({
        params: {
          type: selectedTabKey.value,
        },
      })
      if (value === 'payments') {
        initScheduledPayments()
      } else if (value === 'statements') {
        initStatements()
      }
    },
    {
      immediate: true,
    }
  )

  return {
    loading,
    selectedTabKey,
    isScheduledPaymentsLoading,
    isStatementsTabActive,
    isPaymentsTabActive,
    scheduledStatementAccounts,
    scheduledPayments,
    scheduledPaymentOrdersByDate,
    onMakePaymentClicked,
    onEnableDirectDebitClicked,
  }
}
