<script>
import get from 'lodash/get'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useChat } from '/~rec/composables/chat'

export default {
  name: 'app-notification-item',
  components: {
    BaseDate,
    BaseAvatar,
    BaseIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { chat, openRoom, createRoom } = useChat()

    return {
      chat,
      openRoom,
      createRoom,
    }
  },
  computed: {
    isChat() {
      return /chat/.test(this.item.event)
    },
    isAwardAsAdmin() {
      return /award/.test(this.item.event) && this.item.type === 'administrator'
    },
    openComments() {
      return /comment|mention/.test(this.item.event)
    },
    initials() {
      const name = this.item?.body?.metadata?.notifier_name

      return (
        name &&
        name
          .split(' ')
          .map((v) => v.slice(0, 1))
          .join('')
      )
    },
    imageUrl() {
      return this.cdn(this.item.image).url()
    },
  },
  methods: {
    onNotificationClick(notification) {
      notification.markAsRead()
      this.$emit('close')

      if (notification.isStatementNotification) {
        this.onStatementNotificationClick(notification)
      } else if (notification.isOrderItemDispatchedNotification) {
        this.onOrderItemDispatchedNotificationClick()
      } else {
        this.onRecroomNotificationClick(notification)
      }
    },
    onStatementNotificationClick() {
      this.$router.push({ name: 'statements' })
    },
    onOrderItemDispatchedNotificationClick() {
      this.$router.push({ hash: '#profile-e-purchases' })
    },
    onRecroomNotificationClick(notification) {
      const url = get(notification, 'body.actions.0.link')
      const isManage = /rec-room.+manage/.test(url)

      if (isManage) {
        window.open(url, { target: '_blank' })
      } else if (url) {
        const route = {
          path: url.replace(/^http.+\.au/, ''),
        }

        if (this.openComments) {
          route.query = { comments: true }
        }

        this.$router.push(route)

        if (this.isChat) {
          this.openRoom(this.item?.body?.metadata?.room_id)
        }

        if (this.isAwardAsAdmin) {
          this.onUserWonAward()
        }
      }
    },
    async onUserWonAward() {
      const winnerId = this.item?.body?.metadata?.user_id
      const isAwardPublic = /(\/posts\/)/.test(
        get(this.item, 'body.actions.0.link')
      )

      if (!isAwardPublic) {
        const chatId = this.chat?.joinedRooms?.hits?.find((room) => {
          return (
            room.membersWithoutMe.length === 1 &&
            room.membersWithoutMe.some((p) => p.id === winnerId)
          )
        })?.id

        if (chatId) {
          this.openRoom(chatId)
        } else {
          const room = await this.createRoom({ userIds: [winnerId] })

          this.openRoom(room.id)
        }
      }
    },
  },
}
</script>

<template>
  <button
    :key="item.id"
    data-test="notifications-item"
    :class="[
      'relative flex min-h-12 w-full cursor-pointer border-b bg-white px-5 py-2.5 text-left',
      !item.isRead && 'bg-sky-50',
    ]"
    @click="onNotificationClick(item)"
  >
    <div class="relative mr-[30px] h-16 shrink-0">
      <base-avatar
        v-if="item.image || initials"
        :abbr="initials"
        :src="imageUrl"
        size="2xl"
        :background="false"
        class="rounded-full bg-eonx-neutral-50 p-[5px]"
      />
      <div
        v-else
        class="h-16 w-16 rounded-full bg-eonx-neutral-200 p-[15px] text-eonx-neutral-800"
      >
        <base-icon
          class="[&>svg]:stroke-[1]"
          size="auto"
          svg="heroicons/outline/user"
        />
      </div>
      <div class="absolute bottom-0 right-0 -mr-5 -mb-0.5 h-[35px] w-[35px]">
        <base-icon size="auto" :svg="item.icon" />
      </div>
    </div>
    <div class="grow">
      <span class="grid grid-cols-1 break-words leading-none">
        {{ item.title }}
      </span>
      <div class="mt-[5px] flex items-center text-sm text-eonx-neutral-600">
        <base-icon svg="heroicons/mini/clock" size="sm" class="mr-1.5" />
        <base-date type="from-now" :date="item.createdAt" />
      </div>
    </div>
    <div
      v-if="!item.isRead"
      class="my-auto ml-[15px] h-2.5 w-2.5 shrink-0 rounded-full bg-primary-darken"
    />
  </button>
</template>
