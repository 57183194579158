<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import modal from '/~/core/mdl'
import { useHi5Component } from '/~/extensions/rec-room/composables/hi5-component'
import { formatNumber } from '/~/utils/format/numeric'
import { pluralize } from '/~/utils/format/string'
import { useRecProfile } from '/~rec/composables/profile'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import DetailsMobile from '/~rec/components/recognition/recognition-details-box-mobile.vue'

const props = defineProps<{
  hi5Id: string
}>()

const { init, isSender, userDetails, hi5, loading } = useHi5Component(props)
const { currency } = useRecProfile()
const router = useRouter()

function close() {
  modal.hide()
  router.replace({
    name: 'rec-hi5',
    query: { type: hi5.value?.raw.status },
  })
}

init()
</script>

<template>
  <base-mdl fullscreen width="screen" transition="slide-right" :layout="false">
    <details-mobile
      v-if="hi5"
      :loading="loading"
      :award="hi5 || {}"
      entity-name="rec-hi"
      :alt-text="'Hi5'"
      @close="close"
    >
      <template #badge>
        <div>
          <span
            class="mb-5 inline-block rounded-3xl px-5 py-[5px] text-sm font-semibold capitalize"
            :class="{
              'bg-orange-100 text-yellow-900': isSender,
              'bg-violet-100 text-violet-800': !isSender,
            }"
          >
            {{ isSender ? 'Sent' : 'Received' }}
          </span>
        </div>
      </template>
      <template #nominalText>
        <span class="text-sm font-semibold leading-none">
          {{ formatNumber(hi5.nominal || 0) }}
          <span class="capitalize">
            {{ pluralize(hi5.nominal || 0, currency.slice(0, -1)) }}
          </span>
          {{ `${isSender ? 'sent' : 'received'} with this Hi-5 ` }}
        </span>
      </template>
      <template #avatar>
        <div class="my-5 flex items-center text-center">
          <base-avatar
            class="mr-[15px]"
            :src="userDetails.avatarUrl"
            :abbr="userDetails.initials"
            size="lg"
          />
          <div class="truncate">
            <div class="truncate text-left text-base font-bold leading-tight">
              {{ userDetails.fullName }}
            </div>
            <div class="leading-right flex flex-wrap font-bold">
              <base-date
                :date="hi5 ? hi5.createdAt : ''"
                class="text-base text-eonx-neutral-600"
                type="day-time-spr"
              />
            </div>
          </div>
        </div>
      </template>
    </details-mobile>
  </base-mdl>
</template>
