<script>
import ui from '/~/core/ui'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'

export default {
  name: 'rec-placeholder-chat',
  components: {
    BasePlaceholder,
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <div
    class="rounded-md bg-white py-2.5"
    :class="{
      'bg-white': ui.desktop,
      'bg-zinc-50': !ui.desktop,
    }"
  >
    <div class="flex w-full">
      <div class="mr-2.5">
        <base-placeholder :width="40" :height="40" rounded-full theme="dark" />
      </div>
      <div class="flex max-w-24 grow flex-col">
        <div class="mb-[5px]">
          <base-placeholder :height="14" theme="dark" />
        </div>
        <div>
          <base-placeholder :height="14" theme="dark" />
        </div>
      </div>
    </div>
  </div>
</template>
