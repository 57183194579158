export default {
  /*
   *
   */
  maxMarkersOnMap: 100,
  /*
   * Melbourne VIC, Australia
   */
  mapDefaultPlaceId: 'ChIJ90260rVG1moRkM2MIXVWBAQ',
  mapMarkerIcons: {
    default: 'https://i.ewalletcdn.com/3ea1cb6e-276a-4ad4-a9aa-e26ba0b6bd06/',
    red: 'https://i.ewalletcdn.com/df9d795b-e36f-4480-9874-86822fc834a7/',
    orange: 'https://i.ewalletcdn.com/37eaf94f-f954-4fdc-adb6-3e84d0685fe0/',
  },
}
