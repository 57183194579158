import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomChatMessage } from '/~rec/core/chat-message'
import { formatDate } from '/~/utils/format/date'

export class ChatMessagesProcessor extends EntityProcessor {
  constructor(params = {}) {
    super({
      mapping: (record) => new RecroomChatMessage(record),
      entity: `chat/rooms/${params.id}/messages`,
    })

    this.lastMessage = null
    this.hitsByDate = {}
  }

  get isEmpty() {
    return this.length === 0
  }

  get allRecordsLoaded() {
    return this.length > 0 && this.total <= this.length
  }

  stackMessages() {
    const days = {}

    this.hits.forEach((message) => {
      const date = formatDate('daymonthyearnumeric', message.createdAt)

      days[date] = [...(days[date] || []), message]
    })

    const daysArr = Object.keys(days).sort((a, b) => a.localeCompare(b))

    this.hitsByDate = daysArr.reduce((arr, day) => {
      arr.push({
        day,
        messages: days[day].sort((a, b) =>
          a.createdAt.localeCompare(b.createdAt)
        ),
      })
      return arr
    }, [])
  }

  async getData(params) {
    this.hitsByDate = {}
    await super.getData(params)
    this.stackMessages()
  }

  async next() {
    await super.next()
    this.stackMessages()
  }

  pushMessage(raw) {
    const msgIndex = this.hits.findIndex((item) => {
      return item.raw.request_id === raw.request_id
    })
    const message = new RecroomChatMessage(raw)

    if (msgIndex >= 0) {
      this.hits[msgIndex] = message
    } else {
      this.hits.push(message)

      if (this.pagination) {
        this.pagination.total_items += 1
      }

      if (this.perPage < this.length) {
        this.hits.shift()
      }
    }

    if (msgIndex < 0 || msgIndex >= this.total - 1) {
      this.lastMessage = message
    }

    this.stackMessages()

    return message
  }
}
