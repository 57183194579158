<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'rec-quiz-leave-modal',
  components: {
    BaseButton,
    BaseIcon,
    BaseMdl,
  },
  methods: {
    onLeave() {
      this.$emit('left')
      this.onClose()
    },
    onClose() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" width="xs">
    <div class="relative p-5 sm:p-[30px]">
      <div class="absolute top-0 right-0 m-5 sm:m-[30px]">
        <base-button icon="plain/close" @click="onClose" />
      </div>

      <div class="mt-[5px] mb-6 text-center">
        <base-icon :size="64" class="mx-auto text-error-700" svg="rec/leave" />
      </div>
      <div class="text-center">
        <h2 class="leading-tight">Are you sure you wish to leave this Quiz?</h2>
        <p class="mt-[30px]">
          Your progress will be saved for when you wish to continue.
        </p>
        <base-button class="mt-10" full-width @click="onLeave">
          Leave
        </base-button>
      </div>
    </div>
  </base-mdl>
</template>
