<script>
import ui from '/~/core/ui'
import OnlineOffersFilterDesktop from './oo-filter.desktop.vue'
import OnlineOffersFilterMobile from './oo-filter.mobile.vue'

export default {
  name: 'online-offers-filter-v2',
  functional: true,
  render(h, ctx) {
    return h(
      ui.mobile ? OnlineOffersFilterMobile : OnlineOffersFilterDesktop,
      ctx.data,
      ctx.props
    )
  },
}
</script>
