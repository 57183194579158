<script setup lang="ts">
import { ref, computed } from 'vue'
import { pluralize } from '/~/utils/format/string'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'

const { providerClientName, generalPhone } = useProvider()
const { translate } = useLocalization()

const { getMenuItemByAttachedModule } = useCms()

defineProps<{
  onConfirm?: () => void
}>()

const checked = ref<boolean>(false)

const menuLabel = computed(() => {
  const label = getMenuItemByAttachedModule('left', 'statement-order')?.label

  return label ? `‘${label}’` : 'menu'
})

const directDebitsTitle = pluralize(2, translate('directDebit.text') as string)
</script>
<template>
  <base-mdl width="sme" :closable="false">
    <template #header>
      <div class="relative text-center">
        <span class="text-primary">
          <base-icon
            svg="heroicons/solid/information-circle"
            :size="64"
            class="mx-auto mt-6"
          />
        </span>
      </div>
    </template>

    <h2 class="mb-12 text-center text-eonx-neutral-700">
      Manage your existing {{ directDebitsTitle }}
    </h2>
    <div class="mb-16 text-left text-eonx-neutral-700">
      <p class="mb-5">
        You can enable {{ directDebitsTitle }} through this platform at any
        time, whether through {{ menuLabel }} or when making a payment in line.
      </p>
      <p class="mb-5 font-bold">
        As this platform runs independently from
        {{ providerClientName }} Accounts Receivable, please note any existing
        {{ directDebitsTitle.toLowerCase() }} with {{ providerClientName }} will
        be cancelled please contact your {{ providerClientName }} account
        manager or call {{ providerClientName }} on {{ generalPhone }} if you
        have any questions.
      </p>
    </div>
    <base-checkbox v-model="checked" look="v3" class="mb-3">
      <span class="font-normal text-eonx-neutral-600">
        I understand that my existing
        {{ directDebitsTitle }} with {{ providerClientName }} have been
        cancelled
      </span>
    </base-checkbox>
    <div class="text-center">
      <base-button full-width :disabled="!checked" @click="onConfirm">
        Confirm
      </base-button>
    </div>
  </base-mdl>
</template>
