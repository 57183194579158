<script>
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import { useCms } from '/~/composables/cms'

export default {
  name: 'auth-layout',
  components: {
    BaseAction,
  },
  props: {
    image: {
      type: String,
      default: 'https://i.ewalletcdn.com/354c3ca6-1f6f-44c7-9ce6-a851e2d2d34d/',
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { getMenuItems, cmsConfig } = useCms()

    return {
      getMenuItems,
      cmsConfig,
    }
  },
  computed: {
    footerMenu() {
      return this.getMenuItems('login-footer')
    },
    loginImage() {
      return this.cmsConfig['login-page-image']?.desktop || this.image
    },
    loginContent() {
      return this.cmsConfig['login-page-content']
    },
    hasOverlayContent() {
      return this.loginContent?.length > 0
    },
  },
}
</script>

<template>
  <div class="flex h-full flex-col overflow-y-auto bg-light md:min-h-screen">
    <div class="relative flex shrink-0 grow flex-col md:flex-row-reverse">
      <div class="flex grow flex-col" :class="centered && 'justify-center'">
        <slot />
        <div
          class="flex flex-col justify-center p-5 text-center"
          :class="!centered && 'mt-auto'"
        >
          <div
            class="flex flex-col flex-wrap items-center justify-around text-sm text-neutral-600"
          >
            <div class="h-5">
              <transition name="fade">
                <ul v-if="footerMenu.length" class="flex divide-x-2 font-bold">
                  <li v-for="(item, idx) in footerMenu" :key="item.id || idx">
                    <base-action
                      v-bind="item.action || {}"
                      class="px-2.5 text-inherit hover:text-primary"
                    >
                      {{ item.label }}
                    </base-action>
                  </li>
                </ul>
              </transition>
            </div>
            <div class="mt-[5px]">
              Powered by EONX SERVICES PTY LTD (ABN 39 615 958 873)
            </div>
          </div>
        </div>
      </div>
      <div class="w-full max-w-[576px]" />
      <div
        class="fixed left-0 hidden min-h-full w-[576px] flex-col items-center justify-center bg-cover bg-top bg-no-repeat text-center md:flex"
        :style="{
          backgroundImage: `url(${cdn(loginImage)
            .resize('576x')
            .format('auto')
            .quality('smart')
            .url()}`,
        }"
      >
        <div
          class="relative z-10 flex h-[800px] w-full flex-col items-center justify-center text-white"
        >
          <transition name="fade">
            <div
              v-if="loginContent"
              ref="imageEntry"
              class="w-xse p-[30px]"
              v-html="loginContent"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
