<script setup lang="ts">
import { formatDollar } from '/~/utils/format/money'
import { useCheckoutReactive } from '/~/composables/checkout'

const { payment } = useCheckoutReactive()
</script>

<template>
  <div class="text-eonx-neutral-600">
    {{ formatDollar(payment.subTotal) }}
  </div>
</template>
