<script>
import LoyaltyBrands from '/~/drawer/loyalty-cards/loyalty-brands.vue'

export default {
  name: 'profile-loyalty-brands',
  components: {
    LoyaltyBrands,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    onSelect(slug) {
      this.$router.push({
        hash: `#profile-loyalty-card-add/${slug}`,
      })
    },
  },
}
</script>

<template>
  <loyalty-brands :id="id" @select="onSelect" />
</template>
