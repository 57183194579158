<script>
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-post-header-detailed',
  components: {
    BaseImage,
    BaseAvatar,
    BaseDate,
  },
  inject: ['post'],
  setup() {
    const { features } = useRecProfile()

    return { features }
  },
  computed: {
    isEvent() {
      return /event/.test(this.post?.type)
    },
    isAward() {
      return /award/.test(this.post?.type)
    },
    imageBindings() {
      const image = this.isEvent
        ? this.post?.event?.image
        : this.post?.award?.image
      const driver = this.cdn(image).quality('best')
      const natural = driver.scaleCrop('88x88').url()
      const retina = driver.scaleCrop('176x176').url()

      return {
        src: natural,
        srcset: `${natural} 1x, ${retina} 2x`,
      }
    },
    title() {
      return this.isEvent ? this.post?.event?.name : 'Award Winner!'
    },
    eventSubtitle() {
      const isInvited = this.post.type === 'event_member'

      return isInvited
        ? 'You have been invited by'
        : 'Event has been created by'
    },
    subtitle() {
      return this.isEvent ? this.eventSubtitle : this.awardSubtitle
    },
    author() {
      return this.post?.event?.creator || this.post?.winner
    },
  },
  methods: {
    openProfile(id) {
      if (this.features.directory) {
        this.$router.replace({ query: { recProfileId: id } })
      }
    },
  },
}
</script>

<template>
  <div class="flex p-[15px] pb-[30px] text-eonx-neutral-800 md:p-6">
    <div class="relative h-20 w-20 shrink-0">
      <div class="h-full w-full overflow-hidden rounded-xl">
        <base-image v-bind="imageBindings" :ratio="false" />
      </div>
      <div
        class="absolute bottom-0 right-0 -mb-[5px] -mr-[15px] rounded-full border-4 border-white bg-white"
        style="border-width: 5px"
        @click="openProfile(author.id)"
      >
        <base-avatar
          :class="{
            'cursor-pointer': features.directory,
          }"
          :src="author.avatarUrl"
          :abbr="author.initials"
          size="xse"
        />
      </div>
    </div>
    <div class="flex flex-col overflow-hidden pl-[30px]">
      <div class="truncate text-xl font-semibold">
        {{ title }}
      </div>

      <div class="mt-[5px] font-semibold text-eonx-neutral-600">
        <span v-if="isEvent">
          <span v-html="eventSubtitle" />
          <span
            class="font-semibold text-eonx-neutral-800"
            :class="{
              'cursor-pointer': features.directory,
            }"
            @click="openProfile(author.id)"
          >
            {{ author.fullName }}
          </span>
        </span>
        <span v-else class="break-words">
          <span
            class="font-semibold text-eonx-neutral-800"
            :class="{
              'cursor-pointer': features.directory,
            }"
            @click="openProfile(author.id)"
          >
            {{ author.fullName }}
          </span>
          <span>
            won
            {{ post.award.title }}
          </span>
        </span>
        ,
        <base-date :date="post.createdAt" type="day-time-spr" />
      </div>
    </div>
  </div>
</template>
