<script>
import { computed } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'

export default {
  name: 'profile-payment-to',
  components: {
    BaseIcon,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props) {
    const { isDarkThemeForEwallet } = useCms()
    const { translate } = useLocalization()

    const type = computed(() => props.to.type)
    const firstRowText = computed(() => props.to.name)

    const secondRowText = computed(() => {
      const { email, billerCode, bsb = '', accountNumber = '', id } = props.to

      switch (type.value) {
        case 'ewallet':
          return email
        case 'bpay':
          return billerCode
        case 'bankAccount':
          return translate('bankAccount.details', {
            acc: `*** ${accountNumber.slice(-3)}`,
            bsb: bsb ? `*** ${bsb.slice(-3)}` : undefined,
          })
        default:
          return id
      }
    })

    const icon = computed(() => {
      if (props.isSelected) {
        return 'v2/custom/check-circle'
      }

      switch (type.value) {
        case 'ewallet':
          return 'v2/custom/user-circle'
        case 'bpay':
          return 'v2/custom/bpay-circle'
        case 'bankAccount':
          return 'v2/custom/bank-circle'
        default:
          return 'menu/label'
      }
    })

    const isTypeBankAccount = computed(() => {
      return type.value === 'bankAccount'
    })

    return {
      isDarkThemeForEwallet,
      firstRowText,
      secondRowText,
      icon,
      isTypeBankAccount,
    }
  },
}
</script>

<template>
  <div
    class="flex w-full items-center justify-between rounded-xl"
    :style="{
      'background-color': isSelected ? '#f1fbf5' : null,
    }"
    @click="$emit('click')"
  >
    <div
      class="flex h-12 w-12 items-center justify-center rounded-full text-white"
    >
      <base-icon
        :svg="icon"
        :size="48"
        :class="{
          'text-primary': !isSelected,
        }"
        :style="{
          color: isSelected ? '#6FCF97' : null,
        }"
      />
    </div>
    <div class="flex-1 overflow-hidden pl-[15px]" @click="$emit('click')">
      <div
        class="truncate font-bold"
        :class="{
          'text-eonx-neutral-800': isSelected || !isDarkThemeForEwallet,
        }"
      >
        {{ firstRowText }}
      </div>
      <div
        class="truncate text-eonx-neutral-600"
        :class="{
          'text-sm': isTypeBankAccount,
        }"
      >
        {{ secondRowText }}
      </div>
    </div>

    <div v-if="$slots.action" class="ml-5 shrink-0">
      <slot name="action" />
    </div>
  </div>
</template>
