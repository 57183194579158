<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import RenderBanner from '/~rec/components/render/render-banner.vue'
import RenderAvatars from '/~rec/components/render/render-avatars.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'entity-details',
  components: {
    BaseIcon,
    RenderBanner,
    RenderAvatars,
    BaseLoader,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    created: {
      type: String,
      default: null,
    },
    admin: {
      type: String,
      default: null,
    },
    users: {
      type: Object,
      default: () => ({
        list: [],
        processing: false,
      }),
    },
    location: {
      type: String,
      default: null,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    accessType: {
      type: String,
      validator: (v) => !v || /public|private/.test(v),
      default: null,
    },
  },
  data() {
    return {
      fetching: false,
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    async openMembers() {
      this.$emit('open-members')
    },
  },
}
</script>

<template>
  <div class="min-h-[500px] px-5">
    <div
      class="float-right mt-[15px] flex cursor-pointer items-center font-bold text-primary"
      @click="onClose"
    >
      <span>Close</span>
      <base-icon svg="close" size="md" />
    </div>

    <div class="pt-12 pb-5">
      <div class="text-center">
        <render-banner
          :image="image"
          :ratio="false"
          class="mx-auto mb-2.5 h-[140px] w-[140px]"
        />

        <h3 v-if="title" class="mb-5 break-words text-eonx-neutral-800">
          {{ title }}
        </h3>
      </div>

      <slot />

      <div v-if="description" class="mb-5 break-words text-eonx-neutral-800">
        {{ description }}
      </div>

      <div class="w-full">
        <div
          v-if="location"
          class="mb-2.5 flex w-full flex-nowrap items-center justify-start text-eonx-neutral-600"
        >
          <base-icon :size="19" class="mr-2.5 shrink-0" svg="rec/map" />
          <div
            class="grid grid-cols-1 break-words text-sm font-semibold leading-4"
          >
            {{ location }}
          </div>
        </div>

        <div
          v-if="date"
          class="mb-2.5 flex w-full flex-nowrap items-center justify-start text-eonx-neutral-600"
        >
          <base-icon
            :size="19"
            class="mr-2.5 shrink-0"
            svg="rec/calendar-line-filled"
          />
          <div class="text-sm font-semibold leading-4" v-html="date" />
        </div>

        <div
          v-if="created"
          class="mb-2.5 flex w-full flex-nowrap items-center justify-start text-eonx-neutral-600"
        >
          <base-icon
            :size="19"
            class="mr-2.5 shrink-0"
            svg="rec/calendar-line-filled"
          />
          <div class="text-sm font-semibold leading-4">
            Created {{ created }}
          </div>
        </div>

        <div
          v-if="accessType"
          class="mb-2.5 flex w-full items-center font-semibold text-eonx-neutral-600"
        >
          <base-icon
            :svg="accessType === 'private' ? 'rec/lock-solid' : 'rec/planet'"
            :size="19"
            class="mr-2.5 shrink-0"
          />
          <span class="text-sm capitalize leading-4">
            {{ accessType }}
          </span>
        </div>

        <div
          v-if="admin"
          class="flex flex-nowrap items-center justify-start text-eonx-neutral-600"
        >
          <base-icon :size="19" class="mr-2.5 shrink-0" svg="rec/cog" />
          <div
            class="grid cursor-pointer grid-cols-1 break-words text-sm font-semibold leading-4"
          >
            Admin: {{ admin }}
          </div>
        </div>
      </div>

      <template v-if="users.list.length">
        <div class="relative inline-flex items-center py-2.5">
          <render-avatars
            :users="users.list"
            look="row"
            size="lg"
            :class="{
              'opacity-50': users.processing,
            }"
            @click="openMembers"
          />

          <base-loader
            v-if="users.processing"
            size="sm"
            class="absolute inset-0"
          />
        </div>
      </template>

      <template v-if="actions">
        <div
          v-for="(item, idx) in actions"
          :key="idx"
          class="-mx-[5px] mt-[5px] flex items-center rounded-xl p-2.5"
          :class="{
            'cursor-default': item.disabled,
            'cursor-pointer hover:bg-eonx-neutral-50': !item.disabled,
          }"
          @click="() => !item.disabled && item.action()"
        >
          <div class="text-white">
            <base-icon v-if="!item.disabled" :svg="item.icon" :size="50" />
            <div v-else class="m-px flex h-12 w-12 items-center justify-center">
              <base-loader class="my-[5px]" size="sm" />
            </div>
          </div>
          <div class="flex flex-col truncate pl-5">
            <span class="truncate font-bold capitalize text-eonx-neutral-800">
              {{ item.title }}
            </span>
            <span class="truncate text-sm leading-5 text-eonx-neutral-600">
              {{ item.text }}
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
