import { RecroomAttachment } from '/~rec/core/attachment'

export class RecroomAttachmentsProcessor {
  constructor(attachments = [], fullPost = {}) {
    this.hits = attachments.map((attachment) => {
      return new RecroomAttachment(attachment, fullPost)
    })
  }

  get isEmpty() {
    return this.hits.length === 0
  }

  get count() {
    return this.hits.length
  }

  get hasAttachments() {
    return this.hits.length > 0
  }

  get hasAudioAttachments() {
    return this.audioAttachments.length > 0
  }

  get audioAttachments() {
    return this.hits.filter((i) => i.type === 'audio')
  }

  get audioAttachmentsCount() {
    return this.audioAttachments.length
  }

  get hasPreviewAttachments() {
    return this.previewAttachments.length > 0
  }

  get previewAttachments() {
    return this.hits.filter((i) => i.hasPreview)
  }

  get previewAttachmentsCount() {
    return this.previewAttachments.length
  }

  get hasDownloadableAttachments() {
    return this.downloadableAttachments.length > 0
  }

  get downloadableAttachments() {
    return this.hits.filter((i) => !i.hasPreview && i.type !== 'audio')
  }

  get downloadableAttachmentsCount() {
    return this.downloadableAttachments.length
  }
}
