<script>
import PostNomination from './types/post-nomination.vue'
import PostHi5 from './types/post-hi5.vue'
import PostDefault from './types/post-default.vue'
import PostAward from './types/post-award.vue'
import PostEvent from './types/post-event.vue'
import PostGroup from './types/post-group.vue'
import PostPoll from './types/post-poll.vue'
import PostQuiz from './types/post-quiz/post-quiz.vue'

export default {
  name: 'rec-post-content',
  components: {
    PostNomination,
    PostHi5,
    PostDefault,
    PostEvent,
    PostGroup,
    PostPoll,
    PostQuiz,
  },
  inject: ['post'],
  computed: {
    entryComponent() {
      const { type } = this.post

      if (type === 'gamification_nomination') return PostNomination
      else if (type === 'gamification_high_five') return PostHi5
      else if (type === 'gamification_award') return PostAward
      else if (type === 'event' || type === 'event_member') return PostEvent
      else if (type === 'group_member') return PostGroup
      else if (type === 'poll') return PostPoll
      else if (type === 'quiz') return PostQuiz
      return PostDefault
    },
  },
}
</script>

<template>
  <component :is="entryComponent" :post="post" class="text-eonx-neutral-800" />
</template>
