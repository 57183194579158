import { computed, reactive, ref } from 'vue'
import { ApiResponseList } from '/~/types/api'
import { AlgoliaWorker, getDefaultIndex } from '/~/core/algolia'
import api from '/~/core/api'
import { createDate } from '/~/utils/format/date'
import { useExtensions } from '/~/composables/extensions'
import { useSearch } from '/~/composables/search'
import { useUser } from '/~/composables/user'
import { CommunityHubOffer } from './core/CommunityHubOffer'

export interface RedeemedCoupon {
  code: string
  redeemedAt: string
}

const COMMUNITY_HUB_MODULE_NAME = 'community-hub'

const offerTypeNameMap = {
  coupon: 'Coupon Code',
  show: 'Show and Save',
  url: 'URL',
}

const { getConfigByName, getManifestByName } = useExtensions()
const { registerAlgoliaSearch } = useSearch()

const config = computed(() => getConfigByName(COMMUNITY_HUB_MODULE_NAME) || {})
const module = computed(() => getManifestByName(COMMUNITY_HUB_MODULE_NAME))
const label = computed(() => module.value?.label ?? 'Community Hub')
const searchConfig = computed(() => config.value?.search ?? {})
const searchGroup = computed(() => searchConfig.value?.group ?? label.value)
const useTagsAsMembershipFilter = computed(
  () => config.value?.useTagsAsMembershipFilter ?? false
)

const defaultIndex = computed(() => getDefaultIndex(config.value))
const sortValues = computed(() =>
  (config.value?.indexes ?? []).map((index) => ({
    text: index.label,
    value: index.index_id || index.name,
    default: index.default,
  }))
)
const defaultSortValue = computed(
  () => sortValues.value.find((v) => v.default) || sortValues.value[0]
)

const algolia = reactive(new AlgoliaWorker())

const searchQuery = ref('')
const selectedSorting = ref(defaultIndex.value)
const categories = ref(null)
const selectedCategory = ref(null)
const offerTypes = ref(null)
const selectedOfferTypes = ref(null)

const isFetchingUniqueCouponCode = ref(false)

const routeState = computed(() => {
  const params = {
    category: selectedCategory.value,
  }
  const query = {}

  if (searchQuery.value) {
    query.search = searchQuery.value
  }

  if (selectedSorting.value && selectedSorting.value !== defaultIndex.value) {
    query.sort = selectedSorting.value
  }

  if (selectedOfferTypes.value?.length > 0) {
    query.types = selectedOfferTypes.value.join(',')
  }

  return { query, params }
})

const isFiltersSelected = computed(() => {
  return (
    searchQuery.value !== '' ||
    selectedCategory.value !== null ||
    (selectedSorting.value !== defaultSortValue.value?.value &&
      selectedSorting.value !== null) ||
    selectedOfferTypes.value?.length > 0
  )
})

const defaultFacetFilters = computed(() => {
  const { user } = useUser()

  if (useTagsAsMembershipFilter.value) {
    return [`payload.offer.tags:${user.value.membershipLabel}`]
  }

  return []
})

const defaultFilters = computed(() => {
  const { user } = useUser()
  const filters = ['payload.active:true', 'payload.offersCount>0']

  if (useTagsAsMembershipFilter.value) {
    filters.push(`payload.offer.tags:"${user.value.membershipLabel}"`)
  }

  return filters.join(' AND ')
})

function initCommunityHub() {
  algolia.setParams(config.value)

  registerAlgoliaSearch({
    group: label.value,
    order: 4,
    config: config.value,
    algolia: {
      params: {
        filters: defaultFilters.value,
        facetFilters: defaultFacetFilters.value,
      },
    },
    mapping: (item) => {
      const image = item.payload.logo
      const isUcareCdn = image.indexOf('ucarecdn') > -1

      return {
        id: item.objectID,
        image: isUcareCdn ? null : image,
        uploadcare: isUcareCdn ? image : null,
        label: item.payload.name,
        target: {
          name: `${COMMUNITY_HUB_MODULE_NAME}-retailer`,
          params: {
            id: item.retailer_id,
          },
        },
      }
    },
  })
}

function resetFilters() {
  searchQuery.value = ''
  selectedSorting.value = null
  selectedCategory.value = null
  selectedOfferTypes.value = null
}

async function getContent() {
  if (!algolia.isSet) {
    initCommunityHub()
  }

  // NOTE: reset items to trigger processing in core/algolia
  algolia.reset()

  const categoryFacetFilter = selectedCategory.value
    ? [`payload.categories:${algolia.decodePath(selectedCategory.value)}`]
    : []

  const offerTypeFaceFilter = (selectedOfferTypes.value ?? []).map(
    (type) => `payload.offer.types:${type}`
  )

  const filters = [defaultFilters.value, offerTypeFaceFilter.join(' OR ')]
    .filter((v) => v)
    .map((v) => `(${v})`)
    .join(' AND ')

  await algolia.getDataMultiple({
    multiple: [
      {
        indexName: selectedSorting.value,
        query: searchQuery.value,
        params: {
          facetFilters: [...defaultFacetFilters.value, ...categoryFacetFilter],
          filters,
          distinct: true,
        },
      },
      {
        indexName: defaultIndex.value,
        // query: searchQuery.value,
        params: {
          page: 0,
          hitsPerPage: 1,
          attributesToRetrieve: [],
          facets: ['payload.categories'],
          facetFilters: defaultFacetFilters.value,
        },
      },
      {
        indexName: defaultIndex.value,
        // query: searchQuery.value,
        params: {
          page: 0,
          hitsPerPage: 1,
          attributesToRetrieve: [],
          facets: ['payload.offer.types'],
          facetFilters: defaultFacetFilters.value,
        },
      },
    ],
  })

  const [categoriesResult, offerTypesResult] = algolia.multiple

  const categoriesFacets = algolia.parseFacets(categoriesResult)

  if (categoriesFacets) {
    const newCategories = categoriesFacets.get('payload.categories')

    if (newCategories) {
      newCategories.values.unshift({
        label: 'All categories',
        count: newCategories.total,
        id: null,
      })

      categories.value = newCategories.values
    } else {
      categories.value = [
        {
          label: 'All categories',
          count: -1,
          id: null,
        },
      ]
    }
  }

  const offerTypesFacets = algolia.parseFacets(offerTypesResult)

  if (offerTypesFacets) {
    const newOfferTypes = offerTypesFacets.get('payload.offer.types')

    offerTypes.value = (newOfferTypes?.values ?? []).map((type) => {
      type.label = offerTypeNameMap[type.id]

      return type
    })
  }
}

function syncState({ to }) {
  selectedCategory.value = to.params?.category || null

  searchQuery.value = to.query?.search || ''
  selectedOfferTypes.value = to.query.types ? to.query.types.split(',') : []
  selectedSorting.value = to.query?.sort || defaultIndex.value
}

async function getDetails(id) {
  try {
    const [retailerResponse, offersResponse] = await Promise.all([
      algolia.getDataOnce({
        index: defaultIndex.value,
        search: {
          hitsPerPage: 1,
          facetFilters: [`retailer_id:${id}`],
          distinct: false,
        },
      }),
      getRetailerOffers(id),
    ])

    const retailer = retailerResponse?.hits?.[0]?.payload ?? null
    const offers = []

    if (offersResponse) {
      const now = createDate()
      const rawOffers = offersResponse?.items ?? []

      offers.push(
        ...rawOffers.filter(({ active, endsAt }) => {
          if (!active) return false

          return endsAt === null || now.isBefore(createDate(endsAt))
        })
      )
    }

    return {
      retailer,
      offers,
    }
  } catch (error) {
    console.error(error)
  }
}

function getRetailerOffers(retailerId) {
  return api.get(`/v3/community-hub/retailer/${retailerId}/offers`)
}

const offerGroups = ref([])
const groupOffers = ref([])
const currentOfferGroupId = ref(null)

async function fetchOfferGroups() {
  const { items } = await api.get('/v3/community-hub/offer-groups')

  offerGroups.value = items
  return items
}

async function fetchOfferGroupOffers(offerGroupId) {
  const { items } = await api.get(
    `/v3/community-hub/offer-groups/${offerGroupId}/offers?enabled=true`
  )

  currentOfferGroupId.value = offerGroupId
  groupOffers.value = items.map((item) => new CommunityHubOffer(item))
}

async function fetchOffer(offerId: string) {
  const offer = groupOffers.value?.find((item) => item.id === offerId)

  if (offer) {
    return offer
  }
  const { data } = await api.get(`/v3/community-hub/offers/${offerId}`)

  return new CommunityHubOffer(data)
}

async function redeemOffer(offerId: string) {
  isFetchingUniqueCouponCode.value = true

  try {
    return await api.post('/v3/community-hub/redeem', { offerId })
  } catch (error) {
    console.error('community-hub', error)
    throw error
  } finally {
    isFetchingUniqueCouponCode.value = false
  }
}

async function fetchRedeemedCoupons(offerId: string) {
  try {
    const response = await api.get<ApiResponseList<RedeemedCoupon>>(
      `/v3/community-hub/offers/${offerId}/redeemed-coupons?perPage=15&page=1`
    )

    return response.data.items
  } catch (error) {
    console.error(error)
    return []
  }
}

export function useCommunityHub() {
  return {
    moduleName: COMMUNITY_HUB_MODULE_NAME,
    algolia,
    label,
    searchGroup,

    sortValues,
    defaultSortValue,

    searchQuery,
    selectedSorting,
    selectedCategory,
    selectedOfferTypes,
    routeState,
    categories,
    offerTypes,
    isFiltersSelected,
    isFetchingUniqueCouponCode,

    initCommunityHub,
    resetFilters,
    getContent,
    getDetails,
    getRetailerOffers,
    syncState,

    offerGroups,
    groupOffers,
    currentOfferGroupId,

    fetchOffer,
    fetchOfferGroups,
    fetchOfferGroupOffers,
    redeemOffer,
    fetchRedeemedCoupons,
  }
}
