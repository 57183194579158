<script>
import { useRouter } from 'vue-router/composables'
import BaseButton from '/~/components/base/button/base-button'
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'virtual-card-add',
  components: {
    BaseButton,
    BaseImage,
  },
  setup() {
    const router = useRouter()

    function onCreate() {
      router.push({ hash: '#profile-create-virtual-card' })
    }

    function onCancel() {
      router.push({ hash: '#profile-home' })
    }

    return {
      onCreate,
      onCancel,
    }
  },
}
</script>

<template>
  <div class="flex h-full w-full flex-col">
    <div
      class="relative flex w-full shrink-0 grow flex-col overflow-hidden bg-white px-5 pt-[120px] pb-5 text-center"
    >
      <h2 class="font-extrabold">Take your rewards further</h2>
      <div class="mt-2.5 font-inter">
        Access your account funds right away and seamlessly transact with your
        {{ ewalletLabels.ewalletCashBalance }} anywhere.
      </div>
      <div class="mt-2.5 -mr-5 mb-auto w-auto">
        <base-image src="/virtual-cards/create-bg.png" :ratio="false" />
      </div>
      <div class="mt-4">
        <base-button class="w-full" @click="onCreate">
          Create virtual card
        </base-button>
        <base-button look="link" class="mt-2.5 w-full" @click="onCancel">
          Cancel
        </base-button>
      </div>
    </div>
  </div>
</template>
