<script setup lang="ts">
import StatementsListItem from '/~/extensions/scheduled-payments/components/list/item-statements.desk.vue'
import Item from '/~/extensions/scheduled-payments/components/list/item.vue'
import LayoutMain from '/~/extensions/scheduled-payments/layouts/main.vue'
import { formatDate } from '/~/utils/format/date'
import { pluralize } from '/~/utils/format/string'
import BaseButton from '/~/components/base/button/base-button'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import EmptyState from '/~/components/empty-state/empty-state.vue'
import { useLocalization } from '/~/composables/localization'
import { useScheduledPaymentsList } from '/~/composables/scheduled-payments'

const props = withDefaults(
  defineProps<{
    type?: 'statements' | 'payments'
  }>(),
  {
    type: 'statements',
  }
)

const {
  loading,
  selectedTabKey,
  isScheduledPaymentsLoading,
  isStatementsTabActive,
  isPaymentsTabActive,
  scheduledStatementAccounts,
  scheduledPayments,
  scheduledPaymentOrdersByDate,
  onMakePaymentClicked,
  onEnableDirectDebitClicked,
} = useScheduledPaymentsList(props)

const { translate } = useLocalization()

const directDebitsTitle = pluralize(2, translate('directDebit.text') as string)
const directDebitTitle = `${translate('directDebit.text')}`
</script>

<template>
  <layout-main>
    <div class="h-full">
      <div class="flex h-full flex-col overflow-y-auto px-12 py-8">
        <h1 class="text-2xl text-eonx-neutral-800">Scheduled payments</h1>
        <div class="mt-2 mb-8 text-eonx-neutral-600">
          Manage your scheduled payments &
          {{ directDebitsTitle }}
        </div>
        <base-tabs
          v-model="selectedTabKey"
          look="v4"
          class="mb-8 mr-auto flex-shrink-0"
        >
          <base-tab :name="directDebitTitle" value="statements" keep-alive />
          <base-tab name="Once off" value="payments" keep-alive />
        </base-tabs>
        <base-loader v-if="isScheduledPaymentsLoading || loading" />
        <template v-else>
          <div class="flex h-full w-auto grow flex-col overflow-y-auto">
            <div class="h-full w-full space-y-4">
              <template
                v-if="
                  isStatementsTabActive && scheduledStatementAccounts.length
                "
              >
                <statements-list-item
                  v-for="item in scheduledStatementAccounts"
                  :key="item.number || item.id"
                  :account="item"
                />
              </template>
              <template
                v-else-if="isPaymentsTabActive && scheduledPayments.length"
              >
                <div
                  v-for="(
                    payments, scheduledAt
                  ) of scheduledPaymentOrdersByDate"
                  :key="scheduledAt"
                  class="w-full space-y-4"
                >
                  <div class="font-bold text-eonx-neutral-600">
                    {{ formatDate('daymonth', scheduledAt) }}
                  </div>
                  <item
                    v-for="payment of payments"
                    :key="payment.number || payment.id"
                    :payment="payment"
                  />
                </div>
              </template>
              <empty-state
                v-else-if="isPaymentsTabActive"
                icon="heroicons/solid/calendar"
                class="flex h-full grow flex-col justify-center"
              >
                <template #title>
                  <div class="pt-1">No scheduled payments listed</div>
                </template>
                <template #description>
                  <div>Manage your scheduled payments with ease</div>
                  <div class="mt-8">
                    <base-button class="w-80" @click="onMakePaymentClicked">
                      Make a payment
                    </base-button>
                  </div>
                </template>
              </empty-state>
              <empty-state
                v-else
                icon="heroicons/solid/calendar"
                class="flex h-full grow flex-col justify-center"
              >
                <template #title>
                  <div class="pt-1">No {{ directDebitsTitle }} listed</div>
                </template>
                <template #description>
                  <div>
                    Setup a {{ translate('directDebit.text') }} in Accounts &
                    statements to ensure your statements are paid on time
                  </div>
                  <div class="mt-8">
                    <base-button
                      class="w-80"
                      @click="onEnableDirectDebitClicked"
                    >
                      Enable a
                      {{ directDebitTitle.toLowerCase() }}
                    </base-button>
                  </div>
                </template>
              </empty-state>
            </div>
          </div>
        </template>
      </div>
    </div>
  </layout-main>
</template>
