<script>
import get from 'lodash-es/get'
import { computed } from 'vue'
import { useGiftcards } from '/~/extensions/giftcards/composables'
import cdn, { getProviderDefaultUrl } from '/~/utils/cdn'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useProvider } from '/~/composables/provider'
import GcBadge from './gc-badge.vue'

export default {
  name: 'giftcards-tile',
  components: {
    BaseLink,
    BaseImage,
    GcBadge,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isPhysicalEnabled, selectedCardType } = useGiftcards()
    const { isBupaTemplate } = useProvider()

    const defaultImage = computed(() => {
      if (isBupaTemplate.value) {
        return getProviderDefaultUrl('bupa')
      }

      return undefined
    })

    return {
      isPhysicalEnabled,
      selectedCardType,
      defaultImage,
    }
  },
  data() {
    return {
      isPhysical: false,
      routeName: 'giftcards-retailer',
    }
  },
  computed: {
    imageBindings() {
      const altLogo = this.tile.retailer_alt_logo

      const url = this.cdn(altLogo).url()
      const best = cdn(url)
        .preview('300x300')
        .format('auto')
        .quality('smart')
        .url()
      const better = cdn(url)
        .preview('600x600')
        .format('auto')
        .quality('smart')
        .url()

      return {
        src: best,
        srcset: `${best} 1x, ${better} 2x`,
      }
    },
    offerName() {
      return get(this.tile, 'offer_name', '')
        .replace(/gift|egift|cards|card/gi, '')
        .replace(/\boff\b/gi, 'OFF')
        .trim()
    },
    alt() {
      return `${this.tile.retailer_name} ${this.offerName}`
    },
  },
  created() {
    this.isPhysical = this.selectedCardType === 'physical'
  },
}
</script>

<template>
  <base-link
    :to="{
      name: routeName,
      params: {
        slug: tile.retailer_slug,
        id: tile.offer_external_id,
      },
    }"
    class="group relative z-0 block h-full"
  >
    <base-image
      v-bind="imageBindings"
      :default-src="defaultImage"
      :alt="alt"
      aria-hidden="true"
      class="rounded-lg shadow-xs transition duration-100 group-hover:shadow-lg"
    />
    <div
      v-if="isPhysicalEnabled"
      class="absolute left-1/2 z-1 -mt-[15px] -translate-x-1/2 transform text-xs"
    >
      <gc-badge :is-physical="isPhysical" />
    </div>
    <div class="mt-4 px-2.5 text-center text-default">
      <h3
        class="truncate text-center text-sm text-eonx-neutral-600"
        data-cy="ew-retailer"
      >
        {{ tile.retailer_name }}
      </h3>
      <div v-if="offerName" class="truncate font-bold">
        {{ offerName }}
      </div>
      <div v-if="tile.offer_subtitle" class="truncate font-bold">
        {{ tile.offer_subtitle }}
      </div>
    </div>
  </base-link>
</template>
