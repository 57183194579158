<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import { cardCode } from '/~/utils/cards'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { ensureNumber } from '/~/utils/format/numeric'
import { formatPoints } from '/~/utils/points'
import ActivitySummaryItems from '/~/components/activity/summary/activity-summary-items.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useActivity } from '/~/composables/activity'
import { useAddresses } from '/~/composables/addresses'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import ActivityDetailsItemPayee from './activity-details-item-payee.vue'
import TaxInvoice from './tax-invoice.vue'

export default {
  name: 'activity-details-item',
  components: {
    TaxInvoice,
    BaseButton,
    BaseIcon,
    ActivitySummaryItems,
    PointsPanel: () => import('/~/components/points/points-panel.v2.vue'),
    CashbackPanel: () => import('/~/components/cashback/cashback-panel.vue'),
    ActivityDetailsItemPayee,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  setup(props) {
    const router = useRouter()
    const { taxationLabel } = useProvider()
    const {
      isPurchaseOrderPoints,
      isPaymentOrderPoints,
      calculatePointsEarnedForPayment,
      calculatePointsEarnedForPurchase,
    } = usePoints()
    const { accountPresentation } = useBankAccountForm()
    const { translate } = useLocalization()
    const { ewalletLabels } = useCms()
    const { userAddress } = useAddresses()
    const { routeState, getActivityItem, getFlowOrderItem } = useActivity()
    const { user } = useUser()

    const loading = ref(true)
    const item = ref({})
    const order = ref({})

    const config = computed(() => {
      const config = {
        baseURL: eonx.hosts.api.v1_1,
      }

      return config
    })

    const flowType = computed(() => item.value?.flowType ?? FlowType.purchase)

    async function getActivityDetails() {
      loading.value = true
      try {
        item.value = await getActivityItem(props.id, config.value)
        order.value = await getOrder()
      } catch (error) {
        console.error(error)
        goBack()
      } finally {
        loading.value = false
      }
    }

    function getOrder() {
      if (item.value) {
        return getFlowOrderItem(flowType.value, item.value.orderNumber)
      }

      return Promise.resolve(undefined)
    }

    function goBack() {
      router.replace({ name: 'activity', ...routeState.value })
    }

    onBeforeMount(() => {
      getActivityDetails()
    })

    return {
      calculatePointsEarnedForPayment,
      calculatePointsEarnedForPurchase,
      formatDollar,
      loading,
      userAddress,
      getActivityItem,
      isPurchaseOrderPoints,
      isPaymentOrderPoints,
      ui,
      taxationLabel,
      PaymentMethodType,
      accountPresentation,
      translate,
      ewalletLabels,
      item,
      order,
      config,
      flowType,
      goBack,
      user,
    }
  },
  computed: {
    hasOrder() {
      return !(
        this.item.isTypePaymentOrder && this.item.transactionType === 'credit'
      )
    },
    statusColor() {
      if (this.item.isStatusCompleted) {
        return 'text-green-400'
      } else if (this.item.isStatusPending) {
        return 'text-warning-700'
      }
      return 'text-error-700'
    },
    showPrintButton() {
      return (
        !ui.mobile &&
        !this.item.isStatusFailed &&
        (this.item.isTypePurchaseOrder ||
          this.item.isTypePaymentOrder ||
          this.item.isTypeStatementOrder ||
          this.item.isTypeBatchOrder)
      )
    },
    showPayTo() {
      return (
        this.item.isTypePurchaseOrder ||
        this.item.isTypePaymentOrder ||
        this.item.isTypeStatementOrder
      )
    },
    printContent() {
      let address = {
        ...this.userAddress,
        companyName:
          this.order.userStatementAccount?.name ||
          this.userAddress?.companyName,
        accountNumber: this.order.userStatementAccount?.number,
        mobile: null,
        email: null,
      }

      if (this.order.billingAddress) {
        address = { ...this.order.billingAddress }
      } else if (this.order.shippingAddress) {
        address = { ...this.order.shippingAddress }
      }

      return {
        ...this.item,
        ...this.order,
        payees: this.order.payees || [this.payee],
        address,
        date: this.date,
      }
    },
    payee() {
      return {
        ...this.order.payee,
        ...this.item.payee,
        reference: this.item.reference,
        subtotal: this.item.subtotal,
      }
    },
    payeeName() {
      const { accountName, name } = this.payee

      return name || accountName
    },
    paymentMethods() {
      if (this.item.isTypePointsTransaction) return []

      const paymentMethods =
        this.order.paymentMethods ||
        this.order.paymentSources ||
        this.item.paymentMethods ||
        []

      return paymentMethods
        .map((method) => {
          let result

          if (method.type) {
            result = {
              ...method,
            }
          } else {
            result = {
              type: method,
              amount: paymentMethods.length === 1 ? this.item.total : 0,
              brand: 'plain/creditcards',
            }
          }

          const issuingNetwork = getCardTypeByNumber(method.number)
          const brand = cardCode({ brand: issuingNetwork?.type })

          if (brand) {
            result.brand = `cards/${brand}`
          }

          switch (result.type) {
            case this.PaymentMethodType.points:
              result.title = 'Points'
              result.details = `Using ${this.formatPoints(result.points)} PTS`
              break
            case this.PaymentMethodType.eWallet:
              result.title = this.ewalletLabels.ewalletCash
              break
            case this.PaymentMethodType.creditCard:
              result.title = 'Credit Card'
              result.details = result.number
              break
            case this.PaymentMethodType.bankAccount:
              result.title = 'Bank Account'
              result.bsb = method.bsb
              break
            case this.PaymentMethodType.couponCode:
              result.title = 'Coupon'
              result.details = method.number
              break
          }

          return result
        })
        .filter((i) => i.amount > 0)
    },
    name() {
      if (this.item.isTypePurchaseOrder) {
        return this.item.description?.[1]
      } else if (this.item.isTypePointsTransaction) {
        return this.item.isStatusPending
          ? 'Points to be earned'
          : 'Points Earned'
      } else if (this.item.isTypeTopUpOrder) {
        return this.item.description?.[0]
      }

      return this.payeeName
    },
    pointsEarned() {
      if (this.item.isTypePointsTransaction) {
        return Math.round(this.item.total)
      }

      const pointsTransactions = this.order.pointsTransactions || []
      const earnTransaction = pointsTransactions.filter(
        (t) => t.type === 'earn'
      )

      if (earnTransaction.length > 0) {
        return earnTransaction[0].allocatedValue
      }

      if (this.item.isTypeStatementOrder) {
        return this.calculatePointsEarnedForPayment(this.item.subtotal)
      }

      if (this.item.isStatusPending) {
        return this.item.isTypePurchaseOrder
          ? this.calculatePointsEarnedForPurchase(this.order.items)
          : this.calculatePointsEarnedForPayment(
              this.item.subtotal || this.item.total
            )
      }

      return null
    },
    isPointsEarnShow() {
      if (this.item.isTypePointsTransaction) {
        return true
      }
      if (this.item.isTypePurchaseOrder) {
        return this.isPurchaseOrderPoints
      }
      if (
        this.item.isTypePaymentOrder ||
        this.item.isTypeStatementOrder ||
        this.item.isTypeBatchOrder
      ) {
        return this.isPaymentOrderPoints
      }
      return false
    },
    date() {
      let value

      if (this.item.isTypePointsTransaction) {
        value = this.item.happenedAt
      } else {
        value =
          this.order.paidAt || this.order.completedAt || this.item.updatedAt
      }

      return formatDate('daymonthyearlongtime', value)
    },
    fees() {
      return this.order.fees?.items || []
    },
    paymentFees() {
      let amount = this.getFeesByType('Processing Fee')

      if (this.user.isCombineFees) {
        amount += this.programFees
      }

      return amount
    },
    programFees() {
      return this.getFeesByType('Program Fee')
    },
    shippingFees() {
      return this.getFeesByType('Shipping Fee')
    },
    orderItems() {
      return this.order?.items ?? []
    },
    cashbackRebateTotal() {
      if (
        this.item.isTypeTopUpOrder &&
        this.order.fundingSource === 'cashback'
      ) {
        return this.order.total
      }

      return ensureNumber(this.order?.rebateTotal)
    },
  },
  methods: {
    formatPoints,
    getFeesByType(label) {
      const fees = this.fees.filter?.((fee) => fee.label === label)

      return fees?.reduce?.((value, fee) => {
        return Number(fee?.amount ?? 0) + value
      }, 0)
    },
    print() {
      window.print()
    },
  },
}
</script>

<template>
  <div class="flex h-full w-full">
    <div
      class="flex h-full w-full flex-col rounded-t-3xl bg-eonx-neutral-50 sm:mr-6 sm:pt-[30px]"
    >
      <div
        class="relative mx-auto flex h-full w-full flex-col bg-white sm:max-w-xl sm:rounded-t-3xl sm:pt-[35px]"
      >
        <div
          class="mt-5 flex items-center justify-between px-5 pb-5 sm:mt-0 sm:px-10"
        >
          <base-button v-if="!ui.mobile" look="link" @click="goBack">
            <base-icon svg="chevron-left" size="md" class="mr-[5px]" />
            <span class="text-sm">Back</span>
          </base-button>

          <base-button
            v-if="showPrintButton"
            :disabled="loading"
            look="link"
            class="text-sm"
            @click="print"
          >
            <base-icon svg="plain/printer" size="md" class="mr-[5px]" />
            <span class="text-sm">Print Invoice</span>
          </base-button>
        </div>
        <div class="grow overflow-y-auto px-5 sm:px-10">
          <div
            class="flex justify-center text-center font-bold text-eonx-neutral-600"
          >
            Transaction No.
            <span
              v-if="loading"
              class="ml-[5px] animate-pulse rounded bg-eonx-neutral-50 text-transparent"
            >
              loading
            </span>
            <template v-else>#{{ item.orderNumber }}</template>
          </div>

          <div class="mt-[5px] text-center font-bold text-eonx-neutral-600">
            <div
              v-if="loading"
              class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
            >
              loading
            </div>
            <template v-else>
              {{ date }}
            </template>
          </div>

          <div
            class="mt-[5px] mb-[30px] text-center font-bold text-eonx-neutral-600"
          >
            <div
              v-if="loading"
              class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
            >
              loading
            </div>
            <template v-else>
              Status:
              <span class="capitalize" :class="[statusColor]">
                <template v-if="item.isStatusCompleted">Completed</template>
                <template v-else-if="item.isStatusPending">Pending</template>
                <template v-else-if="item.isStatusIncomplete">
                  Incomplete
                </template>
                <template v-else>Failed</template>
              </span>
            </template>

            <div v-if="item.performedBy" class="mt-1.5">
              Performed by: {{ item.performedBy.fullName }}
            </div>
          </div>

          <!-- pay to -->
          <div
            v-if="showPayTo && payeeName"
            class="mb-5 border-b border-zinc-100 pb-5"
          >
            <div class="mb-2.5 font-bold text-eonx-neutral-600">Paid to</div>
            <activity-details-item-payee :payee="payee" />
          </div>

          <div
            v-else-if="order.payees"
            class="mb-5 border-b border-zinc-100 pb-5"
          >
            <div class="mb-2.5 font-bold text-eonx-neutral-600">Paid to</div>
            <activity-details-item-payee
              v-for="(orderPayee, index) in order.payees"
              :key="orderPayee.id || index"
              :payee="orderPayee"
              class="mt-2.5"
            />
          </div>
          <h2
            v-else-if="name"
            class="mb-5 border-b border-zinc-100 pb-5 text-center"
          >
            <div
              v-if="loading"
              class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
            >
              loading
            </div>
            <template v-else>
              {{ name }}
            </template>
          </h2>

          <div
            v-if="loading || orderItems.length > 0"
            class="mb-5 border-b border-zinc-100 pb-5"
          >
            <div v-if="loading" class="flex">
              <div
                class="h-20 w-20 shrink-0 animate-pulse rounded bg-eonx-neutral-50"
              />
              <div class="flex-1 pl-2.5">
                <div class="mb-2.5 flex w-full">
                  <div
                    class="flex-1 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
                  >
                    loading
                  </div>
                  <div
                    class="ml-12 shrink-0 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
                  >
                    loading
                  </div>
                </div>
                <div
                  class="mb-[5px] animate-pulse rounded bg-eonx-neutral-50 text-xs text-transparent"
                >
                  loading
                </div>
                <div
                  class="animate-pulse rounded bg-eonx-neutral-50 text-xs text-transparent"
                >
                  loading
                </div>
              </div>
            </div>
            <activity-summary-items
              v-if="orderItems.length > 0"
              :items="orderItems"
              :activity="item"
            />
          </div>

          <!-- paid with -->
          <div
            v-if="paymentMethods.length > 0"
            class="mb-5 border-b border-zinc-100 pb-5"
          >
            <div class="mb-2.5 font-bold text-eonx-neutral-600">Paid with</div>

            <div class="space-y-[15px]">
              <div
                v-for="(method, index) in paymentMethods"
                :key="index"
                class="flex items-center justify-between"
              >
                <div class="flex items-center">
                  <div
                    class="h-12 w-12 overflow-hidden"
                    :class="{
                      'rounded-full': method.type !== PaymentMethodType.eWallet,
                    }"
                  >
                    <div
                      v-if="method.type === PaymentMethodType.points"
                      class="h-full w-full text-primary"
                    >
                      <base-icon
                        svg="points/token"
                        size="md"
                        class="min-h-full min-w-full"
                      />
                    </div>
                    <div
                      v-else-if="method.type === PaymentMethodType.bankAccount"
                      class="flex h-full w-full items-center justify-center bg-primary text-white"
                    >
                      <base-icon svg="v2/custom/bank" size="md" />
                    </div>
                    <div
                      v-else-if="method.type === PaymentMethodType.eWallet"
                      class="flex h-full w-full items-center justify-center"
                    >
                      <base-icon svg="plain/ewallet" size="xl" />
                    </div>
                    <div
                      v-else-if="method.type === PaymentMethodType.creditCard"
                      class="flex h-12 w-12 shrink-0 items-center justify-center"
                    >
                      <base-icon :svg="method.brand" size="xl" />
                    </div>
                    <div
                      v-else-if="method.type === PaymentMethodType.couponCode"
                      class="flex h-12 w-12 shrink-0 items-center justify-center text-primary"
                    >
                      <base-icon svg="coupon" size="2xl" />
                    </div>
                    <div v-else class="h-full w-full bg-eonx-neutral-50" />
                  </div>

                  <div class="ml-[15px] flex flex-col">
                    <div class="font-bold text-eonx-neutral-800">
                      <template v-if="hasOrder">
                        {{ method.title || method.name }}
                      </template>
                      <template
                        v-else-if="method.type === PaymentMethodType.points"
                      >
                        Points
                      </template>
                      <template
                        v-else-if="
                          method.type === PaymentMethodType.bankAccount
                        "
                      >
                        Bank Account
                      </template>
                      <template
                        v-else-if="method.type === PaymentMethodType.eWallet"
                      >
                        {{ ewalletLabels.ewalletCash }}
                      </template>
                      <template
                        v-else-if="method.type === PaymentMethodType.creditCard"
                      >
                        Credit Card
                      </template>
                      <template
                        v-else-if="method.type === PaymentMethodType.couponCode"
                      >
                        Coupon
                      </template>
                    </div>
                    <div class="text-sm text-eonx-neutral-600">
                      <span
                        v-if="
                          method.type === PaymentMethodType.creditCard &&
                          method.details
                        "
                      >
                        **** **** **** {{ method.details.slice(-4) }}
                      </span>
                      <span
                        v-if="
                          method.type === PaymentMethodType.bankAccount &&
                          method.number
                        "
                      >
                        {{
                          translate('bankAccount.details', {
                            acc: `*** *** *${method.number.slice(-2)}`,
                            bsb: method.bsb || method.prefix,
                          })
                        }}
                      </span>
                      <span
                        v-if="
                          method.type === PaymentMethodType.points &&
                          method.details
                        "
                      >
                        {{ method.details }}
                      </span>
                      <span
                        v-if="
                          method.type === PaymentMethodType.couponCode &&
                          method.details
                        "
                      >
                        {{ method.details }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="hasOrder && method.finalAmount"
                  class="text-eonx-neutral-600"
                >
                  {{ formatDollar(method.finalAmount) }}
                </div>
                <div v-else class="text-eonx-neutral-600">
                  {{ formatDollar(method.amount) }}
                </div>
              </div>
            </div>
          </div>

          <!-- transaction amount details -->
          <template v-if="!loading">
            <div class="flex items-center justify-between">
              <div class="font-bold text-eonx-neutral-800">
                <template v-if="item.isTypePaymentOrder">
                  Bill Pay Amount
                </template>
                <template v-else>Order Amount</template>
              </div>
              <div class="font-bold text-eonx-neutral-800">
                {{ formatDollar(item.subtotal) }}
              </div>
            </div>

            <div
              v-if="!user.isCombineFees && programFees"
              class="mt-[5px] flex items-center justify-between"
            >
              <div class="text-eonx-neutral-600" data-testid="program-fee">
                Program Fee
                <span class="inline-block whitespace-nowrap">
                  (inc. {{ taxationLabel }})
                </span>
              </div>
              <div class="text-eonx-neutral-600">
                {{ formatDollar(programFees) }}
              </div>
            </div>

            <div
              v-if="paymentFees"
              class="mt-[5px] flex items-center justify-between"
            >
              <div class="text-eonx-neutral-600">Payment Processing Fee</div>
              <div class="text-eonx-neutral-600">
                {{ formatDollar(paymentFees) }}
              </div>
            </div>

            <div
              v-if="shippingFees"
              class="mt-[5px] flex items-center justify-between"
            >
              <div class="text-eonx-neutral-600">Shipping Fee</div>
              <div class="text-eonx-neutral-600">
                {{ formatDollar(shippingFees) }}
              </div>
            </div>

            <div class="mt-[5px] flex items-center justify-between">
              <div class="text-2xl font-bold text-eonx-neutral-800">
                Total Amount
              </div>
              <div class="text-2xl font-bold text-eonx-neutral-800">
                {{ formatDollar(order.total) }}
              </div>
            </div>

            <div
              v-if="!item.isStatusFailed"
              class="-mx-5 mt-5 sm:-mx-10 sm:mt-[30px]"
            >
              <points-panel
                v-if="isPointsEarnShow && pointsEarned"
                :total="pointsEarned"
                :completed="!item.isStatusPending"
              />
              <cashback-panel
                :total="cashbackRebateTotal"
                :completed="!item.isStatusPending"
              />
            </div>
          </template>
        </div>
        <!-- <div class="px-5 sm:px-10">
          <div class="left-0 flex justify-between w-full py-5 mb-10 space-x-2.5 bg-white sm:mb-0 sm:sticky sm:bottom-0">
            <div class="flex-1">
              <base-button
                look="outlined-color"
                class="w-full"
              >
                <span class="text-sm">
                  Email Remittance
                </span>
              </base-button>
            </div>
            <div class="flex-1">
              <base-button
                class="w-full"
                @click="goBack"
              >
                <span class="text-sm">
                  Done
                </span>
              </base-button>
            </div>
          </div>
        </div> -->
      </div>
      <tax-invoice
        v-if="!loading"
        :is-type-purchase-order="item.isTypePurchaseOrder"
        :is-type-payment-order="item.isTypePaymentOrder"
        :is-type-cashback-reconciliation-feed="
          item.isTypeCashbackReconciliationFeed
        "
        :is-type-statement-order="item.isTypeStatementOrder"
        :is-type-batch-order="item.isTypeBatchOrder"
        :is-type-cash-transaction="item.isTypeCashTransaction"
        :content="printContent"
      />
    </div>
  </div>
</template>
