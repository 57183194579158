<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'base-card',
  props: {
    ratio: {
      type: Number,
      default: 380 / 235,
    },
  },
  emits: ['click', 'keyup', 'dragenter', 'dragleave', 'dragover', 'drop'],
})
</script>

<template>
  <div
    v-bind="$attrs"
    tabindex="0"
    @click="$emit('click', $event)"
    @keyup="$emit('keyup', $event)"
    @dragenter="$emit('dragenter', $event)"
    @dragleave="$emit('dragleave', $event)"
    @dragover="$emit('dragover', $event)"
    @drop="$emit('drop', $event)"
  >
    <div
      class="relative"
      :style="{
        paddingBottom: `${(1 / Number(ratio)) * 100}%`,
      }"
    >
      <div class="absolute inset-0">
        <slot />
      </div>
    </div>
  </div>
</template>
