<script>
import modal from '/~/core/mdl'
import DetailsBlock from '/~rec/components/details/details-block.vue'
import { formatDate } from '/~/utils/format/date'
import { useRecProfile } from '/~rec/composables/profile'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'group-info',
  components: {
    DetailsBlock,
  },
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
    usersProcessing: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isMeAdmin, isMeSuperAdmin } = useRecProfile()
    const { groups } = useGroups()
    const allUsers = groups.defaultGroup.members.hits

    return { isMeAdmin, isMeSuperAdmin, allUsers }
  },
  data() {
    return {
      disabled: false,
    }
  },
  computed: {
    allowAction() {
      return this.group?.isMeOwner || this.isMeAdmin || this.isMeSuperAdmin
    },
    actions() {
      const { isMeOwner, isMeMember } = this.group

      return [
        {
          icon: 'rec/round/edit',
          title: 'Edit Group',
          text: 'Edit details for this Group',
          action: () => {
            this.$router.push({
              name: 'rec-group-edit',
              params: { initialGroup: this.group },
            })
          },
          hidden: !this.allowAction,
          disabled: this.disabled,
        },
        {
          icon: 'rec/round/leave',
          title: 'Leave Group',
          text: 'Leave this Group',
          action: () => {
            modal.show('rec-group-leave', {
              props: {
                group: this.group,
              },
              on: {
                success: async () => {
                  await this.group.members.refresh()
                  await this.group.edit()
                },
              },
            })
          },
          disabled: this.disabled,
          hidden: !isMeMember || (isMeOwner && this.group.totalMembers <= 1),
        },
        {
          icon: 'rec/round/delete',
          title: 'Delete Group',
          text: 'Delete this Group',
          action: async () => {
            modal.show('rec-group-delete', {
              props: {
                group: this.group,
              },
              on: {
                success: () => {
                  this.$router.replace({ name: 'rec-groups' })
                  modal.hideAll()
                },
              },
            })
          },
          disabled: this.disabled,
          hidden: !this.allowAction,
        },
        {
          icon: 'rec/round/enter-group',
          title: 'Join Group',
          text: 'Join this Group',
          action: async () => {
            try {
              this.disabled = true
              await this.group.join()
              await this.group.members.refresh()
              await this.group.edit()
              this.$notify({
                text: `You have successfully joined the group ${this.group.name}`,
                type: 'success',
                duration: 2000,
              })
            } catch (error) {
              console.error(error)
              this.$notify({
                text: 'Some thing went wrong, try again later',
                type: 'error',
                duration: 2000,
              })
            } finally {
              this.disabled = false
            }
          },
          disabled: this.disabled,
          hidden: this.group.isPrivate || (!this.group.isPrivate && isMeMember),
        },
      ].filter((i) => !i.hidden)
    },
    admin() {
      if (!this.group?.creatorIsAdmin && this.group?.ownersUuids?.length) {
        const admin = this.allUsers.find(
          (user) => user.id === this.group?.ownersUuids[0]
        )

        return admin.fullName
      } else {
        const user = this.group?.raw?.creator

        return user?.first_name + ' ' + user?.last_name
      }
    },
    created() {
      return formatDate('daymonthyearnumeric', this.group?.raw?.created_at)
    },
  },
}
</script>

<template>
  <details-block
    :image="group.image"
    :title="group.name"
    :description="group.description"
    :created="created"
    :admin="admin"
    :users="{
      list: group.members.hits,
      processing: usersProcessing,
    }"
    :access-type="group.isPrivate ? 'private' : 'public'"
    :actions="actions"
    @close="$emit('close')"
    @open-members="$emit('open-members')"
  />
</template>
