<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

export default {
  name: 'drawer-activity-details-item',
  components: { BaseIcon },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    detailsLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      PaymentMethodType,
    }
  },
  data() {
    return {
      statuses: {
        success: {
          class: 'iconSuccess',
          bg: 'success',
          icon: 'plain/check',
        },
        warning: {
          class: 'iconWarning',
          bg: 'white',
          icon: 'warning',
        },
        error: {
          class: 'error',
        },
      },
    }
  },
  computed: {
    status() {
      const status = this.items.find(({ title }) => title === 'Status')

      return this.statuses[status.state]
    },
  },
}
</script>

<template>
  <dl class="my-2.5 grid grid-cols-3 gap-y-2.5 gap-x-[5px] rounded-lg bg-light">
    <template v-for="({ title, value, icon, state }, index) in items">
      <template v-if="value">
        <dt
          :key="`dt${index}`"
          class="text-sm font-bold leading-6 text-eonx-neutral-600"
        >
          {{ title }}
        </dt>
        <dd :key="`dd${index}`" class="col-span-2 pl-5 font-bold">
          <span
            v-if="title === 'Payment' && Array.isArray(value)"
            class="flex items-center"
          >
            <template v-for="(pm, pmIndex) in value">
              <div
                v-if="pm.icon"
                :key="`${index}${pmIndex}`"
                :class="[
                  'mr-2.5 flex items-center',
                  pm.type === PaymentMethodType.eWallet ? 'w-6' : 'w-[35px]',
                ]"
              >
                <base-icon size="auto" :svg="pm.icon" />
              </div>
              <span :key="`${index}${pmIndex}span`">{{ pm.text }}</span>
            </template>
          </span>
          <span v-else-if="title === 'Payment'" class="flex items-center">
            <base-icon :svg="icon" size="md" class="mr-2.5" />
            {{ value }}
          </span>
          <template v-else-if="title !== 'Status'">
            {{ value }}
          </template>
          <template v-else>
            <span
              v-if="status"
              class="flex items-center whitespace-nowrap text-sm capitalize"
              :class="{
                'text-success-700': state === 'success',
                'text-warning-700': state === 'warning',
                'text-error-700': state === 'error',
              }"
            >
              <base-icon
                v-if="status.icon"
                :svg="status.icon"
                size="sm"
                class="mr-2.5 items-center justify-center rounded-full"
                color="warning"
              />
              {{ value }}
            </span>
          </template>
        </dd>
      </template>
    </template>
  </dl>
</template>
