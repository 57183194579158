<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { BatchOrderType } from '/~/types/api'
import { useBatchOrder } from '/~/composables/batch-order'
import router from '/~/router'

const { batchOrderFlowType } = useBatchOrder()

const props = withDefaults(
  defineProps<{
    batchOrderFlowType?: BatchOrderType
  }>(),
  {
    batchOrderFlowType: 'business',
  }
)

watch(
  () => props.batchOrderFlowType,
  (value) => {
    batchOrderFlowType.value = value
  }
)

onMounted(() => {
  batchOrderFlowType.value = props.batchOrderFlowType
})

router.push({ name: 'bank-file-upload' })
</script>

<template>
  <router-view />
</template>
