<script>
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import ActivitySummaryItemDelivery from '/~/components/activity/summary/activity-summary-item-delivery.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import EwProductType from '/~/components/ewallet/product/ew-product-type.vue'
import { useCms } from '/~/composables/cms'
import { useGiftRecipients } from '/~/composables/gift-recipients'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'

export default {
  name: 'activity-summary-item',
  components: {
    ActivitySummaryItemDelivery,
    BaseImage,
    EwProductType,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    prependEstoreTitleWithPrice: {
      type: Boolean,
      default: true,
    },
    activity: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isActivePurchasesLoaded, fetchActivePurchases } = usePurchases()
    const { getGiftRecipient } = useGiftRecipients()
    const { isBillPaymentsTemplate } = useProvider()
    const { ewalletLabels } = useCms()

    const isEstore = computed(() => props.item.type === 'estore')
    const isCinema = computed(() => props.item.type === 'voucher')
    const isDeliveredToEwallet = computed(() => {
      return (
        ['giftcard', 'voucher'].includes(props.item?.type) &&
        props.item?.physical === false &&
        props.item?.dispatched
      )
    })

    if (isDeliveredToEwallet.value && !isActivePurchasesLoaded.value) {
      fetchActivePurchases()
    }

    return {
      isDeliveredToEwallet,
      isActivePurchasesLoaded,
      getGiftRecipient,
      formatDollar,
      isEstore,
      isCinema,
      isBillPaymentsTemplate,
      ewalletLabels,
    }
  },
  data() {
    return {
      gift: undefined,
      loading: false,
    }
  },
  computed: {
    hasGift() {
      return this.item.gift && this.gift
    },
    image() {
      if (this.item) {
        const { retailerLogo, productImage } = this.item

        return this.cdn(productImage || retailerLogo).url()
      }
      return null
    },
    isShowImage() {
      return (
        this.image &&
        (!this.isDeliveredToEwallet || this.isActivePurchasesLoaded)
      )
    },
    title() {
      switch (this.item.type) {
        case 'dining':
          return this.item.name
        default:
          return this.item.retailerName
      }
    },
    subtitle() {
      return `${this.item.quantity} x ${this.shortName}`
    },
    price() {
      const { price, priceGst } = this.item

      if (this.isEstore) {
        return priceGst
      } else {
        return price
      }
    },
    formattedTotal() {
      return formatDollar(this.price * this.item.quantity)
    },
    shortName() {
      const { physical, value, name } = this.item
      let cardType

      if (this.isEstore) {
        if (this.prependEstoreTitleWithPrice) {
          cardType = name
        } else {
          return name
        }
      } else if (this.isCinema) {
        cardType = 'eVoucher'
      } else if (physical) {
        cardType = 'Physical Gift Card'
      } else {
        cardType = 'eGift Card'
      }

      return `${formatDollar(value)} ${cardType}`
    },
  },
  async mounted() {
    if (this.item && this.item.giftRecipientId) {
      this.loading = true
      this.gift = await this.getGiftRecipient(this.item.giftRecipientId)
      this.loading = false
    }
  },
}
</script>

<template>
  <div class="flex items-center justify-between sm:items-start">
    <div
      v-if="isShowImage"
      class="mr-5 flex h-full w-[72px] shrink-0 flex-col items-center text-center"
    >
      <div class="w-[72px]">
        <base-image
          :src="image"
          :fit="isEstore ? 'contain' : 'cover'"
          alt="offer logo"
          class="h-full w-full object-contain"
        />
      </div>
    </div>
    <div class="flex-1 overflow-hidden">
      <div class="flex flex-nowrap items-center sm:items-start">
        <div class="flex-1 space-y-[5px] overflow-x-hidden">
          <div class="truncate font-bold">
            {{ title }}
          </div>
          <div class="truncate text-sm leading-6 sm:mt-[5px]">
            {{ subtitle }}
          </div>
          <template v-if="loading">
            <div
              class="h-5 w-1/3 animate-pulse rounded-md bg-eonx-neutral-50 sm:mt-[5px]"
            />
          </template>
          <template v-else>
            <ew-product-type
              v-if="hasGift"
              class="text-eonx-neutral-600"
              :gift="item.gift"
              :gift-card="!!item.retailerName"
              :physical="item.physical"
            >
              <template #digital>
                <div>Gift to {{ gift.email }}</div>
              </template>
              <template #physical>
                <div>Gift to {{ gift.name }}</div>
              </template>
            </ew-product-type>
            <activity-summary-item-delivery
              v-if="item.physical"
              :dispatched="item.dispatched"
              :delivery-tracking-details="item.deliveryTrackingDetails"
              class="space-y-[5px]"
            />
            <ew-product-type
              v-else-if="!hasGift"
              class="text-eonx-neutral-600"
              :physical="item.physical"
            >
              <template #digital>
                Delivered to
                {{ ewalletLabels.ewallet }}
              </template>
            </ew-product-type>
          </template>
        </div>
        <div class="mt-[15px] ml-6 text-right text-eonx-neutral-600">
          {{ formattedTotal }}
        </div>
      </div>
    </div>
  </div>
</template>
