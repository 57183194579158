<script setup lang="ts">
import { pluralize } from '/~/utils/format/string'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useProvider } from '/~/composables/provider'

const props = withDefaults(
  defineProps<{
    lockDuration?: number
    temporarily?: boolean
  }>(),
  {
    lockDuration: 15,
    temporarily: false,
  }
)

const { customerServiceNumber, generalEmail } = useProvider()
</script>

<template>
  <div class="flex flex-col">
    <div class="flex items-center justify-center pb-5">
      <div class="flex flex-col items-center">
        <div
          class="flex h-20 w-20 items-center justify-center rounded-full bg-red-100 text-red-700"
        >
          <base-icon svg="heroicons/solid/lock-closed" :size="38" />
        </div>

        <span class="mt-4 text-2xl font-bold text-eonx-neutral-800">
          Account {{ temporarily ? 'Temporarily' : '' }} Locked
        </span>
      </div>
    </div>

    <div class="mb-12 text-center">
      <div class="text-eonx-neutral-600">
        Too many
        {{ temporarily ? 'code resend' : 'unsuccessful' }}
        attempts
      </div>
    </div>

    <div class="text-eonx-neutral-600">
      <template v-if="temporarily">
        <p class="mb-[22px]">
          Your account has been temporarily locked for security purposes. Please
          wait for the lockout period to end before attempting to log in again.
          This lockout period will last for
          {{ props.lockDuration }}
          {{ pluralize(props.lockDuration, 'minute') }} from the time of the
          last code resent attempt.
        </p>
        <p class="mb-[22px]">
          If you require immediate access to your account, please contact our
          support team at
          <base-link :href="`tel:${customerServiceNumber}`">
            {{ customerServiceNumber }}
          </base-link>
          or
          <base-link :href="`mailto:${generalEmail}`">
            {{ generalEmail }}
          </base-link>
          for assistance.
        </p>
        <p>
          We apologise for any inconvenience this may have caused. We take the
          security of your account seriously, and locking your account after too
          many code resend attempts is one of the measures we take to keep your
          account safe.
        </p>
      </template>
      <template v-else>
        <p class="mb-[22px]">
          For security purposes, your account has been locked after three
          unsuccessful verification attempts.
        </p>
        <p class="mb-[22px]">
          Please contact our Support Team on
          <base-link :href="`tel:${customerServiceNumber}`">
            {{ customerServiceNumber }}
          </base-link>
          or
          <base-link :href="`mailto:${generalEmail}`">
            {{ generalEmail }}
          </base-link>
          to have your account unlocked.
        </p>
        <p class="mb-[22px]">
          Please note, our Support Team operates Mon-Fri, 9am to 5pm AEST.
        </p>
        <p>
          If you contact our team out of hours, they will respond on the next
          business day.
        </p>
      </template>
    </div>
  </div>
</template>
