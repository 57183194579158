<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { Activity } from '/~/composables/activity'
import { useAustraliapostActivityListItem } from '/~/templates/australiapost/composables/activity'

const props = defineProps<{ item: Activity }>()

const {
  canSeeDetails,
  formattedAmountInPoints,
  icon,
  iconColor,
  isPointsOnlyPurchase,
  pointsTransactions,
  subtitle,
  title,
  typeIcon,
  showPoints,
} = useAustraliapostActivityListItem(props)
</script>

<template>
  <base-link
    :to="{ name: 'activity-details', params: { id: item.id } }"
    :active="canSeeDetails"
    class="block w-full"
    :class="{
      'text-neutral-400': !canSeeDetails,
    }"
  >
    <div class="flex items-center justify-evenly space-x-[15px]">
      <div
        class="relative flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white"
        :class="{
          'bg-primary':
            item.isTypePointsTransaction || item.isTypeMigratedPoints,
          'bg-red-700': item.isTypeCampaign,
          'bg-white text-red-700': item.isTypeMigratedOrder,
          'bg-gray-500':
            !item.isTypePointsTransaction &&
            !item.isTypeCampaign &&
            !item.isTypeMigratedOrder &&
            !item.isTypeMigratedPoints,
        }"
      >
        <base-icon
          :svg="typeIcon.svg"
          :size="typeIcon.size"
          :class="typeIcon.classes"
        />
        <div
          class="absolute flex h-5 w-5 rounded-full bg-white"
          :class="iconColor"
          :style="{
            right: '-10px',
            bottom: '-2px',
          }"
        >
          <base-icon size="auto" :svg="icon" />
        </div>
      </div>

      <div class="w-11/12 min-w-0 shrink" data-testid="title-column">
        <p class="font-bold text-eonx-neutral-800">
          {{ title }}
        </p>
        <div v-if="item.isTypePointsTransferRequest">
          <span class="text-sm font-semibold" :class="[item.statusLabel.color]">
            {{ item.statusLabel.text }}
          </span>
        </div>

        <div v-else class="text-sm text-eonx-neutral-600">
          {{ subtitle }}
        </div>
      </div>

      <div
        v-if="showPoints && !item.isTypePointsTransferRequest"
        class="flex-1 shrink-0 whitespace-nowrap text-right font-bold text-eonx-neutral-800"
      >
        <template v-if="isPointsOnlyPurchase">
          {{ formattedAmountInPoints }}
        </template>
        <template v-else>
          <span
            v-for="(pointsTransaction, key) in pointsTransactions"
            :key="key"
          >
            {{ pointsTransaction }}
          </span>
        </template>
      </div>
    </div>
  </base-link>
</template>
