<script>
import ui from '/~/core/ui'
import CheckoutAddressDesktop from './checkout-address.desktop.vue'
import CheckoutAddressMobile from './checkout-address.mobile.vue'

export default {
  name: 'checkout-confirmation',
  functional: true,
  render(h, ctx) {
    let component

    if (ui.mobile) {
      component = CheckoutAddressMobile
    } else {
      component = CheckoutAddressDesktop
    }

    return h(component, ctx.data, ctx.children)
  },
}
</script>
