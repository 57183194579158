<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-details-mobile-wrapper',
  components: {
    BaseIcon,
    BaseLoader,
    BaseButton,
    HeaderMobile,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    award: {
      type: Object,
      default: () => ({}),
    },
    entityName: {
      type: String,
      required: true,
    },
    titleMessage: {
      type: String,
      default: 'Message',
    },
    altText: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { features } = useRecProfile()

    return { features }
  },
  data() {
    return {
      isOpen: false,
      loadingImage: true,
      loadingError: false,
    }
  },
  computed: {
    isNominalShow() {
      return (
        this.features.points &&
        (this.award.nominal ||
          (this.award.nominal !== 0 && this.isAward) ||
          this.award.prize ||
          this.award.cost)
      )
    },
    isAward() {
      return this.altText === 'award'
    },
    allLoad() {
      return !this.loading || !this.loadingImage || this.loadingError
    },
    getTitle() {
      return this.isOpen && this.isAward
        ? this.award.title || this.award.name
        : ''
    },
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    },
    onImageLoaded() {
      this.loadingImage = false
    },
    onImageError() {
      this.loadingError = true
    },
  },
}
</script>

<template>
  <div class="w-full">
    <transition-group
      :id="entityName"
      :name="entityName"
      tag="aside"
      class="flex h-full flex-col overflow-y-auto"
    >
      <div :key="entityName" class="min-h-full w-full overflow-hidden">
        <div class="bg-white">
          <div>
            <div
              class="absolute inset-0 transition-all duration-300 ease-in"
              :class="{
                'bottom-auto h-0': isOpen && isAward,
                'bottom-auto h-64': isOpen && !isAward,
                'h-full': !isOpen,
              }"
            >
              <div
                v-if="!allLoad"
                class="absolute top-1/2 left-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform bg-transparent"
              >
                <base-loader class="absolute inset-0" fullwidth />
              </div>
              <img
                v-show="allLoad"
                :src="award.image || award.logo"
                :alt="altText"
                :style="{ filter: 'brightness(0.9)' }"
                class="h-full w-full object-cover"
                @load="onImageLoaded"
                @error="onImageError"
              />
            </div>

            <div :class="['flex flex-col', !isOpen && 'absolute inset-0']">
              <header-mobile
                :title="getTitle"
                class="z-1"
                :class="{
                  'h-full max-h-full': !isOpen,
                  'h-64 max-h-64': isOpen && !isAward,
                }"
                @back="$emit('close')"
              />
              <div
                class="relative w-full shrink-0 transition-all duration-300 ease-in"
                :class="{
                  'flex-1 overflow-y-auto bg-white': isOpen,
                  'text-white': !isOpen,
                }"
              >
                <div
                  v-show="allLoad"
                  class="relative flex h-full grow flex-col px-5"
                >
                  <div
                    v-if="!isOpen || !isAward"
                    class="flex items-center pt-5"
                    :class="{
                      'pb-6 xxs:pb-2.5': isAward,
                      'pb-2.5': !isAward,
                    }"
                  >
                    <h2
                      class="w-full break-words text-2xl font-bold leading-6"
                      :class="{
                        'text-center': isOpen,
                      }"
                    >
                      {{ award && (award.title || award.name) }}
                    </h2>
                  </div>
                  <slot name="badge" />
                  <div
                    v-if="isNominalShow"
                    class="relative mb-[15px] w-full rounded-lg bg-current py-[5px] pr-[15px] text-amber-400 sm:w-[352px]"
                  >
                    <div
                      class="flex min-h-5 items-center justify-center text-white"
                    >
                      <base-icon
                        class="mx-2.5 block"
                        svg="rec/token"
                        size="sm"
                      />
                      <slot name="nominalText" />
                    </div>
                  </div>
                  <slot :is-open="isOpen" name="nominations" />
                  <div class="flex h-full grow flex-col">
                    <h3 class="mb-2.5 text-base">
                      {{ titleMessage }}
                    </h3>
                    <p
                      class="overflow-hidden text-justify text-base"
                      :class="{
                        'break-words text-eonx-neutral-600': isOpen,
                        'text-gray-100 line-clamp-3': !isOpen,
                      }"
                    >
                      {{ award.message || award.description }}
                    </p>
                    <div v-show="isOpen">
                      <slot name="avatar" />
                    </div>
                    <slot v-if="isOpen" name="actions" />
                    <slot v-if="isOpen" name="footer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="allLoad && !isOpen"
            class="absolute bottom-0 h-20 w-full text-neutral-400"
            :style="{
              background:
                'linear-gradient(360deg, #6b6969 0%, rgba(75, 75, 75, 0) 100%)',
            }"
          >
            <base-button
              icon="rec/chevron/down"
              size="sme"
              class="flex h-full w-full items-center justify-center text-white"
              @click="toggleOpen"
            />
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>
