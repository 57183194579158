<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'drawer-deletable-item',
  components: {
    BaseIcon,
  },
  props: {
    text: {
      type: String,
      default: 'Are you sure?',
    },
    deleting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggled: false,
    }
  },
  computed: {
    transformStyle() {
      if (this.toggled) {
        return { transform: 'translateX(-100%)' }
      }

      return {}
    },
  },
  watch: {
    isToggled(flag) {
      this.toggled = flag
    },
    toggled(flag) {
      this.$emit('update:is-toggled', flag)
    },
  },
  methods: {
    toggleDelete() {
      this.toggled = true
    },
  },
}
</script>

<template>
  <div
    class="relative z-1 flex select-none items-stretch justify-start overflow-hidden"
    :class="[deleting && 'animate-pulse']"
  >
    <div
      :style="transformStyle"
      class="w-full shrink-0 transition duration-100"
    >
      <slot :toggle-delete="toggleDelete" />
    </div>

    <div
      :style="transformStyle"
      class="flex w-full shrink-0 items-center justify-between bg-error-700 px-5 font-bold text-white transition duration-100"
      @click.stop
    >
      <span>{{ text }}</span>

      <div class="flex items-center justify-center space-x-2.5">
        <div
          class="flex h-[35px] w-[35px] items-center justify-center rounded-full bg-white text-error-700"
          @click="toggled = false"
        >
          <base-icon svg="plain/no" size="sm" />
        </div>
        <div
          class="flex h-[35px] w-[35px] items-center justify-center rounded-full bg-white text-success-700"
          @click="$emit('delete-confirmed')"
        >
          <base-icon svg="plain/yes" size="sm" />
        </div>
      </div>
    </div>
  </div>
</template>
