<script>
import DefaultEstoreTile from '/~/extensions/estore/components/estore-tile.vue'
import EstoreTilePrice from './tile/estore-tile-price.vue'

export default {
  components: {
    EstoreTilePrice,
  },
  extends: DefaultEstoreTile,
}
</script>
