<script>
import DownloadableEntry from './entry/downloadable-post-entry.vue'

export default {
  name: 'rec-comment-downloadable',
  components: {
    DownloadableEntry,
  },
  props: {
    attachments: {
      type: Object,
      required: true,
    },
    contentColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      previewCount: 3,
      showHidden: false,
    }
  },
  computed: {
    visibleAttachments() {
      if (this.showHidden) return this.attachments.downloadableAttachments
      return this.attachments.downloadableAttachments.slice(
        0,
        this.previewCount
      )
    },
    hiddenCount() {
      const count = this.attachments.downloadableAttachmentsCount

      return count > this.previewCount ? count - this.previewCount : 0
    },
  },
}
</script>

<template>
  <div>
    <downloadable-entry
      v-for="attachment in visibleAttachments"
      :key="attachment.id"
      class="bg-dark py-2.5 first:rounded-t-lg last:rounded-b-lg"
      :attachment="attachment"
      :content-color="contentColor"
    />

    <span
      v-if="hiddenCount > 0"
      class="inline-block cursor-pointer text-sm font-bold text-primary"
      @click="showHidden = !showHidden"
    >
      <span>
        {{ showHidden ? 'Show less' : `Show more (${hiddenCount})` }}
      </span>
    </span>
  </div>
</template>
