<script>
import DefaultEstoreCatalog from '/~/extensions/estore/templates/default/views/estore-catalog.vue'
import EstoreTile from '../components/estore-tile.vue'

export default {
  name: 'estore-catalog',
  components: {
    EstoreTile,
  },
  extends: DefaultEstoreCatalog,
}
</script>

<template>
  <ew-catalog-processor
    :processor="algolia"
    :columns="{
      xxs: 2,
      sm: 3,
      lg: 4,
    }"
    class="px-5 sm:px-0"
  >
    <template #empty>
      <p>No Result found</p>
      <p>Please try another category</p>
    </template>

    <template #tile="{ tile }">
      <estore-tile
        :tile="tile"
        :thumb-width="300"
        :thumb-height="240"
        :loading="false"
      />
    </template>
  </ew-catalog-processor>
</template>
