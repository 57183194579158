<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import AwardNominee from '/~rec/views/recognition/awards/award-nominee.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'

export default {
  name: 'award-nominee-mobile',
  components: {
    BaseMdl,
    AwardNominee,
    HeaderMobile,
  },
  props: {
    awardStatus: {
      type: String,
      default: '',
    },
    awardId: {
      type: String,
      default: '',
    },
    nomineeId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    currentAwardId: {
      type: String,
      default: '',
    },
  },
  methods: {
    back() {
      modal.hide()
      this.$router.push({
        name: 'rec-award',
        params: {
          awardStatus: this.awardStatus,
          awardId: this.awardId,
        },
      })
    },
  },
}
</script>

<template>
  <base-mdl transition="slide-right" fullscreen width="screen" :layout="false">
    <header-mobile :title="title" @back="back" />
    <award-nominee
      :award-status="awardStatus"
      :award-id="awardId"
      :nominee-id="nomineeId"
      :current-award-id="currentAwardId"
      class="p-5 pt-0"
    />
  </base-mdl>
</template>
