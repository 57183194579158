<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import PointsTransferSuccessfulDesktop from './points-transfer-successful.desktop.vue'

export default {
  name: 'checkout-mobile',
  components: {
    BaseAsidePage,
  },
  extends: PointsTransferSuccessfulDesktop,
  setup() {
    return {
      ...PointsTransferSuccessfulDesktop.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <base-aside-page :title="title" shadow no-padding>
    <div
      class="min-h-full w-full grow rounded-b-3xl rounded-t-3xl sm:rounded-b-none"
    >
      <div
        v-if="transfer"
        class="mx-auto flex h-full min-h-full w-full flex-col px-5 sm:w-sm sm:rounded-t-3xl"
      >
        <div class="text-eonx-neutral-800">
          <div class="mx-auto mt-6 h-[88px] w-[88px]" :class="iconColor">
            <base-icon :svg="icon" />
          </div>
          <div class="mt-5 space-y-1 text-center text-sm font-bold leading-5">
            <div>Transaction No. #{{ transfer.number }}</div>
            <div>
              {{ formattedDate }}
            </div>
          </div>
          <div
            v-if="!statusFailed"
            class="mt-4 leading-6 text-eonx-neutral-600"
          >
            {{ selectedPartner.programCurrency }} will normally take 24 hours to
            appear in your {{ selectedPartner.name }} account but may take up to
            14 days.
          </div>
        </div>

        <h3 class="mt-10 text-base font-bold text-eonx-neutral-800">
          Member details
        </h3>

        <div class="mt-4 mb-5 flex h-12 items-center space-x-4">
          <div class="w-12">
            <div class="flex w-12 items-center justify-center">
              <base-icon :svg="partnerIcon" />
            </div>
          </div>
          <div class="ml-4 flex flex-col justify-between">
            <span class="font-bold text-eonx-neutral-800">
              {{ `${partner.name} ${partner.type}` }}
            </span>
            <span class="text-sm text-eonx-neutral-600">
              {{ selectedPartner.accountNumberLabel }}
              {{ formatBusinessNumber(partner.accountNumber) }}
            </span>
          </div>
        </div>

        <div class="border-t pt-5 pb-32">
          <h3 class="text-base font-bold text-eonx-neutral-800">
            Transfer amounts
          </h3>
          <div class="mt-4 flex h-12 items-center justify-between">
            <div class="flex w-12 items-center justify-center">
              <base-icon class="h-10 w-10 text-primary" svg="points/token" />
            </div>
            <div class="ml-4 grow font-bold text-eonx-neutral-800">
              {{ providerTitle }} Points
            </div>
            <div class="text-sm text-eonx-neutral-600">
              -{{ formatNumber(pointsSpent) }}
            </div>
          </div>
          <div class="mt-4 flex h-12 items-center justify-between">
            <div class="flex h-full w-12 items-center justify-center">
              <base-image
                :svg="partnerIcon"
                class="h-12 w-12 text-orange-700"
                :ratio="false"
              />
            </div>
            <div class="ml-4 grow font-bold text-eonx-neutral-800">
              {{ selectedPartner.programCurrency }}
            </div>
            <div class="text-sm text-eonx-neutral-600">
              +{{ formatNumber(pointsReceived) }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="mx-auto flex h-full min-h-full w-full flex-col bg-white px-10 py-[30px] sm:w-sm sm:rounded-t-3xl"
      >
        <base-loader class="mx-auto" />
      </div>
    </div>
    <div class="fixed bottom-0 w-full border-t bg-white p-5 shadow-md">
      <div class="flex w-full space-x-[10px]">
        <base-button
          data-testid="btn-make-transfer"
          class="flex-1"
          look="outlined-color"
          @click="$router.push({ name: 'fly' })"
        >
          Make another transfer
        </base-button>

        <base-button class="flex-1" @click="onLeave">Done</base-button>
      </div>
    </div>
  </base-aside-page>
</template>
