import { ref } from 'vue'
import api from '/~/core/api'
import modal from '/~/core/mdl'
import { useUser } from '/~/composables/user'

const claims = ref([])
const map = ref({})
const animation = ref(false)

function fetchReservations() {
  return api
    .get('/v3/qr-connect/claims?state=confirmed&order[startsAt]=ASC')
    .then((response) => {
      claims.value = response?.items ?? []
      return response
    })
    .catch((error) => [error])
}

function cancelClaim(payload) {
  return api.post('/v3/qr-connect/claims/cancel', payload)
}

async function scanQrCode(payload) {
  return api
    .post('/v3/qr-connect/qr_codes/scan', payload, { notify: false })
    .then((data) => [null, data])
    .catch((error) => [error])
}

function serachAvailableBusinessAssets(payload) {
  return api.post('/v3/qr-connect/search_available_assets', payload)
}

function searchAvailableBusinessAssetsWithPagination({
  payload,
  perPage = 100,
  page = 1,
}) {
  return api.post(
    `/v3/qr-connect/search_available_assets?perPage=${perPage}&page=${page}`,
    payload
  )
}

async function processQRCode({
  qrCode,
  userExternalId,
  onComplete = () => ({}),
}) {
  const { user } = useUser()

  const [error, response] = await scanQrCode({
    qrCode,
    userExternalId: userExternalId || user.value.externalId,
  })

  const callback = (error, response) => {
    if (!error) {
      modal.show(
        response.success ? 'qr-ws-confirmed' : 'qr-workspace-unavailable',
        {
          props: {
            title: response.title,
            message: response.message,
            onHide: () => onComplete(response.success),
          },
        }
      )
    }
  }

  if (
    !error &&
    !response.success &&
    response.extra &&
    response.extra.endsAtRequired
  ) {
    modal.show('qr-code-scan-dates', {
      props: {
        code: qrCode,
        callback,
        ...response.extra,
      },
    })
  } else {
    callback(error, response)
  }

  return [error, response]
}

export function useQrConnect() {
  return {
    animation,
    claims,
    map,

    processQRCode,
    fetchReservations,
    cancelClaim,
    scanQrCode,
    serachAvailableBusinessAssets,
    searchAvailableBusinessAssetsWithPagination,
  }
}
