<script setup lang="ts">
import ServicesBrands from '/~/extensions/services/components/services-brands.vue'
import ServicesSelect from '/~/extensions/services/components/services-select.vue'
import { useEditablePage } from '/~/composables/cms'
import LayoutRounded from '/~/layouts/rounded/layout-rounded.vue'

const { page } = useEditablePage()
</script>

<template>
  <layout-rounded>
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
    <div
      v-else
      class="relative flex h-40 items-center overflow-hidden bg-gradient-to-r from-white md:h-80"
    >
      <div
        class="relative z-10 mx-auto w-lg max-w-full px-5 text-xl sm:px-10 md:text-3xl"
      >
        Sit back, relax and save as we compare
        <br />
        and
        <b>connect the right deal for you</b>
      </div>
      <img
        class="absolute top-0 right-0 z-1 h-full w-full object-cover object-right"
        src="https://i.ewalletcdn.com/c7bc3b5e-7dd4-428b-bf1c-0d745cd8dc26/"
      />
    </div>
    <div class="px-5">
      <services-select />
      <div class="my-10 text-center text-2xl font-light">
        Proudly partnering with Australia's leading service providers.
      </div>
      <services-brands class="my-11 md:my-20" />
    </div>
    <div class="my-11 flex flex-col bg-light md:my-20 lg:flex-row">
      <div
        class="mx-auto min-h-56 w-full bg-cover bg-center bg-no-repeat lg:w-1/2"
        style="
          background-image: url('https://i.ewalletcdn.com/77638040-f22c-4fc3-8601-cb5aaa3a951a/');
        "
      />

      <div class="lg:w-1/2">
        <div class="p-5 sm:p-11">
          <h2 class="mb-[30px] text-3xl font-light leading-tight md:text-4xl">
            <span>Save time & money for</span>
            <span class="font-bold">what really matters</span>
          </h2>
          <img
            class="h-10 w-10"
            src="https://i.ewalletcdn.com/797a1330-09f0-4164-abe9-c380f946c899/"
          />
          <div class="my-5 italic">
            I couldn’t believe how easy it was - from my initial query online to
            somebody getting back to me within 10 minutes! I have been using the
            same energy provider for years and thought it was all too hard to
            shop around for a better deal.
          </div>
          <div class="text-eonx-neutral-600">Amanda | Mentone, VIC</div>
        </div>
      </div>
    </div>
    <div
      class="mx-auto grid w-lg max-w-full gap-[30px] px-5 text-center sm:px-[30px] md:my-11 md:grid-cols-3"
    >
      <div class="py-5">
        <div
          class="mx-auto h-[120px] w-[120px] bg-cover bg-center bg-no-repeat"
          style="
            background-image: url('https://i.ewalletcdn.com/81f9dd57-39aa-428b-a0e4-d994bede9124/');
          "
        />
        <div class="my-5 text-xl font-bold">We make it easy</div>
        <div>
          We compare the deals and offers without you needing to sift through
          the small print on multiple policies.
        </div>
      </div>
      <div class="py-5">
        <div
          class="mx-auto h-[120px] w-[120px] bg-cover bg-center bg-no-repeat"
          style="
            background-image: url('https://i.ewalletcdn.com/5fbd8d6a-1948-4f33-8352-7b9ce4b471e7/');
          "
        />
        <div class="my-5 text-xl font-bold">No surprising fees</div>
        <div>
          We don’t charge you anything as companies pay us a commission when we
          successfully connect you to a deal.
        </div>
      </div>
      <div class="py-5">
        <div
          class="mx-auto h-[120px] w-[120px] bg-cover bg-center bg-no-repeat"
          style="
            background-image: url('https://i.ewalletcdn.com/5401be40-0eb4-48e3-8074-641abc24f159/');
          "
        />
        <div class="my-5 text-xl font-bold">Specialist advice</div>
        <div>
          We pride ourselves on delivering personalised service with expert
          industry and local knowledge.
        </div>
      </div>
    </div>
    <div
      class="mx-auto my-20 w-md max-w-full px-5 text-center text-sm sm:px-[30px]"
    >
      * Please be advised that we do not work in conjunction with every product
      provider in the market, and the offers compared on this site are not
      representative of all offers available in the market.
    </div>
  </layout-rounded>
</template>
