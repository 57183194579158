<script setup lang="ts">
import { useProvider } from '/~/composables/provider'
const { isBillPaymentsV15Template } = useProvider()
</script>

<template>
  <div
    class="h-full sm:bg-eonx-neutral-50"
    :class="{
      'sm:mr-6 sm:rounded-t-3xl': !isBillPaymentsV15Template,
    }"
  >
    <slot />
  </div>
</template>
