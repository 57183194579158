<script setup lang="ts">
import { ValidationProvider } from 'vee-validate'
import { ref, computed, watch } from 'vue'
import DatePicker from 'vue2-datepicker'
import { DateType } from '/~/types/date'
import ui from '/~/core/ui'
import { formatDate, createDate } from '/~/utils/format/date'
import BaseField from '/~/components/base/field/base-field.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useInput } from '/~/composables/base/use-input'
import { useLocalization } from '/~/composables/localization'
import './datepicker-default.css'

const props = withDefaults(
  defineProps<{
    value?: Date | string | Array<Date | string>
    valueType?: string
    placeholder?: string
    format?: DateType
    disabledDates?: { to?: Date | string; from?: Date | string }
    range?: boolean
    rangeSeparator?: string
    confirm?: boolean
    appendToBody?: boolean
    look?: string
    entryClass?: string
    popupStyle?: object
    icon?: string
    iconClass?: string
    iconPosition?: 'left' | 'right'
    isFullwidth?: boolean
    minDateMobile?: string
    maxDateMobile?: string
    editable?: boolean
    disabled?: boolean
    label?: string
    required?: boolean
    nolabel?: boolean
    error?: string
    floated?: boolean
    theme?: string
    validation?: object
    positionRelative?: boolean
  }>(),
  {
    value: '',
    valueType: 'format',
    placeholder: 'Enter Date',
    format: 'daymonthyear',
    disabledDates: () => ({}),
    range: false,
    rangeSeparator: '~',
    confirm: false,
    appendToBody: true,
    look: '',
    entryClass: 'overflow-hidden rounded-lg h-10',
    popupStyle: () => ({}),
    icon: 'rec/calendar-linear',
    iconClass: 'text-eonx-neutral-800 bg-default max-w-5',
    iconPosition: 'left',
    isFullwidth: false,
    minDateMobile: '',
    maxDateMobile: '',
    editable: false,
    disabled: false,
    positionRelative: false,
  }
)

const emit = defineEmits<{
  (e: 'input', value: Date | string | Array<Date | string>): void
}>()

const localization = useLocalization()

const { validationProviderRef, isFocused, onInput } = useInput(
  props as any,
  emit as any
)

const date = ref(props.value)
const target = ref<HTMLDivElement>()
const datepicker = ref<InstanceType<any>>()

const nativeDate = computed({
  get: () => formatDate('daymonthyearnumeric', date.value as any),
  set: (value) => {
    date.value = formatDate(props.format, value) as any
  },
})

const popupStyles = computed(() => {
  if (props.positionRelative) {
    return {
      position: 'relative',
      left: 0,
      top: '100%',
    }
  } else {
    return props.popupStyle
  }
})

watch(
  () => props.value,
  (val) => {
    date.value = val
  },
  { deep: true }
)

const disabledDatesForPicker = (date: Date) => {
  if (props.disabledDates.to && !props.disabledDates.from) {
    return createDate(props.disabledDates.to)
      .startOf('day')
      .isSameOrAfter(createDate(date).endOf('day'))
  }
  if (!props.disabledDates.to && props.disabledDates.from) {
    return createDate(props.disabledDates.from)
      .startOf('day')
      .isSameOrBefore(createDate(date).startOf('day'))
  }
  if (props.disabledDates.to && props.disabledDates.from) {
    return (
      createDate(props.disabledDates.to)
        .startOf('day')
        .isSameOrAfter(createDate(date).endOf('day')) ||
      createDate(props.disabledDates.from)
        .startOf('day')
        .isSameOrBefore(createDate(date).startOf('day'))
    )
  }
  return false
}

const onOpen = () => {
  if (props.positionRelative) {
    const el = datepicker.value?.$refs?.popup.$el

    target.value?.appendChild(el)
  }
  isFocused.value = true
}
</script>

<template>
  <validation-provider
    v-slot="{ errors }"
    v-bind="validation"
    ref="validationProviderRef"
    :detect-input="false"
    slim
  >
    <base-field
      :label="label"
      :required="required"
      :focused="isFocused"
      :expanded="isFocused || !!value || !!placeholder"
      :nolabel="nolabel"
      :error="errors[0] || error"
      :disabled="disabled"
      :floated="floated"
      :border-floated="look === 'border-floated'"
      class="datepicker m-0"
      :class="{
        'icon-left': iconPosition === 'left',
        'icon-right': iconPosition === 'right',
        'datepicker-fullwidth': isFullwidth,
        'bill-payments': theme === 'bill-payments',
        '!mb-0': positionRelative,
      }"
      :theme="theme"
      :entry-class="entryClass"
      data-testid="datepicker-default"
    >
      <input
        v-if="ui.mobile"
        v-model="nativeDate"
        class="absolute inset-0 z-10 h-full w-full opacity-0"
        type="date"
        data-test="native-trigger"
        :min="minDateMobile"
        :max="maxDateMobile"
        @change="$emit('input', date)"
      />
      <date-picker
        ref="datepicker"
        v-model="date"
        :placeholder="placeholder"
        :clearable="false"
        :first-day-of-week="1"
        :value-type="valueType"
        :format="localization.getDatePickerFormat(format)"
        :range="range"
        :range-separator="rangeSeparator"
        :confirm="confirm"
        :popup-style="popupStyles"
        :append-to-body="appendToBody"
        lang="en"
        :editable="editable"
        :disabled-date="disabledDatesForPicker"
        :disabled="disabled"
        @input="onInput"
        @change="isFocused = false"
        @blur="isFocused = false"
        @open="onOpen"
        @close="isFocused = false"
      >
        <template #icon-calendar>
          <div class="w-6">
            <base-icon :svg="icon" size="md" :class="iconClass" />
          </div>
        </template>
      </date-picker>
    </base-field>
    <div
      v-if="positionRelative"
      ref="target"
      :style="{ position: 'relative' }"
      class="datepicker-relative"
    />
  </validation-provider>
</template>
