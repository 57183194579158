<script>
import DefaultCartItem from '/~/components/cart-menu/cart-item.vue'
import EwProductType from '/~/templates/australiapost/components/ewallet/product/ew-product-type.vue'

export default {
  components: {
    EwProductType,
  },
  extends: DefaultCartItem,
  props: {
    showDelivery: {
      type: Boolean,
      default: false,
    },
    prependEstoreTitleWithPrice: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    total() {
      return this.price * this.item.quantity + this.item.subShippingFee
    },
  },
}
</script>
