<script>
import PasswordConfirmationForm from '/~/components/drawer/views/personal-details/views/edit-profile/password-confirmation-form.vue'

export default {
  name: 'password-confirmation-bottom-sheet',
  components: {
    PasswordConfirmationForm,
  },
}
</script>

<template>
  <password-confirmation-form
    @submit="$emit('submit', $event)"
    @hide="$emit('hide')"
  />
</template>
