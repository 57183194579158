<script>
import { computed } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { pluralize } from '/~/utils/format/string'
import { formatDate } from '/~/utils/format/date'
import RecognitionTileBox from '/~rec/components/recognition/recognition-tile-box.vue'

export default {
  name: 'rec-hi5-tile',
  components: {
    BaseIcon,
    RecognitionTileBox,
  },
  props: {
    hi: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
      validator: (v) => /available|sent|received/.test(v),
    },
    compactView: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'gold',
    },
  },
  setup(props) {
    const fullName = computed(() => {
      if (props.type !== 'available') {
        return props.type === 'sent'
          ? props.hi.recipientFullName
          : props.hi.senderFullName
      }
      return ''
    })

    const date = computed(() =>
      formatDate('daymonthyeartime', props.hi.createdAt)
    )

    return {
      pluralize,
      date,
      fullName,
    }
  },
}
</script>

<template>
  <recognition-tile-box
    :nominal="hi.nominal ? hi.nominal : null"
    title="Review this Hi-5"
    color="gold"
    :image="hi.image"
    alt-text="Hi-5"
    v-on="$listeners"
  >
    <div
      class="w-full truncate bg-white py-2.5"
      :class="{
        'flex flex-wrap items-center px-2.5 text-left': compactView,
        'px-2.5 sm:px-5': !compactView,
        'mb-[5px]': !fullName,
      }"
    >
      <h3
        v-if="hi.title"
        class="w-full shrink grow truncate text-base text-eonx-neutral-800"
      >
        {{ hi.title }}
      </h3>
      <div
        v-if="date"
        class="mt-[5px] flex items-start truncate font-normal text-eonx-neutral-600 md:font-bold"
      >
        <base-icon
          svg="rec/calendar-filled"
          size="xxs"
          class="mr-2.5 mt-[5px] shrink-0"
        />
        <span class="truncate text-sm md:text-base">
          {{ date }}
        </span>
      </div>
      <div
        v-if="fullName"
        class="mt-[5px] mb-[5px] flex w-full items-center truncate font-normal text-eonx-neutral-600 md:font-bold"
        :class="{
          'ml-2.5': compactView,
        }"
      >
        <base-icon
          svg="rec/paper-airplane"
          size="xxs"
          class="mr-2.5 shrink-0"
        />
        <span class="truncate leading-4 xxs:text-sm md:text-base">
          {{ fullName }}
        </span>
      </div>
    </div>
  </recognition-tile-box>
</template>
