<script setup lang="ts">
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { onUnmounted } from 'vue'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import { StatementAccount } from '/~/composables/statements'
import { useEditAccount } from './edit-account'

const props = defineProps<{ account: StatementAccount }>()
const emit = defineEmits<{
  (event: 'save' | 'hide'): void
}>()

const {
  accountForm,
  isProcessing,
  accountNameRef,
  accountName,
  metafieldsSchema,
  backendErrors,
  form,
  onCancel,
  onSave,
  onClear,
} = useEditAccount(props, emit)

onUnmounted(() => {
  emitter.off('metafields:validate')
})
</script>
<template>
  <div>
    <div class="relative border-b px-5 py-[15px] text-center">
      <h2 class="text-center text-xl text-eonx-neutral-800">
        Edit Account Name
      </h2>
      <span class="absolute top-0 right-[5px] p-[15px]">
        <base-button
          icon="heroicons/outline/x-mark"
          :size="32"
          @click="onCancel()"
        />
      </span>
    </div>

    <validation-observer
      ref="accountForm"
      v-slot="{ valid }"
      tag="div"
      class="px-8 pb-6"
    >
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="Account Name"
        mode="eager"
      >
        <base-input
          ref="accountNameRef"
          v-model="accountName"
          label="Account name"
          required
          :error="errors[0]"
          autofocus
          class="mt-12"
          entry-class="rounded !h-11 mt-1"
          label-class="!leading-normal !p-0"
        >
          <template slot="inner-filter">
            <div class="mx-3 px-4">
              <base-button size="sm" look="link" @click="onClear">
                <span class="text-sm">Clear</span>
              </base-button>
            </div>
          </template>
        </base-input>
      </validation-provider>
      <base-metafield
        v-model="form"
        :schema="metafieldsSchema"
        :backend-errors="backendErrors"
        class="mt-6"
      />

      <div class="flex space-x-2.5 border-t pt-5">
        <button
          class="w-full rounded-md border-2 border-primary p-2.5 font-bold text-primary hover:bg-primary-lighten"
          @click="onCancel"
        >
          Cancel
        </button>
        <button
          class="w-full rounded-md border-2 border-primary bg-primary p-2.5 font-bold text-white filter hover:contrast-50 disabled:pointer-events-none disabled:border-neutral-300 disabled:bg-eonx-neutral-300"
          :disabled="!valid || isProcessing"
          @click="onSave"
        >
          <span v-if="isProcessing">Processing...</span>
          <span v-else>Save</span>
        </button>
      </div>
    </validation-observer>
  </div>
</template>
