<script>
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import ui from '/~/core/ui'
import GroupHeaderMobile from '/~rec/components/group/group-header-mobile.vue'
import GroupFeed from '/~rec/components/group/group-feed.vue'
import PostCreatorFab from '/~rec/components/post/creator/post-creator-fab.vue'
import { RecroomGroup } from '/~rec/core/group'
import { formatDate } from '/~/utils/format/date'

/*
 * Group details for the mobile view
 */
export default {
  name: 'rec-group-details-modal',
  components: {
    GroupHeaderMobile,
    PostCreatorFab,
    BaseLoader,
    GroupFeed,
    BaseMdl,
  },
  props: {
    groupId: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  data() {
    return {
      colorBgGray50: {
        fill: 'var(--color-gray-50)',
      },
      group: null,
    }
  },
  computed: {
    createdDate() {
      return formatDate('daymonthyearnumeric', this.group?.raw?.created_at)
    },
    admin() {
      const user = this.group?.raw?.creator

      return user?.first_name + ' ' + user?.last_name
    },
  },
  async created() {
    const group =
      this.$route?.params.initialGroup ||
      (await RecroomGroup.fetchById(this.groupId))

    await group?.members?.refresh()
    await group?.posts?.refresh()

    this.group = group
  },
  methods: {
    onPostCreated() {
      this.group.posts.getData({ force: false })
    },
    onBack() {
      this.$router.push({ name: 'rec-groups' })
    },
    onNewPostsLoaded() {
      const content = document.getElementById('rec-group-mobile')

      content.scrollIntoView({
        block: 'start',
      })
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" transition="slide-right" width="screen" fullscreen>
    <div id="rec-group-mobile" class="flex h-full flex-col">
      <template v-if="group">
        <group-header-mobile :group="group" @back="onBack" />

        <div class="flex grow flex-col bg-dark">
          <group-feed
            ref="feed"
            class="mt-6 sm:mt-0"
            :group="group"
            @posts-loaded="onNewPostsLoaded"
          />

          <post-creator-fab
            v-if="!ui.desktop"
            :source="group"
            @created="onPostCreated"
          />
        </div>
      </template>
      <base-loader v-else class="absolute inset-0" />
    </div>
  </base-mdl>
</template>
