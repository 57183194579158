<script>
import { ValidationObserver } from 'vee-validate'
import { onMounted } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useConfigForm } from '/~rec/composables/config-form'
import { useForm } from '/~/composables/base/use-form'

export default {
  name: 'rec-post-config-likes',
  components: {
    BaseInput,
    BaseIcon,
    ValidationObserver,
  },
  props: {
    actionEarnLimits: {
      type: Object,
      required: true,
    },
    typeString: {
      type: String,
      default: 'Post',
    },
    confirmedEarnConfigs: {
      type: Object,
      default: () => ({}),
    },
    currency: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      filteredConfigs,
      confirmedEarnConfigs,
      actionEarnLimits,
      earnLimits,
      form,
      formInit,
    } = useConfigForm()
    const { validationObserverRef } = useForm()

    onMounted(() => {
      formInit()
      actionEarnLimits.value = props.actionEarnLimits
      confirmedEarnConfigs.value = props.confirmedEarnConfigs
    })

    return {
      filteredConfigs,
      earnLimits,
      form,
      validationObserverRef,
    }
  },
  methods: {
    async update() {
      const isValid = await this.validationObserverRef?.validate()

      this.$emit('checkValidation')

      if (!isValid) return

      this.$emit('update', this.filteredConfigs)
    },
  },
}
</script>

<template>
  <validation-observer ref="validationObserverRef" slim>
    <div>
      <div class="mt-5 mb-2.5">
        <div class="flex items-center font-bold">
          <base-icon class="mr-2.5" svg="rec/heart" size="md" />
          Likes
        </div>
      </div>
      <div class="my-2.5 flex items-start justify-between">
        <span class="text-sm font-bold text-eonx-neutral-600">
          <span class="capitalize">
            {{ currency }}
          </span>
          earned for Liking your {{ typeString }} (max.
          {{ earnLimits.post_like_create }})
        </span>
        <div class="w-[120px] shrink-0 pl-5 text-center">
          <base-input
            v-model="form.post_like_create"
            :validation="{
              rules: `required|max_value:${earnLimits.post_like_create}`,
              name: 'Post Comment Create',
            }"
            name="post_like_create"
            :mask="{ mask: '000' }"
            nolabel
            @input="update()"
          />
        </div>
      </div>
      <div class="my-2.5 flex items-start justify-between">
        <span class="text-sm font-bold text-eonx-neutral-600">
          <span class="capitalize">
            {{ currency }}
          </span>
          earned for Liking any Comment on your {{ typeString }} (max.
          {{ earnLimits.post_comment_like_create }})
        </span>
        <div class="w-[120px] shrink-0 pl-5 text-center">
          <base-input
            v-model="form.post_comment_like_create"
            :validation="{
              rules: `required|max_value:${earnLimits.post_comment_like_create}`,
              name: 'Post Comment Like',
            }"
            name="post_comment_like_create"
            class="ml-6"
            :mask="{ mask: '000' }"
            nolabel
            @input="update()"
          />
        </div>
      </div>
    </div>
  </validation-observer>
</template>
