<script>
import BusinessList from './components/business-list.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'

export default {
  name: 'fly-business-mobile',
  components: {
    BusinessList,
    BaseAsidePage,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { selectedPartner } = usePointsPrograms()

    function add() {
      emit('add')
    }
    return {
      selectedPartner,
      add,
    }
  },
}
</script>

<template>
  <base-aside-page
    :back="{ name: 'fly-partners-list' }"
    title="Member accounts"
    :action="add"
    shadow
    no-padding
  >
    <div class="bg-eonx-neutral-50 py-6 px-5">
      <h2 class="mb-4 mt-5 text-base text-eonx-neutral-800">
        {{ selectedPartner.name }}
      </h2>
      <business-list :loading="loading" />
    </div>
  </base-aside-page>
</template>
