<script>
import { computed, ref } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useEwallet } from '/~/composables/ewallet'
import { usePoints } from '/~/composables/points'
import { useUser } from '/~/composables/user'
import { useVirtualCards } from '/~/composables/virtual-cards'

export default {
  name: 'ewallet-tile',
  components: {
    BaseLoader,
    BaseIcon,
  },
  props: {
    appearance: {
      type: String,
      default: 'saved-to-date',
    },
    label: {
      type: String,
      default: 'Saved to date',
    },
    align: {
      type: String,
      default: 'left',
    },
    bgColor: {
      type: String,
      default: '#0079D0',
    },
    textColor: {
      type: String,
      default: '#FFFFFF',
    },
    bgImage: {
      type: Object,
      default: () => ({}),
    },
    virtualCardBgColor: {
      type: String,
      default: '#C5E5FC',
    },
    virtualCardTextColor: {
      type: String,
      default: '#034A7C',
    },
  },
  setup(props) {
    const {
      isVirtualCardsEnabled,
      isFetchingVirtualCards,
      firstVirtualCard,
      fetchVirtualCardSensitiveData,
    } = useVirtualCards()
    const { ewalletBalance, fetchingEwallet } = useEwallet()
    const { formattedPointsBalance, pointsLoading: isPointsLoading } =
      usePoints()
    const { user } = useUser()

    const isSensitiveDateVisible = ref(false)

    const isAlignCenter = computed(() => props.align === 'center')
    const isAlignRight = computed(() => props.align === 'right')

    const backgroundImageUrl = computed(
      () =>
        `url(${
          props.bgImage?.desktop || '/images/ewallet/tile-widget-bg-default.png'
        })`
    )

    const isEwalletCashAppearance = computed(
      () => props.appearance === 'ewallet-cash'
    )
    const isPointsAppearance = computed(() => props.appearance === 'points')

    const isBalanceLoading = computed(
      () => isPointsLoading.value || fetchingEwallet.value
    )

    const addVirtualCardRoute = computed(() => {
      return { hash: '#profile-add-virtual-card' }
    })

    const virtualCardNumber = computed(() => {
      if (!isSensitiveDateVisible.value) {
        return firstVirtualCard.value?.number
      }

      return (
        firstVirtualCard.value?.sensitiveData.number ??
        firstVirtualCard.value.number
      )
    })
    const virtualCardDate = computed(() => {
      if (
        !isSensitiveDateVisible.value ||
        !firstVirtualCard.value?.sensitiveData.expiryMonth ||
        !firstVirtualCard.value?.sensitiveData.expiryYear
      ) {
        return '**/**'
      }

      const { expiryMonth, expiryYear } = firstVirtualCard.value.sensitiveData

      return `${expiryMonth}/${expiryYear.substr(-2)}`
    })
    const virtualCardCVV = computed(() => {
      if (!isSensitiveDateVisible.value) {
        return '***'
      }

      return firstVirtualCard.value.sensitiveData?.verificationCode ?? '***'
    })

    function toggleSensitiveDataVisibility() {
      isSensitiveDateVisible.value = !isSensitiveDateVisible.value

      if (
        !isSensitiveDateVisible.value ||
        firstVirtualCard.value?.sensitiveData.isFetched
      ) {
        return
      }

      fetchVirtualCardSensitiveData(firstVirtualCard.value.id)
    }

    return {
      ewalletBalance,
      isVirtualCardsEnabled,
      isFetchingVirtualCards,
      firstVirtualCard,
      virtualCardNumber,
      virtualCardDate,
      virtualCardCVV,
      isEwalletCashAppearance,
      isPointsAppearance,
      addVirtualCardRoute,
      backgroundImageUrl,
      formattedPointsBalance,
      isPointsLoading,
      isBalanceLoading,
      user,
      isSensitiveDateVisible,
      isAlignCenter,
      isAlignRight,
      formatDollar,
      toggleSensitiveDataVisibility,
    }
  },
}
</script>

<template>
  <div
    class="relative block overflow-hidden rounded-xl bg-cover bg-center bg-no-repeat text-white"
    :style="{
      backgroundColor: bgColor,
      color: textColor,
      backgroundImage: backgroundImageUrl,
    }"
  >
    <div
      class="relative z-10 flex flex-col p-5"
      :class="{
        'items-center': isAlignCenter,
        'items-end': isAlignRight,
      }"
    >
      <div
        class="mb-1 flex items-center justify-between text-lg font-semibold tracking-wider"
      >
        {{ label }}
        <base-icon
          v-if="firstVirtualCard"
          svg="menu-new/ewallet/mastercard"
          :size="47"
          class="-my-[10px]"
        />
      </div>

      <div v-if="isBalanceLoading" class="min-h-10">
        <base-loader color="var(--color-inherit)" size="lg" />
      </div>
      <div v-else class="text-4xl font-black">
        <template v-if="isEwalletCashAppearance">
          {{ formatDollar(ewalletBalance) }}
        </template>
        <template v-else-if="isPointsAppearance">
          {{ formattedPointsBalance }}
          <span class="text-base font-bold">PTS</span>
        </template>
        <template v-else>
          {{ user.totalSaving }}
        </template>
      </div>

      <div v-if="isVirtualCardsEnabled" class="mt-4 w-full">
        <base-loader
          v-if="
            isFetchingVirtualCards ||
            (firstVirtualCard &&
              firstVirtualCard.sensitiveData.isFetching &&
              isSensitiveDateVisible)
          "
          color="var(--color-inherit)"
          size="lg"
        />
        <router-link
          v-else-if="!firstVirtualCard"
          :to="addVirtualCardRoute"
          type="button"
          class="flex items-center rounded-md bg-primary-lightest p-2 text-base font-bold text-primary"
          :style="{
            backgroundColor: virtualCardBgColor,
            color: virtualCardTextColor,
          }"
        >
          <base-icon svg="heroicons/outline/plus" :size="24" class="mr-1" />
          <span class="mr-auto">Create a digital Mastercard</span>
          <base-icon
            svg="menu-new/ewallet/mastercard"
            :size="40"
            class="-my-[7px]"
          />
        </router-link>
        <div
          v-else
          class="cursor-pointer"
          @click="toggleSensitiveDataVisibility"
        >
          <div class="text-2xl font-semibold">
            {{ virtualCardNumber }}
          </div>
          <div class="flex items-center gap-x-3">
            <div class="flex items-baseline gap-x-2">
              <span class="font-semibold">EXP</span>
              <span class="text-lg">{{ virtualCardDate }}</span>
            </div>
            <div class="flex items-baseline gap-x-2">
              <span class="font-semibold">CVV</span>
              <span class="text-lg">{{ virtualCardCVV }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
