<script setup lang="ts">
import emitter from '/~/core/emitter'
import { useCmsEwallet } from '/~/composables/cms'
import { useEwalletSdk } from '/~/composables/ewallet-sdk'

const props = defineProps<{
  close?: () => void
}>()

const { cmsEwalletSettings, cmsEwalletWidgets } = useCmsEwallet()
const { sendCloseEvent } = useEwalletSdk()

emitter.on('ewallet:close', () => {
  props.close?.()
  sendCloseEvent()
})
</script>

<template>
  <div
    class="relative flex h-full w-full flex-col overflow-y-auto overflow-x-hidden px-5 pt-6 pb-5"
    :class="{
      'bg-white': !cmsEwalletSettings.bgColor,
      'text-neutral-800': !cmsEwalletSettings.textColor,
    }"
    :style="{
      backgroundColor: cmsEwalletSettings.bgColor,
      color: cmsEwalletSettings.textColor,
    }"
    @keyup.esc="close"
  >
    <widgets-builder
      v-if="cmsEwalletWidgets"
      id="ewallet-widgets-builder"
      :widgets="cmsEwalletWidgets"
      class="!space-y-3 !pb-4"
    />
  </div>
</template>
