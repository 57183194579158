<script>
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'

export default {
  name: 'activity-details-cashback-print',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      formatDate,
      formatDollar,
    }
  },
  computed: {
    status() {
      const { content } = this

      if (content && content.metadata) {
        return content.metadata.status
      }

      return ''
    },
  },
}
</script>

<template>
  <div>
    <div class="table w-full border-b border-black pb-5 font-bold">
      <div class="table-cell w-1/2 align-top">
        Transaction No. {{ content.id }}
      </div>
      <div class="table-cell w-1/2 align-top">
        <span class="line float-right leading-normal">
          {{ formatDate('daymonthyearlongtime', content.date) }}
        </span>
      </div>
    </div>

    <div class="table w-full px-0 py-[30px]">
      <div class="table-cell w-1/2 align-top">
        <strong>Status</strong>
        <span v-if="status === 'paid'">Paid</span>
        <span v-else-if="status === 'cancelled'">Cancelled</span>
        <span v-else-if="status === 'pending'">Processing</span>
      </div>
    </div>

    <div class="pt-10">
      <table class="w-full">
        <tr class="border-b font-bold">
          <td class="px-0 py-5">Item</td>
          <td class="py-5 pl-0" />
          <td class="py-5 pl-0 text-right">Total</td>
        </tr>
        <tr class="border-b">
          <td class="px-0 py-5">
            {{ content.description ? content.description[0] : '' }}
            <br />
            {{ content.description ? content.description[1] : '' }}
          </td>
          <td class="py-5 pl-0" />
          <td class="py-5 pl-0 text-right">
            {{ formatDollar(content.total) }}
          </td>
        </tr>
        <tr class="h-5 border-b" />
        <!-- Fees !-->
        <tr class="font-bold">
          <td class="px-0 py-5" />
          <td class="py-5 pl-0">Total</td>
          <td class="py-5 pl-0 text-right text-3xl">
            <strong>{{ formatDollar(content.total) }}</strong>
          </td>
        </tr>
        <!-- /Fees !-->
      </table>
    </div>
  </div>
</template>
