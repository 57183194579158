<script setup lang="ts">
import CheckoutPay from '/~/views/checkout/components/pay/checkout-pay.vue'
import { useQuickBuyComponent } from '/~/views/quick-buy/checkout.component'
import CheckoutSummary from './components/summary/quick-buy-summary.desktop.vue'

const {
  scrollTop,
  isLoading,
  processing,
  payDisabled,
  total,
  transitions,
  onScroll,
  onSubmit,
} = useQuickBuyComponent()
</script>

<template>
  <div class="flex h-full flex-col">
    <div
      class="flex flex-1 flex-col px-5 sm:flex-row sm:justify-between sm:overflow-hidden sm:px-0 md:pl-[30px]"
    >
      <div
        class="relative flex shrink-0 flex-col overflow-hidden sm:w-1/2 sm:rounded-t-3xl sm:bg-dark"
      >
        <div
          class="pointer-events-none absolute top-0 left-0 z-10 block h-[30px] w-full shadow-inner transition duration-300 sm:rounded-tl-3xl md:rounded-t-3xl"
          :class="scrollTop > 20 ? 'opacity-100' : 'opacity-0'"
        />
        <div
          class="h-full py-6 sm:overflow-auto sm:overflow-x-hidden"
          @scroll="onScroll"
        >
          <div class="relative mx-auto flex max-w-xl flex-nowrap">
            <transition v-bind="transitions">
              <router-view
                :is-loading="isLoading"
                class="w-full shrink-0 transform transition duration-500 sm:px-5"
              />
            </transition>
          </div>
        </div>
        <div class="flex justify-center py-6 md:pr-[72px] md:pl-5 lg:px-[72px]">
          <checkout-pay
            :is-loading="isLoading"
            :processing="processing"
            :pay-disabled="payDisabled"
            :total="total"
            @pay-button-clicked="onSubmit"
          />
        </div>
      </div>

      <div class="flex shrink-0 flex-col sm:w-1/2">
        <checkout-summary :loading="isLoading" />
      </div>
    </div>
  </div>
</template>
