<script>
import DefaultActivitySummaryItem from '/~/components/activity/summary/activity-summary-item.vue'
import EwProductType from '/~/templates/australiapost/components/ewallet/product/ew-product-type.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    EwProductType,
  },
  extends: DefaultActivitySummaryItem,
})
</script>
