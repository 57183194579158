<script>
export default {
  name: 'rec-message-entry-gif',
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    hiddenAttachmentsCount: {
      type: Number,
      default: 0,
    },
    dimension: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasMoreAttachments() {
      return this.hiddenAttachmentsCount > 0
    },
  },
}
</script>

<template>
  <div class="relative flex">
    <span
      v-if="hasMoreAttachments"
      class="text absolute inset-0 z-10 flex items-center justify-center bg-overlay text-3xl font-bold text-white"
    >
      +{{ hiddenAttachmentsCount }}
    </span>
    <img class="w-full object-cover" :src="attachment.cdn" />
  </div>
</template>
