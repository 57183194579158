<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'base-drawer',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(_, { emit }) {
    const isActivated = ref(false)

    function onClose() {
      emit('close')
    }

    function beforeEnter() {
      isActivated.value = true
    }

    function afterLeave() {
      isActivated.value = false
    }

    return {
      isActivated,
      onClose,
      beforeEnter,
      afterLeave,
    }
  },
})
</script>

<template>
  <div v-show="isActivated" class="fixed inset-y-0 z-modal h-full w-full">
    <transition
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
      @before-enter="beforeEnter"
      @after-leave="afterLeave"
    >
      <div
        v-if="visible"
        class="translate absolute top-0 right-0 z-1 h-full w-xse max-w-full transform overflow-hidden bg-dark pb-1 duration-300 ease-out"
      >
        <slot />
      </div>
    </transition>
    <transition
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="visible"
        class="absolute inset-0 z-0 h-full w-full bg-overlay transition-opacity duration-200"
        data-skip-inert-toggling
        @click="onClose"
      />
    </transition>
  </div>
</template>
