<script>
import CheckoutCard from '../../components/cart-menu-row.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCheckout } from '/~/composables/checkout'

export default {
  name: 'cart-payments',
  components: {
    CheckoutCard,
    BaseIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { selectedPaymentInfo } = useCheckout()

    return {
      selectedPaymentInfo,
    }
  },
}
</script>

<template>
  <div>
    <h2 class="mb-2.5 text-lg font-bold">Pay with</h2>

    <checkout-card v-if="isLoading" loading class="rounded-lg bg-white" />
    <checkout-card
      v-else
      v-bind="selectedPaymentInfo"
      @click="$router.replace({ hash: '#cart-payment-methods' })"
    >
      <base-icon svg="v2/custom/chevron-right-outline" :size="14" />
    </checkout-card>
  </div>
</template>
