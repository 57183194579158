<script setup lang="ts">
import { computed } from 'vue'
import { useAction } from '/~/composables/base/use-action'
import { useEwallet } from '/~/composables/ewallet'
import { usePoints } from '/~/composables/points'
import { useUser } from '/~/composables/user'
import HeaderTierAndBalancePure from './widget-header-tier-and-balance.pure.vue'

const props = defineProps<{
  showIcon?: boolean
  icon?: string
  iconColor?: string
  showTier?: boolean
  showPoints?: boolean
  showPointsDivider?: boolean
  pointsSuffixText?: string
  showBalance?: boolean
  showBalanceDivider?: boolean
  balanceSuffixText?: string
  onClickAction: object
}>()

const { ewalletBalance, fetchingEwallet } = useEwallet()
const { pointsBalance, pointsLoading } = usePoints()
const { user } = useUser()

const isClickable = computed(() =>
  Boolean(props.onClickAction && props.onClickAction.type !== 'none')
)

function onClick() {
  const { handleAction } = useAction(props.onClickAction)

  handleAction()
}
</script>

<template>
  <header-tier-and-balance-pure
    v-bind="{
      ...props,
      tierText: user.membershipName,
      pointsAmount: pointsBalance,
      isPointsLoading: pointsLoading,
      balanceAmount: ewalletBalance,
      isBalanceLoading: fetchingEwallet,
      isClickable,
    }"
    @click="onClick"
  />
</template>
