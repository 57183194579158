<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'activity-summary-item-delivery',
  components: {
    BaseIcon,
  },
  props: {
    dispatched: {
      type: Boolean,
      default: false,
    },
    deliveryTrackingDetails: {
      type: Object,
      default: null,
    },
  },
}
</script>

<template>
  <div>
    <div
      v-if="dispatched && deliveryTrackingDetails"
      class="text-sm text-eonx-neutral-600"
    >
      <span class="text-primary">
        {{ deliveryTrackingDetails.courierProvider }}
      </span>
      Track: {{ deliveryTrackingDetails.trackingUrl }}
    </div>
    <div class="flex grow-0">
      <div
        class="flex grow-0 flex-row items-center rounded-full py-0.5 px-2.5 text-sm"
        :class="[
          dispatched
            ? 'bg-green-100 text-green-800'
            : 'bg-orange-100 text-orange-800',
        ]"
      >
        <base-icon
          :svg="dispatched ? 'points-only/truck' : 'points-only/time-five'"
          :size="14"
          class="mr-[5px]"
        />
        {{ dispatched ? 'Dispatched' : 'Processing' }}
      </div>
    </div>
  </div>
</template>
