<script>
import find from 'lodash-es/find'
import Offer from './offer.vue'
import BaseBox from '/~/components/base/box/base-box.vue'
import BaseLink from '/~/components/base/link/base-link.vue'

export default {
  name: 'details-view-service-offers',
  components: {
    Offer,
    BaseBox,
    BaseLink,
  },
  props: {
    retailer: {
      type: Object,
      default: () => ({}),
    },
    offers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    offersAvailable() {
      return this.retailer.active && this.offers.length
    },
    hasHtmlOffers() {
      return this.offers && !!find(this.offers, { type: 'html' })
    },
  },
}
</script>

<template>
  <base-box hide-header>
    <div class="mx-5 mt-[5px] sm:mx-0">
      <h2
        class="mb-[15px] block text-center text-3xl font-light leading-10 sm:text-left"
      >
        {{ retailer.name }}
      </h2>

      <div class="text-eonx-neutral-800">
        {{ retailer.description }}
      </div>

      <div class="mt-[15px] sm:hidden">
        <base-link
          v-if="retailer.website"
          :href="retailer.website"
          class="block overflow-hidden truncate font-bold leading-8"
          look="underline"
        >
          {{ retailer.website.replace(/^https?:\/\/|\/$/g, '') }}
        </base-link>
      </div>

      <div
        v-if="!hasHtmlOffers"
        class="mt-[30px] text-xl font-bold text-eonx-neutral-800"
      >
        Offers
      </div>

      <template v-if="offersAvailable">
        <offer
          v-for="offer in offers"
          :key="offer.id"
          :retailer="retailer"
          :offer="offer"
          class="mt-[15px]"
        />
      </template>

      <div v-else class="leading-6 text-eonx-neutral-600">
        <hr class="my-5" />
        <div>
          Sorry, offers are currently unavailable for this retailer.
          <br />
          Please try again later.
        </div>
      </div>
    </div>
  </base-box>
</template>
