<script>
import ui from '/~/core/ui'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'qr-layout',
  components: {
    BaseLink,
    BaseIcon,
  },
  props: {
    reserveWorkspace: {
      type: Object,
      default: null,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    back() {
      return this.$route.meta.back || 'qr-connect'
    },
    backText() {
      return this.$route.meta.title || 'Back'
    },
  },
}
</script>

<template>
  <div class="mb-5">
    <div class="mx-auto flex w-full max-w-2xl flex-col sm:pt-[30px]">
      <component :is="ui.mobile ? 'portal' : 'div'" to="app-header">
        <transition
          enter-class="translate-x-full"
          leave-to-class="translate-x-full"
        >
          <div
            class="absolute z-10 flex h-full w-full transform items-center bg-primary px-5 py-[15px] transition duration-300 sm:relative sm:bg-transparent sm:py-0"
          >
            <base-link
              :name="back"
              :params="{ data: reserveWorkspace }"
              class="block text-white sm:text-primary"
            >
              <div
                class="flex content-center items-center text-xl font-bold leading-none text-white sm:text-2xl sm:text-primary"
              >
                <base-icon svg="plain/chevronleft" class="mr-5" size="sm" />
                <span class="leading-none">
                  {{ backText }}
                </span>
              </div>
            </base-link>
          </div>
        </transition>
      </component>
      <div class="overflow-y-auto px-5 pt-6 sm:pt-[30px]">
        <slot />
      </div>
    </div>

    <portal v-if="ui.mobile && hideFooter" to="mobile-footer">
      <div class="absolute inset-0 z-10 -mt-px bg-primary-lightest" />
    </portal>
  </div>
</template>
