<script>
import DrawerEPurchasesCurrent from '/~/components/drawer/views/e-purchases/drawer-e-purchases-current.vue'
import EpurchaseTile from '/~/components/drawer/components/tiles/drawer-e-purchases-tile.v2.vue'
import { usePurchases } from '/~/composables/purchases'
import bottomSheet from '/~/core/bottom-sheet'

export default {
  name: 'epurchases-current',
  components: {
    EpurchaseTile,
  },
  extends: DrawerEPurchasesCurrent,
  setup(props) {
    const { purchases, fetchCurrentPurchases } = usePurchases()

    if (!purchases.active.processing) {
      fetchCurrentPurchases()
    }

    function showUnlockModal() {
      bottomSheet.show('epurchase-unlock-v2', {
        style: {
          centered: true,
        },
        to: props.to,
      })
    }

    return {
      ...DrawerEPurchasesCurrent.setup?.(...arguments),
      purchases,
      showUnlockModal,
    }
  },
}
</script>
