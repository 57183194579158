<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import CheckoutConfirmationDesktop from './checkout-confirmation.desktop.vue'

export default {
  name: 'checkout-mobile',
  components: {
    BaseAsidePage,
  },
  extends: CheckoutConfirmationDesktop,
  setup() {
    return {
      ...CheckoutConfirmationDesktop.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <base-aside-page :title="label" no-padding shadow :back="backRoute">
    <div class="flex h-screen w-full bg-eonx-neutral-50 pt-5 sm:pt-0">
      <div
        class="mb-18 flex h-full w-full flex-col rounded-t-3xl bg-eonx-neutral-50 sm:mr-6 sm:pt-[30px]"
      >
        <div
          class="relative mx-auto flex min-h-full w-full max-w-lg flex-col sm:rounded-t-3xl sm:pt-[35px]"
        >
          <div class="flex flex-col px-5 pb-24">
            <base-loader v-if="!isReady" fullwidth />

            <div
              v-else-if="failed"
              class="py-[30px] text-center text-2xl font-bold"
            >
              Order information not found

              <div class="mt-[30px]">
                <base-button full-width @click="$router.push({ name: 'home' })">
                  Back to Dashboard
                </base-button>
              </div>
            </div>

            <template v-else>
              <div class="grow">
                <div
                  class="flex items-center justify-center px-5 pb-5 sm:px-10"
                >
                  <div class="flex flex-col items-center">
                    <div class="flex items-center justify-center rounded-full">
                      <base-icon
                        :svg="statusIcon"
                        :size="isFailed ? 50 : 64"
                        :class="statusColor"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="mb-6 text-center text-sm font-bold text-eonx-neutral-800"
                >
                  <div v-if="formattedDate">
                    {{ formattedDate }}
                  </div>
                  <div>Transaction No. #{{ paymentId }}</div>
                  <div>
                    Status:
                    <span :class="statusLabel.color">
                      {{ statusLabel.label }}
                    </span>
                  </div>
                </div>

                <div
                  v-if="
                    payWithPayId &&
                    cancelDescription(order.status, order.subStatus)
                  "
                  class="mt-3 text-center"
                  v-html="cancelDescription(order.status, order.subStatus)"
                ></div>

                <payment-status-tracker
                  v-if="statusTracker.length"
                  class="mt-6 mb-8"
                  :items="statusTracker"
                />

                <points-panel
                  v-if="!isFailed && payment.isOrderPoints"
                  :total="pointsEarned"
                  :completed="
                    !isFailed && !isStatusGroupPending && !isScheduled
                  "
                  class="mt-[15px] mb-6 rounded-lg"
                />

                <confirmation-addresses
                  v-if="
                    isUserAddressEnabled &&
                    (order.billingAddress || order.shippingAddress)
                  "
                  class="mb-5 border-b border-neutral-300 pb-5"
                  :order="order"
                />

                <div class="mb-5">
                  <div class="mb-5 font-bold">{{ payToLabel }}</div>
                  <confirmation-pay-to :order="order" />
                </div>

                <div class="mt-5 border-t border-neutral-300 py-5">
                  <div class="mb-5 font-bold">{{ payWithLabel }}</div>
                  <div class="space-y-[15px]">
                    <confirmation-pay-with :order="order" />
                    <hr class="my-5 h-px w-full bg-gray-50" />
                  </div>
                </div>
                <confirmation-total :order="order" :sticky-total="true" />
                <div
                  v-if="isBatchOrderCurrentFlowType"
                  class="sticky top-9 z-10 my-6 h-px border-t border-neutral-400"
                />
                <batch-payee-table
                  v-if="isBatchOrderCurrentFlowType"
                  class="py-6 text-eonx-neutral-800"
                />
              </div>
            </template>
          </div>
        </div>
        <div
          class="fixed bottom-0 z-20 mt-16 w-full border-t border-eonx-neutral-300 bg-white p-5"
        >
          <div
            class="mx-auto flex max-w-lg gap-x-2"
            :class="{
              'grid-cols-1': !showViewButton,
              'grid-cols-2': showViewButton,
            }"
          >
            <base-button
              v-if="showViewButton"
              look="outlined-color"
              :loading="!isReady"
              class="flex-auto"
              @click="onView"
            >
              {{ viewButtonTitle }}
            </base-button>
            <base-button class="flex-auto" @click="onLeave">Done</base-button>
          </div>
        </div>
      </div>
    </div>
  </base-aside-page>
</template>
