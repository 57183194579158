<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useProvider } from '/~/composables/provider'
import FaqSection from '/~/modals/faq/faq-section.vue'
import Loader from './components/loader.vue'

const HowItWorksDefault = () => ({
  component: import('./how-it-works.vue'),
  loading: Loader,
})

const HowItWorksBillPayments = () => ({
  component: import(
    '/~/templates/bill-payments/modals/how-it-works/how-it-works.vue'
  ),
  loading: Loader,
})

export default {
  name: 'how-it-works-and-faq-modal',
  components: {
    HowItWorksDefault,
    HowItWorksBillPayments,
    FaqSection,
    BaseMdl,
  },
  setup() {
    const { isBillPaymentsTemplate } = useProvider()

    return {
      isBillPaymentsTemplate,
    }
  },
}
</script>

<template>
  <base-mdl
    title="How it works"
    width="lg"
    fullscreen="mobile"
    class="modal-faq"
    layout="v2"
  >
    <how-it-works-bill-payments v-if="isBillPaymentsTemplate" />
    <how-it-works-default v-else />

    <faq-section />
  </base-mdl>
</template>
