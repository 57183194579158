<script setup lang="ts">
import { getCurrentInstance, computed, ref, watch } from 'vue'
import { useIMask } from 'vue-imask'
import { useBaseInputCode } from './base-input-code'

withDefaults(
  defineProps<{
    error?: boolean
    disabled?: boolean
  }>(),
  {
    error: false,
    disabled: false,
  }
)

const emit = defineEmits<(event: 'enter') => void>()

const { code, count, onKeyup, onBackspace, onPaste, onFocus } =
  useBaseInputCode()

const inputValue = ref<string | number | null>(null)

const { el } = useIMask({
  mask: '0',
})
const instance = computed(() => getCurrentInstance()?.proxy)
const key = computed<number>(() => Number(instance.value?.$vnode.key))
const itemDisabled = computed(
  () =>
    key.value !== 1 &&
    code.value.length !== count.value &&
    code.value[key.value - 2] === undefined
)

watch(
  code,
  (value) => {
    inputValue.value = value[key.value - 1]
  },
  { deep: true }
)

function focus(e: Event) {
  onFocus(key.value)
}

function keyup(e: KeyboardEvent) {
  if (e.code === 'Backspace' || e.key === 'Backspace') {
    onBackspace(key.value)
  }
  if (e.code === 'Enter' || e.key === 'Enter') {
    emit('enter')
  }
}

function paste(e: Event) {
  const text: string = e.target?.value || e.clipboardData?.getData('text')

  if (text?.length === count.value) {
    onPaste(text)
  }

  if (
    text?.length === 1 &&
    e.type === 'input' &&
    Number.isInteger(parseInt(text))
  ) {
    onKeyup(key.value)
    code.value[key.value - 1] = parseInt(text)
    code.value = [...code.value]
  }

  if (
    text?.length === 2 &&
    e.type === 'input' &&
    Number.isInteger(parseInt(text[1])) &&
    Number.isInteger(parseInt(text[0])) &&
    key.value <= count.value
  ) {
    const numberText: string = text.replace(code.value[key.value - 1], '')

    const number: number =
      numberText.length > 1 ? parseInt(numberText[0]) : parseInt(numberText)

    inputValue.value = number
    code.value[key.value - 1] = number
    code.value = [...code.value]
    onKeyup(key.value)
  }
}
</script>
<template>
  <input
    ref="el"
    v-model="inputValue"
    :autofocus="key === 1"
    class="h-12 w-10 rounded-md border border-eonx-neutral-200 text-center text-xl text-eonx-neutral-800 !outline-none focus:border-primary disabled:bg-inherit"
    :class="{
      'border-red-700 bg-red-50 text-red-700': error,
    }"
    :disabled="disabled || itemDisabled"
    inputmode="numeric"
    type="text"
    autocomplete="one-time-code"
    @paste.prevent="paste"
    @focus="focus"
    @keyup="keyup"
    @input="paste"
    @change="paste"
  />
</template>
