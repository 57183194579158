import { ref, computed } from 'vue'
import { useExtensions } from '/~/composables/extensions'

const { getConfigByName } = useExtensions()

const MODULE_NAME = 'ticket-master'

const config = computed(() => getConfigByName(MODULE_NAME))

const widget = computed(() => config.value.widget ?? {})

const loading = ref(false)

function init() {
  if (!config.value) {
    return console.error('no config for ticket master module')
  }
}

function loadScript() {
  const existingScript = document.getElementById('ticket-master-script')

  if (existingScript) {
    existingScript.remove()
  }

  loading.value = true

  const firstScript = document.getElementsByTagName('script')[0]
  const script = document.createElement('script')

  script.async = !0
  script.type = 'text/javascript'
  script.src =
    'https://ticketmaster-api-staging.github.io/products-and-docs/widgets/event-discovery/1.0.0/lib/main-widget.js'
  script.id = 'ticket-master-script'
  script.onload = () => {
    loading.value = false
  }

  firstScript.parentNode.insertBefore(script, firstScript)
}

export function useTicketMaster() {
  return {
    moduleName: MODULE_NAME,
    init,
    loadScript,
    widget,
    loading,
  }
}
