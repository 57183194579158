<script>
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import RenderMessage from '/~rec/components/render/render-message.vue'
import BaseClampLine from '/~/components/base/clamp-line/base-clamp-line.vue'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'announcement-card',
  components: {
    BaseDate,
    BaseLink,
    BaseAvatar,
    RenderMessage,
    BaseClampLine,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { allMembers } = useGroups()

    return { allMembers }
  },
  computed: {
    formattedMsg() {
      let msg = this.post.content
      const allMentions = msg.match(/@\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/g)

      if (allMentions?.length) {
        allMentions.forEach((m) => {
          const user = this.allMembers.find((u) => u.id === m.replace('@', ''))

          if (user) {
            msg = msg.replace(
              `[${m}]`,
              `<span class="text-blue-500 font-semibold">@${user?.fullName}</span>`
            )
          }
        })
      }
      return msg
    },
  },
}
</script>

<template>
  <base-link
    class="block"
    :to="{
      name: 'rec-feed',
      params: { feedId: 'announcements' },
      query: { postId: post.id },
    }"
  >
    <div
      class="cursor-pointer rounded-xl bg-white p-[15px] text-eonx-neutral-800 duration-300 hover:bg-slate-200 md:bg-eonx-neutral-50"
    >
      <div class="mb-2.5 flex items-center">
        <base-avatar
          class="mr-[15px]"
          size="md"
          :src="post.author.avatarUrl"
          :abbr="post.author.initials"
        />
        <div class="truncate">
          <div class="truncate font-bold leading-tight">
            {{ post.author.fullName }}
          </div>
          <div class="flex flex-wrap font-bold leading-tight">
            <base-date
              :date="post.createdAt"
              class="text-xs text-eonx-neutral-600"
              type="day-time-spr"
            />
          </div>
        </div>
      </div>
      <base-clamp-line :rows="4">
        <render-message class="leading-normal" :content="formattedMsg" />
      </base-clamp-line>
    </div>
  </base-link>
</template>
