<script>
import { useRouter } from 'vue-router/composables'
import BaseBox from '/~/components/base/box/base-box.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCart } from '/~/composables/cart'
import { useQuickBuyCheckout } from '/~/composables/checkout'
import QuickBuyCard from './quick-buy-card.vue'

export default {
  name: 'quick-buy-payments',
  components: {
    QuickBuyCard,
    BaseIcon,
    BaseBox,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter()
    const { fetchCart } = useCart()
    const {
      selectedCoupons,
      selectedPaymentInfo,
      selectedCard,
      selectedPoints,
      selectedEwallet,
      selectedBankAccount,
    } = useQuickBuyCheckout()

    return {
      fetchCart,
      selectedCoupons,
      selectedPaymentInfo,
      selectedCard,
      selectedPoints,
      selectedEwallet,
      selectedBankAccount,
      router,
    }
  },
  data() {
    return {
      isCoupons: false,
      coupon: '',
      addingCoupon: false,
      removingCoupon: false,
    }
  },
  computed: {
    isCouponApplied() {
      return this.selectedCoupons.length > 0
    },
  },
  watch: {
    isCouponApplied: {
      handler(value) {
        this.isCoupons = value
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <base-box title="Pay with" new-look>
    <quick-buy-card v-if="isLoading" loading />
    <router-link
      v-else
      :to="{
        name: 'quick-buy-payment-methods',
        query: router.currentRoute.query,
      }"
    >
      <quick-buy-card v-bind="selectedPaymentInfo" icon-box-size="w-11 h-11">
        <base-icon svg="plain/chevron-right" size="sm" />
      </quick-buy-card>
    </router-link>
  </base-box>
</template>
