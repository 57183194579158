<script>
import Vue, { nextTick } from 'vue'
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const anchors = this.$el.getElementsByTagName('a')

    Array.from(anchors).forEach((anchor) => {
      const url = anchor.getAttribute('href')

      if (url.startsWith('http')) {
        return
      }
      const propsData = { to: url }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const parent = this

      const RouterLink = Vue.component('RouterLink')
      const routerLink = new RouterLink({ propsData, parent })

      nextTick(() => {
        routerLink.$el.innerHTML = anchor.innerHTML
        routerLink.$el.className = anchor.className
      })
      routerLink.$mount(anchor)
    })
  },
  render(createElement) {
    const options = {
      domProps: { innerHTML: this.content },
    }

    return createElement('div', options)
  },
}
</script>
