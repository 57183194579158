<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { IMaskComponent } from 'vue-imask'
import RecPoints from '/~/components/app/header/app-header-rec-points.vue'

export default {
  name: 'rec-hi5-points-gifting',
  components: {
    BaseButton,
    BaseMdl,
    BaseIcon,
    RecPoints,
    'imask-input': IMaskComponent,
  },
  props: {
    current: {
      type: Number,
      default: null,
    },
    balance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputValue: `${this.current}`,
    }
  },
  computed: {
    left() {
      if (!this.inputValue || this.inputValue.length === 1) {
        return '60%'
      }
      if (this.inputValue.length === 2) {
        return '62%'
      }
      if (this.inputValue.length === 3) {
        return '67%'
      }
      if (this.inputValue.length === 4) {
        return '73%'
      }
      return '77%'
    },
    tokenBalance() {
      return Number(this.balance.points.replace(/,/g, ''))
    },
    disabled() {
      return !this.inputValue || Number(this.inputValue) > this.tokenBalance
    },
    isPersonal() {
      return this.balance.type === 'personal'
    },
    displayPts() {
      return {
        showPersonal: this.isPersonal,
        showManagement: !this.isPersonal,
      }
    },
  },
  mounted() {
    this.$refs.input.$el.focus()
  },
  methods: {
    save() {
      this.$emit('selected', this.inputValue)
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl
    transition="slide-bottom"
    class="ease transform duration-500"
    modal-styles="max-height:35rem; margin-bottom:0"
    modal-class="rounded-t-3xl"
    width="screen"
    fullscreen="mobile"
    :layout="false"
  >
    <div
      class="absolute right-0 z-10 m-5 text-eonx-neutral-800 sm:mr-10"
      @click="modal.hide()"
    >
      <base-icon svg="close" size="md" />
    </div>

    <div
      class="px flex items-center justify-center pt-[30px]"
      :class="{
        'text-amber-400': isPersonal,
        'text-violet-400': !isPersonal,
      }"
    >
      <base-icon svg="v3/token" :size="220" />
    </div>

    <div class="mt-2.5 flex flex-col">
      <h2 class="mb-[5px] text-center">Enter amount</h2>

      <div class="relative mx-auto h-auto text-center">
        <imask-input
          ref="input"
          v-model="inputValue"
          placeholder="0"
          class="mx-auto w-1/2 text-center text-6xl font-bold"
          :mask="/^(?!0)\d+?$/"
        />

        <div
          class="absolute text-xl text-eonx-neutral-600"
          :style="{
            left: left,
            top: '40%',
          }"
        >
          PTS
        </div>
      </div>

      <div class="flex justify-center pt-2.5 pb-5">
        <rec-points v-bind="displayPts" />
      </div>

      <div class="flexmt-auto px-[30px] pb-5">
        <div class="w-full sm:mx-auto sm:mb-[15px] sm:w-48">
          <base-button
            color="primary"
            full-width
            :disabled="disabled"
            @click="save"
          >
            Save
          </base-button>
        </div>
      </div>
    </div>
  </base-mdl>
</template>
