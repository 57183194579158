<script>
import { computed } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseState from '/~/components/base/state/base-state.vue'
import { useActivity } from '/~/composables/activity'
import ActivityListItem from './components/drawer-activity-list-item.vue'

export default {
  name: 'drawer-activity-list',
  components: {
    ActivityListItem,
    BaseButton,
    BaseState,
    BaseLoader,
  },
  setup(_, { emit }) {
    const { activity, recordGroupsByDate } = useActivity()

    const empty = computed(() => {
      return activity.value.isEmpty
    })

    const allLoaded = computed(() => {
      return activity.value.allRecordsLoaded
    })

    activity.value.refresh()

    function loadMore(isLoaderVisible) {
      if (isLoaderVisible) {
        activity.value.next()
      }
    }

    function onRecordClick(id) {
      emit('view-detail', id)
    }

    return {
      empty,
      allLoaded,
      recordGroupsByDate,
      loadMore,
      onRecordClick,
    }
  },
}
</script>

<template>
  <base-state
    v-if="empty"
    title="No activity listed"
    image="/empty/empty-activity.svg"
    class="m-auto min-h-full p-5"
  >
    <p class="mb-[30px]">
      Start shopping and manage all your activity history here.
    </p>
    <base-button @click="$router.push({ name: 'giftcards' })">
      Show Gift Cards
    </base-button>
  </base-state>
  <div v-else class="space-y-5 bg-light px-6 pb-[30px]">
    <div v-for="group in recordGroupsByDate" :key="group.id">
      <div class="mb-[15px] text-sm font-bold text-eonx-neutral-600">
        {{ group.name }}
      </div>
      <div class="space-y-5">
        <activity-list-item
          v-for="record in group.records"
          :key="record.id"
          :item="record"
          @click="onRecordClick"
        />
      </div>
    </div>

    <base-loader
      v-if="!allLoaded"
      v-observe-visibility="loadMore"
      class="py-5"
      fullwidth
    />
  </div>
</template>
