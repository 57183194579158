import get from 'lodash-es/get'
import capitalize from 'lodash-es/capitalize'
import { formatNumber } from '/~/utils/format/numeric'
import { useRecProfile } from '/~rec/composables/profile'

const { currency } = useRecProfile()

export class RecroomEarn {
  constructor(raw) {
    this.raw = raw || {}
  }

  get action() {
    return get(this.raw, 'action')
  }

  get title() {
    return get(this.raw, 'action_message')
  }

  get currency() {
    return currency.value
  }

  get icon() {
    return this.isCompleted ? 'rec/check-circle' : 'rec/token'
  }

  get earnedToday() {
    return get(this.raw, 'today_earned')
  }

  get dailyLimit() {
    return get(this.raw, 'daily_limit')
  }

  get isCompleted() {
    return this.earnedToday === this.dailyLimit
  }

  get earnPerAction() {
    return get(this.raw, 'earned_per_action')
  }

  get showAction() {
    return this.earnPerAction && this.earnPerAction !== 0
  }

  get description() {
    return get(this.raw, 'earned_per_action_message')
  }

  get points() {
    return get(this.raw, 'points')
  }

  get statusFraction() {
    const earnedToday = this.earnedToday
      ? this.earnedToday / this.earnPerAction
      : 0
    const dailyLimit = this.dailyLimit
      ? this.dailyLimit / this.earnPerAction
      : 0

    return `${earnedToday}/${dailyLimit}`
  }

  get badgeContent() {
    return this.isCompleted
      ? `${this.dailyLimit} ${capitalize(this.currency)} Earned`
      : `Earn ${formatNumber(this.earnPerAction)} ${this.currency}`
  }
}
