import { useRoute } from 'vue-router/composables'
import { useUI } from '/~/composables/ui'
import { getMenuActiveItem } from '/~/utils/menu'
import { computed } from 'vue'

export function useMarketplaceMenu() {
  const { leftMenu, uiReady } = useUI()
  const route = useRoute()

  const activeTopMenuItem = computed(() => {
    if (!uiReady.value || !leftMenu.value) {
      return null
    }

    return getMenuActiveItem(route, leftMenu.value)
  })

  const menu = computed(() => {
    const children = activeTopMenuItem.value?.children ?? []

    return children.map((i: any) => ({
      ...i,
      route: {
        name: i.action.value,
        params: i.action.params,
      },
    }))
  })

  const activeItem = computed(() => getMenuActiveItem(route, menu.value))

  const hideMenu = computed(() =>
    Boolean(
      menu.value.length === 0 ||
        route.meta?.hideMenu ||
        route.path.includes('rec-room')
    )
  )

  return {
    hideMenu,
    menu,
    activeItem,
  }
}
