<script>
import DefaultEstore from '/~/extensions/estore/templates/default/views/estore.vue'
import EstoreCatalog from './estore-catalog.vue'
import EstoreFiltersDesktop from '/~/extensions/estore/components/estore-filters.desktop.vue'
import EstoreFiltersMobile from '/~/extensions/estore/components/estore-filters.mobile.vue'

export default {
  name: 'estore-bill-pay',
  components: {
    EstoreCatalog,
    EstoreFiltersDesktop,
    EstoreFiltersMobile,
  },
  extends: DefaultEstore,
}
</script>

<template>
  <div class="mx-auto h-full w-full max-w-7xl">
    <portal to="rewards-header">
      <estore-filters-desktop
        v-if="!ui.mobile"
        class="mx-auto max-w-7xl py-2.5 px-2.5 sm:px-0"
      />
    </portal>

    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />

    <estore-filters-mobile v-if="ui.mobile" class="py-2.5 px-2.5 sm:px-5" />
    <estore-catalog class="px-2.5 sm:px-0" />
  </div>
</template>
