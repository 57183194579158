<script>
import ui from '/~/core/ui'
import ChatRooms from '/~rec/components/chat/rooms/chat-rooms.vue'

export default {
  name: 'rec-chat-view',
  components: {
    ChatRooms,
  },
  setup() {
    return {
      ui,
    }
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      if (!ui.desktop) {
        this.$router.replace({ name: 'rec-room' })
      }
    },
  },
}
</script>

<template>
  <div class="bg-zinc-50 px-5">
    <chat-rooms v-if="!ui.desktop" />
  </div>
</template>
