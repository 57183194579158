import { rules } from '/~/configurator/composables/use-rules'
import australiaPostBanner from './australiapost-banner.json'
import australiaPostHomeBanner from './australiapost-home-banner.json'
import australiapostSlider from './australiapost-slider.json'
import australiaPostWelcomeBanner from './australiapost-welcome-banner.json'
import billpayHomeRightBannerB2B from './billpay-home-right-banner-b2b.json'
import billpayHomeRightBannerV3 from './billpay-home-right-banner-v3.json'
import billpayHomeRightBanner from './billpay-home-right-banner.json'
import bupaCatalogueBanner from './bupa-catalogue-banner.json'
import bupaCommunityHubOfferGroups from './bupa-community-hub-offer-groups.json'
import bupaDiscountsTiles from './bupa-discounts-tiles.json'
import bupaHomeBannerSlider from './bupa-home-banner-slider.json'
import bupaMembershipLevel from './bupa-membership-level.json'
import bupaSplitBannerSlider from './bupa-split-banner-slider.json'
import bupaTiles from './bupa-tiles.json'
import button from './button.json'
import code from './code.json'
import content from './content.json'
import epworthBanner from './epworth-banner.json'
import epworthHomeBanner from './epworth-home-banner.json'
import ewalletDivider from './ewallet-divider.json'
import ewalletEpurchases from './ewallet-epurchases.json'
import ewalletHeader from './ewallet-header.json'
import ewalletImage from './ewallet-image.json'
import ewalletPointsAndTier from './ewallet-points-and-tier.json'
import ewalletProfile from './ewallet-profile.json'
import ewalletServices from './ewallet-services.json'
import ewalletShortcuts from './ewallet-shortcuts.json'
import ewalletTile from './ewallet-tile.json'
import fullscreenBanner from './fullscreen-banner.json'
import greetings from './greetings.json'
import headerLogo from './header-logo.json'
import headerMenu from './header-menu.json'
import headerMobileMenuButton from './header-mobile-menu-button.json'
import headerTierAndBalance from './header-tier-and-balance.json'
import headerTierAndPoints from './header-tier-and-points.json'
import healthSlider from './health-slider.json'
import heroBanner from './hero-banner.json'
import homeBanner from './home-banner.json'
import image from './image.json'
import images from './images.json'
import newsfeed from './newsfeed.json'
import optusHomeBanner from './optus-home-banner.json'
import optusV2HomeBanner from './optus-v2-home-banner.json'
import optusV2Tiles from './optus-v2-tiles.json'
import payidNotifyPanel from './payid-notify-panel.json'
import paymentsOverview from './payments-overview.json'
import pointsBalance from './points-balance.json'
import popular from './popular.json'
import racgpBanner from './racgp-banner.json'
import racqHomeBanner from './racq-home-banner.json'
import sdaHomeBanner from './sda-home-banner.json'
import slider from './slider.json'
import specialOffersSlider from './special-offers-slider.json'
import staffHomeBanner from './staff-home-banner.json'
import statementItems from './statement-items.json'
import statementsNotifyPanel from './statements-notify-panel.json'
import text from './text.json'
import tileImage from './tile-image.json'
import tilesEpworth from './tiles-epworth.json'
import tilesHemisphere from './tiles-hemisphere.json'
import tilesNew from './tiles-new.json'
import tilesOptus from './tiles-optus.json'
import tilesSda from './tiles-sda.json'
import tilesShort from './tiles-short.json'
import tilesStaff from './tiles-staff.json'
import tiles from './tiles.json'
import unverifiedNotification from './unverified-notification.json'
import valuedbenefitsHomeBanner from './valuedbenefits-home-banner.json'

const widgets = {
  default: {
    label: 'Default',
    widgets: {
      newsfeed,
      button,
      code,
      image,
      images,
      popular,
      slider,
      text,
      content,
      'tiles-new': tilesNew,
      'hero-banner': heroBanner,
      'home-banner': homeBanner,
      'tile-image': tileImage,
      'tiles-short': tilesShort,
      tiles,
    },
  },
  australiapost: {
    label: 'Australia Post',
    widgets: {
      'australiapost-banner': australiaPostBanner,
      'australiapost-home-banner': australiaPostHomeBanner,
      'australiapost-welcome-banner': australiaPostWelcomeBanner,
      'australiapost-slider': australiapostSlider,
    },
  },
  billpay: {
    label: 'Bill Payments',
    widgets: {
      'billpay-home-right-banner': billpayHomeRightBanner,
      'billpay-home-right-banner-b2b': billpayHomeRightBannerB2B,
      'billpay-home-right-banner-v3': billpayHomeRightBannerV3,
      'payid-notify-panel': payidNotifyPanel,
      'points-balance': pointsBalance,
      'payments-overview': paymentsOverview,
      greetings: greetings,
      'special-offers-slider': specialOffersSlider,
      'statement-items': statementItems,
      'statements-notify-panel': statementsNotifyPanel,
      'unverified-notification': unverifiedNotification,
    },
  },
  bupa: {
    label: 'Bupa',
    widgets: {
      'bupa-membership-level': bupaMembershipLevel,
      'bupa-home-banner-slider': bupaHomeBannerSlider,
      'bupa-tiles': bupaTiles,
      'bupa-split-banner-slider': bupaSplitBannerSlider,
      'bupa-discounts-tiles': bupaDiscountsTiles,
      'bupa-catalogue-banner': bupaCatalogueBanner,
      'bupa-community-hub-offer-groups': bupaCommunityHubOfferGroups,
    },
  },
  epworth: {
    label: 'Epworth Employee Rewards',
    widgets: {
      'epworth-banner': epworthBanner,
      'epworth-home-banner': epworthHomeBanner,
      'tiles-epworth': tilesEpworth,
    },
  },
  hemisphere: {
    label: 'Hemisphere',
    widgets: {
      'tiles-hemisphere': tilesHemisphere,
    },
  },
  optus: {
    label: 'Optus',
    widgets: {
      'optus-home-banner': optusHomeBanner,
      'optus-v2-home-banner': optusV2HomeBanner,
      'optus-v2-tiles': optusV2Tiles,
      'tiles-optus': tilesOptus,
    },
  },
  racq: {
    label: 'Racq',
    widgets: {
      'racgp-banner': racgpBanner,
      'racq-home-banner': racqHomeBanner,
    },
  },
  sda: {
    label: 'Sda',
    widgets: {
      'sda-home-banner': sdaHomeBanner,
      'tiles-sda': tilesSda,
    },
  },
  staff: {
    label: 'Staff',
    widgets: {
      'staff-home-banner': staffHomeBanner,
      'fullscreen-banner': fullscreenBanner,
      'tiles-staff': tilesStaff,
      'health-slider': healthSlider,
    },
  },
  valuedbenefits: {
    label: 'Valuedbenefits',
    widgets: {
      'valuedbenefits-home-banner': valuedbenefitsHomeBanner,
    },
  },
  ewallet: {
    label: 'Ewallet',
    widgets: {
      'ewallet-divider': ewalletDivider,
      'ewallet-epurchases': ewalletEpurchases,
      'ewallet-header': ewalletHeader,
      'ewallet-image': ewalletImage,
      'ewallet-points-and-tier': ewalletPointsAndTier,
      'ewallet-profile': ewalletProfile,
      'ewallet-shortcuts': ewalletShortcuts,
      'ewallet-services': ewalletServices,
      'ewallet-tile': ewalletTile,
    },
  },
  header: {
    label: 'Header',
    widgets: {
      'header-logo': headerLogo,
      'header-menu': headerMenu,
      'header-mobile-menu-button': headerMobileMenuButton,
      'header-tier-and-points': headerTierAndPoints,
      'header-tier-and-balance': headerTierAndBalance,
    },
  },
}

const fields = [
  {
    type: 'boolean',
    name: 'enableWidget',
    default: true,
    props: {
      label: 'Enable widget',
    },
  },
  {
    type: 'boolean',
    name: 'showOnDesktop',
    default: true,
    props: {
      label: 'Show on desktop',
    },
  },
  {
    type: 'boolean',
    name: 'showOnMobile',
    default: true,
    props: {
      label: 'Show on mobile',
    },
  },
  {
    type: 'select',
    name: 'customRules',
    props: {
      options: [
        {
          value: rules.forBusinessOwnerOnly,
          label: 'Show only to business owners.',
        },
      ],
      label: 'Custom rules',
      multiple: true,
    },
    default: [],
  },
]

export const widgetsList = Object.values(widgets).reduce((acc, section) => {
  if (section.widgets) {
    Object.entries(section.widgets).forEach(([name, value]) => {
      acc[name] = value
    })
  }
  return acc
}, {})

Object.keys(widgets).forEach((groupKey) =>
  Object.keys(widgets[groupKey].widgets).forEach((key) =>
    widgets[groupKey].widgets[key]?.fields?.unshift({
      type: 'select',
      name: 'side',
      props: {
        options: [
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Centre',
            value: 'center',
          },
          {
            label: 'Right',
            value: 'right',
          },
        ],
        label: 'Widget position',
      },
      default: 'center',
    })
  )
)

function addFieldsToAllLevels(obj: any) {
  if (obj.fields && Array.isArray(obj.fields)) {
    obj.fields.unshift(...fields)
  }

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      typeof obj[key] === 'object' &&
      obj[key] !== null
    ) {
      addFieldsToAllLevels(obj[key])
    }
  }
}

addFieldsToAllLevels(widgets)

export default widgets
