<script>
import QrReservationItem from './qr-reservation-item.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useQrConnect } from '/~/extensions/qr-connect/composables'

export default {
  name: 'qr-my-reservations',
  components: {
    QrReservationItem,
    BaseLoader,
  },
  setup() {
    const { claims, fetchReservations } = useQrConnect()

    return {
      claims,
      fetchReservations,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  async mounted() {
    this.loading = true
    await this.fetchReservations()
    this.loading = false
  },
  methods: {
    deleteMockedReservation() {
      this.fetchReservations()
    },
  },
}
</script>

<template>
  <div v-if="loading" class="py-20 text-center">
    <base-loader />
  </div>
  <div
    v-else-if="claims"
    class="mx-auto mb-6 max-w-96 px-5 pt-6 sm:max-w-3xl sm:pt-10"
  >
    <div v-if="claims.length" class="mb-[15px] font-bold text-eonx-neutral-800">
      Manage my current reservations
    </div>
    <div
      v-if="claims && claims.length"
      class="gap-y-5 sm:grid sm:grid-cols-2 sm:gap-x-[30px]"
    >
      <qr-reservation-item
        v-for="claim in claims"
        :key="claim.id"
        :claim="claim"
        @delete="deleteMockedReservation"
      />
    </div>
    <div v-else class="mt-6 flex flex-col items-center text-center">
      <img src="/images/qr/empty-events.svg" alt="empty" class="mb-[15px]" />
      <div class="mb-[5px] text-sm font-bold">
        You have no current reservations.
      </div>
      <div class="text-sm text-eonx-neutral-600">
        You can make a reservation by scanning
        <br />
        into or reserving a workspace.
      </div>
    </div>
  </div>
</template>
