<script>
import EntryMessage from './gallery/gallery-message-entry.vue'
import EntryComment from './gallery/gallery-comment-entry.vue'
import EntryPost from './gallery/gallery-post-entry.vue'

export default {
  name: 'rec-gallery-entry',
  props: {
    type: {
      type: String,
      required: true,
    },
    attachments: {
      type: Object,
      required: true,
    },
  },
  computed: {
    entryComponent() {
      if (this.type === 'message') return EntryMessage
      else if (this.type === 'post') return EntryPost
      else if (this.type === 'comment') return EntryComment
      return null
    },
  },
}
</script>

<template>
  <component
    :is="entryComponent"
    v-if="entryComponent"
    :attachments="attachments"
  />
</template>
