<script>
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'widget-tiles-optus-content',
  components: {
    BaseAction,
    BaseIcon,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <div class="relative flex h-full flex-col font-mark-w01 text-black">
    <base-action
      v-if="tile.link"
      v-bind="tile.link"
      origin="banner"
      class="w-full"
    >
      <img
        :src="
          cdn(tile.image.desktop)
            .preview('1496x808')
            .format('auto')
            .quality('smart')
            .url()
        "
        :alt="tile.image.alt"
        class="w-full rounded-xl"
      />
    </base-action>
    <div
      class="my-6 text-xl font-medium xs:text-2xl sm:text-xl"
      :style="{
        'line-height': 1.1,
      }"
      v-html="tile.content.text"
    />
    <base-action
      v-if="tile.link"
      v-bind="tile.link"
      origin="banner"
      class="mt-auto flex items-center"
      :style="{
        color: '#006280',
      }"
      appearance="link"
    >
      <span
        class="text-xl font-bold leading-none xs:text-2xl sm:text-xl"
        v-html="tile.link.label"
      />
      <base-icon svg="plain/chevron-right" :size="18" class="ml-[5px]" />
    </base-action>
    <div
      class="my-6 w-full bg-gray-300"
      :style="{
        height: '2px',
      }"
    />
  </div>
</template>
