<script>
import CheckoutSummaryItem from './checkout-summary-item.vue'

export default {
  name: 'checkout-summary-items',
  components: {
    CheckoutSummaryItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isConfirmation: {
      type: Boolean,
      default: false,
    },
    showPointsToBurn: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div v-if="items.length > 0" class="space-y-5">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="w-full"
      @click="$emit('item', item)"
    >
      <checkout-summary-item
        v-if="item"
        :item="item"
        :is-confirmation="isConfirmation"
        :show-points-to-burn="showPointsToBurn"
      />
    </div>
  </div>
</template>
