<script>
import ui from '/~/core/ui'
import QrMenu from '../../components/qr-menu.vue'
import QrMyReservations from '../../components/qr-my-reservations.vue'

export default {
  name: 'qr-main',
  components: {
    QrMenu,
    QrMyReservations,
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    banner() {
      return ui.mobile
        ? '/images/qr/banner-v2-mobile.png'
        : '/images/qr/banner-v2.png'
    },
  },
}
</script>

<template>
  <div>
    <div class="relative overflow-hidden bg-primary sm:rounded-t-3xl">
      <div class="absolute inset-0">
        <img
          class="h-full w-full object-cover object-top"
          :src="banner"
          alt=""
        />
        <div
          class="absolute inset-0 bg-primary"
          style="mix-blend-mode: multiply; opacity: 0.8"
          aria-hidden="true"
        />
      </div>
      <div
        class="relative mx-auto h-56 max-w-7xl px-5 py-24 sm:py-20 sm:px-[30px] lg:px-10"
      />
    </div>
    <qr-menu />
    <qr-my-reservations />
  </div>
</template>
