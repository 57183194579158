<script setup lang="ts">
import cdn from '/~/utils/cdn'
import { formatNumber } from '/~/utils/format/numeric'
import GreenBox from '/~/templates/australiapost/components/green-box/green-box.vue'
import QualityLeadsBanner from '/~/templates/australiapost/components/quality-leads-banner/quality-leads-banner.vue'

const steps = [
  {
    title: '1. Spot your leads',
    text: "Find out who your customer is, where they're based and exactly what the new business opportunity is.",
    image: cdn('//ucarecdn.com/e8acd364-213a-4855-a3c8-768128d5c3e6/')
      .crop('320x250')
      .preview('1200x400')
      .format('auto')
      .quality('smart')
      .url(),
  },
  {
    title: '2. Submit your leads',
    text: 'Gather as much customer information as possible and go to the <a class="text-blue-500 hover:text-blue-400" href="/lead-submission">Submit a Lead</a> section.',
    image:
      'https://ucarecdn.com/31592a73-ef3b-4aad-b737-f7cf4886c979/Frame2.png',
  },
  {
    title: '3. Receive email updates',
    text: `
            <p>We'll email you regularly to let you know the status of your lead.</p>
            <p>This is where your lead goes to our sales verification team, and will be verified based on the eligibility criteria (<a class="text-blue-500 hover:text-blue-400" href="/quality-leads">See Spotting Leads page</a>).</p>
          `,
    points: `
            <ul class="space-y-[15px] list-disc list-inside">
              <li>The team will try and call the customer 3 times over 3 days, if the customer is reached the qualification conversation will start. If not, your lead will be transferred to a local Business Services Manager to fullow-up. This could take up to a few weeks for your lead to be closed out.</li>
              <li>The lead will be closed disqualified if our team could not convert the lead to new business or if the customer was after information only. No points will be awarded, you'll receive a Seeker email indicating why your lead wasn't converted.</li>
            </ul>`,
    image:
      'https://ucarecdn.com/a1858e1a-a188-42f3-92f4-2b2b7093a364/Frame21.png',
  },
  {
    title: '4. Earn your points',
    text: "If your lead's eligible and wins business for us you'll earn points. Points are awarded fortnightly and as soon as you receive them you can start spending them!",
    points: `
            <ul class="space-y-[15px] list-disc list-inside">
              <li>Your lead has now been converted to an opportunity. This means that it has now been transferred to a sales manager to work on.</li>
              <li>Depending on the complexity of your opportunity, it could take between 2 and 8 weeks to close.</li>
              <li>After this, there are 3 possible outcomes:
                <ul class="pt-5 ml-5 space-y-[15px] list-disc list-inside">
                  <li>Your opportunity has been closed as disqualified (you'll receive 50 points) – has made it to a certain stage in the sales process but there was no further opportunity</li>
                  <li>Your opportunity has been closed as lost (you'll receive 100 points) – has made it a bit further along the sales process but there was no further opportunity</li>
                  <li>Your opportunity has been closed as won (you'll receive points, see table below) – congratulations the opportunity was successful.</li>
                </ul>
              </li>
            </ul>
          `,
    image:
      'https://ucarecdn.com/fa1a3eb0-3475-41a7-a951-8a0fc25cfdc2/Frame2.png',
  },
  {
    title: '5. Get your rewards',
    text: 'Choose from over 3,000 rewards in our huge Seeker store, including technology, beauty products, fashion and holidays.',
    image: cdn('//ucarecdn.com/cd2ccd45-ca51-4997-b8b9-ba5e76c90f80/')
      .crop('320x250')
      .preview('1200x400')
      .format('auto')
      .quality('smart')
      .url(),
  },
]
const earnPoints = {
  head: [['Stage', 'Points']],
  body: [
    ['New Lead – Verified', 100],
    ['Opportunity – Closed Disqualified/Lost', 50],
    ['Opportunity – Closed Won (Up to $1,999)', 150],
    ['Opportunity – Closed Won ($2,000 – $9,999)', 500],
    ['Opportunity – Closed Won ($10,000 – $19,999)', 1000],
    ['Opportunity – Closed Won ($20,000 – $29,999)', 2000],
    ['Opportunity – Closed Won ($30,000 – $49,999)', 3000],
    ['Opportunity – Closed Won ($50,000 – $99,999)', 5000],
    ['Opportunity – Closed Won ($100,000 to $299,999)', 7000],
    ['Opportunity – Closed Won ($300,000 to $499,999)', 9000],
    ['Opportunity – Closed Won ($500,000 to $799,999)', 12000],
    ['Opportunity – Closed Won ($800,000 to $999,999)', 15000],
    ['Opportunity – Closed Won ($1,000,000 to 1,299,999)', 18000],
    ['Opportunity – Closed Won ($1,300,000 to $1,499,999)', 22000],
  ],
}
const earnPointsBusiness = {
  head: [['MyPost Business Stages', 'New']],
  body: [
    ['MyPost Business Registration', 100],
    ['$1 - $499 spend', 75],
    ['$500 - $1,999 spend', 200],
    ['$2,000 - $4,999 spend', 400],
    ['$5,000 - $7,999 spend', 600],
    ['$8,000 - $11,999 spend', 1000],
    ['$12,000 - $14,999 spend', 1500],
    ['$15,000 - $24,999 spend', 1800],
    ['$25,000 - $39,999 spend', 2500],
    ['$40,000+ spend', 3500],
  ],
}
const earnPointsBusinessNew = {
  head: [['MPB Spend', 'Points']],
  body: [
    ['$100 - $499', 300],
    ['$500 - $999', 500],
    ['$1,000 - $1,999', 700],
    ['$2,000 - $3,999', 900],
    ['$4,000 - $9,999', 1200],
    ['$10,000+', 2000],
  ],
}
</script>
<template>
  <div class="bg-gray-50">
    <div
      class="mx-auto w-full max-w-6xl space-y-20 py-[30px] text-sm sm:px-2.5"
    >
      <div class="relative min-h-60">
        <div class="absolute px-[30px]">
          <div
            class="mt-11 text-4xl font-semibold leading-none text-white xs:mt-10 xs:text-3xl sm:mt-11 sm:text-4xl sm:leading-12 md:mt-20 md:text-5xl"
          >
            How seeker works
          </div>
          <div
            class="mt-2.5 text-xl font-semibold text-white xs:mt-0 md:mt-2.5"
          >
            Find out everything you need to know
          </div>
        </div>
        <img
          class="hidden xs:block"
          :src="
            cdn('//ucarecdn.com/e23d02fd-565f-45e6-825c-dcf1e4219d48/')
              .crop('1392x348')
              .preview('1200x400')
              .format('auto')
              .quality('smart')
              .url()
          "
        />
        <img
          class="w-full xs:hidden"
          :src="
            cdn('//ucarecdn.com/14a9f16d-ac56-48b2-8dbd-90afb11e50ea/')
              .crop('414x381')
              .preview('1200x400')
              .format('auto')
              .quality('smart')
              .url()
          "
        />
      </div>

      <div class="mx-5 max-w-5xl space-y-8 sm:mx-11">
        <h1 class="text-center text-3xl text-red-700">What is Seeker?</h1>
        <p class="mx-auto text-2xl text-eonx-neutral-600">
          Seeker is our exciting points based lead referral program that rewards
          you for seeking out new business opportunities. Whatever part of our
          organisation you work in, leads can be spotted by everyone,
          everywhere, every day.
        </p>
        <video class="mx-auto w-full xs:max-w-3xl" controls>
          <source
            src="https://ucarecdn.com/fd87376b-4aab-412a-808d-cf2f302e420d/Seeker_Anim_720p_HQ.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="mx-5 max-w-5xl space-y-8 sm:mx-11">
        <h1 class="text-center text-3xl text-red-700">How does it work?</h1>
        <div>
          <green-box
            v-for="(step, i) in steps"
            :key="i"
            :item="step"
            class="mx-5 mb-2.5 sm:mx-11"
          />
        </div>
      </div>

      <div class="mx-5 max-w-5xl space-y-8 sm:mx-11">
        <h1 class="text-center text-3xl text-red-700">
          How many points can you earn for contracts?
        </h1>
        <p class="text-2xl text-eonx-neutral-600">
          For every lead you submit that turns into new business for us, you'll
          earn points. The number of points you're awarded depends on how much
          the new business is worth.
        </p>
        <table class="mt-12 w-full text-left text-lg text-eonx-neutral-800">
          <thead>
            <tr
              v-for="(row, rowIndex) in earnPoints.head"
              :key="rowIndex"
              class="bg-gray-300"
            >
              <th
                v-for="(col, colIndex) in row"
                :key="colIndex"
                class="px-5 py-2.5 sm:px-12"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in earnPoints.body"
              :key="rowIndex"
              class="even:bg-gray-300"
            >
              <td class="px-5 py-2.5 sm:px-12">
                {{ row[0] }}
              </td>
              <td class="w-2.5 px-5 py-2.5 sm:px-12">
                {{ formatNumber(row[1]) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mx-5 max-w-5xl space-y-8 sm:mx-11">
        <h1 class="text-center text-3xl text-red-700">
          How many points can you earn for MyPost Business?
        </h1>
        <p class="text-2xl text-eonx-neutral-600">
          For every lead you submit that turns into a new MyPost Business
          customer, you'll earn points. The number of points you're awarded
          depends on whether the customer registers within 15 days of the lead
          being submitted and their
          <b>business spend</b>
          in the first 8 weeks.
        </p>
        <table class="w-full text-left text-lg text-eonx-neutral-800">
          <thead>
            <tr
              v-for="(row, rowIndex) in earnPointsBusinessNew.head"
              :key="rowIndex"
              class="bg-gray-300"
            >
              <th
                v-for="(col, colIndex) in row"
                :key="colIndex"
                class="px-5 py-2.5 sm:px-12"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in earnPointsBusinessNew.body"
              :key="rowIndex"
              class="even:bg-gray-300"
            >
              <td class="px-5 py-2.5 sm:px-12">
                {{ row[0] }}
              </td>
              <td class="h-12 w-2.5 px-5 py-2.5 sm:px-12">
                {{ row[1] ? formatNumber(row[1]) : '' }}
              </td>
            </tr>
          </tbody>
        </table>
        <p class="text-center text-lg text-eonx-neutral-600">
          *Registered to MyPost Business within 15 days of lead submitted and
          business spend within the first 8 weeks from registration
        </p>
      </div>

      <div class="mx-5 max-w-5xl space-y-8 sm:mx-11">
        <h1 class="text-center text-3xl text-red-700">
          Points balance and rewards
        </h1>
        <p class="text-2xl text-eonx-neutral-600">
          Check your points balance online within 'My Leads' or the homepage. As
          soon as you receive your points you can spend them in the rewards
          store or save up for something special. Choose from thousands of
          goodies including the latest technology, fragrances, beauty products,
          sports equipment, holidays and more.
        </p>
      </div>

      <quality-leads-banner show-button />
    </div>
  </div>
</template>
