<script setup lang="ts">
import isEmpty from 'lodash-es/isEmpty'
import { nanoid } from 'nanoid'
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import ui from '/~/core/ui'
import BaseDrawer from '/~/components/base/drawer/base-drawer.vue'
import { useDrawerMenu } from '/~/components/drawer/use-drawer-menu'
import { useUI } from '/~/composables/ui'

const { getMatchingRoute } = useDrawerMenu()

const props = withDefaults(
  defineProps<{
    routes?: any[]
    prefix: string
    portalTarget: string
    isVisible: boolean
    isLoading?: boolean
  }>(),
  {
    routes: () => [],
    isLoading: false,
  }
)

const emit = defineEmits<{
  (event: 'toggle', value: boolean): void
}>()

const route = useRoute()
const router = useRouter()

const { lockKeyboardAccessArea, unlockKeyboardAccessArea } = useUI()

const transitionView = ref([])
const previousActiveElement = ref(null)
const menuReference = ref(null)

// MenuHome is a root view
const MenuHomeComponent = {
  viewComponent: props.routes[0]?.component ?? null,
  appear: 'right',
  pathParam: '',
}

const animationDirection = ref('forward')
const menuViewsStack = ref([MenuHomeComponent])
const menuViewsStackCopy = ref([MenuHomeComponent])

const appearAnimation = computed(() => {
  const lastIndex = Math.max(menuViewsStackCopy.value.length - 1, 0)
  const lastView = menuViewsStackCopy.value[lastIndex]

  return lastView.appear
})

async function close() {
  emit('toggle', false)

  menuViewsStack.value = [MenuHomeComponent]
  menuViewsStackCopy.value = [MenuHomeComponent]

  bottomSheet.hideAll()

  if (!transitionView.value || !transitionView.value.length) {
    return
  }

  unlockKeyboardAccessArea(
    transitionView.value?.[menuViewsStack.value.length - 2]
      ?.keyboardAccessAreaId
  )
}

async function handleHashRouting(newHash) {
  const [, ...pathParams] = newHash.split('/')
  const routeObject = getMatchingRoute(props.routes, newHash)

  if (!routeObject) {
    return
  }

  const pathParam = pathParams.join('/')
  const menuViewsStackLength = menuViewsStack.value.length
  const viewComponent = routeObject.component
  const viewComponentIndex = menuViewsStack.value.findIndex((v) => {
    return v.viewComponent === viewComponent
  })

  if (viewComponentIndex !== -1) {
    menuViewsStack.value[viewComponentIndex].pathParam = pathParam
    menuViewsStack.value.splice(viewComponentIndex + 1)
    animationDirection.value = 'backward'
  } else {
    const { meta, props = {} } = routeObject
    const { appear } = meta ?? {}

    menuViewsStack.value.push({
      appear,
      viewComponent: viewComponent,
      pathParam,
      meta,
      props,
    })
    animationDirection.value = 'forward'
    menuViewsStackCopy.value = menuViewsStack.value.concat([])
  }

  previousActiveElement.value = document.activeElement

  await nextTick()

  if (viewComponentIndex > -1 && menuViewsStackLength === 2) {
    updateA11y(false)
  }
}

function updateA11y(isNewViewAdded) {
  const keyboardAccessAreaId = nanoid()
  const currentTransitionView =
    transitionView.value[transitionView.value.length - 1]

  if (isNewViewAdded) {
    currentTransitionView.keyboardAccessAreaId = keyboardAccessAreaId

    lockKeyboardAccessArea({
      id: keyboardAccessAreaId,
      rootElement: currentTransitionView.$el,
      previousActiveElement:
        previousActiveElement.value ?? document.activeElement,
      delay: 400,
    })
  } else {
    unlockKeyboardAccessArea()
  }
}

function catchDuplicationError(error) {
  if (error.name !== 'NavigationDuplicated') {
    throw error
  }
}

function afterLeave() {
  if (menuViewsStack.value.length < menuViewsStackCopy.value.length) {
    menuViewsStackCopy.value = menuViewsStack.value.concat([])
  }
}

watch(
  () => route.hash,
  (newHash) => {
    const routeObject = getMatchingRoute(props.routes, newHash)

    if (routeObject) {
      emit('toggle', !!newHash)
      handleHashRouting(newHash)
    } else {
      close()
    }
  },
  {
    immediate: true,
  }
)

watch(
  () => props.isVisible,
  (isVisible) => {
    if (isVisible) {
      ui.lockViewport()

      nextTick()
        .then(() => {
          const { hash } = route

          if (hash && hash !== '') {
            handleHashRouting(hash)
          } else {
            router.push({ hash: `#${props.prefix}-home` })
          }
        })
        .catch(catchDuplicationError)
    } else {
      if (menuReference.value) {
        menuReference.value.scrollTop = 0
        menuReference.value.scrollLeft = 0
      }

      const { hash = '' } = route

      if (hash.startsWith(`#${props.prefix}`)) {
        router.replace({ hash: '' }).catch(catchDuplicationError)
      }

      ui.unlockViewport()
    }
  }
)

watch(route, (to, from) => {
  if (isEmpty(to.query)) {
    if (to.meta?.quickBuy) {
      router.push({ hash: to.hash, query: from.query })
    }
  }
})
</script>

<template>
  <base-drawer :visible="isVisible" @close="close">
    <transition-group
      :id="`${prefix}-menu`"
      ref="menuReference"
      name="menu-slide"
      tag="aside"
      class="h-full w-full"
      @after-leave="afterLeave"
    >
      <div
        v-for="(view, index) in menuViewsStackCopy"
        v-show="index === menuViewsStack.length - 1"
        :id="view.pathParam || `${index}-${prefix}-menu`"
        :key="`${index}_v`"
        ref="transitionView"
        class="flex h-full flex-1 flex-col overflow-y-auto"
        :class="[appearAnimation, animationDirection]"
        :data-index="index"
      >
        <component
          :is="view.viewComponent"
          v-bind="view.props"
          :close="close"
          :data-index="index"
          :meta="view.meta"
          :is-loading="isLoading"
          @hook:mounted="updateA11y(true)"
        />
      </div>
    </transition-group>

    <portal-target :name="portalTarget" multiple />
  </base-drawer>
</template>
