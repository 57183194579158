<script setup lang="ts">
import { useUser, useUserEvents } from '/~/composables/user'
import TermsConditions from './terms-conditions.vue'

const { user } = useUser()
const { termsAndConditionsVisited } = useUserEvents()

termsAndConditionsVisited({ type: 'page' })
</script>

<template>
  <div
    class="mx-auto w-full max-w-5xl bg-light px-5 pb-11 sm:px-[30px]"
    :class="{
      'overflow-y-auto': !user.authorized,
    }"
  >
    <h1 class="my-[30px] text-2xl sm:mb-11 sm:text-3xl">
      Terms and Conditions
    </h1>
    <terms-conditions />
  </div>
</template>
