<script>
import { ValidationObserver } from 'vee-validate'
import { computed, ref, nextTick } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import { useForm } from '/~/composables/base/use-form'
import { useLocalization } from '/~/composables/localization'
import { usePayeeBankAccountForm, usePayees } from '/~/composables/payees'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'payments-payees-bank-account-form',
  components: {
    BaseButton,
    BaseMetafield,
    ValidationObserver,
  },
  setup() {
    const { providerTitle, isCaProvider } = useProvider()
    const { payees, createPayee, getPayeeFromNumber } = usePayees()
    const { getMask, translate } = useLocalization()
    const { bankAccountSchema, form, initiateValues, showFormDescription } =
      usePayeeBankAccountForm()
    const { validationObserverRef } = useForm()

    const bankAccountForm = ref(null)
    const submitting = ref(false)

    const businessNumberMask = computed(() => getMask('businessNumber'))

    return {
      isCaProvider,
      payees,
      businessNumberMask,
      validationObserverRef,
      createPayee,
      bankAccountForm,
      bankAccountSchema,
      form,
      initiateValues,
      submitting,
      showFormDescription,
      translate,
      providerTitle,
      getPayeeFromNumber,
    }
  },
  computed: {
    formattedAccountNumber() {
      const length = 9
      const { accountNumber } = this.form

      return '0'.repeat(length - accountNumber.length) + accountNumber
    },
  },
  created() {
    this.form = { ...this.initiateValues }
  },
  methods: {
    handleFieldSubmit({ field }) {
      if (field.key === 'abn') {
        this.onBusinessNumberEnter()
      }
    },
    processBackendErrors(data) {
      const { validationErrors } = data
      const backendErrors = {}

      if (validationErrors) {
        for (const key in validationErrors) {
          backendErrors[
            (this.bankAccountSchema, key.replace('payeeMetadata.', ''))
          ] = validationErrors[key] || []
        }
        this.validationObserverRef?.setErrors(backendErrors)
      }
    },
    async onSubmit() {
      const isValid = await this.validationObserverRef?.validate()

      this.validationObserverRef?.setErrors({})

      if (this.payees.creating || !isValid) return

      try {
        const payload = { ...this.form }

        if (!this.isCaProvider) {
          payload.accountNumber = this.formattedAccountNumber
        }

        await this.createPayee(payload)
        this.$emit('success')
      } catch (error) {
        this.processBackendErrors(error.data || error)
      }
    },
    async onBusinessNumberEnter() {
      this.validationObserverRef?.setErrors({})

      if (this.form.abnName) {
        this.form = { ...this.initiateValues }
        this.validationObserverRef?.reset()
      } else {
        this.submitting = true
        await this.validationObserverRef?.refs.abn.validate()

        const validationResult =
          window.BUSINESS_NUMBERS_DICT[this.form.abn]?.data ?? {}

        const { entityName } = validationResult?.response ?? {}

        const { message, failedEntityName } = validationResult ?? {}

        if (entityName) {
          this.form.name = entityName
          this.form.abnName = entityName
          nextTick(() => {
            this.validationObserverRef?.refs.name.validate()
          })
        } else {
          this.validationObserverRef?.setErrors({
            abn: [
              message
                ? message
                : !this.form.abn
                ? `The ${this.translate(
                    'payment.businessNumber'
                  )} field is required`
                : `The ${this.translate(
                    'payment.businessNumber'
                  )} field is invalid or not found`,
            ],
          })
          this.form.abnName = failedEntityName
        }
        this.submitting = false
      }
    },
  },
}
</script>

<template>
  <div class="overflow-y-auto px-5 sm:px-0">
    <validation-observer
      v-slot="{ invalid }"
      ref="validationObserverRef"
      tag="form"
      class="mx-auto flex h-full w-full max-w-lg flex-col justify-between px-2.5"
      :class="{ 'pt-8': !showFormDescription }"
      @submit.prevent="onSubmit"
    >
      <div>
        <div v-if="showFormDescription" class="my-8 text-neutral-600">
          {{ translate('payees.formDescription', { providerTitle }) }}
        </div>
        <base-metafield
          ref="bankAccountForm"
          v-model="form"
          :submitting="submitting"
          :schema="bankAccountSchema"
          :is-disabled="payees.creating"
          class="grow"
          @field-submit="handleFieldSubmit"
        />
      </div>
      <div
        class="sticky bottom-0 mx-auto w-full max-w-lg bg-eonx-neutral-50 py-2.5"
      >
        <base-button
          :loading="payees.creating"
          :disabled="invalid"
          class="mt-auto"
          full-width
          type="submit"
        >
          Add
        </base-button>
      </div>
    </validation-observer>
  </div>
</template>
