<script>
import appConfig from '/~/config'
import LocationMarker from './markers/_location.vue'

export default {
  name: 'base-map',
  props: {
    /*
     * Instance of the MapWorker class. See ~/core/map
     */
    state: {
      type: Object,
      required: true,
    },
    markers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gmap: null,
      renderedMarkers: {},
      infoWindow: null,
      /*
       * Vue components rendered in the InfoWindow layer
       */
      infoWindowComponents: [],
    }
  },
  watch: {
    'state.place'(place) {
      this.gmap.fitBounds(place.geometry.bounds)
    },
    markers: {
      handler() {
        this.setMarkers()
      },
      deep: true,
    },
  },
  async mounted() {
    if (!this.state.initialized) {
      await this.state.init()
    }
    this.init()
  },
  methods: {
    async init() {
      const { state } = this
      const { Map, InfoWindow } = window.google.maps

      const infoWindow = new InfoWindow({
        maxWidth: 200,
      })

      const gmap = new Map(this.$refs.map, {
        center: state.center,
        zoom: state.zoom,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        gestureHandling: 'greedy',
        ...(this.options || {}),
      })

      if (!state.center && state.place) {
        gmap.fitBounds(state.place.geometry.bounds)
      }

      gmap.addListener('idle', () => {
        const center = gmap.getCenter()
        const bounds = gmap.getBounds()

        if (center) this.state.setCenter(center)
        if (bounds) this.state.setBounds(bounds)
      })

      gmap.addListener('zoom_changed', () => {
        state.setZoom(gmap.getZoom())
      })

      gmap.addListener('click', () => {
        infoWindow.close()
        this.clearInfoWindowComponents()
      })

      this.gmap = gmap
      this.infoWindow = infoWindow
      this.setMarkers()
    },
    setMarkers() {
      const { infoWindow } = this
      const { mapMarkerIcons } = appConfig
      const { Marker } = window.google.maps
      const newMarkers = {}

      this.markers.forEach((item) => {
        if (!item.id) {
          return console.warn('marker should have id')
        }

        const renderedMarker = this.renderedMarkers[item.id]

        if (renderedMarker) {
          newMarkers[item.id] = renderedMarker
          return
        }

        const marker = new Marker({
          position: {
            lat: Number(item.position.lat),
            lng: Number(item.position.lng),
          },
          map: this.gmap,
          icon: mapMarkerIcons[item.color] || mapMarkerIcons.default,
        })

        marker.addListener('click', () => {
          const div = document.createElement('div')
          const node = new LocationMarker({
            propsData: {
              marker: item,
            },
          }).$mount(div)

          infoWindow.setContent(node.$el)
          infoWindow.open(this.gmap, marker)
          this.clearInfoWindowComponents()
          this.infoWindowComponents.push(node)
        })

        newMarkers[item.id] = marker
      })

      /*
       * Remove out of boundaries markers
       */
      for (const locId in this.renderedMarkers) {
        const needToRemove = !newMarkers[locId]

        if (needToRemove) {
          const marker = this.renderedMarkers[locId]

          marker.setMap(null)
        }
      }

      this.renderedMarkers = newMarkers
    },
    clearInfoWindowComponents() {
      this.infoWindowComponents.forEach((node) => {
        node.$destroy()
        node.$el.remove()
      })
      this.infoWindowComponents = []
    },
  },
}
</script>

<template>
  <div>
    <transition name="fade">
      <div ref="map" class="h-full w-full" />
    </transition>
  </div>
</template>
