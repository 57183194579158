import get from 'lodash-es/get'
import { RecroomEarn } from './earn'

export class RecroomEarnConfig extends RecroomEarn {
  constructor(raw = {}) {
    super(raw)
  }

  get maxEarnValue() {
    return get(this.raw, this.currency)
  }

  get earnValue() {
    return this.raw.earn_value
  }
}
