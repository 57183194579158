<script>
import { ref, computed } from 'vue'
import cdn from '/~/utils/cdn'
import { formatNumber } from '/~/utils/format/numeric'
import { isEmptyText } from '/~/utils/format/string'
import BaseAction from '/~/components/base/action/base-action.vue'

export default {
  name: 'widget-billpay-home-right-banner',
  components: {
    BaseAction,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Object,
      default: () => ({}),
    },
    backgroundImage: {
      type: Object,
      default: () => ({}),
    },
    bannerAction: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    side: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const banner = ref(0)
    const bannerStyle = computed(() => ({
      color: props.textColor,
      textAlign: props.info.contentAlignmentX,
      backgroundImage: `url(${cdn(props.backgroundImage.desktop)
        .preview('600x812')
        .format('auto')
        .quality('smart')
        .url()})`,
      backgroundColor: props.backgroundColor,
    }))

    return {
      isEmptyText,
      formatNumber,
      bannerStyle,
      banner,
    }
  },
}
</script>

<template>
  <section>
    <base-action
      v-bind="bannerAction"
      class="block hover:opacity-90"
      origin="banner"
      appearance="link"
    >
      <div
        ref="banner"
        class="flex flex-col items-center rounded-3xl bg-right bg-no-repeat md:max-w-[300px]"
        :class="{ 'bg-contain': banner.clientHeight > 300 }"
        :style="bannerStyle"
      >
        <div class="p-5">
          <div
            v-if="!isEmptyText(info.title)"
            class="block text-2xl font-semibold"
            v-html="info.title"
          />
          <div
            v-if="!isEmptyText(info.text)"
            class="mt-[15px] block text-sm"
            v-html="info.text"
          />
          <div
            v-if="!isEmptyText(list.title)"
            class="block text-xl font-semibold"
            v-html="list.title"
          />
          <div
            v-if="!isEmptyText(list.text)"
            class="mt-[15px] block text-lg font-bold leading-8"
            v-html="list.text"
          />
        </div>
        <div class="flex space-x-2.5 px-5 pb-[15px]">
          <div v-for="(item, index) of items" :key="index">
            <img
              :key="item.id"
              :src="item.image.desktop"
              :alt="item.image.alt"
              class="w-auto"
            />
          </div>
        </div>
      </div>
    </base-action>
  </section>
</template>
