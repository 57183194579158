<script>
import DefaultEstoreTilePrice from '/~/extensions/estore/components/tile/estore-tile-price.vue'
import { formatPoints } from '/~/utils/points'

export default {
  extends: DefaultEstoreTilePrice,
  computed: {
    pointsToBurn() {
      return this.calculatePointsToBurnOrder(
        this.currentPrice + this.shippingTax
      )
    },
    formattedPointsToBurn() {
      return formatPoints(this.pointsToBurn)
    },
  },
}
</script>

<template>
  <div
    v-if="currentPrice"
    class="flex flex-col items-center overflow-hidden rounded-b-lg"
  >
    <span
      class="text-sm text-eonx-neutral-600"
      :class="{
        'mb-[15px]': !showPointsToBurn,
      }"
      data-cy="calculation-amount"
    >
      {{ formatDollar(currentPrice) }}
    </span>
    <template v-if="isPointsAvailable && showPointsToBurn">
      <span
        class="relative mt-[5px] mb-[15px] flex h-[35px] items-center overflow-hidden whitespace-nowrap rounded-full bg-red-400/25 px-[15px] font-bold text-primary"
      >
        <div>
          <span class="text-xl">
            {{ formattedPointsToBurn }}
          </span>
          <span class="text-xs">PTS</span>
        </div>
      </span>
    </template>
  </div>
</template>
