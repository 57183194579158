export default {
  // award
  'rec-token-transactions': () => import('./award/token-transactions.vue'),
  'rec-award-details-mobile': () => import('./award/award-details-mobile.vue'),
  'rec-award-nominee-mobile': () => import('./award/award-nominee-mobile.vue'),
  'rec-token-transactions-mobile': () =>
    import('./award/token-transactions-mobile.vue'),
  'rec-award-nominate-mobile': () =>
    import('./award/award-nominate-mobile.vue'),
  'rec-award-nominate-user-mobile': () =>
    import('./award/award-nominate-user-mobile.vue'),
  // chat
  'rec-chat-popup': () => import('./chat/chat-popup.vue'),
  'rec-chat-leave': () => import('./chat/chat-leave.vue'),
  'rec-chat-delete': () => import('./chat/chat-delete.vue'),
  'rec-chat-settings': () => import('./chat/chat-settings.vue'),
  'rec-chat-offensive-warning': () =>
    import('./chat/chat-offensive-warning.vue'),
  'rec-chat-create': () => import('./chat/chat-create.vue'),
  // comment
  'rec-comment-post': () => import('./comment/comment-post.vue'),
  'rec-comment-report': () => import('./comment/comment-report.vue'),
  'rec-comment-delete': () => import('./comment/comment-delete.vue'),
  // earn
  'rec-earn': () => import('./earn/earn.vue'),
  'rec-earn-post': () => import('./earn/earn-post.vue'),
  'rec-earn-info': () => import('./earn/earn-info.vue'),
  'rec-earn-transactions': () => import('./earn/earn-transactions.vue'),
  // event
  'rec-event-form': () => import('./event/event-form.vue'),
  'rec-event-delete': () => import('./event/event-delete.vue'),
  'rec-event-details': () => import('./event/event-details.vue'),
  'rec-event-info-mobile': () => import('./event/event-info-mobile.vue'),
  // group
  'rec-group-leave': () => import('./group/group-leave.vue'),
  'rec-group-delete': () => import('./group/group-delete.vue'),
  'rec-group-form': () => import('./group/group-form.vue'),
  'rec-group-details': () => import('./group/group-details.vue'),
  'rec-group-info-mobile': () => import('./group/group-info-mobile.vue'),
  // high-five
  'rec-hi5-mobile': () => import('./hi5/hi5-mobile.vue'),
  'rec-hi5-points-gifting': () => import('./hi5/hi5-points-gifting.vue'),
  'rec-hi5-send-mobile': () => import('./hi5/hi5-send-mobile.vue'),
  'rec-hi5-send-to-mobile': () => import('./hi5/hi5-send-to-mobile.vue'),
  // leaving send hi5/nominate award mobile
  'rec-leaving-confirm': () => import('./leaving-confirm.vue'),
  // perk
  'rec-perk-details-mobile': () => import('./perk/perk-details-mobile.vue'),
  'rec-perk-request-approval': () => import('./perk/perk-request-approval.vue'),
  // post
  'rec-post-delete': () => import('./post/post-delete.vue'),
  'rec-post-report': () => import('./post/post-report.vue'),
  'rec-post-quiz-qa': () => import('./post/post-quiz-qa.vue'),
  'rec-post-creator': () => import('./post/post-creator.vue'),
  'rec-quiz-leave': () => import('./post/quiz/quiz-leave.vue'),
  'rec-post-reporters': () => import('./post/post-reporters.vue'),
  'rec-post-disregard': () => import('./post/post-disregard.vue'),
  'rec-post-edit-warn': () => import('./post/post-edit-warn.vue'),
  'rec-poll-voter-list': () => import('./post/poll/poll-voter.vue'),
  'rec-post-announcement': () => import('./post/post-announcement.vue'),
  'rec-quiz-submissions': () => import('./post/quiz/quiz-submissions.vue'),
  'rec-post-creator-mobile': () => import('./post/post-creator-mobile.vue'),
  'rec-post-nominate-success': () => import('./post/post-nominate-success.vue'),
  'rec-post-offensive-warning': () =>
    import('./post/post-offensive-warning.vue'),
  'rec-post-earn-config': () =>
    import('./post/earn-config/post-earn-config.vue'),
  // users
  'rec-groups-manager': () => import('./users/groups-manager.vue'),
  'rec-members-manager': () => import('./users/members-manager.vue'),
  'rec-directory-profile': () => import('./users/directory-profile.vue'),
  'rec-users-manager': () => import('./users/users-manager.vue'),
  'rec-user-info': () => import('./users/user-info.vue'),
}
