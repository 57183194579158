<script setup lang="ts">
import { computed } from 'vue'
import DatePicker from 'vue2-datepicker'
import { useLocalization } from '/~/composables/localization'

type RangeValue = string | null

const props = withDefaults(
  defineProps<{
    title?: string
    value?: RangeValue[]
  }>(),
  {
    value: () => [null, null],
  }
)

const { getDatePickerFormat } = useLocalization()
const datepickerFormat = computed(() =>
  getDatePickerFormat('daymonthyearnumeric')
)

const localValue = computed({
  get: () => props.value,
  set: (value) => {
    emit('input', value)
  },
})

const emit = defineEmits<{
  (event: 'input', value: RangeValue[]): void
}>()
</script>

<template>
  <div class="px-5 py-2.5">
    <div v-if="title" class="my-2.5 font-bold">
      {{ title }}
    </div>

    <date-picker
      v-model="localValue"
      data-testid="datepicker"
      :placeholder="datepickerFormat.toLowerCase()"
      :clearable="false"
      :first-day-of-week="1"
      value-type="format"
      :format="datepickerFormat"
      lang="en"
      range
      inline
      is-fullwidth
      class="ew-date-range"
    />
  </div>
</template>

<style lang="postcss">
.ew-date-range :not(:first-child).mx-calendar.mx-calendar-panel-date {
  @apply hidden;
}

.ew-date-range .mx-calendar.mx-calendar-panel-date {
  @apply w-full p-0;
}

.ew-date-range .mx-datepicker-main {
  @apply border-none;
}
</style>
