<script>
import Uploadcare from 'uploadcare-vue'
import { fileSizeRestriction } from './restrictions/file-size-restriction'
import {
  fileTypeRestriction,
  extensions,
} from './restrictions/file-type-restriction'

export default {
  name: 'base-uploader',
  components: {
    Uploadcare,
  },
  props: {
    tabs: {
      type: String,
      default: 'file url camera',
    },
    camera: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 100 * 1024 * 1024,
    },
    fileTypes: {
      type: Array,
      default: () => Object.values(extensions),
    },
    validators: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    uploadCareKey() {
      /*
       * Replace it with action user/GET_UPLOAD_KEY
       * See profile-picture-edit.modal.vue as example
       */
      return 'c02a7caa705bc76eead2'
    },
    uploadValidators() {
      const validators = []

      if (this.maxSize) validators.push(fileSizeRestriction(this.maxSize))
      if (this.fileTypes) validators.push(fileTypeRestriction(this.fileTypes))

      validators.push(...this.validators)

      return validators
    },
    uploadTabs() {
      if (this.camera) {
        return 'camera file url'
      }

      return this.tabs
    },
  },
  methods: {
    getAttachmentType(mimeType) {
      let [type, additionalType] = mimeType.split('/')

      if (type === 'application') {
        switch (additionalType) {
          case extensions.PDF_EXTENSION:
            type = extensions.PDF_EXTENSION
            break
          default:
            type = 'file'
        }
      }

      return type
    },
    onSuccess(fileInfo) {
      try {
        this.$emit('update:loading', true)

        const type = this.getAttachmentType(fileInfo.mimeType)
        const size = fileInfo.size

        const attachment = {
          file_size: size && size.toString(),
          cdn: fileInfo.cdnUrl,
          name: fileInfo.name,
          type,
        }

        // Add video thumbnail
        this.$emit('upload', attachment)
      } catch (error) {
        console.error('base-uploader', error)
      } finally {
        this.$emit('update:loading', false)
      }
    },
    onError() {
      this.$emit('update:loading', false)
    },
    show() {
      this.$refs.upload.onClick()
      this.$emit('update:loading', true)
    },
  },
}
</script>

<template>
  <uploadcare
    ref="upload"
    :validators="uploadValidators"
    :public-key="uploadCareKey"
    :multiple="multiple"
    :tabs="uploadTabs"
    @success="onSuccess"
    @error="onError"
  />
</template>
