import Vue, { ref, computed } from 'vue'
import {
  ApiResponseList,
  PaymentOrderList,
  ScheduledOrderRawData,
} from '/~/types/api'
import api from '/~/core/api'
import { createDate, formatDate } from '/~/utils/format/date'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useExtensions } from '/~/composables/extensions'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { ScheduledPayment } from '/~/composables/scheduled-payments/core/ScheduledPayment'
import Statement from '/~/composables/statements/core/Statement'

const { isBillPaymentsTemplate } = useProvider()
const { getConfigByName } = useExtensions()
const { translate } = useLocalization()

const scheduledPaymentsConfig = computed(() => {
  return getConfigByName('scheduled-payments')
})

const isScheduledPaymentsEnabled = computed(() => {
  return scheduledPaymentsConfig.value?.makeAPayment.enabled ?? false
})
const scheduledPaymentsDateLimit = computed(() => {
  return scheduledPaymentsConfig.value?.makeAPayment.dateLimit ?? 1
})

const isScheduledPaymentsLoading = ref(false)
const scheduledPayments = ref([])
const scheduledPaymentsPagination = ref({
  totalItems: 0,
})

async function deleteScheduledPayment(number) {
  const paymentIndex = scheduledPayments.value.findIndex(
    (payment) => payment.number === number
  )

  if (paymentIndex < 0) {
    return
  }

  try {
    await scheduledPayments.value[paymentIndex].delete()
    scheduledPayments.value.splice(paymentIndex, 1)
    scheduledPaymentsPagination.value.totalItems--
  } catch (error) {
    Vue.notify({
      text: 'There was an error removing a scheduled payment',
      type: 'error',
      duration: 5000,
    })
  }
}

async function fetchScheduledPayments(options) {
  isScheduledPaymentsLoading.value = true

  const queryString = new URLSearchParams({
    ...(options.queryParams ?? {}),
    'statuses[]': 'scheduled',
  })

  let payments
  let response

  try {
    if (isBillPaymentsTemplate.value) {
      response = await api.get<ApiResponseList<ScheduledOrderRawData>>(
        `/v3/scheduled-orders?${queryString}`
      )

      payments = (response.data?.items ?? []).map((scheduledPayment) => {
        if (
          scheduledPayment.orderType === FlowType.statement &&
          !options.useScheduledPaymentsForStatements
        ) {
          return new Statement(scheduledPayment.order)
        }
        if (
          scheduledPayment.orderType === FlowType.payment ||
          options.useScheduledPaymentsForStatements
        ) {
          return new ScheduledPayment(scheduledPayment)
        }
      })
    } else {
      response = await api.get<ApiResponseList<PaymentOrderList>>(
        `/v3/payment-orders?${queryString}`
      )

      payments = (response.data?.items ?? []).map(
        (scheduledPayment) =>
          new ScheduledPayment({
            order: scheduledPayment,
            orderType: FlowType.payment,
          })
      )
    }

    if (options.appendResults) {
      scheduledPayments.value.push(...payments)
    } else {
      scheduledPayments.value = payments
    }

    scheduledPaymentsPagination.value = response.data?.pagination
  } catch (error) {
    console.error('scheduled-payment', error)
  } finally {
    isScheduledPaymentsLoading.value = false
  }
}

function resetScheduledPayments() {
  scheduledPayments.value = []
  scheduledPaymentsPagination.value = {
    totalItems: 0,
  }
}
export function useScheduledPayments() {
  const { isBatchOrderCurrentFlowType, isStatementCurrentFlowType, payment } =
    useCheckoutReactive()

  const scheduledPaymentDateFrom = computed(() =>
    createDate()
      .add(1, 'day')
      .utc(true)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
  )

  const scheduledPaymentDateTo = computed(() => {
    if (
      isStatementCurrentFlowType.value &&
      payment.value.statement?.blockedPaymentDate
    ) {
      return createDate(payment.value.statement.blockedPaymentDate).utc(true)
    }
    return createDate().utc(true).add(scheduledPaymentsDateLimit.value, 'day')
  })

  const dateToPay = ref(payment.value.date || scheduledPaymentDateFrom.value)
  const dateToPayFormatted = computed(() =>
    formatDate('daymonthyearnumeric', dateToPay.value)
  )

  const schedulePaymentDisabledMessage = computed(() => {
    if (isBatchOrderCurrentFlowType.value) {
      return 'Schedule for later is not available for batch payments.'
    } else if (isStatementCurrentFlowType.value) {
      if (payment.value.isDirectDebit) {
        return `Schedule for later is not available when ${translate(
          'directDebit.text'
        )} is enabled. You can still pay now or wait for the ${translate(
          'directDebit.text'
        )} to process.`
      }

      // Received from server in Australian timezone
      // '2024-03-29T00:30:00.000000+11:00'
      const blockedPaymentDateString =
        payment.value.statement?.blockedPaymentDate

      if (blockedPaymentDateString) {
        // extract the UTC offset from the string to convert the dates to Australian timezone
        const utcOffset = blockedPaymentDateString.slice(-6)

        const blockedPaymentDate = createDate(
          blockedPaymentDateString
        ).utcOffset(utcOffset)
        // createDate() returns '2024-04-04T23:30:00.000000+08:00'
        // utcOffset converts it to Australian timezone so date can be compared
        const currentDate = createDate().utcOffset(utcOffset)

        // blockedPaymentDate is the last payment date
        if (currentDate.isSameOrAfter(blockedPaymentDate, 'day')) {
          return 'Schedule for later is not available for statements that have reached the last payment date. You can still pay now.'
        }
      }
    } else if (payment.value.payWithPoints) {
      return 'Schedule for later is not available when paying with points.'
    } else if (payment.value.payWithPayId) {
      return 'Schedule for later is not available when paying with PayID.'
    }

    return null
  })

  const isScheduledPaymentDisabled = computed(() =>
    Boolean(schedulePaymentDisabledMessage.value)
  )

  return {
    isScheduledPaymentsEnabled,
    scheduledPaymentsDateLimit,
    isScheduledPaymentsLoading,
    scheduledPayments,
    scheduledPaymentsPagination,
    deleteScheduledPayment,
    fetchScheduledPayments,
    resetScheduledPayments,
    schedulePaymentDisabledMessage,
    isScheduledPaymentDisabled,
    scheduledPaymentDateFrom,
    scheduledPaymentDateTo,
    dateToPay,
    dateToPayFormatted,
  }
}
