<script setup lang="ts">
import { ValidationProvider } from 'vee-validate'
import Vue, { reactive, ref, watch, nextTick } from 'vue'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseSelectAsync from '/~/components/base/select-async/base-select-async.vue'
import { useAddressFinder } from '/~/composables/addresses'
import { useProvider } from '/~/composables/provider'
import BaseMetafield from './base-metafield.vue'

export type Metafield = {
  type:
    | 'bool'
    | 'array'
    | 'object'
    | 'address'
    | 'int'
    | 'string'
    | 'radio'
    | 'list'
    | 'abn'
    | 'composite'
    | 'group'
    | 'currency'
  key: string
  validations?: ('required' | 'unique')[]
  label?: string
  placeholder?: string
  class?: string
  entryClass?: string
  inputClass?: string
  labelClass?: string
  mask?: {
    mask: RegExp | string
    lazy: boolean
  }
  disabled?: boolean
  readonly?: boolean
  labelSuffix?: string
  disclaimer?: string
  onInput?: (value: string) => unknown
  replacer?: (value: string) => unknown
  unmaskedModelValue?: boolean
  mode?: 'passive' | 'aggressive' | 'eager' | 'lazy'
  requiredAsterisk?: boolean
  validation?: {
    name?: string
    rules?: string
    vid?: string
    customMessages?: { [k: string]: string }
  }
  maxlength?: number
  selectFirst?: boolean
}

export type MetafieldsSchema = {
  fields: Metafield[]
  types: MetafieldsTypes
}

type MetafieldsTypes = {
  [key: string]: MetafieldType
}

type MetafieldType = {
  fields: Metafield[]
  key?: string
}

type MetafieldValue = any

const props = withDefaults(
  defineProps<{
    value?: MetafieldValue
    backendErrors?: {
      [key: string]: string | string[]
    }
    schema: MetafieldsSchema
    parentFieldValue?: MetafieldValue[]
    isDisabled?: boolean
    submitting?: boolean
    namePrefix?: string
  }>(),
  {
    value: () => ({}),
    backendErrors: () => ({}),
    parentFieldValue: () => [],
    isDisabled: false,
    submitting: false,
    namePrefix: '',
  }
)

const emit = defineEmits<{
  (event: 'input', value: MetafieldValue): void
  (
    event: 'field-value-changed',
    data: { field?: Metafield; value?: string }
  ): void
  (event: 'field-submit', data: { field?: Metafield; value?: string }): void
}>()

const {
  isAddressFinderSearchingById,
  addressFinderSearchResultsForSelect,
  searchAddress,
  getAddressById,
} = useAddressFinder()
const { isGoogleAddressFinderEnabled } = useProvider()

const localValue = reactive({
  ...Object.fromEntries(
    props.schema.fields?.map((field: Metafield) => {
      switch (field.type) {
        case 'bool':
          return [field.key, false]
        case 'array':
          return [field.key, isFieldRequired(field) ? [{}] : []]
        case 'group':
        case 'object':
          return [field.key, {}]
        case 'address':
          return [
            field.key,
            {
              address: '',
              suburb: '',
              postcode: '',
              state: '',
            },
          ]
      }

      return [field.key, '']
    })
  ),
  ...props.value,
})
const compositeValue = reactive({
  ...Object.fromEntries(
    props.schema.fields
      ?.map((field: Metafield) => {
        if (field.type === 'composite') {
          return [field.key, {}]
        }

        return false
      })
      .filter(Boolean)
  ),
})

const addressSelected = ref<boolean>(false)

watch(
  localValue,
  (_localValue) => {
    emit('input', _localValue)
  },
  {
    deep: true,
  }
)

watch(
  () => props.value,
  (_value) => {
    Object.assign(localValue, _value)
  },
  { deep: true }
)

const isManualAddressInputType = ref(
  Boolean(
    !isGoogleAddressFinderEnabled.value || localValue['address']?.fullAddress
  )
)

function isAddStatementAccountDisabled(field: Metafield) {
  const allowMultipleStatementAccounts =
    eonx.modules.find((module) => module.label === 'statement-order').config
      ?.statementAccounts?.allowMultiple ?? true

  return field.key === 'statement_accounts'
    ? allowMultipleStatementAccounts
    : true
}

function enableManualAddressInput(status = true, field?: Metafield) {
  if (!status && field) {
    clearAddress(field)
  } else {
    nextTick(() => {
      nextTick(() => {
        emitter.emit('metafields:validate')
      })
    })
  }

  isManualAddressInputType.value = status
}

function getFullAddress(field: Metafield) {
  return (
    localValue[field.key].fullAddress ||
    Object.values(localValue[field.key]).filter(Boolean).join(', ')
  )
}

function getFieldName(field: Metafield) {
  return props.namePrefix + (props.namePrefix ? `[${field.key}]` : field.key)
}

function isFieldRequired(field: Metafield) {
  return (
    field.validations?.includes('required') ||
    /required/.test(field.validation?.rules ?? '')
  )
}

function isFieldUnique(field: Metafield) {
  return field.validations?.includes('unique') ?? false
}

function isAddressField(field: Metafield) {
  return field.type === 'address'
}

function getFieldBackendError(field: Metafield) {
  let key = getFieldName(field).replaceAll('[', '.').replaceAll(']', '')
  const result = props.backendErrors[key] || props.backendErrors[field.key]

  if (field.type === 'address' && !isManualAddressInputType.value) {
    return (
      props.backendErrors[`${key}.state`] ||
      props.backendErrors[`${key}.suburb`] ||
      props.backendErrors[`${key}.address`] ||
      props.backendErrors[`${key}.postcode`]
    )
  }

  return result
}

function getFieldValidationRules(field: Metafield) {
  const rules: Record<string, string | object | boolean> = {}

  if (field.validation?.rules) {
    return field.validation.rules
  }

  if (isAddressField(field) && !isManualAddressInputType.value) {
    rules['googleaddress'] = {
      address: localValue[field.key],
    }
  }

  if (isFieldRequired(field)) {
    rules['required'] = field.type === 'bool' ? 'required:true' : true
  }

  if (isFieldUnique(field) && props.parentFieldValue) {
    rules['unique'] = {
      field: field,
      list: props.parentFieldValue,
      key: field.key,
    }
  }

  return rules
}

function addItemToArray(field: Metafield) {
  localValue[field.key].push({})

  emit('field-value-changed', { field, value: localValue[field.key] })
}

function removeItemFromArray(field: Metafield, fieldValueIndex: number) {
  localValue[field.key].splice(fieldValueIndex, 1)

  emit('field-value-changed', { field, value: localValue[field.key] })
}

function getSelectOptions(field: Metafield) {
  return props.schema.types[field.key].fields.map((field: Metafield) => {
    return {
      text: field.label,
      value: field.key,
    }
  })
}

async function onAddressFetch(query: string) {
  return searchAddress(query).then(() => {
    return addressFinderSearchResultsForSelect.value
  })
}

async function onAddressSelect(
  field: Metafield,
  option: {
    label: string
    value: { id: string }
  },
  selectElement
) {
  emit('field-value-changed', { field, value: option.label })

  try {
    const address = await getAddressById(option.value.id)

    Object.assign(localValue[field.key], {
      fullAddress: option.label,
      address: address?.address,
      suburb: address?.suburb,
      postcode: address?.postcode,
      state: address?.state?.toUpperCase(),
    })

    addressSelected.value = true
  } catch (error) {
    console.error(error)
    Vue.notify({
      text: 'There was an error selecting address. Please try again',
      type: 'error',
      duration: 5000,
    })
  }

  selectElement?.validate(localValue[field.key].fullAddress)
}

function onAddressBlur(field: Metafield, selectElement?) {
  selectElement?.validate()
}

function clearAddress(field: Metafield, selectElement?) {
  Object.assign(localValue[field.key], {
    fullAddress: '',
    address: '',
    suburb: '',
    postcode: '',
    state: '',
  })

  addressSelected.value = false

  if (selectElement) {
    selectElement?.validate?.()
  }
}

function onFieldChanged(field: Metafield, value?: string) {
  if (value && field.onInput) {
    field.onInput(value)
  }

  emit('field-value-changed', { field, value })
}

function onBaseInputChanged(field: Metafield, value?: string) {
  if (!field.unmaskedModelValue) {
    localValue[field.key] = value
    onFieldChanged(field, value)
  }
}

function onBaseInputUnmasked(field: Metafield, value?: string) {
  if (field.unmaskedModelValue) {
    localValue[field.key] = value
    onFieldChanged(field, value)
  }
}

function onFieldSubmit(field: Metafield, value?: string) {
  nextTick(() => {
    emit('field-submit', { field, value })
  })
}

function onCompositeFieldChanged(field: Metafield) {
  localValue[field.key] = Object.values(compositeValue[field.key]).join('')
}

function onGroupFieldChanged(field: Metafield) {
  Object.assign(localValue, localValue[field.key])
  delete localValue[field.key]
}
</script>

<template>
  <fragment>
    <template v-for="field in schema.fields">
      <template v-if="field.type === 'group'">
        <div :key="getFieldName(field)" :class="field.class">
          <base-metafield
            v-if="schema.types[field.key]"
            v-model="localValue[field.key]"
            :schema="{
              fields: schema.types[field.key].fields,
              types: schema.types,
            }"
            :backend-errors="backendErrors"
            @input="onGroupFieldChanged(field, $event)"
          />
        </div>
      </template>
      <template v-if="['int', 'string'].includes(field.type)">
        <base-input
          :key="getFieldName(field)"
          :ref="field.key"
          :value="localValue[field.key]"
          :validation="{
            rules: getFieldValidationRules(field),
            vid: field.key,
            unmasked: field.unmaskedModelValue,
            mode: field.mode,
          }"
          :mask="field.type === 'int' ? { mask: Number, scale: 0 } : field.mask"
          :disabled="isDisabled || submitting || field.disabled"
          :required="isFieldRequired(field)"
          :error="getFieldBackendError(field)"
          :label="field.label"
          :name="getFieldName(field)"
          :class="field.class"
          :entry-class="field.entryClass"
          :input-class="field.inputClass"
          :label-class="field.labelClass"
          :placeholder="field.placeholder"
          :data-testid="field.key"
          :required-asterisk="field.requiredAsterisk"
          :maxlength="field.maxlength"
          :readonly="field.readonly"
          @input="onBaseInputChanged(field, $event)"
          @unmasked="onBaseInputUnmasked(field, $event)"
        >
          <template #label-suffix>
            <span v-if="field.labelSuffix">
              {{ field.labelSuffix }}
            </span>
          </template>
          <template #disclaimer>
            {{ field.disclaimer }}
          </template>
        </base-input>
      </template>
      <template v-if="field.type === 'currency'">
        <base-input
          :key="getFieldName(field)"
          :ref="field.key"
          :value="localValue[field.key]"
          :validation="{
            rules: getFieldValidationRules(field),
            vid: field.key,
            unmasked: field.unmaskedModelValue,
          }"
          :disabled="isDisabled || submitting || field.disabled"
          :required="isFieldRequired(field)"
          :error="getFieldBackendError(field)"
          :label="field.label"
          :name="getFieldName(field)"
          :class="field.class"
          :entry-class="field.entryClass"
          :input-class="field.inputClass"
          :label-class="field.labelClass"
          :placeholder="field.placeholder"
          :data-testid="field.key"
          :readonly="field.readonly"
          currency
          @input="onBaseInputChanged(field, $event)"
          @blur="onBaseInputChanged(field, $event)"
        >
          <template #disclaimer>
            {{ field.disclaimer }}
          </template>
        </base-input>
      </template>
      <template v-else-if="field.type === 'abn'">
        <div :key="getFieldName(field)" class="mb-4 flex items-center">
          <base-input
            :ref="field.key"
            :value="localValue[field.key]"
            :mask="field.mask"
            :disabled="isDisabled || submitting"
            :required="isFieldRequired(field)"
            :label="field.label"
            :name="getFieldName(field)"
            :class="field.class"
            :error="getFieldBackendError(field)"
            :entry-class="field.entryClass"
            :input-class="field.inputClass"
            :label-class="field.labelClass"
            :placeholder="field.placeholder"
            :validation="{
              vid: field.key,
              rules: getFieldValidationRules(field),
              unmasked: field.unmaskedModelValue,
              mode: field.mode,
            }"
            :required-asterisk="field.requiredAsterisk"
            :readonly="field.readonly"
            @input="onBaseInputChanged(field, $event)"
            @unmasked="onBaseInputUnmasked(field, $event)"
          >
            <template #inner-filter="{ loading, errors }">
              <slot name="inner-filter">
                <base-button
                  class="flex !h-full !w-16 !min-w-0 !rounded-none !rounded-r-sm !p-0 text-white"
                  :class="{
                    'pointer-events-none opacity-80': loading || submitting,
                  }"
                  :color="
                    errors[0] || getFieldBackendError(field)
                      ? 'error'
                      : 'primary'
                  "
                  @click="onFieldSubmit(field, localValue[field.key])"
                >
                  <base-loader v-if="loading || submitting" size="xs" />
                  <div
                    v-else-if="localValue['name'] || value['abnName']"
                    class="text-sm font-bold"
                  >
                    Clear
                  </div>
                  <base-icon
                    v-else-if="!localValue['name']"
                    :svg="'v2/heroic/plane'"
                    class="!h-4 !w-4"
                  />
                </base-button>
              </slot>
            </template>
            <template #disclaimer>
              {{ value['abnName'] || field.disclaimer }}
            </template>
          </base-input>
        </div>
      </template>
      <template v-else-if="field.type === 'bool'">
        <validation-provider
          :key="getFieldName(field)"
          :rules="getFieldValidationRules(field)"
          :name="field.label"
          :vid="field.key"
          :mode="field.mode"
          tag="div"
          class="mb-4 space-y-2"
          :class="{ 'opacity-50': isDisabled || field.disabled }"
        >
          <div class="flex items-start text-eonx-neutral-800">
            <base-checkbox
              v-model="localValue[field.key]"
              :disabled="isDisabled || field.disabled"
              :name="getFieldName(field)"
              look="v4"
              class="!mt-px"
              @change="onFieldChanged(field, $event)"
            />
            <div>
              {{ field.label }}
            </div>
          </div>
        </validation-provider>
      </template>
      <div
        v-else-if="field.type === 'array'"
        :key="getFieldName(field)"
        class="mt-4 mb-6 space-y-6 border-y border-eonx-neutral-300 py-6"
      >
        <template v-if="localValue[field.key].length === 0">
          <div
            class="font-bold"
            :class="{
              'text-error-700': getFieldBackendError(field),
            }"
          >
            {{ field.label }} {{ isFieldRequired(field) ? '*' : '' }}
          </div>
          <div
            v-if="getFieldBackendError(field)"
            class="mt-2 text-[13px] text-error-700"
          >
            {{ getFieldBackendError(field) }}
          </div>
          <div v-if="localValue[field.key].length === 0" class="mt-2 italic">
            {{ field.label }} not added yet
          </div>
        </template>
        <div
          v-for="(_, fieldValueIndex) in localValue[field.key]"
          :key="`${getFieldName(field)}[${fieldValueIndex}]`"
          class="border-t border-eonx-neutral-300 pt-6 first:border-t-0 first:pt-0"
        >
          <div
            class="mb-4 flex justify-between text-xl font-bold text-eonx-neutral-800 sm:mb-10 sm:text-left sm:text-2xl md:mt-[60px]"
          >
            <div v-if="field.label" class="font-bold">
              {{ field.label }}
              {{ fieldValueIndex > 0 ? fieldValueIndex + 1 : '' }}
              {{ isFieldRequired(field) ? '*' : '' }}
            </div>
            <base-button
              v-if="
                !isFieldRequired(field) ||
                fieldValueIndex > 0 ||
                localValue[field.key].length > 1
              "
              look="link"
              class="ml-auto"
              color="none"
              :disabled="isDisabled"
              @click="removeItemFromArray(field, fieldValueIndex)"
            >
              <base-icon
                :size="24"
                svg="heroicons/solid/x-circle"
                class="text-eonx-neutral-500"
              />
            </base-button>
          </div>
          <base-metafield
            v-model="localValue[field.key][fieldValueIndex]"
            :schema="{
              fields: schema.types[field.key].fields,
              types: schema.types,
            }"
            :parent-field-value="localValue[field.key]"
            :backend-errors="backendErrors"
            :name-prefix="`${getFieldName(field)}[${fieldValueIndex}]`"
            class="w-full"
            @field-value-changed="emit('field-value-changed', $event)"
          />
        </div>
        <div v-if="isAddStatementAccountDisabled(field)">
          <base-button
            look="link"
            :disabled="isDisabled"
            @click="addItemToArray(field)"
          >
            <base-icon
              svg="heroicons/mini/plus-circle"
              :size="20"
              class="mr-1"
            />
            Add {{ localValue[field.key].length > 0 ? 'additional' : '' }}
            {{ field.label }}
          </base-button>
        </div>
      </div>
      <div v-else-if="field.type === 'object'" :key="getFieldName(field)">
        <div
          v-if="!field.class"
          class="mb-4 text-xl font-bold text-eonx-neutral-800 sm:mb-10 sm:text-left sm:text-2xl md:mt-[60px]"
        >
          <div v-if="field.label" class="font-bold">
            {{ field.label }} {{ isFieldRequired(field) ? '*' : '' }}
          </div>
        </div>
        <div :class="field.class">
          <base-metafield
            v-if="schema.types[field.key]"
            v-model="localValue[field.key]"
            :schema="{
              fields: schema.types[field.key].fields,
              types: schema.types,
            }"
            :backend-errors="backendErrors"
            :name-prefix="getFieldName(field)"
            class="mt-2"
          />
        </div>
      </div>
      <validation-provider
        v-else-if="field.type === 'radio'"
        :key="getFieldName(field)"
        v-slot="{ errors }"
        :rules="getFieldValidationRules(field)"
        :name="field.label"
        :vid="field.key"
        :mode="field.mode || 'aggressive'"
        tag="div"
        class="mb-4"
      >
        <div
          :class="{
            'text-error-700': errors[0] || getFieldBackendError(field),
          }"
        >
          {{ field.label }} {{ isFieldRequired(field) ? '*' : '' }}
        </div>
        <div
          v-if="getFieldBackendError(field)"
          class="mt-1 text-[13px] text-error-700"
        >
          {{ getFieldBackendError(field) }}
        </div>
        <div class="mt-1 flex flex-wrap gap-x-4 gap-y-2 px-1">
          <base-radio
            v-for="(option, index) in schema.types[field.key].fields"
            :key="index"
            v-model="localValue[field.key]"
            :name="getFieldName(field)"
            :value="option.key"
            :required="isFieldRequired(field)"
            :disabled="isDisabled"
            class="pr-1"
            @input="onFieldChanged(field, option.label)"
          >
            {{ option.label }}
          </base-radio>
        </div>
        <div
          v-if="getFieldBackendError(field)"
          class="text-[13px] text-error-700"
        >
          {{ getFieldBackendError(field) }}
        </div>
      </validation-provider>
      <template v-else-if="field.type === 'list'">
        <base-select
          :key="getFieldName(field)"
          v-model="localValue[field.key]"
          :options="getSelectOptions(field)"
          :validation="{
            rules: getFieldValidationRules(field),
            name: field.label,
            mode: field.mode,
          }"
          :label="field.label"
          :name="getFieldName(field)"
          :vid="field.key"
          :disabled="isDisabled"
          :required="isFieldRequired(field)"
          :error="getFieldBackendError(field)"
          :entry-class="field.entryClass"
          :label-class="field.labelClass"
          :placeholder="field.placeholder"
          :select-first="field.selectFirst"
          @input="onFieldChanged(field)"
        />
      </template>
      <template v-else-if="field.type === 'address'">
        <div
          v-if="isManualAddressInputType"
          :key="getFieldName(field)"
          class="pb-5"
        >
          <div
            v-if="field.label && !field.hideManualAddressTypeLabel"
            class="pl-[3px]"
          >
            {{ field.label }} {{ isFieldRequired(field) ? '*' : '' }}
          </div>
          <base-metafield
            v-model="localValue[field.key]"
            :schema="{
              fields: schema.types[field.key].fields,
              types: schema.types,
            }"
            :backend-errors="backendErrors"
            :name-prefix="getFieldName(field)"
            :class="field.class"
            @field-value-changed="emit('field-value-changed', $event)"
          />
          <base-button
            v-if="isGoogleAddressFinderEnabled"
            look="link"
            class="flex items-center text-primary"
            @click="enableManualAddressInput(false, field)"
          >
            <base-icon :size="24" svg="heroicons/outline/arrow-small-left" />
            <span class="font-normal">Address lookup</span>
          </base-button>
        </div>
        <base-select-async
          v-else
          :key="getFieldName(field)"
          :value="getFullAddress(field)"
          :label="field.label"
          :name="getFieldName(field)"
          :vid="field.key"
          :required="isFieldRequired(field)"
          clearable
          :nolabel="false"
          icon=""
          :icon-plain="false"
          :error="getFieldBackendError(field)"
          :validation="{
            rules: getFieldValidationRules(field),
            mode: 'passive',
            skipIfEmpty: true,
          }"
          :fetch-on-focus="!addressSelected"
          :fetch="onAddressFetch"
          :entry-class="field.entryClass"
          :input-class="field.inputClass"
          :label-class="field.labelClass"
          :is-fetching-selected-item="isAddressFinderSearchingById"
          @input="clearAddress(field)"
          @blur="onAddressBlur(field, ...arguments)"
          @change="onAddressSelect(field, ...arguments)"
          @clear="clearAddress(field, ...arguments)"
        >
          <template #noOptionsFound>
            <div />
          </template>
          <template #afterOptions>
            <div class="py-1.5 px-3">
              Don’t see your address?
              <base-button look="link" @click="enableManualAddressInput">
                Enter manually
              </base-button>
            </div>
          </template>
        </base-select-async>
      </template>
      <base-metafield
        v-else-if="field.type === 'composite'"
        :key="getFieldName(field)"
        v-model="compositeValue[field.key]"
        :schema="{
          fields: schema.types[field.key].fields,
          types: schema.types,
        }"
        :backend-errors="backendErrors"
        :name-prefix="getFieldName(field)"
        class="mt-2"
        @input="onCompositeFieldChanged(field)"
      />
    </template>
  </fragment>
</template>
