<script>
import AppLogo from '/~/components/app/app-logo.vue'
import DefaultBaseAsidePage from '/~/components/base/aside-page/base-aside-page.v1.vue'

export default {
  name: 'base-aside-page-v2',
  components: {
    AppLogo,
  },
  extends: DefaultBaseAsidePage,
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
    appLogo: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ...DefaultBaseAsidePage.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <div
    v-analytics:keyup.esc="{ cta: 'Back', pageGroup: 'Drawer', page: title }"
    class="fixed top-0 flex h-full w-full grow flex-col overflow-hidden bg-white"
    :class="{
      'footer-extra': isStandalone,
    }"
    tabindex="-1"
    @keyup.esc="onBackClick"
  >
    <template v-if="iconHeader">
      <slot name="iconHeader" />
    </template>
    <div
      v-else
      class="relative bg-white text-eonx-neutral-800 md:py-2.5"
      :class="{ shadow: shadow }"
    >
      <div class="flex h-16 items-center px-6">
        <base-button
          v-if="!isBackDisabled"
          ref="backButton"
          v-analytics:click="{
            cta: 'Back',
            pageGroup: 'Drawer',
            page: title,
          }"
          data-testid="back-button"
          icon="heroicons/outline/chevron-left"
          class="p-1"
          :size="26"
          :title="title"
          alt="back"
          @click="onBackClick"
        />
        <div
          class="flex grow flex-col overflow-hidden px-4 text-center"
          :class="{
            'pr-12': !isBackDisabled && !(action || $slots.action),
          }"
        >
          <div v-if="appLogo" class="flex justify-center">
            <app-logo :max-width="116" />
          </div>
          <h2 class="cursor-default truncate text-xl font-bold">
            {{ title }}
          </h2>
          <span v-if="subtitle" class="truncate text-sm leading-tight">
            {{ subtitle }}
          </span>
        </div>
        <div
          v-if="action || $slots.action"
          class="flex h-8 w-8 shrink-0 items-center justify-center"
        >
          <slot name="action">
            <div
              tabindex="0"
              role="button"
              class="flex cursor-pointer items-center justify-center rounded-full bg-white text-primary"
              :aria-label="addButtonLabel"
              @click="action"
              @keyup.enter="action"
              @keyup.space="action"
            >
              <base-icon
                data-testid="base-aside-page-action-button"
                svg="v2/custom/plus-circle"
                alt="add"
                :size="32"
              />
            </div>
          </slot>
        </div>
      </div>
      <slot name="header" />
    </div>

    <div
      ref="defaultSlot"
      class="flex grow flex-col overflow-y-auto bg-eonx-neutral-50"
      :class="{
        'p-5': !noPadding,
        [bodyClass]: true,
      }"
    >
      <slot />
    </div>
    <div v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>
