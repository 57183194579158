<script>
import BaseInput from '/~/components/base/input/base-input.vue'

export default {
  name: 'rec-groups-search',
  components: {
    BaseInput,
  },
}
</script>

<template>
  <base-input
    v-bind="$attrs"
    look="recroom"
    icon="plain/search"
    placeholder="Search by Group or member name"
    icon-plain
    nolabel
    clearable
    field-style="flexGrow: 0"
    v-on="$listeners"
  />
</template>
