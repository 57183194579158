<script>
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseClampLine from '/~/components/base/clamp-line/base-clamp-line.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'

export default {
  name: 'dining-details-info',
  components: {
    BaseLink,
    BaseTabs,
    BaseTab,
    BaseCheckbox,
    BaseClampLine,
    BaseIcon,
  },
  props: {
    link: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    terms: {
      type: String,
      default: '',
    },
    address: {
      type: Object,
      default: () => ({}),
    },
    operations: {
      type: Object,
      default: () => ({}),
    },
    feature: {
      type: Array,
      default: () => [],
    },
    period: {
      type: Number,
      default: 0,
    },
    tabsProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      days: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
    }
  },
  computed: {
    cleanLink() {
      return this.link.replace(/https*:\/\//g, '').replace(/\/$/, '')
    },
    operationsKeys() {
      return Object.keys(this.operations)
    },
    isOperationsEmpty() {
      return Object.keys(this.operations).reduce((result, key) => {
        const isEmpty =
          Object.keys(this.operations[key]).length === 0 &&
          this.operations[key].constructor === Object

        return result && isEmpty
      })
    },
    normalizeLink() {
      if (/^(http|\/)/.test(this.link)) {
        return this.link
      }

      return `http://${this.link}`
    },
    hrefPhone() {
      return `tel:${this.phone.replace(/\s/g, '')}`
    },
  },
}
</script>

<template>
  <div>
    <base-tabs v-bind="tabsProps" class="-mx-5 sm:mx-0">
      <base-tab name="Details" selected class="mx-5 sm:mx-0">
        <div
          v-if="link"
          class="mb-5 inline-block font-bold opacity-75 hover:opacity-100"
        >
          <base-link
            :href="normalizeLink"
            :title="`Visit ${cleanLink}`"
            data-test="info-web"
          >
            <span>
              {{ cleanLink }}
            </span>
          </base-link>
        </div>
        <base-link
          v-if="phone"
          :href="hrefPhone"
          :title="`Call to ${phone}`"
          data-test="info-phone"
          class="flex items-center hover:opacity-75"
        >
          <base-icon
            svg="plain/phone"
            class="text-eonx-neutral-600"
            :size="20"
          />
          <span class="ml-2.5 font-bold text-eonx-neutral-600">
            {{ phone }}
          </span>
        </base-link>
        <div v-if="address" class="mb-5 flex items-center">
          <base-icon
            svg="plain/marker"
            class="text-eonx-neutral-600"
            :size="24"
          />
          <span class="ml-2.5 truncate font-bold text-eonx-neutral-600">
            {{ address.addressLine1 }}
            {{ address.suburb }}
            {{ address.postcode }}
            {{ address.state }}
          </span>
        </div>
        <div
          class="mb-5 leading-normal text-eonx-neutral-600"
          data-test="description"
        >
          <span v-html="description" />
        </div>
        <table
          v-if="operations && !isOperationsEmpty"
          class="mb-5 w-full border-none leading-normal text-eonx-neutral-600"
        >
          <thead>
            <tr>
              <th />
              <th
                v-for="key in operationsKeys"
                :key="key"
                class="font-bold capitalize"
              >
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="day in days" :key="day">
              <th class="max-w-xs">
                {{ day }}
              </th>
              <td v-for="key in operationsKeys" :key="key">
                <div class="flex justify-center">
                  <base-checkbox
                    class="pointer-events-none"
                    :hide-label="true"
                    :checked="operations[key][day]"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="feature">
          <span class="text-eonx-neutral-800">Features</span>
          <ul class="list-inside list-disc text-eonx-neutral-600">
            <li v-for="(item, index) in feature" :key="`${item}_${index}`">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="mt-5 font-bold text-eonx-neutral-800">
          <template v-if="period">
            Valid up to {{ period }} month(s) from purchase.
          </template>
          <template v-else>eGift Cards have no expiry date</template>
        </div>
      </base-tab>
      <base-tab v-if="terms" name="Terms" class="mx-5 sm:mx-0">
        <base-clamp-line :rows="10">
          <p class="text-eonx-neutral-800" v-html="terms" />
        </base-clamp-line>
      </base-tab>
    </base-tabs>
  </div>
</template>
