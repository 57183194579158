import findIndex from 'lodash-es/findIndex'
import { computed, reactive, ref } from 'vue'
import mdl from '/~/core/mdl'
import ui from '/~/core/ui'
import { env } from '/~/utils'
import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomChatRoom } from '/~rec/core/chat-room'
import { useRecProfile } from './profile'

const { profile, orgId } = useRecProfile()

const state = reactive({
  joinedRooms: new EntityProcessor({
    entity: 'chat/rooms',
    mapping: (record) => new RecroomChatRoom(record),
    perPage: 500,
    filters: {
      default: {
        participantStatus: 'active',
      },
    },
    sorting: (a, b) => {
      return b.updatedAt.toDate() - a.updatedAt.toDate()
    },
  }),
  openedRooms: [],
  selectedRoom: null,
})

const hasNewMessages = computed(() =>
  state.joinedRooms?.hits.some((room) => room.hasUnreadMessages)
)

function initChat() {
  const channelId = `private-chat-${env}-${orgId.value}-user-${profile.me.id}`
  const mainChannel = profile.pusher.subscribe(channelId)

  state.joinedRooms.getData()

  mainChannel.bind('room-created', (msg) => {
    RecroomChatRoom.fetchById(msg.id).then((room) => {
      state.joinedRooms.addHit(room)
    })
  })
}

async function openRoom(roomId) {
  if (!state.joinedRooms?.hits.some((r) => r.id === roomId)) {
    await state.joinedRooms.getData()
  }
  const room = state.joinedRooms?.hits.find((r) => r.id === roomId)

  if (ui.desktop) {
    if (findIndex(state.openedRooms, { id: roomId }) < 0) {
      if (state.openedRooms?.length === 2) {
        closeRoom(state.openedRooms[0])
      }
      state.openedRooms.push(room)
    }

    state.selectedRoom = room
  } else {
    mdl.show('rec-chat-popup', {
      props: { room },
    })
  }
}

function selectRoom(room) {
  state.selectedRoom = room
}

function closeRoom(room) {
  const idx = state.openedRooms.indexOf(room)

  state.openedRooms.splice(idx, 1)
  state.selectedRoom = state.openedRooms[0]
}

async function createRoom(payload) {
  const newRoom = await RecroomChatRoom.create(payload)

  await state.joinedRooms.refresh()

  return newRoom
}

async function leaveRoom({ room, ownerId }) {
  closeRoom(room)
  await room.leave({ ownerId })
  state.joinedRooms.refresh()
}

async function deleteRoom(room) {
  closeRoom(room)

  await room.delete()
  await state.joinedRooms.refresh()
}

const isNewChatProcessCreating = ref(false)

export const useChat = () => ({
  chat: state,
  hasNewMessages,
  initChat,
  openRoom,
  closeRoom,
  createRoom,
  leaveRoom,
  deleteRoom,
  selectRoom,
  isNewChatProcessCreating,
})
