export default {
  extensions: {
    'gift-cards-cinema': {
      templateName: 'australiapost',
    },
    'gift-cards': {
      templateName: 'australiapost',
    },
    dining: {
      templateName: 'australiapost',
    },
    estore: {
      templateName: 'australiapost',
    },
  },
}
