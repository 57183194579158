<script>
import modal from '/~/core/mdl'
import LayoutInit from '/~rec/layouts/init/layout-init.vue'
import LayoutMain from '/~rec/layouts/main/layout-main'
import { useRecProfile } from '/~rec/composables/profile'
import { useRecroom } from '/~rec/composables/recroom'

export default {
  name: 'recroom-app',
  components: {
    LayoutInit,
    LayoutMain,
  },
  setup() {
    const { features } = useRecProfile()
    const { initialized } = useRecroom()

    return { features, initialized }
  },
  watch: {
    '$route.query.recProfileId'() {
      if (this.features.directory) {
        const dirRecRoot = document.getElementById('directoryRecRoot')
        const id = this.$route.query.recProfileId

        if (!dirRecRoot && id) {
          modal.show('rec-directory-profile', {
            on: {
              back: () => {
                const query = Object.assign({}, this.$route.query)

                delete query.recProfileId

                this.$router.replace({ query })
              },
            },
          })
        }
      }
    },
  },
}
</script>

<template>
  <layout-main v-if="initialized" class="h-full">
    <router-view />
  </layout-main>
  <layout-init v-else />
</template>
