<script>
export default {
  name: 'details-sidebar-root',
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <transition name="slide">
    <div
      v-if="isShow"
      class="absolute right-0 z-20 mx-auto h-full w-full max-w-2xl shrink-0 overflow-y-auto bg-default md:max-w-[376px]"
    >
      <slot />
    </div>
  </transition>
</template>
