<script lang="ts" setup>
import { computed } from 'vue'
import { pluralize } from '/~/utils/format/string'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import SummaryPayTo from '/~/views/checkout.v2/components/checkout/summary/summary-pay-to.vue'

const { payment } = useCheckoutReactive()

const count = computed(() => {
  const itemsCount = payment.value.checkoutItemsCount

  return `${itemsCount} ${pluralize(itemsCount, 'item')}`
})
</script>

<template>
  <base-collapse
    v-if="count"
    show-icon
    :autoscroll="false"
    icon="v2/custom/chevron-down-outline"
    :icon-size="24"
  >
    <template #trigger>
      <span class="block font-bold">View Summary ({{ count }})</span>
    </template>

    <div class="mt-4">
      <summary-pay-to />
    </div>
  </base-collapse>
</template>
