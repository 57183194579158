<script setup lang="ts">
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { computed } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseField from '/~/components/base/field/base-field.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import { useEditProfileComponent } from '/~/components/drawer/views/personal-details/views/edit-profile/use-edit-profile-component'
import { BackendErrors } from '/~/composables/backend-validation'
import { useCms } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'

// component used in australianpost provider

interface EditProfileComponentProps {
  mobile?: boolean
  to?: string
  isFormFieldsScrollable?: boolean
  showOnlyRequiredFields?: boolean
  id?: string | null
}
const props = withDefaults(defineProps<EditProfileComponentProps>(), {
  mobile: false,
  to: 'modals-portal',
  isFormFieldsScrollable: true,
  showOnlyRequiredFields: false,
  id: null,
})

const emit = defineEmits<{
  (event: 'profile-saved'): void
  (event: 'edit-profile-error', errors: BackendErrors): void
}>()

const { profileConfigForm } = useProvider()
const {
  form,
  formFields,
  genderFields,
  genderField,
  updating,
  onSubmitForm,
  isFormValid,
  disabled,
  errorsList,
  validationObserverRef,
} = useEditProfileComponent(props, emit)

const { isDarkThemeForEwallet } = useCms()
const { isRequiredAsteriskVisible } = useUI()

const formFieldsV2 = computed(() => {
  return formFields.value.map((formField) => {
    switch (formField.name) {
      case 'firstName':
      case 'lastName': {
        return {
          ...formField,
          isHalfwidth: true,
        }
      }
      default: {
        return formField
      }
    }
  })
})
</script>

<template>
  <validation-observer
    v-slot="{ errors, handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <form
      class="relative flex h-full flex-col"
      @submit.prevent="handleSubmit(onSubmitForm)"
    >
      <div class="flex-grow overflow-y-auto px-5">
        <div class="grid grid-cols-2 gap-x-4">
          <template v-for="field in formFieldsV2">
            <base-textarea
              v-if="field.textarea"
              :key="field.name"
              v-model="form[field.name]"
              :validation="field.validation"
              :label="field.label"
              :name="field.name"
              :disabled="field.disabled"
              :required="field.required"
              :error="field.error"
              :placeholder="field.placeholder"
              :rows="2"
              :maxlength="1300"
              :required-asterisk="isRequiredAsteriskVisible"
              :class="{
                'col-span-2': !field.isHalfwidth,
              }"
              entry-class="rounded-md"
              :style="{
                '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
              }"
            />
            <base-input
              v-else
              :key="field.name"
              v-model="form[field.name]"
              :validation="field.validation"
              :name="field.name"
              :disabled="field.disabled"
              :required="field.required"
              :error="field.error"
              :label="field.label"
              :maxlength="field.maxlength"
              :mask="field.mask"
              :type="field.type"
              :placeholder="field.placeholder"
              :required-asterisk="isRequiredAsteriskVisible"
              :class="{
                'col-span-2': !field.isHalfwidth,
              }"
              entry-class="h-12 rounded-md"
              :style="{
                '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
              }"
            >
              <template #disclaimer>
                <div
                  v-if="field.disclaimer"
                  class="mt-[5px] text-eonx-neutral-600"
                >
                  {{ field.disclaimer }}
                </div>
              </template>
            </base-input>
          </template>
        </div>

        <validation-provider
          v-if="profileConfigForm.sex && profileConfigForm.sex.isVisible"
          v-bind="genderField.validation"
          slim
        >
          <base-field
            label="Gender"
            :border="false"
            :required="genderField.required"
            class="base-input--simple"
            :error="errors['sex'] ? errors['sex'][0] : null"
            entry-class="flex mt-1 pl-5 gap-x-5"
          >
            <base-radio
              v-for="field in genderFields"
              :key="field.value"
              v-model="form.sex"
              :disabled="genderField.disabled"
              :value="field.value"
              name="sex"
            >
              {{ field.label }}
            </base-radio>
          </base-field>
        </validation-provider>
      </div>
      <div class="flex-shrink-0 p-5">
        <base-button
          type="submit"
          blocked-reason="profile-edit-errors-list"
          :blocked="disabled || !isFormValid"
          full-width
        >
          {{ updating ? 'Saving...' : 'Save' }}
        </base-button>
        <div id="profile-edit-errors-list" class="hidden">
          {{ errorsList }}
        </div>
      </div>
    </form>
  </validation-observer>
</template>
