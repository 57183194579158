<script>
import get from 'lodash-es/get'
import morphdom from 'morphdom'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'widget-code',
  props: {
    code: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isCodeWidgetsEnabled } = useProvider()

    return {
      isCodeWidgetsEnabled,
    }
  },
  computed: {
    html() {
      return get(this.code, 'value') || get(this.code, 'html', '')
    },
  },
  watch: {
    html(val) {
      const el = document.createElement('section')

      el.innerHTML = val
      el.classList = this.$refs.section.classList
      el.style = this.$refs.section.style

      morphdom(this.$refs.section, el)
    },
  },
  methods: {
    /**
     * Process links with router
     */
    onClick(e) {
      const target = e.target || e.srcElement

      if (!target || !target.closest) return

      const linkWrapper = target.closest('a')
      const href = linkWrapper?.getAttribute('href')

      if (!href) return

      const resolved = this.$router.resolve(href)
      const routeName = resolved?.route?.name
      const currentRouteName = this.$router.currentRoute.name

      if (routeName && routeName !== currentRouteName) {
        e.preventDefault()
        this.$router.push({ name: routeName })
      }
    },
  },
}
</script>

<template>
  <section
    v-if="isCodeWidgetsEnabled"
    v-once
    ref="section"
    @click="onClick"
    v-html="html"
  />
</template>
