<script>
import AppHeaderLogo from '/~/components/app/header/app-header-logo.vue'
import Points from '/~/components/points/points.v2.vue'
import { useCms } from '/~/composables/cms'
import HeaderTopMenu from './components/header-top-menu/header-top-menu.v2.vue'

export default {
  name: 'app-header-v2-bill-payments',
  components: {
    AppHeaderLogo,
    HeaderTopMenu,
    Points,
  },
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { cmsConfig } = useCms()

    return {
      cmsConfig,
    }
  },
  computed: {
    logoWidth() {
      return this.cmsConfig['header-logo-max-width']
    },
    showShadow() {
      return false
    },
  },
  methods: {
    showTireModal() {
      this.$router.push({
        hash: '#profile-home',
        query: { tire: true },
      })
    },
  },
}
</script>

<template>
  <header class="bg-light">
    <div
      class="relative flex h-14 items-center justify-between px-[15px] sm:px-6 md:h-[88px]"
    >
      <portal-target name="app-header" slim />

      <app-header-logo class="mr-5 py-2.5" :max-width="logoWidth" />
      <points class="ml-auto mr-[30px]" />
      <header-top-menu />
    </div>
  </header>
</template>
