<script>
import includes from 'lodash-es/includes'
import { getCurrentInstance, nextTick } from 'vue'
import { mixin as clickaway } from 'vue-clickaway'
import ui from '/~/core/ui'
import './base-select.scss'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseField from '/~/components/base/field/base-field.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useUI } from '/~/composables/ui'
import Popup from './popup'
import { useBaseSelect } from './use-base-select'
import InputMixin from '../input/input.mixin'

export default {
  name: 'base-select',
  components: {
    Popup,
    BaseField,
    BaseIcon,
    ButtonIcon,
  },
  mixins: [InputMixin, clickaway],
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    options: {
      type: [Array, Object],
      default: () => [],
    },

    /**
     * Requires if the initial value of v-model does not match any of the options.
     * On iOS this will cause the user not being able to select the first item
     * because iOS does not fire a change event.
     */
    defaultOption: {
      type: String,
      default: '-',
    },
    arrowIcon: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    look: {
      type: String,
      default: '',
      validator: (v) => !v || /simple|health|recroom|border-floated/.test(v),
    },
    border: {
      type: Boolean,
      default: true,
    },
    fullwidthPopup: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
    entryClass: {
      type: String,
      default: 'rounded h-10',
    },
    icon: {
      type: String,
      default: '',
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: '',
    },
    selectListClass: {
      type: String,
      default: '',
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    selectFirst: {
      type: Boolean,
      default: false,
    },
    popupOffset: {
      type: Object,
      default: () => ({ top: 0 }),
    },
    customSelect: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'no matching',
    },
    search: {
      type: Object,
      default: () => ({
        enabled: false,
        allowSpace: false,
        fields: [],
        maxlength: Infinity,
      }),
    },
  },
  setup(props) {
    const internalInstance = getCurrentInstance()
    const { lockKeyboardAccessArea, unlockKeyboardAccessArea } = useUI()

    const keyboardAccessAreaId = 'base-select'

    const {
      selectedPosition,
      hoverPosition,
      blockHover,
      isOpen,
      groups,
      selected,
      isSelected,
      optionsWithoutGroup,
      onHover,
      onKeyUpHandler,
      onPreSelectHandler,
      onSelectClickHandler,
      onBlurHandler,
      isSelect,
      handleToggle,
      clearInput,
      isHover,
      getOptionsByGroup,
      input,
      searchQuery,
    } = useBaseSelect(props, internalInstance.proxy)

    return {
      ...InputMixin.setup?.(...arguments),
      ...clickaway.setup?.(...arguments),
      keyboardAccessAreaId,
      lockKeyboardAccessArea,
      unlockKeyboardAccessArea,
      selectedPosition,
      hoverPosition,
      blockHover,
      isOpen,
      groups,
      selected,
      isSelected,
      optionsWithoutGroup,
      onHover,
      onKeyUpHandler,
      onPreSelectHandler,
      onSelectClickHandler,
      onBlurHandler,
      isSelect,
      handleToggle,
      clearInput,
      isHover,
      getOptionsByGroup,
      ui,
      input,
      searchQuery,
    }
  },
  data() {
    return {
      allSelectedValues: [],
      filteredOption: [],
      isNativeSelectVisible: true,
    }
  },
  computed: {
    isExpanded() {
      const { fieldValue } = this

      return (
        this.expanded ||
        (fieldValue !== null && fieldValue !== undefined && fieldValue !== '')
      )
    },
    fieldValue() {
      return ui.mobile && !this.customSelect
        ? String(this.value)
        : this.selected.text
    },
    bindings() {
      return ui.mobile && !this.search.enabled
        ? {
            placeholder: this.placeholder,
          }
        : {
            value: this.fieldValue,
            readonly: !this.search.enabled,
            placeholder: this.placeholder,
          }
    },
  },
  watch: {
    isOpen(isOpen) {
      this.checkOptionVisibility()
      if (isOpen) {
        this.$emit('open')
        if (!this.value && this.options[0]?.value && this.selectFirst) {
          this.onInput(this.options[0]?.value)

          // fix for iOS to redraw native select element when default option is hidden
          if (ui.mobile && !this.customSelect) {
            this.isNativeSelectVisible = false
            nextTick(() => {
              this.isNativeSelectVisible = true
              nextTick(() => {
                this.input?.click()
              })
            })
          }
        }
        if (!ui.mobile) {
          nextTick(() => {
            const rootElement = this.$refs.list || this.$refs.listEmpty

            this.lockKeyboardAccessArea({
              id: this.keyboardAccessAreaId,
              rootElement,
              parentElement: this.$refs.field.$el,
              focusElement: rootElement.querySelector(
                '.base-select__item--selected'
              ),
            })
          })
        }
      } else {
        this.unlockKeyboardAccessArea(this.keyboardAccessAreaId)
        this.$emit('close')
      }
    },
    selectedPosition(val) {
      this.hoverPosition = val
      this.checkOptionVisibility()
    },
    hoverPosition(val) {
      this.$refs[`option-${this.options[val]?.value}`]?.[0]?.focus()
    },
  },
  beforeDestroy() {
    this.unlockKeyboardAccessArea(this.keyboardAccessAreaId)
  },
  methods: {
    handleEnterPress() {
      this.selectHovered()
      this.handleToggle()
    },
    selectHovered() {
      if (this.hoverPosition || this.hoverPosition === 0) {
        const option = this.options[this.hoverPosition].value

        this.onInput(option)
      }
    },
    onKeyDownHandler(event) {
      if (ui.mobile) {
        return
      }

      this.blockHover = true

      switch (event.code) {
        case 'Space':
          if (!this.search.allowSpace) {
            event.preventDefault()
          }
          break
        case 'Enter':
          event.preventDefault()
          break
        case 'Escape':
          event.preventDefault()
          this.isOpen = false
          break
        case 'End':
        case 'Home':
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Tab':
          if (event.code === 'Tab' && !this.isOpen) {
            break
          }

          event.preventDefault()
          this.onPreSelectHandler(event)
          this.checkOptionVisibility('hovered')
          break
      }
    },
    hoverFromSearch(search) {
      const index = this.options.findIndex(
        (el) =>
          el.text.substr(0, search.length).toLowerCase() ===
          search.toLowerCase()
      )

      if (index >= 0) {
        this.hoverPosition = index
        this.checkOptionVisibility('hovered')
      }
    },

    isOptionDisabled(item) {
      return Boolean(
        this.optionsWithoutGroup.find((option) => option.value === item)
          ?.disabled
      )
    },

    handleItemClick(item) {
      if (this.isOptionDisabled(item)) return
      // check if item is event that comes from native element
      if (includes(Object.prototype.toString.call(item), 'Event')) {
        this.validateOnBlur(item.target.value)
        this.onInput(item.target.value)
        this.isOpen = this.search.enabled
      } else {
        this.validateOnBlur(item)
        this.onInput(item)
        if (this.search.enabled) {
          nextTick(() => {
            if (this.input) {
              this.input.value = this.fieldValue
            }
          })
        }
        this.isOpen = false
      }
    },
    async checkOptionVisibility(type) {
      if (this.isOpen && !ui.mobile && !this.search.enabled) {
        const { $refs } = this
        let option

        switch (type) {
          case 'hovered':
            option = this.options[this.hoverPosition]
            break
          case 'selected':
          default:
            option = this.options[this.selectedPosition]
        }

        if (option) {
          const optionRef = $refs[`option-${option.value}`]

          await nextTick()

          if (optionRef) {
            const offset = optionRef[0].offsetTop
            const height = optionRef[0].offsetHeight
            const { scrollTop, offsetHeight } = $refs.list

            if (offset + height > scrollTop + offsetHeight) {
              $refs.list.scrollTop = offset - offsetHeight + height
            } else if (offset < scrollTop) {
              $refs.list.scrollTop = offset
            }
          }
        }
      }
    },
    onKeyDownOption(event) {
      if (ui.mobile) {
        return
      }

      switch (event.code) {
        case 'Escape':
          event.preventDefault()
          this.blockHover = true
          this.isOpen = false
          this.input?.focus()
          return
      }

      this.onKeyDownHandler(event)
    },
    onBlur($event) {
      this.isFocused = false
      this.$emit('blur', $event)
    },
  },
}
</script>

<template>
  <validation-provider
    v-slot="{ errors }"
    v-bind="validation"
    ref="validationProviderRef"
    :detect-input="false"
    slim
  >
    <base-field
      ref="field"
      :input-id="id"
      :label="label"
      :required="required"
      :required-asterisk="requiredAsterisk"
      :disabled="disabled"
      :focused="isFocused"
      :expanded="isExpanded"
      :description="description"
      :error="errors[0] || error"
      :floated="floated || look === 'simple'"
      :border-floated="look === 'border-floated'"
      :icon="!search.enabled ? icon : ''"
      :border="look !== 'health' ? border : false"
      :nolabel="nolabel"
      :class="[
        'base-select',
        !search.enabled && 'base-select__icon-divided',
        noLabel && 'base-select__no-label',
        isOpen && `base-select--open`,
        look && `base-select--${look}`,
      ]"
      data-cy="base-select"
      :entry-class="entryClass"
      :label-class="labelClass"
    >
      <template v-if="search.enabled" #icon>
        <div class="mr-2 h-6 w-6">
          <base-icon :svg="'heroicons/outline/magnifying-glass'" :size="24" />
        </div>
      </template>

      <b v-if="prefix" class="mr-[5px] shrink-0">
        {{ prefix }}
      </b>

      <template v-if="isNativeSelectVisible">
        <select
          v-if="ui.mobile && !customSelect"
          :id="id"
          ref="input"
          v-on-clickaway="onBlurHandler"
          v-bind="bindings"
          :class="[
            'base-select__input',
            ui.mobile && 'base-select__input--native',
            look && look === 'recroom' && 'base-select__input--recroom',
          ]"
          :maxlength="search.maxlength"
          autocomplete="off"
          :disabled="disabled"
          @keydown="onKeyDownHandler"
          @keyup="onKeyUpHandler"
          @click="onSelectClickHandler"
          @input="handleItemClick"
          @focus="onFocus"
        >
          <option
            v-if="!isSelected"
            :class="['base-select__hidden-option', 'is-default']"
            value=""
            disabled
            selected
          >
            {{ defaultOption }}
          </option>

          <option
            v-for="option in optionsWithoutGroup"
            :key="option.value"
            :class="[option.hidden && 'base-select__hidden-option']"
            :selected="isSelect(option)"
            :value="option.value"
          >
            {{ option.text }}
          </option>

          <optgroup
            v-for="group in groups"
            :key="group.name"
            :label="group.name"
            role="group"
          >
            <option
              v-for="option in getOptionsByGroup(group.name)"
              :key="option.value"
              :selected="isSelect(option)"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </optgroup>
        </select>
        <input
          v-else
          :id="id"
          ref="input"
          v-on-clickaway="onBlurHandler"
          v-bind="bindings"
          :class="[
            'base-select__input',
            ui.mobile && 'base-select__input--native',
            look && look === 'recroom' && 'base-select__input--recroom',
          ]"
          :maxlength="search.maxlength"
          autocomplete="off"
          :disabled="disabled"
          @keydown="onKeyDownHandler"
          @keyup="onKeyUpHandler"
          @click="onSelectClickHandler"
          @input="handleItemClick"
          @focus="onFocus"
        />
      </template>
      <div
        v-if="
          ($scopedSlots.selected && !search.enabled) ||
          ($scopedSlots.selected && search.enabled && isSelected)
        "
        class="pointer-events-none absolute left-3 z-1 flex h-full items-center bg-white"
        :class="[!search.enabled ? 'right-10' : 'right-0']"
      >
        <slot name="selected" :option="selected"></slot>
      </div>

      <template #icon-right>
        <div
          class="base-field__icon base-field__icon--right"
          @click.stop="onSelectClickHandler"
        >
          <button-icon
            v-if="isSelected"
            class="base-select__clear !right-auto z-10"
            icon="heroicons/outline/x-circle"
            size="md"
            @click.stop="clearInput"
          />
          <base-icon
            v-else
            class="base-select__arrow pointer-events-none"
            :class="{
              'base-select__arrow--up': isOpen,
              'base-select__arrow--recroom': look === 'recroom',
            }"
            size="sm"
            :svg="arrowIcon || 'plain/chevron-bottom'"
          />
        </div>
      </template>

      <popup
        :fullwidth="fullwidthPopup"
        :visible="(isOpen && !ui.mobile) || (isOpen && customSelect)"
        :popup-offset="popupOffset"
      >
        <ul
          v-if="optionsWithoutGroup.length"
          ref="list"
          :class="[
            'base-select__list',
            look && `base-select__list--${look}`,
            selectListClass,
          ]"
          role="listbox"
          data-test="select-list"
        >
          <!-- ######################## -->

          <template v-for="option in optionsWithoutGroup">
            <li
              v-if="'item' in $scopedSlots"
              :key="option.value"
              @click="handleItemClick(option.value)"
            >
              <slot
                name="item"
                :option="option"
                :hover="isHover(option)"
                :selected="isSelect(option)"
                :handler="handleItemClick"
              />
            </li>
            <li
              v-else-if="option.value !== undefined"
              :key="option.value"
              :ref="`option-${option.value}`"
              :class="[
                'base-select__item',
                isHover(option) && 'base-select__item--hover',
                isSelect(option) && 'base-select__item--selected',
                option.hidden && 'base-select__hidden-option',
                look && `base-select__item--${look}`,
              ]"
              role="option"
              tabindex="0"
              @mouseover="onHover(option)"
              @click="handleItemClick(option.value)"
              @mousedown.prevent
              @keydown="onKeyDownOption"
              @keyup="onKeyUpHandler"
            >
              <span>{{ option.text }}</span>
            </li>
          </template>

          <!-- ######################## -->

          <li
            v-for="group in groups"
            :key="group.name"
            class="base-select__group"
          >
            <div class="base-select__group-title">
              <div v-if="group.icon" class="base-select__group-icon">
                <base-icon :svg="group.icon" size="md" />
              </div>
              <div class="base-select__group-title-text">
                {{ group.name }}
              </div>
            </div>
            <ul role="listbox" class="base-select__group-list">
              <template v-for="option in getOptionsByGroup(group.name)">
                <li
                  :ref="`option-${option.value}`"
                  :key="option.value"
                  :class="[
                    'base-select__item',
                    isSelect(option) && 'base-select__item--selected',
                    isHover(option) && 'base-select__item--hover',
                    look && `base-select__item--${look}`,
                  ]"
                  role="option"
                  @mouseover="onHover(option)"
                  @click="handleItemClick(option.value)"
                  @mousedown.prevent
                >
                  {{ option.text }}
                </li>
              </template>
            </ul>
          </li>
        </ul>
        <div
          v-else
          ref="listEmpty"
          :class="[
            'base-select__list',
            look && `base-select__list--${look}`,
            selectListClass,
            '!px-5 !py-5',
          ]"
          role="listbox"
        >
          {{ emptyText }}
        </div>
      </popup>
    </base-field>
  </validation-provider>
</template>
