<script>
import DefaultEstoreProduct from '/~/extensions/estore/templates/default/views/product.vue'
import EstoreSummary from '../components/estore-summary.vue'

export default {
  components: {
    EstoreSummary,
  },
  extends: DefaultEstoreProduct,
}
</script>
