import qs from 'query-string'
import { computed, ref, watch } from 'vue'
import api from '/~/core/api'
import { EntityProcessor } from '/~/core/processors/entity'
import { Competition } from '/~/extensions/competitions/core/competition'
import { capitalize } from '/~/utils/format/string'
import { useExtensions } from '/~/composables/extensions'
import { useSearch } from '/~/composables/search'

const { getConfigByName, getManifestByName } = useExtensions()

const COMPETITIONS_MODULE_NAME = 'competitions'

const config = computed(() => getConfigByName(COMPETITIONS_MODULE_NAME) || {})
const module = computed(() => getManifestByName(COMPETITIONS_MODULE_NAME))
const label = computed(() =>
  module.value?.label ? capitalize(module.value.label) : 'Competitions'
)
const searchConfig = computed(() => config.value?.search ?? {})
const searchGroup = computed(() => searchConfig.value?.group ?? label.value)
const defaultFilters = computed(() => {
  const filters = {}

  for (const filter of config.value.filters ?? []) {
    filters[filter.key] = filter.value
  }

  return filters
})

const searchQuery = ref(undefined)

const sortValues = computed(() =>
  (config.value?.sorting ?? []).map((i) => ({
    ...i,
    value: `orderBy=${i.orderBy}&sortBy=${i.sortBy}`,
  }))
)

const defaultSort = computed(
  () => sortValues.value.find((v) => v.default) ?? sortValues.value[0]
)
const defaultSorting = computed(() => defaultSort.value?.value)
const selectedSorting = ref(defaultSorting.value)
const selectedSortValue = computed(() =>
  sortValues.value.find((i) => i.value === selectedSorting.value)
)

const queryString = computed(() =>
  qs.stringify({
    title: searchQuery.value || undefined,
    orderBy: selectedSortValue.value?.orderBy || undefined,
    sortBy: selectedSortValue.value?.sortBy || undefined,
    ...defaultFilters.value,
  })
)

const isFiltersSelected = computed(() => {
  return Boolean(
    searchQuery.value !== undefined ||
      (selectedSorting.value !== defaultSorting.value && selectedSorting.value)
  )
})

watch(queryString, (value) => {
  processor.value.queryString = value
})

const processor = ref(
  new EntityProcessor({
    entity: 'v3/campaigns',
    mapping: (record) => new Competition(record),
    queryString: queryString.value,
    perPage: 20,
  })
)

const isEnabled = computed(() => {
  if (!config.value) {
    console.error('no config for competitions module')
    return false
  }

  return true
})

const routeState = computed(() => {
  const query = {}

  if (searchQuery.value) {
    query.title = searchQuery.value
  }

  if (selectedSorting.value && selectedSorting.value !== defaultSorting.value) {
    query.orderBy = selectedSortValue.value?.orderBy
    query.sortBy = selectedSortValue.value?.sortBy
  }

  return { query }
})

function resetFilters() {
  searchQuery.value = undefined
  selectedSorting.value = defaultSorting.value
}

function getContent() {
  processor.value.getData()
}

function init() {
  const { registerSearch } = useSearch()

  registerSearch({
    group: label.value,
    order: 5,
    config: config.value,
    filters: defaultFilters.value,
    sort: defaultSorting.value,
    url: '/v3/campaigns',
    searchField: 'slug',
    mapping: (record) => {
      const competition = new Competition(record)

      return {
        label: competition.title,
        image: competition.image,
        imageRatio: 4 / 3,
        offer: 'Competition',
        target: {
          name: 'competition-details',
          params: {
            id: competition.id,
          },
        },
      }
    },
  })
}

function syncState({ to }) {
  searchQuery.value = to.query?.title || undefined

  const sortQueryString = qs.stringify({
    orderBy: to.query?.orderBy,
    sortBy: to.query?.sortBy,
  })

  selectedSorting.value =
    sortValues.value.find((i) => i.value === sortQueryString)?.value ||
    defaultSorting.value
}

async function fetchCompetition(id) {
  try {
    const response = await api.get(`/v3/campaigns/${id}`)

    return [null, new Competition(response.data)]
  } catch (error) {
    return [error]
  }
}

function submitCompetition(id, payload) {
  return api.post(`/v3/campaign-leads/${id}`, payload).then(({ data }) => data)
}

const competitionSubmittedDetails = ref(null)

export function useCompetitions() {
  return {
    moduleName: COMPETITIONS_MODULE_NAME,
    init,
    label,
    isEnabled,
    processor,
    selectedSorting,
    sortValues,
    syncState,
    searchQuery,
    isFiltersSelected,
    fetchCompetition,
    submitCompetition,
    competitionSubmittedDetails,
    searchGroup,
    routeState,
    getContent,
    resetFilters,
    defaultSorting,
    defaultSort,
  }
}
