<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'confirm-choice-of-reward-modal',
  components: {
    BaseMdl,
    BaseIcon,
    BaseButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm',
    },
    icon: {
      type: String,
      default: 'trash',
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
      modal.hide()
    },
    hide() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl width="sme" height="sm">
    <div class="relative text-center">
      <span class="text-primary">
        <base-icon :svg="icon" :size="48" class="mx-auto" :class="iconColor" />
      </span>

      <h2 class="mt-6 text-center text-3xl font-semibold text-zinc-800">
        {{ title }}
      </h2>
    </div>
    <div class="mt-[35px] mb-[72px] text-zinc-800" v-html="description" />
    <div>Once redeemed choice of reward cannot be changed.</div>
    <template #footer>
      <div class="mt-5 sm:flex sm:flex-row-reverse sm:justify-between">
        <div class="pl-[5px] sm:w-1/2">
          <base-button full-width size="lge" @click="confirm">
            {{ confirmButtonText }}
          </base-button>
        </div>
        <div class="pr-[5px] sm:w-1/2">
          <base-button
            full-width
            look="outlined-color"
            class="mt-2.5 border-2 hover:opacity-50 sm:mt-0"
            size="lge"
            @click="hide"
          >
            Cancel
          </base-button>
        </div>
      </div>
    </template>
  </base-mdl>
</template>
