<script>
import { getMenuActiveItem } from '/~/utils/menu'
import { useUI } from '/~/composables/ui'
import LayoutView from '/~/templates/bill-payments/layouts/view/layout-view.vue'
import ActivityDetailsItem from './components/activity-details-item.vue'

export default {
  name: 'activity-details-desktop',
  components: {
    LayoutView,
    ActivityDetailsItem,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  setup() {
    const { leftMenu, uiReady } = useUI()

    return {
      leftMenu,
      uiReady,
    }
  },
  computed: {
    activeTopMenuItem() {
      if (!this.uiReady || !this.leftMenu) return

      return getMenuActiveItem(this.$route, this.leftMenu)
    },
    description() {
      return this.activeTopMenuItem?.description
    },
    title() {
      return this.activeTopMenuItem?.label
    },
    menu() {
      return this.activeTopMenuItem?.children ?? []
    },
  },
}
</script>

<template>
  <layout-view :heading="title" :menu="menu">
    <template #description>
      <div v-if="description" v-html="description" />
    </template>
    <activity-details-item :id="id" />
  </layout-view>
</template>
