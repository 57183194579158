<script>
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'retailer-logo',
  components: {
    BaseImage,
  },
  props: {
    alt: {
      type: String,
      default: 'Retailer Logo',
    },
    src: {
      type: String,
      default: '',
    },
    defaultSrc: {
      type: String,
      default: undefined,
    },
  },
}
</script>

<template>
  <div
    class="mx-auto h-auto max-h-80 w-full max-w-64 rounded-lg border border-zinc-100 bg-light sm:max-w-full"
    data-test="image"
  >
    <base-image
      :src="src"
      :alt="alt"
      :default-src="defaultSrc"
      :ratio="1"
      :fit="false"
      rounded
    />
  </div>
</template>
