<script>
import Vue, { ref, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import { cardCode } from '/~/utils/cards'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import {
  getExpiryDate,
  usePaymentMethods,
  PaymentMethodType,
  usePayId,
} from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'payments-methods-default-item-v4',
  components: {
    BaseIcon,
    BaseLoader,
  },
  props: {
    method: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { payment } = useCheckoutReactive()
    const router = useRouter()
    const route = useRoute()
    const { isBankAccountsNavEnabled, isCreditCardsNavEnabled } = useProvider()
    const { initiatingAccountsIds, deletePaymentMethod } = usePaymentMethods()
    const { translate } = useLocalization()
    const { pointsLabel } = usePoints()
    const { payIdManager } = usePayId()

    const loading = ref(false)

    const expiryDate = reactive(
      getExpiryDate(
        props.method,
        props.flowType === FlowType.payment && payment?.date
      )
    )

    const isCard = computed(
      () => props.method.type === PaymentMethodType.creditCard
    )
    const isBankAccount = computed(
      () => props.method.type === PaymentMethodType.bankAccount
    )
    const isPoints = computed(
      () => props.method.type === PaymentMethodType.points
    )
    const isPayId = computed(
      () => props.method.type === PaymentMethodType.payId
    )

    const status = computed(() => (props.method?.status ?? '').toLowerCase())
    const state = computed(() => (props.method?.state ?? '').toLowerCase())

    const isVerified = computed(
      () =>
        isPayId.value ||
        isPoints.value ||
        status.value === 'verified' ||
        (isCard.value && !isCreditCardsNavEnabled.value) ||
        (isBankAccount.value && !isBankAccountsNavEnabled.value)
    )

    const isAllowed = computed(() => state.value === 'active')
    const isNotAllowed = computed(() => state.value === 'restricted')

    const isUnselectable = computed(
      () =>
        (isPoints.value && !payment.value.isEnoughPointsOrder) ||
        isNotAllowed.value ||
        expiryDate.isExpired ||
        !isVerified.value ||
        (isCard.value && expiryDate.isExpiryLessThanScheduledAt)
    )

    const isBlocked = computed(
      () => status.value === 'blocked' || status.value === 'locked'
    )

    const isMasterCard = computed(() => cardCode(props.method) === 'mastercard')

    const icon = computed(() => {
      if (isCard.value) {
        if (isUnselectable.value && isMasterCard.value) {
          return 'billpay/payment-methods/mastercard-gray'
        }
        return `billpay/payment-methods/${cardCode(props.method)}`
      } else if (isBankAccount.value) {
        return 'v2/custom/bank'
      } else if (isPoints.value) {
        return 'symbion/token'
      } else if (isPayId.value) {
        return 'billpay/payment-methods/payid'
      }
      return null
    })
    const firstRow = computed(() => {
      const { name, type, accountName, description } = props.method

      switch (type) {
        case PaymentMethodType.payId:
          return description
        case PaymentMethodType.points:
          return pointsLabel.value
        case PaymentMethodType.creditCard:
          return name
        case PaymentMethodType.bankAccount:
          return accountName
        default:
          return ''
      }
    })
    const secondRow = computed(() => {
      const { number, accountNumber, bsb } = props.method

      if (isCard.value) {
        return `**** **** **** ${number.slice(-4)}`
      } else if (isBankAccount.value) {
        return translate('bankAccount.details', {
          acc: `*** *** *${accountNumber.slice(-2)}`,
          bsb,
        })
      } else if (isPoints.value && !payment.value.isEnoughPointsOrder) {
        return 'Not enough points for payment'
      }

      return null
    })

    const initializing = computed(() =>
      initiatingAccountsIds.value.includes(props.method.id)
    )

    async function onRemove() {
      if (loading.value) {
        return
      }

      loading.value = true
      try {
        await deletePaymentMethod(props.method.id)
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong while deleting. Please try again.',
        })
      } finally {
        loading.value = false
      }
    }

    async function onClick() {
      if (
        expiryDate.isExpired ||
        isBlocked.value ||
        initializing.value ||
        isNotAllowed.value
      ) {
        return
      }

      if (!isVerified.value) {
        modal.hideAll()
        await router.push({
          hash: '#profile-payment-methods',
          query: route.query,
        })
        bottomSheet.show('method-verify', {
          to: 'menu-modal',
          props: {
            method: props.method,
          },
        })
        return
      }

      if (isUnselectable.value) return

      if (isPayId.value) {
        if (!isAllowed.value) {
          try {
            loading.value = true
            await payIdManager.create()
          } catch (e) {
            console.error(e)
            return
          } finally {
            loading.value = false
          }
        }
      }

      emit('click')
    }

    return {
      icon,
      loading,
      isPoints,
      isCard,
      isBankAccount,
      isUnselectable,
      isNotAllowed,
      isVerified,
      isBlocked,
      firstRow,
      secondRow,
      onRemove,
      initializing,
      expiryDate,
      onClick,
      isPayId,
      payIdManager,
    }
  },
}
</script>

<template>
  <div
    class="relative flex min-h-16 items-center overflow-hidden rounded border border-eonx-neutral-200 bg-white px-4 py-2.5 outline outline-2 outline-transparent transition-all duration-300 hover:outline-primary sm:min-h-20 sm:px-5"
    :class="{
      'opacity-75': isUnselectable,
      'cursor-pointer': !isBlocked,
      'text-neutral-400': !isVerified || isUnselectable,
    }"
    @click="onClick"
  >
    <div class="flex grow items-center truncate">
      <div
        class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full sm:h-12 sm:w-12"
        :class="{
          'bg-eonx-neutral-600 text-white': isBankAccount,
        }"
      >
        <base-loader
          v-if="loading || (isPayId && payIdManager.loading)"
          color="white"
        />
        <base-icon
          v-else-if="icon"
          :svg="icon"
          :size="isCard ? 48 : isBankAccount ? 32 : '2xl'"
          :class="{
            grayscale: isUnselectable,
            'text-eonx-neutral-600': isPoints,
          }"
        />
      </div>

      <div
        class="mx-4 flex flex-col space-y-1"
        :class="[
          isVerified ? 'text-eonx-neutral-800' : 'text-eonx-neutral-600',
        ]"
      >
        <span class="truncate text-sm font-bold sm:text-base">
          {{ firstRow }}
        </span>
        <span v-if="secondRow || expiryDate.isExpired" class="truncate text-sm">
          <span>{{ secondRow }}</span>
          <span
            v-if="expiryDate.isExpired"
            class="mx-[5px] cursor-pointer border-l px-[5px] text-red-700 hover:opacity-60"
            @click.prevent="onRemove"
          >
            Remove
          </span>
        </span>
      </div>

      <base-loader v-if="initializing" class="ml-auto shrink-0" size="xs" />

      <div
        v-else-if="isBlocked"
        class="ml-auto shrink-0 text-sm text-eonx-neutral-600"
      >
        ACCOUNT BLOCKED
      </div>

      <div
        v-else-if="isNotAllowed"
        class="absolute top-0 right-0 mt-2 mr-2 whitespace-nowrap rounded-sm border border-orange-700 bg-orange-50 px-2 text-xs font-bold uppercase leading-5 text-orange-700"
      >
        NOT ACCEPTED
      </div>

      <div
        v-else-if="expiryDate.isExpired"
        class="absolute top-0 right-0 mt-2 bg-white px-2"
      >
        <div
          class="whitespace-nowrap rounded-sm border border-red-700 bg-red-50 px-2 text-xs font-bold uppercase leading-5 text-red-700 shadow-md"
        >
          Expired
        </div>
      </div>

      <div
        v-else-if="!isVerified && !initializing"
        class="absolute top-0 right-0 mt-2 bg-white px-2"
      >
        <div
          class="whitespace-nowrap rounded-sm border border-red-700 bg-red-50 px-2 text-xs font-bold uppercase leading-5 text-red-700 shadow-md"
        >
          Verify Account
        </div>
      </div>
    </div>

    <div
      v-if="expiryDate.isExpiryLessThanScheduledAt"
      class="mt-[5px] shrink-0 text-sm text-eonx-neutral-600"
    >
      Card will expire before the scheduled date
    </div>
  </div>
</template>
