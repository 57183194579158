<script>
export default {
  name: 'base-header-default',
}
</script>

<template>
  <div>
    <slot />
  </div>
</template>
