<script>
import CommunityHubFilterDesktop from './community-hub-filter.desktop.vue'
import CommunityHubFilterMobile from './community-hub-filter.mobile.vue'
import ui from '/~/core/ui'

export default {
  name: 'community-hub-catalog-filter-v2',
  functional: true,
  render(h, ctx) {
    return h(
      ui.mobile ? CommunityHubFilterMobile : CommunityHubFilterDesktop,
      ctx.data,
      ctx.props
    )
  },
}
</script>
