<script setup lang="ts">
import { formatDate } from '/~/utils/format/date'
import { useCheckoutReactive } from '/~/composables/checkout'

const { payment } = useCheckoutReactive()
</script>

<template>
  <div v-if="payment.isActiveScheduledPayment" class="mt-2.5 text-sm">
    Scheduled to pay on {{ formatDate('daymonthyearlong', payment.date) }}
  </div>
</template>
