<script>
import { computed } from 'vue'
import CheckoutSummaryItem from '/~/components/checkout/summary/checkout-summary-item.v3.vue'
import { useCheckoutReactive } from '/~/composables/checkout'

export default {
  name: 'confirmation-pay-to-cart',
  components: {
    CheckoutSummaryItem,
  },
  setup() {
    const { payment } = useCheckoutReactive()

    const items = computed(() => {
      return payment.value.confirmation.order?.items ?? []
    })

    return {
      items,
    }
  },
}
</script>

<template>
  <div v-if="items.length > 0" class="space-y-6">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="w-full"
      @click="$emit('item', item)"
    >
      <checkout-summary-item v-if="item" :item="item" />
    </div>
  </div>
</template>
