<script>
import { nextTick, ref } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

// Requesting an element's offsetHeight will trigger a reflow of the element's content.
export const reflow = (el) => el?.offsetHeight

export default {
  name: 'base-collapse',
  components: {
    BaseIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoscroll: {
      type: [Boolean, String],
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'plain/chevrondown',
    },
    iconSize: {
      type: [String, Number],
      default: 'sm',
    },
    usePlusMinus: {
      type: Boolean,
      default: false,
    },
    triggerClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    const visible = ref(false)
    const toggling = ref(false)

    return { visible, toggling }
  },
  watch: {
    value(v) {
      if (v !== this.visible) {
        this.toggle(v)
      }
    },
  },
  mounted() {
    if (this.value !== this.visible) {
      this.toggle(this.value)
    }
  },
  methods: {
    toggle(value) {
      if (this.toggling || this.disabled) return
      this.toggling = true
      const old = this.visible

      this.visible = typeof value === 'boolean' ? value : !this.visible
      this.$emit('input', this.visible)

      if (old !== this.visible) {
        this.$emit('change', this.visible)
      }

      // safari needs a little delay to prevent animation glitch
      setTimeout(() => {
        this.toggling = false
      }, 350)
    },
    onEnter(el) {
      reflow(el)
      nextTick(() => {
        requestAnimationFrame(() => {
          el.style.height = 0
          el.style.height = `${el.scrollHeight}px`
        })
      })
    },
    onAfterEnter(el) {
      el.style.height = null
      this.$emit('shown')

      if (this.autoscroll && this.$refs.trigger) {
        this.$refs.trigger.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    },
    onLeave(el) {
      el.style.height = 'auto'
      el.style.display = 'block'
      reflow(el)
      requestAnimationFrame(() => {
        el.style.height = el.getBoundingClientRect().height + 'px'
        el.style.height = 0
      })
    },
    onAfterLeave(el) {
      el.style.height = null
      this.$emit('hidden')
    },
  },
}
</script>

<template>
  <div>
    <button
      ref="trigger"
      class="mt-[5px] flex items-center text-left focus:outline-none"
      :aria-expanded="visible.toString()"
      :aria-label="label"
      :class="{
        'w-full': fullWidth,
        'cursor-default': disabled,
        'cursor-pointer': !disabled,
        [triggerClass]: triggerClass,
      }"
      data-testid="base-collapse-trigger"
      @click.prevent="toggle"
    >
      <span class="grow">
        <slot name="trigger" />
      </span>
      <slot v-if="showIcon" name="icon" :visible="visible">
        <span v-if="usePlusMinus" class="flex">
          <base-icon v-if="visible" svg="minus" :size="iconSize" />
          <base-icon v-else svg="plus" :size="iconSize" />
        </span>
        <span
          v-else
          class="ml-5 shrink-0 transition duration-300"
          :class="{
            'rotate-180 transform': visible,
          }"
        >
          <base-icon :svg="icon" :size="iconSize" />
        </span>
      </slot>
    </button>

    <slot name="beforeCollapse" />
    <transition
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @leave="onLeave"
      @afterLeave="onAfterLeave"
    >
      <div
        v-show="visible"
        class="relative overflow-hidden transition-height duration-300 ease-out"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>
