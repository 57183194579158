<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import CartItemV1 from '/~/components/cart-menu/cart-item.vue'

export default {
  name: 'cart-item-v2',
  components: {
    BaseIcon,
  },
  extends: CartItemV1,
}
</script>

<template>
  <div v-if="item">
    <div class="relative rounded-xl border border-zinc-100 p-4">
      <div class="absolute top-0 right-0 -mt-2.5 -mr-2.5">
        <button
          type="button"
          class="flex h-10 w-10 items-center justify-center rounded-full border-4 border-white bg-red-700 text-white hover:opacity-90 disabled:bg-disabled"
          :disabled="cart.updating"
          :aria-label="removeButtonLabel"
          @click="removeItem"
        >
          <base-icon svg="v2/heroic/trash" alt="remove" :size="16" />
        </button>
      </div>
      <div class="mb-[5px] flex items-center pr-[15px]">
        <div class="mr-5 w-20 flex-shrink-0">
          <base-image
            v-if="image"
            :src="image"
            :alt="item.name"
            :fit="isEstore ? 'contain' : 'cover'"
          />
        </div>
        <div class="flex items-end">
          <base-counter
            class="flex-grow"
            :value="item.quantity"
            :disabled="cart.updating"
            rounded
            :min="1"
            :max="10"
            @input="updateItem"
          />
        </div>
        <div class="ml-auto text-2xl font-bold">
          {{ formatDollar(total) }}
        </div>
      </div>

      <div class="mt-2.5">
        <span class="font-bold">
          {{ title ? `${title} - ` : '' }}
        </span>
        <span>
          {{ product }}
        </span>
      </div>

      <template v-if="loading">
        <div class="h-5 w-full animate-pulse rounded-md bg-eonx-neutral-50" />
      </template>
      <template v-else>
        <ew-product-type
          v-if="isEstore"
          class="mt-[5px] text-eonx-neutral-600"
          physical
          physical-icon="v2/box-icons/bx-truck"
          :icon-size="24"
          :show-delivery="showDelivery"
        >
          <template #physical>Delivered by Supplier</template>
        </ew-product-type>
        <ew-product-type
          v-else-if="item.gift && gift"
          class="mt-[5px] text-eonx-neutral-600"
          :gift="item.gift"
          :gift-card="!!item.retailerName"
          :icon-size="item.physical ? 20 : 24"
          gift-icon="v2/box-icons/bx-gift-filled"
          :physical="item.physical"
          :show-delivery="showDelivery"
        >
          <template #digital>
            <div>Gift to {{ gift.email }}</div>
          </template>
          <template #physical>
            <div>Gift to {{ gift.name }}</div>
          </template>
        </ew-product-type>
        <ew-product-type
          v-else
          class="mt-[5px] text-eonx-neutral-600"
          :gift="item.gift"
          :gift-card="!!item.retailerName"
          :icon-size="item.physical ? 20 : 24"
          gift-icon="v2/box-icons/bx-gift-filled"
          :physical="item.physical"
          :show-delivery="showDelivery"
        >
          <template #digital>Delivered to {{ ewalletLabels.ewallet }}</template>
          <template #physical>Delivered by AusPost</template>
        </ew-product-type>
      </template>
    </div>
  </div>
</template>
