<script>
import ui from '/~/core/ui'
import TravelFilterDesktop from './travel-filters.desktop.vue'
import TravelFilterMobile from './travel-filters.mobile.vue'

export default {
  name: 'travel-filters-v2',
  functional: true,
  render(h, ctx) {
    return h(
      ui.mobile ? TravelFilterMobile : TravelFilterDesktop,
      ctx.data,
      ctx.props
    )
  },
}
</script>
