<script setup lang="ts">
import { useCms } from '/~/composables/cms'
import AppLogo from '/~/components/app/app-logo.vue'
import BaseAction from '/~/components/base/action/base-action.vue'

withDefaults(
  defineProps<{
    minHeight?: string | number | boolean
    maxWidth?: string | number
  }>(),
  {
    minHeight: false,
    maxWidth: 144,
  }
)

const { headerLogoAction, headerLogoAria } = useCms()
</script>

<template>
  <base-action
    v-bind="headerLogoAction"
    appearance="link"
    :style="{
      minHeight: minHeight && `${minHeight}px`,
      maxWidth: maxWidth && `${maxWidth}px`,
    }"
    :aria-label="headerLogoAria || 'Go to Home'"
    class="flex h-full cursor-pointer items-center"
  >
    <app-logo :min-height="minHeight" :max-width="maxWidth" />
  </base-action>
</template>
