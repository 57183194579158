<script>
import RetailerDetails from '/~/components/retailer/retailer-details.vue'

export default {
  name: 'retailer-details-bupa',
  extends: RetailerDetails,
}
</script>

<template>
  <div v-if="offer">
    <base-tabs class="-mx-5 sm:mx-0">
      <base-tab
        v-if="description"
        name="How to Redeem"
        selected
        class="mx-5 sm:mx-0"
      >
        <div v-if="retailerUrl" class="mb-5">
          <base-link :href="handleUrl" class="font-bold">
            {{ website }}
          </base-link>
        </div>
        <base-clamp-line
          :rows="7"
          :is-expanded="descriptionExpanded"
          @expand="descriptionExpanded = $event"
        >
          <p
            class="leading-normal text-eonx-neutral-600 [&>a]:break-all [&>a]:font-bold [&>a]:text-primary"
            v-html="description"
          />
        </base-clamp-line>
        <section class="mt-5 font-bold text-eonx-neutral-800">
          <template v-if="period">
            Valid up to {{ period }} month(s) from purchase.
          </template>
          <template v-else>eGift Cards have no expiry date</template>
        </section>
      </base-tab>
      <base-tab v-if="terms" name="Terms" class="mx-5 sm:mx-0">
        <base-clamp-line
          :rows="7"
          :is-expanded="termsExpanded"
          @expand="termsExpanded = $event"
        >
          <p
            class="leading-normal text-eonx-neutral-600 [&>a]:break-all [&>a]:font-bold [&>a]:text-primary"
            v-html="terms"
          />
        </base-clamp-line>
      </base-tab>
    </base-tabs>
  </div>
  <base-placeholder
    v-else-if="loading"
    theme="dark"
    :rows="4"
    type="text"
    class="mt-2.5"
  />
</template>
