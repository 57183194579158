<script>
import { defineComponent } from 'vue'
import DefaultActivitySummaryItem from '/~/components/activity/summary/activity-summary-item.vue'

export default defineComponent({
  extends: DefaultActivitySummaryItem,
  props: {
    prependEstoreTitleWithPrice: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<template>
  <div class="flex items-center justify-between">
    <div
      v-if="isShowImage"
      class="mr-5 flex h-full w-12 shrink-0 flex-col items-center text-center"
    >
      <div class="w-12">
        <base-image
          :src="image"
          :fit="isEstore ? 'contain' : 'cover'"
          alt="offer logo"
          class="h-full w-full object-contain"
        />
      </div>
    </div>
    <div class="flex-1 overflow-hidden">
      <div class="flex flex-nowrap items-center sm:items-start">
        <div class="flex-1 overflow-x-hidden">
          <div class="truncate font-bold">
            {{ isEstore ? shortName : title }}
          </div>
          <div
            class="w-[344px] truncate text-sm leading-6 text-eonx-neutral-600 sm:mt-[5px]"
          >
            {{ item.quantity }} x
            {{ isEstore ? formatDollar(price) : shortName }}
          </div>
          <template v-if="loading">
            <div
              class="h-5 w-1/3 animate-pulse rounded-md bg-eonx-neutral-50 sm:mt-[5px]"
            />
          </template>
          <div v-else class="flex items-center justify-between space-x-[5px]">
            <ew-product-type
              v-if="hasGift"
              class="text-eonx-neutral-600"
              :gift="item.gift"
              :gift-card="!!item.retailerName"
              :physical="item.physical"
            >
              <template #digital>
                <div>Gift to {{ gift.email }}</div>
              </template>
              <template #physical>
                <div>Gift to {{ gift.name }}</div>
              </template>
            </ew-product-type>

            <ew-product-type
              v-else
              class="font-normal text-eonx-neutral-600"
              :icon-size="18"
              physical-icon="v2/box-icons/bx-truck"
              :physical="item.physical"
            >
              <template #digital>
                Delivered to
                {{ ewalletLabels.yourEwallet }}
              </template>
              <template #physical>Delivered by supplier</template>
            </ew-product-type>

            <div class="text-right text-sm text-eonx-neutral-600">
              {{ formattedTotal }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
