<script>
import FeedPosts from '/~rec/components/feed/feed-posts.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'

export default {
  name: 'rec-group-feed',
  components: {
    FeedPosts,
    RecBaseState,
  },
  provide() {
    return {
      sources: this.sources,
    }
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sources: {},
    }
  },
  watch: {
    group() {
      this.sources.source = this.group
    },
  },
  created() {
    this.sources.source = this.group
  },
}
</script>

<template>
  <feed-posts
    :feed="group.posts"
    :group-id="group.id"
    feed-type="group"
    @posts-loaded="$emit('posts-loaded')"
  >
    <template #empty>
      <rec-base-state
        image="recroom/state/simple-state.svg"
        title="No posts yet"
        subtitle="Share content, ideas or posts with your group"
      />
    </template>
  </feed-posts>
</template>
