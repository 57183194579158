<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import AppHeaderLogo from '/~/components/app/header/app-header-logo.vue'
import MobileMenu from '/~/components/mobile-menu/mobile-menu.v2.vue'
import { useCms } from '/~/composables/cms'
import HeaderTopMenu from './components/header-top-menu/header-top-menu.v5.vue'

const route = useRoute()
const { isHeaderHidden } = useCms()

const hidden = computed(
  () => isHeaderHidden.value || route.meta?.hideMobileHeader
)
</script>

<template>
  <header
    v-if="!hidden"
    class="relative border-b bg-light px-2.5 sm:border-none"
  >
    <portal-target name="app-header" slim />
    <div class="relative flex h-16 items-center">
      <mobile-menu ref="mobileMenuRef" />
      <portal-target name="app-header-menu" slim />

      <app-header-logo class="ml-4" :max-width="115" />
      <header-top-menu class="ml-auto" />
    </div>
  </header>
</template>
