<script>
import get from 'lodash-es/get'
import isEqual from 'lodash-es/isEqual'
import pick from 'lodash-es/pick'
import values from 'lodash-es/values'
import { ValidationObserver } from 'vee-validate'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useAddressForm, useAddresses } from '/~/composables/addresses'
import { useForm } from '/~/composables/base/use-form'
import { useLocalization } from '/~/composables/localization'
import { useUser } from '/~/composables/user'

const defaultData = {
  companyName: '',
  default: false,
  email: '',
  firstName: '',
  id: -1,
  lastName: '',
  mobile: '',
  postcode: '',
  state: '',
  streetAddress: '',
  suburb: '',
}

export default {
  name: 'new-address-modal',
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseMdl,
    BaseSelect,
    ValidationObserver,
  },
  props: {
    type: {
      type: String,
      default: 'create',
    },
    data: {
      type: Object,
      default: () => ({ ...defaultData }),
    },
  },
  setup() {
    const { addresses, addAddress, updateAddress } = useAddresses()
    const {
      countriesOptions,
      postcodeBindings,
      stateBindings,
      suburbBindings,
    } = useAddressForm()
    const { user } = useUser()
    const { states, translate } = useLocalization()
    const { validationObserverRef } = useForm()

    return {
      addresses,
      addAddress,
      updateAddress,
      user,
      countriesOptions,
      postcodeBindings,
      stateBindings,
      suburbBindings,
      states,
      translate,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {
        ...defaultData,
      },
      disabledSetDefault: false,
      submitting: false,
      backendErrors: {},
      initialData: {},
    }
  },
  computed: {
    stateOptions() {
      return this.states.map((state) => {
        return {
          text: state.label,
          value: state.label,
        }
      })
    },
    selectedStateId() {
      const state = this.states.find((state) => {
        return state.label === this.form.state
      })

      return state ? state.id : ''
    },
    disableSaveBtn() {
      const requiredFields = pick(this.form, [
        'email',
        'firstName',
        'lastName',
        'mobile',
        'postcode',
        'state',
        'streetAddress',
        'suburb',
      ])

      const emailErr = get(this.$refs, 'email.error')

      const fields = values(requiredFields)
      const filled = fields.every((item) => item) && !emailErr

      return this.submitting || !filled || isEqual(this.initialData, this.form)
    },
  },
  created() {
    const { email, firstName, lastName, mobile } = this.user
    const userDetails =
      this.type === 'create' ? { email, firstName, lastName, mobile } : {}

    const form = {
      ...this.form,
      ...this.data,
      ...userDetails,
    }

    /*
     * State selector based on label, because we need to fill values from autocomplete
     */
    if (form.state) {
      const dictState = this.states.find((state) => {
        return state.id === form.state
      })

      form.state = dictState ? dictState.label : form.state
    }

    this.form = form
    this.disabledSetDefault = this.type === 'upd' && form.default

    this.initialData = { ...form }
  },
  methods: {
    processBackendErrors(exception) {
      const { response } = exception

      if (response) {
        const { data, status } = response

        if (status === 400 || status === 403) {
          this.$notify({
            text: data.message,
            type: 'error',
            duration: 10000,
          })
        } else if (status === 422) {
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              const firstError = data[key][0]

              data[key] = firstError || ''
            }
          }

          this.backendErrors = data
        } else {
          console.error(exception)
        }
      } else {
        console.error(exception)

        for (const key in exception) {
          if (Object.prototype.hasOwnProperty.call(exception, key)) {
            this.$notify({
              text: exception[key][0],
              type: 'error',
              duration: 10000,
            })
            break
          }
        }
      }
    },
    async sendForm() {
      const { form } = this

      this.submitting = true
      this.backendErrors = {}

      const action =
        this.type === 'create' ? this.addAddress : this.updateAddress

      const params = {
        ...form,
        mobile: (form.mobile || '').replace(/\s/g, '').replace(/^\+61/g, '0'),
        state: this.selectedStateId || form.state,
      }

      return action(params)
        .then((data) => {
          this.submitting = false
          this.$emit('submit', data.id)
          modal.hide()
        })
        .catch((exception) => {
          this.submitting = false
          this.processBackendErrors({
            errors: exception,
          })
        })
    },
  },
}
</script>

<template>
  <base-mdl
    :title="type === 'create' ? 'Add address' : 'Edit address'"
    width="sm"
    fullscreen="mobile"
  >
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="grid gap-x-5 sm:grid-cols-2"
        @submit.prevent="handleSubmit(sendForm)"
      >
        <!-- input helps autofill State select -->
        <!-- hidden from user, cannot be invisible or hidden by code -->
        <div class="z-back absolute top-0 left-0 opacity-0">
          <base-input v-model="form.state" label="State" required />
        </div>

        <div class="sm:col-span-2">
          <base-input
            v-model="form.companyName"
            :error="backendErrors.companyName"
            label="Company name (if applicable)"
            name="Company name (if applicable)"
          />
        </div>

        <base-input
          v-model="form.firstName"
          :validation="{
            rules: 'required',
            name: 'First name',
          }"
          :error="backendErrors.firstName"
          label="First name"
          required
          name="firstName"
        />

        <base-input
          v-model="form.lastName"
          :validation="{
            rules: 'required',
            name: 'Last name',
          }"
          :error="backendErrors.lastName"
          label="Last name"
          required
          name="lastName"
        />

        <base-input
          v-model="form.mobile"
          :validation="{
            rules: 'required|mobile',
            name: 'Mobile',
          }"
          :error="backendErrors.mobile"
          type="tel"
          mask="mobile"
          label="Mobile"
          required
          name="mobile"
        />

        <base-input
          ref="email"
          v-model="form.email"
          :validation="{
            rules: 'required|email',
            name: 'Email',
          }"
          :error="backendErrors.email"
          label="Email"
          required
          name="email"
          type="email"
        />

        <base-input
          v-model="form.streetAddress"
          :validation="{
            rules: 'required',
            name: 'Street address',
          }"
          :error="backendErrors.streetAddress"
          label="Street address"
          required
          name="streetAddress"
          :maxlength="100"
        />

        <base-input
          v-bind="suburbBindings"
          v-model="form.suburb"
          :validation="{
            rules: 'required',
            name: 'suburb',
          }"
          :error="backendErrors.suburb"
          required
          name="suburb"
        />

        <base-select
          v-bind="stateBindings"
          v-model="form.state"
          :error="backendErrors.state"
          :options="stateOptions"
          name="state"
        />

        <base-input
          v-bind="postcodeBindings"
          v-model="form.postcode"
          :validation="{
            ...postcodeBindings.validation,
            rules: 'required',
          }"
          :error="backendErrors.postcode"
          required
          name="postcode"
          :label="translate('address.postcode')"
          class="addr-plugin__field landing-item"
        />

        <div
          class="mt-5 flex w-full flex-col sm:col-span-2 md:flex-row md:items-center md:justify-between"
        >
          <base-checkbox
            v-model="form.default"
            :disabled="disabledSetDefault"
            class="self-start pt-[15px] pb-[30px] md:self-center md:p-0"
          >
            Set as default
          </base-checkbox>

          <base-button
            :disabled="disableSaveBtn"
            :loading="submitting"
            full-width="mobile"
            type="submit"
          >
            Save
          </base-button>
        </div>
      </form>
    </validation-observer>
  </base-mdl>
</template>
