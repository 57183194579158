<script>
import { ref } from 'vue'
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { useProvider } from '/~/composables/provider'
import SummaryAddresses from './summary/summary-addresses.vue'
import SummaryPayTo from './summary/summary-pay-to.vue'
import SummaryPayWith from './summary/summary-pay-with.vue'
import SummaryPointsEarned from './summary/summary-points-earned.vue'
import SummaryTotal from './summary/summary-total.vue'

export default {
  name: 'checkout-summary',
  components: {
    BaseIcon,
    SummaryAddresses,
    SummaryPayTo,
    SummaryPayWith,
    SummaryTotal,
    SummaryPointsEarned,
  },
  setup() {
    const { isUserAddressEnabled } = useProvider()
    const { isQuickBuyCurrentFlowType } = useCheckoutReactive()

    const isHidden = ref(ui.mobile && isQuickBuyCurrentFlowType.value)

    function toggle() {
      isHidden.value = !isHidden.value
    }
    return {
      isQuickBuyCurrentFlowType,
      isUserAddressEnabled,
      isHidden,
      toggle,
      ui,
    }
  },
}
</script>

<template>
  <div class="mt-5 flex h-full flex-col text-eonx-neutral-800 sm:mt-0 sm:p-8">
    <div class="mb-5 flex h-full flex-col space-y-5 overflow-y-auto sm:mb-0">
      <div
        v-if="isQuickBuyCurrentFlowType && ui.mobile"
        class="flex cursor-pointer items-center justify-between hover:opacity-70"
        @click="toggle"
      >
        <span class="font-bold">View Summary (1 item)</span>
        <base-icon
          svg="heroicons/outline/chevron-up"
          :class="{ 'rotate-180': isHidden }"
          :size="24"
        />
      </div>
      <span v-else class="mt-2.5 mb-5 text-2xl font-bold">Summary</span>
      <div :class="{ hidden: isHidden }">
        <summary-addresses v-if="isUserAddressEnabled" />
        <summary-pay-to />
        <summary-pay-with />
      </div>
    </div>

    <summary-total />
    <summary-points-earned />
  </div>
</template>
