export default [
  {
    icon: 'health/question',
    name: 'General',
    questions: [
      {
        title: 'When were you born?',
        content: {
          type: 'date-picker',
          required: true,
        },
      },
      {
        title: 'What gender do you identify as?',
        content: {
          type: 'select',
          actions: [
            {
              icon: 'health/round/male',
              name: 'Male',
              value: 'male',
            },
            {
              icon: 'health/round/female',
              name: 'Female',
              value: 'female',
            },
          ],
        },
      },
      {
        title: 'What is your relationship status?',
        content: {
          type: 'select-buttons',
          actions: [
            {
              name: 'Single',
              value: 'single',
            },
            {
              name: 'Divorced',
              value: 'divorced',
            },
            {
              name: 'Engaged',
              value: 'engaged',
            },
            {
              name: 'Married',
              value: 'married',
            },
            {
              name: 'Widowed',
              value: 'widowed',
            },
          ],
        },
      },
      {
        title: 'Are you a parent?',
        content: {
          type: 'select',
          actions: [
            {
              icon: 'health/round/yes',
              name: 'Yes',
              value: true,
            },
            {
              icon: 'health/round/no',
              name: 'No',
              value: false,
            },
          ],
        },
      },
      {
        title:
          'Do you provide care and support to  someone who has disability, medical condition, mental illness or frail aged?',
        content: {
          type: 'select',
          actions: [
            {
              icon: 'health/round/yes',
              name: 'Yes',
              value: true,
            },
            {
              icon: 'health/round/no',
              name: 'No',
              value: false,
            },
          ],
        },
      },
      {
        title: 'How would you rate your overall health?',
        content: {
          type: 'select-buttons',
          actions: [
            {
              name: 'Very Unhealthy',
              value: 'very-unhealthy',
            },
            {
              name: 'Unhealthy',
              value: 'unhealthy',
            },
            {
              name: 'Standard',
              value: 'standard',
            },
            {
              name: 'Healthy',
              value: 'healthy',
            },
            {
              name: 'Very Healthy',
              value: 'very-healthy',
            },
          ],
        },
      },
    ],
  },
  {
    icon: 'health/medical',
    name: 'Medical',
    questions: [
      {
        title:
          'Have you ever been treated or prescribed medication for any of the following medical conditions?',
        content: {
          type: 'select-multiple',
          required: true,
          actions: [
            {
              name: 'Allergies',
              value: 'allergies',
            },
            {
              name: 'Arthritis',
              value: 'arthritis',
            },
            {
              name: 'Asthma',
              value: 'asthma',
            },
            {
              name: 'Back pain',
              value: 'back-pain',
            },
            {
              name: 'Cancer',
              value: 'cancer',
            },
            {
              name: 'Pulmonary Disease',
              value: 'pulmonary-disease',
            },
            {
              name: 'Chronic pain',
              value: 'chronic-pain',
            },
            {
              name: 'Depression',
              value: 'depression',
            },
            {
              name: 'Diabetes',
              value: 'diabetes',
            },
            {
              name: 'Heartburn or acid reflux',
              value: 'heartburn-or-acid-reflux',
            },
            {
              name: 'Heart disease',
              value: 'heart-disease',
            },
            {
              name: 'High blood pressure',
              value: 'high-blood-pressure',
            },
            {
              name: 'High cholesterol',
              value: 'high-cholesterol',
            },
            {
              name: 'Migraine headaches',
              value: 'migraine-headaches',
            },
            {
              name: 'Osteoporosis',
              value: 'osteoporosis',
            },
            {
              name: 'Sleep disorder',
              value: 'sleep-disorder',
            },
            {
              name: 'Stroke',
              value: 'stroke',
            },
            {
              name: 'Thyroid disease',
              value: 'thyroid-disease',
            },
            {
              name: 'Other',
              value: 'other',
            },
          ],
        },
      },
      {
        title:
          'Has anyone in your family ever been treated or prescribed medication for any of the following medical conditions?',
        content: {
          type: 'select-multiple',
          required: true,
          actions: [
            {
              name: 'Asthma',
              value: 'asthma',
            },
            {
              name: 'Cancer',
              value: 'cancer',
            },
            {
              name: 'Diabetes',
              value: 'diabetes',
            },
            {
              name: 'Heart disease',
              value: 'heart-disease',
            },
            {
              name: 'High blood pressure',
              value: 'high-blood-pressure',
            },
            {
              name: 'High cholesterol',
              value: 'high-cholesterol',
            },
            {
              name: 'Pulmonary Disease',
              value: 'pulmonary-disease',
            },
            {
              name: 'Stroke',
              value: 'stroke',
            },
            {
              name: 'Osteoporosis',
              value: 'osteoporosis',
            },
          ],
        },
      },
    ],
  },
  {
    icon: 'health/measurements',
    name: 'Measurements',
    questions: [
      {
        title: 'How tall are you?',
        content: {
          type: 'numeric',
          scale: 'cm',
        },
      },
      {
        title: 'How much do you weight?',
        content: {
          type: 'numeric',
          scale: 'kg',
        },
      },
      {
        title: 'How much does your waist measure?',
        content: {
          type: 'numeric',
          scale: 'cm',
        },
      },
      {
        title:
          'Please provide some information from your last blood test results',
        content: {
          type: 'radio',
          actions: [
            {
              name: "What's your total cholesterol level?",
              values: ['Below 4.0 mmol/L', 'Above 4.0 mmol/L'],
            },
            {
              name: "What's your Low Density lipoprotein level?",
              values: ['Below 2.0 mmol/L', 'Above 2.0 mmol/L'],
            },
            {
              name: "What's your High Density lipoprotein level?",
              values: ['Below 1.0 mmol/L', 'Above 1.0 mmol/L'],
            },
          ],
        },
      },
      // {
      //   title: 'Please provide some other medical test results',
      //   component: null,
      // },
    ],
  },
  {
    icon: 'health/social',
    name: 'Social',
    questions: [
      {
        title: 'Do you currently use any tobacco or nicotine products?',
        content: {
          type: 'select',
          required: true,
          actions: [
            {
              icon: 'health/round/yes',
              name: 'Yes',
              value: true,
            },
            {
              icon: 'health/round/no',
              name: 'No',
              value: false,
            },
          ],
        },
      },
      {
        title:
          'Which of the following tobacco or nicotine products do you use?',
        parent: 0,
        content: {
          type: 'select-multiple',
          required: true,
          actions: [
            {
              name: 'Cigarettes',
              value: 'cigarettes',
            },
            {
              name: 'Cigars',
              value: 'cigars',
            },
            {
              name: 'Pipe tobacco',
              value: 'pipe-tobacco',
            },
            {
              name: 'Chewing tobacco',
              value: 'chewing-tobacco',
            },
            {
              name: 'Electronic cigarettes',
              value: 'electronic-cigarettes',
            },
          ],
        },
      },
      {
        title: 'How often are you smoking per week?',
        parent: 0,
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: '1 - 5 times a week',
              value: '1-5/w',
            },
            {
              name: '5 - 10 times a week',
              value: '5-10/w',
            },
            {
              name: '1 - 5 times a day',
              value: '1-5/d',
            },
            {
              name: '5 - 10 times a day',
              value: '5-10/d',
            },
            {
              name: 'More than 10 times a day',
              value: '10+/d',
            },
          ],
        },
      },
      {
        title:
          'Do you intend to make any changes to your current smoking habits?',
        parent: 0,
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Would like to stop immediately',
              value: 'immediately',
            },
            {
              name: 'Would like to stop soon',
              value: 'soon',
            },
            {
              name: 'Could consider a change',
              value: 'consider',
            },
            {
              name: 'Not for now',
              value: 'not-now',
            },
            {
              name: 'I have no intention to stop',
              value: 'no',
            },
          ],
        },
      },
      {
        title: 'Do you currently consume any alcohol?',
        content: {
          type: 'select',
          required: true,
          actions: [
            {
              icon: 'health/round/yes',
              name: 'Yes',
              value: true,
            },
            {
              icon: 'health/round/no',
              name: 'No',
              value: false,
            },
          ],
        },
      },
      {
        title: 'How often do you consume alcohol? ',
        parent: 4,
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: '1 - 2 times a week',
              value: '1-2/w',
            },
            {
              name: '2 - 5 times a week',
              value: '2-5/w',
            },
            {
              name: '1 - 5 times a day',
              value: '1-5/d',
            },
            {
              name: 'Every day of the week',
              value: 'every-day',
            },
          ],
        },
      },
      {
        title: 'How many standard drinks would you normally consume?',
        parent: 4,
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Only one drink',
              value: '1',
            },
            {
              name: '2 - 3 drinks',
              value: '2-3',
            },
            {
              name: '3 - 5 drinks',
              value: '3-5',
            },
            {
              name: 'More than 5 drinks',
              value: '5+',
            },
          ],
        },
      },
      {
        title:
          'Do you intend to make any changes to your current alcohol consumption  habits?',
        parent: 4,
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Would like to stop immediately',
              value: 'immediately',
            },
            {
              name: 'Would like to stop soon',
              value: 'soon',
            },
            {
              name: 'Could consider a change',
              value: 'consider',
            },
            {
              name: 'Not for now',
              value: 'not-now',
            },
            {
              name: 'I have no intention to stop',
              value: 'no',
            },
          ],
        },
      },
    ],
  },
  {
    icon: 'health/diet',
    name: 'Diet',
    questions: [
      {
        title: 'What is your average vegetable intake per day?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'I don’t eat vegetables',
              value: '0',
            },
            {
              name: '1 - 2 times a week',
              value: '1-2',
            },
            {
              name: '2 - 4 times a week',
              value: '2-4',
            },
            {
              name: '4 - 6 times a week',
              value: '4-6',
            },
            {
              name: 'Every day of the week',
              value: 'every-day',
            },
          ],
        },
      },
      {
        title: 'What is your average fruit intake per day?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'I don’t eat fruits',
              value: '0',
            },
            {
              name: '1 - 2 times a week',
              value: '1-2',
            },
            {
              name: '2 - 4 times a week',
              value: '2-4',
            },
            {
              name: '4 - 6 times a week',
              value: '4-6',
            },
            {
              name: 'Every day of the week',
              value: 'every-day',
            },
          ],
        },
      },
      {
        title: 'What is your average wholegrain intake per day?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'I don’t eat wholegrain',
              value: '0',
            },
            {
              name: '1 - 2 times a week',
              value: '1-2',
            },
            {
              name: '2 - 4 times a week',
              value: '2-4',
            },
            {
              name: '4 - 6 times a week',
              value: '4-6',
            },
            {
              name: 'Every day of the week',
              value: 'every-day',
            },
          ],
        },
      },
      {
        title:
          'On average, how many meals per week are prepared with higher fat cooking methods (i.e. deep-frying, etc)',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: '0 meals',
              value: '0',
            },
            {
              name: '1 - 2 meals a week',
              value: '1-2',
            },
            {
              name: '2 - 4 meals a week',
              value: '2-4',
            },
            {
              name: '4 - 6 meals a week',
              value: '4-6',
            },
            {
              name: '7 - 10 meals a week',
              value: '7-10',
            },
            {
              name: 'Almost every meal',
              value: 'every-meal',
            },
          ],
        },
      },
      {
        title:
          'On average, how much extra salt are you adding during meal preparation per day?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'I don’t add extra salt',
              value: 'no',
            },
            {
              name: 'Half a teaspoon',
              value: 'half',
            },
            {
              name: 'Level teaspoon',
              value: 'level',
            },
            {
              name: '2 Teaspoons',
              value: '2',
            },
            {
              name: 'A tablespoon',
              value: 'tablespoon',
            },
            {
              name: 'More than a tablespoon',
              value: 'more',
            },
          ],
        },
      },
      {
        title:
          'On average, how many salty foods (packet soups, stock cubes, salty snacks, canned food) are you eating?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'I don’t eat salty foods',
              value: '0',
            },
            {
              name: '1 - 2 a week',
              value: '1-2/w',
            },
            {
              name: '2 - 4 a week',
              value: '2-4/w',
            },
            {
              name: '4 - 6 a week',
              value: '4-6/w',
            },
            {
              name: '1 - 2 every day',
              value: '1-2/d',
            },
            {
              name: '2 - 4 every day',
              value: '2-4/d',
            },
            {
              name: '5 or more every day',
              value: 'more',
            },
          ],
        },
      },
      {
        title:
          'On average, how many meals with lean meats (turkey, fish, skinless chicken, red meats, etc) do you consume?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'I don’t eat lean meats',
              value: '0',
            },
            {
              name: '1 - 2 a week',
              value: '1-2',
            },
            {
              name: '2 - 4 a week',
              value: '2-4',
            },
            {
              name: '4 - 6 a week',
              value: '4-6',
            },
            {
              name: 'One every day of the week',
              value: '1/d',
            },
            {
              name: 'More than one a day',
              value: 'more',
            },
          ],
        },
      },
      {
        title:
          'On average, how many fat free, or low-fat dairy products do you consume?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'I don’t eat low-fat products',
              value: '0',
            },
            {
              name: '1 - 2 a week',
              value: '1-2',
            },
            {
              name: '2 - 4 a week',
              value: '2-4',
            },
            {
              name: '4 - 6 a week',
              value: '4-6',
            },
            {
              name: 'One every day of the week',
              value: '1/d',
            },
            {
              name: 'More than one a day',
              value: 'more',
            },
          ],
        },
      },
    ],
  },
  {
    icon: 'health/physical',
    name: 'Physical',
    questions: [
      {
        title:
          'On average, how many hours per day do you spend sitting (work, watching television, etc)?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Less than an hour',
              value: '0-1',
            },
            {
              name: '1 - 2 hours',
              value: '1-2',
            },
            {
              name: '2 - 4 hours',
              value: '2-4',
            },
            {
              name: '4 - 8 hours',
              value: '4-8',
            },
            {
              name: 'More than 8 hours',
              value: 'more',
            },
          ],
        },
      },
      {
        title:
          'Which of the following best defines your physical activity level?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Unactive (1 -3 hours a week)',
              value: '1-3/w',
            },
            {
              name: 'Reasonably active (3 - 6 hours per week)',
              value: '3-6/w',
            },
            {
              name: 'Active (1 hour a day)',
              value: '1/d',
            },
            {
              name: 'Very Active (more than 2 hours a day)',
              value: '2/d',
            },
          ],
        },
      },
      {
        title:
          'On average, how many times per week would you complete each type of exercise?',
        content: {
          type: 'radio',
          actions: [
            {
              name: 'Strength exercises (i.e. push-ups, lifting weights, etc)',
              values: [
                'Never',
                '1 - 2 times a week',
                '2 - 6 times a week',
                'At least once a day ',
              ],
            },
            {
              name: 'Flexibility exercises (i.e. stretching, yoga, pilates, etc)',
              values: [
                'Never',
                '1 - 2 times a week',
                '2 - 6 times a week',
                'At least once a day ',
              ],
            },
          ],
        },
      },
      {
        title: 'Do you expect to make a change to your current activity level?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Would like to make a change immediately',
              value: 'immediately',
            },
            {
              name: 'Would like to make a change soon',
              value: 'soon',
            },
            {
              name: 'Could consider a to make change',
              value: 'consider',
            },
            {
              name: 'Not for now',
              value: 'not-now',
            },
            {
              name: 'I have no intention to make a change',
              value: 'no',
            },
          ],
        },
      },
      {
        title:
          'Over the past month, would you say you experienced any of the following: trouble falling asleep, staying asleep, waking up feeling unrested?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Never',
              value: 'never',
            },
            {
              name: 'Rarely',
              value: 'rarely',
            },
            {
              name: 'Sometimes',
              value: 'sometimes',
            },
            {
              name: 'Often',
              value: 'often',
            },
            {
              name: 'Very often',
              value: 'very-often',
            },
            {
              name: 'Almost every day',
              value: 'every-day',
            },
          ],
        },
      },
      {
        title: 'On average, how many hours do you sleep per night?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Less than 4',
              value: '0-4',
            },
            {
              name: 'Between 5 - 6',
              value: '5-6',
            },
            {
              name: 'Between 7 - 8',
              value: '7-8',
            },
            {
              name: 'More than 8',
              value: 'more',
            },
          ],
        },
      },
    ],
  },
  {
    icon: 'health/emotional',
    name: 'Emotional',
    questions: [
      {
        title: 'Please provide some information to measure your stress levels',
        content: {
          type: 'radio',
          actions: [
            {
              name: 'Over the past 30 days, how would you rate your general stress levels?',
              values: ['None', 'Very low', 'Low', 'Standar', 'High'],
            },
            {
              name: 'In the last 3 months, what would you consider a major source/cause of stress in your life?',
              values: [
                'Home life',
                'Work life',
                'Economy',
                'Personal problems',
              ],
            },
            {
              name: 'Do you actively participate in stress reduction activities/strategies?',
              values: ['Yes', 'No'],
            },
          ],
        },
      },
      {
        title:
          'Would you consider making a change to your current lifestyle to better manage stress caused through daily life/work?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Would like to make a change immediately',
              value: 'immediately',
            },
            {
              name: 'Would like to make a change soon',
              value: 'soon',
            },
            {
              name: 'Could consider a to make change',
              value: 'consider',
            },
            {
              name: 'Not for now',
              value: 'not-now',
            },
            {
              name: 'I have no intention to make a change',
              value: 'no',
            },
          ],
        },
      },
      {
        title: 'Please provide some information to measure your rested levels',
        content: {
          type: 'radio',
          actions: [
            {
              name: 'On average, how would you rate your overall rested levels per day?',
              values: ['Poorly rested', 'Rested', 'Very well rested'],
            },
            {
              name: 'On average, how would you rate your overall energy levels per day?',
              values: [
                'Very low energy',
                'Low energy',
                'Standar energy levels',
                'High energy levels',
                'Very high energy levels',
              ],
            },
            {
              name: 'During the last 30 days, how often have you felt tired out for no good reason? (K10 Stress Test)',
              values: ['Never', 'Sporadically', 'Often', 'Very often'],
            },
          ],
        },
      },
      {
        title:
          'During the last 30 days, how often have you felt so nervous that nothing could calm you down?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Never',
              value: 'never',
            },
            {
              name: 'Sporadically',
              value: 'sporadically',
            },
            {
              name: 'Often',
              value: 'often',
            },
            {
              name: 'Very often',
              value: 'very-often',
            },
            {
              name: 'All the time',
              value: 'all-time',
            },
          ],
        },
      },
      {
        title:
          'During the last 30 days, how often have you felt so restless that you could not sit still?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Never',
              value: 'never',
            },
            {
              name: 'Sporadically',
              value: 'sporadically',
            },
            {
              name: 'Often',
              value: 'often',
            },
            {
              name: 'Very often',
              value: 'very-often',
            },
            {
              name: 'All the time',
              value: 'all-time',
            },
          ],
        },
      },
      {
        title:
          'During the last 30 days, how often have you felt so depressed that nothing could cheer you up?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Never',
              value: 'never',
            },
            {
              name: 'Sporadically',
              value: 'sporadically',
            },
            {
              name: 'Often',
              value: 'often',
            },
            {
              name: 'Very often',
              value: 'very-often',
            },
            {
              name: 'All the time',
              value: 'all-time',
            },
          ],
        },
      },
      {
        title:
          'During the last 30 days, how often have you felt that everything was an effort?',
        content: {
          type: 'select-buttons',
          required: true,
          actions: [
            {
              name: 'Never',
              value: 'never',
            },
            {
              name: 'Sporadically',
              value: 'sporadically',
            },
            {
              name: 'Often',
              value: 'often',
            },
            {
              name: 'Very often',
              value: 'very-often',
            },
            {
              name: 'All the time',
              value: 'all-time',
            },
          ],
        },
      },
    ],
  },
]
