<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

/**
 * Todo: based on the 'width', can resize the image preview
 */
export default {
  name: 'rec-message-entry-video',
  components: {
    BaseIcon,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    hiddenAttachmentsCount: {
      type: Number,
      default: 0,
    },
    dimension: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasMoreAttachments() {
      return this.hiddenAttachmentsCount > 0
    },
  },
}
</script>

<template>
  <div class="relative flex">
    <span
      v-if="hasMoreAttachments"
      class="text absolute inset-0 z-10 flex items-center justify-center bg-overlay text-3xl font-bold text-white sm:text-5xl"
    >
      +{{ hiddenAttachmentsCount }}
    </span>
    <span v-else class="absolute inset-0 z-10 flex items-center justify-center">
      <base-icon
        svg="rec/play-circle"
        :size="/full|half/.test(dimension.size) ? '4xl' : '2xl'"
      />
    </span>
    <video
      :src="`${attachment.cdn}#t=0.1`"
      preload="metadata"
      class="object-cover"
    />
  </div>
</template>
