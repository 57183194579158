import omitBy from 'lodash-es/omitBy'
import { computed, reactive, ref } from 'vue'
import { AlgoliaWorker, getDefaultIndex } from '/~/core/algolia'
import emitter from '/~/core/emitter'
import { MapWorker } from '/~/core/map'
import { useExtensions } from '/~/composables/extensions'
import { useProvider } from '/~/composables/provider'
import { useSearch } from '/~/composables/search'
import appConfig from '/~/config'

const { getManifestByName, getConfigByName, getMetaByName } = useExtensions()
const { registerAlgoliaSearch } = useSearch()

const DINING_MODULE_NAME = 'dining'

const algolia = reactive(new AlgoliaWorker())
const mapState = reactive(new MapWorker())
const searchInput = ref('')
const selectedFeeds = ref([])
const retailerImage = ref('')
const cuisines = ref(null)
const selectedCuisine = ref(null)
const selectedState = ref('')
const features = ref([])
const selectedFeatures = ref([])
const priceRanges = ref([])
const selectedPriceRanges = ref([])

const module = computed(() => getManifestByName(DINING_MODULE_NAME))
const config = computed(() => getConfigByName(DINING_MODULE_NAME))
const meta = computed(() => getMetaByName(DINING_MODULE_NAME))
const label = computed(() => module.value?.label ?? 'Dining')
const index = computed(() => getDefaultIndex(config.value))

const searchConfig = computed(() => config.value?.search ?? {})
const searchGroup = computed(() => searchConfig.value?.group ?? label.value)

const feeds = computed(() => {
  const { diningSources } = useProvider()

  return diningSources.value.map((item) => {
    return {
      source: item.source,
      label: item.label || item.source,
      text: item.label,
      value: item.source,
      icon: {
        name: 'plain/pin',
        color: item.source === 'GoodFood' ? '#1491d3' : '#fcb418',
      },
    }
  })
})
const allFeedsSelected = computed(
  () => feeds.value.length === selectedFeeds.value.length
)
const routeQuery = computed(() => {
  const query = {
    ...mapState.routeQuery,
    search: searchInput.value,
    state: selectedState.value,
    cuisines: selectedCuisine.value,
    features: selectedFeatures.value.join(';'),
    priceRanges: selectedPriceRanges.value.join(';'),
  }

  if (selectedFeeds.value.length && !allFeedsSelected.value) {
    query.feeds = selectedFeeds.value.join(',')
  }

  return omitBy(query, (v) => !v)
})
const offerDiscount = computed(() => meta.value?.discount ?? 0)
const isFiltersSelected = computed(
  () =>
    searchInput.value !== '' ||
    selectedState.value !== '' ||
    (selectedCuisine.value !== cuisines.value?.[0].value &&
      selectedCuisine.value !== null) ||
    selectedFeatures.value.length > 0 ||
    selectedPriceRanges.value.length > 0
)

function resetFilters() {
  searchInput.value = ''
  selectedState.value = ''
  selectedCuisine.value = null
  selectedFeatures.value.splice(0)
  selectedPriceRanges.value.splice(0)
}

function initDining() {
  if (!config.value) {
    return console.error('no config for dining module')
  }

  const image = meta.value?.retailer?.image

  if (image) {
    retailerImage.value = image
  }

  algolia.setParams(config.value)

  registerAlgoliaSearch({
    group: config.value?.search?.group ?? 'Dining',
    order: 4,
    config: config.value,
    algolia: {
      params: {},
    },
    mapping: (item) => {
      return {
        id: item.objectID,
        image: item.imgs.length && item.imgs[0],
        label: item.name,
        target: {
          name: 'dining-item',
          params: {
            slug: item.slug,
          },
        },
      }
    },
  })
}

async function syncState(routeQueryParams: any) {
  const newStateIsEmpty = Object.keys(routeQueryParams).length === 0
  const prevState = JSON.stringify(routeQuery.value)

  if (newStateIsEmpty) {
    mapState.reset()
    algolia.reset()
  }

  await mapState.sync(routeQueryParams)

  const newState = JSON.stringify(routeQuery.value)
  const isDifferentStates = newState !== prevState

  if (routeQueryParams.feeds) {
    selectedFeeds.value = routeQueryParams.feeds.split(',')
  } else {
    selectedFeeds.value = feeds.value.map((feed: any) => feed.value)
  }
  /*
   * If map visible, locations will be got by bounds watcher in dining-catalog
   */
  if ((newStateIsEmpty || isDifferentStates) && !routeQueryParams.map) {
    getLocations()
  }
  getFilters()
}

emitter.once('google-map:auth-failed', () => {
  getLocations()
})

async function getSearchResults(queryString) {
  return algolia
    .getData({
      index: index.value,
      search: {
        query: queryString,
        hitsPerPage: 25,
        facets: ['cuisine', 'address.state', 'feature', 'priceRange'],
        facetFilters: commonFilters(),
      },
    })
    .then((result) => {
      if (selectedFeatures.value.length === 0 || algolia.hits.length > 0) {
        features.value =
          algolia.parseFacets({ facets: algolia.facets }).get('feature')
            ?.values ?? []
      }

      return result.hits.map((item) => {
        return {
          ...item,
          label: `${item.name} | Restaurant`,
        }
      })
    })
    .catch(() => [])
}

async function getRelativeAddress(queryString: string) {
  const placesTask = mapState
    .getPlacePredictions(queryString)
    .then((result) => {
      return (result as { place_id: string; description: string }[]).map(
        (item) => {
          return {
            placeId: item.place_id,
            label: `${item.description} | Suburb`,
          }
        }
      )
    })
    .catch(() => [])

  const [locations] = await Promise.all([placesTask])

  return locations
}

function getLocations({ ignoreBoundingBox = false } = {}) {
  const ignoreBoundingBoxExtended = !mapState.visible || ignoreBoundingBox

  return algolia
    .getData({
      index: index.value,
      search: {
        query: searchInput.value,
        hitsPerPage: mapState.visible ? appConfig.maxMarkersOnMap : 25,
        insideBoundingBox:
          selectedState.value || ignoreBoundingBoxExtended
            ? ''
            : mapState.boundsString,
        facets: ['cuisine', 'address.state', 'feature', 'priceRange'],
        facetFilters: commonFilters(),
        // filters: selectedFeeds.value.map(i => `source:${i}`).join(' OR '),
      },
    })
    .then(() => {
      if (selectedFeatures.value.length === 0 || algolia.hits.length > 0) {
        features.value =
          algolia.parseFacets({ facets: algolia.facets }).get('feature')
            ?.values ?? []
      }
    })
}

async function getFilters() {
  const facets = await algolia.getFacets({
    index: index.value,
    search: {
      facets: ['cuisine', 'feature', 'priceRange'],
    },
  })

  if (facets) {
    const cuisinesFacets = facets.get('cuisine')
    const featuresFacets = facets.get('feature')
    const priceRangeFacets = facets.get('priceRange')

    if (cuisinesFacets) {
      cuisinesFacets.values.unshift({
        label: 'All cuisines',
        count: cuisinesFacets.total,
        id: null,
      })
      cuisines.value = cuisinesFacets.values
    } else {
      cuisines.value = [
        {
          label: 'All cuisines',
        },
      ]
    }

    features.value = featuresFacets?.values ?? []
    priceRanges.value = priceRangeFacets?.values ?? []
  }
}

function commonFilters() {
  const cuisine = algolia.decodePath(selectedCuisine.value)
  const address = algolia.decodePath(selectedState.value)
  const featuresFilter = selectedFeatures.value.map((item) => {
    return `feature:${item}`
  })
  const pricesFilter = selectedPriceRanges.value.map((item) => {
    return `priceRange:${item}`
  })

  return [
    ...(cuisine ? [`cuisine:${cuisine}`] : []),
    ...(address ? [`address.state:${address}`] : []),
    ...(featuresFilter.length ? [featuresFilter] : []),
    ...(pricesFilter.length ? [pricesFilter] : []),
  ]
}

export function useDining() {
  return {
    module,
    config,
    meta,
    label,
    index,
    feeds,
    algolia,
    searchGroup,
    allFeedsSelected,
    routeQuery,
    offerDiscount,
    isFiltersSelected,
    mapState,
    moduleName: DINING_MODULE_NAME,
    searchInput,
    cuisines,
    selectedCuisine,
    features,
    selectedFeatures,
    priceRanges,
    selectedPriceRanges,
    selectedState,

    resetFilters,
    initDining,
    syncState,
    getSearchResults,
    getRelativeAddress,
    getLocations,
  }
}
