import { readonly, ref } from 'vue'
import { ApiResponseData } from '/~/types/api'
import api from '/~/core/api'
import { PhotoId } from './core/photo-id'

const isPhotoIdLoading = ref(false)
const photoId = ref<PhotoId | null>(null)

function setPhotoId(document: PhotoId) {
  photoId.value = document
}

async function fetchPhotoId(id: string) {
  isPhotoIdLoading.value = true

  try {
    const response = await api.get<ApiResponseData<unknown>>(
      `/v3/identification/documents/${id}`
    )

    photoId.value = new PhotoId(response.data)
  } catch (error) {
    console.error('identification', error)
    throw error
  } finally {
    isPhotoIdLoading.value = false
  }
}

async function deletePhotoId() {
  if (!photoId.value) {
    return
  }

  isPhotoIdLoading.value = true

  try {
    await api.delete(`/v3/identification/documents/${photoId.value.id}`)

    photoId.value = null
  } catch (error) {
    console.error('identification', error)
  } finally {
    isPhotoIdLoading.value = false
  }
}

async function fetchPhotoIdImage(hash: string) {
  return api.get(`/v3/identification/images/${hash}`, { responseType: 'blob' })
}

export function usePhotoId() {
  return {
    isPhotoIdLoading: readonly(isPhotoIdLoading),
    photoId: readonly(photoId),

    setPhotoId,
    fetchPhotoId,
    deletePhotoId,
    fetchPhotoIdImage,
  }
}
