<script setup lang="ts">
import ui from '/~/core/ui'
import NavListDesktop from '/~/components/section-menu/nav-list.desktop.vue'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'

const { leftMenu } = useUI()
const { isBillPaymentsV15Template } = useProvider()
</script>

<template>
  <div
    class="scrollable-content max-w-[350px] py-8 px-4"
    :class="{
      'min-w-[250px]': !ui.tablet2024, // TODO revert once 1.5 is ready and merged
      'border-r border-eonx-neutral-400': isBillPaymentsV15Template,
    }"
  >
    <nav-list-desktop :items="leftMenu" class="pr-1.5" />
  </div>
</template>
