import { RewardsExtension } from '/~/core/extension'
import { useCheckoutRoutes } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
const { generateCheckoutRoutes } = useCheckoutRoutes()

class BankFilesExtension extends RewardsExtension {
  setRoutes() {
    return [
      generateCheckoutRoutes({
        type: FlowType.batch,
        subFlowType: 'bank-file',
        rootPath: 'payments/bank-files/pay',
        meta: {
          back: {
            name: 'bank-file-upload',
          },
        },
      }),
      {
        path: 'bank-files',
        component: () => import('/~/extensions/bank-file-upload/root.vue'),
        meta: {
          parentRoute: 'payments',
          flowType: FlowType.batch,
        },
        children: [
          {
            path: '',
            name: 'bank-file-upload',
            component: () =>
              import('/~/extensions/bank-file-upload/views/upload.vue'),
            meta: {
              title: 'Upload bank file',
              flowType: FlowType.batch,
            },
          },
          {
            path: 'new-payees-detected',
            name: 'batch-payment-new-payees-detected',
            component: () =>
              import(
                '/~/extensions/bank-file-upload/views/process/new-payees-detected.vue'
              ),
            meta: {
              title: 'New payees detected',
              flowType: FlowType.batch,
            },
          },
          {
            path: 'unsupported-payees-detected',
            name: 'batch-payment-unsupported-payees-detected',
            component: () =>
              import(
                '/~/extensions/bank-file-upload/views/process/unsupported-payees-detected.vue'
              ),
            meta: {
              title: 'Ineligible payees detected',
              flowType: FlowType.batch,
            },
          },
          {
            path: 'pending-payees',
            name: 'batch-payment-pending-payees',
            component: () =>
              import(
                '/~/extensions/bank-file-upload/views/process/pending-payees.vue'
              ),
            meta: {
              title: 'Pending payees',
              flowType: FlowType.batch,
            },
          },
        ],
      },
    ].filter(Boolean)
  }
}

export default BankFilesExtension
