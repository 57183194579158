<script>
import { ref, computed } from 'vue'
import { useServices } from '/~/extensions/health/composables'
import cdn from '/~/utils/cdn'
import BaseLink from '/~/components/base/link/base-link.vue'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import RetailerLogo from './logo.vue'
import Offers from './offers.vue'

export default {
  name: 'details-view-service',
  components: {
    RetailerLogo,
    Offers,
    EwDetailsLayout,
    BaseLink,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { feed, queryState, initServices } = useServices()

    const loading = ref(true)
    const retailer = ref(null)
    const offers = ref([])
    const filteredOffers = ref([
      {
        createdAt: '2020-12-13T22:55:50+00:00',
        id: 'fd8fade6-59b4-4388-80aa-c5344ee47ec6',
        updatedAt: '2020-12-13T22:55:50+00:00',
        active: true,
        couponCode: '#CHEAPRIDES',
        couponType: 'in_store',
        description: 'Use the Coupon Code to get off 10% off all Ride Tickets!',
        endsAt: null,
        locations: [],
        name: '10% off all Ticket Rides!',
        promotionUrl: null,
        provider: {
          id: '216af113-8e9a-4d66-a9d2-d285ffe16594',
          indexId:
            'community_hub_provider_216af113-8e9a-4d66-a9d2-d285ffe16594',
          name: 'Test',
          offersCount: 13,
          search_api_key:
            'ZTRjOWQxYmVmYTY5ZDdiOTQ3ZGQ1ZjM5YmYyODgzNWVlNmRhMjE5MDZhM2RlYWE2ODkxNzg5NGIyMWNjM2ZhNHJlc3RyaWN0SW5kaWNlcz1zdGFnaW5nX2NvbW11bml0eV9odWJfcHJvdmlkZXJfMjE2YWYxMTMtOGU5YS00ZDY2LWE5ZDItZDI4NWZmZTE2NTk0JTJDc3RhZ2luZ19jb21tdW5pdHlfaHViX3Byb3ZpZGVyXzIxNmFmMTEzLThlOWEtNGQ2Ni1hOWQyLWQyODVmZmUxNjU5NF9yZXRhaWxlcl9hc2MlMkNzdGFnaW5nX2NvbW11bml0eV9odWJfcHJvdmlkZXJfMjE2YWYxMTMtOGU5YS00ZDY2LWE5ZDItZDI4NWZmZTE2NTk0X3JldGFpbGVyX2Rlc2MmdmFsaWRVbnRpbD0xNjQ5NDYxNDA4',
          search_app_id: 'UPMIJ6PZJ3',
          indexes: [
            {
              default: true,
              index_id:
                'staging_community_hub_provider_216af113-8e9a-4d66-a9d2-d285ffe16594',
              label: 'Most popular',
            },
            {
              default: false,
              index_id:
                'staging_community_hub_provider_216af113-8e9a-4d66-a9d2-d285ffe16594_retailer_asc',
              label: 'Retailers (A-Z)',
            },
            {
              default: false,
              index_id:
                'staging_community_hub_provider_216af113-8e9a-4d66-a9d2-d285ffe16594_retailer_desc',
              label: 'Retailers (Z-A)',
            },
          ],
        },
        redemptionImage: null,
        redirectContent: null,
        redirectPage: false,
        retailer: {
          id: '8aa286f5-34bc-498a-8d21-49578b1351e6',
          description:
            'Luna Park Melbourne is a historic amusement park located on the foreshore of Port Phillip Bay in St Kilda, Melbourne, Victoria.',
          logo: cdn(
            'https://ucarecdn.com/26442675-a681-4dc3-b218-839e9e403a0e/'
          )
            .crop('400x400')
            .url(),
          name: 'Luna Park',
          website: 'https://lunapark.com.au/',
        },
        retailerId: '8aa286f5-34bc-498a-8d21-49578b1351e6',
        sortIndex: 0,
        startsAt: null,
        terms: 'Coupon Codes can be redeemed up to 5 times in one day.',
        type: 'coupon',
        providerId: '216af113-8e9a-4d66-a9d2-d285ffe16594',
      },
    ])

    const retailerName = computed(() => retailer.value?.name ?? '')
    const logoBindings = computed(() => ({
      image: retailer.value?.logo ?? '',
      alt: retailer.value?.name ?? '',
    }))
    const backRoute = computed(() => {
      return {
        name: 'hlth-professional-network-feed',
        query: queryState.value,
      }
    })

    async function useFeed() {
      feed.value.processing = true
      const response = await feed.value.getDataOnce({
        index: feed.value.params.index,
        search: {
          hitsPerPage: 1,
          facetFilters: [`retailer_id:${props.id}`],
          distinct: false,
        },
      })

      retailer.value = response.hits?.[0]?.payload ?? null
      feed.value.processing = false
    }

    initServices().then(() => {
      loading.value = false
      useFeed()
    })

    return {
      loading,
      retailer,
      offers,
      feed,
      retailerName,
      logoBindings,
      filteredOffers,
      backRoute,
    }
  },
}
</script>

<template>
  <ew-details-layout
    :loading="loading"
    :ready="!loading"
    :title="retailerName"
    :back="backRoute"
    class="-mt-16 sm:mt-0"
    :placeholder-ratio="1"
  >
    <template #gallery>
      <retailer-logo v-if="retailer" v-bind="logoBindings" />
    </template>

    <template #summary>
      <div v-if="retailer">
        <offers :retailer="retailer" :offers="filteredOffers" class="mb-10" />
      </div>
    </template>

    <template #details>
      <div v-if="retailer" class="hidden sm:block">
        <base-link
          v-if="retailer.website"
          :href="retailer.website"
          class="block overflow-hidden truncate font-bold leading-8"
          look="underline"
        >
          {{ retailer.website.replace(/^https?:\/\/|\/$/g, '') }}
        </base-link>
      </div>
    </template>
  </ew-details-layout>
</template>
