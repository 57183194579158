<script>
import Vue, { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import DrawerBankForm from '/~/components/drawer/components/payment-methods/forms/drawer-bank-form.vue'
import DrawerCardForm from '/~/components/drawer/components/payment-methods/forms/drawer-card-form.vue'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import bottomSheet from '/~/core/bottom-sheet'

export default {
  name: 'profile-pay-from-add',
  components: {
    DrawerCardForm,
    DrawerBankForm,
    BaseTabs,
    BaseTab,
    BaseAsidePage,
    BaseLoader,
  },
  props: {
    title: {
      type: String,
      default: 'Add Payment Method',
    },
  },
  setup() {
    const router = useRouter()
    const {
      isMethodAvailable,
      creating,
      everyBankAccountPayable,
      fetchingList: fetching,
      configLoading,
    } = usePaymentMethods()
    const { isBankAccountsNavEnabled } = useProvider()

    const tab = ref('card')

    const loading = computed(() => {
      return configLoading.value || fetching.value
    })

    const showBankTab = computed(() => {
      return everyBankAccountPayable.value && isShowMethod(PaymentMethodType.bankAccount)
    })

    const showCardTab = computed(() => {
      return isShowMethod(PaymentMethodType.creditCard)
    })

    const hasAvailableMethods = computed(() => {
      return showBankTab.value || showCardTab.value
    })

    function isShowMethod(type) {
      return isMethodAvailable(type, router.currentRoute.params.source)
    }

    async function onAdded(paymentMethod) {
      const text =
        paymentMethod.type === PaymentMethodType.bankAccount
          ? 'Bank account successfully added'
          : 'Credit card successfully added'

      router.push({ hash: '#profile-pay-from' })

      if (paymentMethod.type === PaymentMethodType.bankAccount && isBankAccountsNavEnabled.value) {
        Vue.notify({
          text: 'Your bank account verification has been initiated and an amount between $0.01 and $2.99 will appear on your statement. Please allow up to 24 hours and come back once you have located this amount in order to verify your Bank Account.',
          type: 'success',
          duration: 10000,
        })
      } else {
        Vue.notify({
          text,
          type: 'success',
          duration: 3000,
        })
      }

      bottomSheet.show('method-verify', {
        to: 'menu-modal',
        props: {
          method: paymentMethod,
        },
      })
    }

    onMounted(() => {
      tab.value = showCardTab.value ? 'card' : 'bank'
    })

    return {
      creating,
      fetching,
      tab,
      loading,
      showBankTab,
      showCardTab,
      hasAvailableMethods,
      isShowMethod,
      onAdded,
    }
  },
}
</script>

<template>
  <base-aside-page :title="title" no-padding>
    <base-loader v-if="loading" class="py-[30px]" fullwidth />

    <base-tabs
      v-else-if="hasAvailableMethods"
      v-model="tab"
      :disabled="creating"
      class="h-full px-5"
    >
      <base-tab v-show="showCardTab" name="Credit/Debit Card" value="card">
        <drawer-card-form class="pt-2.5" @submit="onAdded" />
      </base-tab>

      <base-tab v-show="showBankTab" name="Bank Account" value="bank">
        <drawer-bank-form class="pt-2.5" @submit="onAdded" />
      </base-tab>
    </base-tabs>
    <div v-else class="p-[30px] text-center font-bold">
      No payment methods available
    </div>
  </base-aside-page>
</template>
