<script>
import CheckoutSummaryItemV1 from '/~/components/checkout/summary/checkout-summary-items.vue'
import CheckoutSummaryItem from './checkout-summary-item.v2.vue'

export default {
  name: 'checkout-summary-items-v2',
  components: {
    CheckoutSummaryItem,
  },
  extends: CheckoutSummaryItemV1,
}
</script>

<template>
  <div v-if="items.length > 0">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="w-full"
      @click="$emit('item', item)"
    >
      <checkout-summary-item
        v-if="item"
        :item="item"
        :is-confirmation="isConfirmation"
        :show-points-to-burn="showPointsToBurn"
      />
    </div>
  </div>
</template>
