<script>
import get from 'lodash-es/get'
import { computed, toRefs } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import DrawerActivityDetailsItem from '../drawer-activity-details-item.vue'

export default {
  name: 'drawer-activity-detail-cashback',
  components: {
    DrawerActivityDetailsItem,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { content } = toRefs(props)

    const transactionId = computed(() => {
      return (
        get(content.value, 'activity.transaction_number') ||
        get(content.value, 'id')
      )
    })

    const activityItems = computed(() => {
      let value = ''
      let state = 'warning'

      if (content.value.metadata && content.value.metadata.status === 'paid') {
        value = 'Paid'
        state = 'success'
      } else if (
        content.value.metadata &&
        content.value.metadata.status === 'canceled'
      ) {
        value = 'Canceled'
        state = 'error'
      } else if (
        content.value.metadata &&
        content.value.metadata.status === 'pending'
      ) {
        value = 'Pending'
      } else {
        value = 'Order Pending'
      }
      return [
        {
          title: 'Transaction No.',
          value: transactionId.value,
        },
        {
          title: 'Date',
          value: formatDate('daymonthyear', content.value.created_at),
        },
        {
          title: 'Status',
          value,
          state,
        },
      ]
    })

    return {
      activityItems,
      transactionId,
      formatDollar,
    }
  },
}
</script>

<template>
  <div>
    <drawer-activity-details-item :items="activityItems" />
    <div class="my-5 space-y-2.5">
      <div class="flex justify-between font-bold">
        <span>Total</span>
        <span>
          {{ formatDollar(content.total) }}
        </span>
      </div>
      <div class="flex justify-between">
        <span>Cashback</span>
        <span>
          {{ formatDollar(content.cashback) }}
        </span>
      </div>
    </div>
  </div>
</template>
