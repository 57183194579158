<script lang="ts">
import BaseButton from '/~/components/base/button/base-button'
import AudioBar from './audio-bar.vue'
import AudioDuration from './audio-duration.vue'

export default {
  name: 'rec-post-audio-entry',
  components: {
    AudioDuration,
    AudioBar,
    BaseButton,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    contentColor: {
      type: String,
      default: 'text-dark',
    },
    buttonColor: {
      type: String,
      default: 'text-primary',
    },
  },
  data() {
    return {
      isPlaying: false,
      audioElement: null,
      duration: 0,
      progress: 0,
    }
  },
  computed: {
    progressPercent() {
      return (this.progress / this.duration) * 100
    },
  },
  created() {
    this.audioSetup()
  },
  beforeDestroy() {
    this.pause()
  },
  methods: {
    onCurrentTimeChange(percentage: number) {
      this.audioElement.currentTime = (this.duration / 100) * percentage
    },
    audioSetup() {
      this.audioElement = new Audio(this.attachment.cdn)
      this.audioElement.addEventListener('durationchange', this.changeDuration)
      this.audioElement.addEventListener('ended', this.reset)
      this.audioElement.ontimeupdate = this.updateProgress
    },
    play() {
      this.audioElement.play()
      this.isPlaying = true
    },
    reset() {
      this.audioElement.load()
      this.isPlaying = false
    },
    pause() {
      this.audioElement.pause()
      this.isPlaying = false
    },
    changeDuration() {
      this.duration = this.audioElement.duration
    },
    updateProgress() {
      this.progress = this.audioElement.currentTime
    },
  },
}
</script>

<template>
  <div :class="['flex select-none items-center', contentColor]">
    <div class="flex w-11 items-center justify-center">
      <base-button
        :class="['text-2xl', buttonColor]"
        :icon="isPlaying ? 'rec/pause' : 'rec/play'"
        @click="isPlaying ? pause() : play()"
      />
    </div>
    <div class="w-full flex-auto truncate px-2.5">
      <p class="mb-[5px] truncate text-sm font-bold">
        {{ attachment.name }}
      </p>
      <audio-bar
        :progress="progress"
        :duration="duration"
        @set-progress="onCurrentTimeChange"
      />
      <audio-duration :progress="progress" :duration="duration" />
    </div>
    <div class="flex w-11 items-center justify-center">
      <a :href="attachment.cdn" class="block cursor-pointer">
        <base-button :class="['text-2xl', buttonColor]" icon="rec/download" />
      </a>
    </div>
  </div>
</template>
