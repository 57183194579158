<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import GroupSelector from '/~rec/components/group/group-selector.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'

export default {
  name: 'rec-groups-manager-modal',
  components: {
    GroupSelector,
    BaseInput,
    BaseMdl,
    HeaderMobile,
  },
  setup() {
    return {
      ui,
    }
  },
  data() {
    return {
      searchValue: '',
    }
  },
  methods: {
    onSave() {
      modal.hide()
    },
    modalHide() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl
    v-bind="$attrs"
    width="sm"
    height="lg"
    fullscreen="mobile"
    transition="slide-bottom"
    :padding="false"
  >
    <template #header>
      <header-mobile v-if="ui.mobile" v-bind="$attrs" @back="modal.hide()">
        <div class="px-5 pb-5">
          <base-input
            v-model="searchValue"
            look="recroom"
            icon="plain/search"
            placeholder="Search Group"
            icon-plain
            nolabel
            clearable
          />
        </div>
      </header-mobile>
    </template>
    <div class="flex h-full flex-col">
      <div v-if="!ui.mobile" class="mb-5 px-[30px]">
        <base-input
          v-model="searchValue"
          class="mb-0 pt-0"
          look="recroom"
          icon="plain/search"
          placeholder="Search Group"
          icon-plain
          clearable
        />
      </div>

      <group-selector
        v-bind="$attrs"
        :search-value="searchValue"
        class="flex-1 overflow-auto"
        v-on="$listeners"
        @save="onSave"
      />
    </div>
  </base-mdl>
</template>
