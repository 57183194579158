<script>
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import Popup from '/~/components/base/select/popup'

const loadEmojis = () => import('./emojis')

let emojis = null

export default {
  name: 'base-emojis',
  components: {
    Popup,
    BaseInput,
    BaseIcon,
    BaseLoader,
  },
  setup() {
    return {
      ui,
    }
  },
  data() {
    return {
      activeTab: 'frequently',
      loading: false,
      visible: false,
      tabs: null,
      search: '',
      popupRootElem: null,
    }
  },
  computed: {
    emojis() {
      if (!this.tabs) return null
      const tabEmojis = emojis[this.activeTab]

      if (this.search) {
        const search = this.search.trim().toLowerCase()
        const searchEmojis = {}

        for (const key of Object.keys(tabEmojis)) {
          if (key.includes(search)) {
            searchEmojis[key] = tabEmojis[key]
          }
        }

        return searchEmojis
      }

      return tabEmojis
    },
  },
  watch: {
    visible(value, oldValue) {
      if (value && !oldValue) {
        this.popupRootElem = ui.mobile ? this.$parent.$el : null
        this.initEmojis()
      }
    },
  },
  methods: {
    async initEmojis() {
      try {
        this.loading = true

        if (!emojis)
          emojis = await loadEmojis().then((module) => module.default)
        this.tabs = Object.keys(emojis).map((name) => ({
          icon: this.getIconTab(name),
          name,
        }))
      } catch (error) {
        console.error('base-emojis', error)
      } finally {
        this.loading = false
      }
    },
    getIconTab(tab) {
      switch (tab) {
        case 'frequently':
          return 'rec/clock-thin'
        case 'people':
          return 'rec/face'
        case 'nature':
          return 'rec/leaf'
        case 'objects':
          return 'rec/bulb'
        case 'places':
          return 'rec/plane'
        case 'symbols':
          return 'rec/heart-thin'
      }
    },
    onEmojiSelect(emoji) {
      this.$emit('select', emoji)
      this.close()
    },
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
  },
}
</script>

<template>
  <div class="relative">
    <popup
      :visible="visible"
      :fullwidth="ui.mobile"
      :rel-element="popupRootElem"
      @click="close"
    >
      <div
        class="relative flex h-96 w-full flex-col overflow-hidden rounded-md bg-white shadow-sm sm:w-80"
      >
        <base-loader v-if="loading" class="absolute inset-0" />

        <!-- Tabs -->
        <div
          class="mb-2.5 flex border-separate justify-between border-b shadow-sm"
        >
          <div
            v-for="(tab, idx) in tabs"
            :key="idx"
            :class="[
              'flex flex-1 items-center justify-center border-b-2 py-2.5',
              activeTab === tab.name
                ? 'border-primary'
                : 'cursor-pointer border-transparent',
            ]"
            @click="activeTab = tab.name"
          >
            <base-icon :svg="tab.icon" size="md" class="text-lg" />
          </div>
        </div>

        <!-- Search -->
        <div class="px-2.5">
          <base-input
            v-model="search"
            placeholder="Search for an emoji..."
            clearable
            nolabel
          />
        </div>

        <!-- Emojis -->
        <div v-if="emojis" class="mt-2.5 flex flex-wrap overflow-auto px-2.5">
          <span
            v-for="(emoji, emojiName) of emojis"
            :key="emojiName"
            class="cursor-pointer rounded-md py-1 px-0.5 text-center text-xl hover:bg-dark"
            :class="[ui.isIE && 'text-lg']"
            :style="ui.isIE && { fontFamily: 'Segoe UI Emoji' }"
            :title="emojiName"
            @click="onEmojiSelect(emoji)"
          >
            {{ emoji }}
          </span>
        </div>
        <div
          v-else
          class="flex flex-1 items-center justify-center text-disabled"
        >
          Not found
        </div>
      </div>
    </popup>
  </div>
</template>
