<script>
import ui from '/~/core/ui'
import BusinessMobile from './business.mobile.vue'
import BusinessDesktop from './business.desk.vue'

export default {
  name: 'fly-business',
  components: {
    BusinessMobile,
    BusinessDesktop,
  },
  setup() {
    return {
      ui,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    addPartner() {
      const type = this.$route.query.type

      this.$router.push({ name: 'fly-partner-new', query: { type } })
    },
  },
}
</script>

<template>
  <business-desktop v-if="!ui.mobile" @add="addPartner" />
  <business-mobile v-else @add="addPartner" />
</template>
