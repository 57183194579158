import { computed } from 'vue'
import {
  Metafield,
  MetafieldsSchema,
} from '/~/components/base/metafield/base-metafield.vue'
import { useAnalytics } from '/~/composables/analytics'
import {
  BankAccountSchemaField,
  BankAccountSchema,
  useLocalization,
} from '/~/composables/localization'
import { usePayees } from '/~/composables/payees/use-payees'

export function usePayeeBankAccountForm() {
  const { onFormFieldChange } = useAnalytics()
  const { billPayModuleConfig, bankAccountWhitelistEnabled } = usePayees()
  const { payeeBankAccount } = useLocalization()

  const {
    initiateValues,
    form,
    bankAccountSchema: rawBankAccountSchema,
    bankAccountSchemaEditName: rawBankAccountSchemaEditName,
    showFormDescription,
  } = payeeBankAccount

  const bankAccountPayeeMetadata = computed(() => {
    const payeeMetadata =
      billPayModuleConfig.value?.bankAccount?.payeeMetadata ?? []
    const payeeMetadataList = payeeMetadata.map((field: Metafield) => {
      return {
        ...field,
        disabled: !form.value['abnName'],
      }
    })

    return payeeMetadataList
  })

  function mapSchema(schema: BankAccountSchema): MetafieldsSchema {
    function mapFields({ analyticsLabel, ...field }: BankAccountSchemaField) {
      if (analyticsLabel) {
        field.onInput = () => onFormFieldChange(analyticsLabel)
      }

      return field
    }

    const fields = schema.fields.map(mapFields)
    const types = Object.fromEntries(
      Object.keys(schema.types).map((key) => [
        key,
        {
          fields: schema.types[key].fields.map(mapFields),
        },
      ])
    )

    if (bankAccountWhitelistEnabled.value) {
      types.payeeMetadata = { fields: bankAccountPayeeMetadata.value }
      types.metadata = { fields: bankAccountPayeeMetadata.value }
    }

    return {
      fields,
      types,
    }
  }

  const bankAccountSchema = computed<MetafieldsSchema>(() =>
    mapSchema(rawBankAccountSchema.value)
  )
  const bankAccountSchemaEditName = computed<MetafieldsSchema>(() =>
    mapSchema(rawBankAccountSchemaEditName)
  )

  return {
    initiateValues,
    form,
    bankAccountSchema,
    bankAccountSchemaEditName,
    showFormDescription,
  }
}
