import { reactive } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { EventsProcessor } from '/~rec/core/events-processor'
import { MapWorker } from '/~/core/map'

const state = reactive({
  feed: null,
  selectedDate: formatDate('daymonthyearnumeric', Date.now()),
  map: new MapWorker(),
})

function setFeed(feedId) {
  state.feed = new EventsProcessor({ feedId })
}

function resetEvents() {
  state.selectedDate = formatDate('daymonthyearnumeric', Date.now())
}

async function findAddress(queryString) {
  await state.map.sync()
  return state.map
    .getPlacePredictions(queryString, 'address')
    .then((result) => {
      return result.slice(0, 3).map((item) => {
        return {
          label: item.description,
        }
      })
    })
    .catch(() => [])
}

export const useEvents = () => ({
  events: state,
  resetEvents,
  findAddress,
  setFeed,
})
