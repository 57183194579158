export default {
  pay: () => import('./make-payment/pay.mdl.vue'),
  'cvv-code': () => import('/~/modals/payment-methods/card/cvv-code.mdl.vue'),
  'points-transfer-terms': () => import('./fly/points-transfer-terms.mdl.vue'),
  'points-transfer-error': () => import('./fly/points-transfer-error.mdl.vue'),
  'init-tier-options-modal': () => import('./tier/init-tier-options.mdl.vue'),
  'remove-account': () => import('./remove-account/remove-account.mdl.vue'),
  'confirm-pay-from-bank': () =>
    import('./payments/confirm-pay-from-bank.mdl.vue'),
  'cancel-existing-direct-debits-confirmation': () =>
    import(
      './direct-debits/cancel-existing-direct-debits-confirmation.mdl.vue'
    ),
  'auto-pay': () => import('./auto-pay/auto-pay.mdl.vue'),
  'pay-now': () => import('./auto-pay/pay-now.mdl.vue'),
  'enable-direct-debit': () =>
    import('./enable-direct-debit/enable-direct-debit.mdl.vue'),
  'edit-account': () => import('./edit-account/edit-account.mdl.vue'),
  'remove-airpartner-account': () =>
    import('./points-programs/remove-airpartner-account.mdl.vue'),
}
