import HeaderClosable from './closable/header-closable.vue'
import HeaderDefault from './default/header-default.vue'
import HeaderMobileBack from './mobile-back/header-mobile-back.vue'
import HeaderMobileImage from './mobile-image/header-mobile-image.vue'
import HeaderMobilePlain from './mobile-plain/header-mobile-plain.vue'

export default {
  name: 'BaseHeader',
  functional: true,
  render(h, ctx) {
    const target = {
      'mobile-plain': HeaderMobilePlain,
      'mobile-image': HeaderMobileImage,
      'mobile-back': HeaderMobileBack,
      closable: HeaderClosable,
    }

    return h(
      target[ctx.props.type] || HeaderDefault,
      {
        ...ctx.data,
        scopedSlots: ctx.scopedSlots,
      },
      ctx.children
    )
  },
}
