<script>
import TermsConditions from '/~/views/terms/terms-conditions.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'

export default {
  name: 'drawer-toc',
  components: {
    TermsConditions,
    BaseAsidePage,
  },
}
</script>

<template>
  <base-aside-page title="Terms & Conditions">
    <terms-conditions class="-m-5 bg-eonx-neutral-50 p-5" />
  </base-aside-page>
</template>
