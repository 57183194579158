import { RouteConfig } from 'vue-router'

export default [
  {
    path: '/health',
    name: 'health',
    component: () => import('./app.vue'),
    redirect: { name: 'hlth-my-health' },
    meta: {
      title: 'My Health and Wellness',
    },
    children: [
      {
        path: 'my-health',
        name: 'hlth-my-health',
        props: true,
        component: () => import('./views/my-health/my-health.vue'),
      },
      {
        path: 'mind-and-body',
        name: 'hlth-mind-and-body-type',
        meta: {
          page: 'hlth-mind-and-body-type',
        },
        component: () => import('./views/mind-and-body/mind-and-body-type.vue'),
      },
      {
        path: 'health-ebits/:type/:id',
        name: 'hlth-health-ebits-detail',
        meta: {
          theme: 'white',
          hideMenu: true,
        },
        props: true,
        component: () => import('./views/health-ebits/health-ebits-detail.vue'),
      },
      {
        path: 'health-ebits',
        name: 'hlth-health-ebits-feed',
        meta: {
          page: 'hlth-health-ebits-feed',
        },
        component: () => import('./views/health-ebits/health-ebits-feed.vue'),
      },
      {
        path: 'lifestyle-library',
        name: 'hlth-lifestyle-library-feed',
        meta: {
          page: 'hlth-lifestyle-library-feed',
        },
        component: () =>
          import('./views/lifestyle-library/lifestyle-library-feed.vue'),
      },
      {
        path: 'lifestyle-library/:type/:id',
        name: 'hlth-lifestyle-library-detail',
        meta: {
          theme: 'white',
          hideMenu: true,
        },
        props: true,
        component: () =>
          import('./views/lifestyle-library/lifestyle-library-detail.vue'),
      },
      {
        path: 'professional-network',
        name: 'hlth-professional-network-feed',
        meta: {
          page: 'hlth-professional-network-feed',
        },
        component: () =>
          import('./views/professional-network/professional-network-feed.vue'),
      },
      {
        path: 'professional-network/:type/:id',
        name: 'hlth-professional-network-detail',
        meta: {
          theme: 'white',
          hideMenu: true,
        },
        props: true,
        component: () =>
          import(
            './views/professional-network/professional-network-detail.vue'
          ),
      },
    ],
  },
] as RouteConfig[]
