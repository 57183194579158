import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomEarn } from '/~rec/core/earn'
import isNil from 'lodash-es/isNil'
import { formatNumber } from '/~/utils/format/numeric'
import { pluralize } from '/~/utils/format/string'
import capitalize from 'lodash-es/capitalize'
import { useRecProfile } from '/~rec/composables/profile'

const { currency } = useRecProfile()

export class RecroomGlobalEarnStatusLimitsProcessor extends EntityProcessor {
  constructor(params = {}) {
    super({
      entity: 'gamification/global-earn-status-limits',
      mapping: (record) => new RecroomEarn(record),
      ...params,
    })
  }

  getPluralizedCurrencyValue(val, capital) {
    const curr = pluralize(val, currency.value.slice(0, -1))

    return `${val} ${capital ? capitalize(curr) : curr}`
  }

  getMaxEarn() {
    let total = 0

    this.hits.forEach((action) => {
      if (isNil(action.dailyLimit)) return
      total += action.dailyLimit
    })
    return `${formatNumber(total)} ${currency.value}`
  }

  hasEarn() {
    return this.hits && this.hits.some((action) => action.dailyLimit > 0)
  }

  disableEarn() {
    return (
      this.hits &&
      this.hits.some((action) => action.earnedToday === action.dailyLimit)
    )
  }

  get Hi5Send() {
    const action = this.hits?.find((hit) => hit.action === 'high_five_create')
    const format = this.getPluralizedCurrencyValue

    return {
      title: `Send a Hi-5 to earn ${format(action?.earnPerAction)}`,
      message: `Each time you send a Hi-5 you will earn ${format(
        action?.earnPerAction,
        true
      )}. You can earn up to ${format(
        action?.dailyLimit,
        true
      )} per day by sending Hi-5s.`,
      max: action?.dailyLimit || 0,
      today: action?.earnedToday || 0,
      fraction: action?.statusFraction || '0/0',
    }
  }

  get Hi5Receive() {
    const action = this.hits?.find((hit) => hit.action === 'high_five_receive')
    const format = this.getPluralizedCurrencyValue

    return {
      title: `Receive a Hi-5 to earn ${format(action?.earnPerAction)}`,
      message: `Each time you receive a Hi-5 you will earn ${format(
        action?.earnPerAction,
        true
      )}. You can earn up to ${format(
        action?.dailyLimit,
        true
      )} per day by receiveng Hi-5s.`,
      max: action?.dailyLimit || 0,
      today: action?.earnedToday || 0,
      fraction: action?.statusFraction || '0/0',
    }
  }

  get awardNominate() {
    const action = this.hits?.find(
      (hit) => hit.action === 'award_nomination_create'
    )
    const format = this.getPluralizedCurrencyValue

    return {
      title: `Nominate someone to earn ${format(action?.earnPerAction)}`,
      message: `Each time you make a nomination for any Award you will earn ${format(
        action?.earnPerAction,
        true
      )}. You can earn up to ${format(
        action?.dailyLimit,
        true
      )} per day by making Nominations.`,
      max: action?.dailyLimit || 0,
      today: action?.earnedToday || 0,
      fraction: action?.statusFraction || '0/0',
    }
  }

  get awardGetNominated() {
    const action = this.hits?.find(
      (hit) => hit.action === 'award_nomination_receive'
    )
    const format = this.getPluralizedCurrencyValue

    return {
      title: `Be nominated to earn ${format(action?.earnPerAction)}`,
      message: `Each time you receive a nomination for any Award you will earn ${format(
        action?.earnPerAction,
        true
      )}. You can earn up to ${format(
        action?.dailyLimit,
        true
      )} per day by being Nominated.`,
      max: action?.dailyLimit || 0,
      today: action?.earnedToday || 0,
      fraction: action?.statusFraction || '0/0',
    }
  }
}
