<script>
import modal from '/~/core/mdl'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'base-header-mobile-image',
  components: {
    BaseIcon,
    BaseImage,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '',
    },
    curved: {
      type: [Boolean, Object],
      default: true,
    },
  },
  data() {
    return {
      isFixed: false,
    }
  },
  computed: {
    imageBindings() {
      const normalLink = this.cdn(this.image).scaleCrop('800x400/smart').url()
      const retinaLink = this.cdn(this.image).scaleCrop('1600x800/smart').url()

      return {
        src: normalLink,
        srcset: `${normalLink} 1x, ${retinaLink} 2x`,
      }
    },
    curveFill() {
      return this.curved.fill || 'var(--color-bg-dark)'
    },
  },
  methods: {
    onVisibilityChanged(visible) {
      this.isFixed = !visible
    },
    onBack() {
      modal.hide()
      this.$emit('back')
    },
  },
}
</script>

<template>
  <div>
    <div>
      <div
        v-observe-visibility="{
          callback: onVisibilityChanged,
          intersection: {
            threshold: 0.24,
          },
        }"
        class="relative h-56"
      >
        <base-image
          v-bind="imageBindings"
          class="h-full"
          :ratio="false"
          :rounded="false"
        />
        <svg
          v-if="curved"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="25"
          viewBox="0 0 600 40"
          preserveAspectRatio="none"
          class="absolute bottom-0 left-0"
        >
          <path
            :fill="curveFill"
            d="M0 40h600v-40c-100 26.7-200 40-300 40S100 28.1 0 1.4v40z"
          />
        </svg>
      </div>
      <div
        class="fixed top-0 left-0 z-40 flex h-16 w-16 items-center"
        @click="onBack"
      >
        <base-icon
          svg="rec/chevron-left"
          size="md"
          class="inset-0 m-auto block h-full text-xl"
          :class="[isFixed && !color ? 'text-default' : 'text-white']"
        />
      </div>
      <slot />
    </div>

    <transition name="fade">
      <div
        v-if="isFixed"
        class="fixed top-0 left-0 z-30 flex h-16 w-full items-center pl-16 pr-5 shadow"
        :class="[color === 'primary' ? 'bg-primary text-white' : 'bg-dark']"
      >
        <slot name="fixed" />
      </div>
    </transition>
  </div>
</template>
