<script>
import DefaultGiftCardsCatalog from '/~/extensions/giftcards/components/gc-catalog.vue'
import GcTile from './gc-tile.vue'

export default {
  name: 'giftcards-catalog',
  components: {
    GcTile,
  },
  extends: DefaultGiftCardsCatalog,
}
</script>

<template>
  <ew-catalog-processor
    tile-class="hover:-translate-y-[5px]"
    :processor="algolia"
    :columns="{
      xxs: 2,
      sm: 3,
      lg: 4,
    }"
  >
    <template #empty>
      <p>No Result found</p>
      <p>Please try another category</p>
    </template>

    <template #tile="{ tile }">
      <gc-tile :tile="tile" />
    </template>
  </ew-catalog-processor>
</template>
