<script>
import { useProvider } from '/~/composables/provider'
import LayoutDefault from '/~/layouts/auth/default.vue'
import LayoutSymbion from '/~/layouts/auth/symbion.vue'

export default {
  name: 'auth-layout',
  functional: true,
  render(h, ctx) {
    const { isSymbioneliteProvider } = useProvider()

    let component

    if (isSymbioneliteProvider.value) {
      component = LayoutSymbion
    } else {
      component = LayoutDefault
    }

    return h(component, ctx)
  },
}
</script>
