import { useUser } from '/~/composables/user'

interface BillPaymentsMembershipRaw {
  id: string
  name: string
  label: string
  description: string
  rate: number
  feePercentage: number
  feeLabel: string
}

export class BillPaymentsMembership {
  raw: BillPaymentsMembershipRaw
  id: string
  name: string
  label: string
  description: string
  pointsEarnRate: number
  feePercentage: number
  feeLabel: string

  constructor(raw: BillPaymentsMembershipRaw) {
    this.raw = raw || {}

    this.id = this.raw.id ?? ''
    this.name = this.raw.name ?? ''
    this.label = this.raw.label ?? ''
    this.description = this.raw.description ?? ''
    this.pointsEarnRate = this.raw.rate ?? 1
    this.feePercentage = this.raw.feePercentage ?? 0
    this.feeLabel = this.raw.feeLabel ?? ''
  }

  get currentMembership() {
    const { user } = useUser()

    return user.value.membershipId
  }

  get isActive() {
    return this.id === this.currentMembership
  }
}
