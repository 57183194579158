<script>
import { ref, computed } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import ConfirmationPayWithItem from '/~/views/checkout.v2/views/checkout/components/confirmation/confirmation-pay-with-item.vue'

export default {
  name: 'confirmation-pay-with',
  components: {
    BaseIcon,
    ConfirmationPayWithItem,
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isSymbioneliteProvider } = useProvider()

    const paymentMethods = computed(() => {
      return (
        (props.order.status === 'scheduled'
          ? props.order.scheduledPaymentMethods
          : props.order.paymentMethods) ?? []
      )
    })

    const bankAccountNotices = ref(
      isSymbioneliteProvider.value
        ? [
            'Payments from bank accounts may take up to 3 business days to complete.',
            'Please ensure you have available funds to avoid any bank dishonour fees.',
            'Your points will be allocated once this payment has successfully completed.',
          ]
        : [
            'Payments from bank accounts may take up to 3 business days to clear.',
            'Please ensure you have available funds to avoid any bank dishonour fees.',
            'Any points earned will be allocated once this payment has cleared.',
          ]
    )

    const showBankAccountNotices = computed(() => {
      return paymentMethods.value.findLast((method) => {
        return method.type === PaymentMethodType.bankAccount
      })?.id
    })

    return {
      bankAccountNotices,
      showBankAccountNotices,
    }
  },
}
</script>

<template>
  <div class="relative">
    <confirmation-pay-with-item
      v-for="method in order.paymentMethods"
      :key="method.id"
      :item="method"
      :order="order"
    />
    <div
      v-if="showBankAccountNotices"
      class="mt-5 flex items-center bg-eonx-neutral-200 py-2.5"
    >
      <div class="flex w-16 shrink-0">
        <base-icon
          svg="v2/custom/alert"
          :size="32"
          class="mx-auto shrink-0 text-eonx-neutral-800"
        />
      </div>
      <ul
        class="list-disc space-y-[5px] pl-5 pr-4 text-sm text-eonx-neutral-600"
      >
        <li v-for="(notice, index) in bankAccountNotices" :key="index">
          {{ notice }}
        </li>
      </ul>
    </div>
  </div>
</template>
