<script>
import DefaultEpurchaseTile from '/~/components/drawer/components/tiles/drawer-e-purchases-tile.vue'

export default {
  name: 'epurchases-tile',
  extends: DefaultEpurchaseTile,
}
</script>

<template>
  <div
    class="group relative flex flex-col no-underline"
    :class="{
      'cursor-pointer': clickable,
    }"
  >
    <div
      class="rounded-lg shadow-xs transition duration-100 group-hover:shadow-lg"
    >
      <base-image
        :src="imageSrc"
        :alt="card.retailerName"
        :active="false"
        class="rounded-lg transition duration-100 group-hover:shadow-lg"
        @click="onClick"
      />
      <div
        v-if="card.pending"
        class="absolute inset-0 flex h-full w-full cursor-default items-center justify-center bg-overlay p-2.5 text-center opacity-0 transition duration-100 hover:opacity-100"
        @click.stop
      >
        <span class="p-[5px] text-sm text-white">
          Pending Delivery
          <br />
          May take up to 72 Hours to Process.
        </span>
      </div>
    </div>

    <div
      v-if="!card.viewed && !card.pending"
      class="absolute top-0 right-0 -mt-2.5 -mr-2.5 rounded-xl bg-red-700 py-0.5 px-2.5 text-center text-xs font-bold uppercase text-white shadow"
    >
      new
    </div>
    <div class="mt-2.5 text-sm font-medium text-eonx-neutral-600">
      {{ title }}
    </div>
    <div class="font-bold leading-6">
      {{ formatDollar(balance) }}
    </div>
    <div v-if="extended" class="mt-0.5 text-center">
      <div>
        {{ title }}
      </div>
      <div class="text-sm font-bold text-eonx-neutral-600">
        {{ `Purchased ${formatDate('daymonthyear', card.purchasedAt)}` }}
      </div>
    </div>
    <div v-if="archived" class="mt-2.5">
      <base-button
        :disabled="restoring"
        :loading="restoring"
        full-width
        size="md"
        @click="restoreCard"
      >
        Restore
      </base-button>
    </div>
  </div>
</template>
