<script setup lang="ts">
import { computed } from 'vue'
import { formatBsb, formatDollar } from '/~/utils/format'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { BatchOrderTransaction } from '/~/composables/batch-order'

const props = defineProps<{
  payee: BatchOrderTransaction
}>()

const status = computed(() => {
  if (
    ['new', 'payees_syncing', 'processing', 'file_processing'].includes(
      props.payee.status
    )
  ) {
    return {
      color: 'text-orange-700',
      icon: 'heroicons/mini/clock',
    }
  }
  if (
    ['completed', 'payees_synced', 'file_processed'].includes(
      props.payee.status
    )
  ) {
    return {
      color: 'text-emerald-700',
      icon: 'heroicons/solid/check-circle',
    }
  }
  return {
    color: 'text-red-700',
    icon: 'heroicons/solid/exclamation-circle',
  }
})
</script>

<template>
  <div class="flex items-center px-2">
    <div class="w-56 px-2">
      <div>{{ payee.accountName }}</div>
      <div>{{ formatBsb(payee.bsb) }} {{ payee.accountNumber }}</div>
    </div>
    <div class="px-2">
      <div>{{ payee.reference }}</div>
    </div>
    <div class="ml-auto mr-5 w-24 flex-none px-2">
      {{ formatDollar(payee.amount) }}
    </div>
  </div>
</template>
