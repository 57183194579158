<script setup>
import { ref } from 'vue'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import SelectField from '/~/components/mobile-filter/select-field.vue'

const {
  selectedCategory,
  selectedSorting,
  searchQuery,
  sortValues,
  defaultSortValue,
  categories,
  isFiltersSelected,
  resetFilters,
  searchGroup,
} = useOnlineOffers()

const localSearchQuery = ref(searchQuery.value)
const localSorting = ref(selectedSorting.value)
const localCategory = ref(selectedCategory.value)

function resetLocalFilters() {
  localSearchQuery.value = searchQuery.value
  localSorting.value = selectedSorting.value
  localCategory.value = selectedCategory.value
}

function applyFilters() {
  searchQuery.value = localSearchQuery.value
  selectedSorting.value = localSorting.value
  selectedCategory.value = localCategory.value
}

function clearFilters() {
  resetFilters()
  resetLocalFilters()
}
</script>

<template>
  <ew-filters
    title="Filters"
    :is-filters-selected="isFiltersSelected"
    @opened="resetLocalFilters"
    @clear="clearFilters"
    @close="resetLocalFilters"
    @apply="applyFilters"
  >
    <div class="space-y-4 py-4">
      <div class="mb-2.5 w-full sm:mb-0">
        <base-input
          v-model="localSearchQuery"
          :placeholder="`Search ${searchGroup}`"
          look="rounded"
          icon-plain
          nolabel
          clearable
        >
          <template #icon>
            <div class="ml-[15px] mr-2.5 flex items-center justify-center">
              <base-icon
                svg="plain/search-v2"
                class="text-eonx-neutral-600"
                :size="19"
              />
            </div>
          </template>
        </base-input>
      </div>
      <select-field
        v-model="localCategory"
        title="Categories"
        :options="categories"
      />
      <select-field
        v-model="localSorting"
        title="Sorting by"
        :options="sortValues"
        :empty-option="defaultSortValue"
      />
    </div>
  </ew-filters>
</template>
