<script>
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'widget-bupa-split-banner',
  components: {
    BaseAction,
    BaseIcon,
  },
  props: {
    bannerButton: {
      type: Object,
      default: () => ({}),
    },
    imageLeftSide: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    widgetTitle: {
      type: Object,
      default: () => ({}),
    },
    enableWidgetTitle: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    buttonTextColor: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <li class="swiper-slide">
    <article>
      <div
        class="sm:flex sm:min-h-[344px] sm:overflow-hidden sm:rounded sm:shadow"
        :class="{ 'flex-row-reverse': !imageLeftSide }"
      >
        <img
          class="w-full object-cover sm:w-1/2"
          :src="
            cdn(image.desktop)
              .preview('1176x714')
              .format('auto')
              .quality('smart')
              .url()
          "
          :alt="image.alt"
        />
        <div
          class="flex flex-col justify-start p-5 sm:min-h-[344px] sm:w-1/2 sm:pt-12 sm:pb-11 sm:pl-14"
          :style="{
            backgroundColor,
            textAlign: content.contentAlignmentX,
          }"
        >
          <div
            :style="{ color: titleColor }"
            v-html="
              content.title.replace(
                /<\s*([a-zA-Z0-9]+)(.*?)>/,
                `<$1 class='text-3xl font-semibold sm:leading-12 sm:pt-14 sm:text-4xl'>`
              )
            "
          />
          <div
            class="mt-[15px] text-xl leading-8"
            :style="{ color: textColor }"
            v-html="content.text"
          />
          <div v-if="bannerButton.enabled" class="mt-auto">
            <base-action
              v-bind="bannerButton"
              class="mt-[30px] inline-block hover:opacity-60"
              :label="image.alt"
              origin="banner"
              appearance="link"
            >
              <span
                class="button flex h-11 w-full items-center justify-center rounded p-6 font-bold xs:w-auto"
                :style="{
                  border: `solid 2px ${buttonTextColor}`,
                  color: buttonTextColor,
                }"
              >
                {{ bannerButton.label }}
                <base-icon class="ml-5" svg="plain/chevron-right" size="sm" />
              </span>
            </base-action>
          </div>
        </div>
      </div>
    </article>
  </li>
</template>
