import { onMounted, onUnmounted } from 'vue'
import { useCms } from '/~/composables/cms/use-cms'
import exchange from '/~/configurator/exchange'

const CONFIGURATOR_EVENTS = {
  'ewallet-widget-selected': 'onWidgetSelected',
}

export function useCmsEwallet() {
  const { cmsEwallet, cmsEwalletSettings, cmsEwalletWidgets } = useCms()

  const EWALLET_HANDLERS = {
    onWidgetSelected(payload) {
      const sections = document.querySelector(
        '#ewallet-widgets-builder'
      )?.children

      if (!sections) {
        return
      }

      const targetSection = sections[payload.widgetIndex]

      if (targetSection) {
        targetSection.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    },
  }

  onMounted(() => {
    for (const event in CONFIGURATOR_EVENTS) {
      const handlerName = CONFIGURATOR_EVENTS[event]

      exchange.addHandler(event, (payload) => {
        const handler = EWALLET_HANDLERS[handlerName]

        if (handler instanceof Function) {
          handler.call(document, payload)
        }
      })
    }
  })

  onUnmounted(() => {
    for (const event in CONFIGURATOR_EVENTS) {
      exchange.removeHandler(event)
    }
  })

  return {
    cmsEwallet,
    cmsEwalletSettings,
    cmsEwalletWidgets,
  }
}
