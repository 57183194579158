<script>
import RecBaseState from '/~rec/components/state/base-state.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'rec-directory-members',
  components: {
    BaseAvatar,
    BaseIcon,
    RecBaseState,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { groups, allMembers } = useGroups()

    return { groups, allMembers }
  },
  data() {
    return {
      membersList: [],
    }
  },
  computed: {
    isEmpty() {
      return !this.membersList.length
    },
  },
  watch: {
    search() {
      this.findMembers()
    },
  },
  mounted() {
    this.findMembers()
  },
  methods: {
    findMembers() {
      this.membersList = this.allMembers.filter((member) =>
        member.fullName.toLowerCase().includes(this.search.toLowerCase())
      )
    },
  },
}
</script>

<template>
  <div
    class="flex h-full flex-col space-y-2.5 overflow-y-auto bg-zinc-50 px-6 pb-12 md:bg-white"
  >
    <rec-base-state
      v-if="isEmpty"
      wide
      image="recroom/state/directory-empty-state.svg"
      title="Can’t find this person"
      subtitle="Try searching using other details"
      class="my-auto"
    />
    <template v-else>
      <div
        v-for="member in membersList"
        :key="member.id"
        class="flex cursor-pointer items-center rounded-xl bg-white p-[15px] hover:bg-slate-200 sm:bg-eonx-neutral-50"
        @click="$emit('select', member)"
      >
        <base-avatar
          :src="member.avatarUrl"
          :abbr="member.initials"
          size="xl"
          class="mr-2.5"
        />
        <div class="flex flex-col truncate">
          <span class="truncate font-bold text-eonx-neutral-800">
            {{ member.fullName }}
          </span>
          <span
            class="truncate text-sm font-semibold leading-5 text-eonx-neutral-600"
          >
            {{ member.email }}
          </span>
        </div>

        <div class="ml-auto text-primary">
          <base-icon svg="plain/chevronright" size="sm" />
        </div>
      </div>
    </template>
  </div>
</template>
