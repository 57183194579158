<script>
import DrawerToc from '/~/templates/bill-payments/components/drawer/views/settings/drawer-toc.vue'

export default {
  name: 'profile-toc',
  components: {
    DrawerToc,
  },
  props: {
    id: {
      type: String,
    },
  },
}
</script>

<template>
  <drawer-toc :id="id" />
</template>
