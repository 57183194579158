<script>
import DetailsView from '/~/extensions/health/components/details/details-view-service/details-view-service.vue'

export default {
  name: 'health-professional-net-work-detail',
  components: {
    DetailsView,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
}
</script>

<template>
  <details-view :id="id" />
</template>
