<script>
import isEqual from 'lodash-es/isEqual'
import ui from '/~/core/ui'
import { useDining } from '/~/extensions/dining/composables'
import BaseButton from '/~/components/base/button/base-button'
import BaseMap from '/~/components/base/map/base-map.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { useEditablePage } from '/~/composables/cms'
import LayoutMap from '/~/layouts/map/layout-map.vue'
import DiningFilter from './components/dining-filter.vue'
import DiningTile from './components/dining-tile.vue'

export default {
  name: 'dining-catalog',
  components: {
    EwCatalogProcessor,
    DiningFilter,
    DiningTile,
    LayoutMap,
    BaseMap,
    BaseButton,
  },
  setup() {
    const {
      selectedFeeds,
      routeQuery,
      moduleName,
      mapState,
      algolia,
      label,
      resetFilters,
      getLocations,
    } = useDining()
    const { page } = useEditablePage()

    return {
      page,
      selectedFeeds,
      routeQuery,
      moduleName,
      mapState,
      algolia,
      label,
      resetFilters,
      getLocations,
      ui,
    }
  },
  computed: {
    markers() {
      if (!this.mapState.isVisible) return []

      return this.algolia.hits.map((hit) => {
        return {
          id: hit.objectID,
          position: hit._geoloc,
          color: 'blue',
          title: hit.name,
          stateCode: hit.address.state,
          postcode: hit.address.post_code,
          description: () => {
            const suburb = (hit.address.suburb || '').trim()
            const address = (hit.address.street || '').trim()

            return [address, suburb].join(' ')
          },
          link: () => {
            switch (hit.source) {
              case 'GoodFood':
                return {
                  label: 'View',
                  route: '/gift-cards/retailer/good-food',
                }
              default:
                return {
                  label: 'View',
                  route: {
                    name: 'dining-item',
                    params: {
                      slug: hit.slug,
                    },
                  },
                }
            }
          },
        }
      })
    },
  },
  watch: {
    'mapState.bounds'(current, prev) {
      this.getLocations()
      /*
       * If first render and URL has a map state,
       * sync cause an error Navigation Duplicated
       */
      if (prev || !this.$route.query.center) {
        this.syncRoute()
      }
    },
    'mapState.visible'() {
      this.resetFilters()
      this.syncRoute()
    },
    selectedFeeds() {
      this.getLocations()
      this.syncRoute()
    },
    routeQuery() {
      this.syncRoute()
    },
  },
  methods: {
    syncRoute() {
      if (!isEqual(this.$route.query, this.routeQuery)) {
        this.$router
          .replace({
            query: this.routeQuery,
            params: this.$route.params,
          })
          .catch((err) => console.warn(err))
      }
    },
    showAllRestaurants() {
      this.resetFilters()
      this.getLocations({ ignoreBoundingBox: true })
      if (this.mapState.isVisible) {
        this.mapState.toggleVisibility()
      }
    },
  },
}
</script>

<template>
  <div class="mx-auto flex h-full w-full max-w-7xl flex-col">
    <dining-filter class="-mt-2.5 mb-2.5 w-full" />
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
    <div class="flex h-full flex-col">
      <layout-map
        title="Select location near me"
        :map-state="mapState"
        class="grow sm:px-0"
      >
        <div
          class="w-full overflow-y-auto sm:px-5"
          :class="{
            'h-full w-full min-w-48 max-w-md': mapState.isVisible,
            'pt-0': !mapState.isVisible,
          }"
        >
          <ew-catalog-processor
            class="mx-auto w-full max-w-7xl divide-y px-2.5 sm:px-0"
            :tile-class="`hover:shadow-lg bg-light ${
              mapState.isVisible
                ? 'hover:translate-x-[5px]'
                : 'hover:-translate-y-[5px]'
            }`"
            :processor="algolia"
            :columns="
              mapState.isVisible && !ui.mobile
                ? 1
                : {
                    xxs: 2,
                    sm: 3,
                    lg: 4,
                  }
            "
          >
            <template #empty>
              <div class="p-5 text-center">
                <p class="text-xl">
                  Sorry, we couldn't find any restaurants in your area.
                </p>
                <base-button class="mt-5" @click="showAllRestaurants">
                  Show all restaurants
                </base-button>
              </div>
            </template>

            <template #tile="{ tile }">
              <dining-tile :location="tile" />
            </template>
          </ew-catalog-processor>
        </div>

        <template #map>
          <base-map
            class="h-[500px] w-full sm:pt-6"
            :markers="markers"
            :state="mapState"
          />
        </template>
      </layout-map>
    </div>
  </div>
</template>
