<script>
import { nextTick } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCms } from '/~/composables/cms'

export default {
  name: 'scan-scanner',
  components: {
    BaseButton,
    BaseIcon,
    BaseLoader,
  },
  props: {
    activated: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isDarkThemeForEwallet } = useCms()

    return {
      isDarkThemeForEwallet,
    }
  },
  data() {
    return {
      loading: true,
      error: '',
      readerEnabled: true,
      showReloadBtn: false,
      dragover: false,
      dropProcessing: false,
    }
  },
  computed: {
    dragLoading() {
      return this.dragover || this.processing || this.dropProcessing
    },
  },
  methods: {
    onQRStreamInit(readyPromise) {
      readyPromise
        .then(() => {
          this.loading = false
        })
        .catch(this.handleQRStreamError)
    },
    handleQRStreamError(error) {
      this.loading = false

      switch (error.name) {
        case 'NotAllowedError':
          this.error = 'Grant camera access permission then reload QR reader.'
          this.showReloadBtn = true
          break
        case 'NotFoundError':
          this.error =
            'Whoops.<br> Seems like you have no camera on this device.'
          break
        case 'NotSupportedError':
          this.error = 'The website must be served over HTTPS.'
          break
        case 'NotReadableError':
          this.error =
            'Hm, maybe your camera is already in use?<br>Try to reload the QR reader.'
          this.showReloadBtn = true
          break
        case 'OverconstrainedError':
          this.error = 'Sorry, but installed camera are not suitable.'
          break
        case 'StreamApiNotSupportedError':
          this.error = 'Sorry.<br>This mobile browser is not supported.'
          break
        default: {
          this.error =
            'An unknown error has occurred.<br>Try to reload the QR reader.'
          this.showReloadBtn = true
        }
      }
    },
    reloadQRReader() {
      this.showReloadBtn = false
      this.error = false
      this.loading = true

      this.readerEnabled = false

      nextTick(() => {
        this.readerEnabled = true
      })
    },
    onQRDecoded(result) {
      // this.loading = true
      // this.error = ''
      // this.readerEnabled = true
      // this.showReloadBtn = false

      this.$emit('qr-decoded', result)
    },
    onActivate() {
      this.$emit('update:activated', true)
    },
    async onDetect(promise) {
      this.dropProcessing = true

      try {
        const { content } = await promise

        this.error = null

        this.onQRDecoded(content)
      } catch (error) {
        this.handleQRStreamError(error)
      } finally {
        this.dropProcessing = false
      }
    },
    logErrors(promise) {
      promise.catch(this.handleQRStreamError)
    },
    onDragOver(isDraggingOver) {
      this.dragover = isDraggingOver
    },
  },
}
</script>

<template>
  <div class="mt-6 h-80">
    <div class="relative flex h-full items-center justify-center">
      <transition name="fade">
        <qrcode-stream
          v-if="readerEnabled && activated"
          v-show="!error && !loading"
          @init="onQRStreamInit"
          @decode="onQRDecoded"
        />
      </transition>
      <qrcode-drop-zone
        class="absolute h-full w-full"
        :class="{ 'z-20': dragLoading }"
        @detect="onDetect"
        @dragover="onDragOver"
        @init="logErrors"
      />

      <template v-if="!activated">
        <div
          class="m-auto h-full w-full max-w-80 text-white"
          :class="{
            'text-gray-300': !(dragLoading || dropProcessing || processing),
            'text-eonx-neutral-800':
              (dragLoading || dropProcessing || processing) &&
              !isDarkThemeForEwallet,
            'text-gray-100':
              (dragLoading || dropProcessing || processing) &&
              isDarkThemeForEwallet,
          }"
        >
          <base-icon svg="v2/custom/scan-qr-code" :size="320" />
        </div>
        <div class="absolute">
          <base-button
            class="h-12 w-[174px]"
            :loading="processing"
            @click="onActivate"
          >
            Activate Camera
          </base-button>
        </div>
      </template>

      <template v-else>
        <div v-if="error" class="text-center text-xl text-white">
          <div v-html="error" />

          <div class="mt-[30px] flex items-center justify-center">
            <base-button v-if="showReloadBtn" size="sm" @click="reloadQRReader">
              Reload QR reader
            </base-button>
          </div>
        </div>

        <div v-else-if="loading">
          <base-loader fullwidth />
          <div class="text-center font-bold text-white">
            Waiting for the camera
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
