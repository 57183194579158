<script setup lang="ts">
import WgtWrapper from '/~/configurator/components/wgt/wgt-wrapper.vue'
import WidgetTilesStaffContent from './widget-tiles-staff-content.vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import { Tile } from '/~/composables/cms/use-cms'
import { Row, Wrapper, useWrapper } from '/~/configurator/composables/wrapper'
import { computed } from 'vue'

const props = defineProps<{
  row?: Row
  wrapper?: Wrapper
  items: Tile[]
}>()

const { defaultWrapper, getTileKey } = useWrapper(props)

const overrideWrapper = computed(() => {
  const wrapper = { ...defaultWrapper.value }

  wrapper.space = true
  wrapper.row.md = wrapper.row.md ?? 4

  return wrapper
})
</script>

<template>
  <wgt-wrapper :wrapper="overrideWrapper">
    <div
      v-for="(tile, idx) in items"
      :key="getTileKey(tile, idx)"
      :wrapper="overrideWrapper"
      :style="{ backgroundColor: tile.backgroundColor }"
      class="w-full overflow-hidden rounded-md bg-light shadow"
    >
      <base-action
        v-if="tile.tileLink && tile.tileLink.enabled"
        v-bind="tile.tileLink"
        appearance="link"
      >
        <widget-tiles-staff-content :tile="tile" />
      </base-action>
      <widget-tiles-staff-content v-else :tile="tile" />
    </div>
  </wgt-wrapper>
</template>
