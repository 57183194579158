<script>
import Vue, { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.v3.vue'
import EpurchaseTile from '/~/components/drawer/components/tiles/drawer-e-purchases-tile.v2.vue'
import PurchaseBalanceModal from '/~/components/drawer/modals/menu-epurchase-balance-modal.v2.vue'
import DrawerEPurchase from '/~/components/drawer/views/e-purchases/drawer-e-purchase.vue'
import { useCms } from '/~/composables/cms'

export default {
  name: 'epurchase-details',
  components: {
    BaseAsidePage,
    BaseIcon,
    DrawerActions,
    EpurchaseTile,
    PurchaseBalanceModal,
  },
  extends: DrawerEPurchase,
  setup() {
    const { isDarkThemeForEwallet } = useCms()
    const route = useRoute()

    const termsRoute = computed(() => {
      return route.meta.terms ?? { hash: '#profile-e-purchase-terms' }
    })

    return {
      ...DrawerEPurchase.setup?.(...arguments),
      isDarkThemeForEwallet,
      bottomSheet,
      ui,
      termsRoute,
    }
  },
  computed: {
    cardLabel() {
      return this.isCinema ? 'eVoucher' : 'gift card'
    },
    actions() {
      return [
        {
          label: 'View Bunnings eGift Card',
          description: 'Access your eGift Card at any time',
          icon: 'menu/eye',
          hidden: !this.externalUrl || !this.isBunningsGiftcard,
          click: () => {
            if (ui.mobile) {
              this.showExternalModal = true
            } else {
              modal.show('purchase-external', {
                props: {
                  url: this.externalUrl,
                  type: 'giftcard',
                },
              })
            }
          },
        },
        {
          label: 'Edit balance manually',
          description: 'Update your remaining balance',
          icon: 'heroicons/mini/pencil-square',
          hidden: this.isCinema || !this.isBalanceAvailable,
          click: () => {
            this.showBalanceModal = true
          },
        },
        {
          label: 'Show loyalty card',
          description: 'Display or add retailer’s loyalty card',
          hidden: !this.associatedCard,
          disabled: this.associatedLoading,
          icon: 'v2/heroic/star-filled',
          click: () => {
            this.$router.push({
              hash: `#profile-loyalty-card-details/${this.associatedCard.id}`,
            })
          },
        },
        {
          label: 'Buy Again',
          description: `Buy another ${this.cardLabel} from this retailer`,
          icon: 'heroicons/solid/shopping-bag',
          hidden: this.isCashrewardsProvider,
          click: () => {
            this.buyAgain()
          },
        },
        {
          label: 'Print',
          description: `Print a copy of your ${this.cardLabel}`,
          icon: 'heroicons/solid/printer',
          hidden:
            this.isCashrewardsProvider ||
            !this.externalUrl ||
            ui.mobile ||
            this.isBunningsGiftcard,
          click: () => {
            if (ui.mobile) {
              this.showExternalModal = true
            } else {
              modal.show('purchase-external', {
                props: {
                  url: this.externalUrl,
                  type: this.isCinema ? 'voucher' : 'giftcard',
                },
              })
            }
          },
        },
        {
          label: 'Terms of use',
          description: 'View terms of use',
          icon: 'heroicons/solid/document-text',
          click: () => {
            this.$router.push(this.termsRoute)
          },
        },
        {
          label: 'Archive',
          description: 'Archive your ePurchase',
          icon: 'heroicons/solid/trash',
          backgroundColor: 'bg-red-700',
          iconColor: 'text-red-700',
          click: () => {
            this.bottomSheet.show('confirmation-modal-v2', {
              to: this.to,
              props: {
                icon: 'heroicons/solid/trash',
                title: 'Are you sure you want to archive this ePurchase?',
                text: 'By archiving this ePurchase you will move the ePurchase from your Current ePurchases to your Archived section.',
                onConfirm: this.onDeleteConfirmationModalConfirm,
                confirmButtonText: 'Archive ePurchase',
              },
            })
          },
        },
      ]
    },
  },
  methods: {
    async onDeleteConfirmationModalConfirm() {
      try {
        await this.archiveGiftCard(this.uuid)
        this.$route.params.nativeBack = true
        await this.$router.back()
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong while deleting. Please try again.',
        })
      }
    },
    onUnlockModalCanceled() {
      this.$router.push({
        name: 'e-purchases',
        params: {
          isBack: true,
        },
      })
    },
  },
}
</script>

<template>
  <base-aside-page :key="id" :title="title" no-padding class="flex flex-col">
    <div class="mt-8 min-h-52 shrink-0">
      <epurchase-tile
        v-if="card"
        :card="card"
        details-view
        class="mx-auto max-w-[300px]"
      />
    </div>
    <div
      v-if="loading || !card || !details"
      class="flex h-48 w-full items-center justify-center"
    >
      <base-loader />
    </div>
    <div v-else class="mt-2.5 flex grow flex-col">
      <div v-if="!isCinema && url" class="mx-5 mb-5">
        <base-link :href="url">
          <base-button full-width color="primary">Redeem</base-button>
        </base-link>
      </div>

      <div
        v-if="isCinema && url"
        class="w-full space-y-2.5 rounded-lg bg-light p-2.5"
      >
        <div class="m-auto pt-6" style="width: 240px">
          <img :src="url" />
        </div>
      </div>

      <template v-if="isBarcodes">
        <div
          v-for="(item, index) in barcodes"
          :key="index"
          class="mx-5 rounded-lg"
        >
          <barcode-unlocked
            class="min-h-20"
            :details="item"
            :options="{
              background: 'transparent',
              fontSize: 16,
              displayValue: false,
            }"
          />

          <div
            v-if="item.cardNumber"
            class="mt-2 text-center text-sm text-eonx-neutral-600"
          >
            {{ item.cardNumber }}
          </div>
          <div
            v-if="item.pin"
            class="mt-[15px] text-center font-bold"
            :class="{
              'text-eonx-neutral-800': isDarkThemeForEwallet,
            }"
          >
            PIN {{ item.pin }}
          </div>
        </div>
      </template>

      <drawer-actions :actions="actions" class="mt-[15px] px-4" />

      <div
        class="m-5 rounded-lg border border-eonx-neutral-200 p-4"
        :class="{
          'bg-gray-50': !isDarkThemeForEwallet,
          'bg-gray-800': isDarkThemeForEwallet,
        }"
      >
        <dl class="grid grid-cols-5 gap-y-4 gap-x-[5px]">
          <template v-for="(item, index) in info">
            <dt
              :key="`dt${index}`"
              class="col-span-2 flex items-center text-eonx-neutral-600"
            >
              <base-icon :svg="item.icon" :size="24" class="mr-[5px]" />
              {{ item.label }}
            </dt>
            <dd
              :key="`dd${index}`"
              class="col-span-3 break-words font-bold"
              :class="{
                'text-eonx-neutral-800': !isDarkThemeForEwallet,
              }"
            >
              {{ item.value }}
            </dd>
          </template>
        </dl>
      </div>
    </div>

    <purchase-external-modal
      v-if="showExternalModal"
      :to="to"
      :visible.sync="showExternalModal"
      :url="externalUrl"
    />
    <purchase-balance-modal
      v-if="showBalanceModal"
      :to="to"
      :visible.sync="showBalanceModal"
    />
  </base-aside-page>
</template>
