<script>
import { ValidationObserver } from 'vee-validate'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useAuth } from '/~/composables/auth'
import { useForm } from '/~/composables/base/use-form'
import { useProvider } from '/~/composables/provider'
import AuthLayout from '/~/layouts/auth/layout-auth.vue'
import AuthLogo from '/~/views/auth/auth-logo.vue'

export default {
  name: 'auth-password',
  components: {
    AuthLogo,
    AuthLayout,
    BaseInput,
    BaseButton,
    BaseBack,
    ValidationObserver,
  },
  setup() {
    const { resetPassword } = useAuth()
    const { isSymbioneliteProvider } = useProvider()
    const { validationObserverRef } = useForm()

    return {
      resetPassword,
      isSymbioneliteProvider,
      validationObserverRef,
    }
  },
  data() {
    return {
      processing: false,
      email: '',
      backendErrors: {},
    }
  },
  computed: {
    submitDisabled() {
      return this.hasErrors || !this.email.length
    },
    hasErrors() {
      return Boolean(this.validationObserverRef?.flags.invalid)
    },
  },
  methods: {
    async submit() {
      this.processing = true

      if (window.lcAnalytics) {
        window.lcAnalytics.track('Forgot Password Started', {
          email: this.email,
        })
      }

      try {
        await this.resetPassword({
          email: this.email,
        })

        this.$router.push({ name: 'auth-login' })
      } catch (error) {
        console.error('auth', error)
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<template>
  <auth-layout>
    <auth-logo :class="{ 'md:hidden': isSymbioneliteProvider }" />

    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="mx-auto mt-16 w-full max-w-screen-xs p-5 md:mt-0"
        @submit.prevent="handleSubmit(submit)"
      >
        <h1
          class="mb-5 text-xl font-bold text-eonx-neutral-800 sm:mb-10 sm:text-left sm:text-2xl md:mt-[60px]"
        >
          Reset Password
        </h1>
        <base-input
          v-model="email"
          :validation="{
            rules: { required: true, email: true, min: 7, max: 254 },
            mode: 'aggressive',
            name: 'Email',
          }"
          :error="backendErrors.email"
          :disabled="processing"
          data-cy="email"
          name="email"
          label="Email"
          type="email"
          required
          autocomplete="username"
          mode="aggressive"
        />
        <base-button
          v-analytics:click="{
            pageGroup: 'Auth',
            page: 'Reset password',
            cta: 'Reset password',
          }"
          :disabled="processing || submitDisabled"
          :loading="processing"
          class="mx-auto mt-5 mb-5 w-full"
          data-cy="submit"
          type="submit"
        >
          Reset Password
        </base-button>
        <div class="text-center">
          <base-back
            v-analytics:click="{
              pageGroup: 'Auth',
              page: 'Reset password',
              cta: 'Back to Login',
            }"
            :to="{ name: 'auth-login' }"
          >
            Back to Login
          </base-back>
        </div>
      </form>
    </validation-observer>
  </auth-layout>
</template>
