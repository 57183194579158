<script setup lang="ts">
import modal from '/~/core/mdl'
import CategoryTag from '/~/extensions/health/components/section-category-tag.vue'
import { useExercises } from '/~/extensions/health/composables'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'

const props = defineProps<{
  tile: {
    id: string
    title: string
    image: string
    video: string
    categories: string[]
  }
}>()

const { isBookmarked, toggleBookmark, categoryTags } = useExercises()

function onClick() {
  modal.show('embedded-video-player', {
    props: { source: props.tile.video },
  })
}
</script>

<template>
  <div
    class="flex h-full cursor-pointer flex-col overflow-hidden rounded-lg bg-white shadow-sm"
  >
    <div class="relative">
      <base-image :src="tile.image" :rounded="false" @click="onClick" />
    </div>
    <div class="-mt-[15px] flex justify-end">
      <div
        class="z-1 mr-[15px] flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full bg-white p-[5px] text-primary shadow-sm hover:shadow"
      >
        <button-icon
          :icon="
            isBookmarked(tile.id) ? 'plain/bookmark-marked' : 'plain/bookmark'
          "
          size="md"
          @click.stop="toggleBookmark(tile.id)"
        />
      </div>
    </div>
    <div class="flex h-full flex-col px-[15px] pb-[15px]" @click="onClick">
      <h3
        class="mb-[5px] min-h-14 w-full text-base font-bold leading-6 text-eonx-neutral-800"
      >
        {{ tile.title }}
      </h3>

      <div class="-m-[5px] mt-auto flex flex-wrap">
        <category-tag
          v-for="(category, i) in tile.categories"
          :key="`tag-${i}`"
          :color="categoryTags[category]"
          :text="category"
          class="m-[5px]"
        />
      </div>
    </div>
  </div>
</template>
