<script>
export default {
  name: 'hlth-tile',
  props: {
    title: {
      type: String,
      default: '',
    },
    topTitle: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <div>
    <h2
      v-if="title && topTitle"
      class="mb-[15px] text-xl leading-6 sm:text-2xl sm:leading-7"
    >
      {{ title }}
    </h2>
    <div
      :class="[
        !plain &&
          'h-full rounded-3xl bg-white px-[15px] py-6 sm:px-6 sm:py-[30px]',
        !plain && shadow && 'shadow-sm',
      ]"
    >
      <h2
        v-if="title && !topTitle"
        class="text-xl leading-6 sm:text-2xl sm:leading-7"
      >
        {{ title }}
      </h2>

      <slot />
    </div>
  </div>
</template>
