<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, computed, reactive } from 'vue'
import { useRouter } from 'vue-router/composables'
import { AlgoliaWorker, getIndexByFlag } from '/~/core/algolia'
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useSwiper } from '/~/composables/base/use-swiper'
import { useExtensions } from '/~/composables/extensions'
import { useUser } from '/~/composables/user'

const router = useRouter()

type PopularContent = {
  title?: string
  contentAlignmentX?: string
}

type PopularBrand = {
  retailer_slug: string
  retailer_alt_logo: string
  retailer_name: string
}

const props = withDefaults(
  defineProps<{
    content: PopularContent
    enableTitle?: boolean
    autoplay?: string
    actionButton?: object
  }>(),
  {
    enableTitle: false,
    autoplay: '',
  }
)

const { getConfigByName } = useExtensions()

const algolia = reactive(new AlgoliaWorker())

const config = computed(() => getConfigByName('gift-cards') || {})
const mostPopularIndex = computed(() =>
  getIndexByFlag(config.value, 'mostPopular')
)
const defaultFilters = computed(() => {
  const { user } = useUser()

  return [
    user.value.membershipId ? `memberships:${user.value.membershipId}` : '',
    config.value.filters || config.value.filter,
  ]
    .filter((v) => v)
    .join(' AND ')
})

async function getPopularBrand() {
  algolia.reset()
  await algolia.getData({
    index: mostPopularIndex.value,
    search: {
      filters: defaultFilters.value,
      attributesToRetrieve: [
        'retailer_name',
        'retailer_alt_logo',
        'retailer_slug',
      ],
    },
  })
}

const { swiperContainer, initSwiper, destroySwiper } = useSwiper(
  computed(() => ({
    autoplay: !props.autoplay
      ? false
      : {
          delay: Number(props.autoplay),
          disableOnInteraction: false,
        },
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    breakpoints: {
      360: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      896: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1025: {
        slidesPerView: 6,
        slidesPerGroup: 6,
      },
    },
  }))
)

const slides = ref<PopularBrand[]>([])
const loading = ref(false)

const content = computed(() => props.content)
const title = computed(() =>
  props.content.title?.replace(
    /<\s*([a-zA-Z0-9]+)(.*?)>/,
    '<$1 class="text-xl font-bold leading-6">'
  )
)
const enableTitle = computed(() => props.enableTitle)

onMounted(() => {
  getBrands()
})

onBeforeUnmount(destroySwiper)

async function getBrands() {
  loading.value = true

  try {
    await getPopularBrand()
    slides.value = algolia.hits ?? []
    if (mostPopularIndex.value) {
      initSwiper()
    }
  } catch (exception) {
    console.warn(exception)
  } finally {
    loading.value = false
  }
}

function brandOfferUrl(brand: PopularBrand) {
  const resolvedRoute = router.resolve({
    name: 'giftcards-retailer',
    params: { slug: brand.retailer_slug },
  })

  return resolvedRoute?.href ?? `/gift-cards/retailer/${brand.retailer_slug}/`
}
</script>

<template>
  <section v-if="mostPopularIndex" data-cy="widget-popular">
    <div
      v-if="enableTitle"
      class="mb-[30px] pt-2.5"
      :style="{
        textAlign: content.contentAlignmentX,
      }"
      v-html="title"
    />
    <base-placeholder
      v-if="loading"
      class="w-full"
      :columns="{
        xxs: 2,
        sm: 3,
        md: 6,
      }"
      height="110"
    />
    <div
      v-show="!loading"
      ref="swiperContainer"
      class="swiper-container overflow-hidden"
    >
      <div data-testid="swiper-container" class="swiper-wrapper w-full">
        <div
          v-for="(slide, index) in slides"
          :key="index"
          class="swiper-slide shrink-0"
          data-testid="swiper-slide"
        >
          <base-link
            ref="link"
            :to="{ path: brandOfferUrl(slide) }"
            class="block rounded-xl bg-light shadow transition duration-200 hover:shadow-md"
          >
            <base-image
              class="mx-auto block"
              :src="
                cdn(slide.retailer_alt_logo)
                  .preview('600x600')
                  .quality('best')
                  .url()
              "
              :srcset="`${cdn(slide.retailer_alt_logo)
                .preview('600x600')
                .quality('best')
                .url()} 1x,
                      ${cdn(slide.retailer_alt_logo)
                        .preview('1200x1200')
                        .quality('best')
                        .url()} 2x`"
              :alt="`${slide.retailer_name} eGift Card`"
            />
          </base-link>
        </div>
      </div>
    </div>
    <div
      v-if="actionButton && actionButton.enabled"
      class="my-6 flex w-full justify-center"
    >
      <base-action v-bind="actionButton">
        {{ actionButton.label }}
      </base-action>
    </div>
  </section>
</template>
