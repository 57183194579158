<script>
import BaseButton from '/~/components/base/button/base-button'
import DrawerModal from '/~/templates/bill-payments/components/drawer-modal.vue'
import PointsPrograms from './points-programs.vue'

export default {
  name: 'tier-modal',
  components: {
    DrawerModal,
    PointsPrograms,
    BaseButton,
  },
}
</script>
<template>
  <drawer-modal v-bind="$attrs" to="menu-modal" @hide="$emit('hide')">
    <span class="absolute top-0 right-0 mr-5 mt-[35px]">
      <base-button icon="plain/close" @click="$emit('hide')" />
    </span>
    <points-programs is-drawer to="menu-modal" />
  </drawer-modal>
</template>
