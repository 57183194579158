<script setup lang="ts">
import debounce from 'lodash-es/debounce'
import Vue, { computed, onBeforeUnmount, reactive, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import { PaymentMethod, ProgramFees, ApiResponseData } from '/~/types/api'
import api, { notify } from '/~/core/api'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { usePayroll } from '/~/extensions/payroll/composables/use-payroll'
import { roundFigure } from '/~/utils/format/numeric'
import { Metafield } from '/~/components/base/metafield/base-metafield.vue'
import { useAddresses } from '/~/composables/addresses'
import {
  useBatchOrder,
  useBatchOrderPayeesTable,
} from '/~/composables/batch-order'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { type ProcessingTimesItem } from '/~/composables/cms/use-cms'
import { usePayees } from '/~/composables/payees'
import {
  useFlowPaymentMethods,
  usePaymentMethods,
  useVerifyCard,
} from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'
import { useScheduledPayments } from '/~/composables/scheduled-payments'
import { Statement, useStatements } from '/~/composables/statements'
import { useUser } from '/~/composables/user'
import {
  PayingToPayee,
  PayingToForm,
} from '/~/templates/bill-payments-v1_5/views/payments/make/components/paying-to.pure.vue'
import PaymentsMakeV2Pure, {
  PaymentsMakePureProps,
} from './payments-make.pure.vue'

const router = useRouter()
const route = useRoute()
const { user } = useUser()
const { taxationLabel } = useProvider()
const { batchOrder, fetchBatchOrder, onCancelBatchOrder } = useBatchOrder()
const {
  providerTitle,
  splitPaymentOrderAllowed,
  splitPaymentCombinationsAllowed,
} = useProvider()
const { currentFlowType, payment } = useCheckoutReactive()
const {
  payees,
  fetchPayees,
  allowMultiplePayees: isAddingNewPayeeEnabled,
} = usePayees()
const {
  fetchPaymentMethods,
  creditCards,
  bankAccounts,
  isMethodAvailable,
  points,
  config,
} = usePaymentMethods()

const {
  fetchBalance,
  pointsBalance,
  burnPointsRatePaymentOrder,
  burnPointsRateStatementOrder,
  calculatePointsEarnedForPayment,
} = usePoints()
const { fetchActiveStatements, activeItems, activeStatementHasNextPage } =
  useStatements()
const { isPayrollEnabled } = usePayroll()
const { verifyingCard } = useVerifyCard()
const { processingTimes } = useCms()

const loading = ref(false)
const isSummaryOpen = ref(false)

const whenToPayHintMessage = computed(() => {
  const { schedulePaymentDisabledMessage } =
    useScheduledPayments(useCheckoutReactive)

  return schedulePaymentDisabledMessage.value
})
const scheduledParams = computed(() => {
  const { scheduledPaymentDateFrom, scheduledPaymentDateTo } =
    useScheduledPayments(useCheckoutReactive)

  return {
    enabled: scheduledPaymentsEnabled.value,
    from: scheduledPaymentDateFrom.value,
    to: scheduledPaymentDateTo.value,
  }
})

const processing = computed(
  () => verifyingCard.value || payment.value.submitting || isSummaryOpen.value
)

const isDirectDebit = computed(() =>
  'isDirectDebit' in payment.value ? payment.value.isDirectDebit : false
)
const scheduledPaymentsEnabled = computed(() => {
  const { isScheduledPaymentsEnabled, schedulePaymentDisabledMessage } =
    useScheduledPayments(useCheckoutReactive)

  return (
    !isDirectDebit.value &&
    isScheduledPaymentsEnabled.value &&
    !schedulePaymentDisabledMessage.value
  )
})

const approvedPayees = computed(() =>
  payees.items.filter((payee) => payee.status === 'approved')
)

const groupedPayees = computed<PayingToPayee[]>(() => {
  const activeStatements = activeItems.value
    .map((item) => {
      return {
        ...item,
        name: item.raw.userStatementAccount.name,
        providerTitle: providerTitle.value,
        number: item.raw.number,
        id: item.payeeId,
        type: 'statement',
        subtotal: item.subtotal,
      }
    })
    .sort(sortByNameProp)

  const otherPayees = [...approvedPayees.value].sort(sortByNameProp)

  return [
    ...activeStatements,
    ...otherPayees.filter((item) => item.type === 'bankAccount'),
    ...otherPayees.filter((item) => item.type === 'bpay'),
  ].map((item) => {
    return {
      ...item,
      value: item.id,
      text:
        'accountName' in item && item.accountName
          ? item.accountName
          : item.name,
    }
  })
})

const pointsSplitSettings = computed<
  PaymentsMakePureProps['pointsSplitSettings']
>(() => {
  const pointsPlusCardEnabled = splitPaymentCombinationsAllowed('creditCard', {
    points: true,
  })
  const pointsPlusBankAccountEnabled = splitPaymentCombinationsAllowed(
    'directDebit',
    { points: true }
  )
  const splitEnabled =
    splitPaymentOrderAllowed(currentFlowType.value) &&
    (pointsPlusCardEnabled || pointsPlusBankAccountEnabled)

  return {
    enabled: splitEnabled,
    pointsPlusCardEnabled,
    pointsPlusBankAccountEnabled,
  }
})

const paymentOptions = computed<PaymentsMakePureProps['paymentOptions']>(() =>
  [
    isMethodAvailable(PaymentMethodType.creditCard, currentFlowType.value) && {
      name: 'Credit/Debit Card',
      type: PaymentMethodType.creditCard,
      icons: ['heroicons/solid/credit-card'],
      iconSize: 24,
      order: config.value.findIndex(
        (item) => item.type === PaymentMethodType.creditCard
      ),
    },
    isMethodAvailable(PaymentMethodType.bankAccount, currentFlowType.value) && {
      name: 'Bank Account',
      type: PaymentMethodType.bankAccount,
      icons: ['billpay/payment-methods/v1.5/direct-debit'],
      notes: '(We will directly debit your bank account)',
      order: config.value.findIndex(
        (item) => item.type === PaymentMethodType.bankAccount
      ),
    },
    isMethodAvailable(PaymentMethodType.points, currentFlowType.value) &&
      pointsBalance.value > 0 && {
        name: pointsSplitSettings.value.enabled ? 'Points + Pay' : 'Points',
        type: PaymentMethodType.points,
        icons: ['billpay/payment-methods/v1.5/points-token'],
        order: config.value.findIndex(
          (item) => item.type === PaymentMethodType.points
        ),
      },
  ]
    .filter(Boolean)
    .sort((a, b) => a.order - b.order)
)

const sortByNameProp = (a: any, b: any) => {
  return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
}

function updateFlowType(flowType: FlowType) {
  currentFlowType.value = flowType
}

const getCreditCards: PaymentsMakePureProps['getCreditCards'] = async (
  payeeId?: string
) => {
  await fetchPaymentMethods(currentFlowType.value, payeeId)

  return creditCards.value as any
}

const getBankAccounts: PaymentsMakePureProps['getBankAccounts'] = async (
  payeeId?: string
) => {
  await fetchPaymentMethods(currentFlowType.value, payeeId)

  return bankAccounts.value as any
}

const getPointsBalance: PaymentsMakePureProps['getPointsBalance'] = async (
  payeeId?: string
) => {
  await fetchBalance()

  let methodsResult: PaymentMethod[] = []

  if (pointsSplitSettings.value.enabled) {
    await fetchPaymentMethods(currentFlowType.value, payeeId)

    if (pointsSplitSettings.value.pointsPlusCardEnabled) {
      methodsResult = methodsResult.concat(creditCards.value)
    }

    if (pointsSplitSettings.value.pointsPlusBankAccountEnabled) {
      methodsResult = methodsResult.concat(bankAccounts.value)
    }
  }

  return methodsResult as any
}

async function fetchPayingToOptions() {
  loading.value = true

  try {
    await Promise.all([fetchPayees(), fetchActiveStatements()]) // the 1st page
    await Promise.all([fetchAllPayees(), featAllActiveStatements()]) // the rest of items
  } catch (error) {
    console.error('payment-v1_5', error)
  } finally {
    loading.value = false
  }
}

async function fetchAllPayees() {
  /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
  while (true) {
    if (!payees.pagination.hasNextPage) {
      return
    } else {
      await fetchPayees({ page: payees.pagination.currentPage + 1 })
    }
  }
}

async function featAllActiveStatements() {
  /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
  while (true) {
    if (!activeStatementHasNextPage.value) {
      return
    } else {
      await fetchActiveStatements({}, true)
    }
  }
}

const initialPayingTo = computed<
  | ({
      payeeId?: string
    } & PayingToForm)
  | undefined
>(() => route.params?.payingTo as any)
const initialPayee = computed(() => {
  const result = groupedPayees.value.find(
    (item) => item.id === initialPayingTo.value?.payeeId
  )

  return result
})

const payee = ref(initialPayee.value)
const payeeForm = reactive<PayingToForm>({
  amount: initialPayingTo.value?.amount,
  description: undefined,
  reference: undefined,
})
const isStatementSelected = computed(() => payee.value?.type === 'statement')
const isBankAccountSelected = computed(
  () => payee.value?.type === 'bankAccount'
)
const isAmountDisabled = computed(
  () =>
    isStatementSelected.value &&
    payee.value &&
    'raw' in payee.value &&
    parseFloat(payee.value.raw?.subtotal) > 0
)
const maxAmountValue = 99999999.99
const minAmountValue = 1
const payeeFormFields = computed(() => {
  if (orderId.value) {
    return [
      {
        type: 'currency',
        key: 'amount',
        label: 'Amount',
        disclaimer: 'Amount imported from ABA file',
        validation: {
          name: 'Amount',
          rules: `required|min_value:${minAmountValue}|max_value:${maxAmountValue}`,
        },
        validations: ['required'],
        readonly: true,
        entryClass: 'rounded-sm h-12',
      },
    ] as Metafield[]
  }

  const result: Metafield[] = [
    {
      type: 'currency',
      key: 'amount',
      label: 'Amount',
      disclaimer: 'This amount will be paid to the payee',
      validation: {
        name: 'Amount',
        rules: `min_value:${minAmountValue}|max_value:${maxAmountValue}`,
      },
      readonly: isAmountDisabled.value,
      entryClass: 'rounded-sm h-12',
    },
  ]

  if (!isStatementSelected.value) {
    result.push({
      type: 'string',
      key: 'description',
      label: 'Description',
      validation: {
        name: 'Description',
        rules: 'required|max:8',
      },
      disclaimer: 'Appears on your statement (max 8 characters)',
      entryClass: 'rounded-sm h-12',
    })
  }

  if (isBankAccountSelected.value) {
    result.push({
      type: 'string',
      key: 'reference',
      label: 'Reference',
      validation: {
        name: 'Reference',
        rules: 'required|max:9',
      },
      disclaimer: 'Appears on the payee statement (max 9 characters)',
      entryClass: 'rounded-sm h-12',
    })
  }

  return result
})
const programFees = ref<ProgramFees>()

function onPayeeSelect(selectedPayee?: PayingToPayee) {
  payee.value = selectedPayee
  programFees.value = undefined

  if (selectedPayee?.type === 'statement' && 'raw' in selectedPayee) {
    updateFlowType(FlowType.statement)
    payeeForm.amount = `${selectedPayee.subtotal}`
    paymentReady.value = false
    paymentLoading.value = true
    initPaymentDebounced()
  } else {
    updateFlowType(FlowType.payment)
    payeeForm.amount = undefined
    paymentReady.value = false
  }

  payeeForm.description = undefined
  payeeForm.reference = undefined
}

const paymentReady = ref(false)
const paymentLoading = ref(false)

async function onPayeeFormUpdate(updatedForm: PayingToForm) {
  payeeForm.description = updatedForm.description
  payeeForm.reference = updatedForm.reference

  if (payeeForm.amount !== updatedForm.amount) {
    payeeForm.amount = updatedForm.amount

    if (
      payeeForm.amount >= minAmountValue &&
      payeeForm.amount <= maxAmountValue
    ) {
      paymentLoading.value = true
      await initPaymentDebounced()
    }
  }
}

const initPaymentDebounced = debounce(initPayment, 1000)

async function initPayment() {
  paymentReady.value = false
  paymentLoading.value = true

  if (!parseFloat(payeeForm.amount ?? '0')) {
    paymentLoading.value = false
    return
  }

  payment.value.reset()

  try {
    await payment.value.initPayment({
      amount: payeeForm.amount,
      reference: payeeForm.reference,
      description: payeeForm.description,
      payee: payee.value?.type !== 'statement' ? payee.value : undefined,
      statement:
        payee.value?.type === 'statement' && 'raw' in payee.value
          ? {
              ...payee.value,
              id: payee.value.number,
            }
          : undefined,
    })

    programFees.value = payment.value.programFeesData
    paymentReady.value = true
  } catch (error) {
    console.error('payment-v1_5', error)
  } finally {
    paymentLoading.value = false
  }
}

function onManagePayees() {
  router.push({ name: 'payments-payees' })
}

const currentProcessingTimesItem = computed(() => {
  function getProcessingTimesType(): ProcessingTimesItem['type'] | undefined {
    switch (currentFlowType.value) {
      case FlowType.statement:
        return 'statement'

      case FlowType.payment:
        if (!payee.value?.type) return

        return payee.value.type as 'bankAccount' | 'bpay' | 'statement'

      case FlowType.batch:
        return payment.value.subFlowType === 'payroll' ? 'payroll' : 'batch'

      default:
        console.warn('Unhandled flow type:', currentFlowType.value)
        return
    }
  }

  const processingTimesType = getProcessingTimesType()

  if (!processingTimesType) return

  return processingTimes.value?.items?.find(
    (item) => item.type === processingTimesType
  )
})

function onProcessingTimes() {
  modal.show('payments-make-ways-to-pay', {
    props: {
      title: processingTimes.value?.title,
      content: currentProcessingTimesItem.value?.content,
    },
  })
}

function onAddNewPaymentMethod() {
  router.push({
    hash: '#profile-payment-methods-add',
    params: {
      source: currentFlowType.value,
    },
  })
}

function onTermsAndConditions() {
  modal.show('terms-conditions')
}

function onViewBatch() {
  const { initBatchOrderPayeesPreview } = useBatchOrderPayeesTable()

  initBatchOrderPayeesPreview()

  modal.show('batch-payment-payee-list')
}

function onCancel() {
  modal.show('payments-make-cancel-confirmation', {
    props: {
      onConfirm: () => {
        payment.value.reset()
        router.push({ name: 'home' })
      },
    },
  })
}

const getProcessingFee: PaymentsMakePureProps['getProcessingFee'] = async (
  id
) => {
  // TODO: replace this method with
  // const { data } = await payment.getTransactionFees()

  // TODO: check why amount is a number

  const payeeFormAmount = parseFloat(payeeForm.amount ?? '0')
  const amountNum = payeeFormAmount + (programFees.value?.totalFees ?? 0)

  if (!id) {
    return ''
  }

  if (payeeFormAmount < minAmountValue || payeeFormAmount > maxAmountValue) {
    return ''
  }

  try {
    const payload: {
      paymentSources: Array<{ paymentMethodId: string; amount: string }>
      paymentDestinations?: Array<{ payeeId?: string; amount: string }>
    } = {
      paymentSources: [
        {
          paymentMethodId: id,
          amount: `${roundFigure(amountNum)}`,
        },
      ],
    }

    if (currentFlowType.value !== FlowType.batch) {
      payload.paymentDestinations = [
        {
          payeeId: payee.value?.id,
          amount: `${payeeForm.amount}`,
        },
      ]
    }

    const transactionFeesURL =
      currentFlowType.value === FlowType.batch
        ? `/v3/payment-methods/fees/batch-orders/${batchOrder.number}`
        : '/v3/payment-methods/fees'

    type TransactionFeesResponseData = {
      paymentSources: [
        {
          paymentMethodId: string
          amount: string
          fee: string
          feePayer: boolean
          fixedFeeRate: string
          percentageFeeRate: string
        }
      ]
      subtotal: string
      fee: string
      total: string
    }

    const { data } = await api.post<
      ApiResponseData<TransactionFeesResponseData>
    >(transactionFeesURL, payload, { notify: false })

    return (
      data?.paymentSources.find((item) => item.paymentMethodId === id)
        ?.percentageFeeRate ?? ''
    )
  } catch (error: any) {
    if (error.data?.errors) {
      notify(
        {
          data: {
            errors: error.data?.errors,
          },
        },
        'error'
      )
    }
    notify(error, 'error')
  }
}

async function onSubmit(summary: any) {
  if (processing.value) {
    return
  }

  isSummaryOpen.value = true

  try {
    summary.flowType = currentFlowType.value
    summary.payingTo.providerTitle = providerTitle.value
    summary.points = calculatePoints(summary.payingTo.amount)
    summary.payingTo.statement = summary.payingTo.statement?.raw
      ? new Statement(summary.payingTo.statement.raw)
      : summary.payingTo.statement

    if (currentFlowType.value === FlowType.batch) {
      summary.payingTo.batchOrder = batchOrder
    }

    summary.action = async () => pay()
    summary.hide = hideSummaryModal

    payment.value.description = summary.payingTo.description
    if ('reference' in payment.value) {
      payment.value.reference = summary.payingTo.reference
    }
    payment.value.date = summary.scheduledDate

    // request transaction fees if only credit card or bank account is selected
    if (summary.payingFrom.method) {
      await payment.value.getTransactionFees()
    }

    if (summary.payingFrom.isPointsSelected) {
      summary.processingFee = payment.value.selectedPoints?.fee
    }

    if (summary.payingFrom.method?.type === PaymentMethodType.creditCard) {
      summary.processingFee = payment.value.selectedCard?.fee
      summary.percentageFeeRate = payment.value.selectedCard?.percentageFeeRate
    }

    if (summary.payingFrom.method?.type === PaymentMethodType.bankAccount) {
      summary.processingFee = payment.value.selectedBankAccount?.fee
      summary.percentageFeeRate =
        payment.value.selectedBankAccount?.percentageFeeRate
    }

    summary.programFeesData = programFees.value
    summary.total = payment.value.total
    summary.payWith = payment.value.payWith

    modal.show('payments-make-summary', {
      props: summary,
    })
  } catch (error: any) {
    console.error('payment-v1_5', error)
  } finally {
    isSummaryOpen.value = false
  }
}

async function initAddress() {
  const { addresses, getAddresses } = useAddresses()

  try {
    if (addresses.value.length > 0) {
      handlePayAddress()
    } else {
      await getAddresses()
      handlePayAddress()
      if (addresses.value.length === 0) {
        showAddAddress()
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function showAddAddress() {
  router.push({
    ...payment.value.getAddAddressRoute(),
  })
}

function handlePayAddress(address = null) {
  const { addresses, defaultAddress, firstAddress } = useAddresses()

  if (addresses.value.length === 0) return
  const selectedAddress = address || defaultAddress.value || firstAddress.value

  if (selectedAddress) {
    payment.value.address = selectedAddress
  }
}

async function pay() {
  await initAddress()

  try {
    await payment.value.checkout({
      onPreview: showPreviewModal,
      onComplete,
    })
  } catch (error: any) {
    Vue.notify({
      text: error,
      type: 'error',
      duration: 5000,
    })
  }
}

function hideSummaryModal() {
  modal.hide('payments-make-summary')
}

async function showPreviewModal(preview: any, { onConfirm, onCancel }: any) {
  if (preview.length > 0) {
    modal.show('checkout-preview', {
      props: {
        onCancel: () => {
          onCancel()
          router.replace({ name: 'home' })
        },
        onConfirm,
      },
    })
  } else {
    onConfirm()
  }
}

async function onComplete(result: any) {
  const { fetchCurrentPurchases } = usePurchases()
  const { fetchPaymentMethods } = useFlowPaymentMethods(
    currentFlowType.value,
    'payeeId' in payment.value ? payment.value.payeeId : undefined
  )

  try {
    if (
      ['completed', 'pending', 'processing', 'scheduled'].includes(
        result.status?.toLowerCase()
      )
    ) {
      await fetchCurrentPurchases()

      fetchPaymentMethods()

      emitter.emit('activity:refresh')
    }

    hideSummaryModal()

    const confirmationRoute = () => {
      switch (currentFlowType.value) {
        case FlowType.statement:
          return 'payments-confirmation-statement'
        case FlowType.batch:
          return 'payments-confirmation-batch'
        default:
          return 'payments-confirmation'
      }
    }

    router.push({
      name: confirmationRoute(),
      params: {
        orderId: result.number,
      },
    })
  } catch (error) {
    console.error(error)
    /* processBackendErrors(error)
    const errorKeys = Object.keys(backendErrors.value)

    if (errorKeys.length) {
      Vue.notify({
        text: backendErrors.value[errorKeys]?.[0],
        type: 'error',
        duration: 5000,
      })
    } */
  }
}

function calculatePoints(sum: string) {
  return {
    balance: calculatePointsEarnedForPayment(sum),
    membershipName: user.value?.membershipName,
  }
}

const hints = computed(() => {
  const result: PaymentsMakePureProps['hints'] = []

  if (isPayrollEnabled.value) {
    result.push({
      type: 'info',
      text: 'Payroll payments can only be made via Internet Banking (Fast payment)',
    })
  }

  return result
})

const hasPointsMethod = computed(() => {
  return Boolean(points.value[0])
})

function onHowToPaySelect() {
  payment.value.resetPaymentMethods()
}

function onPointsToUseChange(payload: {
  pointsToUse: number
  calculatedAmount: number
}) {
  payment.value.selectedPoints = {
    ...points.value[0],
    ...payment.value.selectedPoints,
    usePoints: payload.pointsToUse,
    calculatedAmount: payload.calculatedAmount,
  }
}

function onPaymentMethodSelect(method: PaymentMethod) {
  if (method?.type === PaymentMethodType.creditCard) {
    payment.value.selectedCard = {
      ...method,
      calculatedAmount:
        payment.value.subTotalWithProgramFees -
        (payment.value.selectedPoints?.calculatedAmount ?? 0),
    }
    payment.value.selectedBankAccount = null
  } else if (method?.type === PaymentMethodType.bankAccount) {
    payment.value.selectedCard = null
    payment.value.selectedBankAccount = {
      ...method,
      calculatedAmount:
        payment.value.subTotalWithProgramFees -
        (payment.value.selectedPoints?.calculatedAmount ?? 0),
    }
  } else {
    payment.value.selectedCard = null
    payment.value.selectedBankAccount = null
  }
}

const orderId = computed(() => route.params.orderId)

async function init() {
  updateFlowType(FlowType.purchase)

  if (orderId.value) {
    updateFlowType(FlowType.batch)

    loading.value = true

    try {
      await fetchBatchOrder(orderId.value, {
        useLoading: false,
        checkType: false,
      })
      await payment.value.initPayment({ amount: payment.value.amount })

      paymentReady.value = true
    } catch (error) {
      console.debug(error)
      router.replace({ name: 'bank-file-upload' })
    } finally {
      loading.value = false
    }
  } else {
    fetchPayingToOptions()
    fetchAllPayees()
  }

  fetchBalance()

  if (initialPayee.value) {
    onPayeeSelect(initialPayee.value)
  }
  if (initialPayingTo.value) {
    onPayeeFormUpdate(initialPayingTo.value)
  }
}

function cancelBatchOrder() {
  onCancelBatchOrder({
    onConfirm: () => {
      updateFlowType(FlowType.purchase)
    },
  })
}

init()

onBeforeUnmount(() => {
  hideSummaryModal()
  payment.value.submitting = false
})
</script>

<template>
  <div class="md:pb-8">
    <payments-make-v2-pure
      :payee="payee"
      :payee-form="payeeForm"
      :payee-form-fields="payeeFormFields"
      :min-amount-value="minAmountValue"
      :max-amount-value="maxAmountValue"
      :batch-order="batchOrder"
      :provider-title="providerTitle"
      :payees="groupedPayees"
      :payment-options="paymentOptions"
      :points-split-settings="pointsSplitSettings"
      :burn-points-rates="{
        payment: burnPointsRatePaymentOrder,
        statement: burnPointsRateStatementOrder,
      }"
      :points-balance="pointsBalance"
      :get-credit-cards="getCreditCards"
      :get-bank-accounts="getBankAccounts"
      :get-points-balance="getPointsBalance"
      :get-processing-fee="getProcessingFee"
      :loading="loading"
      :processing="processing"
      :processing-times="{
        title: processingTimes?.title ?? '',
        haveContent: !!currentProcessingTimesItem?.content,
      }"
      :on-add-new-payment-method="onAddNewPaymentMethod"
      :scheduled="scheduledParams"
      :on-terms-and-conditions="onTermsAndConditions"
      :calculate-points="calculatePoints"
      :pay="pay"
      :flow-type="currentFlowType"
      :hints="hints"
      :is-adding-new-payee-enabled="isAddingNewPayeeEnabled"
      :has-points-method="hasPointsMethod"
      :payment-status="{
        ready: paymentReady,
        loading: paymentLoading,
      }"
      :on-view-batch="onViewBatch"
      :on-remove-batch="cancelBatchOrder"
      :program-fees="programFees"
      :taxation="{ label: taxationLabel }"
      :when-to-pay-hint-message="whenToPayHintMessage"
      @processing-times="onProcessingTimes"
      @manage-payees="onManagePayees"
      @payee-select="onPayeeSelect"
      @payee-form-update="onPayeeFormUpdate"
      @payment-method-select="onPaymentMethodSelect"
      @points-to-use-change="onPointsToUseChange"
      @how-to-pay-select="onHowToPaySelect"
      @submit="onSubmit"
      @cancel="onCancel"
    />
  </div>
</template>
