<script>
import { onBeforeMount, onBeforeUnmount, ref } from 'vue'
import emitter from '/~/core/emitter'
import BaseInputAction from '/~/components/base/input-action/input-action.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.vue'
import { useUser } from '/~/composables/user'

export default {
  name: 'password-confirmation-form',
  components: {
    BaseInputAction,
    DrawerIconHeader,
  },
  setup() {
    const { user, updating } = useUser()
    const error = ref(null)

    onBeforeMount(() => {
      emitter.on('edit-profile-errors', onErrors)
    })
    onBeforeUnmount(() => {
      emitter.off('edit-profile-errors', onErrors)
    })

    function onErrors(errors) {
      error.value = errors?.currentPassword ?? null
    }

    return {
      user,
      updating,
      error,
    }
  },
  data() {
    return {
      currentPassword: '',
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.currentPassword)
    },
    onHide() {
      this.$emit('hide')
    },
  },
}
</script>

<template>
  <div>
    <drawer-icon-header
      title="Confirm password"
      icon="secure"
      :action="onHide"
    />

    <div class="px-5 pb-5">
      <div class="pb-10">
        Please enter your current password to update an email address.
      </div>

      <base-input-action
        ref="currentPassword"
        v-model="currentPassword"
        :error="error"
        name="currentPassword"
        btn-text="Confirm"
        label="Current Password"
        type="password"
        :loading="updating"
        :disabled="!currentPassword"
        :action="onSubmit"
      />
    </div>
  </div>
</template>
