import { ref } from 'vue'
import api from '/~/core/api'
import HealthArticle from '/~/extensions/health/core/article'
import HealthArticleProcessor from '/~/extensions/health/core/articles-processor'
import HealthCategory from '/~/extensions/health/core/category'
import Storage from '/~/utils/localStorage'
import { useUser } from '/~/composables/user'

const { user } = useUser()
const feed = ref(new HealthArticleProcessor())
const saved = ref(Storage.get(`${user.value.externalId}/saved_articles`) || [])
const categories = ref([])
const queryState = ref(null)

async function getCategories() {
  try {
    const data = await api.get(
      'https://eonxtn.wpengine.com/wp-json/wp/v2/categories?per_page=100'
    )
    const processedData = data?.map((cat) => new HealthCategory(cat)) ?? []

    categories.value = [
      new HealthCategory({ id: 0, name: 'All categories' }),
      ...processedData,
    ]
  } catch (error) {
    console.error(error)
  }
}

async function getSinglePost(postId) {
  try {
    const post = await api.get(
      `https://eonxtn.wpengine.com/wp-json/wp/v2/posts/${postId}`
    )

    return new HealthArticle(post)
  } catch (error) {
    console.error(error)
  }
}

function saveArticle(articleId) {
  const array = [...saved.value, articleId]

  Storage.set(`${user.value.externalId}/saved_articles`, JSON.stringify(array))
  saved.value = array
}

function removeArticle(articleId) {
  const array = saved.value.filter((id) => id !== articleId)

  Storage.set(`${user.value.externalId}/saved_articles`, JSON.stringify(array))
  saved.value = array
}

function isBookmarked(id) {
  return saved.value.includes(id)
}

function toggleBookmark(id) {
  if (isBookmarked(id)) {
    removeArticle(id)
  } else {
    saveArticle(id)
  }
}

export function useArticles() {
  return {
    feed,
    saved,
    categories,
    queryState,

    getCategories,
    getSinglePost,
    isBookmarked,
    toggleBookmark,
  }
}
