import app from './app'
import pages from './pages'
import widgets from './widgets'

export default function () {
  return {
    app: {
      ...app,
      menus: app.menus(),
    },
    pages,
    widgets,
  }
}
