import type { RouteConfig } from 'vue-router'
import type { CMSPageId } from '/~/types/cms'
import { RewardsExtension } from '/~/core/extension'
import { useDining } from './composables'

const { initDining, syncState, mapState } = useDining()

class DiningExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  async setState() {
    return initDining()
  }

  setRoutes() {
    return [
      {
        path: 'dining',
        component: () => import(`./templates/${this.templateName}/dining.vue`),
        async beforeEnter(to, from, next) {
          syncState({
            map: mapState.visible,
            ...to.query,
          })
          next()
        },
        children: [
          {
            path: 'restaurant/:slug',
            name: 'dining-item',
            component: () =>
              import(
                `./templates/${this.templateName}/views/details/dining-details.vue`
              ),
            props: true,
            meta: {
              title: 'Dining Details',
              theme: 'white',
              hideMenu: true,
            },
          },
          {
            path: ':category?',
            name: 'dining',
            component: () =>
              import(
                `./templates/${this.templateName}/views/catalog/dining-catalog.vue`
              ),
            meta: {
              title: 'Dining',
              page: 'dining' as CMSPageId,
            },
          },
        ],
      },
    ] as RouteConfig[]
  }
}

export default DiningExtension
