<script setup lang="ts">
import Daypicker from './daypicker.vue'
import dayjs from 'dayjs/esm'
import {
  computePosition,
  flip,
  offset,
  shift,
  Placement,
} from '@floating-ui/dom'
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    value: number | null
    activePeriod?: {
      from: number | null
      to: number | null
    }
    placement?: Placement
    hint?: string
  }>(),
  {
    placement: 'bottom-start',
    hint: '',
  }
)

const emit =
  defineEmits<(event: 'select', date: dayjs.Dayjs | number) => void>()

const mainRef = ref()
const referenceRef = ref()
const floatingRef = ref()
const isHidden = ref(true)

async function calculatePosition() {
  const { x, y } = await computePosition(
    referenceRef.value,
    floatingRef.value,
    {
      placement: props.placement,
      middleware: [offset(4), shift({ padding: 5 }), flip()],
    }
  )

  Object.assign(floatingRef.value.style, {
    left: `${x}px`,
    top: `${y}px`,
  })
}

function hide() {
  isHidden.value = true
}

function show() {
  isHidden.value = false
  calculatePosition()
}

function select(day: number) {
  emit('select', day)
  mainRef.value.blur()
}
</script>

<template>
  <div ref="mainRef" tabindex="0" class="group" @focus="show" @focusout="hide">
    <div ref="referenceRef">
      <slot />
    </div>
    <div
      ref="floatingRef"
      :class="['absolute top-0 left-0 z-50', isHidden && 'hidden']"
    >
      <daypicker
        v-bind="props"
        class="rounded-lg border bg-white shadow-md"
        @select="select"
      >
        <template #after-content>
          <div
            v-if="hint"
            class="mt-3 text-sm font-normal leading-tight text-gray-800"
          >
            {{ hint }}
          </div>
        </template>
      </daypicker>
    </div>
  </div>
</template>
