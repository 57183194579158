import activity from './activity.json'
import airlinePartners from './airline-partners.json'
import automotive from './automotive.json'
import benefitPocket from './benefit-pocket.json'
import cinema from './cinema.json'
import communityHub from './community-hub.json'
import competitions from './competitions.json'
import dining from './dining.json'
import eStore from './estore.json'
import giftCards from './gift-cards.json'
import healthEbitsFeed from './health-ebits-feed.json'
import healthLifestyleLibraryFeed from './health-lifestyle-library-feed.json'
import healthMindAndBodyType from './health-mind-and-body-type.json'
import healthProfessionalNetworkFeed from './health-professional-network-feed.json'
import home from './home.json'
import onlineOffers from './online-offers.json'
import preSales from './pre-sales.json'
import services from './services.json'
import signOut from './sign-out.json'
import ticketMaster from './ticket-master.json'
import travel from './travel.json'

export default {
  automotive,
  home,
  giftcards: giftCards,
  'online-offers': onlineOffers,
  estore: eStore,
  services,
  travel,
  dining,
  cinema,
  'community-hub': communityHub,
  competitions,
  'pre-sales': preSales,
  'hlth-mind-and-body-type': healthMindAndBodyType,
  'hlth-health-ebits-feed': healthEbitsFeed,
  'hlth-lifestyle-library-feed': healthLifestyleLibraryFeed,
  'hlth-professional-network-feed': healthProfessionalNetworkFeed,
  'benefit-pocket': benefitPocket,
  'airline-partners': airlinePartners,
  'ticket-master': ticketMaster,
  'sign-out': signOut,
  activity: activity,
}
