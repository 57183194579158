import modal from '/~/core/mdl'
import router from '/~/router'
import navigationController from '/~rec/utils/navigation-controller'

const routes = [
  {
    path: '/rec-room',
    component: () => import('./app.vue'),
    props: true,
    name: 'rec-room',
    redirect: { name: 'rec-feed', params: { feedId: 'feed' } },
    meta: {
      title: 'Rec Room',
    },
    children: [
      {
        name: 'rec-feed',
        path: 'news/:feedId?',
        props: true,
        redirect: { name: 'rec-feed-posts' },
        component: () => import('./views/news/news-root.vue'),
        children: [
          {
            name: 'rec-feed-posts',
            path: '',
            props: true,
            component: () => import('./views/news/news-feed.vue'),
          },
        ],
      },
      {
        name: 'rec-post',
        path: 'posts/:id',
        props: true,
        component: () => import('./views/post/post.vue'),
      },
      {
        name: 'rec-notifications',
        path: 'notifications',
        props: true,
        component: () => import('./views/notifications/notifications.vue'),
      },
      {
        name: 'rec-recognition',
        path: 'recognition',
        component: () => import('./views/recognition/recognition.vue'),
        redirect: { name: 'rec-awards' },
        children: [
          {
            name: 'rec-awards',
            path: 'awards',
            props: true,
            component: () => import('./views/recognition/awards/awards.vue'),
          },
          {
            name: 'rec-award-nominate-user',
            path: 'awards/available/nominate-user/:nomineeId',
            props: true,
            component: () =>
              import('./views/recognition/awards/award-nominate-user-root.vue'),
          },
          {
            name: 'rec-award-nominate',
            path: 'awards/:awardStatus/:awardId/nominate',
            props: true,
            component: () =>
              import('./views/recognition/awards/award-nominate-root.vue'),
          },
          {
            name: 'rec-award-nominee',
            path: 'awards/:awardStatus/:awardId/leaderboard/:nomineeId',
            props: true,
            component: () =>
              import('./views/recognition/awards/award-nominee-root.vue'),
          },
          {
            name: 'rec-award-nominee-finished',
            path: 'awards/:awardStatus/:awardId/leaderboard/:nomineeId/:currentAwardId',
            props: true,
            component: () =>
              import('./views/recognition/awards/award-nominee-root.vue'),
          },
          {
            name: 'rec-award',
            path: 'awards/:awardStatus/:awardId?/:winnerId?',
            props: true,
            component: () => import('./views/recognition/awards/award.vue'),
          },
          {
            name: 'rec-perks',
            path: 'perks',
            props: true,
            component: () => import('./views/recognition/perks/perks.vue'),
          },
          {
            name: 'rec-perk',
            path: 'perks/:perkStatus/:perkId?',
            component: () => import('./views/recognition/perks/perk.vue'),
          },
          {
            name: 'rec-hi5',
            path: 'hi5',
            props: true,
            component: () => import('./views/recognition/hi5/hi5s.vue'),
          },
          {
            name: 'rec-hi5-send-to',
            path: 'hi5/send-to/:sendToId',
            props: true,
            component: () =>
              import('./views/recognition/hi5/hi5-send-to-root.vue'),
          },
          {
            name: 'rec-hi5s-available',
            path: 'hi5/available/:hi5Id',
            props: true,
            component: () =>
              import('./views/recognition/hi5/hi5s-available-root.vue'),
          },
          {
            name: 'rec-hi5-details',
            path: 'hi5/:hi5Status/:hi5Id',
            props: true,
            component: () => import('./views/recognition/hi5/hi5.vue'),
          },
        ],
      },
      {
        name: 'rec-events',
        path: 'events',
        props: true,
        component: () => import('./views/events/events.vue'),
        redirect: { name: 'rec-events-feed' },
        children: [
          {
            name: 'rec-events-feed',
            path: 'feed/:feedId?/:date?',
            props: true,
            component: () => import('./views/events/events-feed.vue'),
          },
          {
            name: 'rec-event-create',
            path: 'create',
            props: true,
            component: () => import('./views/events/event/event-form-root.vue'),
          },
          {
            path: ':eventId',
            props: true,
            component: () => import('./views/events/event/event-root.vue'),
            children: [
              {
                name: 'rec-event-edit',
                path: 'edit',
                props: true,
                component: () =>
                  import('./views/events/event/event-form-root.vue'),
              },
              {
                name: 'rec-event',
                path: '',
                props: true,
                component: () =>
                  import('./views/events/event/event-details.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'groups',
        component: () => import('./views/groups/groups.vue'),
        props: true,
        children: [
          {
            name: 'rec-groups',
            path: '',
            props: true,
            component: () => import('./views/groups/groups-feed.vue'),
          },
          {
            name: 'rec-group-create',
            path: 'create',
            props: true,
            component: () => import('./views/groups/group/group-form-root.vue'),
          },
          {
            path: ':groupId',
            props: true,
            component: () => import('./views/groups/group/group-root.vue'),
            children: [
              {
                name: 'rec-group-edit',
                path: 'edit',
                props: true,
                component: () =>
                  import('./views/groups/group/group-form-root.vue'),
              },
              {
                name: 'rec-group',
                path: '',
                props: true,
                component: () =>
                  import('./views/groups/group/group-details.vue'),
              },
            ],
          },
        ],
      },
      {
        name: 'rec-chat',
        path: 'chat',
        meta: {
          hideBottomBar: true,
        },
        component: () => import('./views/chat/chat.vue'),
      },
      {
        name: 'rec-today',
        path: 'today',
        component: () => import('./views/directory/directory-root.vue'),
      },
      {
        name: 'rec-directory',
        path: 'directory',
        component: () => import('./views/directory/directory-root.vue'),
      },
      {
        path: '*',
        redirect: { name: 'rec-feed' },
      },
    ],
  },
  {
    path: '/rec-room-optus',
    component: () => import('./views/optus/rec-room-optus.vue'),
    props: true,
    name: 'rec-room-optus',
    meta: {
      title: 'Rec Room Optus',
    },
  },
]

routes.forEach((route) => {
  router.addRoute(route)
})

router.beforeEach((to, from, next) => {
  if (to.path.includes('rec-room')) {
    if (from.params.showConfirm) {
      modal.show('rec-leaving-confirm', {
        on: {
          'stay-here': () => {
            modal.hide()
          },
          'leave-page': () => {
            modal.hideAll()
            delete to.params.showConfirm

            navigationController(to, next)
          },
        },
      })
    } else {
      delete to.params.showConfirm
      navigationController(to, next)
    }
  } else {
    next()
  }
})

export default routes
