import { RecroomEvent } from '/~rec/core/event'
import { RecroomPost } from './post'
import get from 'lodash-es/get'

export class RecroomPostEvent extends RecroomPost {
  constructor(raw = {}) {
    super(raw)

    this.event = new RecroomEvent({
      ...get(this.raw, 'events.0'),
      ...this.raw.event,
    })
  }
}
