<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import AwardNominate from '/~rec/views/recognition/awards/award-nominate.vue'
import { RecroomAward } from '/~rec/core/award'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'award-nominate-root',
  components: {
    AwardNominate,
  },
  props: {
    awardId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { getMember } = useGroups()

    return {
      getMember,
      ui,
    }
  },
  data() {
    return {
      award: null,
    }
  },
  computed: {
    canBeNominated() {
      return this.award?.allowedUsers?.canBeNominated
    },
  },
  async created() {
    if (!ui.desktop) {
      this.openMobileForm()
      const award = this.$route.params.award

      if (award) {
        this.award = award
      } else {
        this.loading = true
        this.award = await RecroomAward.fetchById(this.awardId)
        await this.award.getAllowedUsers()
        this.loading = false
      }
    }
  },
  methods: {
    openMobileForm() {
      const userId = this.$route.query.nominateTo

      if (userId) {
        const selectedUser = this.getMember(userId, 'orgMemberId')

        this.selectUser(selectedUser)
      } else {
        modal.show('rec-users-manager', {
          props: {
            type: 'award',
            title: 'Pick a nominee',
            label: 'Nominate someone for this award!',
            loading: this.loading,
            filter: this.filterUsers,
            selectable: true,
          },
          on: {
            'user-select': this.selectUser,
            back: () => {
              this.$router.push({
                name: 'rec-award',
                params: this.$route.params,
              })
            },
          },
        })
      }
    },
    selectUser(user) {
      modal.show('rec-award-nominate-mobile', {
        props: {
          nominee: user,
          awardId: this.awardId,
        },
      })
    },
    filterUsers(allUsers) {
      return allUsers.filter((user) => this.canBeNominated?.includes(user.id))
    },
  },
}
</script>

<template>
  <award-nominate v-if="ui.desktop" :award-id="awardId" />
</template>
