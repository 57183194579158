<script>
import BaseImage from '/~/components/base/image/base-image.vue'
import { computed } from 'vue'
import { useProvider } from '/~/composables/provider'
import cdn, { getProviderDefaultUrl } from '/~/utils/cdn'

export default {
  name: 'community-hub-retailer-logo',
  components: {
    BaseImage,
  },
  props: {
    alt: {
      type: String,
      default: 'Retailer Logo',
    },
    image: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isBupaTemplate } = useProvider()

    const defaultImage = computed(() => {
      if (isBupaTemplate.value) {
        return getProviderDefaultUrl('bupa')
      }

      return 'https://i.ewalletcdn.com/45d490c5-96ca-43bc-99c6-621a63646755/'
    })
    const imageUrl = computed(() => cdn(props.image).resize('400x').url())

    return {
      imageUrl,
      defaultImage,
    }
  },
}
</script>

<template>
  <div class="mx-5 sm:mx-0">
    <div class="mx-auto my-2.5 block h-auto max-w-sm">
      <base-image
        :src="imageUrl"
        :default-src="defaultImage"
        :alt="alt"
        :ratio="1"
        shadow
      />
    </div>
  </div>
</template>
