<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import MenuPaymentTo from '/~/components/ewallet-menu/components/rows/menu-payment-to.vue'

export default {
  name: 'profile-payee-row',
  components: {
    MenuPaymentTo,
    BaseIcon,
  },
  props: {
    payee: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['action-clicked', 'click'],
  setup(props, { emit }) {
    function onActionClicked() {
      emit('action-clicked')
    }

    return {
      onActionClicked,
    }
  },
}
</script>

<template>
  <menu-payment-to
    :to="payee"
    :is-selected="isSelected"
    class="py-2.5 pl-2.5 pr-[5px]"
    @click="$emit('click')"
  >
    <template #action>
      <button
        type="button"
        class="flex items-center"
        @click.stop="onActionClicked"
      >
        <base-icon
          class="text-primary"
          svg="v2/custom/dots-vertical"
          :size="24"
        />
      </button>
    </template>
  </menu-payment-to>
</template>
