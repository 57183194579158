<script>
import PaymentMethodPlaceholder from './payments-method-placeholder.vue'

export default {
  name: 'payments-methods-block-v3',
  components: {
    PaymentMethodPlaceholder,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div class="mb-4 w-full">
    <div class="mb-4">
      <div
        class="flex items-center text-base font-bold leading-6 text-eonx-neutral-800 sm:text-xl"
      >
        <slot name="title">
          <span>
            {{ title }}
          </span>
        </slot>
      </div>
      <slot name="description" />
    </div>
    <div class="flex shrink-0 flex-col justify-center gap-5">
      <payment-method-placeholder v-if="loading" />
      <div v-show="!loading" class="flex flex-col gap-5">
        <slot />
      </div>
    </div>
  </div>
</template>
