<script>
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { useCheckoutReactive } from '/~/composables/checkout'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'confirmation-total',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    stickyTotal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isStatementCurrentFlowType } = useCheckoutReactive()
    const { taxationLabel } = useProvider()

    const fees = computed(() => props.order.fees?.items ?? [])
    const totalFees = computed(() => Number(props.order.fees?.total ?? 0))
    const shippingFees = computed(() => {
      return fees.value.find((i) => i.label === 'Shipping Fee') ?? {}
    })
    const programFees = computed(() => {
      return fees.value.find((i) => i.label === 'Program Fee') ?? {}
    })
    const programFeesAmount = computed(() => {
      return Number(programFees.value.amount ?? 0)
    })
    const programFeesPercentage = computed(() => {
      return Number(programFees.value.percentageFee ?? 0)
    })
    const programFeesLabel = computed(() => {
      return `Program Fee (${programFeesPercentage.value}% inc. ${taxationLabel.value})`
    })
    const processingFees = computed(() => {
      return fees.value.find((i) => i.label === 'Processing Fee') ?? {}
    })
    const processingFeesAmount = computed(() => {
      return Number(processingFees.value.amount ?? 0)
    })
    const processingFeesPercentage = computed(() => {
      return Number(processingFees.value.percentageFee ?? 0)
    })
    const processingFeesLabel = computed(() => {
      const label =
        processingFeesPercentage.value === 0
          ? `inc. ${taxationLabel.value}`
          : `${processingFeesPercentage.value}% inc. ${taxationLabel.value}`

      return `Processing Fee (${label})`
    })
    const notices = computed(() => {
      const notices = []

      const paymentMethods =
        (props.order.status === 'scheduled'
          ? props.order.scheduledPaymentMethods
          : props.order.paymentMethods) ?? []

      const payWithBankAccount = paymentMethods.some(
        (pm) => pm.type === PaymentMethodType.bankAccount
      )

      if (
        isStatementCurrentFlowType.value &&
        totalFees.value > 0 &&
        payWithBankAccount
      ) {
        notices.push('*Fees will be charged as a separate transaction.')
      }

      if (processingFeesAmount.value) {
        notices.push('*Processing Fee is charged on Subtotal plus Program Fee')
      }

      return notices
    })

    return {
      totalFees,
      shippingFees,
      programFees,
      programFeesAmount,
      programFeesLabel,
      processingFees,
      processingFeesAmount,
      processingFeesLabel,
      notices,
      formatDollar,
    }
  },
}
</script>

<template>
  <fragment>
    <div v-if="totalFees" class="text-sm text-eonx-neutral-600">
      <hr class="mb-5 hidden h-px w-full bg-gray-50 sm:block" />
      <div class="text-base font-bold text-eonx-neutral-800">Fees*</div>
      <div
        v-if="shippingFees && shippingFees.amount"
        class="mt-[5px] flex justify-between"
      >
        <div>Shipping Fee</div>
        <div class="pl-4">
          {{ formatDollar(shippingFees.amount) }}
        </div>
      </div>
      <div v-if="programFeesAmount" class="mt-[5px] flex justify-between">
        <div data-testid="program-fee">{{ programFeesLabel }}</div>
        <div class="pl-4">
          {{ formatDollar(programFeesAmount) }}
        </div>
      </div>
      <div v-if="processingFeesAmount" class="mt-[5px] flex justify-between">
        <div data-testid="processing-fee">{{ processingFeesLabel }}</div>
        <div class="pl-4">
          {{ formatDollar(processingFeesAmount) }}
        </div>
      </div>
    </div>
    <hr class="invisible my-5 h-px w-full sm:visible" />
    <div
      class="grid py-1 leading-tight text-eonx-neutral-800"
      :class="{
        'sticky top-0 z-1 -mx-5 bg-eonx-neutral-50 px-5 sm:bg-white':
          stickyTotal,
      }"
      style="grid-template-columns: auto auto"
    >
      <span class="text-xl font-bold sm:text-2xl">Total</span>
      <span class="text-right text-xl font-bold sm:text-2xl">
        {{ formatDollar(order.total) }}
      </span>
    </div>
    <div v-if="notices.length" class="mt-2.5 text-sm text-eonx-neutral-600">
      <div v-for="(notice, index) in notices" :key="index">
        {{ notice }}
      </div>
    </div>
  </fragment>
</template>
