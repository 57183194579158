class CmsSocket {
  constructor() {
    this.events = new Map()
    this.isReady = false

    this.init()
  }

  init() {
    if (window.addEventListener) {
      window.addEventListener('message', this.handler.bind(this), false)
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', this.handler.bind(this))
    }
  }

  handler(msg) {
    if (!msg.isTrusted) return

    try {
      let data = msg.data

      if (typeof msg.data === 'string' && /^(\[|\{)/.test(msg.data)) {
        data = JSON.parse(msg.data)
      }

      const handler = this.events.get(data.type)

      if (handler instanceof Function) {
        handler(data.payload)
      }
    } catch (error) {
      console.error(error)
    }
  }

  on(type, handler) {
    this.events.set(type, handler)
  }

  send(to, type, payload) {
    const message = JSON.stringify({
      to,
      type,
      payload,
    })

    window.parent.postMessage(message, '*')
  }
}

export const cmsSocket = new CmsSocket()
