<script>
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'retailer-product-add',
  components: {
    BaseButton,
  },
  props: {
    isAddButtonShow: {
      type: Boolean,
      default: true,
    },
    isQuickBuyButtonShow: {
      type: Boolean,
      default: false,
    },
    isQuickBuyDisabled: {
      type: Boolean,
      default: false,
    },
    isAddButtonDisabled: {
      type: Boolean,
      default: false,
    },
    adding: {
      type: Boolean,
      default: false,
    },
    physical: {
      type: Boolean,
      default: false,
    },
    justify: {
      type: String,
      default: 'center',
    },
    buttonsFixed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  methods: {
    onBuyNow() {
      this.$emit('click-add-to-quick-buy')
    },
    onAddToCart() {
      this.$emit('click-add-to-cart')
    },
  },
}
</script>

<template>
  <div
    class="flex space-x-2 px-5 sm:justify-end sm:px-0"
    :class="{
      'justify-start': justify === 'start',
      'justify-end': justify === 'end',
      'justify-center': justify === 'center',
      'justify-between': justify === 'between',
      'justify-around': justify === 'around',
      'justify-evenly': justify === 'evenly',
      'fixed bottom-0 z-popup w-full bg-white py-[15px] shadow-md sm:relative sm:z-auto sm:bg-transparent sm:py-0 sm:shadow-none':
        buttonsFixed,
    }"
  >
    <base-button
      v-if="isAddButtonShow"
      :disabled="isAddButtonDisabled"
      :loading="adding"
      :look="isQuickBuyButtonShow ? 'outlined-color' : 'filled'"
      class="flex-1 sm:flex-none"
      :full-width="(physical || !isQuickBuyButtonShow) && ui.mobile"
      data-cy="add-to-cart"
      data-testid="add-to-cart"
      @click="onAddToCart"
    >
      Add to cart
    </base-button>
    <base-button
      v-if="isQuickBuyButtonShow"
      :disabled="isQuickBuyDisabled"
      class="flex-1 sm:flex-none"
      data-cy="add-to-cart"
      data-testid="buy-now"
      @click="onBuyNow"
    >
      Buy Now
    </base-button>
  </div>
</template>
