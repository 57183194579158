<script>
import { computed, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import modal from '/~/core/mdl'
import { useGcCinemaMajor } from '/~/extensions/gift-cards-cinema/composables'
import ProductAdd from '/~/extensions/giftcards/components/product/add/product-add.vue'
import ProductHeader from '/~/extensions/giftcards/components/product/header/product-header.vue'
import ProductTotal from '/~/extensions/giftcards/components/product/total/product-total.vue'
import { useVerifications } from '/~/extensions/otp/composables'
import { formatDollar } from '/~/utils/format/money'
import BaseAlert from '/~/components/base/alert/base-alert.vue'
import BaseCounter from '/~/components/base/counter/base-counter.vue'
import RetailerGift from '/~/components/retailer/retailer-gift.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'gift-cards-cinema-location-major-summary',
  components: {
    RetailerGift,
    BaseAlert,
    ProductTotal,
    ProductHeader,
    BaseCounter,
    ProductAdd,
  },
  props: {
    showProductPrice: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { isGiftingEnabled, isQuickBuyEnabled, isAddToCartEnabled } =
      useProvider()
    const {
      gcCinemaMajorRetailer,
      gcCinemaMajorOffer,
      gcCinemaMajorProducts,
      gcCinemaMajorSelectedProducts,
      isGcCinemaMajorProcessing,
      gcCinemaMajorTitle,
      gcCinemaMajorOfferTitle,
      gcCinemaMajorTotal,
      isGcCinemaMajorDisabled,
      addGcCinemaMajorToCart,
    } = useGcCinemaMajor()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { withVerificationsCheck } = useVerifications()

    const retailerName = computed(
      () => gcCinemaMajorRetailer.value?.attributes?.name
    )

    const disclaimer = computed(
      () => gcCinemaMajorOffer.value?.attributes?.disclaimer
    )

    const terms = computed(
      () => gcCinemaMajorRetailer.value?.attributes?.terms_and_conditions
    )

    const gift = reactive({
      filled: false,
      visible: false,
    })
    const recipient = ref(null)

    const isSubmitDisabled = computed(() => {
      return isGcCinemaMajorDisabled.value || (gift.visible && !gift.filled)
    })

    const isQuickBuyButtonDisabled = computed(() => {
      return (
        isSubmitDisabled.value ||
        gcCinemaMajorSelectedProducts.value.length !== 1 ||
        gift.visible
      )
    })

    function showTerms() {
      modal.show('gift-cards-cinema-location-terms', {
        props: {
          title: retailerName.value,
          terms: terms.value ?? '',
        },
        on: {
          hide: () => router.replace({ hash: '' }),
        },
      })
    }

    function onBuyNow() {
      const product = gcCinemaMajorSelectedProducts.value[0]

      if (!product) {
        return
      }

      const query = {
        type: 'cinema',
        slug: gcCinemaMajorRetailer.value.attributes.slug,
        externalId: gcCinemaMajorOffer.value.id,
        productId: product.external_id,
        count: product.count,
        amount: gcCinemaMajorTotal.value,
      }

      router.push({ name: 'quick-buy-checkout', query })
    }

    watch(
      () => route.hash,
      (hash) => {
        if (/#terms-modal/.test(hash)) {
          showTerms()
        }
      }
    )

    return {
      isGiftingEnabled,
      isQuickBuyEnabled,
      isAddToCartEnabled,
      gcCinemaMajorRetailer,
      gcCinemaMajorOffer,
      gcCinemaMajorProducts,
      isGcCinemaMajorProcessing,
      gcCinemaMajorTitle,
      gcCinemaMajorOfferTitle,
      gcCinemaMajorTotal,
      isGcCinemaMajorDisabled,
      retailerName,
      addGcCinemaMajorToCart,
      formatDollar,
      disclaimer,
      onBuyNow,
      terms,
      backendErrors,
      processBackendErrors,
      gift,
      recipient,
      isSubmitDisabled,
      isQuickBuyButtonDisabled,
      withVerificationsCheck,
    }
  },
  computed: {
    productLabels() {
      return [
        this.gcCinemaMajorTitle,
        ...this.gcCinemaMajorProducts
          .filter((product) => product.count > 0)
          .map((product) => {
            return `${product.count} x ${product.name}`
          }),
      ]
    },
  },
  methods: {
    async onSubmit() {
      this.backendErrors = {}

      const isValid = this.isGiftingEnabled
        ? await this.$refs.retailerGift?.validate()
        : true

      if (isValid) {
        try {
          await this.addGcCinemaMajorToCart({
            giftRecipient:
              this.isGiftingEnabled && this.gift.visible && this.recipient,
          })

          if (this.isGiftingEnabled) {
            this.$refs.retailerGift?.reset()
          }
        } catch (exception) {
          this.processBackendErrors(exception.data || exception)
        }
      }
    },
  },
}
</script>

<template>
  <div class="relative w-full">
    <h3 class="mt-5 px-5 text-center md:mt-0 md:px-0 md:text-left">
      <b>{{ gcCinemaMajorOfferTitle }}</b>
    </h3>

    <product-header class="mt-2.5" :retailer="gcCinemaMajorRetailer" />

    <div
      class="mt-5 flex flex-col justify-around bg-light px-[30px] py-[15px] md:flex-row md:justify-between md:px-5"
    >
      <div v-if="gcCinemaMajorProducts" class="flex flex-col space-y-[15px]">
        <div
          v-for="(product, idx) in gcCinemaMajorProducts"
          :key="idx"
          class="flex items-center justify-evenly md:justify-start"
        >
          <div class="mr-2.5 flex w-[120px] items-center">
            <div class="capitalize">
              {{ product.display_label }}
            </div>
            <template v-if="showProductPrice">
              ({{ formatDollar(product.price) }})
            </template>
          </div>
          <base-counter
            v-model="product.count"
            :disabled="!product.in_stock || isGcCinemaMajorProcessing"
            :min="product.min"
            :max="product.max || 10"
          />
        </div>
      </div>
      <product-total
        :amount="gcCinemaMajorTotal"
        class="mt-2.5 md:mt-0 md:ml-5"
      />
    </div>

    <base-alert
      v-if="disclaimer"
      title="Disclaimer"
      type="warning"
      look="light-filled"
      class="mt-5"
    >
      <span class="markup text-justify" v-html="disclaimer" />
    </base-alert>

    <retailer-gift
      v-if="isGiftingEnabled"
      ref="retailerGift"
      v-model="recipient"
      :visible.sync="gift.visible"
      :filled.sync="gift.filled"
      :disabled="isGcCinemaMajorProcessing"
      :product-labels="productLabels"
      :backend-errors="backendErrors"
      class="my-6 px-5 sm:p-0"
    />
    <product-add
      :is-add-button-show="isAddToCartEnabled"
      :is-quick-buy-button-show="isQuickBuyEnabled"
      :is-quick-buy-disabled="isQuickBuyButtonDisabled"
      :is-add-button-disabled="isSubmitDisabled"
      :adding="isGcCinemaMajorProcessing"
      class="mb-2.5"
      @click-add-to-quick-buy="withVerificationsCheck(onBuyNow)"
      @click-add-to-cart="withVerificationsCheck(onSubmit)"
    />
  </div>
</template>
