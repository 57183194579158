<script setup lang="ts">
import { computed } from 'vue'
import {
  GRID_CLASSES,
  Row,
  Wrapper,
  ScreenSize,
  useWrapper,
} from '/~/configurator/composables/wrapper'

const props = defineProps<{
  row?: Row
  wrapper?: Wrapper
}>()

const { space, columnCounter } = useWrapper(props)

const gridClasses = computed(() => {
  const row = columnCounter.value || {}
  const classes: string[] = []

  Object.keys(row).forEach((key) => {
    const media = GRID_CLASSES[key as ScreenSize] || {}
    const gridClass = media[row[key as ScreenSize]]

    if (gridClass) {
      classes.push(gridClass)
    }
  })

  return classes
})
</script>

<template>
  <section
    class="grid"
    :class="[
      gridClasses,
      space ? 'gap-5 sm:gap-6' : 'overflow-hidden rounded-md shadow',
    ]"
  >
    <slot />
  </section>
</template>
