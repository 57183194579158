<script>
import { pluralize } from '/~/utils/format/string'
import { formatNumber } from '/~/utils/format/numeric'
import RenderBanner from '/~rec/components/render/render-banner.vue'

export default {
  name: 'rec-group-box',
  components: {
    RenderBanner,
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    altText: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      pluralize,
      formatNumber,
    }
  },
  computed: {
    clickable() {
      return !!this.$listeners.click
    },
  },
}
</script>

<template>
  <div
    v-bind="$attrs"
    class="relative overflow-hidden rounded-lg"
    v-on="$listeners"
  >
    <div class="overflow-hidden">
      <div class="relative flex flex-col">
        <div class="absolute top-0 bottom-0 h-full w-full">
          <render-banner
            :image="image"
            :ratio="false"
            rounded="rounded-none"
            class="absolute top-0 bottom-0 h-full w-full"
          />
        </div>
        <svg viewBox="0 0 16 10" />
        <svg viewBox="0 0 0 0" style="height: 24px" />
      </div>
    </div>
    <div
      class="items-between relative flex grow text-sm"
      :class="{
        'cursor-pointer': clickable,
      }"
    >
      <slot />
    </div>
    <slot name="join" />
  </div>
</template>
