<script>
import { ValidationObserver } from 'vee-validate'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms'
import { usePayees } from '/~/composables/payees'

export default {
  name: 'profile-payee-search-ewallet',
  components: {
    BaseInput,
    BaseButton,
    BaseIcon,
    ValidationObserver,
  },
  setup() {
    const { payees, createPayee, searchPayeeByEmail } = usePayees()
    const { isDarkThemeForEwallet, ewalletLabels } = useCms()
    const { validationObserverRef } = useForm()

    return {
      payees,
      createPayee,
      searchPayeeByEmail,
      isDarkThemeForEwallet,
      validationObserverRef,
      ewalletLabels,
    }
  },
  data() {
    return {
      searchQuery: '',
      submitting: false,
      backendErrors: {},

      ewalletReference: '',
      name: '',
      email: '',
    }
  },
  computed: {
    abbr() {
      const [name, surname = ''] = this.name.split(' ')

      return `${name.charAt(0)}${surname.charAt(0)}`.toUpperCase()
    },
    isLoading() {
      return this.submitting
    },
    isSubmitDisabled() {
      return !this.searchQuery.length || this.payees.creating
    },
  },
  methods: {
    async onSearchClick() {
      const searchQuery = this.searchQuery.trim()

      if (!searchQuery.length) {
        return
      }

      this.backendErrors = {}

      this.reset()
      this.submitting = true

      try {
        const { reference, name } = await this.searchPayeeByEmail(searchQuery)

        this.ewalletReference = reference
        this.name = name
        this.email = searchQuery
      } catch (error) {
        if ('email' in error) {
          this.backendErrors.email = error.email[0]
        }
      }

      this.submitting = false
    },
    reset() {
      this.ewalletReference = ''
      this.name = ''
      this.email = ''
    },
    async onEwalletClick() {
      if (this.payees.creating) {
        return
      }

      const { ewalletReference, name, email } = this

      const payload = {
        type: 'ewallet',
        ewalletReference,
        name,
        email,
      }

      try {
        const payee = await this.createPayee(payload)

        emitter.emit('menu:pay-anyone:select-to', payee)
        this.$router.replace({ hash: '#profile-pay-to' })
      } catch (error) {
        const errorObject = error.data || error

        this.$notify({
          text:
            errorObject.message ||
            errorObject.title ||
            'Something went wrong. Try again.',
          type: 'error',
          duration: 5000,
        })
      }
    },
    onCancelClick() {
      this.$router.back()
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <div class="flex h-full flex-col items-stretch justify-between">
      <div>
        <span
          class="mb-[35px] block text-center leading-relaxed text-eonx-neutral-600"
        >
          Enter payee’s email. Note that the user must be a member of this same
          platform.
        </span>

        <div class="flex space-x-2.5">
          <base-input
            v-model="searchQuery"
            :validation="{
              rules: 'required|email',
              name: 'Email',
            }"
            :error="backendErrors.email"
            :disabled="submitting || payees.creating"
            nolabel
            look="rounded"
            :icon-plain="true"
            name="email"
            placeholder="Search payee by email"
            @keydown.enter="handleSubmit(onSearchClick)"
          >
            <template #icon>
              <base-icon
                svg="plain/search"
                :size="16"
                class="ml-6 mr-2.5 text-eonx-neutral-600"
              />
            </template>
          </base-input>
        </div>

        <transition name="fade">
          <div
            v-if="name"
            class="my-10"
            :class="{
              'text-eonx-neutral-800': isDarkThemeForEwallet,
            }"
          >
            <div class="mb-2.5 font-bold text-eonx-neutral-600">
              Payee ({{ ewalletLabels.ewallet }} user)
            </div>

            <div
              class="flex items-center rounded-lg bg-gray-50 p-5"
              :class="[payees.creating ? 'animate-pulse' : 'cursor-pointer']"
              @click="onEwalletClick"
            >
              <div
                class="mr-5 flex h-11 w-11 items-center justify-center rounded-full bg-primary text-white"
              >
                <span v-if="abbr" class="text-lg font-bold">
                  {{ abbr }}
                </span>
                <base-icon v-else svg="ewallet" size="md" />
              </div>

              <div class="font-bold">
                {{ name }}
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div class="grid grid-cols-2 gap-5">
        <base-button
          look="outlined-color"
          class="w-full"
          :disabled="isLoading"
          @click="onCancelClick"
        >
          Cancel
        </base-button>
        <base-button
          class="w-full"
          :loading="isLoading"
          :disabled="isSubmitDisabled"
          @click="handleSubmit(onSearchClick)"
        >
          Search
        </base-button>
      </div>
    </div>
  </validation-observer>
</template>
