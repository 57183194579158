import { computed } from 'vue'
import { Tile } from '/~/composables/cms/use-cms'

export type Size = 1 | 2 | 3 | 4 | 5 | 6

export type ScreenSize = 'xs' | 'sm' | 'md'

export type Row = {
  [S in ScreenSize]?: Size
}

export type Wrapper = {
  space?: boolean
  reverse?: boolean
  row: Row
}

export type WrapperProps = {
  row?: Row
  wrapper?: Wrapper
}

export const GRID_CLASSES: {
  [S in ScreenSize]: {
    [K in Size]: string
  }
} = {
  xs: {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
  },
  sm: {
    1: 'sm:grid-cols-1',
    2: 'sm:grid-cols-2',
    3: 'sm:grid-cols-3',
    4: 'sm:grid-cols-4',
    5: 'sm:grid-cols-5',
    6: 'sm:grid-cols-6',
  },
  md: {
    1: 'md:grid-cols-1',
    2: 'md:grid-cols-2',
    3: 'md:grid-cols-3',
    4: 'md:grid-cols-4',
    5: 'md:grid-cols-5',
    6: 'md:grid-cols-6',
  },
}

export function useWrapper(props: WrapperProps) {
  const space = computed(() => props.wrapper?.['space'] ?? false)
  const reverse = computed(() => props.wrapper?.['reverse'] ?? false)

  const columnCounter = computed<{
    [S in ScreenSize]: Size
  }>(() => ({
    xs: props.row?.['xs'] || props.wrapper?.['row']['xs'] || 1,
    sm: props.row?.['sm'] || props.wrapper?.['row']['sm'] || 2,
    md: props.row?.['md'] || props.wrapper?.['row']['md'] || 3,
  }))

  const defaultWrapper = computed(() => ({
    ...props.wrapper,
    row: props.wrapper?.row ?? {},
  }))

  const getTileKey = (tile: Tile, idx: number | string) => {
    return (tile?.content?.text || 'tile') + String(idx)
  }

  return {
    space,
    reverse,
    columnCounter,
    defaultWrapper,

    getTileKey,
  }
}
