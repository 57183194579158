<script>
import { ValidationObserver } from 'vee-validate'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import { useForm } from '/~/composables/base/use-form'

export default {
  name: 'retailer-gift-digital',
  components: {
    BaseTextarea,
    BaseInput,
    ValidationObserver,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    backendErrors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { validationObserverRef } = useForm()

    return {
      validationObserverRef,
    }
  },
}
</script>

<template>
  <validation-observer ref="validationObserverRef" slim>
    <form
      class="grid grid-cols-1 gap-x-5 sm:grid-cols-2"
      data-cy="form"
      data-testid="gift-digital-form"
    >
      <base-input
        :validation="{
          rules: 'required',
          name: `Recipient's Name`,
          mode: 'aggressive',
          vid: 'name',
        }"
        :value="form.name"
        :error="backendErrors.name"
        name="name"
        label="Recipient's Name"
        required
        placeholder="John Smith"
        data-cy="gift-digital-input"
        :disabled="disabled"
        @input="$emit('name', $event)"
      />
      <base-input
        :validation="{
          rules: 'required|email',
          name: 'Recipient Email',
          mode: 'aggressive',
          vid: 'email',
        }"
        :value="form.email"
        :error="backendErrors.email"
        name="email"
        label="Recipient Email"
        required
        placeholder="name@example.com"
        data-cy="gift-digital-input"
        :disabled="disabled"
        @input="$emit('email', $event)"
      />
      <div class="sm:col-span-2">
        <base-textarea
          :value="form.message"
          :error="backendErrors.message"
          name="message"
          label="Message"
          placeholder="Message..."
          :rows="3"
          data-cy="gift-digital-input"
          :disabled="disabled"
          @input="$emit('message', $event)"
        />
      </div>
    </form>
  </validation-observer>
</template>
