<script>
import ui from '/~/core/ui'
import FilterSelect from '/~rec/components/section/section-filter-select.vue'
import SectionTabs from '/~rec/components/section/section-tabs.vue'

export default {
  name: 'rec-recognition-layout',
  components: {
    FilterSelect,
    SectionTabs,
  },
  props: {
    title: {
      type: String,
      required: true,
      validator: (v) => /Perks|Awards|Hi-5s|Groups/.test(v),
    },
    filterOptions: {
      type: Array,
      required: true,
    },
    selectedFilter: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['filter-selected'],
  setup() {
    return {
      ui,
    }
  },
  computed: {
    isDesktop() {
      return ui.desktop
    },
  },
  methods: {
    onSelectFilter(value) {
      this.$emit('filter-selected', value)
    },
  },
}
</script>

<template>
  <div class="w-full">
    <div
      class="mx-auto flex w-full max-w-screen-md flex-col bg-zinc-50 p-5 md:bg-transparent md:p-[30px] md:py-[30px]"
    >
      <div
        class="mb-0 flex flex-row items-baseline space-x-[15px] md:mb-6 md:items-center"
      >
        <h2 class="flex-auto text-lg text-eonx-neutral-800 md:text-2xl">
          {{ title }}
        </h2>

        <slot name="headerActions" />

        <filter-select
          v-if="!isDesktop"
          :filter-options="filterOptions"
          :selected-filter="selectedFilter"
          @input="onSelectFilter"
        />
      </div>

      <div v-if="isDesktop" class="flex shrink-0 overflow-x-auto">
        <div class="flex w-full space-x-[15px] lg:space-x-[30px]">
          <section-tabs
            class="min-w-[458px] text-eonx-neutral-800"
            look="recroom"
          />
        </div>
        <filter-select
          :filter-options="filterOptions"
          :selected-filter="selectedFilter"
          @input="onSelectFilter"
        />
      </div>
      <slot name="search" />

      <div class="flex grow flex-col pt-2.5 sm:pt-0">
        <slot />
      </div>
    </div>
    <slot name="fab" />
  </div>
</template>
