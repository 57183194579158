<script>
import ui from '/~/core/ui'
import UsersList from '/~rec/components/users/users-list.vue'
import { mixin as clickaway } from 'vue-clickaway'
import BaseInput from '/~/components/base/input/base-input.vue'
import debounce from 'lodash-es/debounce'
import UsersSelectedDesktop from '/~rec/components/users/users-selected-desktop.vue'

export default {
  name: 'user-selector',
  components: {
    UsersList,
    BaseInput,
    UsersSelectedDesktop,
  },
  mixins: [clickaway],
  props: {
    removable: {
      type: Boolean,
      default: true,
    },
    excludeMe: {
      type: Boolean,
      default: true,
    },
    filterUsers: {
      type: Function,
      default: (v) => v,
    },
    multi: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
    tooltipPosition: {
      type: String,
      default: 'top',
      validator: (v) => /bottom|top|right|left/.test(v),
    },
  },
  setup() {
    return {
      ...clickaway.setup?.(...arguments),
      ui,
    }
  },
  data() {
    return {
      selectedUser: null,
      selectedUsers: [],
      searchValue: '',
      showList: false,
    }
  },
  computed: {
    isShowSelectedBlock() {
      return this.selectedUsers?.length || this.selectedUser
    },
    isShowSelectorBlock() {
      return (
        (ui.desktop && this.removable && !this.selectedUser) ||
        (ui.mobile && this.users.length === 0 && this.user === null)
      )
    },
  },
  created() {
    if (this.user) {
      this.selectedUser = this.user
    } else if (this.users?.length > 0) {
      this.selectedUsers = this.users
    }
  },
  methods: {
    onUserRemove(user) {
      const idx = this.selectedUsers.indexOf(user)

      if (idx < 0) {
        this.selectedUsers.push(user)
      } else {
        this.selectedUsers.splice(idx, 1)
      }

      this.$emit('user-select', this.selectedUsers)
    },
    onUserSelect(u) {
      if (this.multi) {
        this.selectedUsers = u
      } else {
        this.selectedUser = u
        this.showList = false
      }

      this.$emit(
        'user-select',
        this.multi ? this.selectedUsers : this.selectedUser
      )
    },
    onClear() {
      this.selectedUser = null
      this.selectedUsers = []
      this.$emit('user-select', this.multi ? [] : null)
    },
    onSave() {
      this.$emit('save', this.selectedUsers)
    },
    onSearchInput: debounce(function (searchValue) {
      this.searchValue = searchValue
    }, 150),
    hideList() {
      this.showList = false
    },
  },
}
</script>

<template>
  <div class="relative w-full">
    <div
      v-if="isShowSelectedBlock"
      class="mb-2.5 rounded-xl bg-gray-50 p-[5px] xxs:p-[15px] md:bg-white"
    >
      <div class="relative flex w-full items-center">
        <users-selected-desktop
          :multi="multi"
          :selected-users="selectedUsers"
          :selected-user="selectedUser"
          :removable="removable"
          @remove="onUserRemove"
          @clear="onClear"
        />
      </div>
    </div>

    <template v-if="isShowSelectorBlock">
      <div
        v-on-clickaway="hideList"
        class="rec-user-selector relative"
        @click="showList = true"
      >
        <v-popover
          trigger="manual"
          offset="10"
          :open="showList"
          :placement="tooltipPosition"
          :container="false"
          :auto-hide="false"
          popover-class="w-full z-modal shadow-md rounded-md"
          popover-inner-class="w-full bg-white p-[5px] rounded-md"
        >
          <base-input
            :value="searchValue"
            style="padding: 0 !important; margin: 0 !important"
            look="recroom"
            icon="plain/search"
            placeholder="Search member"
            icon-plain
            clearable
            @input="onSearchInput"
          />
          <template #popover>
            <div class="z-20 max-h-56 w-full overflow-y-auto bg-white">
              <users-list
                :multi="multi"
                :exclude-me="excludeMe"
                :filter-users="filterUsers"
                :current-user="selectedUser"
                :current-users="selectedUsers"
                :search-value="searchValue"
                small
                @user-select="onUserSelect"
              />
            </div>
          </template>
        </v-popover>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.rec-user-selector {
  .trigger {
    width: 100% !important;
  }
}

.tooltip {
  @apply w-full max-w-md;
}
</style>
