<script setup lang="ts">
import WidgetTilesContent from './widget-bupa-tiles-content.vue'
import {
  GRID_CLASSES,
  Row,
  Wrapper,
  useWrapper,
} from '/~/configurator/composables/wrapper'
import { Content, Tile } from '/~/composables/cms/use-cms'
import { computed } from 'vue'
import ui from '/~/core/ui'

const props = withDefaults(
  defineProps<{
    row?: Row
    wrapper?: Wrapper
    items: Tile[]
    enableWidgetTitle?: boolean
    content: Content
    titleColor?: string
  }>(),
  {
    enableWidgetTitle: false,
    titleColor: '#000000',
  }
)

const { columnCounter, getTileKey } = useWrapper(props)

const screenSize = computed(() => {
  if (ui.width >= 1124) {
    return 'md'
  } else if (ui.width < 1124 && ui.width > 700) {
    return 'sm'
  } else {
    return 'xs'
  }
})

const columns = computed(() => columnCounter.value[screenSize.value])
</script>

<template>
  <section>
    <div
      v-if="enableWidgetTitle"
      class="mb-[30px] pt-2.5 text-xl font-bold leading-6"
      :style="{
        textAlign: content.contentAlignmentX,
        color: titleColor,
      }"
      v-html="content.title"
    />
    <div class="grid gap-[15px]" :class="GRID_CLASSES.md[columns]">
      <div
        v-for="(tile, idx) in items"
        :key="getTileKey(tile, idx)"
        class="w-full overflow-hidden rounded bg-light shadow"
      >
        <widget-tiles-content
          :tile="tile"
          :screen-size="screenSize"
          :columns="columns"
        />
      </div>
    </div>
  </section>
</template>
