import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomHi5 } from '/~rec/core/hi5'
import { RecroomHi5Reason } from '/~rec/core/hi5-reason'
import { reactive } from 'vue'

const avalableFilters = {
  recent: {
    'order[createdAt]': 'desc',
  },
  popular: {
    'order[totalSent]': 'desc',
  },
  asc: {
    'order[content]': 'asc',
  },
}

const restFilters = {
  recent: {
    'order[createdAt]': 'desc',
  },
  asc: {
    'order[reasonContent]': 'asc',
  },
}

const state = reactive({
  available: new EntityProcessor({
    entity: 'gamification/high-five-reasons',
    mapping: (record) => new RecroomHi5Reason(record),
    filters: avalableFilters,
  }),
  sent: new EntityProcessor({
    entity: 'gamification/high-fives',
    mapping: (record) => new RecroomHi5(record),
    filters: restFilters,
  }),
  received: new EntityProcessor({
    entity: 'gamification/high-fives',
    mapping: (record) => new RecroomHi5(record),
    filters: restFilters,
  }),
})

export const useHi5 = () => ({
  hi5: state,
})
