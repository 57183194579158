<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useCms } from '/~/composables/cms'
import { useUI } from '/~/composables/ui'
import MarketplaceMenu from '/~/layouts/main/components/marketplace-menu.vue'
import { useMarketplaceMenu } from '/~/layouts/main/components/use-marketplace-menu'
import LayoutRounded from '/~/layouts/rounded/layout-rounded.vue'
import DesktopFooter from './desktop-footer.vue'
import DesktopSideMenu from './desktop-side-menu.vue'
import DesktopToolbar from './desktop-toolbar.vue'

const route = useRoute()

const hasToolbar = computed<boolean>(() => route.meta?.toolbar ?? true)
const { isLeftMenuAppearanceSidebar, isLeftMenuAppearanceHidden } = useCms()
const { headerHeight } = useUI()
const { hideMenu } = useMarketplaceMenu()

const layoutRoundedExtensions = [
  'gift-cards',
  'dining',
  'online-offers',
  'estore',
  'travel',
  'gift-cards-cinema',
  'community-hub',
  'competitions',
  'pre-sales',
]
const isContentLayoutRounded = computed(() => {
  return layoutRoundedExtensions.includes(route.meta?.extName)
})
</script>

<template>
  <main
    class="flex h-full w-full grow flex-col overflow-y-auto md:pt-0"
    :style="{ paddingTop: `${headerHeight}px` }"
  >
    <div class="grow bg-dark" />
    <div class="flex h-full w-full max-w-full grow overflow-hidden">
      <desktop-toolbar v-if="hasToolbar" />
      <div class="h-full w-full overflow-x-hidden px-4 pb-1 sm:px-0">
        <desktop-side-menu
          :class="{
            'flex flex-col': !isLeftMenuAppearanceSidebar,
          }"
        >
          <div
            v-if="!isContentLayoutRounded && !hideMenu"
            class="px-5 pt-3"
            :class="{
              'md:hidden': isLeftMenuAppearanceSidebar,
              hidden: isLeftMenuAppearanceHidden,
            }"
          >
            <marketplace-menu />
          </div>
          <h2 id="main-content" class="sr-only" tabindex="-1">Main content</h2>
          <layout-rounded v-if="isContentLayoutRounded">
            <div
              v-if="!isLeftMenuAppearanceHidden && !hideMenu"
              class="px-5 pt-3"
              :class="{
                'md:hidden': isLeftMenuAppearanceSidebar,
              }"
            >
              <marketplace-menu />
            </div>
            <slot />
          </layout-rounded>
          <slot v-else />
        </desktop-side-menu>
      </div>
      <portal-target
        name="right-side"
        slim
        class="mr-4 ml-3 flex flex-col overflow-y-auto"
      />
    </div>
    <desktop-footer />
  </main>
</template>
