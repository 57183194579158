import { RecroomUser } from './user'
export class RecroomAwardNominee {
  constructor(raw = {}) {
    this.raw = raw || {}

    this.user = new RecroomUser(raw.user)
  }

  get rank() {
    return this.raw.rank
  }

  get nominationsCount() {
    return this.raw.nominations_count
  }

  get organisationMemberId() {
    return this.raw.organisation_member_id
  }
}
