<script>
import { nextTick } from 'vue'
import ui from '/~/core/ui'
import EventsMonth from '/~rec/components/event/feed/events-month.vue'
import EventsMonthEmpty from '/~rec/components/event/feed/events-month-empty.vue'
import EventsSeparator from '/~rec/components/event/feed/events-separator.vue'
import FeedHeader from '/~rec/components/event/feed/feed-header.vue'
import { createDate } from '/~/utils/format/date'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'
import { useCelebrations } from '/~rec/composables/celebrations'
import { useEvents } from '/~rec/composables/events'
import { useLocalization } from '/~/composables/localization'

export default {
  name: 'recroom-events-feed-view',
  components: {
    FeedHeader,
    EventsSeparator,
    EventsMonth,
    EventsMonthEmpty,
    BasePlaceholder,
    BaseLoader,
    RecBaseState,
  },
  props: {
    feedId: {
      type: String,
      default: '',
    },
    disableAnimation: {
      type: Boolean,
      default: false,
    },
    scrollPosition: {
      type: Number,
      default: null,
    },
    eventId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { celebrations } = useCelebrations()
    const { events, setFeed, resetEvents } = useEvents()
    const { getDatePickerFormat } = useLocalization()

    return {
      celebrations,
      events,
      setFeed,
      resetEvents,
      ui,
      getDatePickerFormat,
    }
  },
  computed: {
    isCelebrations() {
      return this.feedId === 'celebrations'
    },
    isHosting() {
      return this.feedId === 'hosting'
    },
    isAttending() {
      return this.feedId === 'attending'
    },
    isAllEvents() {
      return this.feedId === 'all'
    },
    showFeedPlaceholder() {
      let showFeedPlaceholder = false

      if (this.activities.processing && this.activities.isEmpty) {
        showFeedPlaceholder = true
      } else {
        showFeedPlaceholder = false
      }
      return showFeedPlaceholder
    },
    showFeedLoader() {
      if (
        !this.isCelebrations &&
        this.events?.feed &&
        !this.events.feed.stopAppend
      ) {
        return true
      } else {
        return false
      }
    },
    showFeedEmptyState() {
      let showFeedEmptyState = false

      if (!this.activities.processing && !this.activities.months.length) {
        showFeedEmptyState = true
      } else {
        showFeedEmptyState = false
      }
      return showFeedEmptyState
    },
    selectedDate() {
      return this.isCelebrations
        ? this.celebrations?.selectedDate
        : this.events?.selectedDate
    },
    activities() {
      return this.isCelebrations ? this.celebrations?.feed : this.events?.feed
    },
    // Get list with missed months
    months() {
      if (this.activities?.months?.length) {
        const dates = []
        const { months } = this.activities
        const startDate = months[0].date
        const endDate = months[months.length - 1].date

        let month = startDate.clone()

        // Get list of available dates
        while (month < endDate) {
          dates.push({
            monthYear: month.format('YYYY-MM'),
            date: month.clone(),
          })
          month = month.add(1, 'month')
        }

        dates.push({
          monthYear: month.format('YYYY-MM'),
          date: month.clone(),
        })

        // Add 'yearMonth' to events list
        const eventsByMonths = []

        months.forEach((item) => {
          const yearMonth = item.date.format('YYYY-MM')

          eventsByMonths[yearMonth] = item
        })

        const newDates = dates.reduce((arr, item) => {
          const newItem = eventsByMonths[item.monthYear]
            ? eventsByMonths[item.monthYear]
            : { date: item.date }

          return arr.concat(newItem)
        }, [])

        return newDates
      }

      return []
    },
  },
  watch: {
    '$route.query': {
      handler: function (query) {
        if (query.date && query.date !== this.selectedDate) {
          this.changeDate(query.date)
        }
        this.scrollToEventItem()
      },
      immediate: true,
    },
    feedId() {
      if (!ui.desktop && this.disableAnimation) return
      this.loadFeed().then(() => {
        this.scrollToEventItem()
      })
    },
    async selectedDate(newDate) {
      const { query, name, params } = this.$route

      if (query.date && query.date === newDate) {
        await this.loadDataFeed()
        return
      }
      this.$router.replace({
        ...name,
        params,
      })
      await this.loadDataFeed()
    },
  },
  async mounted() {
    this.resetEvents()
    await this.loadFeed()
    this.scrollToEventItem()
  },
  methods: {
    async loadDataFeed() {
      await this.findSelectedDateElement()
      await this.loadFeed()
    },
    async loadFeed() {
      if (this.isCelebrations) {
        await this.celebrations?.feed?.getData(
          createDate(
            this.selectedDate,
            this.getDatePickerFormat('daymonthyearnumeric')
          )
        )
      } else {
        this.setFeed(this.feedId)
        await this.events.feed.getData(
          createDate(
            this.selectedDate,
            this.getDatePickerFormat('daymonthyearnumeric')
          )
        )
      }
      await this.scrollToSelectedDate()
    },
    async findSelectedDateElement() {
      await nextTick()
      return (
        this.$el.querySelector(`[data-day="${this.selectedDate}"]`) ||
        this.$el.querySelector(
          `[data-month="${createDate(
            this.selectedDate,
            this.getDatePickerFormat('daymonthyearnumeric')
          ).format('YYYY-MM')}"]`
        )
      )
    },
    async scrollToSelectedDate() {
      if (ui.desktop) {
        const dateElement = await this.findSelectedDateElement()

        if (dateElement) {
          dateElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    },
    async scrollToEventItem() {
      await nextTick()
      const eventId = this.$route.query?.eventId
      const element =
        eventId && this.$el.querySelector(`[data-id="${eventId}"]`)

      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    },
    onAppend(isVisible) {
      if (isVisible) {
        if (this.isCelebrations) {
          this.celebrations?.feed.next()
        } else if (!this.activities.processing) {
          this.events.feed.next()
        }
      }
    },
    changeDate(newDate) {
      if (this.isCelebrations) {
        this.celebrations.selectedDate = newDate
      } else {
        this.events.selectedDate = newDate
      }
    },
  },
}
</script>
<template>
  <div
    v-if="activities"
    class="mx-auto flex w-full max-w-screen-md flex-col bg-zinc-50 md:bg-transparent md:p-[30px]"
  >
    <feed-header
      :disable-animation="disableAnimation"
      :is-celebrations="isCelebrations"
      :selected-date="selectedDate"
      @new-date="changeDate"
    />
    <div
      id="events-wrapper"
      class="w-full overflow-y-auto overflow-x-hidden pt-5 md:pt-0"
      :class="{
        'flex grow': !activities.months.length,
        'items-center': showFeedEmptyState,
        'items-start': !showFeedEmptyState,
      }"
    >
      <div
        v-if="showFeedPlaceholder"
        class="mx-auto flex w-full max-w-screen-md grow flex-col pb-2.5 md:pt-2.5"
      >
        <base-placeholder :columns="1" :height="120" />
      </div>
      <!-- All events -->
      <rec-base-state
        v-if="showFeedEmptyState && isAllEvents"
        class="my-[30px] w-full"
        image="recroom/state/calendar-empty-state.svg"
        title="Let’s create some events!"
        subtitle="You can create or join events to share with others"
      />
      <!-- I'm attending -->
      <rec-base-state
        v-if="showFeedEmptyState && isAttending"
        class="my-[30px] w-full"
        image="recroom/state/calendar-empty-state.svg"
        title="Not attending any events yet"
        subtitle="You can create or join events to share with others"
      />
      <!-- I'm hosting -->
      <rec-base-state
        v-if="showFeedEmptyState && isHosting"
        class="my-[30px] w-full"
        image="recroom/state/calendar-empty-state.svg"
        title="Not hosting any events yet"
        subtitle="Create an event you wish to have others attend"
      />
      <!-- Celebrations -->
      <rec-base-state
        v-if="showFeedEmptyState && isCelebrations"
        class="my-[30px] w-full"
        image="recroom/state/state-celebrations-empty.svg"
        title="Celebrate the good times!"
        subtitle="Never miss an important celebration"
      />
      <template v-else>
        <div v-for="(month, idx) in months" :key="month.key + '_' + idx">
          <events-separator
            v-if="idx && idx !== months.length - 1"
            :month="month"
          />
          <events-month
            v-if="month.events || isCelebrations"
            :month="month"
            :is-celebrations="isCelebrations"
          />
          <events-month-empty
            v-if="
              !(month.events || isCelebrations) && idx !== months.length - 1
            "
          />
        </div>
        <base-loader
          v-show="showFeedLoader && !showFeedPlaceholder"
          v-observe-visibility="onAppend"
          class="flex h-24 w-full"
        />
      </template>
    </div>
  </div>
</template>
