import { nanoid } from 'nanoid'

export default {
  label: 'Default',
  value: 'default',
  preset: {
    items: [
      {
        id: nanoid(),
        enabled: true,
        label: 'Home',
        action: {
          type: 'page',
          value: 'home',
        },
        icon: 'plain/home',
      },
      {
        id: nanoid(),
        enabled: false,
        label: 'Rec Room',
        action: {
          type: 'page',
          value: 'rec-feed',
        },
        icon: 'plain/message-bubble',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Gift Cards',
        action: {
          type: 'page',
          value: 'giftcards',
        },
        icon: 'circle/gift-cards',
      },
      {
        id: nanoid(),
        enabled: false,
        label: 'Marketplace',
        action: {
          type: 'action',
          value: 'show-marketplace',
        },
        icon: 'plain/marketplace',
        children: [
          {
            id: nanoid(),
            enabled: true,
            label: 'Gift Cards',
            action: {
              type: 'page',
              value: 'giftcards',
            },
            icon: 'circle/gift-cards',
          },
          {
            id: nanoid(),
            enabled: false,
            label: 'Online Offers',
            action: {
              type: 'page',
              value: 'online-offers',
            },
            icon: 'circle/online-offers',
          },
          {
            id: nanoid(),
            enabled: false,
            label: 'eStore',
            action: {
              type: 'page',
              value: 'estore',
            },
            icon: 'circle/estore',
          },
          {
            id: nanoid(),
            enabled: false,
            label: 'Services',
            action: {
              type: 'page',
              value: 'services',
            },
            icon: 'circle/services',
          },
          {
            id: nanoid(),
            enabled: false,
            label: 'Travel',
            action: {
              type: 'page',
              value: 'travel',
            },
            icon: 'circle/travel',
          },
          {
            id: nanoid(),
            enabled: false,
            label: 'Dining',
            action: {
              type: 'page',
              value: 'dining',
            },
            icon: 'circle/dining',
          },
          {
            id: nanoid(),
            enabled: false,
            label: 'Experiences',
            action: {
              type: 'link',
              value: 'https://loyaltycorp.experienceoz.com.au/en',
            },
            icon: 'circle/experiences',
          },
          {
            id: nanoid(),
            enabled: false,
            label: 'Cinema',
            action: {
              type: 'page',
              value: 'cinema',
            },
            icon: 'circle/cinema',
          },
        ],
      },
      {
        id: nanoid(),
        enabled: false,
        label: 'Make a Payment',
        action: {
          type: 'page',
          value: 'new-payment',
        },
        icon: 'plain/coin',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Show cart',
        action: {
          type: 'action',
          value: 'show-cart',
        },
        icon: 'plain/cart',
      },
    ],
  },
}
