<script>
import get from 'lodash-es/get'
import MajorSummary from '/~/extensions/gift-cards-cinema/components/major-summary.vue'
import { useGcCinemaMajor } from '/~/extensions/gift-cards-cinema/composables'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import RetailerDetails from '/~/components/retailer/retailer-details.vue'
import RetailerLogo from '/~/components/retailer/retailer-logo.vue'

export default {
  name: 'gift-cards-cinema-location-major',
  components: {
    EwDetailsLayout,
    RetailerLogo,
    RetailerDetails,
    MajorSummary,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      gcCinemaMajorRetailer,
      gcCinemaMajorOffer,
      gcCinemaMajorTitle,
      initGcCinemaMajor,
    } = useGcCinemaMajor()

    return {
      gcCinemaMajorRetailer,
      gcCinemaMajorOffer,
      gcCinemaMajorTitle,
      initGcCinemaMajor,
    }
  },
  watch: {
    slug(slug) {
      this.initGcCinemaMajor(slug)
    },
  },
  async created() {
    await this.initGcCinemaMajor(this.slug)
    this.$emit('loaded', get(this.gcCinemaMajorRetailer, 'attributes.name'))
  },
}
</script>

<template>
  <ew-details-layout
    :loading="!gcCinemaMajorRetailer"
    :ready="true"
    :title="gcCinemaMajorTitle"
    :back="{ name: 'cinema' }"
    :class="!gcCinemaMajorRetailer && 'px-5'"
  >
    <template #gallery>
      <retailer-logo :retailer="gcCinemaMajorRetailer" class="mt-5 sm:mt-0" />
    </template>

    <template #details>
      <retailer-details
        :offer="gcCinemaMajorOffer"
        :retailer="gcCinemaMajorRetailer"
        class="px-5"
      />
    </template>

    <template #summary>
      <major-summary />
    </template>
  </ew-details-layout>
</template>
