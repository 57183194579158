<script>
import { computed, defineComponent, ref } from 'vue'
import modal from '/~/core/mdl'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import { useVerifications } from '/~/extensions/otp/composables'
import { formatDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import CommHubOfferVideo from './community-hub-offer-video.vue'

export default defineComponent({
  name: 'community-hub-retailer-offer-video',
  components: {
    BaseButton,
    CommHubOfferVideo,
    BaseLink,
    BaseIcon,
  },
  props: {
    offer: {
      type: Object,
      default: () => ({}),
    },
    featured: {
      type: Boolean,
      default: false,
    },
    retailer: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const {
      redeemOffer: commhubRedeemOffer,
      fetchRedeemedCoupons,
      fetchOffer,
    } = useCommunityHub()
    const {
      withVerificationsCheck,
      hasUnfinishedVerifications,
      showVerificationModal,
    } = useVerifications()

    const showFullDescription = ref(false)

    const isOfferTypeUrl = computed(() => props.offer.type === 'url')
    // html offers doesn't have a coupon code, so assuming description provided in the offer description itself
    const isOfferTypeHtml = computed(() => props.offer.type === 'html')

    if (isOfferTypeHtml.value) {
      console.warn("HTML offers lack a coupon code and aren't implemented yet.")
    }
    const expires = computed(() => {
      if (props.offer.endsAt) {
        const dateFormatted = formatDate('daymonthyear', props.offer.endsAt)

        return `Expires: ${dateFormatted}`
      } else {
        return null
      }
    })

    function redeemOffer() {
      try {
        commhubRedeemOffer(props.offer.id)
      } catch (error) {
        console.error('community-hub', error)
      }
    }

    async function showModalWithTrack() {
      modal.show('community-hub-offer', {
        props: {
          offer: props.offer,
          redeem: commhubRedeemOffer,
          getRedeemedCoupons: fetchRedeemedCoupons,
          fetchOffer,
        },
      })
    }

    function showTermsModal() {
      modal.show('community-hub-offer-terms', {
        props: {
          terms: props.offer.terms,
        },
      })
    }

    const offerDescription = computed(() =>
      props.offer.description.replace(
        /<table\b[^>]*>[\s\S]*?<\/table>/gi,
        (match) => '<div style="overflow-x: auto">' + match + '</div>'
      )
    )

    function withVerificationsCheckToggle(e) {
      if (hasUnfinishedVerifications.value) {
        e.preventDefault()

        showVerificationModal()
      } else {
        redeemOffer()
      }
    }

    return {
      showFullDescription,
      isOfferTypeUrl,
      isOfferTypeHtml,
      expires,
      showModalWithTrack,
      redeemOffer,
      showTermsModal,
      offerDescription,
      hasUnfinishedVerifications,
      withVerificationsCheck,
      withVerificationsCheckToggle,
    }
  },
})
</script>

<template>
  <div
    v-if="offer"
    class="rounded-lg border bg-white p-2.5 pb-0 text-base xs:pb-2.5 sm:border-none"
  >
    <div class="flex flex-col">
      <div class="p-3">
        <div v-if="offer.locations && offer.locations.length">
          <div v-for="(location, index) in offer.locations" :key="index">
            <a
              :href="`http://maps.apple.com/?q=${location.name}`"
              class="mb-[5px] inline-flex items-center text-sm text-eonx-neutral-800 opacity-75 hover:opacity-100"
            >
              <base-icon
                svg="plain/marker"
                class="mr-2.5 text-eonx-neutral-800"
                :size="16"
              />
              <span>{{ location.name }}</span>
            </a>
          </div>
        </div>
        <div
          class="flex flex-col justify-between gap-5 xs:flex-row xs:items-center"
        >
          <div>
            <div
              v-if="!isOfferTypeHtml"
              class="text-eonx-neutral-800"
              :class="{ 'mb-5 text-2xl': featured }"
            >
              <b>{{ offer.name }}</b>
            </div>

            <div
              v-if="offer.terms"
              class="mt-2.5 cursor-pointer text-base font-bold leading-7 text-primary underline opacity-75 hover:no-underline hover:opacity-100"
              @click="showTermsModal"
            >
              See offer Terms & Conditions
            </div>
          </div>

          <div
            v-if="!isOfferTypeHtml"
            class="flex h-12 cursor-pointer items-center justify-center whitespace-nowrap rounded-md bg-primary px-6 py-2.5 text-sm font-bold"
          >
            <template v-if="isOfferTypeUrl">
              <base-link
                v-if="offer.redirectPage && !hasUnfinishedVerifications"
                class="flex h-full w-full items-center justify-center text-white"
                color="none"
                :to="{
                  name: `${$ext.name}-redirect`,
                  params: {
                    promotionUrl: offer.promotionUrl,
                    content: offer.redirectContent,
                    offer: offer.id,
                    retailer,
                  },
                }"
              >
                Redeem Now
              </base-link>

              <base-link
                v-else
                class="flex h-full w-full items-center justify-center text-white"
                color="none"
                :href="offer.promotionUrl"
                @click="withVerificationsCheckToggle"
              >
                Redeem Now
              </base-link>
            </template>

            <template v-else-if="!isOfferTypeHtml">
              <div
                class="flex h-full w-full items-center justify-center text-white"
                @click="withVerificationsCheck(showModalWithTrack)"
              >
                Redeem Now
              </div>
            </template>
          </div>
        </div>
        <hr class="my-6 border-eonx-neutral-200" />
        <div
          v-if="offerDescription"
          class="z-0 mt-[5px] overflow-hidden"
          :class="{ 'h-11': !showFullDescription }"
        >
          <div class="markup text-eonx-neutral-800" v-html="offerDescription" />
        </div>
        <comm-hub-offer-video
          v-if="offer.videoUrl"
          :url="offer.videoUrl"
          :class="{ hidden: !showFullDescription }"
          class="mt-[15px]"
          preview
        />
        <div
          class="z-1 -mt-6 h-6 bg-white"
          :class="{ hidden: showFullDescription }"
          style="filter: blur(0.6rem)"
        />
        <div class="z-1 mt-2.5">
          <base-button
            look="link"
            @click="showFullDescription = !showFullDescription"
          >
            <span class="flex items-center">
              <span class="pb-0.5">
                {{ showFullDescription ? 'Show less' : 'Show more' }}
              </span>
              <base-icon
                svg="plain/chevron-bottom"
                :size="14"
                class="ml-[5px]"
                :class="{
                  'rotate-180 transform': showFullDescription,
                }"
              />
            </span>
          </base-button>
        </div>
        <div v-if="expires" class="mb-[5px] text-eonx-neutral-600">
          {{ expires }}
        </div>
      </div>
    </div>
  </div>
</template>
