<script>
import { computed } from 'vue'
import modal from '/~/core/mdl'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useLocalization } from '/~/composables/localization'

export default {
  name: 'profile-pay-cancel-transaction',
  components: {
    BaseIcon,
    BaseMdl,
  },
  props: {
    payee: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { translate } = useLocalization()

    const type = computed(() => props.payee.type)
    const firstRowText = computed(() => props.payee.name)

    const secondRowText = computed(() => {
      const {
        email,
        billerCode,
        bsb = '',
        accountNumber = '',
        id,
      } = props.payee

      switch (type.value) {
        case 'ewallet':
          return email
        case 'bpay':
          return billerCode
        case 'bankAccount':
          return translate('bankAccount.details', {
            acc: `*** ${accountNumber.slice(-3)}`,
            bsb: bsb ? `*** ${bsb.slice(-3)}` : undefined,
          })
        default:
          return id
      }
    })

    const actions = computed(() => {
      return [
        {
          label: 'Edit Payee',
          icon: {
            svg: 'v2/heroic/pencil',
            size: 24,
          },
          onClick: () => {
            modal.show('edit-payee', {
              props: {
                payee: props.payee,
              },
            })
          },
          isHidden: props.payee.type === 'ewallet',
        },
        {
          label: 'Delete Payee',
          icon: {
            svg: 'v2/box-icons/bx-delete',
            size: 24,
          },
          onClick: () => {
            modal.show('delete-payee', {
              props: {
                payee: props.payee,
              },
            })
          },
        },
      ].filter((action) => !action.isHidden)
    })

    return {
      firstRowText,
      secondRowText,
      actions,
    }
  },
}
</script>

<template>
  <base-mdl title="" width="xs">
    <template #title>
      <div class="text-base">
        <div class="font-bold">
          {{ firstRowText }}
        </div>
        <div class="font-normal text-eonx-neutral-600">
          {{ secondRowText }}
        </div>
      </div>
    </template>
    <div class="space-y-2.5 py-[5px]">
      <button
        v-for="action in actions"
        :key="action.label"
        type="button"
        class="flex w-full items-center"
        @click="action.onClick"
      >
        <span class="mr-4 flex h-11 w-11 items-center justify-center">
          <base-icon v-bind="action.icon" size="md" />
        </span>
        {{ action.label }}
      </button>
    </div>
  </base-mdl>
</template>
