<script>
import Vue from 'vue'
import router from '/~/router'
import BaseButton from '/~/components/base/button/base-button'

export default Vue.extend({
  name: 'base-map-location-marker',
  components: {
    BaseButton,
  },
  props: {
    marker: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      router,
    }
  },
  computed: {
    link() {
      return this.getValue('link')
    },
  },
  methods: {
    onClick() {
      this.router.push(this.link.route)
    },
    getValue(property) {
      const value = this.marker[property]

      return value instanceof Function ? value() : value
    },
  },
})
</script>

<template>
  <div class="w-full text-center">
    <div class="mb-2.5 text-sm font-bold leading-5 sm:text-base">
      {{ getValue('title') }}
    </div>
    <div class="text-xs text-default sm:text-sm sm:leading-6">
      <div>{{ getValue('description') }}</div>
      <div>
        <span>{{ marker.stateCode }}</span>
        <span>{{ marker.postcode }}</span>
      </div>
    </div>
    <div>
      <base-button
        v-if="link"
        class="mt-5"
        look="filled"
        color="primary"
        full-width
        @click="onClick()"
      >
        {{ link.label }}
      </base-button>
    </div>
  </div>
</template>
