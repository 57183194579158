import { ref, computed } from 'vue'
import {
  LoyaltyCardsRawData,
  ApiResponseData,
  LoyaltyCardPayload,
  CatalogCard,
} from '/~/types/api'
import api from '/~/core/api'
import { LoyaltyCard } from '/~/core/loyalty-card'

const loyaltyCards = ref<LoyaltyCardsRawData[]>([])
const isLoyaltyCardsLoading = ref(false)
const loyaltyCardsLoadingError = ref('')
const loyaltyCardsLoadingPromise = ref<Promise<void> | null>(null)

const isLoyaltyCardsEmpty = computed(() => {
  return !isLoyaltyCardsLoading.value && loyaltyCards.value.length === 0
})

const catalogCards = ref<LoyaltyCard[]>([])
const catalogCardsLoadingPromise = ref<Promise<void> | null>(null)
const isCatalogCardsLoading = ref(false)
const isCatalogCardsEmpty = computed(() => {
  return !isCatalogCardsLoading.value && catalogCards.value.length === 0
})

const catalogCardsByLetters = computed(() => {
  const groupsList = {}

  catalogCards.value.forEach((brand) => {
    const groupLetter = brand.name.charAt(0).toUpperCase()

    groupsList[groupLetter] = groupsList[groupLetter] || []
    groupsList[groupLetter].push(brand)
  })

  return Object.keys(groupsList).map((key) => {
    return {
      letter: key,
      cards: groupsList[key],
    }
  })
})

const loyaltyCardColors = ref([
  { name: 'emerald', value: '#00B894' },
  { name: 'cyan', value: '#00CEC9' },
  { name: 'sky', value: '#0984E3' },
  { name: 'indigo', value: '#6C5CE7' },
  { name: 'pink', value: '#E84393' },
  { name: 'red', value: '#D63031' },
  { name: 'light red', value: '#E17055' },
])

async function getLoyaltyCards() {
  isLoyaltyCardsLoading.value = true
  loyaltyCardsLoadingError.value = ''

  loyaltyCardsLoadingPromise.value = api
    .get<ApiResponseData<LoyaltyCardsRawData[]>>('/loyalty-cards', {
      baseURL: '/1.1',
    })
    .then((response) => {
      loyaltyCards.value = (response || []).map((item) => new LoyaltyCard(item))
    })
    .catch((error) => {
      loyaltyCardsLoadingError.value = error.message || error.data?.message
    })
    .finally(() => {
      isLoyaltyCardsLoading.value = false
      loyaltyCardsLoadingPromise.value = null
    })

  return loyaltyCardsLoadingPromise.value
}

async function getLoyaltyCardById(cardId) {
  if (loyaltyCardsLoadingPromise.value) {
    await loyaltyCardsLoadingPromise.value
  } else if (isLoyaltyCardsEmpty.value) {
    await getLoyaltyCards()
  }

  return loyaltyCards.value.find((card) => card.id === cardId)
}

async function createLoyaltyCard(data: LoyaltyCardPayload) {
  return api.post<ApiResponseData<LoyaltyCardsRawData>>(
    '/loyalty-cards',
    data,
    { baseURL: '/1.1', notify: false }
  )
}

async function updateLoyaltyCard(id: string, data: LoyaltyCardPayload) {
  return api.put(`/loyalty-cards/${id}`, data, { baseURL: '/1.1' })
}

async function deleteLoyaltyCard(cardId: string) {
  return api
    .delete(`/loyalty-cards/${cardId}`, { baseURL: '/1.1' })
    .then((response) => {
      getLoyaltyCards()
      return response
    })
}

async function markLoyaltyCardAsViewed(cardId: string) {
  try {
    await api.post(`/loyalty-cards/${cardId}/viewed`, {}, { baseURL: '/1.1' })
  } catch (error) {
    console.warn(error)
  }
}

async function getCatalogCards() {
  isCatalogCardsLoading.value = true
  loyaltyCardsLoadingError.value = ''

  catalogCardsLoadingPromise.value = api
    .get<CatalogCard[]>('/loyalty-cards/catalogue-cards', { baseURL: '/1.1' })
    .then((response) => {
      catalogCards.value = (response || [])
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(
          (raw) =>
            new LoyaltyCard({
              logo: raw.logo,
              name: raw.name,
              external_id: raw.slug || (raw.name || '').toLowerCase(),
              slug: raw.slug,
            })
        )
    })
    .catch((error) => {
      loyaltyCardsLoadingError.value = error.message || error.data?.message
    })
    .finally(() => {
      isCatalogCardsLoading.value = false
      catalogCardsLoadingPromise.value = null
    })

  return catalogCardsLoadingPromise.value
}

async function getCatalogCardById(cardId: string) {
  if (catalogCardsLoadingPromise.value) {
    await catalogCardsLoadingPromise.value
  } else if (isCatalogCardsEmpty.value) {
    await getCatalogCards()
  }

  return catalogCards.value.find((card) => card.id === cardId)
}

async function getAssociatedLoyaltyCard(retailerSlug: string) {
  return api.get(`/v3/loyalty-cards/${retailerSlug}`, { notify: false })
}

export function useLoyaltyCards() {
  return {
    loyaltyCards,
    isLoyaltyCardsEmpty,
    isLoyaltyCardsLoading,
    isCatalogCardsEmpty,
    loyaltyCardColors,
    loyaltyCardsLoadingError,
    catalogCards,
    catalogCardsByLetters,
    isCatalogCardsLoading,
    getLoyaltyCardById,
    getLoyaltyCards,
    createLoyaltyCard,
    updateLoyaltyCard,
    deleteLoyaltyCard,
    markLoyaltyCardAsViewed,
    getCatalogCards,
    getCatalogCardById,
    getAssociatedLoyaltyCard,
  }
}
