<script setup lang="ts">
import CheckoutSummaryItem from '/~/components/checkout/summary/checkout-summary-item.v4.vue'
import { useCheckoutReactive } from '/~/composables/checkout'

const { payment } = useCheckoutReactive()
</script>

<template>
  <div v-if="payment.quickBuyProducts.length > 0">
    <div
      v-for="(item, index) in payment.quickBuyProducts"
      :key="index"
      class="w-full"
      @click="$emit('item', item)"
    >
      <checkout-summary-item v-if="item" :item="item" />
    </div>
  </div>
</template>
