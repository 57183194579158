<script>
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import PostContent from '/~rec/components/post/content/post-content.vue'
import PostHeader from '/~rec/components/post/post-header.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-post-box-reported',
  components: {
    PostContent,
    PostHeader,
    BaseButton,
    BaseIcon,
  },
  provide() {
    return {
      post: this.post,
    }
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    feed: {
      type: Object,
      required: true,
    },
  },
  computed: {
    reportsCount() {
      return get(this.post, 'reporters.length', 0)
    },
  },
  methods: {
    onDisregard() {
      modal.show('rec-post-disregard', {
        props: { post: this.post },
        on: {
          disregarded: () => {
            this.feed.removeHit(this.post)
          },
        },
      })
    },
    onDelete() {
      modal.show('rec-post-delete', {
        props: { post: this.post },
        on: {
          deleted: () => {
            this.feed.removeHit(this.post)
          },
        },
      })
    },
    openReporters() {
      modal.show('rec-post-reporters', {
        props: { post: this.post },
      })
    },
  },
}
</script>

<template>
  <div class="w-full rounded-md shadow-sm">
    <post-header class="pr-[30px]">
      <div class="hidden xs:block">
        <base-button look="link" @click="openReporters">
          <base-icon size="md" svg="plain/alert" />
          <span class="ml-2.5 text-sm">Reported ({{ reportsCount }})</span>
        </base-button>
      </div>
    </post-header>
    <div class="flex justify-center px-5 pb-[15px] xs:hidden">
      <base-button look="link" @click="openReporters">
        <base-icon size="md" svg="plain/alert" />
        <span class="ml-2.5 text-sm">Reported ({{ reportsCount }})</span>
      </base-button>
    </div>

    <div>
      <post-content />
    </div>
    <div
      class="mt-[15px] flex w-full justify-center gap-2.5 border-t px-2.5 py-5 sm:gap-5 sm:px-5"
    >
      <div>
        <base-button class="w-full" look="light-filled" @click="onDisregard()">
          Disregard Report
        </base-button>
      </div>
      <div>
        <base-button class="w-full" color="error" @click="onDelete()">
          Delete Post
        </base-button>
      </div>
    </div>
  </div>
</template>
