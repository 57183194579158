<script>
import DrawerPhotoId from '/~/drawer/photo-ids/photo-id.vue'

export default {
  name: 'profile-photo-id',
  components: {
    DrawerPhotoId,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    toMain() {
      this.$router.replace({ hash: '#profile-photo-ids' })
    },
    onOpen(id) {
      this.$router.push({ hash: `#profile-photo-id-details/${id}` })
    },
  },
}
</script>

<template>
  <drawer-photo-id :id="id" @empty="toMain" @delete="toMain" @open="onOpen" />
</template>
