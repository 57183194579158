import { type RouteConfig } from 'vue-router'
import { lazyEwalletRoute } from '/~/utils/lazy'

const routes: Array<RouteConfig> = [
  {
    path: '#profile-home',
    name: 'profile-home',
    component: import('/~/components/ewallet-menu/views/home/home.vue'),
    meta: {
      appear: null,
    },
  },
  {
    path: '#profile-details',
    name: 'profile-details',
    component: import(
      '/~/templates/bill-payments/components/profile-menu/views/profile/profile-details.vue'
    ),
  },
  {
    path: '#profile-settings',
    name: 'profile-settings',
    component: import(
      '/~/components/drawer/views/settings/drawer-settings.vue'
    ),
  },
  {
    path: '#profile-help',
    name: 'profile-help',
    component: import('./views/settings/profile-help.vue'),
  },
  {
    path: '#profile-help-confirmation',
    name: 'profile-help-confirmation',
    component: import('./views/settings/profile-help-confirmation.vue'),
  },
  {
    path: '#profile-toc',
    name: 'profile-toc',
    component: import('./views/settings/profile-toc.vue'),
  },
  {
    path: '#profile-direct-debit-toc',
    name: 'profile-direct-debit-toc',
    component: import(
      '/~/components/drawer/views/settings/drawer-direct-debit-toc.vue'
    ),
  },
  {
    path: '#profile-privacy-policy',
    name: 'profile-privacy-policy',
    component: import('./views/settings/profile-privacy-policy.vue'),
  },
  {
    path: '#profile-faq',
    name: 'profile-faq',
    component: import(
      '/~/components/drawer/views/settings/faq/drawer-faq-bill-payments.vue'
    ),
  },
  {
    path: '#profile-personal-details',
    name: 'profile-personal-details',
    component: import(
      '/~/templates/bill-payments/components/drawer/views/personal-details/drawer-personal-details.vue'
    ),
  },
  {
    path: '#profile-change-password',
    name: 'profile-change-password',
    component: import(
      '/~/templates/bill-payments/components/drawer/views/change-password/drawer-change-password.vue'
    ),
  },
  {
    path: '#profile-edit-profile',
    name: 'profile-edit-profile',
    component: import(
      '/~/templates/bill-payments/components/drawer/views/profile/drawer-edit-profile.vue'
    ),
  },
  {
    path: '#profile-add-address/:id',
    name: 'profile-add-address',
    component: import(
      '/~/templates/bill-payments/components/drawer/views/add-address/drawer-add-address.vue'
    ),
  },
  {
    path: '#profile-payment-methods',
    name: 'profile-payment-methods',
    component: import('./views/payment-methods/profile-payment-methods.vue'),
  },
  {
    path: '#profile-payment-methods-add',
    name: 'profile-payment-methods-add',
    component: import(
      './views/payment-methods/profile-payment-methods-add.vue'
    ),
  },
  {
    path: '#profile-photo-ids',
    name: 'profile-photo-ids',
    component: import('./views/photo-ids/profile-photo-ids.vue'),
  },
  {
    path: '#profile-photo-id/:id',
    name: 'profile-photo-id',
    component: import('./views/photo-ids/profile-photo-id.vue'),
  },
  {
    path: '#profile-photo-ids-add',
    name: 'profile-photo-ids-add',
    component: import('./views/photo-ids/profile-photo-ids-add.vue'),
  },
  {
    path: '#profile-photo-id-details/:id',
    name: 'profile-photo-id-details',
    component: import('./views/photo-ids/profile-photo-id-details.vue'),
  },
  {
    path: '#profile-e-purchases',
    name: 'profile-e-purchases',
    component: import(
      '/~/components/ewallet-menu/views/e-purchases/e-purchases.vue'
    ),
  },
  {
    path: '#profile-e-purchase/:id',
    name: 'profile-e-purchase',
    component: import(
      '/~/components/ewallet-menu/views/e-purchases/e-purchase.vue'
    ),
  },
  {
    path: '#profile-e-purchase-terms',
    name: 'profile-e-purchase-terms',
    component: import('./views/e-purchases/profile-e-purchase-terms.vue'),
    meta: {
      appear: 'bottom',
    },
  },
  {
    path: '#profile-loyalty-memberships',
    name: 'profile-loyalty-memberships',
    component: import(
      './views/loyalty-memberships/profile-loyalty-memberships.vue'
    ),
  },
  {
    path: '#profile-loyalty-brands',
    name: 'profile-loyalty-brands',
    component: import('./views/loyalty-memberships/profile-loyalty-brands.vue'),
  },
  {
    path: '#profile-loyalty-card-details/:id',
    name: 'profile-loyalty-card-details',
    component: import(
      './views/loyalty-memberships/profile-loyalty-card-details.vue'
    ),
  },
  {
    path: '#profile-loyalty-card-add/:id',
    name: 'profile-loyalty-card-add',
    component: import(
      './views/loyalty-memberships/profile-loyalty-card-add.vue'
    ),
  },
  {
    path: '#profile-loyalty-card-edit/:id',
    name: 'profile-loyalty-card-edit',
    component: import(
      './views/loyalty-memberships/profile-loyalty-card-edit.vue'
    ),
  },
  {
    path: '#profile-third-party-user-agreement',
    name: 'third-party-user-agreement',
    component: () =>
      import(
        '/~/components/ewallet-menu/views/third-party-user/third-party-user-agreement.vue'
      ),
  },
  {
    path: '#profile-third-party-user-permissions',
    name: 'third-party-user-permissions',
    component: () =>
      import(
        '/~/components/ewallet-menu/views/third-party-user/third-party-user-permissions.vue'
      ),
  },
  {
    path: '#profile-third-party-user-add',
    name: 'third-party-user-add',
    component: () =>
      import(
        '/~/components/ewallet-menu/views/third-party-user/third-party-user-add.vue'
      ),
    props: true,
  },
]

export default routes.map((route) => {
  if ('component' in route) {
    const routeProps = typeof route.props === 'object' ? route.props : undefined

    return {
      ...route,
      component: lazyEwalletRoute(route.component),
      meta: {
        appear: 'right',
        ...route.meta,
      },
      props: {
        to: 'menu-modal',
        ...routeProps,
      },
    }
  }
}) as RouteConfig[]
