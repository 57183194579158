<script>
import { useCms } from '/~/composables/cms'
import AppHeaderCustom from './app-header.custom.vue'
import AppHeaderHidden from './app-header.hidden.vue'
import AppHeaderV1 from './app-header.v1.vue'
import AppHeaderV2 from './app-header.v2.vue'
import AppHeaderV3 from './app-header.v3.vue'
import AppHeaderV4 from './app-header.v4.vue'
import AppHeaderV7 from './app-header.v7.vue'

export default {
  name: 'app-header',
  functional: true,
  render(h, ctx) {
    const {
      isHeaderVersionV2,
      isHeaderVersionV3,
      isHeaderVersionV4,
      isHeaderVersionV7,
      isHeaderVersionCustom,
      isHeaderHidden,
    } = useCms()

    let component

    if (isHeaderHidden.value) {
      component = AppHeaderHidden
    } else if (isHeaderVersionCustom.value) {
      component = AppHeaderCustom
    } else if (isHeaderVersionV7.value) {
      component = AppHeaderV7
    } else if (isHeaderVersionV4.value) {
      component = AppHeaderV4
    } else if (isHeaderVersionV3.value) {
      component = AppHeaderV3
    } else if (isHeaderVersionV2.value) {
      component = AppHeaderV2
    } else {
      component = AppHeaderV1
    }

    return h(component, ctx)
  },
}
</script>
