<script setup lang="ts">
import { computed } from 'vue'
import { useCms } from '/~/composables/cms'
import { useHowItWorks } from '/~/templates/bill-payments/modals/how-it-works/use-how-it-works'

const { howItWorks: cmsHowItWorks, benefits: cmsBenefits, ewalletLabels } = useCms()
const { howItWorks: defaultHowItWorks, benefits: defaultBenefits } =
  useHowItWorks()

const howItWorksItems = computed(() =>
  cmsHowItWorks.value.items?.length
    ? cmsHowItWorks.value.items
    : defaultHowItWorks.value.items
)
const benefitsItems = computed(() =>
  cmsBenefits.value.items?.length
    ? cmsBenefits.value.items
    : defaultBenefits.value.items
)
</script>

<template>
  <div>
    <div class="px-6 pt-5 pb-10 sm:p-12">
      <div class="items-start justify-between sm:flex">
        <div
          v-for="(item, index) in howItWorksItems"
          :key="index"
          class="mb-[30px] flex flex-1 items-start sm:flex-col sm:text-center"
        >
          <div
            class="mr-5 shrink-0 sm:mx-auto sm:mb-5 sm:flex sm:h-40 sm:items-end sm:justify-center"
          >
            <img
              class="max-h-full max-w-20 sm:max-w-full"
              :src="'desktop' in item.image ? item.image.desktop : item.image"
              :alt="item.title"
            />
          </div>
          <div>
            <b class="text-lg sm:text-xl">{{ item.title }}</b>
            <div class="mt-[15px]" v-html="item.description" />
          </div>
        </div>
      </div>
    </div>

    <div class="bg-dark px-6 py-10 sm:p-12">
      <h3 class="mb-[30px] text-center sm:mb-5">
        {{ cmsBenefits.title ?? `Benefits of ${ewalletLabels.ewallet}` }}
      </h3>
      <div class="items-start justify-between sm:flex">
        <div
          v-for="(item, index) in benefitsItems"
          :key="index"
          class="mb-[30px] flex flex-1 items-start sm:flex-col sm:text-center"
        >
          <div
            class="mr-5 shrink-0 sm:mx-auto sm:mb-5 sm:flex sm:h-40 sm:items-end sm:justify-center"
          >
            <img
              class="max-h-full max-w-20 sm:max-w-full"
              :src="'desktop' in item.image ? item.image.desktop : item.image"
              :alt="item.title"
            />
          </div>
          <div>
            <b class="text-lg sm:text-xl">
              {{ item.title }}
            </b>
            <div class="mt-[15px]" v-html="item.description" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
