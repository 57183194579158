<script setup lang="ts">
import { computed, defineComponent, ref } from 'vue'
import DownloadableEntry from './entry/downloadable-post-entry.vue'
import { PostAttachment } from '/~/types/extensions'

defineComponent({
  name: 'rec-post-downloadable',
})

const props = defineProps<{
  attachments: PostAttachment
  contentColor: string
}>()

const previewCount = ref(3)
const showHidden = ref(false)

const visibleAttachments = computed(() => {
  if (showHidden.value) return props.attachments.downloadableAttachments

  return props.attachments.downloadableAttachments?.slice(0, previewCount.value)
})

const hiddenCount = computed(() => {
  const count = props.attachments.downloadableAttachments?.length

  return count && count > previewCount.value ? count - previewCount.value : 0
})
</script>

<template>
  <div>
    <div class="space-y-4">
      <downloadable-entry
        v-for="attachment in visibleAttachments"
        :key="attachment.id"
        :attachment="attachment"
        :content-color="contentColor"
        class="rounded-lg rounded-t-lg"
      />
    </div>

    <span
      v-if="hiddenCount > 0"
      class="inline-block cursor-pointer text-sm font-bold text-primary"
      @click="showHidden = !showHidden"
    >
      <span>
        {{ showHidden ? 'Show less' : `Show more (${hiddenCount})` }}
      </span>
    </span>
  </div>
</template>
