export default class HeathRecipe {
  constructor(raw = {}) {
    this.raw = raw
  }

  get id() {
    return this.raw.objectID
  }

  get title() {
    return this.raw['Recipe Title']
  }

  get description() {
    const data = this.raw['Recipe Text']

    return data && JSON.parse(data)
  }

  get excerpt() {
    const text = this.description && this.description[0].text

    return `${text.substring(0, 80)}...`
  }

  get image() {
    return this.raw['Banner Image']
  }

  get createdAt() {
    return '2020-08-05T12:21:00Z'
  }

  get category() {
    return this.raw.Category
  }
}
