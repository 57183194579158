<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import { usePayees } from '/~/composables/payees'
import { useStatementAccounts } from '/~/composables/statements'

const { allowMultiplePayees, NEW_STATEMENT_ACCOUNT_TYPE, BANK_ACCOUNT_TYPE } =
  usePayees()
const { allowMultipleStatementAccounts } = useStatementAccounts()

const route = useRoute()
const router = useRouter()

const routeName = computed(() => route.name)

const canAddNewPayee = computed(() => allowMultiplePayees.value)
const showAddNewPayeeButton = computed(
  () => allowMultipleStatementAccounts.value || route.params.type !== 'primary'
)

interface ActionParams {
  title: string
  back: { name: string; params?: Record<string, string> } | false
  action: string
}

const params = computed<Partial<ActionParams>>(() => {
  switch (routeName.value) {
    case 'payments-pay':
      return {
        title: 'Payment options',
        back: { name: 'payments-select-payee' },
        action: '',
      }
    case 'payment-checkout-points':
      return {
        title: 'Pay with points',
        back: { name: 'payments-pay' },
        action: '',
      }
    case 'payment-checkout-method':
      return {
        title: 'Select payment method',
        back: { name: 'payments-pay' },
        action: 'payments-add',
      }
    case 'payments-select-payee':
      return {
        title: 'Select payee',
        back: { name: 'home' },
        action: canAddNewPayee.value ? 'new-payee' : '',
      }
    case 'new-payee':
      return {
        title: 'Add payee',
        back: { name: 'payments-select-payee' },
        action: '',
      }
    case 'make-new-payee':
      return {
        title: 'Add payee',
        back: { name: 'payments-select-payee' },
        action: '',
      }
    case 'payment-checkout':
      return {
        title: 'Payment Methods',
        back: { name: 'payments-pay' },
        action: 'payments-add',
      }
    case 'payments-summary':
      return {
        title: 'Payment summary',
        back: { name: 'payment-checkout' },
        action: '',
      }
    case 'payment-checkout-confirmation':
      return {
        title: 'Payment Successful',
        back: false,
        action: '',
      }
    default:
      return {}
  }
})

function onAction() {
  switch (params.value.action) {
    case 'new-payee':
      router.push({
        name: route.params.type === 'other' ? 'new-payee' : 'make-new-payee',
        params: {
          origin: 'payments-make',
          type:
            route.params.type === 'other'
              ? BANK_ACCOUNT_TYPE
              : NEW_STATEMENT_ACCOUNT_TYPE,
        },
      })
      break
    case 'payments-add':
      router.push({
        name: 'payments-add',
        params: {
          origin: 'payments-make',
        },
      })
      break
  }
}
</script>

<template>
  <base-aside-page :title="params.title" :back="params.back" :no-padding="true">
    <template v-if="params.action" #action>
      <div
        v-if="showAddNewPayeeButton"
        class="flex h-[35px] w-[35px] flex-none items-center justify-center rounded-full bg-primary text-white"
      >
        <base-button
          data-testid="action-button"
          icon="plus"
          @click="onAction()"
        />
      </div>
    </template>
    <router-view />
  </base-aside-page>
</template>
