<script>
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import DrawerPayFromRowV1 from '/~/components/drawer/components/pay/drawer-pay-from-row.vue'
import DrawerPaymentFrom from '/~/components/ewallet-menu/components/rows/menu-payment-from.vue'

export default {
  name: 'profile-pay-from-row-v2',
  components: {
    DrawerPaymentFrom,
    ButtonIcon,
  },
  extends: DrawerPayFromRowV1,
}
</script>

<template>
  <drawer-deletable-item :deleting="deleting" @delete-confirmed="onDelete">
    <template #default="{ toggleDelete }">
      <drawer-payment-from
        :from="from"
        :is-disabled="disabled || isLocked"
        :class="verificationRequired && 'cursor-default'"
        @click="onClick"
      >
        <template #action>
          <div class="flex items-center">
            <base-button
              v-if="verificationRequired"
              :loading="initializing"
              color="warning"
              size="xs"
              @click="verify"
            >
              Verify
            </base-button>
            <div
              v-else-if="isLocked"
              class="flex h-[30px] w-[72px] items-center justify-center rounded-md bg-error-700 text-sm font-bold text-white"
            >
              Locked
            </div>

            <span class="text-primary">
              <button-icon
                v-if="deletable && !isLocked && !verificationRequired"
                icon="v2/heroic/trash2"
                :size="24"
                class="ml-2.5 text-eonx-neutral-600"
                @click="toggleDelete"
              />
            </span>
          </div>
        </template>
      </drawer-payment-from>
    </template>
  </drawer-deletable-item>
</template>
