<script>
import WgtContent from './wgt-content.vue'

export default {
  name: 'wgt-text',
  mixins: [WgtContent],
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <wgt-content
    class="z-2 absolute top-0 left-0 box-border flex h-full w-full flex-col text-lg leading-tight sm:text-xl"
    :content="content"
  >
    <slot />
  </wgt-content>
</template>
