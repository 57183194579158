export default {
  pay: () => import('./make-payment/pay.bs.vue'),
  'cvv-code': () => import('/~/modals/payment-methods/card/cvv-code.bs.vue'),
  'edit-account': () => import('./edit-account/edit-account.bs.vue'),
  'payment-date-picker': () =>
    import('./payment-date-picker/payment-date-picker.bs.vue'),
  'edit-payee': () => import('./payees/edit-payee.bs.vue'),
  'delete-payee': () => import('./payees/delete-payee.bs.vue'),
  'activity-filter': () => import('./activity-filter/activity-filter.bs.vue'),
  'filter-datepicker': () =>
    import('./filter-datepicker/filter-datepicker.bs.vue'),
  'select-date': () => import('./select-date/select-date.bs.vue'),
  'enable-direct-debit': () =>
    import('./enable-direct-debit/enable-direct-debit.bs.vue'),
}
