export default ({ isPhysicalEnabled = false, ewalletLabels }) => [
  {
    title: 'Complete your profile',
    image: '/images/hiw/complete-profile.svg',
    description: `Click ${ewalletLabels.yourEwallet} logo on the top right of your screen, and fill in your profile details.`,
  },
  {
    title: 'Shop & Save',
    image: '/images/hiw/shop-save.svg',
    description: `Select any retailer and purchase your discounted ${
      isPhysicalEnabled ? 'physical or ' : ''
    }digital Gift Cards and eVouchers.`,
  },
  {
    title: 'Redeem in-store',
    image: '/images/hiw/redeem-store.svg',
    description: `Simply present your purchased ${
      isPhysicalEnabled ? 'physical or ' : ''
    }digital Gift Cards and eVouchers in-store to redeem.`,
  },
]
