<script>
import modal from '/~/core/mdl'
import { formatBsb } from '/~/utils/format/string'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useLocalization } from '/~/composables/localization'
import { usePayees } from '/~/composables/payees'

export default {
  name: 'delete-payee-modal',
  components: {
    BaseButton,
    BaseIcon,
    BaseMdl,
  },
  props: {
    payee: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { removePayee } = usePayees()
    const { formatBusinessNumber } = useLocalization()

    return {
      formatBsb,
      formatBusinessNumber,
      removePayee,
    }
  },
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    onCancel() {
      this.$emit('hide')
      modal.hide()
    },
    async onDelete() {
      this.processing = true
      try {
        await this.removePayee(this.payee.id)
        this.$emit('hide')
        modal.hide()
      } catch (error) {
        console.error('bill-payments', error)
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<template>
  <base-mdl
    width="xse"
    border-radius="2xl"
    layout="v2"
    padding="xs"
    title="Delete payee"
  >
    <template #icon>
      <base-icon svg="payee/error" :size="64" class="text-red-700" />
    </template>

    <div class="text-md mb-8 text-center text-eonx-neutral-600">
      Are you sure you wish to delete this payee?
      <br />
      All pending payments linked to this payee will be automatically cancelled.
      This action cannot be undone.
    </div>

    <div class="flex space-x-2">
      <base-button
        class="flex-auto"
        look="outlined-color"
        :disabled="processing"
        @click="onCancel"
      >
        Cancel
      </base-button>
      <base-button
        class="flex-auto"
        :disabled="processing"
        :loading="processing"
        @click="onDelete"
      >
        Confirm
      </base-button>
    </div>
  </base-mdl>
</template>
