<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useUser } from '/~/composables/user'
import { Pipeline } from '/~/core/pipeline'

export default {
  name: 'pa-public-liability-insurance-form-modal',
  components: {
    BaseInput,
    BaseButton,
    BaseMdl,
    BaseTextarea,
    BaseRadio,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const { user } = useUser()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()

    return {
      user,
      backendErrors,
      processBackendErrors,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {},
      processing: false,
      success: false,
      trackingCode: '',
    }
  },
  computed: {
    currencyMask() {
      return {
        prefix: '$',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralIntegerScale: 12,
        numeralDecimalScale: 2,
        min: 0,
      }
    },
    formTitle() {
      return Pipeline.formTitle('Critical Illness Insurance')
    },
  },
  created() {
    const { email, firstName, lastName, mobile: phone } = this.user
    const userDetails = {
      email,
      first_name: firstName,
      last_name: lastName,
      phone,
    }

    this.form = { ...this.form, ...userDetails }
  },
  methods: {
    async onSubmit() {
      this.processing = true
      this.backendErrors = {}

      try {
        const { trackingId } = await Pipeline.submit(
          'pa/public-liability',
          this.form
        )

        this.trackingCode = trackingId[0]
        this.success = true
      } catch (error) {
        this.processBackendErrors(error)
        this.$emit('error', error)
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<template>
  <base-mdl :title="formTitle" fullscreen="mobile">
    <div v-if="!success">
      <div class="bg-slate-200 p-6">
        <p>
          Getting a quote is easy! Simply complete the form below and indicate
          if you would like furhter information, or to simly be issued with a
          formal quotation.
        </p>
        <p>
          <small>
            Required fields are makred with
            <sup>*</sup>
          </small>
        </p>
      </div>
      <validation-observer
        v-slot="{ errors, handleSubmit }"
        ref="validationObserverRef"
        slim
      >
        <form
          class="-mx-2.5 mt-6 flex flex-wrap"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <base-input
            v-model="form.first_name"
            :validation="{
              rules: 'required',
              name: 'First Name',
            }"
            :error="backendErrors.first_name"
            label="First Name"
            required
            name="first_name"
            class="mx-2.5"
          />
          <base-input
            v-model="form.last_name"
            :validation="{
              rules: 'required',
              name: 'Last Name',
            }"
            :error="backendErrors.firstName"
            label="Last Name"
            required
            name="last_name"
            class="mx-2.5"
          />
          <base-input
            v-model="form.phone"
            :validation="{
              rules: 'required|mobile',
              name: 'Mobile',
            }"
            :error="backendErrors.phone"
            label="Mobile"
            required
            type="tel"
            mask="mobile"
            name="phone"
            class="mx-2.5"
          />
          <base-input
            v-model="form.email"
            :validation="{
              rules: 'required|email',
              name: 'Email',
            }"
            :error="backendErrors.email"
            label="Email"
            required
            name="email"
            type="email"
            class="mx-2.5"
          />

          <div class="mx-2.5 mb-6 w-full grow">
            <h3>Enquiry for:</h3>
            <p>Please provide *</p>
            <validation-provider
              vid="provide"
              rules="required"
              name="Enquiry For"
              mode="aggressive"
              slim
            >
              <div class="flex flex-wrap pl-2.5">
                <base-radio
                  v-model="form.provide"
                  name="provide"
                  value="further_information"
                  :error="backendErrors.provide"
                  class="mr-6"
                >
                  Further information
                </base-radio>
                <base-radio
                  v-model="form.provide"
                  name="provide"
                  value="formal_quote"
                >
                  Formal quote
                </base-radio>
              </div>
              <div
                v-if="errors['provide']"
                class="base-field__message base-field__message--error w-full grow"
              >
                {{ errors['provide'][0] }}
              </div>
            </validation-provider>
          </div>

          <base-input
            v-model="form.insured_name"
            label="Insured name"
            name="insured_name"
            class="mx-2.5"
          />
          <base-input
            v-model="form.occupation"
            label="Occupation"
            name="occupation"
            class="mx-2.5"
          />
          <base-input
            v-model="form.turnover"
            :mask="currencyMask"
            label="Turnover"
            name="turnover"
            currency
            class="w-full sm:mx-2.5 sm:w-auto"
          />
          <base-input
            v-model="form.number_employees"
            type="tel"
            label="Number of Employees"
            name="number_employees"
            class="w-full sm:mx-2.5 sm:w-auto"
          />
          <div class="mx-2.5 mb-6 w-full grow">
            <p>Limit of Indemnity required</p>
            <div class="flex flex-wrap pl-2.5">
              <base-radio
                v-model="form.required_indemnity"
                name="required_indemnity"
                value="$10000000"
                class="mr-6"
              >
                $10,000,000
              </base-radio>
              <base-radio
                v-model="form.required_indemnity"
                name="required_indemnity"
                value="$20000000"
              >
                $20,000,000
              </base-radio>
            </div>
          </div>
          <base-textarea
            v-model="form.comments"
            label="Comments or Questions"
            name="comments"
            class="mx-2.5 w-full grow"
          />
          <div class="mx-2.5 w-full grow">
            <base-button
              class="w-full"
              type="submit"
              :disabled="processing"
              :loading="processing"
            >
              Submit
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
    <template v-else>
      <transition name="fade">
        <div class="my-6 bg-slate-200 p-6">
          <h2>Success</h2>
          <p>
            <strong>Corporate Travel Insurance</strong>
            form have been submitted.
            <br />
            Your Tracking code is:
            <strong>{{ trackingCode }}</strong>
          </p>
        </div>
      </transition>
    </template>
  </base-mdl>
</template>
