<script>
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseButton from '/~/components/base/button/base-button'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-award-baner',
  components: {
    BaseImage,
    BaseButton,
  },
  setup() {
    const { features } = useRecProfile()

    return { features }
  },
  computed: {
    title() {
      if (!this.features.hi5) {
        return 'Recognise your peers with an Award'
      }
      if (!this.features.awards) {
        return 'Recognise your peers with an instant Hi-5'
      }
      return 'Recognise your peers with an Award or an instant Hi-5'
    },
    isSomethingMissing() {
      return !this.features.awards || !this.features.hi5
    },
  },
}
</script>

<template>
  <div v-if="features.awards || features.hi5" class="mt-auto w-full">
    <div class="relative rounded-t-3xl bg-gray-700">
      <div class="p-[15px]">
        <div class="flex w-full">
          <div class="float-left mr-2.5 w-16">
            <base-image
              class="h-12"
              src="/recroom/award-cup.svg"
              alt="Recognise your peers"
            />
          </div>
          <p class="float-left cursor-default text-base font-bold text-white">
            {{ title }}
          </p>
        </div>
        <div
          class="mt-2.5 flex w-full"
          :class="{
            'justify-center': isSomethingMissing,
            'justify-around': !isSomethingMissing,
          }"
        >
          <base-button
            v-if="features.awards"
            class="w-5/12"
            color="white"
            size="sm"
            style="border-radius: calc(1.25 * var(--border-radius))"
            @click="
              $router.push({
                name: 'rec-awards',
              })
            "
          >
            <span
              class="text-sm text-eonx-neutral-800 hover:text-eonx-neutral-600"
            >
              Nominate
            </span>
          </base-button>
          <base-button
            v-if="features.hi5"
            class="w-5/12"
            color="white"
            size="sm"
            style="border-radius: calc(1.25 * var(--border-radius))"
            @click="
              $router.push({
                name: 'rec-hi5',
              })
            "
          >
            <span
              class="text-sm text-eonx-neutral-800 hover:text-eonx-neutral-600"
            >
              Send a Hi-5
            </span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
