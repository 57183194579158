<script>
import ui from '/~/core/ui'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'
import PointsPartner from '../../components/points-partner.vue'

export default {
  name: 'fly-partners-available',
  components: {
    PointsPartner,
  },
  props: {
    initial: {
      type: Boolean,
      default: false,
    },
    partners: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { selectedPartner } = usePointsPrograms()

    return {
      selectedPartner,
      ui,
    }
  },
  methods: {
    handleClick(partner) {
      const partnerType = partner.type

      this.selectedPartner = partner
      if (partner.count === 0) {
        this.$router.push({
          name: 'fly-partner-new',
          query: { type: partnerType },
        })
      } else {
        this.$router.push({
          name: 'fly-business',
          query: { type: partnerType },
        })
      }
    },
  },
}
</script>

<template>
  <div class="space-y-3 sm:space-y-4">
    <points-partner
      v-for="(partner, index) in partners"
      :key="`partner-${index}`"
      :partner="partner"
      :disabled="!partner.enabled"
      counter
      @click="handleClick(partner)"
    />
  </div>
</template>
