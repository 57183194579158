<script>
import ui from '/~/core/ui'
import { formatPoints } from '/~/utils/points'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import WgtImage from '../../wgt/wgt-image.vue'

export default {
  name: 'widget-australiapost-slide',
  components: {
    WgtImage,
    BaseIcon,
    BaseAction,
  },
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      formatPoints,
      ui,
    }
  },
  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.slide.buttonBgColor,
        color: this.slide.buttonTextColor,
      }
    },
    imageStyle() {
      const position = 708 - ui.width / 2

      return {
        marginLeft: position > 70 ? `-${position}px` : '-70px',
      }
    },
    bgStyle() {
      const position = 770 - ui.width / 2

      return {
        borderRadius: '50%',
        width: '782px',
        height: '760px',
        marginRight: position > 120 ? `-${position}px` : '-120px',
        backgroundColor: this.slide.backgroundColor,
      }
    },
    pointsStyle() {
      return {
        padding: '8px 16px',
        color: this.slide.pointsTextColor,
        backgroundColor: this.slide.pointsBgColor,
      }
    },
  },
}
</script>

<template>
  <div class="swiper-slide">
    <div
      class="relative block overflow-hidden xs:flex xs:h-96 xs:w-full xs:rounded-xl xs:shadow"
      :style="{ backgroundColor: slide.backgroundColor }"
    >
      <wgt-image
        :image="slide.image"
        :style="imageStyle"
        class="absolute top-0 left-0 hidden sm:block"
      />
      <div
        class="absolute top-0 right-0 -mt-[174px] hidden sm:block"
        :style="bgStyle"
      />
      <div
        class="top-0 right-0 mr-[30px] flex h-full flex-col justify-center p-5 xs:px-10 sm:absolute sm:w-1/2"
      >
        <div class="flex items-start">
          <img :src="slide.logo.desktop" />
          <div class="ml-5" :style="{ color: slide.offerTextColor }">
            <div class="text-xl font-bold" v-html="slide.offer.title" />
            <div class="flex items-center">
              <base-icon
                svg="australiapost/calendar2"
                size="md"
                class="mr-[5px]"
              />
              <span class="text-xs xs:text-base" v-html="slide.offer.text" />
            </div>
          </div>
          <div
            class="mt-2.5 ml-[30px] hidden items-start xs:block sm:hidden lg:block"
          >
            <span
              v-if="slide.points"
              class="rounded-full text-2xl font-bold"
              :style="pointsStyle"
            >
              {{ formatPoints(slide.points) }} PTS
            </span>
          </div>
        </div>
        <div v-if="slide.points" class="mt-5 xs:hidden sm:inline lg:hidden">
          <span class="rounded-full text-2xl font-bold" :style="pointsStyle">
            {{ formatPoints(slide.points) }} PTS
          </span>
        </div>
        <div
          class="mt-5 max-w-[500px] font-bold"
          :style="{ color: slide.infoTextColor }"
        >
          <div class="text-2xl" v-html="slide.info.title" />
          <div class="mt-[5px]" v-html="slide.info.text" />
        </div>
        <div>
          <base-action
            v-if="slide.bannerButton"
            v-bind="slide.bannerButton"
            class="inline-block"
            origin="banner"
            appearance="link"
          >
            <button
              type="button"
              class="button mt-[30px] flex h-12 items-center rounded px-5 font-bold"
              :style="buttonStyle"
            >
              <span>
                {{ slide.bannerButton.label }}
              </span>
              <base-icon class="ml-5" svg="plain/chevron-right" size="sm" />
            </button>
          </base-action>
        </div>
      </div>
    </div>
  </div>
</template>
