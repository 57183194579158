<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-earn-block',
  components: {
    BaseIcon,
  },
  props: {
    earn: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      if (this.earn.disabled) {
        return 'light'
      } else {
        return this.earn.isCompleted ? 'success' : 'gold'
      }
    },
    statusFraction() {
      return this.earn.statusFraction
    },
  },
}
</script>

<template>
  <div class="relative z-0 min-h-32">
    <div
      class="absolute top-0 right-0 z-1 mt-[15px] -mr-2.5 h-6 rounded-l-md bg-current pr-[15px]"
      :class="{
        'text-success-700': color === 'success',
        'text-eonx-neutral-600': color === 'light',
        'text-amber-400': color === 'gold',
      }"
    >
      <div class="flex h-full items-center text-xs text-white">
        <span class="pl-[15px] font-bold uppercase leading-none">
          {{ earn.badgeContent }}
        </span>
      </div>
      <div
        class="absolute right-0 top-full h-0 w-0 border-t-8 border-r-8 border-solid border-transparent"
        style="border-top-color: currentColor"
      />
      <div
        class="absolute right-0 top-full z-1 h-0 w-0 border-t-8 border-r-8 border-solid border-transparent"
        style="border-top-color: rgba(0, 0, 0, 0.2)"
      />
    </div>
    <div
      class="relative z-0 h-full overflow-hidden rounded-lg text-sm shadow-sm"
    >
      <div class="px-5 pt-[15px] pb-2.5">
        <h3 name="title" class="truncate pr-24 text-sm font-bold">
          {{ earn.title }}
        </h3>
        <div class="mt-[15px] flex">
          <base-icon
            v-if="earn.icon"
            :svg="earn.icon"
            :size="30"
            class="mr-[15px] w-11"
            :class="{
              'text-success-700': color === 'success',
              'text-eonx-neutral-600': color === 'light',
              'text-amber-400': color === 'gold',
            }"
          />
          <div class="flex-auto text-eonx-neutral-600">
            {{ earn.description }}
          </div>
        </div>
        <div
          v-if="statusFraction"
          class="text-right text-sm font-bold text-success-700"
        >
          {{ statusFraction }}
          today
        </div>
      </div>
    </div>
  </div>
</template>
