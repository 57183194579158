<script setup lang="ts">
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import EditProfileForm from '/~/components/drawer/views/personal-details/views/edit-profile/edit-profile-form.vue'
import modal from '/~/core/mdl'

const props = defineProps<{
  onConfirm?: () => Promise<void>
}>()

async function onConfirm() {
  await props.onConfirm?.()
  hide()
}

function hide() {
  modal.hide('edit-profile')
}
</script>

<template>
  <base-mdl width="xs" title="Complete Profile" :closable="false">
    <edit-profile-form
      :is-form-fields-scrollable="false"
      show-only-required-fields
      @profile-saved="onConfirm"
    />
  </base-mdl>
</template>
