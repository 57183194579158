<script setup lang="ts">
import { ref, watch, computed, onBeforeUnmount, onMounted } from 'vue'
import { PaymentMethodBankAccount, PaymentMethodCreditCard } from '/~/types/api'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import PayByCc, {
  type PayByCCProps,
  type UpdateEventPayloads as UpdateCCEventPayloads,
} from './how-to-pay/cc.vue'
import PayByDb, {
  type PayByDBProps,
  type UpdateEventPayloads as UpdateDBEventPayloads,
} from './how-to-pay/db.vue'
import HintPure from './how-to-pay/hint.pure.vue'
import PayByPs, {
  type PayByPointsProps,
  type UpdateEventPayloads as UpdatePSEventPayloads,
} from './how-to-pay/ps.vue'
import PaymentsMakeSection from './section.pure.vue'

interface PaymentOption {
  name: string
  type:
    | PaymentMethodType.creditCard
    | PaymentMethodType.points
    | PaymentMethodType.bankAccount
  icons: Array<string>
  notes?: string
  id: string
  iconClass?: string
  iconSize?: number | string
  order: number
}

export interface TypeSelectEventPayloads {
  type: PaymentOption['type'] | undefined
}

export type ValueChangeEventPayloads =
  | (UpdatePMPayloads & {
      currentMethod?: PaymentMethodCreditCard | PaymentMethodBankAccount
    })
  | null

export type UpdatePMPayloads =
  | UpdateCCEventPayloads
  | UpdateDBEventPayloads
  | UpdatePSEventPayloads

type CreditCard = PayByCCProps['cardsList'][0]
type BankAccount = PayByDBProps['bankAccountsList'][0]

type Hint = {
  type: 'info'
  text: string
}

export type PayByProps = {
  payingTo?: any
  processingFeeOptions: {
    loading: boolean
    value: number
  }
  amount: number
  paymentSplitAllowed: boolean
  hasPointsMethod: boolean
  paymentOptions: Array<PaymentOption>
  flowType?: FlowType
  pointsBalance: number
  burnPointsRates: PayByPointsProps['burnPointsRates']
  hints?: Hint[]
  getCreditCards?: (payeeId?: string) => Promise<Array<CreditCard>>
  getBankAccounts?: (payeeId?: string) => Promise<Array<BankAccount>>
  getPointsBalance?: (
    payeeId?: string
  ) => Promise<Array<CreditCard | BankAccount>>
  onAddNewPaymentMethod?: () => void
  isLoadingProgramFees?: boolean
  processingTimes: {
    title: string
    haveContent: boolean
  }
  taxation: {
    label: string
  }
}

const props = withDefaults(defineProps<PayByProps>(), {
  hints: () => [],
  isLoadingProgramFees: false,
})

const emit = defineEmits<{
  (event: 'value-change', payloads: ValueChangeEventPayloads): void
  (event: 'type-select', payloads: TypeSelectEventPayloads): void
  (event: 'processing-times'): void
}>()

const loading = ref(false)
const paymentOptionsType = ref<PaymentOption['type']>()
const cardsList = ref<Array<CreditCard>>([])
const bankAccountsList = ref<Array<BankAccount>>([])
const paymentMethodsList = ref<Array<CreditCard | BankAccount>>([])

const isPointsOnlyOption = (option: PaymentOption) => {
  return option.type === PaymentMethodType.points && !props.paymentSplitAllowed
}
const isCreditCardOptionSelected = computed(
  () => paymentOptionsType.value === PaymentMethodType.creditCard
)
const isBankAccountOptionSelected = computed(
  () => paymentOptionsType.value === PaymentMethodType.bankAccount
)
const isPointsSplitOptionSelected = computed(
  () => paymentOptionsType.value === PaymentMethodType.points
)

const burnPointsRate = computed(() =>
  props.flowType === FlowType.statement
    ? props.burnPointsRates.statement
    : props.burnPointsRates.payment
)

const pointsMaxToUse = computed(() => {
  const maxPoints = Math.ceil(props.amount / burnPointsRate.value)

  return {
    points: maxPoints,
    enough: maxPoints <= props.pointsBalance,
  }
})
const isPayingToBatch = computed(() => props.flowType === FlowType.batch)
const isPayingToStatement = computed(
  () => props.flowType === FlowType.statement
)
const payeeId = computed<string | undefined>(() => {
  if (isPayingToBatch.value || isPayingToStatement.value) return undefined

  return props.payingTo.id
})

const getOptions = async (optionType: PaymentOption['type']) => {
  switch (optionType) {
    case PaymentMethodType.creditCard:
      if (props.getCreditCards) {
        loading.value = true
        cardsList.value = await props.getCreditCards(payeeId.value)
      }
      break
    case PaymentMethodType.bankAccount:
      if (props.getBankAccounts) {
        loading.value = true
        bankAccountsList.value = await props.getBankAccounts(payeeId.value)
      }
      break
    case PaymentMethodType.points:
      if (props.getPointsBalance) {
        loading.value = true
        paymentMethodsList.value = await props.getPointsBalance(payeeId.value)
      }
      break

    default:
      break
  }

  loading.value = false
}

function getSelectedMethod(type: PaymentMethodType | null, id: string) {
  switch (type) {
    case PaymentMethodType.creditCard: {
      return cardsList.value.find((method) => method.id === id)
    }
    case PaymentMethodType.bankAccount: {
      return bankAccountsList.value.find((method) => method.id === id)
    }
    default: {
      return paymentMethodsList.value.find((method) => method.id === id)
    }
  }
}

function handleMethodUpdate(payloads: UpdatePMPayloads) {
  if (!payloads) {
    emit('value-change', null)
  } else if ('id' in payloads) {
    emit('value-change', {
      ...payloads,
      currentMethod: getSelectedMethod(payloads.type, payloads.id),
    })
  } else {
    emit('value-change', payloads)
  }
}

function onUpdatedPaymentMethod(paymentMethod?: {
  type: PaymentOption['type']
  id: PaymentOption['id']
}) {
  if (!paymentMethod?.type) return
  const { type, id } = paymentMethod
  const currentMethod = getSelectedMethod(
    isPointsSplitOptionSelected.value ? null : type,
    id
  )

  if (currentMethod) {
    emit('value-change', null)
  }

  if (isPointsSplitOptionSelected.value && props.paymentSplitAllowed) {
    getOptions(PaymentMethodType.points)
  } else {
    getOptions(paymentMethod.type)
  }
}

watch(paymentOptionsType, (value) => {
  if (value) {
    getOptions(value)

    emit('type-select', { type: value })

    if (isPointsSplitOptionSelected.value && !props.paymentSplitAllowed) {
      emit('value-change', {
        type: PaymentMethodType.points,
        calculatedAmount: props.amount,
        pointsToUse: pointsMaxToUse.value.points,
      })
    }
  }
})

watch([() => props.payingTo, () => props.amount], () => {
  paymentOptionsType.value = undefined
  emit('type-select', { type: paymentOptionsType.value })
})

onBeforeUnmount(() => {
  emitter.off('payment-methods:created', onUpdatedPaymentMethod)
  emitter.off('payment-methods:verified', onUpdatedPaymentMethod)
  emitter.off('payment-methods:removed', onUpdatedPaymentMethod)
})

onMounted(() => {
  emitter.on('payment-methods:created', onUpdatedPaymentMethod)
  emitter.on('payment-methods:verified', onUpdatedPaymentMethod)
  emitter.on('payment-methods:removed', onUpdatedPaymentMethod)
})
</script>

<template>
  <fragment>
    <payments-make-section label="Select how to pay">
      <template v-if="processingTimes.haveContent" #subHead>
        <base-button look="link" @click="emit('processing-times')">
          <base-icon
            :svg="'heroicons/mini/information-circle'"
            :size="24"
            class="mr-1"
          />
          <span class="text-base md:text-lg">{{ processingTimes.title }}</span>
        </base-button>
      </template>

      <div>
        <base-radio
          v-for="paymentOption in paymentOptions"
          :key="paymentOption.type"
          v-model="paymentOptionsType"
          :disabled="
            !Boolean(amount) ||
            isLoadingProgramFees ||
            (isPointsOnlyOption(paymentOption) && !pointsMaxToUse.enough)
          "
          class="ml-0 -mt-px rounded-none border py-5 px-3"
          :value="paymentOption.type"
        >
          <div class="-my-px flex min-h-[33px] items-center">
            <div
              class="sm:max-w-auto flex w-3/4 flex-wrap items-center gap-x-3"
            >
              <div class="flex flex-col">
                <strong>{{ paymentOption.name }}</strong>
                <span
                  v-if="
                    isPointsOnlyOption(paymentOption) && pointsMaxToUse.points
                  "
                  class="text-sm"
                  :class="[
                    !pointsMaxToUse.enough
                      ? 'text-eonx-neutral-400'
                      : 'text-eonx-neutral-600',
                  ]"
                >
                  Pay using {{ pointsMaxToUse.points }} points
                </span>
              </div>

              <span
                v-if="paymentOption.notes"
                class="whitespace-normal text-eonx-neutral-600"
              >
                {{ paymentOption.notes }}
              </span>
            </div>

            <div class="ml-auto flex gap-x-2.5">
              <base-icon
                v-for="icon in paymentOption.icons"
                :key="icon"
                :svg="icon"
                :size="paymentOption.iconSize || 'unset'"
              />
            </div>
          </div>
        </base-radio>
      </div>

      <hint-pure v-for="(hint, index) in hints" :key="index" v-bind="hint" />
    </payments-make-section>

    <pay-by-cc
      v-if="isCreditCardOptionSelected"
      :loading="loading"
      :cards-list="cardsList"
      :on-add-new-payment-method="onAddNewPaymentMethod"
      :taxation="taxation"
      :processing-fee-options="processingFeeOptions"
      @update="handleMethodUpdate"
    />

    <pay-by-db
      v-else-if="isBankAccountOptionSelected"
      :loading="loading"
      :bank-accounts-list="bankAccountsList"
      :on-add-new-payment-method="onAddNewPaymentMethod"
      @update="handleMethodUpdate"
    />

    <pay-by-ps
      v-else-if="
        isPointsSplitOptionSelected && paymentSplitAllowed && hasPointsMethod
      "
      :loading="loading"
      :flow-type="flowType"
      :points="pointsBalance"
      :payment-methods="paymentMethodsList"
      :remaining-total="amount"
      :burn-points-rates="burnPointsRates"
      :on-add-new-payment-method="onAddNewPaymentMethod"
      :taxation="taxation"
      :processing-fee-options="processingFeeOptions"
      @update="handleMethodUpdate"
    />
  </fragment>
</template>
