import { ref, computed } from 'vue'

interface UseCvvCodeComponentProps {
  cardBrand: 'American Express' | 'amex' | 'Visa' | 'Mastercard'
  onConfirm?: (cvv: string) => void | Promise<void>
  onCancel?: () => void | Promise<void>
  hide?: () => void
}

export function useCvvCodeComponent(props: UseCvvCodeComponentProps) {
  const cvv = ref('')

  const cvvLength = computed(() => {
    switch (props.cardBrand) {
      case 'amex':
      case 'American Express':
        return 4
      default:
        return 3
    }
  })

  const cvvMask = computed(() => '0'.repeat(cvvLength.value))
  const cvvPlaceholder = computed(() => 'X'.repeat(cvvLength.value))
  const isDisabled = computed(() => cvv.value.length !== cvvLength.value)

  async function onConfirm() {
    if (isDisabled.value) {
      return
    }

    await props.onConfirm?.(cvv.value)
    props.hide?.()
  }

  async function onCancel() {
    await props.onCancel?.()
    props.hide?.()
  }

  return {
    cvv,
    cvvMask,
    cvvPlaceholder,
    isDisabled,
    onCancel,
    onConfirm,
  }
}
