<script>
import emitter from '/~/core/emitter'
import ui from '/~/core/ui'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import CheckoutCoupons from '/~/components/checkout/checkout-coupons.vue'
import { useAddresses } from '/~/composables/addresses'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import {
  usePaymentMethods,
  useVerifyCard,
} from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'
import CheckoutPay from './cart-checkout-pay.vue'
import CheckoutAddresses from '../../components/addresses/addresses.vue'
import CheckoutPayments from '../../components/payments/cart-payments.vue'
import CheckoutSummary from '../../components/summary/cart-checkout-summary.vue'

export default {
  name: 'cart-menu-checkout',
  components: {
    BaseAsidePage,
    CheckoutAddresses,
    CheckoutPayments,
    CheckoutSummary,
    CheckoutCoupons,
    CheckoutPay,
  },
  props: {
    close: {
      type: Function,
      default: null,
    },
    meta: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { fetchPaymentMethods } = usePaymentMethods()
    const { verifyingCard } = useVerifyCard()
    const {
      cart,
      fetchCart,
      cartIsEmpty,
      fetchCartOrderAddresses,
      cartOrderAddresses,
      cartShippingFee,
      cartProcessingFee,
    } = useCart()
    const {
      payment,
      readyToPay,
      selectedCard,
      payWithCard,
      pay,
      loadingFees,
      multipleSourcesSelected,
    } = useCheckout()
    const { addresses, getAddresses } = useAddresses()
    const { fetchCurrentPurchases } = usePurchases()
    const { isUserAddressEnabled } = useProvider()

    return {
      cart,
      fetchCart,
      fetchPaymentMethods,
      verifyingCard,
      cartIsEmpty,
      fetchCartOrderAddresses,
      cartOrderAddresses,
      cartShippingFee,
      cartProcessingFee,
      payment,
      readyToPay,
      selectedCard,
      payWithCard,
      pay,
      loadingFees,
      multipleSourcesSelected,
      formatDollar,
      addresses,
      getAddresses,
      fetchCurrentPurchases,
      ui,
      isUserAddressEnabled,
    }
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    processing() {
      return this.verifyingCard || this.payment.submitting || this.loadingFees
    },
    billingAddress() {
      const {
        addresses = [],
        cartOrderAddresses: { billing },
      } = this

      return addresses.find((address) => address.id === billing?.id)
    },
    payDisabled() {
      return (
        !this.readyToPay || (this.isUserAddressEnabled && !this.billingAddress)
      )
    },
    payLoading() {
      return this.isLoading || this.processing
    },
  },
  watch: {
    cartIsEmpty(isEmpty) {
      if (isEmpty) this.redirect()
    },
    addresses: {
      handler(value) {
        if (!value.length) {
          this.redirectToNewAddress()
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchInitialData()
  },
  methods: {
    redirect() {
      const splitHash = this.$route.hash.split('/')
      const hash = splitHash?.[0]

      if (hash === '#cart-checkout-confirmation') {
        return
      }

      this.$router.replace({ name: 'home' })

      return this.$notify({
        text: 'Your cart is currently empty',
        type: 'warning',
      })
    },
    redirectToNewAddress() {
      this.$router.push({ hash: '#cart-address-billing' })
    },
    fetchInitialData() {
      this.isLoading = true

      const promises = [
        this.fetchCart(),
        this.getAddresses(),
        this.fetchCartOrderAddresses(),
      ]

      return Promise.all(promises)
        .then(([, addresses]) => {
          if (this.cart.count && !addresses.length) {
            this.redirectToNewAddress()
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async onSubmit() {
      try {
        await this.payment.checkout({
          address: this.billingAddress,
          cardVerification: {
            modalsConfig: {
              mobile: true,
              to: 'cart-menu-modal',
            },
          },
          onComplete: this.onComplete,
        })
      } catch (error) {
        this.$notify({
          text: error,
          type: 'error',
          duration: 5000,
        })
      }
    },
    async onComplete(result) {
      try {
        await this.fetchCurrentPurchases()

        this.fetchCart()
        this.fetchPaymentMethods()
        emitter.emit('activity:refresh')

        this.$router.push({
          hash: `#cart-checkout-confirmation/${result.number}`,
        })
      } catch (error) {
        if (error.errors) {
          this.$router.push({
            hash: '#cart-payment-failed',
            query: { errors: error.errors, message: error.message },
          })
        }
      }
    },
  },
}
</script>

<template>
  <base-aside-page
    title="Checkout"
    :back="{ hash: '#cart-home' }"
    class="h-full"
    no-padding
  >
    <div class="flex h-full max-h-full flex-col overflow-hidden">
      <div class="flex-1 space-y-6 overflow-auto px-5 pt-[15px]">
        <checkout-addresses
          v-if="isUserAddressEnabled"
          :is-loading="isLoading"
        />

        <checkout-payments :is-loading="isLoading" class="mt-6" />

        <checkout-coupons />

        <div class="mt-10">
          <checkout-summary :loading="isLoading" />
        </div>
      </div>

      <div class="mt-auto p-[15px]">
        <checkout-pay
          :is-loading="payLoading"
          :is-disabled="payDisabled"
          @onSubmit="onSubmit"
        />
      </div>
    </div>
  </base-aside-page>
</template>
