<script>
import PointsTransferSuccessfulMobile from './points-transfer-successful.mobile.vue'
import PointsTransferSuccessfulDesktop from './points-transfer-successful.desktop.vue'
import ui from '/~/core/ui'

export default {
  name: 'fly-checkout-confirmation',
  functional: true,
  render(h, ctx) {
    let component

    if (ui.mobile) {
      component = PointsTransferSuccessfulMobile
    } else {
      component = PointsTransferSuccessfulDesktop
    }

    return h(component, ctx.data, ctx.children)
  },
}
</script>
