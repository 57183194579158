<script setup lang="ts">
import ui from '/~/core/ui'
import WgtWrapper from '../../wgt/wgt-wrapper.vue'
import WgtTile from '../../wgt/wgt-tile.vue'
import WidgetTilesNewContent from './widget-tiles-new-content.vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import { Row, Wrapper, useWrapper } from '/~/configurator/composables/wrapper'
import { computed, nextTick, onBeforeUnmount, onMounted } from 'vue'
import { Tile } from '/~/composables/cms/use-cms'
import { useSwiper } from '/~/composables/base/use-swiper'

const props = withDefaults(
  defineProps<{
    carouselOnDesktop?: boolean
    row?: Row
    wrapper?: Wrapper
    items: Tile[]
    autoplay?: string
  }>(),
  {
    carouselOnDesktop: false,
    autoplay: '',
  }
)

const { defaultWrapper, getTileKey } = useWrapper(props)

const overrideWrapper = computed(() => {
  const wrapper = { ...defaultWrapper.value }

  wrapper.space = true
  wrapper.row.md = wrapper.row.md ?? 4

  return wrapper
})

const autoplayDelay = computed(() => Number(props.autoplay))

const {
  swiperContainer,
  initSwiper: _initSwiper,
  destroySwiper,
} = useSwiper(
  computed(() => ({
    autoplay: autoplayDelay.value
      ? {
          delay: autoplayDelay.value,
          disableOnInteraction: false,
        }
      : undefined,
    resistanceRatio: 0.75,
    passiveListeners: false,
    loop: false,
    watchOverflow: true,
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 24,
  }))
)

onMounted(initSwiper)

function initSwiper() {
  if (ui.mobile) {
    return
  }

  nextTick(_initSwiper)
}

onBeforeUnmount(destroySwiper)
</script>

<template>
  <div
    v-if="carouselOnDesktop && ui.desktop"
    ref="swiperContainer"
    class="swiper-container overflow-hidden"
  >
    <div class="swiper-wrapper">
      <div v-for="(tile, idx) in items" :key="idx" class="swiper-slide">
        <wgt-tile :key="getTileKey(tile, idx)" :wrapper="overrideWrapper">
          <base-action
            v-if="tile.link && tile.link.enabled"
            v-bind="tile.link"
            appearance="link"
          >
            <widget-tiles-new-content :tile="tile" />
          </base-action>
          <widget-tiles-new-content v-else :tile="tile" />
        </wgt-tile>
      </div>
    </div>
  </div>
  <wgt-wrapper v-else :wrapper="overrideWrapper">
    <wgt-tile
      v-for="(tile, idx) in items"
      :key="getTileKey(tile, idx)"
      :wrapper="overrideWrapper"
    >
      <base-action
        v-if="tile.link && tile.link.enabled"
        v-bind="tile.link"
        appearance="link"
      >
        <widget-tiles-new-content :tile="tile" />
      </base-action>
      <widget-tiles-new-content v-else :tile="tile" />
    </wgt-tile>
  </wgt-wrapper>
</template>
