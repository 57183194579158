<script setup lang="ts">
import { computed } from 'vue'
import { createDate, formatDate } from '/~/utils/format'
import EwCategories from '/~/components/ewallet/controls/ew-categories.vue'
import EwDateRange from '/~/components/ewallet/controls/ew-date-range.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import { useActivity } from '/~/composables/activity'

const {
  categories,
  selectedCategory,
  isFiltersSelected,
  selectedStartDate,
  selectedEndDate,
  datepickerFormat,
  resetFilters,
} = useActivity()

const range = computed({
  get: () => [
    selectedStartDate.value
      ? (formatDate('daymonthyearnumeric', selectedStartDate.value) as string)
      : null,
    selectedEndDate.value
      ? (formatDate('daymonthyearnumeric', selectedEndDate.value) as string)
      : null,
  ],
  set: (value) => {
    selectedStartDate.value = value[0]
      ? +createDate(value[0], datepickerFormat.value)
      : null
    selectedEndDate.value = value[1]
      ? +createDate(value[1], datepickerFormat.value)
      : null
  },
})
</script>

<template>
  <div class="flex flex-col items-center sm:flex-row sm:space-x-5">
    <div class="flex w-full space-x-5 pt-5 sm:w-auto sm:p-0">
      <ew-categories
        :categories="categories"
        :selected-id="selectedCategory"
        :render-link="false"
        :filter-empty="false"
        title="Categories"
        class="grow sm:grow"
        @change="selectedCategory = $event"
      />

      <ew-filters
        ref="filters"
        title="Filters"
        :is-filters-selected="isFiltersSelected"
        @clear="resetFilters"
      >
        <ew-date-range v-model="range" title="Filter by date" />
      </ew-filters>
    </div>
  </div>
</template>
