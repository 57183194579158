import { defineComponent } from 'vue'
import { useProvider } from '/~/composables/provider'
import BaseAsidePageV1 from './base-aside-page.v1.vue'
import BaseAsidePageV2 from './base-aside-page.v2.vue'

export default defineComponent({
  name: 'base-aside-page',
  functional: true,
  render(h, ctx) {
    const { isBillPaymentsTemplate } = useProvider()

    let component

    if (isBillPaymentsTemplate.value) {
      component = BaseAsidePageV2
    } else {
      component = BaseAsidePageV1
    }

    return h(component, ctx.data, ctx.children)
  },
})
