<script>
import ChatRoomMessage from './chat-room-message.vue'
import debounce from 'lodash-es/debounce'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'rec-chat-messages',
  components: {
    ChatRoomMessage,
    BaseDate,
    BaseLoader,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      position: 0,
      viewedMessages: [],
    }
  },
  computed: {
    typingMembers() {
      return this.room.activeMembersWithoutMe.filter((member) => member.typing)
    },
    typingAlert() {
      const { typingMembers } = this

      if (this.room.isChannel) {
        if (typingMembers.length > 2) {
          return `${typingMembers.length} users typing...`
        }

        const names = typingMembers.map((member) => member.fullName).join(', ')

        return `${names} typing...`
      }

      return 'Typing...'
    },
  },
  watch: {
    'room.lastMessage'() {
      this.scrollBottom()
    },
    typingMembers() {
      this.scrollBottom()
    },
  },
  methods: {
    scrollBottom(force = false) {
      if (force || this.position < this.$el.clientHeight + 100) {
        debounce(() => {
          this.$el.scrollTop = this.$el.scrollHeight
          this.position = 0
        }, 100)()
      }
    },
    fixPosition() {
      this.$el.scrollTop = this.$el.scrollHeight - this.position
    },
    onScroll(e) {
      const { processing, allRecordsLoaded } = this.room.messages
      const el = e.target

      this.position = el.scrollHeight - el.scrollTop

      if (!processing && !allRecordsLoaded && el.scrollTop < 400) {
        this.$emit('load-more')
      }
    },
    debounceMethod: debounce(function () {
      if (this.viewedMessages.length > 0) {
        this.room.markMessagesAsViewed(this.viewedMessages)
        this.viewedMessages = []
      }
    }, 400),
    markAsRead(message) {
      const idViewed = message.views.find(
        (i) => i.participant_id === this.room.me.memberId
      )

      if (!message.isMeAuthor && !idViewed.viewed_at) {
        this.viewedMessages.push(message.id)
        this.debounceMethod()
      }
    },
  },
}
</script>

<template>
  <div class="overflow-y-auto overflow-x-hidden transition" @scroll="onScroll">
    <base-loader
      v-if="!room.messages.allRecordsLoaded"
      :style="{ opacity: room.messages.processing ? 1 : 0 }"
      class="my-5"
      fullwidth
    />
    <div v-for="{ day, messages } in room.messages.hitsByDate" :key="day">
      <div class="sticky top-0 z-30 mx-auto w-24 py-2.5">
        <span
          class="flex rounded-full bg-white px-2.5 py-[5px] text-xs font-bold uppercase"
        >
          <base-date
            :date="day"
            class="whitespace-nowrap text-xs text-eonx-neutral-600"
            type="day-spr"
          />
        </span>
      </div>
      <chat-room-message
        v-for="message in messages"
        :key="message.id + '_' + message.updatedAt"
        :message="message"
        :room="room"
        @viewed="markAsRead(message)"
        @edit="$emit('edit-message', $event)"
      />
    </div>
    <div v-if="typingMembers.length > 0" class="truncate px-6 py-2.5 text-sm">
      {{ typingAlert }}
    </div>
  </div>
</template>
