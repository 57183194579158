<script>
import cdn from '/~/utils/cdn'
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'quality-leads-banner',
  components: {
    BaseButton,
  },
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div class="relative">
    <div class="absolute px-[30px]">
      <div
        class="mt-5 text-3xl font-semibold leading-none text-white xs:mt-5 xs:text-3xl sm:text-4xl"
        :class="!showButton ? ' md:mt-16' : 'md:mt-[30px]'"
      >
        It’s easy to spot
        <br class="hidden sm:block" />
        quality leads
      </div>
      <div class="mt-2.5 font-semibold text-white xs:text-xl md:mt-5">
        Find the tips you need to submit
        <br />
        quality leads and earn points
      </div>
      <base-button
        v-if="showButton"
        class="mt-2.5"
        look="outlined"
        color="white"
        @click="$router.push({ name: 'quality-leads' })"
      >
        <span class="text-white">Learn more</span>
      </base-button>
    </div>
    <img
      class="hidden xs:block"
      :src="
        cdn('//ucarecdn.com/d7aec6d6-6c56-4933-a232-496d4b2ddbce/')
          .crop('1392x362')
          .preview('1200x400')
          .format('auto')
          .quality('smart')
          .url()
      "
    />
    <img
      class="w-full xs:hidden"
      :src="
        cdn('//ucarecdn.com/1f160d1f-786f-4023-a299-9772bb92bca1/')
          .crop('414x381')
          .preview('1200x400')
          .format('auto')
          .quality('smart')
          .url()
      "
    />
  </div>
</template>
