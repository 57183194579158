<script>
import AppMarketplace from './app-marketplace.vue'
import AppBottomBarItem from './app-bottom-bar-item.vue'
import { useCms } from '/~/composables/cms'
import { useUI } from '/~/composables/ui'

export default {
  name: 'app-bottom-bar',
  components: {
    AppMarketplace,
    AppBottomBarItem,
  },
  setup() {
    const { getMenuItems } = useCms()
    const { isStandalone } = useUI()

    return {
      getMenuItems,
      isStandalone,
    }
  },
  data() {
    return {
      expandedItem: null,
      showMarketplace: false,
    }
  },
  computed: {
    menuItems() {
      return this.getMenuItems('bottom-bar') || []
    },
    activeMenuItem() {
      const { $route } = this

      for (const item of this.menuItems) {
        if (item.action && item.action.type === 'page') {
          for (const route of $route.matched) {
            if (route.name === item.action.value) return item
          }
        }
        if (item.children && item.children.length > 0) {
          for (const child of item.children) {
            if (child.action && child.action.type === 'page') {
              for (const route of $route.matched) {
                if (route.name && route.name.indexOf(child.action.value) >= 0) {
                  return item
                }
              }
            }
          }
        }
      }
      return null
    },
    hidden() {
      return this.$route.meta.hideBottomBar
    },
  },
  watch: {
    expandedItem(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.showMarketplace = true
      }
    },
    showMarketplace(value) {
      if (!value) {
        this.onMenuItemClick(null)
      }
    },
  },
  methods: {
    onMenuItemClick(item) {
      this.expandedItem = item
    },
  },
}
</script>

<template>
  <footer
    v-show="!hidden"
    id="bottom-bar"
    :class="[
      'relative border-t bg-light sm:hidden',
      isStandalone && 'footer-extra',
    ]"
  >
    <portal-target name="mobile-footer">
      <ul class="flex h-14 items-center justify-between">
        <app-bottom-bar-item
          v-for="(item, idx) in menuItems"
          :key="item.id || idx"
          :item="item"
          :active-menu-item="activeMenuItem"
          @expand="onMenuItemClick"
        />
      </ul>
      <app-marketplace
        v-show="showMarketplace"
        :visible.sync="showMarketplace"
        :item="expandedItem"
      />
    </portal-target>
  </footer>
</template>
