<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import ui from '/~/core/ui'
import EwDetailsHeader from '/~/components/ewallet/ew-details-header.vue'

const route = useRoute()
const isHomePage = computed(() => route.name === 'home')
</script>

<template>
  <header
    :class="{
      'sm:pt-4': isHomePage || !ui.mobile,
      'relative flex h-[88px] items-center justify-between px-[15px] sm:px-6':
        !isHomePage && ui.mobile,
    }"
  >
    <template v-if="!isHomePage">
      <ew-details-header
        v-if="$route.meta?.title && $route.name !== 'home'"
        :title="$route.meta?.title"
        :back="{ name: 'home' }"
      />
      <portal-target name="app-header" slim />
    </template>
  </header>
</template>
