export default [
  {
    type: 'medical',
    value: 85,
  },
  {
    type: 'measurements',
    value: 73,
  },
  {
    type: 'social',
    value: 88,
  },
  {
    type: 'diet',
    value: 92,
  },
  {
    type: 'physical',
    value: 67,
  },
  {
    type: 'emotional',
    value: 91,
  },
]
