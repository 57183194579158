<script setup lang="ts">
import { nanoid } from 'nanoid'
import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useSwiper } from '/~/composables/base/use-swiper'
import { Tile } from '/~/composables/cms/use-cms'
import WidgetSlide from './widget-slide.vue'

const props = withDefaults(
  defineProps<{
    autoplay?: string
    items?: Tile[]
  }>(),
  {
    items: () => [],
  }
)

const swiperId = nanoid()
const enableSwipe = computed(() => props.items.length > 1)
const autoplayDelay = computed(() => Number(props.autoplay))

const { swiperContainer, initSwiper, destroySwiper, resetSwiper } = useSwiper(
  computed(() => ({
    autoplay: autoplayDelay.value
      ? {
          delay: autoplayDelay.value,
          disableOnInteraction: false,
        }
      : undefined,
    loop: true,
    navigation: {
      nextEl: `[data-id="${swiperId}"][data-button="next"]`,
      prevEl: `[data-id="${swiperId}"][data-button="prev"]`,
      disabledClass: 'hidden',
    },
    spaceBetween: 5,
    slidesPerView: 1,
    slidesPerGroup: 1,
    allowTouchMove: enableSwipe.value,
  }))
)

watch(() => props.items, resetSwiper, { deep: true })

onMounted(initSwiper)

onBeforeUnmount(destroySwiper)
</script>

<template>
  <section class="relative w-full rounded-lg" data-cy="widget-slider">
    <div
      v-if="items"
      ref="swiperContainer"
      class="swiper-container rounded-md shadow-md"
    >
      <div class="swiper-wrapper">
        <widget-slide
          v-for="(slide, index) in items"
          :key="index"
          v-bind="slide"
        />
      </div>
      <template v-if="enableSwipe">
        <button
          :data-id="swiperId"
          data-button="prev"
          class="absolute top-0 left-0 z-1 flex h-full w-11 items-center justify-center text-white opacity-50 transition duration-200 hover:opacity-100"
        >
          <base-icon svg="plain/chevronleft" size="md" />
        </button>
        <button
          :data-id="swiperId"
          data-button="next"
          class="absolute top-0 right-0 z-1 flex h-full w-11 items-center justify-center text-white opacity-50 transition duration-200 hover:opacity-100"
        >
          <base-icon svg="plain/chevronright" size="md" />
        </button>
      </template>
    </div>
  </section>
</template>
