<script>
import { cardCode } from '/~/utils/cards'
import { formatDollar } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'
import { useEwallet } from '/~/composables/ewallet'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'

export default {
  name: 'drawer-payment-from',
  components: {
    BaseIcon,
  },
  props: {
    from: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { ewalletLabels } = useCms()
    const { ewalletBalance } = useEwallet()
    const { pointsBalance } = usePoints()

    return {
      formatDollar,
      PaymentMethodType,
      ewalletLabels,
      ewalletBalance,
      pointsBalance,
    }
  },
  computed: {
    type() {
      return this.from.type
    },
    firstRowText() {
      const { from } = this

      switch (this.type) {
        case this.PaymentMethodType.eWallet:
          return this.ewalletLabels.ewalletCash
        case this.PaymentMethodType.points:
          return this.ewalletLabels.ewalletPoints
        case this.PaymentMethodType.creditCard:
          return `${from.name} ${from.brand}`
        case this.PaymentMethodType.bankAccount:
          return from.accountName || 'My Bank Account'
        default:
          return 'Payment Method'
      }
    },
    secondRowText() {
      const { from } = this

      switch (this.type) {
        case this.PaymentMethodType.eWallet:
          return formatDollar(this.ewalletBalance)
        case this.PaymentMethodType.points:
          return this.pointsBalance
        case this.PaymentMethodType.creditCard:
          return `**** **** **** ${from.number.slice(-4)}`
        case this.PaymentMethodType.bankAccount:
          return from.accountNumber
            .replace(/\./g, '*')
            .match(/.{0,3}/g)
            .join(' ')
        default:
          return ''
      }
    },
    thirdRowText() {
      const { from } = this

      switch (this.type) {
        case this.PaymentMethodType.bankAccount:
          return from.bsb
        case this.PaymentMethodType.creditCard:
        case this.PaymentMethodType.eWallet:
        case this.PaymentMethodType.points:
        default:
          return null
      }
    },
    icon() {
      switch (this.type) {
        case this.PaymentMethodType.eWallet:
          return { url: 'ewallet', class: 'bg-primary text-white' }
        case this.PaymentMethodType.points:
          return { url: 'points', class: 'bg-primary text-white' }
        case this.PaymentMethodType.creditCard: {
          const icon = cardCode(this.from)
          const url = `cards/${icon}` || 'plain/creditcards'

          return { url, class: 'text-primary' }
        }
        case this.PaymentMethodType.bankAccount:
          return { url: 'bank', class: 'text-primary' }
        default:
          return { url: 'plain/creditcards', class: 'bg-primary text-white' }
      }
    },
  },
  methods: {
    onClick() {
      if (!this.isDisabled) {
        this.$emit('click')
      }
    },
  },
}
</script>

<template>
  <div class="flex w-full items-center justify-between">
    <div
      class="flex h-12 w-12 items-center justify-center rounded-full"
      :class="[icon.class, isDisabled && 'cursor-not-allowed opacity-50']"
    >
      <base-icon :svg="icon.url" size="lg" />
    </div>

    <div
      class="flex-1 overflow-hidden pl-[15px]"
      :class="[isDisabled && 'cursor-not-allowed opacity-50']"
      @click="onClick"
    >
      <div class="truncate font-bold">
        {{ firstRowText }}
      </div>
      <div class="truncate text-sm text-eonx-neutral-600">
        {{ secondRowText }}
      </div>
      <div v-if="thirdRowText" class="text-xs text-eonx-neutral-600">
        {{ thirdRowText }}
      </div>
    </div>

    <div v-if="$slots.action" class="ml-5 shrink-0">
      <slot name="action" />
    </div>
  </div>
</template>
