<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'base-menu-item',
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <button
    v-if="!hidden"
    type="button"
    class="m-1 flex min-w-60 p-2 text-left hover:bg-primary hover:text-white"
    :class="[
      icon && 'whitespace-nowrap',
      disabled && 'pointer-events-none cursor-default text-disabled',
      !disabled && 'text-neutral-600',
    ]"
    @click.stop="$emit('click')"
  >
    <base-icon v-if="icon" :svg="icon" size="md" class="mr-2.5" />
    <span>
      <slot />
    </span>
  </button>
</template>
