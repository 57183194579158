<script setup lang="ts">
import { computed } from 'vue'
import { useApp } from '/~/composables/app'
import { useCms } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'

withDefaults(
  defineProps<{
    minHeight?: string | number | boolean
    maxWidth?: string | number
  }>(),
  {
    minHeight: false,
    maxWidth: 144,
  }
)

const { appLogo } = useApp()
const { providerTitle } = useProvider()
const { cmsConfig } = useCms()

const logoAlt = computed(
  () => cmsConfig.value['logo-alt'] || `${providerTitle.value} Logo`
)
</script>

<template>
  <div
    :style="{
      minHeight: minHeight && `${minHeight}px`,
      maxWidth: maxWidth && `${maxWidth}px`,
    }"
    class="flex items-center"
  >
    <img
      class="w-full object-contain"
      :class="{
        'h-full': minHeight,
        'h-14': !minHeight,
      }"
      :src="appLogo"
      :alt="logoAlt"
    />
  </div>
</template>
