import { lazyEwalletRoute } from '/~/utils/lazy'
import { useProvider } from '/~/composables/provider'

const { isBillPaymentsTemplate } = useProvider()

const routes = [
  {
    path: '#cart-home',
    name: 'cart-home',
    component: isBillPaymentsTemplate.value
      ? import('/~/components/cart-menu/views/home.v3/cart-home.vue')
      : import('/~/components/cart-menu/views/home.v2/cart-home.vue'),
    meta: {
      appear: null,
    },
  },
  {
    path: '#cart-checkout',
    name: 'cart-checkout',
    component: import(
      '/~/components/cart-menu/views/checkout/cart-checkout.vue'
    ),
  },
  {
    path: '#cart-billing',
    name: 'cart-billing',
    component: import('./views/addresses/cart-addresses.vue'),
    meta: {
      type: 'billing',
    },
  },
  {
    path: '#cart-shipping',
    name: 'cart-shipping',
    component: import('./views/addresses/cart-addresses.vue'),
    meta: {
      type: 'shipping',
    },
  },
  {
    path: '#cart-address-billing/:id',
    name: 'cart-address-billing',
    component: import('./views/addresses/cart-address-form.vue'),
    meta: {
      type: 'billing',
    },
  },
  {
    path: '#cart-address-shipping',
    name: 'cart-address-shipping',
    component: import('./views/addresses/cart-address-form.vue'),
    meta: {
      type: 'shipping',
    },
  },
  {
    path: '#cart-payment-methods',
    name: 'cart-payment-methods',
    component: import('./views/payment/cart-payment-methods.vue'),
  },
  {
    path: '#cart-payment-methods-add',
    name: 'cart-payment-methods-add',
    component: import('./views/payment/cart-payment-method-add.vue'),
  },
  {
    path: '#cart-checkout-confirmation',
    name: 'cart-checkout-confirmation',
    component: import('./views/confirmation/cart-checkout-confirmation.vue'),
  },
  {
    path: '#cart-payment-failed',
    name: 'cart-payment-failed',
    component: import('./views/payment-failed/payment-failed.vue'),
  },
]

export default routes.map((route) => ({
  ...route,
  component: lazyEwalletRoute(route.component),
  meta: {
    appear: 'right',
    ...route.meta,
  },
}))
