import { computed } from 'vue'
import { useExtensions } from '/~/composables/extensions'
import { useLocalization } from '/~/composables/localization'

const { getConfigByName } = useExtensions()
const { formatBusinessNumber } = useLocalization()

const pointsProgramConfig = computed(() => {
  return getConfigByName('points-program')
})
const pointsPrograms = computed(() => {
  return pointsProgramConfig.value?.pointsPrograms ?? []
})

export type PointsPartnerResponse = {
  firstName?: string
  lastName?: string
  externalId?: string
  accountNumber?: string
  count?: number
  type?: string
  icon?: string
}

export class PointsPartner {
  raw: PointsPartnerResponse
  firstName: string
  lastName: string
  id: string
  number: string
  count: number
  type: string

  constructor(raw: PointsPartnerResponse = {}) {
    this.raw = raw
    const program = pointsPrograms.value.find(
      (item: { type: string }) => item.type === raw.type
    )

    this.firstName = raw.firstName ?? ''
    this.lastName = raw.lastName ?? ''
    this.id = raw.externalId ?? ''
    this.number = `${program.accountNumberLabel} ${formatBusinessNumber(
      raw.accountNumber ?? ''
    )}`.trim()
    this.count = raw.count ?? 0
    this.type = raw.type ?? ''
  }
}
