<script setup lang="ts">
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import { useCms } from '/~/composables/cms'

const { providerAccountDetailsImage } = useCms()
</script>

<template>
  <base-mdl width="md" fullscreen="mobile">
    <base-image
      v-if="providerAccountDetailsImage.image"
      :src="providerAccountDetailsImage.image"
      :ratio="false"
      :alt="providerAccountDetailsImage.alt"
    />
  </base-mdl>
</template>
