<script setup lang="ts">
import { computed, ref, getCurrentInstance } from 'vue'
import { useGroups } from '/~rec/composables/groups'
import { usePostCreator } from '/~rec/composables/post-creator'
import { useBackendValidation } from '/~rec/composables/backend-validation'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PostCreatorFooter from '../post-creator-footer.vue'
import RichInput from '/~rec/components/rich-input/rich-input.vue'
import { RecroomEvent } from '/~/extensions/rec-room/core/event'
import { RecroomPost } from '/~/extensions/rec-room/core/post'
import { ValidationObserver } from 'vee-validate'

const props = withDefaults(
  defineProps<{
    source?: RecroomEvent
    post?: RecroomPost
    announcement?: boolean
    sourceHidden?: boolean
  }>(),
  {
    sourceHidden: false,
    announcement: false,
  }
)

const emit = defineEmits<{
  (event: 'success'): void
  (event: 'update:loading', flag: boolean): void
}>()

const { backendErrors, processBackendErrors } = useBackendValidation()
const { allMembers } = useGroups()
const internalInstance = getCurrentInstance()

function getPayload() {
  return {
    announcement: props.announcement,
  }
}

const {
  validationObserverRef,
  isEdit,
  content,
  attachments,
  mentioned,
  linksPreviews,
  linkPreviewsProcessing,
  loading,
  isOffensiveWarningShow,
  isAndriod,
  input,
  onEmojiSelect,
  onInput,
  removePreview,
  updateMentioned,
  onSend,
} = usePostCreator({
  post: props.post,
  source: props.source,
  getPayload,
  emit,
  internalInstance,
  processBackendErrors,
})

const inputLabel = computed(() =>
  props.announcement ? 'Write your Announcement' : 'Write Something'
)
const isSendDisabled = computed(
  () =>
    (!content.value.trim() && !attachments.value.length) ||
    !!(input.value?.validationProviderRef?.failedRules['offensive'])
)
</script>

<template>
  <validation-observer
    ref="validationObserverRef"
    slim
  >
    <div class="flex flex-col">
      <div class="flex-1 p-5 pt-0 md:pt-5">
        <div
          v-if="!isEdit && !sourceHidden"
          class="mb-2.5 truncate text-xs font-bold"
        >
          <span class="text-disabled">To News Feed:</span>
          <span v-if="source">
            {{ source.name }}
          </span>
        </div>

        <rich-input
          v-bind="$attrs"
          ref="input"
          :validation="{
            rules: 'offensive|max:1200',
            mode: 'aggressive',
          }"
          :error="backendErrors.content"
          :content.sync="content"
          :mention-users="allMembers"
          :mentioned="mentioned"
          :loading="loading"
          :disable-send="isSendDisabled"
          :placeholder="inputLabel"
          name="content"
          focused
          plain
          :max-length="1200"
          :is-post="true"
          :is-andriod="isAndriod"
          :is-offensive-warning-show="isOffensiveWarningShow"
          v-on="$listeners"
          @send="onSend"
          @input="onInput"
          @update:mentioned="updateMentioned"
        />
      </div>

      <div class="px-5">
        <div v-if="linkPreviewsProcessing" class="mb-5 flex">
          <base-loader size="sm" class="mx-auto" />
        </div>
        <div class="flex w-full flex-col space-y-5">
          <div
            v-for="(preview, idx) in linksPreviews"
            :key="idx + preview.url"
            class="relative flex w-full flex-col overflow-hidden rounded-lg border border-divider"
          >
            <div
              class="absolute right-0 mr-2.5 mt-2.5 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-300 text-eonx-neutral-800 hover:bg-gray-400"
              @click="removePreview(idx)"
            >
              <base-icon svg="close" :size="22" />
            </div>
            <div class="h-full w-full">
              <img :src="preview.image" class="w-full" />
            </div>
            <div
              class="flex flex-col space-y-[5px] bg-gray-50 py-[15px] px-6 text-eonx-neutral-800"
            >
              <span class="text-lg font-semibold">
                {{ preview.title }}
              </span>
              <span class="text-eonx-neutral-600">
                {{ preview.hostname }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <post-creator-footer
        :attachments.sync="attachments"
        :post="post"
        type="post"
        :disabled="isSendDisabled"
        :is-andriod="isAndriod"
        @emoji="onEmojiSelect"
        @send="onSend"
      />
    </div>
  </validation-observer>
</template>
