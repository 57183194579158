<script>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import BaseBack from '/~/components/base/back/base-back.vue'
import PaymentsPointsForm from '/~/components/payments/points/payments-points-form.vue'
import { useCheckoutReactive, useCheckoutRoutes } from '/~/composables/checkout'
import { useFlowPaymentMethods } from '/~/composables/payment-methods'

export default {
  name: 'checkout-points-desktop',
  components: {
    BaseBack,
    PaymentsPointsForm,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { payment, currentFlowType } = useCheckoutReactive()
    const { getCurrentFlowRouteName } = useCheckoutRoutes()
    const { points } = useFlowPaymentMethods(
      currentFlowType.value,
      payment.value.payeeId
    )

    const redirect = computed(() => ({
      name: getCurrentFlowRouteName('checkout'),
      ...route.meta?.back,
      query: route.query,
    }))

    const initialValue = ref(payment.value.selectedPoints?.usePoints ?? 0)

    const remainingTotal = computed(() => {
      return Math.max(payment.value.subTotalWithProgramFeesNoCoupons, 0)
    })

    function onConfirm({ selected, calculatedAmount }) {
      payment.value.resetPaymentMethods()

      payment.value.selectedPoints = {
        ...points.value[0],
        ...payment.value.selectedPoints,
        usePoints: selected,
        calculatedAmount,
      }

      payment.value.getTransactionFees()

      router.push(redirect.value)
    }

    function onCancel() {
      router.replace(redirect.value)
    }

    return {
      initialValue,
      remainingTotal,
      redirect,
      onConfirm,
      onCancel,
    }
  },
}
</script>

<template>
  <div
    class="flex h-full w-full flex-col items-center overflow-hidden px-10 py-8"
  >
    <div class="w-full max-w-xl px-3 pb-8">
      <base-back
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: 'Checkout',
          component1: 'Points select',
          cta: 'Back',
        }"
        class="block self-start leading-5"
        :to="redirect"
      />
    </div>
    <div class="h-full w-full overflow-y-auto">
      <div
        class="mx-auto mb-2 flex w-full max-w-xl shrink-0 flex-wrap justify-between px-2"
      >
        <span class="text-2xl font-bold text-eonx-neutral-800">
          Pay with points
        </span>
      </div>
      <div class="mx-auto mb-8 w-full max-w-xl px-2 text-eonx-neutral-600">
        Allocate the amount of points you wish to use for this transaction.
      </div>

      <div class="mx-auto w-full max-w-xl px-2">
        <div
          class="relative flex w-full max-w-xl flex-col items-center rounded-lg border border-eonx-neutral-200 bg-white p-8"
        >
          <payments-points-form
            v-analytics:confirm="{
              pageGroup: 'Make a payment',
              page: 'Checkout',
              component1: 'Points select',
              cta: 'Confirm',
            }"
            :initial-value="initialValue"
            :remaining-total="remainingTotal"
            @confirm="onConfirm"
          />
        </div>
      </div>
    </div>
  </div>
</template>
