import { AlgoliaWorker } from '/~/core/algolia'
import Recipe from './recipe'

export default class HealthRecipesProcessor extends AlgoliaWorker {
  constructor(params) {
    super({
      algolia_app_id: 'Q52UXC6ULJ',
      algolia_search_key: '3a7406c0ee81ab96cf33ee79d647013a',
      mapping: (data) => new Recipe(data),
      ...params,
    })
  }

  setIndex(index) {
    this.params.index = index
  }

  setFilter(filters) {
    this.params.search = {
      ...this.params.search,
      ...filters,
    }
  }
}
