<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useRecProfile } from '/~rec/composables/profile'
import { useLimits } from '/~rec/composables/limits'

export default {
  name: 'rec-earn-info',
  components: {
    BaseMdl,
    BaseButton,
    BaseCheckbox,
    BasePlaceholder,
    BaseIcon,
  },
  props: {
    mode: {
      type: String,
      default: 'awards', // 'hi5s'
    },
  },
  setup() {
    const { displayPointsPopup, setDisplayPointsPopup } = useRecProfile()
    const { globalEarnStatus } = useLimits()

    return {
      globalEarnStatus,
      displayPointsPopup,
      setDisplayPointsPopup,
      ui,
    }
  },
  data() {
    return {
      isShowAgain: false,
    }
  },
  computed: {
    blocks() {
      let items = []

      if (this.mode === 'awards') {
        items = [
          this.globalEarnStatus.awardNominate,
          this.globalEarnStatus.awardGetNominated,
        ]
      }
      if (this.mode === 'hi5s') {
        items = [
          this.globalEarnStatus.Hi5Send,
          this.globalEarnStatus.Hi5Receive,
        ]
      }
      return items
    },
    loading() {
      return this.globalEarnStatus.processing
    },
  },
  created() {
    this.globalEarnStatus.getData({
      filter: this.mode,
    })
    this.isShowAgain = !this.displayPointsPopup
  },
  methods: {
    close() {
      modal.hide()
    },
    gotIt() {
      this.setDisplayPointsPopup(this.isShowAgain)
      modal.hide()
    },
    getWidth(total, current) {
      const countPersent = (current * 100) / total

      return total ? `${countPersent > 100 ? 100 : countPersent}%` : 0
    },
  },
}
</script>

<template>
  <base-mdl
    :width="!ui.mobile ? 'sm' : 'screen'"
    transition="slide-bottom"
    class="ease transform duration-500"
    :modal-styles="ui.mobile ? 'max-height:40rem; margin-bottom:0' : ''"
    modal-class="rounded-t-3xl"
    fullscreen="mobile"
    :closable="false"
  >
    <template #header>
      <div />
    </template>
    <div class="flex h-full flex-col justify-between">
      <div
        class="flex items-center justify-end py-5 pl-5"
        :class="{
          'min-h-16': ui.mobile,
        }"
      >
        <div v-if="ui.mobile" class="fixed z-1 bg-white">
          <base-button look="link" @click="close">
            <div class="flex cursor-pointer items-center">
              <span class="mr-2.5">Close</span>
              <base-icon svg="close" size="md" class="self-end" />
            </div>
          </base-button>
        </div>
      </div>
      <div class="relative flex items-center justify-center">
        <img class="mx-auto my-5 h-48" src="/icons/v3/token-earns.svg" />
      </div>

      <div class="flex h-full flex-col justify-center">
        <h2 class="mb-[15px] text-center leading-tight">Earn points</h2>
      </div>

      <div v-if="loading" class="m-5">
        <base-placeholder :height="140" theme="dark" />

        <base-placeholder class="mt-5" :height="140" theme="dark" />
      </div>

      <div v-else>
        <div
          v-for="(block, index) in blocks"
          :key="index"
          class="mx-[5px] mt-5"
        >
          <h3 class="text-base font-bold text-eonx-neutral-800 md:text-lg">
            {{ block.title }}
          </h3>

          <div class="rounded-b-lg bg-light pt-2.5">
            <p class="mb-[15px] text-base text-eonx-neutral-600">
              {{ block.message }}
            </p>
            <div
              class="relative mb-2.5 h-2.5 w-full rounded bg-eonx-neutral-50"
            >
              <div
                class="absolute h-2.5 rounded bg-emerald-700"
                :style="{ width: getWidth(block.max, block.today) }"
              />
            </div>
            <div class="flex flex-wrap justify-between">
              <p class="text-left text-emerald-700">
                {{ block.today }}/{{ block.max }} points earned
              </p>
              <p class="text-right text-emerald-700">
                {{ block.fraction }} nominations today
              </p>
            </div>
          </div>
        </div>
      </div>

      <base-checkbox v-model="isShowAgain" class="my-5">
        <div class="text-sm font-bold">Don’t show this message again</div>
      </base-checkbox>

      <div class="w-full pb-5">
        <base-button full-width @click="gotIt">Got it</base-button>
      </div>
    </div>
  </base-mdl>
</template>
