<script>
import ui from '/~/core/ui'
import BaseImage from '/~/components/base/image/base-image.vue'
import { useProvider } from '/~/composables/provider'
import { computed } from 'vue'
import { getProviderDefaultUrl } from '/~/utils/cdn'

export default {
  name: 'competition-logo',
  components: {
    BaseImage,
  },
  props: {
    competition: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isBupaTemplate } = useProvider()

    const defaultImage = computed(() =>
      isBupaTemplate.value ? getProviderDefaultUrl('bupa') : undefined
    )
    const image = computed(() => props.competition.image)

    return {
      isBupaTemplate,
      image,
      defaultImage,
      ui,
    }
  },
}
</script>

<template>
  <div class="h-auto w-full" data-test="image">
    <base-image
      :src="image"
      :default-src="defaultImage"
      :alt="competition.title"
      :rounded="!ui.mobile"
      :ratio="4 / 3"
    />
  </div>
</template>
