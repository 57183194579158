<script>
import { ref } from 'vue'
import { LoyaltyCard } from '/~/core/loyalty-card'
import { useLoyaltyCards } from '/~/composables/loyalty-cards'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import LoyaltyCardForm from './components/card-form.vue'

export default {
  name: 'loyalty-create',
  components: {
    LoyaltyCardForm,
    BaseAsidePage,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { getCatalogCardById, isCatalogCardsLoading } = useLoyaltyCards()

    const card = ref(null)

    if (props.id === 'other') {
      card.value = new LoyaltyCard({
        name: 'Other',
        color: '#00B894',
        external_id: 'other',
        catalogue_card_slug: 'other',
      })
    } else {
      getCatalogCardById(props.id).then((catalogCard) => {
        card.value = catalogCard
      })
    }

    return {
      card,
      isCatalogCardsLoading,
    }
  },
}
</script>

<template>
  <base-aside-page :title="`Add ${card ? card.name : 'Card'}`">
    <div v-if="isCatalogCardsLoading" class="space-y-[15px]">
      <div class="h-5 animate-pulse rounded-xl bg-divider" />
      <div class="h-5 animate-pulse rounded-xl bg-divider" />
      <div class="h-5 animate-pulse rounded-xl bg-divider" />
    </div>

    <loyalty-card-form
      v-else-if="card"
      :card="card"
      @updated="$emit('submit', $event)"
    />
  </base-aside-page>
</template>
