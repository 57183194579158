<script lang="ts" setup>
import { ValidationObserver } from 'vee-validate'
import { ref, computed, onBeforeMount, nextTick } from 'vue'
import { useRouter } from 'vue-router/composables'
import api from '/~/core/api'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import { useUser } from '/~/composables/user'
import { useWithdraw } from '/~/composables/withdraw/use-withdraw'

const { backendErrors, processBackendErrors } = useBackendValidation()
const { bankAccountSchema, form } = useBankAccountForm()
const { user } = useUser()
const router = useRouter()
const { getWithdrawalBankAccounts } = useWithdraw()
const { fetchPaymentMethods } = usePaymentMethods()
const { validationObserverRef } = useForm()

const tacChecked = ref(false)
const submitting = ref(false)
const bankAccountForm = ref(null)

const hasErrors = computed(() => validationObserverRef.value?.flags.invalid)
const canProceed = computed(() => {
  return !submitting.value && !hasErrors.value && tacChecked.value
})

onBeforeMount(() => {
  form.value.accountName = user.value.fullName
  nextTick(() => {
    ;(bankAccountForm.value as any)?.$refs['accountName'][0].validate(
      form.value.accountName
    )
  })
})

function showTermsAndConditionsModal() {
  // maybe need another terms-conditions?
  if (ui.mobile) {
    bottomSheet.show('terms-conditions')
  } else {
    modal.show('terms-conditions')
  }
}

async function createBankAccount() {
  submitting.value = true

  try {
    const payload: any = { ...form.value }

    payload.termsAndConditions = tacChecked.value

    const { data } = await api.post(
      '/v3/payment-methods/withdrawal-bank-accounts',
      payload
    )

    if ((data as any)?.status === 'new') {
      bottomSheet.show('method-verify', {
        to: 'menu-modal',
        props: {
          method: data,
          title: 'Verify account',
          onConfirm: () => {
            goWithdraw()
          },
          onCancel: () => {
            goWithdraw()
          },
        },
      })
    }
  } catch (exception: any) {
    processBackendErrors(exception.data || exception)
  } finally {
    submitting.value = false
  }
}

function goWithdraw() {
  getWithdrawalBankAccounts()
  fetchPaymentMethods()
  router.push({ hash: '#profile/withdraw-funds' })
}

function onCancel() {
  router.back()
}
</script>

<template>
  <base-aside-page title="Add Bank Account" no-padding class="bg-white">
    <div class="flex h-full flex-col items-stretch justify-between">
      <validation-observer
        v-slot="{ valid, pending }"
        ref="validationObserverRef"
        tag="form"
        class="flex w-full grow flex-col"
        @submit.prevent="createBankAccount"
      >
        <div class="px-5">
          <base-metafield
            ref="bankAccountForm"
            v-model="form"
            :submitting="submitting"
            :schema="bankAccountSchema"
            :backend-errors="backendErrors"
          />
        </div>

        <div class="border-t border-eonx-neutral-200 p-5">
          <div
            class="mb-5 flex cursor-pointer items-center space-x-[5px] hover:opacity-80"
          >
            <base-checkbox
              v-model="tacChecked"
              :disabled="submitting"
              look="v2"
            >
              <span class="font-normal text-default">
                I agree to
                <base-action
                  class="font-semibold text-primary underline"
                  type="link"
                  :click-only="true"
                  @click="showTermsAndConditionsModal"
                >
                  Terms & Conditions
                </base-action>
              </span>
            </base-checkbox>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <base-button look="outlined-color" class="w-full" @click="onCancel">
              Cancel
            </base-button>
            <base-button
              class="w-full"
              :disabled="!canProceed || !valid || pending"
              :loading="submitting"
              type="submit"
            >
              Confirm
            </base-button>
          </div>
        </div>
      </validation-observer>
    </div>
  </base-aside-page>
</template>
