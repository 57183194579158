<script>
import ui from '/~/core/ui'
import DeskFilter from './competitions-filter.desk.vue'
import MobileFilter from './competitions-filter.mobile.vue'

export default {
  name: 'competitions-filter',
  components: {
    DeskFilter,
    MobileFilter,
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <mobile-filter v-if="ui.mobile" />
  <desk-filter v-else />
</template>
