<script setup lang="ts">
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useCvvCodeComponent } from '/~/composables/payment-methods/use-cvv-code-component'

const props = defineProps<{
  cardBrand: 'American Express' | 'Visa' | 'Mastercard'
  onConfirm?: (cvv: string) => void
  onCancel?: () => void
}>()

const emit = defineEmits<(event: 'hide') => void>()

const { cvv, cvvMask, cvvPlaceholder, isDisabled, onConfirm, onCancel } =
  useCvvCodeComponent({
    cardBrand: props.cardBrand,
    onConfirm: props.onConfirm,
    onCancel: props.onCancel,
    hide,
  })

function hide() {
  emit('hide')
}
</script>

<template>
  <div class="relative px-5 pt-14">
    <button
      v-analytics:click="{
        pageGroup: 'Make a payment',
        component1: 'Card CVC',
        cta: 'Cancel',
      }"
      type="button"
      class="absolute top-0 right-0 mt-2.5 mr-2.5 p-[15px]"
      @click="onCancel"
    >
      <base-icon svg="base/close" :size="16" />
    </button>

    <base-icon
      svg="symbion/card-cvv"
      :size="80"
      class="mx-auto max-w-40 text-primary"
    />

    <p class="text-center text-2xl font-bold">Enter your card CVC</p>

    <div class="h-16 w-full">
      <base-input
        ref="cvvInput"
        v-model="cvv"
        v-analytics:keyup="
          (e) => {
            if (e.key === 'Enter' && !isDisabled) {
              return {
                pageGroup: 'Make a payment',
                component1: 'Card CVC',
                label: 'CVV submitted',
              }
            }
          }
        "
        :placeholder="cvvPlaceholder"
        class="mx-auto w-20 text-center"
        :mask="{ mask: cvvMask }"
        name="cvv"
        @keyup.enter="onConfirm"
      />
    </div>

    <base-button
      v-analytics:click="{
        pageGroup: 'Make a payment',
        component1: 'Card CVC',
        cta: 'Confirm',
      }"
      color="primary"
      class="mt-11 mb-5 w-full"
      :disabled="isDisabled"
      @click="onConfirm"
    >
      Confirm
    </base-button>
  </div>
</template>
