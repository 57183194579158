<script>
import { computed } from 'vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'ewallet-shortcuts',
  components: {
    BaseAction,
    BaseIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const actions = computed(() => {
      return props.items.slice(0, 4)
    })
    const columnsCount = computed(() => {
      return Math.min(actions.value.length, 3)
    })

    return {
      actions,
      columnsCount,
    }
  },
}
</script>

<template>
  <div
    class="grid w-full gap-x-3"
    :class="{
      [`grid-cols-${columnsCount}`]: true,
    }"
  >
    <base-action
      v-for="(item, index) in actions"
      v-bind="item.action"
      :key="index"
      class="block"
      :type="item.action ? item.action.type : 'none'"
    >
      <template #entry>
        <div
          class="flex w-full shrink-0 grow items-center justify-center rounded-md py-2.5 px-0 text-center transition duration-150 hover:shadow"
          :class="{
            'bg-primary-lightest': !item.bgColor,
            'text-primary-darkest': !item.textColor,
          }"
          :style="{
            backgroundColor: item.bgColor,
            color: item.textColor,
          }"
        >
          <base-icon
            v-if="item.icon"
            :svg="item.icon"
            :size="24"
            class="mr-1"
          />
          <span class="text-sm font-semibold">
            {{ item.label }}
          </span>
        </div>
      </template>
    </base-action>
  </div>
</template>
