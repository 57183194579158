<script setup lang="ts">
import { computed } from 'vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import { Image, Link } from '/~/composables/cms/use-cms'
import WgtImage from '../../wgt/wgt-image.vue'

const props = withDefaults(
  defineProps<{
    image?: Image
    align?: string
    width?: string
    action?: Link
  }>(),
  {
    align: 'center',
    width: '100',
  }
)

const isAlignCenter = computed(() => props.align === 'center')
const isAlignRight = computed(() => props.align === 'right')
</script>

<template>
  <div
    :class="{
      'mx-auto': isAlignCenter,
      'ml-auto': isAlignRight,
    }"
    :style="{
      width: `${width}px`,
    }"
  >
    <base-action
      v-if="action && action.value && action.type !== 'none'"
      v-bind="action"
      :appearance="null"
    >
      <wgt-image :image="image" />
    </base-action>
    <wgt-image v-else :image="image" />
  </div>
</template>
