<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'

export default {
  name: 'dining-checkboxes',
  components: {
    BaseButton,
    BaseCheckbox,
    BaseCollapse,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    filters: {
      type: [Object, Array],
      default: () => ({}),
    },
    scrollToId: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filtersState: this.value,
      showAll: false,
      expanded: true,
    }
  },
  computed: {
    values() {
      const list = (this.filters || [])
        .filter((item) => item.count > 0)
        .sort((a, b) => {
          return b.count - a.count
        })

      return this.showAll ? list : list.slice(0, 5)
    },
  },
  watch: {
    filtersState(filtersState) {
      this.$emit('input', filtersState)
    },
    value: {
      handler() {
        this.filtersState = this.value
      },
      deep: true,
    },
  },
  methods: {
    clearFilter() {
      if (this.scrollToId) {
        this.$scrollTo('#' + this.scrollToId)
      }
      this.filtersState = []
    },
    toggleExpand() {
      this.expanded = !this.expanded
    },
  },
}
</script>

<template>
  <div class="mb-5">
    <base-collapse v-model="expanded" show-icon :autoscroll="false">
      <template #trigger>
        <span class="text-sm font-bold">
          {{ title }}
        </span>
      </template>
      <span>
        <base-checkbox
          v-for="(item, key) in values"
          :key="key"
          v-model="filtersState"
          class="mt-2.5 text-sm font-bold"
          :value="item.id && item.label"
          @click.stop
        >
          {{ item.label }}
          <span v-if="showCount">({{ item.count }})</span>
        </base-checkbox>
      </span>
      <base-button
        v-if="filters.length > 5"
        look="link"
        class="mt-2.5 text-sm font-bold text-primary"
        @click="showAll = !showAll"
      >
        {{ showAll ? 'Show less' : 'Show all' }}
      </base-button>
    </base-collapse>
  </div>
</template>
