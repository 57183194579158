<script>
import BaseState from '/~/components/base/state/base-state.vue'
import EpurchaseTile from '/~/components/drawer/components/tiles/drawer-e-purchases-tile.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { usePurchases } from '/~/composables/purchases'

export default {
  name: 'drawer-e-purchases-archived',
  components: {
    EpurchaseTile,
    EwCatalogProcessor,
    BaseState,
  },
  setup() {
    const { purchases, fetchArchivedPurchases } = usePurchases()

    fetchArchivedPurchases()

    return {
      purchases,
    }
  },
}
</script>

<template>
  <ew-catalog-processor
    :processor="purchases.archived"
    :columns="{ base: 2 }"
    catalog-class="gap-5"
    tile-class="hover:-translate-y-[5px]"
    no-padding
    class="px-5"
  >
    <template #empty>
      <base-state
        title="No archived ePurchases"
        image="/empty/empty-epurchases.svg"
        class="min-h-full py-5"
      >
        <p>
          This is where you can file and retrieve your already used eGift Cards
          and eVouchers.
        </p>
      </base-state>
    </template>

    <template #tile="{ tile }">
      <epurchase-tile :key="tile.id" :card="tile" extended archived />
    </template>
  </ew-catalog-processor>
</template>
