<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { usePhotoIdsTransactions } from '/~/composables/photo-ids'
import Vue from 'vue'

export default {
  name: 'drawer-photo-ids-add',
  components: {
    BaseButton,
    BaseAsidePage,
    BaseLoader,
  },
  setup() {
    const { isPhotoIdsTransactionsLoading, createPhotoIdsTransaction } =
      usePhotoIdsTransactions()

    async function onCreateNew() {
      try {
        await createPhotoIdsTransaction()
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: error.message || 'Something went wrong, please try again later',
        })
      }
    }

    return {
      isPhotoIdsTransactionsLoading,
      onCreateNew,
    }
  },
}
</script>

<template>
  <base-aside-page title="Add new ID">
    <base-loader v-if="isPhotoIdsTransactionsLoading" fullwidth />
    <template v-else>
      <div class="text-xl2 mb-[30px] text-center font-bold">
        Something went wrong, please try again
      </div>
      <div class="flex">
        <base-button class="mr-2.5 grow" type="primary" @click="onCreateNew">
          Retry
        </base-button>
      </div>
    </template>
  </base-aside-page>
</template>
