export class VirtualCard {
  id = ''
  number = ''
  status = ''
  createdAt = ''
  sensitiveData = {
    isFetching: false,
    isFetched: false,
    expiryMonth: null,
    expiryYear: null,
    number: null,
    verificationCode: null,
  }

  isUpdating = false

  constructor(raw = {}) {
    Object.keys(raw).forEach((key) => {
      this[key] = raw[key] ?? this[key]

      switch (key) {
        case 'number':
          this[key] =
            this[key].substr(0, 4) + ' **** **** ' + this[key].substr(-4)
      }
    })
  }

  get isLocked() {
    return this.status === 'inactive'
  }

  get isUnlocked() {
    return !this.isLocked
  }
}

export default VirtualCard
