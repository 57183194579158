<script>
import get from 'lodash-es/get'
import { computed } from 'vue'
import cdn, { getProviderDefaultUrl } from '/~/utils/cdn'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'

export default {
  name: 'gift-cards-cinema-tile-bupa',
  components: {
    BaseLink,
    BaseImage,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const defaultImage = computed(() => getProviderDefaultUrl('bupa'))

    return {
      defaultImage,
    }
  },
  data() {
    return {
      routeName: 'cinema-location',
    }
  },
  computed: {
    imgSource() {
      const altLogo = this.tile.retailer_alt_logo
      const url = this.cdn(altLogo).url()
      const best = cdn(url)
        .preview('300x300')
        .format('auto')
        .quality('smart')
        .url()
      const better = cdn(url)
        .preview('600x600')
        .format('auto')
        .quality('smart')
        .url()

      return {
        src: best,
        srcset: `${best} 1x, ${better} 2x`,
      }
    },
    offerName() {
      return get(this.tile, 'offer_name', '')
    },
    alt() {
      return `${this.tile.retailer_name} ${this.tile.offer_subtitle}`
    },
  },
}
</script>

<template>
  <base-link
    :to="{
      name: routeName,
      params: {
        slug: tile.retailer_slug,
      },
    }"
    class="group relative z-0 block h-full"
  >
    <base-image
      v-bind="imgSource"
      :alt="alt"
      :default-src="defaultImage"
      class="rounded-lg shadow-xs transition duration-100 group-hover:shadow-lg"
    />
    <div aria-hidden="true" class="mt-4 px-2.5 text-center text-default">
      <div v-if="tile.offer_name" class="font-bold">
        {{ tile.offer_name }}
      </div>
      <div v-if="tile.offer_subtitle" class="truncate">
        {{ tile.offer_subtitle }}
      </div>
    </div>
  </base-link>
</template>
