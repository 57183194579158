import { computed } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { Activity, useActivityListItem } from '/~/composables/activity'
import { useCms } from '/~/composables/cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useCommunicationPreferences } from '/~/composables/user'

export function useAustraliapostActivityListItem(props: { item: Activity }) {
  const { isSmsCommunicationPreference } = useCommunicationPreferences()
  const { calculatePointsToBurnOrder, calculatePointsEarnedForPayment } =
    usePoints()
  const {
    getPointsString,
    icon,
    iconColor,
    showPoints,
    userCommunicationTitle,
    userCommunicationSubtitle,
  } = useActivityListItem(props)
  const { ewalletLabels } = useCms()

  const isSmsCommunicationActivity = computed(() =>
    isSmsCommunicationPreference(props.item)
  )

  const pointsTransactions = computed(() => {
    if (props.item.isTypeMigratedOrder) {
      return [
        getPointsString({
          points: props.item.points,
          prefix: '-',
          suffix: 'PTS',
        }),
      ]
    }

    if (props.item.isTypeMigratedPoints) {
      return [
        getPointsString({
          points: props.item.points,
          prefix: '+',
          suffix: 'PTS',
        }),
      ]
    }

    if (props.item.isTypeProgramOrder) {
      return [
        getPointsString({
          points: props.item.points,
          prefix: '-',
          suffix: 'PTS',
        }),
      ]
    }

    if (props.item.isTypePointsTransaction) {
      return [
        getPointsString({
          points: props.item.total,
          prefix: props.item.subtype === 'burn' ? '-' : '+',
          suffix: 'PTS',
        }),
      ]
    }

    if (props.item.isTypeStatementOrder) {
      const points = calculatePointsEarnedForPayment(props.item.subtotal)

      return [
        getPointsString({
          points,
          prefix: '+',
          checkValue: Math.round(points) !== 0,
          suffix: 'PTS',
        }),
      ]
    }

    const { pointsTransactions = [] } = props.item

    if (pointsTransactions.length === 0) {
      return ['n/a']
    }

    return pointsTransactions.map((pointsRecord) => {
      const transactionSign = pointsRecord?.type === 'burn' ? '-' : '+'
      const aValue = pointsRecord?.allocatedValue ?? 0

      return getPointsString({
        points: aValue,
        prefix: transactionSign,
        checkValue: Number(aValue),
        suffix: 'PTS',
      })
    })
  })

  const isPointsOnlyPurchase = computed(() => {
    const displayPaymentMethods = props.item.paymentMethods ?? []

    return (
      props.item.isTypePurchaseOrder &&
      displayPaymentMethods.length === 1 &&
      displayPaymentMethods[0] === PaymentMethodType.points
    )
  })

  const amountInPoints = computed(() =>
    calculatePointsToBurnOrder(props.item.total ?? 0)
  )

  const formattedAmountInPoints = computed(() =>
    getPointsString({
      points: amountInPoints.value,
      prefix: '-',
      suffix: 'PTS',
    })
  )

  const pointsLabel = computed(() => {
    if (props.item.isStatusPending) {
      return 'Points to be earned'
    }
    return 'Points Earned'
  })

  const title = computed(() => {
    if (props.item.isTypeMigratedOrder) {
      return `Purchase ID #${props.item.purchaseId}`
    }

    if (props.item.isTypeMigratedPoints) {
      return pointsLabel.value
    }

    if (props.item.isTypePointsTransaction && props.item.subtype === 'earn') {
      return 'Lead Awarded'
    }

    if (props.item.isTypeUserCommunication) {
      return userCommunicationTitle.value
    }

    if (props.item.isTypeCampaign) {
      return 'Leads Submitted'
    }

    if (props.item.isTypePointsTransferRequest) {
      return props.item.pointsTransferRequest?.title ?? 'Points debited'
    }

    return props.item.description?.[0] || `Order No. #${props.item.orderNumber}`
  })

  const subtitle = computed(() => {
    if (props.item.isTypeMigratedOrder) {
      return null
    } else if (props.item.isTypePurchaseOrder) {
      return itemsCountLabel.value
    } else if (props.item.isTypeMigratedPoints) {
      return props.item.pointSourceName
    } else if (props.item.isTypePointsTransaction) {
      return props.item.description?.[0]
    } else if (props.item.isTypeUserCommunication) {
      return userCommunicationSubtitle.value
    } else if (props.item.isTypeCampaign) {
      return `Lead No. #${props.item.campaign.number}`
    }

    return '-'
  })

  const itemsCount = computed(() => {
    const description =
      props.item.description?.[1] || props.item.description?.[0]

    if (!description) {
      return '-'
    }

    const matches = description.match(/(\d+) items/)

    return matches ? matches[1] : 1
  })

  const itemsCountLabel = computed(() => `Items (${itemsCount.value})`)

  const date = computed(() => {
    let date

    if (
      props.item.isTypePaymentOrder ||
      props.item.isTypeStatementOrder ||
      props.item.isTypeBatchOrder
    ) {
      date = props.item.updatedAt
    } else {
      date = props.item.happenedAt
    }

    return formatDate('daymonthyearnumeric', date)
  })

  const paymentMethodLabel = computed(() => {
    if (isPointsOnlyPurchase.value) return 'Points redeemed'

    return (props.item.paymentMethods ?? [])
      .map((method: string) => {
        switch (method) {
          case PaymentMethodType.eWallet:
            return ewalletLabels.value.ewalletCash
          case PaymentMethodType.points:
            return 'Points'
          case PaymentMethodType.creditCard:
            return 'Credit Card'
          case PaymentMethodType.bankAccount:
            return 'Bank Account'
          case PaymentMethodType.couponCode:
            return 'Coupon code'
        }
      })
      .filter((method: string) => method)
      .join(' + ')
  })

  const canSeeDetails = computed(() => {
    return Boolean(
      props.item.isTypeRefund ||
        isPointsOnlyPurchase.value ||
        props.item.isTypeMigratedPoints ||
        props.item.isTypeMigratedOrder ||
        props.item.isTypeCampaign ||
        props.item.isTypePurchaseOrder ||
        props.item.isTypeTopUpOrder ||
        props.item.comments
    )
  })

  const typeIcon = computed(() => {
    if (props.item.isTypePointsTransaction || props.item.isTypeMigratedPoints) {
      return {
        svg: 'australiapost/token',
        size: 24,
      }
    }

    if (props.item.isTypeCampaign) {
      return {
        svg: 'australiapost/lead-activity',
        size: 24,
      }
    }

    if (props.item.isTypeUserCommunication) {
      if (isSmsCommunicationActivity.value) {
        return {
          svg: 'australiapost/phone',
          size: 26,
        }
      }

      return {
        svg: 'australiapost/email',
        size: 25,
      }
    }

    if (props.item.isTypeMigratedOrder) {
      return {
        svg: 'australiapost/logo',
        size: 48,
        classes: 'text-red-700',
      }
    }

    return {
      svg: 'australiapost/bx-store',
      size: 27,
    }
  })

  const productsInterested = computed(() =>
    props.item.campaign?.productsInterested?.join(', ')
  )

  return {
    showPoints,
    icon,
    date,
    title,
    subtitle,
    iconColor,
    typeIcon,
    isSmsCommunicationActivity,
    canSeeDetails,
    paymentMethodLabel,
    productsInterested,
    isPointsOnlyPurchase,
    formattedAmountInPoints,
    pointsTransactions,
  }
}
