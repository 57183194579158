<script>
import ui from '/~/core/ui'
import DefaultProductHeader from '/~/extensions/giftcards/components/product/header/product-header.vue'
import EwProductType from '/~/templates/australiapost/components/ewallet/product/ew-product-type.vue'

export default {
  components: {
    EwProductType,
  },
  extends: DefaultProductHeader,
  setup() {
    return {
      ...DefaultProductHeader.setup?.(...arguments),
      ui,
    }
  },
}
</script>

<template>
  <div class="text-center sm:text-left">
    <h2
      v-if="!ui.mobile && name"
      class="text-2xl font-normal leading-tight text-eonx-neutral-800 md:text-3xl"
    >
      {{ name }}
    </h2>
    <h3 class="mb-2.5 text-2xl font-normal md:text-3xl">
      <template v-if="!percentage || percentage === '0.00'">
        <b>{{ offerName }}</b>
      </template>
      <template v-else>
        <b>{{ Number(percentage) }}% OFF</b>
        <span>{{ physical ? 'Physical Gift Card' : 'eGift Card' }}</span>
      </template>
    </h3>
    <ew-product-type
      v-if="!ui.mobile"
      class="text-eonx-neutral-600"
      :physical="physical"
      :gift-card="false"
      :show-delivery="true"
    />
  </div>
</template>
