<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { OnlineOffersAlgoliaHitPayload } from '/~/types/api/algolia/online-offers'
import modal from '/~/core/mdl'
import OoReturn from '/~/extensions/online-offers/components/oo-return.vue'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import { useVerifications } from '/~/extensions/otp/composables'
import BaseButton from '/~/components/base/button/base-button'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useCms } from '/~/composables/cms'
import { useUI } from '/~/composables/ui'

const props = defineProps<{
  merchant: OnlineOffersAlgoliaHitPayload
}>()

const router = useRouter()
const { ewalletLabels } = useCms()
const { titleRef } = useUI()
const { hasUnfinishedVerifications, showVerificationModal } = useVerifications()
const { isCashbackReturn, isPointsReturn } = useOnlineOffers()

const cashbackRate = computed(() => props.merchant.cashback_rate)
const shopButtonRoute = computed(() => ({
  name: 'online-offers-redirect',
  params: {
    offer: {
      feed_offer_id:
        props.merchant.feed_offer_id ??
        `${props.merchant.feed_merchant_id}-baseline`,
      feed_merchant_id: props.merchant.feed_merchant_id,
    },
    merchant: props.merchant,
  },
}))
const helpText = computed(() => {
  if (isCashbackReturn.value) {
    return `Click “Shop Now” to open retailer’s website in a new window, shop online and receive bonus Cashback to ${ewalletLabels.value.yourEwallet}.`
  } else if (isPointsReturn.value) {
    return `Click “Shop Now” to open retailer’s website in a new window, shop online and receive bonus points to ${ewalletLabels.value.yourEwallet}.`
  }

  return undefined
})

function showSpecialTermsModal() {
  modal.show('special-terms', {
    props: {
      terms: props.merchant.terms,
    },
  })
}

function onClick(event: Event) {
  if (hasUnfinishedVerifications.value) {
    event.preventDefault()
    showVerificationModal()
  } else {
    router.push(shopButtonRoute.value as any)
  }
}
</script>

<template>
  <div>
    <h2
      v-if="merchant"
      ref="titleRef"
      class="mt-2.5 text-center text-2xl sm:mt-0 sm:text-left sm:text-3xl"
      tabindex="-1"
    >
      {{ merchant.name }}
    </h2>
    <h3
      v-if="cashbackRate"
      class="mt-2.5 mb-[15px] text-center text-2xl font-bold sm:text-left sm:text-3xl"
    >
      <oo-return :cashback-rate="cashbackRate" />
    </h3>
    <div
      v-if="helpText"
      class="hidden min-h-[120px] text-eonx-neutral-800 sm:block"
    >
      {{ helpText }}
    </div>
    <div class="mt-[30px] flex w-full flex-col justify-end opacity-75">
      <div class="mb-5 w-full lg:hidden">
        <base-link :to="shopButtonRoute">
          <base-button
            full-width="mobile"
            type="primary"
            class="w-full"
            @click.prevent="onClick"
          >
            Shop now
          </base-button>
        </base-link>
      </div>
      <div class="flex justify-end space-x-5">
        <base-button
          v-if="isCashbackReturn"
          look="outlined-color"
          class="flex-1 lg:flex-none"
          @click="modal.show('cashback-tips')"
        >
          Cashback Tips
        </base-button>
        <base-button
          v-else-if="isPointsReturn"
          look="outlined-color"
          class="flex-1 lg:flex-none"
          @click="modal.show('points-tips')"
        >
          Points tips
        </base-button>
        <base-button
          look="outlined-color"
          class="flex-1 lg:flex-none"
          @click="showSpecialTermsModal"
        >
          Special Terms
        </base-button>
        <base-link :to="shopButtonRoute" class="hidden lg:block">
          <base-button type="primary" @click.prevent="onClick">
            Shop now
          </base-button>
        </base-link>
      </div>
      <div v-if="helpText" class="mt-[30px] text-eonx-neutral-800 sm:hidden">
        {{ helpText }}
      </div>
    </div>
  </div>
</template>
