<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import { mixin as clickaway } from 'vue-clickaway'
import QrPopup from './qr-pin-popup.vue'

export default {
  name: 'qr-pin',
  components: {
    QrPopup,
  },
  mixins: [clickaway],
  props: {
    pin: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    transform: {
      type: Object,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
    reserveWorkspace: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ...clickaway.setup?.(...arguments),
      ui,
    }
  },
  data() {
    return {
      width: 0,
      height: 0,
      showPopup: false,
    }
  },
  computed: {
    mapPosition() {
      return this.pin.mapPosition
    },
    top() {
      return (
        (this.image.y + this.mapPosition.top * this.image.scale) *
          this.transform.scale +
        this.transform.y
      )
    },
    left() {
      return (
        this.mapPosition.left * this.image.scale * this.transform.scale +
        this.transform.x
      )
    },
    isShown() {
      const { available, enabled } = this.pin

      return available && enabled
    },
  },
  mounted() {
    this.width = this.$el.clientWidth
    this.height = this.$el.clientHeight
  },
  methods: {
    onShowPopup() {
      if (ui.mobile) {
        const { pin, reserveWorkspace } = this

        modal.show('qr-pin-popup-mobile', {
          props: {
            pin,
            reserveWorkspace,
          },
          on: {
            'select-asset': () => this.onSelectAsset(),
          },
        })
      } else {
        this.showPopup = true
      }
    },
    onClosePopup() {
      this.showPopup = false
    },
    onSelectAsset() {
      this.$emit('select-pin')
      this.showPopup = false
    },
  },
}
</script>

<template>
  <div
    v-if="isShown"
    v-on-clickaway="onClosePopup"
    class="absolute h-10 w-[35px] cursor-pointer"
    :class="showPopup ? 'z-10' : 'z-1'"
    :data-pin="JSON.stringify({ x: mapPosition.left, y: mapPosition.top })"
    :style="{
      top: `${top - height}px`,
      left: `${left - width / 2}px`,
    }"
    @click="onShowPopup"
    @touchstart="onShowPopup"
  >
    <img
      class="pointer-events-none h-full w-full cursor-pointer"
      :src="`/images/qr/pin${showPopup ? '-active' : ''}.svg`"
    />
    <qr-popup
      v-if="showPopup"
      :pin="pin"
      :map="map"
      :trigger="{
        left,
        top,
        width,
        height,
      }"
      :reserve-workspace="reserveWorkspace"
      @select-asset="onSelectAsset"
    />
  </div>
</template>
