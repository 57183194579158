<script>
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import DrawerPayFromV1 from '/~/components/drawer/components/pay/drawer-pay-from.vue'
import DrawerPayFromRow from '/~/components/ewallet-menu/components/scan/scan-pay-from-row.vue'
import { useCms } from '/~/composables/cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

export default {
  name: 'scan-pay-from',
  components: {
    DrawerPayFromRow,
    BaseAsidePage,
  },
  extends: DrawerPayFromV1,
  setup() {
    const router = useRouter()
    const { ewalletLabels } = useCms()
    const { canAddPaymentMethods } = usePaymentMethods()

    function onAddPaymentMethodClick() {
      router.push({ hash: '#profile-scan-pay-from-add' })
    }

    return {
      ...DrawerPayFromV1.setup?.(...arguments),
      onAddPaymentMethodClick,
      ewalletLabels,
      canAddPaymentMethods,
      PaymentMethodType,
    }
  },
}
</script>

<template>
  <base-aside-page
    :title="title"
    :action="canAddPaymentMethods && onAddPaymentMethodClick"
  >
    <template v-if="isAvailable(PaymentMethodType.eWallet)">
      <div class="mb-4 font-bold text-eonx-neutral-600">
        <slot name="eWalletsLabel">{{ ewalletLabels.ewallet }}</slot>
      </div>
      <div v-if="fetching" class="mx-auto py-5">
        <base-loader />
      </div>
      <template v-else>
        <div v-if="noEwallets" class="mt-[5px] mb-6 font-bold">
          <slot name="eWalletsEmptyLabel">
            No Available {{ ewalletLabels.ewallets }}
          </slot>
        </div>
        <div v-else class="mb-[30px] space-y-2.5">
          <drawer-pay-from-row
            v-for="ewallet in eWallets"
            :key="ewallet.id"
            :from="ewallet"
            :deletable="false"
            :disabled="!isEnabled(ewallet)"
            @click="selectPayFrom(ewallet)"
          />
        </div>
      </template>
    </template>

    <template v-if="isAvailable(PaymentMethodType.points)">
      <div class="mb-4 font-bold text-eonx-neutral-600">
        <slot name="pointsLabel">Points</slot>
      </div>
      <div v-if="fetching" class="mx-auto py-5">
        <base-loader />
      </div>
      <template v-else>
        <div v-if="noPoints" class="mt-[5px] mb-6 font-bold">
          <slot name="pointsEmptyLabel">No Available Points</slot>
        </div>
        <div v-else class="mb-[30px] space-y-2.5">
          <drawer-pay-from-row
            v-for="pointsItem in points"
            :key="pointsItem.id"
            :from="pointsItem"
            :deletable="false"
            :disabled="!isEnabled(pointsItem)"
            @click="selectPayFrom(pointsItem)"
          />
        </div>
      </template>
    </template>

    <template v-if="isAvailable(PaymentMethodType.creditCard)">
      <div class="mb-4 font-bold text-eonx-neutral-600">
        <slot name="cardsLabel">Credit/Debit Cards</slot>
      </div>
      <div v-if="fetching" class="mx-auto py-5">
        <base-loader />
      </div>
      <template v-else>
        <div v-if="noCreditCards" class="mt-[5px] mb-6 font-bold">
          <slot name="cardsEmptyLabel">No Available Credit/Debit Cards</slot>
        </div>
        <div v-else class="mb-[30px] space-y-2.5">
          <drawer-pay-from-row
            v-for="card in creditCards"
            :key="card.id"
            :from="card"
            :disabled="!isEnabled(card)"
            :is-nav-enabled="isCreditCardsNavEnabled"
            @click="selectPayFrom(card)"
          />
        </div>
      </template>
    </template>

    <template v-if="isAvailable(PaymentMethodType.bankAccount)">
      <div class="mb-4 font-bold text-eonx-neutral-600">
        <slot name="bankAccountsLabel">Bank Accounts</slot>
      </div>
      <div v-if="fetching" class="mx-auto py-5">
        <base-loader />
      </div>
      <template v-else>
        <div v-if="noBankAccounts" class="mt-[5px] mb-6 font-bold">
          <slot name="bankAccountsEmptyLabel">No Available Bank Accounts</slot>
        </div>
        <div v-else class="space-y-2.5">
          <drawer-pay-from-row
            v-for="account in bankAccounts"
            :key="account.id"
            :from="account"
            :disabled="!isEnabled(account)"
            :is-nav-enabled="isBankAccountsNavEnabled"
            @click="selectPayFrom(account)"
          />
        </div>
      </template>
    </template>
  </base-aside-page>
</template>
