import cloneDeep from 'lodash-es/cloneDeep'
import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomUser } from '/~rec/core/user'

export class UsersProcessor extends EntityProcessor {
  constructor(params = {}) {
    super({
      mapping: (record) => new RecroomUser(record),
      perPage: 50,
      filters: {
        default: {
          'order[firstName]': 'asc',
          'order[lastName]': 'asc',
        },
      },
      ...params,
    })

    this.hitsByLetters = {}
    this.hitsByLettersFiltered = {}
  }

  groupHitsByLetters(hits = [], initialData) {
    const result = cloneDeep(initialData)

    hits.forEach((hit) => {
      const firstNameLetter = (hit.firstName[0] || '').toUpperCase()

      result[firstNameLetter] = result[firstNameLetter] || []
      result[firstNameLetter].push(hit)
    })

    return result
  }

  async getData(params = {}) {
    if (params.force !== false) {
      this.hitsByLetters = {}
    }

    const hits = await super.getData(params)

    this.hitsByLetters = this.groupHitsByLetters(hits, {})
  }

  applyFilter(filter) {
    let result = this.hitsByLetters

    if (filter instanceof Function) {
      result = this.groupHitsByLetters(this.hits.filter(filter), {})
    }

    this.hitsByLettersFiltered = result
  }

  async next() {
    const hits = await super.next()

    this.hitsByLetters = this.groupHitsByLetters(hits, this.hitsByLetters)
  }
}
