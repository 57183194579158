import Vue, { computed, reactive, ref } from 'vue'
import type { ApiResponseData } from '/~/types/api'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { EntityProcessor } from '/~/core/processors/entity'

const purchases = reactive({
  active: new EntityProcessor({
    entity: 'v3/e-purchases',
  }),
  pending: new EntityProcessor({
    entity: 'v3/e-purchases/pending',
    perPage: 1000,
    mapping: (purchase) => ({
      ...purchase,
      pending: true,
    }),
  }),
  archived: new EntityProcessor({
    entity: 'v3/e-purchases/archived',
    mapping: (purchase) => ({
      ...purchase,
      archived: true,
      disabled: false,
    }),
  }),
})

const selectedPurchaseCard = ref(null)
const isCardArchiving = ref(false)

const isActivePurchasesLoaded = computed(() => purchases.active.loaded)
const isPendingPurchasesLoaded = computed(() => purchases.pending.loaded)
const isArchivedPurchasesLoaded = computed(() => purchases.archived.loaded)
const isCurrentPurchasesLoaded = computed(
  () => isActivePurchasesLoaded.value && isPendingPurchasesLoaded.value
)
const hasActivePurchases = computed(() => purchases.active.hits.length > 0)
const hasPendingPurchases = computed(() => purchases.pending.hits.length > 0)
const hasCurrentPurchases = computed(
  () => hasActivePurchases.value || hasPendingPurchases.value
)
const hasArchivedPurchases = computed(() => purchases.archived.hits.length > 0)
const hasPurchases = computed(
  () => hasCurrentPurchases.value || hasArchivedPurchases.value
)

const isPurchasesEmpty = computed(
  () =>
    purchases.active.isEmpty &&
    purchases.pending.isEmpty &&
    purchases.archived.isEmpty
)
const isPurchasesLoading = computed(
  () =>
    purchases.active.processing ||
    purchases.pending.processing ||
    purchases.archived.processing
)

const sortedActivePurchases = computed(() =>
  sortPurchases(purchases.active.hits)
)
const sortedPendingPurchases = computed(() =>
  sortPurchases(purchases.pending.hits)
)
const sortedCurrentPurchases = computed(() => [
  ...sortedPendingPurchases.value,
  ...sortedActivePurchases.value,
])

function sortPurchases(array = []) {
  return [...array].sort((a, b) => {
    if (a.purchasedAt === b.purchasedAt) {
      return 0
    }

    const timeA = new Date(a.purchasedAt).getTime()
    const timeB = new Date(b.purchasedAt).getTime()

    return timeA > timeB ? -1 : 1
  })
}

function fetchActivePurchases() {
  return purchases.active.refresh()
}

function fetchPendingPurchases() {
  return purchases.pending.refresh()
}

function fetchArchivedPurchases() {
  return purchases.archived.refresh()
}

function fetchCurrentPurchases() {
  return Promise.all([fetchActivePurchases(), fetchPendingPurchases()])
}

function initPurchases() {
  fetchCurrentPurchases()
  fetchArchivedPurchases()
}

function updateSelected(card) {
  if (selectedPurchaseCard.value?.uuid === card.uuid) {
    selectedPurchaseCard.value = card
  }
}

function setGiftCardDetails(card: any) {
  card.details = card.redemptionDetails
  delete card.redemptionDetails
  updateSelected(card)
}

async function restoreGiftCard(uuid) {
  try {
    await api.post(`/e-purchases/restore/${uuid}`)
    await Promise.all([fetchCurrentPurchases(), fetchArchivedPurchases()])
  } catch (error) {
    console.warn(error)
  }
}

async function unlockGiftCard(uuid: string) {
  if (!uuid) {
    return
  }

  try {
    const { data } = await api.post<ApiResponseData<unknown>>(
      '/v3/e-purchases/unlock',
      { id: uuid }
    )

    await fetchCurrentPurchases()
    setGiftCardDetails(data)

    return data
  } catch (error) {
    console.log(error)
    Vue.notify({
      text: 'Oops! Something went wrong. Could you please try again?',
      type: 'error',
      duration: 5000,
    })
  }
}

async function updateBalance({ uuid, balance }) {
  const response = await api.put(`/e-purchases/${uuid}`, {
    card_balance: balance,
  })

  await fetchCurrentPurchases()

  const cardBalance = response.epurchase?.card_balance ?? 0
  const card = purchases.active.hits.find((card) => card.uuid === uuid)

  card.card_balance = cardBalance
  card.balance = cardBalance
  updateSelected({
    ...selectedPurchaseCard.value,
    ...card,
  })
  return response
}

async function getCardDetails(uuid) {
  if (!uuid) {
    return
  }

  try {
    const { data } = await api.get<ApiResponseData<unknown>>(
      `/v3/e-purchases/${uuid}`
    )

    setGiftCardDetails(data)
    return data
  } catch (error) {
    console.log(error)
    Vue.notify({
      text: 'Oops! Something went wrong. Could you please try again?',
      type: 'error',
      duration: 5000,
    })
  }
}

async function archiveGiftCard(uuid) {
  isCardArchiving.value = true

  try {
    await api.delete(`/e-purchases/${uuid}`)
    await Promise.all([fetchCurrentPurchases(), fetchArchivedPurchases()])
  } catch (error) {
    console.warn(error)
  } finally {
    isCardArchiving.value = false
  }
}

emitter.on('purchases-updated', fetchCurrentPurchases)

export function usePurchases() {
  return {
    purchases,
    hasActivePurchases,
    hasArchivedPurchases,
    hasCurrentPurchases,
    hasPurchases,
    sortedActivePurchases,
    sortedCurrentPurchases,
    isActivePurchasesLoaded,
    isCurrentPurchasesLoaded,
    isArchivedPurchasesLoaded,
    isPurchasesLoading,
    isPurchasesEmpty,

    fetchActivePurchases,
    fetchPendingPurchases,
    fetchCurrentPurchases,
    fetchArchivedPurchases,

    initPurchases,
    restoreGiftCard,
    unlockGiftCard,
    updateBalance,
    getCardDetails,
    archiveGiftCard,

    selectedPurchaseCard,
  }
}
