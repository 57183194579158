<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import modal from '/~/core/mdl'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PointsExpiryWarning from '/~/components/points/points-expiry-warning.vue'
import { useCms } from '/~/composables/cms'
import { usePoints, usePointsExpiry } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import { useMembership } from '/~/templates/bill-payments/composables'

export default {
  name: 'points',
  components: {
    BaseAction,
    BaseIcon,
    BaseLoader,
    PointsExpiryWarning,
  },
  setup() {
    const router = useRouter()
    const { tierBadgeAction, showTierBadge } = useCms()
    const { formattedPointsBalance, pointsLoading } = usePoints()
    const {
      isPointsExpiryLoading,
      pointsExpiryDate,
      showPointsExpiryWarning,
      fetchPointsExpiry,
    } = usePointsExpiry()
    const { user } = useUser()
    const { isBillPaymentsTemplate } = useProvider()
    const { isMembershipSwitchingEnabled } = useMembership()

    const isWarningVisible = ref(false)

    function toggleWarningVisibility() {
      isWarningVisible.value = !isWarningVisible.value
    }

    function onClick() {
      if (showPointsExpiryWarning.value) {
        toggleWarningVisibility()
      } else if (
        !tierBadgeAction.value ||
        tierBadgeAction.value.type === 'none'
      ) {
        if (
          isBillPaymentsTemplate.value &&
          isMembershipSwitchingEnabled.value
        ) {
          modal.show('init-tier-options-modal')
        } else {
          router.push({ hash: '#profile-home' })
        }
      }
    }

    if (!pointsExpiryDate.value) {
      fetchPointsExpiry()
    }

    return {
      tierBadgeAction,
      showTierBadge,
      formattedPointsBalance,
      pointsLoading,
      isPointsExpiryLoading,
      pointsExpiryDate,
      showPointsExpiryWarning,
      isWarningVisible,
      toggleWarningVisibility,
      user,
      onClick,
    }
  },
}
</script>

<template>
  <component
    :is="tierBadgeAction ? 'base-action' : 'div'"
    v-if="showTierBadge"
    v-bind="tierBadgeAction"
    class="relative ml-auto mr-5"
    color="none"
  >
    <div
      class="inline-flex cursor-pointer items-center justify-center rounded-lg px-6 py-2.5 font-bold"
      :class="[
        showPointsExpiryWarning
          ? 'bg-primary text-white'
          : 'bg-primary-lightest',
      ]"
      @click="onClick"
    >
      <base-icon
        v-if="showPointsExpiryWarning"
        svg="points-only/alert-triangle-fill2"
        size="md"
      />
      <base-icon v-else svg="symbion/token" size="md" class="text-primary" />
      <div class="ml-2.5 flex items-baseline space-x-[5px]">
        <base-loader v-if="pointsLoading || isPointsExpiryLoading" />
        <template v-else>
          <p class="text-xl">
            {{ formattedPointsBalance }}
          </p>
          <p class="text-xs">PTS</p>
        </template>
      </div>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="opacity-0 transform -translate-y-2.5"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-out duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0 transform -translate-y-2.5"
    >
      <points-expiry-warning
        v-show="isWarningVisible"
        @got-it-clicked="toggleWarningVisibility"
      />
    </transition>
  </component>
</template>
