<script>
import DefaultGiftcardsRetailer from '/~/extensions/giftcards/templates/default/views/giftcards-retailer.vue'
import RetailerProduct from '/~/extensions/giftcards/templates/australiapost/components/product/retailer-product.vue'

export default {
  components: {
    RetailerProduct,
  },
  extends: DefaultGiftcardsRetailer,
}
</script>
