<script setup lang="ts">
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BaseButton from '/~/components/base/button/base-button'
import BaseField from '/~/components/base/field/base-field.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import { useEditProfileComponent } from '/~/components/drawer/views/personal-details/views/edit-profile/use-edit-profile-component'
import { BackendErrors } from '/~/composables/backend-validation'
import { useProvider } from '/~/composables/provider'

// component used in bill-payment provider

interface EditProfileComponentProps {
  mobile?: boolean
  to?: string
  isFormFieldsScrollable?: boolean
  showOnlyRequiredFields?: boolean
  id?: string | null
}

const props = withDefaults(defineProps<EditProfileComponentProps>(), {
  mobile: false,
  to: 'modals-portal',
  isFormFieldsScrollable: true,
  showOnlyRequiredFields: false,
  id: null,
})

const emit = defineEmits<{
  (event: 'profile-saved'): void
  (event: 'edit-profile-error', errors: BackendErrors): void
}>()

const { profileConfigForm } = useProvider()
const {
  form,
  visibleFormFields,
  genderFields,
  genderField,
  updating,
  validationObserverRef,
  onSubmitForm,
} = useEditProfileComponent(props, emit)
</script>

<template>
  <validation-observer
    v-slot="{ errors, handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <form
      class="relative flex h-full flex-col"
      @submit.prevent="handleSubmit(onSubmitForm)"
    >
      <div
        class="grow"
        :class="{
          'overflow-y-auto': isFormFieldsScrollable,
        }"
      >
        <template v-for="field in visibleFormFields">
          <div :key="field.name">
            <base-textarea
              v-if="field.textarea"
              v-model="form[field.name]"
              :validation="field.validation"
              :label="field.label"
              :name="field.name"
              :disabled="field.disabled"
              :required="field.required"
              :error="field.error"
              :placeholder="field.placeholder"
              :rows="2"
              :maxlength="1300"
            />
            <base-input
              v-else
              v-model="form[field.name]"
              :validation="{
                ...field.validation,
                mode: 'lazy',
              }"
              :name="field.name"
              :disabled="field.disabled"
              :required="field.required"
              :error="field.error"
              :label="field.label"
              :maxlength="field.maxlength"
              :mask="field.mask"
              :type="field.type"
              :placeholder="field.placeholder"
            >
              <template #disclaimer>
                <div
                  v-if="field.disclaimer"
                  class="mt-[5px] text-eonx-neutral-600"
                >
                  {{ field.disclaimer }}
                </div>
              </template>
            </base-input>
          </div>
        </template>

        <validation-provider
          v-if="profileConfigForm.sex && profileConfigForm.sex.isVisible"
          v-bind="genderField.validation"
          slim
        >
          <base-field
            label="Gender"
            :border="false"
            :required="genderField.required"
            class="base-input--simple"
            :error="errors['sex'] ? errors['sex'][0] : null"
            entry-class="flex mt-1 pl-5 gap-x-5"
          >
            <base-radio
              v-for="field in genderFields"
              :key="field.value"
              v-model="form.sex"
              :disabled="genderField.disabled"
              :value="field.value"
              name="sex"
              class="h-10"
            >
              {{ field.label }}
            </base-radio>
          </base-field>
        </validation-provider>
      </div>

      <base-button
        type="submit"
        :disabled="updating"
        full-width
        class="mt-5 shrink-0"
      >
        {{ updating ? 'Saving...' : 'Save' }}
      </base-button>
    </form>
  </validation-observer>
</template>
