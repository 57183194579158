export default class HeathScore {
  constructor(raw = {}) {
    this.raw = raw
  }

  get type() {
    return this.raw.type
  }

  get value() {
    return this.raw.value
  }
}
