<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import AwardNominate from '/~rec/views/recognition/awards/award-nominate.vue'

export default {
  name: 'award-nominate-mobile',
  components: {
    BaseMdl,
    AwardNominate,
  },
  props: {
    nominee: {
      type: Object,
      default: null,
    },
    awardId: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <base-mdl transition="slide-right" fullscreen width="screen" :layout="false">
    <award-nominate :nominee="nominee" :award-id="awardId" />
  </base-mdl>
</template>
