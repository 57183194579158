<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import BaseButton from '/~/components/base/button/base-button'
import { useCart } from '/~/composables/cart'
import { usePurchaseCheckout } from '/~/composables/checkout'
import { useCms } from '/~/composables/cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'

const emit = defineEmits<{
  (event: 'checkout-button-clicked'): void
}>()

const { isCartLoading } = useCart()
const { cartAmount } = usePurchaseCheckout()
const { calculatePointsToBurnOrder } = usePoints()
const { isPurchaseOrderPointsAvailable } = usePaymentMethods()
const { showPointsToBurn } = useCms()

const pointsToBurn = computed(() =>
  calculatePointsToBurnOrder(cartAmount.value)
)
const formattedPointsToBurn = computed(() => formatPoints(pointsToBurn.value))

function onCheckoutButtonClicked() {
  emit('checkout-button-clicked')
}
</script>

<template>
  <base-button
    :disabled="isCartLoading"
    full-width
    @click="onCheckoutButtonClicked()"
  >
    <div class="flex items-center">
      <span class="text-sm">Checkout</span>
      <span class="text-md ml-2.5">
        ({{ formatDollar(cartAmount) }}
        <template v-if="showPointsToBurn && isPurchaseOrderPointsAvailable">
          <span class="text-sm">or use</span>
          {{ formattedPointsToBurn }} PTS)
        </template>
      </span>
    </div>
  </base-button>
</template>
