<script>
import DefaultRetailerProduct from '/~/extensions/giftcards/components/product/retailer-product.vue'
import ProductAdd from '/~/extensions/giftcards/templates/australiapost/components/product/add/product-add.vue'
import ProductHeader from '/~/extensions/giftcards/templates/australiapost/components/product/header/product-header.vue'
import ProductTotal from '/~/extensions/giftcards/templates/australiapost/components/product/total/product-total.vue'

export default {
  components: {
    ProductAdd,
    ProductHeader,
    ProductTotal,
  },
  extends: DefaultRetailerProduct,
}
</script>
