<script>
import ui from '/~/core/ui'
import RewardsDesk from './rewards.desk.vue'
import RewardsMobile from './rewards.mobile.vue'

export default {
  name: 'rewards',
  components: {
    RewardsDesk,
    RewardsMobile,
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <rewards-mobile v-if="ui.mobile" />
  <rewards-desk v-else />
</template>
