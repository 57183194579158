<script>
import CheckoutSummaryItemV1 from '/~/components/checkout/summary/checkout-summary-item.vue'
import EwProductType from '/~/components/ewallet/product/ew-product-type.v2.vue'

export default {
  name: 'checkout-summary-item-v4',
  components: {
    EwProductType,
  },
  extends: CheckoutSummaryItemV1,
  props: {
    prependEstoreTitleWithPrice: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ...CheckoutSummaryItemV1.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <div class="flex items-center justify-between">
    <div v-if="image" class="mr-[15px] flex w-12 shrink-0 text-center">
      <base-image
        :src="image"
        rounded-size="sm"
        :fit="isEstore ? 'contain' : 'cover'"
        alt="offer logo"
        class="h-full w-full"
        shadow
      />
    </div>

    <div class="flex-1 overflow-hidden">
      <div class="flex flex-nowrap items-center sm:items-start">
        <div class="flex-1 overflow-x-hidden">
          <div class="truncate font-bold text-eonx-neutral-800">
            {{ isEstore ? shortName : title }}
          </div>
          <div class="flex justify-between text-eonx-neutral-600 sm:mt-[5px]">
            <div>
              {{ item.quantity }} x
              {{ isEstore ? formatDollar(price) : shortName }}
            </div>
            <div>
              {{ total }}
            </div>
          </div>
        </div>
      </div>
      <template v-if="loading">
        <div
          class="h-5 w-1/3 animate-pulse rounded-md bg-eonx-neutral-50 sm:mt-[5px]"
        />
      </template>
      <template v-else>
        <ew-product-type
          v-if="item.gift && gift"
          class="text-eonx-neutral-600"
          :gift="item.gift"
          :gift-card="!!item.retailerName"
          gift-icon="heroicons/solid/gift-top"
          physical-icon="v2/box-icons/bx-truck"
          :icon-size="20"
          :physical="item.physical"
        >
          <template #digital>
            <div>Gift to {{ gift.email }}</div>
          </template>
          <template #physical>
            <div>Gift to {{ gift.name }}</div>
          </template>
        </ew-product-type>
      </template>
    </div>
  </div>
</template>
