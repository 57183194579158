<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseButton from '/~/components/base/button/base-button'
import { useRecProfile } from '/~rec/composables/profile'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-perk-details-modal',
  components: {
    BaseButton,
    BaseMdl,
    BaseImage,
    BaseIcon,
  },
  props: {
    perk: {
      type: Object,
      required: true,
    },
    redeemed: {
      type: Boolean,
    },
  },
  setup() {
    const { features, getTokenBalance } = useRecProfile()

    return {
      features,
      getTokenBalance,
      ui,
    }
  },
  data() {
    return {
      processing: false,
    }
  },
  computed: {
    textDescription() {
      return this.perk.isApprovalAutomatic
        ? 'You will redeem...'
        : 'You will request approval to redeem...'
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.processing = true
        await this.perk.redeem()
        setTimeout(async () => {
          await this.getTokenBalance()
          this.processing = false
          this.$emit('redeemed', this.perk.isApprovalAutomatic)
          this.onClose()
        }, 6000)
      } catch (error) {
        const msg = error.violations ? error.violations[0] : error.message

        this.$notify({
          type: 'error',
          title: 'Error',
          text: msg || 'Cannot Redeem Perk',
        })

        this.processing = false
      }
    },
    onClose() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl
    width="sm"
    fullscreen="mobile"
    :modal-styles="ui.mobile ? 'max-height: 32rem; margin-bottom: 0' : null"
    :layout="false"
    modal-class="rounded-t-3xl"
    transition="slide-bottom"
    class="ease transform duration-500"
  >
    <div class="flex h-full flex-col py-[30px]">
      <div class="relative flex h-full items-center justify-center px-[30px]">
        <base-icon svg="v3/empty-blue" />

        <div class="absolute w-24">
          <base-image
            :ratio="1"
            :src="perk.logo"
            :alt="perk.name"
            :rounded="false"
            class="mr-[15px] max-w-40 flex-none overflow-hidden rounded-full"
          />
        </div>
      </div>

      <div class="flex h-full grow flex-col pt-5">
        <h2 class="pb-2.5 text-center text-eonx-neutral-800">
          Almost there...
        </h2>

        <div class="text-center">
          <p
            class="text-eonx-neutral-600"
            :class="{
              'mb-6': !features.points,
            }"
          >
            {{ textDescription }}
          </p>
          <p
            v-if="features.points"
            class="break-words px-6 font-bold text-eonx-neutral-800"
          >
            {{ perk.name }} for {{ perk.cost }} Points
          </p>
        </div>

        <div
          class="mt-auto flex space-x-[15px] px-5 pt-[30px] sm:space-x-[30px] sm:px-[30px]"
        >
          <div class="w-full">
            <base-button
              look="outlined"
              full-width
              :size="ui.desktop ? null : 'lg'"
              :disabled="processing"
              @click="onClose"
            >
              <span class="text-base">Cancel</span>
            </base-button>
          </div>
          <div class="w-full">
            <base-button
              full-width
              :size="ui.desktop ? null : 'lg'"
              :loading="processing"
              @click="onSubmit"
            >
              <span class="text-base">
                {{ perk.isApprovalAutomatic ? 'Redeem' : 'Request approval' }}
              </span>
            </base-button>
          </div>
        </div>

        <div
          v-if="!perk.isApprovalAutomatic"
          class="mt-[15px] px-5 text-center"
        >
          <p class="text-eonx-neutral-600">
            This won’t be instant and will require approval from an admin
          </p>
        </div>
      </div>
    </div>
  </base-mdl>
</template>
