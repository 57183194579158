<script>
import { ref } from 'vue'
import BaseClampLine from '/~/components/base/clamp-line/base-clamp-line.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'

export default {
  name: 'estore-v1-product-details',
  components: {
    BaseLink,
    BaseTabs,
    BaseTab,
    BaseClampLine,
  },
  props: {
    description: {
      type: String,
      default: null,
    },
    availability: {
      type: String,
      default: null,
    },
    delivery: {
      type: String,
      default: null,
    },
    terms: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tabsProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const detailsClampExpanded = ref(true)
    const detailsClampExpandedDelivery = ref(true)
    const detailsClampExpandedTerms = ref(true)

    return {
      detailsClampExpanded,
      detailsClampExpandedDelivery,
      detailsClampExpandedTerms,
    }
  },
}
</script>

<template>
  <div v-if="!loading" class="mt-5 sm:mt-0">
    <base-tabs v-bind="tabsProps" class="-mx-5 sm:mx-0">
      <base-tab
        v-if="description || availability"
        name="Details"
        selected
        class="mx-5 sm:mx-0"
      >
        <div v-if="url" class="mb-5">
          <base-link :href="'//' + url">
            {{ url }}
          </base-link>
        </div>
        <base-clamp-line
          :rows="7"
          :is-expanded="detailsClampExpanded"
          @expand="detailsClampExpanded = $event"
        >
          <div v-if="description" data-test="product-details__section">
            <p class="leading-normal text-eonx-neutral-600">
              {{ description }}
            </p>
          </div>
          <div v-if="availability" data-test="product-details__section">
            <p class="leading-normal text-eonx-neutral-600">
              <strong>Availability:</strong>
              {{ availability }}
            </p>
          </div>
        </base-clamp-line>
      </base-tab>
      <base-tab v-if="delivery" name="Delivery" class="mx-5 sm:mx-0">
        <base-clamp-line
          :rows="7"
          :is-expanded="detailsClampExpandedDelivery"
          @expand="detailsClampExpandedDelivery = $event"
        >
          <p class="leading-normal text-eonx-neutral-600" v-html="delivery" />
        </base-clamp-line>
      </base-tab>
      <base-tab v-if="terms" name="Supplier Terms" class="mx-5 sm:mx-0">
        <base-clamp-line
          :rows="7"
          :is-expanded="detailsClampExpandedTerms"
          @expand="detailsClampExpandedTerms = $event"
        >
          <p class="leading-normal text-eonx-neutral-600" v-html="terms" />
        </base-clamp-line>
      </base-tab>
    </base-tabs>
  </div>
</template>
