<script>
import get from 'lodash-es/get'
import pick from 'lodash-es/pick'
import { ValidationObserver } from 'vee-validate'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { RecroomComment } from '/~rec/core/comment'
import RichInput from '/~rec/components/rich-input/rich-input.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import { useGroups } from '/~rec/composables/groups'
import { useRecProfile } from '/~rec/composables/profile'
import { useForm } from '/~/composables/base/use-form'
import {
  fromIdToMention,
  fromMentionToId,
  fromIdToMentionAndroid,
  fromMentionToIdAndriod,
} from '/~rec/utils/process-text-for-mentions'

export default {
  name: 'rec-comment-input',
  components: {
    RichInput,
    BaseAvatar,
    ValidationObserver,
  },
  inject: {
    post: {
      default: () => ({}),
    },
    sources: {
      default: () => ({ source: null }),
    },
  },
  props: {
    comment: {
      type: Object,
      default: null,
    },
    reply: {
      type: Object,
      default: null,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    avatarSize: {
      type: String,
      default: 'sm',
    },
  },
  setup() {
    const { groups } = useGroups()
    const { profile } = useRecProfile()
    const { validationObserverRef } = useForm()

    return {
      groups,
      profile,
      ui,
      validationObserverRef,
    }
  },
  data() {
    const isEdit = Boolean(this.comment)

    const attachments = isEdit
      ? get(this.comment, 'attachments.hits', []).map((a) =>
          pick(a, ['id', 'cdn', 'type', 'name'])
        )
      : []

    const isAndriod = /Android/i.test(navigator.userAgent)
    let content = ''
    let mentioned = this.comment?.mentions || []

    if (isAndriod && isEdit) {
      const postData = fromIdToMentionAndroid(
        this.comment?.content,
        this.comment?.mentions
      )

      content = postData.result
      mentioned = postData.mentioned
    }

    if (!isAndriod && isEdit) {
      content = fromIdToMention(this.comment?.content, this.comment?.mentions)
    }

    if (!isEdit) {
      content = this.getContent()
    }

    return {
      isEdit,
      form: {
        content,
        attachments,
      },
      mentionUsers: [],
      mentioned,
      loading: false,
      fetchingUsers: false,
      isAndriod,
      isOffensiveWarningShow: false,
    }
  },
  computed: {
    source() {
      const injectSource = this.sources && this.sources.source
      const source =
        this.post.toEvent ||
        this.post.toGroup ||
        injectSource ||
        this.groups.defaultGroup

      return source ? source.members : null
    },
    isSendDisabled() {
      return (
        (!this.form.content.trim() && !this.form.attachments.length) ||
        Boolean(this.validationObserverRef?.flags.invalid &&
          !this.$refs.input?.validationProviderRef?.failedRules['offensive'])
      )
    },
  },
  watch: {
    reply() {
      this.form.content = this.getContent()
      this.$refs.input.focus()
    },
  },
  mounted() {
    this.getMentionUsers()
  },
  methods: {
    async getMentionUsers() {
      this.fetchingUsers = true
      if (!this.source) return

      if (!this.source.total) {
        await this.source.refresh()
      }

      this.mentionUsers = this.source.hits
      this.fetchingUsers = false
    },
    getContent() {
      if (this.reply && this.reply.parentCommentId && !this.reply.isMeAuthor) {
        return `<span class="recroom-user-tag" contenteditable="false">@${this.reply.author.fullName}</span> `
      }

      return ''
    },
    async onSend() {
      const send = async () => {
        try {
          this.loading = true

          const content = this.isAndriod
            ? fromMentionToIdAndriod(
                this.form.content.replace(/\n(\s*)(?=\n)/g, '\n'),
                this.mentioned
              )
            : fromMentionToId(
                this.form.content.replace(/\n(\s*)(?=\n)/g, '\n'),
                this.mentioned
              )

          if (!this.isEdit) {
            await RecroomComment.create(this.post.id, {
              ...this.form,
              content,
              parent_comment_id: this.reply
                ? this.reply.parentCommentId || this.reply.id
                : null,
              mentions: this.mentioned.map((v) => v.id),
            })
          } else {
            await this.comment.update({
              ...this.form,
              content,
              mentions: this.mentioned.map((v) => v.id),
            })
          }

          this.form.attachments = []
          this.form.content = ''
          this.$emit('success')
        } catch (error) {
          console.error('rec-room', error)
        } finally {
          this.loading = false
          if (this.isAndriod) {
            this.validationObserverRef.validate()
          }
        }
      }

      if (
        this.$refs.input?.validationProviderRef?.failedRules['offensive'] &&
        !this.isOffensiveWarningShow
      ) {
        this.isOffensiveWarningShow = true
        modal.show('rec-comment-post', {
          props: {
            send,
          },
          on: {
            closeOffensiveWarning: () => {
              this.isOffensiveWarningShow = false
            },
          },
        })
      } else {
        const isValid = await this.validationObserverRef.validate()

        if (!isValid) return
        send()
      }
    },
    onInput(content) {
      if (this.isAndriod) {
        this.form.content = content
      }
    },
    updateMentioned(value) {
      if (Array.isArray(value)) {
        this.mentioned = value
      } else {
        const { user, add } = value

        if (add) {
          this.mentioned = [...this.mentioned, { ...user }]
        } else {
          if (!user) {
            this.mentioned = []
          } else {
            for (const idx in this.mentioned) {
              if (this.mentioned[idx].nanoId === user.nanoId) {
                this.mentioned.splice(idx, 1)
                break
              }
            }
          }
        }
      }
    },
    onEmojiSelect(emoji) {
      this.form.content += emoji
    },
  },
}
</script>

<template>
  <validation-observer ref="validationObserverRef" slim>
    <div class="flex flex-1 flex-row">
      <div v-if="showAvatar" class="mr-2.5 flex h-10 items-center">
        <base-avatar
          :src="profile.me.avatarUrl"
          :abbr="profile.me.initials"
          :size="avatarSize"
          class="shrink-0"
        />
      </div>

      <rich-input
        v-bind="$attrs"
        ref="input"
        :validation="{
          rules: 'offensive|max:500',
          mode: 'aggressive',
        }"
        :content.sync="form.content"
        :mention-users="mentionUsers"
        :attachments.sync="form.attachments"
        :mentioned="mentioned"
        :loading="loading"
        :disable-send="isSendDisabled"
        :disabled="fetchingUsers"
        :center-actions="ui.mobile"
        placeholder="Write a comment..."
        name="comment"
        focused
        :is-andriod="isAndriod"
        :max-length="500"
        :is-offensive-warning-show="isOffensiveWarningShow"
        v-on="$listeners"
        @send="onSend"
        @input="onInput"
        @update:mentioned="updateMentioned"
      />
    </div>
  </validation-observer>
</template>
