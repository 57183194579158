<script>
import { useCart } from '/~/composables/cart'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import { useChat } from '/~rec/composables/chat'

export default {
  name: 'app-bottom-bar-item',
  components: {
    BaseIcon,
    BaseAction,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    activeMenuItem: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { cartQuantity } = useCart()
    const { hasNewMessages } = useChat()

    return {
      cartQuantity,
      hasNewMessages,
    }
  },
  computed: {
    children() {
      return this.item?.children ?? []
    },
    action() {
      return this.item?.action ?? {}
    },
    isClickOnly() {
      return this.action.type === 'none' && this.children.length > 1
    },
  },
  methods: {
    onMenuItemClick(event, item) {
      this.$emit('expand', this.isClickOnly ? item : null)
    },
  },
}
</script>

<template>
  <li
    v-if="item"
    class="relative flex flex-1 items-center justify-center"
    @click="onMenuItemClick($event, item)"
  >
    <base-action v-bind="action" class="block" :click-only="isClickOnly">
      <template #entry>
        <div
          :class="[item === activeMenuItem ? 'text-primary' : 'text-default']"
        >
          <base-icon :svg="item.icon" class="block" size="lg" />
        </div>
      </template>
    </base-action>
    <div
      v-if="item === activeMenuItem"
      class="absolute top-1/2 left-1/2 h-11 w-11 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-primary opacity-20"
    />
    <span
      v-if="action.value === 'rec-room' && hasNewMessages"
      data-test="new-message-badge"
      class="absolute top-0 left-1/2 z-1 mt-1.5 ml-2.5 h-2.5 w-2.5 rounded-full bg-error-700 text-center text-xs font-bold text-white"
    />
    <span
      v-else-if="action.value === 'show-cart' && cartQuantity > 0"
      class="absolute top-0 left-1/2 z-1 mt-1.5 ml-2.5 min-w-2.5 rounded-full bg-error-700 px-[5px] text-center text-xs font-bold text-white"
    >
      {{ cartQuantity }}
    </span>
  </li>
</template>
