<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'qr-select',
  components: {
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
}
</script>

<template>
  <div class="mb-[15px] sm:mb-0" @click="$emit('click', $event)">
    <div class="mb-[5px] text-sm font-bold">
      {{ title }}
    </div>
    <div
      class="flex h-[72px] items-center justify-between rounded-md px-6 py-[15px] sm:h-11 sm:py-[5px]"
      :class="[
        disabled && 'bg-white text-neutral-400',
        !disabled && 'cursor-pointer  bg-white',
      ]"
    >
      <div class="flex w-full items-center">
        <div
          class="mr-[15px] flex h-10 w-10 items-center justify-center rounded-full sm:h-[30px] sm:w-[30px]"
          :class="[!disabled && value && 'bg-amber-300 text-blue-500']"
          :style="
            value && value.color && { background: value.color, color: 'white' }
          "
        >
          <base-icon :svg="icon" size="md" class="text-sm sm:text-lg" />
        </div>
        <div class="truncate">
          {{ (value && value.name) || 'Select' }}
        </div>
      </div>
      <base-icon svg="qr/arrow" size="sm" />
    </div>
  </div>
</template>
