import { ref } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import { cardCode } from '/~/utils/cards'
import { useProvider } from '/~/composables/provider'
import { useFatZebra } from './use-fat-zebra'
import { useNab } from './use-nab'
import { useStripe } from './use-stripe'

interface Card {
  id: string
  brand: string
}

export interface ModalsConfig {
  mobile?: boolean
  to?: string
  cvvModalName?: string
}

interface VerifyCardParams {
  card: Card
  amount: number
  subTotal: number
  address: any
  payeeId?: string
  multipleSources: boolean
  type: 'payees' | 'default'
  modalsConfig?: ModalsConfig
}

const verifying = ref(false)

async function verifyCard({
  card,
  amount = 0,
  subTotal = 0,
  address,
  payeeId,
  multipleSources = false,
  type,
  modalsConfig,
}: VerifyCardParams) {
  const {
    isFZenabled: isDefaultFZenabled,
    isPayeesFZenabled,
    isNABenabled: isDefaultNABenabled,
    isPayeesNABenabled,
    isStripeEnabled: isDefaultStripeEnabled,
    isPayeesStripeEnabled,
    isCVVenabled,
    isUserAddressEnabled,
  } = useProvider()

  if (!card) {
    throw new Error('No card')
  } else if (amount === 0) {
    throw new Error('Amount is zero')
  } else if (isUserAddressEnabled.value && !address) {
    throw new Error('No address')
  }

  const response = {
    securityToken: null as string | null,
    cvv: null as string | null,
  }

  const { verifyCard: verifyCardFz } = useFatZebra()
  const { verifyCard: verifyCardNab } = useNab()
  const { verifyCard: verifyCardStripe } = useStripe()

  let isFZenabled
  let isNABenabled
  let isStripeEnabled

  switch (type) {
    case 'payees':
      isFZenabled = isPayeesFZenabled.value
      isNABenabled = isPayeesNABenabled.value
      isStripeEnabled = isPayeesStripeEnabled.value
      break
    default:
      isFZenabled = isDefaultFZenabled.value
      isNABenabled = isDefaultNABenabled.value
      isStripeEnabled = isDefaultStripeEnabled.value
  }

  verifying.value = true

  try {
    if (isFZenabled) {
      let finalAmount

      if (!multipleSources && cardCode(card) === 'amex') {
        finalAmount = subTotal
      } else {
        finalAmount = amount
      }

      const { securityToken } = (await verifyCardFz({
        cardId: card.id,
        amount: finalAmount,
        address,
        payeeId,
      })) as any

      response.securityToken = securityToken
    } else if (isNABenabled) {
      const { securityToken } = (await verifyCardNab({
        cardId: card.id,
        amount,
        payeeId,
        multipleSources,
        modalsConfig,
      })) as any

      response.securityToken = securityToken
    } else if (isStripeEnabled) {
      const { securityToken } = await verifyCardStripe({
        cardId: card.id,
        amount,
        payeeId,
        multipleSources,
      })

      response.securityToken = securityToken
    }
  } catch (error) {
    verifying.value = false

    emitter.emit('modal:show', {
      name: '3ds-verification-issue',
    })
    throw new Error('Card verification failed')
  }

  try {
    if (isCVVenabled.value) {
      response.cvv = await new Promise((resolve, reject) => {
        let confirmed = false
        const {
          mobile = false,
          to,
          cvvModalName = 'cvv-code',
        } = modalsConfig ?? {}
        const modal = {
          name: cvvModalName,
          props: {
            cardBrand: card.brand,
            onConfirm: (data: string) => {
              confirmed = true
              resolve(data)
            },
          },
          on: {
            hide: () => {
              if (!confirmed) {
                return reject(new Error('Card verification cancelled'))
              }
            },
          },
        }

        if (mobile) {
          bottomSheet.show(cvvModalName, { to, ...modal })
        } else {
          emitter.emit('modal:show', modal)
        }
      })
    }
  } catch (error) {
    console.error('payment-methods', error)
    throw error
  } finally {
    verifying.value = false
  }

  return response
}

export const useVerifyCard = () => ({
  verifyingCard: verifying,
  verifyCard,
})
