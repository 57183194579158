<script>
import ui from '/~/core/ui'
import LayoutView from '/~rec/layouts/view/layout-view.vue'
import BaseFab from '/~/components/base/fab/base-fab.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-events-view',
  components: {
    LayoutView,
    BaseFab,
  },
  props: {
    feedId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { features } = useRecProfile()

    return {
      features,
      ui,
    }
  },
  data() {
    return {
      disableAnimation: false,
      scrollPosition: null,
      prevFeedId: null,
    }
  },
  watch: {
    $route(to, from) {
      if (from.name === 'rec-events-feed' && to.name === 'rec-event') {
        this.scrollPosition =
          document.getElementById(ui.desktop ? 'events-wrapper' : 'app-main')
            ?.scrollTop || 0
        this.prevFeedId = from.params.feedId
      }

      if (from.name === 'rec-event') {
        if (to.params.feedId === this.prevFeedId) {
          this.disableAnimation = true
        } else {
          this.scrollPosition = null
        }
      } else if (this.disableAnimation) {
        this.disableAnimation = false
      }

      if (to.name === 'rec-event') {
        this.disableAnimation = true
      }

      if (from.name === to.name) {
        this.scrollPosition = null
      }

      this.redirectToDefault(to.name)
    },
  },
  created() {
    this.redirectToDefault(this.$route.name)
  },
  methods: {
    redirectToDefault(route) {
      if (
        !this.feedId &&
        (route === 'rec-events' || route === 'rec-events-feed')
      ) {
        this.$router.replace({
          name: 'rec-events-feed',
          params: { feedId: this.features.events ? 'all' : 'celebrations' },
        })
      }
    },
    onEventCreate() {
      this.$router.push({ name: 'rec-event-create' })
    },
  },
}
</script>

<template>
  <layout-view>
    <base-fab v-if="!ui.desktop" @click="onEventCreate" />
    <router-view
      :disable-animation="disableAnimation"
      :scroll-position="scrollPosition"
      :feed-id="feedId"
    />
  </layout-view>
</template>
