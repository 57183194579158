<script setup lang="ts">
import { ValidationObserver } from 'vee-validate'
import Vue, { computed, ref } from 'vue'
import { VueRecaptcha } from 'vue-recaptcha'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useAuth } from '/~/composables/auth'
import { useForm } from '/~/composables/base/use-form'
import { useLogger } from '/~/composables/logger'
import { useProvider } from '/~/composables/provider'
import { ThirdPartyUserStorage } from '/~/composables/third-party-users/core/ThirdPartyUserStorage'
import { useUser } from '/~/composables/user'
import AuthLayout from '/~/layouts/auth/layout-auth.vue'
import AuthLogo from '/~/views/auth/auth-logo.vue'

const logger = useLogger('auth-login')

new ThirdPartyUserStorage().clear()

const { signIn } = useAuth()
const { hasUserClaim, fetchUserClaimStatus } = useUser()
const {
  isRegistrationFormEnabled,
  loginType,
  isClaimsEnabled,
  isRecaptchaEnabled,
  loginUsernameLabel,
  isLoginEnabled,
  providerName,
  isSymbioneliteProvider,
} = useProvider()
const { validationObserverRef } = useForm()

interface Form {
  email: string
  username: string
  password: string
  remember: boolean
}

const form = ref<Form>({
  email: '',
  username: '',
  password: '',
  remember: false,
})

const recaptchaRef = ref<InstanceType<typeof VueRecaptcha> | null>(null)
const recaptchaResponse = ref<string | null>(null)
const recaptchaSiteKey = ref(eonx.keys.recaptcha)
const processing = ref(false)
const backendErrors = ref<any>({})
const autofilled = ref<any[]>([])

const isUnfilled = computed(() => {
  return (
    (loginType.value === 'email'
      ? form.value.email.length === 0
      : form.value.username.length === 0) || form.value.password.length === 0
  )
})
const submitDisabled = computed(() => {
  return isUnfilled.value && autofilled.value.length !== 2
})

const recaptchaPassed = computed(() => {
  if (!isRecaptchaEnabled.value) {
    return true
  }

  return isRecaptchaEnabled.value && recaptchaResponse.value
})

const disabled = computed(() => {
  return processing.value
})

const inputType = computed(() => {
  return loginType.value === 'email' ? 'email' : 'text'
})

const idFieldLabel = computed(() => {
  if (loginType.value === 'email') {
    return 'Email'
  } else {
    return loginUsernameLabel.value
  }
})
const isRegistrationEnabled = computed(() => {
  if (!isRegistrationFormEnabled.value) {
    return false
  }

  if (!isClaimsEnabled.value) {
    return true
  }

  return hasUserClaim.value
})

// Add custom event handler as a workaround for Chrome mobile autofill issue
function onAutofillHandler(event: any) {
  autofilled.value.push(event)
}

interface ISignInPayload {
  password: string
  remember: boolean
  email?: string
  username?: string
  response?: string | null
}

async function submit() {
  if (!recaptchaPassed.value) return

  backendErrors.value = {}
  processing.value = true

  const payload: ISignInPayload = {
    password: form.value.password,
    remember: form.value.remember,
  }

  if (loginType.value === 'email') {
    payload.email = form.value.email
  } else {
    payload.username = form.value.username
  }

  if (recaptchaResponse.value) {
    payload.response = recaptchaResponse.value
  }

  try {
    await signIn(payload)

    const { fetchUserDetails } = useUser()

    const userData = await fetchUserDetails()

    if (isClaimsEnabled.value) {
      await fetchUserClaimStatus()
    }

    emitter.emit('auth:success', userData)
  } catch (e: any) {
    const { response } = e

    if (isRecaptchaEnabled.value && recaptchaRef.value) {
      recaptchaRef.value.reset()
    }

    if (response) {
      const { data, status } = response

      if (status === 201) {
        Vue.notify({
          type: 'success',
          text: response.body.message,
        })
      } else if (status === 422) {
        for (const key in data) {
          if (Object.hasOwn(data, key)) {
            const firstError = data[key][0]

            data[key] = firstError || ''
          }
        }

        backendErrors.value = data
      } else {
        console.error(e)
      }
    } else {
      logger.debug(e)
    }
  }

  processing.value = false
}

function onRecaptchaVerify(response: string | null) {
  if (response) {
    recaptchaResponse.value = response
  }
}
</script>

<template>
  <auth-layout :centered="!isLoginEnabled">
    <auth-logo :class="{ 'md:hidden': isSymbioneliteProvider }" />

    <div v-if="!isLoginEnabled" class="mx-auto max-w-[400px]">
      <h2 class="mb-5 text-center font-bold text-eonx-neutral-800">
        Login not available
      </h2>
      <p class="text-center text-eonx-neutral-600">
        In order to access to {{ providerName }} rewards you must login through
        a partner program.
      </p>
    </div>

    <validation-observer
      v-else-if="loginType"
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        v-analytics:mount="{
          page: 'Login',
          pageGroup: 'Auth',
          label: 'Login Page Rendered',
        }"
        class="mx-auto mt-16 w-full max-w-screen-xs p-5 md:mt-0"
        @submit.prevent="handleSubmit(submit)"
      >
        <h2
          class="mb-5 text-xl font-bold text-eonx-neutral-800 sm:mb-9 sm:text-left sm:text-2xl md:mt-[60px]"
        >
          Login
        </h2>
        <base-input
          v-model="form[loginType]"
          v-analytics:input="{
            page: 'Login',
            pageGroup: 'Auth',
            label: `${idFieldLabel} field changed`,
          }"
          :validation="{
            name: loginType,
            rules: 'required',
            mode: 'passive',
          }"
          :type="inputType"
          :error="backendErrors[loginType]"
          :name="loginType"
          :label="`${idFieldLabel} *`"
          :disabled="disabled"
          :autofill-notice="true"
          autocomplete="username"
          data-testid="username-input"
          @autofilled="onAutofillHandler"
        />
        <base-input
          v-model="form.password"
          v-analytics:input="{
            page: 'Login',
            pageGroup: 'Auth',
            label: `Password field changed`,
          }"
          :validation="{
            name: 'password',
            rules: 'required',
            mode: 'passive',
          }"
          :error="backendErrors.password"
          :disabled="disabled"
          name="password"
          label="Password"
          type="password"
          required
          autocomplete="current-password"
          :autofill-notice="true"
          data-testid="password-input"
          @autofilled="onAutofillHandler"
        />
        <div class="mb-7 mt-6 flex h-8 items-center justify-end">
          <!--         <div class="flex items-center justify-start">
            <base-checkbox
              v-model="form.remember"
              :disabled="disabled"
            >
              Remember me
            </base-checkbox>
            <base-icon
              v-tooltip="{
                content:
                  'Selecting remember me will keep you logged in for 30 days.',
                offset: 16,
                trigger: 'hover click',
              }"
              class="ml-2.5 opacity-50"
              svg="rec/question"
              size="md"
            />
          </div> -->
          <base-link
            ref="forgot-pass-link"
            v-analytics:click="{
              page: 'Login',
              pageGroup: 'Auth',
              cta: 'Forgot password?',
            }"
            name="auth-password"
            :disabled="disabled"
            class="font-bold"
          >
            Forgot password?
          </base-link>
        </div>
        <vue-recaptcha
          v-if="isRecaptchaEnabled"
          ref="recaptchaRef"
          data-testid="recaptcha"
          :sitekey="recaptchaSiteKey"
          load-recaptcha-script
          class="mb-16"
          @verify="onRecaptchaVerify"
          @expired="recaptchaResponse = null"
        />
        <base-button
          :disabled="disabled || submitDisabled || !recaptchaPassed"
          :loading="processing"
          class="mx-auto mb-3 w-full"
          type="submit"
          data-testid="login-button"
        >
          Login
        </base-button>
        <div v-if="isRegistrationEnabled" class="text-center">
          <span class="text-eonx-neutral-600">Don't have an account?</span>
          <base-link
            id="register-link"
            v-analytics:click="{
              page: 'Login',
              pageGroup: 'Auth',
              cta: 'Register now',
            }"
            :disabled="disabled"
            name="auth-register"
            class="ml-2.5 font-bold"
          >
            Register now
          </base-link>
        </div>
      </form>
    </validation-observer>
  </auth-layout>
</template>
