<script>
import DefaultDrawerEPurchasesTile from '/~/components/drawer/components/tiles/drawer-e-purchases-tile.vue'
import { useCms } from '/~/composables/cms'

export default {
  extends: DefaultDrawerEPurchasesTile,
  setup() {
    const { isDarkThemeForEwallet } = useCms()

    return {
      ...DefaultDrawerEPurchasesTile.setup?.(...arguments),
      isDarkThemeForEwallet,
    }
  },
}
</script>

<template>
  <div
    class="group relative flex flex-col no-underline"
    :class="{
      'cursor-pointer': clickable,
    }"
  >
    <component
      :is="clickable ? 'button' : 'div'"
      :type="clickable ? 'button' : null"
      class="block h-full w-full"
      @keydown.enter="onClick"
    >
      <div
        class="overflow-hidden shadow-xs transition duration-100 group-hover:shadow-lg"
        :class="{
          'rounded-3xl': detailsView,
          'rounded-lg': !detailsView,
        }"
      >
        <base-image
          :src="imageSrc"
          :alt="card.retailerName"
          :style="{
            filter: disabled ? 'grayscale(1)' : undefined,
          }"
          :active="false"
          class="transition duration-100 group-hover:shadow-lg"
          :class="{
            'rounded-3xl': detailsView,
            'rounded-lg': !detailsView,
          }"
          @click="onClick"
        >
          <span
            v-if="card.pending"
            class="absolute inset-0 flex h-full w-full cursor-default items-center justify-center bg-overlay p-2.5 text-center opacity-0 transition duration-100 hover:opacity-100"
            @click.stop
          >
            <span class="p-[5px] text-sm text-white">
              Pending Delivery
              <br />
              May take up to 72 Hours to Process.
            </span>
          </span>
        </base-image>
      </div>
    </component>

    <div
      v-if="!card.viewed && !card.pending"
      class="absolute top-0 right-0 -mt-[5px] -mr-[5px] rounded-xl bg-red-700 px-1.5 py-0.5 text-center text-xs font-bold uppercase text-white shadow"
    >
      new
    </div>
    <div
      v-if="detailsView"
      class="relative -mt-6 self-center rounded-xl bg-primary px-[30px] text-center font-bold leading-10 text-white"
    >
      {{ formatDollar(balance) }}
    </div>
    <div v-if="extended" class="mt-2.5">
      <div
        :class="{
          'text-eonx-neutral-600': !isDarkThemeForEwallet,
        }"
      >
        {{ title }}
      </div>
      <div
        class="font-black"
        :class="{
          'text-eonx-neutral-800': !isDarkThemeForEwallet,
        }"
      >
        {{ formatDollar(balance) }}
      </div>
    </div>
    <div v-if="archived" class="mt-2.5">
      <base-button
        :disabled="restoring"
        :loading="restoring"
        full-width
        size="sm"
        @click="restoreCard"
      >
        Restore
      </base-button>
    </div>
  </div>
</template>
