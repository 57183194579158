<script>
import { computed, ref, watch } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { roundFigure, formatNumber, floorFigure } from '/~/utils/format/numeric'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRange from '/~/components/base/range/base-range.vue'
import { usePoints } from '/~/composables/points'

export default {
  name: 'payments-split-form-points',
  components: {
    BaseRange,
    BaseIcon,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    burnPointsRate: {
      type: Number,
      default: 1,
    },
    amountToPay: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { pointsBalance } = usePoints()

    const pointsToUse = ref(props.value)

    const pointsMaxToUse = computed(() => {
      const maxPoints = Math.ceil(props.total / props.burnPointsRate)

      return Math.min(maxPoints, pointsBalance.value)
    })

    const calculatedAmount = computed(() => {
      return floorFigure(pointsToUse.value * props.burnPointsRate)
    })
    const pointsRemaining = computed(
      () => pointsBalance.value - pointsToUse.value
    )

    watch(pointsToUse, (value) => {
      emit('input', value)
    })

    return {
      pointsToUse,
      pointsMaxToUse,
      pointsRemaining,
      formatDollar,
      formatNumber,
      calculatedAmount,
    }
  },
}
</script>

<template>
  <div class="mt-12 w-full">
    <div class="flex text-sm">
      <div class="mr-auto">
        {{ formatNumber(pointsRemaining) }}
        <span class="text-eonx-neutral-600">points available</span>
      </div>
      <div>
        <span class="text-eonx-neutral-600">Value</span>
        {{ formatDollar(calculatedAmount) }}
      </div>
    </div>
    <base-range
      v-model="pointsToUse"
      class="mt-4 w-full"
      :min="0"
      :dot-size="32"
      :max="pointsMaxToUse"
      :tooltip="'none'"
      placement="top"
      formatter="{value}"
      look="points"
    >
      <template #dot>
        <div class="flex flex-col items-center">
          <base-icon
            svg="v2/custom/points-token"
            :size="32"
            class="rounded-full border-2 border-primary bg-white text-primary"
          />
          <div class="whitespace-nowrap text-sm font-bold text-primary">
            {{ pointsToUse }}&nbsp;Pts
          </div>
        </div>
      </template>
    </base-range>
  </div>
</template>
