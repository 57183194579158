<script>
import ui from '/~/core/ui'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useProvider } from '/~/composables/provider'
import { computed } from 'vue'
import { getProviderDefaultUrl } from '/~/utils/cdn'

export default {
  name: 'competitions-tile',
  components: {
    BaseImage,
    BaseLink,
  },
  props: {
    tile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isBupaTemplate } = useProvider()

    const defaultImage = computed(() =>
      isBupaTemplate.value ? getProviderDefaultUrl('bupa') : undefined
    )
    const image = computed(() => props.tile.image)

    return {
      isBupaTemplate,
      image,
      defaultImage,
      ui,
    }
  },
}
</script>

<template>
  <base-link
    :to="{
      name: 'competition-details',
      params: { id: tile.id },
    }"
  >
    <div class="relative h-full rounded-md bg-white">
      <base-image
        :src="image"
        :ratio="4 / 3"
        :default-src="defaultImage"
        :shadow="false"
        :rounded="ui.mobile"
        :top-rounded="!ui.mobile"
      />
      <div
        class="hidden p-[15px] text-center text-sm font-bold text-black sm:block"
      >
        {{ tile.title }}
      </div>
    </div>
  </base-link>
</template>
