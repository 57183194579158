import type { CMSPageId } from '/~/types/cms'
import { RewardsExtension } from '/~/core/extension'

class PresalesExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  setRoutes() {
    return [
      {
        path: '/pre-sales',
        name: 'pre-sales',
        props: true,
        meta: {
          title: 'Pre-sales',
          page: 'pre-sales' as CMSPageId,
        },
        component: () => import('./pre-sales.vue'),
      },
    ]
  }
}

export default PresalesExtension
