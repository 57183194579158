import auspostPreset from './auspost'
import billpayPreset from './billpay'
import bupaPreset from './bupa'
import defaultPreset from './default'

export default {
  default: defaultPreset,
  billpay: billpayPreset,
  bupa: bupaPreset,
  auspost: auspostPreset,
}
