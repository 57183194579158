import { MessageStatus } from '/~/dict/recroom'
import { RecroomAttachmentsProcessor } from '/~rec/core/attachments-processor'
import { useRecProfile } from '/~rec/composables/profile'

const { myRecId } = useRecProfile()

export class RecroomChatMessage {
  constructor(raw) {
    this.raw = raw
    this.attachments = new RecroomAttachmentsProcessor(raw.attachments)
  }

  update(data) {
    this.raw = { ...this.raw, ...data }
    this.attachments = new RecroomAttachmentsProcessor(data.attachments)
  }

  updateBody(content) {
    this.raw.body = content
  }

  get id() {
    return this.raw.id
  }

  get userId() {
    return this.raw.user_id
  }

  get participantId() {
    return this.raw.participant_id
  }

  get body() {
    let body = this.raw.body

    body = body
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&#39;/g, "'")

    return body.trim()
  }

  get createdAt() {
    return this.raw.created_at
  }

  get updatedAt() {
    return this.raw.updated_at
  }

  get sending() {
    return this.raw.sending || false
  }

  get isMeAuthor() {
    return this.userId === myRecId.value
  }

  get views() {
    return this.raw.views
  }

  get isEmpty() {
    return this.raw.body === ''
  }

  get isDeleted() {
    return this.raw.body === MessageStatus.DELETED
  }
}
