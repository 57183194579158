<script>
import CreatorFile from '/~rec/components/post/creator/attachments/creator-file.vue'
import CreatorGif from '/~rec/components/post/creator/attachments/creator-gif.vue'
import Popup from '/~/components/base/select/popup'

export default {
  name: 'rich-attachments-popup',
  components: {
    CreatorFile,
    CreatorGif,
    Popup,
  },
  props: {
    attachments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    files() {
      return this.attachments.filter((a) => a.type !== 'image/gif')
    },
    gifs() {
      return this.attachments.filter((a) => a.type === 'image/gif')
    },
  },
  methods: {
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
  },
}
</script>

<template>
  <div class="relative">
    <popup :visible="visible" @click="close">
      <div
        class="mb-[5px] flex h-full max-h-80 w-80 flex-col overflow-auto rounded-md bg-white p-2.5 shadow-sm"
      >
        <div v-if="files.length" class="flex flex-wrap">
          <creator-file
            v-for="(file, idx) in files"
            :key="idx"
            :attachment="file"
            removable
            class="m-2.5"
            @delete="$emit('delete', file)"
          />
        </div>
        <div v-if="gifs.length" class="flex flex-wrap">
          <creator-gif
            v-for="(gif, idx) in gifs"
            :key="idx"
            :gif="gif"
            removable
            class="m-2.5"
            @delete="$emit('delete', gif)"
          />
        </div>
      </div>
    </popup>
  </div>
</template>
