<script>
import { computed } from 'vue'
import CommunityHubTileDefault from '/~/extensions/community-hub/templates/default/views/catalog/components/community-hub-tile.vue'
import { getProviderDefaultUrl } from '/~/utils/cdn'

export default {
  name: 'community-hub-catalog-tile',
  extends: CommunityHubTileDefault,
  setup() {
    const defaultImage = computed(() => getProviderDefaultUrl('bupa'))

    return {
      ...CommunityHubTileDefault.setup?.(...arguments),
      defaultImage,
    }
  },
}
</script>

<template>
  <base-link
    class="relative flex cursor-pointer overflow-hidden rounded-lg"
    :to="{
      name: `${$ext.name}-retailer`,
      params: { id: tile.retailer_id },
    }"
  >
    <div class="w-[174px] flex-none">
      <base-image
        :src="imageUrl"
        :default-src="defaultImage"
        :alt="retailer.name"
        class="h-full"
        :rounded="false"
        :ratio="1"
        aria-hidden="true"
      />
    </div>
    <div
      class="relative flex w-full flex-col overflow-hidden p-[15px] text-default"
    >
      <div class="border-b border-bg-dark pb-[15px]">
        <h3 class="truncate text-base font-bold">
          {{ retailer.name }}
        </h3>
      </div>
      <div
        class="mt-2.5 text-sm leading-5 text-eonx-neutral-800 line-clamp-3 sm:mt-[15px]"
      >
        {{ retailer.description }}
      </div>
    </div>
  </base-link>
</template>
