<script>
import DefaultEstoreCatalogTile from '/~/extensions/estore/components/estore-tile.vue'
import EstoreTilePrice from './tile/estore-tile-price.vue'

export default {
  name: 'estore-catalog-tile',
  components: {
    EstoreTilePrice,
  },
  extends: DefaultEstoreCatalogTile,
}
</script>

<template>
  <div
    ref="tile"
    class="h-full"
    :class="{
      'pointer-events-none opacity-50': loading,
    }"
  >
    <base-link
      ref="link"
      class="w-full cursor-pointer"
      :to="itemRoute"
      @click.prevent="routeTo"
    >
      <div class="flex h-full w-full flex-col">
        <base-card>
          <base-image
            ref="image"
            :src="imgSource"
            :default-src="defaultImage"
            class="h-full p-2.5 sm:p-5"
            :fit="false"
            :ratio="false"
          />
        </base-card>
        <div
          ref="description"
          class="flex min-h-[120px] grow flex-col text-center"
        >
          <div class="grow px-2.5 py-[5px]">
            <h3
              ref="title"
              class="text-sm font-bold text-eonx-neutral-600"
              data-cy="ew-title"
            >
              {{ data.name }}
            </h3>
          </div>
          <estore-tile-price :tile="tile" />
        </div>
      </div>
    </base-link>
  </div>
</template>
