<script>
import CreatorFile from '/~rec/components/post/creator/attachments/creator-file.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useForm } from '/~/composables/base/use-form'
import { extend, ValidationObserver } from 'vee-validate'

export default {
  name: 'rec-post-config-attachments',
  components: {
    CreatorFile,
    BaseInput,
    BaseIcon,
    ValidationObserver,
  },
  props: {
    actionEarnLimits: {
      type: Object,
      required: true,
    },
    typeString: {
      type: String,
      default: 'Post',
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { validationObserverRef } = useForm()

    return {
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    total() {
      return this.attachments.length
    },
    attachmentView() {
      return this.actionEarnLimits.attachment_view || {}
    },
  },
  watch: {
    attachments: {
      handler() {
        this.setPredefinedConfig()
      },
      deep: true,
    },
  },
  mounted() {
    this.setPredefinedConfig()
    extend('attachments_max_value', {
      validate: (value, { max }) => {
        const maxValue = parseFloat(max)
        const parsedValue = parseFloat(String(value).replace(' ', ''))

        return parsedValue <= maxValue
          ? true
          : `The value should be ${max} or less.`
      },
    })
    extend('attachments_required', {
      validate: (value) => {
        return ['', null, undefined].indexOf(value) === -1
          ? true
          : 'The field is required.'
      },
    })
  },
  methods: {
    setPredefinedConfig() {
      this.attachments.forEach((attachment) => {
        const config = attachment.earnConfigs && attachment.earnConfigs[0]

        if (config) {
          const earnValue = config.earnValue
          const ele = document.querySelector(`input[name="${attachment.id}"]`)

          if (ele) {
            ele.setAttribute('readonly', true)
          }
          this.form[attachment.id] = earnValue
        } else {
          this.form[attachment.id] = 0
        }
      })
    },
    config() {
      return this.attachments.map((attachment) => {
        const inline = attachment.inline

        return {
          ...inline,
          action: 'attachment_view',
          earn_value: this.form[attachment.id],
        }
      })
    },
    async update() {
      const isValid = await this.validationObserverRef?.validate()

      this.$emit('checkValidation')
      if (!isValid) return

      const payload = this.config()

      this.$emit('update', payload)
    },
  },
}
</script>

<template>
  <validation-observer ref="validationObserverRef" slim>
    <div>
      <div class="mt-5 mb-2.5">
        <div class="flex items-center font-bold">
          <base-icon class="mr-2.5" svg="rec/attachment" size="md" />
          Attachments
        </div>
      </div>
      <div class="-mx-5 mt-2.5 flex flex-wrap px-2.5">
        <div
          v-for="(attachment, idx) in attachments"
          :key="idx"
          class="mx-2.5 mb-2.5 flex flex-col items-center justify-center"
        >
          <creator-file :attachment="attachment" class="mb-2.5" />
          <b class="mb-2.5 text-sm text-eonx-neutral-600">
            <span class="capitalize">
              {{ currency }}
            </span>
            (max. {{ attachmentView.maxEarnValue }})
          </b>
          <base-input
            v-model="form[attachment.id]"
            :validation="{
              rules: `attachments_required|attachments_max_value:${attachmentView.maxEarnValue}`,
              name: 'Attachment ID',
            }"
            :name="attachment.id"
            :mask="{ mask: '000' }"
            class="w-full max-w-[120px]"
            nolabel
            @input="update()"
          />
        </div>
      </div>
    </div>
  </validation-observer>
</template>
