<script>
import LayoutView from '/~rec/layouts/view/layout-view.vue'

export default {
  name: 'rec-awards-view',
  components: {
    LayoutView,
  },
}
</script>

<template>
  <layout-view heading="Rec Room">
    <router-view />
  </layout-view>
</template>
