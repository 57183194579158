import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomPerk } from '/~rec/core/perk'
import { RecroomPerkRedeemed } from '/~rec/core/perk-redeemed'
import { reactive } from 'vue'
import { useRecProfile } from '/~rec/composables/profile'

const defaultFilters = {
  recent: {
    'order[createdAt]': 'desc',
  },
  valuable: {
    'order[cost]': 'desc',
  },
  ending: {
    'order[endsAt]': 'desc',
  },
  popular: {
    'order[totalRedeemed]': 'desc',
  },
  asc: {
    'order[name]': 'asc',
  },
}

const { orgMemberId } = useRecProfile()

const state = reactive({
  available: new EntityProcessor({
    entity: 'gamification/perks',
    mapping: (record) => new RecroomPerk(record),
    filters: defaultFilters,
  }),
  pendingApproval: new EntityProcessor({
    entity: 'gamification/redeemed-perks',
    mapping: (record) => new RecroomPerkRedeemed(record),
    filters: () => {
      return {
        default: {
          member: orgMemberId.value,
          'statuses[]': 'pending_approval',
          'order[name]': 'asc',
        },
        valuable: {
          member: orgMemberId.value,
          'statuses[]': 'pending_approval',
          'order[cost]': 'desc',
        },
        asc: {
          member: orgMemberId.value,
          'statuses[]': 'pending_approval',
          'order[name]': 'asc',
        },
        recent: {
          member: orgMemberId.value,
          'statuses[]': 'pending_approval',
          'order[createdAt]': 'asc',
        },
        popular: {
          member: orgMemberId.value,
          'statuses[]': 'pending_approval',
          'order[totalRedeemed]': 'desc',
        },
        ending: {
          member: orgMemberId.value,
          'statuses[]': 'pending_approval',
          'order[endsAt]': 'asc',
        },
      }
    },
  }),
  redeemed: new EntityProcessor({
    entity: 'gamification/redeemed-perks',
    mapping: (record) => new RecroomPerkRedeemed(record),
    filters: () => {
      return {
        default: {
          member: orgMemberId.value,
          'statuses[]': 'fulfilled',
          'statuses[] ': 'approved_completed',
          'order[name]': 'asc',
        },
        valuable: {
          member: orgMemberId.value,
          'statuses[]': 'fulfilled',
          'statuses[] ': 'approved_completed',
          'order[cost]': 'desc',
        },
        asc: {
          member: orgMemberId.value,
          'statuses[]': 'fulfilled',
          'statuses[] ': 'approved_completed',
          'order[name]': 'asc',
        },
        recent: {
          member: orgMemberId.value,
          'statuses[]': 'fulfilled',
          'statuses[] ': 'approved_completed',
          'order[createdAt]': 'asc',
        },
        popular: {
          member: orgMemberId.value,
          'statuses[]': 'fulfilled',
          'statuses[] ': 'approved_completed',
          'order[totalRedeemed]': 'desc',
        },
        ending: {
          member: orgMemberId.value,
          'statuses[]': 'fulfilled',
          'statuses[] ': 'approved_completed',
          'order[endsAt]': 'asc',
        },
      }
    },
  }),
})

export const usePerks = () => ({
  perks: state,
})
