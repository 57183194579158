<script>
import AddressItem from '../../components/addresses/checkout-address-item.vue'
import BaseButtonRound from '/~/components/base/button-round/base-button-round.vue'
import BaseBack from '/~/components/base/back/base-back.vue'
import { useCart } from '/~/composables/cart'
import { useAddresses } from '/~/composables/addresses'

export default {
  name: 'checkout-addresses-view',
  components: {
    AddressItem,
    BaseButtonRound,
    BaseBack,
  },
  setup() {
    const { addCartOrderAddress, removeCartOrderAddress, cartOrderAddresses } =
      useCart()
    const { addresses, defaultAddress, firstAddress } = useAddresses()

    return {
      addCartOrderAddress,
      removeCartOrderAddress,
      cartOrderAddresses,
      addresses,
      defaultAddress,
      firstAddress,
    }
  },
  computed: {
    type() {
      return this.$route.meta.type
    },
  },
  methods: {
    onSelectAddress(address) {
      this.addCartOrderAddress({ address, type: this.type })
      this.$router.push({ name: 'purchase-checkout' })
    },
    isSelected(addressId) {
      const { billing, shipping } = this.cartOrderAddresses

      return (
        (this.type === 'billing' && billing?.id === addressId) ||
        (this.type === 'shipping' && shipping?.id === addressId)
      )
    },
    getFallbackAddress(addressId) {
      if (this.defaultAddress && this.defaultAddress.id !== addressId) {
        return this.defaultAddress
      }

      const filteredAddresses =
        this.addresses.filter((item) => item.id !== addressId) || []

      return filteredAddresses[0] || null
    },
    async onRemoveAddress(addressId) {
      const { billing, shipping } = this.cartOrderAddresses
      const isBilling = billing?.id === addressId
      const isDelivery = shipping?.id === addressId
      const fallbackAddress = this.getFallbackAddress(addressId)

      if (isBilling || isDelivery) {
        if (fallbackAddress) {
          if (isBilling) {
            await this.addCartOrderAddress({
              address: fallbackAddress,
              type: 'billing',
            })
          }
          if (isDelivery) {
            await this.addCartOrderAddress({
              address: fallbackAddress,
              type: 'shipping',
            })
          }
        } else {
          await this.removeCartOrderAddress(this.type)
        }
      }
    },
    onAddAddress() {
      this.$router.push({ hash: '#profile-add-address' })
    },
  },
}
</script>

<template>
  <div>
    <base-back :to="{ name: 'purchase-checkout' }" />

    <div class="my-5 flex items-center justify-between">
      <h2 class="text-xl">Select Address</h2>
      <base-button-round icon="plus" size="lg" @click="onAddAddress" />
    </div>

    <div class="space-y-5">
      <address-item
        v-for="address in addresses"
        :key="address.id"
        :item="address"
        show-menu
        :selected="isSelected(address.id)"
        @click="onSelectAddress(address)"
        @remove="onRemoveAddress(address.id)"
      />
    </div>
  </div>
</template>
