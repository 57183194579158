<script>
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import PostBox from '/~rec/components/post/box/post-box.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import RecFlashPosts from '/~rec/components/feed/flash-posts.vue'
import { useFeeds } from '/~rec/composables/feeds'
import PlacehoderFeed from '/~rec/components/placeholder/placeholder-feed.vue'

export default {
  name: 'rec-feed-posts',
  components: {
    EwCatalogProcessor,
    BasePlaceholder,
    RecFlashPosts,
    PostBox,
    PlacehoderFeed,
  },
  props: {
    feed: {
      type: Object,
      required: true,
    },
    flashPosts: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isNewPostProcessing } = useFeeds()

    return {
      isNewPostProcessing,
    }
  },
}
</script>

<template>
  <div class="relative h-full">
    <rec-flash-posts
      v-if="flashPosts"
      v-bind="$attrs"
      :feed="feed"
      class="fixed right-0 left-0 z-20"
      @loaded="$emit('posts-loaded')"
    />

    <placehoder-feed v-if="isNewPostProcessing" class="mb-5 md:mt-6 md:mb-0" />
    <ew-catalog-processor
      id="rec-feed-desktop"
      :processor="feed"
      :columns="1"
      :tile-class="false"
      class="flex h-full grow"
    >
      <template #empty>
        <slot name="empty" />
      </template>

      <template #placeholder>
        <base-placeholder
          v-if="!isNewPostProcessing"
          :height="128"
          type="block"
          class="sm:mt-[15px]"
        />
        <div v-else />
      </template>

      <template #tile="{ tile }">
        <post-box v-if="tile" class="bg-white" :post="tile" :feed="feed" />
      </template>
    </ew-catalog-processor>
  </div>
</template>
