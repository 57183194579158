<script setup lang="ts">
import BaseImage from '/~/components/base/image/base-image.vue'
import { useEditablePage } from '/~/composables/cms'

const { page } = useEditablePage()
</script>

<template>
  <div v-if="page" class="mx-auto w-full max-w-7xl">
    <widgets-builder v-if="page.widgets" :widgets="page.widgets" />

    <section
      v-if="page.settings && page.settings.groups"
      class="flex w-full flex-col justify-between gap-10 p-2.5 sm:p-5 md:flex-row"
    >
      <router-link
        v-for="(item, index) in page.settings.groups"
        :key="index"
        :to="{
          name: 'cinema-group',
          params: {
            groupSlug: item.slug.toLowerCase(),
          },
        }"
        class="block w-full md:w-1/2"
      >
        <base-image
          :src="item.banner && item.banner.desktop"
          fit="contain"
          :ratio="12 / 5"
        />
      </router-link>
    </section>
  </div>
</template>
