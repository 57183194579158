<script>
import get from 'lodash-es/get'
import { ValidationObserver } from 'vee-validate'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useForm } from '/~/composables/base/use-form'
import { usePayees } from '/~/composables/payees'

export default {
  name: 'profile-payee-add-bpay',
  components: {
    BaseInput,
    BaseButton,
    ValidationObserver,
  },
  setup() {
    const { payees, createPayee, validateBPay } = usePayees()
    const { validationObserverRef } = useForm()

    return {
      payees,
      validationObserverRef,
      createPayee,
      validateBPay,
    }
  },
  data() {
    return {
      form: {
        type: 'bpay',
        name: '',
        billerCode: '',
        reference: '',
      },
      name: '',
      billerCodeValidating: false,
      billerCodeValidated: false,
      backendErrors: {},
    }
  },
  computed: {
    canProceed() {
      const { form } = this

      return (
        form.name &&
        form.billerCode &&
        form.reference &&
        this.validationObserverRef?.flags.valid &&
        this.billerCodeValidated
      )
    },
    isLoading() {
      return this.payees.creating
    },
    isSubmitDisabled() {
      return !this.canProceed
    },
  },
  watch: {
    'form.billerCode'() {
      this.billerCodeValidated = false
    },
  },
  methods: {
    onCancelClick() {
      this.$router.back()
    },
    processBackendErrors(data) {
      const { validationErrors } = data
      const backendErrors = {}

      if (validationErrors) {
        for (const key in validationErrors) {
          const firstError = validationErrors[key][0]

          backendErrors[key] = firstError || ''
        }
        this.backendErrors = backendErrors
      }
    },
    async onSaveClick() {
      this.backendErrors = {}

      const payload = Object.assign({}, this.form)

      try {
        const payee = await this.createPayee(payload)

        emitter.emit('menu:pay-anyone:select-to', payee)
        this.$router.replace({ hash: '#profile-pay-to' })
      } catch (error) {
        this.processBackendErrors(error.data || error)
      }
    },
    async validateBillerCode() {
      const { billerCode } = this.form

      if (!billerCode || billerCode.length < 2 || billerCode.length > 10) {
        this.validationObserverRef?.refs.billerCode?.validate()
        return
      }

      delete this.backendErrors.billerCode

      try {
        this.billerCodeValidating = true
        const result = await this.validateBPay(billerCode)

        this.name = get(result, 'longName', '')
        this.form.name = this.name.substring(0, 32)

        if (this.name) {
          delete this.backendErrors.name
          this.validationObserverRef?.refs.name?.validate()
        }

        this.billerCodeValidated = true
      } catch (error) {
        this.name = ''
        this.backendErrors.billerCode = error.message
      } finally {
        this.billerCodeValidating = false
        this.validationObserverRef?.refs.billerCode?.validate()
      }
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <div class="flex h-full flex-col items-stretch justify-between">
      <div>
        <base-input
          v-model="form.billerCode"
          :validation="{
            rules: 'required|numeric|min:2|max:10',
            name: 'Biller Code',
            mode: 'passive',
            vid: 'billerCode',
          }"
          :error="backendErrors.billerCode"
          :disabled="payees.creating || billerCodeValidating"
          :loading="billerCodeValidating"
          label="BPAY Biller Code"
          required
          name="billerCode"
          maxlength="10"
          :mask="{ mask: '0000000000' }"
          @blur="validateBillerCode"
        />
        <div
          v-if="name"
          class="mb-[15px] -mt-[15px] text-right text-sm text-eonx-neutral-600"
        >
          {{ name }}
        </div>

        <base-input
          v-model="form.name"
          :validation="{
            rules: 'required|max:256',
            name: 'Biller Name',
            vid: 'name',
          }"
          :error="backendErrors.name"
          :disabled="payees.creating"
          label="Biller name"
          required
          name="name"
          maxlength="256"
        />

        <base-input
          v-model="form.reference"
          :validation="{
            rules: 'required|numeric|max:20|min:2',
            name: 'Reference No.',
          }"
          :error="backendErrors.reference"
          :disabled="payees.creating"
          label="Reference No."
          required
          name="reference"
          maxlength="20"
          :mask="{ mask: '00000000000000000000' }"
        />
      </div>

      <div class="grid grid-cols-2 gap-5">
        <base-button
          look="outlined-color"
          class="w-full"
          :disabled="isLoading"
          @click="onCancelClick"
        >
          Cancel
        </base-button>
        <base-button
          class="w-full"
          :loading="isLoading"
          :disabled="isSubmitDisabled"
          @click="handleSubmit(onSaveClick)"
        >
          Save
        </base-button>
      </div>
    </div>
  </validation-observer>
</template>
