<script>
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'base-input-action',
  components: {
    BaseInput,
    BaseButton,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    nolabel: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    mask: {
      type: [String, Object],
      default: null,
    },
    error: {
      type: [String, Array],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    currency: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    customTheme: {
      type: Boolean,
      default: true,
    },
    look: {
      type: String,
      default: '',
      validator: (v) => !v || /ewallet-v2/.test(v),
    },
  },
  computed: {
    isV2Look() {
      return this.look === 'ewallet-v2'
    },
    isV2LightLook() {
      return this.look === 'ewallet-v2-light'
    },
  },
}
</script>

<template>
  <form
    :class="[
      $style.wrapper,
      customTheme && $style.customTheme,
      (isV2Look || isV2LightLook) && $style.v2Theme,
      isV2LightLook && $style.v2LightTheme,
    ]"
    @submit.prevent
  >
    <base-input
      :value="value"
      :label="label"
      :class="{ [$style.nolabel]: nolabel }"
      :icon="icon"
      :mask="mask"
      :error="error"
      :name="name"
      :currency="currency"
      :type="type"
      @input="$emit('input', $event)"
    />
    <base-button
      :disabled="disabled"
      :loading="loading"
      size="small"
      @click="action"
    >
      {{ btnText }}
    </base-button>
  </form>
</template>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: flex-start;

  :global {
    .base-field {
      margin-bottom: 0;

      &__entry {
        border-right: none;

        @apply h-[50px] rounded-r-none;
      }

      &__label {
        @apply overflow-visible pb-2.5 text-sm font-bold leading-4 text-eonx-neutral-600;

        &--with-icon {
          left: 0;
        }
      }
    }

    .base-button {
      @apply mt-6 h-[50px] w-[120px] rounded-l-none;

      &:before {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.customTheme {
  :global {
    .base-field {
      @apply min-h-24;

      &__label {
        color: var(--color-gray);
      }
    }

    .base-button:disabled {
      background-color: #ccc;

      @apply text-white;
    }
  }
}

.v2Theme {
  :global {
    .base-field {
      @apply h-11 min-h-0;

      &__label {
        @apply text-eonx-neutral-800;
      }
    }

    .base-field__entry {
      @apply h-11;
    }

    .base-button {
      @apply h-11;
    }

    .base-button:disabled {
      @apply bg-gray-50 text-eonx-neutral-800;
    }

    .base-button__label {
      @apply text-base;
    }
  }
}

.v2LightTheme {
  :global {
    .base-field__label {
      @apply text-white;
    }
  }
}
</style>
