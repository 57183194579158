<script>
import modal from '/~/core/mdl'
import { formatDate } from '/~/utils/format/date'
import { pluralize } from '/~/utils/format/string'
import { formatNumber } from '/~/utils/format/numeric'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useUI } from '/~/composables/ui'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-user-info',
  components: {
    BaseIcon,
    BaseAvatar,
    BaseMdl,
    BaseLoader,
  },
  setup() {
    const { toggleEwalletMenu } = useUI()
    const {
      profile,
      currency,
      isMeAdmin,
      isMeSuperAdmin,
      getTokenBalance,
      getManagementPoints,
    } = useRecProfile()

    return {
      formatDate,
      formatNumber,
      toggleEwalletMenu,
      profile,
      currency,
      isMeAdmin,
      isMeSuperAdmin,
      getTokenBalance,
      getManagementPoints,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    tokensPersonal() {
      const balance = formatNumber(this.profile.tokenBalance)
      const currency = pluralize(
        this.profile.tokenBalance,
        this.currency.slice(0, -1)
      )

      return `${balance} ${currency}`
    },
    manageUrl() {
      return eonx.recroom.manageUrl
    },
  },
  async mounted() {
    const promises = [this.getTokenBalance()]

    if (this.isMeAdmin || this.isMeSuperAdmin) {
      promises.push(this.getManagementPoints(this.profile.me.orgMemberId))
    }

    this.loading = true
    await Promise.all(promises)
    this.loading = false
  },
  methods: {
    onProfile() {
      modal.hide()
      this.toggleEwalletMenu()
      setTimeout(() => {
        this.$router.replace({ hash: '#profile-details' })
      }, 30)
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" width="xs">
    <div class="p-6">
      <base-loader v-if="loading" fullwidth />

      <div v-else class="flex flex-col">
        <div class="mb-5 flex items-center">
          <base-avatar
            class="mr-[15px]"
            size="2xl"
            :src="profile.me.avatarUrl"
            :abbr="profile.me.initials"
          />
          <div class="flex flex-col">
            <span class="text-lg font-bold">
              {{ profile.me.fullName }}
            </span>
            <div class="flex justify-start">
              <span
                class="flex cursor-pointer items-center text-primary"
                @click="onProfile"
              >
                View Profile
                <base-icon
                  class="ml-[5px]"
                  svg="plain/chevron-right"
                  size="xxs"
                />
              </span>
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-center rounded-xl bg-primary py-2.5 text-white"
        >
          <base-icon class="mx-2.5 block" svg="rec/token" size="md" />
          <span class="uppercase">
            {{ tokensPersonal }}
          </span>
        </div>

        <template v-if="isMeAdmin || isMeSuperAdmin">
          <div class="my-5 -mx-[30px] border-t" />

          <div
            class="flex items-center justify-center rounded-xl bg-primary py-2.5 text-white"
          >
            <base-icon class="mx-2.5 block" svg="rec/token" size="md" />
            <span class="uppercase leading-none">
              {{ formatNumber(profile.managementWallet.remaining_allowance) }}
              management
              <br />
              {{ currency }} remaining
            </span>
          </div>

          <div
            class="my-[15px] text-center text-sm font-bold leading-5 text-eonx-neutral-600"
          >
            Balance reset to
            {{ formatNumber(profile.managementWallet.monthly_allowance) }} on
            the
            {{
              formatDate(
                'daymonthyearnumeric',
                profile.managementWallet.next_top_up_at
              )
            }}
          </div>

          <div class="flex justify-center">
            <a
              :href="manageUrl"
              target="_blank"
              class="inline-flex items-center"
            >
              <b>Go to Recroom Manage</b>
              <base-icon svg="plain/chevron-right" class="ml-2.5" size="xs" />
            </a>
          </div>
        </template>
      </div>
    </div>
  </base-mdl>
</template>
