<script>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import BaseBack from '/~/components/base/back/base-back.vue'
import { useAddresses } from '/~/composables/addresses'
import { useCart } from '/~/composables/cart'
import { useCheckoutRoutes, useCheckout } from '/~/composables/checkout'
import AddressItem from '/~/views/checkout.v2/components/checkout/addresses/addresses-item.vue'

export default {
  name: 'checkout-addresses',
  components: {
    AddressItem,
    BaseBack,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { getCurrentFlowRouteName } = useCheckoutRoutes()
    const { addCartOrderAddress, removeCartOrderAddress, cartOrderAddresses } =
      useCart()
    const { addresses, defaultAddress, firstAddress, addressesWithoutDefault } =
      useAddresses()
    const { payment } = useCheckout()

    const type = computed(() => {
      return route.meta.type
    })

    function onSelectAddress(address) {
      addCartOrderAddress({ address, type: type.value })
      router.push({
        name: getCurrentFlowRouteName('checkout'),
        query: route.query,
      })
    }

    function getFallbackAddress(addressId) {
      if (defaultAddress.value && defaultAddress.value.id !== addressId) {
        return defaultAddress.value
      }

      const filteredAddresses =
        addresses.value.filter((item) => item.id !== addressId) || []

      return filteredAddresses[0] || null
    }

    async function onRemoveAddress(addressId) {
      const { billing, shipping } = cartOrderAddresses
      const isBilling = billing?.id === addressId
      const isDelivery = shipping?.id === addressId
      const fallbackAddress = getFallbackAddress(addressId)

      if (isBilling || isDelivery) {
        if (fallbackAddress) {
          if (isBilling) {
            await addCartOrderAddress({
              address: fallbackAddress,
              type: 'billing',
            })
          }
          if (isDelivery) {
            await addCartOrderAddress({
              address: fallbackAddress,
              type: 'shipping',
            })
          }
        } else {
          await removeCartOrderAddress(type.value)
        }
      }
    }

    function onAddAddress() {
      router.push(payment.getAddAddressRoute())
    }

    return {
      addCartOrderAddress,
      removeCartOrderAddress,
      cartOrderAddresses,
      addresses,
      defaultAddress,
      firstAddress,
      addressesWithoutDefault,
      type,
      onSelectAddress,
      onRemoveAddress,
      onAddAddress,
    }
  },
  methods: {},
}
</script>

<template>
  <div class="flex h-full w-full flex-col items-center overflow-hidden">
    <div class="w-full max-w-xl py-5 px-4">
      <base-back class="block self-start leading-5" />
    </div>
    <div class="w-full flex-1 overflow-auto pb-4">
      <div class="mx-auto w-full max-w-xl px-4">
        <div class="mb-4 flex items-center justify-between">
          <h2 class="text-xl">Select Address</h2>
          <button
            class="cursor-pointer font-bold leading-6 text-primary"
            @click="onAddAddress"
          >
            + Add address
          </button>
        </div>
        <div class="space-y-4">
          <template v-if="defaultAddress">
            <h2 class="text-xl">Default</h2>
            <address-item
              :item="defaultAddress"
              :show-menu="true"
              @click="onSelectAddress(defaultAddress)"
              @remove="onRemoveAddress(defaultAddress.id)"
            />
          </template>
          <h2 class="text-xl">Other Addresses</h2>
          <address-item
            v-for="address in addressesWithoutDefault"
            :key="address.id"
            :item="address"
            :show-menu="true"
            @click="onSelectAddress(address)"
            @remove="onRemoveAddress(address.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
