import { nanoid } from 'nanoid'

export default {
  label: 'AustPost',
  value: 'austpost',
  preset: {
    items: [
      {
        id: nanoid(),
        enabled: true,
        label: 'How it works',
        action: {
          type: 'action',
          value: 'show-faq',
        },
        icon: 'menu/question-block',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Help',
        action: {
          type: 'page',
          value: 'help',
        },
        icon: 'menu/i',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'FAQ',
        action: {
          type: 'action',
          value: 'show-faq-simple',
        },
        icon: 'menu/faq',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Privacy Policy',
        action: {
          type: 'action',
          value: 'show-privacy-modal',
        },
        icon: 'menu/shield',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Terms & Conditions',
        action: {
          type: 'action',
          value: 'show-terms-modal',
        },
        icon: 'menu/pen-n-paper',
      },
      {
        id: nanoid(),
        enabled: true,
        divider: true,
        label: 'Divider',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Logout',
        action: {
          type: 'action',
          value: 'logout',
        },
        'mobile-action': {
          type: 'action',
          value: 'logout',
        },
        icon: 'menu/logout',
      },
    ],
  },
}
