<script>
import BaseImage from '/~/components/base/image/base-image.vue'
import { computed } from 'vue'
import cdn from '/~/utils/cdn'

export default {
  name: 'retailer-logo',
  components: {
    BaseImage,
  },
  props: {
    retailer: {
      type: Object,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    defaultSrc: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const retailerImage = computed(
      () => props.retailer?.attributes?.images?.opt_logo
    )
    const retailerAlt = computed(() => props.retailer?.attributes?.name)

    const imageSrc = computed(() =>
      cdn(props.image || retailerImage.value)
        .resize('400x')
        .url()
    )
    const defaultImageSrc = computed(() =>
      cdn(props.defaultSrc).resize('400x').url()
    )

    return {
      imageSrc,
      retailerAlt,
      defaultImageSrc,
    }
  },
  computed: {},
}
</script>

<template>
  <div class="mx-auto h-auto max-h-80 w-full max-w-64 sm:max-w-[344px]">
    <base-image
      :src="imageSrc"
      :default-src="defaultImageSrc"
      :alt="alt || retailerAlt"
    />
  </div>
</template>
