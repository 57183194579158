<script>
import { computed } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { pluralize } from '/~/utils/format/string'
import { formatNumber } from '/~/utils/format/numeric'
import RecognitionTileBox from '/~rec/components/recognition/recognition-tile-box.vue'

export default {
  name: 'rec-awards-tile',
  components: {
    BaseIcon,
    RecognitionTileBox,
  },
  props: {
    award: {
      type: Object,
      required: true,
    },
    finished: {
      type: Boolean,
      required: false,
    },
    currency: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'gold',
    },
  },
  setup(props) {
    const nominal = computed(() =>
      props.finished ? props.award.prize : props.award.nominal
    )

    return {
      pluralize,
      formatNumber,
      nominal,
    }
  },
  computed: {
    getRank() {
      const remainder = this.award.nomineeRank % 10

      switch (remainder) {
        case 1:
          return `${this.award.nomineeRank}st`
        case 2:
          return `${this.award.nomineeRank}nd`
        case 3:
          return `${this.award.nomineeRank}rd`
        default:
          return `${this.award.nomineeRank}th`
      }
    },
  },
}
</script>

<template>
  <recognition-tile-box
    :nominal="nominal || '0'"
    title="Review this award"
    color="gold"
    :image="award.image"
    alt-text="award"
    class="flex h-full flex-col"
    v-on="$listeners"
  >
    <template v-if="award.nomineeRank" #rank>
      <div
        class="flex min-w-14 max-w-[72px] items-center justify-center rounded-full text-white"
        :class="{
          'bg-emerald-700': award.nomineeRank <= 3,
          'bg-blue-700': award.nomineeRank > 3,
        }"
      >
        <base-icon svg="symbion/award" size="xxs" class="mr-[5px] text-white" />
        <div class="pb-0.5 text-sm font-bold">
          {{ getRank }}
        </div>
      </div>
    </template>
    <div
      class="flex grow flex-col justify-between truncate bg-white p-[15px] pt-2.5 text-eonx-neutral-800"
    >
      <h3 v-if="award.title" class="w-full shrink grow truncate text-base">
        {{ award.title }}
      </h3>
      <div class="mt-auto">
        <div
          class="mt-[5px] flex items-start font-bold text-eonx-neutral-600 md:font-normal"
        >
          <template v-if="award.remaining">
            <div class="mr-2.5 shrink-0 p-px pb-0 pl-0 pr-0">
              <base-icon svg="rec/time" :size="14" class="shrink-0" />
            </div>
            <span class="truncate text-sm font-semibold leading-4">
              {{ finished ? 'Finished' : award.remaining }}
            </span>
          </template>
        </div>
        <div
          class="mt-[5px] flex items-center font-semibold text-eonx-neutral-600"
        >
          <div class="mr-2.5 shrink-0">
            <base-icon svg="rec/users" :size="16" class="shrink-0" />
          </div>
          <span class="truncate text-sm leading-4">
            {{ award.totalNominations || 0 }}
            {{ pluralize(award.totalNominations, 'Nominee') }}
          </span>
        </div>
      </div>
    </div>
  </recognition-tile-box>
</template>
