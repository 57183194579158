<script>
import DeletePayeeMdl from './delete-payee.mdl.vue'

export default {
  name: 'delete-payee-bottom-sheet',
  extends: DeletePayeeMdl,
}
</script>

<template>
  <div class="flex flex-col items-center">
    <div
      class="relative flex h-[120px] w-full flex-col items-center border-b border-neutral-200 pt-5 text-center text-xl font-bold"
    >
      <base-icon svg="payee/error" class="h-[35px] w-[35px]" />
      <div class="absolute top-6 right-5">
        <base-button
          icon="heroicons/outline/x-mark"
          :size="32"
          @click="onCancel()"
        />
      </div>
      <div class="mt-5 text-center text-xl font-bold">Delete Payee</div>
    </div>

    <div class="text-md m-5 text-center text-eonx-neutral-600">
      Are you sure you wish to delete this payee?
      <br />
      All pending payments linked to this payee will be automatically cancelled.
      This action cannot be undone.
    </div>

    <div
      class="flex w-full items-center justify-center border-t border-neutral-200 py-5 px-[30px]"
    >
      <base-button
        class="mx-2.5 w-1/2"
        look="outlined-color"
        :disabled="processing"
        @click="onCancel"
      >
        Cancel
      </base-button>
      <base-button
        class="mx-2.5 w-1/2"
        :disabled="processing"
        :loading="processing"
        @click="onDelete"
      >
        Confirm
      </base-button>
    </div>
  </div>
</template>
