<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'retailer-empty',
  components: {
    BaseButton,
    BaseImage,
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<template>
  <div class="flex flex-col items-center">
    <base-image
      src="giftcards-illustration.svg"
      class="my-[30px] max-w-lg"
      :rounded="false"
    />
    <div class="mb-[30px] text-2xl">Retailer Not Found</div>
    <div class="mb-[30px]">
      <base-button @click="back">Return to previous page</base-button>
    </div>
  </div>
</template>
