<script>
import MarketplaceMenuV2 from './marketplace-menu.v2.vue'
import MarketplaceMenuV3 from './marketplace-menu.v3.vue'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'layout-main-marketplace-menu',
  functional: true,
  render(h, ctx) {
    const { isBillPaymentsTemplate } = useProvider()

    let menu

    if (isBillPaymentsTemplate.value) {
      menu = MarketplaceMenuV3
    } else {
      menu = MarketplaceMenuV2
    }

    return h(
      menu,
      {
        ...ctx.data,
        on: ctx.listeners,
      },
      ctx.children
    )
  },
}
</script>
