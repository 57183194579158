<script>
import DefaultGiftcardsLocation from '/~/extensions/gift-cards-cinema/templates/default/views/giftcards-location.vue'
import MajorSummary from '../components/major-summary.vue'

export default {
  name: 'gift-cards-cinema-location-major',
  components: {
    MajorSummary,
  },
  extends: DefaultGiftcardsLocation,
}
</script>
