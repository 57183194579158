<script>
import { computed, ref, watch } from 'vue'
import { roundFigure } from '/~/utils/format/numeric'
import { cardCode } from '/~/utils/cards'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'

export default {
  name: 'payments-split-form-card',
  components: {
    BaseIcon,
    BaseCurrencyInput,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    card: {
      type: Object,
      required: true,
    },
    amountToPay: {
      type: Number,
      default: 0,
    },
  },
  emits: ['focus'],
  setup(props, { emit }) {
    const currencyInputRef = ref(null)
    const calculatedAmount = computed({
      get: () => props.value,
      set: (value) =>
        emit('input', Math.min(maxAmount.value, roundFigure(value))),
    })
    const maxAmount = computed(() =>
      roundFigure(props.amountToPay + calculatedAmount.value)
    )

    const code = computed(() => cardCode(props.card))

    watch(calculatedAmount, (calculatedAmount) => {
      if (calculatedAmount >= maxAmount.value) {
        currencyInputRef.value?.blur()
      }
    })

    return {
      currencyInputRef,
      code,
      maxAmount,
      calculatedAmount,
    }
  },
}
</script>

<template>
  <div class="mt-10 w-full rounded-lg bg-gray-50 p-5">
    <div class="justify-between sm:flex">
      <div class="flex">
        <base-icon
          class="mr-[15px] text-amber-400"
          :svg="`cards/${code}`"
          size="xl"
        />
        <div>
          <div class="-mb-[5px] font-bold">
            {{ card.name }} {{ card.brand }}
          </div>
          <div class="-mt-[5px] text-sm text-eonx-neutral-600">
            **** **** **** {{ card.number.slice(-4) }}
          </div>
        </div>
      </div>
      <div class="mt-2.5 flex pt-[5px] text-eonx-neutral-600 sm:mt-0">
        <div
          class="flex h-[35px] w-[120px] items-center rounded-md border border-gray-400 bg-white pl-2.5 pr-[5px] focus-within:border-primary"
        >
          <base-currency-input
            ref="currencyInputRef"
            v-model="calculatedAmount"
            :value-range="{ max: maxAmount }"
            @focus="$emit('focus')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
