<script setup lang="ts">
import { computed } from 'vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import { useCms } from '/~/composables/cms'

const { connect } = useCms()

const connectItems = computed(() => connect.value.items)
</script>

<template>
  <div>
    <h3 class="text-center sm:mb-5">
      {{ connect.title }}
    </h3>
    <div class="py-10 px-6">
      <div class="flex flex-wrap items-start justify-center gap-7">
        <base-action
          v-for="(item, index) in connectItems"
          :key="index"
          v-bind="item.action"
          class="w-full max-w-32 shrink-0"
          origin="banner"
          appearance="link"
        >
          <base-image
            :src="item.image.desktop"
            :alt="item.image.alt"
            :width="128"
            :height="128"
            :ratio="1"
          />
        </base-action>
      </div>
    </div>
  </div>
</template>
