<script>
import modal from '/~/core/mdl'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'qr-code-manually',
  components: {
    BaseInput,
    BaseButton,
    BaseIcon,
    BaseMdl,
  },
  props: {
    processQR: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      code: '',
    }
  },
  methods: {
    async onConfirm() {
      this.processing = true

      const [error] = await this.processQR(this.code)

      if (!error) {
        this.onClose()
      }

      this.processing = false
    },
    onClose() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl width="xse">
    <div class="mb-6 text-center">
      <div class="m-auto h-32 w-32">
        <base-icon svg="qr/code-sqr" size="auto" />
      </div>
    </div>
    <h1 class="text-center text-xl">Enter QR code manually</h1>
    <base-input
      v-model="code"
      class="mb-0 pt-5 pb-2.5"
      :mask="{ mask: '********' }"
      :disabled="processing"
      :input-class="['text-center']"
      placeholder="xxxxxxxx"
    />
    <base-button
      class="mt-[30px] w-full"
      :disabled="code.length === 0"
      :loading="processing"
      @click="onConfirm"
    >
      Confirm
    </base-button>
  </base-mdl>
</template>
