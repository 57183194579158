<script setup lang="ts">
import Vue, { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import ThirdPartyUserAdd from '/~/components/third-party-user/third-party-user-add.pure.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useCms } from '/~/composables/cms'
import {
  useThirdPartyUsers,
  type ThirdPartyUserNew,
  type ThirdPartyUser,
} from '/~/composables/third-party-users'

const router = useRouter()
const route = useRoute()
const { terms } = useCms()
const {
  isLoading,
  inviteThirdPartyUser,
  fetchThirdPartyUsers,
  updateThirdPartyUser,
} = useThirdPartyUsers()

const thirdPartyUserAddPure = ref<InstanceType<typeof ThirdPartyUserAdd>>()
const user = ref<ThirdPartyUser>(route.params.user as unknown as ThirdPartyUser)

watch(
  () => route.params,
  (newParams) => {
    if (newParams?.user) {
      user.value = newParams.user as unknown as ThirdPartyUser
    }
  },
  { deep: true }
)

const onTerms = () => {
  bottomSheet.show('confirm', {
    to: 'menu-modal',
    style: {
      centered: false,
    },
    props: {
      title: 'Terms & Conditions',
      description: terms,
      textMarkup: true,
      showConfirm: false,
      showCancel: false,
      stickyHead: true,
      onConfirm: () => {
        thirdPartyUserAddPure.value?.setTac(true)
      },
    },
  })
}

const handleOnBack = () => {
  router.back()
}

const { backendErrors, processBackendErrors } = useBackendValidation()

const handleInvite = async (payload: ThirdPartyUserNew) => {
  try {
    await inviteThirdPartyUser(payload)
    fetchThirdPartyUsers()
    await router.back()
    Vue.notify({
      type: 'success',
      text: 'Authorised User successfully added.',
    })
  } catch (error: any) {
    processBackendErrors({ errors: error })
    console.error(error)
    Vue.notify({
      text: 'Failed to add Authorised User. Please review your details.',
      type: 'error',
      duration: 5000,
    })
  }
}

const handleUpdate = async (payload: Partial<ThirdPartyUserNew>) => {
  try {
    await updateThirdPartyUser(user.value.externalId, {
      ...payload,
      ...{
        status: user.value.status,
      },
    })
    await router.back()
    fetchThirdPartyUsers()
    Vue.notify({
      type: 'success',
      text: 'Authorised User successfully updated.',
    })
  } catch (error: any) {
    processBackendErrors({ errors: error })
    console.error(error)
    Vue.notify({
      text: 'Failed to edit Authorised User. Please review your details.',
      type: 'error',
      duration: 5000,
    })
  }
}
</script>

<template>
  <third-party-user-add
    ref="thirdPartyUserAddPure"
    :on-invite="handleInvite"
    :on-update="handleUpdate"
    :on-back="handleOnBack"
    :backend-errors="backendErrors"
    :on-terms="onTerms"
    :submitting="isLoading"
    :user="user"
  />
</template>
