<script>
import get from 'lodash-es/get'
import EwProductType from '/~/components/ewallet/product/ew-product-type.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import DetailOrderItem from './drawer-activity-detail-order-item.vue'
import { formatDollar } from '/~/utils/format/money'

export default {
  name: 'drawer-activity-detail-order-list',
  components: {
    EwProductType,
    BasePlaceholder,
    DetailOrderItem,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      formatDollar,
    }
  },
  computed: {
    shippingFee() {
      return get(
        (this.content.fees?.items ?? []).find(
          (fee) => fee.label === 'Shipping Fee'
        ),
        'amount',
        0
      )
    },
  },
  methods: {
    card(item) {
      const type = this.getType(item)

      if (type === 'estore') {
        return item.name
      } else if (type === 'voucher') {
        return 'eVoucher'
      }
      return `${this.productType(item) ? 'Physical ' : 'e'}Gift Card`
    },
    productType(item) {
      return get(item, 'physical')
    },
    getType(item) {
      return get(item, 'type')
    },
  },
}
</script>

<template>
  <section>
    <template v-if="loading">
      <base-placeholder :rows="4" :class="$style.placeholder" type="text" />
    </template>
    <ul v-else ref="cardList" :class="$style.cardList" data-cy="card-list-body">
      <li
        v-for="item in content.items"
        ref="items"
        :key="item.number"
        :class="$style.cardItem"
      >
        <template v-if="content.isCinema">
          <div>
            <div :class="$style.ticketTitle">
              <img
                src="https://i.ewalletcdn.com/ce821a14-70d4-43b3-94e3-48d27c9945f4/"
                :class="$style.image"
              />
              {{ content.total_selected_tickets }} x Cinema Ticket
            </div>
            <ew-product-type
              class="text-eonx-neutral-600"
              :class="$style.productType"
              :physical="false"
              mobile-short
            />
          </div>
          <div :class="$style.cardPrice">
            {{ formatDollar(content.subtotal) }}
          </div>
        </template>
        <template v-else>
          <detail-order-item :item="item" />
        </template>
      </li>
      <li
        v-for="item in content.e_store_items"
        ref="items"
        :key="item.number"
        :class="$style.cardItem"
      >
        <div>{{ item.quantity }} x {{ item.name }}</div>
        <div>{{ formatDollar(item.subtotal) }}</div>
      </li>
      <li :class="[$style.cardItem, $style.cardItemTotal]">
        <div
          v-if="content.display_creditcard_fee > 0"
          :class="$style.cardTotalRow"
        >
          <div :class="$style.cartTotalCell">
            Credit Card Fee {{ content.creditcard_fee_rate }}%
          </div>
          <div :class="$style.cartTotalCell">
            {{ formatDollar(content.display_creditcard_fee) }}
          </div>
        </div>
        <div
          v-if="content.display_processing_fee > 0"
          :class="$style.cardTotalRow"
        >
          <div :class="$style.cartTotalCell">Processing Fee</div>
          <div :class="$style.cartTotalCell">
            {{ formatDollar(content.display_processing_fee) }}
          </div>
        </div>
        <div v-if="shippingFee" :class="$style.cardTotalRow">
          <div :class="$style.cartTotalCell">Delivery Fee</div>
          <div :class="$style.cartTotalCell">
            {{ formatDollar(shippingFee) }}
          </div>
        </div>
        <div class="flex justify-end">
          <b>Total</b>
          <b class="ml-6">
            {{ formatDollar(content.total) }}
          </b>
        </div>
      </li>
    </ul>
  </section>
</template>

<style lang="scss" module>
.cardList {
  margin: 0;
  list-style: none;
}

.cardItem {
  border-width: 1px 0 0;

  @apply border-solid border-divider py-2.5 px-0 text-xs leading-6;

  &Cashback {
    display: block;
  }

  &Title {
    display: none;
    border-top: none;

    @apply hidden border-b-2;
  }

  &:last-child {
    border-bottom-width: 1px;
  }
}

.image {
  @apply mr-2.5 w-[60px] rounded;
}

.ticketTitle {
  display: flex;
  align-items: center;
}

.placeholder {
  margin-bottom: 0;
}
</style>
