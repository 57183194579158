<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'question-select-multiple',
  components: {
    BaseIcon,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    local: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit('input', value)
      },
    },
  },
  methods: {
    toggleValue(val) {
      if (this.local.includes(val)) {
        this.local = this.local.filter((item) => item !== val)
      } else {
        this.local = [...this.value, val]
      }
    },
  },
}
</script>

<template>
  <div class="flex flex-wrap">
    <div
      v-for="(action, i) in content.actions"
      :key="i"
      class="relative mx-[5px] mb-2.5 flex cursor-pointer items-center justify-center self-start overflow-hidden rounded px-[15px] py-2.5"
      @click="toggleValue(action.value)"
    >
      <div
        class="absolute inset-[5px] z-0 h-full w-full bg-primary"
        :class="[!local.includes(action.value) && 'opacity-25']"
      />
      <div
        class="z-1 mr-2.5 flex h-5 w-5 items-center justify-center rounded border-2 border-primary"
        :class="[local.includes(action.value) && 'border-white bg-white']"
      >
        <base-icon
          v-if="local.includes(action.value)"
          :size="10"
          class="text-primary"
          svg="plain/check"
        />
      </div>
      <span
        class="z-1 font-bold"
        :class="[local.includes(action.value) ? 'text-white' : 'text-primary']"
      >
        {{ action.name }}
      </span>
    </div>
  </div>
</template>
