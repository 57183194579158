<script>
import { reactive } from 'vue'
import StaticProcessor from '/~/core/processors/static'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { useEditablePage } from '/~/composables/cms'
import CatalogTile from './components/presales-tile.vue'

export default {
  name: 'pre-sales-catalog',
  components: {
    CatalogTile,
    EwCatalogProcessor,
  },
  setup() {
    const processor = reactive(new StaticProcessor())
    const { page } = useEditablePage()

    processor.setData([
      {
        id: 1,
        image: 'uploadcare://d210d223-15a8-4c4c-8679-efadf8a94a50',
        description: 'THE ICONIC Exclusive Edit Pre-sale',
      },
      {
        id: 2,
        image: 'uploadcare://12254b3c-d611-4998-aee0-b469a02082d9',
        description: 'Big Bash Cricket for $15 - Get more bang for your buck',
      },
      {
        id: 3,
        image: 'uploadcare://5a486957-ce7d-4923-870e-39b965846103',
        description: 'Festival X Pre-Sale',
      },
    ])

    return {
      page,
      processor,
    }
  },
}
</script>

<template>
  <div class="mx-auto w-full max-w-7xl">
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />

    <div id="presale-packages" class="p-2.5 sm:p-5">
      <h1 class="mb-[15px]">Pre-sales</h1>
      <ew-catalog-processor
        :processor="processor"
        :columns="{
          xxs: 2,
          sm: 3,
          lg: 4,
        }"
      >
        <template #empty>
          <p>No Result found</p>
        </template>

        <template #tile="{ tile }">
          <catalog-tile ref="tile" :tile="tile" type="pre-sale" />
        </template>
      </ew-catalog-processor>
    </div>
  </div>
</template>
