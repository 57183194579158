<script>
import HealthDatepicker from '../../../health-datepicker.vue'

export default {
  name: 'question-datepicker',
  components: {
    HealthDatepicker,
  },
  props: {
    value: {
      type: Date,
      default: null,
    },
  },
  computed: {
    local: {
      get() {
        return this.value
      },
      set(date) {
        return this.$emit('input', date)
      },
    },
  },
}
</script>

<template>
  <div class="flex flex-col items-center">
    <health-datepicker v-model="local" />
  </div>
</template>
