<script>
import { ref } from 'vue'
import ListDefault from '/~/components/payments/list/default.vue'
import ListSplit from '/~/components/payments/list/split.vue'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'payments-methods-list',
  functional: true,
  render(h, ctx) {
    const { flowType } = ctx.props
    const { splitPaymentOrderAllowed } = useProvider()

    ctx.props.splitEnabled = ref(splitPaymentOrderAllowed(flowType))

    return h(
      splitPaymentOrderAllowed(flowType) ? ListSplit : ListDefault,
      ctx.data,
      ctx.props
    )
  },
}
</script>
