const VERIFY_STATUS = {
  failed: ['completedFlagged', 'expired', 'cancelled'],
  completed: ['completedPass'],
}

export interface NewPhotoIdTransaction {
  transactionId: string
  idvUrl: string
}

export interface PhotoIdTransactionRaw extends NewPhotoIdTransaction {
  status: string
}

export class PhotoIdTransaction {
  raw: PhotoIdTransactionRaw
  isDeleting: boolean
  constructor(raw: PhotoIdTransactionRaw) {
    this.raw = raw || {}
    this.isDeleting = false
  }

  get id() {
    return this.raw.transactionId
  }

  get idvUrl() {
    return this.raw.idvUrl || ''
  }

  get status() {
    return this.raw.status || ''
  }

  get isCompleted() {
    return VERIFY_STATUS.completed.includes(this.raw.status)
  }

  get isPending() {
    return !this.isCompleted && !this.isFailed
  }

  get isFailed() {
    return VERIFY_STATUS.failed.includes(this.raw.status)
  }
}
