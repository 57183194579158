<script>
import modal from '/~/core/mdl'
import DetailsBlock from '/~rec/components/details/details-block.vue'
import EventStatusControl from '/~rec/components/event/event-status-control.vue'
import { useRecProfile } from '/~rec/composables/profile'
import { formatDate } from '/~/utils/format/date'

export default {
  name: 'event-info',
  components: {
    DetailsBlock,
    EventStatusControl,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    usersProcessing: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return { isMeAdmin, isMeSuperAdmin }
  },
  computed: {
    date() {
      if (this.event) {
        const { startsAt, endsAt } = this.event

        if (startsAt.isSame(endsAt, 'day')) {
          return `${formatDate('weekdaylong', startsAt)} ${formatDate(
            'daymonthyear',
            startsAt
          )},
            ${formatDate('time', startsAt)} - ${formatDate('time', endsAt)}`
        }

        return `${formatDate('weekdaylong', startsAt)} ${formatDate(
          'daymonthyear',
          startsAt
        )},
            ${formatDate('time', startsAt)} – <br/> ${formatDate(
          'weekdaylong',
          endsAt
        )} ${formatDate('daymonthyear', endsAt)},
            ${formatDate('time', endsAt)}`
      }

      return ''
    },
    allowAction() {
      return this.event?.isMeOwner || this.isMeAdmin || this.isMeSuperAdmin
    },
    actions() {
      return [
        {
          icon: 'rec/round/edit',
          title: 'Edit Event',
          text: 'Edit details for this Event',
          action: () => {
            this.$router.push({
              name: 'rec-event-edit',
              params: { initialEvent: this.event },
            })
          },
          hidden: !this.allowAction,
        },
        {
          icon: 'rec/round/delete',
          title: 'Delete Event',
          text: 'Delete this event',
          action: async () => {
            modal.show('rec-event-delete', {
              props: {
                event: this.event,
              },
              on: {
                success: async () => {
                  this.$router.replace({ name: 'rec-events' })
                  modal.hideAll()
                },
              },
            })
          },
          hidden: !this.allowAction,
        },
      ].filter((i) => !i.hidden)
    },
  },
}
</script>

<template>
  <details-block
    :image="event.image"
    :title="event.name"
    :date="date"
    :admin="event.creator.fullName"
    :users="{
      list: event.members.hits,
      processing: usersProcessing,
    }"
    :location="event.location"
    :description="event.description"
    :actions="actions"
    @close="$emit('close')"
    @open-members="$emit('open-members')"
  >
    <event-status-control v-if="!event.isPast" :event="event" class="mb-10" />
  </details-block>
</template>
