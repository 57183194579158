<script>
import PrivacyPolicy from '/~/views/privacy-policy/privacy-policy.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { useUI } from '/~/composables/ui'

export default {
  name: 'drawer-privacy-policy',
  components: {
    PrivacyPolicy,
    BaseAsidePage,
  },
  setup() {
    const { hideEwalletMenu } = useUI()

    function onBack() {
      hideEwalletMenu()
    }

    return { onBack }
  },
}
</script>

<template>
  <base-aside-page
    title="Privacy Policy"
    no-padding
    class="bg-white"
    :back="onBack"
  >
    <privacy-policy class="px-5 pb-5" />
  </base-aside-page>
</template>
