import api from '/~rec/core/api'
import { RecroomPost } from './post'
import { RecroomUser } from './user'
import { useRecProfile } from '/~rec/composables/profile'
import { createDate } from '/~/utils/format/date'

const { myRecId, profile } = useRecProfile()

export class RecroomPostPoll extends RecroomPost {
  get isPrivate() {
    return this.raw.private
  }

  get isExpired() {
    return this.expiresAt && new Date(this.expiresAt) < new Date()
  }

  get isVotedByMe() {
    return !!this.myVote
  }

  get myVote() {
    return this.options.find((option) => option.isVotedByMe)
  }

  get totalAnswers() {
    return this.options.reduce((total, option) => {
      return total + option.countOfAnswers
    }, 0)
  }

  get expiresAt() {
    return this.raw.expires_at && createDate(this.raw.expires_at)
  }

  updateOptions() {
    this.options = this.raw.options.map((option) => {
      return new RecroomPostPollOption(option)
    })
  }

  init(rawData) {
    super.init(rawData)
    this.updateOptions()
  }

  async vote(optionId) {
    const option = this.options.find((o) => o.id === optionId)
    const response = await api.put(`/posts/${this.id}/options/${optionId}`)

    if (option) {
      option.applyMyAnswer(response.data)
    } else {
      console.warn('no option')
    }
  }
}

export class RecroomPostPollOption {
  constructor(raw) {
    this.raw = raw
    this.answers = (raw.answers || []).map((answer) => {
      return {
        ...answer,
        author: new RecroomUser(answer.author),
      }
    })
  }

  get id() {
    return this.raw.id
  }

  get content() {
    return this.raw.content
  }

  get countOfAnswers() {
    return this.answers.length
  }

  get isVotedByMe() {
    return !!this.answers.find((answer) => {
      return answer.author_id === myRecId.value
    })
  }

  applyMyAnswer(answer) {
    this.answers.push({
      ...answer,
      author: profile.me,
    })
  }
}
