<script>
import bottomSheet from '/~/core/bottom-sheet'
import { formatPhone } from '/~/utils/format/string'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useAddresses } from '/~/composables/addresses'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import DrawerAddress from './components/drawer-address.vue'

export default {
  name: 'drawer-profile-form',
  components: {
    BaseAsidePage,
    DrawerAddress,
    BaseIcon,
    BaseLoader,
  },
  props: {
    to: {
      type: String,
      default: 'modals-portal',
    },
    showOnlyRequiredFields: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { addresses, getAddresses } = useAddresses()
    const { isUserAddressEnabled, isProfileFormEditable } = useProvider()
    const { user } = useUser()

    return {
      user,
      isProfileFormEditable,
      isUserAddressEnabled,
      bottomSheet,
      getAddresses,
      addresses,
    }
  },
  computed: {
    firstName() {
      return this.user.firstName
    },
    lastName() {
      return this.user.lastName
    },
    email() {
      return this.user.hasTemporaryEmail ? '' : this.user.email
    },
    mobile() {
      return this.formatPhone(this.user.mobile)
    },
    isAddressesLoading() {
      return this.addresses === null
    },
    isAddressesEmpty() {
      return (this.addresses?.length ?? 0) === 0
    },
    sortedAddresses() {
      const items = [...this.addresses]

      return items.sort((item) => (item.default ? -1 : 1))
    },
  },
  created() {
    this.getAddresses()
  },
  methods: {
    onAddAddress() {
      this.$router.push({ hash: '#profile-add-address' })
    },
    editProfile() {
      this.$router.push({ hash: '#profile-edit-profile' })
    },
    formatPhone,
  },
}
</script>

<template>
  <base-aside-page title="Personal details">
    <div class="relative flex h-full flex-col" @submit.prevent="onSubmitForm">
      <div class="grow pb-5">
        <div class="mb-5 mt-2.5 flex items-center justify-between">
          <span class="font-bold text-eonx-neutral-800">Profile</span>
          <button
            v-if="isProfileFormEditable"
            type="button"
            class="flex items-center gap-x-2.5 font-bold text-primary"
            @click="editProfile"
          >
            <span>Edit</span>
            <base-icon
              svg="v2/box-icons/bx-edit-alt"
              :size="20"
              class="mr-[5px]"
            />
          </button>
        </div>

        <span>First name</span>
        <div
          class="mb-5 mt-0.5 flex h-10 items-center rounded border bg-eonx-neutral-50 px-[15px]"
        >
          {{ firstName }}
        </div>
        <span>Last name</span>
        <div
          class="mb-5 mt-0.5 flex h-10 items-center rounded border bg-eonx-neutral-50 px-[15px]"
        >
          {{ lastName }}
        </div>
        <span>Email</span>
        <div
          class="mb-5 mt-0.5 flex h-10 items-center rounded border bg-eonx-neutral-50 px-[15px]"
        >
          {{ email }}
        </div>
        <span>Mobile</span>
        <div
          class="mb-5 mt-0.5 flex h-10 items-center rounded border bg-eonx-neutral-50 px-[15px]"
        >
          {{ mobile }}
        </div>

        <template v-if="isUserAddressEnabled">
          <div class="mt-6 mb-5 flex items-center justify-between">
            <span class="font-bold text-eonx-neutral-800">Address</span>
            <button
              type="button"
              class="font-bold text-primary"
              @click="onAddAddress"
            >
              + Add address
            </button>
          </div>
          <base-loader v-if="isAddressesLoading" fullwidth class="mt-2.5" />
          <div v-else-if="isAddressesEmpty" class="flex justify-center">
            <div class="font-bold">No address stored</div>
          </div>
          <div v-else class="space-y-6">
            <div v-for="address of sortedAddresses" :key="address.id">
              <drawer-address :address="address" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </base-aside-page>
</template>
