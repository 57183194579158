import { EntityProcessor } from './entity-processor'
import transformPostClass from '/~rec/utils/transform-post-class'

export class PostsProcessor extends EntityProcessor {
  constructor(params) {
    super({
      ...params,
      mapping: (data) => {
        const record = params.mapping ? params.mapping(data) : data

        return transformPostClass(record)
      },
    })
  }
}
