import { computed } from 'vue'
import { useExtensions } from '/~/composables/extensions'

const { getConfigByName } = useExtensions()

const otpConfig = computed<Otp.ModuleConfig | null>(
  () => (getConfigByName('otp') as unknown as Otp.ModuleConfig) ?? null
)

const isOtpEnabled = computed(() => otpConfig.value?.enabled ?? false)
const enrolmentOtpConfig = computed(
  () => otpConfig.value?.triggerPoints.enrolment ?? null
)
const isEnrolmentOtpEnabled = computed(
  () => enrolmentOtpConfig.value?.enabled ?? false
)
const enrolmentOtpChannels = computed(
  () => enrolmentOtpConfig.value?.deliveryChannels ?? []
)
const isEnrolmentMobileChannelEnabled = computed(() =>
  Boolean(enrolmentOtpChannels.value.find(({ channel }) => channel === 'email'))
)
const isEnrolmentEmailChannelEnabled = computed(() =>
  Boolean(
    enrolmentOtpChannels.value.find(({ channel }) => channel === 'mobile')
  )
)

export function useOtp() {
  return {
    isOtpEnabled,
    isEnrolmentOtpEnabled,
    isEnrolmentMobileChannelEnabled,
    isEnrolmentEmailChannelEnabled,
  }
}
