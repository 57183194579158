import { RouteConfig } from 'vue-router'
import { unverifiedVerificationsGuard } from '/~/extensions/otp/composables'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { useEwalletSdk } from '/~/composables/ewallet-sdk'
import router from '/~/router'

const { sendCloseEvent } = useEwalletSdk()

router.beforeEach((to, from, next) => {
  if (to.params?.isNull) {
    sendCloseEvent()

    return
  }

  if (
    to.params.isBack ||
    from.params.nativeBack ||
    from.meta?.back?.name === to.name
  ) {
    return next()
  }

  if (to.params.replace) {
    to.params.replacedFrom = {
      ...from,
      params: {
        ...from.params,
        isBack: true,
      },
    }
    to.meta.back = { ...from.meta.back }
  } else {
    to.meta.back = {
      name: from.name ?? 'home',
      meta: from.meta,
      query: from.query,
      params: {
        ...from.params,
        isNull: !from.name,
        isBack: true,
      },
    }
  }

  return next()
})

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import('/~/components/ewallet-menu/views/home/home.vue'),
    props: true,
    meta: {
      page: 'home',
      title: 'Home',
      epurchases: { name: 'e-purchases' },
      itemRoute: '/e-purchases/e-purchase',
    },
    beforeEnter(_, __, next) {
      const { cmsRedirects } = useCms()
      const { homeRedirect = {} } = cmsRedirects.value
      const { enabled = false, value: name, params = {} } = homeRedirect

      if (enabled) {
        next({
          name,
          params,
        })
      } else {
        next()
      }
    },
  },
  {
    path: '/payment-methods',
    meta: {
      title: 'Payment Methods',
    },
    component: () =>
      import('/~/templates/cashrewards/layouts/layouts-root.vue'),
    children: [
      {
        path: '',
        name: 'payment-methods',
        component: () =>
          import(
            '/~/components/ewallet-menu/views/payment-methods/payment-methods.vue'
          ),
        props: {
          verifyModalTitle: 'Verify payment method',
        },
        meta: {
          title: 'Payment Methods',
          back: { name: 'home' },
          add: { name: 'payment-methods-add' },
        },
      },
      {
        path: 'add',
        name: 'payment-methods-add',
        component: () =>
          import(
            '/~/components/ewallet-menu/views/payment-methods/payment-methods-add.vue'
          ),
        meta: {
          title: 'Add a payment method',
          bottomSheetTerms: true,
        },
      },
    ],
  },
  {
    path: '/addresses',
    meta: {
      title: 'Addresses',
    },
    component: () =>
      import('/~/templates/cashrewards/layouts/layouts-root.vue'),
    children: [
      {
        path: '',
        name: 'addresses',
        component: () =>
          import('/~/templates/cashrewards/views/addresses/addresses.vue'),
        meta: {
          title: 'Addresses',
          back: { name: 'home' },
          itemRoute: '/addresses',
        },
      },
      {
        path: 'add',
        name: 'addresses-add',
        props: true,
        component: () =>
          import(
            '/~/components/ewallet-menu/views/profile-settings/profile-add-address.vue'
          ),
      },
      {
        path: ':id',
        name: 'addresses-edit',
        props: true,
        component: () =>
          import(
            '/~/components/ewallet-menu/views/profile-settings/profile-add-address.vue'
          ),
      },
    ],
  },
  {
    path: '/e-purchases',
    meta: {
      title: 'My ePurchases',
    },
    component: () =>
      import('/~/templates/cashrewards/layouts/layouts-root.vue'),
    children: [
      {
        path: '',
        name: 'e-purchases',
        component: () =>
          import(
            '/~/components/ewallet-menu/views/e-purchases/e-purchases.vue'
          ),
        meta: {
          title: 'My ePurchases',
          back: { name: 'home' },
          itemRoute: 'e-purchase',
        },
      },
      {
        path: 'e-purchase/:id',
        name: 'e-purchase',
        props: true,
        component: () =>
          import('/~/components/ewallet-menu/views/e-purchases/e-purchase.vue'),
        meta: {
          terms: { name: 'e-purchase-terms' },
        },
      },
      {
        path: 'e-purchase/terms',
        name: 'e-purchase-terms',
        props: true,
        component: () =>
          import(
            '/~/components/ewallet-menu/views/e-purchases/e-purchase-terms.vue'
          ),
      },
    ],
  },
  {
    path: '/quick-buy',
    meta: {
      title: 'Checkout',
      quickBuy: true,
    },
    redirect: { name: 'quick-buy-checkout' },
    component: () => import('/~/views/checkout.v2/root.vue'),
    children: [
      {
        path: 'billing/:orderId?',
        name: 'quick-buy-checkout-billing',
        props: true,
        component: () =>
          import(
            '/~/views/checkout.v2/views/checkout/views/checkout-address.vue'
          ),
        meta: {
          title: 'Checkout Billing',
          toolbar: false,
          type: 'billing',
          index: 1,
          flowType: FlowType.quickBuy,
          quickBuy: true,
        },
      },
      {
        path: 'method/:orderId?',
        name: 'quick-buy-checkout-method',
        component: () =>
          import(
            '/~/views/checkout.v2/views/checkout/views/checkout-method.vue'
          ),
        meta: {
          title: 'Checkout Select Payment Methods',
          flowType: FlowType.quickBuy,
          next: { name: 'quick-buy-checkout-summary' },
          addPaymentMethods: { name: 'payment-methods-add' },
          index: 1,
          initQuickBuy: true,
          quickBuy: true,
        },
      },
      {
        path: ':orderId?/summary',
        name: 'quick-buy-checkout-summary',
        component: () =>
          import(
            '/~/templates/cashrewards/views/checkout/views/checkout-summary.mobile.vue'
          ),
        meta: {
          title: 'Summary',
          index: 0,
          flowType: FlowType.quickBuy,
          quickBuy: true,
        },
        props: true,
      },
      {
        path: 'confirmation/:orderId',
        name: 'quick-buy-checkout-confirmation',
        component: () =>
          import(
            '/~/templates/cashrewards/views/checkout/views/checkout-confirmation.mobile.vue'
          ),
        meta: {
          title: 'Confirmation',
          back: { name: 'home' },
          index: 1,
          epurchaseRoute: '/e-purchases/e-purchase',
          quickBuy: true,
        },
        props: (route) => {
          return {
            orderId: route.params.orderId,
            flowType: FlowType.quickBuy,
          }
        },
      },
      {
        path: ':orderId?',
        name: 'quick-buy-checkout',
        component: () =>
          import('/~/templates/cashrewards/views/checkout/checkout.mobile.vue'),
        meta: {
          title: 'Checkout',
          toolbar: false,
          flowType: FlowType.quickBuy,
          index: 0,
          quickBuy: true,
        },
        props: true,
      },
    ],
    beforeEnter: unverifiedVerificationsGuard,
  },
]

export default routes
