<script>
import DefaultEwProdctType from './ew-product-type.vue'

export default {
  name: 'ew-product-type-v2',
  extends: DefaultEwProdctType,
}
</script>

<template>
  <div class="flex items-center text-sm leading-tight">
    <template v-if="physical">
      <base-icon
        v-if="hasGift"
        :size="iconSize || 'md'"
        class="mr-2.5"
        :svg="giftIcon"
      />
      <base-icon
        v-else-if="showDelivery"
        :size="iconSize || 'md'"
        :svg="giftCard ? 'auspost' : physicalIcon"
        class="mr-[5px]"
      />
      <slot name="physical">
        {{ title }}
      </slot>
    </template>
    <template v-else>
      <base-icon
        v-if="hasGift"
        :size="iconSize || 'sm'"
        :svg="giftIcon"
        class="mr-[5px]"
      />
      <base-icon
        v-else-if="showDelivery"
        :size="iconSize || 'md'"
        :svg="digitalIcon"
        class="mr-2.5"
      />

      <slot name="digital">
        <span>
          {{ title }}
        </span>
      </slot>
    </template>
  </div>
</template>
