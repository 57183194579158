<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import EditProfileForm from '/~/components/drawer/views/personal-details/views/edit-profile/edit-profile-form.vue'
import { useProvider } from '/~/composables/provider'

defineProps<{ to: 'menu-modal' | 'modals-portal' }>()

const router = useRouter()
const { isProfileFormEditable } = useProvider()

if (!isProfileFormEditable.value) {
  router.push({ hash: '' })
}

function onProfileSaved() {
  router.back()
}
</script>

<template>
  <base-aside-page title="Personal details">
    <edit-profile-form :to="to" mobile @profile-saved="onProfileSaved" />
  </base-aside-page>
</template>
