<script>
import get from 'lodash-es/get'
import isEqual from 'lodash-es/isEqual'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useQrConnect } from '/~/extensions/qr-connect/composables'
import { formatDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button'
import { useUser } from '/~/composables/user'
import QrDatepicker from '../../components/qr-datepicker.vue'
import QrLayout from '../../components/qr-layout.vue'
import QrSelect from '../../components/qr-select.vue'

export default {
  name: 'qr-reserve-workspace',
  components: {
    QrLayout,
    QrSelect,
    QrDatepicker,
    BaseButton,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { user } = useUser()
    const { claims, map, scanQrCode, fetchReservations } = useQrConnect()

    return {
      user,
      claims,
      map,
      scanQrCode,
      fetchReservations,
      ui,
    }
  },
  data() {
    return {
      loading: false,
      reservation: false,
      reserveWorkspace: {
        dates: null,
        building: null,
        level: null,
        asset: null,
      },
    }
  },
  computed: {
    startsAt() {
      return formatDate(
        'daymonthyearnumeric',
        this.reserveWorkspace?.dates?.start || this.data.dates?.start
      )
    },
    endsAt() {
      return formatDate(
        'daymonthyearnumeric',
        this.reserveWorkspace?.dates?.end || this.data.dates?.end
      )
    },
  },
  async created() {
    if (this.data) this.reserveWorkspace = this.data

    if (!this.claims.length) {
      this.loading = true
      await this.fetchReservations()
      this.loading = false
    }

    if (this.showModal) this.showMap()
  },
  methods: {
    setDates(newDates) {
      if (!isEqual(newDates, this.reserveWorkspace.dates)) {
        this.reserveWorkspace = {
          dates: newDates,
        }
        this.routeTo('qr-building-location')
      }
    },
    routeTo(route) {
      this.$router.push({
        name: route,
        params: {
          data: this.reserveWorkspace,
        },
      })
    },
    showMap() {
      modal.show('qr-section-map', {
        props: {
          payload: {
            parent: get(this.data, 'level.id'),
            startsAt: this.startsAt,
            endsAt: this.endsAt,
            map: this.map.id,
          },
          reserveWorkspace: this.reserveWorkspace,
        },
        on: {
          'select-pin': (pin) => {
            this.$set(this.reserveWorkspace, 'asset', pin)
          },
        },
      })
    },
    async reserve() {
      this.reservation = true
      const asset = this.reserveWorkspace.asset

      const code = asset.qrCodes.find(
        (i) => i.scanStrategy.strategy === 'remote_claim'
      )

      const [error, response] = await this.scanQrCode({
        qrCode: code.id,
        userExternalId: this.user.externalId,
        startsAt: this.startsAt,
        endsAt: this.endsAt,
      })

      if (!error) {
        this.reservation = false

        modal.show(
          response.success
            ? 'qr-workspace-reserved'
            : 'qr-workspace-unavailable',
          {
            props: {
              title: response.title,
              message: response.message,
              data: this.reserveWorkspace,
            },
          }
        )
      }
    },
  },
}
</script>

<template>
  <qr-layout class="sm:-mb-20">
    <div class="sm:pb-24">
      <div class="mb-6">
        Reserve your workspace location in advance to secure your arrival
      </div>

      <div class="sm:grid sm:grid-cols-2 sm:gap-y-6 sm:gap-x-[30px]">
        <div class="mb-[15px] sm:mb-0">
          <div class="mb-[5px] text-sm font-bold">Select date range</div>
          <qr-datepicker
            ref="datepicker"
            :value="reserveWorkspace.dates"
            @input="setDates"
          />
        </div>

        <qr-select
          v-model="reserveWorkspace.building"
          title="Building Location"
          icon="qr/building"
          :disabled="!reserveWorkspace.dates"
          @click="reserveWorkspace.dates && routeTo('qr-building-location')"
        />

        <qr-select
          v-model="reserveWorkspace.level"
          title="Building Level"
          icon="qr/level"
          :disabled="!reserveWorkspace.building"
          @click="reserveWorkspace.building && routeTo('qr-building-level')"
        />

        <qr-select
          v-model="reserveWorkspace.asset"
          title="Asset"
          icon="qr/workspace"
          :disabled="!reserveWorkspace.level"
          @click="reserveWorkspace.level && showMap()"
        />
      </div>

      <div class="mt-10 text-center">
        <base-button
          class="w-80"
          :disabled="!reserveWorkspace.asset || reservation"
          @click="reserve"
        >
          Confirm
        </base-button>
      </div>
    </div>
  </qr-layout>
</template>
