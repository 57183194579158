<script>
import BaseHeader from '/~/components/base/header/base-header'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseButton from '/~/components/base/button/base-button'
import { formatDollar } from '/~/utils/format/money'

export default {
  name: 'bank-account-warning',
  components: {
    BaseHeader,
    BaseIcon,
    BaseButton,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
  },
  emits: ['cancel', 'submit'],
  setup(_, { emit }) {
    function onCancel() {
      emit('cancel')
    }

    function onSubmit() {
      emit('submit')
    }

    return {
      formatDollar,
      onCancel,
      onSubmit,
    }
  },
}
</script>

<template>
  <div>
    <base-header
      type="closable"
      class="flex items-center justify-center p-6"
      @close="onCancel"
    >
      <base-icon :size="36" svg="plain/info-circle" class="text-primary" />
    </base-header>
    <div class="space-y-6 p-6">
      <div class="text-center text-2xl font-semibold">
        Paying with bank account
      </div>

      <div class="text-sm">
        By selecting your bank account as the payment method, your order will
        not be fulfilled until the funds have cleared in our system.
        <br />
        <b>This can take up to 3 business days.</b>
      </div>

      <div class="flex space-x-5">
        <base-button class="flex-1" look="outlined-color" @click="onCancel">
          Cancel
        </base-button>
        <base-button class="flex-1" @click="onSubmit">
          Pay {{ formatDollar(total) }}
        </base-button>
      </div>
    </div>
  </div>
</template>
