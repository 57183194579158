<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseSwitch from '/~/components/base/switch/base-switch.vue'

export default {
  name: 'rec-form-type-switch',
  components: {
    BaseIcon,
    BaseSwitch,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <base-switch
    v-bind="$attrs"
    :values="options"
    class="mb-[15px] w-full"
    look="recroom"
    :value="value"
    v-on="$listeners"
  >
    <template #option="{ option }">
      <div
        class="flex w-full flex-col items-center self-start rounded-xl p-[15px]"
      >
        <div class="flex items-center text-base font-bold">
          <base-icon :svg="option.icon" size="md" class="mr-[5px]" />
          <span class="leading-none">
            {{ option.label }}
          </span>
        </div>
        <div class="mt-2.5 text-base font-normal">
          {{ option.description }}
        </div>
      </div>
    </template>
  </base-switch>
</template>
