import { computed } from 'vue'
import cdn from '/~/utils/cdn'
import { ensureNumber } from '/~/utils/format'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import { useCms } from '/~/composables/cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useCommunicationPreferences } from '/~/composables/user'
import { Activity } from './core/activity'

// NOTE: https://github.com/vuejs/core/issues/4294
type ActivityListItemProps = {
  item: Activity
}

// TODO: move to core/activity or use-payment-methods
export type ActivityPaymentMethods = Array<
  | {
      brand: string
      type: string
      typeV3: string
    }
  | string
>

export function useActivityListItem(props: ActivityListItemProps) {
  const { isPointsEnabled, calculatePointsEarnedForPayment } = usePoints()
  const { isEmailCommunicationPreference, getCommunicationPreferenceInfo } =
    useCommunicationPreferences()
  const { providerClientName } = useProvider()
  const { ewalletLabels } = useCms()

  const showPoints = computed(() => {
    return (
      !props.item.isTypeMembership &&
      !props.item.isTypeCampaign &&
      !props.item.isTypeUserCommunication &&
      isPointsEnabled.value
    )
  })

  const icon = computed(() => {
    if (props.item.isSuccessful) {
      return 'heroicons/solid/check-circle'
    } else if (props.item.isStatusGroupPending) {
      return 'heroicons/solid/clock'
    } else if (props.item.isTypeRefund && !props.item.isStatusFailed) {
      return 'heroicons/micro/arrow-uturn-left'
    }

    return 'heroicons/solid/x-circle'
  })

  const iconColor = computed(() => {
    if (props.item.isSuccessful) {
      return 'text-emerald-700'
    } else if (props.item.isStatusGroupPending) {
      return 'text-orange-700'
    } else if (props.item.isTypeRefund && !props.item.isStatusFailed) {
      return 'bg-red-700 text-white p-2.5 m-1 rounded-full'
    }

    return 'text-red-700'
  })

  const logo = computed(() => {
    // TODO: refactor cdn to ts
    return (cdn(props.item.payee?.logo) as any).url()
  })

  const userCommunicationDescription = computed(() => {
    let description

    if (props.item.description?.length > 0) {
      try {
        description = JSON.parse(props.item.description[0])
      } catch (error) {
        description = props.item.description[0]
      }
    }

    return description ?? {}
  })

  const isCommunicationActive = computed(() => {
    return (
      userCommunicationDescription.value.isActive ?? props.item.active === 1
    )
  })

  const userCommunicationTitle = computed(() => {
    const communicationActivityInfo = getCommunicationPreferenceInfo(props.item)
    const isEmailCommunicationActivity = isEmailCommunicationPreference(
      props.item
    )

    return (
      communicationActivityInfo?.title ??
      String(isEmailCommunicationActivity ? ' emails' : '') +
        ' communication preferences ' +
        (isCommunicationActive.value ? 'enabled' : 'disabled')
    )
  })

  const userCommunicationSubtitle = computed(() => {
    return providerClientName.value + ' communication preferences update'
  })

  const title = computed(() => {
    if (props.item.isTypePointsTransaction) {
      if (props.item.isStatusPending) return 'Points to be earned'

      return 'Points Earned'
    } else if (props.item.isTypeUserCommunication) {
      return userCommunicationTitle.value
    }

    if (props.item.isTypePointsTransferRequest) {
      return props.item.pointsTransferRequest?.title ?? 'Points debited'
    }

    return (
      props.item.payee?.name ||
      props.item.description?.[1] ||
      props.item.description?.[0]
    )
  })

  const subtitle = computed(() => {
    if (props.item.isTypeUserCommunication) {
      return userCommunicationSubtitle.value
    } else if (props.item.isTypeCampaign) {
      return `${props.item.campaign?.referenceNumber}`
    } else {
      return null
    }
  })

  const amount = computed(() => {
    if (props.item.isTypeMembership) {
      return ''
    } else if (
      props.item.isTypeProgramOrder ||
      props.item.isTypePointsTransaction
    ) {
      return 'n/a'
    } else {
      return props.item.total ? formatDollar(props.item.total) : 'n/a'
    }
  })

  const canSeeDetails = computed(() => {
    return (
      props.item.isTypePurchaseOrder ||
      (props.item.isTypePaymentOrder &&
        props.item.metadata.transactionType !== 'credit') ||
      props.item.isTypeBatchOrder ||
      props.item.isTypeStatementOrder ||
      props.item.isTypeTopUpOrder ||
      props.item.isTypeProgramOrder ||
      props.item.isTypeRefund
    )
  })

  const showStatus = computed(() => {
    // check if we should display cancelled statuses
    return (
      (props.item.isStatusCompleted ||
        props.item.isStatusPending ||
        props.item.isStatusAwaitingFunds ||
        props.item.isStatusFailed ||
        props.item.isStatusIncomplete) &&
      (subtitle.value || props.item.orderNumber)
    )
  })

  /**
   *
   * @param options.prefix default is `+`
   * @param options.suffix default is `pts`
   * @returns Formatted points string `+1000 pts` or `n/a`
   */
  const getPointsString = (options: {
    points: string | number
    checkValue?: string | number | boolean | undefined
    prefix?: '+' | '-' | ''
    suffix?: string
  }): string => {
    const {
      points,
      checkValue = undefined,
      prefix = '+',
      suffix = 'pts',
    } = options

    if (checkValue !== false) {
      const pointsAmount = ensureNumber(points)

      return pointsAmount
        ? `${prefix}${formatPoints(pointsAmount)} ${suffix}`
        : 'n/a'
    }

    return 'n/a'
  }

  const pointsTransactions = computed(() => {
    const { pointsTransactions = [] } = props.item

    if (props.item.isTypeBatchOrder) {
      const points =
        pointsTransactions.reduce((sum, item) => {
          return sum + Number(item.allocatedValue ?? 0)
        }, 0) || 0
      const prefix = points > 0 ? '+' : '-'

      return [getPointsString({ points, prefix })]
    }

    if (props.item.isTypeProgramOrder) {
      return [getPointsString({ points: props.item.points, prefix: '-' })]
    }

    if (props.item.isTypePointsTransaction) {
      const prefix = props.item.subtype === 'burn' ? '-' : '+'

      return [getPointsString({ points: props.item.total, prefix })]
    }

    if (props.item.isTypeStatementOrder) {
      const points = calculatePointsEarnedForPayment(props.item.subtotal)
      const checkValue = Math.round(points) !== 0

      return [getPointsString({ points, checkValue })]
    }

    if (pointsTransactions.length === 0) {
      return ['n/a']
    }

    return pointsTransactions.map((pointsRecord) => {
      const prefix = pointsRecord?.type === 'burn' ? '-' : '+'
      const points = pointsRecord?.allocatedValue ?? 0

      return getPointsString({ points, prefix, checkValue: Number(points) })
    })
  })

  const cashback = computed(() => {
    // TODO: https://eonx.atlassian.net/browse/PRO-13262
    const types =
      props.item.isTypePurchaseOrder ||
      props.item.isTypeUserCashbackOrder ||
      props.item.isTypeCampaign

    return types && props.item.cashback
      ? formatDollar(props.item.cashback)
      : 'n/a'
  })

  const cashbackTooltipLabel = computed(() => {
    return (!props.item.paidAt || !props.item.isStatusCompleted) &&
      props.item.isTypeUserCashbackOrder &&
      props.item.cashback
      ? `Cashback will be deposited in your ${ewalletLabels.value.ewalletCash} in 90 days from the transaction date`
      : null
  })

  const date = computed(() => {
    let date: string

    if (
      props.item.isTypePaymentOrder ||
      props.item.isTypeStatementOrder ||
      props.item.isTypeBatchOrder
    ) {
      date = props.item.updatedAt
    } else if (props.item.isTypeCampaign) {
      date = props.item.campaign?.departureDate as string
    } else {
      date = props.item.happenedAt
    }

    return formatDate('daymonthyearnumeric', date)
  })

  const paymentMethods = computed(() => {
    if (props.item.isTypePointsTransaction) return

    if (props.item.isTypeProgramOrder) return 'Points'

    return ((props.item.paymentMethods ?? []) as ActivityPaymentMethods)
      .map((method) => {
        const value = typeof method === 'string' ? method : method.type

        switch (value) {
          case PaymentMethodType.eWallet:
            return ewalletLabels.value.ewalletCash
          case PaymentMethodType.points:
            return 'Points'
          case PaymentMethodType.creditCard:
            return 'Credit Card'
          case PaymentMethodType.bankAccount:
            return 'Bank Account'
          case PaymentMethodType.couponCode:
            return 'Coupon code'
        }
      })
      .filter((method) => method)
      .join(' + ')
  })

  const productDetails = computed(() => props.item?.campaign?.package?.name)

  const label = computed(() => {
    if (props.item.orderNumber)
      return `${title.value}. Order number ${props.item.orderNumber}`

    if (subtitle.value) return `${title.value}. ${subtitle.value}`

    return title.value
  })

  return {
    showPoints,
    date,
    logo,
    label,
    icon,
    iconColor,
    title,
    subtitle,
    amount,
    productDetails,
    paymentMethods,
    pointsTransactions,
    cashback,
    cashbackTooltipLabel,
    showStatus,
    canSeeDetails,
    getPointsString,
    userCommunicationTitle,
    userCommunicationSubtitle,
  }
}
