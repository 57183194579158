import get from 'lodash-es/get'
import cdn from '/~/utils/cdn'

const provider = eonx.provider
const cms = eonx.cms

const manifest = {
  name: provider.name,
  short_name: provider.name,
  description: provider.name,
  start_url: window.location.origin,
  background_color: get(cms.design, 'color-secondary', '#fff'),
  theme_color: get(cms.design, 'color-primary', '#e2b272'),
  display: 'standalone',
  icons: provider.square_logo
    ? [
        '36x36',
        '48x48',
        '128x128',
        '144x144',
        '192x192',
        '256x256',
        '512x512',
      ].map((size) => {
        const imgUrl = cdn(provider.square_logo)
          .resize(size)
          .quality('best')
          .format('png')
          .url()

        return {
          src: 'https://' + imgUrl,
          sizes: size,
          type: 'image/png',
        }
      })
    : [],
}

const manifestLink = document.createElement('link')
const blob = new Blob([JSON.stringify(manifest)], {
  type: 'application/json',
})

manifestLink.setAttribute('rel', 'manifest')
manifestLink.setAttribute('href', URL.createObjectURL(blob))
document.head.appendChild(manifestLink)
