<script>
import { computed } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import { formatDate } from '/~/utils/format/date'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.v3.vue'
import DrawerAvatar from '/~/components/drawer/components/drawer-avatar/drawer-avatar.v4.vue'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

export default {
  name: 'profile-home',
  components: {
    BaseAsidePage,
    BaseIcon,
    DrawerActions,
    DrawerAvatar,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const {
      isDisplayMemberSince,
      isPasswordEditable,
      isAvatarVisible,
      isUserAddressEnabled,
    } = useProvider()

    const { user } = useUser()

    const items = computed(() => {
      return [
        {
          icon: 'v2/heroic/pencil-alt',
          label: 'Personal Details',
          description: `Personal information${
            isUserAddressEnabled.value ? ' & address' : ''
          }`,
          route: { hash: '#profile-personal-details' },
        },
        {
          icon: 'v2/box-icons/bx-lock-open',
          label: 'Password',
          description: 'Manage your own secure password',
          hidden: !isPasswordEditable.value,
          route: { hash: '#profile-change-password' },
        },
      ]
    })

    function onChangeAvatar() {
      bottomSheet.show('avatar', {
        to: props.to,
      })
    }

    return {
      isDisplayMemberSince,
      user,
      isAvatarVisible,
      items,
      onChangeAvatar,
      formatDate,
    }
  },
}
</script>

<template>
  <base-aside-page title="Profile" no-padding>
    <div class="px-5">
      <drawer-avatar
        v-if="isAvatarVisible"
        :avatar-url="user.avatarUrl"
        :action="onChangeAvatar"
        class="mx-auto h-56 w-56"
        large
      />
      <div class="mt-[30px] text-center text-xl font-bold leading-7">
        {{ user.fullName }}
      </div>
      <div
        v-if="isDisplayMemberSince"
        class="relative flex flex-row items-center justify-center"
      >
        <div
          class="mx-auto mt-2.5 flex flex-row items-center rounded-full border border-gray-100 px-5 py-2.5 text-sm text-eonx-neutral-600"
        >
          <base-icon
            svg="australiapost/calendar-colored"
            :size="18"
            class="mr-2.5 text-eonx-neutral-600"
          />
          <span class="text-sm font-bold">
            Member since {{ formatDate('daymonthyear', user.joinDate) }}
          </span>
        </div>
      </div>

      <drawer-actions
        v-if="items.length"
        :actions="items"
        class="mt-[30px] space-y-4"
      />
    </div>
  </base-aside-page>
</template>
