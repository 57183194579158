import Vue, { ref } from 'vue'

type Entity = {
  [key: string | number]: string
}

type Errors = {
  violations: Entity
  message: string
}

type Payload = {
  data: Entity
  exclude: string
}

export function useBackendValidation() {
  const backendErrors = ref<Entity>({})
  const responseError = ref<Errors | null>(null)

  function processBackendErrors(error: Errors, payload: Payload) {
    const backendErrorItems: Entity = {}

    const { data, exclude } = payload

    responseError.value = error

    if (error?.violations) {
      for (const key in error.violations) {
        if (Object.prototype.hasOwnProperty.call(error.violations, key)) {
          backendErrorItems[key] = error.violations[key][0] || ''
        }
      }
    } else {
      console.error(error)
    }

    backendErrors.value = backendErrorItems

    if (payload) {
      const formError = Object.keys(data).filter((key) =>
        Object.keys(backendErrors.value).includes(key)
      )

      if (
        (exclude &&
          !Object.keys(backendErrors.value).includes(exclude) &&
          !formError.length) ||
        (!formError.length && !exclude)
      ) {
        showNotifyError()
      }
    }
  }

  function showNotifyError() {
    backendErrors.value = {}
    const backendError =
      responseError.value?.violations?.[0] || responseError.value?.message

    Vue.notify({
      text: backendError || 'Something went wrong, please try again later',
      type: 'error',
      duration: 5000,
    })
    responseError.value = null
  }

  return {
    backendErrors,
    processBackendErrors,
  }
}
