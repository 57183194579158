<script setup lang="ts">
import MaintenanceApp from './app.pure.vue'

async function checkServiceAvailability(): Promise<boolean> {
  try {
    const response = await fetch(`${eonx.hosts.api.v1}/jwt`, {
      headers: {
        Accept: 'application/json',
      },
      cache: 'no-store',
    })

    if (response.status === 503) {
      return false
    }
  } catch (e) {
    console.error('error in checkServiceAvailability', e)
  }

  return true
}

function reloadPage() {
  window.location.reload()
}

eonx.ready()
</script>

<template>
  <maintenance-app
    :check-timeouts="[5, 10, 20, 40, 80, 160, 300]"
    :is-available-callback="checkServiceAvailability"
    @available="reloadPage"
  />
</template>
