<script setup lang="ts">
import ui from '/~/core/ui'
import WidgetTilesHemisphereContent from './widget-tiles-hemisphere-content.vue'
import { Row, Wrapper, useWrapper } from '/~/configurator/composables/wrapper'
import { computed } from 'vue'
import { Tile } from '/~/composables/cms/use-cms'

const props = defineProps<{
  row?: Row
  wrapper?: Wrapper
  items: Tile[]
}>()

const { getTileKey } = useWrapper(props)

const gridClasses = computed(() => {
  return ui.width > 768
    ? 'grid-cols-3'
    : ui.width < 769 && ui.width > 570
    ? 'grid-cols-2'
    : ui.width < 571
    ? 'grid-cols-1'
    : ''
})
</script>

<template>
  <section class="grid gap-6" :class="gridClasses">
    <div
      v-for="(tile, idx) in items"
      :key="getTileKey(tile, idx)"
      class="w-full overflow-hidden rounded-md bg-light shadow"
    >
      <widget-tiles-hemisphere-content :tile="tile" />
    </div>
  </section>
</template>
