<script>
import BaseAction from '/~/components/base/action/base-action.vue'
import WgtImage from '../wgt/wgt-image.vue'
import WgtText from '../wgt/wgt-text.vue'

export default {
  name: 'widget-image',
  components: {
    WgtText,
    WgtImage,
    BaseAction,
  },
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    titleText: {
      type: Object,
      default: () => ({}),
    },
    enableTitle: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      required: true,
    },
    imageHeight: {
      type: Number,
      default: 400,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    bindings() {
      return { ...this.link, label: this.image.alt || this.link.label }
    },
  },
}
</script>

<template>
  <section>
    <div
      v-if="enableTitle"
      class="mb-[30px] pt-2.5 text-xl font-bold leading-6"
      :style="{
        textAlign: titleText.contentAlignmentX,
      }"
      v-html="titleText.title"
    />
    <div class="overflow-hidden" :class="{ shadow: shadow }">
      <base-action
        v-if="link && link.enabled"
        v-bind="bindings"
        class="block h-full w-full"
        appearance="link"
        origin="banner"
      >
        <wgt-image :image="image" class="rounded-lg" />
        <wgt-text
          v-if="content && (content.title || content.text)"
          :content="content"
        />
      </base-action>
    </div>
  </section>
</template>
