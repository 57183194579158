<script>
import { nextTick } from 'vue'
import DrawerPayFrom from '/~/components/drawer/components/pay/drawer-pay-from.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'

export default {
  name: 'profile-add-cash-from',
  components: {
    DrawerPayFrom,
  },
  methods: {
    addPaymentMethod() {
      this.$router.push({
        hash: '#profile-add-cash-from-add',
        params: { source: FlowType.topUpOrder },
      })
    },
    setFocus() {
      nextTick(() => {
        this.$refs.drawerPayFrom.setFocus()
      })
    },
  },
}
</script>

<template>
  <drawer-pay-from
    ref="drawerPayFrom"
    title="Add Cash from"
    :action="addPaymentMethod"
  >
    <template #cardsLabel>Credit/Debit Cards</template>
    <template #cardsEmptyLabel>No Available Credit/Debit Cards</template>
  </drawer-pay-from>
</template>
