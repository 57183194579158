<script>
export default {
  name: 'rec-layout-view-mobile',
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <div class="h-full">
    <div class="relative flex h-full flex-col">
      <slot />
    </div>
  </div>
</template>
