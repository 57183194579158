<script>
import MediaGallery from '/~rec/components/media/media-gallery.vue'
import MediaDownloadable from '/~rec/components/media/media-downloadable.vue'
import MediaAudio from '/~rec/components/media/media-audio.vue'
import MessageBubble from './chat-room-message-bubble.vue'
import processTextForUrls from '/~/utils/process-text-for-urls'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { formatDate } from '/~/utils/format/date'
import { fromIdToMention } from '/~rec/utils/process-text-for-mentions'
import { useRecProfile } from '/~rec/composables/profile'
import { nextTick } from 'vue'

export default {
  name: 'rec-chat-message',
  components: {
    MediaAudio,
    MediaDownloadable,
    MediaGallery,
    MessageBubble,
    BaseButton,
    BaseIcon,
    BaseAvatar,
    BaseMenu,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    room: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return {
      formatDate,
      isMeAdmin,
      isMeSuperAdmin,
    }
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    menuItems() {
      return [
        {
          icon: 'rec/edit',
          content: 'Edit Message',
          disabled: this.message.sending,
          click: () => {
            this.$refs.menu.hide()
            this.$emit('edit', this.message)
          },
        },
        {
          icon: 'trash',
          content: 'Delete Message',
          disabled: this.message.sending,
          click: () => {
            this.room.deleteMessage(this.message)
          },
        },
      ]
    },
    author() {
      const user = this.room.members.find(
        ({ memberId }) => this.message.participantId === memberId
      )

      return (
        user || {
          initials: 'D',
          fullName: 'Deleted',
        }
      )
    },
    textMessage() {
      return this.processTextForUrls(
        this.fromIdToMention(this.message.body, this.message.raw.mentions, true)
      )
    },
    editable() {
      return (
        this.message.isMeAuthor ||
        this.message.isMeAuthor ||
        this.isMeAdmin ||
        this.isMeSuperAdmin
      )
    },
  },
  methods: {
    processTextForUrls,
    fromIdToMention,
    onMouseOver(canEdit) {
      this.hover = canEdit
    },
    onMouseLeave() {
      if (this.$refs.menu) {
        this.$refs.menu.hide()
      }
      nextTick(() => {
        this.hover = false
      })
    },
    onView() {
      if (this.message.isMeAuthor) return

      const view = this.message.views.find((data) => {
        return data.participant_id === this.room.me.memberId
      })

      if (!view.viewed_at) {
        this.$emit('viewed')
      }
    },
  },
}
</script>

<template>
  <div class="my-2.5 pr-2.5 md:p-0">
    <div
      v-if="message.isDeleted"
      class="mx-6"
      :class="{
        'text-right': message.isMeAuthor,
        'text-left': !message.isMeAuthor,
      }"
    >
      <span class="truncate text-xs text-eonx-neutral-600">
        Message deleted
      </span>
    </div>

    <div
      v-else
      v-observe-visibility="{
        callback: onView,
        once: true,
      }"
      class="relative z-1 flex w-full transform flex-col hover:z-40"
      :class="{
        'items-end transition-transform duration-300': message.isMeAuthor,
        'items-start': !message.isMeAuthor,
        'md:hover:-translate-x-2.5': editable,
      }"
      @mouseover="onMouseOver(message.isMeAuthor)"
      @mouseleave="onMouseLeave"
    >
      <div class="flex w-full pl-2.5 pr-6">
        <base-avatar
          v-if="room.isChannel && !message.isMeAuthor"
          class="sticky mb-[5px] mr-2.5"
          style="top: 1rem"
          size="xs"
          :src="author.avatarUrl"
          :abbr="author.initials"
        />
        <div
          class="relative flex w-full flex-col"
          :class="{
            'items-end pl-10': message.isMeAuthor,
            'items-start': !message.isMeAuthor,
          }"
        >
          <message-bubble
            v-if="message.attachments.hasAudioAttachments"
            class="mb-[5px] w-full px-2.5 py-[5px]"
            :message="message"
          >
            <media-audio
              type="message"
              :content-color="message.isMeAuthor ? 'text-white' : undefined"
              :button-color="message.isMeAuthor ? 'text-white' : undefined"
              :attachments="message.attachments"
              :samples="24"
            />
          </message-bubble>
          <message-bubble
            v-if="message.attachments.hasDownloadableAttachments"
            class="mb-[5px] w-full px-[15px] py-2.5"
            :message="message"
          >
            <media-downloadable
              type="message"
              :content-color="message.isMeAuthor ? 'text-white' : undefined"
              :button-color="message.isMeAuthor ? 'text-white' : undefined"
              :attachments="message.attachments"
            />
          </message-bubble>
          <message-bubble
            v-if="message.attachments.hasPreviewAttachments"
            class="mb-[5px] p-[5px]"
            :message="message"
          >
            <media-gallery
              type="message"
              class="overflow-hidden rounded-lg"
              :class="{
                'rounded-br-none': message.isMeAuthor,
                'rounded-bl-none': !message.isMeAuthor,
              }"
              :attachments="message.attachments"
            />
          </message-bubble>
          <message-bubble
            v-if="!message.isEmpty"
            class="mb-[5px] px-[15px] py-2.5"
            :class="{
              'mt-[5px]': message.attachments.hasAttachments,
            }"
            :message="message"
          >
            <div class="whitespace-pre-wrap break-words" v-html="textMessage" />
          </message-bubble>
          <div
            class="-mt-[5px] inline-flex items-center align-middle text-eonx-neutral-600"
            :class="{
              'flex-row-reverse': !message.isMeAuthor,
            }"
          >
            <base-icon
              v-if="message.createdAt !== message.updatedAt"
              svg="plain/pen"
              class="mx-[5px]"
              size="xxs"
            />
            <b class="text-xs">
              <span v-if="room.isChannel && !message.isMeAuthor">
                {{ author.fullName }} -
              </span>
              <span>{{ formatDate('time', message.createdAt) }}</span>
            </b>
          </div>
        </div>
      </div>
      <div
        v-if="editable"
        class="absolute top-0 right-0 z-20 translate-x-[5px] transform"
      >
        <base-menu ref="menu" :items="menuItems" class="z-40" position="right">
          <template #trigger>
            <base-button
              icon="plain/more"
              size="sme"
              class="flex self-center text-primary"
            />
          </template>
        </base-menu>
      </div>
    </div>
  </div>
</template>
