<script setup lang="ts">
import { computed } from 'vue'
import GcBadge from '/~/extensions/giftcards/components/gc-badge.vue'
import OoReturn from '/~/extensions/online-offers/components/oo-return.vue'
import cdn, { getProviderDefaultUrl } from '/~/utils/cdn'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useProvider } from '/~/composables/provider'

const props = defineProps<{
  data: {
    target?: {
      name: 'giftcards-retailer' | 'estore-product'
    }
    offer?: string
    meta?: {
      physical?: boolean
    }
    cashback_rate?: number
    imageRatio?: number
    uploadcare?: string
    image?: string
    label?: string
  }
}>()

const emit = defineEmits<{
  (event: 'close'): void
}>()

const { isBupaTemplate } = useProvider()

const defaultImage = computed(() =>
  isBupaTemplate.value ? getProviderDefaultUrl('bupa') : undefined
)
const isGiftCard = computed(
  () => props.data.target?.name === 'giftcards-retailer'
)
const isPhysical = computed(
  () =>
    props.data.target?.name === 'estore-product' &&
    props.data.offer?.indexOf('Off Gift Cards') !== -1
)
const isPhysicalEnabled = computed(() => props.data.meta?.physical ?? true)
const cashbackRate = computed(() => props.data.cashback_rate ?? 0)
const imageRatio = computed(() => props.data.imageRatio || 1)

function getImage() {
  if (props.data.uploadcare) {
    return cdn(props.data.uploadcare)
      .preview('300x300')
      .format('auto')
      .quality('smart')
      .url()
  } else {
    return props.data.image
  }
}
</script>

<template>
  <base-link
    class="relative flex items-center px-2.5 py-2.5 hover:bg-dark sm:px-2.5"
    :to="data.target"
    @click="emit('close')"
  >
    <div
      class="mr-5 flex h-12 w-12 shrink-0 items-center sm:mr-[30px] sm:h-16 sm:w-16"
    >
      <base-image
        :ratio="imageRatio"
        :src="getImage()"
        :default-src="defaultImage"
        :rounded="false"
      />
    </div>

    <div class="min-w-0 text-default">
      <div class="flex flex-wrap items-center justify-start font-bold">
        <h3 class="truncate" v-html="data.label" />
        <div class="ml-2.5">
          <gc-badge
            v-if="isPhysicalEnabled && isGiftCard"
            :is-physical="isPhysical"
            digital-label="Digital Gift Card"
            physical-label="Physical Gift Card"
            size="sm"
          />
        </div>
      </div>
      <oo-return
        v-if="cashbackRate"
        class="truncate"
        :cashback-rate="cashbackRate"
      />
      <div v-else-if="data.offer" class="truncate">
        {{ data.offer }}
      </div>
    </div>
  </base-link>
</template>
