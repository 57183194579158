<script>
import { moduleAllowed } from '/~/core/module-filters'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import DrawerProfileSettings from '/~/templates/australiapost/components/drawer/views/profile/drawer-profile.vue'

export default {
  name: 'profile-details',
  components: {
    DrawerProfileSettings,
  },
  setup() {
    const { user } = useUser()
    const { isUserAddressEnabled } = useProvider()

    return {
      user,
      isUserAddressEnabled,
    }
  },
  computed: {
    items() {
      const items = [
        {
          icon: 'australiapost/user',
          label: 'Personal Details',
          description: `Update your personal information${
            this.isUserAddressEnabled ? ' & address' : ''
          }`,
          route: { hash: '#profile-personal-details' },
        },
      ]

      if (moduleAllowed('user-communications')) {
        items.push({
          icon: 'australiapost/speaker',
          label: 'Communication Preferences',
          description: 'Stay in touch with products and promotions',
          route: { hash: '#profile-communication' },
        })
      }

      return items
    },
  },
}
</script>

<template>
  <drawer-profile-settings :items="items" to="menu-modal" />
</template>
