import api from '/~rec/core/api'

export class RecroomPerk {
  constructor(rawData = {}) {
    this.raw = rawData
  }

  get id() {
    return this.raw.id
  }

  get name() {
    return this.raw.name
  }

  get description() {
    return this.raw.description
  }

  get logo() {
    return this.raw.logo
  }

  get cost() {
    return this.raw.cost
  }

  get currency() {
    return this.raw.currency
  }

  get stockEnabled() {
    return this.raw.stock_enabled
  }

  get totalStock() {
    return this.raw.total_stock
  }

  get approvalStrategy() {
    return this.raw.approval_strategy
  }

  get isApprovalAutomatic() {
    return this.approvalStrategy === 'automatic'
  }

  get isOutOfStock() {
    return this.status === 'out_of_stock'
  }

  get status() {
    return this.raw.status
  }

  get owner() {
    return this.raw.owner
  }

  static async fetchById(id) {
    const response = await api.get(`/gamification/perks/${id}`)

    return new RecroomPerk(response.data)
  }

  async redeem() {
    return api.post(
      `/gamification/perks/${this.id}/redeem`,
      {},
      { notify: false }
    )
  }
}
