<script>
import ui from '/~/core/ui'
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'

export default {
  name: 'qr-modal-header',
  components: {
    RecognitionHeader,
  },
  props: {
    isSuccess: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <div :class="[ui.mobile ? '' : 'overflow-hidden rounded-t-lg']">
    <recognition-header
      :color="isSuccess ? 'success' : 'error'"
      :icon="isSuccess ? 'qr/success' : 'qr/error'"
      animated
    />
  </div>
</template>
