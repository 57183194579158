<script>
export default {
  name: 'dining',
}
</script>

<template>
  <div>
    <router-view />
  </div>
</template>
