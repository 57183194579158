<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { usePoints } from '/~/composables/points'
import { useUser } from '/~/composables/user'
import { useMembership } from '/~/templates/bill-payments/composables'

const { formattedPointsBalance, pointsLoading } = usePoints()
const { isMembershipSwitchingEnabled } = useMembership()
const { user } = useUser()
const emit = defineEmits<(event: 'membership-name-clicked') => void>()

function onMembershipNameClicked() {
  emit('membership-name-clicked')
}
</script>

<template>
  <div>
    <div class="flex items-baseline justify-center text-eonx-neutral-800">
      <base-loader v-if="pointsLoading" color="var(--color-white)" size="lg" />
      <div v-else class="flex items-end leading-[60px]">
        <h3 class="text-5xl font-bold">
          {{ formattedPointsBalance }}
        </h3>
        <span class="ml-[5px] text-base font-bold">PTS</span>
      </div>
    </div>
    <div class="mt-1.5 flex justify-center">
      <div
        class="flex items-center gap-x-[5px] rounded-full border border-eonx-neutral-50 p-0.5"
        :class="{
          'cursor-pointer hover:border-eonx-neutral-200 hover:bg-dark':
            isMembershipSwitchingEnabled,
        }"
        @click="onMembershipNameClicked"
      >
        <base-icon class="text-primary" svg="points/token" :size="20" />
        <span class="font-semibold uppercase text-eonx-neutral-800">
          {{ user.membershipName }}
        </span>
        <base-icon
          class="text-eonx-neutral-800"
          svg="plain/chevron-right.v2"
          size="sme"
        />
      </div>
    </div>
  </div>
</template>
