<script>
import RecBaseState from '/~rec/components/state/base-state.vue'

export default {
  name: 'rec-news-empty-state',
  components: {
    RecBaseState,
  },
  computed: {
    feedId() {
      return this.$route.params.feedId
    },
    state() {
      switch (this.feedId) {
        case 'announcements':
          return {
            img: 'recroom/state/announcements-empty-state.svg',
            title: 'Announcements',
            text: 'Be alerted with important messages',
          }
        case 'saved-posts':
          return {
            img: 'recroom/state/saved-posts-empty-state.svg',
            title: 'Saved Posts',
            text: 'Save posts you wish to easily access at a later stage',
          }
        case 'my':
          return {
            img: 'recroom/state/simple-state.svg',
            title: 'My Posts',
            text: 'Every post you write will be saved here for you to access anytime',
          }
        case 'reported-posts':
          return {
            img: 'recroom/state/simple-state.svg',
            title: 'Welcome to Reported Posts',
            text: 'This is your broadcast channel to communicate with your peers',
          }
        default:
          return {
            img: 'recroom/state/simple-state.svg',
            title: 'Newsfeed',
            text: 'This is your channel to communicate',
          }
      }
    },
  },
}
</script>

<template>
  <rec-base-state
    :image="state.img"
    :title="state.title"
    :subtitle="state.text"
  />
</template>
