import get from 'lodash-es/get'
import { formatDate } from '/~/utils/format/date'

export class RecroomTransaction {
  constructor(raw) {
    this.raw = raw || {}
  }

  get actionTitle() {
    return get(this.raw, 'action_message')
  }

  get description() {
    /**
     * action object has additional info
     */
    return get(this.raw, 'action.message')
  }

  get memberFullName() {
    return get(this.raw, 'full_name')
  }

  get createdAt() {
    const date = get(this.raw, 'created_at')

    return formatDate('daymonthyeartime', date)
  }

  get type() {
    return get(this.raw, 'type')
  }

  get earnType() {
    return this.type === 'earn'
  }

  get burnType() {
    return this.type === 'burn'
  }

  get transactionValue() {
    return get(this.raw, 'earn_value') || get(this.raw, 'amount')
  }

  get valueSymbol() {
    return this.burnType ? '-' : '+'
  }

  get actionData() {
    return this.raw.action.data
  }

  get actionEntities() {
    return this.raw.action.related_entities
  }

  get actionMessage() {
    return this.raw.action_message
  }
}
