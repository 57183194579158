<script setup lang="ts">
import HeaderTopMenu from '/~/components/app/header/components/header-top-menu/header-top-menu.v5.vue'
import { useHeaderTopMenu } from '/~/components/app/header/components/header-top-menu/use-header-top-menu'

const { activeTopMenuItem } = useHeaderTopMenu()
</script>

<template>
  <header-top-menu :active-item="activeTopMenuItem" />
</template>
