<script>
import get from 'lodash-es/get'
import { useDining } from '/~/extensions/dining/composables'
import RetailerProduct from '/~/extensions/giftcards/components/product/retailer-product.vue'
import { useRetailer } from '/~/extensions/giftcards/composables'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import EwGallery from '/~/components/ewallet/gallery/ew-gallery.vue'
import RetailerEmpty from '/~/components/retailer/retailer-empty.vue'
import DiningInfo from './components/dining-details-info.vue'

export default {
  name: 'dining-details',
  components: {
    RetailerEmpty,
    RetailerProduct,
    DiningInfo,
    EwDetailsLayout,
    EwGallery,
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
    tabsProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { getRetailer, getRetailerOffers } = useRetailer()

    const { retailerImage, index, algolia, meta, routeQuery } = useDining()

    return {
      getRetailer,
      getRetailerOffers,
      retailerImage,
      index,
      algolia,
      meta,
      routeQuery,
    }
  },
  data() {
    return {
      offer: null,
      retailer: null,
      location: {},
      empty: false,
      loading: true,
    }
  },
  computed: {
    backRoute() {
      return {
        name: 'dining',
        query: this.routeQuery,
      }
    },
    ready() {
      return Object.keys(this.location).length > 0
    },
    name() {
      return get(this.location, 'name', '')
    },
    defaultImage() {
      return this.cdn('uploadcare://41c495fe-e05c-40c3-a792-13e6f9fdee7e')
        .preview('360x360')
        .quality('best')
        .url()
    },
    image() {
      const imgPath = this.retailerImage
        ? `uploadcare://${this.retailerImage}`
        : null

      return this.cdn(imgPath).preview('360x360').quality('best').url()
    },
    detailsBindings() {
      return {
        link: get(this, 'location.website', ''),
        description: get(this, 'location.description', ''),
        phone: get(this, 'location.phone', ''),
        address: get(this, 'location.address', {}),
        operations: get(this, 'location.operations', {}),
        feature: get(this, 'location.feature', []),
        terms: get(this.offer, 'attributes.terms', ''),
        period: get(this.offer, 'attributes.validity_period', 0),
      }
    },
    algoliaQuery() {
      return {
        hitsPerPage: 1,
        filters: `slug: ${this.slug}`,
      }
    },
    gallery() {
      const items = this.location?.imgs?.map((item) => {
        return { url: item }
      })

      return items?.length > 0 ? items : [{ url: this.image }]
    },
  },
  watch: {
    $route(to, from) {
      if (to.hash || from.hash) return

      const id = this.meta?.offer?.id

      if (id) {
        this.fetchOffer(to.params.slug, id)
      }
    },
  },
  created() {
    const { offer = {}, retailer = {} } = this.meta
    const { id } = offer

    if (id) {
      this.fetchOffer(retailer.slug || this.slug, id)
    }
  },
  methods: {
    async fetchOffer(slug, id) {
      this.loading = true
      const [error, retailer] = await this.getRetailer(slug)

      if (error) {
        this.empty = true
        return
      }

      const retailerOffer = get(retailer, 'relationships.offers.data.[0]')

      this.retailer = retailer

      if (retailerOffer) {
        this.offer = await this.getRetailerOffers({
          slug,
          offer: id || retailerOffer.id,
        })
      }

      this.location = await this.getData()
      if (this.retailer) {
        this.retailer.attributes.name = this.location.name
      }

      if (this.offer) {
        this.offer.attributes.uuid = this.location.id
        this.offer.relationships.products.data.forEach((item) => {
          item.attributes.uuid = this.location.id
        })
      }

      this.$emit('set-title', this.location.name)
      this.loading = false
    },
    async getData() {
      return this.algolia
        .getDataOnce({
          index: this.index,
          search: {
            ...this.algoliaQuery,
          },
        })
        .then((response) => get(response, 'hits[0]') || {})
        .catch((err) => {
          this.$router.replace(this.backRoute)
          throw err
        })
    },
  },
}
</script>

<template>
  <div class="min-h-full">
    <div class="flex min-h-full flex-col md:flex-row">
      <div class="w-full grow rounded-t-3xl md:bg-eonx-neutral-50">
        <div class="mx-auto w-full space-y-5">
          <retailer-empty v-if="empty" />
          <ew-details-layout
            v-else
            :loading="loading"
            :ready="ready"
            :title="name"
            :placeholder-ratio="1"
            data-cy="giftcard-title"
            :back="backRoute"
            data-test="container"
            class="max-w-full px-5"
          >
            <template #gallery>
              <div class="p-5 sm:p-0">
                <ew-gallery :gallery="gallery" :default-src="defaultImage" />
              </div>
            </template>

            <template #details>
              <dining-info
                v-bind="detailsBindings"
                :tabs-props="tabsProps"
                data-test="details"
                class="px-5 sm:px-0"
              />
            </template>

            <template #summary>
              <retailer-product
                type="dining"
                :offer="offer"
                :retailer="retailer"
                :loading="loading"
                data-test="summary"
              />
            </template>
          </ew-details-layout>
        </div>
      </div>
    </div>
  </div>
</template>
