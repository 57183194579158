<script>
import ui from '/~/core/ui'
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import { RecroomGroup } from '/~rec/core/group'
import GroupForm from '/~rec/components/group/group-form.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { useRecProfile } from '/~rec/composables/profile'
import { useBackendValidation } from '/~rec/composables/backend-validation'

export default {
  name: 'rec-group-create-modal',
  components: {
    GroupForm,
    BaseMdl,
    BaseLoader,
    HeaderMobile,
  },
  setup() {
    const { isMeLimitedUser } = useRecProfile()
    const { backendErrors, processBackendErrors } = useBackendValidation()

    return {
      isMeLimitedUser,
      backendErrors,
      processBackendErrors,
      ui,
    }
  },
  data() {
    return {
      processing: false,
      form: null,
      group: null,
    }
  },
  computed: {
    isEdit() {
      return !!this.$route.params.groupId
    },
    title() {
      return this.isEdit ? 'Edit Group' : 'Groups'
    },
  },
  async created() {
    const initialGroup = this.$route?.params?.initialGroup
    const groupId = this.$route?.params?.groupId

    if (initialGroup) {
      this.group = initialGroup
    } else if (groupId) {
      this.group = await RecroomGroup.fetchById(groupId)

      await this.group?.members?.refresh()
    }

    const defaultImage = this.cdn('/img/banner1').url()
    const { group } = this

    this.form = {
      image: get(group, 'image', defaultImage),
      private: get(group, 'isPrivate', this.isMeLimitedUser),
      name: get(group, 'name', ''),
      description: get(group, 'description', ''),
      members: get(group, 'members.hits', []),
    }
  },
  methods: {
    async onSave() {
      const isValid = await this.$refs.form.validationObserverRef?.validate()

      if (!isValid) return

      const { form } = this
      const membersIds = form.members.map((user) => user.id)
      const data = {
        banner_url: form.image,
        private: form.private,
        name: form.name,
        description: form.description,
      }

      this.processing = true

      try {
        let group = this.group

        if (this.isEdit) {
          await this.group.updateMembers(membersIds)
          group = await this.group.edit(data)
        } else {
          group = await RecroomGroup.create({
            ...data,
            members: membersIds,
          })
        }

        this.$emit('done', group)
        this.$router.replace({
          name: 'rec-group',
          params: {
            groupId: group.id,
          },
        })
        modal.hide()
      } catch (error) {
        this.processBackendErrors(error, { data })
        this.$emit('error', error)
      } finally {
        this.processing = false
      }
    },
    onBack() {
      modal.hide()
      if (this.$router.historyLength === 1) {
        if (!this.isEdit) {
          this.$router.push({ name: 'rec-groups' })
        }
      } else {
        this.$router.back()
      }
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" transition="slide-bottom" fullscreen width="screen">
    <header-mobile v-if="!ui.desktop" :title="title" @back="onBack" />
    <group-form
      v-if="form"
      ref="form"
      class="p-5"
      :processing="processing"
      :form="form"
      :backend-errors="backendErrors"
      :show-switch="!isMeLimitedUser"
      :is-edit="isEdit"
      @members="form.members = $event"
      @image="form.image = $event"
      @private="form.private = $event"
      @name="form.name = $event"
      @description="form.description = $event"
      @save="onSave"
    />
    <base-loader v-else class="absolute inset-0" />
  </base-mdl>
</template>
