<script setup lang="ts">
import { computed } from 'vue'
import ui from '/~/core/ui'
import { formatPoints } from '/~/utils/points'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

type Image = {
  desktop: string
  mobile: string
}

type Offer = {
  title: string
  text: string
}

// TODO: move to base action
type Button = {
  label: string
  type: 'page' | 'action' | 'link' | 'video' | 'none'
}

const props = withDefaults(
  defineProps<{
    offer?: Offer
    info?: Offer
    image?: Image
    logo?: Image
    bannerButton?: Button
    imageOnRight?: boolean
    points?: string
    backgroundColor?: string
    offerTextColor?: string
    infoTextColor?: string
    buttonBgColor?: string
    buttonTextColor?: string
    pointsTextColor?: string
    pointsBgColor?: string
  }>(),
  {
    imageOnRight: false,
  }
)

const logoSrc = computed(() =>
  ui.desktop ? props.logo?.desktop : props.logo?.mobile || props.logo?.desktop
)
</script>

<template>
  <div class="swiper-slide">
    <div
      class="relative block overflow-hidden sm:flex sm:h-96 sm:w-full sm:rounded-t-3xl sm:shadow"
      :style="{
        backgroundColor: ui.mobile && backgroundColor,
      }"
    >
      <img
        :src="
          cdn(image?.desktop)
            .preview('2144x768')
            .format('auto')
            .quality('smart')
            .url()
        "
        class="absolute hidden h-full sm:block"
        :class="{
          'left-[45%]': imageOnRight,
          'right-[45%]': !imageOnRight,
        }"
      />
      <div
        class="absolute top-1/2 hidden h-[240%] w-[240%] -translate-y-1/2 rounded-full sm:block"
        :class="{
          'right-1/2 translate-x-12': imageOnRight,
          'left-1/2 -translate-x-12': !imageOnRight,
        }"
        :style="{ backgroundColor }"
      />
      <div
        class="top-0 flex h-full flex-col justify-center space-y-5 p-10 sm:absolute sm:w-1/2"
        :class="{
          'left-0 sm:pl-12': imageOnRight,
          'right-0': !imageOnRight,
        }"
      >
        <div
          v-if="offer?.title || offer?.text"
          class="flex items-center space-x-5"
        >
          <div v-if="logoSrc" class="h-12 w-12 shrink-0">
            <img class="h-full w-full" :src="logoSrc" />
          </div>
          <div
            :style="{
              color: offerTextColor,
            }"
          >
            <div
              v-if="offer.title"
              class="text-xl font-bold"
              v-html="offer.title"
            />
            <div v-if="offer.text" class="flex items-center">
              <base-icon
                svg="australiapost/calendar2"
                size="md"
                class="mr-[5px]"
              />
              <span class="truncate text-xs xs:text-base" v-html="offer.text" />
            </div>
          </div>
        </div>
        <div v-if="points" class="items-start whitespace-nowrap">
          <span
            class="rounded-full py-2.5 px-[15px] text-2xl font-bold"
            :style="{
              color: pointsTextColor,
              backgroundColor: pointsBgColor,
            }"
          >
            {{ formatPoints(points) }} PTS
          </span>
        </div>
        <div
          v-if="info?.title || info?.text"
          class="max-w-[500px] space-y-[5px] font-bold"
          :style="{
            color: infoTextColor,
          }"
        >
          <div
            v-if="info.title"
            class="text-xl xs:text-2xl"
            v-html="info.title"
          />
          <div v-if="info.text" v-html="info.text" />
        </div>
        <div>
          <base-action
            v-if="bannerButton"
            v-bind="bannerButton"
            class="block xs:inline-block"
            origin="banner"
            appearance="link"
          >
            <button
              type="button"
              class="button flex h-11 w-full items-center justify-center rounded px-[35px] font-bold xs:w-auto"
              :style="{
                backgroundColor: buttonBgColor,
                color: buttonTextColor,
              }"
            >
              <div class="flex items-center">
                {{ bannerButton.label }}
                <base-icon class="ml-5" svg="plain/chevron-right" size="sm" />
              </div>
            </button>
          </base-action>
        </div>
      </div>
    </div>
  </div>
</template>
