<script>
import DrawerLoyaltyCardDetails from '/~/drawer/loyalty-cards/loyalty-details.vue'

export default {
  name: 'profile-loyalty-card-details',
  components: {
    DrawerLoyaltyCardDetails,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    toCards() {
      this.$router.replace({ hash: '#profile-loyalty-memberships' })
    },
    onEdit(id) {
      this.$router.push({ hash: `#profile-loyalty-card-edit/${id}` })
    },
  },
}
</script>

<template>
  <drawer-loyalty-card-details
    :id="id"
    @delete="toCards"
    @edit="onEdit"
    @missed="toCards"
  />
</template>
