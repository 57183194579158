<script>
import ui from '/~/core/ui'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useUser } from '/~/composables/user'
import WgtBgText from '../wgt/wgt-bg-text.vue'
import WgtImage from '../wgt/wgt-image.vue'

export default {
  name: 'widget-australiapost-banner',
  components: {
    BaseAction,
    BaseIcon,
    WgtImage,
    WgtBgText,
  },
  props: {
    bannerButton: {
      type: Object,
      default: () => ({}),
    },
    buttonIcon: {
      type: Boolean,
      default: true,
    },
    image: {
      type: Object,
      default: () => ({}),
    },
    welcomeText: {
      type: Object,
      default: () => ({}),
    },
    welcomeTitleColor: {
      type: String,
      default: '',
    },
    welcomeTitleBgColor: {
      type: String,
      default: '',
    },
    welcomeTextColor: {
      type: String,
      default: '',
    },
    welcomeTextBgColor: {
      type: String,
      default: '',
    },
    buttonColor: {
      type: String,
      default: '',
    },
    buttonTextColor: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { user } = useUser()

    return {
      user,
      ui,
    }
  },
  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.buttonColor,
        color: this.buttonTextColor,
      }
    },
  },
}
</script>

<template>
  <div
    class="relative -mx-5 block overflow-hidden xs:flex xs:h-96 xs:rounded-xl sm:mx-auto sm:w-full sm:shadow"
  >
    <wgt-image :image="image" class="top-0 right-0 h-full w-auto xs:absolute" />
    <img
      :src="'/images/australiapost/home-banner-bg-mobile.svg'"
      class="absolute top-0 left-0 hidden h-full w-auto xs:block md:mr-0"
    />
    <img
      :src="'/images/australiapost/home-banner-bg.svg'"
      class="absolute top-0 left-0 hidden h-full w-auto md:mr-0 md:block"
    />
    <div
      class="absolute top-0 left-0 flex h-full w-full flex-col justify-center p-5 xs:justify-start xs:py-12 xs:px-[30px] sm:px-12"
    >
      <div class="text-2xl font-extrabold sm:text-3xl md:text-3xl">
        <span
          :style="{
            color: welcomeTitleColor,
            backgroundColor: ui.getByScreenSize({
              base: welcomeTitleBgColor,
              xs: '',
            }),
          }"
          class="mb-2.5 inline-block px-[5px] py-[5px] leading-none xs:mb-5"
        >
          Welcome {{ user.firstName }},
        </span>
      </div>
      <div class="mt-5 text-xl font-semibold xs:text-2xl md:text-3xl">
        <wgt-bg-text
          :content="welcomeText.title"
          :text-color="welcomeTextColor"
          :background-color="welcomeTextBgColor"
        />
      </div>
      <div>
        <base-action
          v-if="bannerButton.enabled"
          v-bind="bannerButton"
          class="inline-block"
          origin="banner"
          appearance="link"
        >
          <button
            type="button"
            class="button mt-[30px] flex h-12 items-center rounded px-5 font-bold"
            :style="buttonStyle"
          >
            <span v-html="bannerButton.label" />
            <base-icon
              v-if="buttonIcon"
              class="ml-5"
              svg="plain/chevron-right"
              size="sm"
            />
          </button>
        </base-action>
      </div>
    </div>
  </div>
</template>
