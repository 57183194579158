import get from 'lodash-es/get'
import { RecroomAward } from './award'
import api from '/~rec/core/api'

export class RecroomMemberAward extends RecroomAward {
  constructor(raw = {}) {
    super(raw.award)
    this.rawData = raw || {}
  }

  get totalNominations() {
    return this.rawData.total_nominations
  }

  get prize() {
    return get(this.rawData, 'prize_earned', 0)
  }

  get id() {
    return this.rawData.id
  }

  get currentAwardId() {
    return this.rawData.award_id
  }

  get nominateLimitLabel() {
    return null
  }

  get periodId() {
    return this.rawData.period_id
  }

  static async fetchById(id) {
    const response = await api.get(`/gamification/member-awards/${id}`)

    return new RecroomMemberAward(response.data)
  }
}
