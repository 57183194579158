<script setup lang="ts">
import ui from '/~/core/ui'
import WidgetTilesOptusContent from './widget-tiles-optus-content.vue'
import { Row, Wrapper, useWrapper } from '/~/configurator/composables/wrapper'
import { computed } from 'vue'
import { Tile } from '/~/composables/cms/use-cms'

const props = defineProps<{
  row?: Row
  wrapper?: Wrapper
  items: Tile[]
}>()

const { getTileKey } = useWrapper(props)

const gridClasses = computed(() => {
  let grid = ''

  if (ui.width >= 1124) {
    grid = 'grid-cols-3'
  } else if (ui.width < 1124 && ui.width > 700) {
    grid = 'grid-cols-2'
  } else if (ui.width <= 700) {
    grid = 'grid-cols-1'
  }

  return grid
})
</script>

<template>
  <section class="grid gap-6" :class="gridClasses">
    <div
      v-for="(tile, idx) in items"
      :key="getTileKey(tile, idx)"
      class="w-full overflow-hidden bg-light shadow"
    >
      <widget-tiles-optus-content :tile="tile" />
    </div>
  </section>
</template>
