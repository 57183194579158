<script>
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'

export default {
  name: 'ew-catalog-select',
  components: {
    BasePlaceholder,
    BaseCheckbox,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    filters: {
      type: [Object, Array],
      default: () => ({}),
    },
    scrollToId: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filtersState: this.value,
      expanded: true,
    }
  },
  watch: {
    filtersState: {
      handler(filtersState) {
        this.$emit('input', filtersState)
      },
      deep: true,
    },
    value: {
      handler() {
        this.filtersState = this.value
      },
      deep: true,
    },
  },
  methods: {
    clearFilter() {
      this.filtersState = []
    },
    toggleExpand() {
      this.expanded = !this.expanded
    },
  },
}
</script>

<template>
  <div class="flex grow flex-col overflow-hidden border-t">
    <div
      class="flex h-10 shrink-0 items-center justify-between border-b bg-dark px-5"
    >
      <span class="font-bold">
        {{ title }}
      </span>
      <button
        v-if="filtersState && filtersState.length > 0"
        class="text-sm font-bold leading-none text-primary hover:underline"
        @click="clearFilter"
      >
        Clear
      </button>
    </div>
    <div v-if="loading" class="p-5">
      <base-placeholder :rows="3" type="text" theme="dark" />
    </div>
    <div
      v-show="!loading && filters.length"
      class="space-y-2.5 overflow-y-auto p-5"
    >
      <base-checkbox
        v-for="(item, key) in filters"
        :key="`_${key}`"
        v-model="filtersState"
        :value="item.id"
      >
        {{ item.label }}
      </base-checkbox>
    </div>
  </div>
</template>
