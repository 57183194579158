<script>
import DetailsViewArticle from '/~/extensions/health/components/details/details-view-article.vue'
import { useArticles } from '/~/extensions/health/composables'
import { computed } from 'vue'

export default {
  name: 'health-lifestyle-library-detail',
  components: {
    DetailsViewArticle,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  setup() {
    const { queryState } = useArticles()

    const backRoute = computed(() => {
      return {
        name: 'hlth-lifestyle-library-feed',
        query: queryState.value,
      }
    })

    return {
      backRoute,
    }
  },
}
</script>

<template>
  <details-view-article
    :id="id"
    :back-route="backRoute"
    tile-route-name="hlth-lifestyle-library-detail"
  />
</template>
