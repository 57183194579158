<script>
import debounce from 'lodash-es/debounce'
import omitBy from 'lodash-es/omitBy'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import FeedControls from '/~/extensions/health/components/feed/feed-controls.vue'
import FeedView from '/~/extensions/health/components/feed/feed-view.vue'
import Tabs from '/~/extensions/health/components/tabs.vue'
import { useRecipes } from '/~/extensions/health/composables'
import { useEditablePage } from '/~/composables/cms'
import HealthEbitsFeedList from './health-ebits-feed-list.vue'

export default {
  name: 'health-ebits-feed',
  components: {
    FeedView,
    FeedControls,
    HealthEbitsFeedList,
    Tabs,
  },
  setup() {
    const route = useRoute()
    const { saved, feed, queryState } = useRecipes()
    const { page } = useEditablePage()

    const filters = ref(null)
    const type = computed(() => route.query?.type ?? 'recipes')
    const search = ref(route.query?.search ?? '')
    const category = ref(route.query?.category ?? 'all')
    const params = computed(() => {
      return { search: search.value, category: category.value }
    })

    return {
      page,
      filters,
      type,
      search,
      category,
      params,
      saved,
      feed,
      queryState,
    }
  },
  data() {
    return {
      categories: [
        { text: 'All Categories', value: 'all' },
        { text: 'Meals', value: 'meals' },
        { text: 'Breakfast', value: 'breakfast' },
        { text: 'Dessert', value: 'dessert' },
        { text: 'Salads', value: 'salads' },
      ],
      tabs: [
        {
          label: 'Recipes',
          route: { path: 'health-ebits', query: { type: 'recipes' } },
        },
        {
          label: 'Saved recipes',
          route: { path: 'health-ebits', query: { type: 'saved' } },
        },
      ],
    }
  },
  watch: {
    type: {
      handler() {
        this.feed.reset()
        this.search = ''
        this.category = ''
        this.filters.resetCategory()
        this.filters.search = ''
        this.getData()
      },
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.feed.reset()

      await this.feed.getData({
        search: {
          filters: [
            this.type === 'saved'
              ? this.saved.map((id) => 'objectID:' + id).join(' OR ') ||
                'objectID:null'
              : '',
            this.category && this.category !== 'all'
              ? `Category:${this.category}`
              : '',
          ]
            .filter((i) => !!i)
            .join(' AND '),
          query: this.search,
        },
      })
    },
    onSearch: debounce(function (search) {
      this.search = search
      this.getData()
      this.setRoute()
    }, 400),
    changeCategory(category) {
      this.category = category
      this.getData()
      this.setRoute()
    },
    setRoute() {
      const query = omitBy(
        { search: this.search, category: this.category, type: this.type },
        (v) => !v
      )

      this.$router.replace({ query })
      this.queryState = query
    },
  },
}
</script>

<template>
  <div class="mx-auto max-w-7xl">
    <feed-view>
      <template #hero>
        <tabs :tabs="tabs" :type="type" class="sm:hidden" />

        <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
      </template>

      <template #tabs>
        <tabs :tabs="tabs" :type="type" class="hidden sm:block" />
      </template>

      <template #controls>
        <feed-controls
          ref="filters"
          :categories="categories"
          :params="params"
          placeholder="Search for a recipe..."
          @input="onSearch"
          @category-update="changeCategory"
        />
      </template>

      <health-ebits-feed-list :type="type" :feed="feed" />
    </feed-view>
  </div>
</template>
