<script>
import { mixin as clickaway } from 'vue-clickaway'
import ui from '/~/core/ui'
import CreatorFile from '/~rec/components/post/creator/attachments/creator-file.vue'
import CreatorGif from '/~rec/components/post/creator/attachments/creator-gif.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseEmojis from '/~/components/base/emojis/base-emojis.vue'
import BaseGifs from '/~/components/base/gifs/base-gifs.vue'
import InputMixin from '/~/components/base/input/input.mixin'
import Popup from '/~/components/base/select/popup'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import BaseUploader from '/~/components/base/uploader/base-uploader.vue'
import RichAttachmentsPopup from './rich-attachments-popup.vue'
import RichInputMentions from './rich-input-mentions.vue'

const androidMentions = new RegExp(/^@([\s\S]+?)#\w{5}#| @([\s\S]+?)#\w{5}#/g)

export default {
  name: 'rich-input',
  components: {
    RichAttachmentsPopup,
    RichInputMentions,
    BaseUploader,
    CreatorFile,
    CreatorGif,
    BaseEmojis,
    BaseGifs,
    Popup,
    BaseButton,
    BaseTextarea,
  },
  mixins: [InputMixin, clickaway],
  props: {
    content: {
      type: String,
      default: '',
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Number,
      default: 1,
    },
    maxRows: {
      type: Number,
      default: 6,
    },
    lineHeight: {
      type: Number,
      default: 20,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    attachmentsPosition: {
      type: String,
      default: 'bottom',
      validator: (v) => /top|bottom|popup/.test(v),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableSend: {
      type: Boolean,
      default: false,
    },
    mentionUsers: {
      type: Array,
      default: null,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
    centerActions: {
      type: Boolean,
      default: false,
    },
    isAndriod: {
      type: Boolean,
      default: false,
    },
    mentioned: {
      type: Array,
      default: () => [],
    },
    isOffensiveWarningShow: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ...clickaway.setup?.(...arguments),
      ...InputMixin.setup?.(...arguments),
      ui,
    }
  },
  data() {
    return {
      cursorCurrentNode: null,
      multiline: false,
      mention: null,
      cleanContent: null,
      contentLength: this.content.length,
      oldContent: this.content,
      inputType: null,
    }
  },
  computed: {
    files() {
      return this.attachments.filter((a) => a.type !== 'image/gif')
    },
    gifs() {
      return this.attachments.filter((a) => a.type === 'image/gif')
    },
    isSendDisabled() {
      return this.loading || this.disableSend || this.disabled
    },
    isShowPlaceholder() {
      return this.plain || (!this.plain && !this.content && !this.isFocused)
    },
    getPlaceholderStyle() {
      if (this.plain && (this.isFocused || this.content)) {
        return ui.desktop ? 'top:-15px' : 'top: -13px'
      }
      return null
    },
    firstError() {
      return this.validationProviderRef?.errors[0] || this.error
    },
  },
  watch: {
    content(val) {
      if (this.isAndriod) return
      if (val !== this.$refs.input.innerHTML) {
        this.$refs.input.innerHTML = this.content
        this.setCursorToEnd()
      }

      if (val) {
        this.parseMentions()
      }

      this.cleanContent = this.$refs.input.innerText

      this.validate(this.cleanContent)
      this.$emit('input')

      this.multiline = this.$refs.input.clientHeight > this.lineHeight + 20
    },
  },
  mounted() {
    if (this.content && !this.isAndriod) {
      this.$refs.input.innerHTML = this.content
      this.setCursorToEnd()
    }

    if (this.focused) this.focus()
  },
  methods: {
    setCursorToEnd() {
      let range, selection

      if (document.createRange) {
        range = document.createRange()
        range.selectNodeContents(this.$refs.input)
        range.collapse(false)
        selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
      }
    },
    focus() {
      if (this.isAndriod) return
      this.onFocus()
      this.$refs.input.focus()
      this.saveSelection()
    },
    onAttachClick() {
      this.$refs.attach.show()
    },
    onCameraClick() {
      this.$refs.camera.show()
    },
    onEmojisClick() {
      this.$refs.emojis.show()
    },
    onGifsClick() {
      this.$refs.gifs.show()
    },
    onAddAttachment(attachment) {
      this.$emit('update:attachments', [...this.attachments, attachment])
      this.focus()
    },
    onDeleteAttachment(attachment) {
      const attachments = this.attachments.filter((a) => a !== attachment)

      this.$emit('update:attachments', attachments)

      if (!attachments.length && this.$refs.attachments)
        this.$refs.attachments.close()

      return attachments
    },
    saveSelection() {
      const selection = window.getSelection()

      this.cursorCurrentNode = {
        anchorOffset: selection.anchorOffset,
        rangeCount: selection.rangeCount,
        anchorNode: selection.anchorNode,
        range: selection.getRangeAt(0),
        selection,
      }
    },
    onKeyDown(e) {
      if (this.loading) {
        e.preventDefault()
      }

      if (this.mention && [38, 40, 13].includes(e.keyCode)) {
        return e.preventDefault()
      }

      if (e.keyCode === 13) {
        if (e.shiftKey || e.ctrlKey) {
          if (e.ctrlKey) {
            document.execCommand('insertHTML', false, '\n')
          }

          this.multiline = true
        } else {
          e.preventDefault()
        }
      }
    },
    updateContent() {
      let content = this.$refs.input.innerHTML.replace(/&nbsp;/g, ' ')

      if (content === '\n') content = ''
      this.$emit('update:content', content)
    },

    onKeyUp(e) {
      this.saveSelection()
      this.detectMention()
      this.updateContent()

      if (e.keyCode === 13 && !e.shiftKey && !e.ctrlKey && !this.mention) {
        return this.onSend()
      }
    },
    matchMentionRegExp(text) {
      const mentionRegExp = this.isAndriod
        ? new RegExp(
            /(?:^|[^a-zA-Z0-9_＠!@#$%&*])(?:(?:@|＠)(?!\/))([a-zA-Z0-9/_]{1,15})(?:\b(?!@|＠)|$)$/
          )
        : new RegExp(
            /(?:^|[^a-zA-Z0-9_＠!@#$%&*])(?:(?:@|＠)(?!\/))([a-zA-Z0-9/_]{1,15})(?:\b(?!@|＠)|$)/
          )

      return text.match(mentionRegExp)
    },
    checkSpaceText(text) {
      return /\s/.test(text)
    },
    detectMention() {
      if (!this.mentionUsers || !this.mentionUsers.length) return

      const { selection } = this.cursorCurrentNode

      const currentNodeText = selection.focusNode && selection.focusNode.data

      const cursorPosition = selection.anchorOffset
      const textUntilCursor =
        currentNodeText && currentNodeText.substring(0, cursorPosition)

      if (!textUntilCursor) return

      this.checkMentions(textUntilCursor)
    },
    onPaste(e) {
      e.preventDefault()
      let text = ''

      if (e.clipboardData && e.clipboardData.getData) {
        text = e.clipboardData.getData('text/plain')
      } else if (window.clipboardData && window.clipboardData.getData) {
        text = window.clipboardData.getData('Text')
      }

      document.execCommand('insertHTML', false, text)
    },
    onAwayClick() {
      if (this.mention) this.mention = null
    },
    onEmojiSelect(emoji) {
      if (this.cursorCurrentNode) {
        const emojiTextNode = document.createTextNode(emoji)
        const { range, selection } = this.cursorCurrentNode

        range.insertNode(emojiTextNode)
        range.setEndAfter(emojiTextNode)
        range.collapse(false)
        selection.removeAllRanges()
        selection.addRange(range)
      } else {
        this.$refs.input.innerHTML += emoji
        this.$refs.input.focus()
      }

      this.$emit('update:content', this.$refs.input.innerHTML)
    },
    onSend() {
      if (this.isSendDisabled) return
      this.$emit('send')
    },
    onSelectMention(user) {
      if (this.isAndriod) {
        this.onSelectMentionAndroid(user)
        return
      }
      const { anchorNode, range, selection } = this.cursorCurrentNode

      if (anchorNode.textContent !== `${this.mention}`) {
        const result = this.matchMentionRegExp(anchorNode.textContent)
        const addIdx = this.checkSpaceText(result[0]) ? 1 : 0

        anchorNode.deleteData(result.index + addIdx, result[0].length - addIdx)
      } else anchorNode.remove()

      const taggedText = document.createTextNode(`@${user.name}`)
      const taggedSpan = document.createElement('span')

      taggedSpan.setAttribute('data-user-id', user.id)
      taggedSpan.setAttribute('contenteditable', false)
      taggedSpan.className = 'text-blue-500 font-bold'

      taggedSpan.appendChild(taggedText)

      range.insertNode(document.createTextNode(' '))
      range.insertNode(taggedSpan)
      range.collapse(false)

      selection.removeAllRanges()
      selection.addRange(range)

      this.updateContent()
      this.mention = null
    },
    parseMentions() {
      const result = []

      if (this.content) {
        const div = document.createElement('div')

        div.innerHTML = this.content

        for (const elem of div.querySelectorAll('span')) {
          const userId = elem.getAttribute('data-user-id')
          const mentionUser = this.mentionUsers.find((u) => u.id === userId)

          if (mentionUser) {
            result.push(mentionUser.raw)
          }
        }
      }

      this.$emit('update:mentioned', result)
    },

    checkMentions(value) {
      const result = this.matchMentionRegExp(value)

      this.mention =
        result &&
        !this.checkSpaceText(result.input[result.index + result[0].length])
          ? result[1]
          : null
    },

    getCaretPos(el) {
      el.focus()
      if (el.selectionStart) return el.selectionStart
      if (document.selection) {
        const sel = document.selection.createRange()
        const clone = sel.duplicate()

        sel.collapse(true)
        clone.moveToElementText(el)
        clone.setEndPoint('EndToEnd', sel)
        return clone.text.length
      }
      return 0
    },

    deleteMentitionFromAka(caretPos, content, mentioned) {
      let tryDeleteMention = ''
      let check = false

      const findDeletePosition = (content) => {
        let search = true
        let position = 0
        let currentPosition = 0

        while (search) {
          position = content.indexOf(tryDeleteMention.slice(1), currentPosition)

          if (position === caretPos) {
            search = false
          }
          currentPosition = position + 1
        }
        return position
      }

      for (let i = 0; i <= content.length; i++) {
        if (check || this.oldContent[i] !== content[i]) {
          check = true
          tryDeleteMention += this.oldContent[i]
          if (mentioned.find((i) => i.name === tryDeleteMention.slice(1))) {
            tryDeleteMention += this.oldContent.slice(i + 1, i + 7)
            break
          }
        }
      }

      if (tryDeleteMention && /@([\s\S]+?)/.test(tryDeleteMention)) {
        const position = findDeletePosition(content)

        const firstPart = content.split('').splice(0, position).join('')
        const secondPart = content
          .split('')
          .splice(position + tryDeleteMention.slice(1).length + 1)
          .join('')
          .trim()
        const newContent = firstPart + secondPart

        this.$emit('update:mentioned', {
          add: false,
          user: mentioned.find((m) => m.nanoId === tryDeleteMention.slice(-5)),
        })

        return newContent
      }
      return content
    },

    deleteMenthionAndriod(content, caretPos) {
      let newContent = content
      const allMentions = [...this.oldContent.matchAll(androidMentions)].map(
        (item) => ({
          mention: item[0].slice(0, -1).trim(),
          index: item.index,
          nanoId: item[0].slice(0, -1).trim().slice(-5),
        })
      )

      const mentioned = this.mentioned.map((item) => {
        if (item.name) {
          return {
            name: item.name,
            id: item.id,
            nanoId: item.id.slice(0, 5),
          }
        }
        const { first_name: firstName, last_name: lastName, id } = item

        return {
          name: `${firstName} ${lastName}`,
          id,
          nanoId: id.slice(0, 5),
        }
      })

      let stringDiff = ''

      for (let i = 0; i <= newContent.length; i++) {
        if (this.oldContent[i] !== newContent[i]) {
          stringDiff = this.oldContent[i]
          break
        }
      }

      if (stringDiff === '@') {
        allMentions.forEach((item) => {
          const mayBeMention = newContent.slice(
            caretPos,
            caretPos + item.mention.length + 1
          )

          if (
            item.mention.slice(1) === mayBeMention.trim().slice(0, -1) &&
            item.index + 1 >= caretPos
          ) {
            newContent = this.deleteMentitionFromAka(
              caretPos,
              content,
              mentioned
            )
          }
        })

        return newContent
      }
      const tryDeleteMention = allMentions.find(
        (m) => m.index < caretPos && m.index + m.mention.length + 2 >= caretPos
      )

      if (
        tryDeleteMention &&
        newContent
          .slice(tryDeleteMention, caretPos)
          .includes(`${tryDeleteMention.mention}# `)
      ) {
        return newContent
      }

      if (tryDeleteMention) {
        const mentionToDelete = mentioned.find(
          (m) => m.nanoId === tryDeleteMention.nanoId
        )
        const firstPart = this.oldContent
          .split('')
          .splice(0, tryDeleteMention.index)
          .join('')
        const secondPart = this.oldContent
          .split('')
          .splice(tryDeleteMention.index + tryDeleteMention.mention.length + 2)
          .join('')

        newContent = firstPart + secondPart

        this.$emit('update:mentioned', {
          add: false,
          user: mentionToDelete,
        })
      }

      return newContent
    },

    onInputTextarea(value) {
      if (this.isOffensiveWarningShow) return
      if (value.inputType === 'deleteContentBackward') {
        this.inputType = value.inputType
      }

      if (
        value.inputType === 'insertCompositionText' &&
        !value.data &&
        value.isComposing &&
        this.inputType
      ) {
        this.inputType = null
      }

      let caretPos = 0

      if (this.$refs.input) {
        caretPos = this.getCaretPos(this.$refs.input)
      }

      if (
        ((value.inputType === 'insertText' && !value.data) ||
          value.inputType === 'insertLineBreak' ||
          (value.inputType === 'insertCompositionText' &&
            !value.data &&
            !value.isComposing &&
            this.inputType) ||
          (value.inputType === 'insertCompositionText' &&
            !value.data &&
            value.isComposing &&
            value.target.value.slice(-1) !== ' ' &&
            value.target.value)) &&
        !this.mention
      ) {
        this.onSend()
        return
      }
      if (
        this.mention &&
        (value.inputType === 'insertLineBreak' ||
          (value.inputType === 'insertText' && !value.data))
      ) {
        return
      }

      let content = value.target.value || ''

      if (this.oldContent.length > content.length) {
        content = this.deleteMenthionAndriod(content, caretPos)
      }

      if (this.mentionUsers || this.mentionUsers.length) {
        this.checkMentions(content.slice(0, caretPos))
      }

      this.oldContent = content

      if (!content.length) {
        this.$emit('update:mentioned', {
          add: false,
          user: null,
        })
      }

      this.validate(content)
      this.$emit('input', content)
    },

    onSelectMentionAndroid(user) {
      const nanoId = user.id.slice(0, 5)
      const newContent = this.content.replace(
        `@${this.mention}`,
        ` @${user.name}#${nanoId}# `
      )

      this.$emit('update:mentioned', {
        user: {
          ...user,
          nanoId,
        },
        add: true,
      })

      this.validate(newContent)
      this.$emit('input', newContent)
      this.oldContent = newContent
      this.mention = null
    },
    onClick() {
      this.$refs?.input?.focus()
      this.saveSelection()
    },
  },
}
</script>

<template>
  <validation-provider
    v-bind="validation"
    ref="validationProviderRef"
    :detect-input="false"
    slim
  >
    <div
      v-on-clickaway="onAwayClick"
      class="relative flex w-full px-[5px]"
      :class="{
        'flex-col-reverse': attachmentsPosition === 'top',
        'flex-col': attachmentsPosition !== 'top',
      }"
    >
      <div v-if="mentionUsers" class="relative">
        <popup :visible="!!mention">
          <rich-input-mentions
            :filter="mention"
            :users="mentionUsers"
            @select="onSelectMention"
          />
        </popup>
      </div>

      <div
        class="flex w-full flex-wrap items-center p-[5px] pl-0"
        :class="{
          'rounded-3xl border pl-[15px]': !plain,
          'border-b': isPost && !isAndriod,
          'pointer-events-none opacity-50': disabled,
          'border-primary': isFocused && !firstError,
          'border-error-700': firstError,
        }"
      >
        <div
          class="relative mr-2.5 flex w-full max-w-full flex-auto cursor-text flex-col justify-center leading-5 sm:w-auto"
          :class="{
            'w-full': multiline,
          }"
          @click="onClick"
        >
          <div
            v-if="!isAndriod"
            :id="id"
            ref="input"
            contenteditable="true"
            :style="{
              maxHeight: `${maxRows * lineHeight}px`,
              wordBreak: 'break-word',
            }"
            class="relative z-1 inline-block min-w-[5px] overflow-y-auto whitespace-pre-wrap py-0.5 px-px text-base outline-none"
            :class="{
              'mt-[15px]': isPost,
            }"
            v-on="$listeners"
            @keydown="onKeyDown"
            @keyup="onKeyUp"
            @paste="onPaste"
            @focus="onFocus"
            @blur="onBlur"
          />
          <base-textarea
            v-if="isAndriod"
            :id="id"
            ref="textarea"
            :value="content"
            :label="placeholder"
            :focused="focused"
            :error="firstError"
            :maxlength="maxLength"
            :disabled="disabled"
            look="simple"
            @input="onInputTextarea"
          />
          <div
            v-if="!isPost && !content && !isFocused && !isAndriod"
            class="z-back absolute top-0 left-0 px-[15px] py-2.5 text-sm text-eonx-neutral-600"
            style="z-index: -1"
          >
            {{ placeholder }}
          </div>
          <div
            v-if="isShowPlaceholder && isPost && !isAndriod"
            class="z-back absolute top-0 left-0 px-[15px] py-2.5 text-sm transition-all duration-100"
            :class="{
              'text-eonx-neutral-600': !isFocused && !firstError,
              'text-error-700': !(!isFocused && !firstError),
              'pl-0 pb-0': plain,
              'font-bold text-primary':
                plain && (isFocused || content) && !firstError,
              'font-bold': firstError,
            }"
            :style="getPlaceholderStyle"
          >
            {{ placeholder }}
          </div>
        </div>

        <div
          v-if="!isPost"
          class="flex items-center"
          :class="{
            'mx-auto justify-center': centerActions,
            'ml-auto mr-[15px] justify-end': !centerActions,
            'mt-2.5 sm:mt-0': isAndriod,
          }"
          :style="{
            minHeight: `${lineHeight}px`,
          }"
        >
          <div class="flex self-center text-primary">
            <base-button icon="rec/camera" size="md" @click="onCameraClick" />
            <base-uploader ref="camera" camera @upload="onAddAttachment" />
          </div>
          <div
            v-if="!isAndriod"
            class="relative ml-[15px] flex self-center text-primary"
          >
            <base-button
              icon="rec/happy-face"
              size="md"
              @click="onEmojisClick"
            />
            <base-emojis ref="emojis" @select="onEmojiSelect" />
          </div>
          <div class="ml-[15px] flex self-center text-primary">
            <base-button icon="rec/gif" size="lg" @click="onGifsClick" />
            <base-gifs ref="gifs" @select="onAddAttachment" />
          </div>
          <div class="ml-[15px] flex self-center text-primary">
            <base-button
              icon="rec/attachment"
              size="md"
              @click="onAttachClick"
            />
            <base-uploader ref="attach" multiple @upload="onAddAttachment" />
          </div>
          <div class="ml-[15px] flex self-center text-primary">
            <base-button
              data-test="chat-input-send"
              :class="{
                'cursor-default text-disabled': isSendDisabled,
              }"
              :disabled="isSendDisabled"
              :loading="loading"
              icon="rec/paper-plane"
              size="lg"
              @click="onSend"
            />
          </div>
        </div>
      </div>
      <div
        v-if="firstError && !isAndriod"
        class="mt-[5px] px-[15px] text-xs font-bold text-error-700"
        :class="{
          'pl-0': isPost,
        }"
      >
        {{ firstError }}
      </div>
      <div
        v-if="maxLength && !isAndriod"
        class="mt-[5px] grow text-right text-sm text-eonx-neutral-600"
      >
        {{ content.length }} / {{ maxLength }}
      </div>

      <template v-if="attachments.length">
        <template v-if="attachmentsPosition === 'popup'">
          <rich-attachments-popup
            ref="attachments"
            :attachments="attachments"
            @delete="onDeleteAttachment"
          />
          <b
            data-test="chat-input-attachments-button"
            class="block cursor-pointer px-[15px] pb-[5px] text-sm text-primary"
            @click="$refs.attachments.show()"
          >
            {{ attachments.length }}
            {{ attachments.length === 1 ? 'attachment' : 'attachments' }}
          </b>
        </template>

        <div
          v-else
          :class="{
            '-mb-2.5': attachmentsPosition === 'bottom',
            '-mt-2.5': attachmentsPosition === 'top',
          }"
        >
          <div v-if="files.length" class="-mx-2.5 flex flex-wrap">
            <creator-file
              v-for="(file, idx) in files"
              :key="idx"
              :attachment="file"
              removable
              class="m-2.5"
              @delete="onDeleteAttachment"
            />
          </div>
          <div v-if="gifs.length" class="-mx-2.5 flex flex-wrap">
            <creator-gif
              v-for="(gif, idx) in gifs"
              :key="idx"
              :gif="gif"
              removable
              class="m-2.5"
              @delete="onDeleteAttachment"
            />
          </div>
        </div>
      </template>
    </div>
  </validation-provider>
</template>
