<script setup lang="ts">
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import VerificationCode from '/~/extensions/otp/components/verification-code/verification-code.vue'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    type?: 'account' | 'mobile' | 'email' | null
  }>(),
  {
    type: 'account',
  }
)

const title = computed(() => `Verify ${props.type}`)
</script>

<template>
  <base-aside-page :title="title" no-padding>
    <verification-code :type="type" />
  </base-aside-page>
</template>
