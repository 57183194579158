<script>
import { useLocalization } from '/~/composables/localization'

export default {
  name: 'profile-direct-debit-toc',
  setup() {
    const { translate } = useLocalization()
    const directDebitsTitleLowercased =
      translate('directDebit.text').toLowerCase()

    return {
      directDebitsTitleLowercased,
    }
  },
}
</script>

<template>
  <base-aside-page title="Terms & Conditions">
    <article class="markup">
      <h2>EonX</h2>
      <h4>
        {{ translate('directDebit.text') }} Request Agreement 26 Feb 2020.
      </h4>

      <h4>Acknowledgment</h4>
      <p>
        By registering your details on this platform, you herby agree to the
        Terms and Conditions of this
        {{ translate('directDebit.text') }} Request Agreement. You acknowledge
        having read and understood the Terms and Conditions governing the debit
        arrangements between you and us.
      </p>

      <h4>Terms and Conditions</h4>

      <h4>Definitions</h4>
      <p>
        In these Terms and Conditions, the following terms have the following
        meanings:
      </p>
      <p>
        <b>account</b>
        means the account held at your financial institution from which we are
        authorised to arrange for funds to be debited.
      </p>
      <p>
        <b>agreement</b>
        means this {{ translate('directDebit.text') }} Request Service Agreement
        which is between you and us and subject to the Terms and Conditions.
      </p>
      <p>
        <b>business day</b>
        means a day other than a Saturday or a Sunday or a public holiday listed
        throughout Australia.
      </p>
      <p>
        <b>debit day</b>
        means the day that payment by you to us is due.
      </p>
      <p>
        <b>{{ directDebitsTitleLowercased }}</b>
        request means the {{ translate('directDebit.text') }} Request between us
        and you.
      </p>
      <p>
        <b>us</b>
        or
        <b>we</b>
        means EonX Services Pty Ltd (ABN 39 615 958 873), the entity whom you
        have authorised by agreeing these Terms and Conditions.
      </p>
      <p>
        <b>you</b>
        means the customer who has authorised the
        {{ translate('directDebit.text') }} Request Agreement.
      </p>
      <p>
        <b>your financial institution</b>
        is the financial institution where you hold the account that you have
        authorised us to arrange to debit.
      </p>

      <ol>
        <li>
          <b>1. Debiting your account</b>
          <ol>
            <li>
              1.1 By signing a
              {{ directDebitsTitleLowercased }} request or by providing us with
              a valid instruction, you have authorised us to arrange for funds
              to be debited from your account. You should refer to the
              {{ directDebitsTitleLowercased }}
              request and this agreement for the terms of the arrangement
              between us and you.
            </li>
            <li>
              1.2 We will only arrange for funds to be debited from your account
              as authorised in the
              {{ directDebitsTitleLowercased }} request.
            </li>
            <li>
              1.3 If the debit day falls on a day that is not a business day, we
              may direct your financial institution to debit your account on the
              previous business day. If you are unsure about which day your
              account has or will be debited you should ask your financial
              institution.
            </li>
          </ol>
        </li>

        <li>
          <b>2. Changes by us</b>
          <ol>
            <li>
              2.1 We may vary any details of this agreement or a
              {{ directDebitsTitleLowercased }}
              request at any time by giving you at least fourteen (14) days’
              written notice.
            </li>
          </ol>
        </li>

        <li>
          <b>3. Changes by you</b>
          <ol>
            <li>
              3.1 You may change the arrangements under a
              {{ directDebitsTitleLowercased }} request in the manner set out in
              3.2 and 3.3 below.
            </li>
            <li>
              3.2 If you wish to stop a debit payment you must notify us via
              telephone on XXXXXXX and in writing by email to XXXXX, at least
              fourteen (14) full business days before the value date of the
              transaction between you and us, and you must at or before such
              notification obtain our written agreement to an alternative means
              of payment by you. This notice should be given to us in the first
              instance.
            </li>
            <li>
              3.3 You may also cancel your authority for us to debit your
              account at any time by giving us notice via telephone and in
              writing by email, at least fourteen (14) full business days before
              the next debit day. This notice should be given to us in the first
              instance.
            </li>
          </ol>
        </li>

        <li>
          <b>4. Your obligations</b>
          <ol>
            <li>
              4.1 It is your responsibility to ensure that there are sufficient
              clear funds available in your account to allow a debit payment to
              be made in accordance with the
              {{ directDebitsTitleLowercased }} request.
            </li>
            <li>
              4.2 If there are insufficient clear funds in your account to meet
              a debit payment:  (a) you may be charged a fee and/or interest by
              your financial institution;  (b) you may also incur fees or
              charges imposed or incurred by us; and  (c) you must arrange for
              the debit payment to be made by another method or arrange for
              sufficient clear funds to be in your account by an agreed time, so
              that we can process the debit payment.
            </li>
            <li>
              4.3 You must check your account statement to verify that the
              amounts debited from your account are correct
            </li>
          </ol>
        </li>

        <li>
          <b>5. Dispute</b>
          <ol>
            <li>
              5.1 If you believe that there has been an error in debiting your
              account, you should notify us by email at XXXXX as soon as
              possible so that we can resolve your query.
            </li>
            <li>
              5.2 If we conclude as a result of our investigations that your
              account has been incorrectly debited, we will respond to your
              query by arranging for your financial institution to adjust your
              account (including interest and charges) accordingly. We will also
              notify you in writing of the amount by which your account has been
              adjusted.
            </li>
            <li>
              5.3 If we conclude as a result of our investigation that your
              account has not been incorrectly debited, we will respond to your
              query by providing you with reasons and any evidence for this
              finding.
            </li>
            <li>
              5.4 Any queries you may have about an error made in debiting your
              account should be directed to us in the first instance, so that we
              can attempt to resolve the matter between us and you.
            </li>
            <li>
              5.5 If we cannot resolve the matter you can still refer it to your
              financial institution which will obtain details from you of the
              disputed transaction and may lodge a claim on your behalf.
            </li>
          </ol>
        </li>

        <li>
          <b>6. Accounts:</b>
          <ol>
            <li>You should check,</li>
            <li>
              (a) with your financial institution whether
              {{ translate('directDebit.action').toLowerCase() }} is available
              from your account as
              {{ translate('directDebit.action').toLowerCase() }} is not
              available on all accounts offered by financial institutions.
            </li>
            <li>
              (b) your account details which you have provided to us are correct
              by checking them against a recent account statement; and
            </li>
            <li>
              (c) with your financial institution before completing the direct
              debit request if you have any queries about how to complete the
              {{ directDebitsTitleLowercased }} request
            </li>
          </ol>
        </li>

        <li>
          <b>7. Confidentiality</b>
          <ol>
            <li>
              7.1 We will keep the information in your
              {{ directDebitsTitleLowercased }} request form (including your
              account details) confidential. We will make reasonable efforts to
              keep any such information that we have about you secure and make
              reasonable efforts to inform our employees or agents who have
              access to information about you that they are not to make any
              unauthorised use, modification, reproduction or disclosure of that
              information.
            </li>
            <li>
              7.2 We will only disclose information in your
              {{ directDebitsTitleLowercased }}
              request: (a) to the extent specifically required by law; or (b)
              for the purposes of this agreement or any written agreement or
              written arrangement between you and us (including disclosing
              information in connection with any query or claim).
            </li>
          </ol>
        </li>

        <li>
          <b>8. Notice</b>
          <ol>
            <li>
              8.1 If you wish to notify us in writing about anything relating to
              this agreement, you should write to XXXXXXX or email XXXXXX.
            </li>
            <li>
              8.2 You can call us on XXXXXX to discuss any issue you may have
              with this Agreement.
            </li>
          </ol>
        </li>
      </ol>
    </article>
  </base-aside-page>
</template>
