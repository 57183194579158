<script setup lang="ts">
import { ValidationObserver } from 'vee-validate'
import { ref } from 'vue'
import modal from '/~/core/mdl'
import { useServices } from '/~/extensions/services/composables/use-services'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useForm } from '/~/composables/base/use-form'

const { sendLead, selectedService } = useServices()
const { validationObserverRef } = useForm()

const backendErrors = ref({})
const loading = ref(false)
const success = ref(false)

const form = ref({
  mobile: '',
  contact_time: '',
})

const timeOptions = [
  {
    value: '9am-noon',
    text: '9am-noon',
  },
  {
    value: 'noon-3pm',
    text: 'Noon-3pm',
  },
  {
    value: '3pm-6pm',
    text: '3pm-6pm',
  },
  {
    value: '6pm-8pm',
    text: '6pm-8pm',
  },
]

function processBackendErrors(err: any) {
  const { response } = err

  if (response) {
    const { data, status } = response

    if (status === 422) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const firstError = data[key][0]

          data[key] = firstError || ''
        }
      }

      backendErrors.value = data
    } else {
      console.error(err)
    }
  } else {
    console.error(err)
  }
}

async function onSubmit() {
  if (loading.value) {
    return
  }

  loading.value = true
  backendErrors.value = {}

  try {
    await sendLead(form.value)
    success.value = true
  } catch (error) {
    processBackendErrors(error)
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <base-mdl>
    <div class="text-center">
      <div v-if="success" key="success">
        <span class="text-success-700">
          <base-icon svg="plain/check" size="xl" />
        </span>
        <div class="mt-5 text-xl font-bold">Thank you for your request!</div>
        <div class="my-11 px-5">
          One of our specialists will be in touch with you shortly.
        </div>
        <base-button class="w-full sm:w-40" type="submit" @click="modal.hide()">
          Got it
        </base-button>
      </div>
      <div v-else key="alternate">
        <div
          class="mx-auto flex h-20 w-20 items-center justify-center rounded-full border"
        >
          <base-icon svg="plain/mobile" size="md" />
        </div>
        <div v-if="selectedService" class="mt-5 text-xl font-bold">
          {{ selectedService.text }}
        </div>
        <div class="mx-auto my-5 w-xs max-w-full px-2.5 sm:px-5">
          Please provide us with the best contact number and time for us to call
          you!
        </div>
        <validation-observer
          v-slot="{ handleSubmit }"
          ref="validationObserverRef"
          slim
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="my-5 grid sm:my-10 sm:grid-cols-2 sm:gap-5">
              <base-input
                key="mobile'"
                v-model="form.mobile"
                :validation="{
                  rules: 'required|mobile',
                  name: 'Mobile',
                }"
                :masked="true"
                :error="backendErrors.phone"
                :disabled="loading"
                type="tel"
                mask="mobile"
                label="Mobile"
                required
                name="mobile"
              />
              <base-select
                key="time"
                v-model="form.contact_time"
                :validation="{
                  rules: 'required',
                  name: 'Contact Time',
                }"
                :disabled="loading"
                :error="backendErrors.contact_time"
                :options="timeOptions"
                fullwidth-popup
                label="Contact Time"
                required
                name="contact_time"
              />
            </div>
            <base-button
              :disabled="loading"
              :loading="loading"
              class="w-full sm:w-40"
              type="submit"
            >
              Submit
            </base-button>
          </form>
        </validation-observer>
      </div>
    </div>
  </base-mdl>
</template>
