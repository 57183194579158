import { nanoid } from 'nanoid'

export default {
  label: 'Bupa',
  value: 'bupa',
  preset: {
    items: [
      {
        id: nanoid(),
        enabled: true,
        label: 'How it works',
        action: {
          type: 'action',
          value: 'show-faq',
        },
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Help',
        action: {
          type: 'page',
          value: 'help',
        },
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Program Terms',
        action: {
          type: 'page',
          value: 'terms-conditions',
        },
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Privacy',
        action: {
          type: 'page',
          value: 'privacy-policy',
        },
      },
    ],
  },
}
