<script setup>
import BaseBack from '/~/components/base/back/base-back.vue'
import CheckoutCoupons from '/~/components/checkout/checkout-coupons.vue'
import { useProvider } from '/~/composables/provider'
import CheckoutAddresses from '../../components/addresses/checkout-addresses.vue'
import CheckoutPayments from '../../components/payments/checkout-payments.vue'

const { isUserAddressEnabled } = useProvider()
</script>

<template>
  <div class="space-y-6">
    <base-back :to="{ name: 'home' }" />

    <checkout-addresses v-if="isUserAddressEnabled" v-bind="$attrs" />
    <checkout-payments v-bind="$attrs" />
    <checkout-coupons v-bind="$attrs" />
  </div>
</template>
