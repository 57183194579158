<script>
import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomTransaction } from '/~rec/core/transaction'
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import BaseHeader from '/~/components/base/header/base-header'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { formatNumber } from '/~/utils/format/numeric'

export default {
  name: 'rec-token-activities-modal',
  components: {
    RecognitionHeader,
    EwCatalogProcessor,
    BaseHeader,
    BaseMdl,
    BaseLoader,
  },
  props: {
    tokenBalance: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: 'Token',
    },
  },
  setup() {
    return {
      formatNumber,
    }
  },
  data() {
    return {
      processor: new EntityProcessor({
        entity: 'gamification/transaction-logs',
        perPage: 25,
        mapping: (record) => new RecroomTransaction(record),
      }),
    }
  },
  created() {
    this.processor.getData()
  },
}
</script>

<template>
  <base-mdl width="sme" fullscreen="mobile" :layout="false">
    <base-header class="text-white" type="closable">
      <template #subheader>
        <recognition-header color="warning" icon="rec/round/token" animated />
      </template>
    </base-header>
    <h2 class="mb-5 text-center text-2xl">
      My
      <span class="capitalize">
        {{ currency }}
      </span>
      balance {{ formatNumber(tokenBalance) }}
    </h2>

    <div
      class="flex h-12 border-t border-b bg-dark px-6 font-bold text-primary"
    >
      <div class="w-2/6 py-2.5">Time</div>
      <div class="w-4/6 flex-auto px-5 py-2.5">Action</div>
      <div class="w-1/6 py-2.5 pl-5 text-center capitalize">
        {{ currency }}
      </div>
    </div>
    <div class="px-5 text-sm">
      <ew-catalog-processor
        :processor="processor"
        :columns="1"
        :tile-class="false"
      >
        <template #empty>
          <div class="px-5">No Transaction logs found.</div>
        </template>
        <template #tile="{ tile: log }">
          <div class="-mb-2.5 flex min-w-full border-b py-2.5 sm:-mb-5">
            <div class="w-2/6">
              {{ log.createdAt }}
            </div>
            <div class="w-4/6 flex-auto px-5">
              {{ log.description }}
            </div>
            <div
              class="w-1/6 pl-5 text-center font-bold"
              :class="{
                'text-emerald-700': log.valueSymbol === '+',
                'text-red-700': log.valueSymbol !== '+',
              }"
            >
              {{ log.valueSymbol }}{{ log.transactionValue }}
            </div>
          </div>
        </template>
        <template #placeholder>
          <div class="flex justify-center p-5">
            <base-loader />
          </div>
        </template>
      </ew-catalog-processor>
    </div>
  </base-mdl>
</template>
