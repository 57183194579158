<script>
import { useArticles } from '/~/extensions/health/composables'
import { formatDate } from '/~/utils/format/date'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'hlth-tile-article',
  components: {
    ButtonIcon,
    BaseImage,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isBookmarked, toggleBookmark } = useArticles()

    return {
      formatDate,
      isBookmarked,
      toggleBookmark,
    }
  },
  computed: {
    excerpt() {
      const { excerpt = '' } = this.tile

      if (excerpt.length < 100) {
        return excerpt
      }

      return `${excerpt.substring(0, 100).trim()}...`
    },
  },
}
</script>

<template>
  <div
    class="flex h-full cursor-pointer flex-col overflow-hidden rounded-lg bg-white shadow-sm"
    @click="$emit('tile-click', tile)"
  >
    <div class="relative">
      <base-image :src="tile.image" :rounded="false" />
    </div>
    <div class="-mt-[15px] flex justify-end">
      <div
        class="z-1 mr-[15px] flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full bg-white p-[5px] text-primary shadow-sm hover:shadow"
      >
        <button-icon
          :icon="
            isBookmarked(tile.id) ? 'plain/bookmark-marked' : 'plain/bookmark'
          "
          size="md"
          @click.stop="toggleBookmark(tile.id)"
        />
      </div>
    </div>
    <div class="flex flex-col px-[15px] pb-6">
      <h3
        class="min-h-14 w-full text-base font-bold leading-6 text-eonx-neutral-800"
        v-html="tile.title"
      />

      <div
        class="text-sm text-eonx-neutral-800 line-clamp-2"
        v-html="excerpt"
      />
    </div>
  </div>
</template>
