<script setup lang="ts">
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useHi5Component } from '/~/extensions/rec-room/composables/hi5-component'
import { useRecProfile } from '/~rec/composables/profile'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import DetailsPlaceholder from '/~rec/components/placeholder/placeholder-awards-perks-hi-details.vue'
import RecognitionTokens from '/~rec/components/recognition/recognition-tokens.vue'

const props = defineProps<{ hi5Id: string }>()

const { init, isSender, userDetails, hi5, loading } = useHi5Component(props)
const { features } = useRecProfile()

if (ui.desktop) {
  init()
} else {
  modal.show('rec-hi5-mobile', {
    props: {
      hi5Id: props.hi5Id,
    },
  })
}
</script>

<template>
  <div
    class="mx-auto flex w-full max-w-screen-md flex-col px-5 py-[30px] md:p-[30px]"
  >
    <template v-if="ui.desktop">
      <details-placeholder
        v-if="loading"
        :points="features.points"
        entity="hi5"
        under-img
      />
      <div v-else-if="hi5">
        <div>
          <base-back
            :to="{
              name: 'rec-hi5',
              query: { type: isSender ? 'sent' : 'received' },
            }"
            class="mb-6"
          />
        </div>
        <section>
          <h2 class="mb-2.5 break-words text-2xl text-eonx-neutral-800">
            {{ hi5.title }}
          </h2>
          <span
            class="mb-5 inline-block rounded-3xl px-5 py-[5px] text-sm font-semibold capitalize"
            :class="{
              'bg-orange-100 text-yellow-900': isSender,
              'bg-violet-100 text-violet-800': !isSender,
            }"
          >
            {{ isSender ? 'Sent' : 'Received' }}
          </span>

          <div class="flex flex-col lg:flex-row lg:space-x-6">
            <div class="flex min-w-40 shrink-0 flex-col xl:min-w-64">
              <base-image :alt="'Hi-5'" :src="hi5.image" rounded :ratio="1" />
              <div v-if="features.points && hi5.nominal">
                <recognition-tokens
                  :nominal="hi5.nominal"
                  color="gold"
                  class="my-[15px] w-full rounded-md px-6 py-2.5"
                  icon="rec/token"
                />
                <p class="text-eonx-neutral-600">
                  {{ isSender ? 'Sent' : 'Received' }} with this Hi-5
                </p>
              </div>
            </div>
            <div>
              <h3 class="mb-[5px] text-base text-eonx-neutral-800">Message</h3>
              <p
                class="mb-[15px] grid grid-cols-1 break-words text-justify text-base text-eonx-neutral-800"
              >
                {{ hi5.message }}
              </p>
              <div class="mb-2.5 flex">
                <base-avatar
                  class="mr-2.5"
                  :src="userDetails.avatarUrl"
                  :abbr="userDetails.initials"
                  size="lg"
                />
                <div>
                  <div
                    class="grid grid-cols-1 break-words font-bold leading-6 text-eonx-neutral-800"
                  >
                    {{ userDetails.fullName }}
                  </div>
                  <base-date
                    :date="hi5.createdAt"
                    class="text-sm font-semibold text-eonx-neutral-600"
                    type="day-time-spr"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </template>
  </div>
</template>
