<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    content?: string
    textColor?: string
    backgroundColor?: string
    fullHeightBackground?: boolean
  }>(),
  {
    fullHeightBackground: true,
  }
)

const contentStyle = computed(() => ({
  color: props.textColor,
  background: props.fullHeightBackground
    ? props.backgroundColor
    : `linear-gradient(180deg,transparent 0,transparent 30%,${props.backgroundColor} 0)`,
}))
</script>

<template>
  <span
    :style="contentStyle"
    class="[&>*]:inline [&>*]:[background:inherit]"
    v-html="content"
  />
</template>
