<script setup lang="ts">
import dayjs from 'dayjs/esm'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    activePeriod?: {
      from: number | null
      to: number | null
    }
    value?: number | null
  }>(),
  {
    activePeriod: () => ({
      from: null,
      to: null,
    }),
    value: () => 1,
  }
)

const emit =
  defineEmits<(event: 'select', date: dayjs.Dayjs | number) => void>()
const selectedDate = computed(() => props.value)
const daysInMonth = computed(() => 31)
const activePeriodFrom = computed(() => props.activePeriod.from || 1)
const activePeriodTo = computed(() => props.activePeriod.to || 31)

function select(day: number) {
  if (activePeriodFrom.value <= day && day <= activePeriodTo.value) {
    emit('select', day)
  }
}
</script>

<template>
  <div class="cursor-default overflow-hidden bg-white p-5 sm:w-[375px]">
    <div class="flex justify-center font-bold">Date per calendar month</div>
    <div class="mt-3 grid grid-cols-7 gap-4">
      <div
        v-for="i in daysInMonth"
        :key="i"
        :class="{
          invisible: i < 1 || i > daysInMonth + 1,
          'bg-eonx-neutral-200 text-eonx-neutral-400':
            i < activePeriodFrom || activePeriodTo < i,
          '!bg-primary text-white': i === selectedDate,
          'cursor-pointer border border-eonx-neutral-100 bg-eonx-neutral-50 text-eonx-neutral-800 hover:bg-secondary hover:text-white':
            activePeriodFrom <= i && i <= activePeriodTo,
        }"
        class="flex h-8 w-8 items-center justify-center rounded-full text-sm font-semibold leading-tight"
        @click="select(i)"
      >
        {{ i }}
      </div>
    </div>
    <slot name="after-content" />
  </div>
</template>
