export function loadSurvey({ interceptHost, orgId, key, isTesting }) {
  return new Promise((resolve, reject) => {
    ;(function () {
      const interceptFn = function () {
        if (typeof IMI === 'object') return
        const url =
          '//' +
          interceptHost +
          '/intercept/v1/init/' +
          orgId +
          '/' +
          key +
          '?testing=' +
          isTesting

        this.go = function () {
          const scriptEl = document.createElement('script')

          scriptEl.type = 'text/javascript'
          scriptEl.async = !0
          scriptEl.src = url
          if (document.body) {
            document.body.appendChild(scriptEl)
          }
          resolve()
        }
        this.start = function () {
          if (
            navigator.userAgent.indexOf('MSIE') >= 0 &&
            navigator.userAgent.indexOf('MSIE 11') < 0
          ) {
            return
          }
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _interceptFn = this

          if (!isTesting) {
            if (document.readyState != 'loading') _interceptFn.go()
            else if (window.addEventListener)
              window.addEventListener(
                'DOMContentLoaded',
                function () {
                  _interceptFn.go()
                },
                !1
              )
            else if (window.attachEvent)
              window.attachEvent('onload', function () {
                _interceptFn.go()
              })
          } else {
            _interceptFn.go()
          }
        }
      }

      try {
        new interceptFn().start()
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })()
  })
}
