import { ref } from 'vue'

type Entity = {
  [key: string | number]: string
}

type Errors = {
  [key: string]: Array<string>
}

type ErrorResponse = {
  status: number
  data: Errors
}

export type BackendErrors = {
  response?: ErrorResponse
  errors?: Errors
}

export function useBackendValidation() {
  const backendErrors = ref<Entity>({})

  function processBackendErrors(payload: BackendErrors) {
    const { response, errors } = payload
    const backendErrorItems: Entity = {}

    if (response) {
      const { status } = response
      const data = response.data?.violations || response.data

      if (status !== 500 && status !== 403) {
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            backendErrorItems[key] = data[key][0] || ''
          }
        }
      }
    } else if (errors) {
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          backendErrorItems[key] = errors[key][0] || ''
        }
      }
    } else {
      console.error(payload)
    }

    backendErrors.value = backendErrorItems
  }

  return {
    backendErrors,
    processBackendErrors,
  }
}
