import { ref, computed } from 'vue'
import modal from '/~/core/mdl'
import debounce from 'lodash-es/debounce'
import { urlPattern } from '/~/utils/process-text-for-urls'
import api from '/~rec/core/api'
import {
  fromIdToMention,
  fromMentionToId,
  fromMentionToIdAndriod,
  fromIdToMentionAndroid,
} from '/~rec/utils/process-text-for-mentions'
import { RecroomPost } from '/~/extensions/rec-room/core/post'
import { RecroomEvent } from '/~/extensions/rec-room/core/event'
import { RecroomPostQuiz } from '/~/extensions/rec-room/core/post-quiz'
import { RecroomPostPoll } from '/~/extensions/rec-room/core/post-poll'
import { useForm } from '/~/composables/base/use-form'

export const usePostCreator = ({
  post,
  source,
  emit,
  internalInstance,
  processBackendErrors,
  getPayload,
}: {
  post?: RecroomPost | RecroomPostQuiz | RecroomPostPoll
  source?: RecroomEvent
  emit: {
    (event: 'success'): void
    (event: 'update:loading', flag: boolean): void
  }
  internalInstance: { proxy: Vue } | null
  processBackendErrors: any
  getPayload: () => { [K: string]: any }
}) => {
  const { validationObserverRef } = useForm()
  const isAndriod = /Android/i.test(navigator.userAgent)
  const isEdit = ref(Boolean(post))
  const mentioned = ref(
    (post?.mentions &&
      Object.keys(post.mentions).map((key) => post?.mentions[key])) ||
      []
  )
  const content = ref('')
  const postData = ref()
  const input = ref(null)

  if (isAndriod && isEdit) {
    postData.value = fromIdToMentionAndroid(post?.content, post?.mentions)

    content.value = postData.value.result
    mentioned.value = postData.value.mentioned
  }

  if (!isAndriod && isEdit) {
    content.value = fromIdToMention(post?.content, post?.mentions)
  }

  const attachments = ref(
    isEdit.value ? [...(post?.attachments?.hits ?? [])] : []
  )

  const linksPreviews = ref(
    isEdit.value ? [...(post?.previewInformation ?? [])] : []
  )
  const linkPreviewsProcessing = ref(false)
  const loading = ref(false)
  const isOffensiveWarningShow = ref(false)

  function onEmojiSelect(emoji: any) {
    content.value += emoji
  }

  async function onSend(earnConfig: any) {
    const send = async () => {
      isOffensiveWarningShow.value = false
      let payload = {}

      try {
        emit('update:loading', true)

        const content2 = isAndriod
          ? fromMentionToIdAndriod(
              content.value.replace(/\n(\s*)(?=\n)/g, '\n'),
              mentioned.value
            )
          : fromMentionToId(
              content.value.replace(/\n(\s*)(?=\n)/g, '\n'),
              mentioned.value
            )

        content.value = content.value.trim().replace(/\n(\s*)(?=\n)/g, '\n')

        if (!isEdit.value) {
          payload = {
            attachments: attachments.value.map((a) => a.inline),
            ...earnConfig,
            ...getPayload(),
            content: content2,
            preview_information: linksPreviews.value,
            mentions: mentioned.value.map((v: any) => v.id),
          }

          await source?.createPost({ ...payload })
        } else {
          const updatedAttachments = attachments.value.map(({ inline }) => {
            const attachmentCopy = Object.assign({}, inline)

            delete attachmentCopy.id

            return attachmentCopy
          })

          payload = {
            attachments: updatedAttachments,
            ...earnConfig,
            ...getPayload(),
            content: content.value,
            preview_information: linksPreviews.value,
            mentions: mentioned.value.map((v: any) => v.id),
          }

          await post?.update({ ...payload })
        }

        emit('success')
      } catch (error) {
        processBackendErrors(error, {
          data: payload,
          exclude: 'endsAt',
        })
      } finally {
        emit('update:loading', false)
      }
    }

    if (
      input.value?.validationProviderRef?.failedRules['offensive'] &&
      !isOffensiveWarningShow.value
    ) {
      isOffensiveWarningShow.value = true
      modal.show('rec-post-offensive-warning', {
        props: {
          send,
        },
        on: {
          closeOffensiveWarning: () => {
            isOffensiveWarningShow.value = false
          },
        },
      })
    } else {
      const isValid = await validationObserverRef.value?.validate()

      if (!isValid) return
      send()
    }
  }

  const postLinks = ref<string[]>([])

  function difference(arrayOne: string[], arrayTwo: string[]): string[] {
    return arrayOne.filter((element: string) => !arrayTwo.includes(element))
  }

  function updatePostLinks() {
    postLinks.value = [
      ...new Set([...content.value.matchAll(urlPattern)].map((v) => v[0])),
    ].map((i) => i.replace(/\/$/, ''))
  }

  const processLinkPreviews = debounce(function (value) {
    const links = [
      ...new Set([...value.matchAll(urlPattern)].map((v) => v[0])),
    ].map((i) => i.replace(/\/$/, ''))
    const diffAdd = difference(links, postLinks.value).map((i) =>
      i.replace(/\/$/, '')
    )
    const diffDelete = difference(postLinks.value, links).map((i) =>
      i.replace(/\/$/, '')
    )

    const deleteAllPreviews = () => {
      linksPreviews.value.forEach((i, index) => {
        linksPreviews.value.splice(index, 1)
      })
    }

    if (
      !diffAdd.length &&
      !diffDelete.length &&
      !postLinks.value.length &&
      linksPreviews.value.length === 1
    ) {
      deleteAllPreviews()
      return
    }

    if (!diffAdd.length && !diffDelete.length) return

    if (diffDelete.length) {
      diffDelete.forEach((link: string) => {
        const deletedLink = linksPreviews.value.find(
          (i) => i.url.replace(/\/$/, '') === link.replace(/\/$/, '')
        )

        const index = linksPreviews.value.indexOf(deletedLink)

        postLinks.value = postLinks.value.filter(
          (i: string) =>
            i.replace('https:', 'http:') !== link.replace('https:', 'http:')
        )
        if (index !== -1) {
          linksPreviews.value.splice(index, 1)
        }
        if (!postLinks.value.length) {
          deleteAllPreviews()
        }
      })
    }

    linkPreviewsProcessing.value = true

    Promise.all(
      diffAdd.map((v) =>
        api.post('/link-preview', { link: v }, { notify: false })
      )
    )
      .then((response) => {
        const result = response.map((r) => ({
          ...r.data,
          url: r.data.url.replace(/\/$/, ''),
          hostname: new URL(r.data.url).hostname.replace('www.', ''),
        }))
        let isLinkExist = true

        if (result.length) {
          isLinkExist = linksPreviews.value.find(
            (i) => i.url.replace(/\/$/, '') === result[0].url.replace(/\/$/, '')
          )
        }

        if (!isLinkExist) {
          linksPreviews.value = linksPreviews.value.concat(result)
          updatePostLinks()
        } else {
          updatePostLinks()
        }
      })
      .finally(() => {
        linkPreviewsProcessing.value = false
      })
  }, 500)

  function onInput(newContent: any) {
    if (isAndriod) {
      content.value = newContent
    }
    processLinkPreviews(content.value)
  }

  function removePreview(id: number) {
    linksPreviews.value.splice(id, 1)
  }

  function updateMentioned(value: any) {
    if (Array.isArray(value)) {
      mentioned.value = value
    } else {
      const { user, add } = value

      if (add) {
        mentioned.value = [...mentioned.value, { ...user }]
      } else {
        if (!user) {
          mentioned.value = []
        } else {
          for (const idx in mentioned.value) {
            if (mentioned.value[idx].nanoId === user.nanoId) {
              mentioned.value.splice(idx, 1)
              break
            }
          }
        }
      }
    }
  }

  return {
    validationObserverRef,
    linksPreviews,
    isEdit,
    isAndriod,
    content,
    attachments,
    input,
    mentioned,
    updateMentioned,
    linkPreviewsProcessing,
    loading,
    isOffensiveWarningShow,
    onEmojiSelect,
    onSend,
    onInput,
    removePreview,
  }
}
