<script>
import { useRouter } from 'vue-router/composables'
import FeedTile from '/~/extensions/health/components/feed/tile/feed-tile-recipe.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'

export default {
  components: {
    EwCatalogProcessor,
    FeedTile,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    feed: {
      type: Object,
      default: () => ({}),
    },
    limit: {
      type: Number,
      default: Infinity,
    },
    columns: {
      type: Object,
      default: () => ({
        xs: 2,
        sm: 3,
        lg: 4,
      }),
    },
  },
  setup(props) {
    const router = useRouter()

    function onTileClick(tile) {
      router.push({
        name: 'hlth-health-ebits-detail',
        params: {
          type: props.type,
          id: tile.id,
        },
      })
    }

    return {
      onTileClick,
    }
  },
}
</script>

<template>
  <ew-catalog-processor :processor="feed" :columns="columns" :limit="limit">
    <template #tile="{ tile }">
      <feed-tile :tile="tile" @tile-click="onTileClick" />
    </template>
    <template #empty>
      <div class="h-64">No recipes were found</div>
    </template>
  </ew-catalog-processor>
</template>
