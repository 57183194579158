<script>
import { nextTick } from 'vue'
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import PostComments from '/~rec/components/post/comments/post-comments.vue'
import PostContent from '/~rec/components/post/content/post-content.vue'
import PostEditor from '/~rec/components/post/creator/post-editor.vue'
import PostActions from '/~rec/components/post/post-actions.vue'
import PostHeader from '/~rec/components/post/post-header.vue'
import PostHeaderDetailed from '/~rec/components/post/post-header-detailed.vue'
import PostEarn from '/~rec/components/post/earn/post-earn.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-post-box-default',
  components: {
    PostContent,
    PostHeader,
    PostHeaderDetailed,
    PostActions,
    PostComments,
    PostEditor,
    PostEarn,
    BaseButton,
    BaseMenu,
  },
  provide() {
    return {
      feed: this.feed,
      post: this.post,
    }
  },
  props: {
    feed: {
      type: Object,
      default: null,
    },
    post: {
      type: Object,
      required: true,
    },
    showComments: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup() {
    const { isMeAdmin, isMeSuperAdmin, features } = useRecProfile()

    return {
      features,
      isMeAdmin,
      isMeSuperAdmin,
      ui,
    }
  },
  data() {
    return {
      isCommentsVisible: this.showComments,
      isPostEdit: false,
    }
  },
  computed: {
    canAnnouncement() {
      const types = ['post', 'award', 'poll', 'quiz']

      return types.includes(this.post.type)
    },
    menuItems() {
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      const { post } = this
      const isAdmin = this.isMeAdmin || this.isMeSuperAdmin

      return [
        {
          icon: 'rec/megaphone',
          content: 'Mark as Announcement',
          hidden:
            post.isAnnouncement ||
            !isAdmin ||
            !this.canAnnouncement ||
            !this.features.announcements,
          click: () => {
            post.markAnnouncement()
          },
        },
        {
          icon: 'rec/megaphone',
          content: 'Unmark as Announcement',
          hidden:
            !post.isAnnouncement || !isAdmin || !this.features.announcements,
          click: () => {
            post.unmarkAnnouncement()
          },
        },
        {
          icon: 'rec/alert',
          content: 'Post Reported',
          hidden: !post.isReportedByMe,
          disabled: true,
        },
        {
          icon: 'rec/alert',
          content: 'Report Post',
          hidden: post.isReportedByMe || post.isMeAuthor,
          click: () => {
            modal.show('rec-post-report', {
              props: { post },
              on: {
                disregarded: () => {
                  this.feed?.removeHit(post)
                },
              },
            })
          },
        },
        {
          icon: 'rec/edit',
          hidden:
            !post.isActionsAllow('edit') || (!isAdmin && !post.isMeAuthor),
          content: this.isPostEdit ? 'Cancel Editing Post' : 'Edit Post',
          click: () => {
            if (['quiz', 'poll'].includes(post.type) && !this.isPostEdit) {
              modal.show('rec-post-edit-warn', {
                props: {
                  type: post.type,
                },
                on: {
                  continue: () => {
                    this.isPostEdit = true
                  },
                },
              })
            } else {
              this.isPostEdit = !this.isPostEdit
            }
          },
        },
        {
          icon: 'rec/bin',
          content: 'Delete Post',
          hidden:
            !post.isActionsAllow('delete') || (!isAdmin && !post.isMeAuthor),
          click: () => {
            modal.show('rec-post-delete', {
              props: { post },
              on: {
                deleted: () => {
                  this.feed?.removeHit(post)
                },
              },
            })
          },
        },
      ]
    },
    isMenuAvailable() {
      return this.menuItems.some((i) => !i.hidden)
    },
    showPostActions() {
      return this.post.isActionsAllow('comment', 'like', 'save')
    },
    showDetailedHeader() {
      return /event|group|award/.test(this.post.type)
    },
  },
  watch: {
    isCommentsVisible(v) {
      if (v) {
        nextTick(() => {
          const post = document.querySelector(`[data-id='${this.post.id}']`)

          post?.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          })
        })
      }
    },
  },
  methods: {
    onAnnouncementClick() {
      modal.show('rec-post-announcement')
    },
  },
}
</script>

<template>
  <div
    class="w-full"
    :class="{
      'mb-2.5': ui.mobile,
      'rounded-lg shadow-sm': !ui.mobile,
    }"
    :data-id="post.id"
    @click="$emit('click', $event)"
  >
    <post-header-detailed v-if="showDetailedHeader" />
    <post-header v-else>
      <base-button
        v-if="post.isAnnouncement"
        class="mx-[15px] text-primary"
        icon="rec/user-voice"
        size="sme"
        @click="onAnnouncementClick()"
      />
      <base-menu v-if="isMenuAvailable" :items="menuItems" position="right">
        <template #trigger>
          <base-button icon="rec/more" class="flex self-center text-primary" />
        </template>
      </base-menu>
    </post-header>
    <post-editor v-if="isPostEdit" @success="isPostEdit = false" />
    <template v-else>
      <post-content />
      <div
        class="flex flex-wrap items-center justify-between px-[15px] py-[15px] md:px-6"
      >
        <post-actions
          v-if="showPostActions"
          :feed="feed"
          :post="post"
          :is-comments-visible.sync="isCommentsVisible"
        />
        <div v-if="post.hasEarnConfigs" class="xs:ml-auto">
          <post-earn v-if="features.points" />
        </div>
      </div>
      <post-comments v-if="isCommentsVisible" />
    </template>
  </div>
</template>
