<script setup lang="ts">
import BaseLink from '/~/components/base/link/base-link.vue'
import { useProvider } from '/~/composables/provider'
import AuthLayout from '/~/layouts/auth/layout-auth.vue'
import AuthLogo from '/~/views/auth/auth-logo.vue'

const { providerTitle, generalEmail: providerClientEmail } = useProvider()
</script>

<template>
  <auth-layout
    image="//i.ewalletcdn.com/b4e5cdf3-7950-4923-bea5-b535ca7d928f/"
    class="auth-register"
  >
    <div
      v-analytics:mount="{
        pageGroup: 'Auth',
        page: 'Register',
        label: 'Success register screen displayed',
      }"
      class="mx-auto max-w-2xl px-[30px] pb-20 md:pt-20"
    >
      <auth-logo class="mt-[32] mb-10 md:hidden" />
      <h2 class="text-2xl text-eonx-neutral-800">
        Thank you for registering for {{ providerTitle }}!
      </h2>
      <div class="mt-8 space-y-6 text-eonx-neutral-500">
        <p>
          Your registration has been received and will be processed by our team
          within 10 business days.
        </p>
        <p>
          Once your registration has been approved, our team will contact you by
          phone to assist with next steps.
        </p>
        <p>
          If you have any questions, please email us at
          <base-link :href="`mailto:${providerClientEmail}`">
            {{ providerClientEmail }}
          </base-link>
        </p>
      </div>
    </div>
  </auth-layout>
</template>
