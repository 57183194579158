<script>
import { computed, onBeforeMount, ref } from 'vue'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import RetailerLogo from '/~/extensions/online-offers/templates/default/views/retailer/logo/retailer-logo.vue'
import RetailerOffers from '/~/extensions/online-offers/templates/default/views/retailer/offers/retailer-offers.vue'
import cdn from '/~/utils/cdn'
import BaseClampLine from '/~/components/base/clamp-line/base-clamp-line.vue'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import { useAddresses } from '/~/composables/addresses'

export default {
  name: 'online-offers-retailer',
  components: {
    EwDetailsLayout,
    RetailerOffers,
    RetailerLogo,
    BaseClampLine,
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { getAddresses } = useAddresses()
    const { routeState, getMerchantById } = useOnlineOffers()

    const loading = ref(true)
    const retailer = ref(null)

    const merchant = computed(() => retailer.value?.merchant?.payload ?? {})
    const coupons = computed(() =>
      (retailer.value?.coupons ?? []).map((item) => item.payload)
    )

    const backRoute = computed(() => ({
      name: 'online-offers',
      ...routeState.value,
    }))

    const defaultImage = computed(() =>
      cdn('https://ucarecdn.com/52e1e522-a044-4474-b6a8-1a55460e667a/')
        .resize('400x')
        .url()
    )
    const image = computed(() => cdn(merchant.value?.logo).resize('400x').url())
    const alt = computed(() => merchant.value?.name)

    async function getData() {
      retailer.value = await getMerchantById(props.slug)
      loading.value = false
    }

    onBeforeMount(() => {
      getAddresses()
      getData()
    })

    return {
      loading,
      retailer,
      backRoute,
      merchant,
      coupons,
      image,
      alt,
      defaultImage,
      getData,
    }
  },
  watch: {
    $route(to, from) {
      if (to.hash || from.hash) return

      this.getData()
    },
  },
}
</script>

<template>
  <ew-details-layout
    :loading="loading"
    :ready="Boolean(retailer)"
    :title="merchant.name"
    :back="backRoute"
    :placeholder-ratio="1"
  >
    <template #gallery>
      <retailer-logo
        data-test="retailer-logo"
        :src="image"
        :default-src="defaultImage"
        :alt="alt"
      />
    </template>
    <template #summary>
      <retailer-offers
        data-test="retailer-offers"
        :merchant="merchant"
        :coupons="coupons"
      />
    </template>
    <template v-if="merchant.summary" #details>
      <base-clamp-line
        data-test="clamp-line"
        :rows="5"
        class="hidden leading-normal text-eonx-neutral-600 sm:block"
      >
        {{ merchant.summary }}
      </base-clamp-line>
    </template>
  </ew-details-layout>
</template>
