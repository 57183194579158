<script>
import { computed } from 'vue'
import emitter from '/~/core/emitter'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.vue'

export default {
  name: 'profile-pay-cash-in',
  components: {
    DrawerIconHeader,
    BaseButton,
    BaseAsidePage,
    BaseIcon,
  },
  setup() {
    const date = computed(() => {
      return formatDate('daymonthyeartime', new Date())
    })

    return {
      date,
      formatDollar,
    }
  },
  data() {
    return {
      details: {
        fee: 0,
        subtotal: 0,
        total: 0,
      },
      qrData: null,
      status: null,
    }
  },
  mounted() {
    emitter.on('menu-pay-cash-in:details', this.udpateDetails)
    emitter.on('menu-pay-cash-in:status', this.updateStatus)
  },
  beforeDestroy() {
    emitter.off('menu-pay-cash-in:details', this.udpateDetails)
    emitter.off('menu-pay-cash-in:status', this.updateStatus)
  },
  methods: {
    cancel() {
      emitter.emit('cash-in:cancel', {
        qrData: this.qrData,
        details: this.details,
      })
    },
    proceed() {
      emitter.emit('cash-in:proceed', {
        qrData: this.qrData,
        details: this.details,
      })
    },
    udpateDetails(data) {
      const { qrData, details } = data

      this.qrData = qrData
      this.details = details
    },
    updateStatus(status) {
      this.status = status
    },
  },
}
</script>

<template>
  <base-aside-page no-padding>
    <template #iconHeader>
      <drawer-icon-header title="Pending Cash In" icon="success">
        <template #icon>
          <div class="text-green-600">
            <base-icon size="4xl" svg="success" />
          </div>
        </template>
      </drawer-icon-header>
    </template>
    <div class="flex h-full flex-col justify-between bg-white px-5 pb-[30px]">
      <div class="px-5">
        <div class="flex pb-[15px]">
          <span class="inline-block w-32 shrink-0 font-bold">Date</span>
          <span class="font-bold">
            {{ date }}
          </span>
        </div>
        <div class="flex pb-[15px]">
          <span class="inline-block w-32 shrink-0 font-bold">Fees</span>
          <span class="font-bold">
            {{ formatDollar(details.fee) }}
          </span>
        </div>
        <div class="flex pb-[15px]">
          <span class="inline-block w-32 shrink-0 font-bold">Subtotal</span>
          <span class="font-bold">
            {{ formatDollar(details.subtotal) }}
          </span>
        </div>
        <div class="flex pb-[15px]">
          <span class="inline-block w-32 shrink-0 font-bold">Total</span>
          <span class="font-bold">
            {{ formatDollar(details.total) }}
          </span>
        </div>
      </div>
      <div class="flex flex-col space-y-[15px]">
        <base-button
          :loading="status === 'processing'"
          :disabled="!!status"
          @click="proceed"
        >
          Proceed
        </base-button>
        <base-button
          color="error"
          :loading="status === 'cancelling'"
          :disabled="!!status"
          @click="cancel"
        >
          Cancel
        </base-button>
      </div>
    </div>
  </base-aside-page>
</template>
