import { computed, nextTick, ref } from 'vue'
import ui from '/~/core/ui'

type Group = {
  name: string
  icon: string
}

type Option = {
  group: string
  icon: string
  value: string | number
  text: string
}

export function useBaseSelect(props: any, ctx: any) {
  const selectedPosition = ref<number | null>(null)
  const hoverPosition = ref<number | null>(null)
  const blockHover = ref<boolean>(false)
  const isOpen = ref<boolean>(false)
  const searchQuery = ref<string>('')
  const input = ref<HTMLInputElement>()

  const groups = computed(() => {
    const groups: Array<Group> = []

    props.options.reduce((current: string, option: Option) => {
      if (option.group && current !== option.group) {
        groups.push({
          name: option.group,
          icon: option.icon,
        })

        current = option.group
      }

      return current
    }, null)

    return groups
  })

  const selected = computed(() => {
    const option = (props.options || []).filter(
      (option: Option, index: number) => {
        if (isSelect(option)) {
          selectedPosition.value = index
          return true
        }
      }
    )[0]

    return option || { text: '' }
  })

  const isSelected = computed(() => 'value' in selected.value)

  const optionsWithoutGroup = computed(() => {
    const result = props.options.filter((option: Option) => {
      const noGroup = !('group' in option) || !option.group

      if (props.search.enabled && searchQuery.value && !isSelected.value) {
        if (props.search.fields?.length) {
          return (
            noGroup &&
            (option.text
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase()) ||
              // fix ts because Option has many different fields
              props.search.fields.some((elem: keyof Option) => {
                const field = option[elem]

                if (field) {
                  return String(field)
                    .toLowerCase()
                    .includes(searchQuery.value.toLowerCase())
                }
                return false
              }))
          )
        }

        return (
          noGroup &&
          option.text.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
      } else {
        return noGroup
      }
    })

    return result
  })

  function onHover(option: Option) {
    if (!blockHover.value) {
      hoverPosition.value = props.options.findIndex(
        (el: Option) => el.value === option.value
      )
    }
  }

  function onKeyUpHandler(event: KeyboardEvent) {
    if (ctx.search.enabled) {
      handleSearch(event)
    }

    if (ui.mobile) {
      return
    }

    switch (event.code) {
      case 'Space':
      case 'ArrowUp':
      case 'ArrowDown':
        isOpen.value = true
        break
      case 'Enter':
        ctx.handleEnterPress()
        break
      case 'Escape':
        event.stopPropagation()
        break
    }

    blockHover.value = false
  }

  function onPreSelectHandler(event: KeyboardEvent) {
    const button = event.code
    const pos =
      hoverPosition.value === null && selectedPosition.value !== null
        ? selectedPosition.value
        : hoverPosition.value

    if (
      pos === null &&
      (button === 'ArrowDown' || button === 'ArrowUp' || button === 'Tab')
    ) {
      hoverPosition.value = 0
      return
    }

    if (
      (button === 'ArrowDown' || (button === 'Tab' && !event.shiftKey)) &&
      pos !== null &&
      pos < props.options.length - 1 &&
      hoverPosition.value !== null
    ) {
      hoverPosition.value++
      return
    }

    if (
      (button === 'ArrowUp' || (button === 'Tab' && event.shiftKey)) &&
      pos !== null &&
      pos > 0 &&
      hoverPosition.value !== null
    ) {
      hoverPosition.value--
      return
    }

    if (button === 'Home' && isOpen.value) {
      hoverPosition.value = 0
      return
    }

    if (button === 'End' && isOpen.value) {
      hoverPosition.value = props.options.length - 1
    }
  }

  function onSelectClickHandler() {
    handleToggle()
    input.value?.focus()
  }

  function onBlurHandler(_: Event) {
    isOpen.value = false
    if (!isSelected.value) {
      clearInput()
    }
    hoverPosition.value = selectedPosition.value
    ctx.onBlur()
  }

  function isSelect(item: Option) {
    return String(item.value) === String(props.value)
  }

  function handleToggle() {
    isOpen.value = !isOpen.value

    if (!isOpen.value && !isSelected.value) {
      clearInput()
    }
  }

  function clearInput() {
    ctx.onInput('')
    nextTick(() => {
      if (input.value) {
        searchQuery.value = ''
      }
    })
  }

  function handleSearch(_: KeyboardEvent) {
    if (input.value) {
      searchQuery.value = input.value.value
    }

    ctx.hoverFromSearch(searchQuery.value)
  }

  function isHover(option: Option) {
    return (
      props.options.findIndex((el: Option) => el.value === option.value) ===
      hoverPosition.value
    )
  }

  function getOptionsByGroup(name: string) {
    return props.options.filter((option: Option) => option.group === name)
  }

  return {
    selectedPosition,
    hoverPosition,
    blockHover,
    isOpen,
    groups,
    selected,
    isSelected,
    optionsWithoutGroup,
    onHover,
    onKeyUpHandler,
    onPreSelectHandler,
    onSelectClickHandler,
    onBlurHandler,
    isSelect,
    handleToggle,
    clearInput,
    isHover,
    getOptionsByGroup,
    input,
    searchQuery,
  }
}
