<script setup lang="ts">
import debounce from 'lodash-es/debounce'
import { ref, watch } from 'vue'
import { useTravel } from '/~/extensions/travel/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwCategories from '/~/components/ewallet/controls/ew-categories.vue'

const {
  destinations,
  selectedCategory,
  isFiltersSelected,
  resetFilters,
  searchQuery,
  searchGroup,
} = useTravel()

const localSearchQuery = ref(searchQuery.value)
const searchFocused = ref(false)

watch(searchQuery, (value) => {
  // NOTE: do not update local search value if input have focus
  if (!searchFocused.value) {
    localSearchQuery.value = value
  }
})

const onSearchInput = debounce(function (value) {
  searchQuery.value = value
}, 350)

function onSearchBlur() {
  searchFocused.value = false
  searchQuery.value = localSearchQuery.value
}

function onSearchEnter() {
  searchQuery.value = localSearchQuery.value
}
</script>

<template>
  <div class="flex space-x-5 sm:flex-nowrap">
    <base-input
      v-model="localSearchQuery"
      :placeholder="`Search ${searchGroup}`"
      :icon-plain="true"
      look="rounded"
      clearable
      nolabel
      autofocus
      class="hidden sm:block"
      @input="onSearchInput"
      @focus="searchFocused = true"
      @blur="onSearchBlur"
      @keypress.enter="onSearchEnter"
    >
      <template #icon>
        <div class="ml-[15px] mr-2.5 flex items-center justify-center">
          <base-icon
            svg="plain/search-v2"
            class="text-eonx-neutral-600"
            :size="19"
          />
        </div>
      </template>
    </base-input>
    <ew-categories
      title="Destinations"
      :categories="destinations"
      :selected-id="selectedCategory"
      :is-filters-selected="isFiltersSelected"
      @change="selectedCategory = $event"
      @clear="resetFilters"
    />
  </div>
</template>
