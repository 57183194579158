<script>
import debounce from 'lodash-es/debounce'
import ui from '/~/core/ui'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import SelectField from '/~/components/mobile-filter/select-field.vue'

export default {
  name: 'hlth-feed-controls',
  components: {
    BaseInput,
    BaseSelect,
    BaseLoader,
    EwFilters,
    SelectField,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search...',
    },
    withoutCategory: {
      type: Boolean,
      default: false,
    },
    withoutFilters: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ui,
    }
  },
  data() {
    return {
      search: '',
      selectedCategory: (this.categories[0] || {}).value,
      selectedSort: (this.sortOptions[0] || {}).value,
    }
  },
  computed: {
    isFiltersSelected() {
      return (
        this.search !== '' ||
        (this.selectedCategory !== this.categories[0]?.value &&
          this.selectedCategory !== null) ||
        (this.selectedSort !== this.sortOptions[0]?.value &&
          this.selectedSort !== null)
      )
    },
  },
  watch: {
    search() {
      this.onSearch()
    },
    categories: {
      handler(categories) {
        if (categories[0] && !this.selectedCategory) {
          this.selectedCategory = this.categories[0].value
        }
      },
      deep: true,
    },
    params() {
      this.selectedSort = this.params.sort ?? this.selectedSort
    },
  },
  created() {
    this.search = this.params.search ?? this.search
    this.selectedCategory = this.params.category ?? this.selectedCategory
    this.selectedSort = this.params.sort ?? this.selectedSort
  },
  methods: {
    onSelectCategory(value) {
      this.selectedCategory = value
      this.$emit('category-update', value === 0 ? undefined : value)
    },
    onSelectSort(value) {
      this.selectedSort = value
      this.$emit('sort-update', value)
    },
    onSearch: debounce(function () {
      this.$emit('input', this.search)
    }, 300),
    resetCategory() {
      this.selectedCategory = (this.categories[0] || {}).value
    },
    clearSearch() {
      this.search = ''
    },
    resetFilters() {
      this.search = ''
      this.selectedCategory = (this.categories[0] || {}).value
      this.selectedSort = (this.sortOptions[0] || {}).value
    },
  },
}
</script>

<template>
  <component
    :is="ui.mobile ? 'ew-filters' : 'div'"
    class="w-full"
    :class="{
      'mb-4': ui.mobile,
    }"
    title="Filters"
    :is-filters-selected="isFiltersSelected"
    @clear="resetFilters"
  >
    <div
      :class="{
        'space-y-4 py-4': ui.mobile,
        'flex w-full flex-auto flex-col items-center justify-center space-y-5 text-sm sm:w-auto sm:flex-row sm:space-y-0':
          !ui.mobile,
      }"
    >
      <div class="w-full">
        <base-input
          v-model="search"
          :placeholder="placeholder"
          class="w-full"
          look="rounded"
          icon="health/search"
          icon-plain
          clearable
          nolabel
        >
          <template #inner-filter>
            <base-loader v-if="loading" class="mr-2.5" size="sm" />
          </template>
        </base-input>
      </div>
      <component
        :is="ui.mobile ? 'select-field' : 'base-select'"
        v-if="!withoutCategory && categories.length > 0"
        name="filter"
        class="w-full cursor-pointer xs:ml-5 xs:w-48"
        arrow-icon="heroicons/outline/chevron-down"
        no-label
        fullwidth-popup
        look="health"
        title="Category"
        :value="selectedCategory"
        :options="categories"
        :empty-option="categories[0]"
        @input="onSelectCategory"
      />
      <component
        :is="ui.mobile ? 'select-field' : 'base-select'"
        v-if="sortOptions.length > 0"
        name="filter"
        class="mt-[15px] w-full cursor-pointer text-sm xs:ml-5 xs:mt-0 xs:w-48"
        arrow-icon="heroicons/outline/chevron-down"
        no-label
        fullwidth-popup
        look="health"
        title="Sort by"
        :value="selectedSort"
        :options="sortOptions"
        :empty-option="sortOptions[0]"
        @input="onSelectSort"
      />
    </div>
  </component>
</template>
