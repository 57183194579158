<script>
import { useQrConnect } from '/~/extensions/qr-connect/composables'

export default {
  name: 'qr-connect-root-view',
  setup() {
    const { animation } = useQrConnect()

    return {
      animationState: animation,
    }
  },
  computed: {
    animation() {
      const {
        name,
        params: { withoutAnimation },
      } = this.$route

      if (withoutAnimation) {
        return ''
      }

      return name === 'qr-connect'
        ? {
            'enter-class': '-translate-x-full',
            'leave-active-class': 'absolute top-0 right-0 left-0',
            'leave-to-class': 'translate-x-full',
          }
        : {
            'enter-class': 'translate-x-full',
            'leave-active-class': 'absolute top-0 right-0 left-0',
            'leave-to-class': '-translate-x-full',
          }
    },
  },
  methods: {
    transitionStart() {
      this.animationState = true
    },
    transitionEnd() {
      this.animationState = false
    },
  },
}
</script>

<template>
  <div class="flex h-full w-full">
    <div
      class="flex h-full w-full flex-col overflow-y-auto bg-eonx-neutral-50 sm:mr-6 sm:rounded-t-3xl"
    >
      <transition
        v-bind="animation"
        @beforeEnter="transitionStart"
        @afterLeave="transitionEnd"
      >
        <router-view class="transform transition duration-300" />
      </transition>
    </div>
    <portal-target name="qr-connect" />
  </div>
</template>
