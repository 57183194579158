const PHOTO_ID_IMAGE = {
  'aus-passport': 'linear-gradient(238.18deg, #2B3358 0%, #4B64A4 98.99%)',
  'aus-nsw-licence': 'linear-gradient(238.18deg, #DA9543 0%, #E2C941 98.99%)',
  'aus-vic-licence': 'linear-gradient(238.18deg, #5C8C14 0%, #AACC76 98.99%)',
  'aus-qld-licence': 'linear-gradient(238.18deg, #9B7A0F 0%, #E0C056 98.99%)',
  'aus-wa-licence': 'linear-gradient(238.18deg, #3C6A97 0%, #9CB5CF 98.99%)',
  'aus-tas-licence': 'linear-gradient(238.18deg, #1E6E9D 0%, #4FA7D6 98.99%)',
  'aus-sa-licence': 'linear-gradient(238.18deg, #46927E 0%, #8CC6B7 98.99%)',
  'aus-act-licence': 'linear-gradient(238.18deg, #A57619 0%, #F3C66B 98.99%)',
  'aus-nt-licence': 'linear-gradient(238.18deg, #AB4725 0%, #E07A58 98.99%)',
  'nz-passport': 'linear-gradient(238.18deg, #2B2724 0%, #6F6762 98.99%)',
  'nz-licence': 'linear-gradient(238.18deg, #6BAE83 0%, #AEE2C1 98.99%)',
}

export class PhotoId {
  constructor(raw) {
    this.raw = raw || {}
  }

  get id() {
    return this.raw.external_id
  }

  get country() {
    return this.raw.country || ''
  }

  get isLicence() {
    return this.raw.document_type === 'DRIVER_LICENCE'
  }

  get type() {
    return this.isLicence ? 'Licence' : 'Passport'
  }

  get subType() {
    return this.raw.sub_type || ''
  }

  get fullType() {
    return [this.type, this.subType].join(' ').trim()
  }

  get lastName() {
    return this.raw.identity_document?.last_name || ''
  }

  get firstName() {
    return this.raw.identity_document?.first_name || ''
  }

  get dateOfBirth() {
    return this.raw.identity_document?.date_of_birth || ''
  }

  get countryOfIssue() {
    return this.raw.identity_document?.country_of_issue || ''
  }

  get fullName() {
    return `${this.raw.identity_document?.first_name} ${this.raw.identity_document?.last_name}`.trim()
  }

  get expiryDate() {
    return (
      this.raw.identity_document?.expiry_date ||
      this.raw.identity_document?.date_of_expiry ||
      ''
    )
  }

  get number() {
    return (
      this.raw.identity_document?.licence_number ||
      this.raw.identity_document?.passport_number ||
      ''
    )
  }

  get state() {
    return (
      (this.raw.identity_document?.state === 'NA'
        ? 'VIC'
        : this.raw.identity_document?.state) ?? ''
    )
  }

  get images() {
    return this.raw.images ?? []
  }

  get byNewZeland() {
    return /zeland/i.test(this.countryOfIssue)
  }

  get stateSlug() {
    return this.state.toLowerCase()
  }

  get icon() {
    if (this.byNewZeland) {
      return 'menu/new-zeland'
    }

    return this.isLicence ? `menu/${this.stateSlug}` : 'menu/passport'
  }

  get background() {
    let slug = 'aus-passport'

    if (this.byNewZeland) {
      slug = this.isLicence ? 'nz-licence' : 'nz-passport'
    } else if (this.isLicence) {
      slug = `aus-${this.stateSlug}-licence`
    }

    return PHOTO_ID_IMAGE[slug]
  }
}
