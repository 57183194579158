<script>
const SYMBOLS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

export default {
  name: 'drawer-scroll',
  props: {
    letters: {
      type: Array,
      default: () => SYMBOLS,
    },
    idPrefix: {
      type: String,
      default: 'letter-',
    },
  },
  data() {
    return {
      touchClientX: -1,
      touchClientY: -1,
      lettersRect: { top: 0, height: 0 },
      hoveredLetter: '',
      touchLabelVisible: false,
    }
  },
  computed: {
    lettersList() {
      return this.letters
        .map((l) => l.toUpperCase())
        .sort((a, b) => (a > b ? 1 : -1))
    },
    labelTopOffset() {
      const {
        lettersRect: { top, height },
        touchClientY,
      } = this
      const minOffset = 0
      const maxOffset = height
      const offset = Math.min(
        Math.max(touchClientY - top, minOffset),
        maxOffset
      )

      return `${offset}px`
    },
  },
  watch: {
    hoveredLetter(newLetter, oldLetter) {
      if (newLetter !== oldLetter && newLetter) {
        this.onLetterClick(newLetter)
      }
    },
  },
  methods: {
    onLetterClick(letter) {
      const scrollToNode = document.getElementById(this.idPrefix + letter)
      const scrollWrapper = this.$slots.default[0].elm
      const { container } = this.$refs

      if (!scrollToNode || !scrollWrapper || !container) {
        return
      }

      const wrapperTop = scrollWrapper.getBoundingClientRect().top
      const toNodeTop = scrollToNode.getBoundingClientRect().top

      container.scrollTop = toNodeTop - wrapperTop
    },
    getHoveredLetter(clientY) {
      const hoveredElement = document.elementFromPoint(
        this.touchClientX,
        clientY
      )

      if (hoveredElement !== null) {
        const { letter } = hoveredElement.dataset

        if (letter) {
          this.touchLabelVisible = true
          this.hoveredLetter = letter
          this.touchClientY = clientY
        }
      }
    },
    onTouchStart(e) {
      e.preventDefault()

      const { top, height } = this.$refs.letters.getBoundingClientRect()
      const { clientY, clientX } = e.touches[0]

      this.lettersRect = { top, height }
      this.touchClientX = clientX

      this.getHoveredLetter(clientY)
    },
    onTouchMove(e) {
      e.preventDefault()

      this.getHoveredLetter(e.touches[0].clientY)
    },
    onTouchEnd(e) {
      e.preventDefault()

      this.touchLabelVisible = false
    },
  },
}
</script>

<template>
  <div class="flex h-full w-full justify-between">
    <div ref="container" class="h-full w-full overflow-y-auto pb-5">
      <slot />
    </div>
    <div
      ref="letters"
      class="absolute right-0 max-h-full shrink-0 self-center text-center xs:mr-[15px]"
    >
      <transition
        enter-active-calss="transition-transform transition-opacity duration-300"
        leave-active-calss="transition-transform transition-opacity duration-300"
        enter-class="opacity-0 translate-x-1/2"
        leave-to-class="opacity-0 translate-x-1/2"
        name="scroll-label-slide"
      >
        <div
          v-show="touchLabelVisible"
          class="pointer-events-none absolute z-1 h-10 w-10 -translate-y-1/2 transform"
          :style="{ top: labelTopOffset, right: 'calc(100% + 20px)' }"
        >
          <span
            class="absolute block h-full w-full -rotate-45 transform rounded-full rounded-br-none bg-primary"
          />
          <span class="relative text-2xl font-bold leading-10 text-white">
            {{ hoveredLetter }}
          </span>
        </div>
      </transition>

      <div
        v-for="letter in lettersList"
        :key="letter"
        class="cursor-pointer select-none px-1.5 py-[5px] text-xs font-bold leading-none text-neutral-400 transition-colors transition-opacity duration-200 hover:text-primary"
        :class="{ 'opacity-0': touchLabelVisible }"
        :data-letter="letter"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        @click="onLetterClick(letter)"
      >
        {{ letter }}
      </div>
    </div>
  </div>
</template>
