<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import GroupInfo from '/~rec/components/group/group-info.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'group-info-mobile-modal',
  components: {
    BaseMdl,
    GroupInfo,
  },
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return { isMeAdmin, isMeSuperAdmin }
  },
  data() {
    return {
      usersProcessing: false,
    }
  },
  computed: {
    canEditMembers() {
      return this.group?.isMeOwner || this.isMeAdmin || this.isMeSuperAdmin
    },
    usersMap() {
      return this.group?.members?.hits?.map((u) => u.id)
    },
  },
  methods: {
    back() {
      modal.hide()
      this.$emit('back')
    },
    filterUsers(allUsers) {
      return allUsers.filter((user) => this.usersMap.includes(user.id))
    },
    async removeUserHandler(memberId) {
      await this.group.detachMembers([memberId])

      await this.group?.members?.refresh()
    },
    openMembers() {
      modal.show('rec-users-manager', {
        props: {
          title: () => `${this.group.name} (${this.group.members.hits.length})`,
          removable: this.canEditMembers,
          selectable: this.canEditMembers,
          multi: true,
          getCurrentUsers: () => this.group?.members?.hits || [],
          filter: this.filterUsers,
          removeHandler: this.removeUserHandler,
          disableUser: (id) =>
            this.group.members.hits.map((v) => v.id).includes(id),
          disabledLabel: 'Already in the group',
          hideCurrent: true,
        },
        on: {
          'user-select': async (members) => {
            this.usersProcessing = true
            const membersIds = members.map((m) => m.id)

            await this.group.attachMembers(membersIds)
            await this.group.members.refresh()

            this.usersProcessing = false
          },
        },
      })
    },
  },
}
</script>

<template>
  <base-mdl
    fullscreen
    modal-styles="max-height: 43rem; margin-bottom: 0"
    :layout="false"
    modal-class="rounded-t-3xl"
    transition="slide-bottom"
    class="ease transform duration-500"
    width="screen"
  >
    <group-info
      :group="group"
      :users-processing="usersProcessing"
      @close="back"
      @open-members="openMembers"
    />
  </base-mdl>
</template>
