<script>
import NabVerificationForm from '../form/nab-verification-form.vue'
import bottomSheet from '/~/core/bottom-sheet'

export default {
  name: 'nab-verification-bottom-sheet',
  components: {
    NabVerificationForm,
  },
  setup(props, { emit }) {
    function onComplete(data) {
      emit('hide')
      emit('done', data)
    }

    function onFailed(data) {
      emit('hide')
      emit('fail', data)
    }

    function showNabCancelSheet() {
      bottomSheet.show('nab-verification-cancel', {
        to: props.to,
        closable: false,
        on: {
          submit() {
            emit('hide')
            emit('cancel')
          },
        },
      })
    }

    return {
      onComplete,
      onFailed,
      showNabCancelSheet,
    }
  },
}
</script>

<template>
  <div>
    <div class="mb-[30px]">
      <div class="px-[30px] pt-6 text-center text-2xl font-bold text-primary">
        Processing
      </div>
      <div class="text-center text-sm">
        May take up to 30 seconds. Please wait.
      </div>
    </div>

    <nab-verification-form
      v-bind="$attrs"
      @done="onComplete"
      @fail="onFailed"
      @cancel="showNabCancelSheet"
    />
  </div>
</template>
