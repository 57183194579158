<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerModal from '/~/components/drawer/components/drawer-modal.vue'
import { usePayeeCheckout } from '/~/composables/checkout'

export default {
  name: 'profile-pay-cancel-transaction',
  components: {
    BaseButton,
    BaseIcon,
    DrawerModal,
  },
  setup() {
    const router = useRouter()
    const { resetPayment } = usePayeeCheckout()

    const isVisible = ref(false)

    function show() {
      isVisible.value = true
    }

    function hide() {
      isVisible.value = false
    }

    function onConfirmClick() {
      resetPayment()
      hide()
      router.push({
        hash: '#profile-home',
      })
    }

    return {
      isVisible,
      show,
      hide,
      onConfirmClick,
    }
  },
}
</script>

<template>
  <drawer-modal :visible="isVisible" @hide="hide">
    <div class="pt-11 pb-5">
      <base-icon svg="v2/custom/deny" :size="67" class="mx-auto text-white" />
      <div class="mt-5 text-center text-xl font-bold">Cancel transaction</div>
      <div class="mt-4 text-center">
        Are you sure you want to cancel this transaction?
      </div>
      <div class="mt-6 grid grid-cols-2 gap-5">
        <base-button look="outlined-color" class="w-full" @click="hide">
          Back
        </base-button>
        <base-button class="w-full" @click="onConfirmClick">
          Confirm
        </base-button>
      </div>
    </div>
  </drawer-modal>
</template>
