<script>
export default {
  name: 'checkout-summary-address',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    billing: {
      type: Object,
      default: null,
    },
    shipping: {
      type: Object,
      default: null,
    },
  },
  computed: {
    addresses() {
      return [
        {
          label: 'Billing',
          data: this.billing,
        },
        {
          label: 'Shipping',
          data: this.shipping,
        },
      ].filter((i) => Boolean(i.data))
    },
  },
}
</script>

<template>
  <div v-if="loading || addresses.length > 0" class="space-y-2.5">
    <div v-if="loading" class="flex flex-col items-start space-y-[5px]">
      <div
        class="w-16 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
      >
        loading
      </div>
      <div
        class="w-32 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
      >
        loading
      </div>
      <div
        class="w-40 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
      >
        loading
      </div>
    </div>
    <template v-else>
      <div
        v-for="{ label, data } of addresses"
        :key="label"
        class="space-y-[5px]"
      >
        <h3 class="font-bold text-eonx-neutral-600">
          {{ label }}
        </h3>
        <div>
          <div class="font-bold">{{ data.firstName }} {{ data.lastName }}</div>
          <div class="text-neutral-400">
            {{ data.streetAddress }} {{ data.suburb }}
            <span v-if="data.state" class="uppercase">
              {{ data.state }}
            </span>
            {{ data.postcode }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
