<script>
import DetailsPlaceholder from '/~rec/components/placeholder/placeholder-event-group-details.vue'
import PostCreator from '/~rec/components/post/creator/post-creator.vue'
import EventHeader from '/~rec/components/event/event-header.vue'
import EventFeed from '/~rec/components/event/feed/event-feed.vue'
import DetailsSidebarRoot from '/~rec/components/details/details-sidebar-root.vue'
import EventInfo from '/~rec/components/event/event-info.vue'
import MembersManager from '/~rec/components/users/members-manager.vue'
import EventStatus from '/~rec/components/event/event-status.vue'
import { useRecProfile } from '/~rec/composables/profile'
import { useFeeds } from '/~rec/composables/feeds'

export default {
  name: 'recroom-event-view',
  components: {
    MembersManager,
    DetailsPlaceholder,
    DetailsSidebarRoot,
    PostCreator,
    EventHeader,
    EventFeed,
    EventInfo,
    EventStatus,
  },
  provide() {
    return {
      sources: this.sources,
    }
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
    eventId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { isMeAdmin, isMeSuperAdmin } = useRecProfile()
    const { isNewPostProcessing } = useFeeds()

    return { isMeAdmin, isMeSuperAdmin, isNewPostProcessing }
  },
  data() {
    return {
      sources: {
        source: null,
      },
      showSidebar: true,
      showListManager: false,
      usersProcessing: false,
      isShowScrollHeader: false,
    }
  },
  computed: {
    canRemoveMembers() {
      return this.event?.isMeOwner || this.isMeAdmin || this.isMeSuperAdmin
    },
  },
  watch: {
    eventId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.sources.source = this.event
    },
    async onPostCreated() {
      this.isNewPostProcessing = true
      await this.event.posts.refresh()
      setTimeout(() => {
        this.isNewPostProcessing = false
      }, 200)
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
    },
    async removeUserHandler(id) {
      this.usersProcessing = true
      // This enpoint is used to update event details and event members
      const data = {
        banner_url: this.event.image,
        private: this.event.isPrivate ? 1 : 0,
        type: this.event.type,
        name: this.event.name,
        startsAt: this.event.startsAt,
        endsAt: this.event.endsAt,
        start_timezone: this.event.timeZone,
        location: this.event.location,
        description: this.event.description,
        members: this.event.members.hits
          .filter((u) => u.id !== id)
          .map((member) => {
            return {
              id: member.id,
              status: member.status,
            }
          }),
      }

      await this.event.edit(data)
      await this.event.members.refresh()

      this.usersProcessing = false
    },
    userScroll(event) {
      const { scrollTop } = event.target

      if (scrollTop >= 53 && !this.isShowScrollHeader) {
        this.isShowScrollHeader = true
      }
      if (scrollTop < 35 && this.isShowScrollHeader) {
        this.isShowScrollHeader = false
      }
    },
    onNewPostsLoaded() {
      this.$refs.content.scrollTop = 0
    },
  },
}
</script>

<template>
  <div
    ref="content"
    class="mx-auto w-full flex-1 overflow-y-auto"
    @scroll="userScroll"
  >
    <div v-if="event">
      <template v-if="!showListManager">
        <div class="sticky top-0 z-20 mt-[30px] bg-gray-50">
          <div class="mx-auto max-w-5xl">
            <event-header
              :event="event"
              class="mb-4"
              :mini-header="isShowScrollHeader"
              @toggle="toggleSidebar"
            />
          </div>

          <div class="bg-white shadow-sm">
            <post-creator
              :source="event"
              class="mx-auto max-w-2xl"
              @created="onPostCreated"
            />
          </div>
        </div>

        <div class="mx-auto max-w-2xl px-[30px]">
          <event-feed :event="event" @posts-loaded="onNewPostsLoaded" />
        </div>
      </template>
      <div class="mx-auto w-full max-w-5xl pt-[30px]">
        <members-manager
          v-if="showListManager"
          :removable="canRemoveMembers"
          :name="event.name"
          label="Event members"
          :multi="false"
          :current-users="event.members.hits"
          :remove-handler="removeUserHandler"
          @close="showListManager = false"
        >
          <template #user="{ user }">
            <event-status :event="event" :user="user" class="float-right" />
          </template>
        </members-manager>
      </div>
      <portal to="rec-sidebar">
        <details-sidebar-root :is-show="showSidebar">
          <event-info
            :event="event"
            :users-processing="usersProcessing"
            @close="showSidebar = false"
            @open-members="showListManager = true"
          />
        </details-sidebar-root>
      </portal>
    </div>
    <div v-else class="pt-[30px]">
      <details-placeholder />
    </div>
  </div>
</template>
