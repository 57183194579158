import get from 'lodash-es/get'
import { createDate, formatDate } from '/~/utils/format/date'
import api from '/~rec/core/api'
import { RecroomCelebration } from '/~rec/core/celebration'
import { useRecProfile } from '/~rec/composables/profile'

const { orgId } = useRecProfile()

export class CelebrationsProcessor {
  constructor() {
    this.processing = true
    this.months = []
    this.stopAppend = false
  }

  get length() {
    return this.months.length
  }

  get isEmpty() {
    return this.length === 0
  }

  getData(startDate) {
    this.months = []
    const getThreeMonthData = this.getThreeMonth(createDate(startDate))

    return getThreeMonthData
  }

  async refresh() {
    this.months = await Promise.all(
      this.months.map((month) => this.getMonthCelebrations(month.date))
    )
  }

  /*
   * Getting celebrations for the current and two following months
   */
  async getThreeMonth(startDate) {
    this.processing = true

    let months = await Promise.all([
      this.getMonthCelebrations(startDate),
      // this.getMonthCelebrations(startDate.add(1, 'month')),
      // this.getMonthCelebrations(startDate.add(2, 'month')),
    ])

    let filteredCelebrations

    if (months && months[0] && months[0].celebrations) {
      // Here we need to detect either these celebrations from the future either them from the past.
      filteredCelebrations = months[0].celebrations.some(
        (celebration) => celebration.date.$M >= createDate(startDate).$M
      )
    }

    if (!filteredCelebrations) {
      months = []
    }

    this.months = this.months.concat(months)
    this.processing = false
  }

  /*
   * To meet design requirements we must get all the celebrations in a month at once
   */
  async getMonthCelebrations(monthDate) {
    const monthCelebrations = new CelebrationsMonth(monthDate)

    await monthCelebrations.fetch()

    return monthCelebrations
  }

  /*
   * Getting celebrations for the three next months
   */
  next() {
    const lastMonthDate = get(this.months, [this.length - 1, 'date'])

    if (lastMonthDate) {
      return this.getThreeMonth(lastMonthDate.add(1, 'month'))
    } else {
      console.warn('Last month date is broken')
    }
  }
}

/*
 * Helper class for receiving and rendering celebrations in a month by the days
 */
export class CelebrationsMonth {
  constructor(monthDate) {
    this.key = formatDate('monthyear', monthDate.toDate())
    this.date = monthDate
    this.celebrations = []
    this.error = ''
  }

  get isEmpty() {
    return this.celebrations.length === 0
  }

  get eventsByDays() {
    if (this.celebrations.length > 0) {
      const days = {}

      for (let idx = 0; idx < this.celebrations.length; idx++) {
        const day = this.celebrations[idx].date
        const formatted = day.format('daymonthyear')

        days[formatted] = {
          date: day,
          key: formatted,
          events: [],
        }
      }

      this.celebrations.forEach((celebration) => {
        const day = formatDate('daymonthyear', celebration.startsAt)

        if (!days[day]) return
        days[day].events.push(celebration)
      })

      Object.keys(days).forEach((day) => {
        days[day].events = days[day].events.sort((a, b) => {
          const startA = new Date(a.startsAt)
          const startB = new Date(b.startsAt)

          if (startA.getTime() === startB.getTime()) {
            return new Date(a.endsAt) - new Date(b.endsAt)
          }
          return startA - startB
        })
      })

      return Object.keys(days).map((key) => {
        return days[key]
      })
    } else {
      return []
    }
  }

  async fetch() {
    // TODO add filters as in the events-processor.js
    // and change isEmpty condition in the events-month.vue
    const url = `organisations/${orgId.value}/celebrations`

    const startDate = encodeURIComponent(
      this.date.startOf('day').format('YYYY-MM-DDTHH:mmZZ')
    )
    const endDate = encodeURIComponent(
      this.date.add(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mmZZ')
    )

    const response = await api.get(url, {
      params: {
        startsAt: startDate,
        endsAt: endDate,
      },
    })

    // If there are no next page(more events in future)
    // then stop show loader.
    if (response.data && !response.data.pagination.has_next_page) {
      this.stopAppend = false
    } else {
      this.stopAppend = true
    }

    this.celebrations = get(response, 'data.items', []).map((item) => {
      return new RecroomCelebration(item)
    })
  }
}
