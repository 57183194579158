<script>
import { computed } from 'vue'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { useAddresses } from '/~/composables/addresses'
import { useCart } from '/~/composables/cart'
import { useProvider } from '/~/composables/provider'
import AddressItem from '../../components/addresses/address-item.vue'

export default {
  name: 'cart-menu-addresses',
  components: {
    AddressItem,
    BaseAsidePage,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { addCartOrderAddress, removeCartOrderAddress, cartOrderAddresses } =
      useCart()
    const { addresses, defaultAddress, firstAddress } = useAddresses()
    const { isBillPaymentsTemplate } = useProvider()

    const addressesWithoutDefault = computed(() => {
      return addresses.value.filter((address) => !address.default)
    })

    return {
      addCartOrderAddress,
      removeCartOrderAddress,
      cartOrderAddresses,
      addresses,
      defaultAddress,
      firstAddress,
      addressesWithoutDefault,
      ui,
      isBillPaymentsTemplate,
    }
  },
  computed: {
    type() {
      return this.meta.type
    },
    backRoute() {
      if (this.meta.back) {
        return this.meta.back
      } else if (this.isBillPaymentsTemplate) {
        return { name: 'purchase-checkout' }
      }

      return { hash: '#cart-checkout' }
    },
  },
  methods: {
    onSelectAddress(address) {
      this.addCartOrderAddress({ address, type: this.type })
      this.$router.replace(this.backRoute)
    },
    isSelected(addressId) {
      const { billing, shipping } = this.cartOrderAddresses

      return (
        (this.type === 'billing' && billing?.id === addressId) ||
        (this.type === 'shipping' && shipping?.id === addressId)
      )
    },
    getFallbackAddress(addressId) {
      if (this.defaultAddress && this.defaultAddress.id !== addressId) {
        return this.defaultAddress
      }

      const filteredAddresses =
        this.addresses.filter((item) => item.id !== addressId) || []

      return filteredAddresses[0] || null
    },
    async onRemoveAddress(addressId) {
      const { billing, shipping } = this.cartOrderAddresses
      const isBilling = billing?.id === addressId
      const isDelivery = shipping?.id === addressId
      const fallbackAddress = this.getFallbackAddress(addressId)

      if (isBilling || isDelivery) {
        if (fallbackAddress) {
          if (isBilling) {
            await this.addCartOrderAddress({
              address: fallbackAddress,
              type: 'billing',
            })
          }
          if (isDelivery) {
            await this.addCartOrderAddress({
              address: fallbackAddress,
              type: 'shipping',
            })
          }
        } else {
          await this.removeCartOrderAddress(this.type)
        }
      }
    },
    onAddAddress() {
      this.$router.push({ hash: `#cart-address-${this.type}` })
    },
  },
}
</script>

<template>
  <base-aside-page
    title="Select Address"
    :back="backRoute"
    :action="onAddAddress"
  >
    <template v-if="defaultAddress">
      <div class="mb-5 font-bold text-eonx-neutral-600">Default Address</div>
      <address-item
        :item="defaultAddress"
        class="mb-5"
        show-menu
        show-default
        :selected="isSelected(defaultAddress.id)"
        @click="onSelectAddress(defaultAddress)"
        @remove="onRemoveAddress(defaultAddress.id)"
      />
    </template>
    <div class="mb-5 font-bold text-eonx-neutral-600">Other Addresses</div>
    <address-item
      v-for="address in addressesWithoutDefault"
      :key="address.id"
      :item="address"
      class="mb-5"
      show-menu
      show-default
      :selected="isSelected(address.id)"
      @click="onSelectAddress(address)"
      @remove="onRemoveAddress(address.id)"
    />
  </base-aside-page>
</template>
