<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useUserEvents } from '/~/composables/user'
import PrivacyPolicy from '/~/views/privacy-policy/privacy-policy.vue'

export default {
  name: 'privacy-policy-modal',
  components: {
    PrivacyPolicy,
    BaseMdl,
  },
  setup() {
    const { privacyPolicyVisited } = useUserEvents()

    privacyPolicyVisited({ type: 'modal' })
  },
}
</script>

<template>
  <base-mdl
    width="lg"
    fullscreen="mobile"
    height="lg"
    title="Privacy Policy"
    layout="v2"
  >
    <privacy-policy />
  </base-mdl>
</template>
