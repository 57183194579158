<script setup lang="ts">
import { computed } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { Statement } from '/~/composables/statements'

const props = withDefaults(
  defineProps<{
    statement?: Statement | null
  }>(),
  {
    statement: null,
  }
)

const isDueToday = computed(() => {
  const { statement } = props

  return statement?.active && statement.statementAccount?.isDueToday
})
const isDueSoon = computed(() => {
  const { statement } = props

  return statement?.active && statement.statementAccount?.isDueSoon
})
const isOverdue = computed(() => {
  const { statement } = props

  return statement?.active && statement.statementAccount?.isOverdue
})
const color = computed(() => {
  if (isDueToday.value || isDueSoon.value || isOverdue.value) {
    return 'text-orange-700'
  }

  return 'text-eonx-neutral-800'
})

const label = computed(() => {
  if (isDueSoon.value) {
    return 'Due soon'
  }

  return 'Due date'
})

const processingDueDate = computed(() =>
  props.statement?.processingDueDate &&
  props.statement?.statementAccount?.isShowData
    ? formatDate('daymonthyearnumeric', props.statement.processingDueDate)
    : '-'
)
</script>

<template>
  <div class="flex flex-row items-start justify-between md:h-12 md:flex-col">
    <div
      class="mb-[5px] mt-2 text-sm leading-none text-eonx-neutral-600 sm:mt-0"
    >
      {{ label }}
    </div>
    <div :class="color" class="whitespace-nowrap">
      <span v-if="isDueToday">Today</span>
      <span v-else-if="isOverdue" class="flex items-center space-x-1">
        <span>Overdue</span>
      </span>
      <span v-else>
        {{ processingDueDate }}
      </span>
    </div>
  </div>
</template>
