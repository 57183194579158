<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { cardCode } from '/~/utils/cards'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

export default {
  name: 'payment-successful-pay-with',
  components: {
    BaseIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { translate } = useLocalization()

    return {
      formatDollar,
      formatPoints,
      PaymentMethodType,
      translate,
    }
  },
  computed: {
    code() {
      const issuingNetwork = getCardTypeByNumber(this.item.number)
      const brand = cardCode({ brand: issuingNetwork?.type })

      return brand
    },
  },
}
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center">
      <base-icon
        v-if="item.type === PaymentMethodType.points"
        svg="points/token"
        class="shrink-0 text-primary"
        size="3xl"
      />
      <div
        v-else-if="item.type === PaymentMethodType.bankAccount"
        class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-primary text-white"
      >
        <base-icon svg="v2/custom/bank" size="md" />
      </div>
      <base-icon
        v-else-if="item.type === PaymentMethodType.creditCard"
        :svg="`cards/${code}`"
        size="3xl"
        class="shrink-0"
      />
      <div v-else class="h-12 w-12 shrink-0 rounded-full bg-eonx-neutral-50" />

      <div class="ml-[15px] flex flex-col">
        <div class="font-bold text-eonx-neutral-800">
          {{ item.name || item.accountName || 'Points' }}
        </div>

        <div
          v-if="item.type === PaymentMethodType.creditCard"
          class="text-sm text-eonx-neutral-600"
        >
          **** **** **** {{ item.number.slice(-4) }}
        </div>
        <div
          v-if="item.type === PaymentMethodType.bankAccount"
          class="text-sm text-eonx-neutral-600"
        >
          {{
            translate('bankAccount.details', {
              acc: `*** *** *${item.number.slice(-2)}`,
              bsb: item.prefix,
            })
          }}
        </div>
        <div
          v-if="item.type === PaymentMethodType.points"
          class="text-sm text-eonx-neutral-600"
        >
          Using {{ formatPoints(item.points) }} PTS
        </div>
      </div>
    </div>

    <div v-if="item.finalAmount" class="text-eonx-neutral-600">
      {{ formatDollar(-item.finalAmount) }}
    </div>
  </div>
</template>
