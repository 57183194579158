<script>
import ListMobile from './list.mobile.vue'
import ListDesktop from './list.desk.vue'
import ui from '/~/core/ui'

export default {
  name: 'scheduled-payments-list',
  functional: true,
  render(h, ctx) {
    return h(ui.mobile ? ListMobile : ListDesktop, ctx.data, ctx.children)
  },
}
</script>
