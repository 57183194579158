<script>
import DrawerLoyaltyMemberships from '/~/drawer/loyalty-cards/loyalty-cards.vue'

export default {
  name: 'profile-loyalty-memberships',
  components: {
    DrawerLoyaltyMemberships,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    onAdd() {
      this.$router.push({ hash: '#profile-loyalty-brands' })
    },
    onOpen(id) {
      this.$router.push({
        hash: `#profile-loyalty-card-details/${id}`,
      })
    },
  },
}
</script>

<template>
  <drawer-loyalty-memberships :id="id" @add="onAdd" @open="onOpen" />
</template>
