import { reactive, ref } from 'vue'
import HealthArticleProcessor from '/~/extensions/health/core/articles-processor'
import HealthExerciseProcessor from '/~/extensions/health/core/exercises-processor'
import HealthRecipesProcessor from '/~/extensions/health/core/recipes-processor'
import Sections from '/~/extensions/health/mocks/assessment-questions'
import AssessmentTags from '/~/extensions/health/mocks/assessment-tags'
import Storage from '/~/utils/localStorage'

const activeSectionIdx = ref(0)
const activeQuestionIdx = ref(0)
const sections = ref([...Sections])
const answers = ref([])
const tags = reactive({ ...AssessmentTags })
const userTags = ref({})
const challenges = reactive(
  new HealthExerciseProcessor({
    index: 'production_wellness_solutions_v2',
  })
)
const recipes = reactive(
  new HealthRecipesProcessor({
    index: 'production_bodygoodfood',
  })
)
const articles = reactive(new HealthArticleProcessor())

function tagsByName(name) {
  return userTags.value[name]
}

function setAnswer(value) {
  answers.value = [
    ...answers.value.slice(0, activeSectionIdx.value),
    [
      ...(answers.value[activeSectionIdx.value] || []).slice(
        0,
        activeQuestionIdx.value
      ),
      { answer: value },
    ],
  ]
}

function init() {
  const storage = Storage.get('assessment') || {}

  activeQuestionIdx.value = storage.activeQuestionIdx || 0
  activeSectionIdx.value = storage.activeSectionIdx || 0
  answers.value = storage.answers || []
  userTags.value = storage.tags || {}
}

function toPrevQuestion() {
  if (activeQuestionIdx.value > 0) {
    activeQuestionIdx.value--
  } else if (activeSectionIdx.value > 0) {
    activeSectionIdx.value--
    activeQuestionIdx.value =
      sections.value[activeSectionIdx.value].questions.length - 1
  }
}

function toNextQuestion(value) {
  const activeSection = sections.value[activeSectionIdx.value]

  setAnswer(value)

  if (activeQuestionIdx.value < activeSection.questions.length - 1) {
    activeQuestionIdx.value++
  } else if (activeSectionIdx.value < sections.value.length - 1) {
    activeQuestionIdx.value = 0
    activeSectionIdx.value++
  } else {
    return 'done'
  }
}

function syncUserAnswers() {
  Storage.set(
    'assessment',
    JSON.stringify({
      activeSectionIdx: activeSectionIdx.value,
      activeQuestionIdx: activeQuestionIdx.value,
      answers: answers.value,
      tags: userTags.value,
    })
  )
}

export function useAssessment() {
  return {
    activeSectionIdx,
    activeQuestionIdx,
    sections,
    answers,
    tags,
    userTags,
    challenges,
    recipes,
    articles,
    tagsByName,

    init,
    toPrevQuestion,
    toNextQuestion,
    syncUserAnswers,
  }
}
