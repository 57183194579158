<script setup lang="ts">
import modal from '/~/core/mdl'
import PointsBalance from '/~/templates/bill-payments/components/home/points-balance.vue'
import { useMembership } from '/~/templates/bill-payments/composables'

const { isMembershipSwitchingEnabled } = useMembership()

function onMembershipNameClicked() {
  if (isMembershipSwitchingEnabled.value) {
    modal.show('init-tier-options-modal')
  }
}
</script>

<template>
  <points-balance @membership-name-clicked="onMembershipNameClicked" />
</template>
