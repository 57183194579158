<script setup lang="ts">
import { watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import { useActivity } from '/~/composables/activity'
import ActivityDesk from './activity.desk.vue'
import ActivityMobile from './activity.mobile.vue'

const router = useRouter()
const route = useRoute()
const { routeState, syncState, getContent } = useActivity()

watch(routeState, (value) => {
  router.replace(value)
})

watch(
  () => ({ ...route }),
  (to, from) => {
    if (
      to.fullPath.replace(to.hash, '') !== from?.fullPath.replace(from.hash, '')
    ) {
      syncState({ to })
      nextTick(() => {
        // TODO: sync state -> queryString computed -> queryString watch -> activity getData
        // check if we can simplify the flow above and remove nextTick
        getContent()
      })
    }
  },
  { immediate: true }
)
</script>

<template>
  <activity-mobile v-if="ui.mobile" />
  <activity-desk v-else />
</template>
