import { createDate } from '/~/utils/format/date'
import api from '/~rec/core/api'
import { ApiResponseData } from '/~/types/api'
import { Chat, Message } from '/~/types/extensions'
import { MessageStatus } from '/~/dict/recroom'
import { env } from '/~/utils'
import { RecroomChatMember } from '/~rec/core/chat-member'
import { RecroomChatMessage } from '/~rec/core/chat-message'
import { ChatMessagesProcessor } from '/~rec/core/chat-messages-processor'
import { useRecProfile } from '/~rec/composables/profile'

const { profile, orgId, myRecId } = useRecProfile()

export class RecroomChatRoom {
  constructor(raw: Partial<Chat> = {}) {
    this.raw = raw

    this.messages = new ChatMessagesProcessor({
      id: this.id,
    })

    this.bindEvents()
  }

  get id() {
    return this.raw.id
  }

  get members() {
    return (this.raw.participants || []).map((i) => new RecroomChatMember(i))
  }

  get searchValue() {
    return (
      this.name.toLowerCase() +
      this.members.map((member) => member.fullName.toLowerCase()).join(' ')
    )
  }

  get me() {
    return this.members.find((member) => member.id === myRecId.value)
  }

  get membersWithoutMe() {
    return this.members.filter((member) => member.id !== myRecId.value)
  }

  get activeMembersWithoutMe() {
    return this.members.filter((member) => {
      return member.id !== myRecId.value && member.status !== 'left'
    })
  }

  get lastMessage() {
    const rawLast = this.raw.last_message

    return (
      this.messages.lastMessage ||
      (rawLast ? new RecroomChatMessage(rawLast) : null)
    )
  }

  get title() {
    return this.name || this.membersWithoutMe.map((m) => m.fullName).join(', ')
  }

  get name() {
    return this.raw.name || ''
  }

  get description() {
    return this.raw.description || ''
  }

  get isChannel() {
    return this.raw.type === 'channel' || this.membersWithoutMe.length > 1
  }

  get isPrivate() {
    return this.raw.private
  }

  get isMeOwner() {
    return Boolean(this.raw.participants.find((member) => {
      return member.user_id === myRecId.value && member.role === 'owner'
    }))
  }

  get hasUnreadMessages() {
    return this.raw.unread_count > 0
  }

  get updatedAt() {
    return createDate(this.raw.updated_at)
  }

  static async create(data) {
    const response = await api.post<ApiResponseData<Chat>>(
      '/chat/rooms',
      data,
      { notify: false }
    )

    return new RecroomChatRoom(response.data)
  }

  static async fetchById(id) {
    const response = await api.get<ApiResponseData<Chat>>(`/chat/rooms/${id}`)

    return new RecroomChatRoom(response.data)
  }

  async edit(form) {
    const response = await api.put(`/chat/rooms/${this.id}`, form, {
      notify: false,
    })

    this.raw = response.data
  }

  async update() {
    const response = await api.get(`/chat/rooms/${this.id}`)

    this.raw = response.data
  }

  async delete() {
    await api.delete(`/chat/rooms/${this.id}`)
  }

  leave({ ownerId } = {}) {
    return api.post(`/chat/rooms/${this.id}/leave`, {
      userIds: [this.me.memberId],
      owner: ownerId || '',
    })
  }

  async removeMembers(usersIds: string[]) {
    const { data } = await api.post(
      `/chat/rooms/${this.id}/remove-participants`,
      {
        users: usersIds,
      }
    )

    this.raw.participants = data.participants.filter(
      (p) => !usersIds.includes(p.id)
    )
  }

  inviteMembers(membersIds: string[]) {
    return api.post(`/chat/rooms/${this.id}/invite`, {
      userIds: membersIds,
    })
  }

  async meTyping(typing = false) {
    const participant = this.me.memberId

    await api.put(`/chat/rooms/${this.id}/participants/${participant}/typing`, {
      typing,
    })
  }

  async updateMembers(membersIds: string[]) {
    const { members } = this
    const toRemove: string[] = []
    const toInvite: string[] = []

    members.forEach((member) => {
      if (member.id === myRecId.value) return

      const userExist = Boolean(membersIds.find((id) => id === member.id))

      if (!userExist) {
        toRemove.push(member.id)
      }
    })

    membersIds.forEach((memberId) => {
      if (memberId === myRecId.value) return

      if (toRemove.indexOf(memberId) < 0) {
        toInvite.push(memberId)
      }
    })

    await Promise.all([
      toRemove.length && this.removeMembers(toRemove),
      toInvite.length && this.inviteMembers(toInvite),
    ])

    this.update()
  }

  async createMessage(message: Partial<Message>) {
    this.messages.pushMessage({
      attachments: [],
      views: [],
      sending: true,
      body: 'sending you message',
      created_at: new Date().toISOString(),
      request_id: message.request_id,
      room_id: this.id,
      type: 'text',
      updated_at: new Date().toISOString(),
      user_id: myRecId.value,
    })

    const { data } = await api.post<ApiResponseData<Message>>(
      `chat/rooms/${this.id}/messages`,
      message
    )

    this.messages.pushMessage(data)
  }

  async updateMessage(message, form) {
    message.update({ sending: true })

    const response = await api.put<ApiResponseData<Message>>(
      `chat/rooms/${this.id}/messages/${message.id}`,
      form
    )

    message.update({ ...response.data, sending: false })
  }

  async deleteMessage(message) {
    message.updateBody(MessageStatus.DELETED)
    await api.delete(`chat/rooms/${this.id}/messages/${message.id}`)
  }

  onMessageCreated(data) {
    const message = this.messages.pushMessage(data)

    if (!message.isMeAuthor) {
      this.raw.unread_count += 1
    }
  }

  onMessageUpdated(data) {
    this.messages.pushMessage(data)
  }

  onRoomUpdated(data) {
    console.warn('on room updated', data)
  }

  onParticipantTyping(data) {
    const member = this.raw.participants.find(
      ({ id }) => id === data.participant_id
    )

    member.typing = data.is_typing
  }

  onMessageDeleted(data) {
    this.messages.pushMessage({ ...data, body: MessageStatus.DELETED })
  }

  bindEvents() {
    const channelId = `private-chat-${env}-${orgId.value}-room-${this.id}`
    const roomChannel = profile.pusher.subscribe(channelId)

    roomChannel.bind('message-created', (data) => {
      this.onMessageCreated(data)
    })
    roomChannel.bind('message-updated', (data) => {
      this.onMessageUpdated(data)
    })
    roomChannel.bind('message-deleted', (data) => {
      this.onMessageDeleted(data)
    })
    roomChannel.bind('room-updated', (data) => {
      this.onRoomUpdated(data)
    })
    roomChannel.bind('participant-typing', (data) => {
      this.onParticipantTyping(data)
    })

    this.roomChannel = roomChannel
  }

  destroy() {
    this.roomChannel.unbind()
  }

  async markMessagesAsViewed(messages: string[]) {
    const { data } = await api.post(`/chat/rooms/${this.id}/messages/viewed`, {
      messages,
    })

    this.raw.unread_count -= messages.length

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.messages.pushMessage(data[key])
      }
    }
  }
}
