<script>
import { nextTick } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useRetailer } from '/~/extensions/giftcards/composables'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.vue'
import EpurchaseTile from '/~/components/drawer/components/tiles/drawer-e-purchases-tile.vue'
import { useLoyaltyCards } from '/~/composables/loyalty-cards'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'
import { useUI } from '/~/composables/ui'
import { useMobileMenu } from '/~/composables/ui/use-mobile-menu'
import BarcodeUnlocked from './barcode-unlocked.vue'
import PurchaseExternalModal from '../../modals/drawer-epurchase-external-modal.vue'
import PurchaseBalanceModal from '../../modals/menu-epurchase-balance-modal.vue'

export default {
  name: 'drawer-epurchase-details',
  components: {
    EpurchaseTile,
    BarcodeUnlocked,
    PurchaseBalanceModal,
    PurchaseExternalModal,
    BaseButton,
    BaseAsidePage,
    BaseLoader,
    BaseLink,
    DrawerActions,
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
  },
  setup() {
    const { getAssociatedLoyaltyCard } = useLoyaltyCards()
    const {
      purchases,
      selectedPurchaseCard,
      getCardDetails,
      archiveGiftCard,
      isActivePurchasesLoaded,
    } = usePurchases()
    const { toggleEwalletMenu } = useUI()
    const { retailer, getRetailer } = useRetailer()
    const { isCashrewardsProvider } = useProvider()
    const { hideMobileMenu } = useMobileMenu()

    return {
      purchases,
      bottomSheet,
      isCashrewardsProvider,
      getAssociatedLoyaltyCard,
      isActivePurchasesLoaded,
      card: selectedPurchaseCard,
      getCardDetails,
      archiveGiftCard,
      formatDate,
      formatDollar,
      toggleEwalletMenu,
      hideMobileMenu,
      retailer,
      getRetailer,
      ui,
    }
  },
  data() {
    return {
      loading: true,
      detailsLoading: false,
      associatedLoading: false,
      showBalanceModal: false,
      showExternalModal: false,
      associatedCard: null,
    }
  },
  computed: {
    uuid() {
      return this.card?.uuid
    },
    type() {
      return this.card?.type
    },
    retailerSlug() {
      return this.card?.retailerSlug
    },
    retailerName() {
      return this.card?.retailerName ?? ''
    },
    isCinema() {
      return this.type === 'cinema' || this.type === 'voucher'
    },
    isDining() {
      return this.type === 'dining'
    },
    isBunningsGiftcard() {
      return /bunnings/.test(this.retailerSlug ?? '')
    },
    isBalanceAvailable() {
      return this.card?.isBalanceAvailable
    },
    metaType() {
      return this.card?.metaType
    },
    title() {
      let title

      if (this.isDining) {
        title = this.metaType?.restaurantName
      }

      return title || this.retailerName
    },
    balance() {
      return this.card?.balance ?? 0
    },
    cinemaName() {
      return this.metaType?.cinemaName
    },
    details() {
      return this.card?.details
    },
    externalUrl() {
      return this.details?.externalUrl
    },
    barcodes() {
      const {
        items = [],
        barcode,
        barcodeType,
        cardNumber,
        pin,
      } = this.details ?? {}

      if (items.length > 0) {
        return items
      } else if (barcode) {
        return [
          {
            barcode,
            barcodeType,
            cardNumber,
            pin,
          },
        ]
      }

      return []
    },
    isBarcodes() {
      return this.barcodes.length > 0
    },
    onlineCardNumber() {
      return this.details?.onlineCardNumber
    },
    serialNumber() {
      return this.details?.serialNumber
    },
    inStoreCardNumber() {
      return this.details?.inStoreCardNumber
    },
    cardNumber() {
      return this.details?.cardNumber
    },
    pin() {
      return this.details?.pin
    },
    url() {
      return this.details?.url
    },
    buyAgainRoute() {
      if (this.isCinema) {
        return {
          name: 'cinema-location',
          params: {
            slug: this.retailerSlug,
          },
        }
      } else if (this.isDining) {
        return {
          name: 'dining-item',
          params: {
            slug: this.metaType?.restaurantSlug,
          },
        }
      } else {
        return {
          name: 'giftcards-retailer',
          params: {
            slug: this.retailerSlug,
          },
        }
      }
    },
    terms() {
      const { retailer } = this

      return {
        terms: retailer?.attributes?.terms_and_conditions,
        redemption: retailer?.attributes?.how_to_use_redemption,
        online: retailer?.attributes?.how_to_use_online,
        inStore: retailer?.attributes?.how_to_use_in_store,
        salesAssociate: retailer?.attributes?.how_to_use_sales_associate,
      }
    },
    hasTerms() {
      return Object.values(this.terms).some((v) => v)
    },
    actions() {
      return [
        {
          label: 'View Bunnings eGift Card',
          description: 'Access your eGift Card at any time',
          icon: 'menu/eye',
          hidden: !this.externalUrl || !this.isBunningsGiftcard,
          click: () => {
            if (ui.mobile) {
              this.showExternalModal = true
            } else {
              modal.show('purchase-external', {
                props: {
                  url: this.externalUrl,
                  type: 'giftcard',
                },
              })
            }
          },
        },
        {
          label: 'Edit balance manually',
          description: 'Update your remaining balance',
          icon: 'plain/edit2',
          hidden: !!this.isCinema || !this.isBalanceAvailable,
          click: () => {
            this.showBalanceModal = true
          },
        },
        {
          label: 'Add loyalty card',
          description: "Add retailer's loyalty card",
          hidden: !!this.associatedCard,
          disabled: this.associatedLoading,
          icon: 'plain/star-filled',
          click: () => {
            this.$emit('loyalty-brands')
          },
        },
        {
          label: 'Show loyalty card',
          description: "Display retailer's loyalty card",
          hidden: !this.associatedCard,
          disabled: this.associatedLoading,
          icon: 'plain/star-filled',
          click: () => {
            this.$emit('loyalty-card', this.associatedCard.id)
          },
        },
        {
          label: 'Buy Again',
          description: 'Buy another gift card from this retailer',
          icon: 'plain/cart-filled',
          hidden: this.isCashrewardsProvider,
          click: () => {
            this.buyAgain()
          },
        },
        {
          label: 'Print',
          description: 'Print a copy of your gift card',
          icon: 'menu/printer',
          hidden:
            this.isCashrewardsProvider ||
            !this.externalUrl ||
            ui.mobile ||
            this.isBunningsGiftcard,
          click: () => {
            if (ui.mobile) {
              this.showExternalModal = true
            } else {
              modal.show('purchase-external', {
                props: {
                  url: this.externalUrl,
                  type: this.isCinema ? 'voucher' : 'giftcard',
                },
              })
            }
          },
        },
        {
          label: 'Delete',
          description: 'Delete this from your ePurchases',
          icon: 'plain/trash',
          type: 'danger',
          confirm: {
            progressText: 'Deleting...',
            submit: async () => {
              try {
                await this.archiveGiftCard(this.uuid)
                this.$emit('delete')
              } catch (error) {
                console.error(error)
              }
            },
          },
        },
      ]
    },
    info() {
      const { purchasedAt, expiredAt, orderNumber } = this.card ?? {}

      return [
        {
          label: 'Card Number',
          value: this.cardNumber,
          icon: 'v2/custom/identification',
          hidden: this.isBarcodes,
        },
        {
          label: 'In Store',
          value: this.inStoreCardNumber,
          icon: 'v2/custom/user',
        },
        {
          label: 'Online No.',
          value: this.onlineCardNumber,
          icon: 'v2/custom/identification',
        },
        {
          label: 'PIN',
          value: this.pin,
          hidden: this.isBarcodes,
          icon: 'v2/custom/identification',
        },
        {
          label: 'Purchased',
          value: formatDate('daymonthyeartime', purchasedAt),
          icon: 'heroicons/outline/calendar',
        },
        {
          label: 'Expiry',
          value: formatDate('daymonthyeartime', expiredAt),
          icon: 'heroicons/outline/x-circle',
        },
        {
          label: 'Order No.',
          value: orderNumber,
          icon: 'heroicons/outline/hashtag',
        },
        {
          label: 'Serial No.',
          value: this.serialNumber,
          icon: 'v2/custom/user',
        },
      ].filter((i) => !!i.value && !i.hidden)
    },
  },
  watch: {
    isActivePurchasesLoaded: {
      handler(value) {
        if (value) {
          this.initDetails()
        }
      },
      immediate: true,
    },
    card: {
      handler() {
        if (this.isActivePurchasesLoaded) {
          this.initDetails()
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    emitter.off('menu-loyalty-card-update', this.onLoyaltyCardUpdate)
  },
  methods: {
    async initDetails() {
      if (this.detailsLoading) {
        return
      }

      this.detailsLoading = true

      emitter.on('menu-loyalty-card-update', this.onLoyaltyCardUpdate)

      if (!this.card) {
        this.card = this.purchases.active.hits.find(
          (card) => card.uuid === this.id
        )
      }

      const promises = [this.getCardDetails(this.uuid)]

      if (this.retailerSlug) {
        promises.push(
          this.getRetailer(this.retailerSlug),
          this.onLoyaltyCardUpdate()
        )
      }

      await Promise.all(promises)

      this.loading = false
      this.detailsLoading = false

      if (!this.card?.viewed) {
        let submitted = false

        this.bottomSheet.show('epurchase-unlock-v2', {
          style: {
            centered: true,
          },
          to: this.to,
          props: {
            onSubmit: () => {
              submitted = true
            },
          },
          on: {
            hide: () => {
              if (!submitted) {
                this.$router.back()
              }
            },
          },
        })
      }
    },
    async buyAgain() {
      this.toggleEwalletMenu(false)
      this.hideMobileMenu()
      await nextTick()
      this.$router.replace(this.buyAgainRoute)
    },
    async onDelete() {
      if (this.archiving) return

      this.archiving = true
      await this.archiveGiftCard(this.uuid)
      this.archiving = false

      this.$emit('delete')
    },
    showTerms() {
      this.$emit('terms')
    },
    async onLoyaltyCardUpdate() {
      this.associatedLoading = true
      return this.getAssociatedLoyaltyCard(this.retailerSlug)
        .then((response) => {
          this.associatedCard = response.data
        })
        .catch(() => {
          this.associatedCard = null
        })
        .finally(() => {
          this.associatedLoading = false
        })
    },
  },
}
</script>

<template>
  <base-aside-page
    :key="id"
    :title="title"
    :subtitle="formatDollar(balance)"
    class="flex flex-col"
  >
    <div class="min-h-56 px-[35px]">
      <epurchase-tile v-if="card" :card="card" details-view />
    </div>
    <div
      v-if="loading || !card || !details"
      class="flex h-48 w-full items-center justify-center"
    >
      <base-loader />
    </div>
    <div v-else class="space-y-6">
      <base-link v-if="!isCinema && url" :href="url" class="mb-5 w-full">
        <base-button full-width color="primary">Redeem</base-button>
      </base-link>
      <div
        v-if="isCinema && url"
        class="w-full space-y-2.5 rounded-lg bg-light p-2.5"
      >
        <div class="m-auto pt-6" style="width: 240px">
          <img :src="url" />
        </div>
      </div>

      <template v-if="isBarcodes">
        <div
          v-for="(item, index) in barcodes"
          :key="index"
          class="w-full space-y-2.5 rounded-lg bg-light p-2.5"
        >
          <barcode-unlocked
            class="min-h-20"
            :details="item"
            :options="{
              background: 'transparent',
              fontSize: 16,
              displayValue: false,
            }"
          />
          <dl v-if="item.cardNumber">
            <dt class="text-sm font-bold text-eonx-neutral-600">Card Number</dt>
            <dd class="col-span-2 font-bold">
              {{ item.cardNumber }}
            </dd>
          </dl>
          <dl v-if="item.pin">
            <dt class="text-sm font-bold text-eonx-neutral-600">PIN</dt>
            <dd class="col-span-2 font-bold">
              {{ item.pin }}
            </dd>
          </dl>
        </div>
      </template>

      <div class="space-y-2.5 rounded-lg bg-light p-2.5">
        <dl v-for="(item, index) in info" :key="index">
          <dt class="text-sm font-bold text-eonx-neutral-600">
            {{ item.label }}
          </dt>
          <dd class="col-span-2 break-words font-bold">
            {{ item.value }}
          </dd>
        </dl>
      </div>
    </div>

    <drawer-actions :actions="actions" />

    <div class="flex w-full flex-col items-center justify-center">
      <base-loader v-if="loading" />
      <base-button
        v-else-if="hasTerms"
        look="light-filled"
        full-width
        @click="showTerms"
      >
        View terms of use
      </base-button>
    </div>
    <purchase-external-modal
      v-if="showExternalModal"
      :to="to"
      :visible.sync="showExternalModal"
      :url="externalUrl"
      :type="isCinema ? 'voucher' : 'giftcard'"
    />
    <purchase-balance-modal
      v-if="showBalanceModal"
      :to="to"
      :visible.sync="showBalanceModal"
    />
  </base-aside-page>
</template>
