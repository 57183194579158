<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { PaymentMethodCreditCard } from '/~/types/api'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { getExpiryDate } from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'
import Desktop from '../cc-option/desktop.vue'
import Mobile from '../cc-option/mobile.vue'

interface Props {
  method: PaymentMethodCreditCard
  processingFeeOptions?: {
    loading: boolean
    value: number
  }
  isSelected?: boolean
  noPadding?: boolean
  isOptionSelected?: boolean
  taxation: {
    label: string
  }
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
  noPadding: false,
})

const router = useRouter()
const route = useRoute()

const { isCreditCardsNavEnabled } = useProvider()

const icon = computed(() => {
  if (props.method.brand === 'American Express') {
    return 'billpay/payment-methods/v1.5/amex'
  } else if (props.method.brand === 'Mastercard') {
    return 'billpay/payment-methods/v1.5/mastercard'
  } else {
    return 'billpay/payment-methods/v1.5/visa'
  }
})
const state = computed(() => (props.method?.state ?? '').toLowerCase())
const status = computed(() => (props.method?.status ?? '').toLowerCase())

const expiryDate = getExpiryDate(props.method)
const cardNumber = computed(() => `**** ${props.method.number.slice(-4)}`)

const isVerified = computed(
  () => status.value === 'verified' || !isCreditCardsNavEnabled.value
)
const isDisabled = computed(() => expiryDate.isExpired || isNotAllowed.value)
const isNotAllowed = computed(() => state.value === 'restricted')
const isActive = computed(() => state.value === 'active')

const component = computed(() => (ui.mobile ? Mobile : Desktop))

async function onClick(e: MouseEvent) {
  if (isDisabled.value) {
    e.stopPropagation()
    return
  }

  if (isVerified.value) {
    return
  }

  modal.hideAll()
  await router.push({
    hash: '#profile-payment-methods',
    query: route.query,
  })
  bottomSheet.show('method-verify', {
    to: 'menu-modal',
    props: {
      method: props.method,
    },
  })
}
</script>

<template>
  <component
    :is="component"
    v-bind="props"
    :card-number="cardNumber"
    :icon="icon"
    :is-expired="expiryDate.isExpired"
    :is-verified="isVerified"
    :is-not-allowed="isNotAllowed"
    :is-disabled="isDisabled"
    :is-active="isActive"
    @click-option="onClick"
  />
</template>
