<script>
import DefaultEstore from '/~/extensions/estore/templates/default/views/estore.vue'
import EstoreFiltersDesktop from '/~/extensions/estore/components/estore-filters.desktop.vue'
import EstoreFiltersMobile from '/~/extensions/estore/components/estore-filters.mobile.vue'
import EstoreCatalog from './estore-catalog.vue'

export default {
  components: {
    EstoreFiltersDesktop,
    EstoreFiltersMobile,
    EstoreCatalog,
  },
  extends: DefaultEstore,
}
</script>
