<script setup lang="ts">
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import { useCms } from '/~/composables/cms'
import { useUserEvents } from '/~/composables/user'

const { faqQuestions } = useCms()
const { faqVisited } = useUserEvents()

faqVisited({
  faq: faqQuestions.value,
})
</script>

<template>
  <div class="space-y-2.5">
    <base-collapse
      v-for="(item, idx) in faqQuestions"
      :key="idx"
      :autoscroll="false"
      trigger-class="px-5 py-2.5 border border-solid border-divider"
      show-icon
    >
      <template #trigger>
        {{ item.title }}
      </template>
      <div class="markup p-[15px]" v-html="item.text" />
    </base-collapse>
  </div>
</template>
