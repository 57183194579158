<script>
import DefaultEstoreProduct from '/~/extensions/estore/templates/default/views/product.vue'
import EstoreSummary from '../components/estore-summary.vue'

export default {
  name: 'sumbion-estore-product-view',
  components: {
    EstoreSummary,
  },
  extends: DefaultEstoreProduct,
}
</script>

<template>
  <div class="h-full overflow-y-auto px-5 pt-5 sm:px-0">
    <ew-details-layout
      :loading="loading"
      :ready="!loading"
      :title="retailerProductProps && retailerProductProps.name"
      :back="backRoute"
      :class="loading && 'px-5'"
      :placeholder-ratio="1.4"
    >
      <template #gallery>
        <estore-logo
          v-bind="retailerLogoProps"
          data-test="gallery"
          :default-src="defaultImage"
        />
      </template>

      <template #details>
        <estore-details
          v-bind="retailerDetailsProps"
          :tabs-props="{ look: 'v4' }"
          data-test="details"
        />
      </template>

      <template #summary>
        <estore-summary v-bind="retailerProductProps" data-test="summary" />
      </template>
    </ew-details-layout>
  </div>
</template>
