<script>
import ui from '/~/core/ui'
import get from 'lodash-es/get'
import Pin from '../components/qr-pin.vue'
import modal from '/~/core/mdl'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useQrConnect } from '/~/extensions/qr-connect/composables'

export default {
  name: 'qr-section-map',
  components: {
    Pin,
    BaseIcon,
    BaseMdl,
    BaseLoader,
  },
  props: {
    payload: {
      type: Object,
      default: () => ({}),
    },
    reserveWorkspace: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { map, searchAvailableBusinessAssetsWithPagination } = useQrConnect()

    return {
      ui,
      map,
      searchAssets: searchAvailableBusinessAssetsWithPagination,
    }
  },
  data() {
    return {
      loading: true,
      imageLoaded: false,
      page: 1,
      perPage: 500,
      pins: [],
      transform: null,
      image: null,
      pan: null,
      imageData: {
        scale: 1,
        y: 0,
      },
      defaultScale: 1.25,
    }
  },
  async mounted() {
    window.addEventListener('resize', this.onResize)
    this.searchAllAssets()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async searchAllAssets() {
      const { payload, perPage, page } = this
      const response = await this.searchAssets({ payload, perPage, page })

      const pins = get(response, 'items', [])
        .filter((p) => p.mapPosition)
        .filter((p) =>
          p.qrCodes.find(
            (c) => c.scanStrategy && c.scanStrategy.strategy === 'remote_claim'
          )
        )

      this.pins = [...this.pins, ...pins]
      this.loading = false

      const nextPage = get(response, 'pagination.hasNextPage')

      if (nextPage) {
        this.page++
        this.searchAllAssets()
      }
    },
    onCloseClick() {
      modal.hide()
    },
    getTransform(e) {
      this.transform = e.getTransform()
    },
    onPinSelect(pin) {
      this.$emit('select-pin', pin)
      modal.hide()
    },
    zoomIn() {
      const panZoom = this.$refs.pan.$panZoomInstance
      const { width, height } = this.getClientCoords()

      panZoom.smoothZoom(width, height, 2)
    },
    zoomOut() {
      const panZoom = this.$refs.pan.$panZoomInstance
      const { width, height } = this.getClientCoords()

      panZoom.smoothZoom(width, height, 0.5)
    },
    onImgLoad() {
      this.image = this.$refs.img
      this.imageLoaded = true
      this.onResize()
    },
    onResize() {
      if (this.image) {
        this.imageData.scale = this.image.width / this.image.naturalWidth
        this.imageData.y = this.image.offsetTop
      }
    },
    getClientCoords() {
      const width = this.$el.clientWidth / 2
      const height = this.$el.clientHeight / 2

      return {
        width: width || 0,
        height: height || 0,
      }
    },
  },
}
</script>

<template>
  <base-mdl
    width="xl"
    fullscreen="mobile"
    title="Reference Map"
    :rounded="ui.mobile ? '' : 'xl'"
    :height="ui.mobile ? '' : 'lg'"
    :padding="false"
  >
    <div
      class="relative h-full w-full select-none overflow-hidden bg-dark outline-none"
    >
      <div
        v-if="loading || !imageLoaded"
        class="absolute inset-0 z-20 flex items-center justify-center py-20 text-center"
      >
        <base-loader />
      </div>

      <div
        class="relative h-full w-full transition duration-100"
        :class="[!loading && imageLoaded ? 'opacity-100' : 'opacity-0']"
      >
        <pan-zoom
          ref="pan"
          :options="{ minZoom: 0.5, maxZoom: 10 }"
          @transform="getTransform"
        >
          <div class="flex h-full w-full items-center">
            <img
              ref="img"
              class="pointer-events-none focus:outline-none"
              :width="map.width"
              :height="map.height"
              :src="map.source"
              @load="onImgLoad"
            />
          </div>
        </pan-zoom>

        <pin
          v-for="pin in pins"
          :key="pin.id"
          :pin="pin"
          :map="map"
          :image="imageData"
          :reserve-workspace="reserveWorkspace"
          :transform="transform"
          @select-pin="onPinSelect(pin)"
        />

        <div
          class="absolute top-0 right-0 z-10 mt-2.5 mr-2.5 flex overflow-hidden rounded-sm bg-primary-lightest text-primary shadow-md"
        >
          <button
            class="mr-[5px] flex h-10 w-10 items-center justify-center bg-white font-bold transition duration-100 hover:bg-primary hover:text-white focus:outline-none"
            @click.stop="zoomOut"
            @dblclick.stop.prevent
          >
            <base-icon ref="icon" svg="qr/minus" size="xl" />
          </button>
          <button
            class="flex h-10 w-10 items-center justify-center bg-white font-bold transition duration-100 hover:bg-primary hover:text-white focus:outline-none"
            @click.stop="zoomIn"
            @dblclick.stop.prevent
          >
            <base-icon ref="icon" svg="qr/plus" size="xl" />
          </button>
        </div>
      </div>
    </div>
  </base-mdl>
</template>
