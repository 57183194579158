import { useBatchOrder } from '/~/composables/batch-order'
import { BatchOrderUnsupportedPayees } from './core/BatchOrderUnsupportedPayees'

export function useBatchOrderUnsupportedPayees() {
  const { batchOrder } = useBatchOrder()

  const unsupportedPayees = new BatchOrderUnsupportedPayees({
    url: `/v3/batch-orders/${batchOrder.number}/unsupported-payees`,
    perPage: '10',
  })

  return {
    unsupportedPayees,
  }
}
