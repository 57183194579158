import { computed, ref, watch } from 'vue'

export function useConfirmComponent(props: {
  onConfirm?: () => Promise<void> | void
  onCancel?: () => Promise<void> | void
  hide?: () => void
  emit?: (event: 'loading', value: boolean) => void
}) {
  const confirming = ref(false)
  const cancelling = ref(false)

  const loading = computed(() => confirming.value || cancelling.value)

  async function onConfirm() {
    confirming.value = true

    try {
      await props.onConfirm?.()
      props.hide?.()
    } catch (error) {
      console.error('modal', error)
    } finally {
      confirming.value = false
    }
  }

  async function onCancel() {
    cancelling.value = true

    try {
      await props.onCancel?.()
      props.hide?.()
    } catch (error) {
      console.error('modal', error)
    } finally {
      cancelling.value = false
    }
  }

  watch(loading, (value: boolean) => {
    props.emit?.('loading', value)
  })

  return {
    confirming,
    cancelling,
    loading,
    onCancel,
    onConfirm,
  }
}
