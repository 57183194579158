import dayjs from 'dayjs/esm'
import Vue, { ref, computed, onUnmounted, onMounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import Analytics from '/~/core/analytics'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { cardCode } from '/~/utils/cards'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import { useFlowPaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import {
  useStatementAutoPayments,
  useStatementAccounts,
  useStatementModals,
} from '/~/composables/statements'

interface DirectDebitComponentEmits {
  (event: 'hidden'): void
  (event: 'confirm'): void
  (event: 'hide'): void
  (event: 'cancel'): void
}

function notifyError(error) {
  console.error(error)
  Vue.notify({
    text: 'Something went wrong. Please try again later.',
    type: 'error',
    data: {
      look: 'light',
    },
  })
}

export function useDirectDebitComponent(emit: DirectDebitComponentEmits) {
  const DEFAULT_DUE_DATE = 24

  const router = useRouter()
  const {
    fetchPaymentMethods,
    noCreditCards,
    noBankAccounts,
    isBankAccountsAvailable,
    fetching: fetchingPaymentMethods,
  } = useFlowPaymentMethods(FlowType.statement)
  const {
    selectedStatementAutoPayment,
    hasSelectedPaymentMethods,
    hasDirectDebitOrScheduled,
    unselectPayments,
    createStatementAutoPayment,
    updateStatementAutoPayment,
    selectedCard,
    selectedBankAccount,
    selectPaymentMethod,
    isPayNow,
  } = useStatementAutoPayments()
  const {
    isSecuritisationDisclaimersEnabled,
    updateStatementAccountAutoPayment,
    fetchStatementAccounts,
  } = useStatementAccounts()
  const { providerClientName } = useProvider()
  const { isPointsEnabled } = usePoints()
  const { payment, currentFlowType } = useCheckoutReactive()
  const { translate } = useLocalization()

  const tacChecked = ref(false)
  const isShowPaymentMethods = ref(false)
  const isProcessing = ref(false)

  const { showExistingScheduledPaymentModalOnceOff } = useStatementModals()

  if (!selectedStatementAutoPayment.value) {
    router.push({ name: 'statements' })
  }

  const itemProcessingDueDate = ref(
    selectedStatementAutoPayment.value?.statement?.processingDueDate ?? null
  )

  const processingDueDate = ref(
    itemProcessingDueDate.value
      ? dayjs(itemProcessingDueDate.value)
          .set('month', dayjs().month())
          .set('year', dayjs().year()) // set month and year to current month and year
      : dayjs().set('date', DEFAULT_DUE_DATE)
  )

  const itemProcessingDueDay = ref(
    processingDueDate.value ? dayjs(processingDueDate.value).get('date') : 0
  )

  const processingActivePeriod = {
    from: 0,
    to: itemProcessingDueDay.value ? null : DEFAULT_DUE_DATE,
  }

  const statementId = computed(
    () => selectedStatementAutoPayment.value?.statement?.id
  )

  const title = computed(
    () =>
      selectedStatementAutoPayment.value?.statement?.payeeName ||
      providerClientName.value
  )
  const subtitle = computed(
    () =>
      `Account no. ${selectedStatementAutoPayment.value?.statementAccountNumber}`
  )

  function tacToggle() {
    tacChecked.value = !tacChecked.value
  }

  function onAddPaymentMethodClicked() {
    modal.hide()
    emit('hidden')

    router.push({ hash: '#profile-payment-methods-add' })
  }

  function showPaymentMethods() {
    isShowPaymentMethods.value = true
  }

  const isPaymentMethodsEmpty = computed(
    () => noCreditCards.value && noBankAccounts.value
  )

  const isCreditCardSelected = computed(() => Boolean(selectedCard.value))
  const isBankAccountSelected = computed(() =>
    Boolean(selectedBankAccount.value)
  )
  const isMethodSelected = computed(
    () => isCreditCardSelected.value || isBankAccountSelected.value
  )

  const methodIcon = computed(() => {
    if (isCreditCardSelected.value) {
      return `billpay/payment-methods/${cardCode(selectedCard.value)}`
    } else if (isBankAccountSelected.value) {
      return 'v2/custom/bank'
    }

    return null
  })
  const methodFirstRow = computed(() => {
    if (!isMethodSelected.value) {
      return null
    }

    const { name, type, accountName } = isCreditCardSelected.value
      ? selectedCard.value
      : selectedBankAccount.value

    switch (type) {
      case PaymentMethodType.creditCard:
        return name
      case PaymentMethodType.bankAccount:
        return accountName
      default:
        return ''
    }
  })
  const methodSecondRow = computed(() => {
    if (!isMethodSelected.value) {
      return null
    }

    const { number, type, accountNumber, bsb } = isCreditCardSelected.value
      ? selectedCard.value
      : selectedBankAccount.value

    switch (type) {
      case PaymentMethodType.creditCard:
        return `**** **** **** ${number.slice(-4)}`
      case PaymentMethodType.bankAccount:
        return translate('bankAccount.details', {
          acc: `*** *** *${accountNumber.slice(-2)}`,
          bsb,
        })
      default:
        return null
    }
  })

  const transactionFees = computed(() => {
    if (!isMethodSelected.value) {
      return null
    }
    if (isCreditCardSelected.value) {
      return parseFloat(selectedCard.value.msfConfig?.percentageFeeRate || '0')
    } else {
      return parseFloat(
        selectedBankAccount.value.msfConfig?.percentageFeeRate || '0'
      )
    }
  })

  const showBankAccountNotices = computed(() => {
    return (
      isBankAccountsAvailable.value &&
      !noBankAccounts.value &&
      isSecuritisationDisclaimersEnabled.value
    )
  })
  const pageOptions = computed(() => {
    if (isShowPaymentMethods.value) {
      return {
        title: 'Select payment method',
        back: () => {
          isShowPaymentMethods.value = false
        },
        action: onAddPaymentMethodClicked,
      }
    }

    return {
      title: `${translate('directDebit.text')} Request`,
      back: {
        name: 'statements',
      },
      class: 'pb-32',
    }
  })

  async function suggestPayNow() {
    modal.show('pay-now', {
      props: {
        onConfirm: async () => {
          // keep payment method selected
          isPayNow.value = true

          try {
            await payment.value.initPayment({
              statement: selectedStatementAutoPayment.value?.statement,
            })

            modal.hideAll()

            router.push({
              name: 'statement-checkout',
              params: {
                id: selectedStatementAutoPayment.value?.statement.id,
              },
            })
          } catch (error) {
            notifyError(error)
          }
        },
        onCancel: () => {
          modal.hideAll()
          if (ui.mobile) {
            router.push({ name: 'statements' })
          }
        },
      },
    })
  }

  async function onSave() {
    try {
      if (selectedStatementAutoPayment.value?.isEnabled) {
        await updateStatementAutoPayment(
          selectedStatementAutoPayment.value?.id,
          {
            ...selectedStatementAutoPayment.value?.toJSON(),
            day: itemProcessingDueDay.value,
          }
        )
      } else {
        const autoPayment = await createStatementAutoPayment({
          ...selectedStatementAutoPayment.value?.toJSON(),
          day: itemProcessingDueDay.value,
        })

        updateStatementAccountAutoPayment(
          selectedStatementAutoPayment.value?.statementAccountId,
          autoPayment
        )
      }

      await fetchStatementAccounts({
        includeNextDueStatementOrders: true,
        includeAutoPayments: true,
      })

      Vue.notify({
        text: `Your ${translate(
          'directDebit.text'
        )} request has been confirmed`,
        type: 'success',
        data: {
          look: 'light',
        },
      })

      Analytics.sendEvent({
        label: `${translate('directDebit.text')} request has been confirmed`,
        pageGroup: 'Make a payment',
        component1: 'Direct Debit Request',
      })

      emit('confirm')

      const isDueTodayOrOverdue =
        selectedStatementAutoPayment.value?.statement?.statementAccount
          ?.isDueToday ||
        selectedStatementAutoPayment.value?.statement?.statementAccount
          ?.isOverdue
      const hasPositiveTotal = selectedStatementAutoPayment.value?.total > 0

      if (isDueTodayOrOverdue && hasPositiveTotal) {
        suggestPayNow()
      } else {
        modal.hideAll()
        emit('hide')
        if (ui.mobile) {
          router.push({ name: 'statements' })
        }
      }
    } catch (error) {
      notifyError(error)
    } finally {
      isProcessing.value = false
    }
  }

  const getFees = async () => {
    await selectedStatementAutoPayment.value?.getFees()
  }

  const clearOnUnmount = () => {
    if (!isPayNow.value) {
      unselectPayments()
    }
  }

  onMounted(() => {
    getFees()
    fetchPaymentMethods()
    currentFlowType.value = FlowType.statement
  })

  onUnmounted(clearOnUnmount)

  function onCancel() {
    modal.hide()
    emit('hide')
    emit('cancel')
    if (ui.mobile) {
      router.push({ name: 'statements' })
    }
  }

  async function onConfirm() {
    isProcessing.value = true
    try {
      if (await hasDirectDebitOrScheduled(undefined, statementId.value)) {
        showExistingScheduledPaymentModalOnceOff(onSave)
      } else {
        await onSave()
      }
    } catch (error) {
      notifyError(error)
    } finally {
      isProcessing.value = false
    }
  }

  function showTermsAndConditionsModal() {
    if (ui.mobile) {
      bottomSheet.show('terms-conditions')
    } else {
      modal.show('terms-conditions')
    }
  }

  function handleProcessingDateSelect(date) {
    itemProcessingDueDay.value = date
  }

  function handleBack() {
    isShowPaymentMethods.value = false
  }

  function handlePaymentMethodSelected(method: unknown) {
    selectPaymentMethod(method)
    isShowPaymentMethods.value = false
  }

  return {
    pageOptions,
    showTermsAndConditionsModal,
    itemProcessingDueDay,
    processingActivePeriod,
    selectedStatementAutoPayment,
    tacChecked,
    tacToggle,
    onAddPaymentMethodClicked,
    handleProcessingDateSelect,
    isShowPaymentMethods,
    showPaymentMethods,
    isPaymentMethodsEmpty,
    showBankAccountNotices,
    hasSelectedPaymentMethods,
    isBankAccountSelected,
    isMethodSelected,
    isPointsEnabled,
    methodIcon,
    methodFirstRow,
    methodSecondRow,
    isProcessing,
    onCancel,
    onConfirm,
    onSave,
    title,
    subtitle,
    handleBack,
    handlePaymentMethodSelected,
    getFees,
    clearOnUnmount,
    fetchingPaymentMethods,
    transactionFees,
  }
}
