<script>
import EntryGif from './entry-gif.vue'
import EntryImage from './entry-image.vue'
import EntryVideo from './entry-video.vue'

export default {
  name: 'rec-gallery-entry',
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    hiddenAttachmentsCount: {
      type: Number,
      default: 0,
    },
    dimension: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['click'],
  computed: {
    entryComponent() {
      const { type } = this.attachment

      if (type === 'image') return EntryImage
      if (type === 'image/gif') return EntryGif
      else if (type === 'video') return EntryVideo
      return null
    },
  },
}
</script>

<template>
  <component
    :is="entryComponent"
    v-if="entryComponent"
    :attachment="attachment"
    :hidden-attachments-count="hiddenAttachmentsCount"
    :dimension="dimension"
    @click="$emit('click', $event)"
  />
</template>
