<script>
import get from 'lodash-es/get'
import { ValidationObserver } from 'vee-validate'
import BaseBox from '/~/components/base/box/base-box.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms'
import { useHelp } from '/~/composables/help'
import { useUser, useUserEvents } from '/~/composables/user'

export default {
  name: 'help',
  components: {
    BaseInput,
    BasePlaceholder,
    BaseButton,
    BaseBox,
    BaseTextarea,
    ValidationObserver,
  },
  setup() {
    const { user, fetchUserDetails } = useUser()
    const { sendHelp } = useHelp()
    const {
      helpTitle,
      helpDescription,
      helpMessageMaxLength,
      helpButtonTitle,
      helpFooterNote,
      customerServiceText,
    } = useCms()
    const { helpVisitedEvent } = useUserEvents()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()

    helpVisitedEvent({ type: 'page' })

    return {
      user,
      sendHelp,
      customerServiceText,
      helpTitle,
      helpDescription,
      helpMessageMaxLength,
      helpButtonTitle,
      helpFooterNote,
      fetchUserDetails,
      backendErrors,
      processBackendErrors,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
      showThanks: false,
      isSubmitting: false,
      isLoading: true,
    }
  },
  computed: {
    disableSubmit() {
      return Boolean(
        !this.form.message ||
          this.validationObserverRef?.flags.invalid ||
          this.isSubmitting
      )
    },
    phone() {
      return get(eonx.config, 'general.general.customer_service_number', '')
    },
    errorsList() {
      return Object.entries(this.validationObserverRef?.errors ?? {})
        .map(([_, item]) => item.msg)
        .join('. ')
    },
  },
  created() {
    this.fetchUserDetails().then(() => {
      this.form.name = this.form.name || this.user.fullName || ''
      this.form.email = this.form.email || this.user.email || ''
      this.isLoading = false
    })
  },
  methods: {
    async sendForm() {
      this.isSubmitting = true

      const payload = {
        ...this.form,
      }

      this.sendHelp(payload)
        .then(() => {
          this.showThanks = true
        })
        .catch(({ response }) => {
          this.processBackendErrors(response.data || response)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
  },
}
</script>

<template>
  <div class="mx-auto mb-12 w-sm max-w-full px-5">
    <base-box
      :title="
        showThanks ? 'Your email has been sent!' : helpTitle ?? 'Contact us'
      "
    >
      <template v-if="showThanks">
        Thank you for contacting us, we will be in touch shortly
        <div v-if="phone">
          <p>
            Or call
            <b>{{ phone }}</b>
            .
          </p>
          <p>
            Please note, calls and emails are managed 9.00am to 5.00pm AEST,
            Monday to Friday.
          </p>
        </div>
      </template>
      <template v-else>
        <div
          v-if="helpDescription"
          class="mb-[30px]"
          v-html="helpDescription"
        />
        <base-placeholder v-if="isLoading" :rows="3" type="text" />
        <validation-observer
          v-else
          v-slot="{ handleSubmit }"
          ref="validationObserverRef"
          slim
        >
          <form @submit.prevent="handleSubmit(sendForm)">
            <base-input
              v-model="form.name"
              :validation="{
                rules: 'required',
                name: 'Name',
              }"
              :error="backendErrors.name"
              :disabled="isSubmitting"
              label="Name"
              name="name"
            />
            <base-input
              v-model="form.email"
              :validation="{
                rules: 'required|email',
                name: 'Email',
              }"
              :error="backendErrors.email"
              :disabled="isSubmitting"
              label="Email address"
              name="email"
              type="email"
            />
            <base-textarea
              v-model="form.message"
              :validation="{
                rules: 'required',
                name: 'Message',
                mode: 'aggressive',
              }"
              focused
              :error="backendErrors.message"
              :disabled="isSubmitting"
              :rows="3"
              :label="
                helpMessageMaxLength
                  ? `Message (max character length ${helpMessageMaxLength})`
                  : 'Message'
              "
              name="message"
              :maxlength="helpMessageMaxLength"
            />
            <div class="mt-10 flex justify-end">
              <base-button
                :blocked="disableSubmit"
                blocked-reason="help-errors-list"
                :loading="isSubmitting"
                type="submit"
                full-width="mobile"
              >
                {{ helpButtonTitle ?? 'Send message' }}
              </base-button>
              <div id="help-errors-list" class="hidden">
                {{ errorsList }}
              </div>
            </div>
            <div
              v-if="helpFooterNote"
              class="mt-[30px] space-y-5"
              v-html="helpFooterNote"
            />
            <div v-else-if="phone" class="mt-[30px] space-y-5">
              <p>
                Or call
                <b>{{ phone }}</b>
                .
              </p>
              <div v-if="customerServiceText" v-html="customerServiceText" />
              <p v-else>
                Please note, calls and emails are managed 9.00am to 5.00pm AEST,
                Monday to Friday.
              </p>
            </div>
          </form>
        </validation-observer>
      </template>
    </base-box>
  </div>
</template>
