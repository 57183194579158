<script>
import ui from '/~/core/ui'
import EventsTile from './tile/event-tile.vue'
import CelebrationsTile from './tile/celebration-tile.vue'
import BaseDate from '/~/components/base/date/base-date.vue'

export default {
  name: 'rec-events-month',
  components: {
    EventsTile,
    CelebrationsTile,
    BaseDate,
  },
  props: {
    /*
     * See EventsMonth class in /~rec/core/events-processor
     */
    month: {
      type: Object,
      required: true,
    },
    isCelebrations: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    monthDaysToDisplay() {
      return this.month.eventsByDays.filter((day) => day.events.length)
    },
    componentName() {
      if (this.isCelebrations) {
        return 'celebrations-tile'
      } else {
        return 'events-tile'
      }
    },
  },
  methods: {
    isLastDisplayedDayOfMonth(dayIdx) {
      return dayIdx === this.monthDaysToDisplay.length - 1
    },
  },
}
</script>

<template>
  <div
    :data-month="month.date.format('YYYY-MM')"
    class="mx-auto max-w-screen-md"
  >
    <template v-if="monthDaysToDisplay.length">
      <div
        v-for="(day, dayIdx) in monthDaysToDisplay"
        :key="day.key"
        :data-day="day.key"
        class="relative sm:pr-2.5 sm:pl-2.5 sm:pt-2.5"
        :class="{
          'sm:pb-2.5': isLastDisplayedDayOfMonth(dayIdx),
          'sm:pb-[30px]': !isLastDisplayedDayOfMonth(dayIdx),
        }"
      >
        <div
          class="absolute top-0 left-0 z-10 w-12"
          :class="{
            'ml-2.5': ui.mobile,
          }"
        >
          <base-date
            :date="day.date"
            type="day-date"
            :plain="true"
            look="card"
            :class="{
              'text-sm font-bold text-eonx-neutral-600': ui.mobile,
            }"
          />
        </div>
        <div>
          <template v-if="day.events.length">
            <div
              v-for="(event, idx) in day.events"
              :key="event.id"
              :class="{
                'mb-5': idx + 1 !== day.events.length,
              }"
            >
              <component
                :is="componentName"
                :key="event.id"
                :event="event"
                class="rounded-lg"
              />
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
