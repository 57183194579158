export default {
  Mind: [
    'Sleep',
    'Meditation',
    'Finances',
    'De-stress',
    'Memory',
    'Relationships',
    'Mental health',
    'Beliefs',
  ],
  Body: [
    'Pilates',
    'Energy',
    'Yoga',
    'Strenght',
    'Stretch',
    'Nutrition',
    'Boxing',
    'Martial arts',
    'Skincare',
  ],
  'Food Recipes': ['Snaks', 'Breakfast', 'Salads', 'Meals', 'Dessert'],
  'Health Articles': [
    'Cancer',
    'Diabetes',
    'Gut Health',
    'Mental Health',
    "Women's Health",
    'Child Health',
    'Heart Health',
    'Eating Well',
    'Menaging Weight',
    'Nutrition Supplements',
  ],
}
