import { useRecroom } from '/~rec/composables/recroom'
import { useRecProfile } from '/~rec/composables/profile'

const { initTask, initialized } = useRecroom()
const { features, isMeLimitedUser, isMeSuperAdmin, isAdmin } = useRecProfile()

const rules = [
  {
    path: '/rec-room/news/my',
    isAllowed: () => !isMeLimitedUser.value,
  },
  {
    path: '/rec-room/news/reported',
    isAllowed: () => isMeSuperAdmin.value || isAdmin.value,
  },
  {
    path: '/rec-room/recognition/awards',
    isAllowed: () => features.value.awards,
  },
  {
    path: '/rec-room/recognition/hi5',
    isAllowed: () => features.value.hi5,
  },
  {
    path: '/rec-room/recognition/perks',
    isAllowed: () => features.value.perks,
  },
  {
    path: '/rec-room/groups',
    isAllowed: () => features.value.groups,
  },
  {
    path: '/rec-room/today',
    isAllowed: () => features.value.calendar,
  },
  {
    path: '/rec-room/directory',
    isAllowed: () => features.value.directory,
  },
  {
    path: '/rec-room/news/announcements',
    isAllowed: () => features.value.announcements,
  },
  {
    path: '/rec-room/events',
    isAllowed: ({ params }) => {
      const isCelebrations = params.feedId === 'celebrations'

      return isCelebrations
        ? features.value.celebrations
        : features.value.events
    },
  },
]

const navigationController = (to, next) => {
  if (!initialized.value) {
    next()
  }

  initTask.value.then(() => {
    rules.forEach((item) => {
      if (to.path.includes(item.path) && !item.isAllowed(to)) {
        next({ name: 'rec-feed' })
      } else next()
    })
  })
}

export default navigationController
