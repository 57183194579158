<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Pipeline } from '/~/core/pipeline'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useLocalization } from '/~/composables/localization'
import { useUser } from '/~/composables/user'

export default {
  name: 'pa-prestige-home-content-insurance-form-modal',
  components: {
    BaseDatepicker,
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseMdl,
    BaseSelect,
    BaseTextarea,
    BaseRadio,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const { user } = useUser()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { states, translate } = useLocalization()
    const { validationObserverRef } = useForm()

    return {
      user,
      backendErrors,
      processBackendErrors,
      states,
      translate,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {},
      processing: false,
      success: false,
      trackingCode: '',
      postalCodeMask: {
        mask: /^[0-9]\d{0,3}$/,
        lazy: true,
      },
      yearMask: {
        mask: /^[0-9]\d{0,3}$/,
        lazy: true,
      },
    }
  },
  computed: {
    currencyMask() {
      return {
        prefix: '$',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralIntegerScale: 12,
        numeralDecimalScale: 2,
        min: 0,
      }
    },
    formTitle() {
      return Pipeline.formTitle('Prestige Home Contents Insurance')
    },
    stateOptions() {
      return this.states.map((state) => {
        return {
          text: state.label,
          value: state.label,
        }
      })
    },
    buildingModificationOptions() {
      return [
        {
          text: 'Yes',
          value: 'yes',
        },
        {
          text: 'No',
          value: 'no',
        },
      ]
    },
  },
  created() {
    const { email, firstName, lastName, mobile: phone } = this.user
    const userDetails = {
      email,
      first_name: firstName,
      last_name: lastName,
      phone,
    }

    this.form = { ...this.form, ...userDetails }
  },
  methods: {
    async onSubmit() {
      this.processing = true
      this.backendErrors = {}

      try {
        const { trackingId } = await Pipeline.submit(
          'pa/prestige-hc',
          this.form
        )

        this.trackingCode = trackingId[0]
        this.success = true
      } catch (error) {
        this.processBackendErrors(error)
        this.$emit('error', error)
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<template>
  <base-mdl :title="formTitle" fullscreen="mobile">
    <div v-if="!success">
      <div class="bg-slate-200 p-6">
        <p>
          Getting a quote is easy! Simply complete the form below and indicate
          if you would like furhter information, or to simply be issued with a
          formal quotation.
        </p>
        <p>
          <small>
            Required fields are makred with
            <sup>*</sup>
          </small>
        </p>
      </div>
      <validation-observer
        v-slot="{ errors, handleSubmit }"
        ref="validationObserverRef"
        slim
      >
        <form
          class="mt-6 flex flex-col flex-wrap sm:-mx-2.5 sm:flex-row"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <base-input
            v-model="form.first_name"
            :validation="{
              rules: 'required',
              name: 'First name',
            }"
            :error="backendErrors.first_name"
            label="First name"
            required
            name="first_name"
            class="w-full sm:mx-2.5 sm:w-auto"
          />
          <base-input
            v-model="form.last_name"
            :validation="{
              rules: 'required',
              name: 'Last name',
            }"
            :error="backendErrors.firstName"
            label="Last name"
            required
            name="last_name"
            class="w-full sm:mx-2.5 sm:w-auto"
          />

          <base-input
            v-model="form.phone"
            :validation="{
              rules: 'required',
              name: 'Mobile',
            }"
            :error="backendErrors.phone"
            label="Mobile"
            required
            type="tel"
            mask="mobile"
            name="phone"
            class="w-full sm:mx-2.5 sm:w-auto"
          />

          <base-input
            v-model="form.email"
            :validation="{
              rules: 'required|email',
              name: 'Email',
            }"
            :error="backendErrors.email"
            label="Email"
            required
            name="email"
            type="email"
            class="w-full sm:mx-2.5 sm:w-auto"
          />

          <base-input
            v-model="form.company_name"
            :validation="{
              rules: 'required',
              name: 'Company name',
            }"
            :error="backendErrors.company_name"
            label="Company name"
            required
            name="company_name"
            class="w-full sm:mx-2.5 sm:w-auto"
          />

          <div class="mx-2.5 mb-6 w-full grow">
            <h3>Enquiry for:</h3>
            <p>Please provide *</p>
          </div>

          <div class="mx-2.5 mb-2.5 w-full grow">
            <h3>Address:</h3>
          </div>

          <base-input
            v-model="form.street_address"
            label="Street address"
            name="street_address"
            class="w-full sm:mx-2.5 sm:w-auto"
          />

          <base-input
            v-model="form.suburb"
            label="Suburb"
            name="suburb"
            class="w-full sm:mx-2.5 sm:w-auto"
          />
          <base-select
            v-model="form.state"
            :options="stateOptions"
            label="State"
            name="state"
            class="w-full sm:mx-2.5 sm:w-auto"
          />
          <base-input
            v-model="form.postcode"
            :mask="postalCodeMask"
            :label="translate('address.postcode')"
            name="postcode"
            class="w-full sm:mx-2.5 sm:w-auto"
          />
          <div class="mb-3b sm:mx-2.5" style="width: 245px">
            <base-datepicker
              v-model="form.dob"
              :validation="{
                rules: 'required',
                name: 'Insured date of birth',
                mode: 'aggressive',
              }"
              :error="backendErrors.dob"
              label="Insured date of birth"
              required
              name="dob"
              :max-date-mobile="new Date().toISOString().split('T')[0]"
            />
          </div>
          <base-input
            v-model="form.building_sum_insured"
            :validation="{
              rules: 'required',
              name: 'Building sum insured',
            }"
            :mask="currencyMask"
            :error="backendErrors.building_sum_insured"
            label="Building sum insured"
            required
            name="building_sum_insured"
            currency
            class="w-full sm:mx-2.5 sm:w-auto"
          />

          <base-input
            v-model="form.content_sum_insured"
            :validation="{
              rules: 'required',
              name: 'Content sum insured',
            }"
            :mask="currencyMask"
            :error="backendErrors.content_sum_insured"
            label="Content sum insured"
            required
            name="content_sum_insured"
            currency
            class="w-full sm:mx-2.5 sm:w-auto"
          />
          <base-input
            v-model="form.valuables_sum_insured"
            :validation="{
              rules: 'required',
              name: 'Valuables sum insured',
            }"
            :mask="currencyMask"
            :error="backendErrors.valuables_sum_insured"
            label="Valuables sum insured"
            required
            name="valuables_sum_insured"
            currency
            class="w-full sm:mx-2.5 sm:w-auto"
          />

          <div class="mx-2.5 mb-6 w-full grow">
            <h3>Building details:</h3>
          </div>

          <base-input
            v-model="form.year_of_construction"
            :validation="{
              rules: 'required',
              name: 'Year of construction',
            }"
            :mask="yearMask"
            :error="backendErrors.year_of_construction"
            label="Year of construction"
            required
            name="year_of_construction"
            class="w-full sm:mx-2.5 sm:w-auto"
          />
          <base-select
            v-model="form.building_modification"
            :options="buildingModificationOptions"
            label="Building been rewired and/or replumbed?"
            name="building_modification"
            class="w-full sm:mx-2.5 sm:w-auto"
          />

          <div class="mx-2.5 mb-6 w-full grow">
            <p>Construction:</p>
            <validation-provider
              vid="construction"
              rules="required"
              name="Construction"
              mode="aggressive"
              slim
            >
              <div class="flex flex-wrap pl-2.5">
                <base-radio
                  v-model="form.construction"
                  name="construction"
                  value="brick_beener"
                  :error="backendErrors.construction"
                  class="mr-6"
                >
                  Brick/Brick Beneer
                </base-radio>
                <base-radio
                  v-model="form.construction"
                  name="construction"
                  value="timber_weatherboard"
                >
                  Timber/Weatherboard
                </base-radio>
              </div>
              <div
                v-if="errors['construction']"
                class="base-field__message base-field__message--error w-full grow"
              >
                {{ errors['construction'][0] }}
              </div>
            </validation-provider>
          </div>

          <div class="mx-2.5 mb-6 w-full grow">
            <p>Security (check all that apply to the property):</p>
          </div>
          <div class="mb-6 flex grow flex-wrap">
            <div class="mb-2.5 mr-[15px] w-full sm:mr-0 sm:w-1/2">
              <base-checkbox
                v-model="form.deadlocks"
                name="deadlocks"
                class="sm:mx-2.5"
              >
                Deadlocks
              </base-checkbox>
            </div>
            <div class="mb-2.5 mr-[15px] w-full sm:mr-0 sm:w-1/2">
              <base-checkbox
                v-model="form.keyed_window_locks"
                name="keyed_window_locks"
                class="sm:mx-2.5"
              >
                Keyed Window Locks
              </base-checkbox>
            </div>
            <div class="mb-2.5 mr-[15px] w-full sm:mr-0 sm:w-1/2">
              <base-checkbox
                v-model="form.alarm_local"
                name="alarm_local"
                class="sm:mx-2.5"
              >
                Alarm - Local
              </base-checkbox>
            </div>
            <div class="mb-2.5 mr-[15px] w-full sm:mr-0 sm:w-1/2">
              <base-checkbox
                v-model="form.alarm_monitored"
                name="alarm_monitored"
                class="sm:mx-2.5"
              >
                Alarm - Monitored
              </base-checkbox>
            </div>
          </div>

          <base-textarea
            v-model="form.comments"
            label="Comments or Questions"
            name="comments"
            class="sm:mx-2.5"
          />
          <div class="w-full grow sm:mx-2.5">
            <base-button
              class="w-full"
              type="submit"
              :disabled="processing"
              :loading="processing"
            >
              Submit
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
    <template v-else>
      <transition name="fade">
        <div class="my-6 bg-slate-200 p-6">
          <h2>Success</h2>
          <p>
            <strong>Corporate Travel Insurance</strong>
            form have been submitted.
            <br />
            Your Tracking code is:
            <strong>{{ trackingCode }}</strong>
          </p>
        </div>
      </transition>
    </template>
  </base-mdl>
</template>
