import type { RouteConfig } from 'vue-router'
import type { CMSPageId } from '/~/types/cms/page'
import { RewardsExtension } from '/~/core/extension'
import mdl from '/~/core/mdl'
import { useTravel } from '/~/extensions/travel/composables'
import modals from './modals'

mdl.register(modals)

const { initTravel, syncState, isEnabled } = useTravel()

class TravelExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  async setState() {
    return initTravel()
  }

  setRoutes() {
    if (!isEnabled.value) {
      return []
    }

    return [
      {
        path: '/travel',
        component: () => import('./travel.vue'),
        children: [
          {
            path: 'package/:slug',
            name: 'travel-details',
            props: true,
            meta: {
              title: 'Travel Package',
              theme: 'white',
              hideMenu: true,
            },
            component: () =>
              import('./templates/default/views/travel-details.vue'),
          },
          {
            path: ':category?',
            name: 'travel',
            async beforeEnter(to, from, next) {
              await syncState({ to })
              next()
            },
            meta: {
              title: 'Travel Packages',
              page: 'travel' as CMSPageId,
            },
            component: () =>
              import('./templates/default/views/travel-catalog.vue'),
          },
        ] as RouteConfig[],
      },
    ]
  }
}

export default TravelExtension
