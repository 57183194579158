<script>
import RenderMessage from '/~rec/components/render/render-message.vue'
import PostMedia from '/~rec/components/post/post-media.container.vue'
import PostLinkPreviews from '/~rec/components/post/post-link-previews.container.vue'
import BaseClampLine from '/~/components/base/clamp-line/base-clamp-line.vue'

export default {
  name: 'rec-post-default',
  components: {
    RenderMessage,
    PostMedia,
    PostLinkPreviews,
    BaseClampLine,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div>
    <base-clamp-line
      :rows="10"
      gradient-class="bg-gradient-to-b from-transparent to-white"
      class="px-5"
    >
      <render-message
        class="leading-normal"
        :content="post.content"
        :mentions="post.mentions"
      />
    </base-clamp-line>
    <post-media :post="post" class="pt-5" />
    <post-link-previews :post="post" />
  </div>
</template>
