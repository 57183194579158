<script>
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import WgtBgText from '../wgt/wgt-bg-text.vue'

export default {
  name: 'widget-fullscreen-banner',
  components: {
    BaseAction,
    BaseIcon,
    WgtBgText,
  },
  props: {
    fullHeightContentBackground: {
      type: Boolean,
      default: false,
    },
    bannerTitle: {
      type: Object,
      default: () => ({}),
    },
    titleColor: {
      type: String,
      default: '',
    },
    titleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerSubtitle: {
      type: Object,
      default: () => ({}),
    },
    subtitleColor: {
      type: String,
      default: '',
    },
    subtitleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerContent: {
      type: Object,
      default: () => ({}),
    },
    contentColor: {
      type: String,
      default: '',
    },
    contentBackgroundColor: {
      type: String,
      default: '',
    },
    bannerButton: {
      type: Object,
      default: () => ({}),
    },
    buttonIcon: {
      type: Boolean,
      default: true,
    },
    buttonColor: {
      type: String,
      default: '',
    },
    buttonTextColor: {
      type: String,
      default: '',
    },
    fullScreenImage: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    sectionStyle() {
      return {
        backgroundImage: `url(${cdn(this.fullScreenImage.desktop)
          .preview('2144x804')
          .format('auto')
          .quality('smart')
          .url()})`,
        backgroundPosition: 'top center',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    },
    buttonStyle() {
      return {
        backgroundColor: this.buttonColor,
        color: this.buttonTextColor,
      }
    },
  },
}
</script>

<template>
  <section class="rounded-lg shadow" :style="sectionStyle">
    <div class="flex items-stretch">
      <div
        class="w-full px-5 py-16 md:px-11"
        :class="{
          'text-left': bannerContent.contentAlignmentX === 'left',
          'text-right': bannerContent.contentAlignmentX === 'right',
          'text-center': bannerContent.contentAlignmentX === 'center',
        }"
      >
        <div
          v-if="bannerTitle.text"
          class="text-3xl font-bold leading-tight xs:text-5xl"
        >
          <wgt-bg-text
            :content="bannerTitle.text"
            :text-color="titleColor"
            :background-color="titleBackgroundColor"
            :full-height-background="fullHeightContentBackground"
          />
        </div>
        <div
          v-if="bannerSubtitle.text"
          class="text-3xl font-bold leading-tight xs:text-5xl"
        >
          <wgt-bg-text
            :content="bannerSubtitle.text"
            :text-color="subtitleColor"
            :background-color="subtitleBackgroundColor"
            :full-height-background="fullHeightContentBackground"
          />
        </div>
        <div
          v-if="bannerContent.text"
          class="mt-2.5 inline-block text-2xl leading-tight xs:w-1/2"
        >
          <wgt-bg-text
            :content="bannerContent.text"
            :text-color="contentColor"
            :background-color="contentBackgroundColor"
            :full-height-background="fullHeightContentBackground"
          />
        </div>
        <div>
          <base-action
            v-if="bannerButton.enabled"
            v-bind="bannerButton"
            class="inline-block"
            origin="banner"
            appearance="link"
          >
            <button
              type="button"
              class="button mt-[30px] flex h-12 items-center rounded px-5 font-bold"
              :style="buttonStyle"
            >
              <span>
                {{ bannerButton.label }}
              </span>
              <base-icon
                v-if="buttonIcon"
                class="ml-5"
                svg="plain/chevron-right"
                size="sm"
              />
            </button>
          </base-action>
        </div>
      </div>
    </div>
  </section>
</template>
