<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import EventForm from '/~rec/components/event/event-form.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { RecroomEvent } from '/~rec/core/event'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { ref, provide, computed } from 'vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useRoute } from 'vue-router/composables'

export default {
  name: 'rec-event-form-modal',
  components: {
    EventForm,
    BaseMdl,
    HeaderMobile,
    BaseLoader,
  },
  setup() {
    const route = useRoute()

    const eventLoading = ref(true)
    const event = ref(null)

    const title = computed(() => (event.value ? 'Edit Event' : 'Create Event'))

    const getEvent = async () => {
      const initialEvent = route.params.initialEvent
      const eventId = route.params.eventId

      if (initialEvent) {
        event.value = initialEvent
      } else if (eventId) {
        event.value = await RecroomEvent.fetchById(eventId)

        await event.value.members.refresh()
      }
      eventLoading.value = false
    }

    getEvent()
    provide('eventLoading', eventLoading)

    return {
      event,
      eventLoading,
      title,
      ui,
    }
  },

  methods: {
    onSuccess(event) {
      if (this.$route.name !== 'rec-event') {
        this.$router.push({
          name: 'rec-event',
          params: {
            eventId: event.id,
          },
        })
      }

      modal.hide()
    },
    onBack() {
      modal.hide()
      this.$router.replace(
        this.event
          ? { name: 'rec-event', params: { eventId: this.event.id } }
          : {
              name: 'rec-events',
              params: { feedId: this.$route.params.feedId },
            }
      )
    },
  },
}
</script>

<template>
  <base-mdl transition="slide-bottom" fullscreen width="screen" :layout="false">
    <div v-if="!eventLoading">
      <header-mobile v-if="!ui.desktop" :title="title" @back="onBack" />
      <div class="px-5">
        <event-form
          v-bind="$attrs"
          :is-edit="!!event"
          :event="event"
          @success="onSuccess"
        />
      </div>
    </div>
    <div v-else class="my-auto mx-auto">
      <base-loader size="lg" />
    </div>
  </base-mdl>
</template>
