import get from 'lodash-es/get'
import { createDate } from '/~/utils/format/date'
import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomAwardNominee } from '/~rec/core/award-nominee'
import { RecroomNomination } from '/~rec/core/nomination'
import api from '/~rec/core/api'
import { useRecProfile } from '/~rec/composables/profile'

const { currency, myRecId } = useRecProfile()

export class RecroomAward {
  constructor(raw = {}) {
    this.raw = raw
    this.endsAt = raw.ends_at ? createDate(raw.ends_at) : null

    this.currentPeriod = new RecroomAwardCurrentPeriod(this.raw.current_period)
    this.allowedUsers = null
    this.iCanNominate = false
  }

  get id() {
    return this.raw.id
  }

  get title() {
    return this.raw.title
  }

  get description() {
    return this.raw.description
  }

  get isReached() {
    return this.nominationsMadeCount >= this.nominationUserLimit
  }

  get image() {
    return this.raw.image
  }

  get totalNominations() {
    return this.raw.total_nominations
  }

  get nominationsMadeCount() {
    return this.raw.nominations_made_count
  }

  get nominationUserLimit() {
    return this.raw.nomination_user_limit
  }

  get currency() {
    return currency.value
  }

  get nominal() {
    return get(this.raw, 'earn_configs.0.earn_value', 0)
  }

  get remaining() {
    return this.endsAt
      ? createDate().to(this.endsAt, true) + ' remaining'
      : null
  }

  get recurring() {
    return this.raw.recurring
  }

  get recurringInterval() {
    return this.raw.recurrence_interval
  }

  get status() {
    return this.raw.status
  }

  get iCanNominate() {
    return this._iCanNominate
  }

  set iCanNominate(flag) {
    this._iCanNominate = flag
  }

  static async fetchById(id) {
    const response = await api.get(`/gamification/awards/${id}`)

    return new RecroomAward(response.data)
  }

  nominationsNominator(id) {
    return new EntityProcessor({
      entity: `gamification/awards/${this.id}/nominations?nominator=${id}`,
      mapping: (nominee) => new RecroomNomination(nominee),
    })
  }

  nominationsNominee(id, periodId = null) {
    return new EntityProcessor({
      entity: `gamification/awards/${
        periodId ? this.currentAwardId : this.id
      }/nominations?nominee=${id}${periodId ? `&period=${periodId}` : ''}`,
      mapping: (nominee) => new RecroomNomination(nominee),
    })
  }

  leaderboard(periodId = null) {
    return new EntityProcessor({
      entity: `gamification/awards/${this.raw.id}/leaderboard${
        periodId ? `?period=${periodId}` : ''
      }`,
      mapping: (nominee) => new RecroomAwardNominee(nominee),
    })
  }

  async refresh() {
    const response = await api.get(`/gamification/awards/${this.id}`)

    this.updateRaw(response.data)
  }

  get nomineeRank() {
    return this.raw.nominee_rank
  }

  get nominationsRanOut() {
    return this.nominationsMadeCount === this.nominationUserLimit
  }

  get dailyNominationsLeft() {
    return this.nominationUserLimit - this.nominationsMadeCount
  }

  get nominateLimitLabel() {
    const limit = this.dailyNominationsLeft

    return limit === 0
      ? 'Nomination limit reached'
      : 'You can nominate ' +
          limit +
          ' more ' +
          (limit === 1 ? 'person' : 'people')
  }

  updateRaw(data) {
    this.raw = { ...this.raw, ...data }
  }

  async getAllowedUsers() {
    const userSets =
      (this.raw &&
        this.raw.user_sets?.filter((set) => set.nominator || set.nominee)) ||
      []

    if (userSets.length > 0) {
      const detailedSets = await Promise.all(
        userSets.map((set) => {
          return api.get(`/user-sets/${set.id}`)
        })
      ).then((s) => s.map((v) => v.data))

      const result = {
        canNominate: [],
        canBeNominated: [],
      }

      userSets.forEach((set) => {
        const userIds = detailedSets
          .find((detailed) => detailed.id === set.id)
          .members.map((v) => v.member_id)

        if (set.nominator) {
          result.canNominate = [...result.canNominate, ...userIds]
        }

        if (set.nominee) {
          result.canBeNominated = [...result.canBeNominated, ...userIds]
        }
      })

      this.iCanNominate = result.canNominate.includes(myRecId.value)
      this.allowedUsers = result
    }
  }
}

export class RecroomAwardCurrentPeriod {
  constructor(raw) {
    this.raw = raw
  }

  get endsAt() {
    return this.raw && this.raw.ends_at ? createDate(this.raw.ends_at) : null
  }

  get remaining() {
    return this.endsAt
      ? createDate().to(this.endsAt, true) + ' remaining'
      : null
  }
}
