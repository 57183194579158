<script setup lang="ts">
import { ref } from 'vue'
import emitter from '/~/core/emitter'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'
import { useWithdraw } from '/~/composables/withdraw/use-withdraw'

const props = defineProps<{
  id: string
}>()

const emit = defineEmits<(event: 'close') => void>()

const { getWithdrawalOrder } = useWithdraw()
const { translate } = useLocalization()
const { ewalletLabels } = useCms()

const currentWithdrawal = ref(null)
const loading = ref(false)

function close() {
  emit('close')
  emitter.emit('ewallet:close')
}

async function getOrder() {
  loading.value = true
  currentWithdrawal.value = await getWithdrawalOrder(props.id)
  loading.value = false
}

getOrder()
</script>

<template>
  <base-aside-page title="Withdrawal Successful" class="bg-white">
    <base-loader v-if="loading" fullwidth />

    <template v-else-if="currentWithdrawal">
      <div class="flex flex-col items-center">
        <base-icon
          :svg="'heroicons/solid/check-circle'"
          :size="80"
          class="mb-6 flex-shrink-0 text-success-700"
        />

        <span class="mb-1 text-sm font-bold">
          <base-date
            type="short-date-time"
            :date="currentWithdrawal.completedAt"
          />
        </span>
        <span class="text-sm font-bold">
          Transaction No. #{{ currentWithdrawal.number }}
        </span>
      </div>
      <div class="mt-8">
        <div>
          <span class="font-bold">From</span>
          <div class="mt-4 flex items-start justify-between">
            <div
              class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-black"
            >
              <base-icon :svg="'ewallet'" size="md" class="text-white" />
            </div>
            <div class="mx-4 min-w-0 flex-1 flex-col gap-y-0.5">
              <span class="text-sm font-bold text-eonx-neutral-800">
                {{ ewalletLabels.ewalletCash }}
              </span>
              <span class="text-sm text-eonx-neutral-800">
                Balance
                {{
                  formatDollar(currentWithdrawal.paymentMethods[0].afterBalance)
                }}
              </span>
            </div>

            <span class="self-end whitespace-nowrap">
              -{{ formatDollar(currentWithdrawal.subtotal) }}
            </span>
          </div>
        </div>
        <div v-if="currentWithdrawal.paymentDestination" class="mt-6">
          <span class="font-bold">To</span>
          <div class="mt-4 flex items-start justify-between">
            <div
              class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-black"
            >
              <base-icon :svg="'v2/custom/bank'" size="lg" class="text-white" />
            </div>
            <div class="mx-4 min-w-0 flex-1 flex-col gap-y-0.5">
              <span class="truncate text-sm font-bold text-eonx-neutral-800">
                {{ currentWithdrawal.paymentDestination.name }}
              </span>
              <span class="text-sm text-eonx-neutral-800">
                {{
                  translate('bankAccount.details', {
                    acc: currentWithdrawal.paymentDestination.accountNumber,
                    bsb: currentWithdrawal.paymentDestination.bsb,
                  })
                }}
              </span>
            </div>

            <div class="self-end whitespace-nowrap">
              +{{ formatDollar(currentWithdrawal.total) }}
            </div>
          </div>
        </div>
        <hr class="my-5 border-eonx-neutral-200" />
        <div class="text-eonx-neutral-600">
          Payments made before 5 p.m. Monday to Friday (excluding public
          holidays) will be received the next banking day
        </div>
      </div>
    </template>
    <template #footer>
      <div class="border-t border-eonx-neutral-200 p-5">
        <base-button full-width @click="close">Done</base-button>
      </div>
    </template>
  </base-aside-page>
</template>

<style scoped></style>
