<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import { cardCode } from '/~/utils/cards'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { ensureNumber } from '/~/utils/format/numeric'
import { formatBsb } from '/~/utils/format/string'
import { formatPoints } from '/~/utils/points'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useActivity } from '/~/composables/activity'
import { useAddresses } from '/~/composables/addresses'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import ActivitySummaryItems from '/~/templates/australiapost/components/activity/summary/activity-summary-items.vue'
import ConfirmationFormData from '/~/templates/australiapost/components/lead-submission/confirmation-form-data.vue'
import TaxInvoice from '/~/views/activity/details/components/tax-invoice.vue'

export default {
  name: 'activity-details-item',
  components: {
    TaxInvoice,
    BaseButton,
    BaseIcon,
    ActivitySummaryItems,
    ConfirmationFormData,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  setup(props) {
    const router = useRouter()
    const {
      calculatePointsToBurnOrder,
      calculatePointsEarnedForPayment,
      calculatePointsEarnedForPurchase,
    } = usePoints()
    const { routeState, getActivityItem, getFlowOrderItem } = useActivity()
    const { userAddress } = useAddresses()
    const { formatBusinessNumber, translate } = useLocalization()
    const { taxationLabel } = useProvider()
    const { user } = useUser()

    const loading = ref(true)
    const item = ref({})
    const order = ref({})

    const config = computed(() => {
      const config = {
        baseURL: eonx.hosts.api.v1_1,
      }

      return config
    })

    const flowType = computed(() => item.value?.flowType ?? FlowType.purchase)

    async function getActivityDetails() {
      try {
        loading.value = true
        item.value = await getActivityItem(props.id, config.value)
        order.value = await getOrder()
      } catch (error) {
        console.error(error)
        goBack()
      } finally {
        loading.value = false
      }
    }

    function getOrder() {
      if (item.value) {
        return getFlowOrderItem(flowType.value, item.value.orderNumber)
      }

      return Promise.resolve(undefined)
    }

    function goBack() {
      router.replace({ name: 'activity', ...routeState.value })
    }

    onBeforeMount(() => {
      getActivityDetails()
    })

    return {
      calculatePointsEarnedForPayment,
      calculatePointsEarnedForPurchase,
      formatDollar,
      formatBsb,
      formatBusinessNumber,
      calculatePointsToBurnOrder,
      getActivityItem,
      getFlowOrderItem,
      ui,
      userAddress,
      taxationLabel,
      PaymentMethodType,
      translate,
      item,
      order,
      loading,
      config,
      goBack,
      user,
    }
  },
  data() {
    return {
      hasShadow: false,
    }
  },
  computed: {
    showPrintButton() {
      return this.item.isTypePurchaseOrder && !ui.mobile
    },
    showStatus() {
      return this.item.isTypePurchaseOrder
    },
    showPayTo() {
      return (
        this.item.isTypePurchaseOrder ||
        this.item.isTypePaymentOrder ||
        this.item.isTypeStatementOrder
      )
    },
    printContent() {
      let address = {
        ...this.userAddress,
      }

      if (this.order.billingAddress) {
        address = { ...this.order.billingAddress }
      } else if (this.order.shippingAddress) {
        address = { ...this.order.shippingAddress }
      }

      return {
        ...this.item,
        ...this.order,
        payees: this.order.payees || [this.payee],
        address,
        date: this.date,
      }
    },
    orderNumber() {
      if (this.item.isTypeCampaign) {
        return this.item.campaign.number
      }

      return this.item.orderNumber
    },
    payee() {
      return {
        ...this.order.payee,
        ...this.item.payee,
      }
    },
    paymentMethods() {
      const paymentMethods =
        this.order.paymentMethods ||
        this.order.paymentSources ||
        this.item.paymentMethods ||
        []

      return paymentMethods
        .map((method) => {
          let result

          if (method.type) {
            result = {
              ...method,
            }
          } else {
            result = {
              type: method,
              amount: paymentMethods.length === 1 ? this.item.total : 0,
              brand: 'plain/creditcards',
            }
          }

          const issuingNetwork = getCardTypeByNumber(method.number)
          const brand = cardCode({ brand: issuingNetwork?.type })

          if (brand) {
            result.brand = `cards/${brand}`
          }

          switch (result.type) {
            case this.PaymentMethodType.points:
              result.title = 'Points'
              result.details = `Using ${this.formatPoints(result.points)} PTS`
              break
            case this.PaymentMethodType.creditCard:
              result.title = 'Credit Card'
              result.details = result.number
              break
            case this.PaymentMethodType.bankAccount:
              result.title = 'Bank Account'
              result.bsb = method.bsb
              break
            case this.PaymentMethodType.couponCode:
              result.title = 'Coupon'
              result.details = method.number
              break
          }

          return result
        })
        .filter((i) => i.amount > 0)
    },
    pointsEarned() {
      if (this.item.isTypePointsTransaction) {
        return Math.round(this.item.total)
      }

      if (this.item.isTypeMigratedPoints) {
        return Math.round(this.item.points)
      }

      const pointsTransactions = this.order.pointsTransactions || []
      const earnTransaction = pointsTransactions.filter(
        (t) => t.type === 'earn'
      )

      if (earnTransaction.length > 0) {
        return earnTransaction[0].allocatedValue
      }

      if (this.item.isTypeStatementOrder) {
        return this.calculatePointsEarnedForPayment(this.item.subtotal)
      }

      return this.item.isTypePurchaseOrder
        ? this.calculatePointsEarnedForPurchase(this.order.items)
        : this.calculatePointsEarnedForPayment(
            this.item.subtotal || this.item.total
          )
    },
    date() {
      let value

      if (this.item.isTypePointsTransaction) {
        value = this.item.happenedAt
      } else {
        value =
          this.order.paidAt || this.order.completedAt || this.item.updatedAt
      }

      if (this.item.isTypeCampaign) {
        return `Submitted ${formatDate('daymonthyeartime', value)}`
      }

      return formatDate('daymonthyeartime', value)
    },
    showPaidWith() {
      return !this.item.isTypeCampaign && !this.item.isTypeMigratedOrder
    },
    showTotal() {
      return this.item.isTypePurchaseOrder
    },
    showGotIt() {
      return !this.item.isTypePurchaseOrder
    },
    fees() {
      return this.order.fees?.items || []
    },
    paymentFees() {
      let amount = this.getFeesByType('Processing Fee')

      if (this.user.isCombineFees) {
        amount += this.programFees
      }

      return amount
    },
    programFees() {
      return this.getFeesByType('Program Fee')
    },
    shippingFees() {
      return this.getFeesByType('Shipping Fee')
    },
    orderItems() {
      return this.order?.items ?? []
    },
    cashbackRebateTotal() {
      if (
        this.item.isTypeTopUpOrder &&
        this.order.fundingSource === 'cashback'
      ) {
        return this.order.total
      }

      return ensureNumber(this.order?.rebateTotal)
    },
    amountInPoints() {
      return this.calculatePointsToBurnOrder(this.item.total ?? 0)
    },
    formattedAmountInPoints() {
      return `${formatPoints(this.amountInPoints)} PTS`
    },
    formattedPoints() {
      return `${formatPoints(this.item.points)} PTS`
    },
    hasEPurchases() {
      return this.orderItems.find((item) => !item.physical)
    },
    hasPhysicalPurchases() {
      return this.orderItems.find((item) => item.physical)
    },
    campaignFormData() {
      const { contact, ...rest } = this.item.campaign

      return {
        ...rest,
        contactFirstName: contact.firstName,
        contactLastName: contact.lastName,
        contactEmail: contact.email,
        contactPhoneNumber: contact.phoneNumber,
      }
    },
  },
  methods: {
    formatPoints,
    getFeesByType(label) {
      const fees = this.fees?.filter?.((i) => i.label === label)

      return fees?.reduce?.((value, fee) => {
        return Number(fee?.amount ?? 0) + value
      }, 0)
    },
    showEPurchases() {
      this.$router.push({ hash: '#profile-e-purchases' })
    },
    onScroll(e) {
      this.hasShadow = e.target?.scrollTop > 0
    },
    print() {
      window.print()
    },
  },
}
</script>

<template>
  <div class="flex h-full w-full">
    <div
      class="flex h-full w-full flex-col rounded-t-3xl bg-eonx-neutral-50 sm:mr-6 sm:pt-[30px]"
    >
      <div
        class="relative mx-auto flex h-full w-full flex-col bg-white sm:max-w-xl sm:rounded-t-3xl sm:pt-[35px]"
      >
        <div
          class="mt-5 flex items-center justify-between px-2.5 pb-5 sm:mt-0 sm:px-[35px]"
        >
          <base-button v-if="!ui.mobile" look="link" @click="goBack">
            <base-icon svg="chevron-left" size="md" class="mr-[5px]" />
            <div class="-mt-px text-sm">Back</div>
          </base-button>

          <base-button
            v-if="showPrintButton"
            :disabled="loading"
            look="link"
            class="text-sm"
            @click="print"
          >
            <base-icon svg="plain/printer" size="md" class="mr-[5px]" />
            <span class="text-sm">Print Invoice</span>
          </base-button>
        </div>

        <div
          class="relative flex grow flex-col overflow-y-auto px-5 pb-[30px] sm:px-10"
          :class="{ 'shadow-inner': hasShadow }"
          @scroll="onScroll"
        >
          <div
            class="space-y-[5px]"
            :class="{
              'mb-[30px]': !showStatus,
            }"
          >
            <div
              class="flex justify-center text-center font-bold text-eonx-neutral-600"
            >
              <span
                v-if="loading"
                class="ml-[5px] animate-pulse rounded bg-eonx-neutral-50 text-transparent"
              >
                loading
              </span>
              <template v-else-if="item.isTypeMigratedOrder">
                Purchase ID #{{ item.purchaseId }}
              </template>
              <template
                v-else-if="
                  item.isTypePointsTransaction || item.isTypeMigratedPoints
                "
              >
                {{ item.pointSourceName }}
              </template>
              <template v-else-if="item.isTypeCampaign">
                Lead No. #{{ orderNumber }}
              </template>
              <template v-else>Order No. #{{ orderNumber }}</template>
            </div>

            <div class="text-center font-bold text-eonx-neutral-600">
              <div
                v-if="loading"
                class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
              >
                loading
              </div>
              <template v-else>
                {{ date }}
              </template>
            </div>
          </div>

          <div
            v-if="showStatus"
            class="mt-[5px] mb-[30px] text-center font-bold text-eonx-neutral-600"
          >
            <div
              v-if="loading"
              class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
            >
              loading
            </div>
            <template v-else>
              Status:
              <span class="capitalize" :class="[item.statusLabel.color]">
                {{ item.statusLabel.text }}
              </span>
            </template>

            <div v-if="item.performedBy" class="mt-1.5">
              Performed by: {{ item.performedBy.fullName }}
            </div>
          </div>

          <confirmation-form-data
            v-if="item.isTypeCampaign"
            :form="campaignFormData"
          />

          <div
            v-if="
              item.comments &&
              (item.isTypePointsTransaction || item.isTypeMigratedPoints)
            "
            class="my-11 text-center text-sm leading-5"
          >
            {{ item.comments }}
          </div>

          <div
            v-if="hasPhysicalPurchases"
            class="mb-14 text-center text-sm leading-5"
          >
            eStore Products will be sent to your shipping address within 5 to 15
            business days subject to stock levels. If you have any questions
            about your order, contact us
          </div>

          <div
            v-if="loading || orderItems.length > 0"
            class="mb-5 border-b border-zinc-100 pb-5"
          >
            <div
              v-if="orderItems.length > 0"
              class="mb-2.5 font-bold leading-6 text-eonx-neutral-600"
            >
              Summary
            </div>
            <div v-if="loading" class="mt-2.5 flex">
              <div
                class="h-20 w-20 shrink-0 animate-pulse rounded bg-eonx-neutral-50"
              />
              <div class="flex-1 pl-2.5">
                <div class="mb-2.5 flex w-full">
                  <div
                    class="flex-1 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
                  >
                    loading
                  </div>
                  <div
                    class="ml-12 shrink-0 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
                  >
                    loading
                  </div>
                </div>
                <div
                  class="mb-[5px] animate-pulse rounded bg-eonx-neutral-50 text-xs text-transparent"
                >
                  loading
                </div>
                <div
                  class="animate-pulse rounded bg-eonx-neutral-50 text-xs text-transparent"
                >
                  loading
                </div>
              </div>
            </div>
            <activity-summary-items
              v-if="orderItems.length > 0"
              :items="orderItems"
            />
          </div>

          <!-- Migrated Order -->
          <div
            v-if="item.isTypeMigratedOrder"
            class="mx-2.5 space-y-2.5 text-eonx-neutral-600"
          >
            <div class="text-xl font-bold text-black">Migrated Order</div>
            <div>
              This order has been migrated from the previous website.
              <router-link :to="{ name: 'faq' }" class="text-primary">
                Click here
              </router-link>
              for more information about orders purchased from the previous
              website
            </div>
            <div>
              <div>Product name</div>
              <div class="font-bold text-black">
                {{ item.productName }}
              </div>
            </div>
            <div>
              <div>Points redeemed</div>
              <div class="font-bold text-black">- {{ formattedPoints }}</div>
            </div>
          </div>

          <!-- points earned -->
          <div
            v-if="item.isTypePointsTransaction || item.isTypeMigratedPoints"
            class="mb-5 border-b border-zinc-100 pb-5"
          >
            <div class="mb-2.5 font-bold text-eonx-neutral-600">
              Points earned
            </div>

            <div class="space-y-[15px]">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <div class="h-12 w-12 overflow-hidden rounded-full">
                    <div class="h-full w-full text-primary">
                      <base-icon
                        svg="points/token"
                        size="md"
                        class="min-h-full min-w-full"
                      />
                    </div>
                  </div>

                  <div class="ml-[15px] flex flex-col">
                    <div class="font-bold text-eonx-neutral-800">Points</div>
                    <div class="text-sm text-eonx-neutral-600">
                      {{ item.pointSourceName }}
                    </div>
                  </div>
                </div>
                <div class="text-eonx-neutral-600">
                  +{{ formatPoints(pointsEarned) }} PTS
                </div>
              </div>
            </div>
          </div>

          <!-- paid with -->
          <div
            v-if="paymentMethods.length > 0"
            class="mb-5 border-b border-zinc-100 pb-5"
          >
            <div class="mb-2.5 font-bold text-eonx-neutral-600">Paid with</div>

            <div class="space-y-[15px]">
              <div
                v-for="(method, index) in paymentMethods"
                :key="index"
                class="flex items-center justify-between"
              >
                <div class="flex items-center">
                  <div class="h-12 w-12 overflow-hidden rounded-full">
                    <div
                      v-if="method.type === PaymentMethodType.points"
                      class="h-full w-full text-primary"
                    >
                      <base-icon
                        svg="points/token"
                        size="md"
                        class="min-h-full min-w-full"
                      />
                    </div>
                    <div
                      v-else-if="method.type === PaymentMethodType.bankAccount"
                      class="flex h-full w-full items-center justify-center bg-primary text-white"
                    >
                      <base-icon svg="v2/custom/bank" size="md" />
                    </div>
                    <div
                      v-else-if="method.type === PaymentMethodType.creditCard"
                      class="flex h-12 w-12 shrink-0 items-center justify-center"
                    >
                      <base-icon :svg="method.brand" size="xl" />
                    </div>
                    <div
                      v-else-if="method.type === PaymentMethodType.couponCode"
                      class="flex h-12 w-12 shrink-0 items-center justify-center text-primary"
                    >
                      <base-icon svg="coupon" size="2xl" />
                    </div>
                    <div v-else class="h-full w-full bg-eonx-neutral-50" />
                  </div>

                  <div class="ml-[15px] flex flex-col">
                    <div class="font-bold text-eonx-neutral-800">
                      {{ method.title }}
                    </div>
                    <div class="text-sm text-eonx-neutral-600">
                      <span
                        v-if="
                          method.type === PaymentMethodType.creditCard &&
                          method.details
                        "
                      >
                        **** **** **** {{ method.details.slice(-4) }}
                      </span>
                      <span
                        v-if="
                          method.type === PaymentMethodType.bankAccount &&
                          method.number
                        "
                      >
                        <template v-if="method.bsb || method.prefix">
                          BSB: {{ method.bsb || method.prefix }},
                        </template>
                        *** *** *{{ method.number.slice(-2) }}
                      </span>
                      <span
                        v-if="
                          method.type === PaymentMethodType.points &&
                          method.details
                        "
                      >
                        {{ method.details }}
                      </span>
                      <span
                        v-if="
                          method.type === PaymentMethodType.couponCode &&
                          method.details
                        "
                      >
                        {{ method.details }}
                      </span>
                    </div>
                  </div>
                </div>
                <div v-if="method.finalAmount" class="text-eonx-neutral-600">
                  {{ formatDollar(method.finalAmount) }}
                </div>
              </div>
            </div>
          </div>

          <!-- transaction amount details -->
          <div v-if="showTotal && !loading">
            <div class="flex items-center justify-between">
              <div class="font-bold text-eonx-neutral-800">
                <template v-if="item.isTypePaymentOrder">
                  Bill Pay Amount
                </template>
                <template v-else>Order Amount</template>
              </div>
              <div class="font-bold text-eonx-neutral-800">
                {{ formatDollar(item.subtotal) }}
              </div>
            </div>

            <div
              v-if="!user.isCombineFees && programFees"
              class="mt-[5px] flex items-center justify-between"
              data-testid="program-fee"
            >
              <div class="text-eonx-neutral-600">
                Program Fee
                <span class="inline-block whitespace-nowrap">
                  (inc. {{ taxationLabel }})
                </span>
              </div>
              <div class="text-eonx-neutral-600">
                {{ formatDollar(programFees) }}
              </div>
            </div>

            <div
              v-if="paymentFees"
              class="mt-[5px] flex items-center justify-between"
            >
              <div class="text-eonx-neutral-600">Payment Processing Fee</div>
              <div class="text-eonx-neutral-600">
                {{ formatDollar(paymentFees) }}
              </div>
            </div>

            <div
              v-if="shippingFees"
              class="mt-[5px] flex items-center justify-between"
            >
              <div class="text-eonx-neutral-600">Shipping Fee</div>
              <div class="text-eonx-neutral-600">
                {{ formatDollar(shippingFees) }}
              </div>
            </div>

            <div class="mt-[5px] flex items-center justify-between">
              <div class="text-2xl font-bold text-eonx-neutral-800">
                Total Amount
              </div>
              <div class="text-2xl font-bold text-eonx-neutral-800">
                {{ formatDollar(order.total) }}
              </div>
            </div>
          </div>

          <base-button
            v-if="hasEPurchases"
            class="mt-[30px]"
            look="outlined-color"
            full-width
            @click="showEPurchases"
          >
            View ePurchases
          </base-button>

          <base-button
            v-if="showGotIt && !loading"
            class="mx-auto mt-[30px] w-1/3"
            @click="$router.push({ name: 'activity' })"
          >
            Got it
          </base-button>
        </div>
      </div>
      <tax-invoice
        v-if="!loading"
        :is-type-purchase-order="item.isTypePurchaseOrder"
        :is-type-payment-order="item.isTypePaymentOrder"
        :is-type-cashback-reconciliation-feed="
          item.isTypeCashbackReconciliationFeed
        "
        :is-type-statement-order="item.isTypeStatementOrder"
        :is-type-batch-order="item.isTypeBatchOrder"
        :is-type-cash-transaction="item.isTypeCashTransaction"
        :content="printContent"
      />
    </div>
  </div>
</template>
