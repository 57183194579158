<script>
import find from 'lodash-es/find'
import get from 'lodash-es/get'
import { nextTick } from 'vue'
import { directive as clickaway } from 'vue-clickaway'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'ew-categories',
  components: {
    BaseButton,
    BaseIcon,
  },
  directives: {
    clickaway,
  },
  props: {
    title: {
      type: String,
      default: 'Sort By',
    },
    categories: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: [String, Number],
      default: '',
    },
    patchRoute: {
      type: Function,
      default: () => ({}),
    },
    renderLink: {
      type: Boolean,
      default: true,
    },
    isFiltersSelected: {
      type: Boolean,
      default: false,
    },
    filterEmpty: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    disabled() {
      return get(this.filteredCategories, 'length', 0) === 0
    },
    filteredCategories() {
      if (!this.filterEmpty) {
        return this.categories
      }

      if (this.categories) {
        return this.categories.filter((item) => item.count)
      }

      return []
    },
    selectLabel() {
      if (this.selectedId) {
        const selectedItem = find(this.filteredCategories, {
          id: this.selectedId,
        })

        if (selectedItem) {
          return selectedItem.label
        }
      }
      return get(this.filteredCategories, '0.label', '')
    },
    bindings() {
      return this.isOpen
        ? {
            'aria-owns': 'ew-categories-list',
          }
        : {}
    },
  },
  watch: {
    $route() {
      this.close()
    },
    isOpen(value) {
      if (value) {
        nextTick(() => {
          this.$refs.currentButton?.$el.focus()
        })
      }
    },
  },
  methods: {
    toggle() {
      if (this.disabled) return
      if (ui.mobile) {
        this.showCategoriesModal()
      } else {
        this.isOpen = !this.isOpen
      }
    },
    close() {
      this.isOpen = false
    },
    done() {
      this.$emit('apply')
      this.close()
    },
    onClear() {
      this.$emit('clear')
      this.close()
    },
    showCategoriesModal() {
      modal.show('ew-categories', {
        props: {
          title: this.title,
          categories: this.categories,
          generateRouteTo: this.generateRouteTo,
          checkActive: this.checkActive,
          renderLink: this.renderLink,
          onConfirm: this.onSelect,
        },
      })
    },
    generateRouteTo(category) {
      const to = {
        name: this.$route.name,
        query: this.$route.query,
        params: {
          type: this.$route.params.type,
          category: category.id,
          preventTopScrolling: true,
        },
      }

      return { ...to, ...this.patchRoute(to) }
    },
    checkActive(category = {}) {
      if (this.selectedId) {
        return category.id === this.selectedId
      }
      return !this.selectedId && !category.id
    },
    onSelect(value) {
      this.$emit('change', value)

      if (ui.mobile) {
        const params = {}

        if (value) {
          params.category = value
        }

        modal.hide('ew-categories')
        // Currently causes duplication navigation error as navigation occurs via store or inside modal router-link

        // this.$router.push({
        //   name: this.$route.name,
        //   params,
        // })
      } else {
        if (this.scrollToId) {
          this.$scrollTo('#' + this.scrollToId)
        }
      }

      if (this.name) {
        this.saveToStorage(value)
      }
    },
  },
}
</script>

<template>
  <div class="relative">
    <span
      v-bind="bindings"
      class="flex-0 flex h-10 w-full max-w-full items-center justify-between truncate rounded border border-solid border-divider bg-light px-[15px] disabled:bg-dark disabled:text-eonx-neutral-600 sm:w-64"
      role="listbox"
      aria-haspopup="listbox"
      tabindex="0"
      :aria-label="selectLabel"
      @click="toggle"
      @keyup.enter="toggle"
      @keyup.space="toggle"
    >
      <span class="truncate text-eonx-neutral-800">
        {{ disabled ? title : selectLabel }}
      </span>

      <span class="ml-5 shrink-0 text-eonx-neutral-600">
        <base-icon
          svg="plain/chevron-bottom-v2"
          class="transform text-eonx-neutral-700 transition duration-200"
          :class="[isOpen && 'rotate-180']"
          size="xxs"
        />
      </span>
    </span>
    <div
      v-if="isFiltersSelected"
      class="absolute top-0 right-0 h-2.5 w-2.5 rounded-full bg-error-700 sm:-mt-[5px] sm:-mr-[5px]"
      :style="{
        marginTop: ui.mobile && '3px',
        marginRight: ui.mobile && '3px',
      }"
    />
    <transition
      v-if="!ui.mobile"
      enter-class="scale-75 -translate-y-2.5 opacity"
      leave-to-class="scale-75 -translate-y-2.5 opacity-0"
    >
      <div
        v-if="isOpen"
        v-clickaway="close"
        class="translate absolute right-0 top-full z-10 mt-2.5 flex max-h-screen-60 min-w-80 max-w-full origin-top-right transform flex-col overflow-hidden rounded border bg-light shadow-md duration-100"
        @keyup.esc="close"
      >
        <div
          class="flex h-14 shrink-0 items-center justify-between border-b pl-5"
        >
          <h3 class="text-xl leading-none">
            {{ title }}
          </h3>
          <div v-if="isFiltersSelected" class="space-x-2.5 px-5">
            <base-button
              ref="currentButton"
              look="link"
              size="md"
              @click="onClear"
            >
              Clear all
            </base-button>
            <base-button look="link" size="md" @click="done">Done</base-button>
          </div>

          <base-button
            v-else
            ref="currentButton"
            class="mr-5"
            alt="Close categories"
            icon="plain/close"
            @click="close"
          />
        </div>

        <div class="grow overflow-y-auto pb-[15px]">
          <ul id="ew-categories-list" role="listbox">
            <li
              v-for="category in filteredCategories"
              :key="category.id"
              class="cursor-pointer"
              @click="onSelect(category.id)"
            >
              <component
                :is="renderLink ? 'router-link' : 'button'"
                v-bind="renderLink ? { to: generateRouteTo(category) } : {}"
                class="flex h-10 w-full items-center px-5"
                role="option"
                :aria-selected="checkActive(category)"
                :class="[
                  checkActive(category)
                    ? 'cursor-default bg-primary-lightest font-bold text-primary'
                    : 'hover:bg-dark',
                ]"
              >
                {{ category.label }}
              </component>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>
