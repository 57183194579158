import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import { useAddresses } from '/~/composables/addresses'
import Address from '/~/composables/addresses/core/Address'
import { useCart } from '/~/composables/cart'
import { useQuickBuyCheckout } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import {
  usePaymentMethods,
  useVerifyCard,
} from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'

export function useQuickBuyComponent() {
  const router = useRouter()
  const route = useRoute()
  const {
    loadingFees,
    payment,
    readyToPay,
    total,
    resetMethods,
    initItems,
    productsHasDelivery,
  } = useQuickBuyCheckout()
  const { isUserAddressEnabled } = useProvider()
  const { verifyingCard } = useVerifyCard()
  const { addresses, getAddresses } = useAddresses()
  const { cart, cartOrderAddresses, fetchCartOrderAddresses, cartIsEmpty } =
    useCart()
  const { fetchCurrentPurchases } = usePurchases()
  const { fetchPaymentMethods } = usePaymentMethods()

  const scrollTop = ref(0)
  const isLoading = ref(false)
  const isMounted = ref(false)

  const transitions = ref({
    'enter-class': '',
    'enter-active-class': 'absolute',
    'leave-active-class': '',
  })

  const processing = computed(
    () => verifyingCard.value || payment.submitting || loadingFees.value
  )

  const billingAddress = computed(() =>
    (addresses.value ?? []).find(
      (address: Address) =>
        address.id === (cartOrderAddresses.billing as any)?.id
    )
  )

  const payDisabled = computed(
    () =>
      !readyToPay.value || (isUserAddressEnabled.value && !billingAddress.value)
  )

  function onScroll(event: Event) {
    if (event.target) {
      scrollTop.value = (event.target as HTMLElement).scrollTop
    }
  }

  async function onSubmit() {
    if (isUserAddressEnabled.value && !billingAddress.value) {
      return
    }

    try {
      const addressId = (billingAddress.value as any)?.id

      payment.billingAddressId =
        addressId?.toString() ??
        cartOrderAddresses.billing?.id.toString() ??
        payment.address?.id?.toString()

      payment.shippingAddressId = productsHasDelivery.value
        ? cartOrderAddresses.shipping?.id.toString() ??
          addressId?.toString() ??
          payment.address?.id?.toString()
        : null

      await payment.checkout({
        address: billingAddress.value,
        onComplete,
      })
    } catch (error: any) {
      emitter.emit('notify', {
        text: error,
        type: 'error',
        duration: 5000,
      })
    }
  }

  async function onComplete(result: any) {
    try {
      await fetchCurrentPurchases()

      fetchPaymentMethods(FlowType.quickBuy, null)

      // Add small delay before update activity, as item might not be included into response yet
      setTimeout(() => emitter.emit('activity:refresh'), 2000)
      router.push({
        name: 'quick-buy-confirmation',
        params: {
          orderId: `${(result as any).number}`,
        },
      })
    } catch (error: any) {
      if (error.errors) {
        router.push({
          name: 'purchase-checkout-payment-failed',
          query: { errors: error.errors, message: error.message },
        })
      }
    }
  }

  async function fetchInitialData() {
    isLoading.value = true
    resetMethods()

    initItems({
      type: route.query?.type ?? null,
      query: route.query ?? null,
    })

    const promises = []

    if (isUserAddressEnabled.value) {
      promises.push(getAddresses(), fetchCartOrderAddresses())
    }

    await Promise.all(promises)
      .then(([addresses]) => {
        if (!isMounted.value) return
        if (isUserAddressEnabled.value && cart.count && !addresses?.length) {
          router.push({ hash: '#profile-add-address' })
        }
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  function redirect() {
    const splitHash = route.hash.split('/')
    const hash = splitHash?.[0]

    if (hash === '#cart-checkout-confirmation') {
      return
    }

    router.replace({ name: 'home' })

    emitter.emit('notify', {
      text: 'Your quick buy is empty',
      type: 'warning',
    })
  }

  onBeforeMount(fetchInitialData)

  onMounted(() => {
    isMounted.value = true
  })

  onBeforeUnmount(() => {
    isMounted.value = false
  })

  watch(cartIsEmpty, (isEmpty) => {
    if (isEmpty) {
      redirect()
    }
  })

  watch(route, (to, from) => {
    transitions.value =
      from.meta?.index < to.meta?.index
        ? {
            'enter-class': 'translate-x-full opacity-0',
            'enter-active-class': 'absolute',
            'leave-active-class': 'absolute -translate-x-full opacity-0',
          }
        : {
            'enter-class': '-translate-x-full opacity-0',
            'enter-active-class': 'absolute',
            'leave-active-class': 'absolute translate-x-full opacity-0',
          }
  })

  return {
    scrollTop,
    isLoading,
    processing,
    payDisabled,
    total,
    transitions,
    onScroll,
    onSubmit,
  }
}
