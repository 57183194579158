<script>
import CheckoutMobile from '/~/views/checkout.v2/views/checkout/checkout.mobile.vue'
import { useEwalletSdk } from '/~/composables/ewallet-sdk'

export default {
  name: 'checkout-mobile',
  extends: CheckoutMobile,
  setup() {
    const parent = CheckoutMobile.setup?.(...arguments)
    const { sendCloseEvent } = useEwalletSdk()

    function onCancel() {
      sendCloseEvent()
    }

    return {
      ...parent,
      onCancel,
    }
  },
}
</script>
