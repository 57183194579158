<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import { RecroomAward } from '/~rec/core/award'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import { RecroomPostNomination } from '/~rec/core/post-nomination'
import RecognitionForm from '/~rec/components/recognition/recognition-form.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { useGroups } from '/~rec/composables/groups'
import { useBackendValidation } from '/~rec/composables/backend-validation'

export default {
  name: 'rec-award',
  components: {
    BaseIcon,
    BaseBack,
    BaseAvatar,
    RecognitionForm,
    HeaderMobile,
  },
  props: {
    awardId: {
      type: String,
      default: '',
    },
    nominee: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { getMember } = useGroups()
    const { backendErrors, processBackendErrors } = useBackendValidation()

    return {
      getMember,
      backendErrors,
      processBackendErrors,
      ui,
    }
  },
  data() {
    return {
      success: false,
      submitting: false,
      profile: null,
      userId: null,
      award: null,
      awardLoading: false,
    }
  },
  computed: {
    headerTitle() {
      return !this.success
        ? 'Nominate someone for this award!'
        : 'Your nomination has been sent!'
    },
    headerLabel() {
      return (
        'Select who you want to nominate from the list below, write them a nomination ' +
        'message and simply click nominate.'
      )
    },
    canBeNominated() {
      return this.award?.allowedUsers?.canBeNominated
    },
    backRoute() {
      const name = this.userId ? 'rec-award-nominate-user' : 'rec-awards'
      const params = this.userId
        ? { ...this.$route.params, nomineeId: this.userId }
        : this.$route.params

      return {
        name,
        params,
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.userId = this.$route.query.nominateTo
      const awardId = this.$route.params.awardId
      const nominee = this.$route.params.nominee || this.nominee
      const awardParam = this.$route.params.award

      try {
        this.awardLoading = true

        if (awardParam) {
          this.award = awardParam
        } else {
          this.award = await RecroomAward.fetchById(awardId)
        }

        if (!this.canBeNominated) {
          await this.award.getAllowedUsers()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.awardLoading = false
      }

      if (nominee) {
        this.profile = nominee
      } else if (this.userId) {
        this.profile = this.getMember(this.userId)
      }
    },
    async nominate(formData) {
      try {
        if (!this.award || !formData.nominee || !formData.reason) return

        this.submitting = true

        const form = {
          ...formData,
          notify: false,
        }

        if (form.nominee) {
          form.nominee = form.nominee.orgMemberId || formData.nominee?.id
        }

        await RecroomPostNomination.create(this.award.id, form)
        this.award.refresh()

        this.success = true
        delete this.$route.params.showConfirm
      } catch (error) {
        this.processBackendErrors(error, { data: formData })
      } finally {
        this.submitting = false
      }
    },
    closeNominate() {
      modal.hideAll()
      this.$router.push(this.backRoute)
    },
    filterNominees(allUsers) {
      return allUsers.filter((user) => this.canBeNominated.includes(user.id))
    },
    back() {
      if (this.$route.params.showConfirm) {
        modal.show('rec-leaving-confirm', {
          on: {
            'stay-here': () => {
              modal.hide()
            },
            'leave-page': () => {
              delete this.$route.params.showConfirm
              modal.hide()
              this.closeModal()
            },
          },
        })
      } else {
        this.closeModal()
      }
    },
    closeModal() {
      if (this.$route.query.nominateTo) {
        this.closeNominate()
      } else {
        modal.hide()
      }
    },
  },
}
</script>

<template>
  <div
    class="mx-auto flex w-full max-w-screen-md flex-col md:p-[30px]"
    :class="{
      'py-0': ui.mobile,
    }"
  >
    <header-mobile
      v-if="!ui.desktop"
      title="Nominate"
      :show-back="!success && !submitting"
      @back="back()"
    />
    <div v-if="ui.desktop && !success" class="px-5">
      <base-back :to="backRoute" class="mb-6" />
    </div>
    <div
      v-if="award"
      class="px-5"
      :class="{
        'pb-[88px]': ui.mobile,
      }"
    >
      <recognition-form
        :submitting="submitting"
        :success="success"
        :loading="awardLoading"
        :nominee-label="'Nominee'"
        :nominee="profile"
        :header-title="headerTitle"
        :header-label="headerLabel"
        :gift-label="'Award'"
        :filter-users="filterNominees"
        :user-id="userId"
        :backend-errors="backendErrors"
        @close="closeNominate()"
        @nominate="nominate($event)"
      >
        <template #gift-img>
          <base-avatar class="h-full w-full" :src="award.image" />
        </template>
        <template #gift-title>
          {{ award && award.title }}
        </template>
        <template #gift-subtitle>
          <ul class="flex flex-wrap truncate text-sm">
            <li
              class="mr-5 flex items-center truncate text-eonx-neutral-600"
              :class="{
                'float-left': ui.desktop,
              }"
            >
              <base-icon
                svg="rec/users"
                :size="16"
                class="mr-0.5 shrink-0 xxs:mr-2.5"
              />

              <div class="flex-1 truncate">
                {{ award.nominateLimitLabel }}
              </div>
            </li>
            <li
              v-if="
                award.currentPeriod.endsAt &&
                award.currentPeriod.endsAt.fromNow()
              "
              class="flex items-center truncate text-eonx-neutral-600"
            >
              <base-icon
                svg="rec/time"
                :size="16"
                class="mr-0.5 shrink-0 xxs:mr-2.5"
              />

              <div class="flex-1 truncate">
                Nominations close
                {{ award.currentPeriod.endsAt.fromNow() }}
              </div>
            </li>
          </ul>
        </template>
        <template #nominee-label>Nominee</template>
      </recognition-form>
    </div>
  </div>
</template>
