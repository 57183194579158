<script>
export default {
  name: 'drawer-modal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: 'modals-portal',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <portal :to="to">
    <transition name="drawer-modal" appear>
      <div
        v-show="visible"
        class="absolute inset-0 z-modal flex justify-center bg-overlay"
        @click.self="$emit('hide')"
      >
        <transition duration="400">
          <div
            v-if="visible"
            class="drawer-modal absolute inset-x-0 bottom-0 z-10 flex h-auto max-h-full flex-col overflow-y-auto overflow-x-hidden rounded-t-xl"
          >
            <slot name="iconHeader" />
            <div class="max-h-full grow overflow-y-auto bg-white p-5">
              <div v-if="title" class="px-5 text-center text-xl font-bold">
                {{ title }}
              </div>
              <slot />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </portal>
</template>
