<script setup lang="ts">
import { computed } from 'vue'
import './base-field.scss'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

const props = withDefaults(
  defineProps<{
    inputId?: string
    expanded?: boolean
    focused?: boolean
    iconPlain?: boolean
    border?: boolean
    borderFloated?: boolean
    entryClass?: string
    labelClass?: string
    fieldStyle?: string | Partial<CSSStyleDeclaration>
    theme?: string
    disabled?: boolean
    nolabel?: boolean
    floated?: boolean
    error?: string
    icon?: string
    iconSize?: string | number
    label?: string
    value?: string | number
    required?: boolean
    requiredAsterisk?: boolean
    description?: string
  }>(),
  {
    inputId: '',
    expanded: false,
    focused: false,
    iconPlain: false,
    border: true,
    borderFloated: false,
    entryClass: 'rounded h-10',
    labelClass: '',
    theme: '',
    disabled: false,
    nolabel: false,
    floated: false,
    iconSize: 'md',
    required: false,
    requiredAsterisk: true,
  }
)

const isLocked = computed(() => props.disabled && Boolean(props.value))
const isDisabled = computed(() => props.disabled && Boolean(!props.value))
</script>

<template>
  <div
    class="base-field"
    :class="{
      [`base-field--${theme}`]: theme,
      'base-field--disabled': isDisabled,
      'base-field--locked': isLocked,
      'base-field--expanded': expanded,
      'base-field--label-no': nolabel,
      'base-field--floated': floated,
      'base-field--border': border,
    }"
    :style="fieldStyle"
  >
    <div
      ref="content"
      class="base-field__entry"
      :class="{
        [entryClass]: true,
        'base-field__entry--focused': focused,
        'base-field__entry--disabled': disabled,
        'base-field__entry--error': error,
        'base-field__entry--with-icon': icon,
        'base-field__entry--plain-icon': iconPlain,
        'base-field__entry--floated': floated,
      }"
    >
      <slot name="icon">
        <div v-if="icon" class="base-field__icon base-field__icon--left">
          <div v-if="icon === '$'" class="base-field__dollar-sign">$</div>
          <base-icon v-else :svg="icon" :size="iconSize" />
        </div>
      </slot>
      <div
        v-if="$slots['prefix']"
        class="base-field__icon base-field__icon--left base-field__prefix"
        :class="{
          'base-field__prefix--error': error,
        }"
      >
        <slot name="prefix" />
      </div>
      <slot />
      <slot name="icon-right" />
    </div>

    <label
      :id="inputId + '-label'"
      class="base-field__label"
      :class="[
        {
          'base-field__label--expanded': expanded,
          'base-field__label--focused': focused,
          'base-field__label--error': error,
          'base-field__label--with-icon': icon && !expanded,
          'base-field__label--floated': floated,
          'base-field__label--border-floated': borderFloated,
          'base-field__label--filled': !!value,
        },
        labelClass,
      ]"
      :for="inputId"
      @click="$emit('label-click')"
    >
      <span>{{ label }}</span>
      <slot name="label-suffix" />
      <span v-if="required && label && requiredAsterisk">&nbsp;*</span>
    </label>
    <div class="mt-1 space-y-1">
      <div
        :id="inputId + '-disclaimer'"
        class="base-field__message inline"
        :class="{
          'base-field__message--error': error,
        }"
      >
        <slot name="disclaimer" />
      </div>
      <div
        v-if="error || description"
        :id="inputId + '-message'"
        ref="message"
        class="base-field__message space-y-2"
        :class="{
          'base-field__message--error': error,
        }"
        data-test="base-field-error"
      >
        <slot name="error">
          <div v-html="error || description" />
        </slot>
      </div>
    </div>
  </div>
</template>
