<script>
import BaseButton from '/~/components/base/button/base-button'
import { useCommunityHub } from '/~/extensions/community-hub/composables'

export default {
  name: 'community-hub-catalog-empty',
  components: {
    BaseButton,
  },
  setup() {
    const { algolia, resetFilters } = useCommunityHub()

    function refresh() {
      window.location.reload()
    }

    return {
      algolia,
      resetFilters,
      refresh,
    }
  },
}
</script>

<template>
  <div>
    <template v-if="algolia.message">
      <p>Sorry, we could not load your offers.</p>
      <p>Please refresh your web browser to try again.</p>
      <base-button class="mt-10" @click="refresh">Refresh page</base-button>
    </template>
    <template v-else>
      <p>Sorry, we couldn't find any retailer corresponding to your search.</p>
      <base-button class="mt-10" @click="resetFilters">
        Reset filters
      </base-button>
    </template>
  </div>
</template>
