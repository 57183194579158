<script lang="ts" setup>
import { ValidationObserver } from 'vee-validate'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'
import BaseField from '/~/components/base/field/base-field.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EmptyState from '/~/components/empty-state/empty-state.v2.vue'
import EwalletItem from '/~/components/withdraw/list/ewallet-item.vue'
import WithdrawItem from '/~/components/withdraw/list/withdraw-item.vue'
import { useForm } from '/~/composables/base/use-form'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { useEwallet } from '/~/composables/ewallet'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useUI } from '/~/composables/ui'
import { useWithdraw } from '/~/composables/withdraw/use-withdraw'

const {
  getWithdrawalBankAccounts,
  withdrawalBankAccounts,
  isBankAccountsLoading,
  withdrawCash,
  withdrawCashConfig,
} = useWithdraw()
const { isRequiredAsteriskVisible } = useUI()
const { fetchPaymentMethods } = usePaymentMethods()
const { ewalletBalance, isEwalletEnabled } = useEwallet()
const { validationObserverRef } = useForm()
const { ewalletLabels } = useCms()

const emit = defineEmits<(event: 'close') => void>()
const router = useRouter()
const isLoading = ref(false)
const flowType = ref(FlowType.purchase)
const amount = ref('')
const description = ref('')

const minValue = computed(() => withdrawCashConfig.value.eWallet.min)
const maxValue = computed(() => withdrawCashConfig.value.eWallet.max)
const hasWithdrawalAccounts = computed(
  () => withdrawalBankAccounts.value.length
)
const hasErrors = computed(() => validationObserverRef.value?.flags.invalid)
const canProceed = computed(
  () =>
    amount.value &&
    !hasErrors.value &&
    (withdrawalBankAccounts.value[0] as any).status?.toLowerCase() ===
      'verified'
)

function onCloseClicked() {
  emit('close')
  emitter.emit('ewallet:close')
}

async function submit() {
  isLoading.value = true

  try {
    const result = await withdrawCash(amount.value, description.value)

    if ((result as any).status === 'completed') {
      router.push({
        hash: `#profile/withdraw-funds/confirmation/${(result as any).number}`,
      })
    }
    fetchPaymentMethods()
  } catch (error) {
    console.error('ewallet-menu', error)
  } finally {
    isLoading.value = false
  }
}

getWithdrawalBankAccounts()
</script>

<template>
  <validation-observer
    v-slot="{ errors, handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <base-aside-page
      :title="`Withdraw your ${ewalletLabels.ewalletCash}`"
      no-padding
      class="bg-white"
    >
      <div class="p-5">
        <base-loader v-if="isBankAccountsLoading" fullwidth />
        <div v-else-if="hasWithdrawalAccounts">
          <div class="mb-6">
            <div class="mb-4 font-bold">Withdraw from</div>
            <ewallet-item
              v-if="isEwalletEnabled"
              :flow-type="flowType"
              :balance="`${ewalletBalance}`"
              to="modals-portal"
            />
          </div>
          <div>
            <div class="mb-4 font-bold">Withdraw to</div>
            <withdraw-item :method="withdrawalBankAccounts[0]" />
          </div>
          <div class="mt-6">
            <base-field
              :error="
                errors['withdrawCashAmount']
                  ? errors['withdrawCashAmount'][0]
                  : null
              "
              label="Withdrawal amount"
              label-class="!-top-4 font-bold"
              required
              class="mt-10"
              :required-asterisk="isRequiredAsteriskVisible"
            >
              <div class="pl-2.5 text-eonx-neutral-700">
                <base-icon svg="v2/box-icons/bx-dollar" :size="20" />
              </div>
              <base-currency-input
                v-model="amount"
                :validation="{
                  rules: `required|min_value:${minValue}|withdrawal_balance:${ewalletBalance}|max_value:${maxValue}`,
                  name: 'Amount',
                  vid: 'withdrawCashAmount',
                }"
                name="amount"
                :currency="null"
                class="w-full"
                allow-null-value
              />
            </base-field>
          </div>

          <hr class="border-eonx-neutral-200" />

          <p class="my-6 text-eonx-neutral-600">
            Before completing your withdrawal, take a moment to ensure you're
            sending your funds to the right destination. Once a withdrawal is
            initiated, it will not be reversible.
          </p>
          <p class="text-eonx-neutral-600">
            Payments made before 5 p.m. Monday to Friday (excluding public
            holidays) will be received the next banking day
          </p>
        </div>
        <empty-state v-else class="flex h-full flex-col pt-16">
          <template #icon>
            <div
              class="mr-5 flex h-16 w-16 items-center justify-center rounded-full bg-primary"
            >
              <base-icon
                svg="heroicons/solid/building-library"
                :size="40"
                class="text-white"
              />
            </div>
          </template>
          <template #title>Add a bank account to transfer funds</template>
          <template #description>
            <div>You can have only one bank account linked at a time.</div>
            <base-action
              appearance="button"
              type="link"
              class="mt-6 block"
              :to="{ hash: '#profile/withdraw-funds/add-payment-method' }"
            >
              Add an account
            </base-action>
          </template>
        </empty-state>
      </div>
      <template v-if="hasWithdrawalAccounts" #footer>
        <div class="flex gap-x-2 border-t border-eonx-neutral-200 p-5">
          <div class="w-full">
            <base-button
              class="w-full"
              :disabled="isLoading"
              @click="onCloseClicked"
            >
              Cancel
            </base-button>
          </div>
          <div class="w-full">
            <base-button
              class="w-full"
              :disabled="!canProceed || isLoading"
              :loading="isLoading"
              @click="handleSubmit(submit)"
            >
              Withdraw
            </base-button>
          </div>
        </div>
      </template>
    </base-aside-page>
  </validation-observer>
</template>
