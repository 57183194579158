<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'community-hub-offer-terms-modal',
  components: {
    BaseMdl,
  },
  props: {
    terms: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <base-mdl title="Offer Terms" fullscreen="mobile">
    <div v-html="terms" />
  </base-mdl>
</template>
