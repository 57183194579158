<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { PaymentMethodBankAccount } from '/~/types/api'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useProvider } from '/~/composables/provider'
import Desktop from '../db-option/desktop.vue'
import Mobile from '../db-option/mobile.vue'

export type BankAccount = PaymentMethodBankAccount & {
  value?: string
  text?: string
}

interface Props {
  method: BankAccount
  isSelected?: boolean
  noPadding?: boolean
  isOptionSelected?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
  noPadding: false,
  isOptionSelected: false,
})

const router = useRouter()
const route = useRoute()

const { isBankAccountsNavEnabled } = useProvider()

const icon = computed(() => {
  return 'v2/custom/bank'
})

const status = computed(() => (props.method?.status ?? '').toLowerCase())
const isVerified = computed(
  () => status.value === 'verified' || !isBankAccountsNavEnabled.value
)
const component = computed(() => (ui.mobile ? Mobile : Desktop))

async function onClick(e: MouseEvent) {
  if (isVerified.value) {
    return
  }

  modal.hideAll()
  await router.push({
    hash: '#profile-payment-methods',
    query: route.query,
  })
  bottomSheet.show('method-verify', {
    to: 'menu-modal',
    props: {
      method: props.method,
    },
  })
}
</script>

<template>
  <component
    :is="component"
    v-bind="props"
    :icon="icon"
    :is-verified="isVerified"
    @click-option="onClick"
  />
</template>
