import { nanoid } from 'nanoid'

export default {
  label: 'Australia Post',
  value: 'auspost',
  preset: {
    items: [
      {
        id: nanoid(),
        divider: false,
        mobile: true,
        enabled: true,
        label: 'Notifications',
        action: {
          enabled: true,
          label: '',
          type: 'action',
          value: 'show-notifications',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        icon: 'notifications',
      },
      {
        id: nanoid(),
        divider: false,
        mobile: true,
        enabled: true,
        label: 'Open search',
        action: {
          enabled: true,
          label: '',
          type: 'action',
          value: 'toggle-search',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        icon: 'ppsummer/search',
        children: [],
      },
      {
        id: nanoid(),
        mobile: true,
        enabled: true,
        label: 'Show cart',
        action: {
          enabled: true,
          label: '',
          type: 'action',
          value: 'show-cart',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        icon: 'symbion/cart',
        children: [],
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Show eWallet',
        action: {
          enabled: true,
          label: '',
          type: 'action',
          value: 'show-optus-profile',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        icon: 'user',
      },
    ],
  },
}
