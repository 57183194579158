<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import BaseButton from '/~/components/base/button/base-button'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PaymentMethodsList from '/~/components/payments/payments-methods-list.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import QuickBuyHeader from '/~/views/quick-buy/components/header/quick-buy-header.mobile.vue'
import { useQuickBuyPaymentMethodsComponent } from '/~/views/quick-buy/views/payment-methods/payment-methods.component'

const router = useRouter()
const {
  isShowAddButton,
  addButtonDisabled,
  addPaymentMethod,
  loading,
  backRoute,
} = useQuickBuyPaymentMethodsComponent()
</script>

<template>
  <div>
    <div v-if="isShowAddButton" class="my-5 flex items-center justify-between">
      <base-button
        look="link"
        :disabled="addButtonDisabled"
        @click="addPaymentMethod"
      >
        + Add Payment Method
      </base-button>
    </div>
    <base-loader v-if="loading" class="mt-[30px] w-full" />

    <payment-methods-list
      v-else
      :flow-type="FlowType.quickBuy"
      class="mt-[5px] space-y-6"
    />

    <quick-buy-header @back="router.push(backRoute)">
      Payment methods
    </quick-buy-header>
  </div>
</template>
