import api from '/~rec/core/api'
import { RecroomUser } from './user'
import { RecroomPost } from './post'
import { RecroomAward } from './award'

export class RecroomPostNomination extends RecroomPost {
  constructor(raw = {}) {
    super(raw)

    this.award = raw.award && new RecroomAward(raw.award)
    this.recipient = raw.nominee && new RecroomUser(raw.nominee)
    this.sender = raw.author && new RecroomUser(raw.author)
  }

  get reason() {
    return this.raw.reason
  }

  static async create(awardId, data) {
    const res = await api.post(
      `gamification/awards/${awardId}/nominations`,
      data,
      { notify: false }
    )

    return new RecroomPostNomination(res.data)
  }
}
