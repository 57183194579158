<script>
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import DrawerDeletableItem from '/~/components/drawer/components/drawer-deletable-item.vue'
import MenuPointsPartner from '../../../components/rows/menu-points-partner.vue'

export default {
  name: 'profile-points-partner-item',
  components: {
    DrawerDeletableItem,
    MenuPointsPartner,
    ButtonIcon,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      deleting: false,
    }
  },
  methods: {
    async onDelete() {
      // this.deleting = true
      // this.deleting = false
    },
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<template>
  <drawer-deletable-item :deleting="deleting" @delete-confirmed="onDelete">
    <template #default="{ toggleDelete }">
      <menu-points-partner :partner="partner" @click="onClick">
        <template #action>
          <button-icon
            v-if="deletable"
            size="sm"
            icon="trash"
            @click.stop="toggleDelete"
          />
        </template>
      </menu-points-partner>
    </template>
  </drawer-deletable-item>
</template>
