<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useCvvCodeComponent } from '/~/composables/payment-methods/use-cvv-code-component'

const props = defineProps<{
  cardBrand: 'American Express' | 'Visa' | 'Mastercard'
  onConfirm?: (cvv: string) => void
  onCancel?: () => void
}>()

const cvvInput = ref<HTMLInputElement | null>(null)

const { cvv, cvvMask, cvvPlaceholder, isDisabled, onConfirm, onCancel } =
  useCvvCodeComponent({
    cardBrand: props.cardBrand,
    onConfirm: props.onConfirm,
    onCancel: props.onCancel,
    hide,
  })

onMounted(() => {
  nextTick(() => {
    cvvInput.value?.focus?.()
  })
})

function hide() {
  modal.hide()
}
</script>

<template>
  <base-mdl
    v-analytics:hide.custom="{
      pageGroup: 'Make a payment',
      component1: 'Card CVC',
      cta: 'Cancel',
    }"
    width="xs"
    closable
    @hide="onCancel"
  >
    <div class="flex items-center justify-center text-primary">
      <base-icon svg="symbion/card-cvv" :size="80" />
    </div>

    <p class="text-center text-2xl font-bold">Enter your card CVC</p>

    <div class="h-16 w-full">
      <base-input
        ref="cvvInput"
        v-model="cvv"
        v-analytics:keyup="
          (e) => {
            if (e.key === 'Enter' && !isDisabled) {
              return {
                pageGroup: 'Make a payment',
                component1: 'Card CVC',
                label: 'CVV submitted',
              }
            }
          }
        "
        class="mx-auto w-20 text-center"
        :mask="{ mask: cvvMask }"
        name="cvv"
        look="box"
        :placeholder="cvvPlaceholder"
        input-class="text-center"
        @keyup.enter="onConfirm"
      />
    </div>

    <base-button
      v-analytics:click="{
        pageGroup: 'Make a payment',
        component1: 'Card CVC',
        cta: 'Confirm',
      }"
      color="primary"
      class="mt-11 w-full"
      :disabled="isDisabled"
      @click="onConfirm"
    >
      Confirm
    </base-button>
  </base-mdl>
</template>
