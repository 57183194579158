import api from '/~/core/api'

export class Pipeline {
  constructor(raw) {
    this.raw = raw
  }

  static formTitle(defaultTitle) {
    const titleEle = document.querySelector('.title-html-offer')

    if (titleEle) {
      return titleEle.innerHTML
    }

    return defaultTitle
  }

  static async submit(campaignName, payload) {
    return api
      .post(`/v3/campaign/${campaignName}`, payload)
      .then(({ data }) => data)
  }
}
