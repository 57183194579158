<script>
import ui from '/~/core/ui'
import GcCatalog from '/~/extensions/giftcards/components/gc-catalog.vue'
import GcFilterDesktop from '/~/extensions/giftcards/components/gc-filter.desktop.vue'
import GcFilterMobile from '/~/extensions/giftcards/components/gc-filter.mobile.vue'
import { useEditablePage } from '/~/composables/cms'

export default {
  name: 'giftcards-catalog',
  components: {
    GcCatalog,
    GcFilterMobile,
    GcFilterDesktop,
  },
  setup() {
    const { page } = useEditablePage()

    return {
      page,
      ui,
    }
  },
}
</script>

<template>
  <div class="mx-auto h-full w-full max-w-7xl">
    <div v-if="!ui.mobile" class="px-5">
      <gc-filter-desktop class="py-2.5" />
    </div>

    <gc-filter-mobile v-if="ui.mobile" />

    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />

    <div class="p-2.5 sm:px-5">
      <gc-catalog class="h-full overflow-y-auto" />
    </div>
  </div>
</template>
