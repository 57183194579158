<script>
import { formatDate } from '/~/utils/format/date'

export default {
  name: 'summary-pay-scheduled',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
}
</script>

<template>
  <div
    v-if="order.status === 'scheduled' && order.scheduledAt"
    class="mt-2.5 text-sm"
  >
    Scheduled to pay on
    {{ formatDate('daymonthyearlong', order.scheduledAt) }}
  </div>
</template>
