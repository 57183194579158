<script setup>
import { useEstore } from '/~/extensions/estore/composables'
import { useEstoreFilters } from '/~/extensions/estore/composables/use-estore-filters'
import { useRoute } from 'vue-router/composables'
import EstoreSearch from './estore-search.vue'
import EwCatalogRange from '/~/components/ewallet/controls/ew-catalog-range.vue'
import EwCatalogSelect from '/~/components/ewallet/controls/ew-catalog-select.vue'
import EwCategories from '/~/components/ewallet/controls/ew-categories.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import router from '/~/router'

const { items, brands, categories } = useEstore()

const route = useRoute()
const {
  applyFilters,
  ceilMaxRange,
  currentBrands,
  currentItems,
  currentMax,
  currentMin,
  floorMinRange,
  isFiltersSelected,
  priceRangeInterval,
  resetFilters,
  selectedCategory,
  setSelected,
  updateIntitalFilters,
  updatePriceRange,
  formatter,
  mergeFormatter,
  currency,
} = useEstoreFilters(route)

const onCategorySelect = (category) => {
  if (route.params.category !== category) {
    router.replace({
      name: 'estore',
      query: route.query,
      params: {
        category: category,
      },
    })
  }
}
</script>

<template>
  <div class="flex items-center justify-end space-x-6">
    <estore-search />

    <ew-categories
      v-if="categories.data && categories.data.length"
      :categories="categories.data"
      :selected-id="selectedCategory"
      :render-link="false"
      title="Categories"
      @change="onCategorySelect"
    />
    <ew-filters
      title="Filters"
      :is-filters-selected="isFiltersSelected"
      @apply="applyFilters"
      @clear="resetFilters"
      @close="setSelected"
      @opened="updateIntitalFilters"
    >
      <div class="flex h-56 flex-1 divide-x overflow-hidden">
        <div class="flex w-80 shrink-0 flex-col overflow-y-auto">
          <ew-catalog-select
            v-model="currentItems"
            title="Items"
            :filters="items.data"
            :loading="items.loading"
          />
        </div>
        <div class="flex w-80 shrink-0 flex-col overflow-y-auto">
          <ew-catalog-select
            v-model="currentBrands"
            title="Brands"
            :filters="brands.data"
            :loading="brands.loading"
          />
        </div>
      </div>

      <ew-catalog-range
        class="shrink-0"
        title="Price Range"
        :min="floorMinRange"
        :max="ceilMaxRange"
        :value="[currentMin, currentMax]"
        :interval="priceRangeInterval"
        :formatter="formatter"
        :merge-formatter="mergeFormatter"
        :currency="currency"
        @update="updatePriceRange"
      />
    </ew-filters>
  </div>
</template>
