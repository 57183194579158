<script>
import { computed } from 'vue'
import { useGiftcards } from '/~/extensions/giftcards/composables'
import { useCms } from '/~/composables/cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import getBenefitsRecords from './content/hiw-benefits'
import getStepsRecords from './content/hiw-steps'

export default {
  name: 'how-it-works-content',
  setup() {
    const { howItWorks, benefits, ewalletLabels } = useCms()
    const { isMethodAvailable } = usePaymentMethods()
    const { isPhysicalEnabled } = useGiftcards()

    const steps = computed(() => {
      return howItWorks.value.items?.length
        ? howItWorks.value.items
        : getStepsRecords({
            isPhysicalEnabled: isPhysicalEnabled.value,
            ewalletLabels: ewalletLabels.value,
          })
    })

    const isEwalletEnabled = computed(() =>
      isMethodAvailable(PaymentMethodType.eWallet)
    )
    const benefitsItems = computed(() => {
      return benefits.value.items?.length
        ? benefits.value.items
        : getBenefitsRecords({
            ewalletEnabled: isEwalletEnabled.value,
            ewalletLabels: ewalletLabels.value,
          })
    })

    return {
      benefits,
      ewalletLabels,
      benefitsItems,
      howItWorks,
      steps,
    }
  },
}
</script>

<template>
  <div>
    <div class="px-6 pb-10 sm:p-12">
      <h3 class="mb-[30px] text-center sm:mb-5">
        {{ howItWorks.title ?? 'How it Works' }}
      </h3>
      <div class="items-start justify-between sm:flex">
        <div
          v-for="(item, index) in steps"
          :key="index"
          class="mb-[30px] flex flex-1 items-start sm:flex-col sm:text-center"
        >
          <div
            class="mr-5 shrink-0 sm:mx-auto sm:mb-5 sm:flex sm:items-end sm:justify-center"
          >
            <img
              class="h-[120px]"
              :src="item.image.desktop || item.image"
              :alt="item.title"
            />
          </div>
          <div>
            <b class="text-lg sm:text-xl">{{ item.title }}</b>
            <div class="mt-[15px]" v-html="item.description" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="benefitsItems.length" class="bg-dark px-6 py-10 sm:p-12">
      <h3 class="mb-[30px] text-center sm:mb-5">
        {{ benefits.title ?? `Benefits of ${ewalletLabels.ewallet}` }}
      </h3>
      <div class="items-start justify-between sm:flex">
        <div
          v-for="(item, index) in benefitsItems"
          :key="index"
          class="mb-[30px] flex flex-1 items-start sm:flex-col sm:text-center"
        >
          <div
            class="mr-5 shrink-0 sm:mx-auto sm:mb-5 sm:flex sm:items-end sm:justify-center"
          >
            <img
              class="h-[120px]"
              :src="item.image.desktop || item.image"
              :alt="item.title"
            />
          </div>
          <div>
            <b class="text-lg sm:text-xl">
              {{ item.title }}
            </b>
            <div class="mt-[15px]" v-html="item.description" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
