<script>
import { computed } from 'vue'
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useProvider } from '/~/composables/provider'
import PayForm from '../../views/payments/make/components/pay-form.vue'

export default {
  name: 'bill-payments-pay-modal',
  components: {
    PayForm,
    BaseMdl,
  },
  props: {
    payee: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isBillPaymentsTemplate } = useProvider()

    const modalTitle = computed(() => {
      if (isBillPaymentsTemplate.value) {
        return 'Enter payment information'
      }

      return 'Enter payment information for payee'
    })

    return {
      modalTitle,
    }
  },
  methods: {
    handleConfirm(payload) {
      this.$emit('confirm', payload)
      modal.hide()
    },
    onHide() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl :title="modalTitle" width="sme" title-center>
    <pay-form
      v-bind="$attrs"
      :payee="payee"
      @confirmed="handleConfirm"
      @hide="onHide"
    />
  </base-mdl>
</template>
