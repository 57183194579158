<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import Hi5Available from '/~rec/views/recognition/hi5/hi5s-available.vue'

export default {
  name: 'hi5-send-mobile',
  components: {
    BaseMdl,
    Hi5Available,
  },
  props: {
    nominee: {
      type: Object,
      default: null,
    },
    hi5Id: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <base-mdl transition="slide-right" fullscreen width="screen" :layout="false">
    <hi5-available :nominee="nominee" :hi5-id="hi5Id" />
  </base-mdl>
</template>
