<script>
export default {
  name: 'base-header-mobile-plain',
  data() {
    return {
      isFixed: false,
    }
  },
  methods: {
    onVisibilityChanged(visible) {
      this.isFixed = !visible
    },
  },
}
</script>

<template>
  <div>
    <div
      v-observe-visibility="{
        callback: onVisibilityChanged,
        intersection: {
          threshold: 0.9,
        },
      }"
    >
      <slot />
    </div>

    <transition name="fade">
      <div
        v-if="isFixed"
        class="fixed top-0 left-0 z-30 flex w-full items-center bg-white shadow"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>
