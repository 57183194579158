<script>
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-perk-info',
  components: {
    BaseIcon,
  },
  props: {
    perkStatus: {
      type: String,
      required: true,
    },
    stockEnabled: {
      type: Boolean,
      required: true,
    },
    endsAt: {
      type: Object,
      default: () => ({}),
    },
    totalStock: {
      type: Number,
      default: 0,
    },
    owner: {
      type: Object,
      default: null,
    },
    createdAt: {
      type: String,
      required: true,
    },
    approvedAt: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    approvalText() {
      const { perkStatus, owner } = this
      const ownerName = `${owner?.first_name} ${owner?.last_name}`

      switch (perkStatus) {
        case 'pending':
          return owner && `Approval by ${ownerName}`
        case 'received':
          return owner && `Approved by ${ownerName}`
        default:
          return null
      }
    },
    isShowDateBlock() {
      return (
        (this.owner && this.perkStatus === 'pending') ||
        (this.approvedAt && this.perkStatus === 'received')
      )
    },
    dateBlockText() {
      if (this.isShowDateBlock) {
        return this.approvedAt
          ? `Redeemed: ${this.approvedAt}`
          : `Requested: ${this.createdAt}`
      }
      return ''
    },
  },
}
</script>

<template>
  <ul
    class="text-sm"
    :class="{
      'mb-[15px]': !ui.desktop,
    }"
  >
    <li
      v-if="stockEnabled"
      class="flex items-center truncate font-bold text-eonx-neutral-600"
    >
      <base-icon svg="rec/gift" size="xxs" class="mr-2.5 shrink-0" />

      <div class="flex-1 truncate">{{ totalStock }} Remaining</div>
    </li>
    <li
      v-if="isShowDateBlock"
      class="flex items-center truncate font-bold text-eonx-neutral-600"
    >
      <base-icon svg="rec/time" size="xxs" class="mr-2.5 shrink-0" />

      <div class="flex-1 truncate">
        {{ dateBlockText }}
      </div>
    </li>
    <li
      v-if="approvalText"
      class="flex items-center truncate font-bold text-eonx-neutral-600 md:pb-5"
    >
      <base-icon svg="rec/user" size="xxs" class="mr-2.5 shrink-0" />

      <div class="flex-1 truncate">
        {{ approvalText }}
      </div>
    </li>
  </ul>
</template>
