<script>
import ui from '/~/core/ui'

export default {
  name: 'bill-payments-layout-view-mobile',
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <div
    class="relative flex h-full w-full flex-col overflow-y-auto overflow-x-hidden bg-white"
  >
    <slot />
  </div>
</template>
