<script>
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'

export default {
  name: 'rec-placeholder-awards-perks-hi-details',
  components: {
    BasePlaceholder,
  },
  props: {
    entity: {
      type: String,
      required: true,
      validator: (v) => /award|perk|hi5/.test(v),
    },
    action: {
      type: Boolean,
      default: false,
    },
    rank: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Boolean,
      default: false,
    },
    underImg: {
      type: Boolean,
      default: false,
    },
    points: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAward() {
      return this.entity === 'award'
    },
    getClass() {
      if (!this.isAward) {
        return 'shrink-0 mx-auto mb-[15px] w-64 h-64 md:h-80 md:w-full lg:w-40 lg:h-40 xl:w-64 xl:h-64 lg:mx-0 lg:mb-0'
      }
      return 'mx-auto min-w-full mb-[35px] shrink-0 w-64 h-64 md:w-40 md:h-40 xl:w-64 xl:h-64 lg:mx-0 lg:mb-0'
    },
  },
}
</script>

<template>
  <div class="mx-auto w-full max-w-5xl">
    <div class="mb-6 flex justify-start">
      <base-placeholder :width="70" :height="22" />
    </div>
    <div class="mb-[5px] flex grow flex-col">
      <div class="flex items-center">
        <div class="mb-5 flex flex-col items-start">
          <div class="mt-2.5 mb-[15px] mr-[15px] h-11 w-64 lg:w-[458px]">
            <base-placeholder type="fill" />
          </div>

          <div class="flex justify-start">
            <div class="flex">
              <div class="mr-[15px]">
                <base-placeholder :width="98" :height="31" rounded-full />
              </div>
              <div v-if="rank">
                <base-placeholder :width="80" :height="31" rounded-full />
              </div>
            </div>
          </div>
        </div>

        <div v-if="action" class="ml-auto -mt-2.5 self-center">
          <base-placeholder :width="100" :height="40" />
        </div>
      </div>
    </div>

    <div class="flex h-full flex-col">
      <div v-if="tabs" class="mb-5 flex px-2.5">
        <base-placeholder :width="100" :height="30" :columns="2" />
      </div>

      <div class="mt-2.5 flex flex-col lg:flex-row">
        <div class="flex flex-col">
          <div :class="getClass">
            <base-placeholder type="fill" />
          </div>
          <div
            v-if="underImg && points"
            class="mb-6 flex flex-col"
            :class="{
              'mt-[15px]': entity !== 'hi5',
            }"
          >
            <div
              class="h-11"
              :class="{
                'w-full': isAward,
                'w-64 md:w-full lg:w-40 xl:w-64': !isAward,
              }"
            >
              <base-placeholder type="fill" />
            </div>
            <div
              class="mr-auto"
              :class="{
                'w-full': isAward,
                'w-64 lg:w-40 xl:w-64': !isAward,
              }"
            >
              <base-placeholder :width="256" :height="16" class="mt-1.5" />
            </div>
          </div>
        </div>

        <div class="flex-1 lg:ml-6">
          <div v-if="!underImg && points" class="mb-6 h-10 w-[344px]">
            <base-placeholder type="fill" />
          </div>
          <base-placeholder :columns="1" type="text" :rows="4" />

          <div v-if="entity === 'hi5'" class="mt-[15px] flex space-x-[5px]">
            <base-placeholder :width="48" :height="48" rounded-full />
            <base-placeholder :width="200" :height="48" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
