<script>
import VueSlider from 'vue-slider-component'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
// TODO: https://github.com/NightCatSama/vue-slider-component/issues/642
import './base-range.scss'

export default {
  name: 'base-range',
  components: {
    BaseIcon,
    VueSlider,
  },
  props: {
    min: {
      type: [String, Number],
      default: 1,
    },
    max: {
      type: [String, Number],
      default: 100,
    },
    interval: {
      type: [String, Number],
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    speed: {
      type: Number,
      default: 0.05,
    },
    value: {
      type: [Number, String, Array],
      default: 1,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    formatter: {
      type: [String, Function],
      default: '${value}',
    },
    mergeFormatter: {
      type: [String, Function],
      default: '${value1} ~ ${value2}',
    },
    tooltip: {
      type: String,
      default: 'always',
    },
    dotSize: {
      type: Number,
      default: 14,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    look: {
      type: String,
      default: null,
      validator: (v) => !v || /points/.test(v),
    },
    height: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    isPointsLook() {
      return this.look === 'points'
    },
    heightComp() {
      if (this.isPointsLook && !this.height) {
        return 32
      }

      return this.height || 4
    },
  },
}
</script>

<template>
  <div class="base-range">
    <vue-slider
      ref="rangeSlider"
      :height="heightComp"
      :min="min"
      :max="max"
      :enable-cross="false"
      :lazy="lazy"
      :duration="speed"
      :interval="interval"
      :disabled="disabled || max === min"
      :tooltip-formatter="formatter"
      :value="value"
      :tooltip-placement="placement"
      :tooltip="tooltip"
      :dot-size="dotSize"
      :contained="true"
      class="ranged"
      :class="{
        'multi-ranged': Array.isArray(value),
        'points-range': isPointsLook,
      }"
      @change="$emit('input', $event)"
    >
      <template v-if="isPointsLook" #dot>
        <slot name="dot">
          <base-icon
            svg="v2/custom/points-token"
            :size="32"
            class="rounded-full border-2 border-primary bg-white text-primary"
          />
        </slot>
      </template>
    </vue-slider>
  </div>
</template>
