<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BatchPayeeTable from '/~/extensions/bank-file-upload/components/transactions/payee-table/payee-table.vue'
import { cardCode } from '/~/utils/cards'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { ensureNumber } from '/~/utils/format/numeric'
import { formatPoints } from '/~/utils/points'
import PaymentStatusTracker from '/~/components/activity/details/payment-status-tracker/payment-status-tracker.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import {
  useActivity,
  useActivityDetails,
  StatusTracker,
} from '/~/composables/activity'
import { useAddresses } from '/~/composables/addresses'
import {
  useBatchOrder,
  useBatchOrderPayeesTable,
} from '/~/composables/batch-order'
import { useLocalization } from '/~/composables/localization'
import {
  sortPaymentMethodsByType,
  PaymentMethodType,
  usePayId,
} from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import ActivitySummaryItems from '/~/templates/bill-payments/components/activity/summary/activity-summary-items.vue'
import ActivityDetailsAddresses from '/~/templates/bill-payments/views/activity/details/components/activity-details-addresses.vue'
import ActivityDetailsAirlinePoints from '/~/templates/bill-payments/views/activity/details/components/activity-details-airline-points.vue'
import TaxInvoice from '/~/views/activity/details/components/tax-invoice.vue'
import ActivityDetailsItemPayee from './activity-details-item-payee.vue'
import ActivityDetailsItemRefund from './activity-details-item-refund.vue'
import ActivityDetailsPayidFailed from './activity-details-payid-failed.vue'
import ActivityDetailsPayid from './activity-details-payid.vue'

export default {
  name: 'activity-details-item',
  components: {
    TaxInvoice,
    BaseButton,
    BaseIcon,
    ActivityDetailsAirlinePoints,
    ActivityDetailsAddresses,
    ActivitySummaryItems,
    PointsPanel: () => import('/~/components/points/points-panel.v2.vue'),
    CashbackPanel: () => import('/~/components/cashback/cashback-panel.vue'),
    ActivityDetailsItemPayee,
    BatchPayeeTable,
    PaymentStatusTracker,
    ActivityDetailsPayid,
    ActivityDetailsItemRefund,
    ActivityDetailsPayidFailed,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  setup(props) {
    const router = useRouter()
    const { isSymbioneliteProvider, taxationLabel, isUserAddressEnabled } =
      useProvider()
    const { routeState, getActivityItem, getFlowOrderItem } = useActivity()
    const { title: pageTitle } = useActivityDetails()
    const { batchOrder, fetchBatchOrder } = useBatchOrder()
    const { batchOrderPayeesTable, initBatchOrderPayeesTable } =
      useBatchOrderPayeesTable()
    const {
      isPurchaseOrderPoints,
      isPaymentOrderPoints,
      pointsLabel,
      calculatePointsEarnedForPayment,
      calculatePointsEarnedForPurchase,
    } = usePoints()
    const { userAddress } = useAddresses()
    const { translate } = useLocalization()
    const { cancelDescription } = usePayId()
    const { user } = useUser()

    const loading = ref(true)
    const item = ref({})
    const order = ref({})

    const date = computed(
      () =>
        item.value.happenedAt || order.value?.paidAt || order.value?.completedAt
    )

    const batchOrderFileName = computed(() => item.value.description?.[1])

    const showPrintButton = computed(() => {
      return (
        item.value.isTypePurchaseOrder ||
        item.value.isTypePaymentOrder ||
        item.value.isTypeStatementOrder ||
        item.value.isTypeBatchOrder
      )
    })

    const showPayTo = computed(
      () =>
        item.value.isTypePurchaseOrder ||
        item.value.isTypePaymentOrder ||
        item.value.isTypeStatementOrder ||
        item.value.isTypeBatchOrder
    )

    const title = computed(() => {
      if (item.value.isTypePaymentOrder || item.value.isTypeStatementOrder) {
        return 'Payment details'
      } else if (item.value.isTypeBatchOrder) {
        return 'Batch Payment'
      } else if (item.value.isTypeProgramOrder) {
        return 'Points transfer'
      }

      return 'Order details'
    })

    pageTitle.value = computed(() => title.value)

    const printTaxLabel = computed(() => {
      if (
        item.value.isTypePaymentOrder ||
        item.value.isTypeStatementOrder ||
        item.value.isTypeBatchOrder
      ) {
        return 'Print fees tax invoice'
      }

      return 'Print tax invoice'
    })

    const payToLabel = computed(() =>
      item.value.isStatusGroupPending ? 'Paying to' : 'Paid to'
    )
    const payWithLabel = computed(() =>
      item.value.isStatusGroupPending ? 'Paying with' : 'Paid with'
    )

    const config = computed(() => {
      const config = {
        baseURL: eonx.hosts.api.v1_1,
      }

      return config
    })

    const payee = computed(() => ({
      type: item.value.type === 'batchorder' ? 'bankAccount' : undefined,
      ...order.value?.payee,
      ...item.value.payee,
      reference: item.value.reference,
      subtotal: item.value.subtotal,
    }))
    const payeeName = computed(
      () => payee.value.name || payee.value.accountName
    )

    const name = computed(() => {
      if (item.value.isTypePurchaseOrder) {
        return null
      } else if (item.value.isTypePointsTransaction) {
        return item.value.isStatusGroupPending
          ? 'Points to be earned'
          : 'Points Earned'
      } else if (item.value.isTypeTopUpOrder) {
        return item.value.description?.[0]
      }

      return payeeName.value
    })

    const orderItems = computed(() => order.value?.items ?? [])

    const paymentMethods = computed(() => {
      if (item.value.isTypePointsTransaction) {
        return []
      }

      // get existing payment methods
      const paymentMethods =
        [
          order.value?.scheduledPaymentMethods,
          order.value?.paymentMethods,
          order.value?.paymentSources,
          item.value?.paymentMethods,
        ].find((methods) => methods?.length) || []

      return sortPaymentMethodsByType(
        paymentMethods
          .map((method) => {
            let result

            if (method.type) {
              result = {
                ...method,
              }
            } else {
              result = {
                type: method,
                amount: paymentMethods.length === 1 ? item.value.total : 0,
                brand: 'plain/creditcards',
              }
            }
            const issuingNetwork = getCardTypeByNumber(method.number)
            const brand = cardCode({ brand: issuingNetwork?.type })

            if (brand) {
              result.brand = `billpay/payment-methods/${brand}`
            }
            switch (result.type) {
              case PaymentMethodType.payId:
                result.title = 'PayID'
                result.details = result.name
                break
              case PaymentMethodType.points:
                result.title = pointsLabel.value
                result.details = `${formatPoints(
                  result.points
                )} points redeemed`
                break
              case PaymentMethodType.creditCard:
                result.title = result.name
                result.details = result.number
                break
              case PaymentMethodType.bankAccount:
                result.title = result.name
                result.bsb = method.bsb
                break
              case PaymentMethodType.couponCode:
                result.title = 'Coupon'
                result.details = method.number
                break
            }
            return result
          })
          .filter((i) => i.amount > 0)
      )
    })

    const isPaymentMethodPayId = computed(() =>
      paymentMethods.value.some((i) => i.type === PaymentMethodType.payId)
    )

    const cashbackRebateTotal = computed(() => {
      if (
        item.value.isTypeTopUpOrder &&
        order.value?.fundingSource === 'cashback'
      ) {
        return order.value?.total
      }

      return ensureNumber(order.value?.rebateTotal)
    })

    const isPointsEarnShow = computed(() => {
      if (item.value.isTypePointsTransaction) {
        return true
      }

      if (item.value.isTypePurchaseOrder) {
        return isPurchaseOrderPoints.value
      }

      if (
        item.value.isTypePaymentOrder ||
        item.value.isTypeStatementOrder ||
        item.value.isTypeBatchOrder
      ) {
        return isPaymentOrderPoints.value
      }

      return false
    })

    const pointsEarned = computed(() => {
      if (item.value.isTypePointsTransaction) {
        return Math.round(item.value.total)
      }

      const pointsTransactions = order.value?.pointsTransactions || []
      const earnTransaction = pointsTransactions.filter(
        (t) => t.type === 'earn'
      )

      if (earnTransaction.length > 0) {
        return earnTransaction[0].allocatedValue
      }

      if (item.value.isTypeStatementOrder) {
        return calculatePointsEarnedForPayment(item.value.subtotal)
      }

      if (item.value.isStatusGroupPending) {
        return item.value.isTypePurchaseOrder
          ? calculatePointsEarnedForPurchase(order.value?.items)
          : calculatePointsEarnedForPayment(
              order.value?.subtotal || item.value.total
            )
      }

      return null
    })

    const printContent = computed(() => {
      let address = {
        ...userAddress.value,
        companyName:
          order.value?.userStatementAccount?.name || userAddress.companyName,
        accountNumber: order.value?.userStatementAccount?.number,
        mobile: null,
        email: null,
      }

      if (order.value?.billingAddress) {
        address = { ...order.value.billingAddress }
      } else if (order.value?.shippingAddress) {
        address = { ...order.value?.shippingAddress }
      }
      return {
        ...item.value,
        ...order.value,
        payees: order.value?.payees || [payee.value],
        address,
        date: date.value,
      }
    })

    const bankAccountNotices = ref(
      isSymbioneliteProvider.value
        ? [
            'Payments from bank accounts may take up to 3 business days to complete.',
            'Please ensure you have available funds to avoid any bank dishonour fees.',
            'Your points will be allocated once this payment has successfully completed.',
          ]
        : [
            'Payments from bank accounts may take up to 3 business days to clear.',
            'Please ensure you have available funds to avoid any bank dishonour fees.',
            'Any points earned will be allocated once this payment has cleared.',
          ]
    )

    const showBankAccountNotices = computed(() => {
      return Boolean(
        paymentMethods.value.find(
          (i) => i.type === PaymentMethodType.bankAccount
        )
      )
    })

    function goBack() {
      router.replace({ name: 'activity', ...routeState.value })
    }

    function print() {
      window.print()
    }

    watch(
      () => props.id,
      () => {
        getActivityDetails()
      }
    )

    async function getActivityDetails() {
      loading.value = true

      try {
        item.value = await getActivityItem(props.id, config.value)
        order.value = await getFlowOrderItem(
          item.value.flowType,
          item.value.orderNumber
        )

        loading.value = false
      } catch (error) {
        console.error(error)
        goBack()
      }
    }

    onBeforeMount(() => {
      getActivityDetails()
    })

    const fees = computed(() => order.value?.fees?.items ?? [])
    const totalFees = computed(() => Number(order.value?.fees?.total ?? 0))
    const shippingFees = computed(() => {
      return fees.value.find((i) => i.label === 'Shipping Fee') ?? {}
    })
    const programFees = computed(() => {
      return fees.value.find((i) => i.label === 'Program Fee') ?? {}
    })
    const programFeesAmount = computed(() => {
      return Number(programFees.value.amount ?? 0)
    })
    const programFeesPercentage = computed(() => {
      return Number(programFees.value.percentageFee ?? 0)
    })
    const programFeesLabel = computed(() => {
      return `Program Fee (${programFeesPercentage.value}% inc. ${taxationLabel.value})`
    })
    const processingFees = computed(() => {
      return fees.value.find((i) => i.label === 'Processing Fee') ?? {}
    })
    const processingFeesAmount = computed(() => {
      let amount = Number(processingFees.value.amount ?? 0)

      if (user.value.isCombineFees) {
        amount += programFeesAmount.value
      }

      return amount
    })
    const processingFeesPercentage = computed(() => {
      return Number(processingFees.value.percentageFee ?? 0)
    })
    const processingFeesLabel = computed(() => {
      let percentage = processingFeesPercentage.value

      if (user.value.isCombineFees) {
        percentage += programFeesPercentage.value
      }

      const label =
        percentage === 0
          ? `inc. ${taxationLabel.value}`
          : `${percentage}% inc. ${taxationLabel.value}`

      return `Processing Fee (${label})`
    })
    const totalNotices = computed(() => {
      const notices = []

      const payWithBankAccount = paymentMethods.value.some(
        (pm) => pm.type === PaymentMethodType.bankAccount
      )

      if (
        item.value.isTypeStatementOrder &&
        totalFees.value > 0 &&
        payWithBankAccount
      ) {
        notices.push('*Fees will be charged as a separate transaction.')
      }
      if (processingFeesAmount.value) {
        notices.push('*Processing Fee is charged on Subtotal plus Program Fee')
      }
      return notices
    })

    const statusTracker = computed(
      () =>
        order.value?.statusTracker?.map((item) => new StatusTracker(item)) || []
    )

    return {
      loading,
      showPrintButton,
      showPayTo,
      item,
      order,
      title,
      printTaxLabel,
      payToLabel,
      payWithLabel,

      formatDate,
      date,
      batchOrderFileName,
      payee,
      payeeName,
      name,
      orderItems,
      paymentMethods,
      isPointsEarnShow,
      cashbackRebateTotal,
      pointsEarned,
      printContent,
      bankAccountNotices,
      showBankAccountNotices,
      shippingFees,
      programFeesAmount,
      programFeesLabel,
      processingFeesAmount,
      processingFeesLabel,
      totalFees,
      totalNotices,

      isPaymentMethodPayId,

      print,
      goBack,
      formatDollar,
      formatPoints,
      ui,
      PaymentMethodType,
      pointsLabel,
      translate,
      batchOrderPayeesTable,
      statusTracker,
      cancelDescription,
      user,
      isUserAddressEnabled,
    }
  },
}
</script>

<template>
  <div class="flex min-h-screen w-full">
    <div
      class="flex min-h-screen w-full flex-col sm:mr-6 sm:rounded-t-3xl sm:bg-eonx-neutral-50 sm:pt-[30px]"
    >
      <div
        class="relative mx-auto flex h-full w-full flex-col sm:max-w-lg sm:rounded-t-3xl sm:bg-white sm:pt-[35px]"
      >
        <!-- Back & Print Controls -->
        <div
          class="mt-5 hidden items-center justify-between px-5 sm:mt-0 sm:flex sm:px-6"
        >
          <base-button look="link" @click="goBack">
            <base-icon svg="chevron-left" size="md" class="mr-[5px]" />
            <span class="text-sm">Back</span>
          </base-button>

          <base-button
            v-if="showPrintButton"
            :disabled="loading"
            look="link"
            class="text-sm"
            @click="print"
          >
            <base-icon svg="plain/printer" size="md" class="mr-[5px]" />
            <span class="text-sm">{{ printTaxLabel }}</span>
          </base-button>
        </div>

        <!-- Activity Refund -->
        <activity-details-item-refund
          v-if="item.isTypeRefund"
          :activity="item"
          :loading="loading"
        />
        <!-- Other Activities -->
        <template v-else>
          <div class="flex w-full justify-center sm:hidden">
            <div
              class="mb-1 flex items-center justify-center rounded-full"
              :class="[item.statusLabel?.color]"
            >
              <span
                v-if="loading"
                class="h-[50px] animate-pulse rounded-full bg-eonx-neutral-50 text-transparent"
              >
                loading
              </span>
              <base-icon
                v-else
                :svg="
                  item.isStatusFailed
                    ? 'heroicons/solid/x-circle'
                    : item.isStatusGroupPending
                    ? 'heroicons/solid/clock'
                    : 'heroicons/solid/check-circle'
                "
                :size="64"
              />
            </div>
          </div>
          <div class="px-5 sm:mt-8 sm:px-6">
            <h1
              class="mb-4 hidden text-center text-2xl font-bold text-eonx-neutral-800 sm:block"
            >
              <span
                v-if="loading"
                class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
              >
                loading
              </span>
              <template v-else>{{ title }}</template>
            </h1>

            <div class="mb-5 flex flex-col gap-y-1">
              <div class="text-center text-sm font-bold text-eonx-neutral-800">
                <div
                  v-if="loading"
                  class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
                >
                  loading
                </div>
                <template v-else>
                  {{ formatDate('daymonthyearlongtime', date) }}
                </template>
              </div>

              <div
                class="text-center text-sm font-bold text-eonx-neutral-800"
                :class="[item.isTypeBatchOrder && 'order-first']"
              >
                <span
                  v-if="loading"
                  class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
                >
                  loading
                </span>
                <template v-else>
                  Transaction No. #{{ item.orderNumber }}
                </template>
              </div>

              <div class="text-center text-sm font-bold text-eonx-neutral-800">
                <div
                  v-if="loading"
                  class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
                >
                  loading
                </div>
                <template v-else>
                  Status:
                  <span class="capitalize" :class="[item.statusLabel.color]">
                    {{ item.statusLabel.text }}
                  </span>
                </template>
              </div>

              <div
                v-if="item.performedBy"
                class="text-center text-sm font-bold text-eonx-neutral-800"
              >
                Performed by: {{ item.performedBy.fullName }}
              </div>

              <div
                v-if="
                  isPaymentMethodPayId &&
                  cancelDescription(order.status, order.subStatus)
                "
                class="mt-3 text-center"
                v-html="cancelDescription(order.status, order.subStatus)"
              ></div>

              <payment-status-tracker
                v-if="!loading && statusTracker.length"
                class="mt-5 mb-3"
                :items="statusTracker"
              />
            </div>
            <div>
              <activity-details-payid-failed
                v-if="!loading && isPaymentMethodPayId"
                :activity="item"
                class="mb-5"
              />

              <activity-details-airline-points
                v-if="item.isTypeProgramOrder"
                :item="item"
              />

              <points-panel
                v-if="isPointsEarnShow && pointsEarned"
                :total="pointsEarned"
                :completed="!item.isStatusGroupPending"
                class="mb-5 rounded-lg sm:mb-6"
              />

              <activity-details-addresses
                v-if="
                  isUserAddressEnabled &&
                  ((order && order.billingAddress) ||
                    (order && order.shippingAddress))
                "
                :order="order"
                class="mb-5 border-b border-neutral-200 pb-5"
              />
            </div>
            <!-- pay to -->
            <div
              v-if="
                !loading && showPayTo && payeeName && !item.isTypeBatchOrder
              "
              class="mb-5 border-b border-neutral-200 pb-5"
            >
              <div class="mb-5 font-bold text-eonx-neutral-800">
                {{ payToLabel }}
              </div>
              <activity-details-item-payee
                :payee="payee"
                :order="order"
                :is-statement-order="item.isTypeStatementOrder"
              />
            </div>
            <div
              v-else-if="item.isTypeBatchOrder"
              class="mb-6 border-b border-neutral-200 pb-5"
            >
              <div
                v-if="loading"
                class="w-44 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
              >
                loading
              </div>
              <div v-else class="mb-2.5 font-bold text-eonx-neutral-800">
                {{ payToLabel }}
              </div>

              <div
                v-if="loading"
                class="mt-3 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
              >
                loading
              </div>
              <activity-details-item-payee
                v-else
                :is-batch-order="item.isTypeBatchOrder"
                :payee="payee"
                :order="order"
              />
            </div>

            <h2
              v-else-if="name"
              class="mb-5 border-b border-neutral-200 pb-5 text-center"
            >
              <div
                v-if="loading"
                class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
              >
                loading
              </div>
              <template v-else>
                {{ name }}
              </template>
            </h2>

            <div
              v-if="loading || orderItems.length > 0"
              class="mb-5 border-b border-neutral-200 pb-5"
            >
              <div v-if="loading" class="flex">
                <div
                  class="h-20 w-20 shrink-0 animate-pulse rounded bg-eonx-neutral-50"
                />
                <div class="flex-1 pl-2.5">
                  <div class="mb-2.5 flex w-full">
                    <div
                      class="flex-1 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
                    >
                      loading
                    </div>
                    <div
                      class="ml-12 shrink-0 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
                    >
                      loading
                    </div>
                  </div>
                  <div
                    class="mb-[5px] animate-pulse rounded bg-eonx-neutral-50 text-xs text-transparent"
                  >
                    loading
                  </div>
                  <div
                    class="animate-pulse rounded bg-eonx-neutral-50 text-xs text-transparent"
                  >
                    loading
                  </div>
                </div>
              </div>

              <div
                v-if="orderItems.length > 0"
                class="mb-2.5 font-bold text-eonx-neutral-800"
              >
                {{ payToLabel }}
              </div>

              <activity-summary-items
                v-if="orderItems.length > 0"
                :items="orderItems"
              />
            </div>

            <div v-if="paymentMethods.length > 0 && !loading" class="pb-5">
              <div class="mb-5 font-bold text-eonx-neutral-800">
                {{ payWithLabel }}
              </div>

              <activity-details-payid
                v-if="isPaymentMethodPayId && item.isStatusPending"
                :order="order"
                :loading="loading"
                :flow-type="item.flowType"
              />

              <div v-else class="space-y-5 sm:space-y-[30px]">
                <template v-for="(method, index) in paymentMethods">
                  <div :key="index" class="flex items-center justify-center">
                    <div class="h-10 w-10 flex-shrink-0 overflow-hidden">
                      <div v-if="method.type === PaymentMethodType.payId">
                        <base-icon
                          svg="billpay/payment-methods/payid"
                          size="2xl"
                        />
                      </div>
                      <div
                        v-if="method.type === PaymentMethodType.points"
                        class="flex h-full w-full justify-center text-primary"
                      >
                        <base-icon svg="points/token" size="2xl" />
                      </div>
                      <div
                        v-else-if="
                          method.type === PaymentMethodType.bankAccount
                        "
                        class="flex h-full w-full items-center justify-center rounded-full bg-eonx-neutral-600 text-white"
                      >
                        <base-icon svg="v2/custom/bank" size="lg" />
                      </div>
                      <div
                        v-else-if="method.type === PaymentMethodType.creditCard"
                        class="flex shrink-0 justify-center"
                      >
                        <base-icon :svg="method.brand" size="2xl" />
                      </div>
                      <div
                        v-else-if="method.type === PaymentMethodType.couponCode"
                        class="flex shrink-0 items-center justify-center text-primary"
                      >
                        <base-icon svg="coupon" size="2xl" />
                      </div>
                      <div v-else class="h-full w-full bg-eonx-neutral-50" />
                    </div>

                    <div class="ml-3 grow">
                      <div class="font-bold text-eonx-neutral-800">
                        {{ method.title }}
                      </div>

                      <div
                        class="flex items-center justify-between space-x-2.5"
                      >
                        <div class="text-sm leading-5 text-eonx-neutral-600">
                          <span
                            v-if="
                              method.type === PaymentMethodType.creditCard &&
                              method.details
                            "
                            class="text-eonx-neutral-800"
                          >
                            **** **** **** {{ method.details.slice(-4) }}
                          </span>
                          <span
                            v-if="
                              method.type === PaymentMethodType.bankAccount &&
                              method.number
                            "
                          >
                            {{
                              translate('bankAccount.details', {
                                acc: `*** *** *${method.number.slice(-2)}`,
                                bsb: method.bsb || method.prefix,
                              })
                            }}
                          </span>
                          <span
                            v-if="
                              method.type === PaymentMethodType.points &&
                              method.details
                            "
                          >
                            {{ method.details }}
                          </span>
                          <span
                            v-if="
                              method.type === PaymentMethodType.couponCode &&
                              method.details
                            "
                          >
                            {{ method.details }}
                          </span>
                          <span
                            v-if="
                              method.type === PaymentMethodType.payId &&
                              method.details
                            "
                          >
                            {{ method.details }}
                          </span>
                        </div>

                        <div
                          v-if="method.finalAmount"
                          class="text-sm text-eonx-neutral-600"
                        >
                          {{ formatDollar(method.finalAmount) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <div
              v-if="showBankAccountNotices"
              class="mb-2.5 flex items-center bg-eonx-neutral-200 py-2.5"
            >
              <div class="flex w-16 shrink-0">
                <base-icon
                  svg="v2/custom/alert"
                  :size="32"
                  class="mx-auto shrink-0 text-eonx-neutral-800"
                />
              </div>
              <ul
                class="list-disc space-y-1 pl-5 pr-4 text-sm text-eonx-neutral-600"
              >
                <li
                  v-for="(notice, indexChild) in bankAccountNotices"
                  :key="indexChild"
                >
                  {{ notice }}
                </li>
              </ul>
            </div>
          </div>

          <div v-if="!loading" class="px-5 sm:px-6">
            <div v-if="totalFees" class="text-sm text-eonx-neutral-600">
              <hr
                class="mb-5 h-px w-full border-neutral-200 sm:my-2.5 sm:block"
              />
              <div
                class="mb-5 flex justify-between text-base font-bold text-eonx-neutral-800 sm:mb-0"
              >
                <span>Fees*</span>
                <span class="sm:hidden">{{ formatDollar(totalFees) }}</span>
              </div>

              <div
                v-if="shippingFees && shippingFees.amount"
                class="mt-[5px] flex justify-between"
              >
                <div>Shipping Fee</div>
                <div class="pl-4">
                  {{ formatDollar(shippingFees.amount) }}
                </div>
              </div>
              <div
                v-if="!user.isCombineFees && programFeesAmount"
                class="mt-[5px] flex justify-between"
                data-testid="program-fees"
              >
                <div>{{ programFeesLabel }}</div>
                <div class="pl-4">
                  {{ formatDollar(programFeesAmount) }}
                </div>
              </div>
              <div
                v-if="processingFeesAmount"
                class="mt-[5px] flex justify-between"
                data-testid="processing-fees"
              >
                <div>{{ processingFeesLabel }}</div>
                <div class="pl-4">
                  {{ formatDollar(processingFeesAmount) }}
                </div>
              </div>
            </div>
            <hr
              v-if="order && order.total > 0"
              class="my-2.5 h-px w-full bg-gray-50"
            />
            <div
              v-if="order && order.total > 0"
              class="sticky top-0 z-1 grid bg-white leading-tight text-eonx-neutral-800 sm:-mx-6 sm:px-6"
              style="grid-template-columns: auto auto"
            >
              <span class="mt-2.5 text-2xl font-bold">Total</span>
              <span class="mt-2.5 text-right text-2xl font-bold">
                {{ formatDollar(order.total) }}
              </span>
            </div>
            <div
              v-if="totalNotices.length"
              class="mt-5 text-xs text-eonx-neutral-600 sm:mt-2.5 sm:text-sm"
            >
              <div v-for="(notice, index) in totalNotices" :key="index">
                {{ notice }}
              </div>
            </div>
            <div
              v-if="!loading && !item.isStatusFailed"
              class="-mx-5 mt-5 sm:-mx-10"
            >
              <cashback-panel
                :total="cashbackRebateTotal"
                :completed="!item.isStatusGroupPending"
              />
            </div>
            <div
              class="sticky top-10 z-10 mt-6 h-px border-t border-neutral-400"
            />
            <batch-payee-table
              v-if="item.isTypeBatchOrder"
              :pagination="batchOrderPayeesTable.pagination"
              :payees="batchOrderPayeesTable.transactionList.list"
              :tabs="batchOrderPayeesTable.tabs.list"
              :loading="batchOrderPayeesTable.loader.loading"
              class="py-6 text-eonx-neutral-800"
              @select-tab="batchOrderPayeesTable.setActiveTab($event)"
              @select-page="batchOrderPayeesTable.load($event)"
              @select-per-page="batchOrderPayeesTable.setPerPage($event)"
            />
          </div>
        </template>
      </div>

      <tax-invoice
        v-if="!loading"
        :is-type-purchase-order="item.isTypePurchaseOrder"
        :is-type-payment-order="item.isTypePaymentOrder"
        :is-type-cashback-reconciliation-feed="
          item.isTypeCashbackReconciliationFeed
        "
        :is-type-statement-order="item.isTypeStatementOrder"
        :is-type-batch-order="item.isTypeBatchOrder"
        :is-type-cash-transaction="item.isTypeCashTransaction"
        :content="printContent"
      />
    </div>
  </div>
</template>
