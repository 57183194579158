import set from 'lodash-es/set'
import { RouteConfig } from 'vue-router'
import { useCms } from '/~/composables/cms'
import { useExtensions } from '/~/composables/extensions'
import { useProvider } from '/~/composables/provider'
import router from '/~/router'

export class RewardsExtension {
  constructor(config) {
    this.config = config
  }

  get name() {
    return this.config?.moduleName
  }

  get label() {
    return this.config?.label
  }

  get cms() {
    const { cmsConfig } = useCms()

    return cmsConfig.value
  }

  get isMarketplace() {
    return false
  }

  get templateConfig() {
    const { currentTemplateConfig } = useProvider()

    return currentTemplateConfig.value?.extensions?.[this.name] ?? {}
  }

  get hasModuleConfig() {
    const { getConfigByName } = useExtensions()

    return Boolean(getConfigByName(this.name))
  }

  get templateName() {
    return this.templateConfig.templateName ?? 'default'
  }

  get parentRoute() {
    return this.templateConfig.parentRoute ?? null
  }

  setRoutes(): RouteConfig[] {
    return []
  }

  async setEwalletRoutes(): Promise<RouteConfig[]> {
    return []
  }

  setState() {
    return Promise.resolve()
  }

  async install() {
    if (!this.hasModuleConfig) {
      throw new Error('Module config is not found')
    }

    const { name } = this
    const [routes] = await Promise.all([this.setRoutes(), this.setState()])

    routes.forEach((route) => {
      set(route, 'meta.extName', name)

      if (route.children) {
        route.children.forEach((route) => set(route, 'meta.extName', name))
      }
    })

    routes.forEach((route) => {
      const parentRoute = route.meta?.parentRoute ?? this.parentRoute

      if (parentRoute) {
        if (router.getRoutes().some((route) => route.name === parentRoute)) {
          // router.addRoute overwritten by src/router/router-extensions.ts
          router.addRoute(route, parentRoute)
        } else {
          console.warn(`"${parentRoute}" route is not registered`)
        }
      } else {
        if (route.path && route.path[0] !== '/') {
          route.path = `/${route.path}`
        }

        router.addRoute(route)
      }
    })

    await this.setEwalletRoutes()
  }
}
