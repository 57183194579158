<script setup lang="ts">
import { computed } from 'vue'
import { useCms } from '/~/composables/cms'
import policy from '/~/configurator/defaults/policy.json'

const { cmsContent } = useCms()

const policyContent = computed(() => {
  return cmsContent.policy?.content ?? policy.content
})
</script>

<template>
  <div class="markup" v-html="policyContent" />
</template>
