<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import AwardNominateUser from '/~rec/views/recognition/awards/award-nominate-user.vue'

export default {
  name: 'award-nominate-user-root',
  components: {
    AwardNominateUser,
  },
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  created() {
    if (!ui.desktop) {
      this.openMobileForm()
    }
  },
  methods: {
    openMobileForm() {
      modal.show('rec-award-nominate-user-mobile', {
        props: {
          profile: this.profile,
        },
      })
    },
  },
}
</script>

<template>
  <award-nominate-user
    v-if="ui.desktop"
    class="p-[30px] pt-10"
    :user="profile"
  />
</template>
