<script>
import { computed, ref, watch } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { floorFigure, formatNumber } from '/~/utils/format/numeric'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRange from '/~/components/base/range/base-range.vue'
import { usePoints } from '/~/composables/points'

export default {
  name: 'payments-split-form-points',
  components: {
    BaseRange,
    BaseIcon,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    burnPointsRate: {
      type: Number,
      default: 1,
    },
    amountToPay: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { pointsBalance } = usePoints()

    const pointsToUse = ref(props.value)

    const pointsMaxToUse = computed(() => {
      const maxPoints = Math.ceil(props.total / props.burnPointsRate)

      return Math.min(maxPoints, pointsBalance.value)
    })

    const calculatedAmount = computed(() => {
      return floorFigure(pointsToUse.value * props.burnPointsRate)
    })
    const pointsRemaining = computed(
      () => pointsBalance.value - pointsToUse.value
    )

    watch(pointsToUse, (value) => {
      emit('input', value)
    })

    return {
      pointsToUse,
      pointsMaxToUse,
      pointsRemaining,
      formatDollar,
      formatNumber,
      calculatedAmount,
    }
  },
}
</script>

<template>
  <div class="mt-10 w-full rounded-lg bg-gray-50 p-5">
    <div class="justify-between sm:flex">
      <div class="flex">
        <base-icon
          class="mr-[15px] text-primary"
          svg="points/token"
          :size="45"
        />
        <div>
          <div class="-mb-[5px] font-bold">Points</div>
          <div class="-mt-[5px] text-sm text-eonx-neutral-600">
            Balance available {{ formatNumber(pointsRemaining) }}
          </div>
        </div>
      </div>
      <div class="text-right text-eonx-neutral-600">
        <div class="font-bold">
          {{ formatDollar(calculatedAmount) }}
        </div>
        <div class="text-sm">Value</div>
      </div>
    </div>
    <base-range
      v-model="pointsToUse"
      class="mt-6 w-full"
      :min="0"
      :dot-size="32"
      :max="pointsMaxToUse"
      :tooltip="'none'"
      placement="top"
      formatter="{value}"
    />
  </div>
</template>
