<script setup lang="ts">
import { computed } from 'vue'
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'
import { Image } from '/~/composables/cms/use-cms'

const props = withDefaults(
  defineProps<{
    image: Image
    imageHeight?: number
    columns?: number
  }>(),
  {
    imageHeight: 400,
  }
)

const localImage = computed(() => {
  const reg = /-\/resize\/(\d*x\d*\/)?/

  return {
    ...props.image,
    desktop: props.image.desktop?.replace(reg, '') ?? '',
    mobile: props.image.mobile?.replace(reg, '') ?? '',
  }
})

const imageSource = computed(
  () => localImage.value?.mobile || localImage.value?.desktop || ''
)

const columnWidth = computed(() => {
  const maxContainerWidth = 1200
  const width = props.columns ? ui.width / props.columns : ui.width

  return Math.min(width, maxContainerWidth)
})

const imageWidth = computed(() => Math.round(columnWidth.value))
const imageHeight = computed(() => Math.round(props.imageHeight))

function renderImage(imageSourceValue: string): string {
  return `${imageSourceValue}-/preview/${imageWidth.value}x${
    imageHeight.value
  }/-/format/auto/-/quality/best/ 1x, ${imageSourceValue}-/preview/${
    imageWidth.value * 2
  }x${imageHeight.value * 2}/-/format/auto/-/quality/smart/ 2x`
}

const imageDefault = computed(() => {
  return cdn(imageSource.value)
    .preview(`${imageWidth.value * 2}x${imageHeight.value * 2}`)
    .format('auto')
    .quality('smart')
    .url()
})

const imageDesktop = computed(() => {
  return renderImage(localImage.value?.desktop || localImage.value?.mobile)
})

const imageMobile = computed(() => {
  return renderImage(imageSource.value)
})

const imageTablet = computed(() => {
  return renderImage(localImage.value?.desktop || localImage.value?.mobile)
})

const imageAlt = computed(() => {
  return localImage.value.alt || ''
})
</script>

<template>
  <picture class="block h-full">
    <source
      v-if="imageMobile"
      :srcset="imageMobile"
      media="(max-width: 767px)"
    />
    <source
      v-if="imageTablet"
      :srcset="imageTablet"
      media="(min-width: 896px) and (max-width: 1024px)"
    />
    <source
      v-if="imageDesktop"
      :srcset="imageDesktop"
      media="(min-width: 1025px)"
    />
    <img
      v-if="image.desktop || image.mobile"
      :src="imageDefault"
      :alt="imageAlt"
      class="block h-full w-full object-cover"
    />
  </picture>
</template>
