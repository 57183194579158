<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import CheckoutAddressDesktop from './checkout-address.desktop.vue'

export default {
  name: 'checkout-mobile',
  components: {
    BaseAsidePage,
  },
  extends: CheckoutAddressDesktop,
  setup() {
    return {
      ...CheckoutAddressDesktop.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <base-aside-page
    title="Select address"
    no-padding
    shadow
    :action="onAddAddress"
  >
    <div class="space-y-4 p-5">
      <template v-if="defaultAddress">
        <h2 class="text-base">Default</h2>
        <address-item
          :item="defaultAddress"
          :show-menu="true"
          @click="onSelectAddress(defaultAddress)"
          @remove="onRemoveAddress(defaultAddress.id)"
        />
      </template>
      <h2 class="text-base">Other Addresses</h2>
      <address-item
        v-for="address in addressesWithoutDefault"
        :key="address.id"
        :item="address"
        :show-menu="true"
        @click="onSelectAddress(address)"
        @remove="onRemoveAddress(address.id)"
      />
    </div>
  </base-aside-page>
</template>
