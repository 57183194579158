<script>
import { roundFigure } from '/~/utils/format/numeric'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import LineChart from './line-chart.vue'
import { htmlLegendPlugin } from './plugins/legend-plugin'

export default {
  name: 'home-chart-panel',
  components: {
    LineChart,
    BaseLoader,
    BaseIcon,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    hasData: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      htmlLegendPlugin,
    }
  },
  computed: {
    options() {
      return {
        scales: {
          yAxes: {
            position: 'left',
            display: true,
            grid: {
              drawOnChartArea: true,
            },
            ticks: {
              callback: (label, index, ticks) => {
                if (Math.max(...ticks.map((t) => t.value)) < 1000) {
                  return label
                }
                return label ? roundFigure(label / 1000) + 'k' : 0
              },
              color: '#6B7280',
            },
            border: {
              display: false,
              dash: [2, 2],
              color: '#E5E7EB',
            },
          },
          yAxes1: {
            position: 'right',
            display: true,
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              callback: (label, index, ticks) => {
                if (Math.max(...ticks.map((t) => t.value)) < 1000) {
                  return label
                }
                return label ? roundFigure(label / 1000) + 'k' : 0
              },
            },
            border: {
              display: false,
              dash: [2, 2],
              color: '#E5E7EB',
            },
          },
          x: {
            grid: {
              drawOnChartArea: false,
            },
          },
        },
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          htmlLegend: {
            containerID: 'legend-container',
          },
          legend: {
            display: false,
            labels: {
              generateLabels(chart) {
                const data = chart.data

                if (data.datasets.length) {
                  return data.datasets.map((label, i) => {
                    return {
                      text: label.label,
                      strokeStyle: label.borderColor,
                      fontColor: label.borderColor,
                      hidden: !chart.isDatasetVisible(i),

                      // Extra data used for toggling the correct item
                      index: i,
                    }
                  })
                }
                return []
              },
            },
          },
        },
        animation: false,
      }
    },
    parsedDatasets() {
      return this.datasets.filter((dataset) => {
        return !dataset.hidden
      })
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: this.parsedDatasets,
      }
    },
  },
}
</script>

<template>
  <div class="relative overflow-hidden rounded-md bg-white p-[15px]">
    <div
      class="absolute top-0 left-0 z-10 flex h-full w-full items-center justify-center bg-white transition-all duration-200"
      :class="{
        'pointer-events-none opacity-0': !loading,
      }"
    >
      <base-loader />
    </div>

    <div
      v-if="!loading && !hasData"
      class="relative mx-5 my-2.5 flex h-[550px] items-center justify-center bg-cover bg-no-repeat"
    >
      <img
        src="/images/symbion/chart-no-data-l.svg"
        class="absolute top-0 left-0 hidden md:block"
      />
      <img
        src="/images/symbion/chart-no-data-r.svg"
        class="absolute top-0 right-0 hidden md:block"
      />
      <div class="flex flex-col items-center">
        <base-icon
          svg="billpay/browser-stats"
          class="max-w-36 text-primary"
          alt="Browser stats"
        />
        <div class="mt-2.5 text-xl font-bold text-eonx-neutral-800">
          Track your activity
        </div>
        <div class="mt-2.5 text-center text-sm leading-4 text-eonx-neutral-600">
          Multiple transactions will allow you to preview your total bill
          payments and points balance over time.
        </div>
      </div>
    </div>

    <div v-else class="p-2.5">
      <line-chart
        class="max-h-[300px]"
        :options="options"
        :chart-data="chartData"
        :plugins="[htmlLegendPlugin]"
      />
      <div id="legend-container" class="flex justify-center" />
    </div>
  </div>
</template>
