<script>
import { computed, nextTick } from 'vue'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'

export default {
  name: 'ewallet-header',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    bgColor: {
      type: String,
      default: '',
    },
    hideLogo: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'left',
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { isDarkThemeForEwallet, ewalletLabels } = useCms()

    const isAlignCenter = computed(() => props.align === 'center')

    function onCloseClicked() {
      emit('close')
      emitter.emit('ewallet:close')
    }

    return {
      isDarkThemeForEwallet,
      ewalletLabels,
      onCloseClicked,
      isAlignCenter,
    }
  },
  methods: {
    setFocus() {
      nextTick(() => {
        this.$refs.closeButton.$el.focus()
      })
    },
  },
}
</script>

<template>
  <div
    class="relative -my-6 -mx-5 min-h-6 py-6 px-5"
    :style="{
      backgroundColor: bgColor,
    }"
  >
    <div
      v-if="!hideLogo"
      class="flex w-full"
      :class="{
        'justify-center': isAlignCenter,
      }"
    >
      <img
        v-if="icon && icon.desktop"
        :src="icon.desktop"
        :alt="icon.alt || ewalletLabels.ewallet"
        class="h-10"
      />
      <base-icon
        v-else
        svg="ewallet-full"
        :size="116"
        :alt="ewalletLabels.ewallet"
        class="-my-[45px]"
      />
    </div>
    <div class="absolute top-1/2 right-0 mr-5 -translate-y-1/2">
      <base-button
        v-if="!hideCloseButton"
        ref="closeButton"
        icon="base/close"
        :title="`close ${ewalletLabels.ewallet}`"
        alt="close"
        class="align-top"
        :size="28"
        :padding="5"
        @click="onCloseClicked"
      />
    </div>
  </div>
</template>
