import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useAddresses } from '/~/composables/addresses'
import Address from '/~/composables/addresses/core/Address'
import { useCart } from '/~/composables/cart'

export function useQuickBuyAddressesComponent() {
  const router = useRouter()
  const route = useRoute()
  const { addCartOrderAddress, removeCartOrderAddress, cartOrderAddresses } =
    useCart()
  const { addresses, defaultAddress } = useAddresses()

  const type = computed(() => route.meta?.type)

  function onAddAddress() {
    router.push({ hash: '#profile-add-address' })
  }

  function isSelected(addressId: number) {
    return (
      (type.value === 'billing' &&
        cartOrderAddresses.billing?.id === addressId) ||
      (type.value === 'shipping' &&
        cartOrderAddresses.shipping?.id === addressId)
    )
  }

  function onSelectAddress(address: Address) {
    addCartOrderAddress({ address, type: type.value })
    router.push({
      name: 'quick-buy-checkout',
      query: router.currentRoute.query,
    })
  }

  function getFallbackAddress(addressId: number) {
    if (defaultAddress.value && defaultAddress.value.id !== addressId) {
      return defaultAddress.value
    }

    const filteredAddresses =
      addresses.value.filter((item) => item.id !== addressId) || []

    return filteredAddresses[0] || null
  }

  async function onRemoveAddress(addressId: number) {
    const isBilling = cartOrderAddresses.billing?.id === addressId
    const isDelivery = cartOrderAddresses.shipping?.id === addressId
    const fallbackAddress = getFallbackAddress(addressId)

    if (isBilling || isDelivery) {
      if (fallbackAddress) {
        if (isBilling) {
          await addCartOrderAddress({
            address: fallbackAddress,
            type: 'billing',
          })
        }
        if (isDelivery) {
          await addCartOrderAddress({
            address: fallbackAddress,
            type: 'shipping',
          })
        }
      } else {
        await removeCartOrderAddress(type.value)
      }
    }
  }

  const backRoute = ref({
    name: 'quick-buy-checkout',
    query: router.currentRoute.query,
  })

  return {
    type,
    onAddAddress,
    isSelected,
    onSelectAddress,
    onRemoveAddress,
    backRoute,
  }
}
