<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import MenuPointsPartner from '../../../components/rows/menu-points-partner.vue'

export default {
  name: 'profile-points-partner-all',
  components: {
    MenuPointsPartner,
    BaseIcon,
    BaseAsidePage,
  },
  data() {
    return {
      loading: false,
      availablePartners: [
        {
          name: 'Velocity Frequent Flyer',
          type: 'velocity',
          id: '1',
        },
      ],
    }
  },
  methods: {
    addPointsPartner() {
      this.$router.push({ hash: '#profile-points-partners-add' })
    },
  },
}
</script>

<template>
  <base-aside-page title="Add Airline Partner">
    <div class="mb-2.5 font-bold text-eonx-neutral-600">Airline Partners</div>

    <div class="space-y-2.5">
      <menu-points-partner
        v-for="partner in availablePartners"
        :key="partner.id"
        :partner="partner"
        @click="addPointsPartner"
      >
        <template #action>
          <base-icon svg="plain/chevronright" size="sm" />
        </template>
      </menu-points-partner>
    </div>

    <div class="my-5 text-center text-eonx-neutral-600">
      More Airline Partners coming soon
    </div>
  </base-aside-page>
</template>
