<script>
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'retailer-logo',
  components: {
    BaseImage,
  },
  props: {
    alt: {
      type: String,
      default: 'Retailer Logo',
    },
    image: {
      type: String,
      default: '',
    },
    defaultSrc: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    src() {
      return this.cdn(this.image).resize('400x').url()
    },
    srcset() {
      return `
        ${this.cdn(this.image).preview('400x400').quality('normal').url()} 1x,
        ${this.cdn(this.image).preview('800x800').quality('normal').url()} 2x
      `
    },
  },
}
</script>

<template>
  <div
    class="mx-auto h-auto max-h-80 w-full max-w-64 sm:max-w-80"
    data-test="image"
  >
    <base-image
      :src="cdn(image).resize('400x').url()"
      :alt="alt"
      :default-src="defaultSrc"
      :ratio="1"
      :fit="false"
      rounded
    />
  </div>
</template>
