<script>
import { formatDate } from '/~/utils/format/date'

export default {
  name: 'date-card',
  props: {
    date: {
      type: [String, Date, Number, Object],
      required: true,
      default: '',
    },
    plain: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<template>
  <div>
    <div
      v-if="plain"
      class="relative box-border rounded-lg border px-1.5 pt-1.5 pb-[5px] text-center text-white"
      :class="[
        active && selected && 'bg-primary',
        active && !selected && 'bg-primary-lighten',
        !active && selected && 'bg-primary',
        !active && !selected && 'bg-primary',
      ]"
      @click="onClick"
    >
      <div class="text-sm leading-4">
        {{ formatDate('weekdayshort', date).toUpperCase().slice(0, 1) }}
      </div>
      <b class="block text-xl leading-7">
        {{ formatDate('day', date) }}
      </b>
    </div>

    <div
      v-else
      class="relative box-content w-12 px-[5px] text-center text-xs leading-4"
      :class="[
        selected ? 'bg-primary' : 'bg-white',
        rounded ? 'rounded-md' : null,
      ]"
      @click="onClick"
    >
      <div
        class="border-b-4 pt-2.5 transition duration-75 hover:border-primary"
        :class="[
          active && selected && 'border-primary text-white',
          active && !selected && 'border-primary text-eonx-neutral-800',
          !active && selected && 'border-primary text-white',
          !active && !selected && 'border-white text-black',
        ]"
      >
        <div
          class="pb-[5px] text-sm leading-4"
          :class="selected ? 'text-white' : 'text-eonx-neutral-600'"
        >
          {{ formatDate('weekdayshort', date).toUpperCase().slice(0, 1) }}
        </div>
        <b class="block pb-[5px] text-xl leading-7 transition-none">
          {{ formatDate('day', date) }}
        </b>
      </div>
    </div>
  </div>
</template>
