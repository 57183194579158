<script>
import isEmpty from 'lodash-es/isEmpty'
import { ValidationProvider } from 'vee-validate'
import { nextTick } from 'vue'
import { getValue } from 'vue-currency-input'
import { IMaskDirective } from 'vue-imask'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseField from '/~/components/base/field/base-field.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useInput } from '/~/composables/base'
import templates from './mask.template'
import './base-input.scss'

/*
 * This is very complex component.
 * I think, we need to use only vue-imask component.
 */

export default {
  name: 'base-input',
  components: {
    BaseField,
    BaseIcon,
    ButtonIcon,
    BaseLoader,
    ValidationProvider,
  },
  directives: {
    imask: IMaskDirective,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: [String, Number],
      default: 'md',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: [String, Array],
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredAsterisk: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    nolabel: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      default: null,
    },
    floated: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: null,
    },
    mask: {
      type: [Object, String],
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    currency: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    autofillNotice: {
      type: Boolean,
      default: false,
    },
    nativeInputId: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    iconPlain: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [String, Number],
      default: '',
    },
    tabindex: {
      type: [String, Number],
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    entryClass: {
      type: String,
      default: 'rounded h-10',
    },
    labelClass: {
      type: String,
      default: '',
    },
    inputClass: {
      type: [String, Array],
      default: '',
    },
    look: {
      type: String,
      default: '',
      validator: (v) => !v || /recroom|rounded|simple|box/.test(v),
    },
    fieldStyle: {
      type: [String, Object],
      default: '',
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const {
      id,
      validationProviderRef,
      validateOnBlur,
      validateOnInput,
      validate,
      isFocused,
      onFocus,
      onInput,
    } = useInput(props, emit)

    return {
      id,
      validationProviderRef,
      validateOnBlur,
      validateOnInput,
      validate,
      isFocused,
      onFocus,
      onInput,
      getValue,
    }
  },
  data() {
    return {
      isAutofilled: false,
      alwaysExpandedTypes: [
        'date',
        'time',
        'datetime-local',
        'month',
        'week',
        'range',
        'color',
      ],
      fakedType: this.type,
      unmaskedValue: null,
      showPassword: false,
    }
  },
  computed: {
    bindings() {
      const attrs = {}

      if (this.error || this.description) {
        attrs['aria-describedby'] = `${this.id}-message`
      }

      if (this.name) {
        attrs.name = this.name
      }

      if (this.maxlength) {
        attrs.maxlength = this.maxlength
      }

      return {
        class: [
          'base-input__input',
          this.clearable && 'base-input__input--clear',
          this.inputClass,
        ],
        id: this.nativeInputId || this.id,
        ref: 'input',
        type: this.computedType,
        disabled: this.disabled,
        readonly: this.readonly,
        placeholder: this.placeholder,
        autofocus: this.autofocus,
        autocomplete: this.autocomplete,
        autocapitalize:
          this.name === 'email' || this.type === 'email' ? 'none' : null,
        value: this.value,
        pattern: this.pattern,
        ...attrs,
      }
    },
    valueIsEmpty() {
      return isEmpty(this.value)
    },
    expanded() {
      return (
        this.isFocused ||
        !this.valueIsEmpty ||
        !!this.placeholder ||
        this.isAutofilled
      )
    },
    isMaskUsed() {
      return this.$_mask
    },
    useFakedNumberType() {
      const { isMaskUsed, type } = this

      // this is a special hack coz
      // mask works wrong with number type attribute
      return isMaskUsed && type === 'number'
    },
    computedType() {
      const { type, fakedType } = this

      if (this.useFakedNumberType) {
        return fakedType
      }

      return type
    },
    inputEvtName() {
      if (this.isMaskUsed) {
        return 'accept'
      }

      return 'input'
    },
    $_mask() {
      const { mask } = this

      if (typeof mask === 'string') {
        return templates[mask] || mask
      }

      return mask
    },
    isPassword() {
      return this.type === 'password'
    },
    typeProxy() {
      if (this.isPassword) {
        return this.showPassword ? 'text' : 'password'
      }
      return this.type
    },
  },
  watch: {
    value() {
      if (!this.currency) {
        this.updateMask()
      }
    },
    unmaskedValue(val) {
      this.$emit('unmasked', val)
    },
  },
  mounted() {
    if (!this.currency) {
      this.$refs.input.addEventListener('animationstart', this.onAnimationStart)
    }

    this.unmaskedValue = this.getUnmaskedValue()

    nextTick(() => {
      if (this.autofocus) {
        this.focus()
      }
    })
  },
  beforeDestroy() {
    if (!this.currency) {
      this.$refs.input.removeEventListener(
        'animationstart',
        this.onAnimationStart
      )
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    getMask() {
      return this.$refs.input?.maskRef
    },
    getUnmaskedValue() {
      const mask = this.getMask()

      return mask?.unmaskedValue
    },
    resetValue() {
      const mask = this.getMask()

      if (mask && this.$_mask) {
        mask.value = this.$_mask.minValue
      }
    },
    updateMask() {
      const mask = this.getMask()

      if (mask) {
        mask.updateValue()
      }
    },
    onFocusHandler() {
      if (this.useFakedNumberType) {
        this.fakedType = 'text'
      }

      if (this.$_mask) {
        const { $el } = this

        $el
          .querySelector('.base-field__label')
          .classList.add('base-field__label--focused')

        $el
          .querySelector('.base-field__entry')
          .classList.add('base-field__entry--focused')
      }

      this.onFocus()
      this.updateMask()
    },
    onBlur($event) {
      if (this.useFakedNumberType) {
        this.fakedType = this.type
      }

      this.isFocused = false
      if (!this.disabled) {
        this.$emit('blur', $event)
      }

      if (this.$_mask) {
        nextTick(this.updateMask)

        const { $el } = this

        $el
          .querySelector('.base-field__label')
          .classList.remove('base-field__label--focused')

        $el
          .querySelector('.base-field__entry')
          .classList.remove('base-field__entry--focused')
      }

      this.validateOnBlur(
        this.validation.unmasked ? this.unmaskedValue : this.value
      )
    },
    onCurrencyBlur() {
      if (this.disabled) {
        return
      }

      const value = this.getValue(this.$refs.input)

      this.$emit('blur', value)
      this.validateOnBlur(value)
    },
    selectContent() {
      const inputElement = this.$el.querySelector('input')

      if (inputElement && inputElement.select) {
        const contentLength = inputElement.length

        inputElement.select(0, contentLength)
      }
    },
    onAnimationStart({ target, animationName }) {
      switch (animationName) {
        case 'on-auto-fill-start':
          return this.onAutoFillStart(target)
        case 'on-auto-fill-cancel':
          return this.onAutoFillCancel(target)
      }
    },
    onAutoFillStart() {
      this.isAutofilled = true
      // Add custom event emit as a workaround for Chrome mobile autofill issue
      if (this.autofillNotice) {
        this.$emit('autofilled', this.name)
      }
    },
    onAutoFillCancel() {
      this.isAutofilled = false
    },
    keyup(keyEvent) {
      this.$emit('keyup', keyEvent)
    },
    onKeyDown(keyEvent) {
      if (keyEvent.code === 'Enter') {
        this.$emit('keydown', keyEvent)
      }
    },
    onKeyPress() {
      this.$emit('keypress', ...arguments)
    },
    onPaste() {
      this.$emit('paste', ...arguments)
    },
    clearValue() {
      this.$refs.input.value = ''
      this.updateMask()
      this.$emit('input', '')
      this.$emit('cleared')
    },
    onAccept(e) {
      // this.updateMask()
      const maskRef = e.detail

      this.unmaskedValue = maskRef.unmaskedValue

      this.validateOnInput(
        this.validation.unmasked ? this.unmaskedValue : maskRef.value
      )
      this.$emit('input', maskRef.value)
    },
    onBaseInput(e) {
      if (this.$_mask) {
        const maskRef = this.$refs.input?.maskRef

        if (!maskRef || this.$_mask.clearOnSelectionReplace === false) {
          return
        }

        if (maskRef._selection.start === 0 && maskRef._selection.end > 0) {
          maskRef.masked.reset()
        }
      } else {
        this.onInput(
          this.currency ? this.getValue(this.$refs.input) : e.target.value
        )
      }
    },
  },
}
</script>

<template>
  <validation-provider
    ref="validationProviderRef"
    v-slot="{ errors, pending }"
    v-bind="validation"
    :name="validation.name || label"
    slim
    :detect-input="false"
    :skip-if-empty="false"
  >
    <base-field
      ref="field"
      :input-id="id"
      :label="label"
      :required="required"
      :required-asterisk="requiredAsterisk"
      :border="border"
      :disabled="disabled"
      :focused="isFocused"
      :description="description"
      :error="errors[0] || error"
      :icon="icon"
      :icon-size="iconSize"
      :expanded="expanded"
      :nolabel="nolabel"
      :theme="theme"
      :icon-plain="iconPlain"
      :floated="look === 'simple' || floated"
      :class="['base-input', look && `base-input--${look}`]"
      :entry-class="entryClass"
      :label-class="labelClass"
      :value="value"
      :field-style="fieldStyle"
      @label-click="selectContent"
    >
      <template #error>
        <slot name="error" />
      </template>
      <template #prefix>
        <slot name="prefix" />
      </template>
      <template #icon>
        <slot name="icon" />
      </template>
      <template #label-suffix>
        <slot name="label-suffix" />
      </template>
      <currency-input
        v-if="currency"
        ref="input"
        v-bind="bindings"
        :value="+value"
        :value-range="{ min: 0 }"
        :precision="2"
        :currency="{
          prefix: '$',
        }"
        class="appearance-none px-2.5 py-[5px]"
        @blur="onCurrencyBlur()"
        @input="onBaseInput($event)"
      />
      <input
        v-else
        ref="input"
        v-imask="$_mask"
        v-bind="bindings"
        data-cy="input"
        :tabindex="tabindex"
        :type="typeProxy"
        :aria-labelledby="id + '-label'"
        class="placeholder:!text-eonx-neutral-600/50"
        @input="onBaseInput"
        @blur="onBlur"
        @focus="onFocusHandler"
        @keyup="keyup"
        @keydown="onKeyDown"
        @accept="onAccept"
        @keypress="onKeyPress"
        @paste="onPaste"
      />
      <button-icon
        v-if="clearable && !valueIsEmpty"
        icon="plain/cancel"
        size="xs"
        class="absolute cursor-pointer text-sm text-red-700 transition-colors hover:text-primary"
        :class="{
          'right-[15px]': look !== 'simple',
        }"
        data-cy="input-clear"
        @click="clearValue"
      />
      <slot name="inner-filter" :loading="loading || pending" :errors="errors">
        <base-loader
          v-if="loading || pending"
          size="xs"
          class="absolute inset-y-auto right-0 left-auto mr-10"
        />
      </slot>
      <button
        v-if="isPassword"
        type="button"
        :disabled="disabled"
        :title="showPassword ? 'Hide password' : 'Show password'"
        class="absolute inset-y-0 right-0 z-1 my-auto mr-2.5 inline-block w-6 cursor-pointer p-0 text-eonx-neutral-400 focus:outline-none"
        @click="showPassword = !showPassword"
      >
        <base-icon v-if="showPassword" svg="plain/eye-off-outline" :size="24" />
        <base-icon v-else svg="plain/eye-outline" :size="24" />
      </button>
      <template #disclaimer>
        <slot name="disclaimer" />
      </template>
    </base-field>
  </validation-provider>
</template>
