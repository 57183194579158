import { ValidationObserver } from 'vee-validate'
import { ref, computed, nextTick } from 'vue'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { type MetafieldsSchema } from '/~/components/base/metafield/base-metafield.vue'
import { useAddressForm, useAddresses } from '/~/composables/addresses'
import { useLocalization } from '/~/composables/localization'
import { useStatementAccounts } from '/~/composables/statements'
import { useUser } from '/~/composables/user'

const { states } = useLocalization()
const { postcodeBindings, stateBindings, suburbBindings } = useAddressForm()

const metafieldsSchema: MetafieldsSchema = {
  fields: [
    {
      type: 'object',
      key: 'statementAccounts',
      validation: {
        rules: 'required:true',
      },
    },
  ],
  types: {
    statementAccounts: {
      fields: [
        {
          type: 'address',
          key: 'statementAccountAddress',
          label: 'Business address',
          class: 'grid grid-cols-2 gap-x-6',
          entryClass: 'rounded h-11 mt-1',
          inputClass: '!py-2.5 !px-3 ',
          labelClass: '!leading-normal !p-0',
          validation: {
            rules: 'required',
          },
        },
      ],
      key: 'statementAccounts',
    },
    statementAccountAddress: {
      fields: [
        {
          type: 'string',
          key: 'address',
          label: 'Address',
          validation: {
            rules: 'required',
          },
          class: 'col-span-2 !mb-6',
          entryClass: 'rounded h-11 mt-1',
          inputClass: '!py-2.5 !px-3 ',
          labelClass: '!leading-normal !p-0',
        },
        {
          ...suburbBindings.value,
          type: 'string',
          key: 'suburb',
          validation: {
            rules: 'required',
          },
          class: 'col-span-2 !mb-6',
          entryClass: 'rounded h-11 mt-1',
          inputClass: '!py-2.5 !px-3 ',
          labelClass: '!leading-normal !p-0',
        },
        {
          ...postcodeBindings.value,
          type: 'string',
          key: 'postcode',
          validation: {
            rules: 'required|postcode',
          },
          class: '!mb-8',
          entryClass: 'rounded h-11 mt-1',
          inputClass: '!px-3',
          labelClass: '!leading-normal !p-0',
        },
        {
          ...stateBindings.value,
          type: 'list',
          key: 'state',
          validation: {
            rules: 'required',
          },
          placeholder: '-Select-',
          entryClass: 'rounded h-11 mt-1',
          labelClass: '!leading-normal !p-0',
        },
      ],
      key: 'statementAccountAddress',
    },
    state: {
      fields: states.value.map((state) => ({
        type: 'string',
        label: state.label,
        key: state.id,
      })),
      key: 'state',
    },
  },
}

const addressComponentsMap: Record<string, string> = {
  streetAddress: 'address',
}

export function useEditAccount(
  props: any,
  emit: (event: 'hide' | 'save') => void
) {
  const isProcessing = ref(false)
  const accountNameRef = ref<HTMLElement | null>(null)
  const accountName = ref(props.account.name)
  const error = ref(null)
  const form = ref({
    statementAccounts: {
      statementAccountAddress: {
        address: props.account.address?.streetAddress,
        suburb: props.account.address?.suburb,
        state: props.account.address?.state,
        postcode: props.account.address?.postcode,
      },
    },
  })
  const addressId = ref(props.account.address?.id)
  const backendErrors = ref({})
  const accountForm = ref<InstanceType<typeof ValidationObserver> | null>(null)

  const { updateStatementAccount } = useStatementAccounts()
  const { addAddress } = useAddresses()
  const { user } = useUser()

  const isAddressChanged = computed(
    () =>
      !Object.keys(form.value.statementAccounts.statementAccountAddress).every(
        (key) =>
          (form.value.statementAccounts.statementAccountAddress as any)?.[
            key
          ] ===
          props.account.address?.[key === 'address' ? 'streetAddress' : key]
      )
  )

  async function onSave() {
    isProcessing.value = true

    try {
      if (!addressId.value || isAddressChanged.value) {
        await createAddress()
      }

      if (!addressId.value) {
        return
      }

      await updateStatementAccount(props.account.id, {
        ...props.account,
        name: accountName.value,
        userAddressId: addressId.value,
      })

      modal.hide()
      emit('hide')
      emit('save')
    } catch (e: any) {
      error.value = e.name?.[0] || e.name
      accountNameRef.value?.focus()
    } finally {
      isProcessing.value = false
    }
  }

  function onCancel() {
    modal.hide()
    emit('hide')
  }

  function onClear() {
    accountName.value = ''
    accountNameRef.value?.focus()
  }

  async function createAddress() {
    const address = form.value.statementAccounts.statementAccountAddress
    const payload = {
      id: addressId.value ? addressId.value : null,
      companyAddress: null,
      default: false,
      email: user.value.email,
      firstName: user.value.firstName,
      lastName: user.value.lastName,
      mobile: user.value.mobile.replaceAll(' ', ''),
      postcode: address.postcode,
      state: address.state,
      streetAddress: address.address,
      suburb: address.suburb,
    }

    try {
      const data = await addAddress(payload)

      addressId.value = data?.id
    } catch (error: any) {
      processBackendErrors(
        error.data || error,
        'statementAccounts.statementAccountAddress'
      )

      throw error
    }
  }

  emitter.on('metafields:validate', () => {
    accountForm.value?.validate()
  })

  function processBackendErrors(errors: any, keyPrefix: string) {
    const result: Record<`${string}.${string}`, any> = {}

    for (const key in errors) {
      result[`${keyPrefix}.${addressComponentsMap[key] ?? key}`] =
        errors[key][0] ?? ''
    }

    backendErrors.value = result
  }

  nextTick(() => {
    accountForm.value?.validate()
  })

  return {
    accountForm,
    isProcessing,
    accountNameRef,
    accountName,
    metafieldsSchema,
    backendErrors,
    form,
    onCancel,
    onSave,
    onClear,
  }
}
