import { readonly, ref, computed } from 'vue'
import { type ApiResponseData } from '/~/types/api'
import api from '/~/core/api'
import { useExtensions } from '/~/composables/extensions'
import { PhotoId } from './core/photo-id'

const isPhotoIdsLoading = ref(false)
const photoIds = ref<PhotoId[]>([])

const photoIdsConfig = computed(() => {
  const { getConfigByName } = useExtensions()

  return getConfigByName('identification') || {}
})

const isPhotoIdsEnabled = computed(() => {
  const { ocrId, simpleId } = photoIdsConfig.value

  return (ocrId?.enabled || simpleId?.enabled) ?? false
})

const hasNoPhotoIds = computed(() => {
  return !isPhotoIdsLoading.value && photoIds.value.length === 0
})

async function fetchPhotoIds() {
  isPhotoIdsLoading.value = true

  try {
    const response = await api.get<ApiResponseData<unknown[]>>(
      '/v3/identification/documents?perPage=100'
    )

    photoIds.value = (response.data ?? []).map((rawPhotoId) => {
      return new PhotoId(rawPhotoId)
    })
  } catch (error) {
    console.error('identification', error)
  } finally {
    isPhotoIdsLoading.value = false
  }
}

async function deletePhotoId(id: string) {
  isPhotoIdsLoading.value = true

  try {
    await api.delete(`/v3/identification/documents/${id}`)
    await fetchPhotoIds()
  } catch (error) {
    console.error('identification', error)
    throw error
  } finally {
    isPhotoIdsLoading.value = false
  }
}

export function usePhotoIds() {
  return {
    photoIds: readonly(photoIds),
    isPhotoIdsLoading: readonly(isPhotoIdsLoading),
    isPhotoIdsEnabled,
    hasNoPhotoIds,
    fetchPhotoIds,
    deletePhotoId,
  }
}
