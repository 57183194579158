<script>
import EntryMessage from './downloadable/downloadable-message-entry.vue'
import EntryComment from './downloadable/downloadable-comment-entry.vue'
import EntryPost from './downloadable/downloadable-post-entry.vue'

export default {
  name: 'rec-downloadable-entry',
  props: {
    type: {
      type: String,
      required: true,
    },
    attachments: {
      type: Object,
      required: true,
    },
    contentColor: {
      type: String,
      default: 'text-black',
    },
  },
  computed: {
    entryComponent() {
      if (this.type === 'message') return EntryMessage
      else if (this.type === 'post') return EntryPost
      else if (this.type === 'comment') return EntryComment
      return null
    },
  },
}
</script>

<template>
  <component
    :is="entryComponent"
    v-if="entryComponent"
    :attachments="attachments"
    :content-color="contentColor"
  />
</template>
