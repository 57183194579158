import get from 'lodash-es/get'
import { computed, onMounted, watch } from 'vue'
import { KycServiceFactory } from '/~/extensions/otp/composables/core/Kyc/KycFactory'
import { useExtensions } from '/~/composables/extensions'

export function useKyc() {
  const { getConfigByName } = useExtensions()
  const moduleConfig = getConfigByName('user-kyc') as Kyc.ModuleConfig | null
  const serviceType = get(moduleConfig, 'service', 'stripeId')
  const isKycEnabled = computed(() => Boolean(moduleConfig))
  let kycService = KycServiceFactory.create(serviceType)

  watch(
    () => isKycEnabled,
    async (value) => {
      if (value) {
        kycService = KycServiceFactory.create(serviceType)
        await kycService.syncVerificationSessions()
      }
    }
  )

  onMounted(async () => {
    if (isKycEnabled.value) {
      await kycService.syncVerificationSessions()
    }
  })

  return {
    isKycEnabled,
    kycService,
  }
}
