<script>
import ui from '/~/core/ui'
import ChatRoomsItem from './chat-rooms-item.vue'
import ChatCreator from '/~rec/components/chat/chat-creator.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'
import { useChat } from '/~rec/composables/chat'
import PlaceholderChat from '/~rec/components/placeholder/placeholder-chat.vue'

export default {
  name: 'rec-chat-rooms',
  components: {
    ChatRoomsItem,
    ChatCreator,
    BaseInput,
    BasePlaceholder,
    RecBaseState,
    PlaceholderChat,
  },
  setup() {
    const { chat, openRoom, isNewChatProcessCreating } = useChat()

    return {
      ui,
      chat,
      openRoom,
      isNewChatProcessCreating,
    }
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    rooms() {
      const search = this.search.toLowerCase().trim()

      return this.chat.joinedRooms.sortedHits.filter(
        (room) => !search || room.searchValue.includes(search)
      )
    },
  },
}
</script>

<template>
  <div class="md:mx- mx-auto max-w-xl">
    <base-input
      v-model="search"
      look="recroom"
      icon="plain/search"
      placeholder="Search chat or people..."
      icon-plain
      clearable
      style="padding-top: 1rem; margin-bottom: 0.5rem"
    />
    <div class="mt-2.5 pb-[35px]">
      <placeholder-chat v-if="isNewChatProcessCreating" />
      <base-placeholder
        v-if="chat.joinedRooms.isLoading"
        :height="60"
        :rows="4"
        class="px-5"
        type="text"
        rounded
      />
      <template v-else>
        <rec-base-state
          v-if="!rooms.length"
          wide
          class="grow overflow-hidden pt-6"
          image="recroom/state/chat-empty-state.svg"
          title="It’s nice to chat with someone"
          subtitle="Start your first conversation by searching for someone below"
        />
        <div v-else>
          <div
            v-for="room in rooms"
            :key="room.id"
            class="-mx-5 cursor-pointer px-5 py-2.5 hover:bg-dark"
            @click="openRoom(room.id)"
          >
            <chat-rooms-item :room="room" />
          </div>
        </div>
      </template>
    </div>
    <chat-creator v-if="ui.mobile" type="fab" margin="m-[15px]" />
  </div>
</template>
