<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import EventStatus from '/~rec/components/event/event-status.vue'
import { formatDate } from '/~/utils/format/date'
import TileImage from './tile-image.vue'

export default {
  name: 'rec-events-tile',
  components: {
    TileImage,
    BaseLink,
    BaseIcon,
    EventStatus,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    formatDate,
  },
}
</script>

<template>
  <base-link
    color=""
    class="block w-full overflow-x-auto"
    :to="{
      name: 'rec-event',
      params: {
        initialEvent: event,
        eventId: event.id,
      },
    }"
  >
    <div
      class="relative mb-5 flex w-full items-start bg-white px-[30px] py-5 sm:rounded-lg md:mb-0"
    >
      <div class="relative row-span-2 shrink-0 overflow-hidden rounded-xl">
        <tile-image :event="event" />
      </div>

      <div class="flex w-full min-w-0 flex-col items-stretch pl-5">
        <div class="flex min-w-0 items-center justify-between pb-5 lg:pb-3.5">
          <div class="flex min-w-0 items-center">
            <div class="w-full truncate font-bold text-eonx-neutral-800">
              {{ event.name }}
            </div>
            <base-icon
              class="ml-2.5 shrink-0 text-eonx-neutral-800"
              size="xxs"
              :svg="event.isPrivate ? 'rec/lock-solid' : 'rec/planet'"
            />
          </div>
          <div class="pl-2.5">
            <div
              v-if="event.isPast"
              class="w-20 rounded-full bg-primary py-0.5 px-1.5 text-sm font-semibold text-white"
            >
              Past Event
            </div>
            <event-status v-else :event="event" is-me />
          </div>
        </div>

        <div class="flex flex-col items-stretch justify-start lg:flex-row">
          <div
            class="flex shrink-0 flex-nowrap items-center justify-start text-eonx-neutral-600"
          >
            <div class="mr-[5px] w-[15px]">
              <base-icon size="xs" svg="rec/clock" />
            </div>
            <div class="text-sm font-semibold leading-4">
              {{ formatDate('time', event.startsAt) }} -
              {{ formatDate('time', event.endsAt) }}
            </div>
          </div>
          <div
            class="flex flex-nowrap items-center justify-start truncate text-eonx-neutral-600 lg:pl-5"
          >
            <div class="mr-[5px] w-[15px]">
              <base-icon size="xs" svg="rec/map" />
            </div>
            <div class="truncate text-sm font-semibold leading-4">
              <address class="truncate not-italic" v-html="event.location" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-link>
</template>
