<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import PaymentsSplitForm from '/~/components/payments/split/payments-split-form.vue'

export default {
  name: 'payments-split-modal',
  components: {
    PaymentsSplitForm,
    BaseMdl,
  },
  props: {
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },
  methods: {
    onDiscard() {
      this.onCancel?.()
      modal.hide()
    },
    confirm(result) {
      this.onConfirm?.(result)
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl width="sme" :closable="false">
    <payments-split-form
      v-bind="$attrs"
      @confirm="confirm"
      @discard="onDiscard"
    />
  </base-mdl>
</template>
