import { RecroomPostNomination } from '/~rec/core/post-nomination'
import { RecroomPostHi5 } from '/~rec/core/post-hi5'
import { RecroomPostAward } from '/~rec/core/post-award'
import { RecroomPostQuiz } from '/~rec/core/post-quiz'
import { RecroomPostPoll } from '/~rec/core/post-poll'
import { RecroomPostEvent } from '/~rec/core/post-event'
import { RecroomPostGroup } from '/~rec/core/post-group'
import { RecroomPost } from '/~rec/core/post'
import { PostBoxData } from '/~/types/extensions'

export default function (rawPost: PostBoxData) {
  switch (rawPost.type) {
    case 'gamification_award':
      return new RecroomPostAward(rawPost)
    case 'gamification_nomination':
      return new RecroomPostNomination(rawPost)
    case 'gamification_high_five':
      return new RecroomPostHi5(rawPost)
    case 'poll':
      return new RecroomPostPoll(rawPost)
    case 'quiz':
      return new RecroomPostQuiz(rawPost)
    case 'event':
    case 'event_member':
      return new RecroomPostEvent(rawPost)
    case 'group_member':
      return new RecroomPostGroup(rawPost)
    default:
      return new RecroomPost(rawPost)
  }
}
