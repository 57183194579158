<script setup lang="ts">
import { useUser } from '/~/composables/user'
import FaqContent from './faq.vue'

const { user } = useUser()
</script>

<template>
  <div
    class="mx-auto w-full max-w-5xl bg-light px-5 pb-11 sm:px-[30px]"
    :class="{
      'overflow-y-auto': !user.authorized,
    }"
  >
    <h2 class="my-[30px] text-2xl sm:mb-11 sm:text-3xl">
      Frequently Asked Questions
    </h2>

    <faq-content />
  </div>
</template>
