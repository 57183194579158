<script>
import { computed } from 'vue'
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

export default {
  name: 'ewallet-profile',
  components: {
    BaseAction,
    BaseIcon,
  },
  props: {
    appearance: {
      type: String,
      default: 'edit-profile',
    },
    editProfileLabel: {
      type: String,
      default: 'Edit profile',
    },
    editProfileAction: {
      type: Object,
      default: () => {
        return {
          type: 'page',
          value: '/#profile-details',
        }
      },
    },
    savedToDateLabel: {
      type: String,
      default: 'You have saved',
    },
    savedToDateAction: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { isDarkThemeForEwallet } = useCms()
    const { user } = useUser()
    const { isAvatarVisible } = useProvider()

    const isSavedToDate = computed(
      () => !props.appearance || props.appearance === 'saved-to-date'
    )

    return {
      isDarkThemeForEwallet,
      user,
      isAvatarVisible,
      isSavedToDate,
      cdn,
    }
  },
}
</script>

<template>
  <section>
    <div class="relative z-1 flex items-center">
      <base-action
        v-if="isAvatarVisible"
        v-bind="editProfileAction"
        :appearance="null"
        aria-label="Profile Settings"
        class="relative h-[100px] w-[100px] shrink-0"
        :class="{
          'mr-9': isSavedToDate,
          'mr-5': !isSavedToDate,
        }"
      >
        <div
          class="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-eonx-neutral-200"
        >
          <img
            v-if="user.avatarUrl"
            :src="
              cdn(user.avatarUrl)
                .preview('448x448')
                .format('auto')
                .quality('smart')
                .url()
            "
            alt="avatar"
            class="h-full w-full object-cover object-center"
          />
          <div v-else>
            <base-icon
              class="[&>svg]:stroke-[1.5]"
              svg="heroicons/outline/user"
              :size="68"
            />
          </div>
        </div>
        <div
          v-if="isSavedToDate"
          class="absolute right-0 bottom-0 -mr-4 flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-primary text-white"
        >
          <base-icon svg="heroicons/mini/pencil-square" :size="24" alt="edit" />
        </div>
      </base-action>

      <div class="w-[220px]">
        <h3 class="break-words text-3xl font-bold">{{ user.fullName }}</h3>
        <div class="mt-2">
          <base-action
            v-if="isSavedToDate"
            v-bind="savedToDateAction"
            :appearance="null"
            aria-label="View saved to date"
            class="!text-current"
          >
            <span>
              {{ savedToDateLabel }}
            </span>
            <span class="ml-1 text-2xl font-bold text-primary">
              {{ user.totalSaving }}
            </span>
          </base-action>
          <base-action
            v-else
            v-bind="editProfileAction"
            :appearance="null"
            aria-label="Edit profile"
            class="flex items-center font-bold text-primary"
          >
            <span class="mr-1">
              {{ editProfileLabel }}
            </span>
            <base-icon
              svg="heroicons/outline/chevron-right"
              :size="16"
              alt="Edit profile"
            />
          </base-action>
        </div>
      </div>
    </div>
  </section>
</template>
