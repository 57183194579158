<script>
import get from 'lodash-es/get'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import { useCms } from '/~/composables/cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'

export default {
  name: 'estore-catalog-tile-price',
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { calculatePointsToBurnOrder } = usePoints()
    const { isPurchaseOrderPointsAvailable } = usePaymentMethods()
    const { showPointsToBurn } = useCms()

    return {
      calculatePointsToBurnOrder,
      isPointsAvailable: isPurchaseOrderPointsAvailable,
      showPointsToBurn,
      formatDollar,
    }
  },
  computed: {
    data() {
      return get(this.tile, 'payload')
    },
    pointsToBurn() {
      return this.calculatePointsToBurnOrder(this.currentPrice)
    },
    formattedPointsToBurn() {
      return formatPoints(this.pointsToBurn)
    },
    currentPrice() {
      return get(this.data, 'pricing.price_after_tax', 0)
    },
    originalPrice() {
      return get(this.data, 'pricing.rrp_after_tax', 0)
    },
    shippingTax() {
      return get(this.data, 'shipping.shipping_after_tax', 0)
    },
    hasDiscount() {
      return this.currentPrice < this.originalPrice
    },
  },
}
</script>

<template>
  <div v-if="currentPrice" class="border-t px-[5px] py-5">
    <div
      v-if="originalPrice && hasDiscount"
      class="text-sm text-eonx-neutral-600"
      aria-hidden="true"
    >
      RRP {{ formatDollar(originalPrice) }}
    </div>
    <span class="font-bold text-eonx-neutral-800" data-cy="calculation-amount">
      {{ formatDollar(currentPrice) }}
    </span>

    <span v-if="isPointsAvailable && showPointsToBurn">
      <span class="font-bold text-eonx-neutral-800">or</span>
      <span
        class="inline-block rounded-full bg-primary-lightest px-[15px] py-[5px] font-bold text-primary"
      >
        {{ formattedPointsToBurn }}
        <span class="z-1 text-sm font-bold">PTS</span>
      </span>
    </span>
  </div>
</template>
