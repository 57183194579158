<script>
import HeathScore from '/~/extensions/health/core/score'
import hits from '/~/extensions/health/mocks/score-breakdown'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'hlth-my-health-hero',
  components: {
    BaseIcon,
  },
  props: {
    blocked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    breakdownItems() {
      return hits.map((hit) => new HeathScore(hit))
    },
  },
}
</script>

<template>
  <div class="-m-2.5 flex flex-wrap">
    <div
      v-for="(item, idx) in breakdownItems"
      :key="idx"
      class="flex-auto p-2.5"
    >
      <div
        :class="
          blocked
            ? 'bg-slate-200  text-eonx-neutral-600'
            : 'bg-primary text-white'
        "
        class="flex items-center rounded-lg px-[15px] py-2.5"
      >
        <div
          v-if="item.type"
          :class="[
            blocked
              ? 'bg-gray-300 text-eonx-neutral-600'
              : 'bg-white text-primary',
          ]"
          class="relative flex h-[45px] w-[45px] shrink-0 items-center justify-center rounded-full"
        >
          <base-icon
            :class="blocked ? 'text-eonx-neutral-600' : 'text-primary'"
            :svg="`health/${item.type}`"
            :size="31"
          />
        </div>
        <div class="ml-2.5">
          <p class="text-sm font-bold capitalize leading-4">
            {{ item.type }}
          </p>
          <b class="mt-[5px] block uppercase sm:text-xl sm:leading-6">
            {{ item.value === null ? '' : item.value }}% score
          </b>
        </div>
      </div>
    </div>
  </div>
</template>
