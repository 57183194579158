<script>
import ui from '/~/core/ui'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import PerkTile from '/~rec/components/perk/perk-tile.vue'
import { RecroomPerk } from '/~rec/core/perk'
import RecBaseState from '/~rec/components/state/base-state.vue'
import RecognitionLayout from '/~rec/layouts/view/recognition-layout.vue'
import { usePerks } from '/~rec/composables/perks'

export default {
  name: 'rec-perks-view',
  components: {
    EwCatalogProcessor,
    PerkTile,
    RecBaseState,
    RecognitionLayout,
  },
  props: {
    perkId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { perks } = usePerks()

    return {
      perks,
      ui,
    }
  },
  data() {
    return {
      selectedFilter: 'recent',
      filterOptions: [
        { text: 'Most recent', value: 'recent' },
        { text: 'Most popular', value: 'popular' },
        { text: 'Ending soon', value: 'ending' },
        { text: 'Most valuable', value: 'valuable' },
        { text: 'A-Z', value: 'asc' },
      ],
    }
  },
  computed: {
    isDesktop() {
      return ui.desktop
    },
    processor() {
      switch (this.activeTab) {
        case 'pending':
          return this.perks.pendingApproval
        case 'received':
          return this.perks.redeemed
        default:
          return this.perks.available
      }
    },
    activeTab() {
      const { params, query } = this.$route

      return params.type || query.type || 'available'
    },
    loading() {
      return this.processor.processing
    },
  },
  watch: {
    activeTab: {
      handler() {
        this.getPerks()
      },
      deep: true,
    },
  },
  async created() {
    if (ui.mobile && this.perkId) {
      const perk = await RecroomPerk.fetchById(this.perkId)

      this.openMobilePerkModal(perk)
    }
    this.perks.pendingApproval.getData()
    this.perks.redeemed.getData()
    this.perks.available.getData()
  },
  methods: {
    onSelectFilter(filter) {
      this.selectedFilter = filter
      this.getPerks(filter)
    },
    getPerks() {
      const filter = this.selectedFilter

      this.processor.getData({ filter })
    },
    onPerkClick(perk) {
      this.$router.push({
        name: 'rec-perk',
        params: { perkStatus: this.activeTab, perkId: perk.id },
      })
    },
  },
}
</script>

<template>
  <recognition-layout
    title="Perks"
    :filter-options="filterOptions"
    :selected-filter="selectedFilter"
    :loading="loading"
    @filter-selected="onSelectFilter"
  >
    <ew-catalog-processor
      :processor="processor"
      :columns="{
        xxs: 1,
        xs: 2,
        lg: 3,
        xl: 4,
      }"
      tile-class="rounded-lg shadow-xs cursor-pointer hover:shadow-lg hover:-translate-y-[5px] bg-light"
    >
      <template #empty>
        <rec-base-state
          v-if="activeTab === 'available'"
          class="mt-16 mb-0 md:mt-[120px]"
          image="recroom/state/perks-available-empty-state.svg"
          title="Perks are on the way"
          subtitle="Points can be exchanged to redeem perks"
        />

        <rec-base-state
          v-if="activeTab === 'pending' || activeTab === 'received'"
          class="mt-16 mb-0 md:mt-[120px]"
          image="recroom/state/perks-available-empty-state.svg"
          title="You have not redeemed any perks yet"
          subtitle="Points can be exchanged to redeem perks any time"
        />
      </template>

      <template #tile="{ tile: perk }">
        <perk-tile v-if="perk" :perk="perk" @click="onPerkClick(perk)" />
      </template>
    </ew-catalog-processor>
  </recognition-layout>
</template>
