<script setup lang="ts">
import VerificationWarning from './verification-warning.vue'

const props = withDefaults(
  defineProps<{
    onClose?: () => void
    onCancel?: () => void
    onSubmit?: () => void
  }>(),
  {
    onClose: () => {},
    onCancel: () => {},
    onSubmit: () => {},
  }
)

const emit = defineEmits<{
  (event: 'hide'): void
}>()

function onClose() {
  emit('hide')
  props.onClose()
}

function onCancel() {
  emit('hide')
  props.onCancel()
}

function onSubmit() {
  emit('hide')
  props.onSubmit()
}
</script>

<template>
  <verification-warning
    @close="onClose"
    @cancel="onCancel"
    @submit="onSubmit"
  />
</template>
