<script>
export default {
  name: 'hlth-feed-view',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div class="flex w-full flex-col">
    <div class="w-full">
      <slot name="hero" />
    </div>
    <div class="mt-[30px] hidden px-5 sm:block sm:px-[30px]">
      <slot name="tabs" />
    </div>
    <div class="px-5 sm:px-[30px]">
      <div
        class="-mb-2.5 flex flex-col items-start pt-[15px] sm:mb-0 sm:flex-row sm:items-center"
      >
        <h2
          v-if="title"
          class="text-md mr-[30px] shrink-0 font-bold sm:mb-0 md:mb-2.5"
        >
          {{ title }}
        </h2>
        <slot name="controls" />
      </div>
      <slot />
    </div>
  </div>
</template>
