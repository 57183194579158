<script>
import ui from '/~/core/ui'
import { getMenuActiveItem } from '/~/utils/menu'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { useUI } from '/~/composables/ui'
import LayoutView from '/~/templates/bill-payments/layouts/view/layout-view.vue'
import StatementDetailsItem from './components/statement-details-item.vue'

export default {
  name: 'statement-details',

  components: {
    LayoutView,
    StatementDetailsItem,
    BaseAsidePage,
  },

  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    parentPage: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const { leftMenu, uiReady } = useUI()

    return {
      leftMenu,
      uiReady,
      ui,
    }
  },
  data() {
    return {
      options: [
        {
          text: 'Most recent',
          value: 'most-recent',
        },
      ],
    }
  },

  computed: {
    activeTopMenuItem() {
      if (!this.uiReady || !this.leftMenu) return

      return getMenuActiveItem(this.$route, this.leftMenu)
    },
    description() {
      return this.activeTopMenuItem?.description
    },
    title() {
      return this.activeTopMenuItem?.label
    },
    menu() {
      return this.activeTopMenuItem?.children ?? []
    },
  },
}
</script>

<template>
  <base-aside-page
    v-if="ui.mobile"
    title="Transaction details"
    :no-padding="true"
    class="pb-5"
    :back="{ name: 'statements' }"
  >
    <statement-details-item :id="id" />
  </base-aside-page>
  <layout-view v-else heading="Bill Pay" :menu="menu">
    <template #description>
      <p>
        Earn points every time you make a business payment, whether its for an
        invoice or paying any BPAY biller.
      </p>
    </template>
    <statement-details-item :id="id" :parent-page="parentPage" />
  </layout-view>
</template>
