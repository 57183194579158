<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import FeedTile from '/~/extensions/health/components/feed/tile/feed-tile-article.vue'
import HealthArticle from '/~/extensions/health/core/article'
import HeathExerciseProcessor from '/~/extensions/health/core/articles-processor'
import { Columns } from '/~/utils/style'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'

const props = withDefaults(
  defineProps<{
    type: string
    tileRouteName: string
    feed: HeathExerciseProcessor
    limit?: number
    columns?: Columns
  }>(),
  {
    limit: Infinity,
    columns: () => ({
      xs: 2,
      sm: 3,
      lg: 4,
    }),
  }
)

const router = useRouter()

function onTileClick(article: HealthArticle) {
  router.push({
    name: props.tileRouteName,
    params: {
      type: props.type,
      id: article.id,
    },
  })
}
</script>

<template>
  <ew-catalog-processor :processor="feed" :columns="columns" :limit="limit">
    <template #tile="{ tile }">
      <feed-tile :tile="tile" @tile-click="onTileClick" />
    </template>
    <template #empty>
      <div class="h-64">No items were found</div>
    </template>
  </ew-catalog-processor>
</template>
