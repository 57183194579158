import { PostsProcessor } from '/~rec/core/posts-processor'
import { reactive, computed, ref } from 'vue'
import { useRecProfile } from './profile'

const { myRecId } = useRecProfile()

const state = reactive({
  feed: new PostsProcessor({
    entity: 'feed',
  }),
  announcements: new PostsProcessor({
    entity: 'feed?announcement=true',
  }),
  'saved-posts': new PostsProcessor({
    entity: 'saved-posts',
  }),
  my: new PostsProcessor({
    entity: 'feed',
    filters: () => {
      return {
        default: {
          author: myRecId.value,
        },
      }
    },
  }),
  'reported-posts': new PostsProcessor({
    entity: 'reported-posts',
  }),
  selectedFeedId: 'feed',
})

const selectedFeed = computed(() => state[state.selectedFeedId])

const isNewPostProcessing = ref(false)

export const useFeeds = () => ({
  feeds: state,
  selectedFeed,
  isNewPostProcessing,
})
