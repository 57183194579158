<script>
import DefaultSummaryPayWithItem from '/~/views/checkout.v2/components/checkout/summary/summary-pay-with-item.vue'

export default {
  name: 'summary-pay-with-item',
  extends: DefaultSummaryPayWithItem,
  setup() {
    return {
      ...DefaultSummaryPayWithItem.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <div class="relative">
    <div class="flex gap-4">
      <div
        class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white"
        :class="{
          'bg-eonx-neutral-600': isBillPaymentsTemplate && isBankAccount,
          'bg-primary': !isBillPaymentsTemplate && isBankAccount,
        }"
      >
        <base-icon
          v-if="icon"
          :svg="icon"
          :size="iconSize"
          :style="{
            color: isPoints ? '#F59E0B' : null,
          }"
        />
      </div>
      <div class="flex w-full flex-col">
        <div class="font-bold">
          {{ firstRow }}
        </div>
        <div class="flex justify-between text-eonx-neutral-600">
          <div class="text-sm">{{ secondRow }}</div>
          <div>{{ formatDollar(amountToPay) }}</div>
        </div>
      </div>
    </div>
    <div v-if="hasFeesSeparateTransaction" class="mt-4 flex gap-4">
      <div
        class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white"
        :class="{
          'bg-eonx-neutral-600': isBillPaymentsTemplate,
          'bg-primary': !isBillPaymentsTemplate,
        }"
      >
        <base-icon svg="v2/custom/bank" :size="32" />
      </div>
      <div class="flex w-full flex-col">
        <div class="font-bold">
          {{ firstRow }}
        </div>
        <div class="flex justify-between text-sm text-eonx-neutral-600">
          <div>{{ secondRow }}</div>
          <div>{{ formatDollar(payment.totalFees) }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="showBankAccountNotices"
      class="mt-5 flex items-center bg-eonx-neutral-200 py-2.5"
    >
      <div class="flex w-16 shrink-0">
        <base-icon
          svg="v2/custom/alert"
          :size="32"
          class="mx-auto shrink-0 text-eonx-neutral-800"
        />
      </div>
      <ul
        class="list-disc space-y-[5px] pl-5 pr-4 text-sm text-eonx-neutral-600"
      >
        <li v-for="(notice, index) in bankAccountNotices" :key="index">
          {{ notice }}
        </li>
      </ul>
    </div>
  </div>
</template>
