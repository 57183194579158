<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerModal from '/~/components/drawer/components/drawer-modal.vue'
import { ref } from 'vue'

export default {
  name: 'virtual-card-link-modal',
  components: {
    BaseButton,
    BaseIcon,
    DrawerModal,
  },
  setup() {
    const isVisible = ref(false)

    function show() {
      isVisible.value = true
    }

    function hide() {
      isVisible.value = false
    }

    return {
      isVisible,
      show,
      hide,
    }
  },
}
</script>

<template>
  <drawer-modal to="menu-modal" :visible="isVisible" @hide="hide">
    <div class="pt-20 pb-[30px] font-inter">
      <div class="mb-12 flex items-center justify-center">
        <div
          class="h-20 rounded-full border-2 border-gray-900 px-5 pt-5 pb-[15px]"
        >
          <base-icon svg="google-pay" class="h-full" />
        </div>
        <div
          class="ml-6 h-20 rounded-md border-2 border-gray-900 py-5 px-[15px]"
        >
          <base-icon svg="apple-pay" class="h-full" />
        </div>
      </div>
      <div
        class="mb-4 text-center text-2xl font-bold leading-8 text-eonx-neutral-800"
      >
        Link your Virtual Card to your Google Pay or Apple Pay
      </div>
      <div
        class="mb-[30px] text-center text-base font-normal leading-6 text-eonx-neutral-600"
      >
        In order to link your card to your Google Pay or Apple Pay wallets
        simply open the App and choose the add card manually option. Then simply
        follow the steps and you will be able to use your Virtual Card anywhere
        with ease.
      </div>
      <div class="mt-[30px] flex flex-col items-center">
        <div
          class="font-lato flex items-center text-sm font-bold text-eonx-neutral-800"
        >
          <div
            class="mr-2.5 h-[30px] rounded-full border border-gray-900 p-1.5"
          >
            <base-icon svg="google-pay" class="h-full" />
          </div>
          <a
            href="https://support.google.com/pay/answer/7625139?hl=en&ref_topic=7625285#zippy=%2Ccredit-debit-cards"
            target="_blank"
            class="underline"
          >
            How to add a card to Google Pay
          </a>
          <base-icon
            svg="points-only/chevron-left"
            :size="10"
            class="ml-[15px] rotate-180 transform"
          />
        </div>
        <div
          class="font-lato mt-4 flex items-center text-sm font-bold text-eonx-neutral-800"
        >
          <div class="mr-2.5 h-[30px] rounded border border-gray-900 p-1.5">
            <base-icon svg="apple-pay" class="h-full" />
          </div>
          <a
            href="https://support.apple.com/en-us/HT204506"
            target="_blank"
            class="underline"
          >
            How to add a card to Apple Pay
          </a>
          <base-icon
            svg="points-only/chevron-left"
            :size="10"
            class="ml-[15px] rotate-180 transform"
          />
        </div>
      </div>
      <div class="mt-10">
        <base-button :full-width="true" @click="hide">Got it</base-button>
      </div>
    </div>
  </drawer-modal>
</template>
