<script>
import PostCreator from '/~rec/components/post/creator/post-creator.vue'
import DetailsPlaceholder from '/~rec/components/placeholder/placeholder-event-group-details.vue'
import GroupFeed from '/~rec/components/group/group-feed.vue'
import GroupHeader from '/~rec/components/group/group-header.vue'
import DetailsSidebarRoot from '/~rec/components/details/details-sidebar-root.vue'
import GroupInfo from '/~rec/components/group/group-info.vue'
import MembersManager from '/~rec/components/users/members-manager.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'recroom-group-view',
  components: {
    GroupHeader,
    DetailsPlaceholder,
    PostCreator,
    DetailsSidebarRoot,
    GroupFeed,
    GroupInfo,
    MembersManager,
  },
  provide() {
    return {
      sources: this.sources,
    }
  },
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return { isMeAdmin, isMeSuperAdmin }
  },
  data() {
    return {
      sources: {
        source: null,
      },
      showSidebar: true,
      showListManager: false,
      usersProcessing: false,
      isShowScrollHeader: false,
    }
  },
  computed: {
    canRemoveMembers() {
      return this.group?.isMeOwner || this.isMeAdmin || this.isMeSuperAdmin
    },
  },
  watch: {
    group() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.sources.source = this.group
    },
    onPostCreated() {
      this.group.posts.refresh()
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
    },
    async removeUserHandler(memberId) {
      this.usersProcessing = true
      await this.group?.detachMembers([memberId])
      await this.group?.members?.refresh()
      this.usersProcessing = false
    },
    userScroll(event) {
      const { scrollTop } = event.target

      if (scrollTop >= 53 && !this.isShowScrollHeader) {
        this.isShowScrollHeader = true
      }
      if (scrollTop < 35 && this.isShowScrollHeader) {
        this.isShowScrollHeader = false
      }
    },
    onNewPostsLoaded() {
      this.$refs.content.scrollTop = 0
    },
  },
}
</script>

<template>
  <div
    ref="content"
    class="mx-auto w-full flex-1 overflow-y-auto"
    @scroll="userScroll"
  >
    <template v-if="!loading && group">
      <template v-if="!showListManager">
        <div class="sticky top-0 z-20 mt-[30px] bg-gray-50">
          <div class="mx-auto max-w-5xl">
            <group-header
              :group="group"
              class="mb-4"
              :mini-header="isShowScrollHeader"
              @toggle="toggleSidebar"
            />
          </div>

          <div class="bg-white shadow-sm">
            <post-creator
              :source="group"
              class="mx-auto max-w-2xl"
              @created="onPostCreated"
            />
          </div>
        </div>

        <div class="mx-auto max-w-2xl px-[30px]">
          <group-feed :group="group" @posts-loaded="onNewPostsLoaded" />
        </div>
      </template>
      <div class="mx-auto w-full max-w-5xl pt-[30px]">
        <members-manager
          v-if="showListManager"
          :removable="canRemoveMembers"
          :multi="false"
          :name="group.name"
          label="Group members"
          :current-users="group.members.hits"
          :remove-handler="removeUserHandler"
          @close="showListManager = false"
        />
      </div>

      <portal to="rec-sidebar">
        <details-sidebar-root :is-show="showSidebar">
          <group-info
            :group="group"
            :users-processing="usersProcessing"
            @close="showSidebar = false"
            @open-members="showListManager = true"
          />
        </details-sidebar-root>
      </portal>
    </template>
    <div v-else class="pt-[30px]">
      <details-placeholder />
    </div>
  </div>
</template>
