<template>
  <div class="flex h-[400px] items-center justify-center">
    <base-loader />
  </div>
</template>

<script>
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  components: {
    BaseLoader,
  },
}
</script>
