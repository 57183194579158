<script>
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-post-header',
  components: {
    BaseLink,
    BaseAvatar,
    BaseDate,
  },
  inject: ['post'],
  setup() {
    const { features } = useRecProfile()

    return { features }
  },
  computed: {
    postDate() {
      return this.post.isPostUpdated ? this.post.updatedAt : this.post.createdAt
    },
  },
  methods: {
    openProfile() {
      if (this.features.directory) {
        this.$router.replace({ query: { recProfileId: this.post?.author?.id } })
      }
    },
  },
}
</script>

<template>
  <div
    class="relative flex items-center rounded-t-xl bg-white py-2.5 pl-[15px] pr-1.5 md:py-[15px] md:pl-6 md:pr-5"
  >
    <div
      class="block"
      :class="{
        'cursor-pointer': features.directory,
      }"
      @click="openProfile"
    >
      <base-avatar
        class="mr-[15px]"
        size="lg"
        :src="post.author.avatarUrl"
        :abbr="post.author.initials"
      />
    </div>
    <div class="overflow-hidden">
      <div
        class="inline-block"
        :class="{
          'cursor-pointer': features.directory,
        }"
        @click="openProfile"
      >
        <div class="truncate font-bold text-eonx-neutral-800">
          {{ post.author.fullName }}
        </div>
      </div>
      <div class="flex flex-wrap font-semibold">
        <base-link
          v-if="post.isAnnouncement"
          class="mr-[5px] mt-0.5 text-sm"
          :to="{
            name: 'rec-feed',
            params: { feedId: 'announcements' },
          }"
        >
          Announcements
        </base-link>
        <template v-else-if="post.toGroup || post.toEvent">
          <base-link
            v-if="post.toEvent"
            class="mr-[5px] mt-0.5 text-sm font-semibold"
            :to="{
              name: 'rec-event',
              params: {
                eventId: post.toEvent.id,
              },
            }"
          >
            {{ post.toEvent.name }}
          </base-link>
          <base-link
            v-else-if="post.toGroup.name === 'All members'"
            class="mr-[5px] mt-0.5 text-sm text-primary"
            :to="{
              name: 'rec-feed',
              params: { feedId: 'feed' },
            }"
          >
            Newsfeed
          </base-link>
          <base-link
            v-else
            class="mr-[5px] mt-0.5 text-sm font-semibold text-primary"
            :to="{
              name: 'rec-group',
              params: {
                groupId: post.toGroup.id,
              },
            }"
          >
            {{ post.toGroup.name }}
          </base-link>
        </template>
        <base-date
          :date="postDate"
          class="mt-0.5 text-sm font-semibold text-eonx-neutral-600"
          type="day-time-spr"
        />
      </div>
    </div>
    <div class="ml-auto flex shrink-0 items-center">
      <slot />
    </div>
  </div>
</template>
