<script>
import { ref, nextTick } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useCms } from '/~/composables/cms'
import { useUser } from '/~/composables/user'
import MenuDonutChart from './menu-donut-chart.vue'

export default {
  name: 'profile-saved',
  components: {
    BasePlaceholder,
    MenuDonutChart,
    BaseAsidePage,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const { user, savedCategories, fetchSavedCategories } = useUser()
    const { isDarkThemeForEwallet } = useCms()

    const loading = ref(true)

    fetchSavedCategories().then(() => {
      loading.value = false
    })

    return {
      loading,
      user,
      savedCategories,
      isDarkThemeForEwallet,
    }
  },
  methods: {
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page ref="baseAsidePage" title="Saved to date">
    <div class="rounded-md border border-zinc-100 p-2.5 text-center">
      <div class="text-3xl font-bold">
        {{ user.totalSaving }}
      </div>
      <div class="mt-[5px] font-bold">Saved to date</div>
    </div>

    <div
      class="mt-11 rounded-lg"
      :class="{
        'bg-light': !isDarkThemeForEwallet,
      }"
    >
      <div v-if="loading">
        <div class="mx-auto mb-5 h-72 w-72 rounded-full bg-dark" />
        <base-placeholder class="'my-5'" type="text" :rows="4" :height="30" />
      </div>
      <menu-donut-chart v-else :chart-values="savedCategories" />
    </div>
  </base-aside-page>
</template>
