<script>
import { watch, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import OoCatalogEmpty from '/~/extensions/online-offers/components/oo-catalog-empty.vue'
import OoTile from '/~/extensions/online-offers/components/oo-tile.vue'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'

export default {
  name: 'online-offers-catalog',
  components: {
    EwCatalogProcessor,
    OoCatalogEmpty,
    OoTile,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { routeState, algolia, syncState, getContent } = useOnlineOffers()

    watch(routeState, (value) => {
      router.replace(value)
    })

    watch(
      () => ({ ...route }),
      (to, from) => {
        if (
          to.fullPath.replace(to.hash, '') !==
          from.fullPath.replace(from.hash, '')
        ) {
          syncState({ to })
          getContent()
        }
      }
    )

    onBeforeMount(getContent)

    return {
      algolia,
    }
  },
}
</script>

<template>
  <ew-catalog-processor
    :processor="algolia"
    :columns="{
      xxs: 2,
      sm: 3,
      lg: 4,
    }"
  >
    <template #empty>
      <oo-catalog-empty />
    </template>

    <template #tile="{ tile }">
      <oo-tile :tile="tile" />
    </template>
  </ew-catalog-processor>
</template>
