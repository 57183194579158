<script setup lang="ts">
import { computed, watch, ref, onMounted } from 'vue'
import cdn from '/~/utils/cdn'
import BaseButtonRound from '/~/components/base/button-round/base-button-round.vue'
import BaseCounter from '/~/components/base/counter/base-counter.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import CartItemPrice from '/~/components/cart-menu/components/item/cart-item-price.pure.vue'
import EwProductType from '/~/components/ewallet/product/ew-product-type.v3.vue'
import { useCart } from '/~/composables/cart'
import { useCms } from '/~/composables/cms'
import { useGiftRecipients } from '/~/composables/gift-recipients'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'

const props = withDefaults(
  defineProps<{
    item: Record<string, any>
    showDelivery?: boolean
    prependEstoreTitleWithPrice?: boolean
  }>(),
  {
    showDelivery: true,
    prependEstoreTitleWithPrice: true,
  }
)

const gift = ref(undefined)
const loading = ref(false)

const { calculatePointsToBurnOrder } = usePoints()
const { cart, updateCartItem, removeCartItem } = useCart()
const { getGiftRecipient } = useGiftRecipients()
const { isPurchaseOrderPointsAvailable } = usePaymentMethods()
const { ewalletLabels, showPointsToBurn } = useCms()

const isPointsAvailable = computed(
  () => isPurchaseOrderPointsAvailable.value && showPointsToBurn.value
)

const isEstore = computed(() => props.item.type === 'estore')
const isCinema = computed(() => props.item.type === 'voucher')
const price = computed(() => {
  const { price, priceGst } = props.item

  if (isEstore.value) {
    return priceGst
  } else {
    return price
  }
})
const total = computed(() => price.value * props.item.quantity)
const pointsToBurn = computed(() => calculatePointsToBurnOrder(total.value))
const image = computed(() =>
  cdn(props.item.productImage || props.item.retailerLogo).url()
)

const title = computed(() => {
  const { retailerName, name, type, physical } = props.item

  // duplicated retailerName in physical eStore products
  if (isEstore.value && physical) return

  switch (type) {
    case 'dining':
      return name
    default:
      return retailerName
  }
})

const product = computed(() => {
  const { physical, name } = props.item

  let cardType

  if (isEstore.value) {
    if (props.prependEstoreTitleWithPrice) {
      cardType = name
    } else {
      return name
    }
  } else if (isCinema.value) {
    cardType = 'eVoucher'
  } else if (physical) {
    cardType = 'Physical Gift Card'
  } else {
    cardType = 'eGift Card'
  }

  return cardType
})

const removeItem = () => {
  removeCartItem({
    key: props.item.id,
  })
}

const getGiftDetails = async () => {
  loading.value = true
  gift.value = await getGiftRecipient(props.item.giftRecipientId)
  loading.value = false
}

const updateItem = (counterValue) => {
  updateCartItem({
    key: props.item.id,
    newState: counterValue,
  })
}

watch(
  () => props.item.giftRecipientId,
  (id) => id && getGiftDetails()
)

onMounted(async () => {
  if (props.item.giftRecipientId) {
    getGiftDetails()
  }
})
</script>

<template>
  <div v-if="props.item">
    <div
      class="relative mx-5 my-[15px] rounded-xl border border-gray-200 bg-white p-[15px]"
    >
      <div class="absolute top-0 right-0 -mt-2.5 -mr-2.5">
        <base-button-round
          class="rounded-full border-4 border-solid border-light"
          :disabled="cart.updating"
          icon="plain/trash"
          icon-size="sm"
          color="error"
          :title="title"
          alt="remove"
          data-test="cart-item-remove"
          @click="removeItem"
        />
      </div>
      <div class="mb-[5px] flex items-center pr-[15px]">
        <div class="mr-2.5 w-20 shrink-0">
          <base-image
            v-if="image"
            :src="image"
            :alt="String(props.item.value)"
            :fit="isEstore ? 'contain' : 'cover'"
          />
        </div>
        <div class="min-w-0">
          <p class="truncate font-bold">
            <span>
              {{ title ? `${title} - ` : '' }}
            </span>
            <span>{{ product }}</span>
          </p>

          <template v-if="loading">
            <div
              class="h-5 w-full animate-pulse rounded-md bg-eonx-neutral-50"
            />
          </template>
          <template v-else>
            <ew-product-type
              v-if="isEstore && !gift"
              class="text-eonx-neutral-600"
              physical
              :show-delivery="props.showDelivery"
            >
              <template #physical>Delivered by supplier</template>
            </ew-product-type>
            <ew-product-type
              v-else-if="props.item.gift && gift"
              class="text-eonx-neutral-600"
              :gift="props.item.gift"
              :gift-card="!!props.item.retailerName"
              :physical="props.item.physical"
              :show-delivery="props.showDelivery"
            >
              <template #digital>
                <div>Gift to {{ gift.email }}</div>
              </template>
              <template #physical>
                <div>Gift to {{ gift.name }}</div>
              </template>
            </ew-product-type>
            <ew-product-type
              v-else
              class="text-eonx-neutral-600"
              :gift="props.item.gift"
              :gift-card="!!props.item.retailerName"
              :physical="props.item.physical"
              :show-delivery="props.showDelivery"
            >
              <template #digital>
                Delivered to
                {{ ewalletLabels.ewallet }}
              </template>
              <template #physical>Delivered by AusPost</template>
            </ew-product-type>
          </template>
        </div>
      </div>

      <div class="mt-2.5 flex justify-between">
        <div class="flex items-end">
          <base-counter
            class="grow"
            :value="props.item.quantity"
            :disabled="cart.updating"
            rounded
            :min="1"
            :max="10"
            @input="updateItem"
          />
        </div>
        <cart-item-price
          :total="total"
          :is-points-available="isPointsAvailable"
          :points-to-burn="pointsToBurn"
        />
      </div>
    </div>
  </div>
</template>
