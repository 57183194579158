<script>
import { cardCode } from '/~/utils/cards'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'

export default {
  name: 'drawer-method-tile',
  components: {
    BaseIcon,
  },
  props: {
    method: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['click'],
  setup() {
    const { accountPresentation } = useBankAccountForm()
    const { translate } = useLocalization()

    return {
      PaymentMethodType,
      accountPresentation,
      translate,
    }
  },
  computed: {
    isCard() {
      return this.method.type === this.PaymentMethodType.creditCard
    },
    isBankAccount() {
      return this.method.type === this.PaymentMethodType.bankAccount
    },
    cardCode() {
      return cardCode(this.method)
    },
    isMastercard() {
      return this.cardCode === 'mastercard'
    },
    isVisa() {
      return this.cardCode === 'visa'
    },
    isAmex() {
      return this.cardCode === 'amex'
    },
    icon() {
      if (this.isMastercard) {
        return 'cards/mastercard'
      } else if (this.isVisa) {
        return 'v2/custom/visa'
      } else if (this.isAmex) {
        return 'v2/custom/amex'
      } else if (this.isBankAccount) {
        return 'v2/custom/bank'
      }

      return ''
    },
    iconSize() {
      if (this.isBankAccount) {
        return 26
      } else if (this.isMastercard) {
        return 48
      } else if (this.isVisa) {
        return 74
      } else if (this.isAmex) {
        return 70
      }

      return 0
    },
    firstRow() {
      const { type, number, bsb = '' } = this.method

      switch (type) {
        case this.PaymentMethodType.creditCard: {
          if (this.isAmex) {
            return `<span class="mr-2.5">* * * *</span><span class="mr-2.5">* * * * * *</span>${
              number.replace(/\s/, '').length >= 15
                ? '*' + number.slice(-4)
                : '* * * * *'
            }`
          } else {
            return `<span class="mr-2.5">* * * *</span><span class="mr-2.5">* * * *</span><span class="mr-2.5">* * * *</span>${
              number.replace(/\s/, '').length >= 16
                ? number.slice(-4)
                : '* * ** '
            }`
          }
        }
        case this.PaymentMethodType.bankAccount:
          return this.accountPresentation.bsb
            ? `<span class="mr-2.5">BSB</span><span class="mr-[5px]">* * *</span>${
                bsb.length === 6 ? bsb.slice(-3) : ''
              }`
            : ''
        default:
          return ''
      }
    },
    secondRow() {
      const { type, accountNumber, expiry } = this.method

      switch (type) {
        case this.PaymentMethodType.creditCard:
          return expiry.month.length >= 2 && expiry.year.length >= 4
            ? `${expiry.month}/${expiry.year.slice(-2)}`
            : 'MM/YY'
        case this.PaymentMethodType.bankAccount:
          return `<span class="mr-2.5">ACC</span><span class="mr-[5px]">* * *</span>${
            accountNumber.length >= 5 ? accountNumber.slice(-2) : ''
          }`
        default:
          return ''
      }
    },
    thirdRow() {
      return this.method.name
    },
  },
}
</script>

<template>
  <div
    class="relative mx-auto w-72 overflow-hidden rounded-3xl"
    :class="{
      'bg-gray-800': isMastercard,
      'bg-blue-900': isVisa,
      'bg-gray-600': !isMastercard && !isVisa && !isAmex,
    }"
    :style="{
      'background-color': isAmex ? '#0c66a8' : null,
    }"
    @click="$emit('click')"
  >
    <div
      class="absolute rounded-full"
      :class="{
        'bg-gray-700': isMastercard,
        'bg-blue-800': isVisa,
        'bg-gray-500': isBankAccount,
      }"
      :style="{
        top: '-214px',
        left: '-163px',
        width: '351px',
        height: '351px',
        'background-color': isAmex ? '#0e75bf' : null,
      }"
    />
    <div
      class="absolute rounded-full"
      :class="{
        'bg-gray-900': isMastercard,
        'bg-gray-700': isBankAccount,
      }"
      :style="{
        top: '103px',
        right: '-71px',
        width: '218px',
        height: '218px',
        'background-color': isVisa ? '#182e6e' : isAmex ? '#0a588f' : null,
      }"
    />
    <div class="relative flex h-48 w-full flex-col p-5 text-white">
      <div class="flex h-[35px] items-center">
        <base-icon v-if="icon" :svg="icon" :size="iconSize" />
      </div>
      <div class="mt-6">
        <div v-if="firstRow" class="text-xl font-black" v-html="firstRow" />
        <div
          v-if="secondRow"
          :class="{
            'text-xl font-black': isBankAccount,
            'font-bold': !isBankAccount,
          }"
          v-html="secondRow"
        />
      </div>
      <div v-if="thirdRow" class="mt-auto truncate font-bold">
        {{ thirdRow }}
      </div>
    </div>
  </div>
</template>
