import { computed, ref } from 'vue'
import ui from '/~/core/ui'
import MobileMenu from '/~/components/mobile-menu/mobile-menu.vue'

const mobileMenuRef = ref<InstanceType<typeof MobileMenu>>()

const isMobileMenuToggledOn = computed(
  () => ui.mobile && mobileMenuRef.value?.isToggled === true
)
const isMobileMenuToggledOff = computed(
  () => ui.mobile && mobileMenuRef.value?.isToggled === false
)

function initMobileMenu(ref: InstanceType<typeof MobileMenu>) {
  mobileMenuRef.value = ref
}

function showMobileMenu() {
  if (!mobileMenuRef.value) {
    return
  }

  mobileMenuRef.value.isToggled = true
}

function hideMobileMenu() {
  if (!mobileMenuRef.value) {
    return
  }

  mobileMenuRef.value.isToggled = false
}

export function useMobileMenu() {
  return {
    isMobileMenuToggledOn,
    isMobileMenuToggledOff,
    initMobileMenu,
    showMobileMenu,
    hideMobileMenu,
  }
}
