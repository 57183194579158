<script>
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'leads-table',
  components: {
    BaseIcon,
    BaseCollapse,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <table class="text-lg text-eonx-neutral-800">
    <thead>
      <tr v-for="(row, rowIndex) in data.head" :key="rowIndex">
        <th
          v-for="(col, colIndex) in row"
          :key="colIndex"
          class="bg-gray-300 px-2.5 py-2.5 text-left sm:px-12"
        >
          {{ col }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, rowIndex) in data.body"
        :key="rowIndex"
        :class="{ 'bg-gray-300': row.filled }"
      >
        <td
          v-if="row.header"
          colspan="2"
          class="bg-orange-400 px-2.5 py-2.5 font-semibold text-white sm:px-12"
        >
          {{ row.header }}
        </td>
        <td v-if="row.title" class="w-1/4 px-5 py-2.5 sm:px-12">
          {{ row.title }}
          <ul v-if="row.titleList" class="mt-[15px] list-disc sm:list-inside">
            <li
              v-for="(item, titleItemIndex) in row.titleList"
              :key="titleItemIndex"
            >
              {{ item }}
            </li>
          </ul>
        </td>
        <td
          v-if="row.description || row.list"
          class="w-3/4 px-2.5 py-2.5 sm:px-12"
        >
          <template v-if="row.description">
            {{ row.description }}
          </template>
          <ul v-if="row.list" class="list-disc sm:list-inside">
            <li
              v-for="(item, titleDescIndex) in row.list"
              :key="titleDescIndex"
            >
              {{ item }}
            </li>
          </ul>
          <template v-if="row.notes">
            <base-collapse v-model="row.notesOpen" :autoscroll="false">
              <template #trigger>
                <div
                  class="flex cursor-pointer items-center pt-[15px] pb-[5px] hover:opacity-75"
                >
                  <base-icon
                    :svg="
                      row.notesOpen ? 'australiapost/minus-circle' : 'plain/add'
                    "
                    class="mr-2.5 rotate-90 transform text-red-700 duration-500"
                    :class="{ 'rotate-180': row.notesOpen }"
                    :size="20"
                  />
                  <span class="font-semibold text-eonx-neutral-600">Notes</span>
                </div>
              </template>
              <p>{{ row.notes }}</p>
            </base-collapse>
          </template>
        </td>
        <td v-if="row.descriptionList" class="px-2.5 py-2.5 sm:px-12">
          <template v-for="(descriptionItem, index) in row.descriptionList">
            <p
              :key="index"
              :class="{
                'mb-[15px] break-all sm:break-normal': !descriptionItem.list,
              }"
              v-html="descriptionItem.title"
            />
            <ul
              v-if="descriptionItem.list"
              :key="`desc${index}`"
              class="mb-[5px] list-disc sm:ml-[15px] sm:list-inside"
            >
              <li
                v-for="(listItem, listItemIndex) in descriptionItem.list"
                :key="listItemIndex"
              >
                {{ listItem }}
              </li>
            </ul>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>
