<script>
import SingleAdditionalOffer from './offers-additional-single.vue'

export default {
  components: {
    SingleAdditionalOffer,
  },
  props: {
    merchant: {
      type: Object,
      default: () => ({}),
    },
    offers: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <div class="pt-[30px]">
    <div class="mb-[30px] text-xl font-bold sm:text-2xl">Additional Offers</div>
    <div class="space-y-5">
      <single-additional-offer
        v-for="offer in offers"
        :key="offer.external_id"
        :offer="offer"
        :merchant="merchant"
      />
    </div>
  </div>
</template>
