<script setup type="ts">
import DrawerMenu from '/~/components/drawer/drawer-menu.vue'
import { useDrawerMenu } from '/~/components/drawer/use-drawer-menu'
import { useUI } from '/~/composables/ui'

const { ewalletMenuRoutes } = useDrawerMenu()
const {
  isProfileMenuVisible,
  toggleProfileMenu,
} = useUI()
</script>

<template>
  <drawer-menu
    :routes="ewalletMenuRoutes"
    prefix="profile"
    portal-target="menu-modal"
    :is-visible="isProfileMenuVisible"
    @toggle="toggleProfileMenu"
  />
</template>
