<script>
import { computed } from 'vue'
import DefaultGiftcardsLocation from '/~/extensions/gift-cards-cinema/templates/default/views/giftcards-location.vue'
import { getProviderDefaultUrl } from '/~/utils/cdn'
import MajorSummary from '../components/major-summary.vue'
import RetailerDetails from '../components/retailer-details.vue'

export default {
  name: 'gift-cards-cinema-location-major-bupa',
  components: {
    RetailerDetails,
    MajorSummary,
  },
  extends: DefaultGiftcardsLocation,
  setup() {
    const defaultImage = computed(() => getProviderDefaultUrl('bupa'))

    return {
      ...DefaultGiftcardsLocation.setup?.(...arguments),
      defaultImage,
    }
  },
}
</script>

<template>
  <ew-details-layout
    :loading="!gcCinemaMajorRetailer"
    :ready="true"
    :title="gcCinemaMajorTitle"
    :back="{
      name: 'cinema-group',
      params: { groupSlug: $route.params.groupSlug },
    }"
    :class="!gcCinemaMajorRetailer && 'px-5'"
  >
    <template #gallery>
      <retailer-logo
        :retailer="gcCinemaMajorRetailer"
        :default-src="defaultImage"
        class="mt-5 sm:mt-0"
      />
    </template>

    <template #details>
      <retailer-details
        :offer="gcCinemaMajorOffer"
        :retailer="gcCinemaMajorRetailer"
        class="px-5"
      />
    </template>

    <template #summary>
      <major-summary />
    </template>
  </ew-details-layout>
</template>
