<script>
import { ref, computed } from 'vue'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import { ProviderDefaultUrlMap } from '/~/utils/cdn'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.v3.vue'
import { useProvider } from '/~/composables/provider'
import RetailerLogo from './components/logo/community-hub-logo.vue'
import OfferDetails from './components/offers/offer-details.vue'
import OffersTitle from './components/offers/offers-title.vue'

export default {
  name: 'community-hub-groups-offer',
  components: {
    EwDetailsLayout,
    OffersTitle,
    RetailerLogo,
    OfferDetails,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { fetchOffer } = useCommunityHub()
    const { isBupaTemplate } = useProvider()

    const offer = ref({})
    const loading = ref(true)

    const retailerName = computed(() => offer.value?.retailer?.name)
    const retailer = computed(() => offer.value?.retailer)

    const logo = computed(() => {
      const defaultImage = isBupaTemplate.value
        ? ProviderDefaultUrlMap.bupa
        : ''

      return {
        image: offer.value.retailer?.logo || defaultImage,
        alt: offer.value.retailer?.name,
      }
    })

    const backRoute = computed(() => ({ name: 'home' }))

    async function init() {
      offer.value = await fetchOffer(props.id)

      loading.value = false
    }

    init()

    return {
      logo,
      offer,
      loading,
      retailer,
      backRoute,
      retailerName,
    }
  },
}
</script>

<template>
  <div class="mr-6 min-h-screen rounded-t-3xl bg-slate-100">
    <ew-details-layout
      :loading="loading"
      :ready="!loading"
      :title="retailerName"
      :back="backRoute"
    >
      <template #gallery>
        <retailer-logo v-if="retailer" v-bind="logo" class="mt-5 sm:mt-0" />
      </template>

      <template #summary>
        <offers-title v-if="retailer" :offer="offer" />
      </template>

      <template #details>
        <div v-if="retailer">
          <offer-details :retailer="retailer" :offer="offer" class="mb-10" />
        </div>
      </template>
    </ew-details-layout>
  </div>
</template>
