<script setup lang="ts">
import CheckoutCoupons from '/~/components/checkout/checkout-coupons.default.vue'
import CheckoutCouponsQuickBuy from '/~/components/checkout/checkout-coupons.quick-buy.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useProvider } from '/~/composables/provider'

withDefaults(
  defineProps<{
    flowType?: FlowType
  }>(),
  {
    flowType: FlowType.purchase,
  }
)

const { isPromotionCodeEnabled } = useProvider()
</script>

<template>
  <checkout-coupons-quick-buy
    v-if="isPromotionCodeEnabled && flowType === FlowType.quickBuy"
  />
  <checkout-coupons v-else-if="isPromotionCodeEnabled" />
</template>
