import dayjs from 'dayjs'
import { computed, ref } from 'vue'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'
import { useUser } from '/~/composables/user'

type PadInitPayload = {
  accountName: string
  accountNumber: string
  bsb:
    | string
    | {
        transitNumber: string
        institutionNumber: string
      }
  termsAndConditions: boolean
}

type PadDictionary = {
  date: string
  type: 'Business'
  account_holder: string
  email: string
  transit: string
  institution: string
  account: string
}

export function usePadAgreement() {
  const { padAgreementContent, padAgreementEnabled } = useCms()
  const localization = useLocalization()
  const { user } = useUser()

  const padDictionary = ref<PadDictionary>()

  const isPadEnabled = computed(() => Boolean(padAgreementEnabled.value))
  const padTemplate = computed(() => padAgreementContent.value ?? '')

  function onPadInit(payload: PadInitPayload) {
    const getTransitNumber = (bsb: PadInitPayload['bsb']) => {
      if (!bsb) return ' - '

      if (typeof bsb === 'string') return bsb.substring(3)

      return bsb.transitNumber
    }

    const getInstitutionNumber = (bsb: PadInitPayload['bsb']) => {
      if (!bsb) return ' - '

      if (typeof bsb === 'string') return bsb.substring(0, 3)

      return bsb.institutionNumber
    }

    const format = localization.getDatePickerFormat('daymonthyearnumeric')

    padDictionary.value = {
      date: dayjs().format(format),
      type: 'Business',
      account_holder: payload.accountName,
      email: user.value.email ?? ' - ',
      transit: getTransitNumber(payload.bsb),
      institution: getInstitutionNumber(payload.bsb),
      account: payload.accountNumber ?? ' - ',
    }
  }

  return {
    isPadEnabled,
    padTemplate,
    padDictionary,
    onPadInit,
  }
}
