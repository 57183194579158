<script>
import Vue, { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import { useCompetitions } from '/~/extensions/competitions/composables'
import { useVerifications } from '/~/extensions/otp/composables'
import BaseButton from '/~/components/base/button/base-button'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import CompetitionLogo from './components/competition-logo.vue'
import CompetitionSummary from './components/competition-summary.vue'

export default {
  name: 'competition',
  components: {
    BaseButton,
    EwDetailsLayout,
    CompetitionLogo,
    CompetitionSummary,
  },
  setup() {
    const route = useRoute()
    const { fetchCompetition, routeState } = useCompetitions()
    const { withVerificationsCheck } = useVerifications()

    const loading = ref(false)
    const competition = ref({})

    const backRoute = computed(() => ({
      name: 'competitions',
      ...routeState.value,
    }))

    const loadData = (id) => {
      loading.value = true

      fetchCompetition(id)
        .then((response) => {
          const [error, data] = response

          if (error) {
            Vue.notify({
              text: error.message || 'Something went wrong, please try again',
              type: 'error',
              duration: 5000,
            })
            emitter.emit('router:replace', backRoute)
          } else {
            competition.value = data
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    loadData(route.params.id)

    watch(() => route.params.id, loadData)

    function showQuestionModal() {
      bottomSheet.show('question', {
        to: 'modals-portal',
        props: {
          competition: competition.value,
        },
      })
    }

    return {
      loading,
      competition,
      backRoute,
      showQuestionModal,
      withVerificationsCheck,
    }
  },
}
</script>

<template>
  <div class="flex min-h-full flex-col px-0 pb-[5px] sm:px-5 sm:pt-5">
    <ew-details-layout
      :back="backRoute"
      :loading="loading"
      :ready="!loading"
      :title="competition.title"
      :class="{
        'px-5': loading,
      }"
      :placeholder-ratio="4 / 3"
    >
      <template #gallery>
        <competition-logo
          :competition="competition"
          :loading="loading"
          class="pb-[30px] sm:pb-0"
        />
      </template>

      <template #summary>
        <competition-summary
          :competition="competition"
          class="pb-[30px] sm:pb-0"
        />
      </template>
    </ew-details-layout>

    <template v-if="competition.isActive">
      <div class="flex grow flex-col justify-end px-5 pb-2.5 sm:hidden">
        <base-button
          class="w-full"
          @click="withVerificationsCheck(showQuestionModal)"
        >
          Submit
        </base-button>
      </div>
    </template>
  </div>
</template>
