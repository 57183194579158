import type { Plugin } from 'chart.js'

const getOrCreateLegendList = (id: string) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer?.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.className = 'mt-4 space-x-3 flex'
    legendContainer?.appendChild(listContainer)
  }

  return listContainer
}

export const htmlLegendPlugin: Plugin<'line'> = {
  id: 'htmlLegend',
  afterUpdate(chart, _, options) {
    const ul = getOrCreateLegendList(options.containerID)

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove()
    }

    if (chart.data.labels && chart.data.labels.length <= 1) {
      return
    }

    // Reuse the built-in legendItems generator
    const items =
      chart.options.plugins?.legend?.labels?.generateLabels?.(chart) ?? []

    items.forEach((item) => {
      const li = document.createElement('li')

      li.className = 'flex h-6 cursor-pointer items-center rounded px-3'
      if (item.hidden) {
        li.classList.remove('bg-eonx-neutral-50')
      } else {
        li.classList.add('bg-eonx-neutral-50')
      }

      li.onclick = () => {
        if (item.index !== undefined) {
          if (chart.isDatasetVisible(item.index)) {
            chart.hide(item.index)
          } else {
            chart.show(item.index)
          }
        }
        chart.update()
      }

      // Color box
      const boxDiv = document.createElement('div')

      boxDiv.className =
        'relative mr-3 flex w-6 items-center justify-center border-y'
      if (item.index === 1) {
        boxDiv.classList.add('border-dashed')
      }
      boxDiv.style.borderColor = item.strokeStyle as string
      boxDiv.style.height = '0'
      boxDiv.style.color = item.strokeStyle as string

      const dotDiv = document.createElement('div')

      dotDiv.className =
        'box-content h-1 w-1 rounded-full border-3 border-current bg-current'
      boxDiv.appendChild(dotDiv)

      // Text
      const textContainer = document.createElement('p')

      textContainer.style.color = item.fontColor as string
      textContainer.className = 'text-sm font-bold'
      textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

      const text = document.createTextNode(item.text)

      textContainer.appendChild(text)

      li.appendChild(boxDiv)
      li.appendChild(textContainer)
      ul.appendChild(li)
    })
  },
}
