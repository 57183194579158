<script setup lang="ts">
/*global Kyc*/
import { computed, ref } from 'vue'
import { formatPhone } from '/~/utils/format/string'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export type VerificationFieldType = 'mobile' | 'email' | 'document'

export interface VerificationFieldProps {
  type?: VerificationFieldType
  value?: string
  status?: Kyc.VerificationSessionStatus
  hasError?: boolean
  isVerified: boolean
  isLoading: boolean
  doVerification: (type: VerificationFieldType) => Promise<void>
  doVerificationCancel?: () => Promise<void> | void
}

const props = withDefaults(defineProps<VerificationFieldProps>(), {
  hasError: false,
  type: 'mobile',
  doVerificationCancel: () => {},
})

const requesting = ref<boolean>(false)
const deleting = ref<boolean>(false)

const formattedValue = computed(() =>
  isMobileType.value ? formatPhone(props.value) : props.value
)
const isMobileType = computed(() => props.type === 'mobile')
const isEmailType = computed(() => props.type === 'email')
const isDocumentType = computed(() => props.type === 'document')

const isProcessing = computed(
  () => isDocumentType.value && props.status === 'processing'
)
// pending is BronID only status
const isPending = computed(() => props.status === 'pending')
const isRequiresInput = computed(
  () =>
    isDocumentType.value &&
    (props.status === 'requires_input' || isPending.value)
)
const isCanceled = computed(
  () => isDocumentType.value && props.status === 'canceled'
)
const isVerified = computed(
  () => props.isVerified || props.status === 'verified'
)
const label = computed(() => {
  if (isMobileType.value) return 'Mobile'
  if (isEmailType.value) return 'Email'
  return 'Identity'
})

const statusText = computed(() => {
  if (isDocumentType.value) {
    if (props.hasError) {
      return 'Action required - verification failed'
    }

    if (isVerified.value) {
      return `${label.value} verified`
    }

    switch (props.status) {
      case 'processing':
        return 'Verification pending'

      case 'canceled':
        return 'Action required - verification failed'

      case 'requires_input':
        return 'Action required - continue verification'

      default:
        return `Action required - verify ${label.value.toLowerCase()}`
    }
  }

  return isVerified.value
    ? `${label.value} verified`
    : `Action required - verify ${label.value.toLowerCase()}`
})

const statusIcon = computed(() => {
  if (isProcessing.value) {
    return 'heroicons/solid/clock'
  }

  return isVerified.value
    ? 'heroicons/solid/check-circle'
    : 'heroicons/solid/exclamation-circle'
})

const statusColor = computed(() => {
  if (props.isLoading) {
    return 'text-gray-300'
  }
  if (isProcessing.value) {
    return 'text-blue-700'
  }
  return isVerified.value ? 'text-emerald-700' : 'text-red-700'
})

const showVerifyButton = computed(() => {
  if (isProcessing.value || isRequiresInput.value || isCanceled.value)
    return false

  return !isVerified.value
})

const profileEditRoute = computed(() => {
  return { hash: '#profile-edit-profile' }
})

const onRequest = async function () {
  try {
    requesting.value = true
    await props.doVerification(props.type)
  } catch (error) {
    console.error('otp', error)
  } finally {
    requesting.value = false
  }
}

const onDelete = async function () {
  try {
    deleting.value = true
    await props.doVerificationCancel()
  } catch (error) {
    console.error('otp', error)
  } finally {
    deleting.value = false
  }
}
</script>

<template>
  <div class="space-y-4">
    <div class="space-y-1" :class="statusColor">
      <div>
        {{ label }}
      </div>
      <div
        class="flex h-11 items-center space-x-3 rounded-md border border-current px-3"
      >
        <input
          :value="formattedValue"
          readonly
          class="w-full text-neutral-600 !opacity-100"
        />
        <base-loader v-if="isLoading" size="xs" />
        <base-icon v-else :svg="statusIcon" :size="24" class="flex-shrink-0" />
      </div>
      <div class="text-sm">
        {{ statusText }}
      </div>
    </div>

    <base-button
      v-if="showVerifyButton"
      look="filled"
      class="!h-10 w-full"
      :loading="requesting"
      @click="onRequest()"
    >
      Verify {{ label.toLowerCase() }}
    </base-button>

    <div v-if="isRequiresInput || isCanceled" class="space-y-2">
      <base-button
        v-if="!hasError"
        :loading="requesting"
        look="filled"
        class="!h-10 w-full"
        :disabled="deleting"
        @click="onRequest"
      >
        Resume verification
      </base-button>
      <base-button
        :loading="deleting"
        look="outlined"
        class="!h-10 w-full"
        :disabled="requesting"
        @click="onDelete"
      >
        Delete verification
      </base-button>
    </div>

    <div v-if="!isDocumentType">
      <router-link
        :to="profileEditRoute"
        class="text-sm font-bold text-primary hover:opacity-80"
      >
        Update {{ label.toLowerCase() }}
      </router-link>
    </div>
  </div>
</template>
