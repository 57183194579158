<script>
import DateCard from './date-card.vue'
import DateLine from './date-line.vue'

export default {
  name: 'base-date',
  components: { DateCard, DateLine },
  props: {
    look: {
      type: String,
      default: 'line',
      validator: (v) => !v || /line|card/.test(v),
    },
  },
}
</script>

<template>
  <component :is="`date-${look}`" v-bind="$attrs" v-on="$listeners" />
</template>
