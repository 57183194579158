<script>
import { nextTick } from 'vue'
import CommentInput from './comment-input.vue'
import Comments from './comments.vue'

export default {
  name: 'rec-post-comments',
  components: {
    Comments,
    CommentInput,
  },
  inject: ['post'],
  data() {
    return {
      reply: null,
    }
  },
  methods: {
    async onCommentCreated() {
      await this.post.comments.refresh()
      this.post.totalComments++
    },
    onReply(reply) {
      this.reply = reply

      nextTick(() => {
        this.$refs.replies.scrollToInput()
      })
    },
  },
}
</script>

<template>
  <div class="pb-5">
    <div class="ml-[15px] mr-[30px] flex flex-1 flex-row md:mx-[30px]">
      <comment-input show-avatar @success="onCommentCreated" />
    </div>

    <comments :processor="post.comments" :reply="reply" @reply="onReply">
      <template #replies="{ processor }">
        <comments
          ref="replies"
          :processor="processor"
          :reply="reply"
          :show-reply="false"
          replies
          @reply="onReply"
        />
      </template>
    </comments>
  </div>
</template>
