<script>
import NabVerificationCancelForm from '../form/nab-verification-cancel-form.vue'

export default {
  name: 'nab-verification-cancel-bottom-sheet',
  components: {
    NabVerificationCancelForm,
  },
  setup(_, { emit }) {
    function onSubmit() {
      emit('hide')
      emit('submit')
    }

    return {
      onSubmit,
    }
  },
}
</script>

<template>
  <div>
    <h3 class="mb-[15px] px-[30px] py-6">Cancel a payment?</h3>

    <nab-verification-cancel-form
      class="px-[30px] pb-6"
      @submit="onSubmit"
      @cancel="$emit('hide')"
    />
  </div>
</template>
