<script>
import EpurchaseTile from '/~/templates/australiapost/components/drawer/components/tiles/drawer-e-purchases-tile.vue'
import DefaultArchivedPurchases from '/~/components/drawer/views/e-purchases/drawer-e-purchases-archived.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    EpurchaseTile,
  },
  extends: DefaultArchivedPurchases,
})
</script>
