export default class StaticProcessor {
  loaded = false
  processing = false

  constructor(params) {
    this.params = params || {}
    this.filter = params?.filter
    this.reset()
  }

  reset() {
    this.processing = true
    this.filterQuery = ''
    this.data = []
    this.page = 0
    this.perPage = this.params.perPage || 20
  }

  get hits() {
    const { filter, filterQuery } = this

    return this.data
      .filter((item) => {
        if (filter instanceof Function || filterQuery) {
          return filter(item, filterQuery)
        }
        return item
      })
      .slice(0, this.perPage * (this.page + 1))
  }

  get total() {
    return this.data.length
  }

  get length() {
    return this.hits.length
  }

  get isReady() {
    return !this.processing
  }

  get isEmpty() {
    return !this.processing && this.length === 0
  }

  get isLoading() {
    return this.processing && this.length === 0
  }

  get isRefreshing() {
    return this.loaded && this.processing
  }

  get allRecordsLoaded() {
    return this.loaded && (this.total === 0 || this.total === this.length)
  }

  setData(data) {
    this.data = data
    this.processing = false
    this.loaded = true
  }

  clear() {
    this.data = []
    this.processing = false
  }

  setFilterQuery(query) {
    this.filterQuery = query
  }

  next() {
    this.page += 1
  }
}
