<script>
import cloneDeep from 'lodash-es/cloneDeep'
import { ValidationObserver } from 'vee-validate'
import Vue, { ref, reactive, watch } from 'vue'
import { LoyaltyCard } from '/~/core/loyalty-card'
import BaseButton from '/~/components/base/button/base-button'
import BaseCard from '/~/components/base/card/base-card.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useLoyaltyCards } from '/~/composables/loyalty-cards'
import ImageDrop from './image-loader.vue'

export default {
  name: 'loyalty-cards-form',
  components: {
    BaseCard,
    BaseImage,
    BaseInput,
    BaseButton,
    BaseIcon,
    ImageDrop,
    ValidationObserver,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    card: {
      type: Object,
      required: true,
    },
  },
  emits: ['updated'],
  setup(props, { emit }) {
    const localCard = reactive(new LoyaltyCard(cloneDeep(props.card.raw)))
    const {
      loyaltyCards,
      createLoyaltyCard,
      updateLoyaltyCard,
      loyaltyCardColors,
      getLoyaltyCards,
    } = useLoyaltyCards()
    const { backendErrors, processBackendErrors } = useBackendValidation()

    const isSubmitting = ref(false)
    const frontImage = ref(localCard.frontImage.src)
    const backImage = ref(localCard.backImage.src)
    const form = ref(null)

    function onFrontChange(image) {
      frontImage.value = image
        ? `/2.0/me/uploads/proxy/${image.uuid}/${image.cdnUrlModifiers}`
        : ''
      localCard.raw.front_image = image
        ? `uploadcare://${image.uuid}/${image.cdnUrlModifiers}`
        : ''
      localCard.raw.front_image_modifiers = image ? image.cdnUrlModifiers : ''
    }

    function onBackChange(image) {
      backImage.value = image
        ? `/2.0/me/uploads/proxy/${image.uuid}/${image.cdnUrlModifiers}`
        : ''
      localCard.raw.back_image = image
        ? `uploadcare://${image.uuid}/${image.cdnUrlModifiers}`
        : ''
      localCard.raw.back_image_modifiers = image ? image.cdnUrlModifiers : ''
    }

    function onColorChange(color) {
      localCard.raw.color = color
    }

    async function onSubmit() {
      const isValid = await form.value.validate()

      if (!isValid) return

      isSubmitting.value = true

      const { raw, isCatalog } = localCard
      const requestData = {
        code: raw.code || '',
        front_image: raw.front_image || '',
        back_image: raw.back_image || '',
        front_image_modifiers: raw.front_image_modifiers || '',
        back_image_modifiers: raw.back_image_modifiers || '',
        name: raw.name || '',
      }

      if (isCatalog) {
        requestData.slug = raw.slug
        requestData.logo = raw.logo
      } else {
        requestData.color = raw.color || ''
        requestData.catalogue_card_slug = raw.catalogue_card_slug
      }

      try {
        let response

        if (props.edit) {
          response = await updateLoyaltyCard(localCard.id, requestData)

          Vue.notify({
            text: 'Your card successfully updated',
            type: 'success',
            duration: 5000,
          })
        } else {
          response = await createLoyaltyCard(requestData)

          Vue.notify({
            text: 'New card has been added',
            type: 'success',
            duration: 5000,
          })
        }

        emit('updated', response.external_id)

        await getLoyaltyCards()
      } catch (error) {
        processBackendErrors({ errors: error })
        Vue.notify({
          text: error.message || 'Failed to edit card',
          type: 'error',
          duration: 5000,
        })
      } finally {
        isSubmitting.value = false
      }
    }

    watch(
      () => props.card,
      async () => {
        Object.assign(localCard, new LoyaltyCard(cloneDeep(props.card.raw)))
      }
    )

    return {
      loyaltyCards,
      isSubmitting,
      localCard,
      loyaltyCardColors,
      frontImage,
      backImage,
      onFrontChange,
      onBackChange,
      createLoyaltyCard,
      updateLoyaltyCard,
      onColorChange,
      backendErrors,
      processBackendErrors,
      form,
      onSubmit,
    }
  },
}
</script>

<template>
  <validation-observer
    ref="form"
    v-slot="{ handleSubmit }"
    tag="form"
    class="flex min-h-full flex-col items-center justify-start"
    :disabled="isSubmitting"
    @submit.prevent="onSubmit"
    @keydown.enter="onSubmit"
  >
    <div class="text-md relative mx-auto w-full max-w-xs px-[15px]">
      <base-card v-if="localCard.isCatalog" class="overflow-hidden rounded-xl">
        <base-image v-bind="localCard.image" :alt="localCard.name" />
      </base-card>
      <base-card
        v-else
        class="shrink-0 overflow-hidden rounded-xl"
        :style="{
          backgroundColor: localCard.color,
        }"
      >
        <div
          class="flex h-full flex-col justify-center p-6 font-bold text-white"
        >
          <base-icon size="3xl" svg="circle/loyalty-cards" />
          <div class="mt-5 mb-2.5 text-xl">
            {{ localCard.name }}
          </div>
          <div class="h-6">
            {{ localCard.barcode }}
          </div>
        </div>
      </base-card>
    </div>
    <div
      v-if="!localCard.isCatalog"
      class="flex w-full justify-around px-2.5 pt-[30px]"
    >
      <button
        v-for="color in loyaltyCardColors"
        :key="color.value"
        type="button"
        :style="{ backgroundColor: color.value }"
        class="block h-[35px] w-[35px] shrink-0 cursor-pointer rounded-full border-4 border-solid border-white disabled:opacity-50"
        :class="[color.value === localCard.raw.color && 'border-primary']"
        :aria-label="`${color.name} color`"
        :disabled="isSubmitting"
        @click="onColorChange(color.value)"
      />
    </div>

    <div class="mt-10 w-full">
      <base-input
        v-if="!localCard.isCatalog"
        v-model="localCard.raw.name"
        :validation="{
          rules: 'required',
          name: 'Brand Name',
        }"
        :error="backendErrors.name"
        :disabled="isSubmitting"
        label="Brand Name"
        required
        name="name"
      />

      <base-input
        v-model="localCard.raw.code"
        :validation="{
          rules: 'required|min:1|max:25|alpha_num',
          name: 'Card Number',
        }"
        :error="backendErrors.code"
        :disabled="isSubmitting"
        :mask="{ mask: /^[\d\w]{1,25}$/ }"
        label="Card Number"
        required
        name="code"
      />
    </div>

    <div class="flex w-full flex-col">
      <div class="mb-2.5 w-full">Additional Photos</div>
      <div class="flex w-full justify-between space-x-5">
        <image-drop
          label="Front Image"
          :image="frontImage"
          :disabled="isSubmitting"
          removable
          @upload="onFrontChange"
        />

        <image-drop
          label="Back Image"
          :image="backImage"
          :disabled="isSubmitting"
          removable
          @upload="onBackChange"
        />
      </div>
    </div>

    <div class="mt-auto w-full pt-10">
      <base-button
        :loading="isSubmitting"
        full-width
        @click="handleSubmit(onSubmit)"
      >
        Save
      </base-button>
    </div>
  </validation-observer>
</template>
