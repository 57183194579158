<script>
import EntryMessage from './audio/audio-message-entry.vue'
import EntryPost from './audio/audio-post-entry.vue'
import EntryComment from './audio/audio-comment-entry.vue'

export default {
  name: 'rec-audio-entry',
  props: {
    type: {
      type: String,
      required: true,
    },
    attachments: {
      type: Object,
      required: true,
    },
    contentColor: {
      type: String,
      default: 'text-black',
    },
    buttonColor: {
      type: String,
      default: 'text-primary',
    },
    samples: {
      type: Number,
      default: 20,
    },
  },
  computed: {
    entryComponent() {
      if (this.type === 'message') return EntryMessage
      else if (this.type === 'post') return EntryPost
      else if (this.type === 'comment') return EntryComment
      return null
    },
  },
}
</script>

<template>
  <component
    :is="entryComponent"
    v-if="entryComponent"
    :samples="samples"
    :attachments="attachments"
    :content-color="contentColor"
    :button-color="buttonColor"
  />
</template>
