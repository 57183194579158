<script setup lang="ts">
import { ValidationObserver } from 'vee-validate'
import { ref, watch, computed } from 'vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import {
  type ThirdPartyUserNew,
  type ThirdPartyUser,
} from '/~/composables/third-party-users'

const props = withDefaults(
  defineProps<{
    onInvite?: (user: ThirdPartyUserNew) => Promise<void>
    onUpdate?: (user: Partial<ThirdPartyUser>) => Promise<void>
    onBack?: () => void
    onTerms?: () => void
    submitting?: boolean
    backendErrors?: Record<string, string>
    user?: ThirdPartyUser
  }>(),
  {
    onBack: () => {},
    onTerms: () => {},
  }
)

const formFields = computed(
  (): Array<{
    name: keyof ThirdPartyUserNew
    required: boolean
    label: string
    placeholder: string
    mask?: string
    validation?: {
      rules: string
    }
    disabled?: boolean
  }> => {
    return [
      {
        name: 'firstName',
        required: true,
        label: 'First name',
        placeholder: 'First name',
        validation: {
          rules: 'required',
        },
      },
      {
        name: 'lastName',
        required: true,
        label: 'Last name',
        placeholder: 'Last name',
        validation: {
          rules: 'required',
        },
      },
      {
        name: 'mobile',
        required: true,
        label: 'Mobile',
        placeholder: 'Mobile',
        mask: 'mobile',
        validation: {
          rules: 'required|mobile',
        },
      },
      {
        name: 'email',
        required: true,
        label: 'Email',
        placeholder: 'Email',
        validation: {
          rules: 'required|email',
        },
        disabled: Boolean(props.user),
      },
      {
        name: 'nickname',
        label: 'Nickname',
        placeholder: 'Nickname',
        required: true,
        validation: {
          rules: 'required',
        },
      },
    ]
  }
)

const tacChecked = ref(false)

const user = ref<ThirdPartyUserNew>({
  firstName: '',
  lastName: '',
  nickname: '',
  mobile: '',
  email: '',
})

const setUser = (userData: ThirdPartyUser) => {
  user.value = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    nickname: userData.nickname,
    mobile: userData.mobile,
    email: userData.email,
  }
}

const resetUser = () => {
  user.value = {
    firstName: '',
    lastName: '',
    nickname: '',
    mobile: '',
    email: '',
  }
}

const handleInvite = async () => {
  if (!props.user) {
    await props.onInvite?.(user.value)
  } else {
    await props.onUpdate?.(user.value)
  }
}

const handleBack = () => {
  props.onBack?.()
}

const setTac = (tacValue: boolean) => {
  tacChecked.value = tacValue
}

if (props.user) {
  setUser(props.user)
}

watch(
  () => props.user,
  (user) => {
    if (user) {
      setUser(user)
    } else {
      resetUser()
    }
  }
)

defineExpose({
  setTac,
  tacChecked,
})
</script>

<template>
  <base-aside-page
    :title="props.user ? 'Edit user' : 'Add user'"
    :back="handleBack"
    no-padding
  >
    <div class="relative h-full" data-testid="body">
      <validation-observer
        v-slot="{ valid, handleSubmit }"
        ref="validationObserverRef"
        slim
        tag="div"
        class="flex h-full flex-col space-y-7"
      >
        <div v-if="!props.user" class="px-5 pt-5">
          <h4 class="mb-4 pb-0.5 pt-0.5 text-xl font-bold">
            Adding an authorised user
          </h4>
          <ul
            class="ml-0.5 list-decimal space-y-3 pl-5 marker:mr-1 marker:block"
          >
            <li class="pl-0.5">
              Input information for the trusted individual you wish to grant
              full platform access to, such as your accountant or business
              partner.
            </li>
            <li class="pl-0.5">
              Send invite & the user will promptly receive an email invitation
              to join the platform.
            </li>
            <li class="pl-0.5">
              You can deactivate a users access whenever needed via 'User
              permissions'
            </li>
          </ul>
        </div>

        <form
          data-testid="add-user-form"
          class="flex h-full flex-col"
          @submit.prevent="handleSubmit(handleInvite)"
        >
          <div class="flex-1 px-5">
            <h4 v-if="!props.user" class="mb-6 pb-2 pt-0.5 text-xl font-bold">
              New user details
            </h4>
            <base-input
              v-for="(field, i) in formFields"
              :key="field.name"
              v-model="user[field.name]"
              :class="{
                '!mt-9': i > 0,
              }"
              :error="backendErrors && backendErrors[field.name]"
              :validation="field.validation"
              :name="field.name"
              :disabled="submitting || field.disabled"
              :required="field.required"
              :label="field.label"
              :mask="field.mask"
              :placeholder="field.placeholder"
              entry-class="h-12 rounded-sm"
            />
          </div>

          <div
            class="sticky bottom-0 left-0 right-0 w-full border-t border-eonx-neutral-200 bg-white p-5"
          >
            <div
              class="mb-5 flex cursor-pointer items-center space-x-[5px] hover:opacity-80"
            >
              <base-checkbox
                v-model="tacChecked"
                :disabled="submitting"
                look="v2"
              >
                <span class="font-normal text-default">
                  I agree to
                  <base-action
                    class="font-semibold text-primary underline"
                    type="link"
                    :click-only="true"
                    @click="onTerms"
                  >
                    Terms & Conditions
                  </base-action>
                </span>
              </base-checkbox>
            </div>
            <div class="grid grid-cols-2 gap-3">
              <base-button
                look="outlined-color"
                class="w-full"
                data-testid="button-cancel"
                @click="onBack"
              >
                Cancel
              </base-button>
              <base-button
                class="w-full"
                :disabled="!valid || !tacChecked"
                :loading="submitting"
                data-testid="button-submit"
                type="submit"
              >
                <span class="font-normal">
                  {{ props.user ? 'Save' : 'Send Invite' }}
                </span>
              </base-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </base-aside-page>
</template>
