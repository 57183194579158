<script>
import Vue, { reactive, ref } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { cardCode } from '/~/utils/cards'
import { formatDollar } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCheckout } from '/~/composables/checkout'
import {
  getExpiryDate,
  usePaymentMethods,
} from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'

export default {
  name: 'payments-credit-card-item',
  components: {
    BaseIcon,
    BaseLoader,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    splitEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      payWithCard,
      payWithBankAccount,
      payWithEwallet,
      payWithPoints,
      selectedPoints,
      selectedCard,
      selectedEwallet,
      selectedBankAccount,
      selectedAmount,
      getTransactionFees,
      transactionFees,
      subTotal,
      loadingFees,
      payment,
      isCreditCardsDisabled,
    } = useCheckout()
    const { initiatingAccountsIds, deletePaymentMethod } = usePaymentMethods()
    const { isCreditCardsNavEnabled } = useProvider()
    const { burnPointsRateOrder } = usePoints()
    const { isAnyMenuVisible, drawerModalsTargetName } = useUI()

    const loading = ref(false)

    const expiryDate = reactive(getExpiryDate(props.item))

    async function onRemove() {
      if (loading.value) {
        return
      }

      loading.value = true
      try {
        await deletePaymentMethod(props.item.id)
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong while deleting. Please try again.',
        })
      } finally {
        loading.value = false
      }
    }

    return {
      bottomSheet,
      formatDollar,
      payWithCard,
      payWithBankAccount,
      payWithEwallet,
      payWithPoints,
      selectedPoints,
      selectedCard,
      selectedEwallet,
      selectedBankAccount,
      isCreditCardsNavEnabled,
      selectedAmount,
      getTransactionFees,
      transactionFees,
      subTotal,
      loadingFees,
      burnPointsRateOrder,
      isAnyMenuVisible,
      drawerModalsTargetName,
      initiatingAccountsIds,
      payment,
      loading,
      onRemove,
      isCreditCardsDisabled,
      modal,
      ui,
      expiryDate,
    }
  },
  data: () => ({
    verifying: false,
  }),
  computed: {
    icon() {
      if (this.expiryDate.isExpired) {
        return 'v2/heroic/x'
      }
      if (this.isSelected) {
        return 'v2/heroic/check'
      }
      return `cards/${this.cardCode}`
    },
    isSelected() {
      return this.selectedCard?.id === this.item.id && this.payWithCard
    },
    initializing() {
      return this.initiatingAccountsIds.includes(this.item.id)
    },
    isUnselectable() {
      if (this.payment.submitting || this.isNotAllowed) {
        return true
      }

      return this.remainingTotal === 0
    },
    remainingTotal() {
      return Math.max(
        this.payment.subTotalWithProgramFeesNoCoupons -
          this.payWithEwallet -
          this.payWithPoints,
        0
      )
    },
    status() {
      return (this.item.status ?? '').toLowerCase()
    },
    state() {
      return (this.item.state ?? '').toLowerCase()
    },
    cardCode() {
      return cardCode(this.item)
    },
    isVerified() {
      return this.status === 'verified' || !this.isCreditCardsNavEnabled
    },
    isNotAllowed() {
      return this.state === 'restricted'
    },
    isBlocked() {
      return this.status === 'blocked' || this.status === 'locked'
    },
    methodFees() {
      if (this.loadingFees) {
        return 0
      } else {
        return this.transactionFees
      }
      // return this.method.fee ?? this.selectedMethod.fee ?? 0
    },
    cardStyle() {
      if (this.expiryDate.isExpired) {
        return { 'box-shadow': '0px 0px 0px 3px #EF4444' }
      }
      if (this.isSelected) {
        return { 'box-shadow': '0px 0px 0px 3px #10B981' }
      }
      return null
    },
  },
  methods: {
    async onClick() {
      if (this.isCreditCardsDisabled) {
        if (this.isSelected) {
          this.selectedCard = null
        }
        return
      }

      if (
        this.expiryDate.isExpired ||
        this.isBlocked ||
        this.isUnselectable ||
        this.initializing ||
        this.isNotAllowed
      )
        return

      if (!this.isVerified) {
        if (!this.isAnyMenuVisible) {
          await this.$router.push({
            hash: '#profile-payment-methods',
            query: this.$route.query,
          })
        }

        bottomSheet.show('method-verify', {
          to: this.drawerModalsTargetName,
          props: {
            method: this.item,
          },
        })
      } else {
        if (!this.splitEnabled || !this.selectedBankAccount) {
          this.selectedCard = this.isSelected
            ? null
            : {
                ...this.item,
                calculatedAmount: this.remainingTotal,
              }

          this.getTransactionFees()
        } else {
          const splitModalProps = {
            currentItem: this.item,
            selectedPoints: this.selectedPoints,
            burnPointsRate: this.burnPointsRateOrder,
            selectedEwallet: this.selectedEwallet,
            selectedCard: this.selectedCard,
            selectedBankAccount: this.selectedBankAccount,
            selectedAmount: this.selectedAmount,
            total: this.remainingTotal,
            onConfirm: this.onConfirm,
            onCancel: this.onCancel,
          }

          if (ui.mobile) {
            this.bottomSheet.show('payments-split', {
              to: this.to,
              props: splitModalProps,
            })
          } else {
            modal.show('payments-split-modal', {
              props: splitModalProps,
            })
          }
        }
      }
    },
    onConfirm({
      pointsToUse,
      pointsAmount,
      cardAmount,
      bankAccountAmount,
      ewalletAmount,
    }) {
      if (pointsToUse > 0) {
        this.selectedPoints = {
          ...this.selectedPoints,
          usePoints: pointsToUse,
          calculatedAmount: pointsAmount,
        }
      } else {
        this.selectedPoints = null
      }

      if (ewalletAmount > 0) {
        this.selectedEwallet = {
          ...this.selectedEwallet,
          calculatedAmount: ewalletAmount,
        }
      } else {
        this.selectedEwallet = null
      }

      if (cardAmount > 0) {
        this.selectedCard = {
          ...this.selectedCard,
          ...(this.item.id !== this.selectedCard?.id && this.item),
          calculatedAmount: cardAmount,
        }
      } else {
        this.selectedCard = null
      }

      if (bankAccountAmount > 0) {
        this.selectedBankAccount = {
          ...this.selectedBankAccount,
          calculatedAmount: bankAccountAmount,
        }
      } else {
        this.selectedBankAccount = null
      }

      this.getTransactionFees()
    },
    onCancel() {
      this.selectedPoints = null
      this.selectedEwallet = null
      this.selectedCard = null
      this.selectedBankAccount = null
      this.getTransactionFees()
    },
  },
}
</script>

<template>
  <div
    class="relative flex flex-nowrap items-center rounded-lg border border-eonx-neutral-200 bg-white p-5"
    :class="[
      isUnselectable ? 'text-neutral-400 opacity-75' : 'cursor-pointer',
      !isVerified && 'text-neutral-400',
      isBlocked && 'cursor-default',
      { 'cursor-default grayscale': isCreditCardsDisabled && !isSelected },
      { 'cursor-default bg-red-700': expiryDate.isExpired },
    ]"
    :style="cardStyle"
    @click="onClick"
  >
    <div
      class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white"
      :class="{
        'bg-red-700': expiryDate.isExpired,
        'bg-emerald-700': isSelected,
      }"
    >
      <base-loader v-if="loading" size="xl" color="white" />
      <base-icon
        v-else
        :svg="icon"
        :size="isSelected ? 24 : 'xl'"
        :style="!isVerified && 'filter:grayscale(1)'"
      />
    </div>
    <div class="ml-[15px] flex flex-col overflow-hidden">
      <span
        class="truncate font-bold"
        :class="!isVerified && 'text-eonx-neutral-600'"
      >
        {{ `${item.name} ${item.brand}` }}
      </span>
      <span
        class="text-sm"
        :class="isVerified ? 'text-eonx-neutral-600' : 'text-eonx-neutral-600'"
      >
        <span>{{ `**** **** **** ${item.number.slice(-4)}` }}</span>
        <span
          v-if="expiryDate.isExpired"
          class="mx-[5px] cursor-pointer border-l px-[5px] text-red-700 hover:opacity-60"
          @click="onRemove"
        >
          Remove
        </span>
      </span>
    </div>
    <div
      v-if="isSelected && !expiryDate.isExpired"
      class="ml-auto shrink-0 text-right sm:text-left"
    >
      <div>- {{ formatDollar(payWithCard + methodFees) }}</div>
      <div v-if="methodFees" class="text-xs text-eonx-neutral-600">
        Processing fee: -{{ formatDollar(methodFees) }}
      </div>
    </div>
    <base-loader v-if="initializing" class="ml-auto shrink-0" size="xs" />
    <div
      v-else-if="isBlocked"
      class="ml-auto shrink-0 text-sm text-eonx-neutral-600"
    >
      ACCOUNT BLOCKED
    </div>
    <div
      v-else-if="isNotAllowed"
      class="absolute top-0 right-0 mt-2 mr-2 whitespace-nowrap rounded-sm border border-orange-700 bg-orange-50 px-2 text-xs font-bold uppercase leading-5 text-orange-700"
    >
      NOT ACCEPTED
    </div>
    <div
      v-else-if="expiryDate.isExpired"
      class="absolute top-0 right-0 mt-2.5 mr-2.5 whitespace-nowrap rounded border-red-700 bg-red-50 px-2.5 text-xs font-bold leading-5 text-red-700"
      :style="{ 'box-shadow': '0px 0px 0px 1px #EF4444' }"
    >
      Expired
    </div>

    <div
      v-else-if="!isVerified"
      class="absolute top-0 right-0 -mt-[5px] -mr-2.5 whitespace-nowrap rounded-l-md bg-orange-400 px-6 text-xs font-bold uppercase leading-5 text-white md:mt-[15px]"
    >
      <span
        class="absolute right-0 inline-block h-0 w-0 border-solid border-orange-400 text-orange-400"
        :style="{
          top: '100%',
          'border-width': '9px 8px 0 0',
          'border-color': 'currentColor transparent transparent transparent',
        }"
      />
      Verify Account
    </div>
  </div>
</template>
