<script>
import Vue, { computed } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerMethodTile from '/~/components/drawer/components/tiles/drawer-method-tile.vue'
import { useCms } from '/~/composables/cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'drawer-bank-tile-v2',
  components: {
    BaseIcon,
    BaseLoader,
    DrawerMethodTile,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    link: {
      type: Boolean,
      default: true,
    },
    inForm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { deleting, deletePaymentMethod, initiatingAccountsIds } =
      usePaymentMethods()
    const { isDarkThemeForEwallet } = useCms()
    const { isBankAccountsNavEnabled } = useProvider()

    const accountNumberEnding = computed(() =>
      props.account.accountNumber.slice(-2)
    )

    function onRemoveClick() {
      bottomSheet.show('confirmation-modal', {
        to: 'menu-modal',
        props: {
          icon: 'points-only/trash',
          title: 'Are you sure you want to delete this Payment Method?',
          text: `By deleting this Payment Method you will remove the account ending in ${accountNumberEnding.value} from your Payment Methods permanently.`,
          confirmButtonText: 'Delete Payment Method',
          onConfirm: onDeleteConfirmationModalConfirm,
        },
      })
    }

    async function onDeleteConfirmationModalConfirm() {
      try {
        await deletePaymentMethod(props.account.id)
      } catch (error) {
        if (!error.data?.message) {
          Vue.notify({
            type: 'error',
            text: 'Something went wrong while deleting. Please try again.',
          })
        }
      }
    }

    const removeButtonLabel = computed(
      () => `Bank account number ${props.account?.accountNumber} remove`
    )

    return {
      initiatingAccountsIds,
      isBankAccountsNavEnabled,
      isDarkThemeForEwallet,
      deleting,
      deletePaymentMethod,
      onRemoveClick,
      removeButtonLabel,
      PaymentMethodType,
    }
  },
  computed: {
    initializing() {
      return this.initiatingAccountsIds.includes(this.account.id)
    },
    accountNumber() {
      const preparedNumber = String(this.account.accountNumber)
        .replace(/\./g, '*')
        .match(/.{0,3}/g)
        .join(' ')
      const firstNumMatch = preparedNumber.match(/\d/)
      let dots = ''
      let digits = ''

      if (firstNumMatch) {
        const firstNumIndex = firstNumMatch.index

        dots = preparedNumber.slice(0, firstNumIndex)
        digits = preparedNumber.slice(firstNumIndex)
      }

      return { dots, digits }
    },
    status() {
      return (this.account?.status ?? '').toLowerCase()
    },
    isVerified() {
      return this.status === 'verified' || !this.isBankAccountsNavEnabled
    },
    isBlocked() {
      return this.status === 'blocked' || this.status === 'locked'
    },
    presentational() {
      const { accountNumber } = this.account
      const expectedLength = 9
      const givenLength = accountNumber.length
      const neededDotsCount = expectedLength - givenLength
      let dots = ''
      const digits = accountNumber
        .match(/.{0,3}/g)
        .join(' ')
        .trim()

      if (neededDotsCount > 0) {
        dots = new Array(neededDotsCount)
          .fill('*')
          .join('')
          .match(/.{0,3}/g)
          .reverse()
          .join(' ')
          .trim()
      }

      return { dots, digits }
    },
    showSeparator() {
      const { accountNumber } = this.account

      return accountNumber.length && accountNumber.length % 3 === 0
    },
    presentation() {
      return {
        type: this.PaymentMethodType.bankAccount,
        accountNumber: this.account.accountNumber,
        name: this.account.accountName,
        bsb: this.account.bsb.replace(/-/, ''),
      }
    },
  },
  methods: {
    verify() {
      if (!this.isVerified && !this.isBlocked && !this.initializing) {
        bottomSheet.show('method-verify', {
          to: 'menu-modal',
          props: {
            method: {
              ...this.account,
              type: this.PaymentMethodType.bankAccount,
            },
          },
        })
      }
    },
  },
}
</script>

<template>
  <div class="relative mx-auto max-w-72">
    <drawer-method-tile
      :method="presentation"
      :class="[
        !isVerified && !inForm && !initializing
          ? 'cursor-pointer'
          : 'cursor-default',
      ]"
      @click="verify"
    />
    <div
      class="absolute top-0 right-0 -mt-2.5 -mr-2.5 flex items-center gap-x-[5px]"
    >
      <button
        v-if="!isVerified"
        type="button"
        class="flex cursor-pointer items-center rounded-full py-[5px] px-2.5 text-white"
        :style="{
          backgroundColor: '#F59E0B',
        }"
        @click="verify"
      >
        <base-loader v-if="initializing" size="sm" />
        <template v-else>
          <base-icon svg="v2/heroic/exclamation" :size="24" class="mr-[5px]" />
          <span class="whitespace-no-wrap font-bold uppercase">
            Verify account
          </span>
        </template>
      </button>
      <button
        type="button"
        class="flex h-10 w-10 items-center justify-center rounded-full border-4 bg-red-700 text-white disabled:bg-gray-300"
        :class="{
          'border-white': !isDarkThemeForEwallet,
          'border-gray-900': isDarkThemeForEwallet,
        }"
        :disabled="deleting"
        :aria-label="removeButtonLabel"
        @click="onRemoveClick()"
      >
        <base-loader v-if="deleting" size="sm" />
        <base-icon v-else svg="v2/heroic/trash" :size="16" />
      </button>
    </div>
  </div>
</template>
