import Vue from 'vue'
import WgtContent from './wgt/wgt-content.vue'
import WidgetsBuilder from './widgets/widgets-builder.vue'

Vue.component('widgets-builder', WidgetsBuilder)
Vue.component('widget-code', () => import('./widgets/widget-code.vue'))
Vue.component(
  'widget-popular',
  () => import('./widgets/widget-popular/widget-popular.vue')
)
Vue.component('wgt-content', WgtContent)
