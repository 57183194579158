<script>
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import WgtBgText from '../wgt/wgt-bg-text.vue'

export default {
  name: 'widget-home-banner',
  components: {
    BaseAction,
    BaseIcon,
    WgtBgText,
  },
  props: {
    bannerTitle: {
      type: Object,
      default: () => ({}),
    },
    titleColor: {
      type: String,
      default: '',
    },
    titleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerSubtitle: {
      type: Object,
      default: () => ({}),
    },
    subtitleColor: {
      type: String,
      default: '',
    },
    subtitleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerContent: {
      type: Object,
      default: () => ({}),
    },
    contentColor: {
      type: String,
      default: '',
    },
    contentBackgroundColor: {
      type: String,
      default: '',
    },
    bannerButton: {
      type: Object,
      default: () => ({}),
    },
    buttonColor: {
      type: String,
      default: '',
    },
    buttonTextColor: {
      type: String,
      default: '',
    },
    rightImage: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    rightImageStyle() {
      return {
        backgroundImage: `url(${cdn(this.rightImage.desktop).preview('1176x736').format('auto').quality('smart').url()}`,
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    },
    infoStyle() {
      return {
        backgroundColor: this.backgroundColor,
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    },
    buttonStyle() {
      return {
        backgroundColor: this.buttonColor,
        color: this.buttonTextColor,
      }
    },
  },
}
</script>
<template>
  <section class="rounded-lg shadow">
    <div class="flex items-stretch">
      <div
        class="w-full rounded-l-lg p-5 sm:w-1/2 md:p-12"
        :class="{
          'rounded-r-lg': ui.mobile,
        }"
        :style="infoStyle"
      >
        <div
          v-if="bannerTitle.text"
          class="leading-tight"
          :class="{
            'pb-[5px]': !ui.mobile,
          }"
          style="font-size: 28px"
        >
          <wgt-bg-text
            :content="bannerTitle.text"
            :text-color="titleColor"
            :background-color="titleBackgroundColor"
          />
        </div>
        <div
          v-if="bannerSubtitle.text"
          class="pb-[15px] font-bold leading-tight"
          :style="ui.mobile ? 'font-size: 32px' : 'font-size: 38px'"
        >
          <wgt-bg-text
            :content="bannerSubtitle.text"
            :text-color="subtitleColor"
            :background-color="subtitleBackgroundColor"
          />
        </div>
        <div
          v-if="bannerContent.text"
          class="leading-snug md:mr-24"
          style="font-size: 22px"
        >
          <wgt-bg-text
            :content="bannerContent.text"
            :text-color="contentColor"
            :background-color="contentBackgroundColor"
          />
        </div>
        <div>
          <base-action
            v-if="bannerButton.enabled"
            v-bind="bannerButton"
            class="inline-block"
            origin="banner"
            appearance="link"
          >
            <button
              type="button"
              class="button mt-[30px] flex h-12 items-center rounded px-5 font-bold"
              :style="buttonStyle"
            >
              <span>
                {{ bannerButton.label }}
              </span>
              <base-icon class="ml-5" svg="plain/chevron-right" size="sm" />
            </button>
          </base-action>
        </div>
      </div>
      <div
        v-if="!ui.mobile && rightImage.desktop"
        class="w-1/2 rounded-r-lg"
        :style="rightImageStyle"
      />
    </div>
  </section>
</template>
