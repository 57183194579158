<script setup lang="ts">
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import modal from '/~/core/mdl'
import VerificationWarning from './verification-warning.vue'

const props = withDefaults(
  defineProps<{
    onClose?: () => void
    onCancel?: () => void
    onSubmit?: () => void
  }>(),
  {
    onClose: () => {},
    onCancel: () => {},
    onSubmit: () => {},
  }
)

function onClose() {
  props.onClose()
}

function onCancel() {
  modal.hide()
  props.onCancel()
}

function onSubmit() {
  modal.hide()
  props.onSubmit()
}
</script>

<template>
  <base-mdl width="sme" :layout="false">
    <verification-warning
      @close="onClose"
      @cancel="onCancel"
      @submit="onSubmit"
    />
  </base-mdl>
</template>
