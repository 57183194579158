<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseSelect from '/~/components/base/select/base-select.vue'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import PaymentsMakeDbOption, { type BankAccount } from './db-option.vue'
import PaymentsMakeSection from '../section.pure.vue'

export interface UpdateEventPayloads {
  type: PaymentMethodType.bankAccount
  id: string
}

export interface PayByDBProps {
  loading: boolean
  bankAccountsList: Array<BankAccount>
  onAddNewPaymentMethod?: () => void
}

const props = defineProps<PayByDBProps>()

const emit = defineEmits<{
  (event: 'update', payloads: UpdateEventPayloads | null): void
}>()

const bankAccount = ref<string>()

const bankAccountsListPrep = computed(() =>
  props.bankAccountsList.map((item) => {
    item.value = item.id
    item.text = `${item.accountName} ${item.accountNumber}`
    return item
  })
)

watch(bankAccount, (id) => {
  if (id) {
    const currentBankAccount = bankAccountsListPrep.value.find(
      (item) => item.id === id
    )

    if (currentBankAccount) {
      const { isBankAccountsNavEnabled } = useProvider()

      if (
        isBankAccountsNavEnabled.value &&
        currentBankAccount.status.toLowerCase() !== 'verified'
      ) {
        bankAccount.value = undefined
      } else {
        emit('update', { type: PaymentMethodType.bankAccount, id })
      }
    } else {
      bankAccount.value = undefined
    }
  } else {
    emit('update', null)
  }
})

watch(
  () => props.bankAccountsList,
  () => {
    bankAccount.value = undefined
  }
)
</script>

<template>
  <payments-make-section label="Pay via Bank Account" :loading="loading">
    <template v-if="onAddNewPaymentMethod" #subHead>
      <base-button size="sm" @click="onAddNewPaymentMethod">
        + Add new
      </base-button>
    </template>

    <base-select
      v-model="bankAccount"
      :disabled="loading"
      :options="bankAccountsListPrep"
      default-option="Select Bank Account to Debit"
      :validation="{
        rules: 'required',
        name: 'Bank Account',
      }"
      class="!pt-0"
      entry-class="rounded-sm h-12 text-eonx-neutral-800 !pl-3"
      select-list-class="divide-y !box-border !py-0 !rounded-sm !max-h-36 sm:!max-h-48"
      custom-select
      fullwidth-popup
      no-label
      :select-first="false"
      :popup-offset="{ top: 8 }"
      placeholder="Select Bank Account to Debit"
      :search="{
        enabled: true,
        allowSpace: true,
        fields: ['accountNumber'],
        maxlength: 32,
      }"
      empty-text="no matching Bank Account"
    >
      <template #selected="{ option }">
        <payments-make-db-option
          :method="option"
          no-padding
          is-option-selected
        />
      </template>

      <template #item="{ option, selected }">
        <payments-make-db-option :method="option" :is-selected="selected" />
      </template>
    </base-select>
  </payments-make-section>
</template>
