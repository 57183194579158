<script>
import { watch, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useGiftcards } from '/~/extensions/giftcards/composables'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import GcTile from '../components/gc-tile.vue'

export default {
  name: 'giftcards-catalog',
  components: {
    EwCatalogProcessor,
    GcTile,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { algolia, routeState, getContent, syncState } = useGiftcards()

    watch(routeState, (value) => {
      router.replace(value)
    })

    watch(
      () => ({ ...route }),
      (to, from) => {
        if (
          to.fullPath.replace(to.hash, '') !==
          from.fullPath.replace(from.hash, '')
        ) {
          syncState({ to, from })
          getContent()
        }
      }
    )

    onBeforeMount(() => {
      syncState({ to: route })
      getContent()
    })

    return {
      algolia,
    }
  },
}
</script>

<template>
  <ew-catalog-processor
    tile-class="hover:-translate-y-[5px]"
    :processor="algolia"
    :columns="{
      xxs: 2,
      sm: 3,
      lg: 4,
    }"
  >
    <template #empty>
      <p>No Result found</p>
      <p>Please try another category</p>
    </template>

    <template #tile="{ tile }">
      <gc-tile :tile="tile" />
    </template>
  </ew-catalog-processor>
</template>
