<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'gallery-navigation',
  components: {
    BaseIcon,
  },
  props: {
    iconSize: {
      type: Number,
      default: 24,
    },
  },
  methods: {
    previous() {
      this.$emit('on-handle-previous')
    },
    next() {
      this.$emit('on-handle-next')
    },
  },
}
</script>

<template>
  <div>
    <button
      class="absolute left-0 top-1/2 mx-5 flex -translate-y-1/2 transform items-center justify-center rounded-full text-white opacity-50 hover:opacity-100"
      @click="previous"
    >
      <base-icon svg="rec/arrow-left" :size="iconSize" />
    </button>
    <button
      class="absolute right-0 top-1/2 mx-5 flex -translate-y-1/2 transform items-center justify-center rounded-full text-white opacity-50 hover:opacity-100"
      @click="next"
    >
      <base-icon svg="rec/arrow-right" :size="iconSize" />
    </button>
  </div>
</template>
