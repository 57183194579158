import { useProvider } from '/~/composables/provider'

/**
 * TODO: Improve typing when imask issue is resolved
 * @link https://github.com/uNmAnNeR/imaskjs/issues/793
 */

interface Mask {
  mask: string
  lazy: boolean
  startsWith: RegExp
  fixedPart?: string
}

interface MaskTemplates {
  [key: string]: {
    mask: Mask[]
    dispatch: typeof defaultDispatch
    clearOnSelectionReplace?: boolean
  }
}

const { isUsProvider, isGbProvider, isCaProvider } = useProvider()

let mobileMask

if (isGbProvider.value) {
  mobileMask = [
    {
      mask: '+44 0000 000000',
      lazy: true,
      startsWith: /^\+/,
    },
    {
      mask: '(\\000) 0000 - 0000',
      lazy: true,
      startsWith: /^/,
    },
  ]
} else if (isUsProvider.value || isCaProvider.value) {
  mobileMask = [
    {
      mask: '(000) 000 - 0000',
      lazy: true,
      startsWith: /^(\(|\d{3}\s)/,
    },
    {
      mask: '+1 000 0000000',
      lazy: true,
      startsWith: /^\+/,
    },
    {
      mask: '000-000-0000',
      lazy: true,
      startsWith: /^/,
    },
  ]
} else {
  mobileMask = [
    {
      mask: '+61 400 000 000',
      lazy: true,
      startsWith: /^\+/,
      fixedPart: '+61 4',
    },
    {
      mask: '\\0400 000 000',
      lazy: true,
      startsWith: /^/,
      fixedPart: '04',
    },
  ]
}

function defaultDispatch(
  appended: string,
  dynamicMasked: { value: string; compiledMasks: Mask[] }
) {
  const value = dynamicMasked.value + appended

  return dynamicMasked.compiledMasks.find((mask) => mask.startsWith.test(value))
}

const maskTemplates: MaskTemplates = {
  mobile: {
    mask: mobileMask,
    clearOnSelectionReplace: true,
    dispatch: function (appended, masked) {
      return masked.compiledMasks.find((m) => {
        return m.startsWith.test(masked.value + appended)
      })
    },
  },
  bsb: {
    mask: [
      {
        mask: '000-000',
        lazy: true,
        startsWith: /^\d/,
      },
    ],
    dispatch: defaultDispatch,
  },
}

export default maskTemplates
