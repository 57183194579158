<script>
import get from 'lodash-es/get'
import CreatorPoll from '/~rec/components/post/creator/forms/poll/creator-poll.vue'
import CreatorQuiz from '/~rec/components/post/creator/forms/creator-quiz.vue'
import CreatorPost from '/~rec/components/post/creator/forms/creator-post.vue'
import { RecroomGroup } from '/~rec/core/group'
import { RecroomEvent } from '/~rec/core/event'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'post-creator-form',
  components: {
    BaseLoader,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    source: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { groups } = useGroups()

    return { groups }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    sourceType() {
      const source = this.source || this.groups.defaultGroup

      if (source instanceof RecroomGroup) {
        return 'group'
      }

      if (source instanceof RecroomEvent) {
        return 'event'
      }

      return null
    },
    isAnnouncements() {
      return get(this.$route, 'params.feedId') === 'announcements'
    },
    entryComponent() {
      switch (this.type) {
        case 'poll':
          return CreatorPoll
        case 'quiz':
          return CreatorQuiz
        default:
          return CreatorPost
      }
    },
  },
}
</script>

<template>
  <div>
    <template v-if="loading">
      <div
        class="absolute inset-0 z-10 w-full bg-overlay opacity-25"
        :class="{
          'overflow-hidden rounded-lg': type === 'post',
        }"
      />
      <base-loader class="absolute inset-0 z-20" />
    </template>
    <component
      :is="entryComponent"
      v-bind="$attrs"
      class="h-full"
      :loading.sync="loading"
      :announcement="isAnnouncements"
      :source="source || groups.defaultGroup"
      :source-type="sourceType"
      v-on="$listeners"
    />
  </div>
</template>
