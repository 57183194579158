import { computed, readonly, ref } from 'vue'
import { GoogleAddressFinder } from '/~/composables/addresses/core/GoogleAddressFinder'

let addressFinderService: GoogleAddressFinder

const isAddressFinderSearching = ref(false)
const isAddressFinderSearchingById = ref(false)
const addressFinderSearchResults = ref<GoogleAddressFinder.SearchResult>([])
const addressFinderTask =
  ref<Promise<void | GoogleAddressFinder.GetMetadataResult> | null>(null)

const addressFinderSearchResultsForSelect = computed(() => {
  return addressFinderSearchResults.value.map((address) => {
    return {
      label: address.fullAddress,
      value: address,
    }
  })
})

async function initService() {
  if (addressFinderService) {
    return
  }

  addressFinderService = new GoogleAddressFinder()
}

async function searchAddress(query: string) {
  isAddressFinderSearching.value = true
  addressFinderSearchResults.value.splice(
    0,
    addressFinderSearchResults.value.length
  )

  let fetchTask: Promise<void>
  const isRelevant = () => !fetchTask || addressFinderTask.value === fetchTask

  try {
    await initService()

    fetchTask = addressFinderService.search(query).then((items) => {
      if (isRelevant()) {
        addressFinderSearchResults.value.splice(0, 0, ...items)

        setTimeout(() => {
          isAddressFinderSearching.value = false
        })
      }
    })

    addressFinderTask.value = fetchTask

    await fetchTask
  } catch (error) {
    console.error(error)

    if (isRelevant()) {
      isAddressFinderSearching.value = false
    }
  }
}

async function getAddressById(id: string) {
  isAddressFinderSearching.value = true
  isAddressFinderSearchingById.value = true

  let fetchTask: Promise<GoogleAddressFinder.GetMetadataResult | undefined>
  const isRelevant = () => !fetchTask || addressFinderTask.value === fetchTask

  try {
    await initService()

    fetchTask = addressFinderService.getMetadata(id).then((address) => {
      if (isRelevant()) {
        setTimeout(() => {
          isAddressFinderSearching.value = false
          isAddressFinderSearchingById.value = false
        })
        return address
      }
    })

    addressFinderTask.value = fetchTask

    return await fetchTask
  } catch (error) {
    console.error(error)

    if (isRelevant()) {
      isAddressFinderSearching.value = false
      isAddressFinderSearchingById.value = false
    }
  }
}

export function useAddressFinder() {
  return {
    isAddressFinderSearching: readonly(isAddressFinderSearching),
    isAddressFinderSearchingById: readonly(isAddressFinderSearchingById),
    addressFinderSearchResults: readonly(addressFinderSearchResults),
    addressFinderSearchResultsForSelect: readonly(
      addressFinderSearchResultsForSelect
    ),
    searchAddress,
    getAddressById,
  }
}
