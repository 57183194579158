<script setup lang="ts">
import AuthLayout from '/~/layouts/auth/layout-auth.vue'
import VerificationLocked from '/~/extensions/otp/components/verification-locked/verification-locked.vue'
import BaseButton from '/~/components/base/button/base-button'
import { useAuth } from '/~/composables/auth'
const { logout } = useAuth()

import { ref } from 'vue'

const loading = ref(false)

function onBackToLogin() {
  loading.value = true
  logout()
}
</script>

<template>
  <auth-layout>
    <div class="flex flex-grow items-center sm:bg-eonx-neutral-50 sm:pt-0">
      <div class="flex w-full flex-col rounded-3xl sm:mx-[24px] sm:py-[30px]">
        <div
          class="relative mx-auto flex max-w-[640px] flex-col bg-white sm:rounded-3xl"
        >
          <verification-locked temporarily class="min-h-full p-8" />
          <div class="border-eonx-neutral-200 p-8 sm:border-t">
            <base-button
              :disabled="loading"
              :loading="loading"
              class="w-full"
              @click="onBackToLogin"
            >
              Back to login
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </auth-layout>
</template>
