<script>
import { defineComponent, ref, watch, computed, onMounted } from 'vue'

const DEFAULT_CLASSES = {
  checkboxApperance:
    'h-6 border-2 border-transparent rounded-full w-11 focus:outline-none',
  checkboxAnimation: 'transition-colors duration-200 ease-in-out',
  checkboxActiveColor: 'bg-primary',
  checkboxInactiveColor: 'bg-gray-300',
  checkboxDisabledColor: 'bg-eonx-neutral-50',
  toggleApperance: 'w-5 h-5 transform rounded-full shadow',
  toggleAnimation: 'transition duration-200 ease-in-out',
  toggleEnabled: 'bg-white',
  toggleDisabled: 'bg-eonx-neutral-50',
  toggleValue: 'translate-x-5',
  toggleEmptyValue: 'translate-x-0',
  slot: 'mt-0.5 grow leading-5',
  slotDirect: 'ml-5',
  slotReverse: 'mr-5',
  slotDisabled: 'text-eonx-neutral-600',
}

export default defineComponent({
  name: 'base-toggle',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: null,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    transformValues: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue', 'change', 'click'],
  setup(props, { emit }) {
    const modelProxy = ref(props.modelValue)
    const element = ref({})

    const isDisabled = computed(() => props.disabled)

    function getActiveValue() {
      if (
        Object.prototype.hasOwnProperty.call(
          props.transformValues || {},
          'active'
        )
      ) {
        return props.transformValues.active
      }

      return true
    }

    function getInactiveValue() {
      if (
        Object.prototype.hasOwnProperty.call(
          props.transformValues || {},
          'inactive'
        )
      ) {
        return props.transformValues.inactive
      }

      return false
    }

    const isChecked = computed(() => {
      return modelProxy.value === getActiveValue()
    })

    function setValue(value) {
      modelProxy.value = value
      emit('update:modelValue', modelProxy.value)
      emit('change', modelProxy.value)
      emit('click', modelProxy.value)
    }

    function toggle() {
      const value = isChecked.value ? getInactiveValue() : getActiveValue()

      setValue(value)
    }

    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue !== modelProxy.value) {
          setValue(props.modelValue)
        }
      }
    )

    function setInitialValue() {
      if (props.modelValue === undefined) {
        setValue(getInactiveValue())
      } else {
        modelProxy.value = props.modelValue
      }
    }

    onMounted(() => {
      setInitialValue()
    })

    return {
      isDisabled,
      isChecked,
      modelProxy,
      element,
      handlers: {
        input() {
          toggle()
        },
      },
    }
  },
  data: () => ({
    defaultClasses: {},
  }),
  computed: {
    $classes() {
      return {
        ...DEFAULT_CLASSES,
        ...this.defaultClasses,
        ...this.classes,
      }
    },
  },
})
</script>

<template>
  <label
    class="flex"
    :class="[
      isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
      reverse && 'flex-row-reverse',
    ]"
  >
    <span
      class="flex shrink-0"
      :class="[
        $classes.checkboxApperance,
        $classes.checkboxAnimation,
        !isDisabled &&
          (isChecked
            ? $classes.checkboxActiveColor
            : $classes.checkboxInactiveColor),
        isDisabled && $classes.checkboxDisabledColor,
      ]"
    >
      <span
        :class="[
          $classes.toggleApperance,
          $classes.toggleAnimation,
          isDisabled ? $classes.toggleDisabled : $classes.toggleEnabled,
          isChecked ? $classes.toggleValue : $classes.toggleEmptyValue,
        ]"
      />
    </span>
    <div
      v-if="$slots.default"
      :class="[
        $classes.slot,
        reverse ? $classes.slotReverse : $classes.slotDirect,
        isDisabled && $classes.slotDisabled,
      ]"
    >
      <slot :checked="isChecked" />
    </div>
    <input
      ref="input"
      v-bind="$attrs"
      type="checkbox"
      class="z-back absolute hidden h-0 w-0 opacity-0"
      :name="name"
      :checked="isChecked"
      :disabled="isDisabled"
      v-on="handlers"
    />
  </label>
</template>
