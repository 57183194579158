<script>
import DefaultAddAddress from '/~/components/ewallet-menu/views/profile-settings/profile-add-address.vue'
import BaseAsidePage from '/~/templates/australiapost/components/base/aside-page/base-aside-page.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    BaseAsidePage,
  },
  extends: DefaultAddAddress,
})
</script>
