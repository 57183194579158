<script>
import modal from '/~/core/mdl'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'base-header-mobile-image',
  components: {
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onVisibilityChanged(visible) {
      this.isFixed = !visible
    },
    onBack() {
      modal.hide()
      this.$emit('back')
    },
  },
}
</script>

<template>
  <div
    class="bg-primary text-primary-content"
    :class="[rounded && 'rounded-b-xl']"
  >
    <div class="flex h-16 items-center pr-5">
      <div class="flex h-full w-16 shrink-0 items-center" @click="onBack">
        <base-icon
          svg="rec/chevron-left"
          size="sm"
          class="inset-0 m-auto block h-full text-xl"
        />
      </div>

      <div class="flex items-center truncate text-xl font-bold">
        {{ title }}
        <slot name="title" />
      </div>
      <div class="ml-auto pl-5">
        <slot name="right" />
      </div>
    </div>
    <slot name="bottom" />
  </div>
</template>
