<script lang="ts" setup>
import DrawerLoyaltyCardEdit from '/~/drawer/loyalty-cards/loyalty-edit.vue'
import { useLoyaltyCards } from '/~/composables/loyalty-cards'
import { useRouter } from 'vue-router/composables'

defineProps<{ id: object }>()

const router = useRouter()
const { getLoyaltyCards } = useLoyaltyCards()

async function onSubmit(id: string) {
  await getLoyaltyCards()

  return router.replace({
    hash: `#profile-loyalty-card-details/${id}`,
  })
}
</script>

<template>
  <drawer-loyalty-card-edit :id="id" @submit="onSubmit" />
</template>
