import { computed } from 'vue'
import { MetafieldsSchema } from '/~/components/base/metafield/base-metafield.vue'
import { useAnalytics } from '/~/composables/analytics'
import {
  BankAccountSchemaField,
  BankAccountSchema,
  useLocalization,
} from '/~/composables/localization'

export function useBankAccountForm() {
  const { onFormFieldChange } = useAnalytics()
  const { bankAccount } = useLocalization()

  const {
    form,
    initiateValues,
    bankAccountSchema: rawBankAccountSchema,
    accountPresentation,
  } = bankAccount

  function mapSchema(schema: BankAccountSchema): MetafieldsSchema {
    function mapFields({ analyticsLabel, ...field }: BankAccountSchemaField) {
      if (analyticsLabel) {
        field.onInput = () => onFormFieldChange(analyticsLabel)
      }

      return field
    }

    const fields = schema.fields.map(mapFields)
    const types = Object.fromEntries(
      Object.keys(schema.types).map((key) => [
        key,
        {
          fields: schema.types[key].fields.map(mapFields),
        },
      ])
    )

    return {
      fields,
      types,
    }
  }

  const bankAccountSchema = computed<MetafieldsSchema>(() =>
    mapSchema(rawBankAccountSchema)
  )

  return {
    initiateValues,
    form,
    bankAccountSchema,
    accountPresentation,
  }
}
