<script setup lang="ts">
import { computed } from 'vue'
import { PaymentMethod } from '/~/types/api'
import PaymentMethodItem from '/~/components/payments/list/item/default.v4.vue'
import PaymentMethodBlock from '/~/components/payments/payments-methods-block.v3.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import {
  useFlowPaymentMethods,
  usePaymentMethods,
} from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'
const emit = defineEmits<{
  (event: 'selected', method: PaymentMethod): void
}>()

const { payment, currentFlowType } = useCheckoutReactive()
const { splitPaymentOrderAllowed } = useProvider()
const {
  fetching,
  isCreditCardsAvailable,
  isBankAccountsAvailable,
  isPointsAvailable,
  isPayIdAvailable,
  creditCards,
  bankAccounts,
  points,
  payIds,
} = useFlowPaymentMethods(
  currentFlowType.value,
  (payment.value as any).payeeId,
  payment.value.payee?.type
)
const { config } = usePaymentMethods()

const availablePaymentMethods = computed(() => {
  const methods = {}

  if (
    !splitPaymentOrderAllowed(currentFlowType.value) &&
    isPointsAvailable.value
  ) {
    methods.ps = {
      title: 'Pay with points',
      methods: points.value,
    }
  }
  if (isCreditCardsAvailable.value) {
    methods.cc = {
      title: 'Credit/Debit cards',
      methods: creditCards.value,
    }
  }
  if (isBankAccountsAvailable.value) {
    methods.db = {
      title: 'Bank accounts',
      methods: bankAccounts.value,
    }
  }
  if (isPayIdAvailable.value) {
    methods.pi = {
      title: 'PayID',
      methods: payIds.value,
    }
  }
  return config.value.map((item) => methods[item.type]).filter(Boolean)
})

function onSelect(method: PaymentMethod) {
  emit('selected', method)
}
</script>
<template>
  <div>
    <payment-method-block
      v-for="(item, key) in availablePaymentMethods"
      :key="key"
      :title="item.title"
      :loading="fetching"
    >
      <payment-method-item
        v-for="method in item.methods"
        :key="method.id"
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: 'Select payment method',
          label: 'Payment method selected',
        }"
        :method="method"
        :data-testid="method.id"
        @click="onSelect(method)"
      />
    </payment-method-block>
  </div>
</template>
