<script setup>
import { ref } from 'vue'
import { useTravel } from '/~/extensions/travel/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import SelectField from '/~/components/mobile-filter/select-field.vue'

const {
  destinations: categories,
  selectedCategory,
  searchQuery,
  isFiltersSelected,
  resetFilters,
  searchGroup,
} = useTravel()

const localSearchQuery = ref(searchQuery.value)
const localCategory = ref(selectedCategory.value)

function resetLocalFilters() {
  localSearchQuery.value = searchQuery.value
  localCategory.value = selectedCategory.value
}

function clearFilters() {
  resetFilters()
  resetLocalFilters()
}

function applyFilters() {
  searchQuery.value = localSearchQuery.value
  selectedCategory.value = localCategory.value
}
</script>

<template>
  <ew-filters
    title="Filters"
    :is-filters-selected="isFiltersSelected"
    @opened="resetLocalFilters"
    @clear="clearFilters"
    @close="resetLocalFilters"
    @apply="applyFilters"
  >
    <div class="relative mt-2.5 grow">
      <base-input
        v-model="localSearchQuery"
        :placeholder="`Search ${searchGroup}`"
        :icon-plain="true"
        look="rounded"
        clearable
        nolabel
      >
        <template #icon>
          <div class="ml-[15px] mr-2.5 flex items-center justify-center">
            <base-icon
              svg="plain/search-v2"
              class="text-eonx-neutral-600"
              :size="19"
            />
          </div>
        </template>
      </base-input>
    </div>
    <div class="space-y-4 py-4">
      <select-field
        v-model="localCategory"
        title="Destinations"
        :options="categories"
      />
    </div>
  </ew-filters>
</template>
