<script>
import get from 'lodash-es/get'
import { computed, toRefs } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { useCms } from '/~/composables/cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import DrawerActivityDetailsItem from '../drawer-activity-details-item.vue'

export default {
  name: 'drawer-activity-detail-top-up',
  components: {
    DrawerActivityDetailsItem,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    detailsLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { content } = toRefs(props)

    const { ewalletLabels } = useCms()

    const activityItems = computed(() => {
      return [
        {
          title: 'Transaction No.',
          value: transactionId.value,
        },
        {
          title: 'Date',
          value: formatDate('daymonthyear', content.value.created_at),
        },
        {
          title: 'Status',
          value: activity.value.status === 'completed' ? 'Complete' : 'Pending',
          state: activity.value.status === 'completed' ? 'success' : 'warning',
        },
        {
          title: 'Payment Method',
          value: paymentMethod.value,
        },
        {
          title: 'Amount',
          value: formatDollar(content.value.total),
        },
      ]
    })

    const activity = computed(() => {
      return get(content.value, 'activity', {})
    })

    const transactionId = computed(() => {
      return get(content.value, 'metadata.transactionId', '-')
    })

    const paymentMethod = computed(() => {
      const paymentMethod = get(activity.value, 'paymentMethods.0')

      switch (paymentMethod) {
        case PaymentMethodType.eWallet:
          return ewalletLabels.value.ewalletCash
        case PaymentMethodType.creditCard:
          return 'Debit Card'
        case PaymentMethodType.bankAccount:
          return 'Bank Account'
        default:
          return null
      }
    })

    return {
      activityItems,
      activity,
      transactionId,
      paymentMethod,
      formatDollar,
    }
  },
}
</script>

<template>
  <drawer-activity-details-item
    :items="activityItems"
    :details-loading="detailsLoading"
  />
</template>
