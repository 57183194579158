// import '/~/boot/sw-register'
import 'core-js/stable'
import '/~/boot/bugsnag'
import '/~/boot/datadog'
import '/~/boot/provider/pwa'
import '/~/boot/packages/vee-validate-boot'
import '/~/utils'
import '/~/core/ui'
import dayjs from 'dayjs/esm'
import isBetween from 'dayjs/esm/plugin/isBetween'
import utc from 'dayjs/esm/plugin/utc'
import Vue from 'vue'
import Fragment from 'vue-fragment'
import VueNotification from 'vue-notification'
import type { RouteConfig } from 'vue-router'
import Analytics from '/~/core/analytics'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { useThirdPartyLogin } from '/~/extensions/third-party-login/composables/use-third-party-login'
import cdn from '/~/utils/cdn'
import { useActivity } from '/~/composables/activity'
import { useAuth } from '/~/composables/auth'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import { useVirtualCards } from '/~/composables/virtual-cards'
import router from '/~/router'
import { AUTH_LOGIN_ROUTE } from '/~/router/router-constants'

dayjs.extend(utc)
dayjs.extend(isBetween)

let reqPath: string

const { showCmsModalsOnLogin } = useCms()
const { initThirdPartyLogin } = useThirdPartyLogin()
const { user, checkAccessToApp, fetchUserMetadata } = useUser()
const { isVirtualCardsEnabled, fetchVirtualCards } = useVirtualCards()
const { isProfileCompletionRequired, isSymbioneliteProvider } = useProvider()

Vue.mixin({
  methods: {
    cdn,
  },
})

Vue.use(VueNotification)
Vue.use(Fragment.Plugin)

async function bootstrap() {
  const { providerLocale, currentTemplate, currentTemplateConfig } =
    useProvider()

  await Promise.all([
    import(`./templates/${currentTemplate.value}/routes.ts`).then((module) => {
      module.default.forEach((route: RouteConfig) => {
        router.addRoute(route)
      })
    }),
    import(`./templates/${currentTemplate.value}/config.ts`).then((module) => {
      currentTemplateConfig.value = module.default
    }),
  ])

  if (
    !eonx.user &&
    !router.resolve(window.location.pathname).route.meta?.guestAccess
  ) {
    if (eonx.config.login.type === 'oauth') {
      window.location.replace(`/login?intended=${window.location.pathname}`)
    } else if (!['email', 'username'].includes(eonx.config.login.type)) {
      window.location.pathname = 'logout'
    }
  }

  const app = await import(`./templates/${currentTemplate.value}/app.vue`)

  // Currently analytics is only enabled for Symbion provider on UAT
  if (isSymbioneliteProvider.value) {
    Analytics.initiate(eonx.keys.amplitude?.symbionelite)
  }

  const localization = useLocalization()

  localization.setLocale(providerLocale.value)

  window.eonx.app = new Vue({
    el: '#app',
    router,
    render: (h) => h(app.default),
    i18n: localization.getPlugin(),
  })

  // NOTE: alias for old cms code widgets
  window.eonxApp = window.eonx.app
}

emitter.once('auth:required', () => {
  reqPath = window.location.href.replace(window.location.origin, '')

  bootstrap()
})

emitter.on('auth:unauthenticated', () => {
  const { logoutCleanup } = useAuth()

  logoutCleanup()

  router.push({ name: AUTH_LOGIN_ROUTE }).catch()
})

emitter.once('auth:success', async (userData) => {
  Vue.notify({ clean: true })

  await eonx.fetchCmsConfig()

  for (const name in eonx.cms.customPages) {
    const page = eonx.cms.customPages[name]

    router.addRoute({
      path: `/${page.name}`,
      name: page.name,
      component: () => import('/~/views/home/home.vue'),
      meta: {
        title: page.label,
        page: page.name,
        footer: true,
      },
    })
  }

  if (eonx.env === 'production' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(async (registrations) => {
      const promises = registrations.map((reg) => reg.unregister())

      await Promise.all(promises)
    })
  }

  if (eonx.keys.pendo.apiKey) {
    const { loadPendo, initPendo } = await import('./boot/pendo')

    loadPendo(eonx.keys.pendo.apiKey).then(() => {
      initPendo(eonx.user?.id, eonx.provider.subdomain)
    })
  }

  if (reqPath) {
    router.push(/auth|password/.test(reqPath) ? '/' : reqPath).catch()
  }

  if (!eonx.app) {
    await bootstrap()
  }

  Analytics.setUser(userData.externalId)

  Analytics.sendEvent({
    pageGroup: 'Global',
    label: 'Authenticated user session started',
  })
})

emitter.on('modal:show', ({ name, ...options }) => {
  modal.show(name, options)
})

emitter.on('ui:hide-all-popups', () => {
  bottomSheet.hideAll()
  modal.hideAll()
  Vue.notify({ clean: true })
})

emitter.on('notify', (options) => {
  Vue.notify(options)
})

emitter.on('savings-updated', () => {
  const { fetchUserDetails } = useUser()

  fetchUserDetails()
})

emitter.on('auth:success', () => {
  fetchUserMetadata()
    .then(checkAccessToApp)
    .then(showCmsModalsOnLogin)
    .then(initThirdPartyLogin)

  // force non-closable modal to update profile if user has incomplete profile
  if (
    isProfileCompletionRequired.value &&
    (user.value.hasTemporaryEmail || !user.value.email)
  ) {
    emitter.emit('modal:show', { name: 'edit-profile' })
  }

  if (isVirtualCardsEnabled.value) {
    fetchVirtualCards()
  }
})

emitter.on('activity:refresh', () => {
  const { activity } = useActivity()

  activity.value.refresh()
})

emitter.on('router:push', (payload) => {
  router.push(payload).catch()
})

emitter.on('router:replace', (payload) => {
  router.replace(payload).catch()
})

emitter.on('router:back', () => {
  router.back()
})

emitter.on('signout', () => {
  const { signout } = useAuth()

  signout()
})

Vue.config.productionTip = false

export default {}
