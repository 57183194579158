<script>
import modal from '/~/core/mdl'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'

export default {
  name: 'rec-group-header-mobile',
  components: {
    BaseIcon,
    ButtonIcon,
    HeaderMobile,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onBack() {
      modal.hide()
      this.$emit('back')
    },
    openInfoModal() {
      modal.show('rec-group-info-mobile', {
        props: {
          group: this.group,
        },
      })
    },
  },
}
</script>

<template>
  <header-mobile
    :image="group.image"
    :title="group.name"
    @back="onBack"
    @img-click="openInfoModal"
    @title-click="openInfoModal"
  >
    <template #icons>
      <div class="ml-2.5 text-eonx-neutral-800">
        <base-icon v-if="group.isPrivate" svg="rec/lock-solid" size="sme" />
        <base-icon v-else svg="rec/planet" size="sme" />
      </div>
    </template>

    <template #right>
      <button-icon
        class="cursor-pointer"
        icon="rec/info-blue"
        size="xl"
        @click="openInfoModal"
      />
    </template>
  </header-mobile>
</template>
