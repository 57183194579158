<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import PaymentMethodPlaceholder from './payments-method-placeholder.vue'
import { ref, onMounted, onBeforeUnmount } from 'vue'

export default {
  name: 'payments-methods-select-block',
  components: {
    BaseIcon,
    PaymentMethodPlaceholder,
  },
  props: {
    isItemSelected: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    doNotToggleIfSelected: {
      type: Boolean,
      default: true,
    },
  },
  emits: [],
  setup(props) {
    const selectBlockRef = ref(null)
    const isToggled = ref(false)

    function onToggleClicked() {
      if (
        props.isDisabled ||
        (props.doNotToggleIfSelected && props.isItemSelected)
      ) {
        return
      }

      isToggled.value = true
    }

    function outsideClickListener(event) {
      if (
        !(
          selectBlockRef.value === event.target ||
          selectBlockRef.value?.contains(event.target)
        )
      ) {
        isToggled.value = false
      }
    }

    function toggleOff() {
      isToggled.value = false
    }

    onMounted(() => {
      document.body.addEventListener('click', outsideClickListener)
    })

    onBeforeUnmount(() => {
      document.body.removeEventListener('click', outsideClickListener)
    })

    return {
      selectBlockRef,
      isToggled,
      onToggleClicked,
      toggleOff,
    }
  },
}
</script>

<template>
  <div>
    <payment-method-placeholder v-if="isLoading" />
    <div
      v-else
      ref="selectBlockRef"
      class="relative"
      :style="isDisabled && 'filter:grayscale(1)'"
    >
      <template v-if="isItemSelected">
        <slot
          name="selectedItem"
          :toggle-off="toggleOff"
          :on-toggle-clicked="onToggleClicked"
        />
      </template>
      <div
        v-else
        class="flex cursor-pointer items-center rounded-lg bg-white p-[15px]"
        @click="onToggleClicked"
      >
        <slot name="title">Select payment method</slot>
        <div class="ml-auto pl-5">
          <base-icon
            svg="plain/chevron-bottom"
            :size="14"
            class="text-eonx-neutral-800"
          />
        </div>
      </div>
      <div v-show="isToggled" class="absolute top-0 left-0 z-10 w-full pb-5">
        <div class="max-h-56 overflow-auto rounded-lg bg-white shadow-xl">
          <slot name="items" :toggle-off="toggleOff" />
        </div>
      </div>
    </div>
  </div>
</template>
