<script>
import './base-box.scss'

export default {
  name: 'base-box',
  props: {
    title: {
      type: String,
      default: '',
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    headerShadow: {
      type: Boolean,
      default: true,
    },
    newLook: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pinned: false,
    }
  },
  methods: {
    onBodyScroll(event) {
      if (!this.headerShadow) return

      const scrollTop = event.target.scrollTop

      this.pinned = scrollTop > 0
    },
  },
}
</script>

<template>
  <div
    :class="{
      'base-box': true,
      'base-box--scrollable': scrollable,
      'base-box--new': newLook,
    }"
  >
    <slot name="box-header">
      <div
        v-if="!hideHeader"
        :class="{
          'base-box__header': true,
          'base-box__header--pinned': pinned,
        }"
      >
        <div class="base-box__mainheader">
          <slot name="header">
            <div v-if="'premenu' in $scopedSlots" class="base-box__premenu">
              <slot name="premenu" />
            </div>
            <h2 class="base-box__title">
              <slot name="title">
                {{ title }}
              </slot>
            </h2>
            <div class="base-box__menu">
              <slot name="menu" />
            </div>
          </slot>
        </div>

        <div class="base-box__subheader">
          <slot name="subheader" />
        </div>
      </div>
    </slot>

    <div class="base-box__body" @scroll="onBodyScroll">
      <slot />
    </div>

    <div v-if="$slots.footer" class="base-box__footer">
      <slot name="footer" />
    </div>
  </div>
</template>
