<script>
import { usePaymentMethods } from '/~/composables/payment-methods'
import PaymentMethodBlock from '../payments-methods-block.vue'
import PaymentsBankAccountItem from './payments-bank-account-item.vue'

export default {
  name: 'payments-bank-accounts',
  components: {
    PaymentMethodBlock,
    PaymentsBankAccountItem,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    splitEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      isPurchaseOrderBankAccountsAvailable,
      bankAccounts,
      fetchingList: fetching,
    } = usePaymentMethods()

    return {
      fetching,
      isAvailable: isPurchaseOrderBankAccountsAvailable,
      bankAccounts,
    }
  },
}
</script>

<template>
  <div v-if="isAvailable">
    <payment-method-block
      v-if="fetching || bankAccounts.length"
      title="Bank Accounts"
      :loading="fetching"
    >
      <div class="space-y-6">
        <payments-bank-account-item
          v-for="account in bankAccounts"
          :key="account.id"
          :item="account"
          :to="to"
          :split-enabled="splitEnabled"
        />
      </div>
    </payment-method-block>
  </div>
</template>
