<script>
import LayoutRounded from '/~/layouts/rounded/layout-rounded.vue'

export default {
  name: 'dining',
  components: {
    LayoutRounded,
  },
}
</script>

<template>
  <layout-rounded>
    <router-view />
  </layout-rounded>
</template>
