<script>
import { computed } from 'vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import ConfirmationToBatchFile from './confirmation-pay-to-batch-file.vue'
import ConfirmationToCart from './confirmation-pay-to-cart.vue'
import ConfirmationToPayee from './confirmation-pay-to-payee.vue'
import ConfirmationToStatement from './confirmation-pay-to-statement.vue'

export default {
  name: 'confirmation-pay-to',
  functional: true,
  render(h, ctx) {
    const {
      isPaymentCurrentFlowType,
      isStatementCurrentFlowType,
      isBatchOrderCurrentFlowType,
    } = useCheckoutReactive()

    const component = computed(() => {
      if (isStatementCurrentFlowType.value) {
        return ConfirmationToStatement
      } else if (isPaymentCurrentFlowType.value) {
        return ConfirmationToPayee
      } else if (isBatchOrderCurrentFlowType.value) {
        return ConfirmationToBatchFile
      }

      return ConfirmationToCart
    })

    return h(component.value, ctx.data, ctx.children)
  },
}
</script>
