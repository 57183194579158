<script>
import { computed } from 'vue'

export default {
  name: 'activity-details-addresses',
  props: {
    address: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const name = computed(() => {
      return `${props.address?.firstName} ${props.address?.lastName}`
    })
    const text = computed(() => {
      return [
        props.address?.streetAddress,
        props.address?.suburb,
        (props.address?.state ?? '').toUpperCase(),
        props.address?.postcode,
      ].join(' ')
    })

    return {
      name,
      text,
    }
  },
}
</script>

<template>
  <div>
    <div class="font-bold">
      {{ title }}
    </div>
    <div class="mt-4 space-y-[5px] text-sm text-eonx-neutral-600">
      <div>
        {{ name }}
      </div>
      <div>
        {{ text }}
      </div>
    </div>
  </div>
</template>
