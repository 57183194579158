import Swiper from 'swiper'
import {
  Keyboard,
  Autoplay,
  Pagination,
  Navigation,
  EffectCoverflow,
} from 'swiper/modules'
import { SwiperModule } from 'swiper/types/shared'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { ComputedRef, computed, nextTick, ref, watch } from 'vue'

export type { SwiperOptions, Swiper }

export function useSwiper(options: ComputedRef<SwiperOptions>) {
  const swiper = ref<Swiper>()
  const swiperContainer = ref<HTMLDivElement>()

  const lastActiveIndex = ref(0)

  const currentIndex = computed(() => swiper.value?.realIndex ?? 0)
  const activeIndex = computed(() => swiper.value?.activeIndex ?? 0)
  const slidesCount = computed(() => swiper.value?.slides?.length ?? 0)

  function initModules() {
    const modules: SwiperModule[] = []

    if (options.value.pagination) {
      modules.push(Pagination)
    }
    if (options.value.navigation) {
      modules.push(Navigation)
    }
    if (options.value.autoplay) {
      modules.push(Autoplay)
    }
    if (options.value.keyboard) {
      modules.push(Keyboard)
    }
    if (options.value.coverflowEffect) {
      modules.push(EffectCoverflow)
    }

    Swiper.use(modules)
  }

  function initSwiper() {
    if (!swiperContainer.value || (swiper.value && !swiper.value.destroyed)) {
      return
    }

    initModules()

    swiper.value = new Swiper(swiperContainer.value, {
      roundLengths: true,
      touchEventsTarget: 'container',
      observer: true,
      observeSlideChildren: true,
      initialSlide: lastActiveIndex.value ?? 0,
      ...options.value,
    })
  }

  function destroySwiper() {
    swiper.value?.destroy(true, true)
  }

  function resetSwiper() {
    lastActiveIndex.value = swiper.value?.realIndex ?? 0
    destroySwiper()
    nextTick(initSwiper)
  }

  function selectSlide(index: number) {
    swiper.value?.slideTo(index)
  }

  watch(options, () => {
    destroySwiper()
    nextTick(initSwiper)
  })

  return {
    swiper,
    swiperContainer,
    currentIndex,
    activeIndex,
    slidesCount,
    initSwiper,
    destroySwiper,
    resetSwiper,
    selectSlide,
  }
}
