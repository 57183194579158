<script>
import { formatDollar } from '/~/utils/format/money'
import DrawerPaymentFromV1 from '/~/components/drawer/components/rows/drawer-payment-from.vue'
import { useEwallet } from '/~/composables/ewallet'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'

export default {
  name: 'profile-payment-from-v2',
  extends: DrawerPaymentFromV1,
  setup() {
    const { ewalletBalance } = useEwallet()
    const { pointsBalance } = usePoints()

    return {
      PaymentMethodType,
      ewalletBalance,
      pointsBalance,
    }
  },
  computed: {
    secondRowText() {
      const { from } = this

      switch (this.type) {
        case this.PaymentMethodType.eWallet:
          return formatDollar(this.ewalletBalance)
        case this.PaymentMethodType.points:
          return this.pointsBalance
        case this.PaymentMethodType.creditCard:
          return `**** **** **** ${from.number.slice(-4)}`
        case this.PaymentMethodType.bankAccount:
          return from.accountNumber
            .replace(/\./g, '*')
            .match(/.{0,3}/g)
            .join(' ')
        default:
          return ''
      }
    },
    thirdRowText() {
      const { from } = this

      switch (this.type) {
        case this.PaymentMethodType.bankAccount:
          return from.bsb
        case this.PaymentMethodType.creditCard:
        case this.PaymentMethodType.eWallet:
        case this.PaymentMethodType.points:
        default:
          return null
      }
    },
    icon() {
      switch (this.type) {
        case this.PaymentMethodType.eWallet:
          return {
            url: 'v2/custom/ewallet',
            class: 'w-12 h-12 bg-primary text-white',
            size: 24,
          }
        case this.PaymentMethodType.points:
          return {
            url: 'v2/box-icons/bx-medal',
            class: 'w-12 h-12 bg-primary text-white',
            size: 24,
          }
        case this.PaymentMethodType.creditCard: {
          const icon = this.from.brand.toLowerCase()
          const url = `cards/${icon}` || 'plain/creditcards'

          return { url, class: 'text-primary', size: 32 }
        }
        case this.PaymentMethodType.bankAccount:
          return {
            url: 'v2/custom/bank-outline',
            class: 'w-12 h-12 pt-[5px] bg-primary text-white',
            size: 30,
          }
        default:
          return {
            url: 'plain/creditcards',
            class: 'bg-primary text-white',
            size: 32,
          }
      }
    },
  },
}
</script>

<template>
  <div class="flex w-full items-center justify-between">
    <div
      class="flex items-center justify-center rounded-full"
      :class="[icon.class, isDisabled && 'cursor-not-allowed opacity-50']"
    >
      <base-icon :svg="icon.url" :size="icon.size || 'md'" />
    </div>

    <div
      class="flex-1 overflow-hidden pl-[15px]"
      :class="[isDisabled && 'cursor-not-allowed opacity-50']"
      @click="onClick"
    >
      <div class="truncate font-bold">
        {{ firstRowText }}
      </div>
      <div class="truncate text-sm">
        {{ secondRowText }}
      </div>
      <div v-if="thirdRowText" class="truncate text-sm">
        {{ thirdRowText }}
      </div>
    </div>

    <div v-if="$slots.action" class="ml-5 flex-shrink-0">
      <slot name="action" />
    </div>
  </div>
</template>
