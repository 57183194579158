<script>
import { createDate, formatDate } from '/~/utils/format/date'
import { useLocalization } from '/~/composables/localization'

const { getDatePickerFormat } = useLocalization()

const types = [
  'diff-weeks', // 8
  'time', // 11:30 AM
  'short-time', // 11:30
  'date', // 18 April 2020
  'short-date', // 18 Apr 2020
  'short-date-time', // 18 Apr 2020, 11:30 AM
  'long-date-time', // 18 April 2020, 11:30 AM
  'month-year', // April 2020
  'day-date', // Saturday, 18 April 2020
  'date-spr', // 18-April-2019
  'date-numeric - spr', // 18-04-2020
  'short-date-spr', // 18-Apr-2020
  'short-date-spr-time', // 18-Apr-2019, 11:30 AM
  'day-spr', // Today / Yesterday / 18-Apr-2020
  'day-time-spr', // Today, 11:30 AM / Yesterday, 11:30 AM / 18-Apr-2020, 11:30 AM
  'day-time-sfd', // Today, 11:30 AM / Yesterday, 11:30 AM / Tomorrow, 11:30 AM // Calculating a gap between 2 days
  'day-time', // Today, 11:30 AM / Yesterday, 11:30 AM / 18 Apr 2020
  'from-now', // 6 seconds ago / 1 minute ago / 4 hours ago / 2 days ago / 18-Apr-2020, 11:30 AM
]

export default {
  name: 'date-line',
  props: {
    date: {
      type: [String, Date, Number, Object],
      required: true,
      default: '',
    },
    format: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: '',
      validator: (v) => !v || types.includes(v),
    },
    inputType: {
      type: String,
      default: '',
    },
  },
  computed: {
    formattedDate() {
      if (!this.date) return '-'

      if (this.format) return formatDate(this.format, this.date)

      switch (this.type) {
        case 'diff-weeks':
          return this.difference('week')
        case 'time':
          return this.formatDate('time')
        case 'short-time':
          return this.formatDate('time24')
        case 'month-year':
          return this.formatDate('monthyear')
        case 'day-date':
          return `${this.formatDate('weekdaylong')}, ${this.formatDate(
            'day'
          )} ${this.formatDate('monthlong')} ${this.formatDate('year')}`
        case 'date-spr': // legacy
        case 'short-date-spr': // legacy
        case 'short-date':
          return this.formatDate('daymonthyear')
        case 'date-numeric-spr':
          return this.formatDate('daymonthyearnumeric')
        case 'short-date-spr-time': // legacy
        case 'short-date-time':
          return this.formatDate('daymonthyeartime')
        case 'long-date-time':
          return this.formatDate('daymonthyearlongtime')
        case 'day-time-sfd':
          return this.searchingForDay({
            sameDay: { format: 'time', prefix: 'Today, ' },
            lastDay: { format: 'time', prefix: 'Yesterday, ' },
            nextDay: { format: 'time', prefix: 'Tomorrow, ' },
            sameElse: { format: 'daymonthyeartime' },
          })
        case 'day-time-spr':
          return this.differenceDay({
            sameDay: { format: 'time', prefix: 'Today, ' },
            lastDay: { format: 'time', prefix: 'Yesterday, ' },
            nextDay: { format: 'time', prefix: 'Tomorrow, ' },
            sameElse: { format: 'daymonthyeartime' },
          })
        case 'day-spr':
          return this.differenceDay({
            sameDay: { prefix: 'Today' },
            lastDay: { prefix: 'Yesterday' },
            nextDay: { prefix: 'Tomorrow' },
            sameElse: { format: 'daymonthyear' },
          })
        case 'day-time':
          return this.differenceDay({
            sameDay: { format: 'time', prefix: 'Today, ' },
            lastDay: { format: 'time', prefix: 'Yesterday, ' },
            nextDay: { format: 'time', prefix: 'Tomorrow, ' },
            sameElse: { format: 'daymonthyear' },
          })
        case 'from-now':
          return this.fromNow()
        case 'date':
          return this.formatDate('daymonthyear')
        default:
          return this.date
      }
    },
  },
  methods: {
    difference(unit, decimal) {
      return createDate().diff(this.date, unit, decimal)
    },
    searchingForDay(types) {
      const now = createDate()
      const startOfDay = now.startOf('day')
      const endOfDay = now.endOf('day')
      let key

      if (startOfDay <= this.date && endOfDay >= this.date) {
        key = 'sameDay'
      } else if (startOfDay > this.date) {
        key = 'lastDay'
      } else if (endOfDay < this.date) {
        key = 'nextDay'
      } else {
        key = 'sameElse'
      }

      const { format, prefix = '' } = types[key]

      return [
        prefix,
        format && this.formatDate(types[key] || 'daymonthyearnumeric'),
      ]
        .filter(Boolean)
        .join('')
    },
    differenceDay(types) {
      const diff = this.difference('day', true)
      const roundedDiff = Math.round(diff)
      let key

      switch (roundedDiff) {
        case 0:
          key = 'sameDay'
          break

        case 1:
          key = 'lastDay'
          break

        case -1:
          key = 'nextDay'
          break

        default:
          key = 'sameElse'
      }

      const { format, prefix = '' } = types[key]

      return [
        prefix,
        format && this.formatDate(types[key] || 'daymonthyearnumeric'),
      ]
        .filter(Boolean)
        .join('')
    },
    formatDate(format) {
      return formatDate(
        format,
        createDate(this.date, getDatePickerFormat(this.inputType))
      )
    },
    fromNow() {
      const seconds = this.difference('second')
      const minutes = this.difference('minute')
      const hours = this.difference('hour')
      const days = this.difference('day')

      const format = (interval, word) => {
        const suffix = interval === 1 ? '' : 's'

        return `${interval} ${word + suffix} ago`
      }

      if (seconds === 0) {
        return 'Just now'
      } else if (seconds > 0 && seconds < 60) {
        return format(seconds, 'second')
      } else if (minutes < 60) {
        return format(minutes, 'minute')
      } else if (hours < 24) {
        return format(hours, 'hour')
      } else if (days < 7) {
        return format(days, 'day')
      } else {
        return this.formatDate('daymonthyeartime')
      }
    },
  },
}
</script>

<template>
  <span @click="$emit('click')">
    {{ formattedDate }}
  </span>
</template>
