<script>
import BaseAction from '/~/components/base/action/base-action.vue'
import WgtImage from '../wgt/wgt-image.vue'

export default {
  name: 'widget-australiapost-home-banner',
  components: {
    BaseAction,
    WgtImage,
  },
  props: {
    bannerButton: {
      type: Object,
      default: () => ({}),
    },
    imageLeftSide: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    borderColor: {
      type: String,
      default: '',
    },
    buttonColor: {
      type: String,
      default: '',
    },
    buttonTextColor: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div
    class="-mx-5 sm:mx-auto sm:flex sm:h-[352px] sm:overflow-hidden sm:rounded-lg sm:shadow"
    :class="{ 'flex-row-reverse': imageLeftSide }"
  >
    <div class="flex items-center sm:w-1/2" :style="{ backgroundColor }">
      <div
        class="mx-5 my-10 border-l-3 pl-[30px] xs:my-16 xs:ml-16"
        :style="{ borderColor }"
      >
        <div>
          <span
            class="text-3xl font-semibold leading-tight sm:text-4xl"
            :style="{ color: titleColor }"
            v-html="content.title"
          />
        </div>
        <div class="mt-5">
          <span
            class="inline-block leading-none"
            :style="{ color: textColor }"
            v-html="content.text"
          />
        </div>
        <div>
          <base-action
            v-if="bannerButton.enabled"
            v-bind="bannerButton"
            origin="banner"
            appearance="link"
          >
            <button
              class="button mt-[30px] h-11 w-full rounded-lg px-[35px] font-bold sm:w-auto"
              :style="{
                backgroundColor: buttonColor,
                color: buttonTextColor,
              }"
              type="button"
              v-html="bannerButton.label"
            />
          </base-action>
        </div>
      </div>
    </div>
    <wgt-image class="sm:w-1/2" :image="image" />
  </div>
</template>
