<script setup lang="ts">
import { computed } from 'vue'
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'

const props = withDefaults(
  defineProps<{
    physical?: boolean
    giftCard?: boolean
    gift?: boolean
    mobileShort?: boolean
    iconSize?: number | string | null
    giftIcon?: string
    digitalIcon?: string
    physicalIcon?: string
    physicalByLabel?: string
    showDelivery?: boolean
  }>(),
  {
    physical: false,
    giftCard: false,
    gift: false,
    mobileShort: false,
    iconSize: null,
    giftIcon: 'plain/gift',
    digitalIcon: 'plain/ewallet',
    physicalIcon: 'plain/truck',
    physicalByLabel: 'supplier',
    showDelivery: true,
  }
)

const { ewalletLabels } = useCms()

const hasGift = computed(() => {
  return props.gift && props.giftCard
})

const title = computed(() => {
  let type
  let postfix
  const isMobile = props.mobileShort && ui.mobile

  if (props.physical) {
    type = 'Physical'

    if (props.giftCard) {
      postfix = 'delivered by AusPost'
    } else {
      postfix = `delivered by ${props.physicalByLabel}`
    }
  } else {
    type = 'Digital'
    postfix = `delivered to ${ewalletLabels.value.ewallet}`
  }

  if (isMobile) {
    return postfix[0].toUpperCase() + postfix.slice(1)
  }

  return `${type} product ${postfix}`
})
</script>

<template>
  <div class="flex items-center leading-tight">
    <template v-if="props.physical">
      <base-icon
        v-if="hasGift"
        :size="props.iconSize || 'md'"
        class="mr-2.5"
        :svg="props.giftIcon"
      />
      <base-icon
        v-else-if="props.showDelivery"
        :size="props.iconSize || 'md'"
        :svg="props.giftCard ? 'auspost' : props.physicalIcon"
        class="mr-2.5"
      />

      <slot name="physical">
        <span>
          {{ title }}
        </span>
      </slot>
    </template>
    <template v-else>
      <base-icon
        v-if="hasGift"
        :size="props.iconSize || 'md'"
        :svg="props.giftIcon"
        class="mr-[15px]"
      />
      <base-icon
        v-else-if="props.showDelivery"
        :size="props.iconSize || 'md'"
        :svg="props.digitalIcon"
        class="mr-2.5"
      />

      <slot name="digital">
        <span>
          {{ title }}
        </span>
      </slot>
    </template>
  </div>
</template>
