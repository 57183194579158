import type { CMSPageId } from '/~/types/cms/page'
import { RewardsExtension } from '/~/core/extension'
import mdl from '/~/core/mdl'
import modals from './modals'

mdl.register(modals)

class DiningExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  setRoutes() {
    return [
      {
        path: '/services',
        name: 'services',
        props: true,
        meta: {
          title: 'Services',
          page: 'services' as CMSPageId,
        },
        component: () => import('./views/services.vue'),
      },
    ]
  }
}

export default DiningExtension
