<script>
export default {
  name: 'competition-terms',
  props: {
    competition: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div v-if="competition.terms" class="markup" v-html="competition.terms" />
</template>
