<script>
import BoxDefault from './box-default.vue'
import BoxReported from './box-reported.vue'

export default {
  name: 'rec-post-box',
  props: {
    feed: {
      type: Object,
      default: null,
    },
    post: {
      type: Object,
      required: true,
    },
    showComments: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    component() {
      if (this.isReported) return BoxReported
      return BoxDefault
    },
    isReported() {
      return this.$route.params.feedId === 'reported-posts'
    },
  },
  methods: {
    clickPost(e) {
      const userId = e.target.dataset.userId

      if (userId) {
        this.$router.replace({ query: { recProfileId: userId } })
      }
    },
  },
}
</script>

<template>
  <component
    :is="component"
    :post="post"
    :feed="feed"
    :show-comments="showComments"
    @click="clickPost"
  />
</template>
