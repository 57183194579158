<script>
import { computed } from 'vue'
import { cardCode as getCardCode } from '/~/utils/cards'
import { formatPoints } from '/~/utils/points'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

export default {
  name: 'item-payment-method',
  components: {
    BaseIcon,
  },
  props: {
    method: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { paymentMethods } = usePaymentMethods()
    const { translate } = useLocalization()

    const paymentMethod = computed(() => {
      return (
        paymentMethods.value.find((paymentMethod) => {
          return paymentMethod.id === props.method.id
        }) ?? {}
      )
    })
    const isPoints = computed(() => {
      return paymentMethod.value.type === PaymentMethodType.points
    })
    const isCard = computed(() => {
      return paymentMethod.value.type === PaymentMethodType.creditCard
    })
    const isBankAccount = computed(() => {
      return paymentMethod.value.type === PaymentMethodType.bankAccount
    })
    const isEwallet = computed(() => {
      return paymentMethod.value.type === PaymentMethodType.eWallet
    })
    const cardCode = computed(() => {
      return getCardCode(paymentMethod.value)
    })
    const isMastercard = computed(() => {
      return cardCode.value === 'mastercard'
    })
    const isVisa = computed(() => {
      return cardCode.value === 'visa'
    })
    const isAmex = computed(() => {
      return cardCode.value === 'amex'
    })
    const icon = computed(() => {
      if (isPoints.value) {
        return 'v2/custom/points-token'
      } else if (isMastercard.value) {
        return 'billpay/payment-methods/mastercard'
      } else if (isVisa.value) {
        return 'billpay/payment-methods/visa'
      } else if (isAmex.value) {
        return 'billpay/payment-methods/amex'
      } else if (isBankAccount.value) {
        return 'v2/custom/bank'
      } else if (isEwallet.value) {
        return 'plain/ewallet'
      }
      return null
    })
    const iconSize = computed(() => {
      if (isPoints.value) {
        return 16
      } else if (isBankAccount.value) {
        return 12
      }

      return 20
    })
    const iconClasses = computed(() => {
      if (isBankAccount.value) {
        return 'bg-eonx-neutral-600 text-white'
      } else if (isPoints.value) {
        return 'text-yellow-500'
      }

      return null
    })
    const text = computed(() => {
      if (isCard.value) {
        return `**** **** **** ${(paymentMethod.value.number ?? '').slice(-4)}`
      } else if (isBankAccount.value) {
        return translate('bankAccount.details', {
          acc: `*** *** *${paymentMethod.value?.accountNumber.slice(-2)}`,
          bsb: `*** ${paymentMethod.value?.bsb.slice(-3)}`,
        })
      } else if (isPoints.value) {
        return `${formatPoints(props.method.amount)} PTS`
      }

      return ''
    })

    return {
      paymentMethod,
      isBankAccount,
      icon,
      iconSize,
      iconClasses,
      text,
    }
  },
}
</script>

<template>
  <div class="flex items-center text-sm">
    <div
      v-if="isBankAccount"
      class="mr-[5px] flex h-5 w-5 items-center justify-center rounded-full"
      :class="iconClasses"
    >
      <base-icon :svg="icon" :size="iconSize" />
    </div>
    <base-icon
      v-else
      :svg="icon"
      :size="iconSize"
      class="mr-[5px]"
      :class="iconClasses"
    />
    <span class="whitespace-nowrap">
      {{ text }}
    </span>
  </div>
</template>
