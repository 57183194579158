<script setup lang="ts">
import StatementsListItem from '/~/extensions/scheduled-payments/components/list/item-statements.mobile.vue'
import Item from '/~/extensions/scheduled-payments/components/list/item.vue'
import { formatDate } from '/~/utils/format/date'
import { pluralize } from '/~/utils/format/string'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs-v3.vue'
import EmptyState from '/~/components/empty-state/empty-state.vue'
import { useLocalization } from '/~/composables/localization'
import { useScheduledPaymentsList } from '/~/composables/scheduled-payments'

const props = withDefaults(
  defineProps<{
    type?: 'statements' | 'payments'
  }>(),
  {
    type: 'statements',
  }
)

const {
  loading,
  selectedTabKey,
  isScheduledPaymentsLoading,
  isStatementsTabActive,
  isPaymentsTabActive,
  scheduledStatementAccounts,
  scheduledPayments,
  scheduledPaymentOrdersByDate,
  onMakePaymentClicked,
  onEnableDirectDebitClicked,
} = useScheduledPaymentsList(props)

const { translate } = useLocalization()

const directDebitsTitle = pluralize(2, translate('directDebit.text') as string)
const directDebitTitle = `${translate('directDebit.text')}`
</script>

<template>
  <base-aside-page
    title="Scheduled payments"
    :back="{ name: 'home' }"
    no-padding
  >
    <base-tabs v-model="selectedTabKey" look="v2" class="bg-white">
      <base-tab :name="directDebitTitle" value="statements" keep-alive />
      <base-tab name="Once off" value="payments" keep-alive />
    </base-tabs>
    <div class="h-full bg-eonx-neutral-50">
      <base-loader
        v-if="isScheduledPaymentsLoading || loading"
        class="w-full p-[30px]"
      />
      <template v-else>
        <div class="flex w-auto grow flex-col overflow-y-auto">
          <div class="w-full space-y-5 py-6">
            <template
              v-if="isStatementsTabActive && scheduledStatementAccounts.length"
            >
              <statements-list-item
                v-for="item in scheduledStatementAccounts"
                :key="item.number || item.id"
                :account="item"
                class="mx-5"
              />
            </template>
            <template
              v-else-if="isPaymentsTabActive && scheduledPayments.length"
            >
              <div
                v-for="(payments, scheduledAt) of scheduledPaymentOrdersByDate"
                :key="scheduledAt"
                class="w-full space-y-5 px-5"
              >
                <div class="font-bold text-eonx-neutral-600">
                  {{ formatDate('daymonth', scheduledAt) }}
                </div>
                <item
                  v-for="payment of payments"
                  :key="payment.number || payment.id"
                  :payment="payment"
                />
              </div>
            </template>
            <empty-state
              v-else-if="isStatementsTabActive"
              icon="heroicons/solid/calendar"
              class="flex h-full grow flex-col justify-center px-6"
            >
              <template #title>
                <div class="pt-1">No {{ directDebitsTitle }} listed</div>
              </template>
              <template #description>
                <div>
                  Setup a {{ directDebitTitle }} in Accounts & statements to
                  ensure your statements are paid on time
                </div>
                <div class="mt-8">
                  <base-button @click="onEnableDirectDebitClicked">
                    Accounts & statements
                  </base-button>
                </div>
              </template>
            </empty-state>
            <empty-state
              v-else-if="isPaymentsTabActive"
              icon="heroicons/solid/calendar"
              class="flex h-full grow flex-col justify-center px-6"
            >
              <template #title>
                <div class="pt-1">No scheduled payments listed</div>
              </template>
              <template #description>
                <div>Manage your scheduled payments with ease</div>
                <div class="mt-8">
                  <base-button @click="onMakePaymentClicked">
                    Make a payment
                  </base-button>
                </div>
              </template>
            </empty-state>
          </div>
        </div>
      </template>
    </div>
  </base-aside-page>
</template>
