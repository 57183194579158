<script setup lang="ts">
import TermsConditions from '/~/views/terms/terms-conditions.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { useUI } from '/~/composables/ui'

const props = defineProps<{
  back?: 'close'
}>()

const { hideEwalletMenu } = useUI()

const onBack = props.back === 'close' ? hideEwalletMenu : undefined
</script>

<template>
  <base-aside-page
    title="Terms & Conditions"
    no-padding
    class="bg-white"
    :back="onBack"
  >
    <terms-conditions class="px-5 pb-5" />
  </base-aside-page>
</template>
