<script setup lang="ts">
import { Timezone } from 'timezones.json'
import { ref, computed } from 'vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import { useInput } from '/~/composables/base/use-input'
import './datepicker-timezone.css'

const props = withDefaults(
  defineProps<{
    onlyAustralia?: boolean
    value?: string
    disabled?: boolean
    label?: string
    floated?: boolean
    error?: string
  }>(),
  {
    onlyAustralia: true,
  }
)

const emit = defineEmits(['input'])

const { isFocused } = useInput(props, emit)

const rawTimezonesList = ref<Timezone[]>([])
const loading = ref(true)

const underscoreRE = /_/g

const timezonesList = computed(() => {
  const filteredTimezone = handleFilter()
  const base = [
    {
      text: 'Select a timezone',
      value: '',
    },
  ]

  return filteredTimezone.reduce((acc, tz) => {
    const { offset, utc, abbr } = tz

    utc.forEach((zoneName) => {
      if (zoneName.indexOf('GMT') === -1) {
        const friendlyZoneName = zoneName.replace(underscoreRE, ' ')
        let text = `${friendlyZoneName}, ${abbr}`

        if (offset >= 0) {
          text += `, +${offset}`
        } else {
          text += `, ${offset}`
        }

        return acc.push({
          text,
          value: zoneName,
        })
      }
    })

    return acc
  }, base)
})

const timezonePlaceholder = computed(() => {
  if (loading.value) {
    return 'Loading timezones...'
  }

  return 'Select a timezone'
})

const handleFilter = () => {
  if (!props.onlyAustralia) return rawTimezonesList.value

  return rawTimezonesList.value.filter((timezone) => {
    const utc = timezone.utc
    const regex = /Australia\/(.*)/g

    if (utc.some((e) => regex.test(e))) {
      return timezone
    }
  })
}

// Lifecycle hook
;(async () => {
  loading.value = true
  /*
    Timezone entry example
    {
    value: 'AUS Eastern Standard Time',
    abbr: 'AEST',
    offset: 10,
    isdst: false,
    text: '(UTC+10:00) Canberra, Melbourne, Sydney',
    utc: ['Australia/Melbourne', 'Australia/Sydney'],
  } */
  const timezones = await import('timezones.json')

  rawTimezonesList.value = timezones.default
  loading.value = false
})()
</script>

<template>
  <base-select
    v-bind="$attrs"
    class="datepicker-timezone"
    arrow-icon="plain/chevron-bottom"
    :value="value"
    :options="timezonesList"
    :disabled="disabled || loading"
    :placeholder="timezonePlaceholder"
    :label="label"
    :floated="floated"
    :expanded="isFocused || !!value || !!timezonePlaceholder"
    :error="error"
    @input="$emit('input', $event)"
  />
</template>
