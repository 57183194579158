export class UploadableFile {
  id
  file
  fileType
  uploading = false
  uploaded = false
  loaded = 0
  loadedPercents = 0
  total = 0
  error
  cancel = function () {
    console.warn(
      `cancel() method for UploadableFile with name ${this.name} is not implemented`
    )
  }

  constructor(file) {
    this.file = file
    this.fileType = '.' + file.name?.split('.').pop()
  }
}

export default UploadableFile
