<script lang="ts">
import min from 'lodash-es/min'
import { RewardsType } from '/~/types/api'
import ui from '/~/core/ui'
import ProductHeader from '/~/extensions/giftcards/components/product/header/product-header.vue'
import ProductTotal from '/~/extensions/giftcards/components/product/total/product-total.vue'
import { useVerifications } from '/~/extensions/otp/composables'
import { formatDollar } from '/~/utils/format/money'
import BaseButton from '/~/components/base/button/base-button'
import BaseCounter from '/~/components/base/counter/base-counter.vue'
import BaseRange from '/~/components/base/range/base-range.vue'
import { useCart } from '/~/composables/cart'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'

export default {
  name: 'estore-product-summary',
  components: {
    BaseRange,
    ProductTotal,
    ProductHeader,
    BaseButton,
    BaseCounter,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    originalPrice: {
      type: Number,
      default: 0,
    },
    newPrice: {
      type: Number,
      default: 0,
    },
    shippingTax: {
      type: Number,
      default: 0,
    },
    instock: {
      type: Boolean,
      default: true,
    },
    maxPointsDiscount: {
      type: Number,
      default: 0,
    },
    maxDollarDiscount: {
      type: [String, Number],
      default: 0,
    },
    retailer: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { pointsBalance } = usePoints()
    const { addCartItem, reservedCartPoints } = useCart()
    const {
      estoreSlider,
      estorePointsRatio,
      isQuickBuyEnabled,
      isAddToCartEnabled,
      isEstoreDiscountEnabled,
      isBillPaymentsTemplate,
    } = useProvider()
    const { toggleCartMenu, titleRef } = useUI()
    const { withVerificationsCheck } = useVerifications()

    return {
      titleRef,
      pointsBalance,
      addCartItem,
      reservedCartPoints,
      formatDollar,
      estoreSlider,
      estorePointsRatio,
      isEstoreDiscountEnabled,
      toggleCartMenu,
      isQuickBuyEnabled,
      isAddToCartEnabled,
      isBillPaymentsTemplate,
      ui,
      withVerificationsCheck,
    }
  },
  data() {
    return {
      quantity: 1,
      quantityMin: 1,
      quantityMax: 10,
      adding: false,
      minValue: 0,
      maxValue: 0,
      chosenDiscountPoints: 0,
      pointsLimit: null,
    }
  },
  computed: {
    displayPoints() {
      return false
    },
    price() {
      return this.originalPrice.toFixed(2)
    },
    discount() {
      return this.isEstoreDiscountEnabled && !this.estorePointsRatio
    },
    discountedPrice() {
      if (!this.isEstoreDiscountEnabled) {
        return this.newPrice
      } else if (!this.estorePointsRatio) {
        return this.newPrice - this.maxDollarDiscount
      } else {
        return (
          this.newPrice -
          this.chosenDiscountPoints * (1 / this.estorePointsRatio)
        )
      }
    },
    total() {
      return (this.discountedPrice * this.quantity).toFixed(2)
    },
    totalWithShippingFees() {
      return (
        (this.discountedPrice + this.shippingTax) *
        this.quantity
      ).toFixed(2)
    },
    isAddButtonDisabled() {
      return !this.instock
    },
    isNotEnoughPoints() {
      return (
        !this.isEstoreDiscountEnabled &&
        this.maxPointsDiscount * this.quantity > this.pointsBalance
      )
    },
    pointsMax() {
      const pointsByPriceDiscount =
        this.maxDollarDiscount / (1 / this.estorePointsRatio)

      return parseInt(min([this.maxPointsDiscount, pointsByPriceDiscount]))
    },
    limit() {
      return (
        Math.floor(this.pointsBalance / this.quantity) - this.reservedCartPoints
      )
    },
    pointsLeft() {
      return (
        this.pointsBalance -
        this.chosenDiscountPoints * this.quantity -
        this.reservedCartPoints
      )
    },
  },
  watch: {
    quantity(qty) {
      if (this.chosenDiscountPoints * qty > this.pointsBalance) {
        this.chosenDiscountPoints = Math.floor(this.pointsBalance / qty)
      }
    },
  },
  methods: {
    async addToCart() {
      const params = {
        quantity: this.quantity,
        id: this.id,
        type: 'estore' as RewardsType,
      }

      this.adding = true

      try {
        await this.addCartItem(params)

        this.$refs.nouislider?.reset()
        this.toggleCartMenu()
        this.quantity = 1
      } catch (error) {
        this.$notify({
          text: error?.data?.message ?? error,
          type: 'error',
          duration: 10000,
        })
      } finally {
        this.adding = false
      }
    },
    addToQuickBuy() {
      const query = {
        id: this.id,
        quantity: this.quantity,
        type: 'estore',
        value: this.totalWithShippingFees,
      }

      this.$router.push({ name: 'quick-buy-checkout', query })
    },
  },
}
</script>

<template>
  <div class="sm:pb-20">
    <div class="px-5 text-center sm:text-left md:px-0">
      <h2
        v-if="retailer"
        class="text-2xl font-bold sm:text-3xl"
        data-cy="estore-retailer"
      >
        {{ retailer }}
      </h2>
      <h2
        ref="titleRef"
        class="text-2xl font-normal text-eonx-neutral-800 !outline-none sm:text-3xl"
        tabindex="-1"
        data-test="header"
        data-cy="estore-name"
      >
        {{ name }}
      </h2>
      <div
        class="mt-[15px] flex items-baseline justify-center text-base sm:justify-start"
        data-test="price"
      >
        <span class="text-3xl font-bold">
          {{ formatDollar(discountedPrice) }}
        </span>
        <span
          v-if="discount"
          class="ml-2.5 text-xl text-eonx-neutral-600 line-through"
        >
          {{ formatDollar(originalPrice) }}
        </span>
      </div>
      <product-header class="mt-5" :show-type="false" />
    </div>

    <div v-if="estoreSlider">
      <base-range
        v-model="chosenDiscountPoints"
        :min="0"
        :max="pointsMax"
        :limit="limit"
        :disabled="limit > pointsMax"
        :formatter="`{value}`"
        data-test="slider"
      />
    </div>
    <div v-if="displayPoints" class="mt-5">
      My points balance
      <span class="text-lg font-bold">
        {{ pointsLeft }}
      </span>
    </div>
    <div class="mx-5 mb-[30px] border-t border-b sm:mx-0 sm:border-0">
      <div
        class="mx-auto flex max-w-96 items-center justify-between bg-white px-5 py-[15px] text-xl sm:max-w-none sm:px-6"
      >
        <div data-cy="calculation-counter">
          <base-counter
            v-model="quantity"
            :min="1"
            :max="quantityMax"
            data-test="quantity"
            small
          />
        </div>
        <product-total :amount="total" />
      </div>
    </div>
    <div
      class="mt-[30px] flex items-center justify-end space-x-5"
      data-test="control"
    >
      <base-button
        v-if="isAddToCartEnabled"
        ref="addToCardButton"
        :disabled="adding || isAddButtonDisabled || isNotEnoughPoints"
        data-test="addToCard"
        class="flex-1 sm:flex-none"
        :look="isQuickBuyEnabled ? 'outlined-color' : 'filled'"
        @click="withVerificationsCheck(addToCart)"
      >
        {{ adding ? 'Loading' : 'Add to cart' }}
      </base-button>
      <base-button
        v-if="isQuickBuyEnabled"
        :disabled="adding || isAddButtonDisabled || isNotEnoughPoints"
        class="flex-1 sm:flex-none"
        @click="withVerificationsCheck(addToQuickBuy)"
      >
        Buy Now
      </base-button>
    </div>
  </div>
</template>
