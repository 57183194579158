<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import CheckoutPay from '/~/views/checkout/components/pay/checkout-pay.vue'
import { useQuickBuyComponent } from '/~/views/quick-buy/checkout.component'

const route = useRoute()
const { isLoading, processing, payDisabled, total, onSubmit } =
  useQuickBuyComponent()

const showPay = computed(
  () =>
    route.name === 'quick-buy-checkout' ||
    route.name === 'quick-buy-payment-methods'
)
</script>

<template>
  <div
    class="flex h-full max-h-full flex-col overflow-hidden overflow-x-hidden"
  >
    <div class="flex-1 overflow-auto p-5">
      <router-view :is-loading="isLoading" />
    </div>
    <div v-if="showPay" class="flex p-4">
      <checkout-pay
        :is-loading="isLoading"
        :processing="processing"
        :pay-disabled="payDisabled"
        :total="total"
        @pay-button-clicked="onSubmit"
      />
    </div>
  </div>
</template>
