<script>
import DefaultBaseAsidePage from '/~/components/base/aside-page/base-aside-page.v1.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  extends: DefaultBaseAsidePage,
})
</script>

<template>
  <div
    class="flex h-full w-full grow flex-col overflow-hidden bg-white font-inter"
    :class="[isStandalone && 'footer-extra']"
  >
    <template v-if="iconHeader">
      <slot name="iconHeader" />
    </template>
    <div v-else class="relative text-eonx-neutral-800">
      <div class="flex h-24 items-center space-x-5 px-[30px]">
        <div class="w-5">
          <button
            v-if="!isBackDisabled"
            class="group relative block"
            @click="onBackClick"
          >
            <div
              class="absolute rounded-full bg-current opacity-0 transition duration-100 ease-in group-hover:opacity-10 group-focus:opacity-20"
              :style="{
                top: 'calc(-2rem / 4)',
                left: 'calc(-2rem / 4)',
                width: 'calc(100% + 2rem / 2)',
                height: 'calc(100% + 2rem / 2)',
              }"
            />
            <base-icon
              class="relative z-20 align-top"
              svg="australiapost/chevron-left"
              :size="24"
            />
          </button>
        </div>
        <div class="flex w-full grow flex-col items-center overflow-hidden">
          <span class="cursor-default text-center text-xl font-bold leading-7">
            {{ title }}
          </span>
          <span class="text-center text-sm leading-tight">
            {{ subtitle }}
          </span>
        </div>
        <div class="w-5">
          <div class="flex items-center justify-center">
            <slot name="action">
              <div
                v-if="action"
                class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white text-primary"
                @click="action"
              >
                <base-icon svg="plus" size="md" />
              </div>
            </slot>
          </div>
        </div>
      </div>
      <slot name="header" />
    </div>

    <div
      class="flex grow flex-col overflow-y-auto"
      :class="[!noPadding && 'p-5 pt-0', bodyClass]"
    >
      <slot />
    </div>
    <div v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>
