<script>
import ItemMobile from './item.mobile.vue'
import ItemDesktop from './item.desk.vue'
import ui from '/~/core/ui'

export default {
  name: 'scheduled-payments-list-item',
  functional: true,
  render(h, ctx) {
    return h(ui.mobile ? ItemMobile : ItemDesktop, ctx.data, ctx.children)
  },
}
</script>
