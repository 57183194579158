<script>
import { computed, ref } from 'vue'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useVerifications } from '/~/extensions/otp/composables'
import BaseButton from '/~/components/base/button/base-button'
import BaseUploadFilesArea from '/~/components/base/upload-files/base-upload-files-area.vue'
import BaseUploadFilesList from '/~/components/base/upload-files/base-upload-files-list.vue'
import { useUpload } from '/~/composables/upload'

export default {
  components: {
    BaseButton,
    BaseUploadFilesArea,
    BaseUploadFilesList,
  },
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    extensions: {
      type: Array,
      default: () => [],
    },
    isListVisible: {
      type: Boolean,
      default: true,
    },
    isActionVisible: {
      type: Boolean,
      default: true,
    },
    isAreaVisible: {
      type: Boolean,
      default: false,
    },
    driver: {
      type: String,
      default: 'UploadcareDriver',
      validator: (v) => /UploadcareDriver|S3Driver/.test(v),
    },
  },
  emits: [
    'files-uploading-started',
    'files-uploaded',
    'files-uploading-error',
    'file-removed',
  ],
  setup(props, { emit }) {
    const {
      uploadableFiles,
      isUploading,
      loadedPercents,
      uploadFiles,
      removeUploadableFile,
      resetUploadableFiles,
    } = useUpload({
      driver: props.driver,
    })

    const { hasUnfinishedVerifications, showVerificationModal } =
      useVerifications()

    const fileInput = ref(null)
    const extensionsString = computed(() =>
      props.extensions.map((item) => `.${item}`).join(',')
    )
    const maximumFilesUploaded = computed(
      () => !props.isMultiple && uploadableFiles.value.length >= 1
    )

    function onDrop(files) {
      onFilesSelected(files)
    }

    function onFileInputChange() {
      onFilesSelected(fileInput.value?.files)
    }

    function showFileDialog() {
      if (isUploading.value || maximumFilesUploaded.value) {
        return
      }

      fileInput.value?.click()
    }

    function checkFilesExtensions(files) {
      if (
        files.some((file) => {
          const fileExtension = file.name?.split('.').pop().toLowerCase()

          return !props.extensions.some(
            (extension) => extension === fileExtension
          )
        })
      ) {
        throw new Error(
          `Only files with the following extensions are allowed: ${extensionsString.value}`
        )
      }
    }

    async function onFilesSelected(files) {
      if (hasUnfinishedVerifications.value) {
        showVerificationModal()
      }

      if (
        !files ||
        files.length === 0 ||
        isUploading.value ||
        maximumFilesUploaded.value ||
        hasUnfinishedVerifications.value
      ) {
        return
      }

      if (!props.isMultiple) {
        files = [files[0]]
      }

      if (files.length > 0) {
        files = Array.from(files)

        try {
          checkFilesExtensions(files)
        } catch (error) {
          emit('files-uploading-error', error)
          return
        }

        try {
          emit('files-uploading-started')
          await uploadFiles(files)
          emit('files-uploaded', uploadableFiles.value)
        } catch (error) {
          emit(
            'files-uploading-error',
            new Error('Upload failed, please try again')
          )
        }
      }
    }

    function removeFile(file) {
      if (file.uploading) {
        modal.show('confirm-v2', {
          props: {
            title: 'Are you sure you want to abort this upload?',
            description:
              'Please confirm whether you wish to abort this upload.',
            confirmLabel: 'Abort',
            icon: {
              path: 'heroicons/solid/exclamation-triangle',
              color: 'text-red-700',
            },
            width: 'xs',
            onConfirm: () => {
              removeUploadableFile(file)
            },
          },
        })
      } else {
        removeUploadableFile(file)
      }
    }

    return {
      fileInput,
      extensionsString,
      uploadableFiles,
      isUploading,
      loadedPercents,
      onDrop,
      onFileInputChange,
      showFileDialog,
      removeFile,
      resetUploadableFiles,
      ui,
    }
  },
}
</script>

<template>
  <div>
    <slot v-if="isListVisible" name="list">
      <base-upload-files-list
        :uploadable-files="uploadableFiles"
        @remove-file="removeFile"
      />
    </slot>
    <slot
      v-if="isActionVisible"
      name="action"
      v-bind="{ isUploading, showFileDialog, loadedPercents }"
    >
      <base-button
        :disabled="isUploading"
        class="w-full"
        @click="showFileDialog"
      >
        {{
          isUploading
            ? `Uploading ${loadedPercents}%`
            : 'Select files to upload'
        }}
      </base-button>
    </slot>
    <slot v-if="isAreaVisible && !ui.mobile" name="area" v-bind="this">
      <base-upload-files-area
        :is-uploading="isUploading"
        :loaded-percents="loadedPercents"
        @drop="onDrop"
        @click="showFileDialog"
      />
    </slot>
    <input
      ref="fileInput"
      type="file"
      :multiple="isMultiple"
      class="hidden"
      :accept="extensionsString"
      @change="onFileInputChange"
    />
  </div>
</template>
