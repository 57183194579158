<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BankAccountWarning from './bank-account-warning.vue'
import modal from '/~/core/mdl'

export default {
  name: 'bank-account-warning-mdl',
  components: {
    BaseMdl,
    BankAccountWarning,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    function cancel() {
      props.onCancel?.()
      modal.hide()
    }

    function submit() {
      props.onConfirm?.()
      modal.hide()
    }

    return {
      cancel,
      submit,
    }
  },
}
</script>

<template>
  <base-mdl width="xs" :layout="false">
    <bank-account-warning :total="total" @cancel="cancel" @submit="submit" />
  </base-mdl>
</template>
