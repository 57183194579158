<script>
/**
 * TODO: remove .native after updating vue-router
 */
import get from 'lodash-es/get'

export default {
  name: 'base-link',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: true,
    },
    current: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    ignoreLocalWarning: {
      type: Boolean,
      default: false,
    },
    allowInsecure: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    target: {
      type: String,
      default: '_blank',
    },
    color: {
      type: String,
      default: 'primary',
      validator: (v) => !v || /primary|secondary|default|none/.test(v),
    },
    activeClass: {
      type: String,
      default: '',
    },
    exactActiveClass: {
      type: String,
      default: '',
    },
    inactiveClass: {
      type: String,
      default: null,
    },
    look: {
      type: String /** underline **/,
      default: '',
    },
  },
  emits: ['click'],
  computed: {
    getClass() {
      if (this.look === 'underline') {
        return 'underline underline-offset-4 inline-block mx-1 text-primary hover:text-primary-darken'
      }

      return 'no-underline'
    },
    routerLinkTo({ name, params }) {
      const to = {
        ...params,
      }

      if (name) {
        to.name = name
      }

      return {
        ...to,
        ...(this.to || {}),
      }
    },
  },
  watch: {
    active() {
      this.validateProps()
    },
  },
  created() {
    this.validateProps()
  },
  mounted() {
    /*
     * Remove tab focus if link contains button
     */
    const firstChildTag = get(this.$el, 'firstElementChild.tagName')

    if (/button/i.test(firstChildTag)) {
      this.$el.setAttribute('tabindex', '-1')
    }
  },
  methods: {
    // Perform more complex prop validations than is possible
    // inside individual validator functions for each prop.
    validateProps() {
      if (!this.active) return

      if (this.href) {
        // Check for non-external URL in href.
        if (
          !this.ignoreLocalWarning &&
          !/^((\w+:)?\/{2})|(tel|mailto):/.test(this.href)
        ) {
          return console.warn(
            `Invalid <BaseLink> href: ${this.href}.\nIf you're trying to link to a local URL, specify provide at least a name or to`
          )
        }
      } else {
        // Check for insufficient props.
        if (!this.ignoreLocalWarning && !this.name && !this.to) {
          return console.warn(
            `Invalid <BaseLink> props:\n\n${JSON.stringify(
              this.$props,

              null,
              2
            )}\n\nEither a \`name\` or \`to\` is required for internal links, or an \`href\` for external links.`
          )
        }
      }
    },
  },
}
</script>

<template>
  <a v-if="!active">
    <slot />
  </a>

  <a
    v-else-if="href"
    v-bind="$attrs"
    :href="href"
    :target="target"
    :class="[
      color === 'primary' && 'text-primary',
      color === 'secondary' && 'text-secondary',
      color === 'default' && 'text-default',
      getClass,
    ]"
    data-base-link
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <router-link
    v-else
    v-bind="$attrs"
    :aria-current="current"
    :to="routerLinkTo"
    :active-class="activeClass"
    :exact-active-class="exactActiveClass"
    :class="{
      'text-primary': color === 'primary',
      'text-secondary': color === 'secondary',
      'text-default': color === 'default',
      [getClass]: true,
    }"
    data-base-link
    @click.native="$emit('click', $event)"
  >
    <slot />
  </router-link>
</template>
