import { RouteConfig } from 'vue-router'
import { unverifiedVerificationsGuard } from '/~/extensions/otp/composables'
import { useActivity } from '/~/composables/activity'
import { useCheckoutRoutes } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'

const { generateCheckoutRoutes } = useCheckoutRoutes()
const { syncState: syncActivityState } = useActivity()
const { translate } = useLocalization()

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/home/home.vue'),
    props: true,
    meta: {
      page: 'home',
      title: 'Home',
    },
    beforeEnter(_, __, next) {
      const { cmsRedirects } = useCms()
      const { homeRedirect = {} } = cmsRedirects.value
      const { enabled = false, value: name, params = {} } = homeRedirect

      if (enabled) {
        next({
          name,
          params,
        })
      } else {
        next()
      }
    },
  },
  generateCheckoutRoutes({
    type: FlowType.payment,
    rootPath: '/payments/payees/pay',
    config: {
      beforeEnter: unverifiedVerificationsGuard,
    },
  }),
  generateCheckoutRoutes({
    type: FlowType.statement,
    rootPath: '/statements/pay',
    config: {
      beforeEnter: unverifiedVerificationsGuard,
    },
  }),
  generateCheckoutRoutes({
    type: FlowType.quickBuy,
    rootPath: '/quick-buy',
    config: {
      beforeEnter: unverifiedVerificationsGuard,
    },
  }),
  generateCheckoutRoutes({
    type: FlowType.purchase,
    config: {
      beforeEnter: unverifiedVerificationsGuard,
    },
  }),
  {
    path: '/payments',
    name: 'payments',
    component: () => import('./views/payments/payments.vue'),
    redirect: { name: 'payments-make' },
    meta: {
      flowType: FlowType.payment,
    },
    children: [
      {
        path: 'make',
        name: 'make',
        component: () =>
          import('./views/payments/payees/payments-new-payee.vue'),
        meta: {
          flowType: FlowType.payment,
        },
        children: [
          {
            path: ':type',
            name: 'make-new-payee',
            meta: {
              title: 'Add Payee',
              flowType: FlowType.payment,
              back: { name: 'payments-make' },
            },
            component: () =>
              import('./views/payments/payees/components/new-payee.vue'),
          },
        ],
      },
      {
        path: 'payee',
        name: 'payments-make',
        component: () => import('./views/payments/make/payments-make.vue'),
        redirect: { name: 'payments-select-payee' },
        meta: {
          flowType: FlowType.payment,
        },
        children: [
          {
            path: 'new-payee/:type?',
            name: 'new-payee',
            meta: {
              title: 'Add Payee',
              flowType: FlowType.payment,
              back: { name: 'payments-make' },
            },
            component: () =>
              import('./views/payments/payees/components/new-payee.vue'),
          },
          {
            path: ':type?',
            name: 'payments-select-payee',
            component: () =>
              import(
                './views/payments/make/components/payees/payees-select.vue'
              ),
            meta: {
              title: 'Select Payees',
              flowType: FlowType.payment,
            },
          },
        ].filter(Boolean),
      },
      {
        path: 'payees',
        component: () => import('./views/payments/payees/payments-main.vue'),
        redirect: { name: 'payments-payees' },
        children: [
          {
            path: '',
            name: 'payments-payees',
            component: () =>
              import('./views/payments/payees/payments-payees.vue'),
            meta: {
              title: 'Payees',
            },
          },
          {
            path: 'new-payee/:type?',
            name: 'payments-new-payee',
            meta: {
              title: 'Add payee details',
              back: { name: 'payments-payees' },
            },
            component: () =>
              import('./views/payments/payees/payments-new-payee.vue'),
          },
        ],
      },
    ].filter(Boolean),
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('./views/activity/list/activity.vue'),
    meta: {
      title: 'Activity',
      page: 'activity',
    },
    beforeEnter(to, from, next) {
      syncActivityState({ to, from })
      next()
    },
  },
  {
    path: '/activity/:id',
    name: 'activity-details',
    props: true,
    meta: {
      title: 'Activity Details',
    },
    component: () => import('./views/activity/details/activity-details.vue'),
  },
  {
    path: '/statements',
    name: 'statements',
    component: () => import('./views/statements/statements-root.vue'),
    meta: {
      title: 'Statements',
    },
    redirect: { name: 'statements-list' },
    children: [
      {
        path: '',
        name: 'statements-list',
        component: () => import('./views/statements/list/statements.vue'),
      },
      {
        path: 'make/:type',
        name: 'statements-new-account',
        meta: {
          title: 'Add Symbion Account',
          back: { name: 'statements' },
        },
        component: () =>
          import('./views/payments/payees/payments-new-payee.vue'),
      },
      {
        path: 'direct-debit/:id',
        name: 'statement-direct-debit',
        meta: {
          title: `${translate('directDebit.text')} Request`,
          back: { name: 'statements' },
        },
        component: () =>
          import(
            './views/statements/direct-debit/statement-direct-debit.mobile.vue'
          ),
      },
      {
        path: ':id',
        name: 'statements-details',
        props: true,
        meta: {
          title: 'Statement Details',
          menuHighlight: 'payments',
        },
        component: () =>
          import('./views/statements/details/statement-details.vue'),
      },
    ].filter(Boolean),
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: () => import('./views/rewards/rewards.vue'),
    redirect: { name: 'giftcards' },
  },
  {
    path: '/fly',
    name: 'fly',
    component: () => import('./views/fly/fly.vue'),
    redirect: { name: 'fly-partners-list' },
    children: [
      {
        path: 'transfer',
        name: 'fly-points-transfer',
        props: true,
        component: () =>
          import('./views/fly/points-transfer/points-transfer.vue'),
        meta: {
          title: 'Fly Points Transfer',
          page: 'airline-partners',
        },
      },
      {
        path: 'transfer/successful/:number',
        name: 'fly-points-transfer-successful',
        props: true,
        component: () =>
          import('./views/fly/points-transfer/points-transfer-successful.vue'),
        meta: {
          title: 'Fly Points Transfer Successful',
        },
      },
      {
        path: 'business',
        name: 'fly-business',
        component: () => import('./views/fly/business/business.vue'),
        meta: {
          title: 'Fly Business',
          page: 'airline-partners',
        },
      },
      {
        path: 'partners',
        component: () => import('./views/fly/partners/partners.vue'),
        children: [
          {
            path: 'list/:state?',
            name: 'fly-partners-list',
            component: () =>
              import('./views/fly/partners/list/partners-list.vue'),
            meta: {
              title: 'Fly Partners',
              page: 'airline-partners',
            },
          },
          {
            path: 'new',
            name: 'fly-partner-new',
            component: () =>
              import('./views/fly/partners/add/partner-add-new.vue'),
            meta: {
              title: 'Fly New Partner',
              page: 'airline-partners',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      title: 'Help',
      footer: true,
    },
    component: () => import('/~/views/help/help.vue'),
  },
].filter(Boolean)

export default routes
