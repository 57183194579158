<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseCard from '/~/components/base/card/base-card.vue'

export default {
  name: 'drawer-photo-id-tile',
  components: {
    BaseIcon,
    BaseCard,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <base-card class="mx-auto w-full max-w-72">
    <div
      class="relative flex h-full flex-col overflow-hidden rounded-xl p-5 text-white shadow"
      :style="{
        background: document.background,
      }"
    >
      <div>
        <div class="flex w-12">
          <base-icon :svg="document.icon" type="image" size="md" />
        </div>
        <div class="mt-2.5 text-xs font-bold">
          {{ document.fullType }}
        </div>
      </div>

      <div class="mt-auto">
        <div class="text-lg font-bold">
          {{ document.fullName }}
        </div>
        <div class="mt-0.5 font-bold">
          {{ document.number }}
        </div>
      </div>
      <div
        class="absolute bottom-0 left-0 origin-center opacity-10"
        :style="{
          transform: 'rotateZ(12deg)',
          transformOrigin: '0% 0%',
          width: '130%',
          height: '45%',
          background:
            'linear-gradient(94.4deg, #fff 19.22%, rgba(255, 255, 255, 0.4) 77.44%, rgba(255, 255, 255, 0) 83.65%)',
        }"
      />
    </div>
  </base-card>
</template>
