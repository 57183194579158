<script setup lang="ts">
import { computed } from 'vue'
import returnTips from '/~/extensions/online-offers/modals/return-tips'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useCms } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'

const { ewalletLabels } = useCms()
const { taxationLabel } = useProvider()

const items = computed(() => [...returnTips])
</script>

<template>
  <base-mdl width="sm" fullscreen="mobile" title="Points Tips">
    <ul class="ml-5 list-disc space-y-2.5">
      <li v-for="(item, index) in items" :key="index">
        {{ item }}
      </li>
      <li data-testid="taxation-label">
        Points are only earned on eligible purchases, and may exclude freight,
        delivery, taxes and {{ taxationLabel }}.
      </li>
      <li>
        Subject to retailers terms, points may not be earned with the use of
        coupons, eVouchers or gift cards for payment.
      </li>
      <li>
        If the goods or services are cancelled, returned or refunded, points
        will not be earned.
      </li>
      <li>
        Points earn rate is subject to change and is based on the rate at the
        day of purchase.
      </li>
      <li>
        Points processing & allocation can take up to 120 days subject to
        retailer's terms, however please be aware that delays may occur due to
        public holidays or other unforeseen reasons.
      </li>
      <li>
        Points will be distributed directly into
        {{ ewalletLabels.yourEwallet }}.
      </li>
      <li>
        If you have closed your account before processing points back, this will
        be forfeited.
      </li>
    </ul>
  </base-mdl>
</template>
