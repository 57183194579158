<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import AwardNominee from '/~rec/views/recognition/awards/award-nominee.vue'
import BaseBack from '/~/components/base/back/base-back.vue'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'award-nominee-root',
  components: {
    AwardNominee,
    BaseBack,
  },
  props: {
    awardStatus: {
      type: String,
      default: '',
    },
    awardId: {
      type: String,
      default: '',
    },
    nomineeId: {
      type: String,
      default: '',
    },
    currentAwardId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { getMember } = useGroups()

    return {
      getMember,
      ui,
    }
  },
  computed: {
    nominee() {
      return this.getMember(this.nomineeId, 'orgMemberId') || {}
    },
    backRoute() {
      return {
        name: 'rec-award',
        params: {
          awardStatus: this.awardStatus,
          awardId: this.awardId,
          selectedTab: 'leaderboard',
        },
      }
    },
  },
  created() {
    if (!ui.desktop) {
      this.openMobileView()
    }
  },
  methods: {
    openMobileView() {
      modal.show('rec-award-nominee-mobile', {
        props: {
          awardStatus: this.awardStatus,
          awardId: this.awardId,
          nomineeId: this.nomineeId,
          title: this.nominee?.fullName,
          currentAwardId: this.currentAwardId,
        },
      })
    },
  },
}
</script>

<template>
  <div class="mx-auto h-full w-full max-w-screen-md p-[30px]">
    <base-back :to="backRoute" />
    <award-nominee
      v-if="ui.desktop"
      :award-status="awardStatus"
      :award-id="awardId"
      :nominee-id="nomineeId"
      :nominee="nominee"
      :current-award-id="currentAwardId"
    />
  </div>
</template>
