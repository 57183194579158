<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.vue'
import DrawerModal from '/~/components/drawer/components/drawer-modal.vue'

export default {
  name: 'profile-qr-code-manually-modal',
  components: {
    DrawerIconHeader,
    DrawerModal,
    BaseInput,
    BaseButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: '',
    }
  },
  methods: {
    hide() {
      this.$emit('update:visible', false)
    },
    onSubmit() {
      this.$emit('submit', this.code)
      this.hide()
    },
  },
}
</script>

<template>
  <drawer-modal :visible="visible" @hide="hide">
    <template #iconHeader>
      <drawer-icon-header
        title="Enter QR Code manually"
        icon="menu/qr-code-color"
        :action="hide"
      />
    </template>

    <div class="px-6 pb-6">
      <base-input
        v-model="code"
        class="mb-0 pt-5 pb-2.5"
        :mask="{ mask: '********' }"
        input-class="text-center"
        placeholder="xxxxxxxx"
      />
      <base-button
        class="mt-[30px] w-full"
        :disabled="code.length === 0"
        @click="onSubmit"
      >
        Confirm
      </base-button>
    </div>
  </drawer-modal>
</template>
