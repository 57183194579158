<script>
export default {
  name: 'section-category-tag',
  props: {
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div
    class="relative flex items-center justify-center self-start overflow-hidden rounded-3xl px-2.5 py-[5px]"
  >
    <div
      class="absolute inset-0 h-full w-full opacity-10"
      :style="`backgroundColor:${color}`"
    />
    <span class="text-xs font-bold" :style="`color:${color}`">
      {{ text }}
    </span>
  </div>
</template>
