<script>
import { ValidationObserver } from 'vee-validate'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useAuth } from '/~/composables/auth'
import { useForm } from '/~/composables/base/use-form'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import AuthLayout from '/~/layouts/auth/layout-auth.vue'
import AuthLogo from '/~/views/auth/auth-logo.vue'

export default {
  name: 'reset-password',
  components: {
    AuthLogo,
    AuthLayout,
    BaseInput,
    BaseLink,
    BaseButton,
    ValidationObserver,
  },
  setup() {
    const { createPassword } = useAuth()
    const { customerServiceNumber, generalEmail, isClaimsEnabled } =
      useProvider()
    const { fetchUserClaimStatus, fetchUserDetails } = useUser()
    const { validationObserverRef } = useForm()

    return {
      createPassword,
      customerServiceNumber,
      generalEmail,
      isClaimsEnabled,
      fetchUserClaimStatus,
      fetchUserDetails,
      validationObserverRef,
    }
  },
  data() {
    return {
      processing: false,
      email: '',
      password: '',
      repeatPassword: '',
      backendErrors: {},
    }
  },
  computed: {
    disabled() {
      return this.processing
    },
    submitDisabled() {
      return (
        this.hasErrors || (!this.password.length && !this.repeatPassword.length)
      )
    },
    hasErrors() {
      return this.errors && Boolean(this.errors.count())
    },
    contactPhone() {
      return this.customerServiceNumber ?? '1300 059 257'
    },
    contactEmail() {
      return this.generalEmail ?? 'help@ewallet.com.au'
    },
  },
  methods: {
    async submit() {
      this.processing = true
      const payload = {
        password: this.password,
        confirmPassword: this.repeatPassword,
      }

      try {
        const { data } = await this.createPassword(
          this.$route.params.token,
          payload
        )

        if (!data) {
          return
        }

        this.$router.push({ name: 'auth-login' })
      } catch (error) {
        console.error('auth', error)
      } finally {
        this.processing = false
      }
    },
    onPrivacyClick() {
      modal.show('privacy-policy')
    },
  },
}
</script>

<template>
  <auth-layout>
    <auth-logo />
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="m-auto mt-16 w-full max-w-screen-xs p-5 md:mt-0"
        @submit.prevent="handleSubmit(submit)"
      >
        <base-input
          ref="password"
          v-model="password"
          :validation="{
            rules: 'required|password',
            name: 'New password',
            vid: 'password',
          }"
          :disabled="disabled"
          :error="backendErrors.email"
          name="password"
          label="New password"
          type="password"
          required
          autocomplete="new-password"
        />
        <base-input
          v-model="repeatPassword"
          :validation="{
            rules: 'required|confirmed:@password,New Password',
            name: 'Confirm New Password',
            vid: 'confirmation',
          }"
          :disabled="disabled"
          :error="backendErrors.email"
          name="repeatPassword"
          label="Confirm new password"
          type="password"
          required
        />
        <base-button
          :disabled="submitDisabled"
          :loading="processing"
          class="mx-auto mt-5 mb-5 w-full"
          type="submit"
        >
          Reset Password
        </base-button>
        <div class="text-center">
          Back to
          <base-link data-cy="back" name="auth-login">Login</base-link>
        </div>
        <div class="mt-[30px] text-center">
          <p class="mb-5 sm:mb-0">Having trouble logging in?</p>
          <p>
            Please call
            <base-link :href="`tel:${contactPhone}`">
              {{ contactPhone }}
            </base-link>
            or
            <br class="sm:hidden" />
            email
            <base-link :href="`mailto:${contactEmail}`">
              {{ contactEmail }}
            </base-link>
          </p>
          <br />

          <base-button look="link" @click="onPrivacyClick()">
            View Privacy Policy
          </base-button>
        </div>
      </form>
    </validation-observer>
  </auth-layout>
</template>
