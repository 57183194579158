import Bugsnag from '@bugsnag/js'
import BugsnagVue from '@bugsnag/plugin-vue'
import Vue from 'vue'
import BugsnagIgnore from './bugsnag-ignore.json'

const appVersion = import.meta.env.VITE_APP_BUILD_VERSION || 'last'

if (!import.meta.env.DEV) {
  Bugsnag.start({
    apiKey: eonx.keys.bugsnag,
    redactedKeys: [
      'verificationCode',
      'expiryMonth',
      'expiryYear',
      'cardholder',
      'number',
      /^password$/i,
    ],
    appVersion: appVersion,
    plugins: [new BugsnagVue(Vue)],
    releaseStage: eonx.env ?? undefined,
    onError(event) {
      console.error(event.errors)

      const isIgnorable = BugsnagIgnore.some((re) =>
        new RegExp(re).test(event.errors[0].errorMessage)
      )

      if (isIgnorable) return false

      const isIgnorableByStacktrace = /\/node_modules\//.test(
        event.errors[0].stacktrace[0].file
      )

      if (isIgnorableByStacktrace) return false

      event.setUser(eonx.user?.externalId)
      event.addMetadata('provider', {
        subdomain: eonx.provider.subdomain,
      })
    },
  })
}
