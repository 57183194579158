<script>
import ActivityDetailsItem from './components/activity-details-item.vue'

export default {
  name: 'activity-details',
  components: {
    ActivityDetailsItem,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
}
</script>

<template>
  <activity-details-item :id="id" />
</template>
