<script>
import { ref } from 'vue'
import { useDining } from '/~/extensions/dining/composables'
import SelectField from '/~/components/mobile-filter/select-field.vue'
import DiningFilterV1 from './dining-filter.desktop.vue'

export default {
  name: 'dining-filter-mobile',
  components: {
    SelectField,
  },
  extends: DiningFilterV1,
  setup() {
    const {
      mapState,
      searchInput,
      cuisines,
      selectedCuisine,
      features,
      selectedFeatures,
      priceRanges,
      selectedPriceRanges,
      selectedState,
      isFiltersSelected,
      resetFilters,
      getSearchResults,
      getRelativeAddress,
      syncState,
      getLocations,
    } = useDining()

    const localSearchQuery = ref(searchInput.value)

    function resetLocalFilters() {
      localSearchQuery.value = searchInput.value
    }

    function applyFilters() {
      searchInput.value = localSearchQuery.value
      getSearchResults(searchInput.value)
    }

    return {
      ...DiningFilterV1.setup?.(...arguments),
      mapState,
      searchInput,
      cuisines,
      selectedCuisine,
      features,
      selectedFeatures,
      priceRanges,
      selectedPriceRanges,
      selectedState,

      localSearchQuery,

      isFiltersSelected,
      resetFilters,
      resetLocalFilters,
      applyFilters,

      getSearchResults,
      getRelativeAddress,
      syncState,
      getLocations,
    }
  },
  computed: {
    priceRangesFormatted() {
      return Array.isArray(this.priceRanges)
        ? this.priceRanges?.map((priceRange) => {
            priceRange.label = '$'.repeat(+priceRange.id)

            return priceRange
          })
        : []
    },
  },
  methods: {
    clearFilters() {
      this.clear()
      this.resetLocalFilters()
    },
  },
}
</script>

<template>
  <ew-filters
    title="Filters"
    :is-filters-selected="isFiltersSelected"
    @opened="resetLocalFilters"
    @clear="clearFilters"
    @close="resetLocalFilters"
    @apply="applyFilters"
  >
    <div class="space-y-4 py-4">
      <toggle-map class="w-full" @change="onMapToggle" />

      <base-select-async
        v-if="isMapVisible"
        :fetch="getRelativeAddress"
        icon="plain/search"
        placeholder="Search locations"
        class="grow"
        look="rounded"
        @change="onLocationSelect"
      />
      <base-input
        v-else
        v-model="localSearchQuery"
        placeholder="Search restaurant"
        class="grow pt-0"
        look="rounded"
        icon-plain
        nolabel
        clearable
      >
        <template #icon>
          <div class="ml-[15px] mr-2.5 flex items-center justify-center">
            <base-icon
              svg="plain/search-v2"
              class="text-eonx-neutral-600"
              :size="19"
            />
          </div>
        </template>
      </base-input>

      <select-field
        v-model="state"
        title="State"
        :options="stateOptions"
        :empty-option="stateOptions[0]"
        @input="onStateChanged"
      />
      <select-field
        :value="selectedCuisine"
        title="Cuisine"
        :options="
          (cuisines || []).map((category) => {
            return {
              text: category.label,
              value: category.id || '',
            }
          })
        "
        @input="onCuisineInput"
      />
      <select-field
        v-if="features.length > 0"
        :value="selectedFeatures"
        title="Amenities"
        :options="
          features.map((feature) => {
            return {
              ...feature,
              id: feature.label,
            }
          })
        "
        is-multiple
        @input="onFeatureInput"
      />
      <select-field
        :value="selectedPriceRanges"
        title="Price Range"
        :options="priceRangesFormatted"
        is-multiple
        @input="onPriceInput"
      />
    </div>
  </ew-filters>
</template>
