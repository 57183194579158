<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import AwardTile from '/~rec/components/award/award-tile.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'
import RecognitionLayout from '/~rec/layouts/view/recognition-layout.vue'
import RecognitionHowEarnPoints from '/~rec/components/recognition/recognition-how-earn-points.vue'
import HowToEarnMobile from '/~rec/modals/earn/components/earn-how-to-earn-mobile.vue'
import { useAwards } from '/~rec/composables/awards'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-awards',
  components: {
    EwCatalogProcessor,
    AwardTile,
    RecBaseState,
    RecognitionLayout,
    RecognitionHowEarnPoints,
    HowToEarnMobile,
  },
  setup() {
    const { awards } = useAwards()
    const { orgMemberId, displayPointsPopup, currency, features } =
      useRecProfile()

    return {
      awards,
      orgMemberId,
      displayPointsPopup,
      currency,
      features,
      ui,
    }
  },
  data() {
    return {
      selectedFilter: 'recent',
    }
  },
  computed: {
    filterOptions() {
      return [
        { text: 'Most recent', value: 'recent' },
        this.activeTab !== 'received' && {
          text: 'Ending soon',
          value: 'ending',
        },
        { text: 'Most popular', value: 'popular' },
        { text: 'Most valuable', value: 'valuable' },
        { text: 'A-Z', value: 'asc' },
      ].filter(Boolean)
    },
    processor() {
      switch (this.activeTab) {
        case 'competing':
          return this.awards.competing
        case 'received':
          return this.awards.redeemed
        default:
          return this.awards.available
      }
    },
    activeTab() {
      const { params, query } = this.$route

      return params.type || query.type || 'available'
    },
    isDesktop() {
      return ui.desktop
    },
  },
  watch: {
    activeTab: {
      handler() {
        this.getAwards()

        if (!this.filterOptions.find((v) => v.value === this.selectedFilter)) {
          this.selectedFilter = 'recent'
        }
      },
      deep: true,
    },
  },
  async created() {
    await Promise.all([
      this.awards.competing.getData({
        query: {
          nominee: this.orgMemberId,
        },
        filter: this.selectedFilter,
      }),
      this.awards.redeemed.getData({
        filter: this.selectedFilter,
      }),
      this.awards.available.getData(),
    ])

    if (this.displayPointsPopup) this.openEarnInfoModal()
  },
  methods: {
    onSelectFilter(filter) {
      this.selectedFilter = filter
      this.getAwards()
    },
    getAwards() {
      const filter = this.selectedFilter

      this.processor.getData(
        this.activeTab === 'competing'
          ? {
              filter,
              query: {
                nominee: this.orgMemberId,
              },
            }
          : { filter }
      )
    },
    onAwardClick(award) {
      this.$router.push({
        name: 'rec-award',
        params: {
          awardStatus: this.activeTab,
          awardId: award.id,
        },
      })
    },
    openEarnInfoModal() {
      modal.show('rec-earn-info')
    },
  },
}
</script>
<template>
  <div class="w-full">
    <recognition-layout
      title="Awards"
      :filter-options="filterOptions"
      :selected-filter="selectedFilter"
      :loading="processor.processing"
      @filter-selected="onSelectFilter"
    >
      <template #headerActions>
        <recognition-how-earn-points @open-earn-info="openEarnInfoModal" />
      </template>
      <ew-catalog-processor
        :processor="processor"
        :columns="{
          xxs: 1,
          xs: 2,
          lg: 3,
          xl: 4,
        }"
        tile-class="rounded-lg shadow-xs cursor-pointer hover:shadow-lg hover:-translate-y-[5px] bg-light"
      >
        <template #empty>
          <rec-base-state
            v-if="activeTab === 'available'"
            class="mt-16 mb-0 md:mt-[120px]"
            image="recroom/state/awards-available-empty-state.svg"
            title="No awards available yet"
            subtitle="Awards you can win and earn points for will be displayed here"
          />

          <rec-base-state
            v-if="activeTab === 'competing'"
            class="mt-16 mb-0 md:mt-[120px]"
            image="recroom/state/awards-competing-empty-state.svg"
            title="You are not competing in any awards"
            subtitle="Awards that you are in the running for will be displayed here"
          />

          <rec-base-state
            v-if="activeTab === 'received'"
            class="mt-16 mb-0 md:mt-[120px]"
            image="recroom/state/awards-received-empty-state.svg"
            title="You have not received any awards yet"
            subtitle="Awards that you have won will be displayed here"
          />
        </template>

        <template #tile="{ tile: award }">
          <award-tile
            :award="award"
            :currency="currency"
            :finished="activeTab === 'received'"
            @click="onAwardClick(award)"
          />
        </template>
      </ew-catalog-processor>
    </recognition-layout>
    <how-to-earn-mobile
      v-if="!isDesktop"
      @show-how-to-earn="openEarnInfoModal"
    />
  </div>
</template>
