<script>
import './bootstrap'
import AppAlerts from '/~/components/app/app-alerts.vue'
import AppLoading from '/~/components/app/app-loading.vue'
import AppHeaderWrapper from '/~/components/app/header/app-header-wrapper.vue'
import BottomSheetKeeper from '/~/components/bottom-sheet/bottom-sheet-keeper.vue'
import MdlKeeper from '/~/components/mdl/mdl-keeper.vue'
import { useAuth } from '/~/composables/auth'
import { useUI } from '/~/composables/ui'
import { useUser } from '/~/composables/user'
import LayoutMain from './layouts/main/layout-main'
import { useThirdPartyLogin } from '../../extensions/third-party-login/composables/use-third-party-login'

export default {
  name: 'main-view',
  components: {
    AppHeaderWrapper,
    MdlKeeper,
    BottomSheetKeeper,
    AppAlerts,
    LayoutMain,
    AppLoading,
    AppBottomBar: () => import('/~/components/app/app-bottom-bar.vue'),
    AppSearch: () => import('/~/components/app/search/app-search.vue'),
    CartMenu: () => import('/~/components/cart-menu/cart-menu.vue'),
    ProfileMenu: () => import('/~/components/profile-menu/profile-menu.vue'),
  },
  setup() {
    const { user } = useUser()
    const { uiReady } = useUI()
    const { isLoggingOut } = useAuth()
    const { isThirdPartyLoginRequired, initThirdPartyLogin } =
      useThirdPartyLogin()

    return {
      user,
      uiReady,
      isLoggingOut,
      initThirdPartyLogin,
      isThirdPartyLoginRequired,
    }
  },
  data: () => ({
    innerHeight: window.innerHeight,
  }),
  async mounted() {
    await this.initThirdPartyLogin()
    eonx.ready()
  },
}
</script>

<template>
  <div
    id="app"
    class="flex h-full flex-col overflow-hidden print:block print:h-auto"
  >
    <template v-if="user.authorized && !isThirdPartyLoginRequired">
      <app-header-wrapper />
      <layout-main>
        <router-view />
      </layout-main>
      <template v-if="uiReady">
        <app-bottom-bar />
        <profile-menu />
        <cart-menu />
      </template>
      <app-search />
    </template>
    <app-loading v-else-if="isLoggingOut" />
    <router-view v-else />

    <div id="print-area">
      <portal-target name="print-destination" />
    </div>

    <mdl-keeper />
    <portal-target name="modals-portal" multiple />
    <bottom-sheet-keeper />
    <app-alerts />
  </div>
</template>
