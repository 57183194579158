import api from '/~rec/core/api'
import { RecroomHi5Reason } from './hi5-reason'
import { RecroomEarnConfig } from '/~rec/core/earn-config'
import { RecroomUser } from './user'

export class RecroomHi5 {
  constructor(rawData = {}) {
    this.raw = rawData

    this.reason = rawData.reason && new RecroomHi5Reason(rawData.reason)
    this.recipient = rawData.recipient && new RecroomUser(rawData.recipient)
    this.sender = rawData.sender && new RecroomUser(rawData.sender)
    this.earn =
      rawData.earn_config && new RecroomEarnConfig(rawData.earn_config)
  }

  get id() {
    return this.raw.id
  }

  get createdAt() {
    return this.raw.created_at
  }

  get message() {
    return this.raw.message
  }

  get image() {
    return this.reason.image
  }

  get title() {
    return this.reason.content
  }

  get senderFullName() {
    return `${this.sender.firstName} ${this.sender.lastName}`
  }

  get recipientFullName() {
    return `${this.recipient.firstName} ${this.recipient.lastName}`
  }

  get nominal() {
    return this.earn?.earnValue || 0
  }

  static async create(data) {
    const post = await api.post('/gamification/high-fives', data, {
      notify: false,
    })

    return new RecroomHi5(post)
  }

  static async fetch(id) {
    const response = await api.get(`/gamification/high-fives/${id}`, {
      notify: false,
    })

    return new RecroomHi5(response.data)
  }

  static async fetchAvailable(id) {
    const response = await api.get(`/gamification/high-five-reasons/${id}`)

    return new RecroomHi5({ reason: response.data })
  }
}
