<script setup lang="ts">
import { watch, ref, onBeforeUnmount } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { usePaymentsPayeesComponent } from './use-payments-payees-component'

const emit = defineEmits<(event: 'search', payload: string) => void>()
const { enabledAddNewButton, handleAddNewPayeeClick } =
  usePaymentsPayeesComponent()

const searchString = ref('')

watch(
  () => searchString.value,
  () => emit('search', searchString.value)
)

onBeforeUnmount(() => {
  emit('search', '')
})
</script>

<template>
  <div class="mb-8 flex items-center">
    <base-input
      v-model="searchString"
      placeholder="Search payees"
      look="rounded"
      class="max-w-[352px]"
      nolabel
      icon-plain
      clearable
    >
      <template #icon>
        <base-icon
          svg="heroicons/outline/magnifying-glass"
          :size="28"
          class="ml-[15px] mr-2.5 text-eonx-neutral-600"
        />
      </template>
    </base-input>
    <base-button
      data-testid="add-payee-button"
      :disabled="!enabledAddNewButton"
      class="ml-auto"
      size="sm"
      @click="handleAddNewPayeeClick"
    >
      + Add new
    </base-button>
  </div>
</template>
