<script setup lang="ts">
import BaseAction from '/~/components/base/action/base-action.vue'
import { computed } from 'vue'
import { Tile } from '/~/composables/cms/use-cms'

const props = defineProps<{
  tile: Tile
  screenSize: 'md' | 'sm' | 'xs'
  columns: number
}>()

const tile = computed(() => props.tile)

const buttonStyle = computed(() => {
  return {
    color: tile.value.buttonTextColor,
    border: `2px solid ${tile.value.buttonTextColor}`,
  }
})

const size = computed(() => {
  switch (props.screenSize) {
    case 'xs':
      switch (props.columns) {
        case 1:
          return '670x446'
        case 2:
          return '320x212'
        case 3:
        default:
          return '202x134'
      }
    case 'sm':
      switch (props.columns) {
        case 1:
          return '1456x972'
        case 2:
          return '712x474'
        case 3:
          return '464x310'
        case 4:
        default:
          return '340x226'
      }
    case 'md':
    default:
      switch (props.columns) {
        case 1:
          return '2352x1570'
        case 2:
          return '1160x774'
        case 3:
          return '764x510'
        case 4:
          return '564x376'
        case 5:
          return '446x298'
        case 6:
        default:
          return '366x244'
      }
  }
})

const title = computed(
  () =>
    `${tile.value.content.title.replace(
      /<\s*([a-zA-Z0-9]+)(.*?)>/,
      '<$1 class="mx-5 text-3xl font-semibold">'
    )} <img src="${tile.value.image.desktop}/-/preview/${
      size.value
    }/-/format/auto/-/quality/smart/" alt="${
      tile.value.image.alt
    }" class="w-full mb-5" />`
)
</script>

<template>
  <div
    class="flex h-full flex-grow flex-col"
    :style="{
      backgroundColor: tile.contentBackgroundColor,
      textAlign: tile.content.contentAlignmentX,
    }"
    :class="{
      'items-start': tile.content.contentAlignmentX === 'left',
      'items-center': tile.content.contentAlignmentX === 'center',
      'items-end': tile.content.contentAlignmentX === 'right',
    }"
  >
    <div
      class="flex w-full flex-col-reverse text-2xl font-semibold leading-8"
      :style="{ color: tile.titleColor }"
      v-html="title"
    />
    <div
      v-if="tile.content.text"
      class="mx-5 mt-5 mb-[30px] min-h-12 text-base font-normal"
      :style="{ color: tile.contentColor }"
      v-html="tile.content.text"
    />
    <base-action
      v-if="tile.tileLink && tile.tileLink.enabled"
      v-bind="tile.tileLink"
      :label="tile.image.alt"
      class="mx-5 mt-auto mb-5 flex items-center rounded-md px-6 py-2.5 text-sm font-bold hover:opacity-60"
      origin="banner"
      :style="buttonStyle"
      appearance="link"
    >
      {{ tile.tileLink.label }}
    </base-action>
  </div>
</template>
