<script>
import ProductHeader from '/~/extensions/giftcards/components/product/header/product-header.vue'

export default {
  name: 'retailer-product-header-bupa',
  extends: ProductHeader,
}
</script>

<template>
  <h2
    class="mt-[15px] text-center text-2xl font-semibold leading-tight sm:mt-0 sm:text-left md:text-3xl"
  >
    {{ name }}
  </h2>
</template>
