<script setup>
import { computed, ref, watch } from 'vue'
import { useGiftcards } from '/~/extensions/giftcards/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import SelectField from '/~/components/mobile-filter/select-field.vue'

const {
  isPhysicalEnabled,
  cardTypes,
  categories,
  sortValues,
  defaultSortValue,
  searchQuery,
  selectedCategory,
  selectedSorting,
  selectedCardType,
  resetFilters,
  isFiltersSelected,
  searchGroup,
} = useGiftcards()

const localSearchQuery = ref(searchQuery.value)
const localSorting = ref(selectedSorting.value)
const localCategory = ref(selectedCategory.value)

const searchPlaceholder = computed(() => {
  if (isPhysicalEnabled.value) {
    return selectedCardType.value === 'physical'
      ? 'Search physical gift cards'
      : 'Search digital gift cards'
  }

  return `Search ${searchGroup.value}`
})

watch(selectedCardType, clearFilters)

function resetLocalFilters() {
  localSearchQuery.value = searchQuery.value
  localSorting.value = selectedSorting.value
  localCategory.value = selectedCategory.value
}

function applyFilters() {
  searchQuery.value = localSearchQuery.value
  selectedSorting.value = localSorting.value
  selectedCategory.value = localCategory.value
}

function clearFilters() {
  resetFilters()
  resetLocalFilters()
}
</script>

<template>
  <div class="w-auto bg-white">
    <base-tabs
      v-if="cardTypes.length > 1"
      :value="selectedCardType"
      class="w-auto"
      @input="selectedCardType = $event"
    >
      <base-tab
        v-for="tab in cardTypes"
        :key="tab.value"
        :name="tab.label"
        :value="tab.value"
      />
    </base-tabs>
    <ew-filters
      title="Filters"
      :is-filters-selected="isFiltersSelected"
      @opened="resetLocalFilters"
      @clear="clearFilters"
      @close="resetLocalFilters"
      @apply="applyFilters"
    >
      <div class="relative mt-2.5 grow">
        <base-input
          v-model="localSearchQuery"
          :placeholder="searchPlaceholder"
          :label="searchPlaceholder"
          :icon-plain="true"
          look="rounded"
          clearable
          nolabel
        >
          <template #icon>
            <div class="ml-[15px] mr-2.5 flex items-center justify-center">
              <base-icon
                svg="plain/search-v2"
                class="text-eonx-neutral-600"
                :size="19"
              />
            </div>
          </template>
        </base-input>
      </div>
      <div class="space-y-4 py-4">
        <select-field
          v-model="localCategory"
          title="Categories"
          :options="categories"
        />
        <select-field
          v-model="localSorting"
          title="Sorting by"
          :options="sortValues"
          :empty-option="defaultSortValue"
        />
      </div>
    </ew-filters>
  </div>
</template>
