<script>
import ui from '/~/core/ui'
import { formatDate } from '/~/utils/format/date'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import FeedList from '../feed-list.vue'
import { computed, ref } from 'vue'
import EwDetailsHeader from '/~/components/ewallet/ew-details-header.vue'
import { useArticles } from '/~/extensions/health/composables'
import { useRoute, useRouter } from 'vue-router/composables'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'health-details-view-article',
  components: {
    BaseIcon,
    FeedList,
    EwDetailsHeader,
    BaseLoader,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    backRoute: {
      type: Object,
      default: null,
    },
    tileRouteName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const { feed, getSinglePost } = useArticles()

    const loading = ref(false)

    const type = computed(() => route.params.type)

    function goBack() {
      if (props.backRoute) {
        router.replace(props.backRoute)
      } else {
        router.back()
      }
    }

    return {
      formatDate,
      type,
      goBack,
      feed,
      getSinglePost,
      ui,
      loading,
    }
  },
  data() {
    return {
      tile: {},
    }
  },
  watch: {
    id() {
      this.init()
    },
    '$route.params.type'() {
      const route = this.$route.matched.find((r) => r.name === this.$route.name)
      const parent = route.parent

      this.$router.push({
        name: parent.name,
        params: {
          type: this.type,
        },
      })
    },
  },
  created() {
    this.init()
  },
  mounted() {
    const body = document.getElementById('health-content')

    if (body) {
      body.scrollTop = 0
    }
  },
  methods: {
    async init() {
      this.loading = true

      const [tile] = await Promise.all([
        this.getSinglePost(this.id),
        this.feed.getData({
          search: {
            filters: [
              `NOT objectID:${this.id}`,
              `Category:${this.tile.category}`,
            ]
              .filter((i) => !!i)
              .join(' AND '),
          },
        }),
      ])

      this.tile = tile

      this.loading = false
    },
  },
}
</script>

<template>
  <div class="w-full pb-[30px]">
    <div class="relative">
      <ew-details-header
        v-if="ui.mobile"
        :title="!loading && tile.title"
        :loading="loading"
      />
      <div
        v-else
        class="absolute mt-[30px] ml-10 hidden cursor-pointer items-center rounded-lg bg-white px-5 py-2.5 font-bold text-primary hover:opacity-75 md:flex"
        @click="goBack"
      >
        <base-icon svg="plain/arrow-left" :size="12" class="mr-[15px]" />
        <span class="text-base">Back</span>
      </div>
      <div class="-mt-16 h-[344px] w-full sm:mt-0">
        <img
          v-if="!loading"
          :src="tile.image"
          :alt="tile.title"
          class="h-full w-full object-cover"
        />
      </div>
    </div>

    <base-loader v-if="loading" class="absolute inset-0" fullwidth />

    <template v-else>
      <div
        class="mx-auto mt-[30px] w-full max-w-3xl px-5 text-eonx-neutral-800 sm:px-[30px]"
      >
        <h1 class="text-3xl leading-tight sm:text-5xl">
          {{ tile.title }}
        </h1>

        <div class="mt-[15px] mb-[35px] flex items-center">
          <div
            v-for="(category, index) in tile.categories"
            :key="`category-${index}`"
            class="relative mr-6 px-[15px] py-[5px] text-sm leading-none text-primary"
          >
            <span
              class="pointers-none absolute inset-0 rounded-full bg-primary opacity-20"
            />
            {{ category.text }}
          </div>
        </div>
        <div class="markup markup-health" v-html="tile.description" />
      </div>

      <div v-if="tile && feed.hits.length" class="mx-auto max-w-3xl px-5">
        <h1 class="mt-[30px] border-t pt-[30px] text-2xl text-eonx-neutral-800">
          Related Recipes
        </h1>
        <feed-list
          :feed="feed"
          :type="type"
          :limit="3"
          :columns="{
            xs: 2,
            sm: 3,
          }"
          :tile-route-name="tileRouteName"
        />
      </div>
    </template>
  </div>
</template>
