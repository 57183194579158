import { computed, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router/composables'
import { CMSPageId } from '/~/types/cms'
import ui from '/~/core/ui'
import { useCms } from '/~/composables/cms/use-cms'
import exchange from '/~/configurator/exchange'

interface WidgetSelectedPayload {
  widgetIndex: number
}

const CONFIGURATOR_EVENTS: Record<string, keyof typeof PAGE_HANDLERS> = {
  'page-widget-selected': 'onWidgetSelected',
}

const PAGE_HANDLERS = {
  onWidgetSelected(payload: WidgetSelectedPayload) {
    const sections = document.querySelectorAll('#widgets-builder > section')
    const targetSection = sections[payload.widgetIndex]

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  },
}

export function useEditablePage() {
  const route = useRoute()
  const { pages } = useCms()

  const page = computed(
    () => pages.value[route.meta?.page as CMSPageId] || undefined
  )
  const pageTitle = computed(() => page.value?.settings?.title)

  const haveRightWidget = computed(() =>
    page.value?.widgets?.some(
      (item) =>
        item.props.side === 'right' &&
        (item.props.enableWidget ?? true) &&
        (!ui.mobile ? item.props.showOnDesktop : item.props.showOnMobile)
    )
  )

  onMounted(() => {
    for (const [event, handlerName] of Object.entries(CONFIGURATOR_EVENTS)) {
      exchange.addHandler(event, (payload: WidgetSelectedPayload) => {
        const handler = PAGE_HANDLERS[handlerName]

        if (handler instanceof Function) {
          handler.call(document, payload)
        }
      })
    }
  })

  onUnmounted(() => {
    for (const event in CONFIGURATOR_EVENTS) {
      exchange.removeHandler(event)
    }
  })

  return {
    page,
    pageTitle,
    haveRightWidget,
  }
}
