<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useUI } from '/~/composables/ui'
import { useMobileMenu } from '/~/composables/ui/use-mobile-menu'

const { showMobileMenu } = useMobileMenu()
const { uiReady } = useUI()
</script>

<template>
  <button v-if="uiReady" aria-label="Open menu" @click="showMobileMenu">
    <base-icon svg="v2/box-icons/bx-menu-alt-left-v2" :size="28" />
  </button>
</template>
