<script>
import BaseAction from '/~/components/base/action/base-action.vue'
import WgtImage from '../wgt/wgt-image.vue'
import WgtText from '../wgt/wgt-text.vue'
import WgtTile from '../wgt/wgt-tile.vue'
import WgtWrapper from '../wgt/wgt-wrapper.vue'

export default {
  name: 'widget-images',
  components: {
    WgtWrapper,
    WgtTile,
    WgtText,
    WgtImage,
    BaseAction,
  },
  props: {
    wrapper: {
      type: Object, // TODO: Wrapper from use-wrapper
      default: () => ({}),
    },
    items: {
      type: Array, // TODO: Tile[] from use-wrapper
      default: () => [],
    },
  },
}
</script>

<template>
  <wgt-wrapper :wrapper="wrapper" class="widget-images" data-cy="widget-images">
    <wgt-tile
      v-for="(tile, idx) in items"
      :key="`image-${idx}`"
      :wrapper="wrapper"
      class="overflow-hidden rounded-lg"
    >
      <base-action
        v-if="tile.link && tile.link.enabled"
        v-bind="tile.link"
        appearance="link"
        origin="banner"
        class="relative h-full"
      >
        <wgt-image :image="tile.image" />
        <wgt-text v-if="tile.content" :content="tile.content" />
      </base-action>
    </wgt-tile>
  </wgt-wrapper>
</template>
