<script>
import { ref, computed } from 'vue'
import ui from '/~/core/ui'
import { formatNumber } from '/~/utils/format/numeric'
import { pluralize } from '/~/utils/format/string'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'

export default {
  name: 'rec-award-leaderboard',
  components: {
    BaseIcon,
    BaseAvatar,
    BaseButton,
    BaseLoader,
    RecBaseState,
    EwCatalogProcessor,
  },
  props: {
    award: {
      type: Object,
      required: true,
    },
    nominateIsDisabled: {
      type: Boolean,
      default: false,
    },
    periodId: {
      type: String,
      default: '',
    },
  },
  emits: ['row-click', 'nominate'],
  setup(props) {
    const nominal = computed(() =>
      props.finished ? props.award.prize : props.award.nominal
    )
    const loading = ref(false)
    const processor = ref(props.award.leaderboard(props.award.periodId))

    return {
      loading,
      nominal,
      pluralize,
      formatNumber,
      processor,
      ui,
    }
  },
  async created() {
    try {
      this.loading = true
      this.processor.getData()
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  },
}
</script>

<template>
  <div class="flex h-full grow flex-col overflow-hidden rounded-lg">
    <ew-catalog-processor
      :processor="processor"
      :columns="1"
      :tile-class="false"
      class="flex h-full grow"
      no-padding
    >
      <template #placeholder>
        <div class="mx-auto py-5">
          <base-loader fullwidth />
        </div>
      </template>

      <template #empty>
        <div
          class="flex w-full grow flex-col items-center justify-center py-[30px]"
        >
          <rec-base-state
            class="w-full"
            image="recroom/state/leaderboard-empty-state.svg"
            title="No nominations yet"
            subtitle="There has been no one nominated for this award"
          />

          <v-popover
            trigger="hover"
            offset="15"
            placement="top"
            class="flex flex-col"
            :disabled="loading || award.iCanNominate"
          >
            <base-button
              class="mt-5 hidden w-full md:my-0 md:w-48 lg:block"
              :loading="loading"
              :disabled="nominateIsDisabled"
              @click="$emit('nominate')"
            >
              <span class="mg:text-lg text-base">Nominate!</span>
            </base-button>
            <template #popover>
              <div>You are not eligible to Nominate for this Award</div>
            </template>
          </v-popover>
        </div>
      </template>

      <template #tile="{ tile, index }">
        <div
          class="flex w-full grow cursor-pointer items-center space-x-5 bg-eonx-neutral-50 p-[15px] xxs:px-5 xxs:py-[15px] sm:space-x-[30px] sm:px-6 md:bg-white"
          :class="{
            'border-t sm:border-zinc-100': index > 0,
          }"
          @click="$emit('row-click', tile)"
        >
          <div class="shrink-0">
            <div class="font-bold text-eonx-neutral-800">#{{ tile.rank }}</div>
          </div>

          <div class="flex max-w-full items-center truncate">
            <base-avatar
              :src="tile.user.avatarUrl"
              :abbr="tile.user.initials"
              :size="ui.desktop ? 'lg' : 'md'"
            />

            <div
              class="flex flex-col items-start justify-center truncate pl-[15px]"
            >
              <div
                class="w-full truncate font-bold leading-4 text-eonx-neutral-800"
              >
                {{ tile.user.fullName }}
              </div>
              <div
                class="text-sm font-semibold leading-4 text-eonx-neutral-600"
              >
                {{ tile.nominationsCount }}
                {{ pluralize(tile.nominationsCount, 'nomination') }}
              </div>
            </div>
          </div>

          <div class="ml-auto w-5 shrink-0">
            <base-icon
              svg="plain/chevron-right"
              class="text-primary"
              size="sm"
            />
          </div>
        </div>
      </template>
    </ew-catalog-processor>
  </div>
</template>
