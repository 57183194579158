<script setup lang="ts">
import './bootstrap'
import { onMounted, ref } from 'vue'
import ui from '/~/core/ui'
import { useThirdPartyLogin } from '/~/extensions/third-party-login/composables/use-third-party-login'
import AppAlerts from '/~/components/app/app-alerts.vue'
import AppLoading from '/~/components/app/app-loading.vue'
import AppHeaderWrapper from '/~/components/app/header/app-header-wrapper.vue'
import AppSearch from '/~/components/app/search/app-search.vue'
import BottomSheetKeeper from '/~/components/bottom-sheet/bottom-sheet-keeper.vue'
import CartMenu from '/~/components/cart-menu/cart-menu.vue'
import EwalletMenu from '/~/components/ewallet-menu/ewallet-menu.vue'
import MdlKeeper from '/~/components/mdl/mdl-keeper.vue'
import MobileMenu from '/~/components/mobile-menu/mobile-menu.vue'
import { useAuth } from '/~/composables/auth'
import { useUI } from '/~/composables/ui'
import { useMobileMenu } from '/~/composables/ui/use-mobile-menu'
import { useUser } from '/~/composables/user'
import LayoutMain from '/~/layouts/main/layout-main'

const { user } = useUser()
const { isLoggingOut } = useAuth()
const { uiReady } = useUI()
const { isThirdPartyLoginRequired, initThirdPartyLogin } = useThirdPartyLogin()
const {
  initMobileMenu,
  isMobileMenuToggledOn,
  isMobileMenuToggledOff,
  hideMobileMenu,
} = useMobileMenu()

const mobileMenuRef = ref<InstanceType<typeof MobileMenu>>()

onMounted(async () => {
  initMobileMenu(mobileMenuRef)
  await initThirdPartyLogin()
  eonx.ready()
})
</script>

<template>
  <div
    id="app"
    class="flex h-full flex-col overflow-hidden print:block print:h-auto"
  >
    <template v-if="user.authorized && !isThirdPartyLoginRequired">
      <div
        class="flex h-full flex-grow flex-col shadow-md"
        :class="{
          'mobile-menu__main-content-in': isMobileMenuToggledOn,
          'mobile-menu__main-content-out': isMobileMenuToggledOff,
        }"
      >
        <app-header-wrapper />
        <layout-main>
          <router-view />
        </layout-main>
        <div
          v-if="ui.mobile && isMobileMenuToggledOn"
          class="absolute top-0 left-0 z-50 h-full w-full"
          @click="hideMobileMenu"
        />
      </div>
      <mobile-menu ref="mobileMenuRef" />
      <template v-if="uiReady">
        <ewallet-menu />
        <cart-menu />
      </template>
      <app-search />
    </template>
    <app-loading v-else-if="isLoggingOut" />
    <router-view v-else />

    <div id="print-area">
      <portal-target name="print-destination" />
    </div>

    <mdl-keeper />
    <portal-target name="modals-portal" multiple />
    <bottom-sheet-keeper />
    <app-alerts />
  </div>
</template>
