<script>
import { useRouter } from 'vue-router/composables'
export default {
  name: 'health-tabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    subtype: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter()

    function isActive(item) {
      const query = item.route.query

      return (
        query?.type === props.type &&
        (props.subtype ? props.subtype === query?.subtype : true)
      )
    }

    function click(route) {
      router.replace(route)
    }

    return {
      click,
      isActive,
    }
  },
}
</script>

<template>
  <div class="flex shrink-0 overflow-x-auto bg-white md:bg-transparent">
    <div class="flex w-full space-x-[15px] lg:space-x-[30px]">
      <div
        class="flex flex-col justify-center"
        :class="{ 'w-full sm:w-auto': tabs.length === 2 }"
      >
        <ul
          class="flex shrink-0 items-center overflow-hidden sm:py-[5px] md:-mx-[15px]"
        >
          <li
            v-for="(item, index) in tabs"
            :key="index"
            class="relative h-full sm:mx-[15px]"
            :class="{ 'w-1/2 sm:w-auto': tabs.length === 2 }"
          >
            <span
              class="flex h-full max-w-full cursor-pointer flex-col justify-center truncate px-[15px] py-2.5 text-center font-bold text-eonx-neutral-800 transition duration-100 hover:text-primary sm:py-[5px] sm:px-0"
              :class="{ 'text-primary': isActive(item) }"
              @click="click(item.route)"
            >
              {{ item.label }}
            </span>
            <div
              class="absolute bottom-0 left-0 w-full transform border-b-2 border-primary transition duration-200"
              :class="[isActive(item) ? 'scale-x-100' : 'scale-x-0']"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
