<script>
import ui from '/~/core/ui'
import SectionMenu from '/~/components/section-menu/section-menu.vue'

export default {
  name: 'layout-view-mobile',
  components: {
    SectionMenu,
  },
  inheritAttrs: false,
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <div class="h-full">
    <div class="relative flex h-full flex-col">
      <div
        v-if="menu.length && ui.desktop"
        class="mx-auto w-full max-w-screen-xs"
      >
        <section-menu :items="menu" />
      </div>
      <slot />
    </div>
  </div>
</template>
