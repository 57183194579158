<script>
import BaseImage from '/~/components/base/image/base-image.vue'
import RecognitionTokens from '/~rec/components/recognition/recognition-tokens.vue'
import { pluralize } from '/~/utils/format/string'
import { formatNumber } from '/~/utils/format/numeric'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-tile-box',
  components: {
    BaseImage,
    RecognitionTokens,
  },
  props: {
    color: {
      type: String,
      default: '',
      validator: (v) => !v || /light|success|gold|info/.test(v),
    },
    nominal: {
      type: [String, Number],
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    altText: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { features, currency } = useRecProfile()

    return {
      currency,
      features,
      pluralize,
      formatNumber,
    }
  },
  computed: {
    clickable() {
      return !!this.$listeners.click
    },
  },
}
</script>

<template>
  <div
    v-bind="$attrs"
    class="relative overflow-hidden rounded-lg"
    v-on="$listeners"
  >
    <div class="overflow-hidden">
      <div class="absolute z-1 p-[15px]">
        <slot name="rank" />
      </div>
      <div
        class="relative flex flex-col"
        :style="[nominal ? {} : { height: 'calc(100% + 24px)' }]"
      >
        <div
          class="absolute top-0 bottom-0 h-full w-full"
          :style="[
            nominal && nominal !== '0' && features.points
              ? { height: 'calc(100% - 24px)' }
              : {},
          ]"
        >
          <base-image
            ref="imageRef"
            :alt="altText"
            :src="image"
            :rounded="false"
            class="absolute top-0 bottom-0 h-full w-full"
          />
          <recognition-tokens
            v-if="nominal && nominal !== '0' && features.points"
            :nominal="nominal"
            :color="color"
            icon="rec/token"
            class="absolute top-full"
          />
        </div>
        <svg viewBox="0 0 16 10" />
        <svg viewBox="0 0 0 0" style="height: 24px" />
      </div>
    </div>
    <div
      class="items-between relative flex grow text-sm"
      :class="{
        'cursor-pointer': clickable,
      }"
    >
      <slot />
    </div>
  </div>
</template>
