<script>
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'

export default {
  name: 'rec-placeholder-event-group-details',
  components: {
    BasePlaceholder,
  },
}
</script>

<template>
  <div class="w-full">
    <div class="mx-auto mb-[15px] flex w-full max-w-5xl justify-start pl-6">
      <base-placeholder :width="70" :height="26" />
    </div>
    <div
      class="mx-auto mb-[15px] flex w-full max-w-5xl overflow-hidden px-[30px]"
    >
      <div class="mr-2.5">
        <base-placeholder :width="48" :height="48" :rounded-full="true" />
      </div>
      <div class="mr-2.5">
        <base-placeholder :width="250" :height="48" />
      </div>

      <div class="ml-auto ml-2.5 self-center">
        <base-placeholder :width="30" :height="30" :rounded-full="true" />
      </div>
    </div>
    <div class="mb-[15px]">
      <base-placeholder
        :columns="1"
        :height="48"
        :rounded="false"
        type="fill"
      />
    </div>
    <div class="mx-auto mb-2.5 w-full max-w-3xl px-[72px]">
      <base-placeholder :columns="1" :height="166" />
    </div>
  </div>
</template>
