<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import modal from '/~/core/mdl'
import type CommunityHubOffer from '/~/extensions/community-hub/composables/core/CommunityHubOffer'
import type { RedeemedCoupon } from '/~/extensions/community-hub/composables/use-community-hub'
import cdn from '/~/utils/cdn'
import { formatDate } from '/~/utils/format'
import BaseButton from '/~/components/base/button/base-button'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useLogger } from '/~/composables/logger'
const logger = useLogger('community-hub-offer-modal')

const props = defineProps<{
  offer: {
    id: string
    type: 'coupon' | 'show' | 'url' | 'html'
    couponType: null | 'online' | 'both' | 'in_store'
    redemptionImage: string
    promotionUrl: null | string
    name: string
    couponCode?: string | null
    redemptionLimit?: number | null
  }
  redeem: (id: string) => Promise<{
    data: {
      id: string
      code: null | string
    }
  }>
  getRedeemedCoupons?: (id: string) => Promise<RedeemedCoupon[]>
  fetchOffer?: (id: string) => Promise<CommunityHubOffer>
}>()

const isRedeemingOffer = ref(false)
const code = ref<string | null>('')
const redeemedCoupons = ref<RedeemedCoupon[]>([])
const redemption = ref<CommunityHubOffer['redemption'] | null>(null)
const offer = computed(() => props.offer)

const isOfferTypeCoupon = computed(() => offer.value.type === 'coupon')
const isOfferTypeShow = computed(() => offer.value.type === 'show')
const isCouponTypeOnlineOnly = computed(
  () => offer.value.couponType === 'online'
)
const isCouponTypeOnlineStore = computed(
  () => offer.value.couponType === 'both'
)

const offerModalTitle = computed(() => {
  if (isOfferTypeCoupon.value) {
    return 'Redeem using Coupon Code'
  } else if (isOfferTypeShow.value) {
    return 'Show and Save'
  }

  return ''
})

const redemptionImage = computed(() => cdn(offer.value.redemptionImage).url())

async function redeemOffer() {
  try {
    isRedeemingOffer.value = true
    const { data } = await props.redeem(offer.value.id)

    code.value = data.code
  } catch (error) {
    logger.error(error)
    modal.hide()
  } finally {
    isRedeemingOffer.value = false
  }
}

async function redeemCoupon() {
  try {
    isRedeemingOffer.value = true

    await props.redeem(offer.value.id)

    if (props.fetchOffer && props.getRedeemedCoupons) {
      const { redemption: localRedemption } = await props.fetchOffer(
        props.offer.id
      )

      redemption.value = localRedemption
      redeemedCoupons.value = await props.getRedeemedCoupons(props.offer.id)
    }
  } catch (error) {
    logger.error(error)
    modal.hide()
  } finally {
    isRedeemingOffer.value = false
  }
}

async function getCouponsDetails() {
  if (props.getRedeemedCoupons && props.fetchOffer) {
    isRedeemingOffer.value = true

    const { redemption: localRedemption } = await props.fetchOffer(
      props.offer.id
    )

    redemption.value = localRedemption
    redeemedCoupons.value = await props.getRedeemedCoupons(props.offer.id)
    isRedeemingOffer.value = false
  }
}

function getCode(index: number) {
  return redeemedCoupons.value[index]?.code ?? props.offer.couponCode
}

function getDate(index: number) {
  const date = redeemedCoupons.value[index]?.redeemedAt

  if (date) {
    return formatDate('daymonthyearnumeric', date)
  }

  return null
}

onMounted(async () => {
  if (isOfferTypeCoupon.value) {
    await getCouponsDetails()
  } else {
    await redeemOffer()
  }
})
</script>

<template>
  <base-mdl
    width="sme"
    :title="offerModalTitle"
    :title-center="true"
    :padding="isOfferTypeShow ? false : null"
  >
    <template v-if="isOfferTypeCoupon">
      <div class="flex flex-col pt-7 text-center sm:h-full sm:justify-between">
        <base-loader v-if="isRedeemingOffer" size="xs" />
        <div v-else-if="redemption" class="mx-auto flex w-72 flex-col gap-y-4">
          <div
            v-for="item of redemption.redeemed"
            :key="`redeemed-${item}`"
            class="flex w-full flex-col"
          >
            <div
              class="block w-full rounded border-2 border-dashed px-11 py-[5px] text-2xl font-bold leading-10"
            >
              {{ getCode(item - 1) }}
            </div>
            <div v-if="getDate(item - 1)" class="pt-2 text-sm">
              Redeemed {{ getDate(item - 1) }}
            </div>
          </div>

          <div v-if="'available' in redemption" class="flex flex-col gap-y-4">
            <div
              v-for="item of redemption.available"
              :key="`available-${item}`"
              class="w-full"
            >
              <base-button :full-width="true" size="lg" @click="redeemCoupon">
                <span class="text-2xl uppercase">Redeem Coupon</span>
              </base-button>
            </div>
          </div>
        </div>
        <template v-else>
          <div
            class="mx-auto block rounded border-2 border-dashed px-11 py-[5px] text-2xl font-bold leading-10"
          >
            {{ code }}
          </div>
        </template>
        <div class="mt-[15px] mb-5 sm:mt-5">
          <template v-if="isCouponTypeOnlineOnly || isCouponTypeOnlineStore">
            Use the code above when purchasing online at
            <base-link :href="offer.promotionUrl || ''">
              <b>{{ offer.promotionUrl }}</b>
            </base-link>
            <template v-if="isCouponTypeOnlineStore">
              or show it to cashier for in-store checkout.
            </template>
          </template>
          <template v-else>
            Show the code above to cashier for in-store checkout.
          </template>
        </div>
      </div>
    </template>
    <template v-if="isOfferTypeShow">
      <div>
        <base-image
          :src="redemptionImage"
          :alt="offer.name"
          :ratio="1"
          :rounded="false"
        />
      </div>
    </template>
  </base-mdl>
</template>
