<script setup lang="ts">
import get from 'lodash-es/get'
import Vue, { watch, computed, ref, onBeforeMount, onBeforeUnmount } from 'vue'
import Analytics from '/~/core/analytics'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import { formatDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button'
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCms } from '/~/composables/cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'
import router from '/~/router'

const props = defineProps<{
  method: object
  onCancel?: () => void
  onConfirm?: () => void
  onFailed?: () => void
  title?: string
}>()

const emit = defineEmits<{
  (event: 'loading', loading: boolean): void
}>()

const { isBankAccountsNavEnabled, isCreditCardsNavEnabled } = useProvider()
const { hideEwalletMenu, unlockKeyboardAccessArea } = useUI()
const {
  methodToVerify,
  verifying,
  setMethodToVerify,
  verifyNominalAmount,
  fetchPaymentMethods,
} = usePaymentMethods()
const { isDarkThemeForEwallet } = useCms()

const loading = ref(false)
const verifySum = ref(0)
const guessError = ref('')
const range = ref({
  min: 0.01,
  max: 2.99,
})

const id = computed(() => methodToVerify.value?.id)
const isCard = computed(
  () => methodToVerify.value?.type === PaymentMethodType.creditCard
)
const isBankAccount = computed(
  () => methodToVerify.value?.type === PaymentMethodType.bankAccount
)

const isEnabled = computed(() => {
  return (
    (isCard.value && isCreditCardsNavEnabled.value) ||
    (isBankAccount.value && isBankAccountsNavEnabled.value)
  )
})

watch(verifySum, () => {
  guessError.value = ''
})

watch(id, (newId) => {
  if (!newId) {
    verifySum.value = 0
    guessError.value = ''
  }
})

watch(isEnabled, (isEnabled) => {
  if (!isEnabled) {
    unlockKeyboardAccessArea()
  }
})

watch(
  () => verifying.value,
  (loading) => {
    emit('loading', loading)
  }
)

function hide() {
  bottomSheet.hide('method-verify')
  methodToVerify.value = null
}

async function submitGuess() {
  const { min, max } = range.value

  if (verifySum.value < min || verifySum.value > max) {
    guessError.value = `Amount should be between $${min} and $${max}`
    return
  }

  // Save value within function scope, because "verifyNominalAmount" clears "methodToVerify" value

  try {
    const isCardMethod: boolean = isCard.value

    await verifyNominalAmount(verifySum.value)

    const notifyMsg = `${
      isCardMethod ? 'Card' : 'Bank Account'
    } successfully verified.`

    Vue.notify({
      text: notifyMsg,
      type: 'success',
      duration: 10000,
    })

    Analytics.sendEvent({
      pageGroup: 'Drawer',
      page: 'Verify payment method',
      component1: isCard.value ? 'Credit/Debit card' : 'Bank account',
      label: `${isCard.value ? 'Credit card' : 'Bank account'} verified`,
    })

    guessError.value = ''
    verifySum.value = 0

    if (
      [
        'purchase-checkout-payment-methods',
        'quick-buy-payment-methods',
        'statements-method',
        'payments-method',
      ].includes(router.currentRoute.name as string) &&
      router.currentRoute.hash !== 'profile-pay-from'
    ) {
      hideEwalletMenu()
    }
  } catch (e) {
    const errorText = get(e, 'data.message') || get(e, 'guess.0', '')
    let isGuessError = true
    const isFailed = /Verification Failed/.test(errorText)
    const isExpired = /EonX Payment Verification expired/.test(errorText)

    if (/Verification Attempt Failed/.test(errorText)) {
      guessError.value = errorText
    } else if (isFailed || isExpired) {
      isGuessError = false
      guessError.value = ''
    }

    if (!isGuessError) {
      hide()
      guessError.value = ''
      verifySum.value = 0
      await fetchPaymentMethods()
    }
  }
}

function onCancelClick() {
  props.onCancel?.()
  hide()
}

function onVerified() {
  props.onConfirm?.()
  hide()
}

function onFailed() {
  props.onFailed?.()
  hide()
}

onBeforeMount(async () => {
  emitter.on('payment-methods:failed', onFailed)
  emitter.on('payment-methods:verified', onVerified)
  loading.value = true
  emit('loading', true)
  await setMethodToVerify(props.method)

  loading.value = false
  emit('loading', false)
})

onBeforeUnmount(() => {
  emitter.off('payment-methods:failed', onVerified)
  emitter.off('payment-methods:verified', onVerified)
})
</script>

<template>
  <div class="overflow-x-hidden">
    <div v-if="loading" class="flex items-center justify-center p-10">
      <base-loader size="xl" />
    </div>
    <template v-else>
      <div class="-mx-5 flex h-[84px] items-center px-5">
        <div class="flex grow flex-col overflow-hidden text-center">
          <h2
            class="cursor-default truncate text-lg font-bold text-eonx-neutral-800"
          >
            {{ title || `Verify ${isCard ? 'Card' : 'Bank'} Account` }}
          </h2>
        </div>
      </div>
      <div
        class="flex h-full flex-col overflow-y-auto bg-eonx-neutral-50 px-5"
        :class="{
          'bg-gray-900 text-white': isDarkThemeForEwallet,
        }"
      >
        <div
          class="mb-[35px] flex items-center justify-center bg-center bg-no-repeat pt-6"
        >
          <div
            class="flex h-24 w-24 items-center justify-center rounded-full bg-primary text-white"
          >
            <slot name="icon">
              <base-icon svg="v2/custom/shield-exclamation" :size="58" />
            </slot>
          </div>
        </div>
        <div class="w-full">
          <div>
            <div
              class="text-center text-2xl font-bold"
              :class="{
                'text-eonx-neutral-800': !isDarkThemeForEwallet,
                'text-white': isDarkThemeForEwallet,
              }"
            >
              Verify your
              {{ isCard ? 'card' : 'bank' }}
              account
            </div>
            <div class="mb-4 text-center text-eonx-neutral-800">
              <strong>Your security is our priority!</strong>
            </div>
            <span
              v-if="isCard"
              class="block"
              :class="{
                'text-eonx-neutral-600': !isDarkThemeForEwallet,
                'text-white': isDarkThemeForEwallet,
              }"
            >
              Please follow these three simple steps to verify your nominated
              card account.
            </span>
            <span
              v-else
              class="block"
              :class="{
                'text-eonx-neutral-600': !isDarkThemeForEwallet,
                'text-white': isDarkThemeForEwallet,
              }"
            >
              To verify your account, we have placed a charge for a specific
              amount
              <b>(between ${{ range.min }} and ${{ range.max }})</b>
              of which you must enter below, after following these steps:
            </span>
          </div>

          <ul class="mt-10 mb-5 space-y-2.5">
            <li class="flex">
              <b
                class="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-primary text-center align-middle leading-8 text-white"
              >
                1
              </b>
              <p
                class="ml-[15px]"
                :class="{
                  'text-eonx-neutral-600': !isDarkThemeForEwallet,
                  'text-white': isDarkThemeForEwallet,
                }"
              >
                <span v-if="isCard">
                  Login to your Internet Banking or call your Bank to locate the
                  amount we’ve temporarily authorised against your card account.
                </span>
                <span v-else>
                  Login to your Internet Banking to locate the verification
                  transaction.
                </span>
              </p>
            </li>
            <li class="flex">
              <b
                class="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-primary text-center leading-8 text-white"
              >
                2
              </b>
              <p
                class="ml-[15px]"
                :class="{
                  'text-eonx-neutral-600': !isDarkThemeForEwallet,
                  'text-white': isDarkThemeForEwallet,
                }"
              >
                <span v-if="isCard">
                  Locate the amount between
                  <b
                    :class="{
                      'text-eonx-neutral-600': !isDarkThemeForEwallet,
                      'text-white': isDarkThemeForEwallet,
                    }"
                  >
                    ${{ range.min }} and ${{ range.max }}
                  </b>
                  with the reference of
                  <b
                    :class="{
                      'text-eonx-neutral-600': !isDarkThemeForEwallet,
                      'text-white': isDarkThemeForEwallet,
                    }"
                  >
                    VERIFY AUTH.
                  </b>
                </span>
                <span v-else>
                  You will see a transaction on your account with a reference of
                  <b
                    :class="{
                      'text-eonx-neutral-600': !isDarkThemeForEwallet,
                      'text-white': isDarkThemeForEwallet,
                    }"
                  >
                    VERIFY AUTH.
                  </b>
                </span>
              </p>
            </li>
            <li class="flex items-center">
              <b
                class="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-primary text-center leading-8 text-white"
              >
                3
              </b>
              <p
                class="ml-[15px]"
                :class="{
                  'text-eonx-neutral-600': !isDarkThemeForEwallet,
                  'text-white': isDarkThemeForEwallet,
                }"
              >
                Enter this amount below and click confirm.
              </p>
            </li>
          </ul>

          <div class="grid w-full grid-cols-2 gap-x-5 border-t pt-6">
            <div
              class="pb-[5px]"
              :class="{
                'text-eonx-neutral-600': !isDarkThemeForEwallet,
                'text-white': isDarkThemeForEwallet,
              }"
            >
              VERIFY AUTH amount as of
              <strong>{{ formatDate('daymonthyear', new Date()) }}</strong>
            </div>
            <div class="mb-2.5 space-y-[5px]">
              <div class="flex h-12 items-center rounded-md border bg-white">
                <div
                  class="flex h-full items-center rounded-l-md pl-4 pr-2.5 text-center"
                  :class="{
                    'text-eonx-neutral-600': !isDarkThemeForEwallet,
                    'text-white': isDarkThemeForEwallet,
                  }"
                >
                  $
                </div>
                <base-currency-input
                  v-model="verifySum"
                  :value-range="{ max: 2.99 }"
                  :currency="null"
                  class="pr-2.5"
                />
              </div>
              <div v-if="guessError" class="text-sm text-error-700">
                {{ guessError }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="-mx-5 mt-auto border-t border-eonx-neutral-200 bg-white p-5"
        >
          <div class="grid w-full grid-cols-2 gap-x-5">
            <base-button
              v-analytics:click="{
                pageGroup: 'Drawer',
                page: 'Verify payment method',
                component1: isCard ? 'Credit/Debit card' : 'Bank account',
                cta: 'Cancel',
              }"
              :disabled="verifying"
              look="outlined-color"
              class="h-12 w-full"
              @click="onCancelClick"
            >
              Cancel
            </base-button>
            <base-button
              v-analytics:click="{
                pageGroup: 'Drawer',
                page: 'Verify payment method',
                component1: isCard ? 'Credit/Debit card' : 'Bank account',
                cta: 'Confirm',
              }"
              :disabled="verifying || !verifySum"
              :loading="verifying"
              type="button"
              class="h-12 w-full"
              @click="submitGuess"
            >
              Confirm
            </base-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
