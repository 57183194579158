<script setup lang="ts">
/*global Kyc*/
import { computed } from 'vue'
import VerificationField, {
  VerificationFieldType,
  type VerificationFieldProps,
} from '/~/extensions/otp/components/verification-field/verification-field.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export interface VerificationFieldItem {
  type: VerificationFieldType
  status?: Kyc.VerificationSessionStatus
  errors?: Kyc.VerificationErrors
  value?: string
  isVerified: boolean
  loading?: boolean
}

const props = withDefaults(
  defineProps<{
    verificationFields: VerificationFieldItem[]
    isLoading?: boolean
    doVerification: VerificationFieldProps['doVerification']
    doVerificationCancel?: VerificationFieldProps['doVerificationCancel']
    updateStatus?: VerificationFieldProps['updateStatus']
    back?: Partial<Record<'name' | 'hash', string | null>>
  }>(),
  {
    isLoading: true,
  }
)

const toVerifyLabel = computed(() => {
  const isEnrolmentMobileChannelEnabled = props.verificationFields.some(
    (item) => item.type === 'mobile'
  )
  const isEnrolmentEmailChannelEnabled = props.verificationFields.some(
    (item) => item.type === 'email'
  )

  if (isEnrolmentMobileChannelEnabled && isEnrolmentEmailChannelEnabled) {
    return 'both the email and mobile number'
  } else if (isEnrolmentEmailChannelEnabled) {
    return 'the email'
  } else if (isEnrolmentMobileChannelEnabled) {
    return 'the mobile number'
  } else {
    return null
  }
})

const hasError = (errors?: VerificationFieldItem['errors']) => {
  return Boolean(
    (errors && !Array.isArray(errors)) ||
      (errors && Array.isArray(errors) && errors.length > 0)
  )
}
</script>

<template>
  <base-aside-page title="Verification centre" no-padding :back="back">
    <template #action><div></div></template>
    <div class="flex flex-col space-y-6 py-6 px-5">
      <div
        class="flex h-20 w-20 items-center justify-center self-center rounded-full bg-primary-lightest"
      >
        <base-icon
          svg="heroicons/solid/shield-check"
          :size="43"
          class="text-primary"
        />
      </div>

      <h2 class="text-center">Verify your account</h2>

      <div v-if="toVerifyLabel" class="text-neutral-600">
        To ensure the security of your account, we require you to verify
        {{ toVerifyLabel }} associated with this account.
      </div>

      <verification-field
        v-for="field in verificationFields"
        :key="field.type"
        :type="field.type"
        :status="field.status"
        :has-error="hasError(field.errors)"
        :value="field.value"
        :is-loading="isLoading || Boolean(field.loading)"
        :is-verified="field.isVerified"
        :do-verification="doVerification"
        :do-verification-cancel="doVerificationCancel"
        :update-status="updateStatus"
      />
    </div>
  </base-aside-page>
</template>
