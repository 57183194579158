<script>
import modal from '/~/core/mdl'
import PostCreatorForm from '/~rec/components/post/creator/post-creator-form.vue'

export default {
  name: 'post-editor',
  components: {
    PostCreatorForm,
  },
  inject: ['post'],
  methods: {
    navigate(name, options) {
      modal.show(name, options)
    },
  },
}
</script>

<template>
  <post-creator-form
    :type="post.type"
    :post="post"
    @navigate="navigate"
    v-on="$listeners"
  />
</template>
