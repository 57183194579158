<script>
import PhotoIdTile from './components/photo-id-tile.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.vue'
import BaseCard from '/~/components/base/card/base-card.vue'
import { usePhotoId } from '/~/composables/photo-ids'
import { computed } from 'vue'

export default {
  name: 'drawer-photo-id',
  components: {
    PhotoIdTile,
    DrawerActions,
    BaseAsidePage,
    BaseCard,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { isPhotoIdLoading, photoId, fetchPhotoId, deletePhotoId } =
      usePhotoId()

    const actions = computed(() => {
      return [
        {
          label: `View ${
            photoId.value?.isLicence ? 'Drivers Licence' : 'Passport'
          }`,
          description: `See images of your actual ${
            photoId.value?.isLicence ? 'Licence' : 'Passport'
          }`,
          icon: 'menu/eye',
          click: () => {
            emit('open', photoId.value?.id)
          },
        },
        {
          label: 'Delete',
          description: 'Delete this Photo ID',
          icon: 'plain/trash',
          type: 'danger',
          confirm: {
            text: 'Are you sure?',
            progressText: 'Deleting...',
            async submit() {
              try {
                await deletePhotoId(photoId.value?.id)
                emit('delete')
              } catch (error) {
                this.$notify({
                  text: 'Failed to delete Photo ID',
                  type: 'error',
                  duration: 1000,
                })
              }
            },
          },
        },
      ]
    })

    if (!props.id) {
      emit('empty')
    } else if (!photoId.value) {
      fetchPhotoId(props.id).catch(() => emit('empty'))
    }

    return {
      isPhotoIdLoading,
      photoId,
      actions,
    }
  },
}
</script>

<template>
  <base-aside-page title="Photo ID">
    <div class="mx-auto w-72 max-w-full space-y-[30px]">
      <photo-id-tile v-if="photoId" :document="photoId" />
      <base-card v-else class="animate-pulse rounded-xl bg-divider" />
    </div>

    <drawer-actions :actions="actions" />
  </base-aside-page>
</template>
