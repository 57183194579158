<script>
import ui from '/~/core/ui'
const SYMBOLS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

export default {
  name: 'profile-scroll',
  props: {
    letters: {
      type: Array,
      default: () => SYMBOLS,
    },
    idPrefix: {
      type: String,
      default: 'letter-',
    },
  },
  setup() {
    return {
      ui,
    }
  },
  data() {
    return {
      touchClientX: -1,
      touchClientY: -1,
      lettersRect: { top: 0, height: 0 },
      hoveredLetter: '',
      touchLabelVisible: false,
    }
  },
  computed: {
    lettersList() {
      return this.letters
        .map((l) => l.toUpperCase())
        .sort((a, b) => (a > b ? 1 : -1))
    },
    labelTopOffset() {
      const {
        lettersRect: { top, height },
        touchClientY,
      } = this
      const minOffset = 0
      const maxOffset = height
      const offset = Math.min(
        Math.max(touchClientY - top, minOffset),
        maxOffset
      )

      return `${offset}px`
    },
  },
  watch: {
    hoveredLetter(newLetter, oldLetter) {
      if (newLetter !== oldLetter && newLetter) {
        this.onLetterClick(newLetter)
      }
    },
  },
  methods: {
    onLetterClick(letter) {
      const scrollToNode = document.getElementById(this.idPrefix + letter)
      const scrollWrapper = this.$slots.default[0].elm
      const { container } = this.$refs

      if (!scrollToNode || !scrollWrapper || !container) {
        return
      }

      const wrapperTop = scrollWrapper.getBoundingClientRect().top
      const toNodeTop = scrollToNode.getBoundingClientRect().top

      container.scrollTop = toNodeTop - wrapperTop
    },
    getHoveredLetter(clientY) {
      const hoveredElement = document.elementFromPoint(
        this.touchClientX,
        clientY
      )

      if (hoveredElement !== null) {
        const { letter } = hoveredElement.dataset

        if (letter) {
          this.touchLabelVisible = true
          this.hoveredLetter = letter
          this.touchClientY = clientY
        }
      }
    },
    onTouchStart(e) {
      e.preventDefault()

      const { top, height } = this.$refs.letters.getBoundingClientRect()
      const { clientY, clientX } = e.touches[0]

      this.lettersRect = { top, height }
      this.touchClientX = clientX

      this.getHoveredLetter(clientY)
    },
    onTouchMove(e) {
      e.preventDefault()

      this.getHoveredLetter(e.touches[0].clientY)
    },
    onTouchEnd(e) {
      e.preventDefault()

      this.touchLabelVisible = false
    },
  },
}
</script>

<template>
  <div class="flex h-full w-full items-start justify-between">
    <div ref="container" class="h-full w-full overflow-y-auto pb-5">
      <slot />
    </div>
    <div
      ref="letters"
      :class="[
        $style.letters,
        ui.mobile && $style.mobileLetters,
        touchLabelVisible && $style.hiddenLetters,
      ]"
    >
      <transition name="scroll-label-slide">
        <div
          v-show="touchLabelVisible"
          :class="$style.label"
          :style="{ top: labelTopOffset }"
        >
          <span class="relative text-2xl font-bold text-white">
            {{ hoveredLetter }}
          </span>
        </div>
      </transition>

      <div
        v-for="letter in lettersList"
        :key="letter"
        :class="$style.letter"
        :data-letter="letter"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        @click="onLetterClick(letter)"
      >
        {{ letter }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.label {
  right: calc(100% + 20px);
  transform: translateY(-50%);

  @apply pointer-events-none absolute top-0 z-10 flex h-10 w-10 items-center justify-center;

  &:before {
    content: '';
    background-color: var(--color-primary);
    border-radius: 50% 50% 0;
    transform: rotate(-45deg);

    @apply absolute top-0 left-0 block h-10 w-10;
  }
}

.letters {
  position: absolute;
  right: 1rem;
  z-index: 10;
  flex-shrink: 0;
  align-self: center;
  max-height: 100%;
  text-align: center;

  &.mobileLetters {
    right: 0;
  }

  &.hiddenLetters .letter {
    opacity: 0;
  }
}

.letter {
  transition: color 0.2s ease, opacity 0.3s ease;
  -webkit-tap-highlight-color: transparent;

  @apply cursor-pointer select-none px-[5px] pb-[5px] text-xs font-bold;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: var(--color-primary);
  }
}
</style>

<style lang="scss">
.scroll-label-slide {
  &-enter-active,
  &-leave-active {
    transition: transform 0.3s ease, opacity 0.2s ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-50%) translateX(30%) !important;
  }
}
</style>
