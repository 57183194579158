<script>
import { defineComponent } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DefaultEpurchaseTile from '/~/components/drawer/components/tiles/drawer-e-purchases-tile.vue'

export default defineComponent({
  components: {
    BaseIcon,
  },
  extends: DefaultEpurchaseTile,
})
</script>

<template>
  <div
    class="relative flex flex-col no-underline"
    :class="{
      'cursor-pointer': clickable,
    }"
  >
    <div
      class="overflow-hidden shadow-xs transition duration-100 group-hover:shadow-lg"
      :class="[detailsView ? 'rounded-3xl' : 'rounded-lg']"
    >
      <base-image
        :src="imageSrc"
        :alt="card.retailerName"
        :style="{
          filter: disabled ? 'grayscale(1)' : undefined,
        }"
        :active="false"
        class="rounded-lg transition duration-100 group-hover:shadow-lg"
        @click="onClick"
      >
        <div
          v-if="card.pending"
          class="absolute inset-0 flex h-full w-full cursor-default items-center justify-center bg-overlay p-2.5 text-center opacity-0 transition duration-100 hover:opacity-100"
          @click.stop
        >
          <span class="p-[5px] text-sm text-white">
            Pending Delivery
            <br />
            May take up to 72 Hours to Process.
          </span>
        </div>
      </base-image>
    </div>

    <div
      v-if="!card.viewed && !card.pending"
      class="absolute top-0 right-0 z-10 -mr-[5px] rounded-xl bg-red-700 px-1.5 py-0.5 text-center text-xs font-bold uppercase text-white shadow"
      :class="[!detailsView && '-mt-[5px]']"
    >
      new
    </div>
    <div
      class="leading-6"
      :class="[
        'relative self-center rounded-full text-center font-semibold text-white',
        disabled ? 'bg-neutral-400' : 'bg-primary',
        detailsView
          ? ' -mt-6 px-6 text-base leading-10'
          : '-mt-3.5 px-2.5 text-sm leading-7',
      ]"
    >
      {{ formatDollar(balance) }}
    </div>
    <div v-if="extended" class="mt-2.5">
      <div class="text-base font-bold leading-4">
        {{ title }}
      </div>
      <div
        class="mt-[5px] flex flex-row items-center text-sm text-eonx-neutral-600"
      >
        <base-icon svg="australiapost/calendar2" class="mr-1.5" :size="18" />
        <span class="leading-5">
          {{ formatDate('daymonthyear', card.purchasedAt) }}
        </span>
      </div>
    </div>
    <div v-if="archived" class="mt-2.5">
      <base-button
        :disabled="restoring"
        :loading="restoring"
        full-width
        size="sm"
        @click="restoreCard"
      >
        Restore
      </base-button>
    </div>
  </div>
</template>
