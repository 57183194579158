// A grapheme cluster is a sequence of one or more
// Unicode code points that should be treated as a single unit
// https://unicode.org/reports/tr29/
export function getExtendedGraphemeClusters(string: string) {
  const strLength = string.length
  const clusters = []

  for (let i = 0; i < strLength; i++) {
    let charCode = string.charCodeAt(i)

    if (charCode >= 0xd800 && charCode <= 0xdbff) {
      charCode = string.charCodeAt(i + 1)

      if (charCode >= 0xdc00 && charCode <= 0xdfff) {
        clusters.push(string.slice(i, i + 2))
        i++

        continue
      }
    }

    clusters.push(string.charAt(i))
  }

  return clusters
}
