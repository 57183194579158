<script>
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'rec-sidebar-bottom-banner',
  components: {
    BaseImage,
  },
  computed: {
    imageBindings() {
      const imagePath =
        'https://cdn.eonx.net/e178ea95-0628-4201-8293-cd28c22b1931/'
      const normalLink = this.cdn(imagePath).scaleCrop('325x378/smart').url()
      const retinaLink = this.cdn(imagePath).scaleCrop('650x756/smart').url()

      return {
        src: normalLink,
        srcset: `${normalLink} 1x, ${retinaLink} 2x`,
        ratio: 325 / 378,
      }
    },
  },
}
</script>

<template>
  <div class="relative overflow-hidden rounded-lg">
    <base-image v-bind="imageBindings" />
    <div class="absolute bottom-0 text-white">
      <div
        class="mb-[30px] px-5 text-center leading-6"
        style="font-size: 22px; font-weight: 900"
      >
        Shop and save with Australia’s biggest retailers
      </div>
      <div class="bg-white">
        <router-link
          :to="{ name: 'giftcards' }"
          class="flex cursor-pointer items-center justify-center bg-primary py-[15px] font-bold hover:opacity-75"
          style="font-size: 17px"
        >
          Access eGift Cards
        </router-link>
      </div>
    </div>
  </div>
</template>
