<script>
import debounce from 'lodash-es/debounce'
import { ref } from 'vue'
import { useCompetitions } from '/~/extensions/competitions/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import SelectField from '/~/components/mobile-filter/select-field.vue'

export default {
  name: 'competitions-filter-mobile',
  components: {
    EwFilters,
    BaseIcon,
    BaseInput,
    SelectField,
  },
  setup() {
    const {
      isFiltersSelected,
      resetFilters,
      defaultSorting,
      defaultSort,
      sortValues,
      selectedSorting,
      searchQuery,
      searchGroup,
      label,
    } = useCompetitions()

    const filters = ref(null)
    const isShowSearch = ref(false)

    const localSearchQuery = ref(searchQuery.value)
    const localSorting = ref(selectedSorting.value)

    const onSearchInput = debounce(function (value) {
      searchQuery.value = value
    }, 350)

    function resetLocalFilters() {
      localSearchQuery.value = searchQuery.value
      localSorting.value = selectedSorting.value
    }

    function applyFilters() {
      searchQuery.value = localSearchQuery.value
      selectedSorting.value = localSorting.value
    }

    return {
      isFiltersSelected,
      resetFilters,
      resetLocalFilters,
      applyFilters,
      localSearchQuery,
      localSorting,

      selectedSorting,
      defaultSorting,
      defaultSort,
      sortValues,
      searchQuery,
      isShowSearch,
      onSearchInput,
      searchGroup,
      label,
      filters,
    }
  },
}
</script>

<template>
  <div class="w-auto bg-white">
    <ew-filters
      title="Filters"
      :is-filters-selected="isFiltersSelected"
      @clear="resetFilters"
      @close="resetLocalFilters"
      @apply="applyFilters"
    >
      <div class="relative mt-2.5 grow">
        <base-input
          v-model="localSearchQuery"
          :placeholder="`Search ${searchGroup}`"
          :icon-plain="true"
          look="rounded"
          clearable
          nolabel
        >
          <template #icon>
            <div class="ml-[15px] mr-2.5 flex items-center justify-center">
              <base-icon
                svg="plain/search-v2"
                class="text-eonx-neutral-600"
                :size="19"
              />
            </div>
          </template>
        </base-input>
      </div>
      <div class="space-y-4 py-4">
        <select-field
          v-model="localSorting"
          title="Sorting by"
          :options="sortValues"
          :empty-option="defaultSort"
        />
      </div>
    </ew-filters>
  </div>
</template>
