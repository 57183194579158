<script>
import { ValidationObserver } from 'vee-validate'
import { Pipeline } from '/~/core/pipeline'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useLocalization } from '/~/composables/localization'
import { useUser } from '/~/composables/user'

export default {
  name: 'pa-insurance-comparison-form-modal',
  components: {
    BaseInput,
    BaseButton,
    BaseMdl,
    BaseSelect,
    BaseTextarea,
    ValidationObserver,
  },
  setup() {
    const { user } = useUser()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { states, translate } = useLocalization()
    const { validationObserverRef } = useForm()

    return {
      user,
      backendErrors,
      processBackendErrors,
      states,
      translate,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {},
      processing: false,
      success: false,
      trackingCode: '',
      postalCodeMask: {
        mask: /^[0-9]\d{0,3}$/,
        lazy: true,
      },
    }
  },
  computed: {
    formTitle() {
      return Pipeline.formTitle('Insurance Comparison')
    },
    stateOptions() {
      return this.states.map((state) => {
        return {
          text: state.label,
          value: state.label,
        }
      })
    },
    contactTimeOptions() {
      return [
        { text: '8:30am-noon', value: '8:30am-noon' },
        { text: 'Noon-3pm', value: 'noon-3pm' },
        { text: '3pm-5:30pm', value: '3pm-5:30pm' },
      ]
    },
  },
  created() {
    const { email, firstName, lastName, mobile: phone } = this.user
    const userDetails = {
      email,
      first_name: firstName,
      last_name: lastName,
      phone,
    }

    this.form = { ...this.form, ...userDetails }
  },
  methods: {
    async onSubmit() {
      this.processing = true
      this.backendErrors = {}

      try {
        const { trackingId } = await Pipeline.submit(
          'pa/insurance-comparison',
          this.form
        )

        this.trackingCode = trackingId[0]
        this.success = true
      } catch (error) {
        this.processBackendErrors(error)
        this.$emit('error', error)
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<template>
  <base-mdl :title="formTitle" fullscreen="mobile">
    <div v-if="!success">
      <div class="bg-slate-200 p-6">
        <p class="mb-[15px]">
          To take advantage of the member Advantage Financial Services FREE
          Insurance Comparison offer and make sure you get the best deal...
          simply:
        </p>
        <p class="mb-[15px]">
          Call us now on
          <strong>
            <a href="tel:1300365152" title="call at ">1300 365 152</a>
          </strong>
          or complete the form below.
        </p>
        <p>
          <small>
            Required fields are marked with
            <sup>*</sup>
          </small>
        </p>
      </div>
      <validation-observer
        v-slot="{ handleSubmit }"
        ref="validationObserverRef"
        slim
      >
        <form
          class="-mx-2.5 mt-6 flex flex-wrap"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <base-input
            v-model="form.first_name"
            :validation="{
              rules: 'required',
              name: 'First Name',
            }"
            :error="backendErrors.first_name"
            label="First Name"
            required
            name="first_name"
            class="mx-2.5"
          />
          <base-input
            v-model="form.last_name"
            :validation="{
              rules: 'required',
              name: 'Last Name',
            }"
            :error="backendErrors.firstName"
            data-vv-as="Last Name"
            label="Last Name"
            required
            name="last_name"
            class="mx-2.5"
          />

          <base-input
            v-model="form.street_address"
            label="Street Address"
            name="street_address"
            class="mx-2.5"
          />

          <base-input
            v-model="form.suburb"
            label="Suburb"
            name="suburb"
            class="mx-2.5"
          />
          <base-select
            v-model="form.state"
            :options="stateOptions"
            label="State"
            name="state"
            class="mx-2.5"
          />
          <base-input
            v-model="form.postcode"
            :mask="postalCodeMask"
            :label="translate('address.postcode')"
            name="postcode"
            class="mx-2.5"
          />
          <base-input
            v-model="form.phone"
            :validation="{
              rules: 'required',
              name: 'Mobile',
            }"
            :error="backendErrors.phone"
            label="Mobile"
            required
            type="tel"
            mask="mobile"
            name="phone"
            class="mx-2.5"
          />
          <base-input
            v-model="form.email"
            :validation="{
              rules: 'required|email',
              name: 'Email',
            }"
            :error="backendErrors.email"
            label="Email"
            required
            name="email"
            type="email"
            class="mx-2.5"
          />
          <base-select
            v-model="form.contact_time"
            :options="contactTimeOptions"
            label="Contact Time (Mon-Fri)"
            name="contact_time"
            class="mx-2.5"
          />
          <base-textarea
            v-model="form.comments"
            label="Comments or Questions"
            name="comments"
            class="mx-2.5 w-full grow"
          />

          <div class="mx-2.5 w-full grow">
            <base-button
              class="w-full"
              type="submit"
              :disabled="processing"
              :loading="processing"
            >
              Submit
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
    <template v-else>
      <transition name="fade">
        <div class="my-6 bg-slate-200 p-6">
          <h2>Success</h2>
          <p>
            Form have been submitted.
            <br />
            Your Tracking code is:
            <strong>{{ trackingCode }}</strong>
          </p>
        </div>
      </transition>
    </template>
  </base-mdl>
</template>
