<script>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useAddresses } from '/~/composables/addresses'
import { useCheckout } from '/~/composables/checkout'

export default {
  name: 'addresses-item',
  components: {
    BasePlaceholder,
    BaseIcon,
    BaseLoader,
    BaseMenu,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()

    const { payment } = useCheckout()

    const { addressesLoaded, removeAddress, makeAddressDefault } =
      useAddresses()

    const processing = ref(false)

    const name = computed(() => {
      return `${props.item.firstName} ${props.item.lastName}`
    })
    const address = computed(() => {
      const { item } = props

      return [
        item.streetAddress,
        item.suburb,
        (item.state ?? '').toUpperCase(),
        item.postcode,
      ].join(' ')
    })

    function getMenuItems(address) {
      return [
        {
          icon: 'plain/edit2',
          content: 'Edit address',
          click: () => {
            router.push(payment.getEditAddressRoute(String(address.id)))
          },
        },
        {
          icon: 'trash',
          content: 'Delete address',
          hidden: address.default,
          click: async () => {
            processing.value = true
            emit('remove')
            await removeAddress(address)
            processing.value = false
          },
        },
        {
          icon: 'plain/check',
          content: 'Set as Default',
          hidden: address.default,
          click: async () => {
            processing.value = true
            await makeAddressDefault(address)
            processing.value = false
          },
        },
      ]
    }

    return {
      addressesLoaded,
      removeAddress,
      makeAddressDefault,
      processing,
      name,
      address,
      getMenuItems,
    }
  },
}
</script>

<template>
  <div
    class="relative flex min-h-16 items-center justify-between rounded-lg border border-neutral-200 bg-white px-[15px] py-2.5 text-default sm:min-h-20 sm:px-6"
    :class="[addressesLoaded && !processing && 'cursor-pointer']"
    @click="$emit('click')"
  >
    <div v-if="!addressesLoaded" class="w-full">
      <div class="w-3.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
      <div class="w-1.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
    </div>

    <template v-else>
      <div
        class="mr-5 flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-white"
        :class="processing && 'opacity-50'"
      >
        <base-icon
          svg="v2/heroic/location-marker-filled"
          :size="48"
          class="text-primary"
        />
      </div>

      <div class="flex-1" :class="processing && 'opacity-50'">
        <div class="flex items-start">
          <div class="font-bold leading-6">
            {{ item ? name : 'Select or add billing address' }}
          </div>
        </div>

        <span v-if="item" class="block text-sm leading-6">
          {{ address }}
        </span>
      </div>

      <div
        class="ml-5 flex items-center justify-center"
        :class="processing && 'opacity-50'"
        @click="showMenu && $event.stopPropagation()"
      >
        <base-menu v-if="showMenu" :items="getMenuItems(item)" position="right">
          <template #trigger>
            <div class="flex">
              <base-icon svg="plain/more" class="text-primary" size="lg" />
            </div>
          </template>
        </base-menu>

        <base-icon v-else svg="v2/custom/chevron-right-outline" :size="16" />
      </div>
    </template>

    <base-loader v-if="processing" class="absolute inset-0" fullwidth />
  </div>
</template>
