<script>
import LayoutView from '/~rec/layouts/view/layout-view.vue'

export default {
  name: 'rec-groups-view',
  components: {
    LayoutView,
  },
}
</script>

<template>
  <layout-view>
    <router-view />
  </layout-view>
</template>
