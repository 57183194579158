<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'
import { Statement, useStatements } from '/~/composables/statements'
import StatementsItem from '/~/templates/bill-payments/components/statements-item.vue'

const { activeItems, fetchActiveStatements, isStatementsEnabled } =
  useStatements()

const notBlockedItems = computed<Statement[]>(
  () => activeItems.value.filter((items) => !items.isBlocked) as Statement[]
)

onBeforeMount(() => {
  if (isStatementsEnabled.value) {
    fetchActiveStatements({
      'order[dueDate]': 'asc',
    })
  }
})
</script>

<template>
  <div v-if="isStatementsEnabled">
    <statements-item
      v-for="(item, index) in notBlockedItems"
      :key="index"
      :item="item"
    >
      Pay now
    </statements-item>
  </div>
</template>
