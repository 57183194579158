<script>
import { computed } from 'vue'
import ui from '/~/core/ui'
import AppHeaderLogo from '/~/components/app/header/app-header-logo.vue'
import BaseButton from '/~/components/base/button/base-button'
import Points from '/~/components/points/points.vue'
import { useCms } from '/~/composables/cms'
import HeaderTopMenu from './components/header-top-menu/header-top-menu.v4.vue'

export default {
  name: 'app-header-v4-auspost',
  components: {
    AppHeaderLogo,
    HeaderTopMenu,
    Points,
    BaseButton,
  },
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { cmsConfig } = useCms()
    const logoWidth = computed(() => cmsConfig.value['header-logo-max-width'])

    return {
      logoWidth,
      ui,
    }
  },
}
</script>

<template>
  <header class="border-b bg-light sm:border-none">
    <portal-target name="app-header" slim />
    <div
      class="relative flex h-14 items-center justify-between px-[15px] sm:h-auto sm:px-6 md:h-[88px]"
    >
      <app-header-logo class="mr-5 py-2.5" :max-width="logoWidth" />
      <points class="hidden xs:block" />
      <base-button
        v-if="!ui.mobile"
        look="outlined-color"
        class="mr-6"
        @click="$router.push({ name: 'activity' })"
      >
        View my leads
      </base-button>
      <header-top-menu />
    </div>
    <portal-target name="header-bottom" slim />
  </header>
</template>
