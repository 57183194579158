<script>
import DefaultGiftCardTile from '/~/extensions/gift-cards-cinema/components/gc-tile.vue'

export default {
  name: 'giftcards-tile',
  extends: DefaultGiftCardTile,
}
</script>

<template>
  <base-link
    :to="{
      name: routeName,
      params: {
        slug: tile.retailer_slug,
        id: tile.offer_external_id,
      },
    }"
    class="group relative z-0 block h-full"
  >
    <base-image
      v-bind="imageBindings"
      :default-src="defaultImage"
      :alt="alt"
      aria-hidden="true"
      class="rounded-lg shadow-xs transition duration-100 group-hover:shadow-lg"
    />
    <div
      v-if="isPhysicalEnabled"
      class="absolute left-1/2 z-1 -mt-[15px] -translate-x-1/2 transform text-xs"
    >
      <gc-badge :is-physical="isPhysical" />
    </div>
    <div class="mt-4 px-2.5 text-center text-default">
      <h3
        class="truncate text-center text-sm font-normal text-eonx-neutral-600"
        data-cy="ew-retailer"
      >
        {{ tile.retailer_name }}
      </h3>
      <div
        v-if="tile.offer_subtitle"
        class="mt-[5px] truncate font-bold text-eonx-neutral-800"
      >
        {{ tile.offer_subtitle }}
      </div>
    </div>
  </base-link>
</template>
