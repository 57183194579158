<script>
import ui from '/~/core/ui'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import QrModalHeader from './qr-modal-header.vue'

export default {
  name: 'qr-modal',
  components: {
    QrModalHeader,
    BaseMdl,
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'success',
      validator: (v) => /error|success/.test(v),
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    isSuccess() {
      return this.type === 'success'
    },
  },
}
</script>

<template>
  <base-mdl
    v-bind="$attrs"
    width="xse"
    :fullscreen="ui.mobile"
    transition="slide-bottom"
  >
    <template #header>
      <qr-modal-header :is-success="isSuccess" />
    </template>

    <div class="flex h-full flex-col justify-between">
      <div class="flex flex-col">
        <h1 class="mb-[30px] text-center text-xl">
          <slot name="title" />
        </h1>
        <slot />
      </div>
      <div>
        <slot name="footer" />
      </div>
    </div>
  </base-mdl>
</template>
