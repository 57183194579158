import { activated } from './common'

const commonFilters = [activated]
const specificFilters = {
  // 'bank-file-upload': [someSpecificFilter],
}

export function moduleAllowed(moduleName) {
  const filters = commonFilters.concat(specificFilters[moduleName] ?? [])

  for (const filter of filters) {
    if (!filter(moduleName)) {
      return false
    }
  }

  return true
}
