<script>
import ui from '/~/core/ui'
import RenderBanner from '/~rec/components/render/render-banner.vue'

export default {
  name: 'events-tile-image',
  components: {
    RenderBanner,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <render-banner
    :image="event.image"
    rounded="rounded-lg"
    :ratio="false"
    :class="{
      'h-[88px] w-16': ui.mobile,
      'h-16 w-16': !ui.mobile,
    }"
  />
</template>
