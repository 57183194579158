<script>
import modal from '/~/core/mdl'
import ChatRoom from '/~rec/components/chat/room/chat-room.vue'
import ChatMenu from '/~rec/components/chat/chat-menu.vue'
import RenderAvatars from '/~rec/components/render/render-avatars.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { editMembers } from '/~rec/components/chat/chat-menu'

export default {
  name: 'rec-chat-window-modal',
  components: {
    ChatRoom,
    ChatMenu,
    RenderAvatars,
    BaseMdl,
    HeaderMobile,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onBack() {
      modal.hide()
    },
    editMembers,
  },
}
</script>

<template>
  <base-mdl fullscreen transition="slide-right" width="screen" :layout="false">
    <div class="flex h-full flex-col">
      <header-mobile :title="room.title" @back="onBack">
        <template #right>
          <chat-menu ref="chatMenu" :room="room" />
        </template>

        <template #image>
          <render-avatars
            class="text-primary"
            look="col"
            size="md"
            :users="room.activeMembersWithoutMe"
            @click="editMembers"
          />
        </template>
      </header-mobile>
      <chat-room class="flex-1 overflow-hidden" :room="room" />
    </div>
  </base-mdl>
</template>
