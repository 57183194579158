<script>
import { computed, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseState from '/~/components/base/state/base-state.vue'
import PaymentMethodSplitItem from '/~/components/payments/list/item/split.vue'
import PaymentMethodBlock from '/~/components/payments/payments-methods-block.vue'
import PaymentMethodSelectBlock from '/~/components/payments/payments-methods-select-block.vue'
import PaymentsPba from '/~/components/payments/pba/payments-pba.vue'
import {
  usePurchaseCheckout,
  useQuickBuyCheckout,
  usePayeeCheckout,
  useStatementCheckout,
} from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useEwallet } from '/~/composables/ewallet'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'payments-methods-split-list',
  components: {
    BaseButton,
    BaseIcon,
    BaseState,
    PaymentMethodSplitItem,
    PaymentMethodBlock,
    PaymentMethodSelectBlock,
    PaymentsPba,
  },
  props: {
    flowType: {
      type: String,
      default: FlowType.purchase,
      validator: (v) => !v || Object.values(FlowType).includes(v),
    },
  },
  setup(props) {
    const router = useRouter()
    const { isSplitEnabled, isAustraliaPostProvider } = useProvider()
    const {
      isMethodAvailable,
      points,
      eWallets,
      noEwallets,
      creditCards,
      bankAccounts,
      noCreditCards,
      noBankAccounts,
      fetchingList: fetching,
      isVisaEnabled,
      isMasterCardEnabled,
      isAmexEnabled,
    } = usePaymentMethods()
    const { ewalletBalance } = useEwallet()
    const { pointsBalance } = usePoints()

    const paymentComposable = computed(() => {
      switch (props.flowType) {
        case FlowType.payment:
          return usePayeeCheckout
        case FlowType.statement:
          return useStatementCheckout
      }

      return router.currentRoute.meta.quickBuy
        ? useQuickBuyCheckout
        : usePurchaseCheckout
    })

    const paymentType = computed(() => {
      switch (props.flowType) {
        case FlowType.payment:
          return FlowType.payment
        case FlowType.statement:
          return FlowType.statement
      }

      return FlowType.purchase
    })

    const {
      payment,
      subTotalWithProgramFees,
      selectedPoints,
      selectedEwallet,
      selectedCard,
      selectedBankAccount,
      isPointsDisabled,
      isCreditCardsDisabled,
      isBankAccountsDisabled,
      isEwalletDisabled,
      getTransactionFees,
    } = paymentComposable.value()

    const pointsPaymentMethodBlockRef = ref(null)
    const pointsPaymentMethodItemRef = ref(null)

    const isShowPointsSelectModalOnLoad = computed(() => {
      return (
        isSplitEnabled.value &&
        pointsBalance.value > 0 &&
        isAustraliaPostProvider.value
      )
    })

    const isEwalletAvailable = computed(() =>
      isMethodAvailable(PaymentMethodType.eWallet, paymentType.value)
    )

    const isPointsAvailable = computed(() =>
      isMethodAvailable(PaymentMethodType.points, paymentType.value)
    )

    const isCreditCardsAvailable = computed(() =>
      isMethodAvailable(PaymentMethodType.creditCard, paymentType.value)
    )

    const isBankAccountsAvailable = computed(() =>
      isMethodAvailable(PaymentMethodType.bankAccount, paymentType.value)
    )

    const isEmpty = computed(
      () => noCreditCards.value && noBankAccounts.value && noEwallets.value
    )
    const isPointsToggled = computed(() => {
      return (
        Boolean(selectedPoints.value) || isShowPointsSelectModalOnLoad.value
      )
    })
    const fulfilledAmount = computed(() => {
      return Math.min(
        subTotalWithProgramFees.value,
        (selectedPoints.value?.calculatedAmount ?? 0) +
          (selectedCard.value?.calculatedAmount ?? 0) +
          (selectedBankAccount.value?.calculatedAmount ?? 0)
      )
    })
    const amountToPay = computed(() => {
      return subTotalWithProgramFees.value - fulfilledAmount.value
    })
    const isAmountFulfilledWithPoints = computed(
      () =>
        selectedPoints.value?.calculatedAmount === subTotalWithProgramFees.value
    )

    function addNewPaymentMethod() {
      router.push({
        hash: '#profile-payment-methods-add',
        params: {
          source: payment.orderType,
        },
      })
    }

    function onPaymentMethodConfirm(type) {
      if (isSplitEnabled.value) {
        switch (type) {
          case PaymentMethodType.points:
            if (!selectedPoints.value) {
              pointsPaymentMethodBlockRef.value?.toggleOff()
            }

            break
        }
      }
    }

    function onPaymentMethodCancel(type) {
      if (isSplitEnabled.value) {
        switch (type) {
          case PaymentMethodType.points:
            if (!selectedPoints.value) {
              pointsPaymentMethodBlockRef.value?.toggleOff()
            }

            break
        }
      }
    }

    function onPaymentMethodToggledOff(type) {
      if (isSplitEnabled.value) {
        switch (type) {
          case PaymentMethodType.points:
            if (selectedCard.value && selectedBankAccount.value) {
              resetSelectedMethods()
            } else {
              selectedPoints.value = null

              if (selectedCard.value) {
                selectedCard.value.calculatedAmount += amountToPay.value
              } else if (selectedBankAccount.value) {
                selectedBankAccount.value.calculatedAmount += amountToPay.value
              }

              getTransactionFees()
            }
            break
        }
      }
    }

    function onPaymentMethodToggledOn(type) {
      if (isSplitEnabled.value) {
        switch (type) {
          case PaymentMethodType.points:
            resetSelectedMethods()
            break
        }

        pointsPaymentMethodItemRef.value?.onClick()
      }
    }

    function onPaymentMethodUnselect(type) {
      if (isSplitEnabled.value) {
        switch (type) {
          case PaymentMethodType.points:
            selectedPoints.value = null
            pointsPaymentMethodBlockRef.value?.toggleOff()
            onPaymentMethodToggledOff(type)

            break
          case PaymentMethodType.creditCard:
            if (selectedBankAccount.value) {
              selectedBankAccount.value.calculatedAmount +=
                selectedCard.value.calculatedAmount
            }

            selectedCard.value = null

            break
          case PaymentMethodType.bankAccount:
            if (selectedCard.value) {
              selectedCard.value.calculatedAmount +=
                selectedBankAccount.value.calculatedAmount
            }

            selectedBankAccount.value = null

            break
          case PaymentMethodType.eWallet:
            if (selectedCard.value && selectedBankAccount.value) {
              selectedCard.value.calculatedAmount = 0
              selectedBankAccount.value.calculatedAmount = 0
            } else if (selectedCard.value) {
              selectedCard.value.calculatedAmount +=
                selectedEwallet.value.calculatedAmount
            } else if (selectedBankAccount.value) {
              selectedBankAccount.value.calculatedAmount +=
                selectedEwallet.value.calculatedAmount
            }

            selectedEwallet.value = null

            break
        }

        getTransactionFees()
      }
    }

    function resetSelectedMethods() {
      selectedPoints.value = null
      selectedCard.value = null
      selectedBankAccount.value = null
      getTransactionFees()
    }

    function showPointsSelectModal() {
      onPaymentMethodToggledOn(PaymentMethodType.points)
    }

    onMounted(() => {
      if (isShowPointsSelectModalOnLoad.value) {
        if (fetching.value) {
          emitter.once('payment-methods:fetched', showPointsSelectModal)
        } else {
          showPointsSelectModal()
        }
      }
    })

    return {
      isSplitEnabled,
      points,
      eWallets,
      noEwallets,
      creditCards,
      noCreditCards,
      bankAccounts,
      noBankAccounts,
      fetching,
      payment,
      selectedPoints,
      selectedEwallet,
      selectedCard,
      selectedBankAccount,
      pointsPaymentMethodBlockRef,
      pointsPaymentMethodItemRef,
      isPointsDisabled,
      isCreditCardsDisabled,
      isBankAccountsDisabled,
      isEwalletDisabled,
      isEwalletAvailable,
      isPointsAvailable,
      isCreditCardsAvailable,
      isBankAccountsAvailable,
      isEmpty,
      isPointsToggled,
      amountToPay,
      isAmountFulfilledWithPoints,
      addNewPaymentMethod,
      onPaymentMethodConfirm,
      onPaymentMethodCancel,
      onPaymentMethodToggledOff,
      onPaymentMethodToggledOn,
      onPaymentMethodUnselect,
      PaymentMethodType,
      ewalletBalance,
      isVisaEnabled,
      isMasterCardEnabled,
      isAmexEnabled,
    }
  },
}
</script>

<template>
  <div>
    <template v-if="isPointsAvailable">
      <payment-method-block
        v-if="points.length"
        ref="pointsPaymentMethodBlockRef"
        title="Points"
        :loading="fetching"
        :is-disabled="fetching || isPointsDisabled"
        :type="PaymentMethodType.points"
        :is-togglable="true"
        :is-toggled-initial="isPointsToggled"
        @toggled-off="onPaymentMethodToggledOff(PaymentMethodType.points)"
        @toggled-on="onPaymentMethodToggledOn(PaymentMethodType.points)"
      >
        <payment-method-split-item
          ref="pointsPaymentMethodItemRef"
          :flow-type="flowType"
          :method="points[0]"
          to="modals-portal"
          @confirm="onPaymentMethodConfirm(PaymentMethodType.points)"
          @cancel="onPaymentMethodCancel(PaymentMethodType.points)"
          @unselect="onPaymentMethodUnselect(PaymentMethodType.points)"
        />
      </payment-method-block>
    </template>

    <base-state v-if="isEmpty" title="Add your Payment Methods" align="left">
      <p>
        Securely store and manage your preferred credit and debit cards for
        instant use.
      </p>

      <template #footer>
        <base-button
          v-if="payment.canAddPaymentMethods"
          class="sm:mb-5"
          @click="addNewPaymentMethod()"
        >
          Add Payment Method
        </base-button>
      </template>
    </base-state>

    <div v-if="!isEmpty" class="mb-4 flex items-center justify-between">
      <span class="font-bold text-eonx-neutral-600">Payment Method(s)</span>
      <base-button
        v-if="payment.canAddPaymentMethods"
        look="link"
        @click="addNewPaymentMethod()"
      >
        + Add Payment Method
      </base-button>
    </div>

    <payment-method-split-item
      v-if="isEwalletAvailable && eWallets && eWallets.length > 0"
      :flow-type="flowType"
      :method="eWallets[0]"
      to="modals-portal"
      :is-disabled="
        ewalletBalance === 0 ||
        (!selectedEwallet && amountToPay === 0) ||
        isEwalletDisabled
      "
      @cancel="onPaymentMethodCancel(PaymentMethodType.eWallet)"
      @unselect="onPaymentMethodUnselect(PaymentMethodType.eWallet)"
    />

    <template v-if="isCreditCardsAvailable && !noCreditCards">
      <payment-method-select-block
        :is-item-selected="Boolean(selectedCard)"
        :is-loading="fetching"
        :is-disabled="
          (!selectedCard && isAmountFulfilledWithPoints) ||
          isCreditCardsDisabled
        "
        class="my-6"
      >
        <template #selectedItem>
          <payment-method-split-item
            :key="selectedCard.id"
            :flow-type="flowType"
            :method="selectedCard"
            to="modals-portal"
            @confirm="onPaymentMethodConfirm(PaymentMethodType.creditCard)"
            @cancel="onPaymentMethodCancel(PaymentMethodType.creditCard)"
            @unselect="onPaymentMethodUnselect(PaymentMethodType.creditCard)"
          />
        </template>
        <template #title>
          <div
            class="mr-2.5 flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-eonx-neutral-600"
          >
            <base-icon svg="symbion/card-cvv" :size="26" />
          </div>
          <div class="font-bold">Debit/credit card</div>
          <base-icon
            v-if="isMasterCardEnabled"
            svg="cards/mastercard"
            :size="22"
            class="ml-2.5"
          />
          <base-icon
            v-if="isVisaEnabled"
            svg="cards/visa"
            :size="22"
            class="ml-2.5"
          />
          <base-icon
            v-if="isAmexEnabled"
            svg="cards/amex"
            :size="20"
            class="ml-2.5"
          />
        </template>
        <template #items="{ toggleOff }">
          <payment-method-split-item
            v-for="method in creditCards"
            :key="method.id"
            :flow-type="flowType"
            :method="method"
            to="modals-portal"
            @confirm="
              toggleOff()
              onPaymentMethodConfirm(PaymentMethodType.creditCard)
            "
            @cancel="onPaymentMethodCancel(PaymentMethodType.creditCard)"
          />
        </template>
      </payment-method-select-block>
    </template>

    <template v-if="isBankAccountsAvailable && !noBankAccounts">
      <payment-method-select-block
        :is-item-selected="Boolean(selectedBankAccount)"
        :is-loading="fetching"
        :is-disabled="
          (!selectedBankAccount && isAmountFulfilledWithPoints) ||
          isBankAccountsDisabled
        "
        class="mb-6"
      >
        <template #selectedItem>
          <payment-method-split-item
            :key="selectedBankAccount.id"
            :flow-type="flowType"
            :method="selectedBankAccount"
            to="modals-portal"
            @cancel="onPaymentMethodCancel(PaymentMethodType.bankAccount)"
            @unselect="onPaymentMethodUnselect(PaymentMethodType.bankAccount)"
          />
        </template>
        <template #title>
          <div
            class="mr-2.5 flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-eonx-neutral-600"
          >
            <base-icon svg="v2/custom/bank" :size="26" />
          </div>
          <div class="font-bold">Bank account</div>
        </template>
        <template #items="{ toggleOff }">
          <payment-method-split-item
            v-for="method in bankAccounts"
            :key="method.id"
            :flow-type="flowType"
            :method="method"
            to="modals-portal"
            @confirm="
              toggleOff()
              onPaymentMethodConfirm(PaymentMethodType.bankAccount)
            "
            @cancel="onPaymentMethodCancel(PaymentMethodType.bankAccount)"
          />
        </template>
      </payment-method-select-block>
    </template>
    <payments-pba />
  </div>
</template>
