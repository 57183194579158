<script>
import { defineComponent, nextTick } from 'vue'
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { useProvider } from '/~/composables/provider'
import ProfileEdit from './profile-edit-profile.vue'

export default defineComponent({
  name: 'profile-edit-profile-view-v2',
  components: {
    BaseAsidePage,
    ProfileEdit,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const router = useRouter()
    const { isProfileFormEditable } = useProvider()

    if (!isProfileFormEditable.value) {
      router.push({ hash: '' })
    }

    function onProfileSaved() {
      router.back()
    }

    return {
      onProfileSaved,
    }
  },
  methods: {
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
})
</script>

<template>
  <base-aside-page ref="baseAsidePage" title="Edit Profile" no-padding>
    <profile-edit v-bind="$attrs" @profile-saved="onProfileSaved" />
  </base-aside-page>
</template>
