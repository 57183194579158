<script>
import EwDetailsPlaceholder from './ew-details-placeholder.v2.vue'
import EwDetailsHeader from '../ew-details-header.vue'
import BaseBack from '/~/components/base/back/base-back.vue'

export default {
  name: 'ew-details-layout-v3',
  components: {
    EwDetailsPlaceholder,
    EwDetailsHeader,
    BaseBack,
  },
  props: {
    ready: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    back: {
      type: Object,
      default: null,
    },
    placeholderRatio: {
      type: [Boolean, Number],
      default: 16 / 10,
    },
  },
}
</script>

<template>
  <div class="mx-auto w-full sm:max-w-6xl sm:px-10">
    <div class="hidden h-20 items-center sm:flex">
      <base-back :to="back" />
    </div>

    <ew-details-header :title="title" :loading="loading" :back="back" />

    <ew-details-placeholder v-if="loading" :ratio="placeholderRatio" />

    <template v-else-if="ready">
      <div class="grid w-full grid-cols-1 gap-[30px] sm:grid-cols-2 sm:gap-10">
        <div class="w-full max-w-full">
          <slot name="gallery" />
        </div>
        <div>
          <slot name="summary" />
        </div>
        <div class="sm:col-span-2">
          <slot name="details" />
        </div>
      </div>
    </template>
  </div>
</template>
