<script>
import { defineComponent } from 'vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useProvider } from '/~/composables/provider'

export default defineComponent({
  name: 'default-drawer-avatar-v4',
  components: {
    BaseIcon,
    BaseAvatar,
  },
  props: {
    avatarUrl: {
      type: null,
      default: '',
    },
    action: {
      type: Function,
      default: null,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isAvatarEditable } = useProvider()

    return {
      isAvatarEditable,
    }
  },
  computed: {
    isAction() {
      return this.isAvatarEditable && !!this.action
    },
  },
  methods: {
    onClick() {
      if (this.isAction) {
        this.action()
      }
    },
  },
})
</script>

<template>
  <div
    :class="[
      'relative shrink-0 rounded-full',
      (large || action) && 'mx-auto block',
      isAction && 'cursor-pointer',
    ]"
    @click="onClick"
  >
    <base-avatar v-if="avatarUrl" class="h-full w-full" :src="avatarUrl" />
    <div
      v-else
      class="flex h-full w-full items-center justify-center rounded-full bg-eonx-neutral-200 text-eonx-neutral-800"
      :class="[large ? 'p-12' : 'p-[15px]']"
    >
      <base-icon
        class="[&>svg]:stroke-[1]"
        svg="heroicons/outline/user"
        size="auto"
      />
    </div>

    <button
      v-if="isAction"
      class="absolute right-0 flex h-14 w-14 items-center justify-center rounded-full border-4 border-white bg-primary text-white"
      aria-label="Edit avatar"
      :style="{
        top: '170px',
      }"
    >
      <base-icon svg="points-only/camera" :size="20" alt="edit" />
    </button>
  </div>
</template>
