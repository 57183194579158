<script>
import modal from '/~/core/mdl'
import GalleryEntry from './entry/gallery-entry.vue'

const sizeObject = {
  full: {
    size: 'full',
    normal: '300x200',
    retina: '600x400',
  },
  half: {
    size: 'half',
    normal: '160x143',
    retina: '320x286',
  },
  'two-third': {
    size: 'two-third',
    normal: '436x291',
    retina: '872x582',
  },
}

export default {
  name: 'rec-media-message-gallery',
  components: {
    GalleryEntry,
  },
  props: {
    attachments: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previewCount: 4,
    }
  },
  computed: {
    layout() {
      const count = this.attachments.previewAttachmentsCount

      if (count === 1) return 'view-single'
      else if (count === 2) return 'view-split'
      else if (count === 3) return 'view-three'
      return 'view-multi'
    },
    limitAttachments() {
      return this.attachments.previewAttachments.slice(0, this.previewCount)
    },
    hiddenAttachmentsCount() {
      const count = this.attachments.previewAttachmentsCount

      return count > this.previewCount ? count - this.previewCount : 0
    },
  },
  methods: {
    dimension() {
      if (this.layout === 'view-single') return sizeObject.full
      else if (this.layout === 'view-three') return sizeObject['two-third']
      return sizeObject.half
    },
    openGallery(index) {
      this.initialItemIndex = index
      modal.show('rec-media-gallery', {
        props: {
          sources: this.attachments.previewAttachments,
          initialItemIndex: this.initialItemIndex,
        },
      })
    },
  },
}
</script>

<template>
  <div
    class="grid w-full cursor-pointer gap-1.5"
    :class="{
      'grid-cols-1': layout === 'view-single',
      'grid-cols-2': layout !== 'view-single',
      'auto-rows-fr': layout === 'view-multi',
    }"
  >
    <gallery-entry
      v-for="(attachment, index) in limitAttachments"
      :key="attachment.id"
      :class="{
        'row-span-2': layout === 'view-three' && index === 0,
      }"
      :attachment="attachment"
      :hidden-attachments-count="index === 3 ? hiddenAttachmentsCount : 0"
      :dimension="dimension()"
      @click="openGallery(index)"
    />
  </div>
</template>
