<script>
import ui from '/~/core/ui'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-users-selected-desktop',
  components: {
    BaseAvatar,
    BaseIcon,
  },
  props: {
    selectedUsers: {
      type: Array,
      default: () => [],
    },
    selectedUser: {
      type: Object,
      default: null,
    },
    multi: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { myRecId } = useRecProfile()

    return {
      myRecId,
      ui,
    }
  },
}
</script>

<template>
  <div v-if="multi" class="flex w-full flex-wrap items-center">
    <span
      v-for="u in selectedUsers"
      :key="u.id"
      class="float-left m-[5px] flex max-w-full items-center justify-center truncate rounded-md bg-blue-200 py-1.5 px-2.5 font-medium text-blue-500"
    >
      <base-avatar size="xxs" :src="u.avatarUrl" :abbr="u.initials" />
      <span
        class="mx-2.5 max-w-full flex-initial truncate text-xs font-bold leading-none"
      >
        {{ u.fullName }}
      </span>
      <span
        v-if="removable && u.id !== myRecId"
        class="flex flex-auto cursor-pointer flex-row-reverse"
        @click="$emit('remove', u)"
      >
        <base-icon svg="rec/close" size="xs" />
      </span>
    </span>
  </div>
  <div v-else-if="selectedUser" class="flex w-full items-center">
    <div class="mr-[5px] h-10 w-10 shrink-0 xxs:mr-2.5 xxs:h-12 xxs:w-12">
      <base-avatar
        class="h-full w-full"
        :src="selectedUser.avatarUrl"
        :abbr="selectedUser.initials"
      />
    </div>
    <div class="truncate">
      <div class="truncate font-bold leading-tight">
        {{ selectedUser.fullName }}
      </div>
      <div class="flex flex-wrap truncate font-bold leading-tight">
        <ul class="text-sm">
          <li
            class="float-left mr-2.5 flex items-center truncate font-semibold text-eonx-neutral-600"
          >
            <div class="flex-1 truncate">
              {{ selectedUser.email }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <span
      v-if="removable && ui.desktop"
      class="absolute right-0 flex h-5 cursor-pointer flex-wrap content-center text-primary"
      @click="$emit('clear')"
    >
      Clear
      <base-icon
        class="my-auto ml-1.5"
        svg="rec/round/clear"
        color="primary"
        :size="16"
      />
    </span>
  </div>
</template>
