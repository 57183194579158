<script>
import modal from '/~/core/mdl'
import PostCreatorForm from '/~rec/components/post/creator/post-creator-form.vue'
import { RecroomEvent } from '/~rec/core/event'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'rec-post-creator-mobile-mdl',
  components: {
    PostCreatorForm,
    BaseMdl,
    HeaderMobile,
  },
  setup() {
    const { groups } = useGroups()

    return { groups }
  },
  data() {
    return {
      type: this.$attrs.type,
    }
  },
  computed: {
    source() {
      return this.$attrs.source || this.groups.defaultGroup
    },
    isEventSource() {
      return this.source instanceof RecroomEvent
    },
    sourceName() {
      if (!this.$attrs.source) return 'Newsfeed'
      if (this.source instanceof RecroomEvent) return 'Event'
      return 'Group'
    },
    title() {
      switch (this.type) {
        case 'poll':
          return `Poll to ${this.sourceName}`
        case 'quiz':
          return `Quiz to ${this.sourceName}`
        case 'nominate':
          return 'Nominate'
        case 'hi':
          return 'HI-5'
        default:
          return `Post to ${this.sourceName}`
      }
    },
  },
  methods: {
    navigate(name, options) {
      modal.show(name, options)
    },
    close() {
      modal.hide()
    },
    success() {
      this.close()
      this.$emit('created')
    },
  },
}
</script>

<template>
  <base-mdl
    transition="slide-right"
    :header-shadow="false"
    :padding="false"
    width="screen"
    fullscreen="mobile"
  >
    <template #header>
      <header-mobile :title="title" @back="close">
        <template #title>
          <div class="truncate">
            <div class="text-lg font-bold">
              {{ title }}
            </div>
            <div class="truncate text-xs font-normal sm:hidden">
              {{ source.name }}
            </div>
          </div>
        </template>
      </header-mobile>
    </template>
    <post-creator-form
      v-bind="$attrs"
      class="h-full"
      :source="source"
      :type="type"
      source-hidden
      @navigate="navigate"
      @success="success"
      @close="close"
    />
  </base-mdl>
</template>
