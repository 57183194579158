<script setup lang="ts">
import CellLoading from '/~/extensions/bank-file-upload/components/common/cell-loading.vue'
import { formatDollar } from '/~/utils/format/money'
import { formatBsb } from '/~/utils/format/string'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
import PayToRowStatus from '/~/templates/bill-payments/views/payments/payees/components/pay-to-row-status.vue'

export type Row = {
  accountName: string
  accountNumber: string
  bsb: string
  reference: string
  amount: string
  payee: {
    abn: string
  }
}

defineProps<{
  row: Row
  loading: boolean
}>()

const { formatBusinessNumber } = useLocalization()
</script>

<template>
  <div class="flex-col">
    <div class="flex min-h-16 w-full items-center text-sm">
      <div class="w-52 px-5">
        <cell-loading :loading="loading">
          {{ row.name }}
        </cell-loading>
      </div>
      <div class="w-52 px-5">
        <cell-loading :loading="loading">
          {{ row.accountName }}
        </cell-loading>
      </div>
      <div class="space-x-0.5 px-5">
        <cell-loading :loading="loading">
          {{ formatBsb(row.bsb) }},
        </cell-loading>
        <cell-loading :loading="loading">
          {{ row.accountNumber }}
        </cell-loading>
      </div>
      <div class="ml-auto w-40 whitespace-pre-wrap px-5">
        <cell-loading :loading="loading">
          {{ row.reference }}
        </cell-loading>
      </div>
      <div class="w-28 px-5">
        <cell-loading :loading="loading">
          {{ formatDollar(row.amount) }}
        </cell-loading>
      </div>
      <div class="w-40 px-5">
        <cell-loading :loading="loading">
          {{ formatBusinessNumber(row.payee.abn) }}
        </cell-loading>
      </div>
    </div>
    <pay-to-row-status
      v-if="row.statusPending && !loading"
      :payee="row.payee"
      class="-mb-px flex w-full items-center space-x-1 border-x-0 py-1 px-5 text-left"
    ></pay-to-row-status>
    <div
      v-if="!row.statusPending && row.isLongReference && !loading"
      class="-mb-px flex w-full items-center space-x-1 border-y border-blue-700 bg-blue-50 py-1 px-5 text-left text-sm text-blue-700"
    >
      <base-icon svg="heroicons/solid/information-circle" :size="16" />
      <span>
        Lodgement reference is longer than what is supported. Should you wish to
        proceed as it is, it will be trimmed to 9 characters.
      </span>
    </div>
  </div>
</template>
