<script>
import EwDetailsV1 from './ew-details.v1.vue'
import EwDetailsV2 from './ew-details.v2.vue'

export default {
  name: 'ew-details-layout',
  functional: true,
  render(h, ctx) {
    const { version } = ctx.props

    return h(version === 2 ? EwDetailsV2 : EwDetailsV1, ctx)
  },
}
</script>
