import { computed } from 'vue'
import cdn from '/~/utils/cdn'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

function isObject(item: unknown) {
  return typeof item === 'object' && !Array.isArray(item) && item !== null
}

export function useApp() {
  const { useMembershipForBrandContent, brandLogos, providerLogo } =
    useProvider()
  const { user } = useUser()

  const appLogo = computed(() => {
    const brand =
      (useMembershipForBrandContent.value && user.value.membershipName) ?? ''
    const logos = brandLogos.value

    if (brand && logos) {
      if (Array.isArray(logos) && logos.length > 0) {
        return cdn(logos[0]).url()
      }

      if (isObject(logos) && brand in logos) {
        return cdn(logos[brand]).url()
      }
    }

    return providerLogo.value ? cdn(providerLogo.value).url() : ''
  })

  return {
    appLogo,
  }
}
