<script>
import { ValidationProvider } from 'vee-validate'
import { nextTick } from 'vue'
import { useInput } from '/~/composables/base/use-input'

export default {
  name: 'base-currency-input',
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    isCentrally: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
    allowNullValue: {
      type: Boolean,
      default: false,
    },
    valueRange: {
      type: Object,
      default: null,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { validationProviderRef, validateOnBlur, validateOnInput } = useInput(
      props,
      emit
    )

    return {
      validationProviderRef,
      validateOnBlur,
      validateOnInput,
    }
  },
  data() {
    return {
      inputValue: !this.value && this.allowNullValue ? null : this.value,
      isFocused: false,
    }
  },
  computed: {
    bindings() {
      return { ...this.$attrs, 'value-range': this.valueRange }
    },
  },
  watch: {
    value(newVal) {
      this.inputValue = !newVal && this.allowNullValue ? null : +newVal
    },
  },
  methods: {
    blur() {
      this.$refs.input.$el.blur()
    },
    onBlur() {
      if (!this.inputValue) {
        this.inputValue = this.allowNullValue ? null : 0
      }

      this.isFocused = false
      this.validateOnBlur()
      nextTick(() => {
        this.$emit('blur', this.inputValue)
        this.$emit('input', this.inputValue)
      })
    },
    onInput() {
      if (!this.inputValue) {
        this.inputValue = this.allowNullValue ? null : 0
      }

      this.validateOnInput(this.inputValue)

      nextTick(() => {
        this.$emit('input', this.inputValue)
      })
    },
    async setCursorToEnd() {
      this.isFocused = true

      const el = this.$refs.input.$el

      el.focus()
      el.selectionStart = el.selectionEnd = el.value.length
    },
    onKeyUp(keyEvent) {
      this.$emit('keyup', keyEvent)
    },
  },
}
</script>

<template>
  <validation-provider
    v-bind="validation"
    ref="validationProviderRef"
    :detect-input="false"
    slim
  >
    <div class="relative">
      <div
        v-if="!isFocused"
        class="absolute top-0 left-0 h-full w-full cursor-text"
        @click="setCursorToEnd"
      />
      <currency-input
        ref="input"
        v-bind="bindings"
        v-model="inputValue"
        class="w-full"
        :class="{
          'text-center': isCentrally,
          [inputClass]: true,
        }"
        v-on="$listeners"
        @blur="onBlur"
        @input="onInput"
        @keyup="onKeyUp"
      />
    </div>
  </validation-provider>
</template>
