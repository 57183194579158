export default class HealthExercise {
  constructor(raw = {}) {
    this.raw = raw
  }

  get id() {
    return this.raw.objectID
  }

  get title() {
    return this.raw['Video Name']
  }

  get description() {
    return this.raw['Video Description']
  }

  get image() {
    return this.raw['Thumbnail Url']
  }

  get video() {
    return this.raw['Embed Url']
  }

  get duration() {
    return this.raw.duration || 31
  }

  get views() {
    return this.raw.views || 213
  }

  get categories() {
    return this.raw.Collections.split(', ') || []
  }

  get instructor() {
    return this.raw.Instructor
  }
}
