<script>
export default {
  name: 'rec-audio-duration',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    formattedTime(value) {
      if (!value) return '0:00'
      const minutes = parseInt(value / 60)
      const seconds = parseInt(value % 60)
      const formattedSeconds = seconds < 10 ? '0' + seconds : seconds

      return `${minutes}:${formattedSeconds}`
    },
  },
}
</script>

<template>
  <span class="truncate text-xs opacity-75">
    {{ `${formattedTime(progress)} / ${formattedTime(duration)}` }}
  </span>
</template>
