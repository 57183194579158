<script>
import modal from '/~/core/mdl'
import BaseFab from '/~/components/base/fab/base-fab.vue'
import BaseButtonRound from '/~/components/base/button-round/base-button-round.vue'
import { useChat } from '/~rec/composables/chat'

export default {
  name: 'rec-chat-creator',
  components: {
    BaseFab,
    BaseButtonRound,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
  setup() {
    const { createRoom, openRoom, isNewChatProcessCreating } = useChat()

    return {
      createRoom,
      openRoom,
      isNewChatProcessCreating,
    }
  },
  methods: {
    onCreate() {
      modal.show('rec-users-manager', {
        props: {
          title: 'New Chat',
          fromGroup: true,
          selectable: true,
          multi: true,
          showAddList: true,
        },
        on: {
          'user-select': async (users) => {
            if (users.length > 1) {
              modal.show('rec-chat-settings', {
                props: {
                  title: 'New Chat',
                  height: 'xs',
                  users,
                },
                on: {
                  save: async (newRoom) => {
                    modal.hideAll()
                    this.openRoom(newRoom.id)
                  },
                },
              })
            } else {
              modal.hide()
              this.isNewChatProcessCreating = true
              const newRoom = await this.createRoom({
                userIds: users.map((user) => user.id),
              })

              this.isNewChatProcessCreating = false
              this.openRoom(newRoom.id)
            }
          },
        },
      })
    },
  },
}
</script>

<template>
  <base-button-round
    v-if="type === 'button'"
    v-bind="$attrs"
    icon="plus"
    :loading="isNewChatProcessCreating"
    @click.stop="onCreate"
  />
  <base-fab
    v-else
    v-bind="$attrs"
    :loading="isNewChatProcessCreating"
    @click.native.stop="onCreate"
  />
</template>
