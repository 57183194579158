<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-form-element-label',
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 18,
    },
    label: {
      type: String,
      default: 'Label',
    },
  },
}
</script>

<template>
  <div
    class="flex w-full content-center items-center font-bold text-eonx-neutral-800"
  >
    <base-icon v-if="!!icon.length" :svg="icon" :size="size" class="mr-2.5" />
    <span>
      {{ label }}
    </span>
  </div>
</template>
