<script>
import { nextTick } from 'vue'
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import MembersList from './members/members-list.vue'
import MemberProfile from './members/member-profile.vue'
import AnnouncementsSection from '/~rec/components/directory/announcements-section.vue'
import EventsSection from '/~rec/components/directory/events-section.vue'
import debounce from 'lodash-es/debounce'
import CelebrationsSection from '/~rec/components/directory/celebrations-section.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-directory',
  components: {
    BaseInput,
    BaseIcon,
    MembersList,
    MemberProfile,
    EventsSection,
    AnnouncementsSection,
    CelebrationsSection,
  },
  setup() {
    const { features } = useRecProfile()

    return {
      features,
      ui,
    }
  },
  data() {
    return {
      recProfileId: null,
      search: '',
      selectedMember: null,
    }
  },
  computed: {
    showProfileSearch() {
      return (
        this.features.directory &&
        (ui.desktop || this.$route.name === 'rec-directory')
      )
    },
  },
  watch: {
    '$route.query.recProfileId'() {
      const id = this.$route.query.recProfileId

      if (id) {
        this.recProfileId = id
      }
    },
  },
  created() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize: debounce(function () {
      if (
        ui.desktop &&
        (this.$route.name === 'rec-directory' ||
          this.$route.name === 'rec-announcements')
      ) {
        nextTick(() => {
          this.$router.push({ name: 'rec-feed-posts' })
        })
      }
    }, 200),
    onUserSelect(user) {
      if (ui.desktop) {
        this.selectedMember = user
      } else {
        modal.show('rec-directory-profile', {
          props: {
            user,
          },
        })
      }
    },
    closeProfile() {
      this.selectedMember = null
      this.recProfileId = null
      const query = Object.assign({}, this.$route.query)

      delete query.recProfileId

      this.$router.replace({ query })
    },
  },
}
</script>

<template>
  <div
    id="directoryRecRoot"
    class="mx-auto w-full max-w-2xl shrink-0 overflow-y-auto md:max-w-[376px]"
  >
    <transition>
      <member-profile
        v-if="selectedMember || recProfileId"
        :user="selectedMember"
        @back="closeProfile"
      />
      <div v-else class="flex h-full flex-col">
        <div v-if="showProfileSearch" class="px-5">
          <div class="mt-5 flex font-bold text-eonx-neutral-800">
            <base-icon svg="rec/directory" class="mr-2.5" :size="22" />
            <span>Directory</span>
          </div>

          <div class="py-5">
            <base-input
              v-model="search"
              look="recroom"
              icon="plain/search"
              placeholder="Search for anyone in the platform"
              name="rec-members-search"
              icon-plain
              nolabel
              clearable
            />
          </div>
        </div>

        <members-list
          v-if="search && $route.name !== 'rec-today'"
          :search="search"
          @select="onUserSelect"
        />
        <div
          v-else-if="ui.desktop || $route.name === 'rec-today'"
          class="space-y-[15px] bg-zinc-50 p-5 md:bg-transparent md:pb-16 md:pt-0"
        >
          <announcements-section v-if="features.announcements" />
          <celebrations-section v-if="features.celebrations" />
          <events-section v-if="features.events" />
        </div>
      </div>
    </transition>
  </div>
</template>
