<script setup lang="ts">
import { ValidationProvider } from 'vee-validate'
import Vue, { computed, ref, watch } from 'vue'
import { useIMask } from 'vue-imask'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useLocalization } from '/~/composables/localization'

export type ValidatedResponse = {
  abn: string
  entityName: string
}

const props = withDefaults(
  defineProps<{
    abn?: string
    error?: string
    entityName?: string
    allowClear?: boolean
    disabled?: boolean
  }>(),
  {
    allowClear: false,
    disabled: false,
  }
)

const error = ref('')

const emit = defineEmits<{
  (event: 'validated', response: ValidatedResponse): void
  (event: 'cleared'): void
}>()

const { formatBusinessNumber, getMask, translate } = useLocalization()
const businessNumberMask = computed(() => getMask('businessNumber'))

const validationProviderRef = ref<InstanceType<
  typeof ValidationProvider
> | null>(null)
const businessNumberValue = ref('')
const isLoading = ref(false)

const { el: businessNumberInput, unmasked: unmaskedBusinessNumber } = useIMask({
  // NOTE: not reactive
  mask: businessNumberMask.value.mask,
})

watch(
  () => props.abn,
  () => {
    businessNumberValue.value = ''
  }
)

async function validateBusinessNumber() {
  if (!unmaskedBusinessNumber.value) {
    throw new Error(
      `The ${translate('payment.businessNumber')} field is required`
    )
  }

  const validationResult = await validationProviderRef.value?.validate(
    unmaskedBusinessNumber.value
  )

  if (!validationResult?.valid) {
    throw new Error(validationResult?.errors[0])
  }

  return (window as any).BUSINESS_NUMBERS_DICT[unmaskedBusinessNumber.value]
    ?.data as {
    response: ValidatedResponse
    message: string
  }
}

function onInput() {
  if (
    unmaskedBusinessNumber.value.length ===
    businessNumberMask.value.unmaskedLength
  ) {
    businessNumberInput.value?.blur()
    onSubmit()
  }
}

async function onSubmit() {
  isLoading.value = true

  try {
    const { response } = await validateBusinessNumber()

    resetError()
    emit('validated', response)
  } catch (e: any) {
    error.value =
      e.message ||
      `The ${translate('payment.businessNumber')} not found or invalid.`
  }

  isLoading.value = false
}

function onClear() {
  if (!props.disabled) {
    businessNumberValue.value = ''
    emit('cleared')
  }
}

function resetError() {
  error.value = ''
}
</script>

<template>
  <div class="my-3 max-w-80 sm:min-w-20">
    <div v-if="abn && !error" class="text-sm">
      <div class="flex">
        <div
          class="flex h-8 grow items-center rounded border border-eonx-neutral-400 px-3"
          :class="{
            'rounded-r-none border-r-0': allowClear,
          }"
        >
          <span class="w-full max-w-[300px] truncate">
            {{ abn }}
          </span>
        </div>
        <div
          v-if="allowClear"
          class="flex w-14 items-center justify-center rounded rounded-l-none font-bold text-white"
          :class="{
            'bg-disabled': disabled,
            'cursor-pointer bg-primary hover:opacity-80': !disabled,
          }"
          @click="onClear"
        >
          Clear
        </div>
      </div>
      <div>
        {{ entityName }}
      </div>
    </div>
    <div v-else>
      <div
        class="flex overflow-hidden rounded border"
        :class="{
          'border-red-700': error,
          'border-transparent': !error,
        }"
      >
        <div
          class="relative grow rounded rounded-r-none border border-r-0 p-[5px] py-1.5 pl-[15px]"
          :class="[error ? 'border-transparent' : 'border-gray-400']"
        >
          <validation-provider
            ref="validationProviderRef"
            rules="businessNumber"
            name="abn"
            mode="passive"
            slim
          >
            <input
              ref="businessNumberInput"
              v-model="businessNumberValue"
              class="my-px"
              name="abn"
              type="text"
              :placeholder="businessNumberMask.placeholder"
              :disabled="isLoading"
              @input="onInput"
            />
          </validation-provider>
        </div>
        <div
          class="flex w-14 cursor-pointer items-center justify-center text-white transition-all hover:opacity-80"
          :class="{
            'bg-primary': !error,
            'bg-error-700': error,
          }"
          @click="onSubmit"
        >
          <base-loader
            v-if="isLoading"
            size="xs"
            color="white"
            border-color="rgba(255, 255, 255, 0.12)"
          />
          <base-icon v-else svg="heroicons/outline/paper-airplane" :size="24" />
        </div>
      </div>
      <div v-if="error" class="mt-[5px] text-xs text-red-700">
        {{ error }}
      </div>
    </div>
  </div>
</template>
