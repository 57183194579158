<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'ew-categories-modal',
  components: {
    BaseMdl,
  },
  props: {
    title: {
      type: String,
      default: 'Filter',
    },
    generateRouteTo: {
      type: Function,
      default: () => ({}),
    },
    checkActive: {
      type: Function,
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    renderLink: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
  },
  methods: {
    select(id) {
      this.onConfirm?.(id)
    },
  },
}
</script>

<template>
  <base-mdl width="md" :title="title" fullscreen="mobile" :padding="false">
    <ul>
      <li
        v-for="category in categories"
        :key="category.id"
        class="cursor-pointer"
        @click="select(category.id)"
      >
        <component
          :is="renderLink ? 'router-link' : 'span'"
          v-bind="renderLink ? { to: generateRouteTo(category) } : {}"
          class="flex h-10 w-full items-center px-5"
          :class="[
            checkActive(category)
              ? 'cursor-default bg-primary-lightest font-bold text-primary'
              : 'hover:bg-dark',
          ]"
        >
          {{ category.label }}
        </component>
      </li>
    </ul>
  </base-mdl>
</template>
