<script>
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { ref } from 'vue'
import BaseClampLine from '/~/components/base/clamp-line/base-clamp-line.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'

export default {
  name: 'retailer-details-default',
  components: {
    BaseLink,
    BasePlaceholder,
    BaseTabs,
    BaseTab,
    BaseClampLine,
  },
  props: {
    offer: {
      type: [Object, Array],
      default: null,
    },
    retailer: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tabsProps: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const descriptionExpanded = ref(true)
    const termsExpanded = ref(true)

    return {
      descriptionExpanded,
      termsExpanded,
    }
  },
  computed: {
    terms() {
      return get(this.offer, 'attributes.terms')
    },
    period() {
      return get(this.offer, 'attributes.validity_period')
    },
    description() {
      return get(this.offer, 'attributes.description')
    },
    retailerUrl() {
      return this.url || get(this.retailer, 'attributes.website', '')
    },
    website() {
      return this.retailerUrl
        .replace(/^https?:/, '')
        .replace(/^\/*/, '')
        .replace(/\/$/, '')
    },
    handleUrl() {
      if (!isEmpty(this.retailerUrl) || !isNil(this.retailerUrl)) {
        let newUrl = this.retailerUrl.trim()

        if (!/^(https?:)?\/\//i.test(newUrl)) {
          newUrl = '//' + newUrl
        }
        return newUrl
      }
      return ''
    },
  },
}
</script>

<template>
  <div v-if="offer" class="text-eonx-neutral-800">
    <base-tabs v-bind="tabsProps" class="-mx-5 sm:mx-0">
      <base-tab v-if="description" name="Details" selected class="mx-5 sm:mx-0">
        <div v-if="retailerUrl" class="mb-5">
          <base-link :href="handleUrl" class="font-bold">
            {{ website }}
          </base-link>
        </div>
        <base-clamp-line
          :rows="7"
          :is-expanded="descriptionExpanded"
          @expand="descriptionExpanded = $event"
        >
          <p class="leading-normal" v-html="description" />
        </base-clamp-line>
        <div class="mt-5 font-bold">
          <template v-if="period">
            Valid up to {{ period }} month(s) from purchase.
          </template>
          <template v-else>eGift Cards have no expiry date</template>
        </div>
      </base-tab>
      <base-tab v-if="terms" name="Terms" class="mx-5 sm:mx-0">
        <base-clamp-line
          :rows="7"
          :is-expanded="termsExpanded"
          @expand="termsExpanded = $event"
        >
          <p class="leading-normal" v-html="terms" />
        </base-clamp-line>
      </base-tab>
    </base-tabs>
  </div>
  <base-placeholder
    v-else-if="loading"
    theme="dark"
    :rows="4"
    type="text"
    class="mt-2.5"
  />
</template>
