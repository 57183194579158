<script>
import get from 'lodash-es/get'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'rec-token-activities-tile',
  components: {
    BaseIcon,
    BaseAvatar,
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { getMember } = useGroups()

    return { getMember }
  },
  computed: {
    amount() {
      const { transactionValue, valueSymbol } = this.transaction

      return `${valueSymbol} ${transactionValue}`
    },
    userTarget() {
      const { actionEntities } = this.transaction
      let userId = ''
      const { actionMessage } = this.transaction

      if (/Send a High Five/.test(actionMessage)) {
        userId = get(actionEntities, 'recipient')
      }
      if (/Receive a High Five/.test(actionMessage)) {
        userId = get(actionEntities, 'sender')
      }
      if (/Comment on a Post|Like a Post/.test(actionMessage)) {
        userId = get(actionEntities, 'author')
      }

      const user = this.getMember(userId, 'orgMemberId')

      return {
        abbrs: user?.initials,
        url: user?.avatarUrl,
      }
    },
    entityImage() {
      const perkImg = get(this.transaction, 'actionData.perk_image_url')
      const awardImg = get(this.transaction, 'actionData.award_image_url')

      return perkImg || awardImg
    },
    image() {
      const { userTarget } = this

      return {
        url: this.entityImage || userTarget.url,
        abbrs: userTarget.abbrs,
      }
    },
  },
}
</script>

<template>
  <div class="-my-2.5 flex items-center border-t px-6 py-5">
    <base-avatar
      class="mr-[15px]"
      size="lg"
      :abbr="image.abbrs"
      :src="image.url"
    />
    <div>
      <div class="text-sm font-bold">
        {{ transaction.description }}
      </div>
      <div class="text-sm text-eonx-neutral-600">
        {{ transaction.createdAt }}
      </div>
    </div>
    <div
      class="ml-auto flex items-center whitespace-pre font-bold text-amber-400"
    >
      <base-icon svg="rec/token" class="mr-2.5" :size="24" />
      <div
        :class="{
          'text-error-700': transaction.valueSymbol === '-',
          'text-success-700': transaction.valueSymbol !== '-',
        }"
      >
        {{ amount }}
      </div>
    </div>
  </div>
</template>
