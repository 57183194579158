import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'
import router from '/~/router'

const { zendeskSettings } = useProvider()
const { isIOS } = useUI()

class Zendesk {
  initialize() {
    return new Promise((resolve, reject) => {
      const key = zendeskSettings.value.key

      if (!key || key.length === 0) {
        console.warn('Please enter a Zendesk Web Widget Key')
        return
      }

      const firstScript = document.getElementsByTagName('script')[0]
      const script = document.createElement('script')

      script.async = !0
      script.type = 'text/javascript'
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`
      script.id = 'ze-snippet'
      script.onload = resolve
      script.onerror = reject

      firstScript.parentNode.insertBefore(script, firstScript)

      window.zESettings = {
        webWidget: {
          offset: {
            vertical: '0',
            mobile: {
              vertical: isIOS.value ? '70px' : '50px',
            },
          },
        },
      }
    })
  }

  show() {
    window.zE('webWidget', 'show')
  }

  hide() {
    window.zE('webWidget', 'hide')
  }
}

if (zendeskSettings.value.enabled) {
  const zendesk = new Zendesk()

  zendesk
    .initialize()
    .then(() => {
      if (router.currentRoute.name !== 'home') {
        zendesk.hide()
      }

      router.beforeEach((to, from, next) => {
        next()
        if (to.name === 'home' && !to.hash) {
          zendesk.show()
        } else {
          zendesk.hide()
        }
      })
    })
    .catch(() => {
      console.warn('error while loading Zendesk plugin')
    })
}
