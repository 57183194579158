<script>
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import RenderBanner from '/~rec/components/render/render-banner.vue'

export default {
  name: 'recroom-group-header',
  components: {
    BaseBack,
    BaseIcon,
    RenderBanner,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    miniHeader: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    },
    back() {
      this.$router.push({
        name: 'rec-groups',
      })
    },
  },
}
</script>

<template>
  <div class="px-[30px]">
    <div v-if="!miniHeader">
      <base-back :to="{ name: 'rec-groups' }" class="mb-4">Back</base-back>
    </div>
    <div
      class="flex items-center justify-between"
      :class="{
        'pt-[30px]': miniHeader,
      }"
    >
      <div class="flex min-w-0 items-center justify-start">
        <div v-if="miniHeader">
          <base-back :to="{ name: 'rec-groups' }" class="mr-4">
            <span />
          </base-back>
        </div>
        <div class="transition-all duration-300 ease-in-out">
          <render-banner
            :image="group.image"
            class="shrink-0 cursor-pointer"
            :class="{
              'h-10 w-10': miniHeader,
              'h-12 w-12': !miniHeader,
            }"
            @click="toggle"
          />
        </div>
        <h1
          class="cursor-pointer truncate pl-2.5 text-2xl leading-tight text-eonx-neutral-800"
          data-test="rec-group-title"
          @click="toggle"
        >
          {{ group.name }}
        </h1>

        <div class="ml-2.5 text-eonx-neutral-800">
          <base-icon v-if="group.isPrivate" svg="rec/lock-solid" size="sme" />
          <base-icon v-else svg="rec/planet" size="sme" />
        </div>
      </div>
      <div class="flex items-center justify-end pl-5">
        <div @click="toggle">
          <base-icon class="cursor-pointer" svg="rec/info-blue" size="lg" />
        </div>
      </div>
    </div>
  </div>
</template>
