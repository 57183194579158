<script setup lang="ts">
import { useCms } from '/~/composables/cms'

const { cmsHeaderWidgets } = useCms()
</script>

<template>
  <header class="bg-light">
    <portal-target name="app-header" slim />
    <div
      class="relative flex h-[88px] items-center justify-between px-[15px] sm:px-6"
    >
      <widgets-builder
        v-if="cmsHeaderWidgets"
        :widgets="cmsHeaderWidgets"
        class="flex items-center gap-x-5 !space-y-0 !pb-0"
        side="left"
      />
      <widgets-builder
        v-if="cmsHeaderWidgets"
        :widgets="cmsHeaderWidgets"
        class="flex items-center gap-x-5 !space-y-0 !pb-0"
        side="center"
      />
      <widgets-builder
        v-if="cmsHeaderWidgets"
        :widgets="cmsHeaderWidgets"
        class="flex items-center gap-x-5 !space-y-0 !pb-0"
        side="right"
      />
    </div>
    <portal-target name="header-bottom" slim />
  </header>
</template>
