<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'
import { useUI } from '/~/composables/ui'

export default {
  name: 'base-aside-page-v1',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    darkHeader: {
      type: Boolean,
      default: false,
    },
    back: {
      type: [Object, Boolean, Function],
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    action: {
      type: [Function, Boolean],
      default: null,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isStandalone } = useUI()

    const { isDarkThemeForEwallet } = useCms()

    const isDarkHeader = props.darkHeader ?? isDarkThemeForEwallet.value

    return {
      isDarkHeader,
      isDarkThemeForEwallet,
      isStandalone,
    }
  },
  computed: {
    iconHeader() {
      return Boolean(this.$slots.iconHeader)
    },
    isBackDisabled() {
      return typeof this.back === 'boolean' && !this.back
    },
    addButtonLabel() {
      return `${this.title} add`
    },
  },
  methods: {
    onBackClick() {
      const { back, $router, $route } = this

      if (typeof back === 'function') {
        back()
      } else if (back?.path || back?.hash || back?.name) {
        $router.replace(back)
      } else if ($route.meta.back) {
        $router.replace($route.meta.back)
      } else {
        $router.back()
      }
    },
  },
}
</script>

<template>
  <div
    v-analytics:keyup.esc="{
      cta: 'Back',
      pageGroup: 'Drawer',
      page: title,
    }"
    class="flex h-full w-full grow flex-col overflow-hidden bg-white"
    :class="{
      'footer-extra': isStandalone,
    }"
    tabindex="-1"
    @keyup.esc="onBackClick"
  >
    <template v-if="iconHeader">
      <slot name="iconHeader" />
    </template>
    <div
      v-else
      class="relative py-2"
      :class="{
        'bg-white text-eonx-neutral-800': !isDarkHeader,
        'bg-gray-900 text-white': isDarkHeader,
      }"
    >
      <div class="flex h-16 items-center px-[15px]">
        <div class="mr-5 flex w-[30px] flex-shrink-0 items-center">
          <base-button
            v-if="!isBackDisabled"
            ref="backButton"
            v-analytics:click="{
              cta: 'Back',
              pageGroup: 'Drawer',
              page: title,
            }"
            data-testid="back-button"
            icon="base/chevron"
            :size="24"
            :padding="2"
            :title="title"
            alt="back"
            @click="onBackClick"
          />
        </div>
        <div class="flex grow flex-col overflow-hidden text-center">
          <h2 class="cursor-default truncate text-xl font-bold">
            {{ title }}
          </h2>
          <span class="truncate text-sm leading-tight">
            {{ subtitle }}
          </span>
        </div>
        <div class="ml-5 flex w-[30px] shrink-0 items-center justify-center">
          <slot v-if="action || $slots.action" name="action">
            <div
              tabindex="0"
              role="button"
              class="flex cursor-pointer items-center justify-center rounded-full bg-white text-primary"
              :aria-label="addButtonLabel"
              @click="action"
              @keyup.enter="action"
              @keyup.space="action"
            >
              <base-icon svg="v2/custom/plus-circle" alt="add" :size="32" />
            </div>
          </slot>
        </div>
      </div>
      <slot name="header" />
    </div>

    <div
      ref="defaultSlot"
      class="flex grow flex-col overflow-y-auto"
      :class="{
        'p-5': !noPadding,
        'bg-white': !isDarkThemeForEwallet,
        'bg-gray-900 text-white': isDarkThemeForEwallet,
        [bodyClass]: true,
      }"
    >
      <slot />
    </div>
    <div v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>
