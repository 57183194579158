<script setup lang="ts">
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useMarketplaceMenu } from '/~/layouts/main/components/use-marketplace-menu'

const { hideMenu, menu, activeItem } = useMarketplaceMenu()
</script>

<template>
  <div
    v-if="!hideMenu"
    class="flex shrink-0 snap-x overflow-x-auto py-2.5 sm:pt-[5px]"
  >
    <div
      class="flex shrink-0 space-x-2.5 px-2.5 text-eonx-neutral-800 sm:px-5 sm:pl-0"
    >
      <base-action
        v-for="item in menu"
        :key="item.id"
        v-bind="item.action"
        color=""
        class="flex h-10 snap-center items-center rounded-md px-2.5"
        :class="{
          'bg-gray-200 text-black': activeItem && activeItem.id !== item.id,
        }"
        active-class="text-white bg-primary"
      >
        <base-icon
          v-if="item.icon"
          :size="24"
          color="none"
          class="mr-[5px]"
          :svg="item.icon"
        />
        <div class="shrink-0 text-sm font-bold">
          {{ item.label }}
        </div>
      </base-action>
    </div>
  </div>
</template>
