<script>
import { ref } from 'vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import HtmlContent from '/~/components/content/html-content.vue'

export default {
  name: 'green-box',
  components: {
    BaseIcon,
    HtmlContent,
    BaseCollapse,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const extended = ref(false)

    return {
      extended,
    }
  },
}
</script>

<template>
  <div>
    <div
      class="flex flex-col justify-between overflow-hidden xs:flex-row"
      :class="{
        'rounded-t-xl': item.points,
        'rounded-xl': !item.points,
      }"
      style="background: #97cfd0"
    >
      <div class="p-5 sm:p-12">
        <div class="mb-[30px] text-2xl font-semibold text-eonx-neutral-800">
          {{ item.title }}
        </div>
        <html-content
          class="text-base text-eonx-neutral-600"
          :content="item.text"
        />
      </div>
      <div class="flex items-center justify-center" :class="item.imageClass">
        <img :src="item.image" :alt="item.title" />
      </div>
    </div>

    <div v-if="item.points" style="background: #aad8d7">
      <base-collapse v-model="extended" :autoscroll="false">
        <template #trigger>
          <div
            class="flex cursor-pointer items-center px-5 py-[30px] hover:opacity-75 sm:px-10"
          >
            <base-icon
              :svg="extended ? 'australiapost/minus-circle' : 'plain/add'"
              size="md"
              class="mr-2.5 rotate-90 transform text-red-700 duration-300"
              :class="{ 'rotate-180': extended }"
            />
            <span class="text-xl font-semibold text-eonx-neutral-600">
              Key points
            </span>
          </div>
        </template>
        <div
          class="px-5 pb-[30px] text-base text-eonx-neutral-600 sm:px-10"
          v-html="item.points"
        />
      </base-collapse>
    </div>
  </div>
</template>
