<script>
import { computed } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import DrawerActivityDetailsItem from '../drawer-activity-details-item.vue'

export default {
  name: 'drawer-activity-detail-cash',
  components: {
    DrawerActivityDetailsItem,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const status = computed(() => {
      return props.content.metadata?.status || ''
    })

    const activityItems = computed(() => {
      let value = ''
      let state = 'warning'

      if (status.value === 'paid') {
        value = 'Paid'
        state = 'success'
      } else if (status.value === 'canceled') {
        value = 'Canceled'
        state = 'error'
      } else if (status.value === 'pending') {
        value = 'Pending'
      } else {
        value = 'Order Pending'
      }

      return [
        {
          title: 'Transaction No.',
          value: props.content?.id,
        },
        {
          title: 'Date',
          value: formatDate('daymonthyear', props.content?.created_at),
        },
        {
          title: 'Status',
          value,
          state,
        },
      ]
    })

    return {
      activityItems,
      status,
      formatDollar,
    }
  },
}
</script>

<template>
  <div>
    <drawer-activity-details-item :items="activityItems" />
    <div class="my-5 space-y-2.5">
      <div class="flex justify-between font-bold">
        <span>Total</span>
        <span>
          {{ formatDollar(content.total) }}
        </span>
      </div>
      <div class="flex justify-between">
        <span>Cashback</span>
        <span>
          {{ formatDollar(content.cashback) }}
        </span>
      </div>
    </div>
  </div>
</template>
