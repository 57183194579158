import { RewardsExtension } from '/~/core/extension'
import { useCheckoutRoutes } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'

const { generateCheckoutRoutes } = useCheckoutRoutes()

class PayrollExtension extends RewardsExtension {
  setRoutes() {
    return [
      generateCheckoutRoutes({
        type: FlowType.batch,
        subFlowType: 'payroll',
        rootPath: 'payments/payroll/pay',
        meta: {
          back: {
            name: 'bank-file-upload',
          },
        },
      }),
      {
        path: 'payroll',
        component: () => import('/~/extensions/payroll/root.vue'),
        meta: {
          parentRoute: 'payments',
          flowType: FlowType.batch,
        },
        children: [
          {
            path: '',
            name: 'payroll-upload',
            component: () =>
              import('/~/extensions/bank-file-upload/views/upload.vue'),
            meta: {
              title: 'Pay your payroll',
              flowType: FlowType.batch,
            },
          },
        ],
      },
    ].filter(Boolean)
  }
}

export default PayrollExtension
