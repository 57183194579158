<script setup lang="ts">
import modal from '/~/core/mdl'
import {
  type ServiceItem,
  useServices,
} from '/~/extensions/services/composables/use-services'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'

const { services, selectedService, selectedServiceValue, selectService } =
  useServices()

function onSelectServiceMobile() {
  modal.show('services-list')
}

function onSelectService(service: ServiceItem) {
  if (service) {
    selectService(service)
    modal.show('service-form')
  }
}
</script>

<template>
  <div>
    <div class="sm:hidden">
      <div
        class="base-field base-field--border base-select base-select__icon-divided pt-0"
      >
        <div class="base-field__entry" @click="onSelectServiceMobile">
          <span
            class="base-select__input base-select__input--fake flex items-center"
          >
            {{ selectedService ? selectedService.text : 'Select a service' }}
          </span>
          <span class="base-field__icon base-field__icon--right">
            <base-icon
              class="base-select__arrow"
              size="sm"
              svg="plain/arrow-filled-down"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="mx-auto mt-5 hidden w-xs sm:block">
      <base-select
        :value="selectedServiceValue"
        :options="services"
        default-option="Select a service"
        fullwidth-popup
        placeholder="Select a service"
        @input="onSelectService"
      />
    </div>
  </div>
</template>
