import api from '/~rec/core/api'
import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomPost } from './post'
import { RecroomUser } from '/~rec/core/user'
import { createDate } from '/~/utils/format/date'

export class RecroomPostQuiz extends RecroomPost {
  get hasSubmission() {
    return !!this.raw.submission
  }

  get submission() {
    return this.hasSubmission
      ? new RecroomPostQuizSubmission(this.raw.submission)
      : null
  }

  get countOfQuestions() {
    return this.questions.length
  }

  get totalAnswered() {
    return this.raw.total_answered
  }

  get endsAt() {
    if (!this.raw.ends_at) return null
    return this.raw.ends_at === Object(this.raw.ends_at)
      ? createDate(this.raw.ends_at.date)
      : createDate(this.raw.ends_at)
  }

  get startsAt() {
    if (!this.raw.starts_at) return null
    return this.raw.starts_at === Object(this.raw.starts_at)
      ? createDate(this.raw.starts_at.date)
      : createDate(this.raw.starts_at)
  }

  get isExpired() {
    return this.endsAt && this.endsAt.isBefore(createDate().startOf('day'))
  }

  async answer(questionId, optionId) {
    const res = await api.post(
      `/posts/${this.id}/questions/${questionId}/options/${optionId}`
    )

    this.raw.submission = res.data
  }

  updateQuestions() {
    this.questions = this.raw.questions
      .sort((a, b) => (a.question_order > b.question_order ? 1 : -1))
      .map((question) => {
        return new RecroomPostQuizQuestion(question)
      })

    this.submissions = new EntityProcessor({
      entity: `posts/${this.id}/submissions`,
      mapping: (record) => new RecroomPostQuizSubmission(record),
    })
  }

  init(rawData) {
    super.init(rawData)
    this.updateQuestions()
  }
}

export class RecroomPostQuizQuestion {
  constructor(raw) {
    this.raw = raw

    this.options = this.raw.options
      .sort((a, b) => (a.option_order > b.option_order ? 1 : -1))
      .map((option) => {
        return new RecroomPostQuizOption(option)
      })
  }

  get id() {
    return this.raw.id
  }

  get content() {
    return this.raw.content
  }
}

export class RecroomPostQuizOption {
  constructor(raw) {
    this.raw = raw
  }

  get id() {
    return this.raw.id
  }

  get content() {
    return this.raw.content
  }

  get correct() {
    return this.raw.correct
  }
}

export class RecroomPostQuizSubmission {
  constructor(raw) {
    this.raw = raw

    this.author = new RecroomUser(this.raw.author)
    this.answers = this.raw.answers.map((answer) => {
      return new RecroomPostQuizAnswer(answer)
    })
  }

  get updatedAt() {
    return this.raw.updated_at
  }

  get isPassed() {
    return this.raw.pass
  }

  get status() {
    return this.raw.status
  }

  get isStatusCompleted() {
    return this.status === 'completed'
  }

  get isStatusProgress() {
    return this.status === 'in_progress'
  }

  get totalCorrect() {
    return this.raw.total_correct
  }

  get totalAnswered() {
    return this.raw.total_answered
  }

  get totalQuestions() {
    return this.raw.total_questions
  }

  get totalEarn() {
    return this.raw.total_earned || 0
  }
}

export class RecroomPostQuizAnswer {
  constructor(raw) {
    this.raw = raw
  }

  get optionId() {
    return this.raw.option_id
  }

  get questionId() {
    return this.raw.question_id
  }
}
