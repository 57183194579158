import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomAward } from '/~rec/core/award'
import { RecroomMemberAward } from '/~rec/core/member-award'
import { reactive } from 'vue'

const restFilters = {
  recent: {
    'order[startsAt]': 'desc',
  },
  ending: {
    'order[endsAt]': 'desc',
  },
  popular: {
    'order[nominations]': 'desc',
  },
  valuable: {
    'order[prize]': 'desc',
  },
  asc: {
    'order[title]': 'asc',
  },
}

const receivedFilters = {
  recent: {
    'order[createdAt]': 'desc',
  },
  popular: {
    'order[nominations]': 'desc',
  },
  valuable: {
    'order[prize]': 'desc',
  },
  asc: {
    'order[title]': 'asc',
  },
}

const state = reactive({
  available: new EntityProcessor({
    entity: 'gamification/awards?status=active',
    mapping: (record) => new RecroomAward(record),
    filters: restFilters,
  }),
  competing: new EntityProcessor({
    entity: 'gamification/awards?status=active',
    mapping: (record) => new RecroomAward(record),
    filters: restFilters,
    perPage: 500,
  }),
  redeemed: new EntityProcessor({
    entity: 'gamification/member-awards',
    mapping: (record) => new RecroomMemberAward(record),
    filters: receivedFilters,
  }),
})

export const useAwards = () => ({
  awards: state,
})
