if (eonx.env === 'production' && /racq/.test(eonx.provider.subdomain)) {
  const isExist = !!document.getElementById('google-analytics')

  if (!isExist) {
    const firstScript = document.getElementsByTagName('script')[0]
    const script = document.createElement('script')

    script.async = !0
    script.type = 'text/javascript'
    script.src =
      '//assets.adobedtm.com/launch-ENfd2452521ad145c09f0d940db9809e35.min.js'
    script.id = 'google-analytics'

    firstScript.parentNode.insertBefore(script, firstScript)
  }
}
