<script>
import { computed } from 'vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import PayToBatchFile from './summary-pay-to-batch-file.vue'
import PayToCart from './summary-pay-to-cart.vue'
import PayToPayee from './summary-pay-to-payee.vue'
import PayToQuickBuy from './summary-pay-to-quick-buy.vue'
import PayToStatement from './summary-pay-to-statement.vue'

export default {
  name: 'summary-pay-to',
  functional: true,
  render(h, ctx) {
    const {
      isPaymentCurrentFlowType,
      isQuickBuyCurrentFlowType,
      isStatementCurrentFlowType,
      isBatchOrderCurrentFlowType,
    } = useCheckoutReactive()

    const component = computed(() => {
      if (isStatementCurrentFlowType.value) {
        return PayToStatement
      } else if (isPaymentCurrentFlowType.value) {
        return PayToPayee
      } else if (isBatchOrderCurrentFlowType.value) {
        return PayToBatchFile
      } else if (isQuickBuyCurrentFlowType.value) {
        return PayToQuickBuy
      }

      return PayToCart
    })

    return h(component.value, ctx.data, ctx.children)
  },
}
</script>
