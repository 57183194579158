<script setup lang="ts">
import cdn from '/~/utils/cdn'
import SeekerTile, { type Seeker } from './components/seeker-tile.vue'

const seekers: Array<Seeker> = [
  {
    date: 'Jul-24',
    name: 'Rojit Shrestha',
    position: 'Postal Manager<br/>Drummoyne Post<br/>NSW',
    image: 'https://cdn.eonx.net/30210f9d-c8d0-4e51-a97b-3187d07ac86a/',
  },
  {
    date: 'Jun-24',
    name: 'Boon Tan',
    position: 'Postal Services Officer<br/>Forest Hill<br/>VIC',
    image: 'https://cdn.eonx.net/2af1224a-ff59-4cf9-9dfd-2550ab69e737/',
  },
  {
    date: 'May-24',
    name: 'Kerrie Paine',
    position: 'Postal Manager<br/>Burleigh<br/>QLD',
    image: 'https://cdn.eonx.net/ab0951ce-458f-4bc1-a1aa-a1711e8c7149/',
  },
  {
    date: 'Apr-24',
    name: 'Voula Kotsiopoulos',
    position: 'Senior Postal Service Officer<br/>The Glen Post Office<br/>VIC',
    image: 'https://cdn.eonx.net/d3d9fdf6-7759-4b31-a75c-2896a98b8936/',
  },
  {
    date: 'Mar-24',
    name: 'Nancy Bezerra',
    position: 'Postal Service Officer<br/>Docklands Post Office<br/>VIC',
    image: 'https://cdn.eonx.net/871820d0-4972-43a4-b8c7-69a9ad74beb8/',
  },
  {
    date: 'Feb-24',
    name: 'Imelda Saunders',
    position: 'Postal Service Officer<br/>Richmond Post Office<br/>VIC',
    image: 'https://cdn.eonx.net/22c25f54-fc18-4072-ad04-5c35d63e553b/',
  },
  {
    date: 'Jan-24',
    name: 'Catherine McArthur',
    position: 'Postal Services Officer<br/>Burleigh<br/>QLD',
    image: 'https://cdn.eonx.net/8499274c-8aed-4a9f-9057-15c7ab5f9683/',
  },
  {
    date: 'Dec-23',
    name: 'Daniel Blythin',
    position: 'Senior Postal Services Officer<br/>Cleveland<br/>QLD',
    image: 'https://cdn.eonx.net/5b54e887-a07e-421b-a9ab-ef2b7169d71c/',
  },
  {
    date: 'Nov-23',
    name: 'Minnie Abad',
    position: 'Postal Services Officer<br/>The Glen Post Office',
    image: 'https://cdn.eonx.net/6e3ff62f-3e1d-4850-9bc7-fe844fce490c/',
  },
  {
    date: 'Oct-23',
    name: 'Maria Zongas',
    position: 'Postal Services Officer<br/>Mount Waverley PostShop<br/>VIC',
    image: 'https://cdn.eonx.net/21172549-c981-4435-89b9-9ae18649a2c5/',
  },
  {
    date: 'Sep-23',
    name: 'Eric Luk',
    image: 'https://cdn.eonx.net/22de3508-4e21-416e-9b93-35e38f45c716/',
    position: 'Postal Services Officer<br>Croydon Post Office',
  },
  {
    date: 'Aug-23',
    name: 'Paul Tapash',
    image: 'https://cdn.eonx.net/be3e5806-5e0a-4886-ac37-85bc1e112f9c/',
    position: 'Postal Services Officer<br>Kingswood<br>NSW',
  },
  {
    date: 'Jul-23',
    name: 'Gayika Kumaran',
    image: 'https://cdn.eonx.net/c8337ffc-cd07-45ac-a290-33d42af9bd37/',
    position: 'Postal Services Officer<br>Waverly Gardens<br>VIC',
  },
  {
    date: 'Jun-23',
    name: 'Anastasia Tam',
    image: 'https://cdn.eonx.net/871311cb-3590-4ec6-96e3-f1fbeab0f845/',
    position: 'Postal Services Officer<br>Ryde Post Shop<br>NSW',
  },
  {
    date: 'May-23',
    name: 'Jo-Ann Sy',
    image: 'https://cdn.eonx.net/4ae3810a-c898-4f5b-a499-cfe58d1cdc9d/',
    position: 'Postal Services Officer<br>Seven Hills Post Shop<br>NSW',
  },
  {
    date: 'Apr-23',
    name: 'Andrew Shilling',
    image: 'https://cdn.eonx.net/30214743-49f7-47e8-b5ce-7dacae0cec9b/',
    position: 'Postal Services Officer<br>Australia Fair Post Office<br> QLD',
  },
  {
    date: 'Mar-23',
    name: 'Yun Zheng',
    image: 'https://cdn.eonx.net/bb230cd4-4f1e-4661-84d1-6ac1101016d0/',
    position: 'Postal Manager<br>Mount Waverley Post Office',
  },
  {
    date: 'Feb-23',
    name: 'Katrina Combridge',
    image: 'https://cdn.eonx.net/623e32e3-2325-4c8e-990a-03a4958e3b49/',
    position: 'Relief PSO<br>Metro Southern<br>QLD',
  },
  {
    date: 'Jan-23',
    name: 'Hilla Zohar',
    image: 'https://cdn.eonx.net/21911231-9b36-498a-98be-437c21ff91d7/',
    position: 'Postal Officer<br>Runaway Bay<br>QLD',
  },
  {
    date: 'Dec-22',
    image: 'https://cdn.eonx.net/55c17b00-a9a7-4562-a510-c0ff585862d7/',
    name: 'Vandana Jangra',
    position: 'Postal Service Officer<br>Riverstone<br>NSW',
  },
  {
    date: 'Nov-22',
    image: 'https://cdn.eonx.net/629c11aa-f038-4c6f-8c85-8895a8216afe/',
    name: 'Amrita Kaur',
    position: 'Postal Service Officer<br>Collingwood<br>VIC',
  },
  {
    date: 'Oct-22',
    image: 'https://cdn.eonx.net/ecdaeba3-e633-4836-a5f8-a5dc56898b0a/',
    name: 'Linda Maatouk',
    position: 'Senior Postal Service Officer<br>Seven Hills Post Office<br>NSW',
  },
  {
    date: 'Sep-22',
    image: 'https://cdn.eonx.net/e7f4e5c2-acf4-42ad-ac47-68f032feb106/',
    name: 'Liang Zhu',
    position: 'Postal Service Officer<br>Silverwater Business Hub<br>NSW',
  },
  {
    date: 'Aug-22',
    image: 'https://cdn.eonx.net/cb845e61-2d15-45f3-b11f-810d46fb5cb8/',
    name: 'Hung-Pin Chen',
    position: 'Post Manager<br>NSW',
  },
  {
    date: 'Jul-22',
    image: 'https://cdn.eonx.net/e5dc2172-9fea-4667-9721-ffa1d8972d42/',
    name: 'Billie Johnson',
    position: 'Business Customer Care<br>QLD',
  },
  {
    date: 'Jun-22',
    image: 'https://cdn.eonx.net/f4a064aa-147f-4f06-bb0e-3b306c277977/',
    name: 'Goet Huam',
    position: 'Postal Service Officer<br>Springvale,<br>VIC',
  },
  {
    date: 'May-22',
    image: 'https://cdn.eonx.net/12d2f39e-3b7a-44b4-a8f5-adf9b5e89dec/',
    name: 'Dinesh Shrestha',
    position: 'Postal Service Officer<br>NSW',
  },
  {
    date: 'Apr-22',
    image: 'https://cdn.eonx.net/d8eab972-1dc0-4872-8f88-d2866cea668a/',
    name: 'Corrine Walsh',
    position: 'Business Consultant<br>Northern<br>QLD',
  },
  {
    date: 'Mar-22',
    image: 'https://cdn.eonx.net/d74eb337-85c5-4064-96b6-8df07d6647a7/',
    name: 'Lana Davison',
    position:
      'Digital Customer Care Consultant<br>Nth Consumer/Business Online Team 2',
  },
  {
    date: 'Feb-22',
    image: 'https://cdn.eonx.net/6f45def5-a47d-47ea-9256-a382c08c349b/',
    name: 'Clae Brooks',
    position: 'Customer Care Consultant<br>Customer Contact Centre,<br> QLD',
  },
  {
    date: 'Dec-21',
    image: 'https://cdn.eonx.net/04798420-a608-4d96-a130-1904e26fd34c/',
    name: 'Anurada Bhatia',
    position: 'Postal Manager<br>Wahroonga Post Shop,<br> NSW',
  },
  {
    date: 'Nov-21',
    image: 'https://cdn.eonx.net/2cefcbe3-2ccd-4915-b5d0-4211c353d300/',
    name: 'Anju Pubby',
    position:
      'Business Customer Care Consultant<br>Business Contact Centre, VIC',
  },
  {
    date: 'Oct-21',
    image: 'https://ucarecdn.com/50ca0893-080b-4924-b1fc-0c829d09e8b1/',
    name: 'Jonathan Gollagher',
    position:
      'Business Customer Care<br>Consultant, Business<br>Contact Centre, QLD',
  },
  {
    date: 'Sep-21',
    image: 'https://ucarecdn.com/19c6c64f-f053-4c74-b63e-6a3f2cda5afb/',
    name: 'Luvlin Cutler',
    position:
      'Business and Government<br> Customer Care Consultant<br> Business Contact Centre, VIC',
  },
  {
    date: 'Aug-21',
    image: 'https://ucarecdn.com/846420fd-9fc8-49af-af6c-df50c6caacb6/',
    name: 'Gaurangkumar Dalwadi',
    position:
      'Business Customer Care<br> Consultant, Business<br> Contact Centre, QLD',
  },
  {
    date: 'Jul-21',
    image: 'https://ucarecdn.com/28b89026-3837-4b17-9dcf-88311082c3a9/',
    name: 'Sai Sanath Kartham',
    position:
      'Customer Service Consultant<br> Consumer Business<br> Contact Centre, VIC',
  },

  {
    date: 'Jun-21',
    image: 'https://ucarecdn.com/c154f5c8-ca19-4a37-aa01-e6579f10b691/',
    name: 'Hung Pham',
    position:
      'Business Services Manager<br> Hoppers Crossing<br> Business Centre, VIC',
  },
  {
    date: 'May-21',
    image: 'https://ucarecdn.com/4dc4922e-7225-46cc-b381-1475f7f7b990/',
    name: 'Corinne Walsh',
    position:
      'Customer Service Consultant<br> Business Customer<br> Contact Centre, QLD',
  },
  {
    date: 'Apr-21',
    image: 'https://ucarecdn.com/6c59d0ad-c84c-4599-bec6-a1160fe4c12b/',
    name: 'Lanz Laurencio',
    position:
      'Customer Service Professional<br> Business Customer<br> Contact Centre, VIC',
  },
  {
    date: 'Mar-21',
    image: 'https://ucarecdn.com/7e2593d8-d929-4509-b9b4-a0e12e0436b6/',
    name: 'Mikhaila Garcia',
    position:
      'Customer Care Consultant<br> Business Customer<br> Contact Centre, QLD',
  },

  {
    date: 'Jan-21',
    image: 'https://ucarecdn.com/0372be40-9464-4bb3-87bb-8512b81cc12d/',
    name: 'Jason Billings',
    position: 'Consumer Customer<br> Contact Centre<br> VIC',
  },
  {
    date: 'Oct-20',
    image: 'https://ucarecdn.com/c2389213-674f-4bc0-9d5a-fb8e66b38a1f/',
    name: 'Brandon Myers',
    position: 'Business Customer<br> Contact Centre<br> QLD',
  },
  {
    date: 'Apr-20',
    image: 'https://ucarecdn.com/b3df49fa-7b79-4c8f-94e9-8893ec2e84b9/',
    name: 'Hung Pham',
    position: 'Postal Manager<br> Port Melbourne<br> Business Hub, VIC',
  },
  {
    date: 'Dec-19',
    image: 'https://ucarecdn.com/8f72d2ae-121c-4584-8c7f-5ff1dc8ea604/',
    name: 'Stephanie Murdoch',
    position: 'Customer Service Consultant<br> Melbourne<br> VIC',
  },

  {
    date: 'Nov-19',
    image: 'https://ucarecdn.com/07196fb5-4e39-4184-a8d9-dfc9ab8c05f1/',
    name: 'Marley Hall',
    position: 'Postal Manager, Alexandria<br> Business Services,<br> NSW',
  },
  {
    date: 'Sep-19',
    image: 'https://ucarecdn.com/f44cad7a-463e-48e3-b339-36abd2bdd21e/',
    name: 'Allan D’Souza',
    position:
      'Business Services Manager,<br> Osborne Park Business Hub,<br> WA',
  },
  {
    date: 'Aug-19',
    image: 'https://ucarecdn.com/a32cb5e2-168e-433b-91a2-5554e09da09a/',
    name: 'Gaurav Makhija',
    position:
      'Credit Risk Analyst, Finance and<br> Commercial Services,<br> VIC',
  },
  {
    date: 'Jul-19',
    image: 'https://ucarecdn.com/790c0728-159f-4f9f-a7b0-1c727613dbc7/',
    name: 'Kirsty Milner',
    position: 'Credit Officer, Finance and<br> Commercial Services<br> VIC',
  },

  {
    date: 'Jun-19',
    image: 'https://ucarecdn.com/206d7b30-c6f7-43c7-8fd7-e53949169140/',
    name: 'Elia Amaya',
    position: 'Customer Service Consultant<br> NCCC<br> Brisbane',
  },
  {
    date: 'Apr-19',
    image: 'https://ucarecdn.com/527086e5-b4e1-40a1-a416-ac5b22b8323b/',
    name: 'Linda Vredenbregt',
    position: 'Postal Manager<br> South Yarra<br> Post Office, VIC',
  },
  {
    date: 'Mar-19',
    image: 'https://ucarecdn.com/17f40633-ce05-4abe-83d3-57ea553431df/',
    name: 'Cassidy Upton',
    position: 'Postal Manager<br> Warnbro<br> Post Shop, WA',
  },
  {
    date: 'Feb-19',
    image: 'https://ucarecdn.com/729abafd-5ce5-4182-ba70-3c8e7ffbfac7/',
    name: 'Hung Pham',
    position: 'Postal Manager<br> Somerton<br> Business Hub, VIC',
  },

  {
    date: 'Jan-19',
    image: 'https://ucarecdn.com/b34c9c97-fc36-40c4-bb45-0953f695e987/',
    name: 'Justine Hallett',
    position: 'Business<br> Inbound<br> NCCC – Brisbane',
  },
  {
    date: 'Dec-18',
    image: 'https://ucarecdn.com/511882dc-9477-4959-b3d8-1960b7a8c320/',
    name: 'Jema Leivers',
    position: 'Business<br> Care Consultant<br> Southern CCC – Melbourne',
  },
  {
    date: 'Nov-18',
    image: 'https://ucarecdn.com/9443fde2-82ed-4095-9fe8-409a9e9b6664/',
    name: 'Penny Noutsos',
    position: 'Enterprise Inbound<br> 180 Lonsdale<br> Melbourne',
  },
  {
    date: 'Oct-18',
    image: 'https://ucarecdn.com/13d59f37-e66e-4603-9572-01807690dc6d/',
    name: 'Justine Hallett',
    position: 'Business<br> Care Consultant<br> North CCC',
  },

  {
    date: 'Sep-18',
    image: 'https://ucarecdn.com/b7fc1909-9b3e-435b-9b2f-21bcde3e7d89/',
    name: 'Jason Till',
    position: 'Business<br> Inbound Agent<br> North CCC',
  },
  {
    date: 'Aug-18',
    image: 'https://ucarecdn.com/6fb12a14-f703-498f-b2d1-5e48d393ac7d/',
    name: 'Malcolm Sequeira',
    position: 'Business Services Manager<br> Bibra Lake<br> Business Hub',
  },
  {
    date: 'May-18',
    image: 'https://ucarecdn.com/85b1e589-acef-4594-bc0a-1d5bf4fe96b8/',
    name: 'Talitha Singh Dhindsa',
    position: 'Part-time PSO<br> Morayfield<br> Post Office',
  },
  {
    date: 'Apr-18',
    image: 'https://ucarecdn.com/e51f860a-0ac6-4e1a-a29f-5d95d680795e/',
    name: 'Jason Till',
    position: 'Business<br> Solutions Specialist<br> Brisbane CCC',
  },

  {
    date: 'Mar-18',
    image: 'https://ucarecdn.com/e75e09bd-d6bc-49b0-9006-ec8ab63b0404/',
    name: 'Daniela Pilovski',
    position: 'Business Services Manager<br> Seven Hills<br> Business Hub',
  },
  {
    date: 'Feb-18',
    image: 'https://ucarecdn.com/a856aef1-f9d8-40a0-a108-333db5303a03/',
    name: 'Maggie Marriot',
    position: 'Postal Services Officer<br> Bayswater<br> Business Hub',
  },
  {
    date: 'Jan-18',
    image: 'https://ucarecdn.com/3c02fae4-3567-40ef-b02c-09f5b502824c/',
    name: 'Tracey Dundas',
    position: 'Postal Manager<br> Sunnybank<br> Post Shop',
  },
  {
    date: 'Dec-17',
    image: 'https://ucarecdn.com/f32352e2-f456-41f1-a607-30de280ac4f2/',
    name: 'April Deecke',
    position:
      'Customer Care Consultant<br> Northern Customer<br> Contact Centre',
  },
]
</script>

<template>
  <div class="bg-gray-50">
    <div class="mx-auto w-full max-w-6xl pt-[30px] text-sm sm:px-2.5">
      <div class="relative">
        <div class="absolute px-[30px]">
          <div
            class="mt-11 text-5xl font-semibold leading-12 text-white xs:mt-10 xs:text-3xl sm:mt-11 sm:text-4xl md:mt-20 md:text-5xl"
          >
            Star Seekers
          </div>
          <div
            class="mt-2.5 text-xl font-semibold text-white xs:mt-0 md:mt-2.5"
          >
            Always on the lookout for great
            <br />
            opportunities
          </div>
        </div>
        <img
          class="hidden xs:block"
          :src="
            cdn('https://ucarecdn.com/61e253d9-6159-487f-8341-be552e8abc49/')
              .crop('1392x362', '0,0')
              .preview('1200x400')
              .format('auto')
              .quality('smart')
              .url()
          "
        />
        <img
          class="w-full xs:hidden"
          :src="
            cdn('https://ucarecdn.com/54a76ee2-1d21-41d4-9aff-1015776d52c2/')
              .crop('414x381', '0,0')
              .preview('1200x400')
              .format('auto')
              .quality('smart')
              .url()
          "
        />
      </div>

      <div class="px-5 sm:px-11 md:px-0">
        <h1 class="mt-20 text-center text-3xl text-red-700">
          Who are our Seekers?
        </h1>
        <p class="mx-auto mt-[30px] text-2xl text-eonx-neutral-600">
          Our Star Seekers are shining examples of how to spot leads and win new
          business. They're always on the lookout for great opportunities.
        </p>
        <h1 class="mt-20 text-center text-3xl text-red-700">
          Our Star Seekers
        </h1>
        <p class="mx-auto mt-[30px] text-2xl text-eonx-neutral-600">
          These are the best Seekers in the business. They've shown special
          dedication to Seeker by submitting lots of quality leads, gathering
          good information about customers, and regularly winning opportunities.
          And they're rewarded for their efforts with Star Seeker goodies and a
          whopping 1,000 points. Let's hear it for our Star Seekers!
        </p>
      </div>

      <div
        class="mt-20 grid grid-cols-1 gap-5 px-2.5 text-center xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4"
      >
        <seeker-tile
          v-for="(seeker, index) in seekers"
          :key="index"
          :seeker="seeker"
        />
      </div>

      <div class="px-5 sm:px-11 md:px-0">
        <h1 class="mt-20 text-center text-3xl text-red-700">
          Reach for the Star Seekers
        </h1>
        <div class="mt-[30px] text-2xl text-eonx-neutral-600">
          <p>
            Think you've got what it takes to become a Star Seeker? Follow these
            top tips to help you sky rocket to Star Seeker status, and earn
            yourself 1,000 points.
          </p>
          <ul class="list-inside list-disc py-6">
            <li>Regularly submit quality leads</li>
            <li>Talk to customers to create new opportunities</li>
            <li>Gather as much information from customers as possible</li>
            <li>Keep an eye on your competitors</li>
            <li>Champion Seeker amongst your colleagues</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
