import values from 'lodash-es/values'
import Vue, { ref, computed } from 'vue'
import { useRetailer } from '/~/extensions/giftcards/composables'
import { useCart } from '/~/composables/cart'
import { useGiftRecipients } from '/~/composables/gift-recipients'
import { useUI } from '/~/composables/ui'

const { addCartItem } = useCart()
const { toggleCartMenu } = useUI()
const { addGiftRecipient } = useGiftRecipients()
const { getRetailer, getRetailerOffers } = useRetailer()

const gcCinemaMajorRetailer = ref(null)
const gcCinemaMajorOffer = ref(null)
const gcCinemaMajorProducts = ref(null)
const isGcCinemaMajorProcessing = ref(false)

const gcCinemaMajorSelectedProducts = computed(() => {
  return (gcCinemaMajorProducts.value ?? []).filter(
    (product) => product.count > 0
  )
})
const gcCinemaMajorTitle = computed(() => {
  return gcCinemaMajorRetailer.value?.attributes?.name
})
const gcCinemaMajorOfferTitle = computed(() => {
  return gcCinemaMajorOffer.value?.attributes?.name
})
const gcCinemaMajorTotal = computed(() => {
  return (
    gcCinemaMajorProducts.value?.reduce((acc, offer) => {
      return acc + offer.count * offer.price
    }, 0) ?? 0
  )
})
const isGcCinemaMajorDisabled = computed(() => {
  return (
    gcCinemaMajorProducts.value?.every((offer) => offer.count === 0) ?? false
  )
})

function setGcCinemaMajor({ retailer, offer, products }) {
  gcCinemaMajorRetailer.value = retailer
  gcCinemaMajorOffer.value = offer
  gcCinemaMajorProducts.value = products
}

function setGcCinemaMajorError() {
  // impelement this
}

function resetGcCinemaMajor() {
  gcCinemaMajorRetailer.value = null
  gcCinemaMajorOffer.value = null
  isGcCinemaMajorProcessing.value = false
}

function onBeforeAddGcCinemaMajorToCard() {
  isGcCinemaMajorProcessing.value = true
}

function onAfterAddGcCinemaMajorToCard() {
  gcCinemaMajorProducts.value?.forEach((product) => {
    product.count = 0
  })

  isGcCinemaMajorProcessing.value = false
}

async function initGcCinemaMajor(slug) {
  resetGcCinemaMajor()

  const [error, retailer] = await getRetailer(slug)

  if (error) {
    return setGcCinemaMajorError()
  }

  const offerId = retailer?.relationships?.offers?.data?.[0]?.id

  const offer = await getRetailerOffers({ slug, offer: offerId })

  const rawProducts = offer?.relationships?.products?.data ?? []
  let productsData = []

  if (rawProducts.length === undefined) {
    productsData.push(...values(rawProducts))
  } else {
    productsData = rawProducts
  }

  setGcCinemaMajor({
    retailer,
    offer,
    products: productsData
      .map((item) => {
        return {
          ...item.attributes,
          count: 0,
        }
      })
      .sort((a, b) => a.price - b.price),
  })
}

async function addGcCinemaMajorToCart({ giftRecipient }) {
  onBeforeAddGcCinemaMajorToCard()

  const giftPayload = {}

  if (giftRecipient) {
    const giftResponse = await addGiftRecipient(giftRecipient)

    giftPayload.message = giftResponse.message
    giftPayload.giftRecipientId = giftResponse.id
  }

  for (const product of gcCinemaMajorProducts.value) {
    if (product.count > 0) {
      const payload = {
        id: product.uuid,
        quantity: product.count,
        type: 'giftcard',
        ...giftPayload,
      }

      try {
        await addCartItem(payload)
      } catch (error) {
        Vue.notify({
          text: error?.data?.message ?? error,
          type: 'error',
          duration: 10000,
        })
      }
    }
  }

  toggleCartMenu()
  onAfterAddGcCinemaMajorToCard()
}

export function useGcCinemaMajor() {
  return {
    gcCinemaMajorRetailer,
    gcCinemaMajorOffer,
    gcCinemaMajorProducts,
    gcCinemaMajorSelectedProducts,
    isGcCinemaMajorProcessing,
    gcCinemaMajorTitle,
    gcCinemaMajorOfferTitle,
    gcCinemaMajorTotal,
    isGcCinemaMajorDisabled,
    setGcCinemaMajor,
    setGcCinemaMajorError,
    resetGcCinemaMajor,
    onBeforeAddGcCinemaMajorToCard,
    onAfterAddGcCinemaMajorToCard,
    initGcCinemaMajor,
    addGcCinemaMajorToCart,
  }
}
