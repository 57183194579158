<script>
import { pluralize } from '/~/utils/format/string'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import CashbackPanel from '/~/components/cashback/cashback-panel.vue'
import CheckoutSummaryItems from '/~/components/checkout/summary/checkout-summary-items.v2.vue'
import CheckoutSummaryPayWith from '/~/components/checkout/summary/checkout-summary-pay-with.vue'
import CheckoutSummaryTotal from '/~/components/checkout/summary/checkout-summary-total.v2.vue'
import PointsPanel from '/~/components/points/points-panel.vue'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import { usePoints } from '/~/composables/points'

export default {
  name: 'cart-checkout-summary',
  components: {
    BaseCollapse,
    CheckoutSummaryItems,
    CheckoutSummaryTotal,
    CheckoutSummaryPayWith,
    PointsPanel,
    CashbackPanel,
  },
  props: {
    notice: {
      type: String,
      default:
        '* Important notice: For added security, you may be asked to verify your card details by your bank once you proceed.',
    },
  },
  setup() {
    const { cartItemsFiltered, cart, cashbackRebateTotal, cartOrderAddresses } =
      useCart()
    const {
      payment,
      loadingFees,
      subTotalNoCoupons,
      total,
      pointsEarned,
      selectedCard,
      selectedPoints,
      selectedEwallet,
      selectedBankAccount,
      selectedCoupons,
      payWithCard,
      payWithPoints,
      payWithEwallet,
      payWithBankAccount,
      payWithCoupons,
    } = useCheckout()
    const { isPurchaseOrderPoints } = usePoints()

    return {
      payment,
      cartOrderAddresses,
      cartItemsFiltered,
      cart,
      loadingFees,
      subTotalNoCoupons,
      total,
      pointsEarned,
      cashbackRebateTotal,
      isPurchaseOrderPoints,
      selectedCard,
      selectedPoints,
      selectedEwallet,
      selectedBankAccount,
      selectedCoupons,
      payWithCard,
      payWithPoints,
      payWithEwallet,
      payWithBankAccount,
      payWithCoupons,
    }
  },
  computed: {
    itemsCount() {
      const count = this.cart.items.filter((v) => v.type !== 'coupon').length

      return `${count} ${pluralize(count, 'item')}`
    },
  },
}
</script>

<template>
  <div>
    <hr />
    <base-collapse
      show-icon
      :autoscroll="false"
      icon="v2/custom/chevron-down-outline"
      :icon-size="24"
    >
      <template #trigger>
        <span class="block py-5 font-bold">
          View Summary ({{ itemsCount }})
        </span>
      </template>

      <checkout-summary-items :items="cartItemsFiltered" class="pb-5" />
      <checkout-summary-pay-with
        :credit-card="{ details: selectedCard, total: payWithCard }"
        :points="{ details: selectedPoints, total: payWithPoints }"
        :ewallet="{ details: selectedEwallet, total: payWithEwallet }"
        :bank-account="{
          details: selectedBankAccount,
          total: payWithBankAccount,
        }"
        :coupons="{ items: selectedCoupons, total: payWithCoupons }"
        class="pb-5"
      />
      <checkout-summary-total
        :loading-fees="loadingFees"
        :transaction-fees="payment.transactionFees"
        :shipping-fees="payment.shippingFees"
        :amount="subTotalNoCoupons - payment.shippingFees"
        :total="total"
        class="pb-5"
      />

      <div>
        <points-panel v-if="isPurchaseOrderPoints" :total="pointsEarned" />
        <cashback-panel :total="cashbackRebateTotal" />
      </div>
    </base-collapse>

    <template v-if="notice">
      <hr />
      <div class="mt-5 mb-[15px] text-sm">
        {{ notice }}
      </div>
    </template>
  </div>
</template>
