import { ref, computed } from 'vue'
import { RecroomEarnConfig } from '/~rec/core/earn-config'

type EarnLimits = {
  [key: string]: number
}

type Config = {
  action: string
  earn_value: number
  id?: string
}

type EarnConfigMap = { [key: string]: RecroomEarnConfig }

const confirmedEarnConfigs = ref<EarnConfigMap>({})
const actionEarnLimits = ref<EarnConfigMap>({})

const form = ref({
  post_comment_create: 0,
  post_comment_like_create: 0,
  post_comment_like_create_by_author: 0,
  post_like_create: 0,
  post_poll_answer_create: 0,
  quiz_answer_create_correct: 0,
  quiz_answer_create_correct_all: 0,
})

function formInit(): void {
  Object.entries(form.value).forEach(([key]) => {
    form.value[key as keyof typeof form.value] =
      confirmedEarnConfigs.value[key]?.earnValue || 0
  })
}

const earnLimits = computed(() => {
  const result: EarnLimits = {}

  Object.entries(form.value).forEach(([actionName]) => {
    if (actionEarnLimits.value[actionName]) {
      result[actionName] = actionEarnLimits.value[actionName].maxEarnValue
    }
  })

  return result
})

const filteredConfigs = computed(() =>
  configs.value.filter((config) => config.id || config.earn_value > 0)
)

const configs = computed(() => {
  const result: Config[] = []

  Object.entries(form.value).forEach(([key, val]) => {
    const configObject =
      confirmedEarnConfigs.value && confirmedEarnConfigs.value[key]
    const config = {
      action: key,
      earn_value: val,
      ...(configObject && { id: configObject.id }),
    }

    result.push(config)
  })
  return result
})

export const useConfigForm = () => ({
  filteredConfigs,
  confirmedEarnConfigs,
  actionEarnLimits,
  earnLimits,
  form,
  formInit,
})
