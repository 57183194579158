<script>
import ui from '/~/core/ui'
import BaseAction from '/~/components/base/action/base-action.vue'
import { useWrapper } from '/~/configurator/composables/wrapper'
import WgtContent from '../wgt/wgt-content.vue'
import WgtImage from '../wgt/wgt-image.vue'
import WgtTile from '../wgt/wgt-tile.vue'
import WgtWrapper from '../wgt/wgt-wrapper.vue'

export default {
  name: 'widget-tile-image',
  components: {
    WgtWrapper,
    WgtTile,
    WgtContent,
    WgtImage,
    BaseAction,
  },
  props: {
    short: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    link: {
      type: Object,
      default: () => ({}),
    },
    bottomLink: {
      type: Object,
      default: () => ({}),
    },
    wrapper: {
      type: Object, // TODO: Wrapper from use-wrapper
      default: () => ({}),
    },
    image: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { reverse } = useWrapper(props)

    return {
      reverse,
      ui,
    }
  },
  computed: {
    overrideWrapper() {
      return {
        ...this.wrapper,
        row: {
          ...this.wrapper?.row,
          xs: 1,
          sm: this.short ? 3 : 2,
          md: this.short ? 3 : 2,
        },
      }
    },
    tileBindings() {
      return {
        wrapper: this.wrapper,
        row: {
          xs: 1,
          sm: 2,
          md: 3,
        },
      }
    },
    getImageHeight() {
      return this.short ? 300 : 400
    },
  },
}
</script>

<template>
  <wgt-wrapper :wrapper="overrideWrapper">
    <wgt-tile
      v-bind="tileBindings"
      class="h-full"
      :class="{ 'order-2': reverse }"
    >
      <wgt-content :content="content">
        <div v-if="link && link.enabled" class="mt-[30px] text-primary">
          <base-action v-bind="link" origin="banner" />
        </div>
        <div v-if="bottomLink && bottomLink.enabled" class="pt-5 text-primary">
          <base-action
            v-bind="bottomLink"
            class="text-inherit no-underline"
            origin="banner"
          />
        </div>
      </wgt-content>
    </wgt-tile>
    <wgt-tile
      v-bind="tileBindings"
      :class="{
        'h-80 min-h-full sm:col-span-2': short,
        'order-1': reverse,
      }"
    >
      <base-action
        v-if="link && link.enabled"
        v-bind="link"
        class="h-full"
        origin="banner"
        appearance="link"
      >
        <wgt-image :image="image" />
      </base-action>
      <wgt-image
        v-else-if="image"
        :image="image"
        :image-height="getImageHeight"
      />
    </wgt-tile>
  </wgt-wrapper>
</template>
