export default {
  name: 'Popup',
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    relElement: {
      type: HTMLElement,
      default: null,
    },
    popupOffset: {
      type: Object,
      default: () => ({ top: 0 }),
    },
  },
  data() {
    return {
      width: '',
      left: '',
      top: '',
    }
  },
  updated() {
    if (this.visible) {
      this.displayPopup()
    }
  },
  mounted() {
    document.body.appendChild(this.$el)
  },
  beforeDestroy() {
    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
  },
  methods: {
    getPopupElementSize(element, relativeElement) {
      const originalDisplay = element.style.display
      const originalVisibility = element.style.visibility

      element.style.display = 'block'
      element.style.visibility = 'hidden'
      const styles = window.getComputedStyle(element)

      let width

      if (!this.fullwidth) {
        width =
          element.offsetWidth +
          parseInt(styles.marginLeft, 10) +
          parseInt(styles.marginRight, 10)
      } else {
        width =
          relativeElement.offsetWidth +
          parseInt(styles.marginLeft, 10) +
          parseInt(styles.marginRight, 10)
      }

      const height =
        element.offsetHeight +
        parseInt(styles.marginTop, 10) +
        parseInt(styles.marginBottom, 10)

      element.style.display = originalDisplay
      element.style.visibility = originalVisibility
      return { width, height }
    },
    getRelativePosition(el, targetWidth, targetHeight) {
      let left = 0
      let top = 0
      let offsetX = 0
      let offsetY = 0
      const relativeRect = el.getBoundingClientRect()
      const dw = document.documentElement.clientWidth
      const dh = document.documentElement.clientHeight
      const footer =
        document.getElementById('footer') ??
        document.getElementById('bottom-bar')
      const footerHeight = footer?.getBoundingClientRect().height ?? 0

      offsetX = window.pageXOffset + relativeRect.left
      offsetY = window.pageYOffset + relativeRect.top

      if (
        dw - relativeRect.left < targetWidth &&
        relativeRect.right < targetWidth
      ) {
        left = offsetX - relativeRect.left + 1
      } else if (relativeRect.left + relativeRect.width / 2 <= dw / 2) {
        left = offsetX
      } else {
        left = offsetX + relativeRect.width - targetWidth
      }

      if (
        relativeRect.top <= targetHeight &&
        dh - relativeRect.bottom <= targetHeight
      ) {
        top = offsetY + dh - relativeRect.top - targetHeight
      } else if (relativeRect.top + relativeRect.height / 2 <= dh / 2) {
        top = offsetY + relativeRect.height
      } else if (
        offsetY + relativeRect.height + targetHeight + footerHeight <
        dh
      ) {
        top = offsetY + relativeRect.height
      } else {
        top = offsetY - targetHeight
      }
      return { left: `${left}px`, top: `${top + this.popupOffset.top}px` }
    },
    displayPopup() {
      if (!this.visible) return
      const popup = this.$refs.popup
      const relativeElement = this.relElement || this.$parent.$el

      const { width, height } = this.getPopupElementSize(popup, relativeElement)
      const { left, top } = this.getRelativePosition(
        relativeElement,
        width,
        height
      )

      this.width = `${width}px`
      this.left = left
      this.top = top
    },
  },
  render(h) {
    return (
      this.visible &&
      h('div', [
        h(
          'div',
          {
            ref: 'popup',
            style: {
              top: this.top,
              left: this.left,
              width: this.width,
              position: 'absolute',
              zIndex: 9990,
            },
          },
          this.$slots.default
        ),
        h('div', {
          style: {
            position: 'absolute',
            zIndex: 9980,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
          },
          on: {
            click: () => this.$emit('click'),
          },
        }),
      ])
    )
    // (<div>
    //   <div
    //     ref="popup"
    //     style={{ top: this.top, left: this.left, width: this.width, position: 'absolute', zIndex: 9990 }}
    //   >
    //     {this.$slots.default}
    //   </div>
    //   <div onClick={() => this.$emit('click')} style={{ position: 'absolute', zIndex: 9980, left: 0, top: 0, right: 0, bottom: 0 }}></div>
    // </div>)
  },
}
