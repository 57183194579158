<script setup type="ts">
import DrawerMenu from '/~/components/drawer/drawer-menu.vue'
import { useAddresses } from '/~/composables/addresses'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import { useDrawerMenu } from '/~/components/drawer/use-drawer-menu'
import { useUI } from '/~/composables/ui'
import { computed, ref } from 'vue'

const { getAddresses } = useAddresses()
const { isCartLoading, fetchCartOrderAddresses } = useCart()
const { resetMethods } = useCheckout()
const { cartMenuRoutes } = useDrawerMenu()
const { isCartMenuVisible, toggleCartMenu } = useUI()

const isFetchingInitialData = ref(false)

const isLoading = computed(() => isFetchingInitialData.value || isCartLoading.value)

async function fetchInitialData() {
  isFetchingInitialData.value = true

  resetMethods()

  await Promise.all([getAddresses(), fetchCartOrderAddresses()])

  isFetchingInitialData.value = false
}
</script>

<template>
  <drawer-menu
    :routes="cartMenuRoutes"
    prefix="cart"
    portal-target="cart-menu-modal"
    :is-visible="isCartMenuVisible"
    :is-loading="isLoading"
    @toggle="toggleCartMenu"
    @hook:created="fetchInitialData"
  />
</template>
