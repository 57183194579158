<script>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useEstore } from '/~/extensions/estore/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { transformQueriesForward } from '../helpers'

export default {
  name: 'estore-search',
  components: {
    BaseInput,
    BaseIcon,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { searchGroup } = useEstore()

    const searchString = computed({
      get() {
        return route.query.search || ''
      },
      set(value) {
        return router.replace({
          name: 'estore',
          ...transformQueriesForward(route, 'search', value),
          params: { ...route.params, preventTopScrolling: true },
        })
      },
    })

    return {
      searchString,
      searchGroup,
    }
  },
}
</script>

<template>
  <base-input
    v-model="searchString"
    class="my-0 grow p-0 pr-2.5"
    :placeholder="`Search ${searchGroup}`"
    :icon-plain="true"
    look="rounded"
    nolabel
    clearable
    autofocus
  >
    <template #icon>
      <div class="ml-[15px] mr-2.5 flex items-center justify-center">
        <base-icon
          svg="plain/search-v2"
          class="text-eonx-neutral-600"
          :size="19"
        />
      </div>
    </template>
  </base-input>
</template>
