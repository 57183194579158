import get from 'lodash-es/get'
import Vue from 'vue'
import { RequestOptions } from '/~/types/api'

// why do we have this module separated from the main core/api.ts file?
async function notify(response, type, options = {}) {
  let message = get(response, 'message', get(response, 'message'))

  if (response && /^5/.test(response.status)) {
    message =
      'We are having technical difficulties and are actively working on a fix. Please try again in few minutes.'
  }

  if (message && options.notify !== false) {
    Vue.notify({
      text: message,
      type,
      duration: 5000,
    })
  }
}
class ApiWorker {
  constructor(url = null) {
    this.baseURL = url || eonx.hosts.api.v1
    this.baseHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  getUrl(url) {
    if (/^(http|\/\/|\/\d)/.test(url)) {
      return url
    }
    if (this.baseURL) {
      return `${this.baseURL}/${url.replace(/^\//, '')}`
    }
  }

  async fetch<T>(url: string, options = {}): Promise<T> {
    const parsedUrl = this.getUrl(url)
    const response = await fetch(parsedUrl, {
      headers: this.baseHeaders,
      ...options,
    })

    return this.catchResponse(response, options)
  }

  async get<T>(url: string, options: RequestOptions = {}): Promise<T> {
    let newUrl = url

    if (options.params) {
      const query = Object.keys(options.params)
        .map((key) => `${key}=${options.params[key]}`)
        .join('&')

      newUrl = url.indexOf('?') !== -1 ? `${url}&${query}` : `${url}?${query}`
    }
    return this.fetch(newUrl, options)
  }

  async post<T>(url: string, data: unknown, options?: RequestOptions) {
    return this.fetch<T>(url, {
      method: 'POST',
      body: JSON.stringify(data),
      ...options,
    })
  }

  async put<T>(url: string, data?: unknown, options?: RequestOptions) {
    return this.fetch<T>(url, {
      ...options,
      method: 'PUT',
      body: JSON.stringify(data),
    })
  }

  async patch<T>(url: string, data: unknown, options: RequestOptions) {
    return this.fetch<T>(url, {
      ...options,
      method: 'PATCH',
      body: JSON.stringify(data),
    })
  }

  async delete<T>(url: string, options = {}) {
    return this.fetch<T>(url, {
      ...options,
      method: 'DELETE',
    })
  }

  async catchResponse(response: Response, options: RequestOptions = {}) {
    if (response.ok) {
      if (options.method === 'DELETE') return {}

      try {
        return await response.json().then((json) => {
          notify(json, 'success', options)
          return {
            data: json,
          }
        })
      } catch (error) {
        console.error(error)
      }
    } else {
      let parsedResponse
      const responseClone = response.clone()

      try {
        parsedResponse = await response?.json()
        notify(parsedResponse, 'error', options)
      } catch (jsonParseError) {
        try {
          parsedResponse = await responseClone.text()
        } catch (textParseError) {
          parsedResponse = response
        }
      }

      throw parsedResponse
    }
  }

  setBaseUrl(url: string) {
    this.baseURL = url
  }

  setBaseHeaders(headers) {
    this.baseHeaders = {
      ...this.baseHeaders,
      ...headers,
    }
  }

  getAuthorization() {
    return this.baseHeaders.authorization
  }
}

export default new ApiWorker()
