import { nanoid } from 'nanoid'

export default {
  label: 'Default',
  value: 'default',
  preset: {
    items: [
      {
        id: nanoid(),
        enabled: true,
        label: 'How it works',
        action: {
          type: 'action',
          value: 'show-faq',
        },
        'mobile-action': {
          type: 'action',
          value: 'show-faq-mobile',
        },
        icon: 'v2/box-icons/bx-info-circle',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Help',
        action: {
          type: 'page',
          value: 'help',
        },
        'mobile-action': {
          type: 'action',
          value: 'show-help-mobile',
        },
        icon: 'v2/box-icons/bx-envelope',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Privacy Policy',
        action: {
          type: 'action',
          value: 'show-privacy-modal',
        },
        'mobile-action': {
          type: 'action',
          value: 'show-privacy-policy-mobile',
        },
        icon: 'v2/heroic/shield-exclamation',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Terms & Conditions',
        action: {
          type: 'action',
          value: 'show-terms-modal',
        },
        'mobile-action': {
          type: 'action',
          value: 'show-toc-mobile',
        },
        icon: 'v2/heroic/document-text',
      },
      {
        id: nanoid(),
        enabled: true,
        divider: true,
        label: 'Divider',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Logout',
        action: {
          type: 'action',
          value: 'logout',
        },
        'mobile-action': {
          type: 'action',
          value: 'logout',
        },
        icon: 'v2/heroic/logout',
      },
    ],
  },
}
