export default class HealthCategory {
  constructor(raw = {}) {
    this.raw = raw
  }

  get id() {
    return this.raw.id
  }

  get text() {
    return this.raw.name
  }

  get value() {
    return this.id
  }
}
