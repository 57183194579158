<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import Hi5Available from '/~rec/views/recognition/hi5/hi5s-available.vue'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'hi5-available-root',
  components: {
    Hi5Available,
  },
  props: {
    hi5Id: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { getMember } = useGroups()

    return {
      getMember,
      ui,
    }
  },
  async created() {
    if (!ui.desktop) {
      this.openMobileForm()
    }
  },
  methods: {
    openMobileForm() {
      const userId = this.$route.query.sendTo

      if (userId) {
        const selectedUser = this.getMember(userId, 'orgMemberId')

        this.selectUser(selectedUser)
      } else {
        modal.show('rec-users-manager', {
          props: {
            type: 'hi',
            title: 'Send Hi-5 to',
            label: 'Hi-5 someone awesome!',
            selectable: true,
          },
          on: {
            'user-select': this.selectUser,
            back: () => {
              this.$router.push({
                name: 'rec-hi5',
              })
            },
          },
        })
      }
    },
    selectUser(user) {
      modal.show('rec-hi5-send-mobile', {
        props: {
          nominee: user,
          hi5Id: this.hi5Id,
        },
      })
    },
  },
}
</script>

<template>
  <hi5-available v-if="ui.desktop" :hi5-id="hi5Id" />
</template>
