<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import GroupForm from '/~rec/components/group/group-form.vue'
import BaseBack from '/~/components/base/back/base-back.vue'
import get from 'lodash/get'
import { RecroomGroup } from '/~rec/core/group'
import { useRecProfile } from '/~rec/composables/profile'
import { useBackendValidation } from '/~rec/composables/backend-validation'

export default {
  name: 'rec-group-form-root',
  components: {
    BaseBack,
    GroupForm,
  },
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { isMeLimitedUser } = useRecProfile()
    const { backendErrors, processBackendErrors } = useBackendValidation()

    return {
      isMeLimitedUser,
      backendErrors,
      processBackendErrors,
      ui,
    }
  },
  data() {
    return {
      form: {
        image: this.cdn('/img/banner1').url(),
        private: false,
        name: '',
        description: '',
        members: [],
      },
      processing: false,
    }
  },
  computed: {
    headerTitle() {
      return this.group ? 'Edit Group' : 'Create group'
    },
    backRoute() {
      return this.group ? { name: 'rec-group' } : { name: 'rec-groups' }
    },
  },
  watch: {
    group() {
      this.fillForm()
    },
  },
  created() {
    if (!ui.desktop) {
      modal.show('rec-group-form')
    } else if (this.group) {
      this.fillForm()
    }
  },
  methods: {
    fillForm() {
      const { group } = this
      const defaultImage = this.cdn('/img/banner1').url()

      this.form = {
        image: get(group, 'image', defaultImage),
        private: get(group, 'isPrivate', this.isMeLimitedUser),
        name: get(group, 'name', ''),
        description: get(group, 'description', ''),
        members: get(group, 'members.hits', []),
      }
    },
    async onSave() {
      const { form } = this
      const membersIds = form.members.map((user) => user.id)
      const data = {
        banner_url: form.image,
        private: form.private,
        name: form.name,
        description: form.description,
      }

      this.backendErrors = {}
      this.processing = true

      try {
        let group = this.group

        if (this.group) {
          await this.group.updateMembers(membersIds)
          group = await this.group.edit(data)
        } else {
          group = await RecroomGroup.create({
            ...data,
            members: membersIds,
          })
        }

        this.$emit('done', group)
        this.$router.replace({
          name: 'rec-group',
          params: { groupId: group.id },
        })
      } catch (error) {
        this.processBackendErrors(error, { data })
        this.$emit('error', error)
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<template>
  <div
    v-if="ui.desktop"
    class="mx-auto flex h-full w-full max-w-screen-xs grow flex-col p-[30px]"
  >
    <div>
      <base-back :to="backRoute" class="mb-6" />
    </div>
    <h2 class="text-eonx-neutral-800">
      {{ headerTitle }}
    </h2>

    <group-form
      ref="form"
      :form="form"
      :is-edit="!!group"
      :processing="processing"
      :backend-errors="backendErrors"
      :show-switch="!isMeLimitedUser"
      @image="form.image = $event"
      @private="form.private = $event"
      @name="form.name = $event"
      @description="form.description = $event"
      @members="form.members = $event"
      @save="onSave"
    />
  </div>
</template>
