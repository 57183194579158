<script>
export default {
  name: 'rec-events-month-empty',
}
</script>

<template>
  <div class="py-2.5">
    <div class="w-full rounded-lg bg-white p-5 text-center">
      <div class="flex justify-center py-2.5">
        <img src="/icons/rec/empty-month.svg" alt="" />
      </div>
      <div class="font-bold text-eonx-neutral-800">No events this month</div>
      <p class="mt-[5px] text-sm font-semibold leading-4 text-eonx-neutral-600">
        You can create or join events to share with others
      </p>
    </div>
  </div>
</template>
