import { computed } from 'vue'
import { RawLocation, Route, RouteConfig } from 'vue-router'
import { camelToKebab } from '/~/utils/format/string'
import { useCheckoutReactive } from '/~/composables/checkout'
import {
  FlowType,
  getFlowTypeKey,
} from '/~/composables/checkout/checkout-types'

type GetRouteNamePrefixOptions = {
  type?: `${FlowType}`
  subFlowType?: string
}

function getRouteNamePrefix(options: GetRouteNamePrefixOptions = {}) {
  const { type = FlowType.purchase, subFlowType } = options

  const typeKey = camelToKebab(getFlowTypeKey(type))

  return [typeKey, subFlowType].filter(Boolean).join('-')
}

const currentFlowRouteNamePrefix = computed(() => {
  const { currentFlowType, payment } = useCheckoutReactive()

  return getRouteNamePrefix({
    type: currentFlowType.value,
    subFlowType: payment.value.subFlowType,
  })
})

function getCurrentFlowRouteName(name: string) {
  return `${currentFlowRouteNamePrefix.value}-${name}`
}

type GenerateCheckoutRoutesOptions = {
  type?: FlowType
  subFlowType?: string
  rootPath?: string
  meta?: { back?: RawLocation }
  config?: Partial<RouteConfig>
}

function generateCheckoutRoutes(
  options: GenerateCheckoutRoutesOptions = {}
): RouteConfig {
  const {
    type = FlowType.purchase,
    subFlowType,
    rootPath = '/checkout',
    meta = {},
    config,
  } = options

  const namePrefix = getRouteNamePrefix({
    type,
    subFlowType,
  })

  return {
    ...{
      path: rootPath,
      name: namePrefix,
      meta: {
        title: 'Checkout',
        hideBottomBar: true,
        hideMobileHeader: true,
        toolbar: false,
        flowType: type,
        ...meta,
      },
      redirect: { name: `${namePrefix}-checkout` },
      component: () => import('/~/views/checkout.v2/root.vue'),
      children: [
        // *** ADDRESS ***
        {
          path: 'billing/:orderId?',
          name: `${namePrefix}-checkout-billing`,
          props: true,
          component: () =>
            import(
              '/~/views/checkout.v2/views/checkout/views/checkout-address.vue'
            ),
          meta: {
            title: 'Checkout Billing',
            hideMobileHeader: true,
            toolbar: false,
            type: 'billing',
            index: 1,
            flowType: type,
          },
        },
        // *** SHIPPING ***
        {
          path: 'shipping/:orderId?',
          name: `${namePrefix}-checkout-shipping`,
          props: true,
          component: () =>
            import(
              '/~/views/checkout.v2/views/checkout/views/checkout-address.vue'
            ),
          meta: {
            title: 'Checkout Shipping',
            hideMobileHeader: true,
            toolbar: false,
            type: 'shipping',
            index: 1,
            flowType: type,
          },
        },
        // *** POINTS ***
        {
          path: 'points/:orderId?',
          name: `${namePrefix}-checkout-points`,
          component: () =>
            import(
              '/~/views/checkout.v2/views/checkout/views/checkout-points.vue'
            ),
          meta: {
            title: 'Checkout Select Points To Redeem',
            hideMobileHeader: true,
            toolbar: false,
            index: 1,
            flowType: type,
          },
        },
        // *** METHOD ***
        {
          path: 'method/:orderId?',
          name: `${namePrefix}-checkout-method`,
          component: () =>
            import(
              '/~/views/checkout.v2/views/checkout/views/checkout-method.vue'
            ),
          meta: {
            title: 'Checkout Select Payment Methods',
            hideMobileHeader: true,
            toolbar: false,
            index: 1,
            flowType: type,
          },
        },
        /* {
          path: 'confirmation-v2/:orderId',
          name: `${namePrefix}-checkout-confirmation-v2`,
          component: () =>
            import(
              '/~/templates/bill-payments-v1_5/views/payments/make/successful/payment-successful.vue'
            ),
          meta: {
            title: 'Checkout Confirmation',
            hideMobileHeader: true,
            toolbar: false,
            home: 'home',
            index: 1,
            hideSummary: true,
            flowType: type,
          },
          props: (route: Route) => {
            return {
              orderId: route.params.orderId,
              flowType: type,
            }
          },
        }, */
        {
          path: 'complete-payid/:orderId?',
          name: `${namePrefix}-complete-payid`,
          props: true,
          component: () =>
            import(
              '/~/views/checkout.v2/views/checkout/views/checkout-complete-payid.vue'
            ),
          meta: {
            title: 'Complete payment with PayID',
            toolbar: false,
            index: 1,
            hideSummary: true,
            flowType: type,
          },
        },
        // *** CONFIRMATION ***
        {
          path: 'confirmation/:orderId',
          name: `${namePrefix}-checkout-confirmation`,
          component: () =>
            import(
              '/~/views/checkout.v2/views/checkout/views/checkout-confirmation.vue'
            ),
          meta: {
            title: 'Checkout Confirmation',
            hideMobileHeader: true,
            toolbar: false,
            home: 'home',
            index: 2,
            hideSummary: true,
            flowType: type,
          },
          props: (route: Route) => {
            return {
              orderId: route.params.orderId,
              flowType: type,
            }
          },
        },
        // NOTE: keep `orderId` routes at the end of the list so router will not confuse them with other routes
        {
          path: ':orderId?',
          name: `${namePrefix}-checkout`,
          component: () =>
            import('/~/views/checkout.v2/views/checkout/checkout.vue'),
          meta: {
            title: 'Checkout',
            hideMobileHeader: true,
            toolbar: false,
            index: 0,
            flowType: type,
            back: { name: 'home' },
            ...meta,
          },
          props: true,
        },
        // *** SUMMARY ***
        {
          path: ':orderId?/summary',
          name: `${namePrefix}-checkout-summary`,
          component: () =>
            import(
              '/~/views/checkout.v2/views/checkout/views/checkout-summary.mobile.vue'
            ),
          meta: {
            title: 'Summary',
            hideMobileHeader: true,
            toolbar: false,
            index: 0,
            hideSummary: true,
            flowType: type,
          },
          props: true,
        },
      ],
    },
    ...config,
  }
}

export function useCheckoutRoutes() {
  return {
    getCurrentFlowRouteName,
    generateCheckoutRoutes,
  }
}
