<script>
import DefaultEstoreTilePrice from '/~/extensions/estore/components/tile/estore-tile-price.vue'

export default {
  name: 'estore-catalog-tile-price',
  extends: DefaultEstoreTilePrice,
}
</script>

<template>
  <div v-if="currentPrice" class="px-[5px] py-5">
    <div class="text-sm text-eonx-neutral-600" aria-hidden="true">
      RRP {{ formatDollar(originalPrice) }}
    </div>
    <span class="font-bold text-eonx-neutral-800" data-cy="calculation-amount">
      {{ formatDollar(currentPrice) }}
    </span>
  </div>
</template>
