import { computed, reactive, ref } from 'vue'
import HealthExerciseProcessor from '/~/extensions/health/core/exercises-processor'
import Storage from '/~/utils/localStorage'
import { useUser } from '/~/composables/user'
const { user } = useUser()

const feeds = reactive({
  mind: new HealthExerciseProcessor({
    index: 'production_wellness_solutions_v2',
  }),
  move: new HealthExerciseProcessor({
    index: 'production_wellness_solutions_v2',
  }),
  silver: new HealthExerciseProcessor({
    index: 'production_wellness_solutions_v2',
  }),
  saved: new HealthExerciseProcessor({
    index: 'production_wellness_solutions_v2',
  }),
})
const selectedFeedId = ref('mind')
const saved = ref(Storage.get(`${user.value.externalId}/saved_exercises`) || [])
const initialized = computed(() => Object.keys(feeds) > 0)
const selectedFeed = ref(feeds[selectedFeedId.value])
const categoryTags = computed(() => selectedFeed.value.categoryTags || [])

function selectFeed(feedId) {
  selectedFeedId.value = feeds[feedId] ? feedId : 'mind'
}
function saveExercise(exerciseId) {
  const array = [...saved.value, exerciseId]

  Storage.set(`${user.value.externalId}/saved_exercises`, JSON.stringify(array))
  saved.value = array
}
function removeExercise(exerciseId) {
  const array = saved.value.filter((id) => id !== exerciseId)

  Storage.set(`${user.value.externalId}/saved_exercises`, JSON.stringify(array))
  saved.value = array
}

function isBookmarked(id) {
  return saved.value.includes(id)
}

function toggleBookmark(id) {
  if (isBookmarked(id)) {
    removeExercise(id)
  } else {
    saveExercise(id)
  }
}

export function useExercises() {
  return {
    selectedFeedId,
    selectedFeed,
    categoryTags,
    initialized,
    saved,

    selectFeed,
    isBookmarked,
    toggleBookmark,
  }
}
