<script>
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import BaseHeader from '/~/components/base/header/base-header'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'rec-poll-voter-list-modal',
  components: {
    RecognitionHeader,
    BaseHeader,
    BaseAvatar,
    BaseDate,
    BaseMdl,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <base-mdl fullscreen="mobile" width="xs">
    <template #header>
      <base-header type="closable" class="text-white">
        <recognition-header icon="rec/round/vote" class="text-default" />
      </base-header>
    </template>

    <div v-if="option.content" class="text-center">
      <h2 class="break-words leading-tight">
        <span>{{ option.content }}</span>
        <span class="text-eonx-neutral-600">({{ option.countOfAnswers }})</span>
      </h2>
    </div>
    <div class="mt-6">
      <div
        v-for="answer in option.answers"
        :key="answer.id"
        class="mt-[15px] flex items-center"
      >
        <base-avatar
          :abbr="answer.author.initials"
          :src="answer.author.avatarUrl"
          size="xl"
        />
        <div class="ml-2.5 text-sm leading-4">
          <b class="mb-[5px] grid grid-cols-1 break-words">
            {{ answer.author.fullName }}
          </b>
          <base-date
            :date="answer.created_at"
            type="short-date-spr-time"
            class="text-eonx-neutral-600"
          />
        </div>
      </div>
    </div>
  </base-mdl>
</template>
