<script>
import DefaultGcTile from '/~/extensions/giftcards/components/gc-tile.vue'
import { useGcCinema } from '/~/extensions/gift-cards-cinema/composables'

export default {
  name: 'gift-cards-cinema-tile',
  extends: DefaultGcTile,
  setup() {
    const { gcCinemaSelectedCardType, isGcCinemaEnabledForPhysical } =
      useGcCinema()

    return {
      ...DefaultGcTile.setup?.(...arguments),
      isPhysicalEnabled: isGcCinemaEnabledForPhysical,
      selectedCardType: gcCinemaSelectedCardType,
    }
  },
  data() {
    return {
      routeName: 'cinema-location',
    }
  },
}
</script>
