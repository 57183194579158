import api from '/~rec/core/api'
import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomUser } from '/~rec/core/user'
import { RecroomAttachmentsProcessor } from '/~rec/core/attachments-processor'
import { useRecProfile } from '/~rec/composables/profile'

const { myRecId } = useRecProfile()

export class RecroomComment {
  constructor(rawData = {}) {
    this.raw = rawData

    this.author = new RecroomUser(rawData.author)

    this.comments = new EntityProcessor({
      entity: `posts/${this.postId}/comments/${this.id}/comments?order[createdAt]=desc`,
      mapping: (record) => new RecroomComment(record),
      pagination: {
        total_items: this.totalComments,
      },
      perPage: 5,
    })
    this.attachments = new RecroomAttachmentsProcessor(rawData.attachments)
  }

  get id() {
    return this.raw.id
  }

  get postId() {
    return this.raw.post_id
  }

  get content() {
    return this.raw.content
  }

  get createdAt() {
    return this.raw.created_at
  }

  get likes() {
    return this.raw.likes || []
  }

  get totalLikes() {
    return this.raw.total_likes
  }

  set totalLikes(value) {
    this.raw.total_likes = value
  }

  get totalComments() {
    return this.raw.total_comments
  }

  set totalComments(value) {
    this.raw.total_comments = value
  }

  get parentCommentId() {
    return this.raw.parent_comment_id
  }

  // get reporters() {}

  get isMeAuthor() {
    return this.author.id === myRecId.value
  }

  get isLikedByMe() {
    return this.raw.likes && this.raw.likes.includes(myRecId.value)
  }

  // get isReportedByMe() {}

  get mentions() {
    return Object.values(this.raw.mentions)
  }

  static async create(postId, data) {
    const comment = await api.post(`posts/${postId}/comments`, data)

    return new RecroomComment(comment)
  }

  async update(comment) {
    const { data } = await api.put(
      `posts/${this.postId}/comments/${this.id}`,
      comment
    )

    this.updateRaw(data)
    this.attachments = new RecroomAttachmentsProcessor(data.attachments)
  }

  async delete() {
    return api.delete(`posts/${this.postId}/comments/${this.id}`)
  }

  async report() {
    return api.post('reported-comments', { commentId: this.id })
  }

  async like() {
    const task = api.post(`posts/${this.postId}/comments/${this.id}/likes`)

    this.raw.likes.push(myRecId.value)
    this.raw.total_likes += 1

    return task.then((res) => {
      this.updateRaw(res.data)
    })
  }

  updateRaw(newData) {
    this.raw = { ...this.raw, ...newData }
  }
}
