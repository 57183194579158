<script>
import values from 'lodash-es/values'
import { ValidationObserver } from 'vee-validate'
import { ref, nextTick } from 'vue'
import { directive as clickaway } from 'vue-clickaway'
import ui from '/~/core/ui'
import { useVerifications } from '/~/extensions/otp/composables'
import { roundFigure } from '/~/utils/format/numeric'
import BaseCounter from '/~/components/base/counter/base-counter.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import RetailerGift from '/~/components/retailer/retailer-gift.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import { useGiftRecipients } from '/~/composables/gift-recipients'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'
import ProductAdd from './add/product-add.vue'
import ProductFlash from './flash/product-flash.vue'
import ProductHeader from './header/product-header.vue'
import RetailerProductMixin from './retailer-product.mixin'
import ProductTotal from './total/product-total.vue'

export default {
  name: 'retailer-product',
  components: {
    RetailerGift,
    ProductAdd,
    ProductFlash,
    ProductHeader,
    ProductTotal,
    BaseInput,
    BaseCounter,
    ValidationObserver,
  },
  directives: {
    clickaway,
  },
  mixins: [RetailerProductMixin],
  props: {
    type: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttonsFixed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { addCartItem } = useCart()
    const {
      isBillPaymentsTemplate,
      maxItemsQuantity,
      isGiftingEnabled,
      isQuickBuyEnabled,
      isAddToCartEnabled,
      isCashrewardsProvider,
    } = useProvider()
    const { addGiftRecipient } = useGiftRecipients()
    const { payment } = useCheckout()
    const { toggleCartMenu } = useUI()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()
    const { withVerificationsCheck } = useVerifications()

    const clickOutsideRef = ref(null)
    const selectedPopover = ref(null)

    const handleOutsideClick = () => {
      selectedPopover.value = null
    }

    return {
      ...RetailerProductMixin.setup?.(...arguments),
      addCartItem,
      maxItemsQuantity,
      addGiftRecipient,
      isGiftingEnabled,
      payment,
      isQuickBuyEnabled,
      isAddToCartEnabled,
      toggleCartMenu,
      isBillPaymentsTemplate,
      isCashrewardsProvider,
      backendErrors,
      processBackendErrors,
      validationObserverRef,
      ui,
      clickOutsideRef,
      selectedPopover,
      handleOutsideClick,
      withVerificationsCheck,
    }
  },
  data() {
    return {
      adding: false,

      giftQuantity: 1,
      giftQuantityMin: 1,

      gift: {
        visible: false,
        filled: false,
      },
      selectedPoint: null,
      lastParams: null,
      errorMessage: '',
      recipient: null,
      variableValue: '',
    }
  },
  computed: {
    products() {
      return this.offer?.relationships?.products?.data
    },
    offerId() {
      return this.offer?.attributes?.id
    },
    retailerItem() {
      return {
        image: this.retailer?.attributes?.images?.opt_logo,
        name: this.retailer?.attributes?.name,
        slug: this.retailer?.attributes?.slug,
      }
    },
    titleValue() {
      return Number(this.percentage) || 0
    },
    options() {
      const { products } = this

      if (products) {
        return values(products)
          .sort((a, b) => a.attributes.value - b.attributes.value)
          .map(({ attributes }) => ({
            id: attributes.id,
            price: attributes.price,
            label:
              attributes.display_label ||
              '$' +
                (Number.isInteger(attributes.value)
                  ? attributes.value
                  : attributes.value.toFixed(2)),
            value: attributes.value,
            disabled: !attributes.in_stock,
            external_id: attributes.external_id,
            name: attributes.name,
            display_subtitle: attributes.display_subtitle,
            uuid: attributes.uuid || this.offer?.attributes?.uuid,
          }))
      }

      return []
    },
    isCorrectAmount() {
      if (!this.selectedValue) {
        return false
      }

      const variableValue = parseFloat(this.selectedValue)

      return (
        variableValue >= this.minVariableValue &&
        variableValue <= this.maxVariableValue
      )
    },
    isCounterDisabled() {
      if (this.isVariableProductType) {
        return !this.isCorrectAmount
      }

      return !this.selectedPoint
    },
    productLabels() {
      return [`${this.giftQuantity} x ${this.name} eGift Card(s)`]
    },
    variableProduct() {
      return this.products && this.products[0]
    },
    isVariableProductType() {
      const { products } = this

      if (products) {
        return values(products).every(
          ({ attributes }) => attributes.type === 'variable'
        )
      }

      return false
    },
    isFlashCardType() {
      return this.offer?.attributes?.type === 'flash_card'
    },
    flashCardImage() {
      const image = this.offer?.attributes?.flash_card

      return this.cdn(image).url()
    },
    minVariableValue() {
      return this.variableProduct?.attributes?.min ?? 1
    },
    maxVariableValue() {
      return this.variableProduct?.attributes?.max ?? 2e6
    },
    currentItem() {
      const { options } = this

      if (options) {
        const currentItem = options.filter(
          ({ id }) => id === this.selectedPoint
        )[0]

        return currentItem || null
      }

      return null
    },
    giftTotalAmount() {
      const { currentItem, giftQuantity } = this
      let total

      if (this.isVariableProductType) {
        const preSum = this.selectedValue * giftQuantity

        total = roundFigure(
          preSum - (preSum * parseFloat(this.percentage)) / 100
        )
      } else if (currentItem) {
        total = giftQuantity * currentItem.price
      }

      return total || 0
    },
    isErrors() {
      return Boolean(this.validationObserverRef?.flags.invalid)
    },
    isAddButtonDisabled() {
      if (
        this.isOutOfStock ||
        this.adding ||
        !this.currentItem ||
        (this.gift.visible && !this.gift.filled) ||
        this.isErrors
      ) {
        return true
      } else if (this.giftQuantity && this.isVariableProductType) {
        return !this.isCorrectAmount
      }

      return !this.selectedPoint
    },
    currencyMask() {
      return {
        mask: 'num',
        placeholderChar: '',
        lazy: false,
        signed: false,
        blocks: {
          num: {
            mask: Number,
            min: 0,
            max: this.maxVariableValue,
            radix: '.',
            mapToRadix: ['.', ','],
            scale: 2,
            normalizeZeros: false,
          },
        },
        minValue: this.minVariableValue.toString(),
      }
    },
    isQuickBuyButtonShow() {
      return !this.physical && this.isQuickBuyEnabled
    },
    isQuickBuyDisabled() {
      if (
        this.isOutOfStock ||
        this.adding ||
        !this.currentItem ||
        this.gift.visible ||
        this.isErrors ||
        // NOTE Disabled for gifts for now because in v3 we have different endpoint for gifts
        // and we need to clear it somehow when gifted quick buy is dismissed
        this.isGift
      ) {
        return true
      }

      if (this.giftQuantity && this.isVariableProductType) {
        return !this.isCorrectAmount
      }

      return !this.selectedPoint
    },
    offerExternalId() {
      return this.offer?.attributes?.external_id
    },
    isOutOfStock() {
      return this.options.every((option) => option.disabled)
    },
  },
  watch: {
    isAddButtonDisabled(value) {
      if (!value && this.giftQuantity === 0) {
        this.giftQuantity = 1
      }
    },
    selectedPoint(next, current) {
      this.updateQuantity(next, current)
    },
    selectedValue(next, current) {
      this.updateQuantity(next, current)
    },
    products(value) {
      if (value) {
        this.recordAnalytics()
      }
    },
    offer(value) {
      if (value) {
        this.getProducts()
      }
    },
  },
  created() {
    this.getProducts()
  },
  methods: {
    getProducts() {
      if (this.selectedPoint === null) {
        this.foundMinimalActiveProduct()
      }

      return this.options
    },
    foundMinimalActiveProduct() {
      const filteringProducts = this.options.filter((i) => !i.disabled)

      if (filteringProducts.length) {
        this.selectedPoint = filteringProducts[0].id
      }
    },
    updateQuantity(next, current) {
      if (next && !current && !this.giftQuantity) {
        this.giftQuantity = 1
      }
    },
    async onSubmitHandler() {
      const isValid = this.isGiftingEnabled
        ? await (this.$refs.retailerGift?.validate() ?? true)
        : true

      if (isValid) {
        this.addToCard()
      }
    },
    async addToCard() {
      const params = {
        quantity: this.giftQuantity,
        id: this.currentItem.uuid,
        type: this.type,
      }

      this.backendErrors = {}

      if (this.isVariableProductType) {
        params.value = this.selectedValue
      }

      this.addToCardAnalytics()
      this.adding = true

      if (this.gift.visible) {
        const giftResponse = await this.addGiftRecipient(this.recipient)

        params.message = giftResponse.message
        params.giftRecipientId = giftResponse.id
      }

      try {
        await this.addCartItem(params)

        this.lastParams = params
        this.clearState()
        this.toggleCartMenu()
      } catch (error) {
        this.processBackendErrors(error.data || error)
      } finally {
        this.adding = false
      }
    },
    onSelect(id, _, disabled) {
      if (!disabled) {
        this.selectedPoint = parseInt(id, 10)
      }
    },
    clearState() {
      this.recipient = {}
      this.giftQuantity = 1
      this.selectedValue = ''

      nextTick(() => this.validationObserverRef?.reset())

      if (this.isGiftingEnabled) {
        this.$refs.retailerGift?.reset()
      }

      if (!this.isVariableProductType) {
        this.foundMinimalActiveProduct()
      }
    },
    async addToQuickBuy() {
      const isValid = this.isGiftingEnabled
        ? await (this.$refs.retailerGift?.validate() ?? true)
        : true

      if (!isValid) {
        return
      }

      const query = {
        slug: this.retailerItem.slug,
        offerExternalId: this.offerExternalId,
        quantity: this.giftQuantity,
        type: this.type,
        amount: this.giftTotalAmount,
      }

      if (this.gift.visible) {
        const giftResponse = await this.addGiftRecipient(this.recipient)

        query.message = giftResponse.message
        query.giftRecipientId = giftResponse.id
      }

      if (this.isVariableProductType && this.variableProduct) {
        query.id = this.variableProduct.attributes.uuid
        query.value = Number(this.selectedValue)
        query.price = roundFigure(this.giftTotalAmount / this.giftQuantity)
        query.amount = roundFigure(query.price * this.giftQuantity)
      } else {
        query.id = this.currentItem.uuid
        query.value = this.currentItem.value
        query.price = this.currentItem.price
      }

      this.$router.push({ name: 'quick-buy-checkout', query })
    },
  },
}
</script>

<template>
  <validation-observer v-if="offer" ref="validationObserverRef" slim>
    <div class="mt-6 sm:mt-0">
      <product-flash
        v-if="isFlashCardType"
        :name="offer.attributes.name"
        :src="flashCardImage"
      />
      <div v-else>
        <product-header :offer="offer" :retailer="retailer" />
        <div v-if="html" class="mt-5 text-center" v-html="html" />
        <div v-else>
          <div
            v-if="isVariableProductType"
            class="my-[9px] flex flex-col items-start"
          >
            <div class="mx-auto w-full max-w-64 sm:ml-0">
              <div class="mb-[5px] text-center sm:text-left">
                Enter Amount
                <span v-if="variableProduct" data-test="amount-title">
                  (${{ minVariableValue }} - ${{ maxVariableValue }})
                </span>
              </div>

              <base-input
                ref="moneyInput"
                v-model="selectedValue"
                :validation="{
                  rules: `required|min_value:${minVariableValue}|max_value:${maxVariableValue}`,
                  name: 'Amount',
                  mode: 'aggressive',
                  vid: 'Amount',
                  unmasked: true,
                }"
                :mask="currencyMask"
                data-cy="amount"
                name="selectedValue"
                icon="plain/dollar-v2"
                :icon-size="18"
                nolabel
                :disabled="isOutOfStock"
              />
            </div>
          </div>
          <div
            v-else
            class="mx-5 mb-6 flex justify-center text-eonx-neutral-800 sm:mx-0 sm:my-6"
          >
            <div
              ref="clickOutsideRef"
              v-clickaway="handleOutsideClick"
              class="inline-flex flex-wrap justify-center gap-3 sm:justify-start"
            >
              <div
                v-for="product in options"
                :key="product.id"
                data-test="product-option"
                class="flex flex-col items-center"
              >
                <v-popover
                  v-if="product.disabled"
                  offset="8"
                  :open="selectedPopover === product.id"
                  trigger="manual"
                  :auto-hide="false"
                >
                  <div
                    class="flex h-14 min-w-[72px] cursor-not-allowed items-center justify-center rounded border border-divider px-2 text-center text-lg font-bold text-eonx-neutral-600"
                    tabindex="0"
                    @click="selectedPopover = product.id"
                    @mouseover="selectedPopover = product.id"
                    @mouseleave="selectedPopover = null"
                  >
                    {{ product.label }}
                  </div>

                  <template #popover>
                    <div class="max-w-56 leading-tight">
                      <h4 class="text-2xl font-bold">
                        Sorry, currently out of stock!
                      </h4>
                      <p class="mt-5 text-base">
                        Please select another amount or try again later.
                      </p>
                    </div>
                  </template>
                </v-popover>
                <button
                  v-else
                  class="flex h-14 min-w-[72px] items-center justify-center rounded border border-solid border-primary px-[5px] text-center text-lg font-bold text-primary"
                  :class="[
                    selectedPoint === product.id
                      ? 'border-primary bg-primary text-white'
                      : 'border-divider bg-inherit hover:bg-primary-lightest',
                  ]"
                  @click="onSelect(product.id, product.price, product.disabled)"
                >
                  {{ product.label }}
                </button>
                <div class="mx-2.5">
                  {{ product.display_subtitle }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="offer"
            class="mx-5 mb-6 overflow-hidden rounded border border-eonx-neutral-200 sm:mx-0 sm:border-0"
          >
            <div
              class="mx-auto flex max-w-96 items-center justify-between gap-x-4 bg-white p-3 text-xl sm:max-w-none sm:px-6"
            >
              <div data-cy="calculation-counter">
                <base-counter
                  v-model="giftQuantity"
                  :min="1"
                  :max="maxItemsQuantity"
                  :disabled="isCounterDisabled"
                  small
                />
              </div>
              <product-total v-if="!isOutOfStock" :amount="giftTotalAmount" />
              <div v-else class="flex items-center justify-center truncate">
                <span
                  class="text-lg font-bold leading-none text-eonx-neutral-800 sm:text-2xl sm:leading-none"
                >
                  Out of stock
                </span>
              </div>
            </div>
          </div>
          <retailer-gift
            v-if="isGiftingEnabled"
            ref="retailerGift"
            v-model="recipient"
            :visible.sync="gift.visible"
            :filled.sync="gift.filled"
            :product-labels="productLabels"
            :physical="physical"
            :disabled="adding || isOutOfStock"
            :backend-errors="backendErrors"
            class="my-6 px-5 sm:px-0"
          />
          <product-add
            :is-add-button-show="isAddToCartEnabled"
            :is-quick-buy-button-show="isQuickBuyButtonShow"
            :is-quick-buy-disabled="isQuickBuyDisabled"
            :is-add-button-disabled="isAddButtonDisabled"
            :adding="adding"
            :physical="physical"
            :buttons-fixed="buttonsFixed"
            @click-add-to-quick-buy="withVerificationsCheck(addToQuickBuy)"
            @click-add-to-cart="withVerificationsCheck(onSubmitHandler)"
          />
        </div>
      </div>
    </div>
  </validation-observer>
  <div
    v-else-if="!loading"
    class="mt-6 flex items-center justify-center text-lg sm:mt-0 sm:h-full"
  >
    Offer not available
  </div>
</template>
