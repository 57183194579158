import { ref, computed } from 'vue'
import { RecroomAward } from '/~rec/core/award'
import { RecroomMemberAward } from '/~rec/core/member-award'
import { useRecProfile } from '/~rec/composables/profile'
import router from '/~/router'

const types = {
  available: {
    label: 'Available',
    theme: 'bg-sky-100',
    textColor: 'text-sky-800',
  },
  competing: {
    label: 'Competing in',
    theme: 'bg-violet-100',
    textColor: 'text-violet-800',
  },
  received: {
    label: 'Won',
    theme: 'bg-green-100',
    textColor: 'text-green-800',
  },
  finished: {
    label: 'Finished',
    theme: 'bg-pink-100',
    textColor: 'text-pink-800',
  },
}

type Types = keyof typeof types

type AwardType<T extends boolean> = T extends true
  ? RecroomMemberAward
  : RecroomAward

const { myRecId } = useRecProfile()
const loading = ref(false)
const awardStatus = ref<Types | ''>('')
const awardId = ref('')
const winnerId = ref('')
const award = ref<AwardType<typeof isReceivedOrFinished.value>>()

const isReceivedOrFinished = computed(() => {
  return ['received', 'finished'].includes(awardStatus.value)
})

const status = computed(() => {
  return isMeWinner.value ? types.received : types[awardStatus.value as Types]
})

const isMeWinner = computed(() => {
  return myRecId === (winnerId.value as any)
})

const nominateIsDisabled = computed(() => {
  return (
    !award.value?.iCanNominate ||
    award.value.isReached ||
    awardStatus.value === 'received'
  )
})

function isMemberAward(
  award: RecroomMemberAward | RecroomAward
): award is RecroomMemberAward {
  return isReceivedOrFinished.value
}

const getNominal = computed(() => {
  if (!award.value) {
    return null
  }

  return isMemberAward(award.value) ? award.value.prize : award.value.nominal
})

const rank = computed(() => {
  if (!award.value) return ''
  const remainder = award.value.nomineeRank % 10

  if (!award.value.nomineeRank) {
    return null
  }
  switch (remainder) {
    case 1:
      return { label: `${award.value.nomineeRank}st`, bg: 'bg-green-400' }
    case 2:
      return {
        label: `${award.value.nomineeRank}nd`,
        bg: 'bg-sky-400',
      }
    case 3:
      return {
        label: `${award.value.nomineeRank}rd`,
        bg: 'bg-sky-400',
      }
    default:
      return {
        label: `${award.value.nomineeRank}th`,
        bg: 'bg-sky-400',
      }
  }
})

function onRowClick({
  organisationMemberId,
}: {
  organisationMemberId: string
}) {
  if (awardStatus.value === 'finished') {
    router.push({
      name: 'rec-award-nominee-finished',
      params: {
        nomineeId: organisationMemberId,
        currentAwardId: (award.value as RecroomMemberAward).currentAwardId,
      },
    })
  } else {
    router.push({
      name: 'rec-award-nominee',
      params: {
        nomineeId: organisationMemberId,
      },
    })
  }
}

function nominate() {
  if (award.value) {
    router.push({
      name: 'rec-award-nominate',
      params: {
        award: award.value,
      },
    })
  }
}

async function init() {
  loading.value = true
  try {
    if (isReceivedOrFinished.value) {
      award.value = await RecroomMemberAward.fetchById(awardId.value)
    } else {
      award.value = await RecroomAward.fetchById(awardId.value)
    }
  } catch (error) {
    console.error(error)
  } finally {
    if (award.value) {
      await award.value.getAllowedUsers()
    }
    loading.value = false
  }
}

export const useAward = () => ({
  award,
  awardId,
  awardStatus,
  getNominal,
  init,
  loading,
  nominate,
  nominateIsDisabled,
  onRowClick,
  rank,
  status,
  winnerId,
})
