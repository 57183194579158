<script>
import BaseCard from '/~/components/base/card/base-card.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'drawer-photo-id-tile',
  components: {
    BaseIcon,
    BaseCard,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  emits: ['click'],
}
</script>

<template>
  <base-card
    class="mx-auto w-full max-w-[300px]"
    :ratio="300 / 191"
    @click="$emit('click')"
  >
    <div
      class="relative flex h-full flex-col overflow-hidden rounded-xl bg-cyan-800 p-5 text-white shadow"
    >
      <div
        class="absolute rounded-full bg-cyan-700"
        :style="{
          top: '-214px',
          right: '-163px',
          width: '351px',
          height: '351px',
        }"
      />
      <div
        class="absolute rounded-full bg-cyan-900"
        :style="{
          top: '103px',
          left: '-71px',
          width: '218px',
          height: '218px',
        }"
      />
      <div class="relative flex h-full flex-col">
        <div class="font-bold uppercase">
          {{ document.type }} ({{ document.subType }})
        </div>

        <div class="mt-auto">
          <div class="text-2xl font-black uppercase">
            N{{ document.number }}
          </div>
          <div class="mt-0.5 font-bold">
            {{ document.fullName }}
          </div>
        </div>

        <base-icon
          :svg="document.icon"
          size="md"
          type="image"
          class="absolute bottom-0 right-0 max-w-14"
        />
      </div>
    </div>
  </base-card>
</template>
