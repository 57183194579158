<script>
import BaseSelect from '/~/components/base/select/base-select.vue'

export default {
  name: 'rec-filter-select',
  components: {
    BaseSelect,
  },
  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
    selectedFilter: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <div class="ml-auto flex items-center">
    <base-select
      :options="filterOptions"
      :value="selectedFilter"
      class="mb-[15px] w-40 shrink-0 md:mb-0"
      arrow-icon="plain/chevron-bottom"
      name="filter"
      look="recroom"
      nolabel
      fullwidth-popup
      v-on="$listeners"
    />
  </div>
</template>
