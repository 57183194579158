<script>
import { nextTick } from 'vue'
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useUI } from '/~/composables/ui'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'side-menu-modal',
  components: {
    BaseMdl,
    BaseIcon,
    BaseAction,
  },
  setup() {
    const { settingsMenu } = useUI()
    const { isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return {
      isMeAdmin,
      isMeSuperAdmin,
      settingsMenu,
      modal,
    }
  },
  data() {
    return {
      recManageUrl: eonx.recroom.manageUrl,
    }
  },
  computed: {
    showRecManage() {
      return (
        (this.isMeAdmin || this.isMeSuperAdmin) &&
        this.$route.path.includes('rec-room')
      )
    },
  },
  mounted() {
    nextTick(() => {
      this.$refs.settingsAction[0].$el.focus()
    })
  },
}
</script>

<template>
  <base-mdl
    fullscreen="mobile"
    modal-class="fixed"
    modal-styles="top: unset; margin: auto 0 4rem 3rem; width: 312px;"
    :padding="false"
  >
    <template #header>
      <div />
    </template>
    <div class="flex w-full flex-col space-y-4 p-6">
      <template v-if="settingsMenu.length > 0">
        <template v-for="{ id, icon, label, action, divider } in settingsMenu">
          <hr v-if="divider" :key="id + 'divider'" />
          <div v-else :key="id" class="flex items-center font-bold">
            <base-action
              ref="settingsAction"
              v-bind="action"
              :label="label"
              aria-haspopup="menu"
              color="none"
              class="flex w-full items-center text-eonx-neutral-800 hover:text-eonx-neutral-600"
              @click="modal.hide('side-menu-modal')"
            >
              <template #entry>
                <div
                  class="mr-[15px] flex h-12 w-12 items-center justify-center rounded-full bg-eonx-neutral-50 text-eonx-neutral-800"
                >
                  <base-icon :svg="icon" :size="32" />
                </div>

                <span class="text-inherit">
                  {{ label }}
                </span>

                <div class="ml-auto text-inherit">
                  <base-icon svg="v2/heroic/chevron-right" :size="24" />
                </div>
              </template>
            </base-action>
          </div>
        </template>
      </template>
      <base-action
        v-if="showRecManage"
        type="link"
        :value="recManageUrl"
        color="none"
        class="mb-5 flex items-center text-eonx-neutral-800 hover:text-eonx-neutral-600"
      >
        <div
          class="mr-[15px] flex h-12 w-12 items-center justify-center rounded-full bg-eonx-neutral-50 text-eonx-neutral-800"
        >
          <base-icon svg="menu/wrench" :size="32" />
        </div>

        <span class="font-bold text-inherit">Go to Recroom Manage</span>

        <div class="ml-auto text-inherit">
          <base-icon svg="v2/heroic/chevron-right" :size="24" />
        </div>
      </base-action>
    </div>
  </base-mdl>
</template>
