<script>
import ActivityListMobileLayout from '/~/components/activity/layouts/activity-list-mobile-layout.vue'
import ActivityDetailsItem from './components/activity-details-item.vue'

export default {
  name: 'activity-details',
  components: {
    ActivityDetailsItem,
    ActivityListMobileLayout,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
}
</script>

<template>
  <activity-list-mobile-layout
    title="Transaction details"
    :back="{
      name: 'activity',
    }"
  >
    <activity-details-item :id="id" />
  </activity-list-mobile-layout>
</template>
