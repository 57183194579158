import { ref, computed } from 'vue'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { useExtensions } from '/~/composables/extensions'
import { Notification } from './core/notification'
import { NotificationProcessor } from './core/processors/notification-processor'

const { getManifestByName } = useExtensions()

const notificationsProcessor = ref(
  new NotificationProcessor({
    entity: 'v3/notifications',
    perPage: 15,
    mapping: (item) => new Notification(item),
  })
)

const hasUnreadNotifications = computed(() =>
  notificationsProcessor.value.hits.some((i) => !i.isRead)
)

function initNotifications() {
  const isNotifications = Boolean(getManifestByName('notifications'))

  if (!isNotifications) {
    return
  }

  import('/~/boot/mercure')

  notificationsProcessor.value.getData({
    notify: false,
  })
}

function getSubscriptions() {
  return api
    .get('/v3/notifications/subscriptions', {
      notify: false,
    })
    .then((response) => response.data)
    .catch((e) => {
      console.warn('Failed to load subscriptions to notifications', e)
    })
}

emitter.on('notifications:update_list', () => {
  initNotifications()
})

export function useNotifications() {
  return {
    notificationsProcessor,
    hasUnreadNotifications,

    initNotifications,
    getSubscriptions,
  }
}
