<script>
import DefaultCheckoutSummary from '/~/views/checkout/components/summary/checkout-summary.vue'

export default {
  extends: DefaultCheckoutSummary,
}
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="flex h-full grow flex-col overflow-y-auto px-[30px] py-5">
      <span class="mt-2.5 mb-5 text-2xl font-bold">Summary</span>
      <div class="flex grow flex-col divide-y">
        <checkout-summary-address
          v-if="isUserAddressEnabled"
          :billing="cartOrderAddresses.billing"
          :shipping="
            payment.hasDeliverableItems ? cartOrderAddresses.shipping : null
          "
          :loading="cartOrderAddresses.loading"
          class="pb-5"
        />
        <checkout-summary-items :items="cartItemsFiltered" class="py-5" />
        <checkout-summary-pay-with
          :credit-card="{ details: selectedCard, total: payWithCard }"
          :points="{ details: selectedPoints, total: payWithPoints }"
          :ewallet="{ details: selectedEwallet, total: payWithEwallet }"
          :bank-account="{
            details: selectedBankAccount,
            total: payWithBankAccount,
          }"
          :coupons="{ items: selectedCoupons, total: payWithCoupons }"
          class="pt-5"
        />
        <div v-if="loading" class="w-full space-y-2.5 pt-5">
          <div
            class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
          >
            loading
          </div>
          <div
            class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
          >
            loading
          </div>
        </div>
        <checkout-summary-total
          v-else
          :loading-fees="loadingFees"
          :transaction-fees="payment.transactionFees"
          :shipping-fees="payment.shippingFees"
          :amount="subTotalNoCoupons - payment.shippingFees"
          :total="total"
          class="mt-auto justify-self-end pt-5"
        />
      </div>
    </div>
  </div>
</template>
