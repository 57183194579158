<script>
import ui from '/~/core/ui'
import ActivityDesk from './activity.desk.vue'
import ActivityMobile from './activity.mobile.vue'

export default {
  name: 'australiapost-activity',
  components: {
    ActivityDesk,
    ActivityMobile,
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <activity-mobile v-if="ui.mobile" v-bind="$attrs" v-on="$listeners" />
  <activity-desk v-else v-bind="$attrs" v-on="$listeners" />
</template>
