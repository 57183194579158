import { computed, onBeforeMount, onBeforeUnmount, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import { SwiperOptions, useSwiper } from '/~/composables/base/use-swiper'
import { usePurchases } from '/~/composables/purchases'

const {
  isCurrentPurchasesLoaded,
  isArchivedPurchasesLoaded,
  hasPurchases,
  hasCurrentPurchases,
  fetchCurrentPurchases,
  sortedCurrentPurchases,
} = usePurchases()

type PropsType = {
  toModal: string
  swiperOptions?: SwiperOptions
}

export function useEpurchasesComponent(props: PropsType) {
  const route = useRoute()
  const { swiperContainer, initSwiper, destroySwiper } = useSwiper(
    computed(() => ({
      slidesPerView: 2.5,
      allowTouchMove: sortedCurrentPurchases.value.length > 2,
      spaceBetween: 16,
      slidesOffsetAfter: 40,
      navigation: {
        disabledClass: 'opacity-50 cursor-default',
      },
      ...props.swiperOptions,
    }))
  )

  watch(swiperContainer, initSwiper)

  onBeforeUnmount(() => {
    // /src/styles/transitions.css - .menu-slide-leave-active - transition-duration: 0.4s
    setTimeout(destroySwiper, 400)
  })

  const epurchasesRoute = computed(() => {
    return route.meta?.epurchases ?? { hash: '#profile-e-purchases' }
  })

  function showUnlockModal() {
    bottomSheet.show('epurchase-unlock-v2', {
      style: {
        centered: true,
      },
      to: props.toModal,
    })
  }

  onBeforeMount(fetchCurrentPurchases)

  return {
    fetchCurrentPurchases,
    isCurrentPurchasesLoaded,
    isArchivedPurchasesLoaded,
    hasCurrentPurchases,
    hasPurchases,
    sortedCurrentPurchases,
    epurchasesRoute,
    showUnlockModal,
    swiperContainer,
  }
}
