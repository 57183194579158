import { CMSPageId } from '/~/types/cms'
import { RewardsExtension } from '/~/core/extension'
import { useTicketMaster } from '/~/extensions/ticket-master/composables'

const { init, moduleName } = useTicketMaster()

class TicketMasterExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  async setState() {
    return init()
  }

  setRoutes() {
    return [
      {
        path: moduleName,
        component: () => import('./ticket-master.vue'),
        children: [
          {
            path: '',
            name: 'ticket-master',
            component: () =>
              import('./views/catalog/ticket-master-catalog.vue'),
            meta: {
              title: 'Ticketmaster',
              page: 'ticket-master' as CMSPageId,
            },
          },
        ],
      },
    ]
  }
}

export default TicketMasterExtension
