<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import RenderBanner from '/~rec/components/render/render-banner.vue'
import TypeSwitch from '../form/type-switch.vue'
import BaseButton from '/~/components/base/button/base-button'
import UserSelector from '/~rec/components/users/user-selector.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import UsersSelectedDesktop from '/~rec/components/users/users-selected-desktop.vue'
import FormElementLabel from '/~rec/components/form/form-element-label.vue'
import { useForm } from '/~/composables/base/use-form'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'rec-group-form',
  components: {
    RenderBanner,
    TypeSwitch,
    BaseButton,
    UserSelector,
    BaseTextarea,
    UsersSelectedDesktop,
    FormElementLabel,
    ValidationObserver,
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    backendErrors: {
      type: Object,
      default: () => ({}),
    },
    showSwitch: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { validationObserverRef } = useForm()

    return {
      ui,
      validationObserverRef,
    }
  },
  data() {
    return {
      typeOptions: [
        {
          value: false,
          label: 'Public',
          description: 'Anyone can view and create posts and comments',
          icon: 'rec/planet',
        },
        {
          value: true,
          label: 'Private',
          description:
            'Only invited members can view and create posts and comments',
          icon: 'rec/lock-solid',
        },
      ],
    }
  },
  computed: {
    btnLabel() {
      return this.isEdit ? 'Save' : 'Create Group'
    },
  },
  methods: {
    openUserSelectModal() {
      modal.show('rec-users-manager', {
        props: {
          title: 'Group members',
          multi: true,
          getCurrentUsers: () => this.form?.members || [],
          filter: (users) =>
            users.filter((user) =>
              this.form.members.map((u) => u.id).includes(user.id)
            ),
          selectable: true,
          disabledLabel: 'Already in the group',
          showAddList: true,
        },
        on: {
          'user-select': (users) => this.$emit('members', users),
        },
      })
    },
    onSubmit() {
      this.$emit('save')
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <div class="flex shrink-0 grow flex-col">
      <render-banner
        :image="form.image"
        class="mx-auto mb-5 h-[140px] w-[140px]"
        button
        look="rounded"
        :disabled="processing"
        @change="$emit('image', $event)"
      />

      <type-switch
        v-if="showSwitch"
        :value="form.private"
        :options="typeOptions"
        :disabled="processing"
        class="mb-[30px]"
        @input="$emit('private', $event)"
      />

      <form-element-label label="Group name" icon="rec/edit-contour" />

      <base-textarea
        :validation="{
          rules: 'required|offensive',
          name: 'name',
          mode: 'aggressive',
        }"
        :value="form.name"
        :maxlength="80"
        :max-rows="2"
        :disabled="processing"
        :error="backendErrors.name"
        name="name"
        look="recroom"
        class="mb-[30px] w-full grow-0"
        @input="$emit('name', $event)"
      />

      <form-element-label label="Group description" icon="rec/book" />
      <base-textarea
        :validation="{
          rules: 'required|offensive',
          name: 'description',
          mode: 'aggressive',
        }"
        :value="form.description"
        :maxlength="400"
        :max-rows="4"
        :disabled="processing"
        :error="backendErrors.description"
        required
        name="description"
        look="recroom"
        class="mb-[30px] w-full grow-0"
        @input="$emit('description', $event)"
      />

      <template v-if="form.private || isEdit">
        <form-element-label
          label="Members"
          icon="rec/group-event"
          class="mb-2.5"
        />

        <user-selector
          v-if="ui.desktop"
          :multi="true"
          :users="form.members"
          @user-select="$emit('members', $event)"
        />
        <div
          v-else
          class="flex min-h-12 cursor-pointer rounded-lg bg-gray-50 p-2.5"
          :class="{
            'pointer-events-none opacity-75': processing,
          }"
          @click="openUserSelectModal"
        >
          <users-selected-desktop
            :selected-users="form.members"
            :removable="false"
            multi
          />
        </div>
      </template>

      <div class="mt-auto flex w-full pt-5">
        <base-button
          class="mx-auto w-40"
          :loading="processing"
          data-test="rec-group-save-button"
          @click="handleSubmit(onSubmit)"
        >
          {{ btnLabel }}
        </base-button>
      </div>
    </div>
  </validation-observer>
</template>
