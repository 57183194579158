<script>
export default {
  name: 'rec-layout-view-desktop',
  props: {
    heading: {
      type: String,
      default: '',
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <div class="relative flex h-full grow justify-center">
    <slot />
  </div>
</template>
