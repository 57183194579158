<script>
import QrLayout from '../../components/qr-layout.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'qr-security',
  components: {
    QrLayout,
    BaseIcon,
  },
}
</script>

<template>
  <qr-layout>
    <div class="text-center">
      Please show this unique QR code to security
      <br />
      personnel in order to authorise your access.
    </div>
    <div class="mt-10 text-center">
      <div class="m-auto h-48 w-48">
        <base-icon svg="qr/code-sqr" size="auto" />
      </div>
    </div>
    <p class="mt-[5px] text-center">8163 1833 1180 3170 3021</p>
  </qr-layout>
</template>
