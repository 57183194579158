<script>
import LoyaltyCard from '/~/drawer/loyalty-cards/components/loyalty-card.vue'

export default {
  name: 'loyalty-card-v2',
  extends: LoyaltyCard,
  emits: ['click', 'keyup'],
}
</script>

<template>
  <div>
    <base-card
      v-if="card.isCatalog"
      class="overflow-hidden rounded-xl hover:shadow-lg"
      @click="$emit('click', card)"
      @keyup="$emit('keyup', $event, card)"
    >
      <base-image v-bind="card.image" :alt="card.name" />
    </base-card>
    <base-card
      v-else
      class="overflow-hidden rounded-xl hover:shadow-lg"
      @click="$emit('click', card)"
      @keyup="$emit('keyup', $event, card)"
    >
      <div
        class="flex h-full w-full flex-col items-center justify-center text-white"
        :style="{
          backgroundColor: card.color,
        }"
      >
        <base-icon size="2xl" svg="circle/loyalty-cards" />
        <div class="mt-2.5 font-bold">
          {{ card.name }}
        </div>
      </div>
    </base-card>
    <div v-if="card.name" class="mt-2.5 font-bold capitalize">
      {{ card.name }}
    </div>
  </div>
</template>
