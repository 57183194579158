<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import NabVerificationCancelForm from '../form/nab-verification-cancel-form.vue'

export default {
  name: 'nab-verification-cancel-mdl',
  components: {
    BaseMdl,
    NabVerificationCancelForm,
  },
  methods: {
    hide() {
      modal.hide()
    },
    onSubmit() {
      this.hide()
      this.$emit('submit')
    },
  },
}
</script>

<template>
  <base-mdl width="xs" title="Cancel a payment?">
    <nab-verification-cancel-form @submit="onSubmit" @cancel="hide" />
  </base-mdl>
</template>
