export default class HeathService {
  constructor(raw = {}, objectID, retailerID) {
    this.raw = raw
    this.objectID = objectID
    this.retailerID = retailerID
  }

  get id() {
    return this.raw.id
  }

  get title() {
    return this.raw.name
  }

  get description() {
    return this.raw.description
  }

  get image() {
    return this.raw.logo
  }

  get location() {
    return this.raw.website
  }
}
