<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { formatDollar, formatAmount } from '/~/utils/format/money'

export default {
  name: 'statistic-card',
  components: {
    BaseIcon,
    BaseLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'plain/dollar',
    },
    title: {
      type: String,
      default: '* Title *',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    subValue: {
      type: String,
      default: null,
    },
    isCurrency: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      formatDollar,
      formatAmount,
    }
  },
  computed: {
    formattedValue() {
      if (this.isCurrency) {
        return formatDollar(this.value, 0)
      }

      return formatAmount(this.value, 0)
    },
    fixedValue() {
      return this.formattedValue.split('.')[0]
    },
    fixedSubValue() {
      if (this.isCurrency) {
        return this.formattedValue.split('.')[1] || '00'
      }

      return this.subValue
    },
  },
}
</script>

<template>
  <div class="grid w-full space-y-[15px] rounded-xl bg-white p-[15px]">
    <div class="flex items-center space-x-[5px]">
      <slot name="icon">
        <base-icon :svg="icon" size="md" />
      </slot>

      <div class="text-sm font-bold text-eonx-neutral-600">
        {{ title }}
      </div>
    </div>
    <div class="flex items-baseline space-x-[5px] text-eonx-neutral-800">
      <template v-if="!loading">
        <template v-if="!isCurrency">
          <div class="text-2xl font-bold">
            {{ fixedValue }}
          </div>
          <div class="font-bold">
            {{ fixedSubValue }}
          </div>
        </template>
        <template v-if="isCurrency">
          <div class="text-2xl font-bold">
            {{ formattedValue }}
          </div>
        </template>
      </template>
      <base-loader v-else />
    </div>
  </div>
</template>
