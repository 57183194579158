import api from '/~/core/api'

interface HelpFormPayload {
  name: string
  email: string
  message: string
}

function sendHelp(payload: HelpFormPayload) {
  return api.post('/help/ticket/create', payload, { notify: false })
}

export function useHelp() {
  return {
    sendHelp,
  }
}
