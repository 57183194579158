<script>
import CheckoutSummaryTotalV1 from '/~/components/checkout/summary/checkout-summary-total.vue'

export default {
  name: 'checkout-summary-total-v2',
  extends: CheckoutSummaryTotalV1,
}
</script>

<template>
  <div class="grid grid-cols-2 leading-tight">
    <span class="mb-4 font-bold">Amount</span>
    <span class="text-right font-bold">
      {{ formatDollar(amount) }}
    </span>

    <template v-if="transactionFees > 0">
      <span class="mb-4">Payment Processing Fee</span>
      <span
        v-if="loadingFees"
        class="mb-2.5 ml-auto h-5 w-12 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
      >
        loading
      </span>
      <span v-else class="text-right">
        {{ formatDollar(transactionFees) }}
      </span>
    </template>

    <template v-if="shippingFees > 0">
      <span class="mb-4">Shipping Fee</span>

      <span class="text-right">
        {{ formatDollar(shippingFees) }}
      </span>
    </template>

    <span class="text-xl font-bold">Total</span>
    <span class="text-right text-xl font-bold">
      {{ formatDollar(total) }}
    </span>
  </div>
</template>
