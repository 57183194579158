<script>
import TermsConditionsMdl from './terms-conditions.mdl.vue'

export default {
  name: 'terms-conditions-bottom-sheet',
  extends: TermsConditionsMdl,
}
</script>

<template>
  <div class="bg-neutral-50">
    <div class="sticky top-0 flex h-16 w-full items-center bg-neutral-50">
      <h2 class="w-full text-center text-base text-eonx-neutral-800">
        Terms and Conditions
      </h2>
      <base-button
        v-if="!forceConfirmation"
        icon="heroicons/outline/x-mark"
        :size="32"
        class="absolute right-[15px]"
        @click="close"
      />
    </div>
    <terms-conditions
      class="mx-4 mb-4 overflow-y-auto overflow-x-hidden rounded-xl border border-neutral-200 p-4"
    />
    <div v-if="forceConfirmation" class="flex justify-center border-t py-4">
      <base-button :loading="confirming" @click="confirmTerms">
        Accept
      </base-button>
    </div>
  </div>
</template>
