<script>
import clone from 'lodash-es/clone'
import map from 'lodash-es/map'
import './base-checkbox.scss'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

function initCheckedValue(val) {
  return Array.isArray(val) ? map(val, clone) : clone(val)
}

export default {
  name: 'base-checkbox',
  components: {
    BaseIcon,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: [Boolean, Array],
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Object, Boolean],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: [Number, String],
      default: 0,
    },
    look: {
      type: String,
      default: 'v1',
      validator: (v) => !v || /v1|v2|v3|v4/.test(v),
    },
    hideInput: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkedValue: null,
    }
  },
  computed: {
    checkedModel: {
      get() {
        return this.checkedValue
      },
      set(val) {
        this.checkedValue = val
      },
    },
    isV1Look() {
      return this.look === 'v1'
    },
    isV2Look() {
      return this.look === 'v2'
    },
    isV3Look() {
      return this.look === 'v3'
    },
    isV4Look() {
      return this.look === 'v4'
    },
    icon() {
      if (this.isV3Look || this.isV4Look) {
        return 'v2/box-icons/bx-check-square--inner'
      }

      return 'plain/check'
    },
    iconSize() {
      if (this.isV3Look) {
        return 18
      }
      if (this.isV4Look) {
        return 20
      }

      return null
    },
  },
  watch: {
    checked: {
      handler(val) {
        this.checkedValue = initCheckedValue(val)
      },
      deep: true,
    },
  },
  mounted() {
    this.checkedValue = initCheckedValue(this.checked)
  },
  methods: {
    onSpaceDown() {
      this.$refs.input.click()
    },
    change(event) {
      this.$emit('change', this.checkedModel)
      this.$emit('input', event.target.value, this.checkedModel)
    },
  },
}
</script>

<template>
  <label
    :class="[
      'base-checkbox',
      isV2Look && 'base-checkbox--v2',
      isV3Look && 'base-checkbox--v3',
      isV4Look && 'base-checkbox--v4',
      disabled && 'is-disabled',
    ]"
    :tabindex="disabled ? -1 : tabindex"
    @keydown.space.prevent="onSpaceDown"
  >
    <input
      ref="input"
      v-model="checkedModel"
      :disabled="disabled"
      :value="value"
      class="base-checkbox__input"
      type="checkbox"
      data-testid="checkbox"
      @change="change($event)"
    />
    <span v-if="!hideInput" class="base-checkbox__check">
      <base-icon
        :class="{
          'base-checkbox__tick': isV1Look || isV2Look,
        }"
        :svg="icon"
        :size="iconSize"
      />
    </span>
    <span
      v-if="!hideLabel"
      class="base-checkbox__label"
      :class="{
        'base-checkbox__label_cb-hidden': hideInput,
      }"
    >
      <slot />
    </span>
  </label>
</template>
