<script>
import { nextTick } from 'vue'
import { getExtendedGraphemeClusters } from '/~/utils/unicode-string'
import './payload'
import BaseField from '/~/components/base/field/base-field.vue'
import InputMixin from '../input/input.mixin'
import './base-textarea.scss'

export default {
  name: 'base-textarea',
  components: {
    BaseField,
  },
  mixins: [InputMixin],
  props: {
    mask: {
      type: Object,
      default: null,
    },
    bolder: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 1,
    },
    maxRows: {
      type: Number,
      default: 10,
    },
    maxlength: {
      type: [String, Number],
      default: '',
    },
    lineHeight: {
      type: Number,
      default: 24,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    look: {
      type: String,
      default: null,
      validator: (v) => /simple|recroom/.test(v),
    },
    entryClass: {
      type: String,
      default: 'rounded',
    },
  },
  data() {
    return {
      localValue: '',
      symbolsCount: 0,
    }
  },
  computed: {
    counter() {
      return `${this.symbolsCount}/${this.maxlength}`
    },
    bindings() {
      const attrs = {}

      if (this.error || this.description) {
        attrs['aria-describedby'] = `${this.id}-message`
      }

      return attrs
    },
  },
  watch: {
    value: {
      handler(value, oldValue) {
        if (value !== oldValue && value !== this.localValue) {
          this.setLocalValue({ target: { value } })
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const input = this.$refs.input

    input.style.height = input.scrollHeight + 'px'

    nextTick(() => {
      if (this.focused) {
        this.$refs.input.focus()
      }
    })
  },
  methods: {
    setLocalValue(event) {
      const { value } = event.target
      const { maxlength, $refs, isFocused } = this
      const clusters = getExtendedGraphemeClusters(String(value))
      const clustersCount = clusters.length
      let resultedValue = value
      let resultedSymbolsCount = clustersCount

      if ($refs.input) {
        $refs.input.style.height = $refs.input?.style?.minHeight
        nextTick(() => {
          $refs.input.style.height = $refs.input?.scrollHeight + 'px'

          if (!isFocused) {
            $refs.input.focus()
          }
        })
      }

      if (event.target.scrollHeight) {
        event.target.style.height = event.target.scrollHeight + 'px'
      }

      if (maxlength !== '' && clustersCount > maxlength) {
        if (event.preventDefault) {
          event.preventDefault()
        }

        resultedValue = clusters.slice(0, maxlength).join('')
        resultedSymbolsCount = maxlength
      }

      this.symbolsCount = resultedSymbolsCount
      this.localValue = resultedValue

      this.validateOnInput(this.localValue)
      this.$emit('input', resultedValue)
    },
  },
}
</script>

<template>
  <validation-provider
    ref="validationProviderRef"
    v-slot="{ errors }"
    v-bind="validation"
    :name="validation.name ? validation.name : label"
    slim
    :detect-input="false"
  >
    <base-field
      ref="field"
      :input-id="id"
      :label="label"
      :required="required"
      :required-asterisk="requiredAsterisk"
      :disabled="disabled"
      :focused="isFocused"
      :description="description"
      :error="errors[0] || error"
      :value="localValue"
      :style="{
        lineHeight: `${lineHeight}px`,
      }"
      :icon="icon"
      :expanded="isFocused || !!value || !!placeholder"
      class="base-textarea"
      :class="look && `base-textarea--${look}`"
      :floated="look === 'simple'"
      :border="look !== 'simple'"
      :entry-class="entryClass"
    >
      <textarea
        :id="id"
        ref="input"
        v-bind="bindings"
        :value="localValue"
        :style="{
          minHeight: rows * lineHeight + (look !== 'simple' ? 16 : 2) + 'px',
          maxHeight: maxRows * lineHeight + 'px',
          resize: 'none',
        }"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :rows="rows"
        :name="name"
        :disabled="disabled"
        class="base-textarea__inner"
        @input="setLocalValue"
        @blur="onBlur"
        @focus="onFocus"
      />
      <div v-if="maxlength" class="base-textarea__counter">
        {{ counter }}
      </div>
    </base-field>
  </validation-provider>
</template>
