<script>
import ui from '/~/core/ui'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'leaving-confirm',
  components: {
    BaseButton,
    BaseMdl,
    BaseIcon,
  },
  setup() {
    return {
      ui,
    }
  },
  methods: {
    submit(action) {
      this.$emit(action)
    },
  },
}
</script>

<template>
  <base-mdl
    width="sm"
    fullscreen="mobile"
    :modal-styles="
      ui.mobile ? 'max-height: 30rem; margin-bottom: 0' : 'max-width: 28rem'
    "
    :layout="false"
    modal-class="rounded-t-3xl"
    transition="slide-bottom"
    class="ease transform duration-500"
  >
    <div class="flex h-full flex-col p-5">
      <div class="relative flex items-center justify-center">
        <base-icon class="mx-auto my-0" :size="250" svg="v3/warning" />
      </div>

      <div class="flex h-full grow flex-col">
        <h2 class="pb-2.5 text-center text-eonx-neutral-800">Are you sure?</h2>

        <div class="text-center">
          <p class="text-eonx-neutral-600">
            If you leave this page without finishing this process your progress
            won’t be saved
          </p>
        </div>

        <div class="mt-auto flex space-x-[15px] pt-6 pb-[15px]">
          <div class="w-full">
            <base-button
              look="outlined"
              full-width
              :size="ui.desktop ? null : 'lg'"
              @click="submit('leave-page')"
            >
              <span class="text-base">Leave page</span>
            </base-button>
          </div>
          <div class="w-full">
            <base-button
              full-width
              :size="ui.desktop ? null : 'lg'"
              @click="submit('stay-here')"
            >
              <span class="text-base">Stay here</span>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </base-mdl>
</template>
