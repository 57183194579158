<script setup>
import debounce from 'lodash-es/debounce'
import { ref, watch } from 'vue'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwCatalogSelect from '/~/components/ewallet/controls/ew-catalog-select.vue'
import EwCategories from '/~/components/ewallet/controls/ew-categories.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import EwSorting from '/~/components/ewallet/controls/ew-sorting.vue'

const {
  categories,
  searchQuery,
  offerTypes,
  selectedOfferTypes,
  selectedCategory,
  sortValues,
  selectedSorting,
  resetFilters,
  isFiltersSelected,
} = useCommunityHub()

const localSearchQuery = ref(searchQuery.value)
const searchFocused = ref(false)

watch(searchQuery, (value) => {
  // NOTE: do not update local search value if input have focus
  if (!searchFocused.value) {
    localSearchQuery.value = value
  }
})

const onSearchInput = debounce(function (value) {
  searchQuery.value = value
}, 350)

function onSearchBlur() {
  searchFocused.value = false
  searchQuery.value = localSearchQuery.value
}

function onSearchEnter() {
  searchQuery.value = localSearchQuery.value
}
</script>

<template>
  <div class="flex flex-col items-center sm:flex-row sm:space-x-5">
    <div class="w-full">
      <base-input
        v-model="localSearchQuery"
        placeholder="Search offers"
        label="Search offers"
        :icon-plain="true"
        look="rounded"
        clearable
        nolabel
        @input="onSearchInput"
        @focus="searchFocused = true"
        @blur="onSearchBlur"
        @keypress.enter="onSearchEnter"
      >
        <template #icon>
          <div class="ml-[15px] mr-2.5 flex items-center justify-center">
            <base-icon
              svg="plain/search-v2"
              class="text-eonx-neutral-600"
              :size="19"
            />
          </div>
        </template>
      </base-input>
    </div>

    <div class="flex w-full space-x-5 pt-5 sm:w-auto sm:p-0">
      <ew-categories
        :categories="categories"
        :selected-id="selectedCategory"
        :render-link="false"
        title="Categories"
        class="grow sm:grow"
        @change="selectedCategory = $event"
      />

      <ew-filters
        ref="filters"
        title="Filters"
        :is-filters-selected="isFiltersSelected"
        @clear="resetFilters"
      >
        <ew-sorting
          v-model="selectedSorting"
          title="Sorting by"
          :sorting="sortValues"
        />

        <ew-catalog-select
          v-model="selectedOfferTypes"
          :filters="offerTypes"
          :loading="!offerTypes"
          title="Offer type"
        />
      </ew-filters>
    </div>
  </div>
</template>
