<script setup lang="ts">
import { useVerifications } from '/~/extensions/otp/composables'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import BaseToggle from '/~/components/base/toggle/base-toggle.vue'
import { useLocalization } from '/~/composables/localization'
import {
  useStatementsListItem,
  type StatementAccount,
  type Statement,
} from '/~/composables/statements'
import ItemDate from '/~/templates/bill-payments/views/payments/make/components/select-statement/content/item/date.vue'
import ItemNotification from '/~/templates/bill-payments/views/payments/make/components/select-statement/content/item/notification.vue'

const props = withDefaults(
  defineProps<{
    item?: Statement | null
    account?: StatementAccount | null
  }>(),
  {
    item: null,
    account: null,
  }
)

const { withVerificationsCheck } = useVerifications()
const { translate } = useLocalization()

const {
  title,
  subtotal,
  menuItems,
  clientName,
  clientNumber,
  statementAccount,

  isBlocked,
  isDirectDebitDisabled,
  isAutoPayToggled,
  isToggleBlocked,
  isPaymentInitializing,

  changeToggle,
  onPay,
} = useStatementsListItem(props)
</script>
<template>
  <div class="mb-5 flex flex-col rounded-xl bg-white p-[15px] text-neutral-400">
    <div class="mb-5 flex flex-col justify-between space-y-[15px]">
      <div class="flex items-center justify-between">
        <h3 class="text-lg leading-6 text-neutral-800">
          <span class="block">{{ clientName }}</span>
          <span class="block">{{ clientNumber }}</span>
        </h3>
        <base-menu :items="menuItems" position="right">
          <template #trigger>
            <base-button
              icon="plain/more"
              title="Address"
              alt="More"
              class="flex self-center text-eonx-neutral-800"
            />
          </template>
        </base-menu>
      </div>
      <div
        class="flex justify-between border-y py-[15px]"
        :class="[
          { 'text-neutral-800': !isDirectDebitDisabled },
          { 'text-neutral-400': isDirectDebitDisabled },
        ]"
      >
        <span>{{ translate('directDebit.text') }}</span>
        <base-toggle
          v-model="isAutoPayToggled"
          :disabled="isDirectDebitDisabled || isToggleBlocked"
          @change="changeToggle"
        />
      </div>
    </div>
    <div class="flex items-center space-x-2.5">
      <payee-icon :payee="item?.payee" />
      <h4 class="text-base font-bold text-neutral-800" :title="title">
        {{ title }}
      </h4>
    </div>
    <item-date :statement="item" />
    <div class="flex items-center justify-between">
      <p class="text-sm leading-none text-eonx-neutral-600">Amount</p>
      <p class="text-neutral-800">
        {{ subtotal }}
      </p>
    </div>
    <base-button
      class="mt-4"
      look="outlined-color"
      size="sm"
      rounded
      full-width
      :loading="isPaymentInitializing"
      :disabled="isBlocked"
      @click="withVerificationsCheck(onPay)"
    >
      Pay now
    </base-button>
    <item-notification :account="statementAccount" class="mt-5" />
  </div>
</template>
