<script setup lang="ts">
import { computed, onMounted } from 'vue'
import type { Payee } from '/~/types/api/payee'
import cdn from '/~/utils/cdn'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useProvider } from '/~/composables/provider'

const props = withDefaults(
  defineProps<{
    payee?: Payee
    size?: number | 'xs' | 'sm' | 'md' | 'lg' | 'xsm' | 'xxs'
  }>(),
  {
    size: 'md',
  }
)

const { providerSquareLogo } = useProvider()

const sizeClass = computed(() => {
  if (typeof props.size === 'number') {
    return `h-${props.size} w-${props.size}`
  }

  const sizeMap = {
    xs: 'h-6 w-6',
    xxs: 'h-8 w-8',
    sm: 'h-[35px] w-[35px]',
    xsm: 'h-10 w-10',
    md: 'h-12 w-12',
    lg: 'h-20 w-20',
  }

  return sizeMap[props.size]
})

const paddingClass = computed(() => {
  if (typeof props.size === 'number') {
    return props.size < 12 ? 'p-1' : 'p-2'
  }
  return props.size === 'sm' ? 'p-1' : 'p-2'
})

const bgClass = computed(() => {
  return props.payee?.type === 'bpay' ? 'bg-brand-bpay' : 'bg-eonx-neutral-600'
})

const payeeLogo = computed(() => cdn(props.payee?.logo?.resource ?? '').url())
const payeeIcon = computed(() => {
  if (!props.payee?.type) {
    return
  }
  const typeMap = {
    bpay: 'symbion/bpay',
    ewallet: 'ewallet',
    bankAccount: 'v2/custom/bank',
  } as Record<string, string>

  return typeMap[props.payee?.type] ?? 'menu/label'
})

const fallbackProviderLogo = computed(() =>
  cdn(providerSquareLogo.value ?? '').url()
)

onMounted(() => {
  if (!providerSquareLogo.value) {
    console.error(
      'providerSquareLogo is not set. Please set a providerSquareLogo for the provider.'
    )
  }
})
</script>

<template>
  <div class="shrink-0">
    <img
      v-if="payeeLogo || (fallbackProviderLogo && !payeeIcon)"
      :src="payeeLogo || fallbackProviderLogo"
      :class="[sizeClass]"
      alt="Payee Logo"
    />
    <div
      v-else
      class="flex shrink-0 items-center justify-center rounded-full text-white"
      :class="[sizeClass, bgClass]"
    >
      <base-icon
        :svg="payeeIcon"
        size="auto"
        :class="['block', paddingClass]"
      />
    </div>
  </div>
</template>
