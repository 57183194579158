import api from '/~rec/core/api'
import get from 'lodash-es/get'

export class RecroomOrganization {
  constructor(raw) {
    this.raw = raw || {}
  }

  get id() {
    return get(this.raw, 'id')
  }

  get defaultGroupId() {
    return get(this.raw, 'default_group_id')
  }

  get currency() {
    return get(this.raw, 'currency')
  }

  get enabledFeatures() {
    return get(this.raw, 'enabled_features', [])
  }

  get sidebarBannerImage() {
    return get(this.raw, 'sidebar_banners_image', null)
  }

  static async fetchById(id) {
    const response = await api.get(`/organisations/${id}`)

    return new RecroomOrganization(response.data)
  }
}
