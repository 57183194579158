<script>
import get from 'lodash-es/get'
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'qr-pin-popup',
  components: {
    BaseButton,
  },
  props: {
    pin: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    trigger: {
      type: Object,
      required: true,
    },
    reserveWorkspace: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      width: 0,
      height: 0,
      gutter: {
        body: 30,
        arrowX: 40,
        arrowY: 8,
      },
    }
  },
  computed: {
    posX() {
      const { width, trigger, map } = this

      if (width < map.width - trigger.left - trigger.width) {
        return 'right'
      } else if (width < trigger.left - trigger.width) {
        return 'left'
      }

      return 'center'
    },
    posY() {
      const { height, trigger, map } = this

      if (height < trigger.top - trigger.height) {
        return 'top'
      } else if (height < map.height - trigger.top) {
        return 'bottom'
      }

      return 'center'
    },
    offsetLeft() {
      const { posX, posY, width, trigger } = this

      if (posX === 'center') {
        return -(width / 2 - trigger.width / 2)
      } else if (posX === 'right') {
        return posY === 'center' ? trigger.width : -this.gutter.body
      }

      return 0
    },
    offsetTop() {
      const { posY, height, trigger } = this

      if (posY === 'center') {
        return -(height / 2 - trigger.height / 2)
      } else if (posY === 'bottom') {
        return trigger.height
      }

      return 0
    },
    offsetRight() {
      const { posX, posY, trigger } = this

      if (posX === 'left') {
        return posY === 'center' ? trigger.width : -this.gutter.body
      }

      return 0
    },
    offsetBottom() {
      return this.posY === 'top' ? this.trigger.height : 0
    },
    arrowLeft() {
      if (this.posX === 'right') return this.gutter.arrowX
      return 0
    },
    arrowTop() {
      if (this.posY === 'bottom') return this.gutter.arrowY
      return 0
    },
    arrowBottom() {
      if (this.posY === 'top') return this.gutter.arrowY
      return 0
    },
    arrowRight() {
      if (this.posX === 'left') return this.gutter.arrowX
      return 0
    },
    location() {
      const building = get(this.reserveWorkspace, 'building.name', '')
      const level = get(this.reserveWorkspace, 'level.name')

      return level + (level && building ? ', ' : '') + building
    },
    style() {
      return {
        top: this.offsetTop ? `${this.offsetTop}px` : null,
        left: this.offsetLeft ? `${this.offsetLeft}px` : null,
        bottom: this.offsetBottom ? `${this.offsetBottom}px` : null,
        right: this.offsetRight ? `${this.offsetRight}px` : null,
      }
    },
  },
  mounted() {
    this.width = this.$el.clientWidth
    this.height = this.$el.clientHeight
  },
  methods: {
    onClick() {
      this.$emit('select-asset')
    },
    close() {
      this.$emit('close-popup')
    },
  },
}
</script>

<template>
  <div
    class="pointer-events-none absolute p-[15px]"
    :class="[
      posY === 'top' && 'bottom-0',
      posY === 'bottom' && 'top-0',
      posX === 'left' && 'right-0',
      posX === 'right' && 'left-0',
    ]"
    :style="style"
  >
    <div
      class="pointer-events-auto relative rounded-lg border bg-white p-[15px] shadow-md"
    >
      <div class="mb-[5px] font-bold">
        {{ pin.name }}
      </div>
      <div class="whitespace-nowrap text-sm text-neutral-400">
        {{ location }}
      </div>
      <base-button full-width class="mt-2.5" size="sm" @click="onClick">
        Select
      </base-button>
    </div>
    <div
      class="absolute h-[15px] w-[15px] transform bg-white"
      :style="{
        left: arrowLeft ? `${arrowLeft}px` : null,
        right: arrowRight ? `${arrowRight}px` : null,
        top: arrowTop ? `${arrowTop}px` : null,
        bottom: arrowBottom ? `${arrowBottom}px` : null,
      }"
      :class="[
        posY === 'top' && 'rotate-45 border-r border-b',
        posY === 'bottom' && 'rotate-45 border-l border-t',
        posY === 'center' && 'hidden',
      ]"
    />
  </div>
</template>
