<script setup lang="ts">
import BaseButton from '/~/components/base/button/base-button'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useAction } from '/~/composables/base/use-action'

const props = withDefaults(
  defineProps<{
    enabled?: boolean
    label?: string | null
    ariaHaspopup?: string
    type?: 'page' | 'action' | 'link' | 'video' | 'none'
    origin?: 'banner' | 'menu' | 'none'
    color?: 'primary' | 'secondary' | 'none'
    look?: 'outlined' | 'filled' | 'link' | 'button' | 'banner'
    value?: string
    query?: object
    params?: object
    clickOnly?: boolean
    appearance?: 'link' | 'button'
    isRestricted?: boolean
    classes?: string | string[]
  }>(),
  {
    enabled: true,
    label: 'Action',
    ariaHaspopup: 'false',
    origin: 'none',
    color: 'primary',
    look: 'filled',
    value: '',
    query: () => ({}),
    params: () => ({}),
    clickOnly: false,
    appearance: 'link',
    isRestricted: false,
    classes: '',
  }
)

const emit = defineEmits<{
  (event: 'collapse'): void
  (event: 'click', payload: Event): void
}>()

const { isLink, bindings, handleAction } = useAction(props, emit)

function onClick(e: Event) {
  handleAction()
  emit('click', e)
}
</script>

<template>
  <base-link
    v-if="isLink && enabled"
    v-bind="bindings"
    class="base-action relative cursor-pointer"
    :color="color"
    :data-cy="`base-action-${value ? value : label}`"
    :data-test="`base-action-${type}`"
    :aria-label="label"
    @click="onClick"
  >
    <slot name="entry">
      <base-button
        v-if="appearance === 'button'"
        class="base-action__button"
        :classes="classes"
        :color="color"
        :look="look"
      >
        <slot>
          {{ label }}
        </slot>
      </base-button>
      <slot v-else>
        {{ label }}
      </slot>
    </slot>
  </base-link>
  <button
    v-else-if="enabled"
    v-bind="bindings"
    type="button"
    class="base-action relative"
    :class="{
      'pointer-events-none cursor-not-allowed opacity-50': isRestricted,
      'cursor-pointer': !isRestricted,
    }"
    :data-cy="`base-action-${value ? value : label}`"
    :data-test="`base-action-${type}`"
    :aria-haspopup="ariaHaspopup"
    :aria-label="label"
    :disabled="isRestricted"
    @click="onClick"
  >
    <slot name="entry">
      <base-button
        v-if="appearance === 'button'"
        class="base-action__button"
        :classes="classes"
        :color="color"
        :look="look"
      >
        <slot>
          {{ label }}
        </slot>
      </base-button>
      <slot v-else>
        {{ label }}
      </slot>
    </slot>
  </button>
</template>
