export class UploadcareDriver {
  options

  constructor(options) {
    this.options = options ?? {
      method: 'POST',
      url: 'https://upload.uploadcare.com/base/',
      body: {
        UPLOADCARE_PUB_KEY: 'demopublickey',
        UPLOADCARE_STORE: '1',
      },
    }
  }

  async upload(uploadableFile) {
    const body = new FormData()

    for (const key in this.options.body ?? {}) {
      body.append(key, this.options.body[key])
    }
    body.append('file', uploadableFile.file)

    uploadableFile.uploading = true

    const response = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      uploadableFile.cancel = () => xhr.abort()

      xhr.open(this.options.method ?? 'POST', this.options.url)

      for (const k in this.options.headers ?? {}) {
        xhr.setRequestHeader(k, this.options.headers[k])
      }

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText))
        } else {
          uploadableFile.uploading = false
          uploadableFile.error = `There was an error uploading ${uploadableFile.file.name} file to server: ${xhr.responseText}`
          reject(new Error(uploadableFile.error))
        }
      }

      xhr.onerror = () => {
        uploadableFile.uploading = false
        uploadableFile.error = `There was a network error uploading ${uploadableFile.file.name} file to server`
        reject(new Error(uploadableFile.error))
      }

      if (xhr.upload) {
        xhr.upload.onprogress = (event) => {
          uploadableFile.loaded = event.loaded
          uploadableFile.loadedPercents = Math.floor(
            (event.loaded * 100) / event.total
          )
          uploadableFile.total = event.total
        }
      }

      xhr.send(body)
    })

    uploadableFile.uploading = false
    uploadableFile.uploaded = true
    uploadableFile.id = response.file
  }
}

export default UploadcareDriver
