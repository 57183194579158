<script>
import DetailsView from '/~/extensions/health/components/details/details-view.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import HealthEbitsFeedList from './health-ebits-feed-list.vue'
import { useRecipes } from '/~/extensions/health/composables'

export default {
  name: 'health-ebits-detail',
  components: {
    DetailsView,
    BasePlaceholder,
    HealthEbitsFeedList,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    const { feed, queryState } = useRecipes()

    return {
      feed,
      queryState,
    }
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {
    type() {
      return 'recipes'
    },
    backRoute() {
      return {
        name: 'hlth-health-ebits-feed',
        query: this.queryState,
      }
    },
  },
  watch: {
    id() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.data = null
      const response = await this.feed.getDataByID({
        id: this.id,
        index: this.feed.params.index,
      })

      this.data = {
        title: response['Recipe Title'],
        banner: response['Banner Image'],
        text: this.parseText(response['Recipe Text']),
        category: response.Category,
      }

      await this.feed.getData({
        search: {
          filters: [`NOT objectID:${this.id}`, `Category:${this.data.category}`]
            .filter((i) => !!i)
            .join(' AND '),
        },
      })
    },
    parseText(data) {
      const headings = ['Ingredients', 'Directions']
      let text = JSON.parse(data)
      const taggedIndex = text.findIndex(({ text }) =>
        text.startsWith('Tagged:')
      )

      text = text.slice(0, taggedIndex)

      const createTag = (name, content, attrs) => {
        const inlineAttrs =
          attrs &&
          ' ' +
            Object.entries(attrs)
              .map(([key, value]) => `${key}="${value}"`)
              .join(' ')

        return `<${name}${inlineAttrs}>${content}</${name}>`
      }

      return text.reduce((html, part) => {
        if (headings.includes(part.text)) {
          html += createTag('h3', part.text, { class: 'my-5' })
        } else html += createTag('p', part.text)

        return html
      }, '')
    },
  },
}
</script>

<template>
  <div class="mb-12">
    <details-view v-if="data" :data="data" :back-route="backRoute" />
    <div v-else class="mx-auto w-full max-w-5xl p-5">
      <base-placeholder />
    </div>
    <div v-if="data && feed.hits.length" class="mx-auto max-w-3xl px-5">
      <h1 class="mt-[30px] border-t pt-[30px] text-2xl text-eonx-neutral-800">
        Related Recipes
      </h1>
      <health-ebits-feed-list
        :feed="feed"
        :type="type"
        :limit="3"
        :columns="{
          xs: 2,
          sm: 3,
        }"
      />
    </div>
  </div>
</template>
