<script>
import { mixin as clickaway } from 'vue-clickaway'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useUI } from '/~/composables/ui'

export default {
  name: 'base-fab',
  components: {
    BaseIcon,
    BaseLoader,
  },
  mixins: [clickaway],
  props: {
    icon: {
      type: String,
      default: 'plus',
    },
    actions: {
      type: Array,
      default: () => [],
    },
    margin: {
      type: String,
      default: 'mb-[15px] mr-[15px]',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup() {
    const { isStandalone } = useUI()

    return {
      ...clickaway.setup?.(...arguments),
      isStandalone,
    }
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    hasActions() {
      return this.actions.length > 0
    },
  },
  methods: {
    onClickAway() {
      this.expanded = false
    },
    onActionClick(action) {
      if (action.action) {
        action.action()
      }
      this.expanded = false
    },
    onClick($event) {
      if (this.hasActions) {
        this.expanded = !this.expanded
      }
      this.$emit('click', $event)
    },
  },
}
</script>

<template>
  <div
    v-on-clickaway="onClickAway"
    class="fixed bottom-0 right-0 z-popup"
    :class="{
      'footer-extra': isStandalone,
      [margin]: true,
    }"
  >
    <transition
      enter-class="translate-y-2.5 opacity-0"
      leave-to-class="translate-y-2.5 opacity-0"
    >
      <ul
        v-if="expanded"
        class="z-10 flex transform flex-col items-center transition duration-200 ease-out"
      >
        <li
          v-for="(action, idx) in actions"
          :key="idx"
          class="mb-2.5 flex min-h-14 min-w-14 cursor-pointer items-center justify-center rounded-full bg-white text-primary shadow-lg"
          @click.stop="onActionClick(action)"
        >
          <slot name="action" :action="action">
            <base-icon :svg="action.icon" size="xl" />
          </slot>
        </li>
      </ul>
    </transition>
    <button
      class="ml-auto flex h-12 w-12 items-center justify-center rounded-full shadow-lg disabled:pointer-events-none disabled:cursor-default"
      :class="{
        'bg-white text-primary': expanded,
        'bg-primary text-primary-content': !expanded && !disabled && !loading,
        'bg-disabled opacity-75 shadow-none': disabled || loading,
      }"
      :disabled="disabled || loading"
      @click="onClick"
    >
      <slot name="trigger">
        <base-icon v-if="expanded" svg="close" size="lg" />
        <base-icon v-if="!loading && !expanded" :svg="icon" size="lg" />
        <span
          v-if="loading"
          ref="loader"
          class="absolute inset-0 z-10 flex h-full w-full items-center justify-center"
        >
          <base-loader ref="the-loader" size="xs" color="white" />
        </span>
      </slot>
    </button>
  </div>
</template>
