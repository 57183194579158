<script setup lang="ts">
import { computed } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useProvider } from '/~/composables/provider'
import AuthLayout from '/~/layouts/auth/layout-auth.vue'
import AuthLogo from '/~/views/auth/auth-logo.vue'

const { customerServiceNumber } = useProvider()

const formattedPhoneNumber = computed(() =>
  customerServiceNumber.value.replaceAll(' ', '')
)
</script>

<template>
  <auth-layout
    image="//i.ewalletcdn.com/b4e5cdf3-7950-4923-bea5-b535ca7d928f/"
    class="auth-register"
  >
    <auth-logo />
    <div
      v-analytics:mount="{
        pageGroup: 'Auth',
        page: 'Register',
        label: 'Failed register screen displayed',
      }"
      class="mx-auto mt-auto flex max-w-2xl flex-col items-center px-[30px] pb-20"
    >
      <div
        class="mt-5 flex h-20 w-20 items-center justify-center rounded-full bg-warning-700 p-[5px] text-white"
      >
        <base-icon size="2xl" svg="symbion/activity-status/warning" />
      </div>
      <p class="my-5 text-2xl font-bold">Account not found</p>
      <p>
        <span>
          We couldn’t validate your account with the information provided.
          Please reach out to our support team on
        </span>
        <a :href="`tel:${formattedPhoneNumber}`">
          {{ customerServiceNumber }}
        </a>
        or
        <base-link :to="{ name: 'auth-register' }">try again</base-link>
        .
      </p>
    </div>
  </auth-layout>
</template>
