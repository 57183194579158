<script>
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import modal from '/~/core/mdl'

export default {
  name: 'rec-event-header-mobile',
  components: {
    BaseIcon,
    HeaderMobile,
    ButtonIcon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    actions() {
      return [
        {
          icon: 'rec/round/edit',
          title: 'Edit Event',
          text: 'Place them in the running to win',
          action: () => {
            this.$router.push({
              name: 'rec-event-edit',
              params: { initialEvent: this.event },
            })
          },
          hidden: !this.event.isMeOwner,
        },
        {
          icon: 'rec/round/delete',
          title: 'Delete Event',
          text: 'Show your instant appreciation',
          action: async () => {
            modal.show('rec-event-delete', {
              props: {
                event: this.event,
              },
              on: {
                success: async () => {
                  await this.events.getData(this.event.startsAt)
                  modal.hideAll()
                  this.$router.back()
                },
              },
            })
          },
          hidden: !this.event.isMeOwner,
        },
      ].filter((i) => !i.hidden)
    },
  },
  methods: {
    onBack() {
      modal.hide()
      this.$emit('back')
    },
    openInfoModal() {
      modal.show('rec-event-info-mobile', {
        props: {
          event: this.event,
        },
      })
    },
  },
}
</script>

<template>
  <header-mobile
    :image="event.image"
    :title="event.name"
    @back="onBack"
    @img-click="openInfoModal"
    @title-click="openInfoModal"
  >
    <template #icons>
      <div class="ml-2.5 text-eonx-neutral-800">
        <base-icon v-if="event.isPrivate" svg="rec/lock-solid" size="sme" />
        <base-icon v-else svg="rec/planet" size="sme" />
      </div>
    </template>

    <template #right>
      <button-icon
        class="cursor-pointer"
        icon="rec/info-blue"
        size="xl"
        @click="openInfoModal"
      />
    </template>
  </header-mobile>
</template>
