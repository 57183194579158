<script setup lang="ts">
import { computed, ref, watch, Ref } from 'vue'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import { ProviderDefaultUrlMap } from '/~/utils/cdn'
import BaseLink from '/~/components/base/link/base-link.vue'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import { useProvider } from '/~/composables/provider'
import RetailerLogo from './logo/community-hub-logo.vue'
import CommunityHubOffers from './offers/community-hub-offers.vue'

type Offer = {
  [key: string]: unknown
  type: string
}

const props = withDefaults(
  defineProps<{
    id?: string | null
  }>(),
  {
    id: null,
  }
)

const {
  selectedOfferTypes,
  routeState,
  moduleName,
  getDetails,
}: Omit<ReturnType<typeof useCommunityHub>, 'selectedOfferTypes'> & {
  selectedOfferTypes: Ref<unknown[] | null>
} = useCommunityHub()
const { isBupaTemplate } = useProvider()

const retailer = ref<Record<string, any> | null>(null)
const offers = ref<Offer[]>([])
const loading = ref(true)

const retailerName = computed(() => retailer.value?.name)

const logo = computed(() => {
  const defaultImage = isBupaTemplate.value ? ProviderDefaultUrlMap.bupa : ''

  return {
    image: retailer.value?.logo || defaultImage,
    alt: retailer.value?.name,
  }
})

const filteredOffers = computed(() => {
  if (selectedOfferTypes.value && selectedOfferTypes.value.length > 0) {
    return offers.value.filter(
      (o) =>
        selectedOfferTypes.value &&
        selectedOfferTypes.value.indexOf(o.type) !== -1
    )
  }

  return offers.value
})

watch(
  () => props.id,
  async () => {
    loading.value = true

    const response = await getDetails(props.id)

    if (response) {
      const { retailer: data, offers: offersData } = response

      retailer.value = data
      offers.value = offersData
    } else {
      retailer.value = null
      offers.value = []
    }

    loading.value = false
  },
  { immediate: true }
)

const backRoute = computed(() => ({
  name: moduleName,
  ...routeState.value,
}))
</script>

<template>
  <ew-details-layout
    :placeholder-ratio="1"
    :loading="loading"
    :ready="!loading"
    :title="retailerName"
    :back="backRoute"
  >
    <template #gallery>
      <retailer-logo v-if="retailer" v-bind="logo" />
    </template>

    <template #summary>
      <div v-if="retailer">
        <community-hub-offers
          :retailer="retailer"
          :offers="filteredOffers"
          class="mb-10"
        />
      </div>
    </template>

    <template #details>
      <div
        v-if="retailer"
        class="hidden overflow-hidden truncate text-primary hover:text-primary-darken sm:block"
      >
        <base-link
          v-if="retailer.website"
          :href="retailer.website"
          class="mx-1 font-bold leading-8 !underline underline-offset-4"
          look=""
        >
          {{ retailer.website.replace(/^https?:\/\/|\/$/g, '') }}
        </base-link>
      </div>
    </template>
  </ew-details-layout>
</template>
