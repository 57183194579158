<script>
import BaseButton from '/~/components/base/button/base-button'
import PaymentsSplitForm from '/~/components/payments/split/payments-split-form.vue'

export default {
  name: 'payments-split-bottom-sheet',
  components: {
    BaseButton,
    PaymentsSplitForm,
  },
  props: {
    currentItem: {
      type: Object,
      required: true,
    },
    selectedPoints: {
      type: Object,
      default: null,
    },
    burnPointsRate: {
      type: Number,
      default: 1,
    },
    selectedEwallet: {
      type: Object,
      default: null,
    },
    selectedCard: {
      type: Object,
      default: null,
    },
    selectedBankAccount: {
      type: Object,
      default: null,
    },
    selectedAmount: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    function cancel() {
      props.onCancel?.()
      emit('hide')
    }

    function submit(result) {
      props.onConfirm?.(result)
      emit('hide')
    }

    return {
      cancel,
      submit,
    }
  },
}
</script>

<template>
  <div class="relative px-5 py-[30px]">
    <div class="absolute right-0 px-5">
      <base-button icon="close" class="cursor-pointer" @click="cancel" />
    </div>
    <payments-split-form
      :current-item="currentItem"
      :selected-points="selectedPoints"
      :burn-points-rate="burnPointsRate"
      :selected-ewallet="selectedEwallet"
      :selected-card="selectedCard"
      :selected-bank-account="selectedBankAccount"
      :selected-amount="selectedAmount"
      :total="total"
      @confirm="submit"
      @discard="cancel"
    />
  </div>
</template>
