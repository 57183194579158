<script>
import get from 'lodash-es/get'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import NewAccountForm from '../../components/new-account-form.vue'

export default {
  name: 'fly-partner-add-new-mobile',
  components: {
    NewAccountForm,
    BaseImage,
    BaseLink,
    BaseAsidePage,
  },
  props: {
    partner: {
      type: Object,
      default: null,
    },
    freeLink: {
      type: String,
      default: '',
    },
  },
  computed: {
    icon() {
      return get(this.partner, 'icon', '')
    },
    name() {
      return get(this.partner, 'name', '')
    },
    type() {
      return this.$route.query.type
    },
  },
}
</script>

<template>
  <base-aside-page :title="name" shadow no-padding>
    <div class="flex flex-col items-center bg-eonx-neutral-50 p-5">
      <div class="my-8 h-14 text-orange-700">
        <base-image :src="icon" class="h-full" :ratio="false" />
      </div>
      <h3
        class="mt-6 mb-5 text-center text-2xl font-bold leading-tight text-eonx-neutral-800"
      >
        {{ name }}
      </h3>
      <div
        class="mt-8 space-y-4 leading-6 text-eonx-neutral-600"
        v-html="partner.addNewDescription"
      />
      <new-account-form class="my-8" @success="$emit('success', $event)" />
      <p v-if="freeLink" class="self-start text-eonx-neutral-600">
        Not a {{ name }} member?
        <br />
        <base-link :href="freeLink" look="underline" class="-ml-px font-bold">
          Join free today
        </base-link>
        (Saving $89.50)
      </p>
    </div>
  </base-aside-page>
</template>
