<script setup lang="ts">
import { computed } from 'vue'
import ui from '/~/core/ui'
import { type Content, type Tile } from '/~/composables/cms/use-cms'
import {
  useWrapper,
  GRID_CLASSES,
  type Row,
  type Wrapper,
} from '/~/configurator/composables/wrapper'
import WidgetTilesContent from './widget-bupa-discounts-tiles-content.vue'

const props = withDefaults(
  defineProps<{
    row?: Row
    wrapper?: Wrapper
    items: Tile[]
    content: Content
    enableWidgetTitle?: boolean
    titleColor?: string
  }>(),
  {
    enableWidgetTitle: false,
    titleColor: '#000000',
  }
)

const { columnCounter, getTileKey } = useWrapper(props)

const screenSize = computed(() => {
  if (ui.mobile) {
    return 'xs'
  } else if (ui.tablet) {
    return 'sm'
  } else {
    return 'md'
  }
})

const columns = computed(() => columnCounter.value[screenSize.value])

const title = computed(() =>
  props.content.title.replace(
    /<\s*([a-zA-Z0-9]+)(.*?)>/,
    '<$1 class="text-xl font-bold leading-6">'
  )
)
</script>

<template>
  <section>
    <div
      v-if="enableWidgetTitle"
      class="mb-[30px] pt-2.5"
      :style="{
        textAlign: content.contentAlignmentX,
        color: titleColor,
      }"
      v-html="title"
    />
    <div class="grid gap-[15px]" :class="GRID_CLASSES[screenSize][columns]">
      <div
        v-for="(tile, idx) in items"
        :key="getTileKey(tile, idx)"
        class="w-full rounded bg-light shadow"
      >
        <widget-tiles-content :tile="tile" :columns="columns" />
      </div>
    </div>
  </section>
</template>
