<script>
import modal from '/~/core/mdl'
import { useVerifications } from '/~/extensions/otp/composables'
import { createDate } from '/~/utils/format/date'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'

export default {
  name: 'online-offer-sinlge-additional',
  components: {
    BaseLink,
    BaseIcon,
  },
  props: {
    merchant: {
      type: Object,
      default: () => ({}),
    },
    offer: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { hasUnfinishedVerifications, showVerificationModal } =
      useVerifications()

    return {
      hasUnfinishedVerifications,
      showVerificationModal,
    }
  },
  data() {
    return {
      copied: null,
      lessThanDay: false,
    }
  },
  computed: {
    externalId() {
      return this.merchant.external_id
    },
  },
  methods: {
    expiry(value) {
      const date = value?.date

      if (date) {
        const endDate = createDate(date)
        const currentDate = createDate()
        const countOfDays = endDate.diff(currentDate, 'day')

        if (countOfDays > 0) {
          this.lessThanDay = false
          return `Offer ends in ${countOfDays} days`
        }

        const countOfHours = endDate.diff(currentDate, 'hour')

        if (countOfHours > 0) {
          this.lessThanDay = true
          return `Offer ends in ${countOfHours} hours`
        }
      }
    },
    currentlyCopied(offerId) {
      return this.copied === offerId
    },
    saveCodeToClipboard(code) {
      const el = document.createElement('textarea')

      el.value = code
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.copied = true

      this.$notify({
        text: `Code ${code} copied!`,
        type: 'success',
        duration: 3000,
      })
    },
    showOfferTermsModal() {
      modal.show('offer-terms', {
        props: {
          offer: this.offer,
        },
      })
    },
    onClick(event) {
      if (this.hasUnfinishedVerifications) {
        event.preventDefault()
        this.showVerificationModal()
      } else {
        this.$router.push({
          name: 'online-offers-redirect',
          params: { offer: this.offer, merchant: this.merchant },
        })
      }
    },
  },
}
</script>

<template>
  <div v-if="offer" class="flex flex-col text-base md:flex-row">
    <div
      class="rounded-t-md bg-white p-6 md:w-3/4 md:rounded-l-md md:rounded-t-none"
    >
      <div
        v-if="offer.end_date"
        class="flex items-center text-sm"
        :class="[lessThanDay ? 'text-error-700' : 'text-eonx-neutral-600']"
      >
        <base-icon svg="plain/calendar2" size="sm" class="mr-[5px]" />

        <div>{{ expiry(offer.end_date) }}</div>
      </div>
      <div v-if="offer.description" class="mt-2.5 font-bold">
        {{ offer.description }}
      </div>
      <div
        v-if="offer.type === 'code' && offer.code"
        class="font-bold text-eonx-neutral-600"
      >
        <div class="mt-[15px] mb-2.5 text-sm">Coupon code</div>
        <div class="flex h-12 rounded-md bg-gray-50 sm:max-w-64">
          <div
            class="mx-[15px] my-auto flex grow items-center justify-center sm:w-full"
          >
            {{ offer.code }}
          </div>
          <div class="my-2.5 border-l-3 border-primary" />
          <div
            v-if="copied"
            class="flex min-w-24 cursor-pointer items-center justify-center text-sm text-success-700"
          >
            Copied!
          </div>
          <div
            v-else
            class="flex min-w-24 cursor-pointer items-center justify-center text-sm text-primary"
            @click="saveCodeToClipboard(offer.code)"
          >
            Copy
          </div>

          <div v-if="currentlyCopied(offer.external_id) && showNotification">
            <span>
              {{ offer.code }}
            </span>
            <span>Copied!</span>
          </div>
        </div>
      </div>
      <div v-if="offer.terms" class="flex items-center pt-[15px] text-primary">
        <div class="cursor-pointer" @click="showOfferTermsModal">
          <span class="mr-[5px] underline">See offer Terms & Conditions</span>
          <base-icon svg="plain/chevron-right-bold" size="xxs" />
        </div>
      </div>
    </div>
    <div
      class="flex min-h-12 rounded-b-md bg-blue-200 md:w-1/4 md:rounded-r-md md:rounded-b-none"
    >
      <base-link
        class="flex grow items-center justify-center font-bold"
        :to="{
          name: 'online-offers-redirect',
          params: { offer, merchant },
        }"
        @click.prevent="onClick"
      >
        Shop now
      </base-link>
    </div>
  </div>
</template>
