<script setup lang="ts">
import { Dayjs } from 'dayjs/esm'
import { computed } from 'vue'
import { ProgramFees } from '/~/types/api'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { BatchOrder } from '/~/composables/batch-order'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useVerifyCard } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { Statement } from '/~/composables/statements'
import Actions from '/~/templates/bill-payments-v1_5/views/payments/make/components/summary/actions.vue'
import Fees from '/~/templates/bill-payments-v1_5/views/payments/make/components/summary/fees.vue'
import PayingFrom from '/~/templates/bill-payments-v1_5/views/payments/make/components/summary/paying-from.vue'
import PayingTo from '/~/templates/bill-payments-v1_5/views/payments/make/components/summary/paying-to.vue'
import Points from '/~/templates/bill-payments-v1_5/views/payments/make/components/summary/points.vue'
import Scheduled from '/~/templates/bill-payments-v1_5/views/payments/make/components/summary/scheduled.vue'
import Total from '/~/templates/bill-payments-v1_5/views/payments/make/components/summary/total.vue'

export type TSummary = {
  scheduledDate: Dayjs | undefined
  payingTo: {
    amount: string
    batchOrder: BatchOrder
    statement: Statement
    payee: Record<string, any>
    providerTitle: string
    description?: string
    reference?: string
  }
  points: {
    membershipName: string
    balance: string
  }
  action: () => Promise<void>
  hide: () => void
  payingFrom: {
    method: Record<string, any>
    isPointsSelected: boolean
    isPayId: boolean
    calculatedAmount: number
    pointsToUse: number
  }
  flowType: FlowType
  programFeesData?: ProgramFees
  payByType:
    | PaymentMethodType.points
    | PaymentMethodType.creditCard
    | PaymentMethodType.bankAccount
  total: number
  processingFee?: number
  percentageFeeRate?: number
}

const props = defineProps<TSummary>()

const { verifyingCard } = useVerifyCard()
const { payment } = useCheckoutReactive()

const payFrom = computed(() => {
  if (props.payByType === PaymentMethodType.points) {
    const remaining =
      Number(props.payingTo.amount) - props.payingFrom.calculatedAmount

    return Math.max(remaining, 0)
  }

  return Number(props.payingTo.amount)
})

const processing = computed(() => {
  return verifyingCard.value || payment.value.submitting
})

const payingToType = computed(() => props.payingTo.payee?.type)
const payingFromMethodType = computed(() => props.payingFrom.method?.type)
const totalFees = computed(
  () => (props.programFeesData?.totalFees ?? 0) + (props.processingFee ?? 0)
)
const actionButtonLabel = computed(() => {
  return props.scheduledDate ? 'Schedule' : 'Pay'
})

const showFees = computed(() => totalFees.value > 0)
</script>

<template>
  <base-mdl
    title="Summary"
    :title-center="true"
    width="sme"
    :closable="false"
    layout="v2"
    header-border
    :show-close-icon="false"
    padding="sm"
    max-height="screen"
  >
    <div>
      <points v-if="points" :points="points" />
      <div class="divide-y">
        <scheduled v-if="scheduledDate" :date="scheduledDate" />
        <paying-to :paying-to="payingTo" />
        <fees
          v-if="showFees"
          :program-fees-data="programFeesData"
          :processing-fee="processingFee"
          :percentage-fee-rate="percentageFeeRate"
          :paying-to-type="payingToType"
          :paying-method-type="payingFromMethodType"
        />
        <total :total="total" />
        <paying-from
          :pay-from="payingFrom"
          :total-pay-from="payFrom"
          :total-fees="totalFees"
        />
        <actions
          :loading="processing"
          :action="action"
          :hide="hide"
          :action-button-label="actionButtonLabel"
        />
      </div>
    </div>
  </base-mdl>
</template>
