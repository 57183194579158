import { RecroomAward } from '/~rec/core'
import { RecroomUser } from './user'

export class RecroomNomination {
  constructor(rawData = {}) {
    this.raw = rawData

    this.award = new RecroomAward(rawData.award)

    if (rawData.nominator) {
      this.nominator = new RecroomUser(rawData.nominator.member)
    }

    if (rawData.nominee) {
      this.nominee = new RecroomUser(rawData.nominee.member)
    }
  }

  get nominationsSent() {
    return this.raw.nominations_sent
  }

  get nominationsReceived() {
    return this.raw.nominations_received
  }

  get rank() {
    return this.raw.rank
  }

  get createdAt() {
    return this.raw.created_at
  }

  get reason() {
    return this.raw.reason
  }
}
