<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import { RecroomGroup } from '/~rec/core/group'

export default {
  name: 'rec-group-root',
  props: {
    groupId: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  data() {
    return {
      group: null,
      loading: true,
    }
  },
  async created() {
    if (this.groupId && ui.desktop) {
      const group = await RecroomGroup.fetchById(this.groupId)

      await group?.members?.refresh()
      await group?.posts?.refresh()

      this.group = group
      this.loading = false
    } else {
      modal.show('rec-group-details', {
        props: { groupId: this.groupId },
      })
    }
  },
}
</script>

<template>
  <router-view class="hidden md:block" :group="group" :loading="loading" />
</template>
