import { Loader } from '@googlemaps/js-api-loader'
import { loadGoogleMaps } from '/~/boot/google-maps'
import { useProvider } from '/~/composables/provider'

export class GoogleAddressFinder {
  autocompleteService: any
  geocoderService: any
  sessionToken: any

  loader?: Loader
  geocodingLib?: google.maps.GeocodingLibrary
  placesLib?: google.maps.PlacesLibrary

  async initGeocoder() {
    if (this.geocoderService && this.autocompleteService) {
      return
    }

    this.loader = loadGoogleMaps()
    this.geocodingLib = await this.loader.importLibrary('geocoding')
    this.placesLib = await this.loader.importLibrary('places')

    if (this.placesLib) {
      this.sessionToken = new this.placesLib.AutocompleteSessionToken()
      this.autocompleteService = new this.placesLib.AutocompleteService()
    }
    if (this.geocodingLib) {
      this.geocoderService = new this.geocodingLib.Geocoder()
    }
  }

  async search(query: string): Promise<GoogleAddressFinder.SearchResult> {
    await this.initGeocoder()
    const { providerCountry } = useProvider()
    const { predictions } = await this.autocompleteService.getPlacePredictions({
      input: query,
      componentRestrictions: { country: providerCountry.value },
      types: ['street_address', 'route'],
      sessionToken: this.sessionToken,
    })

    return (predictions ?? []).map((prediction: any) => {
      return {
        id: prediction.place_id,
        fullAddress: prediction.description,
      }
    })
  }

  async getMetadata(
    id: string
  ): Promise<GoogleAddressFinder.GetMetadataResult> {
    await this.initGeocoder()

    const { results } = await this.geocoderService.geocode({ placeId: id })
    const result = results?.[0]

    if (!result) {
      throw new Error('Address details were not found')
    }

    const address = {
      id: result.place_id,
      fullAddress: result.formatted_address,
      address: '',
      suburb: '',
      state: '',
      postcode: '',
    }
    const components = result.address_components

    for (const component of components) {
      if (component.types.includes('route')) {
        const streetNumberComponent = components.find((c: any) =>
          c.types.includes('street_number')
        )

        address.address = streetNumberComponent
          ? `${streetNumberComponent?.long_name} ${component.long_name}`.trim()
          : component.long_name
      } else if (component.types.includes('locality')) {
        address.suburb = component.long_name
      } else if (component.types.includes('administrative_area_level_1')) {
        address.state = component.short_name
      } else if (component.types.includes('postal_code')) {
        address.postcode = component.long_name
      }
    }

    return address
  }
}

export default GoogleAddressFinder
