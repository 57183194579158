<script>
import BaseCard from '/~/components/base/card/base-card.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'loyalty-card',
  components: {
    BaseCard,
    BaseIcon,
    BaseImage,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  emits: ['click'],
}
</script>

<template>
  <base-card
    v-if="card.isCatalog"
    class="overflow-hidden rounded-xl"
    @click="$emit('click', $event, card)"
  >
    <base-image v-bind="card.image" :alt="card.name" />
  </base-card>
  <base-card
    v-else
    class="overflow-hidden rounded-xl"
    @click="$emit('click', $event, card)"
  >
    <div
      class="flex h-full w-full flex-col items-center justify-center text-white"
      :style="{
        backgroundColor: card.color,
      }"
    >
      <base-icon size="2xl" svg="circle/loyalty-cards" />
      <div class="mt-2.5 font-bold">
        {{ card.name }}
      </div>
    </div>
  </base-card>
</template>
