<script setup lang="ts">
import { computed } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.v2.vue'
import { useAuth } from '/~/composables/auth'
import { useCms } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'

const { logout } = useAuth()
const { ewalletLabels } = useCms()
const { isLogoutEnabled } = useProvider()

const actions = computed(() => [
  {
    label: 'How it works',
    description: 'Find the answers to your questions',
    icon: 'menu/question-block',
    route: {
      hash: '#profile-faq',
    },
  },
  {
    label: 'Help',
    description: 'Contact our support centre',
    icon: 'menu/i',
    route: {
      hash: '#profile-help',
    },
  },
  {
    label: 'Privacy Policy',
    description: 'Read up on our privacy and security',
    icon: 'menu/shield',
    route: {
      hash: '#profile-privacy-policy',
    },
  },
  {
    label: 'Terms & Conditions',
    description: 'Learn about the program and how it works',
    icon: 'menu/pen-n-paper',
    route: {
      hash: '#profile-toc',
    },
  },
])
const externalActions = computed(() => [
  {
    label: 'Logout',
    description: `Logout from ${ewalletLabels.value.yourEwallet}`,
    icon: 'menu/logout',
    hidden: !isLogoutEnabled.value,
    click: logout,
  },
])
</script>

<template>
  <base-aside-page title="Settings">
    <div class="space-y-10">
      <drawer-actions :actions="actions" />
      <drawer-actions :actions="externalActions" />
    </div>
  </base-aside-page>
</template>
