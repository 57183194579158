<script setup lang="ts">
import { Row, Wrapper, useWrapper } from '/~/configurator/composables/wrapper'

const props = defineProps<{
  row?: Row
  wrapper?: Wrapper
}>()

const { space } = useWrapper(props)
</script>

<template>
  <div
    class="flex w-full flex-col overflow-hidden bg-light"
    :class="{ 'rounded-md shadow': space }"
  >
    <slot />
  </div>
</template>
