<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { Activity } from '/~/composables/activity'
import { useBillPaymentsActivityListItem } from '/~/templates/bill-payments/composables/activity'

const props = defineProps<{ item: Activity }>()

const { canSeeDetails, icon, iconColor, subtitle, title } =
  useBillPaymentsActivityListItem(props)
</script>

<template>
  <base-link
    :to="{ name: 'activity-details', params: { id: item.id } }"
    :active="canSeeDetails"
    color="none"
    class="block w-full rounded-lg bg-white p-5"
    :class="{
      'text-neutral-400': !canSeeDetails,
    }"
  >
    <div class="flex w-full items-center justify-between space-x-6">
      <div class="h-12 w-12" :class="iconColor">
        <base-icon :svg="icon" :size="49" />
      </div>
      <div class="w-1/3 flex-1">
        <p class="truncate font-semibold text-eonx-neutral-800">
          {{ title }}
        </p>
        <p class="truncate text-sm">
          <span :class="[item.statusLabel.color]">
            {{ item.statusLabel.text }}
          </span>
          <span v-if="subtitle" class="ml-[5px] text-eonx-neutral-600">
            #{{ subtitle }}
          </span>
          <span v-if="item.orderNumber" class="ml-[5px] text-eonx-neutral-600">
            #{{ item.orderNumber }}
          </span>
          <span
            v-if="item.isTypeUserCommunication"
            class="text-sm text-eonx-neutral-600"
          >
            {{ subtitle }}
          </span>
        </p>
      </div>
      <div class="whitespace-nowrap text-right font-bold text-eonx-neutral-800">
        <base-icon
          v-if="canSeeDetails"
          svg="heroicons/outline/chevron-right"
          size="md"
        />
      </div>
    </div>
  </base-link>
</template>
