<script>
import { computed, ref, watch } from 'vue'
import cdn from '/~/utils/cdn'

export default {
  name: 'base-image',
  props: {
    ratio: {
      type: [Boolean, Number],
      default: 16 / 10,
    },
    src: {
      type: String,
      default: null,
    },
    defaultSrc: {
      type: String,
      default: 'no_image.jpg',
    },
    srcset: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    roundedSize: {
      type: String,
      default: 'lg',
      validator: (v) => /sm|normal|md|lg|xl|2xl|3xl|full/.test(v),
    },
    topRounded: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fit: {
      type: [Boolean, String],
      default: 'cover',
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const ready = ref(false)
    const isError = ref(false)

    const source = computed(() => {
      if (isError.value && props.defaultSrc) {
        return props.defaultSrc
      }

      return props.src
    })
    const isSVG = computed(() => /.svg$/.test(source.value))
    const isRemoteSource = computed(() => {
      if (source.value?.includes?.('proxy')) {
        return true
      }

      return new RegExp('^([a-z]+://|//)', 'i').test(source.value)
    })
    const path = computed(() => {
      if (isRemoteSource.value) {
        return source.value
      } else if (source.value) {
        return `/images/${source.value}`.replace('//', '/')
      }

      return ''
    })

    const wrapperStyle = computed(() => {
      if (isSVG.value) {
        return null
      }

      return {
        paddingBottom: props.ratio
          ? `${(1 / Number(props.ratio)) * 100}%`
          : null,
        backgroundImage: source.value ? `url(${path.value})` : null,
      }
    })
    const imageBindings = computed(() => {
      /**
       * Optimise image for normal and retina screens
       * Options are: source, width, height
       * Provide width and height for normal screen
       * Retina will take the double size of normal screen
       */

      let src = source.value ? path.value : ''
      let srcset = ''

      if (props.width && props.height && src) {
        const normalLink = cdn(src)
          .scaleCrop(`${props.width}x${props.height}/smart`)
          .quality('best')
          .url()
        const retinaLink = cdn(src)
          .scaleCrop(`${props.width * 2}x${props.height * 2}/smart`)
          .url()

        src = normalLink
        srcset = `${normalLink} 1x, ${retinaLink} 2x`
      }

      return {
        src,
        srcset: isError.value ? null : srcset || props.srcset,
        alt: props.alt,
      }
    })

    watch(
      () => props.src,
      () => {
        isError.value = false
        ready.value = false
      }
    )

    const image = ref(null)

    function onImageLoaded() {
      ready.value = true
    }

    function onImageError() {
      isError.value = true
    }

    return {
      ready,
      isSVG,
      image,
      wrapperStyle,
      imageBindings,
      onImageLoaded,
      onImageError,
    }
  },
}
</script>

<template>
  <span
    ref="card"
    class="block w-full"
    :class="{
      'bg-transparent': ready,
    }"
    v-on="$listeners"
  >
    <span
      :style="wrapperStyle"
      class="duration relative z-0 block h-full bg-contain bg-no-repeat"
      style="background-position: -999px -999px; will-change: box-shadow"
      :class="{
        'shadow-md transition-all duration-150': shadow,
        'transform cursor-pointer hover:-translate-x-px hover:-translate-y-px hover:shadow-lg':
          shadow && active,
        'bg-cover': fit === 'cover',
        'rounded-sm': (topRounded || rounded) && roundedSize === 'sm',
        rounded: (topRounded || rounded) && roundedSize === 'normal',
        'rounded-md': (topRounded || rounded) && roundedSize === 'md',
        'rounded-lg': (topRounded || rounded) && roundedSize === 'lg',
        'rounded-xl': (topRounded || rounded) && roundedSize === 'xl',
        'rounded-2xl': (topRounded || rounded) && roundedSize === '2xl',
        'rounded-3xl': (topRounded || rounded) && roundedSize === '3xl',
        'rounded-full': (topRounded || rounded) && roundedSize === 'full',
        'rounded-b-none': topRounded,
      }"
    >
      <span
        v-if="loading || !ready"
        class="absolute top-1/2 left-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform bg-transparent"
      >
        <img src="/icons/spinner.svg" alt="spinner" />
      </span>
      <img
        v-show="ready"
        ref="image"
        v-bind="imageBindings"
        class="max-h-full"
        :class="{
          'h-full w-full': isSVG,
          'm-auto rounded-inherit': !isSVG,
          'absolute inset-0': !isSVG && ratio,
          'h-full w-full object-cover': !isSVG && fit === 'cover',
          'rounded-sm': !isSVG && rounded && roundedSize === 'sm',
          rounded: !isSVG && rounded && roundedSize === 'normal',
          'rounded-md': !isSVG && rounded && roundedSize === 'md',
          'rounded-lg': !isSVG && rounded && roundedSize === 'lg',
          'rounded-xl': !isSVG && rounded && roundedSize === 'xl',
          'rounded-2xl': !isSVG && rounded && roundedSize === '2xl',
          'rounded-3xl': !isSVG && rounded && roundedSize === '3xl',
          'rounded-full': !isSVG && rounded && roundedSize === 'full',
        }"
        @load="onImageLoaded"
        @error="onImageError"
      />
      <slot />
    </span>
  </span>
</template>
