export default {
  'qr-code-manually': () => import('./qr-code-manually.mdl.vue'),
  'qr-code-scan-dates': () => import('./qr-code-scan-dates.mdl.vue'),
  'qr-pin-popup-mobile': () => import('./qr-pin-popup-mobile.mdl.vue'),
  'qr-section-map': () => import('./qr-section-map.mdl.vue'),
  'qr-workspace-reserved': () => import('./qr-workspace-reserved.mdl.vue'),
  'qr-workspace-unavailable': () =>
    import('./qr-workspace-unavailable.mdl.vue'),
  'qr-ws-confirmed': () => import('./qr-ws-confirmed.mdl.vue'),
}
