import { EventSourcePolyfill } from 'event-source-polyfill'
import Vue from 'vue'
import emitter, { type Events } from '/~/core/emitter'
import { useNotifications } from '/~/composables/notifications'
import { Notification } from '/~/composables/notifications/core/notification'
import { useProvider } from '/~/composables/provider'

/*
 * Subscribe to events with https://mercure.rocks
 * https://mercure.rocks/spec#subscription
 */

interface Subscription {
  url: string
  topics: string[]
  jwt: string
}

const { getSubscriptions, notificationsProcessor } = useNotifications()
const { isCashrewardsProvider } = useProvider()

function registerSubscription(subscription: Subscription) {
  const url = new URL(subscription.url)

  if (subscription.topics instanceof Array) {
    subscription.topics.forEach((topic) => {
      url.searchParams.append('topic', topic)
    })
  }

  const es = new EventSourcePolyfill(url.toString(), {
    headers: {
      Authorization: `Bearer ${subscription.jwt}`,
    },
  })

  es.onmessage = ({ data }: { data: string }) => {
    const update = JSON.parse(data)
    const notification = new Notification(update)

    if (
      ['payment_order_status_updated', 'batch_order_status_updated'].includes(
        notification.event
      )
    ) {
      const orderNumber = notification.metadata.orderNumber
      const orderStatus = notification.metadata.status

      emitter.emit('notifications:payment_order_status_updated', {
        orderNumber,
        orderStatus,
      })
    }

    if (notification.event.split('.').length === 3) {
      const eventArray = notification.event.split('.')
      const notifyType = eventArray[0]
      const batchOrderId = eventArray[1]
      const batchOrderStatus = eventArray[2]

      if (notifyType === 'batch') {
        emitter.emit('notifications:batch-order', {
          batchOrderId,
          batchOrderStatus,
          notification,
        })
        return
      }
    }

    emitter.emit(
      `notifications:${
        notification.event || notification.action
      }` as keyof Events,
      notification
    )

    if (!notification.title) {
      return
    }

    emitter.emit('notifications:update_list')

    notificationsProcessor.value.addHit(notification)

    if (isCashrewardsProvider.value) {
      return
    }

    Vue.notify({
      text: notification.title,
      data: {
        notification: notification,
      },
      duration: 4000,
    })
  }
}

getSubscriptions().then((subscriptions) => {
  if (subscriptions instanceof Array) {
    subscriptions.forEach((subscription) => {
      registerSubscription(subscription)
    })
  }
})
