import { reactive } from 'vue'
import { EarnConfigProcessor } from '/~rec/core/earn-config-processor'
import { RecroomGlobalEarnStatusLimitsProcessor } from '/~rec/core/global-earn-status-limits-processor'

const globalEarnStatus = reactive(
  new RecroomGlobalEarnStatusLimitsProcessor({
    filters: {
      awards: {
        actions: 'award_nomination_create,award_nomination_receive',
      },
      hi5s: {
        actions: 'high_five_create,high_five_receive',
      },
    },
  })
)

const actionEarnLimit = reactive(new EarnConfigProcessor())

export const useLimits = () => ({
  actionEarnLimit,
  globalEarnStatus,
})
