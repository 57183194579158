import { lazyEwalletRoute } from '/~/utils/lazy'

const routes = [
  {
    path: '#cart-home',
    component: import(
      '/~/templates/australiapost/components/cart-menu/views/home/cart-home.vue'
    ),
    meta: {
      appear: null,
    },
  },
  {
    path: '#cart-checkout',
    component: import(
      '/~/components/cart-menu/views/checkout/cart-checkout.vue'
    ),
  },
  {
    path: '#cart-billing',
    component: import(
      '/~/components/cart-menu/views/addresses/cart-addresses.vue'
    ),
    meta: {
      type: 'billing',
    },
  },
  {
    path: '#cart-shipping',
    component: import(
      '/~/components/cart-menu/views/addresses/cart-addresses.vue'
    ),
    meta: {
      type: 'shipping',
    },
  },
  {
    path: '#cart-address-billing/:id',
    component: import(
      '/~/components/cart-menu/views/addresses/cart-address-form.vue'
    ),
    meta: {
      type: 'billing',
    },
  },
  {
    path: '#cart-address-shipping',
    component: import(
      '/~/components/cart-menu/views/addresses/cart-address-form.vue'
    ),
    meta: {
      type: 'shipping',
    },
  },
  {
    path: '#cart-payment-methods',
    component: import(
      '/~/components/cart-menu/views/payment/cart-payment-methods.vue'
    ),
  },
  {
    path: '#cart-payment-methods-add',
    component: import(
      '/~/components/cart-menu/views/payment/cart-payment-method-add.vue'
    ),
  },
  {
    path: '#cart-checkout-confirmation',
    component: import(
      '/~/components/cart-menu/views/confirmation/cart-checkout-confirmation.vue'
    ),
  },
]

export default routes.map((route) => ({
  ...route,
  component: lazyEwalletRoute(route.component),
  meta: {
    appear: 'right',
    ...route.meta,
  },
}))
