import { createDate, formatDate } from '/~/utils/format/date'
import get from 'lodash-es/get'
import set from 'lodash-es/set'
import cdn from '/~/utils/cdn'
import api from '/~rec/core/api'
import { RecroomUser } from '/~rec/core/user'
import { RecroomEventMember } from '/~rec/core/event-member'
import { PostsProcessor, RecroomPost } from '/~rec/core'
import { UsersProcessor } from '/~rec/core/users-processor'
import { useRecProfile } from '/~rec/composables/profile'

const { myRecId } = useRecProfile()

export class RecroomEvent {
  constructor(raw) {
    this.raw = raw || {}
    this.posts = new PostsProcessor({
      entity: `events/${this.id}/posts`,
    })
    this.members = new UsersProcessor({
      entity: `events/${this.id}/members`,
      perPage: 500,
      mapping: (record) => new RecroomEventMember(record),
    })

    const initialMembers = (
      this.raw.members ||
      this.raw.event_members ||
      []
    ).map((member) => {
      return new RecroomEventMember(member)
    })

    this.members.hits = initialMembers
  }

  get id() {
    return this.raw.id
  }

  get creator() {
    return new RecroomUser(this.raw.creator)
  }

  get name() {
    return this.raw.name
  }

  get description() {
    return this.raw.description
  }

  get location() {
    return this.raw.location
  }

  get isPrivate() {
    return this.raw.private
  }

  get timeZone() {
    return this.raw.start_timezone
  }

  get totalMembers() {
    return this.raw.total_members
  }

  get image() {
    return cdn(this.raw.banner_url || '/img/banner').url()
  }

  get startsAt() {
    return createDate(this.raw.starts_at)
  }

  get endsAt() {
    return createDate(this.raw.ends_at)
  }

  get startsAtTime() {
    return formatDate('time', this.startsAt.local())
  }

  get endsAtTime() {
    return formatDate('time', this.endsAt.local())
  }

  get dateStartEvent() {
    return formatDate('daymonthyear', this.startsAt.local())
  }

  get dateEndEvent() {
    return formatDate('daymonthyear', this.endsAt.local())
  }

  get isPast() {
    const date = createDate()

    return this.endsAt.isBefore(date)
  }

  get type() {
    return this.raw.type
  }

  get isMeOwner() {
    // return this.raw.is_owner
    return this.raw.creator.id === myRecId.value
  }

  get isMeMember() {
    return this.raw.is_member
  }

  get isAccepted() {
    return get(this.raw, 'current_user.status') === 'yes'
  }

  get isDeclined() {
    return get(this.raw, 'current_user.status') === 'no'
  }

  static async fetchById(id) {
    const response = await api.get(`/events/${id}`)

    return new RecroomEvent(response.data)
  }

  static async create(data) {
    const event = await api.post('events', data, { notify: false })

    return new RecroomEvent(event.data)
  }

  async createPost(data) {
    return RecroomPost.create({ ...data, events: [this.id] })
  }

  async edit(data) {
    const response = await api.put(`events/${this.id}`, data, { notify: false })

    this.updateRaw(response.data)
  }

  async accept() {
    set(this.raw, 'current_user.status', 'yes')
    await api.put(`events/${this.id}/members/${myRecId.value}`, {
      status: 'yes',
      permission: 2,
    })

    this.raw.is_member = true
    this.members.refresh()
  }

  async decline() {
    set(this.raw, 'current_user.status', 'no')
    await api.put(`events/${this.id}/members/${myRecId.value}`, {
      status: 'no',
      permission: 2,
    })
    this.raw.is_member = true
    this.members.refresh()
  }

  updateRaw(data) {
    this.raw = { ...this.raw, ...data }
  }

  async delete() {
    return api.delete(`/events/${this.id}`)
  }
}
