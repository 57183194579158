<script>
import DrawerLoyaltyCreate from '/~/drawer/loyalty-cards/loyalty-create.vue'

export default {
  name: 'profile-loyalty-card-add',
  components: {
    DrawerLoyaltyCreate,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    onSubmit(id) {
      this.$router.replace({
        hash: `#profile-loyalty-card-details/${id}`,
      })
    },
  },
}
</script>

<template>
  <drawer-loyalty-create :id="id" @submit="onSubmit" />
</template>
