<script>
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-how-earn-points',
  components: {
    BaseIcon,
  },
  emits: ['open-earn-info'],
  setup() {
    return {
      ui,
    }
  },
  computed: {
    isDesktop() {
      return ui.desktop
    },
  },
}
</script>

<template>
  <div
    v-if="isDesktop"
    class="flex min-h-[30px] cursor-pointer items-center justify-center rounded-3xl bg-amber-400 px-2.5 py-0.5 text-center tracking-wider text-white hover:opacity-75"
    @click="$emit('open-earn-info')"
  >
    <span class="flex items-center">
      <span class="text-sm font-semibold uppercase">How to earn points</span>
      <base-icon svg="rec/info" class="ml-[5px] shrink-0" :size="24" />
    </span>
  </div>
</template>
