<script>
import ui from '/~/core/ui'
import ViewMobile from './view-mobile.vue'
import ViewDesktop from './view-desktop.vue'

export default {
  name: 'rec-layout-view',
  computed: {
    component() {
      return ui.desktop ? ViewDesktop : ViewMobile
    },
  },
}
</script>

<template>
  <component v-bind="$attrs" :is="component" v-on="$listeners">
    <template v-for="(_, slotName) in $scopedSlots" :slot="slotName">
      <slot :name="slotName" />
    </template>
  </component>
</template>
