import PortalVue from 'portal-vue'
import VTooltip from 'v-tooltip'
import Vue from 'vue'
import VueCurrencyInput from 'vue-currency-input'
import { ObserveVisibility } from 'vue-observe-visibility'
import VueScrollTo from 'vue-scrollto'

Vue.directive('observe-visibility', ObserveVisibility)

Vue.use(VueScrollTo)
Vue.use(VTooltip)
Vue.use(PortalVue)
Vue.use(VueCurrencyInput, {
  globalOptions: {
    locale: 'en',
    currency: { prefix: '$' },
    autoDecimalMode: true,
    distractionFree: false,
    allowNegative: false,
  },
})
