<script setup lang="ts">
import { formatBsb } from '/~/utils/format/string'
import AppLogo from '/~/components/app/app-logo.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
interface Props {
  option: any
  providerTitle: string
  isSponsored?: boolean
  isBPay?: boolean
  name?: string
  number?: string
  icon?: string
  isPayee?: boolean
  isStatement?: boolean
  isOptionSelected?: boolean
}
withDefaults(defineProps<Props>(), {})
const { translate } = useLocalization()
</script>
<template>
  <div>
    <div v-if="isSponsored || isStatement">
      <div class="flex w-full items-center justify-start">
        <app-logo :max-width="24" :min-height="true" class="mr-2 shrink-0" />
        <div class="flex text-sm text-eonx-neutral-600">
          <div
            :class="{
              'w-6/12 truncate': isOptionSelected,
            }"
          >
            <span class="first-letter font-bold text-eonx-neutral-800">
              {{ providerTitle }}
            </span>
            <span v-if="!isOptionSelected">&ensp;&ndash;&ensp;</span>
            <span class="text-eonx-neutral-600">{{ name }}</span>
            <span v-if="number" class="text-eonx-neutral-600">
              , Account No. {{ number }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="isBPay">
      <div class="flex w-full items-center justify-start">
        <base-icon
          :svg="icon"
          :size="26"
          class="mr-2 shrink-0 text-brand-bpay"
        />
        <div class="flex text-sm text-eonx-neutral-600">
          <div
            :class="{
              'w-6/12 truncate': isOptionSelected,
            }"
          >
            <span class="font-bold text-eonx-neutral-800">{{ name }}</span>
            <span v-if="!isOptionSelected">&ensp;&ndash;&ensp;</span>
            <span class="text-eonx-neutral-600">
              Biller Code: {{ option.billerCode }}, Ref: {{ option.reference }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="isPayee">
      <div class="flex w-full items-center justify-start">
        <base-icon :svg="icon" :size="24" class="mr-2 shrink-0 text-teal-500" />
        <div class="flex text-sm text-eonx-neutral-600">
          <div
            :class="{
              'w-6/12 truncate': isOptionSelected,
            }"
          >
            <span class="font-bold text-eonx-neutral-800">{{ name }}</span>
            <span v-if="!isOptionSelected">&ensp;&ndash;&ensp;</span>
            <span class="text-eonx-neutral-600">
              {{
                translate('bankAccount.details', {
                  acc: number,
                  bsb: formatBsb(option.bsb),
                })
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
