import { RewardsExtension } from '/~/core/extension'

class LeadsExtension extends RewardsExtension {
  setRoutes() {
    return [
      {
        path: '/lead-submission',
        name: 'lead-submission',
        component: () => import('./views/submission/lead-submission.vue'),
      },
      {
        path: '/lead-submission/:id',
        name: 'lead-submission-confirmation',
        props: true,
        component: () =>
          import('./views/confirmation/lead-submission-confirmation.vue'),
      },
    ]
  }
}

export default LeadsExtension
