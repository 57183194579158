<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatNumber } from '/~/utils/format/numeric'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import PaymentMethodSplitItem from '/~/components/payments/list/item/split.v2.vue'
import PaymentMethodBlock from '/~/components/payments/payments-methods-block.vue'
import PaymentsPba from '/~/components/payments/pba/payments-pba.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { useEwallet } from '/~/composables/ewallet'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

const props = withDefaults(
  defineProps<{
    flowType?: FlowType
  }>(),
  {
    flowType: FlowType.purchase,
  }
)

const router = useRouter()
const { isDarkThemeForEwallet, ewalletLabels } = useCms()
const { isSplitEnabled } = useProvider()
const { pointsBalance, getBurnPointsRate } = usePoints()
const { ewalletBalance } = useEwallet()
const {
  isMethodAvailable,
  points,
  eWallets,
  noEwallets,
  creditCards,
  bankAccounts,
  noCreditCards,
  noBankAccounts,
  fetchingList: fetching,
  isVisaEnabled,
  isMasterCardEnabled,
  isAmexEnabled,
} = usePaymentMethods()

const paymentType = computed(() => {
  switch (props.flowType) {
    case FlowType.payment:
      return FlowType.payment
    case FlowType.batch:
      return FlowType.batch
    case FlowType.statement:
      return FlowType.statement
  }

  return FlowType.purchase
})

const { payment } = useCheckoutReactive()

const pointsPaymentMethodBlockRef = ref(null)
const pointsPaymentMethodItemRef = ref(null)

const hasEnoughPointsToPay = computed(() => {
  return (
    Math.ceil(pointsBalance.value * getBurnPointsRate(props.flowType) * 100) /
      100 >=
    payment.subTotalWithProgramFees
  )
})

const isPointsAvailable = computed(() => {
  return isMethodAvailable(PaymentMethodType.points, paymentType.value)
})
const isEwalletAvailable = computed(() => {
  return (
    isMethodAvailable(PaymentMethodType.eWallet, paymentType.value) &&
    eWallets.value?.length > 0
  )
})
const isPointsDisabled = computed(() => {
  return (
    (!isSplitEnabled.value && !hasEnoughPointsToPay.value) ||
    payment.isPointsDisabled
  )
})
const isEwalletDisabled = computed(() => {
  return (
    (!isSplitEnabled.value &&
      ewalletBalance.value < payment.subTotalWithProgramFees) ||
    payment.isEwalletDisabled.value
  )
})

const isCreditCardsAvailable = computed(() =>
  isMethodAvailable(PaymentMethodType.creditCard, paymentType.value)
)

const isBankAccountsAvailable = computed(() =>
  isMethodAvailable(PaymentMethodType.bankAccount, paymentType.value)
)

const fulfilledAmount = computed(() => {
  return Math.min(
    payment.subTotalWithProgramFees,
    (payment.selectedPoints?.calculatedAmount ?? 0) +
      (payment.selectedCard?.calculatedAmount ?? 0) +
      (payment.selectedBankAccount?.calculatedAmount ?? 0) +
      (payment.selectedEwallet?.calculatedAmount ?? 0)
  )
})

const amountToPay = computed(() => {
  return payment.subTotalWithProgramFees - fulfilledAmount.value
})

const isAmountFulfilledWithPoints = computed(
  () =>
    payment.selectedPoints?.calculatedAmount === payment.subTotalWithProgramFees
)

function addNewPaymentMethod() {
  router.push({
    hash: '#profile-payment-methods-add',
    params: {
      source: payment.orderType,
    },
  })
}

function onPaymentMethodConfirm(type) {
  if (isSplitEnabled.value) {
    switch (type) {
      case PaymentMethodType.points:
        if (!payment.selectedPoints) {
          pointsPaymentMethodBlockRef.value?.toggleOff()
        }

        break
    }
  }
}

function onPaymentMethodCancel(type) {
  if (isSplitEnabled.value) {
    switch (type) {
      case PaymentMethodType.points:
        if (!payment.selectedPoints) {
          pointsPaymentMethodBlockRef.value?.toggleOff()
        }

        break
    }
  }
}

function onPaymentMethodToggledOff(type) {
  if (isSplitEnabled.value) {
    switch (type) {
      case PaymentMethodType.points:
        if (payment.selectedCard && payment.selectedBankAccount) {
          payment.resetPaymentMethods()
        } else {
          payment.selectedPoints = null

          if (payment.selectedCard) {
            payment.selectedCard.calculatedAmount += amountToPay.value
          } else if (payment.selectedBankAccount) {
            payment.selectedBankAccount.calculatedAmount += amountToPay.value
          }

          payment.getTransactionFees()
        }
        break
    }
  } else {
    switch (type) {
      case PaymentMethodType.points:
        payment.selectedPoints = null
        payment.getTransactionFees()

        break
    }
  }
}

function onPaymentMethodToggledOn(type) {
  if (isSplitEnabled.value) {
    switch (type) {
      case PaymentMethodType.points:
        payment.resetPaymentMethods()
        break
    }

    pointsPaymentMethodItemRef.value?.onClick()
  } else {
    switch (type) {
      case PaymentMethodType.points:
        payment.resetPaymentMethods()

        payment.selectedPoints = {
          ...points.value[0],
          calculatedAmount: payment.subTotalWithProgramFees,
        }

        payment.getTransactionFees()

        break
    }
  }
}

function onPaymentMethodUnselect(type) {
  if (isSplitEnabled.value) {
    switch (type) {
      case PaymentMethodType.points:
        payment.selectedPoints = null
        pointsPaymentMethodBlockRef.value?.toggleOff()
        onPaymentMethodToggledOff(type)

        break
      case PaymentMethodType.creditCard:
        payment.selectedCard = null

        break
      case PaymentMethodType.bankAccount:
        payment.selectedBankAccount = null

        break
      case PaymentMethodType.eWallet:
        payment.selectedEwallet.value = null

        break
    }

    payment.getTransactionFees()
  } else {
    payment.resetPaymentMethods()
    pointsPaymentMethodBlockRef.value?.toggleOff()
  }
}

function onPaymentMethodSelect(type, method) {
  if (!isSplitEnabled.value) {
    payment.resetPaymentMethods()

    if (type !== PaymentMethodType.points) {
      pointsPaymentMethodBlockRef.value?.toggleOff()
    }
  }

  const calculatedAmount = isSplitEnabled.value
    ? 0
    : payment.subTotalWithProgramFees

  switch (type) {
    case PaymentMethodType.points:
      payment.selectedPoints = {
        ...method,
        calculatedAmount,
      }

      break
    case PaymentMethodType.creditCard:
      payment.selectedCard = {
        ...method,
        calculatedAmount,
      }

      break
    case PaymentMethodType.bankAccount:
      payment.selectedBankAccount = {
        ...method,
        calculatedAmount,
      }

      break
    case PaymentMethodType.eWallet:
      payment.selectedEwallet = {
        ...method,
        calculatedAmount,
      }

      break
  }

  if (!isSplitEnabled.value) {
    payment.getTransactionFees()
  }
}

function onAddCashClick() {
  router.push({
    hash: '#profile-add-cash',
  })
}

function onAddNewClick() {
  router.push({
    hash: '#profile-pay-from-add',
  })
}
</script>

<template>
  <div class="space-y-6">
    <template v-if="isPointsAvailable">
      <payment-method-block
        v-if="points.length"
        ref="pointsPaymentMethodBlockRef"
        :loading="fetching"
        :type="PaymentMethodType.points"
        :is-togglable="true"
        :is-toggled-initial="Boolean(payment.selectedPoints)"
        :is-disabled="payment.loadingFees || isPointsDisabled"
        @toggled-off="onPaymentMethodToggledOff(PaymentMethodType.points)"
        @toggled-on="onPaymentMethodToggledOn(PaymentMethodType.points)"
      >
        <template #title>
          <div
            :class="{
              'text-eonx-neutral-800': !isDarkThemeForEwallet,
              'text-white': isDarkThemeForEwallet,
            }"
          >
            Pay with Points
          </div>
        </template>
        <template #description>
          <div class="text-eonx-neutral-600">
            {{ formatNumber(pointsBalance) }} points available
          </div>
        </template>
        <payment-method-split-item
          ref="pointsPaymentMethodItemRef"
          :flow-type="flowType"
          :method="points[0]"
          to="menu-modal"
          :is-disabled="payment.loadingFees"
          @confirm="onPaymentMethodConfirm(PaymentMethodType.points)"
          @cancel="onPaymentMethodCancel(PaymentMethodType.points)"
          @select="
            onPaymentMethodSelect(PaymentMethodType.points, ...arguments)
          "
          @unselect="onPaymentMethodUnselect(PaymentMethodType.points)"
        />
      </payment-method-block>
    </template>

    <div v-if="isEwalletAvailable">
      <div class="mb-5 flex items-center justify-between">
        <div class="font-bold">{{ ewalletLabels.ewalletCash }}</div>
        <base-button look="link" @click="onAddCashClick">
          <base-icon svg="v2/custom/plus" :size="12" class="mr-[5px]" />
          Add Cash
        </base-button>
      </div>

      <div
        v-if="ewalletBalance === 0"
        class="flex h-20 items-center rounded-3xl bg-gray-300 pl-5 pr-4 text-xl font-black text-white"
      >
        <base-icon svg="plain/ewallet" :size="32" class="mr-4" />
        $0.00
        <button
          type="button"
          class="ml-auto text-primary"
          @click="onAddCashClick"
        >
          <base-icon svg="v2/custom/plus-circle" :size="32" />
        </button>
      </div>
      <payment-method-split-item
        v-else
        :flow-type="flowType"
        :method="eWallets[0]"
        to="menu-modal"
        :is-disabled="payment.loadingFees || isEwalletDisabled"
        @select="onPaymentMethodSelect(PaymentMethodType.eWallet, ...arguments)"
        @unselect="onPaymentMethodUnselect(PaymentMethodType.eWallet)"
      />
    </div>

    <div>
      <div class="mb-5 flex items-center justify-between">
        <div class="font-bold">Credit/Debit Card</div>
        <base-button look="link" @click="onAddNewClick">
          <base-icon svg="v2/custom/plus" :size="12" class="mr-[5px]" />
          Add New
        </base-button>
      </div>
      <div v-if="isCreditCardsAvailable" class="space-y-4">
        <div
          v-if="noCreditCards"
          class="flex h-20 items-center rounded-3xl bg-gray-300 pl-5 pr-4 text-xl font-black text-white"
        >
          <base-icon
            v-if="isMasterCardEnabled"
            svg="v2/custom/mastercard"
            :size="32"
          />
          <base-icon
            v-if="isVisaEnabled"
            svg="v2/custom/visa"
            :size="63"
            class="ml-5"
          />
          <base-icon
            v-if="isAmexEnabled"
            svg="v2/custom/amex"
            :size="72"
            class="ml-5"
          />
          <button
            type="button"
            class="ml-auto text-primary"
            @click="onAddNewClick"
          >
            <base-icon svg="v2/custom/plus-circle" :size="32" />
          </button>
        </div>
        <template v-else>
          <payment-method-split-item
            v-for="method in creditCards"
            :key="method.id"
            :flow-type="flowType"
            :method="method"
            to="menu-modal"
            :is-disabled="payment.loadingFees || payment.isCreditCardsDisabled"
            @select="
              onPaymentMethodSelect(PaymentMethodType.creditCard, ...arguments)
            "
            @unselect="onPaymentMethodUnselect(PaymentMethodType.creditCard)"
          />
        </template>
      </div>
    </div>

    <div>
      <div class="mb-5 flex items-center justify-between">
        <div class="font-bold">Bank Account</div>
        <base-button look="link" @click="onAddNewClick">
          <base-icon svg="v2/custom/plus" :size="12" class="mr-[5px]" />
          Add New
        </base-button>
      </div>
      <div v-if="isBankAccountsAvailable" class="space-y-4">
        <div
          v-if="noBankAccounts"
          class="flex h-20 items-center rounded-3xl bg-gray-300 pl-5 pr-4 font-bold text-white"
        >
          <base-icon svg="v2/custom/bank" :size="26" />
          <span class="mx-auto">Pay by Account</span>
          <button type="button" class="text-primary" @click="onAddNewClick">
            <base-icon svg="v2/custom/plus-circle" :size="32" />
          </button>
        </div>
        <template v-else>
          <payment-method-split-item
            v-for="method in bankAccounts"
            :key="method.id"
            :flow-type="flowType"
            :method="method"
            to="menu-modal"
            :is-disabled="payment.loadingFees || payment.isBankAccountsDisabled"
            @select="
              onPaymentMethodSelect(PaymentMethodType.bankAccount, ...arguments)
            "
            @unselect="onPaymentMethodUnselect(PaymentMethodType.bankAccount)"
          />
        </template>
      </div>
    </div>
    <payments-pba />
  </div>
</template>
