import mdl from '/~/core/mdl'
import router from '/~/router'
import modals from './modals'
import routes from './routes'

routes.forEach((route) => {
  router.addRoute(route)
})

mdl.register(modals)
