<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import BaseBox from '/~/components/base/box/base-box.vue'
import BaseButtonRound from '/~/components/base/button-round/base-button-round.vue'
import { useAddresses } from '/~/composables/addresses'
import QuickBuyHeader from '/~/views/quick-buy/components/header/quick-buy-header.mobile.vue'
import { useQuickBuyAddressesComponent } from '/~/views/quick-buy/views/addresses/addresses.component'
import AddressItem from '../../components/addresses/quick-buy-address-item.vue'

const router = useRouter()
const { addresses } = useAddresses()
const {
  onAddAddress,
  isSelected,
  onSelectAddress,
  onRemoveAddress,
  backRoute,
} = useQuickBuyAddressesComponent()
</script>

<template>
  <div>
    <base-box title="Select Address" new-look>
      <template #menu>
        <base-button-round icon="plus" size="lg" @click="onAddAddress" />
      </template>

      <div class="mt-[5px]">
        <address-item
          v-for="address in addresses"
          :key="address.id"
          :item="address"
          class="mb-[15px]"
          show-menu
          :selected="isSelected(address.id)"
          @click="onSelectAddress(address)"
          @remove="onRemoveAddress(address.id)"
        />
      </div>
    </base-box>

    <quick-buy-header @back="router.push(backRoute)">
      Select address
    </quick-buy-header>
  </div>
</template>
