<script setup lang="ts">
import { onMounted, onBeforeUnmount, computed } from 'vue'
import cdn from '/~/utils/cdn'
import { useSwiper } from '/~/composables/base/use-swiper'

const { swiperContainer, initSwiper, destroySwiper } = useSwiper(
  computed(() => ({
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 5,
    breakpoints: {
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1024: {
        slidesPerView: 6,
        slidesPerGroup: 6,
      },
    },
  }))
)

const slides = [
  cdn('//i.ewalletcdn.com/30384fee-0ca4-4273-a41f-44c9b6f7406c/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/ddf2b94f-e4b0-4b67-bba5-331d90136e08/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/1dbf2757-9adf-4bbb-bdc3-4743ae2a9778/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/82ffb2b8-d046-4f0e-a9ad-d6edc1a6ad3c/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/76377b18-d83c-4a85-8a9e-f0522c79c97e/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/c40f6aa8-2e82-431b-869e-a0a53a35cd57/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/61079073-140f-4967-bb99-a44a82c867f7/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/56f1b58d-743f-49c8-9f6e-076001393c82/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/e79d1209-de89-477d-b8f3-db1e771eba9c/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/039c8b04-dc62-493f-9f0f-ca09d2ff618d/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/094c58f8-1217-4bbc-97ee-67cd449c8545/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/53d7724c-a568-4b59-8f8c-53dbe5a2cdee/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/9cd66624-92e5-4799-91dd-6e671c2bbfb3/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/f3cc81c7-7a7c-4fb8-ae95-f1e9b8e24c93/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/7060d5fb-037f-4f14-90cb-16cab8e91b9e/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/3ec9ed2a-f0a1-4516-9697-492d363d400e/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/f404bfe3-33a7-493b-9141-ef412fb0fbea/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/e5eaf171-44fb-495a-a47d-104f4f0408dc/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/9a28ccc7-f0f4-41f1-ba99-a791d36ce192/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/dabd02f4-0910-4759-91ba-8396286d6c33/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/bfb5d4aa-2e28-4981-b044-a3912a2cf0a5/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/261087eb-bc46-425c-9f7f-4b3e6b7102e7/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/ceba678c-779d-4231-96d9-b16e188d13c4/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/2efaf4ec-62bd-44ba-9bae-974e9419f050/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/f8214cd9-f95f-4973-ba00-1f8f19f79c86/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/9df85c16-0fbb-4c22-b6ef-a800c767fc63/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/6c567e6c-2167-49b0-a6dc-7278f8e1e964/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/80ae86e4-5082-49f7-98bc-e6f9d6b2ec6e/')
    .resize('100x100')
    .url(),
  cdn('//i.ewalletcdn.com/bd493695-4def-4d41-9e7f-83787a555891/')
    .resize('100x100')
    .url(),
]

onMounted(initSwiper)

onBeforeUnmount(destroySwiper)
</script>

<template>
  <div ref="swiperContainer" class="swiper-container overflow-hidden">
    <div class="swiper-wrapper w-full">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="swiper-slide shrink-0"
      >
        <img
          :src="slide"
          class="mx-auto block h-20 w-20 min-w-20"
          alt="Brand Image"
        />
      </div>
    </div>
  </div>
</template>
