<script>
import Vue, { onBeforeUnmount, computed, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import { cardCode } from '/~/utils/cards'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerCancelTransaction from '/~/components/ewallet-menu/components/menu-pay-cancel-transaction.vue'
import MenuPaymentTo from '/~/components/ewallet-menu/components/rows/menu-payment-to.vue'
import { useAddresses } from '/~/composables/addresses'
import { usePayeeCheckout } from '/~/composables/checkout'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'
import {
  usePaymentMethods,
  useVerifyCard,
} from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'
import { useUser } from '/~/composables/user'

export default {
  name: 'profile-pay-summary',
  components: {
    BaseAsidePage,
    BaseButton,
    BaseIcon,
    MenuPaymentTo,
    DrawerCancelTransaction,
  },
  setup() {
    const router = useRouter()
    const { isDarkThemeForEwallet, ewalletLabels } = useCms()
    const { taxationLabel } = useProvider()
    const { fetchPaymentMethods } = usePaymentMethods()
    const { verifyingCard, verifyCard } = useVerifyCard()
    const { addresses, defaultAddress, firstAddress, getAddresses } =
      useAddresses()
    const { fetchCurrentPurchases } = usePurchases()
    const { translate } = useLocalization()
    const {
      payment,
      payWithCard,
      selectedPoints,
      selectedEwallet,
      selectedCard,
      selectedBankAccount,
      totalFees,
      subTotal,
      total,
      readyToPay,
      pay,
      programFees,
      transactionFees,
      multipleSourcesSelected,
    } = usePayeeCheckout()
    const { user } = useUser()

    const drawerCancelTransactionRef = ref(null)
    const submitting = ref(false)

    const selectedPayee = computed(() => payment.payee ?? {})
    const cardIcons = {
      mastercard: {
        svg: 'cards/mastercard',
        size: 32,
      },
      visa: {
        svg: 'v2/custom/visa',
        size: 32,
        class: 'text-blue',
      },
      amex: {
        svg: 'v2/custom/amex',
        size: 32,
        class: 'text-amber-400',
        style: {
          color: '#0E75BF',
        },
      },
    }
    const selectedCardCode = computed(() =>
      selectedCard.value ? cardCode(selectedCard.value) : null
    )
    const selectedCardIcon = computed(() => cardIcons[selectedCardCode.value])
    const selectedMethods = computed(() => {
      return [
        selectedPoints.value && {
          icon: {
            svg: 'v2/custom/points-token',
            size: 29,
            class: 'text-primary',
          },
          title: 'Points',
          description: `Using ${formatPoints(
            selectedPoints.value.usePoints
          )} Pts`,
          value: selectedPoints.value.calculatedAmount,
        },
        selectedEwallet.value && {
          icon: {
            svg: 'plain/ewallet',
            size: 32,
            class: 'text-primary',
          },
          title: ewalletLabels.value.ewalletCash,
          description: '+ 0.00 processing fee',
          value: selectedEwallet.value.calculatedAmount,
        },
        selectedCard.value && {
          icon: selectedCardIcon.value,
          title: `**** **** **** ${selectedCard.value.number.slice(-4)}`,
          description: `+ ${
            selectedCard.value.percentageFeeRate ?? '0.00'
          }% processing fee`,
          value: selectedCard.value.calculatedAmount,
        },
        selectedBankAccount.value && {
          icon: {
            svg: 'v2/custom/bank',
            size: 26,
            class: 'text-primary',
          },
          title: translate('bankAccount.details', {
            acc: `*** ${selectedBankAccount.value.accountNumber.slice(-2)}`,
            bsb: selectedBankAccount.value.bsb.slice(-3),
          }),
          description: `+ $${
            selectedBankAccount.value.fee ?? '0.00'
          } processing fee`,
          value: selectedBankAccount.value.calculatedAmount,
        },
      ].filter(Boolean)
    })

    const processing = computed(() => {
      return verifyingCard.value || payment.submitting || submitting.value
    })

    const isCardSelected = computed(() => {
      return Boolean(payWithCard.value && selectedCard.value)
    })

    const paymentReference = computed(() => payment?.reference || '-')

    function onAddressAdd() {
      router.replace({ name: 'profile-pay-summary' })
      initAddress()
    }

    async function initAddress() {
      try {
        if (addresses.value.length === 0) {
          await getAddresses()
        }

        handlePayAddress()

        if (addresses.value.length === 0) {
          router.push({
            hash: '#profile-add-address',
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    function handlePayAddress(address) {
      if (addresses.value.length === 0) {
        return
      }

      const selectedAddress =
        address || defaultAddress.value || firstAddress.value
      const isAddress = Boolean(payment.address)

      if (selectedAddress && !isAddress) {
        payment.address = selectedAddress
      }
    }

    async function onSubmit() {
      submitting.value = true

      try {
        if (isCardSelected.value) {
          const { securityToken, cvv } = await verifyCard({
            card: selectedCard.value,
            amount: payWithCard.value + transactionFees.value,
            subTotal: payment.subTotal,
            address: payment.address,
            payeeId: payment.payee?.id,
            multipleSources: multipleSourcesSelected.value,
            type: 'payees',
            modalsConfig: {
              mobile: true,
              to: 'menu-modal',
              cvvModalName: 'cvv-code',
            },
          })

          payment.securityToken = securityToken
          payment.cvv = cvv
        }
        proceed()
      } catch (error) {
        Vue.notify({
          text: error,
          type: 'error',
          duration: 5000,
        })
        submitting.value = false
      }
    }

    async function proceed() {
      try {
        const data = await pay()

        submitting.value = true

        await fetchCurrentPurchases()
        fetchPaymentMethods()
        router.push({
          hash: '#profile-pay-confirmation',
          params: {
            selectedPayee: selectedPayee.value,
            reference: payment.reference,
            selectedMethods: selectedMethods.value,
            isCombineFees: user.value.isCombineFees,
            programFees: programFees.value,
            transactionFees: transactionFees.value,
            totalFees: totalFees.value,
            subTotal: subTotal.value,
            total: total.value,
            order: data,
          },
        })

        emitter.emit('activity:refresh')
      } catch (error) {
        Vue.notify({
          text: error.reference?.[0] || error.data?.message || error.message,
          type: 'error',
          duration: 5000,
        })
      } finally {
        submitting.value = false
      }
    }

    function onCancelClick() {
      drawerCancelTransactionRef.value.show()
    }

    onBeforeUnmount(() => {
      emitter.off('addresses:add', onAddressAdd)
    })

    initAddress()

    emitter.on('addresses:add', onAddressAdd)

    return {
      isDarkThemeForEwallet,
      drawerCancelTransactionRef,
      processing,
      payment,
      user,
      programFees,
      transactionFees,
      totalFees,
      subTotal,
      total,
      selectedPayee,
      selectedMethods,
      readyToPay,
      onCancelClick,
      formatDollar,
      onSubmit,
      taxationLabel,
      paymentReference,
    }
  },
}
</script>

<template>
  <base-aside-page
    title="Pay Summary"
    no-padding
    :back="{ hash: '#profile-pay-from' }"
  >
    <div class="flex min-h-full flex-col">
      <div class="h-full grow overflow-y-auto px-5">
        <div class="font-bold">Payee</div>
        <div class="mt-2.5">
          <menu-payment-to :to="selectedPayee" />
        </div>
        <div class="my-6 border-t border-gray-300" />
        <div class="font-bold">Payment Method(s)</div>
        <div class="mt-4 space-y-4">
          <div
            v-for="(method, index) in selectedMethods"
            :key="index"
            class="flex"
          >
            <div class="w-12">
              <base-icon v-bind="method.icon" size="md" />
            </div>
            <div>
              <div class="border-t-3 border-transparent font-bold">
                {{ method.title }}
              </div>
              <div
                v-if="method.description"
                class="text-sm text-eonx-neutral-600"
              >
                {{ method.description }}
              </div>
            </div>
            <div class="ml-auto text-eonx-neutral-600">
              {{ formatDollar(method.value) }}
            </div>
          </div>
        </div>
        <div class="my-6 border-t border-gray-300" />
        <div class="font-bold">Reference</div>
        <div
          class="mt-2.5 flex h-12 items-center rounded-lg bg-gray-50 px-4"
          :class="{
            'text-eonx-neutral-800': isDarkThemeForEwallet,
          }"
        >
          {{ paymentReference }}
        </div>
        <div class="mt-[30px] flex items-center font-bold">
          Sub Total
          <span class="ml-auto">
            {{ formatDollar(subTotal) }}
          </span>
        </div>
        <template v-if="user.isCombineFees">
          <div
            class="mt-[5px] flex items-center text-eonx-neutral-600"
            data-testid="processing-fees"
          >
            Processing fees (Inc {{ taxationLabel }})
            <span class="ml-auto">
              {{ formatDollar(totalFees) }}
            </span>
          </div>
        </template>
        <template v-else>
          <div
            class="mt-[5px] flex items-center text-eonx-neutral-600"
            data-testid="program-fee"
          >
            Program Fee (Inc {{ taxationLabel }})
            <span class="ml-auto">
              {{ formatDollar(programFees) }}
            </span>
          </div>
          <div class="mt-[5px] flex items-center text-eonx-neutral-600">
            Payment Processing Fee
            <span class="ml-auto">
              {{ formatDollar(transactionFees) }}
            </span>
          </div>
        </template>
        <div class="mt-[5px] flex items-center text-xl font-bold">
          Total
          <span class="ml-auto">
            {{ formatDollar(total) }}
          </span>
        </div>
      </div>
      <div class="w-full shrink-0 p-5">
        <div class="grid grid-cols-2 gap-5">
          <base-button
            look="outlined-color"
            class="w-full"
            :disabled="processing"
            @click="onCancelClick"
          >
            Cancel
          </base-button>
          <base-button
            :disabled="!readyToPay || !payment.address"
            :loading="processing"
            class="w-full"
            @click="onSubmit"
          >
            Pay now
          </base-button>
        </div>
      </div>
    </div>

    <drawer-cancel-transaction ref="drawerCancelTransactionRef" />
  </base-aside-page>
</template>
