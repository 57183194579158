<script>
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'

export default {
  name: 'rec-render-avatars',
  components: {
    BaseAvatar,
  },
  inheritAttrs: false,
  props: {
    /*
     * Array of RecroomUser classes
     */
    users: {
      type: Array,
      default: () => [],
    },
    look: {
      type: String,
      default: 'row',
      validator: (v) => /row|col/.test(v),
    },
    size: {
      type: String,
      default: 'md',
    },
    totalUsers: {
      type: Number,
      default: null,
    },
  },
  computed: {
    avatars() {
      return (
        this.users
          .slice()
          /*
           * Move users with avatars to the beginning of the array
           */
          .sort((a) => (a.avatarUrl ? -1 : 1))
          .slice(0, 4)
          .map((user) => {
            return {
              userId: user.id,
              src: user.avatarUrl,
              abbr: user.initials,
            }
          })
      )
    },
    firstElement() {
      return this.avatars[0] || null
    },
    secondElement() {
      return this.avatars[1] || null
    },
    total() {
      return this.totalUsers || this.users.length
    },
  },
}
</script>

<template>
  <div
    v-if="avatars.length"
    class="cursor-pointer text-white"
    v-on="$listeners"
  >
    <base-avatar
      v-if="avatars.length === 1"
      :src="firstElement.src"
      :abbr="firstElement.abbr"
      :size="size"
    />

    <div v-else-if="look === 'row'" class="flex items-center">
      <div class="flex items-center -space-x-[15px] transition duration-100">
        <template v-for="(avatar, idx) in avatars">
          <base-avatar
            v-if="idx < 4"
            :key="`${avatar.userId}-${idx}`"
            class="rounded-full border-3 border-current"
            :abbr="avatar.abbr"
            :src="avatar.src"
            :size="size"
          />
        </template>
      </div>
      <b
        v-if="total > 4"
        class="ml-[5px] transform text-sm text-default duration-100 ease-out"
        :class="{
          'text-sm': size === 'xs',
          'text-xs': size !== 'xs',
        }"
      >
        +{{ total - 4 }}
      </b>
    </div>

    <div
      v-else-if="look === 'col'"
      class="relative flex"
      :class="{
        'h-[30px] w-[30px]': size === 'xs',
        'h-11 w-11': size === 'md',
        'h-12 w-12': size === 'lg',
      }"
    >
      <div class="absolute bottom-0">
        <base-avatar
          v-if="firstElement"
          :abbr="firstElement.abbr"
          :src="firstElement.src"
          class="rounded-full border-2 border-current"
          :size="size === 'lg' ? 'sm' : 'xs'"
        />
      </div>
      <div class="absolute right-0" :style="{ top: '18%' }">
        <base-avatar
          v-if="avatars.length > 1"
          class="rounded-full border-3 border-current"
          :abbr="total <= 2 ? secondElement.abbr : `+${total - 1}`"
          :src="total <= 2 ? secondElement.src : ''"
          :size="size === 'lg' ? 'sm' : 'xs'"
        />
      </div>
    </div>
  </div>
</template>
