<script>
import { usePaymentMethods } from '/~/composables/payment-methods'
import PaymentCreditCardItem from './payments-credit-card-item.vue'
import PaymentMethodBlock from '../payments-methods-block.vue'

export default {
  name: 'payments-credit-cards',
  components: {
    PaymentMethodBlock,
    PaymentCreditCardItem,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    splitEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      isPurchaseOrderCreditCardsAvailable,
      creditCards,
      fetchingList: fetching,
    } = usePaymentMethods()

    return {
      fetching,
      isAvailable: isPurchaseOrderCreditCardsAvailable,
      creditCards,
    }
  },
}
</script>

<template>
  <div v-if="isAvailable">
    <payment-method-block
      v-if="fetching || creditCards.length"
      title="Credit/Debit Cards"
      :loading="fetching"
    >
      <div class="space-y-6">
        <payment-credit-card-item
          v-for="card in creditCards"
          :key="card.id"
          :item="card"
          :to="to"
          :split-enabled="splitEnabled"
        />
      </div>
    </payment-method-block>
  </div>
</template>
