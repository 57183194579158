import { RecroomAward } from './award'
import { RecroomUser } from './user'
import { RecroomPost } from './post'
import { useRecProfile } from '/~rec/composables/profile'

const { myRecId } = useRecProfile()

export class RecroomPostAward extends RecroomPost {
  constructor(raw = {}) {
    super(raw)
    const winner = raw.award_winner || raw.relation_data?.award_winner
    const award = raw.award || raw.relation_data?.award

    this.award = award && new RecroomAward(award)
    this.winner = winner && new RecroomUser(winner)
  }

  get isMeWinner() {
    return this.winner?.id === myRecId.value
  }

  get memberAwardId() {
    return this.raw.member_award_id
  }

  get winnerId() {
    return this.winner.id
  }
}
