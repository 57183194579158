<script>
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'

export default {
  name: 'widget-optus-v2-home-banner',
  props: {
    title1: {
      type: Object,
      default: () => ({}),
    },
    title2: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    image: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    titleStyles() {
      return ui.getByScreenSize({
        base: {
          'font-size': '24px',
          'line-height': '20px',
        },
        xs: {
          'font-size': '56px',
          'line-height': '48px',
        },
        md: {
          'font-size': '76px',
          'line-height': '68px',
        },
      })
    },
    contentStyles() {
      return {
        color: '#006280',
        'line-height': 1,
        'font-size': ui.getByScreenSize({
          base: '14px',
          xs: '20px',
          md: '24px',
        }),
      }
    },
  },
}
</script>

<template>
  <section class="-mx-5 -mt-5 rounded-lg font-mark-w01 sm:mx-0 sm:mt-0">
    <div
      class="flex"
      :style="{
        'background-color': '#FDCC08',
      }"
    >
      <div
        class="flex w-full flex-col justify-center p-5 xs:w-3.5 xs:py-11 xs:pl-11 xs:pr-0"
      >
        <div
          v-if="title1.text || title2.text"
          class="mb-6 font-extrabold xs:mb-11"
          :style="titleStyles"
        >
          <span
            v-if="title1.text"
            :style="{
              color: '#006280',
            }"
            v-html="title1.text"
          />
          <span
            v-if="title2.text"
            :style="{
              color: '#00828E',
            }"
            v-html="title2.text"
          />
        </div>
        <div
          v-if="content.text"
          class="font-extrabold"
          :style="contentStyles"
          v-html="content.text"
        />
      </div>
      <div
        class="align-end flex w-2.5 shrink-0 flex-col justify-end pt-5 xs:pt-0"
      >
        <img
          :src="cdn(image.desktop).preview('858x570').format('auto').quality('smart').url()"
          class="mt-auto ml-auto"
          :alt="image.alt"
        />
      </div>
    </div>
  </section>
</template>
