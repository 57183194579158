<script setup lang="ts">
import { Dayjs } from 'dayjs/esm'
import { computed } from 'vue'
import { formatDate } from '/~/utils/format'

const props = defineProps<{
  date?: Dayjs
}>()

const formattedDate = computed(() =>
  formatDate('daymonthyearnumeric', props.date)
)
</script>

<template>
  <div class="space-y-1.5 py-6">
    <div class="text-lg font-bold">Scheduled for</div>
    <div>{{ formattedDate }}</div>
  </div>
</template>
