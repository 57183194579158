import { ref } from 'vue'
import { createDate } from '/~/utils/format/date'
import { RecroomEvent } from '/~rec/core/event'
import { useRecProfile } from '/~rec/composables/profile'
import api from '/~rec/core/api'

const { myRecId } = useRecProfile()

const todayEvents = ref([])

const fetchingEvents = ref(false)

async function getTodayEvents() {
  fetchingEvents.value = true
  const from = createDate().startOf('day').toISOString()
  const to = createDate().add(5, 'day').endOf('day').toISOString()

  const { data } = await api.get(
    `/events?from=${from}&to=${to}&user=${myRecId.value}&perPage=30&status=yes,maybe,invited&types[]=custom`
  )

  const events = data.items
    .map((data) => new RecroomEvent(data))
    .filter((e) => !e.isDeclined)

  todayEvents.value = events
  fetchingEvents.value = false
}

async function getDirectoryProfile(id) {
  const response = await api.get(`/search/organisation-members?userId=${id}`)

  return response?.data?.items?.[0]
}

export const useDirectory = () => ({
  getTodayEvents,
  getDirectoryProfile,
  todayEvents,
  fetchingEvents,
})
