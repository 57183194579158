export default [
  '',
  '#23A1E7',
  '#6FCF97',
  '#E883E4',
  '#00CEC9',
  '#407479',
  '#48a19e',
  '#d49463',
  '#d39b8e',
  '#db0db9',
  '#52a1d9',
  '#27f5e6',
  '#256d19',
  '#0e717a',
  '#0F766E',
  '#44bdd2',
  '#51994a',
  '#b15c14',
  '#4b65c2',
  '#e10365',
  '#53aecb',
  '#63bd45',
  '#2234c1',
  '#59a889',
  '#4033a8',
  '#a2a6b9',
]
