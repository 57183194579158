<script>
import ActivitySummaryItem from '/~/components/activity/summary/activity-summary-item.vue'

export default {
  name: 'activity-summary-items',
  components: {
    ActivitySummaryItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activity: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <div v-if="items.length > 0" class="space-y-5">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="w-full"
      @click="$emit('item', item)"
    >
      <activity-summary-item v-if="item" :item="item" :activity="activity" />
    </div>
  </div>
</template>
