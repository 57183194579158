import { computed } from 'vue'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'
import { Countries } from '/~/dict/countries'

const { providerCountry } = useProvider()
const { getMask, states, translate } = useLocalization()
const { isRequiredAsteriskVisible } = useUI()

const bindingsByCountry = {
  postcode: {
    default: {
      label: translate('address.postcode'),
      validation: {
        name: translate('address.postcode'),
      },
      mask: getMask('postcode'),
    },
  },
  state: {
    AU: {
      maxlength: null,
    },
    default: {
      label: translate('address.state'),
      validation: {
        rules: 'required',
        name: translate('address.state'),
      },
      required: true,
      maxlength: 3,
    },
  },
  suburb: {
    default: {
      label: translate('address.suburb'),
      validation: {
        name: translate('address.suburb'),
      },
      maxlength: 255,
    },
  },
}

const countriesOptions = computed(() => {
  return Countries.map((country) => ({
    text: country.label,
    value: country.label,
  }))
})

const bindingsCountry = computed(() => providerCountry.value || 'default')

const postcodeBindings = computed(() => {
  return {
    ...bindingsByCountry.postcode.default,
    ...(bindingsByCountry.postcode[bindingsCountry.value] ?? {}),
  }
})

const stateBindings = computed(() => {
  return {
    ...bindingsByCountry.state.default,
    ...(bindingsByCountry.state[bindingsCountry.value] ?? {}),
  }
})

const suburbBindings = computed(() => {
  return {
    ...bindingsByCountry.suburb.default,
    ...(bindingsByCountry.suburb[bindingsCountry.value] ?? {}),
  }
})

const addressSchema = computed(() => {
  return {
    fields: [
      {
        type: 'address',
        key: 'address',
        label: 'Address',
        validations: ['required'],
        hideManualAddressTypeLabel: true,
      },
    ],
    types: {
      address: {
        fields: [
          {
            type: 'string',
            key: 'address',
            label: 'Street address',
            validation: {
              rules: 'required',
              name: 'Street address',
              vid: 'streetAddress',
            },
            requiredAsterisk: isRequiredAsteriskVisible.value,
          },
          {
            ...suburbBindings.value,
            type: 'string',
            key: 'suburb',
            validation: {
              ...suburbBindings.value.validation,
              rules: 'required',
              vid: 'suburb',
            },
            requiredAsterisk: isRequiredAsteriskVisible.value,
          },
          {
            ...postcodeBindings.value,
            type: 'string',
            key: 'postcode',
            validation: {
              ...postcodeBindings.value.validation,
              rules: 'required|postcode',
              vid: 'postcode',
            },
            requiredAsterisk: isRequiredAsteriskVisible.value,
          },
          {
            ...stateBindings.value,
            type: 'list',
            key: 'state',
            selectFirst: false,
            validation: {
              rules: 'required',
              vid: 'state',
            },
          },
        ],
        key: 'address',
      },
      state: {
        key: 'state',
        fields: states.value.map((state) => ({
          type: 'string',
          label: state.label,
          key: state.id,
        })),
      },
    },
  }
})

export function useAddressForm() {
  return {
    bindingsByCountry,
    countriesOptions,
    postcodeBindings,
    stateBindings,
    suburbBindings,
    addressSchema,
  }
}
