<script>
import { ValidationObserver } from 'vee-validate'
import { ref, computed } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import DrawerCancelTransaction from '/~/components/ewallet-menu/components/menu-pay-cancel-transaction.vue'
import MenuPaymentTo from '/~/components/ewallet-menu/components/rows/menu-payment-to.vue'
import { useForm } from '/~/composables/base/use-form'
import { usePayeeCheckout } from '/~/composables/checkout'
import { useCms } from '/~/composables/cms'
import { useUI } from '/~/composables/ui'

export default {
  name: 'profile-pay-details',
  components: {
    BaseAsidePage,
    BaseButton,
    BaseCurrencyInput,
    BaseInput,
    DrawerCancelTransaction,
    MenuPaymentTo,
    ValidationObserver,
  },
  setup() {
    const { isDarkThemeForEwallet } = useCms()
    const { payment, getProgramFees } = usePayeeCheckout()
    const { isRequiredAsteriskVisible } = useUI()
    const { validationObserverRef } = useForm()

    const drawerCancelTransactionRef = ref(null)

    const selectedPayee = computed(() => payment.payee ?? {})

    function onCancelClick() {
      drawerCancelTransactionRef.value.show()
    }

    return {
      isDarkThemeForEwallet,
      payment,
      drawerCancelTransactionRef,
      selectedPayee,
      getProgramFees,
      onCancelClick,
      isRequiredAsteriskVisible,
      validationObserverRef,
    }
  },
  computed: {
    isContinueDisabled() {
      return Boolean(
        !this.payment.reference ||
          this.payment.amount <= 0 ||
          this.validationObserverRef?.refs.reference?.flags.invalid
      )
    },
  },
  methods: {
    onContinueClick() {
      this.getProgramFees()

      this.$router.push({
        hash: '#profile-pay-from',
      })
    },
  },
}
</script>

<template>
  <base-aside-page
    title="Enter Details"
    no-padding
    :back="{ hash: '#profile-pay-to' }"
  >
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <div class="flex min-h-full flex-col px-5">
        <div class="grow">
          <div class="font-bold">Payee</div>
          <div class="mt-2.5">
            <menu-payment-to :to="selectedPayee" />
          </div>
          <div class="mt-12 font-bold">Reference</div>
          <base-input
            v-model="payment.reference"
            :validation="{
              rules: 'required|max:32|alpha_dash_spaces',
              name: 'Reference',
            }"
            nolabel
            required
            :required-asterisk="isRequiredAsteriskVisible"
            name="reference"
            placeholder="For you and your recepient..."
            input-class="bg-gray-50 rounded-lg px-4"
            entry-class="mt-2.5 h-12 rounded-lg"
            :border="false"
          />
          <div
            v-if="payment.reference"
            class="mt-[5px] text-right text-xs font-bold text-gray-300"
          >
            {{ payment.reference.length }}/32
          </div>
          <div class="mt-12 font-bold">Amount</div>
          <div class="mt-[30px] text-primary">
            <base-currency-input
              v-model="payment.amount"
              :allow-negative="false"
              :value-range="{
                min: 0,
              }"
              :disabled="false"
              :is-centrally="true"
              input-class="text-5xl font-bold text-primary"
            />
          </div>
        </div>
        <div class="w-full shrink-0 pb-5">
          <div class="grid grid-cols-2 gap-5">
            <base-button
              look="outlined-color"
              class="w-full"
              @click="onCancelClick"
            >
              Cancel
            </base-button>
            <base-button
              :disabled="isContinueDisabled"
              class="w-full"
              @click="handleSubmit(onContinueClick)"
            >
              Continue
            </base-button>
          </div>
        </div>
      </div>
    </validation-observer>
    <drawer-cancel-transaction ref="drawerCancelTransactionRef" />
  </base-aside-page>
</template>
