<script lang="ts">
import { computed } from 'vue'
import { nanoid } from 'nanoid'
import JsBarcode from 'jsbarcode'

const DEFAULT_OPTIONS = {
  height: 55,
  fontSize: 16,
  background: 'transparent',
}

export default {
  name: 'render-barcode',
  props: {
    barcode: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const barcodeOptions = computed(() => {
      return {
        ...DEFAULT_OPTIONS,
        width: (1 / props.barcode.length) * 30,
        ...(props.options || {}),
      }
    })

    return {
      barcodeOptions,
    }
  },
  data() {
    return {
      id: nanoid(),
    }
  },
  watch: {
    barcode(val) {
      this.render(val)
    },
  },
  mounted() {
    this.render(this.barcode)
  },
  methods: {
    render(value: string) {
      if (value && typeof value === 'string') {
        JsBarcode(`#barcode-${this.id}`, value, this.barcodeOptions)
      }
    },
  },
}
</script>

<template>
  <canvas v-if="barcode" :id="`barcode-${id}`" class="mx-auto max-w-full" />
</template>
