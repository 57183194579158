<script>
import modal from '/~/core/mdl'
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import RecRenderRelatedAvatars from '/~rec/components/render/render-related-avatars.vue'
import BaseHeader from '/~/components/base/header/base-header'
import BaseButton from '/~/components/base/button/base-button'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-post-nominate-success-model',
  components: {
    RecognitionHeader,
    RecRenderRelatedAvatars,
    BaseHeader,
    BaseButton,
    BaseMdl,
  },
  props: {
    nominee: {
      type: Object,
      required: true,
    },
    award: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { profile } = useRecProfile()

    return { me: profile.me }
  },
  computed: {
    from() {
      return {
        src: this.me.avatarUrl,
        abbr: this.me.initials,
      }
    },
    to() {
      return {
        src: this.nominee.avatarUrl,
        abbr: this.nominee.initials,
      }
    },
    entity() {
      return {
        src: this.award.image,
      }
    },
  },
  methods: {
    close() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" fullscreen="mobile" width="sm">
    <div class="flex h-full flex-col justify-between">
      <base-header class="text-white" type="closable" @close="close">
        <recognition-header icon="rec/nomination" animated />
      </base-header>

      <div class="flex h-full flex-col justify-center px-6 sm:px-[30px]">
        <h2 class="mb-[15px] text-center leading-tight">
          Thank you for your Nomination!
        </h2>

        <rec-render-related-avatars :from="from" :to="to" :entity="entity" />

        <div
          class="my-[15px] text-center text-sm font-bold text-eonx-neutral-600"
        >
          You nominated
          <span class="text-black">
            {{ nominee.fullName }}
          </span>
          for
          <span class="text-black">
            {{ award.title }}
          </span>
        </div>
      </div>
      <div class="mt-5 w-full px-6 pb-[30px] sm:px-[30px]">
        <base-button full-width @click="close">Got it</base-button>
      </div>
    </div>
  </base-mdl>
</template>
