<script setup lang="ts">
import { computed } from 'vue'
import ui from '/~/core/ui'
import Desktop from '../payee-option/desktop.vue'
import Mobile from '../payee-option/mobile.vue'

interface Props {
  option: any
  providerTitle: string
  showPayeeType?: boolean
  isSelected?: boolean
  noPadding?: boolean
  isOptionSelected?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showPayeeType: false,
  isSelected: false,
  noPadding: false,
  isOptionSelected: false,
})

const emit = defineEmits<{
  (event: 'click'): void
}>()

const isStatement = computed(() => props.option.type === 'statement')
const isBankAccount = computed(() => props.option.type === 'bankAccount')
const isBPay = computed(() => props.option.type === 'bpay')
const isPayee = computed(() => 'isProviderPayee' in props.option)
const isSponsored = computed(
  () => isPayee.value && props.option.isProviderPayee
)
const icon = computed(() =>
  isBankAccount.value ? 'v2/custom/bank-circle' : 'v2/custom/bpay-circle'
)
const number = computed(
  () =>
    props.option.statementAccount?.number ||
    props.option.accountNumber ||
    props.option.number
)
const name = computed(() => props.option.name || props.option.accountName)
const type = computed(() => {
  return isStatement.value
    ? 'Sponsored Biller'
    : isBPay.value
    ? 'BPAY'
    : isBankAccount.value
    ? 'Bank account'
    : ''
})

const component = computed(() => (ui.mobile ? Mobile : Desktop))

const onClick = () => {
  emit('click')
}
</script>

<template>
  <div
    class="flex w-full cursor-pointer items-center justify-between hover:bg-eonx-neutral-50"
    :class="{
      'bg-eonx-neutral-50': isSelected,
      'h-12': isOptionSelected || !ui.mobile,
    }"
    @click="onClick"
  >
    <component
      :is="component"
      :is-sponsored="isSponsored"
      :provider-title="providerTitle"
      :name="name"
      :number="number"
      :option="option"
      :is-b-pay="isBPay"
      :icon="icon"
      :is-payee="isPayee"
      :is-statement="isStatement"
      :is-option-selected="isOptionSelected"
    />

    <div
      v-if="showPayeeType"
      :class="{
        'text-primary': isStatement,
      }"
      class="hidden whitespace-nowrap text-sm font-bold leading-none text-eonx-neutral-600 md:block"
    >
      {{ type }}
    </div>
  </div>
</template>
