import { Dayjs } from 'dayjs'
import { createDate, formatDate } from '/~/utils/format/date'

type PayIdOrderParams = {
  id: string
  reference: string
  amount: number
  expiresAt: string
}

export class PayIdOrder {
  id: string
  reference: string
  amount: number
  expiresAt: Dayjs

  constructor(params: PayIdOrderParams) {
    this.id = params.id
    this.reference = params.reference
    this.amount = params.amount
    this.expiresAt = createDate(params.expiresAt)
  }

  get dateTime() {
    return this.expiresAt.format('D MMMM YYYY, h:mm a')
  }
}
