<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useBatchOrder } from '/~/composables/batch-order'

export default {
  name: 'bank-file-uploaded-file',
  components: {
    BaseIcon,
  },
  setup() {
    const { batchOrder, onCancelBatchOrder } = useBatchOrder()

    return {
      batchOrder,
      onCancelBatchOrder,
    }
  },
}
</script>

<template>
  <div
    class="flex items-center self-start overflow-hidden rounded-md border border-gray-300 bg-white font-bold text-eonx-neutral-800 text-default"
  >
    <base-icon svg="billpay/paperclip" :size="16" class="m-[5px]" />
    <div class="flex items-center">
      {{ batchOrder.fileName }}
      <div @click="onCancelBatchOrder">
        <base-icon
          svg="billpay/cross"
          :size="26"
          class="cursor-pointer p-[5px] hover:bg-eonx-neutral-50"
        />
      </div>
    </div>
  </div>
</template>
