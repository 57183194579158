<script>
import DrawerEPurchaseTerms from '/~/components/drawer/views/e-purchases/drawer-e-purchase-terms.vue'

export default {
  name: 'profile-e-purchase-terms',
  components: {
    DrawerEPurchaseTerms,
  },
}
</script>

<template>
  <drawer-e-purchase-terms />
</template>
