import cdn from '/~/utils/cdn'

export class LoyaltyCard {
  constructor(raw) {
    this.raw = raw || {}
  }

  get id() {
    return this.raw.external_id
  }

  get isCatalog() {
    return this.slug !== 'other'
  }

  get slug() {
    return this.raw.catalogue_card_slug || this.raw.slug || ''
  }

  get name() {
    return this.raw.name || ''
  }

  get color() {
    return this.raw.color || ''
  }

  get barcode() {
    return this.raw.code || ''
  }

  get logo() {
    return getImageSet(this.raw.logo)
  }

  get frontImage() {
    return getImageSet(this.raw.front_image_url)
  }

  get backImage() {
    return getImageSet(this.raw.back_image_url)
  }

  get hasManyImages() {
    const hasImages = Boolean(this.frontImage.src || this.backImage.src)

    if (this.isCatalog) {
      return Boolean(this.logo.src) && hasImages
    }

    return hasImages
  }

  get image() {
    return this.logo || this.frontImage || this.backImage
  }
}

function getImageSet(url) {
  if (url) {
    if (/^\//.test(url)) {
      const urlWithTimestamp = `${url}?t=${Date.now()}`

      return {
        src: urlWithTimestamp,
        srcset: urlWithTimestamp,
      }
    }

    const postfix = Date.now()
    const normalSize = cdn(url).preview('390x240').quality('best').url()
    const doubleSize = cdn(url).preview('780x480').quality('best').url()

    return {
      src: `${normalSize}?t=${postfix}`,
      srcset: `${doubleSize}?t=${postfix}`,
    }
  }

  return {
    src: '',
    srcset: '',
  }
}
