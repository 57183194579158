<script>
import modal from '/~/core/mdl'
import { RecroomEvent } from '/~rec/core/event'
import { ref, provide } from 'vue'
import ui from '/~/core/ui'

export default {
  name: 'rec-event-root',
  props: {
    eventId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const event = ref(null)
    const loading = ref(true)

    const getEvent = async () => {
      if (props.eventId && ui.desktop) {
        event.value = await RecroomEvent.fetchById(props.eventId)

        await event.value.members.refresh()
        await event.value.posts.refresh()
        loading.value = false
      } else {
        modal.show('rec-event-details', {
          props: { eventId: props.eventId },
        })
      }
    }

    getEvent()
    provide('eventLoading', loading)

    return {
      event,
      loading,
    }
  },
}
</script>

<template>
  <router-view class="hidden md:block" :event="event" :loading="loading" />
</template>
