/**
 * To support a new extension you need to do the following:
 *  1. Add a const with its name under 'SUPPORTED EXTENSIONS'
 *  2. Add a new icon const under 'ICON NAMES' if extension does not have a group
 * and upload a new icon to icomoon with the same name as the const
 *    NOTE: If const has a group, include it to its respective group
 *  3. Add a new key to 'iconExtensionsMap' with your new extension or
 * extension group as the value
 *  4. Add the extension const to the 'acceptedExtensions' array
 */
import api from '/~rec/core/api'
import isEmpty from 'lodash-es/isEmpty'
import { RecroomEarnPost } from '/~rec/core/earn-post'

// SUPPORTED EXTENSIONS
const DOC_EXTENSION = 'doc'
const DOCX_EXTENSION = 'docx'
const ODT_EXTENSION = 'odt'
const SXW_EXTENSION = 'sxw'
const PUB_EXTENSION = 'pub'
const MYO_EXTENSION = 'myo'
const MYOB_EXTENSION = 'myob'
const GDOC_EXTENSION = 'gdoc'
const TXT_EXTENSION = 'txt'
const XML_EXTENSION = 'xml'
const ZIP_EXTENSION = 'zip'
const XLS_EXTENSION = 'xls'
const XLSX_EXTENSION = 'xlsx'
const CSV_EXTENSION = 'csv'
const PDF_EXTENSION = 'pdf'
const PPT_EXTENSION = 'ppt'
const PPTX_EXTENSION = 'pptx'
const BMP_EXTENSION = 'bmp'
const EXIF_EXTENSION = 'exif'
const GIF_EXTENSION = 'gif'
const JPEG_EXTENSION = 'jpeg'
const JPG_EXTENSION = 'jpg'
const PNG_EXTENSION = 'png'
const PSD_EXTENSION = 'psd'
const PDD_EXTENSION = 'pdd'
const TIF_EXTENSION = 'tif'
const TIFF_EXTENSION = 'tiff'
const GP3_EXTENSION = '3gp'
const AVI_EXTENSION = 'avi'
const MOV_EXTENSION = 'mov'
const MPEG_EXTENSION = 'mpeg'
const MPG_EXTENSION = 'mpg'
const MPE_EXTENSION = 'mpe'
const MKV_EXTENSION = 'mkv'
const MP4_EXTENSION = 'mp4'
const WMA_EXTENSION = 'wma'
const MP3_EXTENSION = 'mp3'
const SWA_EXTENSION = 'swa'
const SKETCH_EXTENSION = 'sketch'
const AI_EXTENSION = 'ai'
const INDD_EXTENSION = 'indd'
const PS_EXTENSION = 'ps'

// ICON NAMES
const ICON_NAME_AI = 'ai'
const ICON_NAME_DOCS = 'doc'
const ICON_NAME_SPREADSHEET = 'csv'
const ICON_NAME_INDD = 'indd'
const ICON_NAME_AUDIO = 'mp3'
const ICON_NAME_PDF = 'pdf'
const ICON_NAME_PLAY = 'play'
const ICON_NAME_PPT = 'ppt'
const ICON_NAME_PS = 'ps'
const ICON_NAME_SKETCH = 'sketch'
const ICON_NAME_ZIP = 'zip'
const ICON_NAME_VIDEO = 'mp4'

// GROUPPED EXTENSION TYPES
const DOCS_EXTENSION_GROUP = [
  DOC_EXTENSION,
  DOCX_EXTENSION,
  ODT_EXTENSION,
  SXW_EXTENSION,
  PUB_EXTENSION,
  MYO_EXTENSION,
  MYOB_EXTENSION,
  GDOC_EXTENSION,
  TXT_EXTENSION,
  XML_EXTENSION,
]
const ZIP_EXTENSION_GROUP = [ZIP_EXTENSION]
const SPREADSHEET_EXTENSION_GROUP = [
  XLS_EXTENSION,
  XLSX_EXTENSION,
  CSV_EXTENSION,
]
const PPT_EXTENSION_GROUP = [PPT_EXTENSION, PPTX_EXTENSION]
const VIDEO_EXTENSION_GROUP = [
  GP3_EXTENSION,
  AVI_EXTENSION,
  MOV_EXTENSION,
  MPEG_EXTENSION,
  MPG_EXTENSION,
  MPE_EXTENSION,
  MKV_EXTENSION,
  MP4_EXTENSION,
]
const AUDIO_EXTENSION_GROUP = [WMA_EXTENSION, MP3_EXTENSION, SWA_EXTENSION]

export const iconExtensionsMap = (function () {
  const map = new Map()

  map.set(ICON_NAME_DOCS, DOCS_EXTENSION_GROUP)
  map.set(ICON_NAME_SPREADSHEET, SPREADSHEET_EXTENSION_GROUP)
  map.set(ICON_NAME_AI, AI_EXTENSION)
  map.set(ICON_NAME_INDD, INDD_EXTENSION)
  map.set(ICON_NAME_AUDIO, AUDIO_EXTENSION_GROUP)
  map.set(ICON_NAME_PDF, PDF_EXTENSION)
  map.set(ICON_NAME_PS, PS_EXTENSION)
  map.set(ICON_NAME_PPT, PPT_EXTENSION_GROUP)
  map.set(ICON_NAME_SKETCH, SKETCH_EXTENSION)
  map.set(ICON_NAME_ZIP, ZIP_EXTENSION_GROUP)
  map.set(ICON_NAME_VIDEO, VIDEO_EXTENSION_GROUP)
  return map
})()

export const acceptedExtensionIcons = [
  ICON_NAME_AI,
  ICON_NAME_DOCS,
  ICON_NAME_SPREADSHEET,
  ICON_NAME_INDD,
  ICON_NAME_AUDIO,
  ICON_NAME_PDF,
  ICON_NAME_PLAY,
  ICON_NAME_PPT,
  ICON_NAME_PS,
  ICON_NAME_SKETCH,
  ICON_NAME_ZIP,
  ICON_NAME_VIDEO,
]

export const acceptedExtensions = [
  DOC_EXTENSION,
  DOCX_EXTENSION,
  ODT_EXTENSION,
  SXW_EXTENSION,
  PUB_EXTENSION,
  MYO_EXTENSION,
  MYOB_EXTENSION,
  GDOC_EXTENSION,
  TXT_EXTENSION,
  XML_EXTENSION,
  ZIP_EXTENSION,
  XLS_EXTENSION,
  XLSX_EXTENSION,
  CSV_EXTENSION,
  PDF_EXTENSION,
  PPT_EXTENSION,
  PPTX_EXTENSION,
  BMP_EXTENSION,
  EXIF_EXTENSION,
  GIF_EXTENSION,
  JPEG_EXTENSION,
  JPG_EXTENSION,
  PNG_EXTENSION,
  PSD_EXTENSION,
  PDD_EXTENSION,
  TIF_EXTENSION,
  TIFF_EXTENSION,
  GP3_EXTENSION,
  AVI_EXTENSION,
  MOV_EXTENSION,
  MPEG_EXTENSION,
  MPG_EXTENSION,
  MPE_EXTENSION,
  MKV_EXTENSION,
  MP4_EXTENSION,
  WMA_EXTENSION,
  MP3_EXTENSION,
  SWA_EXTENSION,
  SKETCH_EXTENSION,
  AI_EXTENSION,
  INDD_EXTENSION,
  PS_EXTENSION,
]

export class RecroomAttachment {
  constructor(raw, postRaw) {
    this.raw = raw
    this.postRaw = postRaw
  }

  get id() {
    return this.raw.id || `fake-id-${this.raw.file_size}`
  }

  get name() {
    return this.raw.name || ''
  }

  get type() {
    return this.raw.type
  }

  get fileSize() {
    return this.raw.file_size
  }

  get cdn() {
    return this.raw.cdn
  }

  get hasPreview() {
    return ['image', 'image/gif', 'video'].indexOf(this.type) >= 0
  }

  get earnValue() {
    return this.raw.earn_value
  }

  get postHasEarn() {
    return this.postRaw && this.postRaw.has_earn_config
  }

  get hasEarn() {
    return !isEmpty(this.earnConfigs) || !isEmpty(this.earnValue)
  }

  get earnConfigs() {
    return (
      this.raw.earn_configs &&
      this.raw.earn_configs.map(
        (earn) => new RecroomEarnPost(earn, this.postRaw)
      )
    )
  }

  get readableSize() {
    const { fileSize } = this

    if (!fileSize) return ''

    const rate = Math.floor(Math.log(this.fileSize) / Math.log(1024))
    const size = (this.fileSize / Math.pow(1024, rate)).toFixed(2) * 1
    const sfx = ['B', 'KB', 'MB', 'GB', 'TB'][rate]

    return `${size} ${sfx}`
  }

  get ext() {
    return this.name.split('.').pop()
  }

  get inline() {
    const data = {
      id: this.id,
      cdn: this.cdn,
      name: this.name,
      type: this.type,
      file_size: this.fileSize,
    }

    /**
     * Eachtime, when post get update with attachment
     * We are sending the new list of attachments
     * where we lost the earn_config that was set to each
     * individual attachment.
     * so, we are adding the earn_config if we already have earn_configs
     * to each attachment object.
     */

    if (this.hasEarn) {
      const savedConfig = this.earnConfigs && this.earnConfigs[0]
      const earnValue = (savedConfig && savedConfig.earnValue) || this.earnValue
      const action = (savedConfig && savedConfig.action) || this.raw.action

      data.earn_configs = [
        {
          action: action,
          earn_value: earnValue,
          currency: 'points', // has to be dynamic from org currency
          earn_strategy: 'instant',
        },
      ]
    }

    return data
  }

  get icon() {
    let extKey = 'unknown'

    for (const [key, value] of iconExtensionsMap) {
      if (value.includes(this.ext)) extKey = key
    }

    return `rec/extension/${extKey}`
  }

  async submitView() {
    const response = await api.post(`/attachments/${this.id}/view`)

    return response
  }
}
