<script>
import ui from '/~/core/ui'
import { usePayeeCheckout } from '/~/composables/checkout'
import { initDirectDebitsConfirmationModal } from '/~/composables/modals'
import { usePaymentMethods } from '/~/composables/payment-methods'
import PaymentMakeDesk from './payment-make.desk.vue'
import PaymentMakeMobile from './payment-make.mobile.vue'

export default {
  name: 'payments-make-v3',
  components: {
    PaymentMakeDesk,
    PaymentMakeMobile,
  },
  setup() {
    const { creditCards } = usePaymentMethods()
    const { selectedCard } = usePayeeCheckout()

    initDirectDebitsConfirmationModal()

    return {
      creditCards,
      selectedCard,
      ui,
    }
  },
  watch: {
    creditCards(cards) {
      if (
        this.selectedCard &&
        !cards.some((item) => item.id === this.selectedCard.id)
      ) {
        this.selectedCard = null
      }
    },
  },
}
</script>

<template>
  <payment-make-desk v-if="!ui.mobile" />
  <payment-make-mobile v-else />
</template>
