<script>
import BaseFab from '/~/components/base/fab/base-fab.vue'
export default {
  name: 'earn-how-to-earn-mobile',
  components: {
    BaseFab,
  },
}
</script>

<template>
  <base-fab icon="rec/info" @click="$emit('show-how-to-earn')" />
</template>
