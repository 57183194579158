<script>
import { ref, computed } from 'vue'
import { floorFigure } from '/~/utils/format'
import { formatAmount } from '/~/utils/format/money'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { usePayeeCheckout } from '/~/composables/checkout'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import PaymentsSplitFormPoints from './payments-split-form-points.v2.vue'

export default {
  name: 'payments-split-form-v2',
  components: {
    BaseButton,
    BaseIcon,
    PaymentsSplitFormPoints,
  },
  props: {
    currentItem: {
      type: Object,
      required: true,
    },
    selectedPoints: {
      type: Object,
      default: null,
    },
    burnPointsRate: {
      type: Number,
      default: 1,
    },
    selectedAmount: {
      type: Number,
      default: 0,
    },
    selectedEwallet: {
      type: Object,
      default: null,
    },
    selectedCard: {
      type: Object,
      default: null,
    },
    selectedBankAccount: {
      type: Object,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  emits: ['confirm', 'discard'],
  setup(props, { emit }) {
    const { selectedEwallet, selectedCard, selectedBankAccount } =
      usePayeeCheckout()

    const isPoints = ref(props.currentItem.type === PaymentMethodType.points)
    const pointsToUse = ref(props.selectedPoints?.usePoints ?? 0)
    const pointsAmount = computed(() =>
      floorFigure(pointsToUse.value * props.burnPointsRate)
    )

    const remainingAmount = computed(() => props.total - props.selectedAmount)

    function onDiscard() {
      emit('discard')
    }

    function onConfirm() {
      emit('confirm', {
        pointsToUse: pointsToUse.value,
        pointsAmount: pointsAmount.value,
      })
    }

    if (selectedEwallet.value) {
      selectedEwallet.value.calculatedAmount = 0
    }
    if (selectedCard.value) {
      selectedCard.value.calculatedAmount = 0
    }
    if (selectedBankAccount.value) {
      selectedBankAccount.value.calculatedAmount = 0
    }

    return {
      pointsToUse,
      pointsAmount,
      isPoints,
      remainingAmount,
      formatAmount,
      onDiscard,
      onConfirm,
    }
  },
}
</script>

<template>
  <div class="mt-2.5 flex w-full flex-col items-center justify-center md:mt-0">
    <div class="flex flex-col items-center">
      <base-icon svg="v2/custom/points-token" :size="57" class="text-primary" />
      <span class="mt-2.5 text-xl font-bold">Pay with Points</span>
    </div>

    <span class="mt-2.5 text-center text-sm">
      Slide to allocate the amount of points you wish to use
    </span>

    <payments-split-form-points
      v-model="pointsToUse"
      :burn-points-rate="burnPointsRate"
      :amount-to-pay="remainingAmount"
      :total="total"
    />

    <span class="mt-12 font-bold text-eonx-neutral-600">Remaining to pay</span>

    <div class="mt-2.5 flex items-start font-bold">
      <span class="mr-[5px] text-2xl leading-tight">$</span>
      <span class="text-5xl leading-10">
        {{ formatAmount(remainingAmount) }}
      </span>
    </div>

    <div class="mt-12 w-full">
      <base-button full-width @click="onConfirm">
        <span class="text-sm">Confirm</span>
      </base-button>
    </div>
  </div>
</template>
