<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import PasswordConfirmationForm from '/~/components/drawer/views/personal-details/views/edit-profile/password-confirmation-form.vue'

export default {
  name: 'password-confirmation-modal',
  components: {
    BaseMdl,
    PasswordConfirmationForm,
  },
  setup() {
    return {
      modal,
    }
  },
}
</script>

<template>
  <base-mdl :layout="false" width="xs">
    <password-confirmation-form
      @submit="$emit('submit', $event)"
      @hide="modal.hide()"
    />
  </base-mdl>
</template>
