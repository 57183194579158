<script>
import BillPayGiftcardsCatalog from '/~/extensions/giftcards/templates/bill-payments/views/giftcards-catalog.vue'
import GcCatalog from '../components/gc-catalog.vue'
import GcFilterMobile from '/~/extensions/gift-cards-cinema/components/gc-filter.mobile.vue'
import GcFilterDesktop from '/~/extensions/gift-cards-cinema/components/gc-filter.desktop.vue'

export default {
  name: 'gift-cards-cinema-catalog',
  components: {
    GcCatalog,
    GcFilterMobile,
    GcFilterDesktop,
  },
  extends: BillPayGiftcardsCatalog,
}
</script>
