<script setup lang="ts">
import { computed } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

const props = withDefaults(
  defineProps<{
    isPhysical?: boolean
    digitalLabel?: string
    physicalLabel?: string
    size?: 'sm' | 'md'
  }>(),
  {
    isPhysical: false,
    digitalLabel: 'Digital',
    physicalLabel: 'Physical',
    size: 'md',
  }
)

const iconSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return props.isPhysical ? 12 : 10
    case 'md':
    default:
      return 'xs'
  }
})
</script>

<template>
  <div
    class="flex items-center justify-center rounded-full border border-zinc-100 font-bold text-white"
    :class="{
      'px-2.5 py-0.5': size === 'md',
      'px-[5px]': size === 'sm',
      'bg-teal-500': isPhysical,
      'bg-indigo-500': !isPhysical,
    }"
  >
    <base-icon
      :svg="isPhysical ? 'plain/box-v2' : 'plain/monitor'"
      :size="iconSize"
    />
    <span
      class="ml-[5px]"
      :class="{
        'text-sm': size === 'md',
        'text-xs': size === 'sm',
      }"
    >
      {{ isPhysical ? physicalLabel : digitalLabel }}
    </span>
  </div>
</template>
