<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useEwallet } from '/~/composables/ewallet'

const router = useRouter()
const { ewalletBalance, fetchingEwallet } = useEwallet()

function onClick() {
  router.push({ hash: '#profile/withdraw-funds' })
}
</script>

<template>
  <button
    class="group flex h-12 items-center justify-center rounded border-2 border-primary active:border-primary-darken disabled:border-eonx-neutral-200"
    @click="onClick"
  >
    <div class="flex h-full items-center p-3">
      <base-icon
        svg="heroicons/outline/currency-dollar"
        size="md"
        class="mr-0.5"
      />
      <base-loader v-if="fetchingEwallet" size="xs" class="px-5" />
      <div v-else class="text-xl font-semibold text-eonx-neutral-800">
        {{ ewalletBalance }}
      </div>
    </div>
    <div
      class="rounded-r-[4px] bg-primary p-3 font-semibold -tracking-[0.01em] text-white group-active:bg-primary-darken group-disabled:bg-eonx-neutral-200"
    >
      Transfer Cash
    </div>
  </button>
</template>
