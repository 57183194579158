<script>
import ui from '/~/core/ui'
import DefaultEwProductType from '/~/components/ewallet/product/ew-product-type.vue'
import { useCms } from '/~/composables/cms'

export default {
  extends: DefaultEwProductType,
  props: {
    digitalIcon: {
      type: String,
      default: 'plain/ewallet',
    },
    physicalIcon: {
      type: String,
      default: 'australiapost/truck2',
    },
    physicalByLabel: {
      type: String,
      default: 'Supplier',
    },
  },
  setup() {
    const { ewalletLabels } = useCms()

    return {
      ewalletLabels,
    }
  },
  computed: {
    title() {
      let type
      let postfix
      let title
      const isMobile = this.mobileShort && ui.mobile

      if (this.physical) {
        type = 'Physical'

        if (this.giftCard) {
          postfix = 'delivered by AusPost'
        } else {
          postfix = `delivered by ${this.physicalByLabel}`
        }
      } else {
        title = `Delivered to ${this.ewalletLabels.yourEwallet}`
      }

      if (isMobile) {
        return postfix[0].toUpperCase() + postfix.slice(1)
      }

      return title ? title : `${type} product ${postfix}`
    },
  },
}
</script>

<template>
  <div class="flex items-center text-sm leading-tight text-eonx-neutral-600">
    <template v-if="physical">
      <template v-if="hasGift">
        <base-icon :size="iconSize || 'md'" class="mr-2.5" :svg="giftIcon" />
        <slot name="physical">
          <span>
            {{ title }}
          </span>
        </slot>
      </template>
      <template v-else-if="showDelivery">
        <base-icon
          :size="iconSize || 'md'"
          :svg="physicalIcon"
          class="mr-2.5"
        />
        <span>
          {{ title }}
        </span>
      </template>
    </template>
    <template v-else>
      <template v-if="hasGift">
        <base-icon :size="iconSize || 'sm'" :svg="giftIcon" class="mr-[15px]" />
        <slot name="digital">
          <span>
            {{ title }}
          </span>
        </slot>
      </template>
      <template v-else-if="showDelivery">
        <base-icon :size="iconSize || 20" :svg="digitalIcon" class="mr-2.5" />
        <span>
          {{ title }}
        </span>
      </template>
    </template>
  </div>
</template>
