<script>
import debounce from 'lodash-es/debounce'
import { ref, watch, nextTick } from 'vue'
import ui from '/~/core/ui'
import { useDining } from '/~/extensions/dining/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseSelectAsync from '/~/components/base/select-async/base-select-async.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import { useLocalization } from '/~/composables/localization'
import DiningCheckboxes from './dining-checkboxes.vue'
import DiningPriceCheckboxes from './dining-price-checkboxes.vue'
import ToggleMap from './dining-toggle-map.vue'

export default {
  name: 'dining-filter-desktop',
  components: {
    DiningCheckboxes,
    DiningPriceCheckboxes,
    ToggleMap,
    EwFilters,
    BaseInput,
    BaseSelectAsync,
    BaseSelect,
    BaseIcon,
  },
  setup() {
    const {
      mapState,
      searchInput,
      cuisines,
      selectedCuisine,
      features,
      selectedFeatures,
      priceRanges,
      selectedPriceRanges,
      selectedState,
      isFiltersSelected,
      resetFilters,
      getSearchResults,
      getRelativeAddress,
      syncState,
      getLocations,
    } = useDining()
    const { states } = useLocalization()

    const localSearchQuery = ref(searchInput.value)
    const searchFocused = ref(false)
    const hasOpenSelect = ref(false)

    watch(searchInput, (value) => {
      // NOTE: do not update local search value if input have focus
      if (!searchFocused.value) {
        localSearchQuery.value = value
      }
    })

    const onSearchInput = debounce(function (value) {
      searchInput.value = value
      getSearchResults(value)
    }, 350)

    function onSearchBlur() {
      searchFocused.value = false
      searchInput.value = localSearchQuery.value
    }

    function onSearchEnter() {
      searchInput.value = localSearchQuery.value
    }

    return {
      mapState,
      searchInput,
      cuisines,
      selectedCuisine,
      features,
      selectedFeatures,
      priceRanges,
      selectedPriceRanges,
      selectedState,
      isFiltersSelected,
      resetFilters,
      getSearchResults,
      getRelativeAddress,
      syncState,
      getLocations,
      localSearchQuery,
      searchFocused,
      onSearchInput,
      onSearchBlur,
      onSearchEnter,
      ui,
      hasOpenSelect,
      states,
    }
  },
  data() {
    return {
      state: '',
      isLocalArea: true,
    }
  },
  computed: {
    stateOptions() {
      /**
       * Got all these place ids from google map
       * https://developers.google.com/places/web-service/place-id
       */

      const allStates = this.states.map((state) => ({
        text: state.label,
        value: state.id,
        placeId: state.placeId,
      }))

      allStates.unshift({
        text: 'Select state',
        value: '',
      })

      return allStates
    },
    isMapVisible() {
      return this.mapState && this.mapState.visible
    },
    priceRangesFormatted() {
      return Array.isArray(this.priceRanges)
        ? this.priceRanges?.map((priceRange) => {
            priceRange.label = '$'.repeat(+priceRange.label)

            return priceRange
          })
        : []
    },
  },
  mounted() {
    this.initFilters()
  },
  methods: {
    initFilters() {
      const { search, state, features, cuisines, priceRanges } =
        this.$route.query

      if (cuisines) {
        this.selectedCuisine = cuisines
      }
      if (priceRanges) {
        const priceRangesArray = priceRanges.split(';').filter((item) => item)

        this.selectedPriceRanges = priceRangesArray
      }
      if (features) {
        const featuresArray = features.split(';').filter((item) => item)

        this.selectedFeatures = featuresArray
      }
      if (state) {
        this.state = state
        this.onStateChanged(state)
      }
      if (search) {
        this.onSearch(search)
      }
    },
    onLocationSelect(item) {
      if (item.placeId) {
        this.isLocalArea = false
        this.resetLocation()
        this.mapState.setPlace({
          placeId: item.placeId,
        })
      } else if (item.source === 'GoodFood') {
        this.$router.push({
          name: 'giftcards-retailer',
          params: {
            slug: 'good-food',
          },
        })
      } else {
        this.$router.push({
          name: 'dining-item',
          params: {
            slug: item.slug,
          },
        })
      }
    },
    onSearch: debounce(function (inputValue) {
      this.searchInput = inputValue
      this.getSearchResults(inputValue)
    }, 350),
    async onStateChanged(state) {
      this.resetFilters()
      const selectedState = this.stateOptions.filter(
        (region) => region.value === state
      )[0]

      if (state === '') {
        this.resetLocation()
        this.resetMap()
        this.isLocalArea = true
      } else {
        this.isLocalArea = false
        this.mapState.setPlace({
          placeId: selectedState.placeId,
        })
        this.selectedState = state
      }
    },
    resetLocation() {
      this.selectedState = ''
      this.state = ''
    },
    resetMap() {
      this.syncState({
        map: this.mapState.visible,
      })
    },
    onCuisineInput(value) {
      this.selectedCuisine = value
      this.getLocations()
    },
    onPriceInput(value) {
      this.selectedPriceRanges = value
      this.getLocations()
    },
    onFeatureInput(value) {
      this.selectedFeatures = value
      this.getLocations()
    },
    onMapToggle() {
      this.getLocations()
    },
    clear() {
      this.resetFilters()
      this.resetLocation()
      nextTick(() => {
        this.getLocations({ ignoreBoundingBox: true })
      })
    },
    onSelectClose() {
      setTimeout(() => {
        this.hasOpenSelect = false
      }, 0)
    },
  },
}
</script>

<template>
  <div class="mx-auto w-full max-w-7xl p-2.5 sm:p-5 sm:pb-2.5">
    <div class="flex items-center space-x-2.5 sm:space-x-5">
      <base-select-async
        v-if="isMapVisible"
        :fetch="getRelativeAddress"
        icon="plain/search"
        placeholder="Search locations"
        class="grow"
        :look="!ui.mobile ? 'rounded' : ''"
        @change="onLocationSelect"
      />
      <base-input
        v-else
        v-model="localSearchQuery"
        placeholder="Search restaurant"
        class="grow pt-0"
        look="rounded"
        icon-plain
        nolabel
        clearable
        autofocus
        @input="onSearchInput"
        @focus="searchFocused = true"
        @blur="onSearchBlur"
        @keypress.enter="onSearchEnter"
      >
        <template #icon>
          <div class="ml-[15px] mr-2.5 flex items-center justify-center">
            <base-icon
              svg="plain/search-v2"
              class="text-eonx-neutral-600"
              :size="19"
            />
          </div>
        </template>
      </base-input>
      <toggle-map v-if="!ui.mobile" class="mx-2.5" @change="onMapToggle" />
      <ew-filters
        title="Filters"
        :is-filters-selected="isFiltersSelected"
        :close-on-click-away="!hasOpenSelect"
        @clear="clear"
      >
        <div class="my-5 space-y-5 px-6">
          <toggle-map v-if="ui.mobile" @change="onMapToggle" />

          <base-select
            v-model="state"
            no-label
            fullwidth-popup
            :options="stateOptions"
            @input="onStateChanged"
            @open="hasOpenSelect = true"
            @close="onSelectClose"
          />

          <base-select
            no-label
            fullwidth-popup
            :value="selectedCuisine || ''"
            :disabled="!cuisines || !cuisines.length"
            :options="
              (cuisines || []).map((category) => {
                return {
                  text: category.label,
                  value: category.id || '',
                }
              })
            "
            @input="onCuisineInput"
            @open="hasOpenSelect = true"
            @close="onSelectClose"
          />

          <dining-checkboxes
            v-if="features.length > 0"
            :value="selectedFeatures"
            :filters="features"
            title="Amenities"
            @input="onFeatureInput"
          />

          <dining-price-checkboxes
            :value="selectedPriceRanges"
            :filters="priceRanges"
            title="Price Range"
            @input="onPriceInput"
          />
        </div>
      </ew-filters>
    </div>
    <div
      v-if="isLocalArea"
      class="mt-2.5 text-sm italic leading-tight text-eonx-neutral-600"
    >
      *The dining listing is currently being filtered by restaurants in your
      area. In order to view more restaurants simply adjust the below filter
      settings.
    </div>
  </div>
</template>
