<script>
export default {
  name: 'rec-event-status',
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    isMe: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      const members = this.event?.members?.hits
      const myStatus = this.event?.raw?.current_user?.status

      return this.isMe
        ? myStatus
        : members.find((item) => item.id === this.user.id)?.status
    },
  },
}
</script>

<template>
  <div
    v-if="status === 'yes'"
    class="flex items-center rounded-full bg-success-700 py-0.5 px-1.5 text-sm font-semibold text-white"
  >
    <svg
      class="mr-[5px] h-5 w-5"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99959 14.3999C11.5342 14.3999 14.3996 11.5346 14.3996 7.99996C14.3996 4.46535 11.5342 1.59998 7.99959 1.59998C4.46498 1.59998 1.59961 4.46535 1.59961 7.99996C1.59961 11.5346 4.46498 14.3999 7.99959 14.3999ZM10.9653 6.96565C11.2777 6.65323 11.2777 6.1467 10.9653 5.83428C10.6529 5.52186 10.1463 5.52186 9.8339 5.83428L7.19959 8.46859L6.16528 7.43427C5.85286 7.12186 5.34633 7.12186 5.03392 7.43427C4.7215 7.74669 4.7215 8.25322 5.03392 8.56564L6.63391 10.1656C6.94633 10.4781 7.45286 10.4781 7.76528 10.1656L10.9653 6.96565Z"
        fill="white"
      />
    </svg>

    <span class="whitespace-nowrap">Going</span>
  </div>
  <div
    v-else-if="status === 'no'"
    class="flex items-center rounded-full bg-error-700 py-0.5 px-1.5 text-sm font-semibold text-white"
  >
    <svg
      class="mr-[5px] h-5 w-5"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99959 14.3999C11.5342 14.3999 14.3996 11.5346 14.3996 7.99996C14.3996 4.46535 11.5342 1.59998 7.99959 1.59998C4.46498 1.59998 1.59961 4.46535 1.59961 7.99996C1.59961 11.5346 4.46498 14.3999 7.99959 14.3999ZM6.96528 5.83428C6.65286 5.52186 6.14633 5.52186 5.83391 5.83428C5.52149 6.1467 5.52149 6.65323 5.83391 6.96565L6.86822 7.99996L5.83391 9.03427C5.52149 9.34669 5.52149 9.85322 5.83391 10.1656C6.14633 10.4781 6.65286 10.4781 6.96528 10.1656L7.99959 9.13133L9.03391 10.1656C9.34632 10.4781 9.85285 10.4781 10.1653 10.1656C10.4777 9.85322 10.4777 9.34669 10.1653 9.03427L9.13096 7.99996L10.1653 6.96565C10.4777 6.65323 10.4777 6.1467 10.1653 5.83428C9.85285 5.52186 9.34632 5.52186 9.03391 5.83428L7.99959 6.86859L6.96528 5.83428Z"
        fill="white"
      />
    </svg>
    <span class="whitespace-nowrap">Can’t go</span>
  </div>
  <div
    v-else
    class="flex items-center rounded-full bg-warning-700 py-0.5 px-1.5 text-sm font-semibold text-white"
  >
    <svg
      class="mr-[5px] h-5 w-5"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3996 7.99996C14.3996 11.5346 11.5342 14.3999 7.99959 14.3999C4.46498 14.3999 1.59961 11.5346 1.59961 7.99996C1.59961 4.46535 4.46498 1.59998 7.99959 1.59998C11.5342 1.59998 14.3996 4.46535 14.3996 7.99996ZM7.9996 5.59997C7.7045 5.59997 7.44567 5.75934 7.30614 6.00054C7.08491 6.38299 6.59553 6.51368 6.21308 6.29245C5.83063 6.07121 5.69994 5.58183 5.92118 5.19939C6.33494 4.48411 7.11026 3.99997 7.9996 3.99997C9.32508 3.99997 10.3996 5.07448 10.3996 6.39996C10.3996 7.44494 9.73175 8.33392 8.7996 8.66339V8.79996C8.7996 9.24178 8.44143 9.59995 7.9996 9.59995C7.55778 9.59995 7.19961 9.24178 7.19961 8.79996V7.99996C7.19961 7.55813 7.55778 7.19996 7.9996 7.19996C8.44143 7.19996 8.7996 6.84179 8.7996 6.39996C8.7996 5.95814 8.44143 5.59997 7.9996 5.59997ZM7.99959 11.9999C8.44142 11.9999 8.79959 11.6418 8.79959 11.2C8.79959 10.7581 8.44142 10.4 7.99959 10.4C7.55777 10.4 7.19959 10.7581 7.19959 11.2C7.19959 11.6418 7.55777 11.9999 7.99959 11.9999Z"
        fill="white"
      />
    </svg>
    <span class="whitespace-nowrap">Maybe</span>
  </div>
</template>
