<script>
import CatalogTile from './community-hub-tile.vue'
import CommunityHubItemsDefault from '/~/extensions/community-hub/templates/default/views/catalog/components/community-hub-items.vue'

export default {
  name: 'community-hub-catalog-items',
  components: {
    CatalogTile,
  },
  extends: CommunityHubItemsDefault,
}
</script>
