<script>
import { computed } from 'vue'
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'

export default {
  name: 'bupa-catalogue-banner',
  props: {
    title: {
      type: String,
      default: null,
    },
    image: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const backgroundImage = computed(() =>
      ui.mobile && props.image.mobile
        ? `url(${cdn(props.image.mobile)
            .preview('1670x576')
            .format('auto')
            .quality('smart')
            .url()})`
        : `url(${cdn(props.image.desktop)
            .preview('2272x576')
            .format('auto')
            .quality('smart')
            .url()})`
    )

    return {
      backgroundImage,
    }
  },
}
</script>

<template>
  <section
    class="relative w-full p-2.5 pt-[30px] sm:p-5 sm:pt-10"
    data-cy="widget-banner"
  >
    <div
      class="relative flex min-h-72 items-center overflow-hidden rounded-lg bg-cover bg-center bg-no-repeat font-montserrat md:shadow"
      :style="{ backgroundImage }"
    >
      <h2
        v-if="title"
        class="absolute left-[10%] bottom-0 rounded-t-xl bg-primary px-5 pt-[15px] pb-11 text-2xl font-bold text-white"
      >
        {{ title }}
      </h2>
    </div>
  </section>
</template>
