<script setup lang="ts">
import { nextTick } from 'vue'
import modal from '/~/core/mdl'
import {
  type ServiceItem,
  useServices,
} from '/~/extensions/services/composables/use-services'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

const { servicesByGroups, selectService } = useServices()

function onSelectService(service: ServiceItem['value']) {
  selectService(service)
  modal.hide()
  nextTick(() => {
    modal.show('service-form')
  })
}
</script>

<template>
  <base-mdl width="md" fullscreen="mobile" title="Services">
    <ul>
      <div v-for="group in servicesByGroups" :key="group.label">
        <div
          v-if="group.label"
          class="relative flex items-center rounded bg-dark px-2.5 py-2.5 text-eonx-neutral-600"
        >
          <base-icon :svg="group.icon" size="md" class="mr-2.5" />
          <span>
            {{ group.label }}
          </span>
        </div>
        <div class="py-2.5">
          <template v-for="service in group.services">
            <li
              v-if="service.value"
              :key="service.text"
              class="px-5 py-2.5 hover:bg-primary-lightest"
              @click="onSelectService(service.value)"
            >
              {{ service.text }}
            </li>
          </template>
        </div>
      </div>
    </ul>
  </base-mdl>
</template>
