<script>
import { nextTick } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useRecUi } from '/~rec/composables/ui'
import SectionTabs from '/~rec/components/section/section-tabs.vue'

export default {
  name: 'rec-layout-mobile-menu',
  components: {
    BaseIcon,
    SectionTabs,
  },
  setup() {
    const { mainMenuMobile } = useRecUi()

    return { mainMenuMobile }
  },
  mounted() {
    this.scrollToActiveMenuItem()
  },
  methods: {
    scrollToActiveMenuItem() {
      nextTick(() => {
        const activeMenuItem = this.$el.querySelector('.rec-active-menu-item')

        if (activeMenuItem) {
          activeMenuItem.scrollIntoView({ block: 'start', inline: 'center' })
        }
      })
    },
  },
}
</script>

<template>
  <div class="w-full bg-white">
    <div
      class="mx-auto flex min-h-16 items-center space-x-2.5 overflow-x-auto px-4 py-2.5 text-eonx-neutral-800 sm:px-0 sm:pt-[5px]"
    >
      <router-link
        v-for="item in mainMenuMobile"
        :key="item.route"
        v-slot="{ href, navigate, isActive }"
        :to="{ name: item.route }"
        custom
      >
        <a
          class="flex h-10 shrink-0 items-center rounded-md px-4"
          :class="{
            'bg-gray-50': !isActive,
            'rec-active-menu-item bg-primary text-white': isActive,
          }"
          :href="href"
          @click="navigate"
        >
          <base-icon v-bind="item.icon" :size="24" class="mr-2.5" />
          <div class="shrink-0 font-bold">
            {{ item.label }}
          </div>
        </a>
      </router-link>
    </div>
    <section-tabs />
  </div>
</template>
