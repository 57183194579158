<script>
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useExtensions } from '/~/composables/extensions'

export default {
  name: 'qr-menu',
  components: {
    BaseLink,
    BaseIcon,
  },
  setup() {
    const { getConfigByName } = useExtensions()

    return {
      getConfigByName,
    }
  },
  computed: {
    config() {
      return this.getConfigByName(this.$route.meta.moduleName)
    },
    menu() {
      const { config = {} } = this
      const { scanQr, showSecurity, remoteChecking, reserveWorkspace } = config

      return [
        scanQr &&
          scanQr.enabled && {
            label: 'Scan<br>QR',
            key: 'qr-scan',
            icon: 'qr/qr-scan',
          },
        showSecurity &&
          showSecurity.enabled && {
            label: 'Show<br>security',
            key: 'qr-security',
            icon: 'qr/security',
          },
        remoteChecking &&
          remoteChecking.enabled && {
            label: 'Remote<br>Check-in',
            key: 'qr-check-in',
            icon: 'qr/home-v2',
          },
        reserveWorkspace &&
          reserveWorkspace.enabled && {
            label: 'Reserve<br>Workspace',
            key: 'qr-reserve-workspace',
            icon: 'qr/reserve',
          },
      ].filter((i) => i)
    },
  },
}
</script>

<template>
  <div class="mx-auto max-w-96 px-5 pt-6 sm:max-w-3xl sm:pt-10">
    <div class="mb-2.5 font-bold leading-none text-eonx-neutral-800">
      Check into work
    </div>
    <div class="sm:bg-primary-lighten sm:p-[5px]">
      <div class="grid grid-cols-2 gap-2.5 sm:grid-cols-4 sm:gap-[5px]">
        <base-link
          v-for="item in menu"
          :key="item.key"
          :to="{ name: item.key }"
          class="flex flex-col overflow-hidden rounded-lg sm:rounded-none"
        >
          <div
            class="relative h-full bg-primary hover:opacity-60"
            :class="[$route.name === item.key && 'text-primary-content']"
          >
            <div
              class="flex h-full w-full flex-col content-center justify-center py-2.5"
            >
              <div
                class="mt-[15px] flex content-center items-end text-white sm:mt-[35px]"
              >
                <base-icon
                  :svg="item.icon"
                  :size="60"
                  class="mx-auto text-white"
                />
              </div>
              <h3
                class="mx-auto mt-[15px] flex grow justify-center text-center text-sm font-bold leading-4 text-white sm:mb-6 sm:text-base sm:leading-none"
                v-html="item.label"
              />
            </div>
          </div>
        </base-link>
      </div>
    </div>
  </div>
</template>
