<script>
import { ref, computed } from 'vue'
import { cardCode } from '/~/utils/cards'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { useLocalization } from '/~/composables/localization'
import { useFlowPaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'summary-pay-with-item',
  components: {
    BaseIcon,
  },
  props: {
    method: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isStatementCurrentFlowType, payment, currentFlowType } =
      useCheckoutReactive()
    const { initiatingAccountsIds } = useFlowPaymentMethods(
      currentFlowType.value,
      payment.value.payeeId
    )
    const {
      isBankAccountsNavEnabled,
      isCreditCardsNavEnabled,
      isSymbioneliteProvider,
      isBillPaymentsTemplate,
      taxationLabel,
    } = useProvider()
    const { pointsLabel } = usePoints()
    const { translate } = useLocalization()

    const isPayId = computed(
      () => props.method.type === PaymentMethodType.payId
    )

    const isPoints = computed(
      () => props.method.type === PaymentMethodType.points
    )
    const isCard = computed(
      () => props.method.type === PaymentMethodType.creditCard
    )
    const isBankAccount = computed(
      () => props.method.type === PaymentMethodType.bankAccount
    )
    const isCoupon = computed(
      () => props.method.type === PaymentMethodType.couponCode
    )

    const bankAccountNotices = ref(
      isSymbioneliteProvider.value
        ? [
            'Payments from bank accounts may take up to 3 business days to complete.',
            'Please ensure you have available funds to avoid any bank dishonour fees.',
            'Your points will be allocated once this payment has successfully completed.',
          ]
        : [
            'Payments from bank accounts may take up to 3 business days to clear.',
            'Please ensure you have available funds to avoid any bank dishonour fees.',
            'Any points earned will be allocated once this payment has cleared.',
          ]
    )

    const isShowFees = computed(() => {
      if (payment.value.totalFees === 0) {
        return false
      }
      if (payment.value.isCardOrBankAccountMethodSelected) {
        return !isPoints.value
      } else {
        return isPoints.value
      }
    })

    const showBankAccountNotices = computed(() => {
      return isBankAccount.value && !payment.value.termsShouldBeAccepted
    })

    const icon = computed(() => {
      if (isPayId.value) {
        return 'billpay/payment-methods/payid'
      } else if (isPoints.value) {
        return 'symbion/token'
      } else if (isCard.value) {
        return `billpay/payment-methods/${cardCode(props.method)}`
      } else if (isBankAccount.value) {
        return 'v2/custom/bank'
      } else if (isCoupon.value) {
        return 'coupon'
      }
      return null
    })
    const iconSize = computed(() => {
      return isBankAccount.value ? 32 : 40
    })
    const firstRow = computed(() => {
      const { name, type, accountName } = props.method

      switch (type) {
        case PaymentMethodType.payId:
          return 'PayID'
        case PaymentMethodType.points:
          return isSymbioneliteProvider.value
            ? pointsLabel.value
            : 'Pay with points'
        case PaymentMethodType.creditCard:
          return name
        case PaymentMethodType.bankAccount:
          return accountName
        case PaymentMethodType.couponCode:
          return 'Coupon'
        default:
          return ''
      }
    })
    const secondRow = computed(() => {
      const { number, accountNumber, bsb } = props.method

      if (isPayId.value) {
        return payment.value.selectedPayId.reference
      } else if (isPoints.value) {
        return `${formatPoints(
          payment.value.selectedPoints?.usePoints
        )} points redeemed`
      } else if (isCard.value) {
        return `**** **** **** ${number.slice(-4)}`
      } else if (isBankAccount.value) {
        return translate('bankAccount.details', {
          acc: `*** *** *${accountNumber.slice(-2)}`,
          bsb,
        })
      } else if (isCoupon.value) {
        return props.method.promotionCode
      }

      return null
    })
    const programFeesLabel = computed(() => {
      return `Program Fee (${payment.value.programFeesPercentage}% inc. ${taxationLabel.value})`
    })
    const paymentProcessingFeesLabel = computed(() => {
      const { type } = props.method

      const label =
        props.method.percentageFeeRate === 0
          ? `inc. ${taxationLabel.value}`
          : `${props.method.percentageFeeRate}% inc. ${taxationLabel.value}`

      switch (type) {
        case PaymentMethodType.creditCard:
          return `Card Processing Fee (${label})`
        case PaymentMethodType.bankAccount:
          return `Bank Processing Fee (${label})`
        default:
          return ''
      }
    })
    const totalFeesLabel = computed(() => {
      return isStatementCurrentFlowType.value && isBankAccount.value
        ? '*Total fees will be charged as a separate transaction.'
        : '*Total fees'
    })
    const amountToPay = computed(() => {
      return isCoupon.value
        ? props.method.amount
        : hasFeesSeparateTransaction.value
        ? payment.value.subTotal
        : props.method.calculatedAmount + (props.method.fee ?? 0)
    })
    const hasFeesSeparateTransaction = computed(() => {
      return Boolean(
        isBankAccount.value &&
          isStatementCurrentFlowType.value &&
          payment.value.programFees + payment.value.transactionFees > 0
      )
    })

    return {
      isBankAccountsNavEnabled,
      isCreditCardsNavEnabled,
      formatDollar,
      formatPoints,
      initiatingAccountsIds,
      isSymbioneliteProvider,
      isBillPaymentsTemplate,
      isStatementCurrentFlowType,
      payment,
      bankAccountNotices,
      showBankAccountNotices,
      isPoints,
      isCard,
      isBankAccount,
      isCoupon,
      isShowFees,
      icon,
      iconSize,
      firstRow,
      secondRow,
      programFeesLabel,
      paymentProcessingFeesLabel,
      totalFeesLabel,
      amountToPay,
      hasFeesSeparateTransaction,
    }
  },
}
</script>

<template>
  <div class="relative pb-3">
    <div class="flex gap-4">
      <div
        class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white"
        :class="{
          'bg-eonx-neutral-600': isBillPaymentsTemplate && isBankAccount,
          'bg-primary': !isBillPaymentsTemplate && isBankAccount,
        }"
      >
        <base-icon
          v-if="icon"
          :svg="icon"
          :size="iconSize"
          :class="{
            'text-primary': isCoupon || isPoints,
          }"
        />
      </div>
      <div class="flex w-full flex-col">
        <div class="font-bold">
          {{ firstRow }}
        </div>
        <div class="flex justify-between text-sm text-eonx-neutral-600">
          <div>{{ secondRow }}</div>
          <div>{{ formatDollar(amountToPay) }}</div>
        </div>
      </div>
    </div>
    <div v-if="hasFeesSeparateTransaction" class="mt-4 flex gap-4">
      <div
        class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white"
        :class="{
          'bg-eonx-neutral-600': isBillPaymentsTemplate,
          'bg-primary': !isBillPaymentsTemplate,
        }"
      >
        <base-icon svg="v2/custom/bank" :size="32" />
      </div>
      <div class="flex w-full flex-col">
        <div class="font-bold">
          {{ firstRow }}
        </div>
        <div class="flex justify-between text-sm text-eonx-neutral-600">
          <div>{{ secondRow }}</div>
          <div>{{ formatDollar(payment.totalFees) }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="showBankAccountNotices"
      class="mt-5 flex items-center bg-eonx-neutral-200 py-2.5"
    >
      <div class="flex w-16 shrink-0">
        <base-icon
          svg="v2/custom/alert"
          :size="32"
          class="mx-auto shrink-0 text-eonx-neutral-800"
        />
      </div>
      <ul
        class="list-disc space-y-[5px] pl-5 pr-4 text-sm text-eonx-neutral-600"
      >
        <li v-for="(notice, index) in bankAccountNotices" :key="index">
          {{ notice }}
        </li>
      </ul>
    </div>
  </div>
</template>
