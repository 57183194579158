<script>
import { nanoid } from 'nanoid'
import JsBarcode from 'jsbarcode'
import * as PDF417 from 'pdf417-generator'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'barcode-unlocked',
  components: {
    QrcodeVue,
  },
  props: {
    details: {
      type: Object,
      default: undefined,
    },
    options: {
      type: Object,
      default: () => ({
        font: 'Lato',
      }),
    },
  },
  data() {
    return {
      id: nanoid(),
    }
  },
  computed: {
    barcode() {
      return this.details?.barcode
    },
    barcodeType() {
      let type = this.details?.barcodeType?.toLowerCase()

      if (!type) {
        type = 'ca128'
      }

      switch (type) {
        case 'ca128':
        case 'c128':
          return 'code128'
        default:
          return type
      }
    },
    isImage() {
      return this.barcodeType === 'image'
    },
    isPDF() {
      return this.barcodeType === 'pdf417'
    },
    isBarcode() {
      return ['code128', 'code39'].includes(this.barcodeType)
    },
    isQR() {
      return this.barcodeType === 'qrcode'
    },
  },
  mounted() {
    if (this.barcode) {
      if (this.isBarcode) {
        this.createBarcode(this.barcode)
      } else if (this.isPDF) {
        this.createPDF(this.barcode)
      }
    }
  },
  methods: {
    createBarcode(value) {
      JsBarcode(this.$refs.canvas, value, {
        format: this.barcodeType,
        margin: 0,
        ...this.options,
      })
    },
    createPDF(value) {
      PDF417.draw(value, this.$refs.canvas, 6, -1, 3)
    },
  },
}
</script>

<template>
  <div
    class="flex-column flex w-full justify-center rounded-sm bg-white p-[6px]"
  >
    <figure v-if="isImage" class="flex justify-center">
      <img
        class="mx-auto block h-full w-72 max-w-full"
        :src="barcode"
        alt="barcode"
      />
    </figure>
    <figure v-else-if="isBarcode || isPDF" class="flex justify-center">
      <canvas
        ref="canvas"
        height="100"
        width="300"
        class="barcode__js mx-auto h-auto max-w-full"
      />
    </figure>
    <div v-else-if="isQR" class="mx-auto">
      <qrcode-vue :value="barcode" size="200" />
    </div>
  </div>
</template>
