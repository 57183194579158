<script setup lang="ts">
import { computed } from 'vue'
import OoCashbackPure from '/~/extensions/online-offers/components/oo-cashback.pure.vue'
import OoPointsPure from '/~/extensions/online-offers/components/oo-points.pure.vue'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import { calculatePurchasePointsEarned } from '/~/utils/points'
import { usePoints } from '/~/composables/points'

const props = defineProps<{
  cashbackRate: number
}>()

const { isCashbackReturn, isPointsReturn } = useOnlineOffers()
const { earnPointsRate } = usePoints()

const earnPoints = computed(() =>
  Math.round(
    calculatePurchasePointsEarned(
      1,
      props.cashbackRate,
      earnPointsRate.value.purchase
    )
  )
)
</script>

<template>
  <oo-cashback-pure v-if="isCashbackReturn" :value="cashbackRate" />
  <oo-points-pure v-else-if="isPointsReturn" :value="earnPoints" />
</template>
