<script>
export default {
  name: 'confirmation-form-data',
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
}
</script>
<template>
  <div class="space-y-5 pb-5">
    <div>
      <div class="mb-2.5 font-bold">Your details</div>
      <div class="space-y-2.5">
        <div>
          <div class="text-eonx-neutral-600">
            Your email address - we’ll email you regularly to let you know the
            status of your lead
          </div>
          <div class="font-bold">
            {{ form.email || '-' }}
          </div>
        </div>

        <div>
          <div class="text-eonx-neutral-600">Business Channel</div>
          <div class="font-bold">
            {{ form.channel || '-' }}
          </div>
        </div>

        <div class="pt-5">
          <strong class="text-eonx-neutral-800">Customer details</strong>
        </div>

        <div>
          <div class="text-eonx-neutral-600">Organisaton</div>
          <div class="font-bold">
            {{ form.organization || '-' }}
          </div>
        </div>

        <div>
          <div class="text-eonx-neutral-600">First Name</div>
          <div class="font-bold">
            {{ form.contactFirstName || '-' }}
          </div>
        </div>

        <div>
          <div class="text-eonx-neutral-600">Last Name</div>
          <div class="font-bold">
            {{ form.contactLastName || '-' }}
          </div>
        </div>

        <div>
          <div class="text-eonx-neutral-600">Customer email address</div>
          <div class="font-bold">
            {{ form.contactEmail || '-' }}
          </div>
        </div>

        <div>
          <div class="text-eonx-neutral-600">Mobile</div>
          <div class="font-bold">
            {{ form.contactPhoneNumber || '-' }}
          </div>
        </div>

        <div>
          <div class="text-eonx-neutral-600">State</div>
          <div class="font-bold">
            {{ form.state || '-' }}
          </div>
        </div>

        <div>
          <div class="text-eonx-neutral-600">Product Interested in:</div>
          <template v-if="form.productsInterested.length">
            <div
              v-for="item in form.productsInterested"
              :key="item"
              class="font-bold"
            >
              {{ item }}
            </div>
          </template>
          <span v-else>-</span>
        </div>

        <div>
          <div class="text-eonx-neutral-600">
            Why is this lead being submitted?
          </div>
          <div class="font-bold">
            {{ form.reason || '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
