<script>
import cdn from '/~/utils/cdn'
import BaseButton from '/~/components/base/button/base-button'
import GreenBox from '/~/templates/australiapost/components/green-box/green-box.vue'
import LeadsTable from '/~/templates/australiapost/components/leads-table/leads-table.vue'
import QualityLeadsBanner from '/~/templates/australiapost/components/quality-leads-banner/quality-leads-banner.vue'

export default {
  name: 'quality-leads',
  components: {
    QualityLeadsBanner,
    LeadsTable,
    BaseButton,
    GreenBox,
  },
  data() {
    return {
      selectedVideoIndex: 0,
      videos: [
        'https://ucarecdn.com/2901cb92-65eb-4299-b2ce-dc47e1f600c1/AUS0131SeekerCallCentreBURNEDCAPTIONS.mp4',
        'https://ucarecdn.com/bdf222a5-2344-477b-9442-63d5e9900475/AUS0131SeekerRetailOutletBURNEDCAPTIONS.mp4',
        'https://ucarecdn.com/698ed92f-54a4-44c4-80e5-941eb934658b/AUS0131SeekerPostalTransportBURNEDCAPTIONS.mp4',
      ],
      pdfs: [
        'https://ucarecdn.com/461938d4-4176-4d94-96f9-85922d9ef7b8/AUS0131SeekerCallCentreTRANSCRIPT.pdf',
        'https://ucarecdn.com/3fb260eb-c49a-4fce-845e-c6e24f28cf75/AUS0131SeekerRetailOutletTRANSCRIPT.pdf',
        'https://ucarecdn.com/44daf4c1-f981-4525-af0e-119b23908188/AUS0131SeekerPostalTransportTRANSCRIPT.pdf',
      ],
      in4SimpleStepsPDF:
        'https://ucarecdn.com/bf329337-36f2-4f86-b70f-9f6e710e6341/AusPostSEEKERSpotLeadsIn4SimpleSteps.pdf',
      screenshots: [
        cdn('//cdn.eonx.net/88a3b189-aa30-4374-b5e9-adec6961417b/')
          .preview('200x120')
          .format('auto')
          .quality('smart')
          .url(),
        cdn('//cdn.eonx.net/79fb2a48-333d-4b8d-a46c-be8b42ef801f/')
          .preview('200x120')
          .format('auto')
          .quality('smart')
          .url(),
        cdn('//cdn.eonx.net/1e2717bd-4d4f-43d4-bb72-b4058e32be28/')
          .preview('200x120')
          .format('auto')
          .quality('smart')
          .url(),
      ],
      steps: [
        {
          title: '1. Look:',
          text: 'Keep your eyes open for opportunities across all our channels. Take notice of the following:',
          image: 'https://cdn.eonx.net/fb5f2f4d-c46a-4455-a5c6-08f8a065b936/',
          imageClass: 'px-12 py-5',
          points: `<ul class="space-y-[15px] list-disc list-inside">
            <li>new businesses opening</li>
            <li>bulk postage product purchases</li>
            <li>competitor branded packaging labels</li>
            <li>parcels waiting for collection from a competitor</li>
            <li>customers lodging multiple parcels at once</li>
            <li>customers using international distribution</li>
            <li>customers with a Business Credit Account but aren’t using eParcel.</li>
          </ul>`,
        },
        {
          title: '2. Listen:',
          text: 'There are key phrases and terms to pay attention for, such as:',
          image: 'https://cdn.eonx.net/540d532b-3b6a-4822-bdb9-7be88431b9b0/',
          imageClass: 'px-12 py-5',
          points: `<ul class="space-y-[15px] list-disc list-inside">
            <li>“customer”, indicating they may have a business</li>
            <li>rates, collections or better ways to do things</li>
            <li>establishing or running a business/service</li>
            <li>contracts or accounts with competitors</li>
            </ul>`,
        },
        {
          title: '3. Talk',
          text: 'Get to know your customers and build up a rapport. It’s as easy as:',
          points: `<ul class="space-y-[15px] list-disc list-inside">
            <li>asking ‘do you have a business?’</li>
            <li>gaining an understanding of their needs</li>
            <li>identifying if they would benefit from contract services</li>
            <li>promoting the benefits of a business solution</li>
          </ul>`,
          image: 'https://cdn.eonx.net/5a36f275-8ba8-454a-aab3-d0e96f309bb0/',
          imageClass: 'px-12 py-5',
        },
        {
          title: '4. Act',
          text: 'If the customer agrees to be contacted to discuss business solutions, you’ll need to act:',
          points: `<ul class="space-y-[15px] list-disc list-inside">
            <li>capture organisation name, contact name, phone number and email address</li>
            <li>identify the reason for the lead (what do they qualify for?)</li>
            <li>submit the lead via the Seeker program, Salesforce or SnapIt</li>
            <li>stay informed of your lead status via email</li>
          </ul>`,
          image: 'https://cdn.eonx.net/6d2127a5-983a-4f5c-b962-c5ce3be24c1a/',
          imageClass: 'px-12 py-5',
        },
      ],
      leadsInfo: {
        head: [['Lead Type', 'Qualifying Criteria']],
        body: [
          { title: 'MyPost Business', description: 'No minimum spend' },
          { header: 'Domestic Parcels' },
          {
            title: 'eParcel - Parcel Post, Express and Returns',
            list: [
              '2000+ parcels per year',
              'No items heavier than 22kg',
              'No oversized goods (greater than 105cm in one direction)',
            ],
            filled: true,
          },
          {
            title: 'Add ons: Data Insights',
            list: [
              'Database of 12,000 records',
              ' Customer must have an AP account, and be a large parcel customer',
            ],
            filled: true,
          },
          {
            title: 'Delivery Choice Enablement (DCE)',
            list: [
              'A range of delivery enhancements including specified delivery times or additional choices for their customers',
              'Notes: Must meet eParcel / Parcel Send criteria',
            ],
            filled: true,
          },
          {
            title: 'Fulfilio',
            list: [
              'Minimum 800 parcels per month',
              'No dangerous/perishable or temperature-controlled goods',
              'No items heavier than 22kgs',
              'No oversized goods (greater than 105cms',
            ],
          },
          {
            title: 'Next Day – StarTrack Premium',
            list: [
              '$1,000 minimum monthly spend on freight',
              'Maximum Dimensions: 100cm (L) x 60cm (H) x 60cm (W)',
              'Maximum parcel weight 22kg',
            ],
            filled: true,
          },
          {
            title: '3PL - StarTrack',
            list: ['$2,000 minimum monthly spend'],
          },
          {
            title: 'Road Express – StarTrack',
            list: [
              'Minimum 800 parcels per month',
              'No dangerous/perishable or temperature-controlled goods',
              'No items heavier than 22kgs',
              'No oversized goods (greater than 105cms in one direction)',
            ],
            filled: true,
          },
          {
            title: 'StarTrack on Demand',
            titleList: [
              '3 Hour (On Demand Now)',
              'Afternoon',
              'Evening',
              'Saturday',
            ],
            list: [
              '$1,000 minimum monthly spend per month or 10,000 articles per annum. This includes Road Express, Premium and On Demand Products, across all lodgement locations',
              'Despatch and delivery within On Demand service zone*',
              '15 daily minimum per service per pickup location^ i.e. if we pick up from 2 locations and the merchant uses 1 On Demand service (say On Demand Tonight) that would mean the minimum is 30 parcels/day',
              'Maximum 25kg parcel weight',
              'No dimension exceeding 50cm',
              'No dangerous goods',
            ],
            notes:
              '*On Demand services are only available to selected merchants. On Demand services are available to consumers within specified geographical areas of Sydney, Melbourne, Brisbane and Adelaide provided that the merchant’s point of dispatch is within specified geographical areas in the same city\n' +
              '^Minimum charge is based on an average over the billing period, the shortfall will be applied to the On Demand invoice',
            notesOpen: false,
          },
          {
            header: 'International Parcels',
          },
          {
            title: 'Outbound',
            description: 'Minimum $3,000 per year',
            filled: true,
          },
          {
            title: 'Inbound',
            description:
              'Inbound parcels (via the post) is provided within the framework of the Universal Postal Union. As such, we do not have a contract directly with the senders, we provide final leg of the delivery for items sent via other postal services.',
          },
          {
            header: 'Domestic Letters',
          },
          {
            title: 'Print Post',
            description: 'Minimum revenue of $1M per year',
            filled: true,
          },
          {
            title: 'Domestic letter with tracking Imprint',
            description: 'Send a minimum annual volume of 10,000 articles',
          },
          {
            title: 'Unaddressed Mail',
            description: 'Sending 100,000 articles per year',
            filled: true,
          },
          {
            title: 'Mail collection and drop off service (Shuttle run)',
            description:
              'Customers must commit to a minimum of 2 scheduled service days per week.',
            notes: 'Offering mail collection and drop off',
            notesOpen: false,
          },
          {
            title: 'Decipha',
            description: 'No minimum volume required',
            filled: true,
          },
          {
            header: 'International Letters',
          },
          {
            title: 'Outbound',
            description:
              'Minimum spend of $3,000 p.a. on international letters.',
            filled: true,
          },
          {
            header: 'Identity Services',
          },
          {
            title: 'Verification of ID for property transfer',
            descriptionList: [
              {
                title: 'For use of our existing VOI solution:',
                list: [
                  'No minimum volumes - A practitioner chooses to use our service at their leisure and have the choice of consumer pays or agency pays – hence why we have a fast and relatively automated on-boarding process: neither involve any setup costs for the client.',
                ],
              },
              {
                title: 'For a fully customised VOI solution:',
                list: [
                  'Minimum ~5k transactions or ~$200k transaction revenue per annum, however this is assessed on a case by case basis and can be adapted to the financial appetite of the client to either pay upfront ‘build’ costs or alternatively higher transaction pricing to recover our investment.',
                ],
              },
              {
                title:
                  'If a transaction can be derived from our existing VOI solution, then the qualifying amounts are less.',
              },
            ],
            filled: true,
          },
          {
            title: 'Workforce Verification',
            description:
              'There’s no limit, however, you can only upload details of up to 1,000 candidates at a time. Individual customer requests or consumer requests to be directed in store or online.',
          },
          {
            title: 'Digital iD',
            description: 'No minimum eligibility criteria',
            filled: true,
          },
          {
            header: 'Financial Services',
          },
          {
            title: 'Post BillPay',
            descriptionList: [
              { title: 'No minimum eligibility criteria' },
              {
                title:
                  'Prospective billers can apply to become a Post Billpay biller at <a href="https://auspost.com.au/post-billpay-business" class="text-blue-500 hover:text-blue-400" target="_blank">https://auspost.com.au/post-billpay-business</a>.',
              },
              {
                title:
                  'Account managed customers and new customers likely to exceed 500k transactions per year should be sent through to sales as per current process.`',
              },
            ],
            filled: true,
          },
        ],
      },
      notLeadsInfo: {
        body: [
          [
            'StarTrack Couriers',
            'The perfect service for that once-off pickup.',
          ],
          [
            'Clean Mail Services',
            'A range of bulk letter options for domestic delivery.',
          ],
          [
            'Credit Charge Account',
            'Simply send the customer the Business - Credit Account application form.',
          ],
          [
            'Electronic Lodgement Management System (eLMs)',
            'A web-based form allowing customers to lodge high volumes of mail instead of completing mailing statements.',
          ],
          [
            'Information only',
            'The customer only requires information on products and services.',
          ],
        ],
      },
    }
  },
  computed: {
    selectedVideo() {
      return this.videos[this.selectedVideoIndex]
    },
    selectedPDF() {
      return this.pdfs[this.selectedVideoIndex]
    },
  },
}
</script>
<template>
  <div class="bg-gray-50">
    <div class="mx-auto w-full max-w-6xl pt-[30px] text-sm sm:px-2.5">
      <quality-leads-banner />

      <div class="m-5 max-w-5xl sm:m-11">
        <h1 class="mt-20 text-center text-3xl text-red-700">
          It's easy to spot and submit leads
        </h1>
        <p
          class="mx-auto mt-[30px] max-w-5xl text-lg font-semibold text-eonx-neutral-600 sm:text-2xl"
        >
          Find out who your customer is, the business opportunity and gather as
          much information as you can before entering your leads via the Seeker
          website or Salesforce. Watch the videos to learn more.
        </p>
      </div>
      <div class="bg-eonx-neutral-50 py-11">
        <video
          :key="selectedVideo"
          class="mx-auto w-full xs:max-w-3xl"
          controls
        >
          <source :src="selectedVideo" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div class="mx-auto sm:w-sme">
          <h1
            class="mt-5 mb-6 text-center text-3xl text-eonx-neutral-600 sm:mt-20"
          >
            Retail Outlet
          </h1>

          <div
            class="mb-6 flex flex-col items-center space-y-6 px-[15px] sm:mb-20 sm:flex-row sm:space-y-0 sm:space-x-[30px]"
          >
            <p class="text-eonx-neutral-600">
              Find out how our Retail Outlet employees spot opportunities in
              their enviorment every day, submit leads and get the most from
              Seeker rewards.
            </p>
            <a :href="selectedPDF" target="_blank" download>
              <base-button>Read the video transcript</base-button>
            </a>
          </div>

          <div class="mb-20 grid justify-center gap-6 sm:grid-cols-3">
            <div
              v-for="(src, idx) in screenshots"
              :key="idx"
              class="border-2 border-transparent"
              :class="{ 'border-red-700': idx === selectedVideoIndex }"
              @click="selectedVideoIndex = idx"
            >
              <img class="h-full w-full object-cover" :src="src" />
            </div>
          </div>
        </div>

        <h2 class="mb-12 text-center text-3xl text-red-700">
          Spot leads in 4 simple steps
        </h2>

        <green-box
          v-for="(step, i) in steps"
          :key="i"
          :item="step"
          class="mx-5 mb-2.5 sm:mx-11"
        />

        <div class="my-12 text-center">
          <a :href="in4SimpleStepsPDF" target="_blank" download>
            <base-button>Download PDF</base-button>
          </a>
        </div>

        <h2 class="mb-6 px-5 text-center text-3xl text-red-700">
          Identifying sales leads
        </h2>

        <p
          class="mx-auto mb-12 max-w-5xl px-5 text-lg font-semibold text-eonx-neutral-600 sm:text-2xl"
        >
          A sales lead is a person, business or organisation that could be
          interested in purchasing products and services in volume that they do
          not currently use. They can be a completely new customer, or existing,
          whereby a new opportunity has been discovered.
        </p>

        <leads-table class="mb-12" :data="leadsInfo" />

        <h2 class="mb-6 px-5 text-center text-3xl text-red-700">
          What isn't a sales lead?
        </h2>

        <p
          class="mb-6 px-5 text-center text-2xl font-semibold text-eonx-neutral-600"
        >
          A lead will not be verified if the customer:
        </p>

        <ul
          class="mx-auto mb-12 max-w-5xl list-inside list-disc px-5 text-lg font-semibold text-eonx-neutral-600 sm:text-2xl"
        >
          <li>
            Already holds an account and has a contract in place for the product
            or service the lead is for
          </li>
          <li>Is renewing their contract</li>
          <li>Lodges at a Licensed Post Office or Australia post Franchisee</li>
          <li>Only requires a business credit account application form</li>
          <li>Only requires information on products and services</li>
        </ul>

        <table class="my-12 w-full text-left text-lg text-eonx-neutral-800">
          <tbody>
            <tr
              v-for="(row, index) in notLeadsInfo.body"
              :key="index"
              class="odd:bg-gray-300"
            >
              <td class="w-1/4 px-5 py-2.5 sm:px-12">
                {{ row[0] }}
              </td>
              <td class="w-3/4 px-5 py-2.5 sm:px-12">
                {{ row[1] }}
              </td>
            </tr>
          </tbody>
        </table>

        <p class="px-5 text-center text-lg text-eonx-neutral-600">
          <strong>Note:</strong>
          Customers who already have an eParcel or BPA contract and wish to make
          additions to their agreement need to be referred to their Account
          Manager.
        </p>
      </div>
    </div>
  </div>
</template>
