import get from 'lodash-es/get'
import { cmsSocket } from '/~/core/cms-socket'
import emitter from '/~/core/emitter'
import { useDrawerMenu } from '/~/components/drawer/use-drawer-menu'
import { useCms } from '/~/composables/cms'
import { useExtensions } from '/~/composables/extensions'
import { useProvider } from '/~/composables/provider'
import actions from '/~/configurator/actions.json'
import presets from '/~/configurator/presets'
import schemes from '/~/configurator/schemes'
import { getConfigNameByRoute } from '/~/configurator/utils'
import router from '/~/router'

let pageSelectedTask
let icons

eonx._fetchCmsConfig = eonx.fetchCmsConfig
eonx.fetchCmsConfig = async () => {
  await eonx._fetchCmsConfig()
  sendDebiLoaded()
}

cmsSocket.on('page-selected', (payload) => {
  const current = get(router, 'app.$route.name')

  const task = () => {
    if (current !== payload.name) {
      router.push(payload)
    } else {
      cmsSocket.send('cms', 'route-changed', {
        page: payload.name,
      })
    }
  }

  const { extensions } = useExtensions()

  if (!extensions.loaded) {
    pageSelectedTask = task
  } else {
    task()
    pageSelectedTask = undefined
  }
})

const { pages, cms, updateVariables } = useCms()

cmsSocket.on('page-updated', (payload) => {
  if (payload.name && payload.content) {
    pages.value = {
      ...pages.value,
      [payload.name]: payload.content,
    }
  }
})

cmsSocket.on('page-created', (page) => {
  if (!page.name) {
    return
  }

  router.addRoute({
    path: `/${page.name}`,
    name: page.name,
    component: () => import('/~/views/home/home.vue'),
    meta: {
      title: page.label,
      page: page.name,
      footer: true,
    },
  })

  cmsSocket.send('cms', 'routes-loaded', window.routes)
})

cmsSocket.on('page-deleted', () => {
  router.push({ name: 'home' })
})

cmsSocket.on('modals-updated', (payload) => {
  cms.value.modals = payload
  updateVariables()
})

cmsSocket.on('menus-updated', (payload) => {
  cms.value.menus = payload
})

cmsSocket.on('debi-reload', () => {
  window.location.reload()
})

cmsSocket.on('reload', () => {
  window.location.reload()
})

cmsSocket.on('design-updated', (payload) => {
  cms.value.design = payload
  updateVariables()
})

cmsSocket.on('configuration-updated', (payload) => {
  cms.value.config = payload
  updateVariables()
})

cmsSocket.on('redirects-updated', (payload) => {
  cms.value.redirects = payload
  updateVariables()
})

cmsSocket.on('content-updated', (payload) => {
  cms.value.content = payload
  updateVariables()
})

cmsSocket.on('ewallet-updated', (payload) => {
  cms.value.ewallet = payload
  updateVariables()
})

cmsSocket.on('header-updated', (payload) => {
  cms.value.header = payload
})

router.beforeEach((to, from, next) => {
  next()

  if (from.name && from.name !== to.name) {
    cmsSocket.send('cms', 'route-changed', {
      page: getConfigNameByRoute(to) || to.name,
    })
  }
})

emitter.on('page-empty', (page) => {
  cmsSocket.send('cms', 'page-loaded', {
    name: page,
    content: {
      widgets: [],
      settings: {},
    },
  })
})

emitter.on('page-loaded', (payload) => {
  cmsSocket.send('cms', 'page-loaded', payload)
})

emitter.on('page-error', (payload) => {
  cmsSocket.send('cms', 'page-error', payload)
})

emitter.on('router:after-each', (state) => {
  cmsSocket.send('cms', 'route-state', state)
})

async function fetchMeta() {
  const response = await fetch('/meta/icons.json').then((response) => {
    if (response.ok) return response.json()
    return {}
  })

  icons = response

  sendDebiLoaded()
  cmsSocket.send('viewer', 'app-ready')
}

emitter.on('extensions-loaded', async () => {
  const { ewalletMenuRoutes } = useDrawerMenu()

  cmsSocket.send('cms', 'routes-loaded', [
    ...window.routes,
    ...ewalletMenuRoutes.value.map((route) => {
      const newRoute = {
        ...route,
        path: `/${route.path}`,
        meta: {
          ...route.meta,
          group: 'ewallet',
        },
      }

      delete newRoute.name

      return newRoute
    }),
  ])

  if (pageSelectedTask) {
    pageSelectedTask()
    pageSelectedTask = undefined
  }

  sendDebiLoaded()
})

function sendDebiLoaded() {
  const {
    cmsModals,
    cmsMenus,
    cmsDesign,
    cmsConfig,
    cmsRedirects,
    cmsContent,
    cmsEwallet,
    cmsHeader,
  } = useCms()
  const { accessRestrictionRoles } = useProvider()

  const restrictedRolesFieldIndex =
    schemes().app.menus.presets.menu.fields.findIndex((field) => {
      return field.name === 'restrictedRoles'
    })

  schemes().app.menus.presets.menu.fields[
    restrictedRolesFieldIndex
  ].props.options = accessRestrictionRoles.value.map((role) => {
    return {
      label: role.roleSlug,
      value: role.roleSlug,
    }
  })

  cmsSocket.send('cms', 'app-ready', {
    provider: eonx.cms.provider,
    provider_config: eonx.config,
    env: eonx.env,
    routes: [],
    schemes: schemes(),
    menus: cmsMenus.value,
    modals: cmsModals.value,
    design: cmsDesign.value,
    configuration: cmsConfig.value,
    redirects: cmsRedirects.value,
    content: cmsContent.value,
    customPages: eonx.cms.customPages,
    ewallet: cmsEwallet.value,
    header: cmsHeader.value,
    actions,
    presets,
    icons,
  })
}

fetchMeta()

cmsSocket.send('cms', 'get-cms-provider')

cmsSocket.on('set-cms-provider', async (payload) => {
  eonx.cms.currentProvider = payload.provider

  await eonx.fetchCmsConfig()

  emitter.emit('cms:frame-loaded')
})
