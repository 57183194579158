import type { RouteConfig } from 'vue-router'
import type { CMSPageId } from '/~/types/cms/page'
import bottomSheet from '/~/core/bottom-sheet'
import { RewardsExtension } from '/~/core/extension'
import mdl from '/~/core/mdl'
import { useCompetitions } from './composables'
import modals from './modals'
import bottomSheets from './modals/bottom-sheets'

const { init, moduleName, label, syncState } = useCompetitions()

mdl.register(modals)
bottomSheet.register(bottomSheets)

class CompetitionsExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  async setState() {
    return init()
  }

  setRoutes() {
    return [
      {
        path: `${moduleName}`,
        component: () => import('./competitions.vue'),
        meta: {
          title: label.value,
        },
        children: [
          {
            path: '',
            name: 'competitions',
            meta: {
              title: label.value,
              page: 'competitions' as CMSPageId,
            },
            component: () =>
              import(
                `./templates/${this.templateName}/views/catalog/competitions-catalog.vue`
              ),
            async beforeEnter(to, _, next) {
              syncState({ to })
              next()
            },
          },
          {
            path: ':id',
            name: 'competition-details',
            component: () =>
              import(
                `./templates/${this.templateName}/views/details/competition.vue`
              ),
            meta: {
              title: 'Competition details',
              hideBottomBar: true,
              hideMenu: true,
            },
          },
          {
            path: ':id/confirmation',
            name: 'competition-confirmation',
            component: () =>
              import(
                `./templates/${this.templateName}/views/confirmation/competition-confirmation.vue`
              ),
            meta: {
              title: 'Competitions confirmation',
              hideBottomBar: true,
              hideMenu: true,
            },
          },
        ] as RouteConfig[],
      },
    ]
  }
}

export default CompetitionsExtension
