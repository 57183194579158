<script>
import DefaultMajorSummary from '/~/extensions/gift-cards-cinema/components/major-summary.vue'
import ProductTotal from '/~/extensions/giftcards/templates/australiapost/components/product/total/product-total.vue'
import ProductHeader from '/~/extensions/giftcards/templates/australiapost/components/product/header/product-header.vue'

export default {
  components: {
    ProductTotal,
    ProductHeader,
  },
  extends: DefaultMajorSummary,
  props: {
    showProductPrice: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
