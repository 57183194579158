import { createDate } from '/~/utils/format/date'
import { useLocalization } from '/~/composables/localization'

const { getDatePickerFormat } = useLocalization()

export class RecroomCelebration {
  constructor(raw) {
    this.raw = raw
    this.member = new RecroomCelebrationMember(this.raw.member)
    this.award = new RecroomCelebrationAward(this.raw.award)
  }

  get id() {
    return this.raw.id
  }

  get type() {
    return this.raw.celebration_type
  }

  get name() {
    return this.raw.celebration_name
  }

  get date() {
    return createDate(
      this.raw.celebration_date,
      getDatePickerFormat('daymonthyearnumeric')
    )
  }

  get image() {
    return this.raw.celebrant_photo
  }

  get originDate() {
    return createDate(
      this.raw.celebration_origin_date,
      getDatePickerFormat('daymonthyearnumeric')
    )
  }

  get prizeEarned() {
    return this.raw.prize_earned
  }

  get startsAt() {
    return this.date
  }

  get endsAt() {
    return this.date
  }
}

export class RecroomCelebrationMember {
  constructor(raw) {
    this.raw = raw
  }

  get id() {
    return this.raw.id
  }

  get firstName() {
    return this.raw.first_name
  }

  get lastName() {
    return this.raw.last_name
  }

  get hideAge() {
    return this.raw.hide_age
  }
}

export class RecroomCelebrationAward {
  constructor(raw) {
    this.raw = raw
  }

  get id() {
    return this.raw.id
  }

  get name() {
    return this.raw.name
  }
}
