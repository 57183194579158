import get from 'lodash-es/get'
import Vue from 'vue'
import api from '/~/core/api'
import { formatDate } from '/~/utils/format/date'

export class Notification {
  constructor(raw) {
    this.raw = raw
    this.body = JSON.parse(raw.body || raw.message || '{}')
  }

  get id() {
    return this.raw.id
  }

  get topic() {
    return this.raw.topic
  }

  get title() {
    return get(this.body, 'body', '')
  }

  get actions() {
    return (get(this.body, 'actions') || []).map((action) => {
      return {
        ...action,
        link: action.link.replace(/^http.+\.au/, ''),
      }
    })
  }

  get action() {
    return this.body.action
  }

  get product() {
    return get(this.body, 'product', '')
  }

  get event() {
    return get(this.body, 'event', '')
  }

  get metadata() {
    return this.body?.metadata ?? {}
  }

  get image() {
    return this.metadata.logo ?? this.body?.image ?? ''
  }

  get type() {
    return get(this.body, 'type', '')
  }

  get createdAt() {
    return this.raw.createdAt
  }

  get date() {
    const date = this.raw.createdAt

    return formatDate('daymonthyearfulltime', date)
  }

  get status() {
    return this.raw.status
  }

  get icon() {
    const { event, type } = this
    const map = [
      [/(hi5|five)/, 'rec/notifications/hi5'],
      [/poll/, 'rec/notifications/poll'],
      [/ann/, 'rec/notifications/announcement'],
      [/quiz/, 'rec/notifications/quiz'],
      [/chat/, 'rec/notifications/chat'],
      [/saved/, 'rec/notifications/saved'],
      [/like/, 'rec/notifications/like'],
      [/comment/, 'rec/notifications/comment'],
      [/nominat|award/, 'rec/notifications/award'],
      [/group/, 'rec/notifications/group'],
      [/event/, 'rec/notifications/event'],
      [/approved/, 'rec/notifications/perk'],
    ]

    if (this.isStatementNotification) {
      return this.isAutopayEnabledForStatement
        ? 'notifications/dollar-repeat'
        : 'notifications/dollar'
    } else if (type === 'administrator') {
      return 'rec/notifications/cog'
    } else if (event) {
      const mapItem = map.find((i) => {
        const regex = i[0]

        return regex && regex.test(event)
      })

      return mapItem?.[1] ?? 'rec/notifications/cog'
    }

    return ''
  }

  get isRead() {
    return this.status === 'read'
  }

  get isStatementNotification() {
    return (
      this.isStatementReadyNotification ||
      this.isStatementDueNotification ||
      this.isStatementCompletedNotification
    )
  }

  get isStatementReadyNotification() {
    return this.event === 'statement_order_ready'
  }

  get isStatementDueNotification() {
    return ['statement_order_due', 'statement_order_due_autopay'].includes(
      this.event
    )
  }

  get isStatementCompletedNotification() {
    return [
      'statement_order_completed',
      'statement_order_completed_autopay',
    ].includes(this.event)
  }

  get isAutopayEnabledForStatement() {
    return [
      'statement_order_due_autopay',
      'statement_order_completed_autopay',
    ].includes(this.event)
  }

  get isOrderItemDispatchedNotification() {
    return this.event === 'order_item_dispatched'
  }

  async markAsRead() {
    if (this.isRead) return

    Vue.set(this.raw, 'status', 'read')

    const response = await api.put('/v3/notifications', {
      messages: [this.id],
      status: 'read',
    })

    return response
  }

  remove() {
    return api.delete(`/v3/notifications/${this.id}`)
  }
}
