<script setup lang="ts">
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import Controls from '/~/extensions/bank-file-upload/components/common/controls.vue'
import Notification from '/~/extensions/bank-file-upload/components/common/notification.vue'
import Pagination from '/~/extensions/bank-file-upload/components/common/pagination.vue'
import UploadedFile from '/~/extensions/bank-file-upload/components/common/uploaded-file.vue'
import NewPayeesTable from '/~/extensions/bank-file-upload/components/new-payees/table/new-payees-table.vue'
import LayoutMain from '/~/extensions/bank-file-upload/layouts/main.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import {
  useBatchOrder,
  useBatchOrderNewPayees,
} from '/~/composables/batch-order'

const { missingPayees, allCreated, payeesRemaining, loading, onContinue } =
  useBatchOrderNewPayees()

const { onCancelBatchOrder } = useBatchOrder()

async function init() {
  await missingPayees.load(1)
  if (!allCreated.value) {
    modal.show('batch-payment-new-payees')
  }
}

init()

const cancelButtonText = 'Cancel'
const continueButtonText = 'Continue'

const tableLabels = [
  { name: 'Account name', class: 'px-5 w-72' },
  { name: 'BSB / Account', class: 'px-5' },
  { name: 'Payee ABN', class: 'ml-auto w-80 px-5' },
  { name: '', class: 'w-60 px-5' },
]
</script>

<template>
  <layout-main>
    <component
      :is="ui.mobile ? BaseAsidePage : 'div'"
      :title="ui.mobile && 'Upload file details'"
      :no-padding="ui.mobile && true"
      :back="ui.mobile && { name: 'home' }"
      class="h-full"
    >
      <div class="flex h-full flex-col p-5 lg:px-12 lg:py-8">
        <notification class="mb-8" icon="heroicons/solid/information-circle">
          Add all new payees in order to continue batch payment.
        </notification>

        <div class="mb-8">
          <h1
            v-if="!ui.mobile"
            class="mb-8 text-2xl leading-none text-eonx-neutral-800"
          >
            New payees detected
          </h1>
          <div class="mt-4 space-y-4">
            <p>Add all new payees in order to continue batch payment.</p>
            <p>
              Once complete, all new payees will be verified before the batch
              payment is processed.
            </p>
          </div>
          <div class="mt-8 flex space-x-5 divide-x">
            <uploaded-file />
            <div class="space-x-1 pl-5 font-bold">
              <span class="text-eonx-neutral-600">New payees remaining:</span>
              <span class="text-eonx-neutral-800">
                {{ payeesRemaining }}
              </span>
            </div>
          </div>
        </div>
        <new-payees-table
          :labels="tableLabels"
          :rows="missingPayees.payeeList.list"
          :loading="missingPayees.loader.loading"
          @validated="missingPayees.payeeList.abnValidated($event)"
          @clear="missingPayees.payeeList.clearAbn($event)"
          @addPayee="missingPayees.payeeList.addPayee($event)"
        />

        <pagination
          v-if="missingPayees.pagination.totalPages > 1"
          class="mt-8"
          :current-page="missingPayees.pagination.currentPage"
          :total-pages="missingPayees.pagination.totalPages"
          @selectPage="missingPayees.load($event)"
        />

        <controls
          class="sticky bottom-0 mt-8 w-full bg-eonx-neutral-50"
          :is-continue-disabled="!allCreated"
          :is-continue-loading="missingPayees.loader.loading || loading"
          :cancel-text="cancelButtonText"
          :continue-text="continueButtonText"
          @cancel="onCancelBatchOrder"
          @continue="onContinue"
        />
      </div>
    </component>
  </layout-main>
</template>
