<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import RecDirectoryProfile from '/~rec/views/directory/members/member-profile.vue'

export default {
  name: 'rec-directory-profile-modal',
  components: {
    BaseMdl,
    RecDirectoryProfile,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onClose() {
      modal.hide()
      this.$emit('back')
    },
  },
}
</script>

<template>
  <base-mdl
    width="screen"
    transition="slide-bottom"
    class="ease transform duration-500"
    modal-styles="max-height:40rem; margin-bottom:0"
    modal-class="rounded-t-3xl"
    fullscreen
    :closable="false"
  >
    <template #header>
      <div />
    </template>
    <rec-directory-profile :user="user" @back="onClose" />
  </base-mdl>
</template>
