/**
 * Once business confirm about the video feature, this function may not required.
 */
import isEmpty from 'lodash-es/isEmpty'

export default function (videoUrl, dimensions = '300x300') {
  if (isEmpty(videoUrl)) return
  const fullUrl = new URL(videoUrl).searchParams
  const extractThumbnail = fullUrl.get('thumbnail')

  return `${extractThumbnail}-/scale_crop/${dimensions}/center/`
}
