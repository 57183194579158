<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import CashbackPanel from '/~/components/cashback/cashback-panel.vue'
import CheckoutSummaryAddress from '/~/components/checkout/summary/checkout-summary-address.vue'
import CheckoutSummaryItems from '/~/components/checkout/summary/checkout-summary-items.vue'
import CheckoutSummaryPayWith from '/~/components/checkout/summary/checkout-summary-pay-with.vue'
import CheckoutSummaryTotal from '/~/components/checkout/summary/checkout-summary-total.vue'
import PointsPanel from '/~/components/points/points-panel.vue'
import { useCart } from '/~/composables/cart'
import { useQuickBuyCheckout } from '/~/composables/checkout'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

withDefaults(
  defineProps<{
    loading?: boolean
  }>(),
  {
    loading: false,
  }
)

const router = useRouter()
const route = useRoute()
const { cartOrderAddresses } = useCart()
const { isPurchaseOrderPoints } = usePoints()
const { isUserAddressEnabled } = useProvider()
const {
  payment,
  selectedCard,
  payWithCard,
  selectedPoints,
  payWithPoints,
  selectedEwallet,
  payWithEwallet,
  selectedBankAccount,
  payWithBankAccount,
  selectedCoupons,
  payWithCoupons,
  loadingFees,
  subTotalNoCoupons,
  total,
  initItems,
  quickBuyProducts,
  cashbackRebateTotal,
} = useQuickBuyCheckout()

try {
  initItems({
    type: route.query?.type ?? null,
    query: route.query ?? null,
  })
} catch (e) {
  console.error(e)
  router.push({ name: 'home' })
}
</script>

<template>
  <div class="flex h-full flex-col">
    <div
      class="flex h-full grow flex-col overflow-y-auto px-0 py-5 sm:px-[30px]"
    >
      <span class="mt-2.5 mb-5 text-2xl font-bold">Summary</span>
      <div class="divide-y">
        <checkout-summary-address
          v-if="isUserAddressEnabled"
          :billing="cartOrderAddresses.billing"
          :shipping="
            payment.hasDeliverableItems ? cartOrderAddresses.shipping : null
          "
          :loading="cartOrderAddresses.loading"
          class="pb-5"
        />
        <checkout-summary-items :items="quickBuyProducts" class="py-5" />
        <checkout-summary-pay-with
          :credit-card="{ details: selectedCard, total: payWithCard }"
          :points="{ details: selectedPoints, total: payWithPoints }"
          :ewallet="{ details: selectedEwallet, total: payWithEwallet }"
          :bank-account="{
            details: selectedBankAccount,
            total: payWithBankAccount,
          }"
          :coupons="{ items: selectedCoupons, total: payWithCoupons }"
          class="pt-5"
        />
        <div v-if="loading" class="w-full space-y-2.5 pt-5">
          <div
            class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
          >
            loading
          </div>
          <div
            class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
          >
            loading
          </div>
        </div>
        <checkout-summary-total
          v-else
          :loading-fees="loadingFees"
          :transaction-fees="payment.transactionFees"
          :shipping-fees="payment.shippingFees"
          :amount="subTotalNoCoupons - payment.shippingFees"
          :total="total"
          class="pt-5"
        />
      </div>
    </div>
    <div :class="{ 'flex px-[30px] pb-[30px]': ui.desktop }">
      <points-panel
        v-if="isPurchaseOrderPoints"
        :total="payment.pointsEarned"
        class="-mx-5 sm:mx-0"
      />
      <cashback-panel :total="cashbackRebateTotal" class="-mx-5 sm:mx-0" />
    </div>
  </div>
</template>
