<script>
import AddressItem from './addresses/addresses-item.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import emitter from '/~/core/emitter'
import { useCart } from '/~/composables/cart'
import { useAddresses } from '/~/composables/addresses'
import { useRoute, useRouter } from 'vue-router/composables'
import { useCheckout, useCheckoutRoutes } from '/~/composables/checkout'
import { computed, ref, watch, onBeforeUnmount } from 'vue'

export default {
  name: 'checkout-select-address',
  components: {
    AddressItem,
    BaseCheckbox,
  },
  props: {
    showAddresses: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { getCurrentFlowRouteName } = useCheckoutRoutes()
    const { payment } = useCheckout()
    const { addCartOrderAddress, removeCartOrderAddress, cartOrderAddresses } =
      useCart()
    const { addresses, defaultAddress, firstAddress, addressesLoaded } =
      useAddresses()
    const billingAddress = computed(() => {
      const addressesList = addresses.value ?? []

      return addressesList.find(
        (address) => address.id === cartOrderAddresses.billing?.id
      )
    })
    const shippingAddress = computed(() => {
      const addressesList = addresses.value ?? []

      return addressesList.find(
        (address) => address.id === cartOrderAddresses.shipping?.id
      )
    })
    const differentAddress = ref(null)
    const hasAddresses = computed(() => {
      return !!(addresses.value && addresses.value.length)
    })
    const isDelivery = computed(() => {
      return hasAddresses.value && payment.hasDeliverableItems
    })

    async function initAddresses() {
      await setDefaultAddress()
      checkDifferentAddress()
    }

    function onAddressAdd() {
      router.replace({ name: getCurrentFlowRouteName('checkout') })
      initAddresses()
    }

    function checkDifferentAddress() {
      const { billing, shipping } = cartOrderAddresses

      if (shipping && billing?.id !== shipping.id) {
        differentAddress.value = true
      }
    }
    function setDefaultAddress() {
      const fallbackAddress = defaultAddress.value || firstAddress.value

      if (!fallbackAddress) {
        return
      }

      if (!cartOrderAddresses.billing) {
        addCartOrderAddress({
          address: fallbackAddress,
          type: 'billing',
        })
      }

      if (isDelivery.value) {
        if (!cartOrderAddresses.shipping) {
          addCartOrderAddress({
            address: fallbackAddress,
            type: 'shipping',
          })
        }
      } else {
        if (cartOrderAddresses.shipping) {
          removeCartOrderAddress('shipping')
        }
      }
    }

    watch(
      addressesLoaded,
      () => {
        if (!addressesLoaded.value) {
          return
        }

        initAddresses()
      },
      {
        immediate: true,
      }
    )

    watch(differentAddress, (val) => {
      if (val) {
        return
      }

      addCartOrderAddress({
        address: cartOrderAddresses.billing,
        type: 'shipping',
      })
    })

    function onBilling() {
      router.push({
        name: getCurrentFlowRouteName('checkout-billing'),
        query: route.query,
      })
    }

    function onShipping() {
      router.push({
        name: getCurrentFlowRouteName('checkout-shipping'),
        query: route.query,
      })
    }

    onBeforeUnmount(() => {
      emitter.off('addresses:add', onAddressAdd)
    })

    emitter.on('addresses:add', onAddressAdd)

    return {
      addressesLoaded,
      addCartOrderAddress,
      cartOrderAddresses,
      addresses,
      defaultAddress,
      firstAddress,
      billingAddress,
      shippingAddress,
      differentAddress,
      hasAddresses,
      isDelivery,
      onBilling,
      onShipping,
    }
  },
}
</script>

<template>
  <div v-if="showAddresses">
    <h2 class="mb-4 text-base sm:text-xl">Billing</h2>
    <address-item :item="billingAddress" @click="onBilling" />

    <template v-if="addressesLoaded && isDelivery">
      <div class="mt-4">
        <base-checkbox v-model="differentAddress" look="v1">
          <span class="text-sm font-semibold sm:text-base">
            Ship to different address
          </span>
        </base-checkbox>
      </div>
      <template v-if="differentAddress && shippingAddress">
        <h2 class="my-4 text-xl">Shipping</h2>
        <address-item :item="shippingAddress" @click="onShipping" />
      </template>
    </template>
  </div>
</template>
