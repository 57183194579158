<script>
import ui from '/~/core/ui'
import CashbackPanel from '/~/components/cashback/cashback-panel.vue'
import CheckoutSummaryAddress from '/~/components/checkout/summary/checkout-summary-address.vue'
import CheckoutSummaryItems from '/~/components/checkout/summary/checkout-summary-items.vue'
import CheckoutSummaryPayWith from '/~/components/checkout/summary/checkout-summary-pay-with.vue'
import CheckoutSummaryTotal from '/~/components/checkout/summary/checkout-summary-total.vue'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

const { isBillPaymentsTemplate } = useProvider()

export default {
  name: 'checkout-summary',
  components: {
    CheckoutSummaryItems,
    CheckoutSummaryAddress,
    CheckoutSummaryPayWith,
    CheckoutSummaryTotal,
    PointsPanel: () =>
      isBillPaymentsTemplate.value
        ? import('/~/components/points/points-panel.v2.vue')
        : import('/~/components/points/points-panel.vue'),
    CashbackPanel,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { cartItemsFiltered, cartOrderAddresses, cashbackRebateTotal } =
      useCart()
    const { isUserAddressEnabled } = useProvider()

    const {
      payment,
      selectedCard,
      payWithCard,
      selectedPoints,
      payWithPoints,
      selectedEwallet,
      payWithEwallet,
      selectedBankAccount,
      payWithBankAccount,
      selectedCoupons,
      payWithCoupons,
      loadingFees,
      subTotal,
      subTotalNoCoupons,
      total,
      pointsEarned,
    } = useCheckout()
    const { isPurchaseOrderPoints } = usePoints()

    return {
      cartItemsFiltered,
      selectedCard,
      payWithCard,
      selectedPoints,
      payWithPoints,
      selectedEwallet,
      payWithEwallet,
      selectedBankAccount,
      payWithBankAccount,
      selectedCoupons,
      cartOrderAddresses,
      payWithCoupons,
      loadingFees,
      subTotal,
      subTotalNoCoupons,
      total,
      pointsEarned,
      payment,
      cashbackRebateTotal,
      isPurchaseOrderPoints,
      isBillPaymentsTemplate,
      isUserAddressEnabled,
      ui,
    }
  },
}
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="flex h-full grow flex-col overflow-y-auto px-[30px] py-5">
      <h2 class="mt-2.5 mb-5 text-2xl font-bold">Summary</h2>
      <div class="divide-y">
        <checkout-summary-address
          v-if="isUserAddressEnabled"
          :billing="cartOrderAddresses.billing"
          :shipping="
            payment.hasDeliverableItems ? cartOrderAddresses.shipping : null
          "
          :loading="cartOrderAddresses.loading"
          class="pb-5"
        />
        <checkout-summary-items :items="cartItemsFiltered" class="py-5" />
        <checkout-summary-pay-with
          :credit-card="{ details: selectedCard, total: payWithCard }"
          :points="{ details: selectedPoints, total: payWithPoints }"
          :ewallet="{ details: selectedEwallet, total: payWithEwallet }"
          :bank-account="{
            details: selectedBankAccount,
            total: payWithBankAccount,
          }"
          :coupons="{ items: selectedCoupons, total: payWithCoupons }"
          class="pt-5"
        />
        <div v-if="loading" class="w-full space-y-2.5 pt-5">
          <div
            class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
          >
            loading
          </div>
          <div
            class="animate-pulse rounded bg-eonx-neutral-50 text-transparent"
          >
            loading
          </div>
        </div>
        <checkout-summary-total
          v-else
          :loading-fees="loadingFees"
          :transaction-fees="payment.transactionFees"
          :shipping-fees="payment.shippingFees"
          :amount="subTotalNoCoupons - payment.shippingFees"
          :total="total"
          class="pt-5"
        />
      </div>
    </div>
    <div class="md:flex">
      <points-panel
        v-if="isPurchaseOrderPoints"
        :total="pointsEarned"
        :class="{ 'm-[30px] w-full': isBillPaymentsTemplate && ui.desktop }"
      />
      <cashback-panel :total="cashbackRebateTotal" />
    </div>
  </div>
</template>
