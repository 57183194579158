<script>
import ui from '/~/core/ui'
import get from 'lodash-es/get'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { formatNumber } from '/~/utils/format/numeric'
import { useDining } from '/~/extensions/dining/composables'

export default {
  name: 'dining-tile',
  components: {
    BaseLink,
    BaseImage,
    BaseIcon,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { mapState, retailerImage, offerDiscount } = useDining()

    return {
      mapState,
      retailerImage,
      offerDiscount,
      formatNumber,
      ui,
    }
  },
  computed: {
    isMapVisible() {
      return this.mapState.isVisible && !ui.mobile
    },
    fakeCosts() {
      const { average_cost: cost } = this.location
      const count = cost.length <= 3 ? 3 - cost.length : 0

      return cost ? `${'$'.repeat(count)}` : ''
    },
    defaultImage() {
      return this.cdn('uploadcare://41c495fe-e05c-40c3-a792-13e6f9fdee7e')
        .preview('270x150')
        .quality('best')
        .url()
    },
    imageSrc() {
      const imgPath = this.retailerImage
        ? `uploadcare://${this.retailerImage}`
        : null
      const locImage = get(this.location, 'imgs.0')

      return (
        locImage || this.cdn(imgPath).preview('270x150').quality('best').url()
      )
    },
    linkTo() {
      return {
        name: 'dining-item',
        params: {
          slug: this.location.slug,
        },
      }
    },
  },
}
</script>

<template>
  <base-link
    :to="linkTo"
    class="relative flex h-full overflow-hidden rounded-md border"
    :class="{
      'h-30 flex-row': isMapVisible,
      'flex-col': !isMapVisible,
    }"
    data-test="dining-tile__container"
  >
    <base-image
      :src="imageSrc"
      :alt="location.name"
      :default-src="defaultImage"
      :rounded="false"
      class="shrink-0"
      :class="{
        'max-w-48': isMapVisible,
      }"
      data-test="dining-tile__image"
      aria-hidden="true"
    />
    <div
      class="absolute top-0 m-1.5"
      aria-hidden="true"
      :class="{
        'left-0': isMapVisible,
        'right-0': !isMapVisible,
      }"
    >
      <div
        class="rounded bg-white px-[5px] text-sm font-medium text-eonx-neutral-800"
        data-test="dining-tile__price"
      >
        <span
          v-for="index in 3"
          :key="index"
          :class="index > location.priceRange && 'opacity-25'"
        >
          $
        </span>
      </div>
    </div>
    <div
      class="grow p-2.5 md:p-[15px]"
      :class="{
        'text-left': isMapVisible,
        'text-center': !isMapVisible,
      }"
    >
      <div class="flex">
        <h3
          v-if="location.name"
          class="w-0 flex-1 truncate text-sm font-bold text-eonx-neutral-600"
          data-test="dining-tile__title"
        >
          {{ location.name }}
        </h3>
      </div>
      <div
        v-if="location.address"
        class="my-0.5 flex items-center text-sm font-bold text-eonx-neutral-600"
        :class="{ 'justify-center': !isMapVisible }"
      >
        <base-icon svg="plain/marker" size="xs" />
        <span class="truncate text-sm" data-test="dining-tile__address">
          {{ location.address.city }}
        </span>
      </div>
      <div
        v-if="offerDiscount"
        class="truncate font-bold text-eonx-neutral-800"
        data-test="dining-tile__discount"
      >
        {{ formatNumber(offerDiscount) }}% OFF
      </div>
    </div>
  </base-link>
</template>
