import Vue, { computed, reactive, readonly, ref } from 'vue'
import UploadableFile from './core/UploadableFile'

export function useUpload(
  options = {
    driver: 'UploadcareDriver',
    driverOptions: null,
  }
) {
  let driverInstance

  const uploadableFiles = ref([])
  const currentUploadableFiles = ref([])
  const isUploading = computed(() =>
    uploadableFiles.value.some((file) => file.uploading)
  )
  const loadedPercents = computed(() => {
    if (currentUploadableFiles.value.length === 0) {
      return 0
    }

    return Math.floor(
      currentUploadableFiles.value.reduce((loadedPercents, file) => {
        return loadedPercents + file.loadedPercents
      }, 0) / currentUploadableFiles.value.length
    )
  })

  async function ensureDriver() {
    if (driverInstance) {
      return
    }

    let DriverClass

    switch (options.driver) {
      case 'S3Driver':
        DriverClass = (await import('./core/drivers/S3Driver')).default
        break
      default:
        DriverClass = (await import('./core/drivers/UploadcareDriver')).default
    }

    driverInstance = new DriverClass(options.driverOptions)
  }

  async function uploadFiles(files) {
    await ensureDriver()

    const uploadPromises = []

    currentUploadableFiles.value.splice(0, currentUploadableFiles.value.length)

    files.forEach((file) => {
      const uploadableFile = reactive(new UploadableFile(file))

      uploadableFiles.value.push(uploadableFile)
      currentUploadableFiles.value.push(uploadableFile)
      uploadPromises.push(driverInstance.upload(uploadableFile))
    })

    await Promise.allSettled(uploadPromises)

    let hasUploadErrors = false

    currentUploadableFiles.value.forEach((file) => {
      if (!file.error) {
        return
      }

      hasUploadErrors = true

      Vue.notify({
        type: 'error',
        text: file.error,
      })
    })

    if (hasUploadErrors) {
      throw new Error('Something went wrong while uploading. Please try again.')
    }
  }

  function removeUploadableFile(file) {
    file.cancel()

    const uploadableFileIndex = uploadableFiles.value.findIndex(
      (uploadableFile) => uploadableFile === file
    )
    const currentUploadableFileIndex = currentUploadableFiles.value.findIndex(
      (currentUploadableFile) => currentUploadableFile === file
    )

    if (uploadableFileIndex !== -1) {
      uploadableFiles.value.splice(uploadableFileIndex, 1)
    }

    if (currentUploadableFileIndex !== -1) {
      currentUploadableFiles.value.splice(currentUploadableFileIndex, 1)
    }
  }

  function resetUploadableFiles() {
    uploadableFiles.value.splice(0, uploadableFiles.value.length)
    currentUploadableFiles.value.splice(0, currentUploadableFiles.value.length)
  }

  return {
    uploadableFiles: readonly(uploadableFiles),
    isUploading,
    loadedPercents,
    uploadFiles,
    removeUploadableFile,
    resetUploadableFiles,
  }
}
