<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import EventInfo from '/~rec/components/event/event-info.vue'
import EventStatus from '/~rec/components/event/event-status.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'event-info-mobile-modal',
  components: {
    BaseMdl,
    EventInfo,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return { isMeAdmin, isMeSuperAdmin }
  },
  data() {
    return {
      usersProcessing: false,
    }
  },
  computed: {
    canEditMembers() {
      return this.event?.isMeOwner || this.isMeAdmin || this.isMeSuperAdmin
    },
    usersMap() {
      return this.event.members.hits.map((u) => u.id)
    },
  },
  methods: {
    filterUsers(allUsers) {
      return allUsers.filter((user) => this.usersMap.includes(user.id))
    },
    async updateEvent(membersMap = []) {
      // This enpoint is used to update event details and event members
      const data = {
        banner_url: this.event.image,
        private: this.event.isPrivate ? 1 : 0,
        type: this.event.type,
        name: this.event.name,
        startsAt: this.event.startsAt,
        endsAt: this.event.endsAt,
        start_timezone: this.event.timeZone,
        location: this.event.location,
        description: this.event.description,
        members: membersMap.map((member) => {
          return {
            id: member.id,
            status: member.status,
          }
        }),
      }

      await this.event.edit(data)
      await this.event.members.refresh()
    },
    openMembers() {
      modal.show('rec-users-manager', {
        props: {
          title: () =>
            `${this.event?.name} (${this.event?.members?.hits?.length})`,
          removable: this.canEditMembers,
          multi: true,
          getCurrentUsers: () => this.event.members.hits || [],
          disableUser: (id) => this.usersMap.includes(id),
          removeHandler: this.memberRemoveHandler,
          filter: this.filterUsers,
          disabledLabel: 'Already invited',
          hideCurrent: true,
          selectable: this.canEditMembers,
          slotData: {
            component: EventStatus,
            params: { event: this.event },
          },
        },
        on: {
          'user-select': async (members) => {
            this.usersProcessing = true
            const currentMembers = this.event.members.hits.map((u) => ({
              id: u.id,
              status: u.status,
            }))
            const newMembers = members.map((m) => {
              return {
                id: m.id,
                status:
                  currentMembers.find((c) => c.id === m.id)?.status ||
                  'invited',
              }
            })

            await this.updateEvent(newMembers)
            this.usersProcessing = false
          },
        },
      })
    },
    async memberRemoveHandler(id) {
      this.usersProcessing = true
      await this.updateEvent(this.event.members.hits.filter((u) => u.id !== id))
      this.usersProcessing = false
    },
    closeModal() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl
    fullscreen
    modal-styles="max-height: 44rem; margin-bottom: 0"
    :layout="false"
    width="screen"
    modal-class="rounded-t-3xl"
    transition="slide-bottom"
    class="ease transform duration-500"
  >
    <event-info
      :event="event"
      :users-processing="usersProcessing"
      @close="closeModal"
      @open-members="openMembers"
    />
  </base-mdl>
</template>
