import bottomBar from './bottom-bar'
import footer from './footer'
import left from './left'
import leftMobile from './left-mobile'
import loginFooter from './login-footer'
import settings from './settings'
import top from './top'

export default {
  'bottom-bar': bottomBar,
  footer,
  'login-footer': loginFooter,
  top,
  settings,
  left,
  'left-mobile': leftMobile,
}
