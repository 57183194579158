<script>
import { toRefs } from 'vue'
import { useAward } from '/~rec/composables/award'
import { useRecProfile } from '/~rec/composables/profile'
import AwardLeaderboard from '/~rec/components/award/award-leaderboard.vue'
import AwardNominations from '/~rec/components/award/award-nominations.vue'
import AwardPlaceholder from '/~rec/components/placeholder/placeholder-awards-perks-hi-details.vue'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import modal from '/~/core/mdl'
import RecognitionTokens from '/~rec/components/recognition/recognition-tokens.vue'
import ui from '/~/core/ui'

export default {
  name: 'rec-award',
  components: {
    BaseTab,
    BaseTabs,
    BaseIcon,
    BaseBack,
    BaseImage,
    BaseButton,
    RecognitionTokens,
    AwardNominations,
    AwardLeaderboard,
    AwardPlaceholder,
  },
  props: {
    awardStatus: {
      type: String,
      default: '',
    },
    awardId: {
      type: String,
      default: '',
    },
    selectedTab: {
      type: String,
      default: 'information',
    },
    winnerId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const propsAsRefs = toRefs(props)

    const { features } = useRecProfile()
    const {
      award,
      awardId,
      awardStatus,
      getNominal,
      init,
      loading,
      nominate,
      nominateIsDisabled,
      onRowClick,
      rank,
      status,
      winnerId,
    } = useAward()

    awardStatus.value = propsAsRefs.awardStatus
    awardId.value = propsAsRefs.awardId
    winnerId.value = propsAsRefs.winnerId

    return {
      award,
      features,
      getNominal,
      init,
      loading,
      nominate,
      nominateIsDisabled,
      onRowClick,
      rank,
      status,
      ui,
    }
  },
  data() {
    return {
      activeTab: this.selectedTab,
    }
  },
  computed: {
    getTypeForBaseBack() {
      return this.awardStatus === 'finished' ? 'available' : this.awardStatus
    },
  },
  created() {
    if (ui.desktop) {
      this.init()
    } else {
      modal.show('rec-award-details-mobile', {
        props: {
          awardId: this.awardId,
          awardStatus: this.awardStatus,
          winnerId: this.winnerId,
        },
      })
    }
  },
  methods: {
    changeTabs(value) {
      this.activeTab = value.toLowerCase()
    },
    close() {
      modal.hideAll()
      this.$router.push({
        name: 'rec-awards',
        params: {
          type: this.awardStatus,
        },
      })
    },
  },
}
</script>

<template>
  <div
    class="mx-auto flex w-full max-w-screen-md flex-col px-5 py-[30px] md:p-[30px]"
  >
    <award-placeholder
      v-if="loading || !award"
      :rank="awardStatus === 'received'"
      :points="features.points"
      entity="award"
      action
      tabs
    />
    <div v-else>
      <div>
        <base-back
          :to="{
            name: 'rec-awards',
            query: {
              type: getTypeForBaseBack,
            },
          }"
          class="mb-6"
        />
      </div>
      <div class="relative h-full">
        <div class="flex grow flex-col space-y-2.5">
          <div class="flex items-center">
            <div class="flex flex-col items-start">
              <h2
                class="mb-[5px] grid grid-cols-1 break-words pr-[15px] text-eonx-neutral-800"
              >
                {{ award.title }}
              </h2>

              <div class="flex justify-start">
                <div v-if="status" class="mr-2.5">
                  <span
                    :class="[
                      'inline-block rounded-3xl px-5 py-[5px] text-sm font-semibold capitalize',
                      status.theme,
                      status.textColor,
                    ]"
                  >
                    {{ status.label }}
                  </span>
                </div>

                <div v-if="rank">
                  <span
                    :class="[
                      'mb-5 inline-flex items-center rounded-3xl px-5 py-[5px] text-sm font-semibold capitalize text-white',
                      rank.bg,
                    ]"
                  >
                    <base-icon
                      svg="symbion/award"
                      size="xxs"
                      class="mr-2.5 shrink-0"
                    />
                    {{ rank.label }}
                  </span>
                </div>
              </div>
            </div>

            <div class="ml-auto hidden p-px md:block">
              <v-popover
                trigger="hover"
                offset="15"
                placement="top"
                class="flex flex-col"
                :disabled="loading || award.iCanNominate"
              >
                <base-button
                  :loading="loading"
                  :disabled="nominateIsDisabled"
                  @click="nominate()"
                >
                  <span class="text-base">Nominate</span>
                </base-button>
                <template #popover>
                  <div>You are not eligible to Nominate for this Award</div>
                </template>
              </v-popover>
            </div>
          </div>

          <div class="flex h-full flex-col">
            <base-tabs
              look="recroom"
              class="grow text-eonx-neutral-800"
              :value="activeTab"
              @change="changeTabs"
            >
              <base-tab
                name="Information"
                class="mt-[5px] grow"
                :value="'information'"
                :selected="activeTab === 'information'"
              >
                <div
                  class="flex flex-col space-y-[30px] lg:flex-row lg:space-x-6 lg:space-y-0"
                >
                  <div class="min-w-40 shrink-0 space-y-5 xl:min-w-64">
                    <base-image :ratio="1" :src="award.image" />
                  </div>

                  <div class="flex-1 space-y-[15px]">
                    <div v-if="features.points && getNominal" class="flex">
                      <recognition-tokens
                        :nominal="getNominal"
                        label=" awarded for most nominations"
                        color="gold"
                        class="rounded-md px-6 py-2.5"
                        icon="rec/token"
                      />
                    </div>

                    <p
                      class="grid grid-cols-1 break-words leading-6 text-eonx-neutral-800"
                    >
                      {{ award.description }}
                    </p>

                    <award-nominations
                      :status="awardStatus"
                      :nomination-limit-label="award.nominateLimitLabel"
                      :ends-at="award.endsAt"
                      :current-period="award.currentPeriod.endsAt"
                      class="text-eonx-neutral-600"
                    />
                  </div>
                </div>
              </base-tab>
              <base-tab
                name="Leaderboard"
                :value="'leaderboard'"
                :selected="activeTab === 'leaderboard'"
              >
                <div class="overflow-x-hidden lg:space-x-6 lg:space-y-0">
                  <award-leaderboard
                    :award="award"
                    :nominate-is-disabled="nominateIsDisabled"
                    :period-id="award.periodId"
                    @row-click="onRowClick"
                    @nominate="nominate()"
                  />
                </div>
              </base-tab>
            </base-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
