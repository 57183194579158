<script setup lang="ts">
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import { useCheckoutReactive } from '/~/composables/checkout'

const { payment } = useCheckoutReactive()
</script>

<template>
  <div
    v-show="payment.termsShouldBeAccepted"
    class="mt-1 flex items-start sm:mt-3"
  >
    <base-checkbox
      v-model="payment.termsAccepted"
      look="v3"
      name="termsAccepted"
    />
    <div class="-mt-[3px]">
      <span class="text-default">
        I acknowledge bank payments take up to 3 business days and insufficient
        funds may incur bank dishonour fees.
      </span>
    </div>
  </div>
</template>
