<script>
import emitter from '/~/core/emitter'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useAddresses } from '/~/composables/addresses'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import AddressItem from './address-item.vue'

export default {
  name: 'cart-menu-addresses',
  components: {
    AddressItem,
    BaseIcon,
    BaseCheckbox,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    billingRoute: {
      type: Object,
      default: () => ({ hash: '#cart-billing' }),
    },
    shippingRoute: {
      type: Object,
      default: () => ({ hash: '#cart-shipping' }),
    },
  },
  setup() {
    const { addCartOrderAddress, cartOrderAddresses } = useCart()
    const { payment } = useCheckout()
    const { addresses, defaultAddress, firstAddress } = useAddresses()

    return {
      payment,
      addCartOrderAddress,
      cartOrderAddresses,
      addresses,
      defaultAddress,
      firstAddress,
    }
  },
  data() {
    return {
      differentAddress: false,
    }
  },
  computed: {
    hasAddresses() {
      return !!(this.addresses && this.addresses.length)
    },
    isDelivery() {
      return this.payment.hasDeliverableItems && this.hasAddresses
    },
    billingAddress() {
      const {
        addresses = [],
        cartOrderAddresses: { billing },
      } = this

      return addresses.find((address) => address.id === billing?.id)
    },
    shippingAddress() {
      const {
        addresses = [],
        cartOrderAddresses: { shipping },
      } = this

      return addresses.find((address) => address.id === shipping?.id)
    },
  },
  watch: {
    isLoading() {
      if (!this.isLoading) {
        this.initAddresses()
      }
    },
    differentAddress(val) {
      if (!val) {
        this.addCartOrderAddress({
          address: this.cartOrderAddresses.billing,
          type: 'shipping',
        })
      }
    },
  },
  created() {
    emitter.on('cart-new-address', this.initAddresses)
  },
  mounted() {
    this.initAddresses()
  },
  beforeDestroy() {
    emitter.off('cart-new-address', this.initAddresses)
  },
  methods: {
    async initAddresses() {
      if (!this.isLoading) {
        await this.setDefaultAddress()
        this.checkDifferentAddress()
      }
    },
    checkDifferentAddress() {
      const { billing, shipping } = this.cartOrderAddresses

      if (shipping && billing?.id !== shipping.id) {
        this.differentAddress = true
      }
    },
    setDefaultAddress() {
      const fallbackAddress = this.defaultAddress || this.firstAddress

      if (fallbackAddress) {
        if (!this.cartOrderAddresses.billing) {
          this.addCartOrderAddress({
            address: fallbackAddress,
            type: 'billing',
          })
        }

        if (this.isDelivery && !this.cartOrderAddresses.shipping) {
          this.addCartOrderAddress({
            address: fallbackAddress,
            type: 'shipping',
          })
        }
      }
    },
  },
}
</script>

<template>
  <div>
    <h2 class="mb-2.5 text-lg font-bold">Billing</h2>
    <address-item
      :loading="isLoading"
      :item="billingAddress"
      @click="$router.push(billingRoute)"
    >
      <template #menu>
        <base-icon svg="v2/custom/chevron-right-outline" :size="14" />
      </template>
    </address-item>

    <template v-if="!isLoading && isDelivery">
      <div class="mt-2.5">
        <base-checkbox v-model="differentAddress" look="v2">
          Ship to Different Address
        </base-checkbox>
      </div>

      <div
        v-if="differentAddress && shippingAddress"
        title="Shipping"
        class="mt-[15px]"
      >
        <h2 class="mb-2.5 text-lg font-bold">Shipping</h2>
        <address-item
          :loading="isLoading"
          :item="shippingAddress"
          @click="$router.push(shippingRoute)"
        >
          <template #menu>
            <base-icon svg="v2/custom/chevron-right-outline" :size="14" />
          </template>
        </address-item>
      </div>
    </template>
  </div>
</template>
