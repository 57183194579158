<script>
import { getValue } from 'vue-currency-input'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRange from '/~/components/base/range/base-range.vue'

export default {
  name: 'ew-catalog-range',
  components: {
    BaseCollapse,
    BaseIcon,
    BaseRange,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    value: {
      type: Array,
      default: () => [this.min, this.max],
    },
    interval: {
      type: Number,
      default: 1,
    },
    formatter: {
      type: [String, Function],
      default: '{value}',
    },
    mergeFormatter: {
      type: [String, Function],
      default: '{value1} ~ {value2}',
    },
    currency: {
      type: [String, Object],
      default: () => ({
        prefix: '$',
      }),
    },
  },
  data() {
    return {
      isToggled: false,
      localValue: this.getValue(this.value),
    }
  },
  computed: {
    subtitle() {
      return `${this.formatter.replace(
        '{value}',
        this.localValue[0]
      )} - ${this.formatter.replace('{value}', this.localValue[1])}`
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.localValue = this.getValue(newValue)
      },
      deep: true,
    },
  },
  methods: {
    getValue(newValue) {
      let [min, max] = newValue

      if (max < this.min || max > this.max) {
        max = this.max
      }

      if (min > this.max || min < this.min) {
        min = this.min
      }

      return [min, max]
    },
    onInputFocus(index) {
      if (index === 0) {
        this.$refs.minInput.setValue(null)
      } else {
        this.$refs.maxInput.setValue(null)
      }
    },
    onInputBlur(index) {
      let input

      if (index === 0) {
        input = this.$refs.minInput
      } else {
        input = this.$refs.maxInput
      }

      let value = getValue(input)

      if (value === null) {
        input.setValue(this.localValue[index])
      } else {
        if (index === 0) {
          if (value > this.localValue[1]) {
            value = this.localValue[1]
          } else if (value < this.min) {
            value = this.min
          }
        } else {
          if (value > this.max) {
            value = this.max
          } else if (value < this.localValue[0]) {
            value = this.localValue[0]
          }
        }

        this.localValue[index] = value
        input.setValue(this.localValue[index])
        this.$emit('update', this.localValue)
      }
    },
  },
}
</script>

<template>
  <base-collapse v-model="isToggled">
    <template #trigger>
      <span class="flex w-full items-center font-bold">
        <span class="mr-auto">
          {{ title }}
        </span>
        <span class="mr-2.5 text-sm font-semibold text-primary">
          {{ subtitle }}
        </span>
        <base-icon
          :class="{
            'rotate-180 transform': isToggled,
          }"
          :size="12"
          svg="plain/chevron-bottom-v2"
        />
      </span>
    </template>
    <template #body>
      <base-range
        class="pt-4"
        :value="localValue"
        :min="min"
        :max="max"
        :interval="interval"
        tooltip="none"
        :formatter="formatter"
        :merge-formatter="mergeFormatter"
        @input="$emit('update', $event)"
      />
    </template>
    <div class="pt-4">
      <div class="mt-2.5 flex justify-between space-x-5 text-xs">
        <div class="rounded border">
          <currency-input
            ref="minInput"
            v-model="localValue[0]"
            :value-range="{ min, max }"
            class="appearance-none px-2.5 py-[5px]"
            :precision="0"
            :currency="currency"
            @focus="onInputFocus(0)"
            @blur="onInputBlur(0)"
            @input="onInputBlur(0)"
          />
        </div>

        <div class="rounded border">
          <currency-input
            ref="maxInput"
            v-model="localValue[1]"
            :value-range="{ min, max }"
            :precision="0"
            :currency="currency"
            class="appearance-none px-2.5 py-[5px] text-right"
            @focus="onInputFocus(1)"
            @blur="onInputBlur(1)"
            @input="onInputBlur(1)"
          />
        </div>
      </div>
    </div>
  </base-collapse>
</template>
