<script>
import { formatDate } from '/~/utils/format/date'
import { formatNumber } from '/~/utils/format/numeric'
import { pluralize } from '/~/utils/format/string'
import { RecroomAward } from '/~rec/core/award'
import { RecroomMemberAward } from '/~rec/core/member-award'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'

export default {
  name: 'rec-award',
  components: {
    BaseIcon,
    BaseAvatar,
    BaseLoader,
    EwCatalogProcessor,
  },
  props: {
    awardStatus: {
      type: String,
      default: '',
    },
    awardId: {
      type: String,
      default: '',
    },
    nomineeId: {
      type: String,
      default: '',
    },
    nominee: {
      type: Object,
      default: () => ({}),
    },
    currentAwardId: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      modal,
      ui,
    }
  },
  data() {
    return {
      loading: false,
      award: null,
      processor: null,
    }
  },
  computed: {
    backRoute() {
      return {
        name: 'rec-award',
        params: {
          awardStatus: this.awardStatus,
          awardId: this.awardId,
        },
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    pluralize,
    formatNumber,
    formatDate,
    async init() {
      this.loading = true
      try {
        if (this.awardStatus === 'received') {
          this.award = await RecroomMemberAward.fetchById(this.awardId)
        } else {
          this.award = await RecroomAward.fetchById(
            this.currentAwardId || this.awardId
          )
        }

        this.processor = this.award.nominationsNominee(
          this.nomineeId,
          this.award.periodId
        )

        await this.processor.getData()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<template>
  <div class="flex h-full flex-col space-y-[30px]">
    <div v-if="loading" class="flex grow flex-col items-center justify-center">
      <base-loader />
    </div>

    <template v-else>
      <div>
        <div
          class="mt-6 hidden break-words text-xl font-bold text-eonx-neutral-800 md:block"
        >
          {{ nominee.fullName }}
        </div>

        <div>
          <span class="text-sm font-semibold text-eonx-neutral-600">
            {{ processor.total }}
            {{ pluralize(processor.total, 'nomination') }} for:
          </span>

          <router-link
            class="break-words text-sm font-semibold text-primary"
            :to="backRoute"
            @click="modal.hide()"
          >
            {{ award.title }}
          </router-link>
        </div>
      </div>

      <div
        class="rounded-lg bg-white"
        :class="{
          'bg-gray-50': ui.mobile && !loading,
        }"
      >
        <ew-catalog-processor
          :processor="processor"
          :columns="1"
          :tile-class="false"
          no-padding
        >
          <template #tile="{ tile, index }">
            <div
              class="p-2.5 sm:flex sm:space-x-[15px] sm:p-5"
              :class="{
                'border-t border-zinc-100': index > 0,
              }"
            >
              <div
                class="shrink-0"
                :class="{
                  'float-left mr-2.5': ui.mobile,
                }"
              >
                <base-avatar
                  :src="tile.nominator.avatarUrl"
                  :abbr="tile.nominator.initials"
                  size="lg"
                />
              </div>
              <div class="flex-1 truncate text-eonx-neutral-800">
                <div
                  :class="{
                    'flex justify-between space-x-2.5': ui.desktop,
                  }"
                >
                  <div
                    class="truncate font-bold"
                    :class="{
                      'text-sm': ui.mobile,
                    }"
                  >
                    {{ tile.nominator.fullName }}
                  </div>
                  <div
                    class="flex shrink-0 items-center space-x-2.5 text-sm text-eonx-neutral-600"
                  >
                    <base-icon svg="rec/calendar-filled" size="xs" />
                    <span class="font-bold">
                      {{ formatDate('daymonthyeartime', tile.createdAt) }}
                    </span>
                  </div>
                </div>

                <span v-if="ui.desktop">
                  {{ tile.reason }}
                </span>
              </div>
              <p
                v-if="ui.mobile"
                class="float-left my-2.5 w-full break-words text-justify text-base"
              >
                {{ tile.reason }}
              </p>
            </div>
          </template>
        </ew-catalog-processor>
      </div>
    </template>
  </div>
</template>
