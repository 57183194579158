import { EntityProcessor } from '/~rec/core/entity-processor'
import HealthArticle from '/~/extensions/health/core/article'
import get from 'lodash-es/get'
import api from '/~/core/api'

export default class HeathExerciseProcessor extends EntityProcessor {
  constructor() {
    super({
      mapping: (record) => new HealthArticle(record),
      entity: 'https://eonxtn.wpengine.com/wp-json/wp/v2/posts',
    })
  }

  setFilters(filters) {
    this.filters = filters
  }

  async getData(params = {}) {
    const { query, force } = params

    this.reset({ force })

    const fetch = async ({ page }) => {
      this.processing = true

      const url = new URL(this.entity)

      url.search = new URLSearchParams({
        page,
        per_page: params.perPage || this.perPage,
        ...this.filters,
        ...(query || {}),
      })

      const { data: items = [], headers = {} } = await api.get(url, {
        includeHeaders: true,
      })

      if (page === 1) {
        this.pagination = {
          total_items: +(headers.get('x-wp-total') ?? 0),
          total_pages: +(headers.get('x-wp-totalpages') ?? 0),
        }
      }

      if (this.mapping instanceof Function) {
        return items.map((item) => this.mapping(item))
      }

      return items
    }

    this.fetch = fetch

    try {
      const hits = await fetch({ page: 1 })

      this.destroyHits()
      this.hits = hits

      return this.hits
    } catch (error) {
      console.error(error)
      this.message = get(error, 'response.data.message', 'Request Error')
    } finally {
      this.processing = false
      this.loaded = true
    }
  }
}
