<script setup lang="ts">
import dayjs from 'dayjs/esm'
import Datepicker from '/~/components/base/datepicker/datepicker/datepicker.vue'

const emit = defineEmits<{
  (event: 'hide'): void
  (event: 'select', date: dayjs.Dayjs | number): void
}>()
const props = withDefaults(
  defineProps<{
    activePeriod?: {
      from: dayjs.Dayjs | null
      to: dayjs.Dayjs | null
    }
    value?: dayjs.Dayjs | null
    displayMode?: 'days' | 'calendar'
  }>(),
  {
    activePeriod: () => ({
      from: dayjs().subtract(10, 'year'),
      to: dayjs(),
    }),
    value: () => dayjs(),
    displayMode: 'calendar',
  }
)

function selectDate(date: dayjs.Dayjs | number) {
  emit('select', date)
  emit('hide')
}
</script>

<template>
  <div class="mx-auto">
    <div
      class="mx-auto mt-[15px] h-[5px] w-[134px] rounded-full bg-eonx-neutral-200"
    ></div>
    <datepicker
      v-bind="props"
      class="px-2.5 py-6 sm:px-[30px]"
      :display-mode="displayMode"
      @select="selectDate"
    />
  </div>
</template>
