<script>
import DrawerPhotoIds from '/~/drawer/photo-ids/photo-ids.vue'

export default {
  name: 'profile-photo-ids',
  components: {
    DrawerPhotoIds,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    onAdd() {
      this.$router.push({ hash: '#profile-photo-ids-add' })
    },
  },
}
</script>

<template>
  <drawer-photo-ids :id="id" to="menu-modal" @add="onAdd" />
</template>
