<script>
import capitalize from 'lodash-es/capitalize'
import debounce from 'lodash-es/debounce'
import omitBy from 'lodash-es/omitBy'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import FeedControls from '/~/extensions/health/components/feed/feed-controls.vue'
import FeedView from '/~/extensions/health/components/feed/feed-view.vue'
import FeedTile from '/~/extensions/health/components/feed/tile/feed-tile'
import Tabs from '/~/extensions/health/components/tabs.vue'
import { useExercises } from '/~/extensions/health/composables'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { useEditablePage } from '/~/composables/cms'

export default {
  name: 'hlth-mind-and-body',
  components: {
    EwCatalogProcessor,
    FeedView,
    FeedControls,
    FeedTile,
    Tabs,
  },
  setup() {
    const route = useRoute()
    const { selectedFeedId, selectedFeed, selectFeed, saved } = useExercises()
    const { page } = useEditablePage()

    const filters = ref(null)
    const type = computed(() => route.query?.type ?? 'mind')
    const subtype = computed(() => route.query?.subtype ?? null)
    const search = ref(route.query?.search ?? '')
    const category = ref(route.query?.category ?? null)
    const params = computed(() => {
      return { search: search.value, category: category.value }
    })

    return {
      page,
      filters,
      type,
      subtype,
      search,
      params,
      selectedFeedId,
      selectedFeed,
      selectFeed,
      saved,
    }
  },
  data() {
    return {
      processing: true,
      tabs: [
        {
          label: 'Mind',
          route: { path: 'mind-and-body', query: { type: 'mind' } },
        },
        {
          label: 'Move',
          route: { path: 'mind-and-body', query: { type: 'move' } },
        },
        {
          label: 'Mind Silver',
          route: {
            path: 'mind-and-body',
            query: { type: 'silver', subtype: 'mind' },
          },
        },
        {
          label: 'Move Silver',
          route: {
            path: 'mind-and-body',
            query: { type: 'silver', subtype: 'move' },
          },
        },
        {
          label: 'Saved Playlist',
          route: { path: 'mind-and-body', query: { type: 'saved' } },
        },
      ],
    }
  },
  computed: {
    catalogType() {
      const { type, subtype } = this
      const catalog = capitalize(type)
      const subCatalog = subtype ? `${capitalize(subtype)} - ` : ''

      return `'EonX Category': '${subCatalog}${catalog}'`
    },
  },
  watch: {
    type: {
      handler(type) {
        if (type !== this.selectedFeedId) {
          this.getData()
        }

        if (type === 'silver' && !this.subtype) {
          this.$router.replace({ params: { subtype: 'move' } })
        }
      },
      deep: true,
    },
    subtype: {
      handler() {
        if (this.type === 'silver' && !this.subtype) {
          this.$router.replace({ params: { subtype: 'move' } })
        }

        this.getData()
      },
      deep: true,
    },
  },
  created() {
    this.useFeed()
    this.selectedFeed.getCategories()
  },
  methods: {
    async useFeed() {
      await this.selectFeed(this.type)

      if (this.selectedFeed) {
        this.selectedFeed.reset()
        this.selectedFeed.setFilter({
          filters:
            this.type === 'saved'
              ? this.saved.map((id) => 'objectID:' + id).join(' OR ') ||
                'objectID:null'
              : this.catalogType,
        })

        if (this.selectedFeed) {
          this.selectedFeed.getData()
        }
      } else {
        console.warn('feeds non-consistent')
      }

      this.processing = false
    },
    onSearch: debounce(function (query) {
      this.search = query
      this.selectedFeed.setFilter({ query })
      this.useFeed()
      this.setRoute()
    }, 300),
    changeCategory(value) {
      this.category = value
      this.selectedFeed.setParams({
        facetFilters:
          value && value !== 'All Categories'
            ? [`Collections:${value}`]
            : undefined,
      })
      this.useFeed()
      this.setRoute()
    },
    setRoute() {
      const query = omitBy(
        {
          search: this.search,
          category: this.category,
          type: this.type,
          subtype: this.subtype,
        },
        (v) => !v
      )

      this.$router.replace({ query })
    },
    getData() {
      this.processing = true
      this.filters.resetCategory()
      this.filters.clearSearch()
      this.selectedFeed.setFilter({ query: '' })
      this.useFeed()
      this.selectedFeed.getCategories()

      const body = document.getElementById('health-content')

      if (body) {
        body.scrollTop = 0
      }
    },
  },
}
</script>

<template>
  <div class="mx-auto max-w-7xl">
    <tabs :tabs="tabs" :type="type" :subtype="subtype" class="sm:hidden" />
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
    <feed-view>
      <template #tabs>
        <tabs
          :tabs="tabs"
          :type="type"
          :subtype="subtype"
          class="hidden sm:block"
        />
      </template>

      <template #controls>
        <div class="w-full">
          <feed-controls
            v-if="selectedFeed"
            ref="filters"
            placeholder="Search for a video..."
            :categories="selectedFeed.categories"
            :params="params"
            without-category
            @input="onSearch"
            @category-update="changeCategory"
          />
        </div>
      </template>

      <ew-catalog-processor
        v-if="selectedFeed"
        class="sm:min-h-96"
        :processor="selectedFeed"
        :columns="{
          xs: 2,
          sm: 3,
          lg: 4,
        }"
      >
        <template #tile="{ tile }">
          <feed-tile :tile="tile" />
        </template>

        <template #empty>
          <div class="h-64 sm:min-h-96">No exercises were found</div>
        </template>
      </ew-catalog-processor>
    </feed-view>
  </div>
</template>
