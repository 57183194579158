<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.v3.vue'
import { useEwallet } from '/~/composables/ewallet'
import { useUser } from '/~/composables/user'

const { ewalletBalance, fetchingEwallet } = useEwallet()
const { user } = useUser()

const items = computed(() => {
  return [
    {
      icon: 'v2/custom/eye',
      label: 'Show card details',
      description: 'Show card number information',
      disabled: true,
    },
    {
      icon: 'v2/box-icons/bx-lock',
      label: 'Lock this card',
      description: 'Instantly restrict transactions on this card',
      disabled: true,
    },
  ]
})
</script>

<template>
  <base-aside-page
    title="Cash Card"
    :back="{ hash: '#profile-home' }"
    class="text-center"
  >
    <div class="font-bold">Cash Balance</div>
    <base-loader v-if="fetchingEwallet" size="lg" />
    <div class="text-5xl font-bold">
      {{ formatDollar(ewalletBalance) }}
    </div>
    <div class="mt-10">
      Use your Cash anywhere by simply linking your Cash Card to Apple Pay or
      Google Pay wallet
    </div>
    <div class="relative mt-[30px]">
      <img src="/images/crown/cash-card.jpg" alt="" />
      <div
        class="absolute bottom-0 left-0 flex w-full items-end justify-between px-6 pb-6"
      >
        <span class="text-base leading-4 text-white">{{ user.fullName }}</span>
        <span
          class="text-xl font-bold leading-5"
          :style="{
            color: '#ccb375',
          }"
        >
          Cash Card
        </span>
      </div>
    </div>

    <div class="mt-5 font-bold">Link to</div>
    <div class="mt-5 flex items-center justify-center space-x-5">
      <div
        class="h-20 w-20 cursor-not-allowed rounded-xl bg-eonx-neutral-50 p-2.5 opacity-50"
      >
        <base-icon svg="apple-pay" class="h-full" />
      </div>
      <div
        class="h-20 w-20 cursor-not-allowed rounded-xl bg-eonx-neutral-50 p-2.5 opacity-50"
      >
        <base-icon svg="google-pay" class="h-full" />
      </div>
    </div>
    <drawer-actions
      v-if="items.length"
      :actions="items"
      class="mt-[30px] space-y-4"
    />
  </base-aside-page>
</template>
