<script>
import FeedPosts from '/~rec/components/feed/feed-posts.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'

export default {
  name: 'rec-event-feed',
  components: {
    FeedPosts,
    RecBaseState,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <feed-posts
    :feed="event.posts"
    :event-id="event.id"
    feed-type="event"
    @posts-loaded="$emit('posts-loaded')"
  >
    <template #empty>
      <rec-base-state
        title="No posts yet"
        subtitle="Share content, ideas or posts in this event"
      />
    </template>
  </feed-posts>
</template>
