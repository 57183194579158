<script>
import { formatNumber } from '/~/utils/format/numeric'
import { pluralize } from '/~/utils/format/string'
import { usePerk } from '/~rec/composables/perk'
import { useRecProfile } from '/~rec/composables/profile'
import { useRoute } from 'vue-router/composables'
import BaseButton from '/~/components/base/button/base-button'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import DetailsMobile from '/~rec/components/recognition/recognition-details-box-mobile.vue'
import modal from '/~/core/mdl'
import PerkInfo from '/~rec/components/perk/perk-info.vue'
import PerkRedeemed from '/~rec/components/perk/perk-redeemed.vue'

export default {
  name: 'rec-perk-details-mobile-modal',
  components: {
    BaseButton,
    BaseMdl,
    PerkRedeemed,
    DetailsMobile,
    PerkInfo,
  },
  setup() {
    const route = useRoute()

    const { currency, isAvailablePerk } = useRecProfile()

    const {
      gotIt,
      init,
      loading,
      openRequestModal,
      perk,
      perkDate,
      perkId,
      perkRedeemDisabled,
      perkStatus,
      redeemed,
      redeemedStatus,
      status,
    } = usePerk()

    perkStatus.value = route.params.perkStatus
    perkId.value = route.params.perkId

    return {
      currency,
      gotIt,
      init,
      isAvailablePerk,
      loading,
      openRequestModal,
      perk,
      perkDate,
      perkRedeemDisabled,
      perkStatus,
      redeemed,
      redeemedStatus,
      status,
    }
  },
  created() {
    this.init()
  },
  methods: {
    pluralize,
    formatNumber,
    close() {
      modal.hide()
      this.$router.replace({
        name: 'rec-perks',
        query: { type: this.perkStatus },
      })
    },
  },
}
</script>

<template>
  <base-mdl fullscreen :layout="false" width="screen" transition="slide-bottom">
    <div class="relative h-full bg-white">
      <details-mobile
        v-if="!redeemed"
        :loading="loading"
        :award="perk || {}"
        entity-name="rec-perk"
        alt-text="perk"
        title-message="Details"
        @close="close"
      >
        <template #badge>
          <div class="flex items-center">
            <div v-if="status">
              <span
                :class="[
                  'mb-5 inline-block rounded-3xl px-5 py-[5px] text-sm font-semibold capitalize',
                  status.theme,
                  status.textColor,
                ]"
              >
                {{ status.label }}
              </span>
            </div>
          </div>
        </template>
        <template #nominalText>
          <span class="text-sm font-bold leading-none">
            {{ formatNumber(perk.cost) }}
            <span class="capitalize">
              {{ pluralize(perk.cost, currency.slice(0, -1)) }}
            </span>
            &nbsp;required to redeem
          </span>
        </template>
        <template v-if="perk" #nominations>
          <perk-info
            :perk-status="perkStatus"
            :stock-enabled="perk.stockEnabled"
            :total-stock="perk.totalStock"
            :created-at="perkDate(perk.createdAt)"
            :owner="perk.owner"
          />
        </template>
        <template #actions>
          <div
            class="relative mt-auto flex justify-center space-y-2.5 pt-2.5 pb-5 opacity-100 transition-all duration-300"
          >
            <base-button
              v-if="perkStatus === 'available'"
              class="w-full sm:w-48"
              :disabled="perkRedeemDisabled"
              @click="openRequestModal"
            >
              <span class="text-base">
                {{ perk.isApprovalAutomatic ? 'Redeem' : 'Request approval' }}
              </span>
            </base-button>
          </div>
        </template>
      </details-mobile>
      <div v-if="redeemed" class="p-5">
        <perk-redeemed
          :perk="perk"
          :redeemed-status="redeemedStatus"
          @submit="gotIt()"
        />
      </div>
    </div>
  </base-mdl>
</template>
