<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'creator-gif',
  components: {
    BaseIcon,
  },
  props: {
    gif: {
      type: Object,
      default: () => ({}),
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div
    class="relative flex h-20 w-32 cursor-pointer flex-col overflow-hidden shadow-sm"
  >
    <div
      v-if="removable"
      class="absolute inset-0 z-10 flex w-full items-center justify-center rounded-md bg-white opacity-0 transition duration-300 ease-in-out hover:opacity-100"
      @click="$emit('delete', gif)"
    >
      <base-icon class="text-error-700" svg="plain/bin" size="2xl" />
    </div>

    <img :src="gif.cdn" class="h-full w-full rounded-md object-cover" />
  </div>
</template>
