import get from 'lodash-es/get'
import { createDate } from '/~/utils/format/date'
import { formatNumber } from '/~/utils/format/numeric'

export class RecroomEarnPost {
  constructor(raw, postRaw) {
    this.postRaw = postRaw || {}
    this.raw = raw || {}
  }

  get id() {
    return get(this.raw, 'id')
  }

  get action() {
    return get(this.raw, 'action')
  }

  get title() {
    return get(this.raw, 'action_message', this.action)
  }

  get earnValue() {
    return get(this.raw, 'earn_value')
  }

  get showAction() {
    return this.earnValue && this.earnValue !== 0
  }

  get currency() {
    return get(this.raw, 'currency')
  }

  get icon() {
    return this.isCompleted ? 'rec/check-circle' : 'rec/token'
  }

  get remainingEarnValue() {
    if (!this.postRaw.target_earn_limit) return 0

    const targetEarnLimit = this.postRaw.target_earn_limit
    const maxEarnValue = targetEarnLimit[`${this.currency}_limit`]
    const earnedSoFar = targetEarnLimit[`${this.currency}_earned`]

    return maxEarnValue - earnedSoFar
  }

  get isCompleted() {
    return get(this.raw, 'user_earn_completed')
  }

  get disabled() {
    if (this.isCompleted) return false

    const endsAt = get(this.raw, 'ends_at')
    const now = createDate()

    if (endsAt && now.isAfter(createDate(endsAt))) {
      return true
    } else if (this.remainingEarnValue) {
      return this.remainingEarnValue < this.earnValue
    } else {
      return false
    }
  }

  get description() {
    return get(this.raw, 'earned_per_action_message')
  }

  get badgeContent() {
    let earnValue = this.earnValue

    if (this.action === 'quiz_answer_create_correct') {
      const totalQuestions = this.postRaw && this.postRaw.questions.length

      earnValue = this.earnValue * totalQuestions
    }
    return `Earn ${formatNumber(earnValue)} ${this.currency}`
  }

  get endsAt() {
    return get(this.raw, 'ends_at')
  }
}
