<script>
import AudioEntry from './entry/audio-message-entry.vue'

export default {
  name: 'rec-message-audio',
  components: {
    AudioEntry,
  },
  props: {
    attachments: {
      type: Object,
      required: true,
    },
    samples: {
      type: Number,
      required: true,
    },
    contentColor: {
      type: String,
      default: 'text-dark',
    },
    buttonColor: {
      type: String,
      default: 'text-primary',
    },
  },
  data() {
    return {
      previewCount: 3,
      showHidden: false,
    }
  },
  computed: {
    visibleAttachments() {
      return this.attachments.audioAttachments.slice(0, this.previewCount)
    },
  },
}
</script>

<template>
  <div>
    <audio-entry
      v-for="attachment in visibleAttachments"
      :key="attachment.id"
      class="first:rounded-t-lg last:rounded-b-lg"
      :attachment="attachment"
      :samples="samples"
      :content-color="contentColor"
      :button-color="buttonColor"
    />
  </div>
</template>
