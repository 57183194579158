<script>
import BaseButton from '/~/components/base/button/base-button'
import PaymentsSplitForm from '/~/components/payments/split/payments-split-form.v2.vue'
import DefaultSplit from './payments-split.bs.vue'

export default {
  name: 'payments-split-bottom-sheet-v2',
  components: {
    BaseButton,
    PaymentsSplitForm,
  },
  extends: DefaultSplit,
}
</script>
