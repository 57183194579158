<script>
import { ref } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import ConfirmationModal from '/~/components/drawer/modals/confirmation-modal.vue'
import { useCms } from '/~/composables/cms'
import PhotoIdTile from '/~/drawer/photo-ids/components/photo-id-tile.v2.vue'
import DrawerPhotoIds from '/~/drawer/photo-ids/photo-ids.vue'

export default {
  name: 'photo-ids',
  components: {
    BaseAsidePage,
    ConfirmationModal,
    PhotoIdTile,
  },
  extends: DrawerPhotoIds,
  setup() {
    const { isDarkThemeForEwallet, ewalletLabels } = useCms()

    const warningConfirmationModalRef = ref(null)

    return {
      ...DrawerPhotoIds.setup?.(...arguments),
      isDarkThemeForEwallet,
      ewalletLabels,
      warningConfirmationModalRef,
    }
  },
  watch: {
    showWarning(showWarning) {
      if (showWarning) {
        this.warningConfirmationModalRef.show()
      }
    },
  },
}
</script>

<template>
  <base-aside-page title="Photo IDs" no-padding>
    <template #action>
      <base-button
        v-if="hasPhotoIdsPendingTransactions"
        :disabled="isPhotoIdsTransactionsLoading || isRedirecting"
        icon="plain/reload"
        :size="32"
        class="flex cursor-pointer items-center justify-center rounded-full"
        :class="{
          'animate-spin': isPhotoIdsTransactionsLoading,
          'text-primary': !isDarkThemeForEwallet,
        }"
        @click="onReloadTransactions"
      />

      <base-button
        v-else-if="!isPhotoIdsTransactionsLoading"
        :disabled="isRedirecting"
        icon="v2/custom/plus-circle"
        :size="32"
        class="flex cursor-pointer items-center justify-center rounded-full"
        :class="{
          'text-primary': !isDarkThemeForEwallet,
        }"
        @click="onCreatePhotoId"
      />
    </template>

    <photo-ids-redirect v-if="isRedirecting" @back="onRedirectCancel" />

    <photo-ids-empty
      v-else-if="hasNoPhotoIdsTransactions && hasNoPhotoIds"
      @create="onCreatePhotoId"
    />

    <div v-else class="mx-auto w-[300px] max-w-full space-y-[30px] py-5">
      <base-card
        v-if="isPhotoIdsLoading"
        class="animate-pulse rounded-xl bg-divider"
      />
      <template v-else>
        <photo-id-tile
          v-for="photoId in photoIds"
          :key="photoId.id"
          class="cursor-pointer"
          :document="photoId"
          @click="onPhotoIdTileClick(photoId)"
        />
      </template>

      <base-card
        v-if="isPhotoIdsTransactionsLoading"
        class="animate-pulse rounded-xl bg-divider"
      />
      <template v-else>
        <photo-id-transaction
          v-for="transaction in photoIdsIncompleteTransactions"
          :key="transaction.id"
          :transaction="transaction"
          @delete="onDelete(transaction)"
        />
      </template>
    </div>

    <confirmation-modal
      ref="warningConfirmationModalRef"
      type="success"
      icon="v2/heroic/check"
      :icon-size="75"
      title="Photo IDs can only be added using your mobile phone"
      :text="`Login to ${ewalletLabels.yourEwallet} using your mobile phone to securely store and validate your Australian Drivers License or Passport.`"
      :show-cancel-button="false"
    >
      <template #confirmButtonText>Got it</template>
    </confirmation-modal>
  </base-aside-page>
</template>
