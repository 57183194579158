<script setup lange="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import { useEditablePage, useCms } from '/~/composables/cms'
import { useUI } from '/~/composables/ui'
import MarketplaceMenu from '/~/layouts/main/components/marketplace-menu.vue'

const route = useRoute()
const { page } = useEditablePage()
const { isLeftMenuAppearanceHidden } = useCms()

const theme = computed(() => route.meta.theme ?? 'dark')

const showRightSideWidgetsTab = computed(
  () => page.value?.settings?.showRightSideWidgetsTab
)
const secondTabTitle = computed(
  () => page.value?.settings?.secondTabTitle || 'Newsfeed'
)

const { headerHeight } = useUI()
</script>

<template>
  <main
    class="flex grow flex-col overflow-y-auto"
    :class="{
      'bg-dark': theme === 'dark',
    }"
    :style="{ paddingTop: `${headerHeight}px` }"
  >
    <portal to="headroom-bottom">
      <marketplace-menu v-if="!isLeftMenuAppearanceHidden" />
    </portal>

    <component
      :is="showRightSideWidgetsTab ? 'portal' : 'fragment'"
      to="tabs-first"
    >
      <slot />
    </component>

    <component
      :is="showRightSideWidgetsTab ? 'portal' : 'fragment'"
      v-if="page && page.widgets"
      to="tabs-second"
      class="px-5"
    >
      <widgets-builder :widgets="page.widgets" side="right" class="w-full" />
    </component>

    <base-tabs v-if="showRightSideWidgetsTab" capitalize>
      <base-tab :name="page.name" selected>
        <portal-target name="tabs-first" />
      </base-tab>
      <base-tab :name="secondTabTitle" class="px-5">
        <portal-target name="tabs-second" />
      </base-tab>
    </base-tabs>
  </main>
</template>
