<script>
import BankAccountWarning from './bank-account-warning.vue'

export default {
  name: 'bank-account-warning-bottom-sheet',
  components: {
    BankAccountWarning,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },
  emits: ['hide'],
  setup(props, { emit }) {
    function cancel() {
      props.onCancel?.()
      emit('hide')
    }

    function submit() {
      props.onConfirm?.()
      emit('hide')
    }

    return {
      cancel,
      submit,
    }
  },
}
</script>

<template>
  <bank-account-warning :total="total" @cancel="cancel" @submit="submit" />
</template>
