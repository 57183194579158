<script>
import { computed } from 'vue'
import DefaultDiningFilter from '/~/extensions/dining/templates/default/views/catalog/components/dining-filter.desktop.vue'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'dining-filter',
  extends: DefaultDiningFilter,
  setup() {
    const { isBillPaymentsTemplate } = useProvider()
    const searchPlaceholder = computed(() =>
      isBillPaymentsTemplate.value ? 'Search dining' : 'Search restaurant'
    )

    return {
      ...DefaultDiningFilter.setup?.(...arguments),
      isBillPaymentsTemplate,
      searchPlaceholder,
    }
  },
}
</script>

<template>
  <div class="mx-auto w-full max-w-7xl py-5 px-5 sm:px-0 sm:py-3">
    <div class="flex items-center space-x-2.5 sm:space-x-6">
      <base-select-async
        v-if="isMapVisible"
        :fetch="getRelativeAddress"
        icon="plain/search"
        placeholder="Search locations"
        class="grow"
        :look="!ui.mobile ? 'rounded' : ''"
        @change="onLocationSelect"
      />
      <base-input
        v-else
        v-model="localSearchQuery"
        :placeholder="searchPlaceholder"
        class="grow pt-0"
        look="rounded"
        icon-plain
        nolabel
        clearable
        autofocus
        @input="onSearchInput"
        @focus="searchFocused = true"
        @blur="onSearchBlur"
        @keypress.enter="onSearchEnter"
      >
        <template #icon>
          <div class="ml-[15px] mr-2.5 flex items-center justify-center">
            <base-icon
              svg="plain/search-v2"
              class="text-eonx-neutral-600"
              :size="19"
            />
          </div>
        </template>
      </base-input>
      <toggle-map v-if="!ui.mobile" class="mx-2.5" />
      <ew-filters
        title="Filters"
        :is-filters-selected="isFiltersSelected"
        :close-on-click-away="!hasOpenSelect"
        @clear="clear"
      >
        <div class="my-5 space-y-5 sm:px-6">
          <toggle-map v-if="ui.mobile" class="w-full" />

          <base-select
            v-model="state"
            no-label
            fullwidth-popup
            :options="stateOptions"
            @input="onStateChanged"
            @open="hasOpenSelect = true"
            @close="onSelectClose"
          />

          <base-select
            no-label
            fullwidth-popup
            :value="selectedCuisine || ''"
            :disabled="!cuisines || !cuisines.length"
            :options="
              (cuisines || []).map((category) => {
                return {
                  text: category.label,
                  value: category.id || '',
                }
              })
            "
            @input="onCuisineInput"
            @open="hasOpenSelect = true"
            @close="onSelectClose"
          />

          <dining-checkboxes
            v-if="features.length > 0"
            :value="selectedFeatures"
            :filters="features"
            title="Amenities"
            @input="onFeatureInput"
          />

          <dining-price-checkboxes
            :value="selectedPriceRanges"
            :filters="priceRanges"
            title="Price Range"
            @input="onPriceInput"
          />
        </div>
      </ew-filters>
    </div>
    <div
      v-if="isLocalArea && !isBillPaymentsTemplate"
      class="mt-2.5 text-sm italic leading-tight text-eonx-neutral-600"
    >
      *The dining listing is currently being filtered by restaurants in your
      area. In order to view more restaurants simply adjust the below filter
      settings.
    </div>
  </div>
</template>
