import { ref, computed, nextTick } from 'vue'
import InputCodeItem from './input-code-item.vue'

const code = ref<Array<number | null>>([])
const inputRef = ref<(typeof InputCodeItem)[]>([])
const count = ref(6)

const canVerify = computed(
  () =>
    !code.value.some((item) => item === null) &&
    code.value.length === count.value
)

function onPaste(data: string) {
  const codeNumber: number = parseInt(data)

  if (codeNumber > 0) {
    setTimeout(() => {
      code.value = codeNumber
        .toString()
        .split('')
        .map((item) => Number(item))
      onFocus(String(codeNumber).length)
    }, 1)
  }
}

function onKeyup(key: number) {
  if (key < count.value) {
    nextTick(() => {
      inputRef.value[key]?.$el.focus()
    })
  }
}

function onBackspace(key: number) {
  if (key > 1) {
    if (code.value[key - 1] === null || code.value[key - 1] === undefined) {
      inputRef.value[key - 2]?.$el.focus()
      code.value[key - 2] = null
    } else {
      code.value[key - 1] = null
    }
    code.value = [...code.value]
  }
}

function onFocus(key: number) {
  nextTick(() => {
    inputRef.value[key - 1]?.$el.setSelectionRange(1, 1)
    if (key - 1 > code.value.length) {
      inputRef.value[code.value.length - 1]?.$el.focus()
    } else {
      inputRef.value[key - 1]?.$el.focus()
    }
  })
}

export function useBaseInputCode() {
  return {
    count,
    code,
    canVerify,
    inputRef,
    onFocus,
    onPaste,
    onKeyup,
    onBackspace,
  }
}
