import DaysTape from './days-tape/datepicker-days-tape.vue'
import DefaultPicker from './default/datepicker-default.vue'
import MonthText from './month-text/datepicker-month-text.vue'
import TimePicker from './time/datepicker-time.vue'
import Timezone from './timezone/datepicker-timezone.vue'

export default {
  name: 'BaseDatepicker',
  functional: true,
  render(h, ctx) {
    const { type } = ctx.props
    let component

    switch (type) {
      case 'month-text':
        component = MonthText
        break
      case 'days-tape':
        component = DaysTape
        break
      case 'timezone':
        component = Timezone
        break
      case 'time':
        component = TimePicker
        break
      default:
        component = DefaultPicker
    }

    return h(component, ctx.data, ctx.children)
  },
}
