<script>
import PostQuizOption from '/~rec/components/post/content/types/post-quiz/post-quiz-option.vue'
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import { pluralize } from '/~/utils/format/string'
import BaseHeader from '/~/components/base/header/base-header'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseDate from '/~/components/base/date/base-date.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'rec-quiz-submissions-modal',
  components: {
    PostQuizOption,
    RecognitionHeader,
    BaseHeader,
    BaseButton,
    BaseIcon,
    BaseAvatar,
    BaseDate,
    BaseMdl,
    BaseLoader,
  },
  provide() {
    return {
      processor: this.processor,
    }
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      pluralize,
    }
  },
  data() {
    return {
      processor: this.post.submissions,
      selectedSubmission: null,
      loading: false,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        this.loading = true
        await this.processor.getData()
      } catch (error) {
        console.error('rec-room', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<template>
  <base-mdl :padding="false" fullscreen="mobile" width="sme" height="md">
    <template #header>
      <base-header type="closable" class="relative text-white">
        <recognition-header icon="rec/round/quiz" />

        <transition name="fade">
          <div
            v-if="selectedSubmission"
            class="absolute bottom-0 left-0 z-10 mb-[15px] ml-6 sm:ml-[30px]"
          >
            <base-button
              class="block"
              look="link"
              @click="selectedSubmission = null"
            >
              <div class="flex items-center leading-4">
                <base-icon
                  svg="rec/chevron-left"
                  size="xxs"
                  class="mr-2.5 sm:mr-[15px]"
                />
                Back to results
              </div>
            </base-button>
          </div>
        </transition>
      </base-header>
    </template>

    <div class="flex flex-nowrap pb-[30px]">
      <transition
        :name="selectedSubmission ? 'slide-right' : 'slide-right-reverse'"
      >
        <div
          v-if="!selectedSubmission"
          key="submissions"
          class="w-full shrink-0"
        >
          <h2 class="mb-5 px-[30px] text-center text-2xl leading-8">
            {{ post.totalAnswered }}
            {{ pluralize(post.totalAnswered, 'Member') }} answered your Quiz
          </h2>

          <table class="w-full">
            <thead
              class="hidden bg-dark text-left text-primary sm:table-row-group"
            >
              <tr class="h-12 border-t border-b">
                <th class="w-full px-5 py-2.5">Member</th>
                <th class="min-w-48 px-5 py-2.5">Time</th>
                <th class="px-5 py-2.5 text-center">Results</th>
                <th class="px-5 py-2.5 text-center">Score</th>
              </tr>
            </thead>

            <tbody class="text-sm">
              <tr v-if="loading">
                <td colspan="4">
                  <base-loader class="py-5" fullwidth />
                </td>
              </tr>
              <tr v-else-if="processor.message">
                <td class="px-11 text-center" colspan="4">
                  {{ processor.message }}
                </td>
              </tr>
              <template v-else-if="!processor.isEmpty">
                <template v-for="(submission, idx) in processor.hits">
                  <tr
                    :key="submission.objectID || `submission-desktop-${idx}`"
                    class="hidden sm:table-row"
                  >
                    <td
                      class="truncate px-5 py-2.5 font-bold"
                      style="max-width: 0"
                    >
                      {{ submission.author.fullName }}
                    </td>
                    <td class="whitespace-nowrap px-5 py-2.5">
                      <base-date
                        type="short-date-spr-time"
                        :date="submission.updatedAt"
                      />
                    </td>
                    <td class="px-5 py-2.5 text-center font-bold">
                      {{ submission.isPassed ? 'Passed' : 'Failed' }}
                    </td>
                    <td
                      class="cursor-pointer px-5 py-2.5 text-center font-bold text-primary"
                      @click="selectedSubmission = submission"
                    >
                      {{ submission.totalCorrect }}/{{
                        submission.totalQuestions
                      }}
                    </td>
                  </tr>
                  <tr
                    :key="submission.objectID || `submission-mobile-${idx}`"
                    class="border-t border-b sm:hidden"
                    colspan="4"
                    @click="selectedSubmission = submission"
                  >
                    <div
                      class="flex items-center justify-between px-6 py-[15px]"
                    >
                      <div class="flex items-center">
                        <base-avatar
                          :abbr="submission.author.initials"
                          :src="submission.author.avatarUrl"
                          size="xl"
                        />
                        <div class="ml-2.5 text-sm leading-4">
                          <b class="mb-[5px] block">
                            {{ submission.author.fullName }}
                          </b>
                          <base-date
                            :date="submission.updatedAt"
                            type="short-date-spr-time"
                            class="text-eonx-neutral-600"
                          />
                        </div>
                      </div>

                      <div
                        class="flex items-center"
                        :class="{
                          'text-success-700': submission.isPassed,
                          'text-error-700': !submission.isPassed,
                        }"
                      >
                        <base-icon
                          :svg="
                            submission.isPassed
                              ? 'rec/check-circle'
                              : 'rec/error-circle'
                          "
                          class="mr-[5px]"
                          size="md"
                        />
                        <b class="text-xs">
                          {{ submission.totalCorrect }}/{{
                            submission.totalQuestions
                          }}
                        </b>
                      </div>
                    </div>
                  </tr>
                </template>

                <tr v-if="processor.processing && processor.hits.length">
                  <td colspan="4">
                    <base-loader class="py-5" fullwidth />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

          <div
            v-if="
              processor.hits.length &&
              !processor.processing &&
              !processor.allRecordsLoaded
            "
            v-observe-visibility="processor.next()"
            class="flex items-center justify-center"
          />
        </div>

        <div
          v-else
          key="submissionDetails"
          class="mt-2.5 w-full shrink-0 px-6 sm:px-[30px]"
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <base-avatar
                :abbr="selectedSubmission.author.initials"
                :src="selectedSubmission.author.avatarUrl"
                size="xl"
              />
              <div class="ml-2.5 text-sm leading-4">
                <b class="mb-[5px] block">
                  {{ selectedSubmission.author.fullName }}
                </b>
                <base-date
                  :date="selectedSubmission.updatedAt"
                  type="short-date-spr-time"
                  class="text-eonx-neutral-600"
                />
              </div>
            </div>

            <div
              class="flex items-center"
              :class="{
                'text-success-700': selectedSubmission.isPassed,
                'text-error-700': !selectedSubmission.isPassed,
              }"
            >
              <base-icon
                :svg="
                  selectedSubmission.isPassed
                    ? 'rec/check-circle'
                    : 'rec/error-circle'
                "
                class="mr-[5px]"
                size="md"
              />
              <b class="text-xs">
                {{ selectedSubmission.totalCorrect }}/{{
                  selectedSubmission.totalQuestions
                }}
              </b>
            </div>
          </div>

          <div
            v-for="(question, index) in post.questions"
            :key="`q-${index}`"
            :class="{
              'mt-6': index === 0,
              'mt-10': index !== 0,
            }"
          >
            <b class="block leading-5">Question {{ index + 1 }}</b>
            <span class="mt-2.5 block text-eonx-neutral-600">
              {{ question.content }}:
            </span>

            <post-quiz-option
              v-for="(option, idx) in question.options"
              :key="`o-${idx}`"
              :selected-option="selectedSubmission.answers[index].optionId"
              :option="option"
              :idx="idx"
            />
          </div>
        </div>
      </transition>
    </div>
  </base-mdl>
</template>
