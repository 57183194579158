<script>
import DefaultActivitySummaryItems from '/~/components/activity/summary/activity-summary-items.vue'
import ActivitySummaryItem from '/~/templates/australiapost/components/activity/summary/activity-summary-item.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    ActivitySummaryItem,
  },
  extends: DefaultActivitySummaryItems,
})
</script>
