<script>
import BaseRadio from '/~/components/base/radio/base-radio.vue'

export default {
  name: 'ew-sorting',
  components: {
    BaseRadio,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    sorting: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        if (value && typeof value === 'string') {
          this.$emit('input', value)
        }
      },
    },
  },
}
</script>

<template>
  <fieldset class="px-5 py-2.5">
    <legend v-if="title" class="my-2.5 font-bold">
      {{ title }}
    </legend>
    <base-radio
      v-for="option in sorting"
      :key="option.value"
      v-model="fieldValue"
      class="block"
      name="filter"
      :value="option.value"
      @click="fieldValue = option.value"
    >
      {{ option.text || option.label }}
    </base-radio>
  </fieldset>
</template>
