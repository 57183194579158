<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import MenuPaymentTo from '/~/components/ewallet-menu/components/rows/menu-payment-to.vue'
import { useCms } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'

const router = useRouter()
const { taxationLabel } = useProvider()
const { isDarkThemeForEwallet } = useCms()

const orderData = reactive(router.currentRoute.params) as unknown as {
  selectedPayee: Record<string, unknown>
  reference: string
  selectedMethods: Array<{
    icon: any
    title: string
    description: string
    value: string | number
  }>
  isCombineFees: boolean
  programFees: number
  transactionFees: number
  totalFees: number
  subTotal: number
  total: number
  order: any
}

const confirmationType = computed(() => {
  switch (orderData.order.status) {
    case 'completed':
      return 'success'
    case 'pending':
      return 'warning'
    default:
      return 'error'
  }
})
const confirmationTitle = computed(() => {
  switch (orderData.order.status) {
    case 'completed':
      return 'Payment Approved'
    case 'pending':
      return 'Payment is Pending'
    default:
      return 'Payment Failed'
  }
})
const confirmationIcon = computed(() => {
  switch (orderData.order.status) {
    case 'completed':
      return 'symbion/activity-status/check'
    case 'pending':
      return 'symbion/activity-status/processing'
    default:
      return 'symbion/activity-status/close'
  }
})
const isSuccess = computed(() => {
  return confirmationType.value === 'success'
})
const isWarning = computed(() => {
  return confirmationType.value === 'warning'
})
const isError = computed(() => {
  return confirmationType.value === 'error'
})
</script>

<template>
  <base-aside-page no-padding>
    <template #iconHeader>
      <div />
    </template>
    <div
      class="flex h-full flex-col justify-between"
      :class="{
        'bg-white': !isDarkThemeForEwallet,
        'bg-gray-900 text-white': isDarkThemeForEwallet,
      }"
    >
      <div
        class="flex flex-grow flex-col items-center overflow-auto px-5 pt-12 pb-2.5"
      >
        <div>
          <div
            class="flex h-72 w-72 items-center justify-center bg-center bg-no-repeat pt-2.5 pr-[5px]"
            :style="{
              backgroundImage: `url('/images/drawer/confirmation-modal/header-bg-${confirmationType}.svg')`,
            }"
          >
            <div class="h-[120px] w-[120px] p-1.5">
              <div
                class="flex h-full w-full items-center justify-center rounded-full text-white"
                :class="{
                  'bg-emerald-700': isSuccess,
                  'bg-orange-700': isWarning,
                  'bg-red-700': isError,
                }"
              >
                <base-icon class="h-20 w-20" :svg="confirmationIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-12 text-2xl font-bold">
          {{ confirmationTitle }}
        </div>
        <div class="mt-14 text-sm font-bold">
          {{
            formatDate(
              'daymonthyeartime',
              orderData.order.completedAt || orderData.order.updatedAt
            )
          }}
        </div>
        <div class="text-sm">Order #{{ orderData.order.number }}</div>
        <div class="my-6 w-full border-t border-gray-300" />
        <div class="w-full font-bold">Payee</div>
        <div class="mt-2.5 w-full">
          <menu-payment-to :to="orderData.selectedPayee" />
        </div>
        <div class="my-6 w-full border-t border-gray-300" />
        <div class="w-full font-bold">Payment Method(s)</div>
        <div class="mt-4 w-full space-y-4">
          <div
            v-for="(method, index) in orderData.selectedMethods"
            :key="index"
            class="flex"
          >
            <div class="w-12 pr-4">
              <base-icon v-bind="method.icon" size="md" class="mx-auto" />
            </div>
            <div>
              <div class="border-t-3 border-transparent font-bold">
                {{ method.title }}
              </div>
              <div
                v-if="method.description"
                class="text-sm text-eonx-neutral-600"
              >
                {{ method.description }}
              </div>
            </div>
            <div class="ml-auto text-eonx-neutral-600">
              {{ formatDollar(method.value) }}
            </div>
          </div>
        </div>
        <div class="my-6 w-full border-t border-gray-300" />
        <div class="flex w-full items-center font-bold">
          Sub Total
          <span class="ml-auto">
            {{ formatDollar(orderData.subTotal) }}
          </span>
        </div>
        <template v-if="orderData.isCombineFees">
          <div
            class="mt-[5px] flex w-full items-center text-eonx-neutral-600"
            data-testid="processing-fees"
          >
            Processing fees (Inc {{ taxationLabel }})
            <span class="ml-auto">
              {{ formatDollar(orderData.totalFees) }}
            </span>
          </div>
        </template>
        <template v-else>
          <div
            class="mt-[5px] flex w-full items-center text-eonx-neutral-600"
            data-testid="program-fee"
          >
            Program Fee (Inc {{ taxationLabel }})
            <span class="ml-auto">
              {{ formatDollar(orderData.programFees) }}
            </span>
          </div>
          <div class="mt-[5px] flex w-full items-center text-eonx-neutral-600">
            Payment Processing Fee
            <span class="ml-auto">
              {{ formatDollar(orderData.transactionFees) }}
            </span>
          </div>
        </template>
        <div class="mt-[5px] flex w-full items-center text-xl font-bold">
          Total
          <span class="ml-auto">
            {{ formatDollar(orderData.total) }}
          </span>
        </div>
        <div
          v-if="!isError"
          class="mt-14 w-full text-center text-eonx-neutral-600"
        >
          May take up to 3 business days for receipient to receive cleared funds
        </div>
      </div>
      <div class="shrink-0 p-5">
        <base-button
          type="primary"
          class="w-full"
          @click="$router.push({ hash: '#profile-home' })"
        >
          Done
        </base-button>
      </div>
    </div>
  </base-aside-page>
</template>
