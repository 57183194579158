<script>
import EstoreTile from '/~/extensions/estore/components/estore-tile.vue'
import { useEstore } from '/~/extensions/estore/composables'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'

export default {
  name: 'estore-catalog',
  components: {
    EwCatalogProcessor,
    EstoreTile,
  },
  setup() {
    const { algolia } = useEstore()

    return {
      algolia,
    }
  },
}
</script>

<template>
  <ew-catalog-processor
    :processor="algolia"
    :columns="{
      xxs: 2,
      sm: 3,
      lg: 4,
    }"
  >
    <template #empty>
      <p>No Result found</p>
      <p>Please try another category</p>
    </template>

    <template #tile="{ tile }">
      <estore-tile
        :tile="tile"
        :thumb-width="300"
        :thumb-height="240"
        :loading="false"
      />
    </template>
  </ew-catalog-processor>
</template>
