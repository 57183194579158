<script>
export default {
  name: 'base-tab',
  props: {
    name: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    keepAlive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    href() {
      return `#${this.name.toLowerCase().replace(/ /g, '-')}`
    },
  },
  mounted() {
    this.isActive = this.selected
    if (this.$parent && typeof this.$parent.registerTab === 'function') {
      this.$parent.registerTab(this)
    }
  },
}
</script>

<template>
  <keep-alive v-if="keepAlive">
    <div v-show="isActive && $slots.default" class="flex grow flex-col pt-5">
      <slot />
    </div>
  </keep-alive>
  <div v-else-if="isActive && $slots.default" class="flex grow flex-col pt-5">
    <slot />
  </div>
</template>
