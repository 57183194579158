import { AlgoliaWorker } from '/~/core/algolia'
import HealthExercise from './exercise'
import colorsSet from '../utils/colorsSet'
export default class HealthExerciseProcessor extends AlgoliaWorker {
  constructor(params) {
    super({
      algolia_app_id: 'Q52UXC6ULJ',
      algolia_search_key: '3a7406c0ee81ab96cf33ee79d647013a',
      mapping: (data) => {
        return new HealthExercise(data)
      },
      ...params,
    })
    this.categories = []
  }

  get categoryTags() {
    const tags = {}

    this.categories.forEach((category, idx) => {
      tags[category.value] = colorsSet[idx]
    })

    return tags
  }

  async getCategories() {
    const categories = await super.getCategories({
      index: this.params.index,
      firstId: 0,
      firstLabel: 'All Categories',
      search: {
        facets: ['Collections'],
      },
    })

    this.categories = categories
      .filter((i) => !/,/.test(i.label))
      .map(({ label }) => ({ text: label, value: label }))

    return this.categories
  }

  setFilter(filters) {
    this.params.search = {
      ...this.params.search,
      ...filters,
    }
  }

  reset() {
    this.processing = true
    this.hits = []
    this.page = 0
    this.message = ''
    this.total = 0
    this.perPage = 1
    this.multiple = []
  }
}
