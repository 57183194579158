<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'reward-redeemed-modal',
  components: {
    BaseMdl,
    BaseIcon,
    BaseButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: '',
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm',
    },
    icon: {
      type: String,
      default: 'trash',
    },
    iconColor: {
      type: String,
      default: null,
    },
    singleButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
      modal.hide()
    },
    hide() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl width="sme" height="sm">
    <div class="relative text-center">
      <span class="text-primary">
        <base-icon :svg="icon" :size="48" class="mx-auto" :class="iconColor" />
      </span>

      <h2 class="mt-6 text-center text-3xl font-semibold text-zinc-800">
        {{ title }}
      </h2>
    </div>
    <div v-if="image" class="mt-5 flex justify-center">
      <img :src="image" alt="Check-out Code" />
    </div>
    <div v-if="code" class="mt-5 flex justify-center">
      <span
        class="rounded border border-dashed border-neutral-400 px-5 py-2.5 text-2xl font-semibold"
      >
        {{ code }}
      </span>
    </div>
    <div
      class="mt-[35px] mb-[72px] text-zinc-800"
      :class="{ 'text-center': code || image }"
      v-html="description"
    />
    <template #footer>
      <div class="mt-5 sm:flex sm:flex-row-reverse sm:justify-between">
        <div
          :class="{
            'pl-[5px] sm:w-1/2': !singleButton,
            'sm:w-full': singleButton,
          }"
        >
          <base-button full-width size="lge" @click="confirm">
            {{ confirmButtonText }}
          </base-button>
        </div>
        <div v-if="!singleButton" class="pr-[5px] sm:w-1/2">
          <base-button
            full-width
            look="outlined-color"
            class="mt-2.5 border-2 hover:opacity-50 sm:mt-0"
            size="lge"
            @click="hide"
          >
            Cancel
          </base-button>
        </div>
      </div>
    </template>
  </base-mdl>
</template>
