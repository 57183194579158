<script setup lang="ts">
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PaymentMethodsList from '/~/components/payments/list/default.v4.vue'
import PaymentMethodBlockItem from '/~/components/payments/payments-methods-block-item.v3.vue'
import PaymentMethodBlock from '/~/components/payments/payments-methods-block.v3.vue'
import { useProvider } from '/~/composables/provider'
import { useDirectDebitComponent } from '/~/templates/bill-payments/composables/statements/use-direct-debit-component'
import EarnPointsPanel from '/~/templates/bill-payments/views/statements/direct-debit/components/earn-points-panel.vue'
import PayeeInfo from '/~/templates/bill-payments/views/statements/direct-debit/components/payee-info.vue'
import PaymentDatePicker from '/~/templates/bill-payments/views/statements/direct-debit/components/payment-date-picker.vue'

interface DirectDebitComponentEmits {
  (event: 'hidden'): void
  (event: 'confirm'): void
  (event: 'hide'): void
  (event: 'cancel'): void
}

const emit = defineEmits<DirectDebitComponentEmits>()

const {
  pageOptions,
  showTermsAndConditionsModal,
  itemProcessingDueDay,
  processingActivePeriod,
  selectedStatementAutoPayment,
  tacChecked,
  tacToggle,
  handleProcessingDateSelect,
  isShowPaymentMethods,
  showPaymentMethods,
  showBankAccountNotices,
  hasSelectedPaymentMethods,
  isBankAccountSelected,
  isMethodSelected,
  isPointsEnabled,
  methodIcon,
  methodFirstRow,
  methodSecondRow,
  isProcessing,
  onCancel,
  onConfirm,
  title,
  subtitle,
  handlePaymentMethodSelected,
  transactionFees,
  fetchingPaymentMethods,
} = useDirectDebitComponent(emit)

const { taxationLabel } = useProvider()
</script>
<template>
  <base-aside-page v-bind="pageOptions" :no-padding="true">
    <!-- Select Date, Method -->
    <div v-if="!isShowPaymentMethods" class="flex flex-col p-5">
      <earn-points-panel v-if="isPointsEnabled" />

      <div class="mt-[30px] mb-5 text-xl font-bold">Payee</div>
      <payee-info
        :payee="selectedStatementAutoPayment?.statement?.payee"
        :title="title"
        :subtitle="subtitle"
      />
      <payment-date-picker
        v-model="itemProcessingDueDay"
        v-analytics:select.custom="{
          label: 'Payment date set',
          pageGroup: 'Make a payment',
          component1: 'Direct Debit Request',
        }"
        :active-period="processingActivePeriod"
        class="mt-8"
        :is-disabled="isProcessing"
        @select="handleProcessingDateSelect"
      />

      <payment-method-block title="Payment method" class="mt-8">
        <payment-method-block-item
          v-analytics:click.custom="{
            cta: 'Select or add payment method',
            pageGroup: 'Make a payment',
            component1: 'Direct Debit Request',
          }"
          :is-disabled="isProcessing"
          @click="showPaymentMethods"
        >
          <template v-if="isMethodSelected">{{ methodFirstRow }}</template>
          <template v-else>Select or add payment method</template>

          <template v-if="isMethodSelected" #subtitle>
            {{ methodSecondRow }}
          </template>

          <template #icon>
            <div
              class="flex h-full w-full items-center justify-center rounded-full"
              :class="{
                'bg-eonx-neutral-600 text-white': isBankAccountSelected,
                'text-primary': !isMethodSelected,
              }"
            >
              <base-icon
                :svg="isMethodSelected ? methodIcon : 'plain/credit-card'"
                :size="isBankAccountSelected ? 'md' : 40"
              />
            </div>
          </template>
        </payment-method-block-item>
      </payment-method-block>

      <base-loader
        v-if="
          selectedStatementAutoPayment &&
          selectedStatementAutoPayment.isLoadingFees
        "
      />
      <div
        v-else-if="
          selectedStatementAutoPayment.programFeesPercentage > 0 ||
          transactionFees
        "
        class="text-eonx-neutral-600"
      >
        <div class="mb-2.5 font-bold text-eonx-neutral-800">Fees</div>
        <div
          v-if="selectedStatementAutoPayment.programFeesPercentage > 0"
          data-testid="program-fee"
        >
          Program Fee ({{ selectedStatementAutoPayment.programFeesPercentage }}%
          inc. {{ taxationLabel }})
        </div>
        <div v-if="transactionFees" data-testid="processing-fee">
          Processing Fee ({{ transactionFees }}% inc. {{ taxationLabel }})
        </div>
      </div>

      <div class="absolute bottom-0 -mx-5 w-full border-t bg-neutral-50 p-5">
        <div
          class="mb-5 flex cursor-pointer items-center space-x-[5px] hover:opacity-80"
        >
          <button
            v-analytics:click="
              () => ({
                pageGroup: 'Make a payment',
                component1: 'Direct Debit Request',
                label: `${
                  tacChecked ? 'Tick' : 'Untick'
                } I agree to Terms and Conditions`,
              })
            "
            class="inline-flex items-center space-x-2.5 text-eonx-neutral-600"
            @click="tacToggle"
          >
            <span
              class="flex h-3.5 w-3.5 items-center rounded border-2 border-primary"
              :class="{ 'bg-primary': tacChecked }"
            >
              <base-icon svg="plain/check-bold" :size="16" class="text-white" />
            </span>
            <span>I agree to</span>
          </button>
          <b
            v-analytics:click="{
              cta: 'Terms & Conditions',
              pageGroup: 'Make a payment',
              component1: 'Direct Debit Request',
            }"
            class="text-primary"
            @click="showTermsAndConditionsModal"
          >
            Terms & Conditions
          </b>
        </div>
        <div class="mt-2.5 flex space-x-2.5">
          <button
            v-analytics:click="{
              cta: 'Cancel',
              pageGroup: 'Make a payment',
              component1: 'Direct Debit Request',
            }"
            class="w-full rounded-md border-2 border-primary p-2.5 font-bold text-primary hover:bg-primary-lighten"
            @click="onCancel"
          >
            Cancel
          </button>
          <button
            v-analytics:click="{
              cta: 'Confirm',
              pageGroup: 'Make a payment',
              component1: 'Direct Debit Request',
            }"
            class="w-full rounded-md border-2 border-primary bg-primary p-2.5 font-bold text-white filter hover:contrast-50 disabled:pointer-events-none disabled:border-neutral-300 disabled:bg-eonx-neutral-300"
            :disabled="
              !tacChecked || !hasSelectedPaymentMethods || isProcessing
            "
            @click="onConfirm"
          >
            <span v-if="isProcessing">Processing...</span>
            <span v-else>Confirm</span>
          </button>
        </div>
      </div>
    </div>
    <!-- Select Payment Method -->
    <base-loader v-else-if="fetchingPaymentMethods" class="mt-8 w-full" />
    <div v-else class="p-5">
      <payment-methods-list
        v-analytics:selected.custom="{
          label: 'Payment method set',
          pageGroup: 'Make a payment',
          component1: 'Direct Debit Request',
        }"
        class="w-full"
        @selected="handlePaymentMethodSelected"
      />
      <div
        v-if="showBankAccountNotices"
        class="flex items-center rounded-md bg-eonx-neutral-200 p-2.5 text-eonx-neutral-800"
      >
        <div class="mr-6">
          <base-icon svg="plain/alert-filled" :size="24" />
        </div>
        <div class="text-sm text-eonx-neutral-600">
          <ul class="list-disc">
            <li>
              Payments from bank accounts may take up to 3 business days to
              clear.
            </li>
            <li>
              Please ensure you have available funds to avoid any bank dishonour
              fees.
            </li>
            <li>
              Your points will be allocated once this payment has cleared.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </base-aside-page>
</template>
