<script setup lang="ts">
import { formatBsb } from '/~/utils/format/string'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { useLocalization } from '/~/composables/localization'
import SummaryPayScheduled from './summary-pay-scheduled.vue'
import SummaryPayToAmount from './summary-pay-to-amount.vue'
import SummaryPayToHeader from './summary-pay-to-header.vue'

const { payment } = useCheckoutReactive()
const { translate } = useLocalization()
</script>

<template>
  <div v-if="payment.payee" class="flex shrink-0 flex-col pb-5">
    <summary-pay-to-header class="mb-4" />
    <div class="flex shrink-0 gap-x-4">
      <payee-icon :payee="payment.payee" />
      <div class="flex w-full flex-col text-sm text-eonx-neutral-600">
        <div class="text-base font-bold text-eonx-neutral-800">
          {{ payment.payee.name }}
        </div>
        <div class="flex justify-between">
          <div class="mt-[5px]">
            <template v-if="payment.payee.type === 'bankAccount'">
              {{
                translate('bankAccount.details', {
                  acc: payment.payee.accountNumber,
                  bsb: formatBsb(payment.payee.bsb || ''),
                })
              }}
            </template>
            <template v-else-if="payment.payee.type === 'bpay'">
              Biller: {{ payment.payee.billerCode }} | Ref:
              {{ payment.reference }}
            </template>
          </div>
          <summary-pay-to-amount class="ml-auto self-end" />
        </div>
      </div>
    </div>
    <summary-pay-scheduled />
  </div>
</template>
