import TileArticle from './feed-tile-article.vue'
import TileExercise from './feed-tile-exercise.vue'
import TileRecipe from './feed-tile-recipe.vue'
import TileService from './feed-tile-service.vue'

export default {
  name: 'HlthTile',
  functional: true,
  render(h, ctx) {
    const { type } = ctx.props

    let component = TileExercise

    if (type === 'recipe') component = TileRecipe
    if (type === 'article') component = TileArticle
    if (type === 'service') component = TileService

    return h(component, ctx.data, ctx.children)
  },
}
