import '/~/styles/markup.css'
import '/~/configurator/components'
import 'swiper/css/bundle'
import '/~/boot/packages'
import '/~/boot/mixins'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import bfModals from '/~/extensions/bank-file-upload/modals'
import bfBottomSheets from '/~/extensions/bank-file-upload/modals/bottom-sheets'
import gcModals from '/~/extensions/giftcards/modals'
import { useCart } from '/~/composables/cart'
import { useEwallet } from '/~/composables/ewallet'
import { useExtensions } from '/~/composables/extensions'
import { useNotifications } from '/~/composables/notifications'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { usePurchases } from '/~/composables/purchases'
import {
  useMembership,
  usePointsPrograms,
} from '/~/templates/bill-payments/composables'
import bottomSheets from '/~/templates/bill-payments/modals/bottom-sheets'
import modals from './modals'

modal.register(gcModals)
modal.register(bfModals)
modal.register(modals)
bottomSheet.register(bottomSheets)
bottomSheet.register(bfBottomSheets)

async function bootstrap() {
  const { fetchBalance: fetchPointsBalance } = usePoints()
  const { fetchBalance: fetchEwalletBalance } = useEwallet()
  const { fetchPaymentMethods, getPaymentMethodsConfig } = usePaymentMethods()
  const { fetchCart } = useCart()
  const { initNotifications } = useNotifications()
  const { initExtensions } = useExtensions()
  const { initPurchases } = usePurchases()
  const { fetchMemberships } = useMembership()
  const { initAirPartners } = usePointsPrograms()

  initExtensions().then(() => {
    initNotifications()
    initAirPartners()
  })

  fetchMemberships()

  initPurchases()
  fetchCart()

  getPaymentMethodsConfig().then(() => {
    fetchPointsBalance()
    fetchEwalletBalance()
  })
  fetchPaymentMethods()

  import('/~/boot/zendesk')
  import('/~/boot/google-analytics')
}

if (eonx.user) {
  bootstrap()
} else {
  emitter.once('auth:success', bootstrap)
}
