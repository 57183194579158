<script>
import ui from '/~/core/ui'
import { inject } from 'vue'
import PostCreator from '/~rec/components/post/creator/post-creator.vue'
import PostCreatorFab from '/~rec/components/post/creator/post-creator-fab.vue'
import LayoutView from '/~rec/layouts/view/layout-view.vue'
import { useFeeds } from '/~rec/composables/feeds'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-news-view',
  components: {
    PostCreator,
    LayoutView,
    PostCreatorFab,
  },
  props: {
    feedId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const hasShadow = inject('hasShadow', false)
    const { selectedFeed, isNewPostProcessing } = useFeeds()
    const { isMeLimitedUser, isMeSuperAdmin, isMeAdmin } = useRecProfile()

    return {
      hasShadow,
      selectedFeed,
      isMeLimitedUser,
      isMeSuperAdmin,
      isMeAdmin,
      isNewPostProcessing,
      ui,
    }
  },
  data() {
    return {
      scrollPosition: null,
    }
  },
  computed: {
    isDesktop() {
      return ui.desktop
    },
    postCreatorTabs() {
      const { feedId, isMeLimitedUser } = this
      const isFeed = feedId === 'feed'
      const isAnnouncements = feedId === 'announcements'
      const isAdmin = this.isMeAdmin || this.isMeSuperAdmin
      const canCreateAnnouncement =
        isAnnouncements && isAdmin && !isMeLimitedUser

      if (!isMeLimitedUser && (isFeed || canCreateAnnouncement)) {
        return ['post', 'poll', 'quiz']
      }

      return []
    },
    isPostCreatorVisible() {
      return this.postCreatorTabs?.length
    },
  },
  created() {
    const { params } = this.$route

    if (!this.feedId) {
      this.$router.replace({ params: { ...params, feedId: 'feed' } })
    }
  },
  methods: {
    async onPostCreated() {
      this.isNewPostProcessing = true
      await this.selectedFeed?.getData({ force: false })
      setTimeout(() => {
        this.isNewPostProcessing = false
      }, 200)
    },
  },
}
</script>

<template>
  <layout-view>
    <div class="relative flex w-full flex-col">
      <div
        v-if="isDesktop && isPostCreatorVisible"
        class="sticky top-0 z-30 w-full bg-light transition duration-300"
        :class="{
          shadow: hasShadow,
        }"
      >
        <div class="mx-auto max-w-2xl px-2.5 sm:px-5">
          <post-creator :tabs="postCreatorTabs" @created="onPostCreated" />
        </div>
      </div>
      <router-view />
    </div>
    <post-creator-fab
      v-if="!ui.desktop && isPostCreatorVisible"
      :actions="postCreatorTabs"
      @created="onPostCreated"
    />
  </layout-view>
</template>
