<script>
import RecOptusMessage from './rec-optus.vue'

export default {
  name: 'recroom-optus',
  components: {
    RecOptusMessage,
  },
}
</script>

<template>
  <div id="recroom">
    <img
      class="fixed inset-x-0 -mt-12 block w-full sm:hidden"
      src="/images/recroom/optus/mobile.png"
    />
    <img
      class="fixed inset-x-0 hidden w-full sm:block"
      src="/images/recroom/optus/desktop.png"
    />
    <rec-optus-message />
  </div>
</template>
