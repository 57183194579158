import debounce from 'lodash-es/debounce'
import { computed, ref, watch, onMounted } from 'vue'
import { useEstore } from '/~/extensions/estore/composables'
import {
  transformQueriesBack,
  transformQueriesForward,
  defaultEstoreSettings,
} from '/~/extensions/estore/helpers'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import router from '/~/router'

export function useEstoreFilters(route) {
  const { ITEMS_DEBOUNCE } = defaultEstoreSettings
  const { isAustraliaPostProvider } = useProvider()
  const isPointsUse = computed(() => isAustraliaPostProvider.value)

  const { calculatePointsToBurnOrder, calculateAmountFromPointsToBurnOrder } =
    usePoints()
  const {
    minRange: initialMinRange,
    maxRange: initialMaxRange,
    catalogIsLoading,
    getCatalog,
    categories,
  } = useEstore()

  const currentSearch = ref('')
  const currentItems = ref([])
  const currentBrands = ref([])
  const currentMin = ref(null)
  const currentMax = ref(null)
  const initialFiltersState = ref({})
  const priceRangeInterval = computed(() =>
    isPointsUse.value ? +calculatePointsToBurnOrder(50) : 50
  )

  const minRangePoints = computed(() =>
    calculatePointsToBurnOrder(initialMinRange.value)
  )

  const maxRangePoints = computed(() =>
    calculatePointsToBurnOrder(initialMaxRange.value)
  )

  const minRange = computed(() =>
    isPointsUse.value ? minRangePoints.value : initialMinRange.value
  )
  const maxRange = computed(() =>
    isPointsUse.value ? maxRangePoints.value : initialMaxRange.value
  )

  const priceRangeFromPts = computed(() => {
    const { min, max } = route.query

    return {
      min: calculateAmountFromPointsToBurnOrder(min || floor(minRange.value)),
      max: calculateAmountFromPointsToBurnOrder(max || ceil(maxRange.value)),
      rawMin: calculateAmountFromPointsToBurnOrder(min || minRange.value),
      rawMax: calculateAmountFromPointsToBurnOrder(max || maxRange.value),
    }
  })

  const selectedCategory = computed(() => route.params.category || null)

  const selectedItems = computed(() =>
    transformQueriesBack(route, 'selected-items')
  )

  const selectedBrands = computed(() =>
    transformQueriesBack(route, 'selected-brands')
  )

  function updateIntitalFilters() {
    initialFiltersState.value = setQuery()
  }
  const floorMinRange = computed(() => floor(minRange.value))

  const ceilMaxRange = computed(() => ceil(maxRange.value))

  const priceRange = computed(() => {
    const { min, max } = route.query

    return {
      min: +min || floorMinRange.value,
      max: +max || ceilMaxRange.value,
      rawMin: +min || minRange.value,
      rawMax: +max || maxRange.value,
    }
  })

  const searchString = ref(route.query.search || '')

  watch(searchString, (newValue) => {
    router.replace({
      name: 'estore',
      ...transformQueriesForward(route, 'search', newValue),
      params: { ...route.params, preventTopScrolling: true },
    })
  })

  watch(
    () => route.query.search,
    (newSearch) => {
      searchString.value = newSearch || ''
    }
  )

  const filters = computed(() => ({
    selectedCategory: selectedCategory.value,
    selectedItems: selectedItems.value,
    selectedBrands: selectedBrands.value,
    searchString: searchString.value,
    priceRange: isAustraliaPostProvider.value
      ? priceRangeFromPts.value
      : priceRange.value,
  }))

  const isFiltersSelected = computed(() => {
    return (
      !!currentItems.value.length ||
      !!currentBrands.value.length ||
      currentMin.value > floorMinRange.value ||
      currentMax.value < ceilMaxRange.value ||
      Boolean(selectedCategory.value)
    )
  })

  function floor(value) {
    return (
      Math.floor(value / priceRangeInterval.value) * priceRangeInterval.value
    )
  }

  function ceil(value) {
    return (
      Math.ceil(value / priceRangeInterval.value) * priceRangeInterval.value
    )
  }

  async function getEstoreCatalog(params) {
    if (catalogIsLoading.value) {
      return
    }
    await getCatalog(params)
  }

  function setSelected() {
    currentItems.value = selectedItems.value
    currentBrands.value = selectedBrands.value
    currentSearch.value = searchString.value
    currentMin.value = priceRange.value.min
    currentMax.value = priceRange.value.max
  }

  function setQuery() {
    return {
      ...route.query,
      min: currentMin.value,
      max: currentMax.value,
      'selected-items': currentItems.value,
      'selected-brands': currentBrands.value,
      search: currentSearch.value,
    }
  }

  async function resetFilters() {
    const params = { ...route.params, preventTopScrolling: true }

    params.category = null
    await router.replace({
      name: 'estore',
      query: {},
      params,
    })
    setSelected()
    updateInitialFilters()
  }

  function updatePriceRange(value) {
    currentMin.value = value[0]
    currentMax.value = value[1]
  }

  function updateInitialFilters() {
    initialFiltersState.value = setQuery()
  }

  function applyFilters() {
    updateInitialFilters()
    const query = setQuery()

    if (query.min === floorMinRange.value) delete query.min
    if (query.max === ceilMaxRange.value) delete query.max

    if (JSON.stringify(route.query) === JSON.stringify(query)) {
      return
    }

    router.replace({
      query: query,
      name: 'estore',
      params: { ...route.params, preventTopScrolling: true },
    })
  }

  const getDebounceCatalog = debounce((params) => {
    getEstoreCatalog(params)
  }, ITEMS_DEBOUNCE)

  watch(minRange, () => {
    setSelected()
    updateInitialFilters()
  })

  watch(maxRange, () => {
    setSelected()
    updateInitialFilters()
  })

  watch(route, (current, previous) => {
    if (current.name !== 'estore') return
    if (current.params.category !== previous.params.category) {
      getEstoreCatalog(filters.value)
      return
    }

    getDebounceCatalog(filters.value)
  })

  onMounted(async () => {
    updatePriceRange([floorMinRange.value, ceilMaxRange.value])
  })

  const formatter = computed(() =>
    isPointsUse.value ? '{value} PTS' : '${value}'
  )
  const mergeFormatter = computed(() =>
    isPointsUse.value ? '{value1} PTS ~ {value2} PTS' : '${value1} ~ ${value2}'
  )
  const currency = computed(() =>
    isPointsUse.value ? { suffix: ' PTS' } : { prefix: '$' }
  )

  const filteredCategories = computed(
    () => categories.value?.data?.filter((item) => item.count) ?? []
  )

  return {
    priceRangeInterval,
    currentSearch,
    currentItems,
    currentBrands,
    currentMin,
    currentMax,
    initialFiltersState,

    minRangePoints,
    maxRangePoints,
    priceRangeFromPts,
    selectedCategory,
    selectedItems,
    selectedBrands,
    floorMinRange,
    ceilMaxRange,
    priceRange,
    filters,
    isFiltersSelected,
    isPointsUse,
    formatter,
    mergeFormatter,
    currency,
    filteredCategories,

    floor,
    ceil,
    getEstoreCatalog,
    setSelected,
    setQuery,
    resetFilters,
    updatePriceRange,
    updateInitialFilters,
    applyFilters,
    getDebounceCatalog,
    updateIntitalFilters,
  }
}
