import { useUser } from '/~/composables/user'

const { user } = useUser()

export const rules = {
  forBusinessOwnerOnly: 'forBusinessOwnerOnly',
}

export function useRules() {
  const functions = {
    [rules.forBusinessOwnerOnly]: function () {
      return user.value?.isBusinessOwner
    },
  }

  return functions
}
