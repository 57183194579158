<script setup lang="ts">
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { computed } from 'vue'
import { cardCode } from '/~/utils/cards'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

type PaymentMethod = {
  type: string
  number: string
  brand?: string
  title?: string
  msfFee?: number
  subtitle?: string
  prefix?: string
  bsb?: number
  finalAmount?: number
  amount: number
  name: string
  accountName: string
  points?: number
}

type Fees = {
  total: number
}

type Order = {
  name: string
  type: string
  accountName: string
  status: string
  fees: Fees
}

const props = defineProps<{
  item: PaymentMethod
  order: Order
}>()

const { isSymbioneliteProvider, isBillPaymentsTemplate } = useProvider()
const { pointsLabel } = usePoints()
const { translate } = useLocalization()

const status = computed(() => props.order.status ?? '')
const isPayId = computed(() => props.item.type === PaymentMethodType.payId)
const isPoints = computed(() => props.item.type === PaymentMethodType.points)
const isCard = computed(() => props.item.type === PaymentMethodType.creditCard)
const isBankAccount = computed(
  () => props.item.type === PaymentMethodType.bankAccount
)
const isCoupon = computed(
  () => props.item.type === PaymentMethodType.couponCode
)
const isScheduled = computed(() => status.value === 'scheduled')
const code = computed(() => {
  const issuingNetwork = getCardTypeByNumber(props.item.number)

  return cardCode({ brand: issuingNetwork?.type })
})

const icon = computed(() => {
  if (isPayId.value) return 'billpay/payment-methods/payid'
  if (isPoints.value) return 'symbion/token'
  if (isCard.value) return `billpay/payment-methods/${code.value}`
  if (isBankAccount.value) return 'v2/custom/bank'
  if (isCoupon.value) return 'coupon'

  return null
})

const firstRow = computed(() => {
  const { name, type, accountName } = props.item

  switch (type) {
    case PaymentMethodType.payId:
      return 'PayID'
    case PaymentMethodType.points:
      return isSymbioneliteProvider.value
        ? pointsLabel.value
        : 'Pay with points'
    case PaymentMethodType.creditCard:
      return name
    case PaymentMethodType.bankAccount:
      return accountName ?? name
    case PaymentMethodType.couponCode:
      return 'Coupon'
    default:
      return ''
  }
})
const secondRow = computed(() => {
  const { number = '', prefix: bsb, name } = props.item

  if (isPayId.value) return name
  if (isPoints.value)
    return `${formatPoints(
      props.item.points ?? props.item.amount
    )} points redeemed`
  if (isCard.value) return `**** **** **** ${number.slice(-4)}`
  if (isBankAccount.value) {
    return translate('bankAccount.details', {
      acc: `*** *** *${number.slice(-2)}`,
      bsb,
    })
  }
  if (isCoupon.value) return number

  return null
})
const totalFees = computed(() => {
  return Number(props.order.fees?.total ?? 0)
})
const amountToPay = computed(() => {
  return (
    Number(props.item.finalAmount ?? props.item.amount ?? 0) +
    (isScheduled.value && (isCard.value || isBankAccount.value)
      ? totalFees.value
      : 0)
  )
})
</script>
<template>
  <div class="mb-[30px] flex gap-4">
    <div
      class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full text-white"
      :class="{
        'bg-eonx-neutral-600': isBillPaymentsTemplate && isBankAccount,
        'bg-primary': !isBillPaymentsTemplate && isBankAccount,
        'p-[5px]': isBankAccount,
      }"
    >
      <base-icon
        v-if="icon"
        :svg="icon"
        :size="isBankAccount ? 'lg' : 40"
        :class="{
          'text-primary': isCoupon || isPoints,
        }"
      />
    </div>
    <div class="flex w-full flex-col self-center">
      <div class="font-bold">
        {{ firstRow }}
      </div>
      <div class="flex justify-between text-sm text-eonx-neutral-600">
        <div>{{ secondRow }}</div>
        <div>{{ formatDollar(amountToPay) }}</div>
      </div>
    </div>
  </div>
</template>
