<script>
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import isEmpty from 'lodash-es/isEmpty'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import emitter from '/~/core/emitter'
import { formatNumber } from '/~/utils/format/numeric'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-post-earn',
  components: {
    BaseIcon,
    BaseLoader,
  },
  inject: ['post'],
  setup() {
    const { features, isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return { formatNumber, features, isMeAdmin, isMeSuperAdmin }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    totalEarn() {
      return this.post.earnedSoFar
    },
    currency() {
      return this.post.currency
    },
    type() {
      return this.post.type
    },
    userEarnedPoints() {
      return this.post.userEarnedPoints
    },
    attachmentEarnConfigs() {
      const attachments = get(this.post, 'attachments.hits')
      const configs = []

      attachments.forEach((attachment) => {
        const config = attachment.earnConfigs

        if (isEmpty(config)) return
        configs.push(config[0])
      })

      return configs
    },
    allEarnConfigs() {
      return this.post.earnConfigs.concat(this.attachmentEarnConfigs)
    },
  },
  created() {
    if (this.features.points) {
      emitter.on(
        'notifications:target_earn_limit_update',
        this.updatePostPointsEarned
      )
    }
  },
  beforeDestroy() {
    emitter.off(
      'notifications:target_earn_limit_update',
      this.updatePostPointsEarned
    )
  },
  methods: {
    async openEarnStatusModal() {
      if (this.loading) return
      this.loading = true
      await this.post.earnStatus.getData()
      this.loading = false
      modal.show('rec-earn-transactions', {
        props: {
          transactions: this.post.earnStatus,
          currency: this.currency,
          totalEarn: this.totalEarn,
          type: this.type,
        },
      })
    },
    async handleEarnModal() {
      if (this.loading) return
      this.loading = true
      await this.post.fullPost()
      this.loading = false
      modal.show('rec-earn-post', {
        props: {
          earnConfigs: this.allEarnConfigs,
          post: this.post,
        },
      })
    },
    updatePostPointsEarned(data) {
      const postId = data.body?.metadata?.limit_target_id

      if (this.post.id === postId) {
        this.post.fullPost()
      }
    },
  },
}
</script>

<template>
  <div class="mt-2.5 flex w-full justify-start xs:mt-0">
    <div
      v-if="isMeAdmin || isMeSuperAdmin"
      class="flex h-[30px] w-[174px] cursor-pointer items-center justify-center rounded-3xl tracking-wider text-white"
      :class="{
        'bg-gray-300': loading,
        'bg-amber-400 hover:opacity-75': !loading,
      }"
      @click="openEarnStatusModal"
    >
      <base-loader v-if="loading" size="xs" />
      <template v-else>
        <span class="text-sm font-semibold uppercase">
          {{ formatNumber(totalEarn) }} {{ currency }} Given
        </span>
        <base-icon svg="rec/info" :size="24" />
      </template>
    </div>
    <div
      v-else
      class="flex h-[30px] w-40 cursor-pointer items-center justify-center rounded-3xl tracking-wider text-white"
      :class="{
        'bg-gray-300': loading,
        'bg-amber-400 hover:opacity-75': !loading,
      }"
      @click="handleEarnModal"
    >
      <base-loader v-if="loading" size="xs" />
      <template v-else>
        <span v-if="userEarnedPoints > 0" class="flex items-center">
          <base-icon svg="rec/token" :size="24" class="mr-[5px]" />
          {{ userEarnedPoints }}
        </span>
        <span v-else class="flex items-center">
          <span class="text-sm font-semibold uppercase">
            Earn {{ currency }}
          </span>
          <base-icon svg="rec/info" class="ml-[5px]" :size="24" />
        </span>
      </template>
    </div>
  </div>
</template>
