<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'question-select-actions',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    local: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit('input', value)
      },
    },
  },
  methods: {
    setValue(val) {
      this.local = val
      this.$emit('next')
    },
  },
}
</script>

<template>
  <div class="mb-5 flex">
    <div
      v-for="(action, i) in content.actions"
      :key="i"
      class="mx-2.5 flex flex-col items-center text-primary"
    >
      <base-button look="link" @click="setValue(action.value)">
        <base-icon :svg="action.icon" :size="60" />
      </base-button>
      <span class="mt-2.5 font-bold text-neutral-400">
        {{ action.name }}
      </span>
    </div>
  </div>
</template>
