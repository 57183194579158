<script>
import ui from '/~/core/ui'
import ViewMobile from './view-mobile.vue'
import ViewDesktop from './view-desktop.vue'

export default {
  name: 'layout-view',
  functional: true,
  render(h, ctx) {
    return h(ui.desktop ? ViewDesktop : ViewMobile, ctx)
  },
}
</script>
