<script>
import ui from '/~/core/ui'
import ActivityDetailsDesk from './activity-details.desk.vue'
import ActivityDetailsMobile from './activity-details.mobile.vue'

export default {
  name: 'activity-details',
  components: {
    ActivityDetailsDesk,
    ActivityDetailsMobile,
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <activity-details-mobile v-if="ui.mobile" v-bind="$attrs" v-on="$listeners" />
  <activity-details-desk v-else v-bind="$attrs" v-on="$listeners" />
</template>
