<script>
import './bootstrap'
import MdlKeeper from '/~/components/mdl/mdl-keeper.vue'
import BottomSheetKeeper from '/~/components/bottom-sheet/bottom-sheet-keeper.vue'
import AppAlerts from '/~/components/app/app-alerts.vue'
import { useEwalletSdk } from '/~/composables/ewallet-sdk'
import { useUser } from '/~/composables/user'
import { useUI } from '/~/composables/ui'
import { onMounted } from 'vue'

export default {
  name: 'main-view',
  components: {
    MdlKeeper,
    BottomSheetKeeper,
    AppAlerts,
  },
  setup() {
    const { sendReadyEvent } = useEwalletSdk()
    const { user } = useUser()
    const { uiReady } = useUI()

    onMounted(() => {
      eonx.ready()
      sendReadyEvent()
    })

    return {
      user,
      uiReady,
    }
  },
}
</script>

<template>
  <div
    id="app"
    class="flex h-full flex-col overflow-x-hidden print:block print:h-auto"
  >
    <template v-if="user.authorized">
      <template v-if="uiReady">
        <router-view />
      </template>
    </template>
    <router-view v-else />

    <div id="print-area">
      <portal-target name="print-destination" />
    </div>

    <mdl-keeper />
    <portal-target name="menu-modal" multiple />
    <bottom-sheet-keeper />
    <app-alerts />
  </div>
</template>
