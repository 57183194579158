<script>
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseButton from '/~/components/base/button/base-button'
import { useChat } from '/~rec/composables/chat'
import { useRecProfile } from '/~rec/composables/profile'
import { useDirectory } from '/~rec/composables/directory'

export default {
  name: 'rec-directory-member-profile',
  components: {
    BaseIcon,
    BaseAvatar,
    BaseLoader,
    BaseButton,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { chat, createRoom, leaveRoom, openRoom } = useChat()
    const { myRecId, features } = useRecProfile()
    const { getDirectoryProfile } = useDirectory()

    return {
      chat,
      createRoom,
      leaveRoom,
      myRecId,
      features,
      openRoom,
      getDirectoryProfile,
      ui,
    }
  },
  data() {
    return {
      loadChat: false,
      fetching: false,
      profile: null,
    }
  },
  computed: {
    routeProfileId() {
      return this.$route.query.recProfileId
    },
    isMe() {
      return this.profile?.id === this.myRecId
    },
    menu() {
      return [
        {
          icon: 'rec/solid/award',
          title: 'Nominate for an Award',
          text: 'Place them in the running to win',
          action: () => {
            this.$router.push({
              name: 'rec-award-nominate-user',
              params: {
                nomineeId: this.profile.userId,
                profile: this.profile,
              },
            })
          },
          hidden: !this.features.awards,
        },
        {
          icon: 'rec/solid/hi5',
          title: 'Send a Hi-5',
          text: 'Show your instant appreciation',
          action: () => {
            this.$router.push({
              name: 'rec-hi5-send-to',
              params: {
                sendToId: this.profile.userId,
                profile: this.profile,
              },
            })
          },
          hidden: !this.features.hi5,
        },
        {
          icon: 'rec/solid/chat',
          title: 'Start a chat',
          loading: this.loadChat,
          text: 'Message privately through direct chat',
          action: async () => {
            this.loadChat = true

            const room = this.chat?.joinedRooms?.hits?.find((room) => {
              return (
                room.activeMembersWithoutMe?.length === 1 &&
                room.activeMembersWithoutMe.some((p) => {
                  return p?.id === this.profile?.userId
                })
              )
            })

            if (room) {
              this.openRoom(room?.id)
            } else {
              const createdRoom = await this.createRoom({
                userIds: [this.profile?.userId],
              })

              this.openRoom(createdRoom?.id)
            }

            this.loadChat = false
          },
          hidden: !this.features.chat,
        },
      ].filter((i) => !i.hidden)
    },
  },
  watch: {
    routeProfileId: {
      handler(id) {
        if (id) {
          this.fetchProfile(id)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchProfile(this.routeProfileId || this.user.id)
  },
  methods: {
    async fetchProfile(id) {
      this.fetching = true
      this.profile = await this.getDirectoryProfile(id)
      this.fetching = false
    },
  },
}
</script>

<template>
  <div class="md:min-h-[500px] md:px-5">
    <div
      class="mt-[15px] hidden cursor-pointer items-center text-primary hover:text-blue-400 md:flex"
      @click="$emit('back')"
    >
      <base-icon svg="plain/chevronleft" size="xs" class="mr-2.5" />
      <span class="font-bold">Back</span>
    </div>
    <div class="absolute right-0 mt-2.5 md:hidden">
      <base-button color="white" class="text-primary" @click="$emit('back')">
        <div class="flex items-center">
          <span class="mr-2.5">Close</span>
          <base-icon svg="rec/close" size="xxs" />
        </div>
      </base-button>
    </div>

    <div v-if="fetching" class="flex h-full w-full items-center justify-center">
      <base-loader size="xl" class="mt-[30px]" />
    </div>

    <div
      v-if="!fetching && profile"
      class="pt-[30px] md:mt-0 md:space-y-5 md:pt-11"
    >
      <div class="space-y-2.5 text-center">
        <base-avatar
          :src="profile.avatarUrl"
          :abbr="profile.initials"
          class="mx-auto mb-[15px] h-[140px] w-[140px] text-6xl uppercase md:mb-0 md:h-48 md:w-48"
        />

        <h3 class="hidden break-words md:block">
          {{ profile.fullName }}
        </h3>
      </div>

      <div
        class="m-auto my-[15px] flex w-10/12 items-center divide-x text-center md:m-0 md:w-full"
      >
        <div v-if="features.awards" class="w-full">
          <p class="font-medium text-eonx-neutral-600">Awards</p>
          <h4 class="text-2xl font-bold md:text-xl">
            {{ profile.awardsWon }}
          </h4>
        </div>
        <div v-if="features.hi5" class="w-full">
          <p class="font-medium text-eonx-neutral-600">Hi-5s</p>
          <h4 class="text-2xl font-bold md:text-xl">
            {{ profile.highFivesReceived }}
          </h4>
        </div>
      </div>

      <div v-if="!isMe" class="mb-[5px] p-[5px] md:mb-0 md:p-0">
        <div
          v-for="(item, idx) in menu"
          :key="idx"
          class="mb-[15px] flex cursor-pointer items-center rounded-xl py-[5px] hover:bg-eonx-neutral-50 md:mb-0 md:px-5 md:py-2.5"
          @click="() => item.action()"
        >
          <div class="text-white">
            <base-icon
              :svg="item.loading ? 'spinner' : item.icon"
              :size="ui.mobile ? 48 : 50"
              class="rounded-full bg-primary"
              :class="{
                'md:opacity-1 p-[15px] opacity-75': !item.loading,
              }"
            />
          </div>
          <div class="flex flex-col truncate pl-2.5 md:pl-5">
            <span
              class="md:leading-1 truncate font-bold leading-7 text-eonx-neutral-800"
            >
              {{ item.title }}
            </span>
            <span
              class="truncate text-sm font-normal text-eonx-neutral-600 md:font-semibold md:leading-5"
            >
              {{ item.text }}
            </span>
          </div>
        </div>
      </div>

      <div class="space-y-[15px]">
        <div
          v-if="profile.bio"
          class="break-words px-[5px] text-eonx-neutral-800 md:px-6"
        >
          &ldquo;{{ profile.bio }}&rdquo;
        </div>

        <div
          v-if="profile.phoneNumber"
          class="flex items-center px-2.5 font-bold text-eonx-neutral-600 md:px-6"
        >
          <base-icon svg="rec/phone" :size="21" />
          <span class="pl-2.5 leading-4 md:pb-[5px]">
            {{ profile.phoneNumber }}
          </span>
        </div>

        <div
          v-if="profile.email"
          class="flex items-center px-2.5 font-bold text-eonx-neutral-600 md:px-6"
        >
          <base-icon svg="rec/email" :size="21" />
          <span class="pl-2.5 leading-4 md:pb-[5px]">
            {{ profile.email }}
          </span>
        </div>
        <div
          v-if="profile.location"
          class="flex items-center px-2.5 font-bold text-eonx-neutral-600 md:mb-0 md:px-6"
        >
          <base-icon svg="rec/location" :size="21" />
          <span class="pl-2.5 leading-4 md:pb-[5px]">
            {{ profile.location }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
