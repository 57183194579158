<script>
import ui from '/~/core/ui'
import { getTailwindWidthByColumns } from '/~/utils/style'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'ew-catalog-processor',
  components: {
    BasePlaceholder,
    BaseLoader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    processor: {
      type: Object,
      required: true,
    },
    columns: {
      type: [Number, Object],
      default: () => ({
        xs: 2,
        sm: 4,
        md: 5,
      }),
    },
    placeholder: {
      type: Object,
      default: () => ({}),
    },
    tile: {
      type: Object,
      default: () => ({}),
    },
    tileClass: {
      type: [String, Boolean],
      default: 'shadow-xs hover:shadow-lg hover:-translate-y-[5px] bg-light',
    },
    catalogClass: {
      type: String,
      default: '',
    },
    useOverlayLoader: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: Infinity,
    },
    groupBy: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    gridClass() {
      return `${getTailwindWidthByColumns(this.columns)} ${this.catalogClass}`
    },
    showOverlayLoader() {
      const { processing, hits, page } = this.processor

      return (
        this.useOverlayLoader && processing && hits && hits.length && page === 1
      )
    },
    showPlaceholderLoader() {
      const { processing, hits, page } = this.processor

      return (
        processing && hits && !hits.length && (page === 1 || page === 0)
        // ||
        // !this.useOverlayLoader
      )
    },
    hits() {
      const { hits, sortedHits } = this.processor
      const data = sortedHits || hits || []

      return this.limit === Infinity ? data : data.slice(0, this.limit)
    },
    groupedHits() {
      const groupedHits = []

      if (this.groupBy) {
        for (const item of this.hits) {
          const id = String(item[this.groupBy]).toLowerCase()
          const index = groupedHits.findIndex((item) => item.id === id)

          if (index === -1) {
            groupedHits.push({ id, items: [item] })
          } else {
            groupedHits[index].items = [...groupedHits[index].items, item]
          }
        }
      } else {
        groupedHits.push({ id: 'default', items: this.hits })
      }

      return groupedHits
    },
  },
  methods: {
    onAppend(isVisible) {
      if (!isVisible) return
      this.processor.next()
      this.$emit('append')
    },
    onTileClick(hit) {
      const { tile } = this

      if (tile.click instanceof Function) {
        tile.click(hit)
      }
    },
  },
}
</script>

<template>
  <div
    class="flex w-full grow flex-col"
    role="region"
    aria-live="polite"
    aria-relevant="additions removals"
    @scroll="$emit('scroll', $event)"
  >
    <div
      v-if="title || $slots.header"
      class="flex shrink-0 items-center justify-between py-[15px]"
    >
      <h2 class="text-xl sm:text-2xl">
        <span>{{ title }}</span>
        <slot name="header" />
      </h2>
    </div>

    <slot v-if="showPlaceholderLoader" name="placeholder">
      <base-placeholder
        :columns="columns"
        :rows="placeholder.rows || 1"
        :height="placeholder.height || 128"
        :type="placeholder.type"
        class="pb-6"
        :class="{
          'pt-5': !ui.mobile,
        }"
      />
    </slot>

    <div v-else class="flex w-full grow flex-col">
      <div v-if="processor.message" class="w-full">
        {{ processor.message }}
      </div>
      <div
        v-else-if="processor.isEmpty && !$slots.defaultItems"
        class="ew-catalog__empty flex h-full flex-col justify-center pb-6"
        :class="{
          'pt-5': !ui.mobile,
        }"
      >
        <slot name="empty" />
      </div>

      <template v-else>
        <div
          v-if="$slots.defaultItems"
          class="grid pb-6"
          :class="{
            'gap-2.5 sm:gap-5': !noPadding,
            'pt-5': !ui.mobile,
            [gridClass]: true,
          }"
        >
          <slot name="defaultItems" />
        </div>
        <div
          v-for="(group, i) in groupedHits"
          :key="(group && `${group.id}-${i}`) || `tile-group-${i}`"
          :class="{
            'pb-10 last:pb-0': groupedHits.length > 1,
          }"
          :data-group="group.id"
        >
          <slot :name="`group-${group.id}`" />

          <transition-group
            tag="ul"
            :name="tile.transition"
            class="grid pb-6"
            :class="{
              'gap-4 sm:gap-5': !noPadding,
              'pt-5': !ui.mobile,
              [gridClass]: true,
            }"
          >
            <li
              v-for="(hit, j) in group.items"
              :key="
                (hit && hit.objectID) ||
                (hit && `${hit.id}-${i}-${j}`) ||
                `tile-${j}`
              "
              class="relative"
              :class="['ew-catalog__tile', tile.class]"
              @click="onTileClick(hit)"
            >
              <div
                class="h-full rounded-lg"
                :class="{
                  'transform transition duration-100': tileClass !== false,
                  [tileClass]: true,
                }"
                :style="
                  tileClass !== false && 'will-change: transform, box-shadow'
                "
              >
                <slot :tile="hit" :index="i" name="tile" />
              </div>
            </li>
          </transition-group>
        </div>

        <base-loader
          v-if="!processor.allRecordsLoaded && limit === Infinity"
          key="observer"
          v-observe-visibility="onAppend"
          class="py-[30px]"
          fullwidth
        />
      </template>
    </div>
    <slot />
  </div>
</template>
