<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { useCheckoutReactive, useCheckoutRoutes } from '/~/composables/checkout'
import { usePayId, PayIdOrder } from '/~/composables/payment-methods'
import CompletePayId from '/~/views/checkout.v2/views/checkout/components/complete-payid/complete-payid.vue'

const router = useRouter()
const route = useRoute()
const { getCurrentFlowRouteName } = useCheckoutRoutes()
const { payment, currentFlowType, getFlowTypeFromOrderNumber } =
  useCheckoutReactive()
const { onCancelPaymentOrder } = usePayId()

const props = defineProps<{
  orderId: string
}>()

const payIdOrder = computed(() => {
  return new PayIdOrder({
    id: payment.value.confirmation.order?.paymentMethods?.[0].name,
    reference: props.orderId,
    amount: payment.value.confirmation.order?.total,
    expiresAt: payment.value.confirmation.order?.expiresAt,
  })
})
const loading = ref(false)

function goToConfirmationPage() {
  modal.hideAll()
  router.push({
    name: getCurrentFlowRouteName('checkout-confirmation'),
    params: { orderId: props.orderId },
  })
}

function goToActivityPage() {
  router.push({
    name: 'activity',
  })
}

onBeforeMount(async () => {
  try {
    loading.value = true
    currentFlowType.value = getFlowTypeFromOrderNumber(props.orderId)
    await payment.value.getOrder(props.orderId)
    const status = payment.value.confirmation.order?.status

    if (status && status !== 'awaiting_funds_pi') {
      goToConfirmationPage()
    }
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
})

emitter.on('notifications:payment_order_status_updated', (event) => {
  const { orderNumber, orderStatus } = event

  if (orderStatus === 'awaiting_funds_pi') {
    return
  }

  if (orderNumber !== props.orderId) {
    return
  }

  const completePayIdRouteName = getCurrentFlowRouteName('complete-payid')

  if (route.name === completePayIdRouteName) {
    goToConfirmationPage()
  }
})
</script>

<template>
  <div class="h-full overflow-auto pt-4 sm:pt-8">
    <div class="mx-auto h-full max-w-xl rounded-t-3xl bg-white p-8">
      <div class="text-center text-3xl font-bold">
        Complete payment with PayID
      </div>
      <complete-pay-id
        class="mt-8"
        :pay-id-order="payIdOrder"
        :loading="loading"
        @cancel="
          onCancelPaymentOrder(orderId, goToConfirmationPage, payment.flowType)
        "
        @continue="goToActivityPage()"
      />
    </div>
  </div>
</template>
