<script>
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'layout-map',
  components: {
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    mapState: {
      type: Object,
      required: true,
    },
    markers: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    loading() {
      if (this.mapState.userLocation) {
        return false
      }

      return !this.mapState.isReady
    },
  },
}
</script>

<template>
  <div class="relative">
    <div
      v-if="loading"
      class="flex h-full flex-col items-center justify-center p-5"
    >
      <base-icon svg="puff" color="primary" size="4xl" />
      <span class="mt-5 text-lg font-bold">
        <slot name="loader">Locating near you</slot>
      </span>
    </div>

    <div v-else class="relative flex h-full w-full">
      <slot v-if="!ui.mobile || !mapState.isVisible" />
      <div v-if="mapState.isVisible" class="h-full w-full">
        <slot name="map" />
      </div>
    </div>
  </div>
</template>
