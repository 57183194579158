import ui from '/~/core/ui'
import { computed } from 'vue'
import { useRecProfile } from './profile'
import { useAwards } from '/~rec/composables/awards'
import { useHi5 } from '/~rec/composables/hi5'
import { usePerks } from '/~rec/composables/perks'

const { features, isMeLimitedUser, isMeAdmin, isMeSuperAdmin } = useRecProfile()
const { awards } = useAwards()
const { hi5 } = useHi5()
const { perks } = usePerks()

const mainMenu = [
  {
    icon: {
      svg: 'rec/mobile-menu/feeds',
    },
    label: 'Newsfeed',
    tooltip: 'Go to News Room',
    route: 'rec-feed',
  },
  {
    icon: {
      svg: 'rec/star-2',
    },
    label: 'Today',
    tooltip: 'Go to What’s on today',
    route: 'rec-today',
    permission: ['calendar'],
  },
  {
    icon: {
      svg: 'rec/mobile-menu/groups',
    },
    label: 'Groups',
    tooltip: 'Go to Groups',
    route: 'rec-groups',
    permission: ['groups'],
  },
  {
    icon: {
      svg: 'rec/mobile-menu/events',
    },
    label: 'Events',
    tooltip: 'Go to Events',
    route: 'rec-events',
    permission: ['events', 'celebrations'],
  },
  {
    icon: {
      svg: 'rec/mobile-menu/awards',
    },
    label: 'Awards',
    tooltip: 'Go to Awards',
    route: 'rec-awards',
    permission: ['awards'],
  },
  {
    icon: {
      svg: 'rec/mobile-menu/hi5',
    },
    label: 'Hi-5',
    tooltip: 'Go to Hi-5',
    route: 'rec-hi5',
    permission: ['hi5'],
  },
  {
    icon: {
      svg: 'rec/mobile-menu/perks',
    },
    label: 'Perks',
    tooltip: 'Go to Perks',
    route: 'rec-perks',
    permission: ['perks'],
  },
  {
    icon: {
      svg: 'rec/mobile-menu/chat',
    },
    label: 'Chat',
    tooltip: 'Go to Chat',
    route: 'rec-chat',
    permission: ['chat'],
  },
  {
    icon: {
      svg: 'rec/profile',
    },
    label: 'Directory',
    tooltip: 'Go to Directory',
    route: 'rec-directory',
    permission: ['directory'],
  },
]

const mainMenuMobile = computed(() => {
  return mainMenu.filter((item) => {
    return item.permission
      ? item.permission.every((i) => features.value[i])
      : true
  })
})

const showSidebar = computed(() => {
  return ['directory', 'events', 'celebrations', 'sidebarBanners'].some(
    (i) => features.value[i]
  )
})

const allMobileSections = computed(() => [
  // Feed posts
  {
    name: 'posts',
    routeParams: {
      name: 'feedId',
      type: 'params',
    },
    tabWidth: 140,
    route: 'rec-feed-posts',
    tabs: [
      {
        name: 'All Posts',
        value: 'feed',
      },
      {
        name: 'Announcements',
        value: 'announcements',
        hidden: !features.value.announcements,
      },
      {
        name: 'Saved Posts',
        value: 'saved-posts',
      },
      {
        name: 'My Posts',
        value: 'my',
        hidden: isMeLimitedUser.value,
      },
      {
        name: 'Reported Posts',
        value: 'reported-posts',
        hidden: !isMeAdmin.value && !isMeSuperAdmin.value,
      },
    ].filter((v) => !v.hidden),
  },
  // Groups
  {
    name: 'groups',
    route: 'rec-groups',
    routeParams: {
      name: 'type',
      type: 'query',
    },
    tabs: [
      {
        name: 'My Groups',
        value: 'my',
      },
      {
        name: 'Other Groups',
        value: 'other',
      },
    ],
  },
  // Events
  {
    route: 'rec-events-feed',
    tabWidth: ui.desktop ? 105 : 140,
    routeParams: {
      name: 'feedId',
      type: 'params',
    },
    tabs: [
      {
        value: 'all',
        name: 'All Events',
        hidden: !features.value.events,
      },
      {
        value: 'attending',
        name: 'Attending',
        hidden: !features.value.events,
      },
      {
        value: 'hosting',
        name: 'Hosting',
        hidden: !features.value.events,
      },
      {
        value: 'celebrations',
        name: 'Celebrations',
        hidden: !features.value.celebrations,
      },
    ].filter((v) => !v.hidden),
  },
  // Awards
  {
    name: 'awards',
    route: 'rec-awards',
    routeParams: {
      type: 'query',
      name: 'type',
    },
    tabs: [
      {
        value: 'available',
        label: `Available (${awards.available.total})`,
        name: `${
          ui.desktop ? `Available (${awards.available.total})` : 'Available'
        }`,
      },
      {
        value: 'competing',
        label: `${ui.desktop ? 'Competing in' : 'Competing'} (${
          awards.competing.total
        })`,
        name: `${
          ui.desktop ? `Competing in (${awards.competing.total})` : 'Competing'
        }`,
      },
      {
        value: 'received',
        label: `Received (${awards.redeemed.total})`,
        name: `${
          ui.desktop ? `Received (${awards.redeemed.total})` : 'Received'
        }`,
      },
    ],
  },
  // Hi5s
  {
    route: 'rec-hi5',
    routeParams: {
      type: 'query',
      name: 'type',
    },
    tabs: [
      {
        value: 'available',
        name: `${
          ui.desktop ? `Available (${hi5.available.total})` : 'Available'
        }`,
      },
      {
        value: 'sent',
        name: `${ui.desktop ? `Sent (${hi5.sent.total})` : 'Sent'}`,
      },
      {
        value: 'received',
        name: `${ui.desktop ? `Received (${hi5.received.total})` : 'Received'}`,
      },
    ],
  },
  // Perks
  {
    route: 'rec-perks',
    routeParams: {
      type: 'query',
      name: 'type',
    },
    tabs: [
      {
        value: 'available',
        name: `${
          ui.desktop ? `Available (${perks.available.total})` : 'Available'
        }`,
      },
      {
        value: 'pending',
        name: `${
          ui.desktop
            ? `Pending Approval (${perks.pendingApproval.total})`
            : 'Pending'
        }`,
      },
      {
        value: 'received',
        name: `${
          ui.desktop ? `Received (${perks.redeemed.total})` : 'Received'
        }`,
      },
    ],
  },
])

export const useRecUi = () => ({
  mainMenu,
  showSidebar,
  mainMenuMobile,
  allMobileSections,
})
