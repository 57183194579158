import Analytics, { IAnalyticsEvent, IEventParams } from '/~/core/analytics'

function onFormFieldChange(field: string) {
  trackBankFormAnalytics(
    { label: `${field} field changed` },
    { debounce: true }
  )
}

function trackBankFormAnalytics(event: IAnalyticsEvent, params?: IEventParams) {
  Analytics.sendEvent(
    {
      pageGroup: 'Drawer',
      page: 'Add payment method',
      component1: 'Bank account',
      ...event,
    },
    params
  )
}

export function useAnalytics() {
  return {
    onFormFieldChange,
    trackBankFormAnalytics,
  }
}
