<script>
import { createDate } from '/~/utils/format/date'
import Vue from 'vue'
import VCalendar from 'v-calendar'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useQrConnect } from '/~/extensions/qr-connect/composables'

Vue.use(VCalendar)

export default {
  name: 'qr-datepicker',
  components: {
    BaseIcon,
  },
  setup() {
    const { animation, claims } = useQrConnect()

    return {
      animation,
      claims,
    }
  },
  data() {
    return {
      firstDate: null,
    }
  },
  computed: {
    disabledDates() {
      // return this.claims.map(i => {
      //   return {
      //     start: new Date(i.startsAt),
      //     end: new Date(i.endsAt),
      //   }
      // })
      const dates = []

      // Before today
      const past = new Date()

      dates.push({
        end: past.setDate(past.getDate() - 1),
      })

      // Block already claimed dates
      const claimedDates = this.claims.map((i) => {
        return {
          start: new Date(i.startsAt),
          end: new Date(i.endsAt),
        }
      })

      // Block +/-week from selected
      // let disabledFirst = []

      if (this.firstDate) {
        let disableAfter = new Date(this.firstDate)

        disableAfter = disableAfter.setDate(disableAfter.getDate() + 7)

        const start = claimedDates.reduce((acc, d) => {
          return createDate(d.start).isBefore(acc) &&
            createDate(d.start).isAfter(this.firstDate)
            ? d.start
            : acc
        }, disableAfter)

        dates.push({
          start,
        })

        let disabledBefore = new Date(this.firstDate)

        disabledBefore = disabledBefore.setDate(disabledBefore.getDate() - 7)

        const end = claimedDates.reduce((acc, d) => {
          return createDate(d.end).isAfter(acc) &&
            createDate(d.start).isBefore(this.firstDate)
            ? d.start
            : acc
        }, disabledBefore)

        dates.push({
          end,
        })
      }

      return [...dates, ...claimedDates]
    },
  },
  methods: {
    dayclick(date) {
      if (this.firstDate) return

      const now = new Date()

      now.setHours(0, 0, 0, 0)

      if (createDate(date.id).isBefore(now)) return

      const disabled = this.disabledDates.reduce((acc, d) => {
        if (
          createDate(date.id).isSameOrAfter(d.start) &&
          createDate(date.id).isSameOrBefore(d.end)
        )
          return true
        else return acc
      }, false)

      if (disabled) return

      this.firstDate = date.id
    },
    input($event) {
      this.firstDate = null
      this.$emit('input', $event)
    },
    getValue({ start, end }) {
      return start ? `${start} - ${end || start}` : null
    },
    popoverDidHide() {
      this.firstDate = null
    },
  },
}
</script>

<template>
  <div ref="picker" class="relative">
    <v-date-picker
      mode="date"
      v-bind="$attrs"
      color="blue"
      is-range
      :disabled-dates="disabledDates"
      @dayclick="dayclick"
      @input="input"
      @popoverDidHide="popoverDidHide"
    >
      <template #default="{ inputValue, inputEvents }">
        <div class="relative" v-on="inputEvents.start">
          <base-icon
            class="absolute left-0 ml-[35px] mt-5 cursor-pointer sm:ml-[30px] sm:mt-2.5"
            :size="25"
            svg="plain/calendar2"
          />
          <div
            class="flex h-16 w-full cursor-pointer items-center rounded-md bg-white px-2.5 pl-[72px] sm:h-11"
          >
            <template v-if="getValue(inputValue)">
              {{ getValue(inputValue) }}
            </template>
            <span v-else class="text-neutral-400">Enter Date</span>
          </div>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>
