<script setup lang="ts">
import ui from '/~/core/ui'
import { useCheckoutReactive } from '/~/composables/checkout'
import SummaryPayWithItem from './summary-pay-with-item.vue'

const { payment } = useCheckoutReactive()
</script>

<template>
  <div
    v-if="
      payment.selectedCoupons ||
      payment.selectedPayId ||
      payment.selectedCard ||
      payment.selectedPoints ||
      payment.selectedBankAccount
    "
  >
    <hr v-if="!ui.mobile" class="my-5 h-px w-full bg-gray-50" />
    <div class="pb-2 font-bold leading-7">Paying with</div>
    <div
      class="mt-[5px] space-y-3 rounded-md sm:mt-3 sm:mb-5 sm:rounded-none sm:bg-transparent"
    >
      <summary-pay-with-item
        v-if="payment.selectedPayId && payment.payWithPayId"
        :method="payment.selectedPayId"
      />

      <summary-pay-with-item
        v-if="payment.selectedPoints"
        :method="payment.selectedPoints"
      />

      <summary-pay-with-item
        v-if="payment.selectedCard && payment.payWithCard"
        :method="payment.selectedCard"
      />

      <summary-pay-with-item
        v-if="payment.selectedBankAccount && payment.payWithBankAccount"
        :method="payment.selectedBankAccount"
      />

      <div v-if="payment.selectedCoupons.length > 0 && payment.payWithCoupons">
        <template v-for="(coupon, index) in payment.selectedCoupons">
          <summary-pay-with-item :key="index" :method="coupon" />
        </template>
      </div>
    </div>
  </div>
</template>
