import { ref } from 'vue'
import { getDefaultIndex } from '/~/core/algolia'
import api from '/~/core/api'
import HealthServicesProcessor from '/~/extensions/health/core/services-processor'
import Storage from '/~/utils/localStorage'
import { useUser } from '/~/composables/user'

const { user } = useUser()

const feed = ref(null)
const indexes = ref([])
const saved = ref(
  Storage.get(`${user.value.externalId}/saved_heath_service`) || []
)
const queryState = ref(null)

async function initServices() {
  if (!feed.value) {
    const response = await api.get(
      '//api.prod.v1.community-hub.eonx.com/providers/260d8c66-777d-441c-a1ab-82cd46c231d3',
      {
        headers: {
          Authorization: 'Basic Ym53Zmw4ZjY0b3Q3bWtudg==',
        },
      }
    )

    if (response) {
      indexes.value = response.indexes
      const config = {
        id: response.id,
        index: getDefaultIndex({ indexes: response.indexes }),
        algolia_app_id: response.search_app_id,
        algolia_search_key: response.search_api_key,
      }

      feed.value = new HealthServicesProcessor(config)
    }
  }
}

function saveService(serviceId) {
  const array = [...saved.value, serviceId]

  Storage.set(
    `${user.value.externalId}/saved_heath_service`,
    JSON.stringify(array)
  )
  saved.value = array
}

function removeService(serviceId) {
  const array = saved.value.filter((id) => id !== serviceId)

  Storage.set(
    `${user.value.externalId}/saved_heath_service`,
    JSON.stringify(array)
  )
  saved.value = array
}

function isBookmarked(id) {
  return saved.value.includes(id)
}

function toggleBookmark(id) {
  if (isBookmarked(id)) {
    removeService(id)
  } else {
    saveService(id)
  }
}

export function useServices() {
  return {
    feed,
    saved,
    indexes,
    queryState,

    isBookmarked,
    toggleBookmark,
    initServices,
  }
}
