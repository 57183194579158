<script>
import { useCompetitions } from '/~/extensions/competitions/composables'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import CompetitionTile from './competitions-tile.vue'

export default {
  name: 'competitions-items',
  components: {
    EwCatalogProcessor,
    CompetitionTile,
  },
  setup() {
    const { processor } = useCompetitions()

    return {
      processor,
    }
  },
}
</script>

<template>
  <ew-catalog-processor
    :processor="processor"
    :columns="{
      xs: 2,
      sm: 3,
      lg: 4,
    }"
    tile-class="sm:hover:-translate-y-[5px]"
  >
    <template #empty>
      <p>No Result found</p>
    </template>

    <template #tile="{ tile }">
      <competition-tile :tile="tile" />
    </template>
  </ew-catalog-processor>
</template>
