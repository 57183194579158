<script>
import ui from '/~/core/ui'
import EstoreFiltersDesktop from '/~/extensions/estore/components/estore-filters.desktop.vue'
import EstoreFiltersMobile from '/~/extensions/estore/components/estore-filters.mobile.vue'
import { useEditablePage } from '/~/composables/cms'
import EstoreCatalog from './estore-catalog.vue'

export default {
  name: 'estore',
  components: {
    EstoreCatalog,
    EstoreFiltersDesktop,
    EstoreFiltersMobile,
  },
  setup() {
    const { page } = useEditablePage()

    return {
      ui,
      page,
    }
  },
}
</script>

<template>
  <div class="mx-auto w-full max-w-7xl sm:mt-0">
    <div v-if="!ui.mobile" class="px-5 pt-2 pb-3">
      <estore-filters-desktop />
    </div>
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
    <div class="p-2.5 sm:p-5">
      <estore-filters-mobile v-if="ui.mobile" />
      <estore-catalog />
    </div>
  </div>
</template>
