<script>
import { computed, defineComponent } from 'vue'
import modal from '/~/core/mdl'

export default defineComponent({
  name: 'community-hub-retailer-offer',
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const isYoutubeVideo = computed(() =>
      ['youtube.com', 'youtu.be'].some((domain) => props.url.includes(domain))
    )
    const formattedOfferVideoUrl = computed(() => {
      if (!props.url) return null
      else if (isYoutubeVideo.value) {
        let videoId = ''
        const url = new URL(props.url)

        if (url.pathname.includes('watch')) {
          videoId = url.search.replace('?v=', '')
        } else {
          videoId = url.pathname.replace('/', '')
        }
        return `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&autoplay=0${
          props.preview ? 'controls=2' : ''
        }`
      } else {
        return props.url
      }
    })
    const sizeAttrs = computed(() => {
      return props.preview
        ? {
            width: 300,
            height: 200,
          }
        : {
            class: 'w-full',
            height: isYoutubeVideo.value ? window.innerHeight * 0.75 : '',
          }
    })

    function openModal() {
      modal.show('community-hub-offer-video-play', {
        props: {
          url: props.url,
        },
      })
    }

    return {
      formattedOfferVideoUrl,
      isYoutubeVideo,
      openModal,
      sizeAttrs,
    }
  },
})
</script>

<template>
  <div
    v-if="formattedOfferVideoUrl"
    class="pointer-events-auto flex justify-center"
  >
    <div
      class="relative overflow-hidden rounded-md bg-gray-50"
      :class="{
        'flex-grow': !preview,
      }"
    >
      <iframe
        v-if="isYoutubeVideo"
        v-bind="sizeAttrs"
        :src="formattedOfferVideoUrl"
        frameBorder="0"
        allowFullScreen
        title="Stream"
      />
      <video v-else v-bind="sizeAttrs" preload="auto" controls>
        <source
          :src="formattedOfferVideoUrl"
          type="video/mp4"
          codecs="avc1.42E01E, mp4a.40.2"
        />
      </video>
      <div
        v-if="preview"
        class="absolute inset-0 z-1 cursor-pointer"
        @click="openModal"
      />
    </div>
  </div>
</template>
