<script>
import DrawerEPurchasesArchived from '/~/components/drawer/views/e-purchases/drawer-e-purchases-archived.vue'
import EpurchaseTile from '/~/components/drawer/components/tiles/drawer-e-purchases-tile.v2.vue'

export default {
  name: 'epurchases-archived',
  components: {
    EpurchaseTile,
  },
  extends: DrawerEPurchasesArchived,
}
</script>
