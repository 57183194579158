<script setup lang="ts">
import Vue, { computed, onMounted, ref } from 'vue'
import { jwt } from '/~/core/api'
import { useThirdPartyLogin } from '/~/extensions/third-party-login/composables/use-third-party-login'
import LayoutMain from '/~/extensions/third-party-login/layouts/layout-main.vue'
import BaseButton from '/~/components/base/button/base-button'
import { useAuth } from '/~/composables/auth'
import { useCms } from '/~/composables/cms'

const { isLoggingOut, logout } = useAuth()
const { terms } = useCms()
const {
  thirdPartyOwners,
  fetchThirdPartyOwners,
  fetchThirdPartyOwnerAccessToken,
  acceptThirdPartyEngagementAgreement,
} = useThirdPartyLogin()

const agreementTextRef = ref<HTMLElement | null>(null)
const isAgreeButtonEnabled = ref(false)

const defaultStrategyJwtToken = jwt.token
const isGeneratingAccessToken = ref(false)

const isProcessing = computed(
  () => isLoggingOut.value || isGeneratingAccessToken.value
)

if (thirdPartyOwners.value.length === 0) {
  fetchThirdPartyOwners()
}

async function onAgree() {
  isGeneratingAccessToken.value = true

  try {
    await acceptThirdPartyEngagementAgreement()
    await fetchThirdPartyOwnerAccessToken(
      defaultStrategyJwtToken ?? '',
      thirdPartyOwners.value[0].owner?.externalId ?? ''
    )
  } catch (error) {
    Vue.notify({
      type: 'error',
      text: 'Failed to login',
    })

    isGeneratingAccessToken.value = false
  }
}

onMounted(() => {
  ;(agreementTextRef.value as HTMLElement).addEventListener(
    'scroll',
    (event) => {
      const target = event.target as HTMLElement

      isAgreeButtonEnabled.value =
        Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight
    }
  )
})
</script>

<template>
  <layout-main>
    <div class="overflow-hidden p-6">
      <div class="m-auto flex h-full max-w-2xl flex-col border bg-white">
        <div class="border-b px-5 py-3">
          <h2 class="text-center">Authorised User Agreement</h2>
        </div>
        <div ref="agreementTextRef" class="flex-grow overflow-auto">
          <div class="markup text-gray flex-grow px-5 py-5" v-html="terms" />
        </div>
        <div class="mt-5 border-t p-5">
          <div class="grid gap-3 sm:grid-cols-2">
            <base-button
              look="outlined-color"
              :loading="isLoggingOut"
              :disabled="isProcessing"
              class="!rounded"
              @click="logout"
            >
              Logout
            </base-button>
            <base-button
              :loading="isGeneratingAccessToken"
              :disabled="isProcessing || !isAgreeButtonEnabled"
              class="!rounded"
              @click="onAgree"
            >
              I agree
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </layout-main>
</template>
