import get from 'lodash-es/get'
import { computed, reactive } from 'vue'
import { ApiResponseData } from '/~/types/api'
import api from '/~/core/api'

interface DailyEntry {
  day: string
  ordersSubtotal: string
  paymentOrdersSubtotal: string | null
  statementOrdersSubtotal: string | null
  batchOrdersSubtotal: string | null
  pointsBalance: string | null
}

interface MonthlyEntry {
  month: string
  ordersSubtotal: string
  paymentOrdersSubtotal: string
  statementOrdersSubtotal: string | null
  batchOrdersSubtotal: string | null
  pointsBalance: string
}

interface UserStats {
  daily?: DailyEntry[]
  monthly?: MonthlyEntry[]
  totals: typeof DEFAULT_TOTAL_STATS
}

const DEFAULT_FILTER_OPTION = '30d'
const DEFAULT_TOTAL_STATS = {
  ordersSubtotal: '0',
  ordersCount: '0',
  pointsEarned: '0',
  pointsBurned: '0',
}

const state = reactive<{
  total: typeof DEFAULT_TOTAL_STATS
  filter: string
  stats: DailyEntry[] | MonthlyEntry[]
  fetching: boolean
  inited: boolean
}>({
  total: DEFAULT_TOTAL_STATS,
  filter: DEFAULT_FILTER_OPTION,
  stats: [],
  fetching: false,
  inited: false,
})

const statsLoading = computed(() => state.fetching)
const statsTotal = computed(() => state.total)
const stats = computed(() => state.stats)
const statsFilter = computed(() => state.filter)
const statsFormat = computed(() =>
  statsFilter.value === 'all' ? 'monthly' : 'daily'
)

function initFilter(defaultFilter?: string) {
  if (!state.inited) {
    state.filter = defaultFilter || DEFAULT_FILTER_OPTION
    state.inited = true
  }
}

const fetchUserStats = async () => {
  try {
    state.fetching = true

    await api
      .get<ApiResponseData<UserStats>>(
        `/v3/user-stats/?filter=${statsFilter.value}&format=${statsFormat.value}`
      )
      .then(({ data }) => {
        state.total = get(data, 'totals', DEFAULT_TOTAL_STATS)
        state.stats = get(data, statsFormat.value, [])
      })
  } catch (error: any) {
    console.error((error.data && error.data.message) || error)
  } finally {
    state.fetching = false
  }
}

const updateUserStats = (filter = DEFAULT_FILTER_OPTION) => {
  state.filter = filter
  fetchUserStats()
}

export const useUserStats = () => ({
  statsLoading,
  statsTotal,
  stats,
  statsFilter,
  statsFormat,
  fetchUserStats,
  updateUserStats,
  initFilter,
})
