import get from 'lodash-es/get'
import Vue from 'vue'
import ui from '/~/core/ui'
import { slug } from '/~/utils/slug'

interface Utils {
  [key: string]: (...args: any[]) => any
}

const utils: Utils = {}
const envMap: Map<string, string> = new Map()

envMap.set('development', 'dev')
envMap.set('production', 'prod')
envMap.set('test', 'dev')

const env: string | undefined = envMap.get(eonx.env?.toString() ?? '')

function register(name: string, func: any): void {
  Vue.filter(name, func)
  utils[name] = func
}

register('get', get)
register('ui', ui)
register('slug', slug)

export { env }

export default utils
