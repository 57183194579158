<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import MediaGalleryNavigation from './gallery-navigation.vue'
import MediaGalleryThumbnails from './gallery-thumbnails.vue'
import extractVideoThumbnail from './extract-video-thumbnail'
import MediaGalleryZoombar from './gallery-zoombar.vue'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'base-media-gallery',
  components: {
    MediaGalleryNavigation,
    MediaGalleryThumbnails,
    MediaGalleryZoombar,
    BaseIcon,
    BaseMdl,
    BaseLoader,
  },
  props: {
    /**
     * SOURCES
     * Accepted parameters are
     * - cdn (uploadcare image or video url)
     * - type
     gallery: [
      {
        cdn: "https://ucarecdn.com/e9f2e9b2-1abe-426d-89bf-9ba68f350691/",
        type: 'image',
      },
      {
        cdn: "https://ucarecdn.com/2114fa13-a6ee-48ff-8142-150b22f5c558/",
        type: 'video',
      },
      ]
    */
    sources: {
      type: Array,
      default: () => [],
    },
    initialItemIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeItemIndex: -1,
      activeItem: {},
      preloadItem: {},
      loading: false,
      imageKey: 'initial',
      // Zoom feature
      defaultImageWidth: 0,
      defaultImageHeight: 0,
      activeImageNaturalWidth: 0,
      activeImageNaturalHeight: 0,
      initialZoomLevel: 1,
      minimumZoomLevel: 0.25, // 25%
      maximumZoomLevel: 2,
      currentZoomLevel: 1,
      imageWidth: 0,
      imageHeight: 0,
      touchMoveOffset: 0,
      touchStart: false,
      offset: [0, 0],
    }
  },
  computed: {
    isReady() {
      return !isEmpty(this.sources)
    },
    hasMultiSource() {
      return this.sources && this.sources.length > 1
    },
    navIconSize() {
      return ui.mobile ? 30 : 40
    },
    zoomPercentage() {
      return `${Math.round(this.currentZoomLevel * 100)}%`
    },
    dynamicImageDimension() {
      return {
        width: this.imageWidth !== 'auto' ? `${this.imageWidth}px` : 'auto',
      }
    },
    disableZoomIn() {
      return this.currentZoomLevel >= this.maximumZoomLevel
    },
    disableZoomOut() {
      return this.currentZoomLevel === this.initialZoomLevel
    },
    $threshold() {
      return 50
    },
    $touchElement() {
      return this.$refs.touchElement
    },
    $isTouchActive() {
      return this.touchMoveOffset !== 0
    },
  },
  watch: {
    currentZoomLevel(value) {
      if (this.initialZoomLevel < value) {
        this.$removeTouchEvents()
      } else {
        this.$addTouchEvents()
      }
    },
  },
  mounted() {
    if (this.isReady) {
      this.loadItem(this.initialItemIndex)
    }
    this.handleDefaultImageDimensions()

    window.addEventListener('resize', this.handleResize)
    window.addEventListener('keyup', this.handleKeys)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeys)
  },
  methods: {
    isVideo(item) {
      return item.type === 'video'
    },
    handleDefaultImageDimensions() {
      this.defaultImageWidth =
        ui.mobile || this.$isTablet
          ? window.innerWidth
          : Math.min(1000, window.innerWidth - 150)
      this.defaultImageHeight =
        ui.mobile || this.$isTablet
          ? window.innerHeight - 150
          : window.innerHeight - 300
    },
    loadItem(currentItemIndex) {
      if (this.activeItemIndex === currentItemIndex) return

      this.loading = true
      this.preloadItem = this.sources[currentItemIndex]
      this.activeItemIndex = currentItemIndex

      if (this.isVideo(this.preloadItem)) {
        this.makeItemActive(currentItemIndex)
      }
    },
    makeItemActive() {
      this.activeItem = this.sources[this.activeItemIndex]
      this.imageKey = `loaded-${this.activeItem.source}`
      this.loading = false
      this.initZoom()
      this.submitView(this.activeItem)
    },
    async submitView(attachment) {
      /**
       * When the attachment has earn_config setup
       * this call is require to register earn for user
       */
      if (!attachment.postHasEarn) return
      await attachment.submitView()
    },
    initZoom() {
      if (!isNil(this.$refs?.preloadImage)) {
        const image = this.$refs.preloadImage

        // Get the natural dimension of active image
        this.activeImageNaturalWidth = image.naturalWidth
        this.activeImageNaturalHeight = image.naturalHeight

        // Get image dimension ration based on defalut/viewport dimension
        const heightRatio =
          this.defaultImageHeight / this.activeImageNaturalHeight
        const widthRatio = this.defaultImageWidth / this.activeImageNaturalWidth

        // Always use the minimun ratio so that image will appear full on initial
        this.currentZoomLevel = Math.min(heightRatio, widthRatio, 1)

        this.initialZoomLevel = this.currentZoomLevel
        this.resizeImage(this.currentZoomLevel)
      }
    },
    zoomIn() {
      this.currentZoomLevel = this.maintainZoomLevel(
        this.currentZoomLevel + 0.05
      )
      this.resizeImage(this.currentZoomLevel)
    },
    zoomOut() {
      // Always maintain the minimum zoom level
      // unless the initial zoom level is smaller than minimum zoom level.
      if (
        this.currentZoomLevel - this.initialZoomLevel >=
        this.initialZoomLevel
      ) {
        this.currentZoomLevel = this.maintainZoomLevel(
          this.currentZoomLevel - this.initialZoomLevel
        )
      } else {
        this.currentZoomLevel = this.initialZoomLevel
      }
      this.resizeImage(this.currentZoomLevel)
    },
    zoomReset() {
      this.currentZoomLevel = this.initialZoomLevel
      this.resizeImage(this.currentZoomLevel)
    },
    maintainZoomLevel(currentLevel) {
      // Always maintain the zoom level by multily of 25
      return Math.ceil(currentLevel / 0.25) * 0.25
    },
    resizeImage(zoomLevel) {
      this.imageWidth = Math.round(this.activeImageNaturalWidth * zoomLevel)
    },
    onImagePreloaded() {
      this.makeItemActive()
    },
    compressImage(source = '') {
      if (source.match('https://ucarecdn')) {
        return `${source}-/preview/2048x2048`
      } else {
        return source
      }
    },
    next() {
      let newIndex = null

      if (this.activeItemIndex < this.sources.length - 1) {
        newIndex = this.activeItemIndex + 1
      } else {
        newIndex = 0
      }
      this.loadItem(newIndex)
    },
    previous() {
      let newIndex = null

      if (this.activeItemIndex > 0) {
        newIndex = this.activeItemIndex - 1
      } else {
        newIndex = this.sources.length - 1
      }
      this.loadItem(newIndex)
    },
    videoPoster(url) {
      return extractVideoThumbnail(url, '900x600')
    },
    handleResize() {
      this.handleDefaultImageDimensions()
      this.initZoom()
    },
    handleKeys(event) {
      if (event.keyCode === 39) {
        this.next()
      } else if (event.keyCode === 37) {
        this.previous()
      }
    },
    emitClose() {
      this.$emit('close')
      modal.hide()
    },
    closed() {
      this.activeItemIndex = 0
      this.activeItem = this.sources[0]
      this.preloadItem = {}
      this.loading = false
      this.activeItemIndex = -1
    },
    opened(e) {
      this.loadItem(this.initialItemIndex)
      this.$emit('opened', e)
      setTimeout(() => {
        this.$addTouchEvents()
      }, 1000)
    },
    clickAway() {
      this.emitClose()
    },
    $addTouchEvents() {
      if (!this.hasMultiSource && !this.$touchElement) return
      this.$touchElement.addEventListener(
        'touchstart',
        this.$onTouchStart,
        true
      )
      this.$touchElement.addEventListener('touchmove', this.$onTouchMove, true)
      this.$touchElement.addEventListener('touchend', this.$onTouchEnd, true)
    },
    $removeTouchEvents() {
      if (!this.$touchElement) return
      this.$touchElement.removeEventListener(
        'touchstart',
        this.$onTouchStart,
        true
      )
      this.$touchElement.removeEventListener(
        'touchmove',
        this.$onTouchMove,
        true
      )
      this.$touchElement.removeEventListener('touchend', this.$onTouchEnd, true)
    },
    $onTouchMove(e) {
      e.preventDefault()
      this.touchMoveOffset = Math.ceil(
        e.targetTouches[0].clientX + this.offset[0]
      )

      if (this.touchStart) {
        this.$touchElement.style.cssText = `transform: translateX(${this.touchMoveOffset}px)`
      }
    },
    $onTouchStart(e) {
      e.preventDefault()
      this.touchStart = true
      this.offset = [this.$touchElement.offsetLeft - e.targetTouches[0].clientX]
    },
    $onTouchEnd(e) {
      e.preventDefault()
      if (this.touchMoveOffset > this.$threshold) {
        this.$touchElement.style.cssText = 'transform: translateX(-100%)'
        // Trigger previous
        this.previous()
      }
      if (this.touchMoveOffset < -this.$threshold) {
        this.$touchElement.style.cssText = 'transform: translateX(100%)'
        // Trigger next
        this.next()
      }
      setTimeout(() => {
        this.$resetMediaPosition()
      }, 100)
      this.touchMoveOffset = 0
      this.touchStart = false
    },
    $resetMediaPosition() {
      if (!isNil(this.$touchElement)) {
        this.$touchElement.style.cssText = 'transform: translateX(0)'
      }
    },
  },
}
</script>

<template>
  <base-mdl
    ref="galleryModal"
    fullscreen
    transparent
    width="screen"
    :layout="false"
    transition="fade"
  >
    <template v-if="isReady">
      <button
        class="absolute top-0 right-0 z-20 mt-[15px] mr-[15px] flex h-[30px] w-[30px] items-center justify-center text-white md:mt-[30px] md:mr-6"
        @click="emitClose"
      >
        <base-icon svg="rec/rec-slim-cross" size="md" />
      </button>
      <div class="relative h-full w-full">
        <div
          ref="preview"
          class="relative flex h-full max-w-full items-center overflow-visible"
        >
          <div class="absolute inset-0 z-0" @click="clickAway" />
          <base-loader v-if="loading" class="absolute inset-0" />
          <div
            ref="touchElement"
            class="relative z-1 m-auto flex w-auto items-center transition duration-300 ease-out"
          >
            <div
              class="m-auto max-w-full overflow-auto transition-opacity delay-300 duration-200 ease-in"
              :class="[loading && 'opacity-0']"
            >
              <img
                v-if="!isVideo(activeItem)"
                ref="activeImage"
                :key="imageKey"
                :src="compressImage(activeItem.cdn)"
                class="max-w-auto block"
                :style="dynamicImageDimension"
              />
              <video
                v-else
                ref="video"
                :key="`v_${activeItem.cdn}`"
                controls
                width="100%"
                autoplay
                :poster="videoPoster(activeItem.cdn)"
                class="block w-md max-w-full bg-black"
              >
                <source :src="activeItem.cdn" type="video/mp4" />
                <source :src="activeItem.cdn" type="video/webm" />
                <source :src="activeItem.cdn" type="video/ogg" />
              </video>
            </div>
          </div>
          <img
            ref="preloadImage"
            :key="`preload-${activeItemIndex}`"
            :src="compressImage(preloadItem.cdn)"
            class="z-back absolute inset-0 hidden"
            :class="['preloadItem']"
            @load="onImagePreloaded"
          />
        </div>
        <transition name="fade">
          <media-gallery-zoombar
            v-if="!isVideo(activeItem)"
            :view-percentage="zoomPercentage"
            :disable-zoom-in="disableZoomIn"
            :disable-zoom-out="disableZoomOut"
            @on-zoom-in="zoomIn()"
            @on-zoom-out="zoomOut()"
            @on-zoom-reset="zoomReset()"
          />
        </transition>
        <media-gallery-navigation
          v-if="hasMultiSource"
          :icon-size="navIconSize"
          @on-handle-previous="previous"
          @on-handle-next="next"
        />
      </div>
      <media-gallery-thumbnails
        v-if="hasMultiSource"
        :sources="sources"
        :active-item-index="activeItemIndex"
        @active-item="loadItem"
      />
    </template>
    <div
      v-else
      class="m-auto flex h-32 w-xs max-w-full items-center justify-center bg-black text-center text-white"
    >
      No image or video source available.
    </div>
  </base-mdl>
</template>
