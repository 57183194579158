<script setup lang="ts">
import CashbackPanel from '/~/components/cashback/cashback-panel.vue'
import PointsPanel from '/~/components/points/points-panel.v2.vue'
import { useCheckoutReactive } from '/~/composables/checkout'

const { payment } = useCheckoutReactive()
</script>

<template>
  <div class="space-x-4 space-y-4 sm:flex sm:space-y-0">
    <points-panel
      v-if="payment.isOrderPoints"
      :total="payment.pointsEarned"
      class="rounded-lg"
    />
    <cashback-panel :total="100" class="rounded-lg" />
  </div>
</template>
