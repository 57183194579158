<script>
import { formatDollar } from '/~/utils/format/money'

export default {
  name: 'checkout-summary-total',
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    loadingFees: {
      type: Boolean,
      default: false,
    },
    transactionFees: {
      type: Number,
      default: 0,
    },
    shippingFees: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {
      formatDollar,
    }
  },
}
</script>

<template>
  <div class="grid grid-cols-2 leading-tight">
    <template v-if="amount > 0">
      <span class="mb-2.5 font-bold">Amount</span>
      <span class="text-right font-bold">
        {{ formatDollar(amount) }}
      </span>
    </template>
    <template v-if="transactionFees > 0">
      <span class="mb-2.5 text-eonx-neutral-600">Payment Processing Fee</span>
      <span
        v-if="loadingFees"
        class="mb-2.5 ml-auto h-5 w-12 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
      >
        loading
      </span>
      <span v-else class="text-right text-eonx-neutral-600">
        {{ formatDollar(transactionFees) }}
      </span>
    </template>

    <template v-if="shippingFees > 0">
      <span class="mb-2.5 text-eonx-neutral-600">Shipping Fee</span>

      <span class="text-right text-eonx-neutral-600">
        {{ formatDollar(shippingFees) }}
      </span>
    </template>

    <span class="text-2xl font-bold">Total</span>
    <span class="text-right text-2xl font-bold">
      {{ formatDollar(total) }}
    </span>
  </div>
</template>
