<script>
import ui from '/~/core/ui'

export default {
  name: 'base-placeholder',
  props: {
    type: {
      type: String,
      default: 'block',
      validator: (value) => /block|text|fill/.test(value),
    },
    columns: {
      type: [String, Number, Object],
      default: 1,
    },
    rows: {
      type: [String, Number],
      default: 3,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: 0,
    },
    // Themes can be 'light', 'dark'
    theme: {
      type: String,
      default: 'light',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    roundedFull: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    isColumnsObject() {
      return typeof this.columns === 'object'
    },
    heightStyle() {
      if (!this.height) return null
      return String(this.height).includes('%')
        ? this.height
        : this.height + 'px'
    },
    realHeightStyle() {
      if (!this.isColumnsObject) return null
      return String(this.height).includes('%')
        ? this.height
        : (Number(this.height) ? Number(this.height) + 20 : 148) + 'px'
    },
    parts() {
      if (this.type === 'text') {
        return Number(this.rows)
      }

      return this.isColumnsObject
        ? ui.getByScreenSize({ base: 1, ...this.columns })
        : Number(this.columns)
    },
    postfix() {
      return this.type === 'fill' ? 'stretchy' : this.type
    },
  },
}
</script>

<template>
  <div
    class="overflow-hidden"
    :class="{
      '-m-2.5': postfix === 'block' || postfix === 'text',
      'h-full': postfix === 'stretchy',
    }"
  >
    <div
      class="mx-0"
      :class="{
        'h-full': postfix === 'stretchy',
        flex: postfix === 'block',
        'p-2.5': postfix === 'text',
        'flex-wrap overflow-hidden': isColumnsObject,
      }"
      :style="{
        height: realHeightStyle,
      }"
    >
      <div
        v-for="(i, idx) in parts"
        :key="idx"
        class="flex justify-center"
        :class="{
          'h-full': postfix === 'stretchy',
          'box-border p-2.5': postfix === 'block',
          'w-full': postfix === 'block' && !isColumnsObject,
          'mb-2.5 last:mb-0': postfix === 'text',
          'flex-1': isColumnsObject,
        }"
      >
        <div
          class="relative overflow-hidden"
          :class="{
            'h-full': postfix === 'stretchy',
            'h-32': postfix === 'block',
            'h-4': postfix === 'text',
            'bg-dark': theme === 'dark',
            'bg-light': theme === 'light',
            'rounded-md': rounded,
            'rounded-full': roundedFull,
          }"
          :style="{
            width: width && !isColumnsObject ? width + 'px' : '100%',
            height: heightStyle,
          }"
        >
          <div
            class="absolute h-full w-full animate-placeholder-slide bg-gradient-to-r"
            :class="{
              'top-0 left-0 from-transparent via-white to-zinc-100/25':
                theme === 'light',
              'from-transparent via-transparent/5 to-transparent':
                theme === 'dark',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
