<script>
import DefaultCheckout from '/~/views/checkout/checkout.vue'
import CheckoutPay from '/~/templates/australiapost/views/checkout/components/pay/checkout-pay.vue'
import CheckoutSummary from '/~/templates/australiapost/views/checkout/components/summary/checkout-summary.vue'

export default {
  components: {
    CheckoutPay,
    CheckoutSummary,
  },
  extends: DefaultCheckout,
}
</script>
