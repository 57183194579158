<script>
import { computed } from 'vue'
import ui from '/~/core/ui'
import { useUI } from '/~/composables/ui'
import { useRules } from '/~/configurator/composables/use-rules.ts'
import { widgetsList } from '/~/configurator/schemes/widgets'

export default {
  name: 'widgets-builder',
  components: {
    WidgetAustraliapostBanner: () =>
      import('./widget-australiapost-banner.vue'),
    WidgetAustraliapostHomeBanner: () =>
      import('./widget-australiapost-home-banner.vue'),
    WidgetAustraliapostWelcomeBanner: () =>
      import('./widget-australiapost-welcome-banner.vue'),
    WidgetAustraliapostSlider: () =>
      import('./australiapost-slider/widget-australiapost-slider.vue'),
    WidgetBillpayHomeRightBanner: () =>
      import('./widget-billpay-home-right-banner.vue'),
    WidgetBillpayHomeRightBannerB2b: () =>
      import('./widget-billpay-home-right-banner-b2b.vue'),
    WidgetBillpayHomeRightBannerV3: () =>
      import('./widget-billpay-home-right-banner-v3.vue'),
    WidgetBupaHomeBannerSlider: () =>
      import('./bupa-home-banner-slider/bupa-home-banner-slider.vue'),
    WidgetBupaTiles: () => import('./bupa-tiles/widget-bupa-tiles.vue'),
    WidgetBupaMembershipLevel: () =>
      import('./bupa-membership-level/widget-bupa-membership-level.vue'),
    WidgetBupaSplitBannerSlider: () =>
      import('./bupa-split-banner-slider/bupa-split-banner-slider.vue'),
    WidgetBupaDiscountsTiles: () =>
      import('./bupa-discounts-tiles/widget-bupa-discounts-tiles.vue'),
    WidgetBupaCatalogueBanner: () =>
      import('./bupa-catalogue-banner/bupa-catalogue-banner.vue'),
    WidgetBupaCommunityHubOfferGroups: () =>
      import(
        './bupa-community-hub-offer-groups/bupa-community-hub-offer-groups.vue'
      ),
    WidgetEpworthBanner: () => import('./widget-epworth-banner.vue'),
    WidgetEpworthHomeBanner: () => import('./widget-epworth-home-banner.vue'),
    WidgetHealthSlider: () =>
      import('./health-slider/widget-health-slider.vue'),
    WidgetHeroBanner: () => import('./widget-hero-banner.vue'),
    WidgetHomeBanner: () => import('./widget-home-banner.vue'),
    WidgetCode: () => import('./widget-code.vue'),
    WidgetSdaHomeBanner: () => import('./widget-sda-home-banner.vue'),
    WidgetRacgpBanner: () => import('./widget-racgp-banner.vue'),
    WidgetRacqHomeBanner: () => import('./widget-racq-home-banner.vue'),
    WidgetFullscreenBanner: () => import('./widget-fullscreen-banner.vue'),
    WidgetSlider: () => import('./slider/widget-slider.vue'),
    WidgetImage: () => import('./widget-image.vue'),
    WidgetImages: () => import('./widget-images.vue'),
    WidgetSpecialOffersSlider: () =>
      import('./special-offers-slider/widget-special-offers-slider.vue'),
    WidgetOptusHomeBanner: () => import('./widget-optus-home-banner.vue'),
    WidgetOptusV2HomeBanner: () => import('./widget-optus-v2-home-banner.vue'),
    WidgetOptusV2Tiles: () =>
      import('./optus-v2-tiles/widget-optus-v2-tiles.vue'),
    WidgetPaymentsOverview: () => import('./widget-payments-overview.vue'),
    WidgetGreetings: () => import('./widget-greetings.vue'),
    WidgetPopular: () => import('./widget-popular/widget-popular.vue'),
    WidgetStaffHomeBanner: () => import('./widget-staff-home-banner.vue'),
    WidgetText: () => import('./widget-text.vue'),
    WidgetContent: () => import('./widget-content.vue'),
    WidgetTileImage: () => import('./widget-tile-image.vue'),
    WidgetTilesSda: () => import('./tiles-sda/widget-tiles-sda.vue'),
    WidgetTilesEpworth: () =>
      import('./tiles-epworth/widget-tiles-epworth.vue'),
    WidgetTilesHemisphere: () =>
      import('./tiles-hemisphere/widget-tiles-hemisphere.vue'),
    WidgetTilesOptus: () => import('./tiles-optus/widget-tiles-optus.vue'),
    WidgetTilesShort: () => import('./widget-tiles-short.vue'),
    WidgetTilesStaff: () => import('./tiles-staff/widget-tiles-staff.vue'),
    WidgetTilesNew: () => import('./tiles-new/widget-tiles-new.vue'),
    WidgetTiles: () => import('./widget-tiles.vue'),
    WidgetValuedbenefitsHomeBanner: () =>
      import('./widget-valuedbenefits-home-banner.vue'),
    WidgetEwalletDivider: () => import('./ewallet/widget-ewallet-divider.vue'),
    WidgetEwalletEpurchases: () =>
      import('./ewallet/widget-ewallet-epurchases.vue'),
    WidgetEwalletHeader: () => import('./ewallet/widget-ewallet-header.vue'),
    WidgetEwalletImage: () => import('./ewallet/widget-ewallet-image.vue'),
    WidgetEwalletPointsAndTier: () =>
      import('./ewallet/widget-ewallet-points-and-tier.vue'),
    WidgetEwalletProfile: () => import('./ewallet/widget-ewallet-profile.vue'),
    WidgetEwalletServices: () =>
      import('./ewallet/widget-ewallet-services.vue'),
    WidgetEwalletShortcuts: () =>
      import('./ewallet/widget-ewallet-shortcuts.vue'),
    WidgetEwalletTile: () => import('./ewallet/widget-ewallet-tile.vue'),
    WidgetNewsfeed: () =>
      import('/~/configurator/components/widgets/recroom/post-box-list.vue'),
    WidgetHeaderLogo: () => import('./header/widget-header-logo.vue'),
    WidgetHeaderMenu: () => import('./header/widget-header-menu.vue'),
    WidgetHeaderMobileMenuButton: () =>
      import('./header/widget-header-mobile-menu-button.vue'),
    WidgetHeaderTierAndPoints: () =>
      import('./header/widget-header-tier-and-points.vue'),
    WidgetHeaderTierAndBalance: () =>
      import(
        '/~/configurator/components/widgets/header/widget-header-tier-and-balance.vue'
      ),
    WidgetStatementsNotifyPanel: () =>
      import('./widget-statements-notify-panel.vue'),
    WidgetUnverifiedNotification: () =>
      import('./widget-unverified-notification.vue'),
    WidgetPointsBalance: () => import('./widget-points-balance.vue'),
    WidgetStatementItems: () => import('./widget-statement-items.vue'),
    WidgetButton: () => import('./widget-button.vue'),
    WidgetPayidNotifyPanel: () => import('./widget-payid-notify-panel.vue'),
  },
  props: {
    widgets: {
      type: Array,
      default: () => [],
    },
    side: {
      type: String,
      default: 'center',
      validator: (v) => /left|right|center|top|bottom/.test(v),
    },
  },
  setup(props) {
    const ruleFunctions = useRules()
    const { uiReady } = useUI()

    function handleCustomRules(customRules) {
      return customRules.every((rule) => ruleFunctions[rule]())
    }

    const filteredWidgets = computed(() => {
      if (!uiReady.value) return []

      return props.widgets
        .filter((widget) => {
          const {
            props: {
              enableWidget = true,
              showOnDesktop = true,
              showOnMobile = true,
              side = 'center',
              customRules = [],
            } = {},
          } = widget

          if (!widgetsList[widget.name]) {
            console.warn(`Unknown widget: ${widget.name}`)

            return false
          }

          if (customRules.length) {
            return handleCustomRules(customRules)
          }

          const isCorrectScreenSize =
            (showOnDesktop && !ui.mobile) || (showOnMobile && ui.mobile)

          const isCorrectSide = side === props.side

          if (isCorrectScreenSize && isCorrectSide && enableWidget) {
            return true
          }

          return false
        })
        .map((widget) => {
          if (widget.props?.items) {
            const items = widget.props.items.filter((item) => {
              if (item.customRules?.length) {
                return handleCustomRules(item.customRules)
              }
              return true
            })

            return {
              ...widget,
              props: {
                ...widget.props,
                items,
              },
            }
          }
          return widget
        })
    })

    return {
      filteredWidgets,
    }
  },
}
</script>

<template>
  <div
    v-show="filteredWidgets.length"
    id="widgets-builder"
    class="space-y-[30px] rounded-lg pb-[30px]"
  >
    <template v-for="(widget, idx) in filteredWidgets">
      <component
        :is="`widget-${widget.name}`"
        :key="widget.name + idx"
        v-bind="widget.props"
      />
    </template>
  </div>
</template>
