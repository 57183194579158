<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseUploader from '/~/components/base/uploader/base-uploader.vue'

export default {
  name: 'rec-render-banner',
  components: {
    BaseUploader,
    BaseIcon,
    BaseLoader,
    BaseImage,
  },
  model: {
    prop: 'image',
    event: 'change',
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    dimension: {
      type: Object,
      default: () => ({
        width: 400,
        height: 400,
      }),
    },
    button: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ratio: {
      type: [Number, Boolean],
      default: 1,
    },
    look: {
      type: String,
      default: '',
    },
    rounded: {
      type: String,
      default: 'rounded-full',
    },
  },
  emits: ['click'],
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    imageBindings() {
      const { width, height } = this.dimension
      const driver = this.cdn(this.image).quality('best')
      const normalLink = driver.scaleCrop(`${width}x${height}/smart`).url()
      const retinaLink = driver
        .scaleCrop(`${width * 2}x${height * 2}/smart`)
        .url()

      return {
        src: normalLink,
        srcset: `${normalLink} 1x, ${retinaLink} 2x`,
      }
    },
  },
  methods: {
    handleClick() {
      this.$refs.uploader.show()
    },
    onAddAttachment(attachment) {
      const { type, cdn } = attachment

      if (type !== 'image') return

      this.$emit('change', cdn)
    },
  },
}
</script>

<template>
  <div class="relative" @click="$emit('click', $event)">
    <base-image
      v-bind="imageBindings"
      class="h-full overflow-hidden bg-gray-300"
      :class="rounded"
      :ratio="ratio"
      :rounded="false"
    />
    <base-loader
      v-if="isLoading"
      class="absolute inset-0 z-20 h-full w-full bg-white"
    />
    <div v-else-if="button" class="absolute inset-0 flex items-end justify-end">
      <div
        class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-3 border-white bg-primary p-2.5"
        @click="handleClick"
      >
        <base-icon svg="rec/edit-pen" size="auto" class="text-white" />
      </div>
    </div>
    <base-uploader
      ref="uploader"
      :loading.sync="isLoading"
      @upload="onAddAttachment"
    />
    <slot name="overlay" />
  </div>
</template>
