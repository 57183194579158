<script>
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'rec-post-popover-actions',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { allMembers } = useGroups()

    return { allMembers }
  },
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    usersArray() {
      const total = this.entity.length
      let names = this.entity
        .map(
          (id) => this.allMembers.find((member) => member.id === id)?.fullName
        )
        .filter(Boolean)

      if (names.length > 20) {
        names = names.splice(0, 20)
        names.push(`<b>And ${total - 20} other people...</b>`)
      }

      return names
    },
  },
}
</script>

<template>
  <v-popover
    v-if="isVisible"
    trigger="hover"
    offset="0"
    placement="right"
    popover-inner-class=""
    class="flex"
    :disabled="!usersArray.length"
  >
    <div class="flex items-center">
      <slot name="button-action" />
      <b class="ml-[5px] leading-4 text-eonx-neutral-600">
        {{ count }}
      </b>
    </div>
    <template #popover>
      <div class="rounded-lg bg-gray-800 p-2.5 text-sm text-zinc-100">
        <p class="mb-[5px] font-bold">
          {{ title }}
        </p>
        <div
          v-for="(userName, idx) in usersArray"
          :key="idx"
          v-html="userName"
        />
      </div>
    </template>
  </v-popover>
</template>
