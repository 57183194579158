<script>
import DefaultDiningDetails from '/~/extensions/dining/templates/default/views/details/dining-details.vue'
import RetailerProduct from '/~/extensions/giftcards/templates/bill-payments/components/product/retailer-product.vue'

export default {
  name: 'dining-details',
  components: {
    RetailerProduct,
  },
  extends: DefaultDiningDetails,
  props: {
    tabsProps: {
      type: Object,
      default: () => ({ look: 'v4' }),
    },
  },
}
</script>

<template>
  <div class="min-h-full">
    <div class="flex min-h-full flex-col md:flex-row">
      <div class="w-full grow rounded-t-3xl md:bg-eonx-neutral-50">
        <div class="mx-auto w-full space-y-5">
          <retailer-empty v-if="empty" />
          <ew-details-layout
            v-else
            :loading="loading"
            :ready="ready"
            :title="name"
            :placeholder-ratio="1"
            data-cy="giftcard-title"
            :back="backRoute"
            data-test="container"
            class="max-w-full px-5"
          >
            <template #gallery>
              <div class="-mb-12 p-5 sm:mb-0 sm:p-0">
                <ew-gallery :gallery="gallery" :default-src="defaultImage" />
                <h3 class="mt-6 text-center sm:hidden">{{ name }}</h3>
              </div>
            </template>

            <template #details>
              <dining-info
                v-bind="detailsBindings"
                :tabs-props="tabsProps"
                data-test="details"
                class="px-5 sm:px-0"
              />
            </template>

            <template #summary>
              <retailer-product
                type="dining"
                :offer="offer"
                :retailer="retailer"
                :loading="loading"
                data-test="summary"
                buttons-fixed
              />
            </template>
          </ew-details-layout>
        </div>
      </div>
    </div>
  </div>
</template>
