<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-message-downloadable-entry',
  components: {
    BaseIcon,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    contentColor: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <a
    :href="attachment.cdn"
    :class="['flex cursor-pointer items-center', contentColor]"
  >
    <div class="relative h-10 w-10 shrink-0">
      <div
        class="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-dark"
      >
        <base-icon :svg="attachment.icon" size="md" />
      </div>
    </div>
    <div class="flex-1 truncate pl-2.5">
      <p class="truncate text-sm font-bold">
        {{ attachment.name }}
      </p>
      <div class="text-xs opacity-75">
        {{ attachment.readableSize }}
      </div>
    </div>
  </a>
</template>
