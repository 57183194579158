<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import EventForm from '/~rec/components/event/event-form.vue'
import BaseBack from '/~/components/base/back/base-back.vue'
import { computed, inject, onMounted, ref } from 'vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'rec-event-form-view',
  components: {
    BaseBack,
    EventForm,
    BaseLoader,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const headerTitle = computed(() =>
      props.event ? 'Edit event' : 'Create event'
    )
    const backRoute = computed(() =>
      props.event ? { name: 'rec-event' } : { name: 'rec-events' }
    )
    const eventLoading = inject('eventLoading', ref(false))

    onMounted(() => {
      if (!ui.desktop) {
        modal.show('rec-event-form')
      }
    })

    return {
      headerTitle,
      backRoute,
      eventLoading,
      ui,
    }
  },
  methods: {
    onSuccess(event) {
      this.$router.push({
        name: 'rec-event',
        params: {
          eventId: event.id,
        },
      })
    },
  },
}
</script>

<template>
  <div
    v-if="ui.desktop && !eventLoading"
    class="mx-auto flex h-full w-full max-w-screen-xs grow flex-col p-5 md:p-[30px]"
  >
    <div>
      <base-back :to="backRoute" class="mb-6" />
    </div>

    <div>
      <h2 class="text-eonx-neutral-800">
        {{ headerTitle }}
      </h2>

      <event-form :event="event" :is-edit="!!event" @success="onSuccess" />
    </div>
  </div>
  <div v-else class="my-auto">
    <base-loader size="xl" />
  </div>
</template>
