import modals from '/~/templates/bill-payments/modals/index'

export default {
  ...modals,
  'payments-make-ways-to-pay': () =>
    import('./modals/payments-make/payments-make-ways-to-pay.mdl.vue'),
  'payments-make-cancel-confirmation': () =>
    import('./modals/payments-make/payments-make-cancel.mdl.vue'),
  'payments-make-summary': () =>
    import('./modals/payments-make/payments-make-summary.mdl.vue'),
}
