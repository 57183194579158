<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useProvider } from '/~/composables/provider'
import DesktopFooter from '/~/layouts/main/desktop/desktop-footer.vue'
import DesktopToolbar from './desktop-toolbar.vue'

const route = useRoute()
const { isBillPaymentsV15Template } = useProvider()

const hasToolbar = computed<boolean>(() => route.meta?.toolbar ?? true)
const isBillPaymentsV15Route = computed<boolean>(
  () => route.meta?.isBillPaymentsV15Route ?? false
)
</script>

<template>
  <main
    class="bill-pay-theme h-full w-full overflow-y-auto"
    :class="{
      'bg-white': !isBillPaymentsV15Route,
      'bg-neutral-50': isBillPaymentsV15Route,
    }"
  >
    <div class="flex h-full w-full flex-col">
      <div class="grow bg-dark" />
      <div
        class="flex h-full w-full max-w-full grow overflow-hidden"
        :class="{
          'border-t border-eonx-neutral-400': isBillPaymentsV15Template,
        }"
      >
        <desktop-toolbar v-if="hasToolbar" />
        <div
          class="h-full w-full flex-1 overflow-x-hidden px-6 sm:px-0"
          :class="{
            'bg-eonx-neutral-50': isBillPaymentsV15Template,
          }"
        >
          <slot />
        </div>
      </div>
      <desktop-footer />
    </div>
  </main>
</template>
