<script setup lang="ts">
import { nanoid } from 'nanoid'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { BottomSheet } from '/~/core/bottom-sheet'
import { useUI } from '/~/composables/ui'

const props = defineProps<{
  sheet: BottomSheet
  hide: () => void
}>()

const { lockKeyboardAccessArea, unlockKeyboardAccessArea } = useUI()

const rootElement = ref(null)
const keyboardAccessAreaId = nanoid()

const loading = ref(false)

function handleKeys(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    close()
  }
}

onMounted(() => {
  window.addEventListener('keyup', handleKeys)
  props.sheet.on?.show?.()
  setTimeout(() => {
    lockKeyboardAccessArea({
      id: keyboardAccessAreaId,
      rootElement: rootElement.value,
      delay: 200,
    } as any)
  })
})

onUnmounted(() => {
  window.removeEventListener('keyup', handleKeys)
  unlockKeyboardAccessArea(keyboardAccessAreaId)
  props.sheet.on?.hide?.()
})

function close() {
  if (props.sheet.closable && !loading.value) {
    props.hide()
  }
}

const listeners = computed(() => {
  const { show, hide, ...rest } = props.sheet.on ?? {}

  return rest
})

const centered = computed(() => props.sheet.style.centered ?? false)
const fullheight = computed(() => props.sheet.style.fullheight ?? false)
const animation = computed(() => {
  switch (props.sheet.style.animation) {
    case 'right':
      return 'bottom-sheet-right'
    case 'bottom':
    default:
      return 'bottom-sheet'
  }
})

function onLoading(value: boolean) {
  loading.value = value
}
</script>

<template>
  <portal :to="sheet.to">
    <transition :name="animation" appear>
      <div
        ref="rootElement"
        class="absolute top-0 left-0 z-modal flex h-full w-full justify-center"
        :class="{
          'flex items-center justify-center px-5': centered,
        }"
        :style="{ backgroundColor: 'rgba(0, 0, 0, .35)' }"
        @click.self="close"
      >
        <transition duration="400">
          <div
            class="bottom-sheet absolute z-10 flex max-h-full flex-col overflow-y-auto overflow-x-hidden"
            :class="{
              relative: centered,
              'bottom-0 left-0 w-full': !centered,
              'rounded-3xl': centered && !fullheight,
              'rounded-t-3xl': !centered && !fullheight,
              'h-full': fullheight,
              'h-auto': !fullheight,
            }"
          >
            <div class="flex max-h-full grow flex-col overflow-y-auto bg-light">
              <component
                :is="sheet.component"
                v-bind="sheet.props"
                v-on="listeners"
                @hide="hide"
                @loading="onLoading"
              />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </portal>
</template>
