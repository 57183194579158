<script setup>
import { ref } from 'vue'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import SelectField from '/~/components/mobile-filter/select-field.vue'

const {
  categories,
  searchQuery,
  offerTypes,
  selectedOfferTypes,
  selectedCategory,
  sortValues,
  selectedSorting,
  resetFilters,
  isFiltersSelected,
  defaultSortValue,
} = useCommunityHub()

const localSearchQuery = ref(searchQuery.value)
const localSorting = ref(selectedSorting.value)
const localOfferTypes = ref(selectedOfferTypes.value)
const localCategory = ref(selectedCategory.value)

function resetLocalFilters() {
  localSearchQuery.value = searchQuery.value
  localSorting.value = selectedSorting.value
  localOfferTypes.value = selectedOfferTypes.value
  localCategory.value = selectedCategory.value
}

function applyFilters() {
  searchQuery.value = localSearchQuery.value
  selectedSorting.value = localSorting.value
  selectedOfferTypes.value = localOfferTypes.value
  selectedCategory.value = localCategory.value
}

function clearFilters() {
  resetFilters()
  resetLocalFilters()
}
</script>

<template>
  <ew-filters
    ref="filters"
    title="Filters"
    :is-filters-selected="isFiltersSelected"
    @opened="resetLocalFilters"
    @clear="clearFilters"
    @close="resetLocalFilters"
    @apply="applyFilters"
  >
    <div class="space-y-4 py-4">
      <div class="w-full">
        <base-input
          v-model="localSearchQuery"
          placeholder="Search offers"
          :icon-plain="true"
          look="rounded"
          clearable
          nolabel
        >
          <template #icon>
            <div class="ml-[15px] mr-2.5 flex items-center justify-center">
              <base-icon
                svg="plain/search-v2"
                class="text-eonx-neutral-600"
                :size="19"
              />
            </div>
          </template>
        </base-input>
      </div>

      <select-field
        v-model="localCategory"
        title="Categories"
        :options="categories"
        :empty-option="categories && categories[0]"
        :is-loading="!categories"
      />

      <select-field
        v-model="localSorting"
        title="Sorting by"
        :options="sortValues"
        :empty-option="defaultSortValue"
        :is-loading="!sortValues"
      />

      <select-field
        v-model="localOfferTypes"
        title="Offer type"
        :options="offerTypes"
        :is-loading="!offerTypes"
        is-multiple
      />
    </div>
  </ew-filters>
</template>
