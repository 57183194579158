<script>
import ui from '/~/core/ui'
import BaseBack from '/~/components/base/back/base-back.vue'
import Hi5Tile from '/~rec/components/hi5/hi5-tile.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import { useHi5 } from '/~rec/composables/hi5'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'hi5-send-to',
  components: {
    Hi5Tile,
    BaseBack,
    BaseLoader,
    EwCatalogProcessor,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    sendToId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { hi5 } = useHi5()
    const { groups, getMember } = useGroups()

    return {
      hi5,
      groups,
      getMember,
      ui,
    }
  },
  data() {
    return {
      profile: this.user,
    }
  },
  computed: {
    fullName() {
      return this.profile?.fullName
    },
    fetching() {
      return this.hi5.available.processing
    },
  },
  async created() {
    if (!this.profile) {
      this.profile = this.getMember(this.sendToId)
    }
    this.hi5.available.getData()
  },
  methods: {
    selectHi5(hi5) {
      this.$router.push({
        name: 'rec-hi5s-available',
        params: {
          hi5Id: hi5.id,
          nominee: this.profile,
          hi: hi5,
        },
        query: { sendTo: this.profile.id },
      })
    },
  },
}
</script>

<template>
  <div class="mx-auto flex w-full max-w-screen-md flex-col sm:px-6">
    <base-loader v-if="fetching" class="absolute inset-0" fullwidth />

    <div v-if="ui.desktop">
      <base-back :to="{ name: 'rec-hi5' }" class="mb-6" />
    </div>

    <div v-if="profile && !fetching">
      <h2
        class="text-eonx-neutral-800"
        :class="{
          'text-base leading-6': ui.mobile,
        }"
      >
        Hi-5 {{ fullName }}!
      </h2>

      <div
        :class="{
          'text-eonx-neutral-800': ui.desktop,
          'text-base leading-6 text-eonx-neutral-600': ui.mobile,
        }"
      >
        Start by selecting a Hi-5 from the list below.
      </div>

      <div class="flex grow flex-col pt-5 md:pt-0">
        <ew-catalog-processor
          :processor="hi5.available"
          :columns="{ xs: 2, md: 2, lg: 3, xl: 4 }"
          tile-class="rounded-lg shadow-xs cursor-pointer hover:shadow-lg hover:-translate-y-[5px] bg-light"
        >
          <template #tile="{ tile: hi }">
            <hi5-tile :hi="hi" :type="'available'" @click="selectHi5(hi)" />
          </template>
        </ew-catalog-processor>
      </div>
    </div>
  </div>
</template>
