<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { PaymentMethodCreditCard } from '/~/types/api'
import BaseButton from '/~/components/base/button/base-button'
import BaseSelect from '/~/components/base/select/base-select.vue'
import { getExpiryDate } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import PaymentsMakeCcOption from './cc-option.vue'
import PaymentsMakeSection from '../section.pure.vue'

export interface UpdateEventPayloads {
  type: PaymentMethodType.creditCard
  id: string
}

export interface PayByCCProps {
  loading: boolean
  cardsList: Array<PaymentMethodCreditCard>
  processingFeeOptions?: {
    loading: boolean
    value: number
  }
  onAddNewPaymentMethod?: () => void
  taxation: {
    label: string
  }
}

const props = defineProps<PayByCCProps>()
const emit = defineEmits<{
  (event: 'update', payloads: UpdateEventPayloads | null): void
}>()

const card = ref<string>()

const cardsListPrep = computed(() =>
  props.cardsList.map((item: any) => {
    const cardNumber = `**** ${item.number.slice(-4)}`
    const expiryDate = getExpiryDate(item)
    const isNotAllowed = item.state.toLowerCase() === 'restricted'

    item.value = item.id
    item.text = `${item.brand} ${cardNumber}`
    item.lastDigitsNumber = `${item.number.slice(-4)}`
    item.disabled = expiryDate.isExpired || isNotAllowed
    item.hiddenNumber = `${item.number.slice(-4)}`

    return item
  })
)

watch(card, (id) => {
  if (id) {
    const currentCard = cardsListPrep.value.find((item) => item.id === id)

    if (currentCard) {
      const { isCreditCardsNavEnabled } = useProvider()

      if (
        isCreditCardsNavEnabled.value &&
        currentCard.status.toLowerCase() !== 'verified'
      ) {
        card.value = undefined
      } else {
        emit('update', { type: PaymentMethodType.creditCard, id })
      }
    } else {
      card.value = undefined
    }
  } else {
    emit('update', null)
  }
})

watch(
  () => props.cardsList,
  () => {
    card.value = undefined
  }
)
</script>

<template>
  <payments-make-section label="Pay via Credit/Debit Card" :loading="loading">
    <template v-if="onAddNewPaymentMethod" #subHead>
      <base-button size="sm" @click="onAddNewPaymentMethod">
        + Add new
      </base-button>
    </template>

    <base-select
      v-model="card"
      :disabled="loading"
      :options="cardsListPrep"
      default-option="Select Credit/Debit Card"
      :validation="{
        rules: 'required',
        name: 'Credit/Debit Card',
      }"
      class="!pt-0"
      entry-class="rounded-sm h-[60px] sm:h-12 text-eonx-neutral-800 !pl-3"
      select-list-class="divide-y !box-border !py-0 !rounded-sm !max-h-36 sm:!max-h-48"
      custom-select
      fullwidth-popup
      no-label
      :select-first="false"
      :popup-offset="{ top: 8 }"
      placeholder="Select Credit/Debit Card"
      :search="{
        enabled: true,
        allowSpace: true,
        fields: ['hiddenNumber'],
        maxlength: 32,
      }"
      empty-string="no matching Credit/Debit Card"
    >
      <template #selected="{ option }">
        <payments-make-cc-option
          :method="option"
          no-padding
          is-option-selected
          :taxation="taxation"
          :processing-fee-options="processingFeeOptions"
        />
      </template>

      <template #item="{ option }">
        <payments-make-cc-option :method="option" :taxation="taxation" />
      </template>
    </base-select>
  </payments-make-section>
</template>
