<script>
import modal from '/~/core/mdl'
import PostCreatorTabs from './post-creator-tabs.vue'

export default {
  name: 'post-creator',
  components: {
    PostCreatorTabs,
  },
  props: {
    source: {
      type: Object,
      default: null,
    },
    tabs: {
      type: Array,
      default: null,
    },
  },
  methods: {
    selectTab(id) {
      modal.show('rec-post-creator', {
        props: {
          source: this.source,
          tabs: this.tabs,
          type: id,
        },
        on: {
          created: () => this.$emit('created'),
        },
        appearTransition: 'none',
      })
    },
  },
}
</script>

<template>
  <post-creator-tabs :tabs="tabs" @input="selectTab" />
</template>
