<script setup lang="ts">
import { computed } from 'vue'
import ui from '/~/core/ui'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EmptyState from '/~/components/empty-state/empty-state.vue'
import { useActivity } from '/~/composables/activity'
import ActivityListItemDesk from './activity-list-item.desk.vue'
import ActivityListItemMobile from './activity-list-item.mobile.vue'

const { activity, recordGroupsByDate } = useActivity()

const empty = computed(() => {
  return activity.value.isEmpty
})

const allLoaded = computed(() => {
  return activity.value.allRecordsLoaded
})

const processing = computed(() => {
  return activity.value.processing
})

const component = computed(() =>
  ui.mobile ? ActivityListItemMobile : ActivityListItemDesk
)

function loadMore(isLoaderVisible: boolean) {
  if (isLoaderVisible && !processing.value) {
    activity.value.next()
  }
}
</script>

<template>
  <div class="p-5">
    <empty-state v-if="empty" icon="heroicons/solid/chart-bar" class="m-auto">
      <template #title>No activity available</template>
    </empty-state>
    <div v-else class="w-full space-y-12">
      <div v-for="group in recordGroupsByDate" :key="group.id">
        <div class="mb-4 font-bold text-eonx-neutral-800">
          {{ group.name }}
        </div>
        <div class="space-y-4">
          <template v-for="record in group.records">
            <component :is="component" :key="record.id" :item="record" />
          </template>
        </div>
      </div>

      <base-loader
        v-if="!allLoaded || processing"
        v-observe-visibility="loadMore"
        class="mt-[30px]"
        fullwidth
      />
    </div>
  </div>
</template>
