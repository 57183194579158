<script>
import { formatDate } from '/~/utils/format/date'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import ui from '/~/core/ui'

export default {
  name: 'rec-award-nominations',
  components: {
    BaseIcon,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    nominationLimitLabel: {
      type: String,
      default: '',
    },
    endsAt: {
      type: Object,
      default: () => ({}),
    },
    currentPeriod: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    closeDate() {
      return this.currentPeriod?.fromNow()
    },
    endDate() {
      return formatDate('daymonthyearnumeric', this.endsAt)
    },
    isFinishedStatus() {
      return ['received', 'finished'].includes(this.status)
    },
  },
}
</script>

<template>
  <ul
    class="text-sm"
    :class="{
      'mb-[15px]': !ui.desktop,
    }"
  >
    <li
      v-if="nominationLimitLabel"
      class="mb-[5px] flex items-center truncate font-bold"
    >
      <base-icon svg="rec/users" :size="18" class="mr-1.5 shrink-0" />

      <div class="flex-1 truncate">
        {{ nominationLimitLabel }}
      </div>
    </li>
    <li v-if="isFinishedStatus" class="flex items-center truncate font-bold">
      <base-icon svg="rec/time" :size="18" class="mr-2.5 shrink-0" />

      <div class="flex-1 truncate">Finished {{ endDate }}</div>
    </li>
    <li v-else class="flex items-center truncate font-bold">
      <base-icon svg="rec/time" size="xxs" class="mr-2.5 shrink-0" />

      <div class="flex-1 truncate">Nominations close {{ closeDate }}</div>
    </li>
  </ul>
</template>
