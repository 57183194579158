<script>
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
export default {
  name: 'points-partner-placeholder',
  components: {
    BasePlaceholder,
  },
  props: {
    rows: {
      type: Number,
      default: 1,
    },
  },
}
</script>
<template>
  <div>
    <div
      v-for="index in rows"
      :key="`placeholder-${index}`"
      class="mb-[15px] flex"
    >
      <base-placeholder :height="80" class="flex-auto" />
    </div>
  </div>
</template>
