<script setup>
import { useRoute } from 'vue-router/composables'
import { useEstore } from '/~/extensions/estore/composables'
import { useEstoreFilters } from '/~/extensions/estore/composables/use-estore-filters'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import RangeField from '/~/components/mobile-filter/range-field.vue'
import SelectField from '/~/components/mobile-filter/select-field.vue'
import router from '/~/router'

const { items, brands, searchGroup } = useEstore()
const route = useRoute()

const {
  priceRangeInterval,
  currentSearch,
  currentItems,
  currentBrands,
  currentMin,
  currentMax,
  selectedCategory,
  floorMinRange,
  ceilMaxRange,
  isFiltersSelected,
  updateIntitalFilters,
  setSelected,
  resetFilters,
  updatePriceRange,
  applyFilters,
  formatter,
  mergeFormatter,
  currency,
  filteredCategories,
} = useEstoreFilters(route)

function onCategorySelected(categoryId) {
  router.push({
    name: route.name,
    query: route.query,
    params: {
      type: route.params.type,
      category: categoryId,
      preventTopScrolling: true,
    },
  })
}
</script>

<template>
  <div class="flex w-full space-x-2.5">
    <ew-filters
      title="Filters"
      :is-filters-selected="isFiltersSelected"
      @apply="applyFilters"
      @clear="resetFilters"
      @close="setSelected"
      @opened="updateIntitalFilters"
    >
      <div class="relative mt-2.5 grow">
        <base-input
          v-model="currentSearch"
          :placeholder="`Search ${searchGroup}`"
          :icon-plain="true"
          look="rounded"
          clearable
          nolabel
        >
          <template #icon>
            <div class="ml-[15px] mr-2.5 flex items-center justify-center">
              <base-icon
                svg="plain/search-v2"
                class="text-eonx-neutral-600"
                :size="19"
              />
            </div>
          </template>
        </base-input>
      </div>
      <div class="space-y-4 py-4">
        <select-field
          :value="selectedCategory"
          title="Categories"
          :options="filteredCategories"
          @input="onCategorySelected"
        />
        <select-field
          v-model="currentItems"
          title="Items"
          :options="items.data"
          :is-loading="items.loading"
          is-multiple
        />
        <select-field
          v-model="currentBrands"
          title="Brands"
          :options="brands.data"
          :is-loading="brands.loading"
          is-multiple
        />
        <range-field
          title="Price Range"
          :min="floorMinRange"
          :max="ceilMaxRange"
          :value="[currentMin, currentMax]"
          :interval="priceRangeInterval"
          :formatter="formatter"
          :merge-formatter="mergeFormatter"
          :currency="currency"
          @update="updatePriceRange"
        />
      </div>
    </ew-filters>
  </div>
</template>
