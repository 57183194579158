import type { RouteConfig } from 'vue-router'
import type { CMSPageId } from '/~/types/cms'
import { RewardsExtension } from '/~/core/extension'
import mdl from '/~/core/mdl'
import { useGiftcards } from '/~/extensions/giftcards/composables'
import modals from './modals'

mdl.register(modals)

const { initGiftcards, syncState } = useGiftcards()

class GiftCardsExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  async setState() {
    return initGiftcards()
  }

  setRoutes() {
    return [
      {
        path: 'gift-cards',
        component: () =>
          import(`./templates/${this.templateName}/giftcards.vue`),
        children: [
          {
            path: 'retailer/:slug/:id?',
            name: 'giftcards-retailer',
            props: true,
            meta: {
              menu: 'giftcards',
              title: 'Gift Card',
              back: { name: 'giftcards' },
              theme: 'white',
              hideMenu: true,
            },
            component: () =>
              import(
                `./templates/${this.templateName}/views/giftcards-retailer.vue`
              ),
          },
          {
            path: ':type?/:category?',
            name: 'giftcards',
            meta: {
              title: 'Gift Cards',
              page: 'giftcards' as CMSPageId,
            },
            props: true,
            beforeEnter(to, from, next) {
              syncState({ to })
              next()
            },
            component: () =>
              import(
                `./templates/${this.templateName}/views/giftcards-catalog.vue`
              ),
          },
        ] as RouteConfig[],
      },
    ]
  }
}

export default GiftCardsExtension
