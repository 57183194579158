<script setup lang="ts">
import debounce from 'lodash-es/debounce'
import { ref, watch } from 'vue'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import EwCategories from '/~/components/ewallet/controls/ew-categories.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import EwSorting from '/~/components/ewallet/controls/ew-sorting.vue'

const {
  selectedCategory,
  selectedSorting,
  searchQuery,
  sortValues,
  categories,
  isFiltersSelected,
  resetFilters,
  searchGroup,
} = useOnlineOffers()

const localSearchQuery = ref(searchQuery.value)
const searchFocused = ref(false)
const filters = ref<InstanceType<typeof EwFilters> | null>(null)

watch(searchQuery, (value) => {
  // NOTE: do not update local search value if input have focus
  if (!searchFocused.value) {
    localSearchQuery.value = value
  }
})

const onSearchInput = debounce(function (value) {
  searchQuery.value = value
}, 350)

function onSearchBlur() {
  searchFocused.value = false
  searchQuery.value = localSearchQuery.value
}

function onSearchEnter() {
  searchQuery.value = localSearchQuery.value
}

function onSort(value: string) {
  selectedSorting.value = value
  filters.value?.close()
}
</script>

<template>
  <div class="flex flex-col space-x-[15px] sm:flex-row sm:items-center">
    <div class="mb-2.5 w-full sm:mb-0">
      <base-input
        v-model="localSearchQuery"
        :placeholder="`Search ${searchGroup}`"
        look="rounded"
        icon-plain
        nolabel
        clearable
        autofocus
        @input="onSearchInput"
        @focus="searchFocused = true"
        @blur="onSearchBlur"
        @keypress.enter="onSearchEnter"
      >
        <template #icon>
          <div class="ml-[15px] mr-2.5 flex items-center justify-center">
            <base-icon
              svg="plain/search-v2"
              class="text-eonx-neutral-600"
              :size="19"
            />
          </div>
        </template>
      </base-input>
    </div>

    <div class="flex space-x-2.5 sm:space-x-5">
      <ew-categories
        :categories="categories"
        :selected-id="selectedCategory"
        :render-link="false"
        title="Categories"
        class="flex-1"
        @change="selectedCategory = $event"
      />

      <ew-filters
        ref="filters"
        title="Select Filters"
        :is-filters-selected="isFiltersSelected"
        @clear="resetFilters"
      >
        <ew-sorting
          title="Sorting by"
          :value="selectedSorting"
          :sorting="sortValues"
          @input="onSort"
        />
      </ew-filters>
    </div>
  </div>
</template>
