<script>
export default {
  name: 'hlth-hero',
  props: {
    image: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div class="relative w-full">
    <div
      class="inset-0 min-h-40 bg-cover bg-center bg-no-repeat xs:absolute"
      :style="{
        backgroundImage: `url(${image})`,
      }"
    />
    <div
      class="relative flex items-center justify-center xs:p-10 sm:justify-start sm:px-16 sm:py-[72px]"
    >
      <slot />
    </div>
  </div>
</template>
