<script>
import { ValidationObserver } from 'vee-validate'
import { computed } from 'vue'
import emitter from '/~/core/emitter'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelectAsync from '/~/components/base/select-async/base-select-async.vue'
import { useForm } from '/~/composables/base/use-form'
import { useLocalization } from '/~/composables/localization'
import { usePayees } from '/~/composables/payees'

export default {
  name: 'profile-payee-add-account',
  components: {
    BaseInput,
    BaseButton,
    BaseSelectAsync,
    BaseCheckbox,
    ValidationObserver,
  },
  setup() {
    const { payees, createPayee, getPayeeFromName, getPayeeFromNumber } =
      usePayees()
    const { getMask, translate } = useLocalization()
    const businessNumberMask = computed(() => getMask('businessNumber'))
    const { validationObserverRef } = useForm()

    return {
      payees,
      businessNumberMask,
      validationObserverRef,
      createPayee,
      getPayeeFromName,
      getPayeeFromNumber,
      translate,
    }
  },
  data() {
    return {
      form: {
        type: 'bankAccount',
        name: '',
        bsb: '',
        accountNumber: '',
        accountABN: '',
      },
      unmaskedBSB: '',
      unmaskedBusinessNumber: '',
      backendErrors: {},
      searchByBusinessNumber: false,
      isBusinessNumberLoading: false,
      businessNumberError: '',
    }
  },
  computed: {
    canProceed() {
      const { name, accountABN, accountNumber } = this.form

      return (
        name &&
        accountABN &&
        accountNumber &&
        !this.processingBSB &&
        this.unmaskedBSB &&
        this.unmaskedBSB.length === 6 &&
        this.unmaskedBusinessNumber
      )
    },
    processingBSB() {
      return this.validationObserverRef?.refs.bsb?.flags.pending
    },
    formattedAccountNumber() {
      const length = 9
      const { accountNumber } = this.form

      return '0'.repeat(length - accountNumber.length) + accountNumber
    },
    isLoading() {
      return this.payees.creating || this.processingBSB
    },
    isSubmitDisabled() {
      return !this.canProceed
    },
  },
  watch: {
    searchByBusinessNumber() {
      this.clearBusinessNumber()
    },
  },
  methods: {
    processBackendErrors(data) {
      const { validationErrors } = data
      const backendErrors = {}

      if (validationErrors) {
        for (const key in validationErrors) {
          const firstError = validationErrors[key][0]

          backendErrors[key] = firstError || ''
        }
        this.backendErrors = backendErrors
      }
    },
    async onSaveClick() {
      this.backendErrors = {}

      const { name, type } = this.form
      const payload = {
        accountNumber: this.formattedAccountNumber,
        bsb: this.unmaskedBSB,
        abn: this.unmaskedBusinessNumber,
        name: name,
        accountName: name,
        type,
      }

      try {
        const payee = await this.createPayee(payload)

        emitter.emit('menu:pay-anyone:select-to', payee)
        this.$router.replace({ hash: '#profile-pay-to' })
      } catch (error) {
        this.processBackendErrors(error.data || error)
      }
    },
    nameChange(value) {
      this.form.accountABN = value.value
      this.form.name = value.label
    },
    clearBusinessNumber() {
      this.form.accountABN = ''
      this.form.name = ''
    },
    async onBusinessNumberInput(value) {
      this.businessNumberError = ''

      if (value.length === this.businessNumberMask.length) {
        this.isBusinessNumberLoading = true
        const response = await this.getPayeeFromNumber(value)

        if (response !== null) {
          this.form.name = response.entityName
        } else {
          this.form.name = ''
          this.businessNumberError = `Payee with this ${this.translate(
            'payment.businessNumber'
          )} is not found`
        }

        this.isBusinessNumberLoading = false
      } else if (this.form.name) {
        this.form.name = ''
      }
    },
    onCancelClick() {
      this.$router.back()
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <div class="flex h-full flex-col items-stretch justify-between">
      <div>
        <base-checkbox v-model="searchByBusinessNumber" class="mb-5">
          Search by Payee {{ translate('payment.businessNumber') }}
        </base-checkbox>
        <template v-if="searchByBusinessNumber">
          <base-input
            v-model="form.accountABN"
            :label="`Payee ${translate('payment.businessNumber')}`"
            name="ABN"
            :maxlength="businessNumberMask.length"
            icon="plain/search"
            :mask="{ mask: businessNumberMask.mask }"
            :placeholder="`Type Payee ${translate('payment.businessNumber')}`"
            :icon-plain="true"
            :loading="isBusinessNumberLoading"
            :error="businessNumberError"
            @input="onBusinessNumberInput"
            @unmasked="(value) => (unmaskedBusinessNumber = value)"
          />
          <base-input
            :value="form.name"
            label="Payee Name"
            disabled
            name="name"
          />
        </template>

        <template v-else>
          <base-select-async
            key="name"
            :value="form.name"
            :validation="{
              rules: 'required',
              name: 'Payee Name',
            }"
            :error="backendErrors.name"
            :disabled="payees.creating"
            label="Payee Name"
            required
            :nolabel="false"
            name="name"
            :fetch="getPayeeFromName"
            class="mb-5"
            placeholder="Type Payee Name"
            @change="nameChange"
            @clear="clearBusinessNumber"
          />
          <base-input
            :value="form.accountABN"
            :label="`Payee ${translate('payment.businessNumber')}`"
            disabled
            name="ABN"
            :maxlength="businessNumberMask.length"
            :mask="{ mask: businessNumberMask.mask }"
            @unmasked="(value) => (unmaskedBusinessNumber = value)"
          />
        </template>

        <base-input
          v-model="form.bsb"
          :validation="{
            rules: 'required|bsb',
            name: 'BSB',
            vid: 'bsb',
            unmasked: true,
          }"
          :error="backendErrors.bsb"
          :disabled="payees.creating || processingBSB"
          mask="bsb"
          label="BSB"
          required
          name="bsb"
          maxlength="7"
          @unmasked="(value) => (unmaskedBSB = value)"
        />

        <base-input
          v-model="form.accountNumber"
          :validation="{
            rules: 'required|numeric|max:9',
            name: 'Account Number',
          }"
          :error="backendErrors.accountNumber"
          :disabled="payees.creating"
          label="Account No."
          required
          name="accountNumber"
          maxlength="9"
          :mask="{ mask: '000000000' }"
        />
      </div>

      <div class="grid grid-cols-2 gap-5">
        <base-button
          look="outlined-color"
          class="w-full"
          :disabled="isLoading"
          @click="onCancelClick"
        >
          Cancel
        </base-button>
        <base-button
          class="w-full"
          :loading="isLoading"
          :disabled="isSubmitDisabled"
          @click="handleSubmit(onSaveClick)"
        >
          Save
        </base-button>
      </div>
    </div>
  </validation-observer>
</template>
