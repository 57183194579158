import type { RouteConfig } from 'vue-router'
import type { CMSPageId } from '/~/types/cms'
import { RewardsExtension } from '/~/core/extension'
import mdl from '/~/core/mdl'
import { useGcCinema } from '/~/extensions/gift-cards-cinema/composables'
import { useProvider } from '/~/composables/provider'
import modals from './modals'

const { gcCinemaIsEmpty, initGcCinema, syncGcCinemaState } = useGcCinema()
const { isBupaTemplate } = useProvider()

mdl.register(modals)

class GiftCardsCinemaExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  setState() {
    return initGcCinema()
  }

  setRoutes() {
    return [
      {
        path: 'cinema',
        component: () =>
          import(`./templates/${this.templateName}/giftcards.vue`),
        children: (
          [
            {
              path: isBupaTemplate.value
                ? 'group/:groupSlug/:slug/:id?'
                : 'location/:slug/:id?',
              name: 'cinema-location',
              props: true,
              meta: {
                menu: 'cinema',
                title: 'Cinema Location',
                hideMenu: true,
                back: { name: 'cinema' },
              },
              component: () =>
                import(
                  `./templates/${this.templateName}/views/giftcards-location.vue`
                ),
            },
            isBupaTemplate.value && {
              path: 'group/:groupSlug',
              name: 'cinema-group',
              meta: {
                title: 'Cinema',
                page: 'cinema' as CMSPageId,
              },
              props: true,
              async beforeEnter(to, from, next) {
                if (gcCinemaIsEmpty.value || from.name !== 'cinema-location') {
                  await syncGcCinemaState({ to })
                }
                next()
              },
              component: () =>
                import('./templates/bupa/views/giftcards-group.vue'),
            },
            {
              path: ':type?/:category?',
              name: 'cinema',
              meta: {
                title: 'Cinema',
                page: 'cinema' as CMSPageId,
              },
              props: true,
              async beforeEnter(to, from, next) {
                if (
                  !isBupaTemplate.value &&
                  (gcCinemaIsEmpty.value ||
                    (from.name !== 'cinema-location' &&
                      from.name !== 'cinema-group'))
                ) {
                  await syncGcCinemaState({ to })
                }

                next()
              },
              component: () =>
                import(
                  `./templates/${this.templateName}/views/giftcards-catalog.vue`
                ),
            },
          ] as RouteConfig[]
        ).filter(Boolean),
      },
    ]
  }
}

export default GiftCardsCinemaExtension
