import { ref, computed } from 'vue'
import { ApiResponseData } from '/~/types/api'
import api from '/~/core/api'
import { usePoints } from '/~/composables/points'
import { useUser } from '/~/composables/user'
import { BillPaymentsMembership } from '../core/membership'

type MembershipItem = {
  feeLabel: string
  feePercentage: string
  id: string
  name: string
  label: string
}

const membershipItems = ref<MembershipItem[]>([])
const membershipsFetching = ref(false)

const { customEarnRates } = usePoints()

const memberships = computed(() => {
  return membershipItems.value.map((item) => {
    return new BillPaymentsMembership({
      ...item,
      ...(customEarnRates.value.find((rate) => {
        return rate.membershipLabel === item.label
      }) ?? {}),
    })
  })
})

const hasAvailableMembership = computed<boolean>(() =>
  Boolean(
    membershipItems.value.some(
      (membership: MembershipItem) =>
        membership.label.toLowerCase() !== 'hidden'
    )
  )
)

const currentMembership = computed(() => {
  const { user } = useUser()

  return (
    memberships.value.find((membership: BillPaymentsMembership) => {
      return membership.label === user.value.membershipLabel
    }) ?? {}
  )
})

async function fetchMemberships() {
  try {
    membershipsFetching.value = true

    const { data } = await api.get<ApiResponseData<MembershipItem[]>>(
      '/v3/memberships'
    )

    membershipItems.value = data
  } catch (e) {
    console.error(e)
  } finally {
    membershipsFetching.value = false
  }
}

function updateMembership(membershipId: string) {
  return api.post('/v3/memberships', { membershipId })
}

const isMembershipSwitchingEnabled = computed<boolean>(() => {
  const { user } = useUser()

  const { membershipLabel } = user.value
  const membershipLabelLowerCase = membershipLabel.toLowerCase()
  const { membershipSwitching } = eonx.config?.general ?? {}
  const isAllowed = !membershipSwitching?.exceptWhen?.some(
    (item) => membershipLabelLowerCase === item.toLowerCase()
  )
  const isEnabled = membershipSwitching?.enabled ?? false

  return isAllowed && isEnabled && hasAvailableMembership.value
})

export function useMembership() {
  return {
    memberships,
    membershipsFetching,
    currentMembership,
    isMembershipSwitchingEnabled,

    fetchMemberships,
    updateMembership,
  }
}
