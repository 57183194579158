<script>
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'ew-details-header',
  components: {
    BasePlaceholder,
    BaseButton,
  },
  props: {
    title: {
      type: String,
      default: 'Activity',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    back: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onBack() {
      if (this.back) {
        this.$router.push(this.back)
      } else {
        this.$router.back()
      }
    },
  },
}
</script>

<template>
  <portal to="app-header">
    <div class="absolute inset-0 z-1 h-full sm:hidden">
      <div class="flex h-full items-center justify-center bg-light">
        <div
          class="absolute top-0 left-0 flex h-full cursor-pointer items-center justify-center px-[15px]"
        >
          <base-button
            ref="backButton"
            icon="base/chevron"
            :size="24"
            :padding="2"
            alt="back"
            @click="onBack"
          />
        </div>
        <base-placeholder
          v-if="loading"
          class="w-40"
          type="text"
          rows="1"
          height="16"
        />
        <div v-else class="w-7/12 truncate text-center text-lg font-bold">
          <span v-html="title" />
        </div>
      </div>
    </div>
  </portal>
</template>
