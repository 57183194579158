<script setup lang="ts">
import PayeeIcon from '/~/components/app/payee-icon.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import SummaryPayScheduled from './summary-pay-scheduled.vue'
import SummaryPayToAmount from './summary-pay-to-amount.vue'
import SummaryPayToHeader from './summary-pay-to-header.vue'

const { payment } = useCheckoutReactive()
</script>

<template>
  <div>
    <summary-pay-to-header class="mb-4" />
    <div v-if="payment.statement" class="flex">
      <payee-icon :payee="payment.statement.payee" class="mr-4 pt-2.5" />
      <div class="flex flex-col text-sm text-eonx-neutral-600">
        <div class="text-base font-bold text-eonx-neutral-800">
          {{ payment.statement.payeeName }}
        </div>
        <div class="mt-[5px] flex w-full justify-between">
          <div>
            <div>{{ payment.statement.statementDateMonthName }} statement</div>
            <div>{{ payment.statement.client.name }}</div>
            <div class="max-w-44 truncate pr-2">
              Account no. {{ payment.statement.clientNumber }}
            </div>
          </div>
        </div>
      </div>
      <summary-pay-to-amount class="ml-auto self-end" />
    </div>
    <summary-pay-scheduled />
  </div>
</template>
