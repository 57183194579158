<script setup lang="ts">
import dayjs from 'dayjs/esm'
import { ref, computed } from 'vue'
import { formatDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button'

const props = withDefaults(
  defineProps<{
    activePeriod?: {
      from?: dayjs.Dayjs | null
      to?: dayjs.Dayjs | null
    }
    value?: dayjs.Dayjs | null
    selectable?: boolean
    displayMode?: 'days' | 'calendar'
  }>(),
  {
    activePeriod: () => ({
      from: null,
      to: null,
    }),
    value: () => dayjs(),
    selectable: true,
    displayMode: 'days',
  }
)

const emit =
  defineEmits<(event: 'select', date: dayjs.Dayjs | number) => void>()
const selectedDate = computed(() => props.value)
const calendarDate = ref(props.value || dayjs())
const monthFistDay = computed(() => calendarDate.value.startOf('month').day())
const daysInMonth = computed(() => calendarDate.value.daysInMonth())
const activePeriodFrom = computed(() => props.activePeriod.from || dayjs())
const activePeriodTo = computed(
  () => props.activePeriod.to || dayjs().add(1, 'year')
)
const currentMonth = computed(() => formatDate('monthlong', calendarDate.value))

function dateFromDay(day: number) {
  return dayjs(
    new Date(
      calendarDate.value.get('year'),
      calendarDate.value.get('month'),
      day - monthFistDay.value
    )
  )
}

function select(day: number) {
  if (
    dateFromDay(day).isBetween(activePeriodFrom.value, activePeriodTo.value)
  ) {
    emit('select', dateFromDay(day))
  }
}

function prevMonth() {
  calendarDate.value = calendarDate.value.subtract(1, 'month')
}
function nextMonth() {
  calendarDate.value = calendarDate.value.add(1, 'month')
}
</script>

<template>
  <div class="cursor-default overflow-hidden bg-white p-5 sm:w-[375px]">
    <div v-if="displayMode === 'calendar'" class="flex justify-between">
      <base-button
        icon="heroicons/mini/chevron-left"
        :size="32"
        :padding="6"
        alt="prev"
        class="rounded-full bg-primary text-white"
        @click="prevMonth"
      />
      <span>{{ currentMonth }}</span>
      <base-button
        icon="heroicons/mini/chevron-right"
        :size="32"
        :padding="6"
        alt="next"
        class="rounded-full bg-primary text-white"
        @click="nextMonth"
      />
    </div>
    <div v-else class="flex justify-center font-bold">
      Date per calendar month
    </div>
    <div class="mt-3 grid grid-cols-7 gap-4">
      <div
        v-for="i in daysInMonth + monthFistDay"
        :key="i"
        :class="{
          invisible: i <= monthFistDay || i > daysInMonth + monthFistDay,
          'bg-eonx-neutral-200 text-eonx-neutral-400': !dateFromDay(
            i
          ).isBetween(activePeriodFrom, activePeriodTo),
          '!bg-primary text-white': dateFromDay(i).isSame(selectedDate, 'date'),
          'cursor-pointer border border-eonx-neutral-100 bg-eonx-neutral-50 text-eonx-neutral-800 hover:bg-secondary hover:text-white':
            selectable &&
            dateFromDay(i).isBetween(activePeriodFrom, activePeriodTo),
        }"
        class="flex h-8 w-8 items-center justify-center rounded-full text-sm font-semibold leading-tight"
        @click="select(i)"
      >
        {{ i - monthFistDay }}
      </div>
    </div>
    <slot name="after-content" />
  </div>
</template>
