<script setup lang="ts">
import { computed } from 'vue'
import WgtWrapper from '../../wgt/wgt-wrapper.vue'
import WidgetTilesEpworthContent from './widget-tiles-epworth-content.vue'
import { Tile } from '/~/composables/cms/use-cms'
import { Row, Wrapper, useWrapper } from '/~/configurator/composables/wrapper'

const props = defineProps<{
  row?: Row
  wrapper?: Wrapper
  items: Tile[]
}>()

const { defaultWrapper, getTileKey } = useWrapper(props)

const overrideWrapper = computed(() => {
  const wrapper = { ...defaultWrapper.value }

  wrapper.space = true
  wrapper.row.md = wrapper.row.md ?? 4

  return wrapper
})
</script>

<template>
  <wgt-wrapper :wrapper="overrideWrapper">
    <div
      v-for="(tile, idx) in items"
      :key="getTileKey(tile, idx)"
      :wrapper="overrideWrapper"
      class="w-full overflow-hidden rounded-md bg-light shadow"
    >
      <widget-tiles-epworth-content :tile="tile" />
    </div>
  </wgt-wrapper>
</template>
