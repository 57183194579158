<script>
import DrawerPayFrom from '/~/components/drawer/components/pay/drawer-pay-from.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'

export default {
  name: 'profile-points-pay-from',
  components: {
    DrawerPayFrom,
  },
  methods: {
    addPaymentMethod() {
      this.$router.push({
        hash: '#profile-points-pay-from-add',
        params: { source: FlowType.payment },
      })
    },
  },
}
</script>

<template>
  <drawer-pay-from title="Payment Methods" :action="addPaymentMethod" />
</template>
