import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomEarnConfig } from '/~rec/core/earn-config'

export class EarnConfigProcessor extends EntityProcessor {
  constructor(params = {}) {
    super({
      entity: 'gamification/action-earn-limits',
      mapping: (record) => new RecroomEarnConfig(record),
      ...params,
    })

    this.hitsByAction = {}
  }

  async getData(params = {}) {
    const actions = {}
    const hits = await super.getData(params)

    for (let i = 0; i < hits.length; i++) {
      const action = hits[i].action

      actions[action] = hits[i]
    }

    this.hitsByAction = actions
  }
}
