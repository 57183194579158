import get from 'lodash-es/get'

export default {
  props: {
    offer: {
      type: [Object, Array],
      default: null,
    },
    retailer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedValue: '',
    }
  },
  computed: {
    physical() {
      return !!get(this.offer, 'attributes.physical')
    },
    name() {
      return get(this.retailer, 'attributes.name')
    },
    offerName() {
      return get(this.offer, 'attributes.name')
    },
    percentage() {
      return get(this.offer, 'attributes.percentage')
    },
    html() {
      return get(this.offer, 'attributes.html')
    },
  },
  methods: {
    recordAnalytics() {
      const { lcAnalytics, dataLayer } = window

      if (!lcAnalytics) {
        return
      }

      const productName = this.products[0].attributes.name
      const retailerName = `${this.name} ${productName}`
      const sbPrefix = 'SB - '

      dataLayer.events = ['PRODUCT_VIEW']
      dataLayer.productSet = [{ name: sbPrefix + retailerName }]

      lcAnalytics.track('Product Viewed', {
        name: retailerName,
      })
    },
    addToCardAnalytics() {
      const { lcAnalytics, dataLayer, webAnalytics } = window

      if (!lcAnalytics) {
        return
      }

      const { currentItem } = this
      const retailerName = this.name
      let productName = currentItem.name
      const total = this.giftTotalAmount
      const quantity = this.giftQuantity
      const price = total / quantity
      let originalPrice = currentItem.value
      const sbPrefix = 'SB - '

      if (this.isVariableProductType) {
        originalPrice = this.selectedValue
        productName = this.fullName
      }

      const name = `${retailerName} ${productName}`
      const product = {
        name,
        price,
        quantity,
        originalPrice,
        total,
        discountPercentage: this.percentage,
      }
      const suncorpProduct = {
        name: sbPrefix + name,
        price,
        quantity,
      }

      lcAnalytics.track('productSet', [suncorpProduct])
      lcAnalytics.track('Product Added', { products: [product] })

      dataLayer.productAdded = suncorpProduct

      if (webAnalytics) {
        webAnalytics.publish('event', 'addtocart')
      }
    },
  },
}
