<script setup lang="ts">
import { watch } from 'vue'
import { useBaseInputCode } from './base-input-code'
import InputCodeItem from './input-code-item.vue'

withDefaults(
  defineProps<{
    error?: boolean
    disabled?: boolean
  }>(),
  {
    error: false,
    disabled: false,
  }
)

const emit = defineEmits<{
  (event: 'enter'): void
  (event: 'change', value: Array<number | null>): void
}>()

const { count, inputRef, code } = useBaseInputCode()

watch(code, (v) => {
  emit('change', v)
})

function onEnter() {
  emit('enter')
}
</script>

<template>
  <div class="flex space-x-2">
    <template v-for="i in count">
      <input-code-item
        ref="inputRef"
        :key="i"
        :error="error"
        :disabled="disabled"
        @enter="onEnter"
      />
    </template>
  </div>
</template>
