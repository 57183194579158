<script>
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'

export default {
  name: 'dining-checkboxes',
  components: {
    BaseCheckbox,
    BaseCollapse,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    filters: {
      type: [Object, Array],
      default: () => ({}),
    },
    scrollToId: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filtersState: this.value,
      showAll: false,
      expanded: true,
    }
  },
  watch: {
    filtersState(filtersState) {
      this.$emit('input', filtersState)
    },
    value: {
      handler() {
        this.filtersState = this.value
      },
      deep: true,
    },
  },
  methods: {
    clearFilter() {
      if (this.scrollToId) {
        this.$scrollTo('#' + this.scrollToId)
      }
      this.filtersState = []
    },
    toggleExpand() {
      this.expanded = !this.expanded
    },
    priceIndicator(priceValue) {
      return '$'.repeat(+priceValue)
    },
  },
}
</script>

<template>
  <div class="mt-5 pb-5">
    <base-collapse v-model="expanded" show-icon :autoscroll="false">
      <template #trigger>
        <span class="text-sm font-bold">
          {{ title }}
        </span>
      </template>
      <span>
        <template v-for="(item, key) in filters">
          <base-checkbox
            :key="key"
            v-model="filtersState"
            class="mt-2.5 text-sm font-bold"
            :value="item.id"
            @click.stop
          >
            {{ priceIndicator(item.id) }}
          </base-checkbox>
        </template>
      </span>
    </base-collapse>
  </div>
</template>
