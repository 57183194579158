<script>
import CompetitionQuestionForm from '/~/extensions/competitions/templates/default/views/details/components/competition-question-form.vue'
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'question-bottom-sheet',
  components: {
    BaseButton,
    CompetitionQuestionForm,
  },
  props: {
    competition: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    function cancel() {
      emit('cancel')
      emit('hide')
    }

    function submit(form) {
      emit('submit', form)
      emit('hide')
    }

    return {
      cancel,
      submit,
    }
  },
}
</script>

<template>
  <div class="p-5">
    <competition-question-form :competition="competition" @submit="submit">
      <template #buttons="{ loading }">
        <base-button
          class="flex-1"
          look="outlined-color"
          :disabled="loading"
          @click="cancel"
        >
          Cancel
        </base-button>
      </template>
    </competition-question-form>
  </div>
</template>
