<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { formatDollar } from '/~/utils/format/money'

export default {
  name: 'checkout-summary-pay-item',
  components: {
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      default: 'xl',
    },
    iconClass: {
      type: String,
      default: null,
    },
    details: {
      type: String,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {
      formatDollar,
    }
  },
}
</script>

<template>
  <div v-if="total > 0" class="relative flex items-center">
    <div
      class="flex h-12 w-12 shrink-0 items-center justify-center"
      :class="iconClass"
    >
      <base-icon :svg="icon" :size="iconSize" />
    </div>
    <div class="ml-[15px] flex flex-col">
      <span class="font-bold">
        {{ title }}
      </span>
      <span v-if="details" class="text-sm text-eonx-neutral-600">
        {{ details }}
      </span>
    </div>
    <div class="ml-auto shrink-0 text-sm text-eonx-neutral-600">
      {{ formatDollar(-total) }}
    </div>
  </div>
</template>
