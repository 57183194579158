import { ref } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import router from '/~/router'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'

const { deleteAccount } = usePointsPrograms()

export type Account = {
  id: string
  accountNumber: string
  firstName: string
  lastName: string
  type: string
}

const confirmed = ref(false)
const resposeErrorCode = ref(0)

async function deletePointsProgramAccount(account: Account) {
  try {
    confirmed.value = false
    await deleteAccount(account.id)
    confirmed.value = true
  } catch (error: any) {
    resposeErrorCode.value = error.data?.status ?? 0
  }
}

function showRemoveAccountModal(account: Account) {
  const removeAccountOptions = {
    props: {
      account,
      title: 'Remove partner account',
      description:
        'Are you sure you want to remove this account? Please confirm your choice by clicking the "Confirm" button. Please note that this action cannot be undone.',
      icon: {
        path: 'heroicons/solid/trash',
        color: 'text-red-700',
      },
      onConfirm: async () => {
        await deletePointsProgramAccount(account)
      },
    },
    on: {
      hide: () => {
        if (confirmed.value) {
          showRemovedAccountModal(account)
          confirmed.value = false
        } else if (resposeErrorCode.value === 409) {
          showUnableRemoveAccountModal(account)
          resposeErrorCode.value = 0
        }
      },
    },
  }

  if (ui.mobile) {
    bottomSheet.show('confirm', removeAccountOptions)
  } else {
    modal.show('remove-airpartner-account', removeAccountOptions)
  }
}

function showRemovedAccountModal(account: Account) {
  const removedAccountOptions = {
    props: {
      account,
      title: 'Account removed successfully',
      description:
        'If you have any questions or concerns, please feel free to contact our support team.',
      icon: {
        path: 'heroicons/solid/check-circle',
        color: 'text-emerald-700',
      },
      showCancel: false,
      confirmLabel: 'Done',
    },
    on: {
      hide: () => {
        router.push({ name: 'fly-partners-list' })
      },
    },
  }

  if (ui.mobile) {
    bottomSheet.show('confirm', removedAccountOptions)
  } else {
    modal.show('remove-airpartner-account', removedAccountOptions)
  }
}

function showUnableRemoveAccountModal(account: Account) {
  const unableRemoveAccountOptions = {
    props: {
      account,
      title: 'Unable to remove account',
      description:
        'This account has a points transfer pending. The account cannot be removed until the pending points transfer has been completed.',
      icon: {
        path: 'heroicons/solid/trash',
        color: 'text-red-700',
      },
      showCancel: false,
      confirmLabel: 'Done',
    },
  }

  if (ui.mobile) {
    bottomSheet.show('confirm', unableRemoveAccountOptions)
  } else {
    modal.show('remove-airpartner-account', unableRemoveAccountOptions)
  }
}

export function usePointsProgramsModals() {
  return {
    showRemoveAccountModal,
  }
}
