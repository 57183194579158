<script>
import CompetitionQuestionForm from './competition-question-form.vue'
import { useUI } from '/~/composables/ui'

export default {
  name: 'competition-summary',
  components: {
    CompetitionQuestionForm,
  },
  props: {
    competition: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { titleRef } = useUI()

    return {
      titleRef,
    }
  },
}
</script>

<template>
  <div class="sm:pb-20">
    <div class="space-y-5 px-5 sm:px-0">
      <h2
        ref="titleRef"
        class="text-2xl font-normal leading-9 !outline-none sm:text-3xl"
        tabindex="-1"
      >
        {{ competition.title }}
      </h2>
      <div v-if="competition.intro" v-html="competition.intro" />
    </div>

    <div
      v-if="competition.isActive"
      class="mt-[30px] hidden border-t border-dashed border-primary pt-[30px] sm:block"
    >
      <competition-question-form :competition="competition" />
    </div>
  </div>
</template>
