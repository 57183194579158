<script>
import ChatPopup from './chat-popup.vue'
import { useChat } from '/~rec/composables/chat'

export default {
  name: 'rec-chat-popup-keeper',
  components: {
    ChatPopup,
  },
  setup() {
    const { chat, selectRoom } = useChat()

    return {
      selectRoom,
      chat,
    }
  },
}
</script>

<template>
  <div class="fixed bottom-0 right-0 z-40 mr-[352px] flex space-x-[30px]">
    <chat-popup
      v-for="room in chat.openedRooms"
      :key="room.id"
      :room="room"
      @click="selectRoom(room)"
    />
  </div>
</template>
