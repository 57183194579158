import { createDate } from '/~/utils/format/date'
import api from '/~rec/core/api'
import cdn from '/~/utils/cdn'
import { UsersProcessor } from '/~rec/core/users-processor'
import { PostsProcessor } from '/~rec/core/posts-processor'
import { RecroomGroupMember } from '/~rec/core/group-member'
import { RecroomPost } from '/~rec/core'
import { useRecProfile } from '/~rec/composables/profile'

const { myRecId } = useRecProfile()

export class RecroomGroup {
  constructor(rawData) {
    this.updateRaw(rawData)

    this.posts = new PostsProcessor({
      entity: `groups/${this.id}/posts`,
      mapping: (record) => {
        return { ...record, groups: [rawData] }
      },
    })

    this.members = new UsersProcessor({
      entity: `groups/${this.id}/members`,
      perPage: 500,
      mapping: (record) => new RecroomGroupMember(record),
    })
  }

  get id() {
    return this.raw.id
  }

  get name() {
    return this.raw.name || ''
  }

  get image() {
    return cdn(this.raw.banner_url).url()
  }

  get imagePreview() {
    return cdn(this.raw.banner_url).preview('160x160').url()
  }

  get description() {
    return this.raw.description
  }

  get isPrivate() {
    return this.raw.private
  }

  get isMeOwner() {
    return this.raw.is_owner
  }

  get creatorIsAdmin() {
    return this.raw.creator.is_admin
  }

  get ownersUuids() {
    return this.raw.owners_uuids
  }

  get isMeMember() {
    return this.raw.is_member
  }

  get totalMembers() {
    return this.raw.total_members
  }

  get isMyGroup() {
    return this.isMeMember || this.isMeOwner
  }

  get updatedAt() {
    return createDate(this.raw.updated_at)
  }

  get notifications() {
    return this.raw.notifications
  }

  get searchValue() {
    return this.name.toLowerCase() + this.description.toLowerCase()
  }

  static async fetchById(id) {
    const response = await api.get(`/groups/${id}`)

    return new RecroomGroup(response.data)
  }

  static async create(data) {
    const response = await api.post('groups', data, { notify: false })

    return new RecroomGroup(response.data)
  }

  updateRaw(raw) {
    this.raw = {
      ...(this.raw || {}),
      ...raw,
      banner_url: raw.banner_url || `/img/banner${14 % raw.name.length}`,
    }
  }

  async edit(data) {
    let response = null

    if (data) {
      response = await api.put(`groups/${this.id}`, data, { notify: false })
    } else {
      response = await api.get(`/groups/${this.id}`)
    }

    this.updateRaw(response.data)

    return this
  }

  createPost(data) {
    return RecroomPost.create({ ...data, groups: [this.id] })
  }

  update() {
    return Promise.all([this.posts.refresh(), this.members.refresh()])
  }

  delete() {
    return api.delete(`/groups/${this.id}`)
  }

  async updateMembers(membersIds) {
    const members = this.members.hits
    const toDetach = []
    const toAttach = []

    members.forEach((member) => {
      if (member.id === myRecId.value) return

      const userExist = !!membersIds.find((id) => id === member.id)

      if (!userExist) {
        toDetach.push(member.id)
      }
    })

    membersIds.forEach((memberId) => {
      if (memberId === myRecId.value) return

      if (toDetach.indexOf(memberId) < 0) {
        toAttach.push(memberId)
      }
    })

    await Promise.all([
      toDetach.length && this.detachMembers(toDetach),
      toAttach.length && this.attachMembers(toAttach),
    ])

    await this.update()
  }

  async attachMembers(membersIds) {
    await api.post(`/groups/${this.id}/members/${membersIds.join(',')}`)
  }

  async detachMembers(membersIds) {
    await api.delete(`/groups/${this.id}/members/${membersIds.join(',')}`)
  }

  updateMember(memberId, data) {
    return api.put(`/groups/${this.id}/members/${memberId}`, data)
  }

  leave() {
    return api.delete(`/groups/${this.id}/members/${myRecId.value}`)
  }

  join() {
    return this.attachMembers([myRecId.value])
  }
}
