import { ref, computed } from 'vue'
import emitter from '/~/core/emitter'
import api from '/~rec/core/api'
import { useChat } from './chat'
import { useGroups } from './groups'
import { useRecProfile } from './profile'

const { features, initProfile, getTokenBalance, getManagementPoints } =
  useRecProfile()
const { initChat } = useChat()
const { initGroups } = useGroups()

const config = ref(null)
const initTask = ref(null)
const errorMessage = ref(null)
const initialized = ref(false)

const apiKey = computed(() => config?.value.api_key)
const apiUrl = computed(() => config?.value.api_url)

async function init() {
  let resolveInit

  try {
    initTask.value = new Promise((resolve) => {
      resolveInit = resolve
    })

    const { data } = await api.get('/1.1/workplace/api-key')

    config.value = data.data

    if (!apiKey.value || !apiUrl.value) {
      throw new Error('Api key for recroom not found')
    }

    api.setBaseUrl(apiUrl.value)
    api.setBaseHeaders({
      authorization: `Basic ${btoa(apiKey.value + ':')}`,
    })

    await initProfile(apiKey.value)
    await Promise.all([initChat(), initGroups()])

    initialized.value = true

    // Add listeners to points update on rec app init
    if (features.value.points) {
      emitter.on('notifications:points_balance_update', getTokenBalance)
      emitter.on('notifications:wallet_transaction_create', getManagementPoints)
    }

    resolveInit()
  } catch (error) {
    console.error(error)
    errorMessage.value = error.message
  }
}

export const useRecroom = () => ({
  config,
  initTask,
  initialized,
  apiKey,
  apiUrl,
  init,
})
