<script>
import AdditionalOffers from '/~/extensions/online-offers/templates/default/views/retailer/offers/offers-additional.vue'
import FeaturedOffer from '/~/extensions/online-offers/templates/default/views/retailer/offers/offers-featured.vue'
import { createDate } from '/~/utils/format/date'

export default {
  name: 'retailer-offers',
  components: {
    AdditionalOffers,
    FeaturedOffer,
  },
  props: {
    merchant: {
      type: Object,
      default: () => ({}),
    },
    coupons: {
      type: Array,
      default: () => [],
    },
    slug: {
      type: String,
      default: '',
    },
  },
  computed: {
    actualCoupons() {
      const currentDate = createDate()

      return this.coupons.filter((offer) => {
        const date = offer?.end_date?.date
        const endDate = createDate(date)
        const diff = endDate.diff(currentDate, 's')

        return diff > 0
      })
    },
  },
}
</script>

<template>
  <div class="px-5 sm:px-0">
    <featured-offer v-if="merchant" :merchant="merchant" :slug="slug" />
    <additional-offers
      v-if="actualCoupons && actualCoupons.length"
      :offers="actualCoupons"
      :merchant="merchant"
    />
  </div>
</template>
