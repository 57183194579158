<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import { useCms } from '/~/composables/cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'

const props = withDefaults(
  defineProps<{
    amount?: string | number
    shippingTax?: number
  }>(),
  {
    amount: 0,
    shippingTax: 0,
  }
)

const { calculatePointsToBurnOrder } = usePoints()
const { isPurchaseOrderPointsAvailable } = usePaymentMethods()
const { showPointsToBurn } = useCms()

const pointsToBurn = computed(() => calculatePointsToBurnOrder(+props.amount))
const formattedPointsToBurn = computed(() => formatPoints(pointsToBurn.value))
</script>

<template>
  <div class="flex grow flex-col items-center justify-center space-y-[5px]">
    <span class="flex items-center gap-2.5 whitespace-nowrap leading-none">
      <span v-if="true">Pay</span>
      <span v-else>Total</span>
      <span
        class="text-xl font-bold leading-none sm:text-2xl sm:leading-none"
        data-cy="calculation-amount"
      >
        {{ formatDollar(amount) }}
      </span>
    </span>
    <template v-if="showPointsToBurn && isPurchaseOrderPointsAvailable">
      <span
        class="mx-5 whitespace-nowrap text-sm font-bold text-eonx-neutral-600"
      >
        or use
      </span>
      <div class="flex flex-col items-center justify-center space-y-[5px]">
        <div
          class="relative whitespace-nowrap rounded-full bg-primary-lighten px-[15px] font-bold text-primary"
        >
          <span class="text-xl sm:text-2xl">
            {{ formattedPointsToBurn }}
          </span>
          <span class="text-sm">PTS</span>
        </div>
      </div>
    </template>
  </div>
</template>
