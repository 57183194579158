import ui from '/~/core/ui'
import MainDesktop from './desktop/main-desktop.vue'
import MainMobile from './mobile/main-mobile.vue'

export default {
  name: 'rec-layout-main',
  functional: true,
  render(h, ctx) {
    return h(ui.desktop ? MainDesktop : MainMobile, ctx)
  },
}
