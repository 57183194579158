<script>
import ui from '/~/core/ui'
import PointsTransferDesk from './points-transfer.desk.vue'
import PointsTransferMobile from './points-transfer.mobile.vue'

export default {
  name: 'fly-points-transfer',
  components: {
    PointsTransferDesk,
    PointsTransferMobile,
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <div class="h-full">
    <points-transfer-desk v-if="!ui.mobile" v-bind="$attrs" />
    <points-transfer-mobile v-else v-bind="$attrs" />
  </div>
</template>
