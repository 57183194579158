<script setup lang="ts">
import { SwiperOptions } from 'swiper'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { Content } from '/~/composables/cms/use-cms'
import EpurchasesEmpty from './components/empty.vue'
import EpurchaseTile from './components/tile.vue'
import { useEpurchasesComponent } from './epurchases.component'

const props = withDefaults(
  defineProps<{
    toModal?: string
    isFullwidth?: boolean
    swiperOptions?: SwiperOptions
    swiperSlideClass?: string
    emptyStateTitle?: string
    emptyStateTitleColor?: string
    emptyStateText?: Content
    emptyStateTextColor?: string
    emptyStateIcon?: string
    emptyStateIconColor?: string
    emptyStateBgColor?: string
    headerTitle?: string
    headerButtonLabel?: string
    headerButtonColor?: string
  }>(),
  {
    toModal: 'menu-modal',
    isFullwidth: true,
    swiperOptions: () => ({}),
    emptyStateTitle: 'No ePurchases yet',
    emptyStateIcon: 'heroicons/solid/gift-top',
    headerTitle: 'My ePurchases',
    headerButtonLabel: 'View all',
  }
)

const {
  isCurrentPurchasesLoaded,
  isArchivedPurchasesLoaded,
  hasCurrentPurchases,
  hasPurchases,
  sortedCurrentPurchases,
  epurchasesRoute,
  showUnlockModal,
  swiperContainer,
} = useEpurchasesComponent(props)
</script>

<template>
  <div
    class="overflow-hidden"
    :class="{
      '-mx-5 w-auto': isFullwidth,
    }"
  >
    <div
      v-if="isCurrentPurchasesLoaded && isArchivedPurchasesLoaded"
      class="mb-4 flex items-start justify-between text-lg"
      :class="{
        'px-5': isFullwidth,
      }"
    >
      <span class="font-bold">{{ headerTitle }}</span>
      <router-link
        v-if="hasPurchases"
        class="text-primary no-underline"
        :style="{
          color: headerButtonColor,
        }"
        :to="epurchasesRoute"
      >
        {{ headerButtonLabel }}
      </router-link>
      <div v-else class="text-eonx-neutral-300">{{ headerButtonLabel }}</div>
    </div>

    <template v-if="isCurrentPurchasesLoaded">
      <div
        v-if="hasCurrentPurchases"
        ref="swiperContainer"
        class="swiper-container z-0 w-full overflow-visible"
      >
        <div
          class="swiper-wrapper"
          :class="{
            'w-auto px-5 pb-2.5': isFullwidth,
          }"
        >
          <div
            v-for="(purchase, idx) in sortedCurrentPurchases"
            :key="idx"
            class="swiper-slide"
            :class="swiperSlideClass"
            :style="{
              width: '179px',
            }"
          >
            <epurchase-tile
              :card="purchase"
              @showUnlockModal="showUnlockModal"
            />
          </div>
        </div>
      </div>
      <epurchases-empty
        v-if="!hasCurrentPurchases && isCurrentPurchasesLoaded"
        :title="emptyStateTitle"
        :title-color="emptyStateTitleColor"
        :text="emptyStateText ? emptyStateText.text : null"
        :text-color="emptyStateTextColor"
        :icon="emptyStateIcon"
        :icon-color="emptyStateIconColor"
        :background-color="emptyStateBgColor"
      />
    </template>
    <div v-else class="flex items-center justify-center py-[15px]">
      <base-loader />
    </div>
  </div>
</template>
