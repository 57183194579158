export class RecroomHi5Reason {
  constructor(rawData = {}) {
    this.raw = rawData
  }

  get id() {
    return this.raw.id
  }

  get content() {
    return this.raw.content
  }

  get title() {
    return this.raw.content
  }

  get image() {
    return this.raw.image
  }
}
