<script setup lang="ts">
import { pick } from 'lodash-es'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { computed, getCurrentInstance, onBeforeMount, ref, watch } from 'vue'
import { RecroomEvent } from '/~/extensions/rec-room/core/event'
import { RecroomPostPoll } from '/~/extensions/rec-room/core/post-poll'
import { createDate, formatDate, isValidDate } from '/~/utils/format/date'
import { useGroups } from '/~rec/composables/groups'
import { usePostCreator } from '/~rec/composables/post-creator'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import CreatorPollOption from './creator-poll-option.vue'
import PostCreatorFooter from '../../post-creator-footer.vue'
import RichInput from '/~rec/components/rich-input/rich-input.vue'
import { useBackendValidation } from '/~rec/composables/backend-validation'

const props = withDefaults(
  defineProps<{
    source?: RecroomEvent
    post?: RecroomPostPoll
    announcement?: boolean
    sourceHidden?: boolean
  }>(),
  {
    sourceHidden: false,
    announcement: false,
  }
)

const emit = defineEmits<{
  (event: 'success'): void
  (event: 'update:loading', flag: boolean): void
}>()

const internalInstance = getCurrentInstance()
const { backendErrors, processBackendErrors } = useBackendValidation()
const { allMembers } = useGroups()

const poll = ref(
  props.post?.raw.options.map((o: { id: string; content: any }) =>
    pick(o, ['id', 'content'])
  ) ?? [{ content: '' }, { content: '' }]
)
const expiresAt = ref(
  isValidDate(props.post?.expiresAt)
    ? formatDate('daymonthyear', props.post?.expiresAt)
    : null
)
const privatePoll = ref(props.post?.raw.private ?? false)

function getPayload() {
  return {
    poll: poll.value,
    expires_at: expiresAt.value,
    private: privatePoll.value,
    announcement: props.announcement,
  }
}

const {
  validationObserverRef,
  isEdit,
  isAndriod,
  content,
  attachments,
  mentioned,
  linksPreviews,
  linkPreviewsProcessing,
  loading,
  input,
  onEmojiSelect,
  onInput,
  removePreview,
  updateMentioned,
  onSend,
} = usePostCreator({
  post: props.post,
  source: props.source,
  getPayload,
  emit,
  internalInstance,
  processBackendErrors,
})

const focused = ref<string>()
const pollEndDate = ref<any>(!!expiresAt.value)

const isMaxLenght = computed(() => poll.value.length >= 6)

const isSendDisabled = computed(
  () =>
    (!content.value.trim() && !attachments.value.length) ||
    !!input.value?.validationProviderRef?.failedRules['offensive']
)

watch(pollEndDate, (val) => {
  if (!val) {
    expiresAt.value = null
  }
})

onBeforeMount(() => {
  extend('uniqueOption', {
    message: () => 'This field must be unique',
    validate: (v) => {
      const str = (s: string) => s.toLowerCase().trim()
      const value = str(v)

      const options = poll.value.map(({ content }: any) => str(content))

      options.splice(options.indexOf(value), 1)

      return !options.includes(str(value))
    },
  })
})

function onAddClick() {
  if (!isMaxLenght.value) {
    poll.value.push({
      content: '',
    })
  }
}

function onDeleteClick(idx: number) {
  poll.value.splice(idx, 1)
}
</script>

<template>
  <validation-observer ref="validationObserverRef" slim>
    <div class="flex flex-col">
      <div class="flex-1 p-5 pt-0 md:pt-5">
        <div
          v-if="!isEdit && !sourceHidden"
          class="mb-2.5 truncate text-xs font-bold"
        >
          <span class="text-disabled">To News Feed:</span>
          <span v-if="source">
            {{ source.name }}
          </span>
        </div>

        <rich-input
          v-bind="$attrs"
          ref="input"
          :validation="{
            rules: 'offensive|max:1200',
            name: 'Question',
            mode: 'aggressive',
          }"
          :error="backendErrors.content"
          :content.sync="content"
          :mention-users="allMembers"
          :attachments.sync="attachments"
          :mentioned="mentioned"
          :loading="loading"
          :disable-send="isSendDisabled"
          placeholder="Ask your question"
          name="content"
          focused
          plain
          :max-length="1200"
          :is-post="true"
          :is-andriod="isAndriod"
          v-on="$listeners"
          @send="onSend"
          @input="onInput"
          @update:mentioned="updateMentioned"
        />
        <div v-if="linkPreviewsProcessing" class="mb-5 flex">
          <base-loader size="sm" class="mx-auto" />
        </div>

        <div class="mt-10 mb-[15px] flex justify-between">
          <b>Poll Options</b>
          <a
            class="font-primary select-none font-bold"
            :class="{
              'opacity-50': isMaxLenght,
              'cursor-pointer': !isMaxLenght,
            }"
            @click="onAddClick"
          >
            + Add
          </a>
        </div>

        <validation-provider
          v-for="(option, idx) in poll"
          v-slot="{ errors }"
          :key="option.id"
          rules="required|uniqueOption"
          :name="`Option ${idx + 1}`"
          :vid="`option-${idx}`"
          slim
        >
          <creator-poll-option
            v-model="option.content"
            :removable="poll.length > 2"
            :name="`option-${idx}`"
            :index="idx"
            :error="errors && errors[0]"
            format="DD MMM YYYY"
            @focus="focused = `poll.${idx}.content`"
            @delete="onDeleteClick(idx)"
          />
        </validation-provider>

        <base-checkbox v-model="privatePoll" class="mt-5 flex items-center">
          <div class="text-sm font-bold">Anonymous votes</div>
          <span class="text-xs text-disabled">
            No one will be able to see who voted in each option
          </span>
        </base-checkbox>

        <div class="mt-[15px] flex justify-start md:justify-between">
          <base-checkbox
            v-model="pollEndDate"
            class="mr-[15px] flex h-10 flex-none items-center pt-2.5 md:mr-0"
          >
            <div class="mr-2.5 text-sm font-bold">Poll End Date</div>
          </base-checkbox>
          <div v-if="pollEndDate">
            <!-- TODO Now  expires_at maybe in the past date and BE is ok. Then BE fix it change backendErrors.expires_at to BE param -->
            <base-datepicker
              v-model="expiresAt"
              class="flex-auto sm:flex-none"
              :error="backendErrors.expires_at"
              nolabel
              :disabled-dates="{
                to: createDate(),
              }"
              :popup-style="{
                zIndex: 999,
              }"
              format="DD MMM YYYY"
              :is-edit="isEdit"
              :min-date-mobile="new Date().toISOString().split('T')[0]"
            />
          </div>
        </div>
      </div>
      <div class="px-5">
        <div class="flex w-full flex-col space-y-5">
          <div
            v-for="(preview, idx) in linksPreviews"
            :key="idx + preview.url"
            class="relative flex w-full flex-col overflow-hidden rounded-lg border border-divider"
          >
            <div
              class="absolute right-0 mt-2.5 mr-2.5 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-300 text-eonx-neutral-800 hover:bg-gray-400"
              @click="removePreview(idx)"
            >
              <base-icon svg="close" :size="22" />
            </div>
            <div class="h-full w-full">
              <img :src="preview.image" class="w-full" />
            </div>
            <div
              class="flex flex-col space-y-[5px] bg-gray-50 px-6 py-[15px] text-eonx-neutral-800"
            >
              <span class="text-lg font-semibold">
                {{ preview.title }}
              </span>
              <span class="text-eonx-neutral-600">
                {{ preview.hostname }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <post-creator-footer
        :attachments.sync="attachments"
        :post="post"
        type="poll"
        :disabled="isSendDisabled"
        :is-andriod="isAndriod"
        @emoji="onEmojiSelect"
        @send="onSend"
      />
    </div>
  </validation-observer>
</template>
