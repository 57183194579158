<script>
import WgtImage from '../../wgt/wgt-image.vue'

export default {
  name: 'widget-tiles-staff',
  components: {
    WgtImage,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <div class="relative text-black">
    <wgt-image :image="tile.image" />

    <div
      class="py-5"
      :class="{
        'text-left': tile.content.contentAlignmentX === 'left',
        'text-right': tile.content.contentAlignmentX === 'right',
        'text-center': tile.content.contentAlignmentX === 'center',
        'px-5 md:px-[30px]': tile.content.padding === 'sm',
        'px-[30px] md:px-14 md:pb-[30px]': tile.content.padding === 'md',
        'px-10 md:px-20 md:pb-10': tile.content.padding === 'lg',
        'border-t': !tile.backgroundColor,
      }"
    >
      <span
        v-if="tile.content.title"
        class="font-bold"
        style="font-size: 1.375rem"
        v-html="tile.content.title"
      />
      <div
        v-if="tile.content.text"
        class="mt-[5px] text-eonx-neutral-600"
        v-html="tile.content.text"
      />
    </div>
  </div>
</template>
