<script>
import RetailerDetails from './components/community-hub-details.vue'

export default {
  name: 'community-hub-retailer',
  components: {
    RetailerDetails,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div class="sm:mt-0">
    <retailer-details :id="id" />
  </div>
</template>
