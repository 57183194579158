export default class HealthArticle {
  constructor(raw = {}) {
    this.raw = raw
  }

  get id() {
    return this.raw.id
  }

  get title() {
    return this.raw.title && this.raw.title.rendered
  }

  get excerpt() {
    return this.raw.excerpt && this.raw.excerpt.rendered
  }

  get description() {
    return this.raw.content && this.raw.content.rendered
  }

  get image() {
    const regex = /<img.*?src="(.*?)"[^>]+>/
    const images = regex.exec(this.description)
    const src = images && images[1]

    return src
  }

  get date() {
    return this.raw.date
  }
}
