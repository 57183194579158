<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import BaseButton from '/~/components/base/button/base-button'
import CompetitionTerms from './competition-terms.vue'
import { useUserEvents } from '/~/composables/user'

export default {
  components: {
    BaseMdl,
    BaseButton,
    CompetitionTerms,
  },
  props: {
    competition: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { termsAndConditionsVisited } = useUserEvents()

    termsAndConditionsVisited({ type: 'modal', name: 'Competition terms' })
  },
  methods: {
    print() {
      window.print()
    },
  },
}
</script>

<template>
  <base-mdl
    :padding="false"
    width="sme"
    height="lg"
    fullscreen="mobile"
    title="Terms & Conditions"
  >
    <template #menu>
      <base-button icon="plain/printer" @click="print" />
    </template>
    <competition-terms
      :competition="competition"
      class="px-[30px] pt-5 pb-[30px]"
    />
    <portal to="print-destination">
      <competition-terms :competition="competition" />
    </portal>
  </base-mdl>
</template>
