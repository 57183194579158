<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import hits from '/~/extensions/health/mocks/score-health'
import ScoreBreakdown from './components/score-breakdown.vue'
import MyHealthSlider from './components/my-health-slider.vue'
import HealthHero from '/~/extensions/health/components/hero.vue'
import HealthTile from '/~/extensions/health/components/tile.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseImage from '/~/components/base/image/base-image.vue'
import { useAssessment } from '/~/extensions/health/composables'

export default {
  name: 'my-health',
  components: {
    ScoreBreakdown,
    HealthTile,
    HealthHero,
    MyHealthSlider,
    BaseButton,
    BaseImage,
  },
  setup() {
    const { init, userTags, challenges, recipes, articles } = useAssessment()

    init()
    return {
      userTags,
      challenges,
      recipes,
      articles,
      ui,
    }
  },
  data() {
    return {
      blocked: true,
      hero: {
        image: 'https://cdn.eonx.net/82bd27fa-daa0-40b0-828d-a61e21126cc2/',
        title: 'Welcome to My Wellness',
        description:
          'In order to take full advantage of this part of the platform you first need to complete your Health Assesment',
      },
    }
  },
  computed: {
    isMobile() {
      return ui.mobile
    },
    healthItems() {
      return hits
    },
    chartImg() {
      const img = this.blocked
        ? 'https://cdn.eonx.net/aa25b6fd-979c-46d6-aff3-393561f3bf25/'
        : 'https://cdn.eonx.net/cbd64fc1-0117-4e1b-b7fe-7c9bd018e0e4/'

      return this.cdn(img).url()
    },
  },
  watch: {
    blocked(value) {
      if (!value) {
        this.getSlidersData()
      }
    },
  },
  created() {
    if (Object.keys(this.userTags).length) {
      this.blocked = false
    }

    if (!this.blocked) {
      this.getSlidersData()
    }
  },
  methods: {
    getSlidersData() {
      this.challenges.getData({
        search: {
          filters: [
            ...this.userTags.Body.map(
              (item) => `Collections:${item.toLowerCase()}`
            ),
            ...this.userTags.Mind.map(
              (item) => `Collections:${item.toLowerCase()}`
            ),
          ]
            .filter((i) => !!i)
            .join(' OR '),
        },
      })
      this.recipes.getData({
        search: {
          filters: [
            ...this.userTags['Food Recipes'].map(
              (item) => `Category:${item.toLowerCase()}`
            ),
          ]
            .filter((i) => !!i)
            .join(' OR '),
        },
      })
      this.articles.getData({
        search: {
          filters: [
            ...this.userTags['Health Articles'].map(
              (item) => `Category:${item.toLowerCase()}`
            ),
          ]
            .filter((i) => !!i)
            .join(' OR '),
        },
      })
    },
    onHeroBtnClick() {
      modal.show('assessment', {
        on: {
          finished: () => {
            this.blocked = false
          },
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <health-hero v-if="blocked" :image="hero.image">
      <health-tile
        :title="hero.title"
        :shadow="false"
        class="mx-5 mt-6 xs:m-0 sm:max-w-sm"
      >
        <p class="mt-[15px] text-sm leading-5 sm:text-base sm:leading-normal">
          {{ hero.description }}
        </p>
        <base-button
          full-width="mobile"
          class="mt-[15px] sm:mt-6"
          size="lge"
          @click="onHeroBtnClick"
        >
          Complete Health Assesment
        </base-button>
      </health-tile>
    </health-hero>

    <div class="px-5 py-10 sm:py-6">
      <div class="-m-[15px] flex flex-col sm:flex-row">
        <div class="p-[15px] sm:w-1/2">
          <health-tile
            :top-title="isMobile"
            title="Health Score"
            class="h-full"
          >
            <div class="mx-2.5 flex items-center sm:mx-0 sm:mt-[15px]">
              <base-image
                :src="chartImg"
                :rounded="false"
                :ratio="false"
                class="h-full max-w-56"
              />

              <div class="ml-[30px]">
                <div class="flex items-center">
                  <div
                    :class="
                      blocked
                        ? 'bg-slate-200  text-eonx-neutral-600'
                        : 'bg-primary-lighten text-primary'
                    "
                    class="flex w-24 shrink-0 flex-col items-center justify-center rounded-md p-[5px] sm:flex-row"
                  >
                    <b class="text-xl leading-6 sm:text-3xl sm:leading-9">
                      {{ healthItems.your === null ? '' : healthItems.your }}%
                    </b>

                    <b class="block text-xs uppercase leading-5 sm:hidden">
                      You
                    </b>
                  </div>
                  <b
                    :class="blocked ? 'text-eonx-neutral-600' : 'text-primary'"
                    class="ml-2.5 hidden uppercase leading-5 sm:block"
                  >
                    Your
                    <br />
                    Score
                  </b>
                </div>

                <div class="mt-6 flex items-center sm:mt-10">
                  <div
                    :class="
                      blocked
                        ? 'bg-slate-200  text-eonx-neutral-600'
                        : 'bg-fuchsia-100 text-fuchsia-400'
                    "
                    class="flex w-24 shrink-0 flex-col items-center justify-center rounded-md p-[5px] sm:flex-row"
                  >
                    <b class="text-xl leading-6 sm:text-3xl sm:leading-9">
                      {{
                        healthItems.average === null ? '' : healthItems.average
                      }}%
                    </b>
                    <b class="block text-xs uppercase leading-5 sm:hidden">
                      Average
                    </b>
                  </div>
                  <b
                    :class="
                      blocked ? 'text-eonx-neutral-600' : 'text-fuchsia-400'
                    "
                    class="ml-2.5 hidden uppercase leading-5 sm:block"
                  >
                    Average
                    <br />
                    Score
                  </b>
                </div>
              </div>
            </div>
          </health-tile>
        </div>

        <div class="p-[15px] sm:w-1/2">
          <health-tile
            :top-title="isMobile"
            :plain="isMobile"
            title="Score breakdown"
            class="h-full"
          >
            <score-breakdown :blocked="blocked" class="sm:mt-[5px]" />
          </health-tile>
        </div>
      </div>
      <template v-if="!blocked">
        <div v-if="!challenges.isEmpty" class="-mx-5 mt-5">
          <my-health-slider
            title="Challenges for you"
            type="challenge"
            :slides="challenges.hits"
            :loading="challenges.isLoading"
            :loop="true"
          />
        </div>
        <div v-if="!recipes.isEmpty" class="-mx-5 mt-5">
          <my-health-slider
            title="Recipes for you"
            type="recipe"
            :slides="recipes.hits"
            :loading="recipes.isLoading"
            :loop="true"
          />
        </div>

        <div v-if="!articles.isEmpty" class="-mx-5 mt-5">
          <my-health-slider
            title="Articles for you"
            type="article"
            :slides="articles.hits"
            :loading="articles.isLoading"
            :loop="true"
          />
        </div>
      </template>
    </div>
  </div>
</template>
