<script>
import ui from '/~/core/ui'
import { computed } from 'vue'
import { formatAmount } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'cashback-panel',
  components: {
    BaseIcon,
  },
  props: {
    total: {
      type: [String, Number],
      default: 0,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isOnlineOffersEnabled } = useOnlineOffers()
    const { showRebatesCalculation } = useProvider()

    const isEnabled = computed(
      () =>
        showRebatesCalculation.value &&
        props.total > 0 &&
        isOnlineOffersEnabled.value
    )
    const formattedValue = computed(() =>
      String(formatAmount(props.total)).split('.')
    )
    const label = computed(() =>
      (props.completed
        ? 'CASHBACK EARNED'
        : 'CASHBACK TO BE EARNED'
      ).toUpperCase()
    )

    return {
      isEnabled,
      label,
      formattedValue,
      ui,
    }
  },
}
</script>
<template>
  <div
    v-if="isEnabled"
    class="flex h-[88px] flex-1 items-center justify-center space-x-[30px] bg-blue-100 px-5 py-[15px] sm:space-x-5"
    :class="{
      'bg-cover bg-no-repeat': ui.desktop,
    }"
    :style="{
      backgroundImage: ui.desktop
        ? 'url(/images/symbion/cashback-bg.svg)'
        : null,
    }"
  >
    <div class="flex items-center justify-end">
      <base-icon
        class="text-blue-400"
        svg="symbion/wallet"
        :size="ui.desktop ? 34 : 45"
      />
    </div>
    <div
      class="flex flex-col items-start justify-center space-y-[5px] font-semibold"
    >
      <span class="text-sm font-normal">
        {{ label }}
      </span>
      <div class="relative text-4xl leading-none sm:text-2xl">
        <span
          v-if="loading"
          class="w-54 animate-pulse rounded bg-eonx-neutral-50 text-transparent"
        >
          loading
        </span>
        <template v-else>
          <span>${{ formattedValue[0] }}</span>
          <span
            class="absolute right-0 top-[5px] mt-2.5 -mr-px translate-x-full transform text-sm font-bold leading-none xs:top-0 xs:mt-0 sm:top-0"
          >
            {{ formattedValue[1] }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>
