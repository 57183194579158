<script>
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'rec-recognition-header',
  components: {
    BaseIcon,
    BaseAvatar,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: (v) => /primary|warning|success|error/.test(v),
    },
    icon: {
      type: String,
      default: null,
    },
    animated: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: null,
    },
  },
}
</script>

<template>
  <div class="overflow-hidden pb-14">
    <div
      class="relative flex min-h-40 items-center justify-center bg-gradient-to-br"
      :class="{
        'from-primary to-primary-lighten': color === 'primary',
        'from-warning to-amber-300': color === 'warning',
        'from-emerald-700 to-green-300': color === 'success',
        'from-red-700 to-red-300': color === 'error',
      }"
    >
      <div
        class="absolute bottom-0 left-1/2 z-1 h-[120px] w-[120px] -translate-x-1/2 translate-y-1/2 transform"
        :class="{
          'rounded-full bg-white p-[15px]': !!image,
        }"
      >
        <div
          class="absolute rounded-full border-solid border-white delay-200"
          :class="{
            'inset-0 animate-pulse-scale border': animated,
            'top-1/2 left-1/2 h-48 w-48 -translate-x-1/2 -translate-y-1/2 transform border opacity-40':
              !animated,
          }"
        />
        <div
          class="absolute rounded-full border-solid border-white"
          :class="{
            'inset-0 animate-pulse-scale-delayed border delay-300': animated,
            'top-1/2 left-1/2 h-40 w-40 -translate-x-1/2 -translate-y-1/2 transform border-3 opacity-50':
              !animated,
          }"
        />
        <base-avatar v-if="image" :src="image" class="h-full w-full" />
        <base-icon v-else :svg="icon" :size="120" />
      </div>
      <div class="relative">
        <div
          v-for="idx in 14"
          :key="idx"
          class="award-dot absolute h-2.5 w-2.5 rounded-full bg-white"
          :class="{
            'award-dot-animated': animated,
          }"
        />
      </div>
    </div>
  </div>
</template>

<style>
.award-dot {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 9999px;
}
.award-dot:nth-child(1) {
  top: 27px;
  left: -121px;
  width: 8px;
  height: 8px;
}
.award-dot:nth-child(2) {
  top: -45px;
  left: 2px;
  width: 8px;
  height: 8px;
}
.award-dot:nth-child(3) {
  top: -25px;
  left: -135px;
  width: 8px;
  height: 8px;
}
.award-dot:nth-child(4) {
  top: 23px;
  left: 109px;
  width: 8px;
  height: 8px;
}
.award-dot:nth-child(5) {
  top: -15px;
  left: -97px;
  width: 6px;
  height: 6px;
}
.award-dot:nth-child(6) {
  top: -17px;
  left: 71px;
  width: 6px;
  height: 6px;
}
.award-dot:nth-child(7) {
  top: -4px;
  left: 113px;
  width: 6px;
  height: 6px;
}
.award-dot:nth-child(8) {
  top: -43px;
  left: -57px;
  width: 6px;
  height: 6px;
}
.award-dot:nth-child(9) {
  top: -63px;
  left: 10px;
  width: 4px;
  height: 4px;
}
.award-dot:nth-child(10) {
  top: 5px;
  left: -150px;
  width: 4px;
  height: 4px;
}
.award-dot:nth-child(11) {
  top: -35px;
  left: 111px;
  width: 4px;
  height: 4px;
}
.award-dot:nth-child(12) {
  top: -51px;
  left: -107px;
  width: 4px;
  height: 4px;
}
.award-dot:nth-child(13) {
  top: 13px;
  left: 141px;
  width: 4px;
  height: 4px;
}
.award-dot:nth-child(14) {
  top: -51px;
  left: 50px;
  width: 4px;
  height: 4px;
}

@-webkit-keyframes dots-animation-1 {
  20% {
    -webkit-transform: translate3d(-243%, 153%, 0);
    transform: translate3d(-243%, 153%, 0);
  }
  40% {
    -webkit-transform: translate3d(-451%, -235%, 0);
    transform: translate3d(-451%, -235%, 0);
  }
  80% {
    -webkit-transform: translate3d(104%, -24%, 0);
    transform: translate3d(104%, -24%, 0);
  }
}

@keyframes dots-animation-1 {
  20% {
    -webkit-transform: translate3d(-243%, 153%, 0);
    transform: translate3d(-243%, 153%, 0);
  }
  40% {
    -webkit-transform: translate3d(-451%, -235%, 0);
    transform: translate3d(-451%, -235%, 0);
  }
  80% {
    -webkit-transform: translate3d(104%, -24%, 0);
    transform: translate3d(104%, -24%, 0);
  }
}

@-webkit-keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(1) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-1 20s 1.6s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-1 20s 1.6s infinite;
}

@-webkit-keyframes dots-animation-2 {
  20% {
    -webkit-transform: translate3d(50%, -222%, 0);
    transform: translate3d(50%, -222%, 0);
  }
  40% {
    -webkit-transform: translate3d(12%, -109%, 0);
    transform: translate3d(12%, -109%, 0);
  }
  80% {
    -webkit-transform: translate3d(215%, -12%, 0);
    transform: translate3d(215%, -12%, 0);
  }
}

@keyframes dots-animation-2 {
  20% {
    -webkit-transform: translate3d(50%, -222%, 0);
    transform: translate3d(50%, -222%, 0);
  }
  40% {
    -webkit-transform: translate3d(12%, -109%, 0);
    transform: translate3d(12%, -109%, 0);
  }
  80% {
    -webkit-transform: translate3d(215%, -12%, 0);
    transform: translate3d(215%, -12%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(2) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-2 20s 1.5s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-2 20s 1.5s infinite;
}

@-webkit-keyframes dots-animation-3 {
  20% {
    -webkit-transform: translate3d(-6%, 2%, 0);
    transform: translate3d(-6%, 2%, 0);
  }
  40% {
    -webkit-transform: translate3d(-203%, -224%, 0);
    transform: translate3d(-203%, -224%, 0);
  }
  80% {
    -webkit-transform: translate3d(-14%, 220%, 0);
    transform: translate3d(-14%, 220%, 0);
  }
}

@keyframes dots-animation-3 {
  20% {
    -webkit-transform: translate3d(-6%, 2%, 0);
    transform: translate3d(-6%, 2%, 0);
  }
  40% {
    -webkit-transform: translate3d(-203%, -224%, 0);
    transform: translate3d(-203%, -224%, 0);
  }
  80% {
    -webkit-transform: translate3d(-14%, 220%, 0);
    transform: translate3d(-14%, 220%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(3) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-3 20s 2.2s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-3 20s 2.2s infinite;
}

@-webkit-keyframes dots-animation-4 {
  20% {
    -webkit-transform: translate3d(-56%, 97%, 0);
    transform: translate3d(-56%, 97%, 0);
  }
  40% {
    -webkit-transform: translate3d(358%, -233%, 0);
    transform: translate3d(358%, -233%, 0);
  }
  80% {
    -webkit-transform: translate3d(272%, 68%, 0);
    transform: translate3d(272%, 68%, 0);
  }
}

@keyframes dots-animation-4 {
  20% {
    -webkit-transform: translate3d(-56%, 97%, 0);
    transform: translate3d(-56%, 97%, 0);
  }
  40% {
    -webkit-transform: translate3d(358%, -233%, 0);
    transform: translate3d(358%, -233%, 0);
  }
  80% {
    -webkit-transform: translate3d(272%, 68%, 0);
    transform: translate3d(272%, 68%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(4) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-4 20s 1.7s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-4 20s 1.7s infinite;
}

@-webkit-keyframes dots-animation-5 {
  20% {
    -webkit-transform: translate3d(158%, -92%, 0);
    transform: translate3d(158%, -92%, 0);
  }
  40% {
    -webkit-transform: translate3d(288%, 202%, 0);
    transform: translate3d(288%, 202%, 0);
  }
  80% {
    -webkit-transform: translate3d(116%, -13%, 0);
    transform: translate3d(116%, -13%, 0);
  }
}

@keyframes dots-animation-5 {
  20% {
    -webkit-transform: translate3d(158%, -92%, 0);
    transform: translate3d(158%, -92%, 0);
  }
  40% {
    -webkit-transform: translate3d(288%, 202%, 0);
    transform: translate3d(288%, 202%, 0);
  }
  80% {
    -webkit-transform: translate3d(116%, -13%, 0);
    transform: translate3d(116%, -13%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(5) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-5 20s 2.8s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-5 20s 2.8s infinite;
}

@-webkit-keyframes dots-animation-6 {
  20% {
    -webkit-transform: translate3d(-193%, 19%, 0);
    transform: translate3d(-193%, 19%, 0);
  }
  40% {
    -webkit-transform: translate3d(-57%, -38%, 0);
    transform: translate3d(-57%, -38%, 0);
  }
  80% {
    -webkit-transform: translate3d(-65%, -70%, 0);
    transform: translate3d(-65%, -70%, 0);
  }
}

@keyframes dots-animation-6 {
  20% {
    -webkit-transform: translate3d(-193%, 19%, 0);
    transform: translate3d(-193%, 19%, 0);
  }
  40% {
    -webkit-transform: translate3d(-57%, -38%, 0);
    transform: translate3d(-57%, -38%, 0);
  }
  80% {
    -webkit-transform: translate3d(-65%, -70%, 0);
    transform: translate3d(-65%, -70%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(6) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-6 20s 1.9s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-6 20s 1.9s infinite;
}

@-webkit-keyframes dots-animation-7 {
  20% {
    -webkit-transform: translate3d(-129%, -112%, 0);
    transform: translate3d(-129%, -112%, 0);
  }
  40% {
    -webkit-transform: translate3d(-678%, -25%, 0);
    transform: translate3d(-678%, -25%, 0);
  }
  80% {
    -webkit-transform: translate3d(-131%, 191%, 0);
    transform: translate3d(-131%, 191%, 0);
  }
}

@keyframes dots-animation-7 {
  20% {
    -webkit-transform: translate3d(-129%, -112%, 0);
    transform: translate3d(-129%, -112%, 0);
  }
  40% {
    -webkit-transform: translate3d(-678%, -25%, 0);
    transform: translate3d(-678%, -25%, 0);
  }
  80% {
    -webkit-transform: translate3d(-131%, 191%, 0);
    transform: translate3d(-131%, 191%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(7) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-7 20s 3.4s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-7 20s 3.4s infinite;
}

@-webkit-keyframes dots-animation-8 {
  20% {
    -webkit-transform: translate3d(-106%, 58%, 0);
    transform: translate3d(-106%, 58%, 0);
  }
  40% {
    -webkit-transform: translate3d(129%, -255%, 0);
    transform: translate3d(129%, -255%, 0);
  }
  80% {
    -webkit-transform: translate3d(165%, 40%, 0);
    transform: translate3d(165%, 40%, 0);
  }
}

@keyframes dots-animation-8 {
  20% {
    -webkit-transform: translate3d(-106%, 58%, 0);
    transform: translate3d(-106%, 58%, 0);
  }
  40% {
    -webkit-transform: translate3d(129%, -255%, 0);
    transform: translate3d(129%, -255%, 0);
  }
  80% {
    -webkit-transform: translate3d(165%, 40%, 0);
    transform: translate3d(165%, 40%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(8) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-8 20s 2.1s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-8 20s 2.1s infinite;
}

@-webkit-keyframes dots-animation-9 {
  20% {
    -webkit-transform: translate3d(-123%, -37%, 0);
    transform: translate3d(-123%, -37%, 0);
  }
  40% {
    -webkit-transform: translate3d(369%, -35%, 0);
    transform: translate3d(369%, -35%, 0);
  }
  80% {
    -webkit-transform: translate3d(129%, 42%, 0);
    transform: translate3d(129%, 42%, 0);
  }
}

@keyframes dots-animation-9 {
  20% {
    -webkit-transform: translate3d(-123%, -37%, 0);
    transform: translate3d(-123%, -37%, 0);
  }
  40% {
    -webkit-transform: translate3d(369%, -35%, 0);
    transform: translate3d(369%, -35%, 0);
  }
  80% {
    -webkit-transform: translate3d(129%, 42%, 0);
    transform: translate3d(129%, 42%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(9) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out, dots-animation-9 20s 4s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-9 20s 4s infinite;
}

@-webkit-keyframes dots-animation-10 {
  20% {
    -webkit-transform: translate3d(-77%, -118%, 0);
    transform: translate3d(-77%, -118%, 0);
  }
  40% {
    -webkit-transform: translate3d(203%, 145%, 0);
    transform: translate3d(203%, 145%, 0);
  }
  80% {
    -webkit-transform: translate3d(85%, 21%, 0);
    transform: translate3d(85%, 21%, 0);
  }
}

@keyframes dots-animation-10 {
  20% {
    -webkit-transform: translate3d(-77%, -118%, 0);
    transform: translate3d(-77%, -118%, 0);
  }
  40% {
    -webkit-transform: translate3d(203%, 145%, 0);
    transform: translate3d(203%, 145%, 0);
  }
  80% {
    -webkit-transform: translate3d(85%, 21%, 0);
    transform: translate3d(85%, 21%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(10) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-10 20s 1.3s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-10 20s 1.3s infinite;
}

@-webkit-keyframes dots-animation-11 {
  20% {
    -webkit-transform: translate3d(83%, 14%, 0);
    transform: translate3d(83%, 14%, 0);
  }
  40% {
    -webkit-transform: translate3d(-411%, -6%, 0);
    transform: translate3d(-411%, -6%, 0);
  }
  80% {
    -webkit-transform: translate3d(-19%, 138%, 0);
    transform: translate3d(-19%, 138%, 0);
  }
}

@keyframes dots-animation-11 {
  20% {
    -webkit-transform: translate3d(83%, 14%, 0);
    transform: translate3d(83%, 14%, 0);
  }
  40% {
    -webkit-transform: translate3d(-411%, -6%, 0);
    transform: translate3d(-411%, -6%, 0);
  }
  80% {
    -webkit-transform: translate3d(-19%, 138%, 0);
    transform: translate3d(-19%, 138%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(11) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-11 20s 1.6s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-11 20s 1.6s infinite;
}

@-webkit-keyframes dots-animation-12 {
  20% {
    -webkit-transform: translate3d(84%, 97%, 0);
    transform: translate3d(84%, 97%, 0);
  }
  40% {
    -webkit-transform: translate3d(-292%, -90%, 0);
    transform: translate3d(-292%, -90%, 0);
  }
  80% {
    -webkit-transform: translate3d(207%, 14%, 0);
    transform: translate3d(207%, 14%, 0);
  }
}

@keyframes dots-animation-12 {
  20% {
    -webkit-transform: translate3d(84%, 97%, 0);
    transform: translate3d(84%, 97%, 0);
  }
  40% {
    -webkit-transform: translate3d(-292%, -90%, 0);
    transform: translate3d(-292%, -90%, 0);
  }
  80% {
    -webkit-transform: translate3d(207%, 14%, 0);
    transform: translate3d(207%, 14%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(12) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-12 20s 1.5s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-12 20s 1.5s infinite;
}

@-webkit-keyframes dots-animation-13 {
  20% {
    -webkit-transform: translate3d(138%, -42%, 0);
    transform: translate3d(138%, -42%, 0);
  }
  40% {
    -webkit-transform: translate3d(-573%, 53%, 0);
    transform: translate3d(-573%, 53%, 0);
  }
  80% {
    -webkit-transform: translate3d(164%, 148%, 0);
    transform: translate3d(164%, 148%, 0);
  }
}

@keyframes dots-animation-13 {
  20% {
    -webkit-transform: translate3d(138%, -42%, 0);
    transform: translate3d(138%, -42%, 0);
  }
  40% {
    -webkit-transform: translate3d(-573%, 53%, 0);
    transform: translate3d(-573%, 53%, 0);
  }
  80% {
    -webkit-transform: translate3d(164%, 148%, 0);
    transform: translate3d(164%, 148%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(13) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-13 20s 2.2s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-13 20s 2.2s infinite;
}

@-webkit-keyframes dots-animation-14 {
  20% {
    -webkit-transform: translate3d(191%, 120%, 0);
    transform: translate3d(191%, 120%, 0);
  }
  40% {
    -webkit-transform: translate3d(-519%, -274%, 0);
    transform: translate3d(-519%, -274%, 0);
  }
  80% {
    -webkit-transform: translate3d(59%, 28%, 0);
    transform: translate3d(59%, 28%, 0);
  }
}

@keyframes dots-animation-14 {
  20% {
    -webkit-transform: translate3d(191%, 120%, 0);
    transform: translate3d(191%, 120%, 0);
  }
  40% {
    -webkit-transform: translate3d(-519%, -274%, 0);
    transform: translate3d(-519%, -274%, 0);
  }
  80% {
    -webkit-transform: translate3d(59%, 28%, 0);
    transform: translate3d(59%, 28%, 0);
  }
}

@keyframes firework {
  0% {
    top: 1.875rem;
    left: 1.875rem;
  }
  40% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.award-dot-animated:nth-child(14) {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: firework 1.3s ease-in-out,
    dots-animation-14 20s 1.7s infinite;
  animation: firework 1.3s ease-in-out, dots-animation-14 20s 1.7s infinite;
}
</style>
