<script>
import DrawerAction from '/~/components/drawer/components/actions/drawer-action.v3.vue'
import DefaultDrawerActions from '/~/components/drawer/components/actions/drawer-actions.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'drawer-actions-v3',
  components: {
    DrawerAction,
  },
  extends: DefaultDrawerActions,
})
</script>

<template>
  <div v-if="hasActions" class="space-y-[5px]">
    <template v-for="action in visibleActions">
      <drawer-action
        :key="action.label"
        :disabled="disabled"
        :action="action"
      />
    </template>
  </div>
</template>
