<script>
import { computed } from 'vue'
import { getProviderDefaultUrl } from '/~/utils/cdn'
import { formatDollar } from '/~/utils/format/money'
import BaseCard from '/~/components/base/card/base-card.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useCms } from '/~/composables/cms'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'travel-packages-tile',
  components: {
    BaseLink,
    BaseIcon,
    BaseImage,
    BaseCard,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { ewalletLabels } = useCms()
    const { isBupaTemplate } = useProvider()

    const defaultImage = computed(() =>
      isBupaTemplate.value ? getProviderDefaultUrl('bupa') : undefined
    )

    return {
      ewalletLabels,
      defaultImage,
      formatDollar,
    }
  },
  data: () => {
    return {
      isLoad: false,
    }
  },
  computed: {
    image() {
      const { offer } = this

      if (offer.hero_image) {
        return offer.hero_image
      }

      const gallery = offer?.gallery
      const images = gallery ? JSON.parse(gallery) : []

      return images[0]?.url
    },
    hasParams() {
      return this.offer.package_id
    },
    to() {
      return this.hasParams
        ? {
            name: 'travel-details',
            params: {
              slug: this.offer.package_id,
            },
          }
        : null
    },
  },
  methods: {
    loaded() {
      this.isLoad = true
    },
  },
}
</script>

<template>
  <base-link
    ref="link"
    :to="to"
    class="flex h-full flex-col overflow-hidden rounded-lg text-center"
    :class="{ 'cursor-default opacity-50': !hasParams }"
  >
    <base-card>
      <base-image
        :src="image"
        :default-src="defaultImage"
        :alt="offer.name"
        :rounded="false"
        aria-hidden="true"
      />
    </base-card>
    <div
      class="z-1 -mt-7 flex justify-end bg-gradient-to-t from-black p-2.5 text-white"
    >
      <ul class="flex items-center space-x-[15px] text-white">
        <li>
          <base-icon svg="plain/gift-v2" :size="13" />
        </li>
        <li>
          <base-icon svg="plain/plane-v2" :size="13" />
        </li>
        <li>
          <base-icon svg="plain/bed-v2" :size="13" />
        </li>
      </ul>
    </div>
    <div class="flex grow flex-col bg-light text-default">
      <div class="relative flex flex-col overflow-hidden p-[15px] font-bold">
        <h3 class="truncate text-base" v-html="offer.name" />
        <div class="text-sm text-eonx-neutral-600">
          {{ offer.room_type }}
        </div>
      </div>
      <div class="mt-auto border-t border-light py-[15px] font-bold">
        <span class="text-sm text-eonx-neutral-600">
          {{ offer.nights }} nights from
        </span>
        <div>
          <span>{{ formatDollar(offer.price) }}</span>
          <span>pp</span>
          <span class="text-sm font-normal text-eonx-neutral-600">
            + Bonus Value {{ formatDollar(offer.bonus_value) }}
          </span>
        </div>
      </div>
    </div>
    <footer
      class="flex h-12 shrink-0 items-center justify-center bg-gray-700 text-white"
      aria-hidden="true"
    >
      <base-icon svg="ewallet" :size="13" />
      <span class="ml-[5px] text-sm">
        Plus $100 {{ ewalletLabels.ewallet }} Cashback
      </span>
    </footer>
  </base-link>
</template>
