<script>
import ui from '/~/core/ui'
import { useRouter } from 'vue-router/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import EwDetailsHeader from '/~/components/ewallet/ew-details-header.vue'

export default {
  name: 'health-details-view',
  components: {
    BaseIcon,
    EwDetailsHeader,
  },
  props: {
    backLabel: {
      type: String,
      default: 'Back',
    },
    backRoute: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()

    function goBack() {
      if (props.backRoute) {
        router.replace(props.backRoute)
      } else {
        router.back()
      }
    }

    return {
      goBack,
      ui,
    }
  },
  watch: {
    '$route.params.type'() {
      const route = this.$route.matched.find((r) => r.name === this.$route.name)
      const parent = route.parent

      this.$router.push({
        name: parent.name,
        params: {
          type: this.$route.params.type,
        },
      })
    },
  },
}
</script>

<template>
  <div class="h-full w-full">
    <div class="relative">
      <ew-details-header
        v-if="ui.mobile"
        :title="data.title"
        :loading="false"
      />
      <div
        v-else
        class="absolute mt-[30px] ml-10 hidden cursor-pointer items-center rounded-lg bg-white px-5 py-2.5 font-bold text-primary hover:opacity-75 md:flex"
        @click="goBack"
      >
        <base-icon svg="plain/arrow-left" :size="12" class="mr-[15px]" />
        <span class="text-base">Back</span>
      </div>
      <img
        :src="data.banner"
        :alt="data.title"
        class="-mt-16 h-[344px] w-full object-cover sm:mt-0"
      />
    </div>
    <div class="mx-auto mt-[30px] w-full max-w-3xl px-5 text-eonx-neutral-800">
      <h1 class="text-3xl leading-tight sm:text-5xl">
        {{ data.title }}
      </h1>
      <div class="mt-[15px] mb-[35px] flex items-center">
        <div
          class="relative mr-6 px-[15px] py-[5px] text-sm leading-none text-primary"
        >
          <span
            class="pointers-none absolute inset-0 rounded-full bg-primary opacity-20"
          />
          {{ data.category }}
        </div>
      </div>
      <div v-html="data.text" />
    </div>
  </div>
</template>
