<script>
import { ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import { usePhotoIdsTransactions } from '/~/composables/photo-ids'

export default {
  name: 'drawer-photo-ids',
  components: {
    BaseButton,
  },
  emits: ['back'],
  setup() {
    const { createPhotoIdsTransaction } = usePhotoIdsTransactions()
    const redirectError = ref('')

    async function redirect() {
      try {
        await createPhotoIdsTransaction()
      } catch (error) {
        redirectError.value =
          error.message || 'Something went wrong, please try again later'
      }
    }

    redirect()

    return {
      redirectError,
      redirect,
    }
  },
}
</script>

<template>
  <div class="flex flex-col items-center py-[30px] text-center">
    <svg
      class="h-20 w-20 animate-pulse text-eonx-neutral-600"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
      />
    </svg>
    <p class="mt-5 text-lg" :class="[redirectError && 'text-eonx-neutral-600']">
      You will be redirected
      <br />
      to an external resource
    </p>
    <div v-if="redirectError" class="mt-5 flex flex-col text-error-700">
      <span>Failed to complete transaction request</span>
      <span class="text-sm">
        {{ redirectError }}
      </span>
      <div class="mt-[30px] flex w-full max-w-80 space-x-5">
        <div class="flex-1">
          <base-button size="sm" look="outlined" @click="$emit('back')">
            Back to Photo IDs
          </base-button>
        </div>

        <div class="flex-1">
          <base-button full-width size="sm" @click="redirect">
            Try again
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
