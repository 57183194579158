<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useScheduledPayments } from '/~/composables/scheduled-payments'

const { schedulePaymentDisabledMessage, isScheduledPaymentDisabled } =
  useScheduledPayments()
</script>

<template>
  <div
    v-show="isScheduledPaymentDisabled"
    class="inline-flex w-full items-center justify-start gap-2 rounded-md bg-blue-50 px-2 py-1"
  >
    <div class="flex h-6 w-6 items-center justify-center">
      <base-icon
        svg="plain/info-circle"
        :size="20"
        class="shrink-0 text-blue-700"
      />
    </div>
    <div
      class="shrink grow basis-0 font-['Lato'] text-sm font-normal leading-tight text-blue-700"
    >
      <span>{{ schedulePaymentDisabledMessage }}</span>
    </div>
  </div>
</template>
