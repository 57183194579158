<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'

const props = withDefaults(
  defineProps<{
    total?: number
    pointsToBurn?: number
    isPointsAvailable?: boolean
  }>(),
  {
    total: 0,
    pointsToBurn: 0,
    isPointsAvailable: false,
  }
)

const formattedPointsToBurn = computed(() => formatPoints(props.pointsToBurn))
</script>

<template>
  <div class="px-[15px] leading-7">
    <div class="text-right font-bold">
      {{ formatDollar(total) }}
    </div>
    <div v-if="isPointsAvailable" class="text-right font-bold text-primary">
      or use {{ formattedPointsToBurn }} PTS
    </div>
  </div>
</template>
