import { Loader } from '@googlemaps/js-api-loader'

export function loadGoogleMaps() {
  const loader = new Loader({
    apiKey: eonx.keys.google,
    version: 'weekly',
    libraries: ['places'],
    language: 'en',
  })

  return loader
}
