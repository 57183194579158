<script lang="ts" setup>
import { computed } from 'vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import { useUser } from '/~/composables/user'

withDefaults(
  defineProps<{
    action: object
    actionTitle?: string
    backgroundColor?: string
  }>(),
  {
    action: () => ({}),
    actionTitle: 'See my tier benefits',
  }
)

const { user } = useUser()

const userMembershipName = computed(() => {
  const name = user.value.membershipName

  return name + (name.toLowerCase().includes('member') ? '' : ' Member')
})
</script>

<template>
  <div
    class="rounded-md p-4 text-center text-white"
    :class="{
      'bg-primary': !backgroundColor,
    }"
    data-cy="widget-bupa-membership-level"
    :style="{ backgroundColor }"
  >
    <div class="space-y-4">
      <div v-if="user">
        <div class="font-medium">
          Welcome back {{ user.fullName }} to your Life Rewards Member Account.
        </div>
        <div class="font-semibold">
          You are currently a {{ userMembershipName }}
        </div>
      </div>
      <div v-else class="font-medium">
        Welcome to your Life Rewards Member Account.
      </div>
      <base-action v-bind="action" class="inline-block">
        <div class="font-semibold text-white underline">{{ actionTitle }}</div>
      </base-action>
    </div>
  </div>
</template>
