<script>
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'

export default {
  name: 'activity-details-cashback-print',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      formatDate,
      formatDollar,
    }
  },
}
</script>

<template>
  <div>
    <div class="table w-full border-b border-black pb-6 font-bold">
      <div class="table-cell w-0.5 align-top">
        Transaction No. {{ content.id }}
      </div>
      <div class="table-cell w-0.5 align-top">
        <span class="float-right text-right leading-snug">
          {{ formatDate('daymonthyearlongtime', content.date) }}
        </span>
      </div>
    </div>

    <div class="pt-11">
      <table class="w-full">
        <tr class="border-b font-bold">
          <td class="px-0 py-5">Item</td>
          <td class="py-5 pr-0" />
          <td class="text py-5 pr-0 text-right">Total</td>
        </tr>
        <tr class="border-b">
          <td class="px-0 py-5">
            {{ content.description ? content.description[0] : '' }}
            <br />
            {{ content.description ? content.description[1] : '' }}
          </td>
          <td class="py-5 pr-0" />
          <td class="text py-5 pr-0 text-right">
            {{ formatDollar(content.total) }}
          </td>
        </tr>
        <tr class="h-5 border-b" />
        <!-- Fees !-->
        <tr class="font-bold">
          <td class="px-0 py-5" />
          <td class="py-5 pr-0">Total</td>
          <td class="text py-5 pr-0 text-right text-2xl">
            <strong>{{ formatDollar(content.total) }}</strong>
          </td>
        </tr>
        <!-- /Fees !-->
      </table>
    </div>
  </div>
</template>
