import modal from '/~/core/mdl'
import { useProvider } from '/~/composables/provider'
import { useStatementAutoPayments } from '/~/composables/statements'
import { useUser } from '/~/composables/user'

const {
  directDebitConfirmed,
  updateDirectDebitsConfirmation,
  userMetaDataLoaded,
} = useUser()
const { isBillPaymentsTemplate } = useProvider()
const { isAutoPayEnabled } = useStatementAutoPayments()

export function showDirectDebitsConfirmationModal() {
  const modalName = 'cancel-existing-direct-debits-confirmation'

  modal.show(modalName, {
    props: {
      onConfirm: () => {
        updateDirectDebitsConfirmation(true)
        modal.hide(modalName)
      },
    },
  })
}

export function initDirectDebitsConfirmationModal() {
  if (
    userMetaDataLoaded.value &&
    isBillPaymentsTemplate.value &&
    !directDebitConfirmed.value &&
    isAutoPayEnabled.value
  ) {
    showDirectDebitsConfirmationModal()
  }
}
