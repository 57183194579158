<script setup lang="ts">
import { ref, computed } from 'vue'
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import { Content, Image, Link } from '/~/composables/cms/use-cms'

const props = withDefaults(
  defineProps<{
    info: Content
    heroImage: Image
    heroImageRounded?: boolean
    bannerAction: Link
    backgroundColor?: string
    backgroundImage: Image
    textColor?: string
    separatorColor?: string
    side?: string
  }>(),
  {
    heroImageRounded: true,
  }
)

const banner = ref<HTMLDivElement | null>(null)
const bannerStyle = computed(() => ({
  color: props.textColor,
  textAlign: props.info.contentAlignmentX,
  backgroundColor: props.backgroundColor,
  backgroundImage: `url(${cdn(props.backgroundImage.desktop)
    .preview('600x812')
    .format('auto')
    .quality('smart')
    .url()}`,
}))
</script>

<template>
  <section>
    <base-action
      class="block hover:opacity-90"
      v-bind="bannerAction"
      origin="banner"
      appearance="link"
    >
      <div
        ref="banner"
        class="flex w-auto flex-col items-center rounded-3xl bg-cover md:max-w-[300px]"
        :class="{ 'bg-contain': banner && banner.clientHeight > 300 }"
        :style="bannerStyle"
      >
        <div class="px-5 pt-5">
          <img
            :src="
              cdn(heroImage.desktop)
                .preview('520x160')
                .format('auto')
                .quality('smart')
                .url()
            "
            :alt="heroImage.alt"
            :class="heroImageRounded && 'rounded-xl'"
          />
        </div>
        <div class="px-5 pb-5 pt-3">
          <div
            v-if="info.title"
            class="block text-center text-[23px] font-semibold"
            v-html="info.title"
          />
          <div
            class="my-3 flex-grow border-t"
            :style="{ borderColor: separatorColor }"
          ></div>
          <div
            v-if="info.text"
            class="block text-center text-sm"
            v-html="info.text"
          />
        </div>
      </div>
    </base-action>
  </section>
</template>
