import modal from '/~/core/mdl'

export function editMembers() {
  const room = this.room

  if (!room.isMeOwner || !room.isChannel) return

  const props = {
    props: {
      source: this.room,
      editable: true,
      status: 'active',
    },
    on: {
      delete: async (member) => {
        if (room) {
          await room.removeMembers([member.id])
        }
      },
      save: (members) => {
        const membersIds = members.map((user) => user.id)

        room.updateMembers(membersIds)
        modal.hideAll()
      },
    },
  }

  modal.show('rec-members-manager', props)
}
