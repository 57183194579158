<script>
import ui from '/~/core/ui'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import AwardTile from '/~rec/components/award/award-tile.vue'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import RecBaseState from '/~rec/components/state/base-state.vue'
import { useAwards } from '/~rec/composables/awards'
import { useRecProfile } from '/~rec/composables/profile'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'award-nominate-user',
  components: {
    BaseBack,
    BaseLoader,
    AwardTile,
    EwCatalogProcessor,
    RecBaseState,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { awards } = useAwards()
    const { orgMemberId } = useRecProfile()
    const { getMember } = useGroups()

    return {
      awards,
      orgMemberId,
      getMember,
      ui,
    }
  },
  data() {
    return {
      loading: false,
      profile: this.user,
    }
  },
  computed: {
    processor() {
      return this.awards.available
    },
    fullName() {
      return this.profile?.fullName
    },
    fetching() {
      return this.loading || this.processor.processing
    },
    nomineeId() {
      return this.$route.params.nomineeId
    },
  },
  watch: {
    nomineeId(id) {
      if (id) {
        this.loadData()
      }
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    async fetchAwards() {
      await this.processor.getData({
        query: {
          canBeNominated: this.profile.orgMemberId,
          canNominate: this.orgMemberId,
        },
      })

      this.processor.filterHits((hit) => hit.isReached)
    },
    selectAward(award) {
      this.$router.push({
        name: 'rec-award-nominate',
        params: {
          awardId: award.id,
          awardStatus: 'available',
          nominee: this.profile,
          award,
        },
        query: { nominateTo: this.profile.id },
      })
    },
    async loadData() {
      this.loading = true

      this.profile = this.getMember(this.nomineeId)

      await this.fetchAwards()

      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
}
</script>

<template>
  <div class="mx-auto flex w-full max-w-screen-md flex-col">
    <base-loader v-if="fetching" class="absolute inset-0" fullwidth />

    <div class="hidden md:block">
      <base-back :to="{ name: 'rec-awards' }" class="mb-6" />
    </div>

    <div v-if="profile && !fetching" class="pb-5">
      <h2
        class="text-eonx-neutral-800"
        :class="{
          'text-base leading-6': ui.mobile,
        }"
      >
        Nominate {{ fullName }}!
      </h2>

      <div
        class="text-base leading-6 text-eonx-neutral-600 md:text-eonx-neutral-800"
      >
        Start by selecting an award from the list below.
      </div>

      <div class="flex grow flex-col pt-5 md:pt-0">
        <ew-catalog-processor
          :processor="processor"
          :columns="{
            xs: 2,
            lg: 3,
            xl: 4,
          }"
          tile-class="rounded-lg shadow-xs cursor-pointer hover:shadow-lg hover:-translate-y-[5px] bg-light"
        >
          <template #tile="{ tile: award }">
            <award-tile :award="award" @click="selectAward(award)" />
          </template>

          <template #empty>
            <rec-base-state
              class="mt-16 mb-0 md:mt-[120px]"
              image="recroom/state/awards-available-empty-state.svg"
              title="Something great is coming your way!"
              subtitle="Unfortunately, there are no awards available"
            />
          </template>
        </ew-catalog-processor>
      </div>
    </div>
  </div>
</template>
