<script>
import { nextTick } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useCms } from '/~/composables/cms'

export default {
  name: 'profile-points-converter',
  components: {
    BaseInput,
    BaseIcon,
  },
  props: {
    type: {
      type: String,
      default: null,
      validator: (v) => /buy|transfer/.test(v),
    },
    conversionRate: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const { ewalletLabels } = useCms()

    return {
      ewalletLabels,
    }
  },
  data() {
    return {
      valueFrom: '',
      valueTo: '',
    }
  },
  computed: {
    details() {
      const isBuy = this.type === 'buy'

      return {
        prefixFrom: isBuy ? '$' : 'Pts',
        labelFrom: isBuy ? 'Amount' : this.ewalletLabels.ewalletPoints,
        prefixTo: 'Pts',
        labelTo: isBuy ? this.ewalletLabels.ewalletPoints : 'Points Partner',
      }
    },
  },
  methods: {
    calculate(source, val) {
      const { conversionRate } = this

      this[source] = +val

      if (source === 'valueFrom') {
        this.valueTo = +val * +conversionRate
      } else {
        this.valueFrom = +val / +conversionRate
      }

      nextTick(() => {
        this.$emit('converted', {
          valueFrom: this.valueFrom,
          valueTo: this.valueTo,
        })
      })
    },
  },
}
</script>

<template>
  <div class="flex items-center">
    <base-input
      :value="valueFrom"
      :label="details.labelFrom"
      @input="calculate('valueFrom', $event)"
    >
      <template #prefix>
        <span>
          {{ details.prefixFrom }}
        </span>
      </template>
    </base-input>
    <div class="mx-2.5 shrink-0">
      <base-icon svg="plain/transfer" size="md" />
    </div>
    <base-input
      :value="valueTo"
      :label="details.labelTo"
      @input="calculate('valueTo', $event)"
    >
      <template #prefix>
        <span>
          {{ details.prefixTo }}
        </span>
      </template>
    </base-input>
  </div>
</template>
