<script>
import modal from '/~/core/mdl'
import RichInput from '/~rec/components/rich-input/rich-input.vue'
import ChatRoomMessages from './chat-room-messages.vue'
import debounce from 'lodash-es/debounce'
import BaseButton from '/~/components/base/button/base-button'
import RecBaseState from '/~rec/components/state/base-state.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import {
  fromIdToMention,
  fromMentionToId,
  fromIdToMentionAndroid,
  fromMentionToIdAndriod,
} from '/~rec/utils/process-text-for-mentions'
import { useForm } from '/~/composables/base/use-form'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'rec-chat-room',
  components: {
    RichInput,
    ChatRoomMessages,
    BaseButton,
    RecBaseState,
    BaseLoader,
    ValidationObserver,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { validationObserverRef } = useForm()

    return {
      validationObserverRef,
    }
  },
  data() {
    const isAndriod = /Android/i.test(navigator.userAgent)

    return {
      typing: false,
      editing: undefined,
      form: {
        body: '',
        attachments: [],
      },
      isAndriod,
      mentioned: [],
      isOffensiveWarningShow: false,
    }
  },
  computed: {
    isSendDisabled() {
      return (
        (!this.form.body.trim() && this.form.attachments.length === 0) ||
        !!(
          this.validationObserverRef?.errors.length &&
          !this.$refs.reply?.validationProviderRef?.failedRules['offensive']
        )
      )
    },
  },
  watch: {
    'form.body'() {
      if (this.$refs.messagesBox) {
        this.$refs.messagesBox.scrollBottom()
      }

      if (!this.typing) {
        this.typing = true
        this.room.meTyping(true)
      }

      this.debounceStopTyping()
    },
    active(isActive) {
      if (isActive) this.$refs.reply.focus()
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    debounceStopTyping: debounce(function () {
      this.typing = false
      this.room.meTyping(false)
    }, 800),
    async onSend() {
      const send = async () => {
        const mentioned = this.mentioned.map((m) => ({
          ...m,
          id: m.user_id || m.id,
          nanoId: m.user_id ? m.user_id.slice(0, 5) : m.id.slice(0, 5),
        }))

        const body = this.isAndriod
          ? fromMentionToIdAndriod(
              this.form.body.replace(/\n(\s*)(?=\n)/g, '\n'),
              mentioned
            )
          : fromMentionToId(
              this.form.body.replace(/\n(\s*)(?=\n)/g, '\n'),
              mentioned
            )

        if (this.editing) {
          await this.room.updateMessage(this.editing, {
            attachments: this.form.attachments,
            body,
            mentions: this.mentioned.map((v) => v.user_id || v.id),
          })
        } else {
          await this.room.createMessage({
            attachments: this.form.attachments,
            body,
            request_id: Date.now() + '',
            mentions: this.mentioned.map((v) => v.user_id || v.id),
          })
          if (this.$refs.messagesBox) {
            this.$refs.messagesBox.scrollBottom(true)
          }
        }

        this.editing = undefined
        this.form.body = ''
        this.form.attachments = []
        if (this.isAndriod) {
          this.validationObserverRef.validate()
        }
      }

      if (
        this.$refs.reply?.validationProviderRef?.failedRules['offensive'] &&
        !this.isOffensiveWarningShow
      ) {
        this.isOffensiveWarningShow = true
        modal.show('rec-chat-offensive-warning', {
          props: {
            send,
          },
          on: {
            closeOffensiveWarning: () => {
              this.isOffensiveWarningShow = false
            },
          },
        })
      } else {
        const isValid = await this.validationObserverRef.validate()

        if (!isValid) return
        send()
      }
    },
    onEdit(message) {
      let mentioned =
        (message.raw.mentions &&
          Object.keys(message.raw.mentions).map(
            (key) => message.raw.mentions[key]
          )) ||
        []
      let body = message.body.replace(/\n(\s*)(?=\n)/g, '\n')

      if (this.isAndriod) {
        const postData = fromIdToMentionAndroid(body, message.raw.mentions)

        body = postData.result
        mentioned = postData.mentioned
      }

      if (!this.isAndriod) {
        body = fromIdToMention(body, message.raw.mentions)
      }

      this.editing = message
      this.form.body = body
      this.form.attachments = message.raw.attachments
      this.mentioned = mentioned
    },
    stopEdit() {
      this.editing = undefined
      this.form.body = ''
      this.form.attachments = []
    },
    async loadData() {
      await this.room.messages.getData()

      if (this.$refs.messagesBox) {
        this.$refs.messagesBox.scrollBottom(true)
      }
    },
    async onLoadMore() {
      await this.room.messages.next()

      if (this.$refs.messagesBox) {
        this.$refs.messagesBox.fixPosition()
      }
    },
    onInput(body) {
      if (this.isAndriod) {
        this.form.body = body
      }
    },
    updateMentioned(value) {
      if (Array.isArray(value)) {
        this.mentioned = value
      } else {
        const { user, add } = value

        if (add) {
          this.mentioned = [...this.mentioned, { ...user }]
        } else {
          if (!user) {
            this.mentioned = []
          } else {
            for (const idx in this.mentioned) {
              if (this.mentioned[idx].nanoId === user.nanoId) {
                this.mentioned.splice(idx, 1)
                break
              }
            }
          }
        }
      }
    },
    onEmojiSelect(emoji) {
      this.form.body += emoji
    },
  },
}
</script>

<template>
  <validation-observer
    ref="validationObserverRef"
    slim
  >
    <div class="chat-room relative flex flex-col">
      <base-loader
        v-if="room.messages.isLoading"
        class="absolute top-0 z-10 h-full bg-white"
        fullwidth
      />
      <rec-base-state
        v-else-if="room.messages.isEmpty"
        class="mx-5 grow"
        wide
        image="recroom/state/chat-empty-state.svg"
      />
      <chat-room-messages
        v-else
        ref="messagesBox"
        class="flex-1 grow"
        :room="room"
        @edit-message="onEdit"
        @load-more="onLoadMore"
      />
      <div v-if="editing" class="flex justify-between border-t px-2.5 py-2.5">
        <span class="text-sm">Edit message</span>
        <base-button size="sm" icon="plain/close" @click="stopEdit" />
      </div>
      <rich-input
        ref="reply"
        :validation="{
          rules: 'offensive|max:2000',
          mode: 'aggressive',
        }"
        :content.sync="form.body"
        :mention-users="room.members"
        :attachments.sync="form.attachments"
        :disable-send="isSendDisabled"
        placeholder="Write a reply"
        attachments-position="popup"
        class="border-t"
        :class="{
          'px-[15px] pt-[15px]': isAndriod,
        }"
        name="reply"
        focused
        plain
        :is-andriod="isAndriod"
        :max-length="500"
        :mentioned="mentioned"
        :is-offensive-warning-show="isOffensiveWarningShow"
        @send="onSend"
        @update:mentioned="updateMentioned"
        @input="onInput"
      />
    </div>
  </validation-observer>
</template>
