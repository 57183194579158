<script setup lang="ts">
import { computed } from 'vue'
import { Payee } from '/~/types/api'
import bottomSheet from '/~/core/bottom-sheet'
import BaseButton from '/~/components/base/button/base-button'
import PayForm from '../../views/payments/make/components/pay-form.vue'

withDefaults(
  defineProps<{
    payee: Payee
  }>(),
  {
    payee: () => ({} as Payee),
  }
)

const emit = defineEmits<(event: 'confirm', payload: any) => void>()

const modalTitle = computed(() => 'Enter payment information')

function handleConfirm(payload: {
  reference: string
  amount: number
  scheduledAt: string
}) {
  emit('confirm', payload)
  bottomSheet.hide()
}

function handleHide() {
  bottomSheet.hide()
}
</script>

<template>
  <div class="pt-5">
    <div class="px-5 text-center text-xl font-bold">
      {{ modalTitle }}
    </div>
    <div class="absolute right-5 top-5">
      <base-button icon="close" class="cursor-pointer" @click="handleHide" />
    </div>
    <div class="p-5">
      <pay-form :payee="payee" @confirmed="handleConfirm" @hide="handleHide" />
    </div>
  </div>
</template>
