<script>
import EventHeaderMobile from '/~rec/components/event/event-header-mobile.vue'
import EventFeed from '/~rec/components/event/feed/event-feed.vue'
import PostCreatorFab from '/~rec/components/post/creator/post-creator-fab.vue'
import { RecroomEvent } from '/~rec/core/event'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useEvents } from '/~rec/composables/events'
import { useFeeds } from '/~rec/composables/feeds'

export default {
  name: 'rec-event-details-modal',
  components: {
    EventHeaderMobile,
    PostCreatorFab,
    EventFeed,
    BaseMdl,
  },
  provide() {
    return {
      sources: this.sources,
    }
  },
  props: {
    eventId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { events } = useEvents()
    const { isNewPostProcessing } = useFeeds()

    return { events, isNewPostProcessing }
  },
  data() {
    return {
      sources: {
        source: null,
      },
      event: null,
    }
  },
  computed: {
    feedId() {
      return this.events?.feed?.feedId || 'all'
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.event =
        this.$route?.params?.initialEvent ||
        (await RecroomEvent.fetchById(this.eventId))
      this.sources.source = this.event

      await this.event.members.getData()
      await this.event.posts.getData()
    },
    async onPostCreated() {
      this.isNewPostProcessing = true
      await this.event.posts.refresh()
      setTimeout(() => {
        this.isNewPostProcessing = false
      }, 200)
    },
    onBack() {
      const { feedId } = this

      this.$router.push({ name: 'rec-events', params: { feedId } })
    },
    onNewPostsLoaded() {
      const content = document.getElementById('rec-event-mobile')

      content.scrollIntoView({
        block: 'start',
      })
    },
  },
}
</script>

<template>
  <base-mdl transition="slide-right" width="screen" :layout="false" fullscreen>
    <div id="rec-event-mobile" class="flex h-full flex-col">
      <template v-if="event">
        <event-header-mobile :event="event" @back="onBack" />

        <div class="flex-1 bg-dark">
          <event-feed
            :event="event"
            class="mt-6 bg-dark sm:mt-0"
            @posts-loaded="onNewPostsLoaded"
          />

          <post-creator-fab :source="event" @created="onPostCreated" />
        </div>
      </template>
    </div>
  </base-mdl>
</template>
