<script>
export default {
  name: 'base-progress-bar',
  props: {
    progress: {
      type: Number,
      default: 0,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    progressLine() {
      return Math.round((this.progress / this.total) * 100)
    },
  },
}
</script>

<template>
  <div class="h-1.5 overflow-hidden rounded-full bg-divider-light">
    <div
      class="h-1.5 rounded-full bg-primary transition-width duration-500 ease-out"
      :style="{ width: `${progressLine}%` }"
    />
  </div>
</template>
