import configuration from './configuration.json'
import content from './content.json'
import design from './design.json'
import ewallet from './ewallet'
import header from './header.json'
import menus from './menus'
import modals from './modals.json'
import modules from './modules.json'
import redirects from './redirects.json'

export default {
  configuration,
  content,
  design,
  menus,
  modals,
  modules,
  redirects,
  ewallet,
  header,
}
