<script>
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'question-select-multiple',
  components: {
    BaseButton,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      values: [],
    }
  },
  methods: {
    toggleValue(i, y) {
      this.values[i] = y
      this.values = [...this.values]
    },
    confirm() {
      this.$emit('input', this.values.join(', '))
      this.$emit('next')
      this.values = []
    },
  },
}
</script>

<template>
  <div>
    <div v-for="(action, i) in content.actions" :key="i" class="mb-2.5">
      <h3 class="mb-2.5 text-eonx-neutral-600">
        {{ action.name }}
      </h3>
      <div class="mb-[5px] flex flex-wrap">
        <div
          v-for="(radio, y) in action.values"
          :key="y"
          class="relative mx-[5px] mb-2.5 flex cursor-pointer items-center justify-center self-start overflow-hidden rounded px-[15px] py-2.5"
          @click="toggleValue(i, y)"
        >
          <div
            class="absolute inset-[5px] z-0 h-full w-full bg-primary"
            :class="[values[i] !== y && 'opacity-25']"
          />
          <div
            class="z-1 mr-2.5 flex h-[15px] w-[15px] items-center justify-center rounded-full border-2 border-primary"
            :class="[values[i] === y && 'border-white bg-white']"
          />
          <span
            class="z-1 font-bold"
            :class="[values[i] === y ? 'text-white' : 'text-primary']"
          >
            {{ radio }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <base-button
        class="mx-[5px] mb-[15px] w-40"
        :disabled="values.length !== content.actions.length"
        @click="confirm"
      >
        Confirm
      </base-button>
    </div>
  </div>
</template>
