<script>
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { usePointsProgramsModals } from '/~/templates/bill-payments/composables'

export default {
  name: 'points-partner',
  components: {
    BaseIcon,
    BaseMenu,
    BaseButton,
    BaseImage,
  },
  props: {
    partner: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    fill: {
      type: Boolean,
      default: true,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { showRemoveAccountModal } = usePointsProgramsModals()

    return {
      showRemoveAccountModal,
    }
  },
  computed: {
    type() {
      return this.partner.type
    },
    icon() {
      return this.partner.icon
    },
    description() {
      return this.partner.description
    },
    number() {
      return this.partner.number
    },
    count() {
      return this.partner.count
    },
    hasCount() {
      return this.count > 0
    },
    menuItems() {
      return [
        {
          content: 'Transfer points',
          click: () => this.onClick(),
        },
        {
          content: 'Remove',
          click: () => this.onDelete(),
        },
      ]
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('click', this.partner)
      }
    },
    async onDelete() {
      await this.showRemoveAccountModal(this.partner)
    },
  },
}
</script>

<template>
  <div
    class="flex min-h-20 shrink-0 rounded-md border border-neutral-200 py-4 outline outline-2 outline-transparent transition-all duration-1000 sm:rounded-lg"
    :class="{
      'hover:outline-primary': !disabled && arrow,
      'bg-white px-4 sm:px-5': fill,
    }"
    @click="onClick"
  >
    <div
      class="flex w-full items-center"
      :class="{
        'cursor-pointer': !disabled && arrow,
        'opacity-25': disabled,
      }"
    >
      <div class="mr-5 flex w-20 items-center justify-center">
        <base-image class="h-full w-full" :src="icon" :ratio="false" />
      </div>
      <div class="text-sm">
        <div>
          <div class="font-bold">
            {{ partner.name || 'Select partner' }}
          </div>
          <div v-if="counter && hasCount" class="text-sm text-neutral-400">
            Accounts added ({{ count }})
          </div>
          <div v-if="description" class="text-sm text-neutral-400">
            {{ description }}
          </div>
        </div>
        <div v-if="number" class="text-sm text-eonx-neutral-600">
          {{ number }}
        </div>
      </div>
      <div class="ml-auto flex h-10 flex-col justify-center">
        <base-icon
          v-if="arrow && !disabled"
          svg="plain/chevron-right.v2"
          size="md"
        />

        <base-menu
          v-else-if="showMenu && !disabled"
          ref="menu"
          :items="menuItems"
          position="right"
          class="mt-2"
        >
          <template #trigger>
            <base-button
              icon="heroicons/outline/ellipsis-vertical"
              alt="close"
              :size="28"
              :padding="2"
            />
          </template>
        </base-menu>
      </div>
    </div>
  </div>
</template>
