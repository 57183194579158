/* eslint-disable */
import { createDate, formatDate as localizeDate } from '/~/utils/format/date'
import api from '/~rec/core/api'
import { RecroomEvent } from '/~rec/core/event'
import { useRecProfile } from '/~rec/composables/profile'

const { myRecId } = useRecProfile()

export class EventsProcessor {
  constructor({ feedId } = {}) {
    this.feedId = feedId
    this.processing = true
    this.months = []
    this.allEvents = []
    this.stopAppend = false
    this.response = null
    this.page = 1
    this.startDate = null
  }

  get length() {
    return this.months.length
  }

  get isEmpty() {
    return this.length === 0
  }

  getData(startDate) {
    this.startDate = startDate
    this.months = []
    return this.loadTenEvents()
  }

  /*
   * Getting 10 events next to the current date
   */
  async loadTenEvents(startDate) {
    this.processing = true

    await this.fetchTenEvents(startDate)

    if (this.page !== 1) this.months = []
    this.setMonthsArray()
    this.processing = false
  }

  setMonthsDateArray() {
    const monthsDate = []

    // Create array with dates of months
    this.allEvents.forEach((event) => {
      const startsAtMonthNumber = createDate(event.startsAt).get('month')
      const startsAtYearNumber = createDate(event.startsAt).get('year')

      // Check if month is already in array
      const monthIsHere = monthsDate.some((item) => {
        const iteratedMonthNumber = item.get('month')
        const iteratedYearNumber = item.get('year')

        return (
          iteratedMonthNumber === startsAtMonthNumber &&
          startsAtYearNumber === iteratedYearNumber
        )
      })

      // If this month is not in the array then add it here.
      if (!monthIsHere) {
        monthsDate.push(createDate(event.startsAt))
      }
    })
    return monthsDate
  }

  setMonthsArray() {
    const monthsDate = this.setMonthsDateArray()

    monthsDate.forEach((monthDate) => {
      const monthEvents = new EventsMonth(monthDate)

      monthEvents.filterEvents(this.allEvents)
      this.months = this.months.concat(monthEvents)
    })
  }

  async fetchTenEvents() {
    const date = createDate(this.startDate)
    const from = date.startOf('day').toISOString()

    const filters = {
      all: {},
      attending: {
        status: 'yes',
        user: myRecId.value,
      },
      hosting: {
        is_owner: true,
        user: myRecId.value,
      },
    }[this.feedId]

    this.response = await api.get('/events', {
      params: {
        page: this.page,
        from,
        perPage: 10,
        'types[]': 'custom',
        ...(filters || {}),
      },
    })
    this.updateEventsList()
  }

  updateEventsList() {
    const { items, pagination } = this.response.data

    this.setStopAppend(pagination)
    const newItems = items.map((item) => {
      return new RecroomEvent(item)
    })

    this.allEvents = this.allEvents.length
      ? [...this.allEvents, ...newItems]
      : newItems
  }

  setStopAppend(pagination) {
    if (pagination && pagination.has_next_page) {
      this.stopAppend = false
    } else {
      this.stopAppend = true
    }
  }

  /*
   * Getting events for the three next months
   */
  next() {
    this.page = this.page + 1
    return this.loadTenEvents()
  }
}

/*
 * Helper class for receiving and rendering events in a month by the days
 */
export class EventsMonth {
  constructor(monthDate) {
    this.date = monthDate
    this.events = []
    this.error = ''
  }

  get isEmpty() {
    return this.events.length === 0
  }

  get eventsByDays() {
    const result = []

    if (this.events.length > 0) {
      const days = {}
      const firstMonthDate = this.date.clone().date(1)
      const daysInMonth = firstMonthDate.daysInMonth()

      Array.apply(null, Array(daysInMonth)).forEach((_, index) => {
        const day = firstMonthDate.add(index, 'day')
        const formatted = this.formatDate(day)

        days[formatted] = {
          date: day,
          key: formatted,
          events: [],
        }
      })

      this.events.forEach((event) => {
        const day = this.formatDate(event.startsAt)

        if (days[day]) days[day].events.push(event)
      })

      Object.keys(days).forEach((day) => {
        days[day].events = days[day].events.sort((a, b) => {
          const startA = a.startsAt.valueOf()
          const startB = b.startsAt.valueOf()

          if (startA === startB) {
            return a.endsAt.valueOf() - b.endsAt.valueOf()
          }
          return startA - startB
        })
        result.push(days[day])
      })
    }
    return result
  }

  formatDate(date) {
    return localizeDate('daymonthyear', date)
  }

  filterEvents(events) {
    events.forEach((event) => {
      const eventMonth = createDate(event.startsAt).get('month')

      if (eventMonth === this.date.get('month')) {
        this.events = this.events.concat(event)
      }
    })
  }
}
