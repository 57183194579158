<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
interface Props {
  onConfirm?: () => void
}
const props = withDefaults(defineProps<Props>(), { onConfirm: () => {} })
const emit = defineEmits(['hide'])

function onConfirmHandler() {
  props.onConfirm()
  hide()
}

function hide() {
  emit('hide')
  modal.hide()
}
</script>

<template>
  <base-mdl
    width="xse"
    layout="v2"
    border-radius="md"
    modal-class="border"
    padding="xs"
  >
    <template #header>
      <header
        class="relative z-40 flex flex-col border-b transition duration-200"
      >
        <div class="flex flex-col items-center justify-between">
          <h2 class="py-5 text-2xl tracking-tight text-eonx-neutral-800">
            Cancel payment
          </h2>
          <div
            class="absolute top-1/2 right-6 flex -translate-y-1/2 transform items-center"
          >
            <base-button
              ref="closeButton"
              icon="heroicons/solid/x-mark"
              :size="32"
              alt="close"
              data-test="modal-close"
              @click="hide"
            />
          </div>
        </div>
      </header>
    </template>

    <div class="px-1 pt-6 pb-1">
      <div class="text-eonx-neutral-800">
        Cancelling will navigate you away from the current payment process. Are
        you sure you want to proceed?
      </div>
    </div>

    <template #footer>
      <div class="flex gap-x-3 border-t border-default p-6">
        <base-button class="flex-1" look="outlined-color" @click="hide">
          Cancel
        </base-button>
        <base-button class="flex-1" @click="onConfirmHandler">
          Confirm
        </base-button>
      </div>
    </template>
  </base-mdl>
</template>
