<script setup lang="ts">
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { ScheduledPayment } from '/~/composables/scheduled-payments/core/ScheduledPayment'
import ItemPaymentMethod from './item/item-payment-method.vue'
import { useScheduledPaymentItemComponent } from './item.component'

const props = defineProps<{
  payment: ScheduledPayment
}>()

const {
  order,
  isPaymentOrder,
  isStatementOrder,
  payee,
  isBankAccountPayee,
  isBpayPayee,
  icon,
  onDeleteClick,
  translate,
  formatBsb,
  formatDate,
  formatDollar,
  paymentMethods,
} = useScheduledPaymentItemComponent(props)
</script>

<template>
  <div class="relative flex min-h-14 justify-between rounded-lg bg-white p-5">
    <div v-if="isPaymentOrder" class="flex w-2/5 md:w-1/3">
      <div
        class="mr-5 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full"
        :class="{
          'bg-eonx-neutral-600': isBankAccountPayee,
          'bg-brand-bpay': isBpayPayee,
        }"
      >
        <base-icon :svg="icon" :size="32" class="text-white" />
      </div>
      <div class="mb-[5px] sm:mb-0 sm:pr-5">
        <div class="w-[174px] truncate">
          <span class="font-semibold text-eonx-neutral-800">
            {{ payee.name }}
          </span>
        </div>
        <div
          class="mt-[5px] flex space-x-2 truncate text-sm text-eonx-neutral-600"
        >
          <template v-if="isBpayPayee">
            <div>Biller: {{ payee.billerCode }}</div>
            <div>Ref: {{ payee.reference }}</div>
          </template>
          <template v-else-if="isBankAccountPayee">
            {{
              translate('bankAccount.details', {
                acc: `*** *** *${payee.accountNumber.slice(-2)}`,
                bsb: formatBsb(payee.bsb || ''),
              })
            }}
          </template>
        </div>
      </div>
    </div>
    <div v-else-if="isStatementOrder" class="flex w-2/5 md:w-1/3">
      <div
        class="mr-5 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full"
      >
        <payee-icon :payee="payee" />
      </div>
      <div class="mb-[5px] sm:mb-0 sm:pr-5">
        <div class="w-[188px] truncate">
          <span class="font-semibold text-eonx-neutral-800">
            {{ payee.name }}
          </span>
        </div>
        <div
          class="mt-[5px] flex space-x-2 truncate text-sm text-eonx-neutral-600"
        >
          <span>Account no. {{ order.userStatementAccount.number }}</span>
        </div>
      </div>
    </div>
    <div class="w-1/6">
      <div class="truncate text-sm text-eonx-neutral-600">Scheduled date</div>
      <div class="mt-[5px] text-eonx-neutral-800">
        {{ formatDate('daymonthyearnumeric', order.scheduledAt) }}
      </div>
    </div>
    <div class="block w-1/6 pr-2.5">
      <div class="text-sm text-eonx-neutral-600">Amount</div>
      <div class="mt-[5px] text-eonx-neutral-800">
        {{ formatDollar(order.subtotal) }}
      </div>
    </div>
    <div class="hidden w-1/6 md:block">
      <div class="flex text-sm text-eonx-neutral-600">
        <span class="mr-[5px]">Fees</span>
        <v-popover
          v-if="payment.totalFees"
          trigger="hover click"
          placement="top-start"
          popover-inner-class="bg-neutral-700 text-white py-[5px] px-2.5 rounded-md text-xs"
          popover-arrow-class="hidden"
        >
          <base-icon
            svg="v2/custom/info-circle-filled"
            :size="16"
            class="cursor-pointer text-primary"
          />
          <template #popover>
            <div v-if="payment.programFees">
              <b class="mr-[5px]">Program Fee:</b>
              {{ formatDollar(payment.programFees) }}
            </div>
            <div v-if="payment.processingFees">
              <b class="mr-[5px]">Processing Fee:</b>
              {{ formatDollar(payment.processingFees) }}
            </div>
          </template>
        </v-popover>
      </div>
      <div class="mt-[5px] text-eonx-neutral-800">
        {{ formatDollar(payment.totalFees) }}
      </div>
    </div>
    <div class="hidden w-1/3 md:block">
      <div class="truncate text-sm text-eonx-neutral-600">Payment method</div>
      <div class="mt-[5px] space-y-[5px] text-eonx-neutral-800">
        <item-payment-method
          v-for="method in order?.scheduledPaymentMethods"
          :key="method.id || method.number"
          :method="method"
        />
      </div>
    </div>
    <div class="flex items-center justify-end">
      <base-loader v-if="payment.isLoading" size="xs" />
      <base-button
        v-else
        type="button"
        icon="heroicons/solid/trash"
        :size="24"
        :padding="2"
        alt="Delete scheduled payment"
        class="text-red-700"
        @click="onDeleteClick"
      />
    </div>
  </div>
</template>
