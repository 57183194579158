<script>
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import RenderRelatedAvatars from '/~rec/components/render/render-related-avatars.vue'
import BaseButton from '/~/components/base/button/base-button'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-post-nomination',
  components: {
    RecognitionHeader,
    RenderRelatedAvatars,
    BaseButton,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { myRecId } = useRecProfile()

    return { myRecId }
  },
  computed: {
    from() {
      const { sender } = this.post

      return (
        sender && {
          src: sender.avatarUrl,
          abbr: sender.initials,
          id: sender.id,
        }
      )
    },
    to() {
      const { recipient } = this.post

      return (
        recipient && {
          src: recipient.avatarUrl,
          abbr: recipient.initials,
          id: recipient.id,
        }
      )
    },
    entity() {
      const { award } = this.post

      return (
        award && {
          src: award.image,
        }
      )
    },
    isMeRecipient() {
      return this.post.recipient.id === this.myRecId
    },
  },
  methods: {
    onClick() {
      this.$router.push({
        name: 'rec-award',
        params: {
          awardId: this.post.award.id,
          awardStatus: 'available',
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <recognition-header icon="rec/nomination" />

    <div class="px-6 pb-2.5 sm:px-[30px]">
      <h2 class="mb-[15px] text-center leading-tight">
        {{ isMeRecipient ? 'You' : post.recipient.fullName }} received an Award
        Nomination
      </h2>

      <render-related-avatars
        class="my-[15px]"
        :from="from"
        :to="to"
        :entity="entity"
      />

      <div class="text-center text-sm font-bold text-eonx-neutral-600">
        <span class="text-black">
          {{ post.sender.fullName }}
        </span>

        nominated
        <template v-if="isMeRecipient">you</template>
        <span v-else class="text-black">
          {{ post.recipient.fullName }}
        </span>
        for

        <span class="text-black">
          {{ post.award.title }}
        </span>
      </div>

      <div class="my-6 text-center">
        {{ post.reason }}
      </div>

      <div class="flex justify-center">
        <base-button slot="footer" @click="onClick">
          See Award information & leaderboard
        </base-button>
      </div>
    </div>
  </div>
</template>
