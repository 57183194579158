<script setup lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { PostAttachment } from '/~/types/extensions'
import modal from '/~/core/mdl'
import GalleryEntry from './entry/gallery-entry.vue'

const sizeObject = {
  full: {
    size: 'full',
    normal: '600x400',
    retina: '1200x800',
  },
  half: {
    size: 'half',
    normal: '300x200',
    retina: '600x400',
  },
  'one-third': {
    size: 'one-third',
    normal: '160x143',
    retina: '320x286',
  },
  'two-third': {
    size: 'two-third',
    normal: '436x291',
    retina: '872x582',
  },
}

defineComponent({
  name: 'rec-media-post-gallery',
})

const props = defineProps<{
  attachments: PostAttachment
}>()

const previewCount = ref(3)
const initialItemIndex = ref(0)

const layout = computed(() => {
  const count = props.attachments.previewAttachments?.length

  if (count === 1) return 'view-single'
  else if (count === 2) return 'view-split'
  return 'view-multi'
})

const limitAttachments = computed(() => {
  return props.attachments.previewAttachments?.slice(0, previewCount.value)
})

const hiddenAttachmentsCount = computed(() => {
  const count = props.attachments.previewAttachments?.length

  if (count) {
    return count > previewCount.value ? count - previewCount.value : 0
  }

  return 0
})

const dimension = function (index: number) {
  if (layout.value === 'view-single') return sizeObject.full
  else if (layout.value === 'view-split') return sizeObject.half
  else if (layout.value === 'view-multi' && index === 0)
    return sizeObject['two-third']
  return sizeObject['one-third']
}
const openGallery = function (index: number) {
  initialItemIndex.value = index
  modal.show('rec-media-gallery', {
    props: {
      sources: props.attachments.previewAttachments,
      initialItemIndex: index,
    },
  })
}
</script>

<template>
  <div
    class="grid w-full cursor-pointer gap-1.5"
    :class="{
      'grid-cols-1': layout === 'view-single',
      'grid-cols-2': layout === 'view-split',
      'grid-cols-3': layout === 'view-multi',
    }"
  >
    <gallery-entry
      v-for="(attachment, index) in limitAttachments"
      :key="attachment.id"
      :class="{
        'col-span-2 row-span-2': layout === 'view-multi' && index === 0,
      }"
      :attachment="attachment"
      :hidden-attachments-count="index === 2 ? hiddenAttachmentsCount : 0"
      :dimension="dimension(index)"
      @click="openGallery(index)"
    />
  </div>
</template>
