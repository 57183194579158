<script>
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'

export default {
  name: 'rec-render-related-avatars',
  components: {
    BaseAvatar,
  },
  props: {
    from: {
      type: Object,
      required: true,
    },
    to: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <div>
    <div class="mx-auto flex h-24 w-80 flex-col overflow-hidden">
      <div class="z-10 mx-auto rounded-full border-4 border-white">
        <base-avatar size="2xl" :src="entity.src" />
      </div>
      <div
        class="-mt-10 h-80 w-80 shrink-0 rounded-full border-3 border-dashed border-disabled"
      />
    </div>

    <div class="mx-auto mt-[5px] flex w-80 justify-between">
      <base-avatar size="2xl" :abbr="from.abbr" :src="from.src" />
      <base-avatar size="2xl" :abbr="to.abbr" :src="to.src" />
    </div>
  </div>
</template>
