<script>
import { ValidationObserver } from 'vee-validate'
import { ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import InputAction from '/~/components/base/input-action/input-action.v2.vue'
import { useForm } from '/~/composables/base/use-form'
import { usePayees } from '/~/composables/payees'

export default {
  name: 'payments-payees-bpay-form',
  components: {
    BaseInput,
    BaseButton,
    InputAction,
    ValidationObserver,
  },
  setup() {
    const { payees, createPayee, validateBPay } = usePayees()
    const { validationObserverRef } = useForm()

    const billerCodeInputRef = ref(null)
    const nameInputRef = ref(null)
    const referenceRef = ref(null)

    return {
      payees,
      validationObserverRef,
      billerCodeInputRef,
      nameInputRef,
      referenceRef,
      createPayee,
      validateBPay,
    }
  },
  data() {
    return {
      form: {
        type: 'bpay',
        name: '',
        billerCode: '',
        reference: '',
      },
      name: '',
      billerCodeValidating: false,
      billerCodeValidated: false,
      backendErrors: {},
    }
  },
  computed: {
    canProceed() {
      return (
        this.form.name &&
        this.form.billerCode &&
        this.form.reference &&
        this.validationObserverRef?.flags.valid &&
        this.billerCodeValidated
      )
    },
  },
  watch: {
    'form.billerCode'() {
      this.billerCodeValidated = false
      if (!this.form.billerCode) {
        this.onCodeEnter()
      }
    },
    'form.reference'(value) {
      if (
        value.length >= 2 &&
        value.length <= 20 &&
        this.billerCodeInputRef?.$refs.input?.validationProviderRef?.flags
          .valid &&
        this.nameInputRef?.validationProviderRef?.flags.valid
      ) {
        this.referenceRef?.validationProviderRef?.validate(value)
      }
    },
  },
  methods: {
    processBackendErrors(data) {
      const { validationErrors } = data
      const backendErrors = {}

      if (validationErrors) {
        for (const key in validationErrors) {
          const firstError = validationErrors[key][0]

          backendErrors[key] = firstError || ''
        }
        this.backendErrors = backendErrors
      }
    },
    async onSubmit() {
      if (!this.name) {
        this.validateBillerCode()
        return
      }

      this.backendErrors = {}

      if (
        this.payees.creating ||
        !this.canProceed ||
        this.billerCodeValidating
      ) {
        return
      }

      try {
        await this.createPayee(this.form)
        this.$emit('success')
      } catch (error) {
        this.processBackendErrors(error.data || error)
      }
    },
    async validateBillerCode() {
      const { billerCode } = this.form

      if (!billerCode || billerCode.length < 2 || billerCode.length > 10) {
        this.billerCodeInputRef?.$refs.input?.validationProviderRef?.validate()
        return
      }

      delete this.backendErrors.billerCode

      try {
        this.billerCodeValidating = true
        const result = await this.validateBPay(billerCode)

        this.name = result?.longName ?? ''
        this.form.name = this.name.substring(0, 32)

        if (this.name) {
          delete this.backendErrors.name
          await this.nameInputRef?.validationProviderRef?.validate(
            this.form.name
          )
        }

        this.billerCodeValidated = true
      } catch (error) {
        this.name = ''
        this.backendErrors.billerCode = error.message
      } finally {
        this.billerCodeValidating = false
        this.billerCodeInputRef?.$refs.input?.validationProviderRef?.validate()
      }
    },
    onCodeEnter() {
      if (this.name) {
        this.name = ''
        this.form = {
          type: 'bpay',
          name: '',
          billerCode: '',
          reference: '',
        }
        this.backendErrors = {}
        this.validationObserverRef?.reset()
      } else {
        this.validateBillerCode()
      }
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <div class="flex grow flex-col overflow-hidden sm:pb-5">
      <div class="w-full grow overflow-y-auto">
        <div class="mx-auto max-w-lg p-5" @keyup.enter="handleSubmit(onSubmit)">
          <input-action
            ref="billerCodeInputRef"
            v-model="form.billerCode"
            v-analytics:input="{
              pageGroup: 'Make a payment',
              page: 'Add payee',
              component1: 'BPAY form',
              label: 'BPAY biller code field changed',
            }"
            :validation="{
              rules: 'required|numeric|min:2|max:10',
              name: 'BPAY biller code',
            }"
            :error="backendErrors.billerCode"
            :disabled="payees.creating || billerCodeValidating"
            label="BPAY biller code"
            required
            name="billerCode"
            maxlength="10"
            :mask="{ mask: '0000000000' }"
            :loading="billerCodeValidating"
            :icon="name ? '' : 'v2/heroic/plane'"
            data-testid="biller-code"
            @click="onCodeEnter"
          >
            <template v-if="name" #button-text>Clear</template>
          </input-action>

          <base-input
            ref="nameInputRef"
            v-model="form.name"
            v-analytics:input="{
              pageGroup: 'Make a payment',
              page: 'Add payee',
              component1: 'BPAY form',
              label: 'Biller name field changed',
            }"
            :validation="{
              rules: 'required|max:256|min:2',
              name: 'Biller name',
            }"
            :error="backendErrors.name"
            :disabled="payees.creating || !name"
            label="Biller name"
            required
            name="name"
            maxlength="256"
            data-testid="biller-name"
          />

          <base-input
            ref="referenceRef"
            v-model="form.reference"
            v-analytics:input="{
              pageGroup: 'Make a payment',
              page: 'Add payee',
              component1: 'BPAY form',
              label: 'Reference number field changed',
            }"
            :validation="{
              rules: 'required|numeric|max:20|min:2',
              name: 'Reference number',
            }"
            :error="backendErrors.reference"
            :disabled="payees.creating || !name"
            label="Reference number"
            required
            name="reference"
            maxlength="20"
            :mask="{ mask: '00000000000000000000' }"
            data-testid="reference"
          />
        </div>
      </div>
      <div class="mx-auto w-full max-w-lg space-y-2.5 px-5 pb-5">
        <base-button
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: 'Add payee',
            cta: 'Add',
          }"
          :loading="payees.creating"
          :disabled="!canProceed"
          class="mt-auto"
          full-width
          data-testid="add-payee"
          @click="handleSubmit(onSubmit)"
        >
          Add
        </base-button>
      </div>
    </div>
  </validation-observer>
</template>
