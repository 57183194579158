import get from 'lodash-es/get'
import { BaseProcessor } from '/~/core/processors/base'
import api from '/~rec/core/api'

export class EntityProcessor extends BaseProcessor {
  constructor(params) {
    super(params)
    this.entity = params.entity
    this.filters = params.filters
    this.mapping = params.mapping
    this.pagination = params.pagination || null
    this.perPage = params.perPage || 25
    this.filterForHits = params.filterForHits || null
    this.reset()
  }

  get total() {
    return Number(get(this.pagination, 'total_items', 0))
  }

  get totalPages() {
    return get(this.pagination, 'total_pages')
  }

  get hasNextPage() {
    return get(this.pagination, 'has_next_page')
  }

  reset(options = {}) {
    const { force = true } = options

    this.processing = true

    if (force) {
      this.destroyHits()
    }

    this.page = 1
    this.message = ''
  }

  /*
   * Reset state and get first page for the current feed
   */
  async getData(params = {}) {
    const { filter, query, force, firstRequest } = params

    this.reset({ force })

    const fetch = async ({ page }) => {
      this.processing = true

      const response = await api.get(`/${this.entity}`, {
        params: {
          page,
          perPage: params.perPage || this.perPage,
          ...this.getFilters(filter),
          ...(query || {}),
        },
      })

      if (this.fetch !== fetch) {
        return
      }

      const items = get(response, 'data.items', [])

      if (page === 1) {
        this.pagination = get(response, 'data.pagination', {})
      }

      if (this.mapping instanceof Function) {
        return items.map((item) => this.mapping(item))
      }

      return items
    }

    this.fetch = fetch

    try {
      const hits = await fetch({ page: 1 })

      if (force) {
        this.destroyHits()
      }

      if (hits) {
        if (this.filterForHits) {
          const { data, diff } = this.filterNextData(hits)

          this.hits = data
          if (firstRequest && diff) {
            this.pagination.total_items -= diff
          }
        } else {
          this.hits = hits
        }
      }

      return this.hits
    } catch (error) {
      console.error(error)
      this.message = get(error, 'response.data.message', 'Request Error')
    } finally {
      this.processing = false
      this.loaded = true
    }
  }

  refresh() {
    if (this.length === 0) {
      return this.getData()
    }

    return this.getData({
      perPage: this.page * this.perPage,
      force: false,
    })
  }

  /*
   * Get next page for the current feed
   */
  async next() {
    const oldPage = this.page
    const nextPage = oldPage + 1

    this.page = nextPage

    try {
      let nextData = await this.fetch({ page: nextPage })

      if (this.filterForHits) {
        const { data } = this.filterNextData(nextData)

        nextData = data
      }

      this.hits = (this.hits || []).concat(nextData)

      return nextData
    } catch (error) {
      this.page = oldPage
      console.error(error)
    } finally {
      this.processing = false
    }
  }

  /*
   * Returns object of filters
   */
  getFilters(filterName = 'default') {
    let filters = this.filters

    if (filters instanceof Function) {
      filters = filters()
    }

    return get(filters, filterName, {})
  }

  filterHits(condition) {
    this.hits.map((i) => {
      if (condition(i)) {
        setTimeout(() => {
          this.removeHit(i)
        })
      }
    })
  }

  destroyHits() {
    const firstHit = get(this.hits, '0', {})

    if (firstHit.destroy instanceof Function) {
      this.hits.forEach((hit) => hit.destroy())
    }

    this.hits = []
  }

  removeHit(hit) {
    const index = this.hits.findIndex((h) => h.id === hit.id)

    if (index >= 0) {
      if (hit.destroy instanceof Function) {
        hit.destroy()
      }

      this.hits.splice(index, 1)
      this.pagination.total_items -= 1
    } else {
      console.error('There is no such hit to remove: ', hit)
    }
  }

  addHit(hit, toStart = false) {
    const { sortedHits, hits } = this

    if (toStart) {
      hits.unshift(hit)

      this.pagination.total_items += 1
      return
    }

    if (this.perPage > this.length) {
      hits.push(hit)

      if (this.pagination) {
        this.pagination.total_items += 1
      }
    } else {
      const hitToRemove = sortedHits[sortedHits.length - 1]
      const pureIndex = hits.indexOf(hitToRemove)

      if (pureIndex >= 0) {
        hits.splice(pureIndex, 1, hit)

        if (hitToRemove.destroy instanceof Function) {
          hitToRemove.destroy()
        }
      } else {
        console.warn('Element index is not found')
      }
    }
  }

  resetNotification(id) {
    const item = this.hits.find((hit) => hit.id === id)

    item.raw.notifications = 0
  }

  filterNextData(data) {
    const { condition } = this.filterForHits

    const filteredData = data?.filter((item) => !condition(item))

    return {
      data: filteredData,
      diff: data?.length - filteredData?.length || 0,
    }
  }
}
