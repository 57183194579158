<script setup lang="ts">
import { computed } from 'vue'
import { Content } from '/~/composables/cms/use-cms'

const props = defineProps<{
  content?: Content
}>()

const contentAlignmentX = computed(
  () => props.content?.contentAlignmentX ?? 'left'
)
const contentAlignmentY = computed(
  () => props.content?.contentAlignmentY ?? 'top'
)
const padding = computed(() => props.content?.padding ?? 'lg')
const title = computed(() => props.content?.title)
const text = computed(() => props.content?.text)
</script>

<template>
  <div
    class="flex w-full flex-1 shrink-0 flex-col p-6 pt-5"
    :class="{
      'sm:p-10': padding === 'md',
      'sm:p-16': padding === 'lg',
      'justify-center': contentAlignmentY === 'middle',
      'justify-end': contentAlignmentY === 'bottom',
      'items-end': contentAlignmentX === 'right',
      'items-center': contentAlignmentX === 'center',
    }"
  >
    <div v-if="title" class="mb-2.5 text-2xl sm:text-3xl" v-html="title" />
    <div
      v-if="text"
      class="flex flex-1 shrink-0 flex-col"
      :class="{
        'justify-center': contentAlignmentY === 'middle',
        'justify-end': contentAlignmentY === 'bottom',
      }"
      v-html="text"
    />
    <slot />
  </div>
</template>
