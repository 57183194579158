<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import BaseButton from '/~/components/base/button/base-button'
import Datepicker from '/~/components/base/datepicker/datepicker/datepicker.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { useScheduledPayments } from '/~/composables/scheduled-payments'
import SchedulerStatusAlert from '/~/templates/bill-payments/components/payments/scheduler-status-alert.vue'
import DrawerModal from '../../components/drawer-modal.vue'

const props = defineProps<{ onConfirm?: () => void }>()

const {
  isScheduledPaymentDisabled,
  scheduledPaymentDateFrom,
  scheduledPaymentDateTo,
  dateToPay,
  dateToPayFormatted,
} = useScheduledPayments()

const { payment } = useCheckoutReactive()

const isScheduledToggled = ref(payment.value.isScheduledPayment)
const isShowDatepicker = ref(false)
const emit = defineEmits<(event: 'hide') => void>()

function hideMenu() {
  emit('hide')
}

function onContinue() {
  if (isScheduledToggled.value && !dateToPay.value) {
    showDaypickerBs()
    return
  }

  hideMenu()
  props.onConfirm?.()
}

function showDaypickerBs() {
  isShowDatepicker.value = true
}

function onCancel() {
  hideMenu()
}

function onDateToPay(date: any) {
  dateToPay.value = date
  payment.value.date = date
  isShowDatepicker.value = false
}

watch(isScheduledToggled, () => {
  if (!isScheduledToggled.value) {
    payment.value.date = null
  } else {
    onDateToPay(dateToPay.value)
  }
})
</script>

<template>
  <div class="pt-5">
    <div class="px-5 text-center text-xl font-bold">Select date</div>
    <div class="absolute top-0 right-0 mt-5 mr-5">
      <base-button
        icon="close"
        class="cursor-pointer"
        size="lg"
        @click="hideMenu"
      />
    </div>
    <div
      class="inline-flex w-full flex-col items-center justify-start gap-5 p-5"
    >
      <div class="inline-flex items-start justify-start gap-3 self-stretch">
        <base-radio
          v-model="isScheduledToggled"
          v-analytics:input="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            component1: 'Select date mobile drawer',
            label: 'Pay now selected',
          }"
          class="ml-0 shrink grow basis-0 items-center justify-start !py-0"
          :checked="!isScheduledToggled"
          :value="false"
        >
          Pay now
        </base-radio>
        <base-radio
          v-model="isScheduledToggled"
          v-analytics:input="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            component1: 'Select date mobile drawer',
            label: 'Schedule for later selected',
          }"
          class="ml-0 items-center justify-start !py-0"
          :checked="isScheduledToggled"
          :value="true"
          :disabled="isScheduledPaymentDisabled"
        >
          Schedule for later
        </base-radio>
      </div>

      <scheduler-status-alert />
    </div>

    <div v-if="isScheduledToggled" class="mt-5 px-5">
      <span>Date</span>
      <div class="mt-[5px]" :selectable="false" @click="showDaypickerBs">
        <div
          class="flex cursor-pointer justify-between rounded border border-neutral-200 bg-white px-3 py-2.5 outline outline-2 outline-transparent transition-all duration-300 hover:outline-primary group-focus:outline-primary"
        >
          <div class="flex space-x-2.5">
            <base-icon svg="v2/custom/calendar" class="w-6 text-neutral-600" />
            <span>
              {{ dateToPayFormatted || 'dd/mm/yyyy' }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-auto flex w-full shrink-0 justify-center gap-x-5 p-5 pb-5">
      <div class="w-full">
        <base-button
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            component1: 'Select date mobile drawer',
            cta: 'Cancel',
          }"
          class="w-full"
          look="outlined-color"
          @click="onCancel"
        >
          Cancel
        </base-button>
      </div>
      <div class="w-full">
        <base-button
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            component1: 'Select date mobile drawer',
            cta: 'Continue',
          }"
          class="w-full"
          @click="onContinue"
        >
          Continue
        </base-button>
      </div>
    </div>

    <drawer-modal :visible="isShowDatepicker" @hide="isShowDatepicker = false">
      <datepicker
        v-analytics:select.custom="{
          pageGroup: 'Make a payment',
          page: 'Checkout',
          component1: 'Select date mobile drawer',
          label: 'Set payment date',
        }"
        :value="dateToPay"
        format="daymonthyearnumeric"
        display-mode="calendar"
        :active-period="{
          from: scheduledPaymentDateFrom,
          to: scheduledPaymentDateTo,
        }"
        @select="onDateToPay"
      />
    </drawer-modal>
  </div>
</template>
