<script>
import AuthLayout from '/~/layouts/auth/layout-auth.vue'
import AuthRegisterForm from './auth-register-form.vue'
import AuthLogo from '/~/views/auth/auth-logo.vue'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'auth-register',
  components: {
    AuthLayout,
    AuthRegisterForm,
    AuthLogo,
  },
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isSymbioneliteProvider } = useProvider()

    return {
      isSymbioneliteProvider,
    }
  },
}
</script>

<template>
  <auth-layout
    image="//i.ewalletcdn.com/b4e5cdf3-7950-4923-bea5-b535ca7d928f/"
    class="auth-register"
  >
    <auth-logo :class="{ 'md:hidden': isSymbioneliteProvider }" />
    <auth-register-form :query="query" />
  </auth-layout>
</template>
