import { nanoid } from 'nanoid'

export default {
  label: 'Bill Pay',
  value: 'billpay',
  preset: {
    items: [
      {
        id: nanoid(),
        enabled: true,
        label: 'Home',
        action: {
          enabled: true,
          label: '',
          type: 'page',
          value: 'home',
          query: {},
          queryString: '',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        icon: 'v2/heroic/home',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Payments',
        action: {
          enabled: true,
          label: '',
          type: 'action',
          value: 'collapse',
          color: 'primary',
          look: 'filled',
        },
        'mobile-action': {
          enabled: true,
          label: '',
          type: 'action',
          value: 'collapse',
          color: 'primary',
          look: 'filled',
        },
        icon: 'v2/heroic/currency-dollar',
        children: [
          {
            id: nanoid(),
            enabled: true,
            label: 'Make a payment',
            action: {
              enabled: true,
              label: '',
              type: 'page',
              value: 'payments-select-payee',
              query: {},
              queryString: '',
              params: {},
              appearance: 'link',
              color: 'primary',
              look: 'filled',
            },
            'mobile-action': {
              enabled: true,
              label: '',
              type: 'page',
              value: '',
              query: {},
              queryString: '',
              params: {},
              appearance: 'link',
              color: 'primary',
              look: 'filled',
            },
            icon: 'v2/box-icons/bx-dollar',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Accounts & statements',
            action: {
              enabled: true,
              label: '',
              type: 'page',
              value: 'statements-list',
              query: {},
              queryString: '',
              params: {},
              appearance: 'link',
              color: 'primary',
              look: 'filled',
            },
            'mobile-action': {
              enabled: true,
              label: '',
              type: 'page',
              value: '',
              query: {},
              queryString: '',
              params: {},
              appearance: 'link',
              color: 'primary',
              look: 'filled',
            },
            icon: 'v2/heroic/document-text',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Manage payees',
            action: {
              enabled: true,
              label: '',
              type: 'page',
              value: 'payments-payees',
              query: {},
              queryString: '',
              params: {},
              appearance: 'link',
              color: 'primary',
              look: 'filled',
            },
            'mobile-action': {
              enabled: true,
              label: '',
              type: 'page',
              value: '',
              query: {},
              queryString: '',
              params: {},
              appearance: 'link',
              color: 'primary',
              look: 'filled',
            },
            icon: 'v2/heroic/users',
          },
          {
            id: nanoid(),
            enabled: true,
            label: 'Scheduled payments',
            action: {
              enabled: true,
              label: '',
              type: 'page',
              value: 'scheduled-payments-list',
              query: {},
              queryString: '',
              params: {},
              appearance: 'link',
              color: 'primary',
              look: 'filled',
            },
            'mobile-action': {
              enabled: true,
              label: '',
              type: 'page',
              value: '',
              query: {},
              queryString: '',
              params: {},
              appearance: 'link',
              color: 'primary',
              look: 'filled',
            },
            icon: 'v2/heroic/calendar',
          },
        ],
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Rewards',
        description:
          '<p>Use your points just like cash, PLUS earn bonus points every time you shop in our rewards marketplace.</p>',
        action: {
          enabled: true,
          label: '',
          type: 'page',
          value: 'giftcards',
          query: {},
          queryString: '',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        'mobile-action': {
          enabled: true,
          label: '',
          type: 'page',
          value: '',
          query: {},
          queryString: '',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        icon: 'v2/box-icons/bx-gift',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Airline partners',
        description:
          '<p>Looking to book your next holiday? Simply transfer your Symbion Elite Points to participating airline partners.</p>',
        action: {
          enabled: true,
          label: '',
          type: 'page',
          value: 'fly-partners-list',
          query: {},
          queryString: '',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        'mobile-action': {
          enabled: true,
          label: '',
          type: 'page',
          value: '',
          query: {},
          queryString: '',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        icon: 'v2/custom/plane-alt',
      },
      {
        id: nanoid(),
        enabled: true,
        label: 'Activity',
        description:
          '<p>View your transaction history and manage your payments or print tax invoices for your payment activity.</p>',
        action: {
          enabled: true,
          label: '',
          type: 'page',
          value: 'activity',
          query: {},
          queryString: '',
          params: {},
          appearance: 'link',
          color: 'primary',
          look: 'filled',
        },
        icon: 'v2/box-icons/bx-bar-chart-alt',
      },
    ],
  },
}
