<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import { useVerifications } from '/~/extensions/otp/composables'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

const router = useRouter()
const { hasUnfinishedVerifications } = useVerifications()

function onVerify() {
  router.push({ hash: '#profile-verification' })
}
</script>

<template>
  <div
    v-if="hasUnfinishedVerifications"
    class="flex flex-wrap items-center justify-between gap-3 rounded-md bg-orange-50 py-3 px-4 text-orange-700 shadow"
  >
    <div class="flex items-center gap-x-3">
      <base-icon
        svg="plain/clock-v3"
        class="flex-shrink-0 text-orange-700"
        :size="32"
      />
      <span>Please complete account verification to make transactions.</span>
    </div>

    <base-button
      look="outlined-color"
      class="!h-8 w-full self-end sm:w-auto"
      size="xs"
      color="none"
      @click="onVerify"
    >
      Verify now
    </base-button>
  </div>
</template>
