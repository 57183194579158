<script>
import DrawerPaymentMethodsAdd from '/~/components/drawer/components/payment-methods/drawer-payment-methods-add.vue'

export default {
  name: 'profile-points-pay-from-add',
  components: {
    DrawerPaymentMethodsAdd,
  },
}
</script>

<template>
  <drawer-payment-methods-add />
</template>
