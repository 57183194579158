<script>
import RetailerProduct from '/~/extensions/giftcards/templates/cashrewards/components/product/retailer-product.vue'
import DefaultGiftcardsRetailer from '/~/extensions/giftcards/templates/default/views/giftcards-retailer.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'

export default {
  components: {
    RetailerProduct,
    BaseAsidePage,
  },
  extends: DefaultGiftcardsRetailer,
  setup() {
    return {
      ...DefaultGiftcardsRetailer.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <base-aside-page :title="name" no-padding body-class="h-screen pb-20">
    <retailer-empty v-if="empty" class="p-5" />

    <ew-details-layout
      v-else
      :loading="loading"
      :ready="ready"
      :title="`${name} eGift Card`"
      data-cy="giftcard-title"
      class="mb-0 pt-5 md:pt-0"
      :class="{ 'px-5': loading }"
      :back="backRoute"
    >
      <template #gallery>
        <retailer-logo
          :image="image"
          :alt="name"
          class="pt-5 sm:pt-0"
          :default-src="defaultImage"
        />
        <h3 class="mt-4 text-center sm:hidden">
          {{ name }}
        </h3>
      </template>

      <template #details>
        <retailer-details
          :offer="offer"
          :retailer="retailer"
          :url="logoUrl"
          :loading="loading"
          :tabs-props="{ look: 'v4' }"
          class="px-5 sm:px-0"
        />
      </template>

      <template #summary>
        <retailer-product
          type="giftcard"
          :offer="offer"
          :retailer="retailer"
          :loading="loading"
          buttons-fixed
        />
      </template>
    </ew-details-layout>
  </base-aside-page>
</template>
