export const Countries = [
  {
    id: 'australia',
    label: 'Australia',
  },
  {
    id: 'usa',
    label: 'USA',
  },
  {
    id: 'canada',
    label: 'Canada',
  },
  {
    id: 'united-kingdom',
    label: 'United Kingdom',
  },
]
