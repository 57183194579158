<script>
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import CatalogEmpty from './community-hub-empty.vue'
import CatalogTile from './community-hub-tile.vue'
import { useCommunityHub } from '/~/extensions/community-hub/composables'

export default {
  name: 'community-hub-catalog-items',
  components: {
    EwCatalogProcessor,
    CatalogEmpty,
    CatalogTile,
  },
  setup() {
    const { algolia } = useCommunityHub()

    return {
      algolia,
    }
  },
}
</script>

<template>
  <ew-catalog-processor :processor="algolia" :columns="{ sm: 2 }">
    <template #empty>
      <catalog-empty />
    </template>

    <template #tile="{ tile }">
      <catalog-tile :tile="tile" />
    </template>
  </ew-catalog-processor>
</template>
