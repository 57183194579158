<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'health-video-player-modal',
  components: {
    BaseMdl,
  },
  props: {
    source: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" transition="slide-bottom" width="sm" height="xs">
    <iframe
      :src="source"
      frameborder="0"
      class="h-full w-full"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </base-mdl>
</template>
