<script lang="ts">
import AudioEntry from './entry/audio-post-entry.vue'
import { AudioAttachment } from '/~/types/extensions'

export default {
  name: 'rec-post-audio',
  components: {
    AudioEntry,
  },
  props: {
    attachments: {
      type: Object,
      required: true,
    },
    contentColor: {
      type: String,
      default: 'text-dark',
    },
    buttonColor: {
      type: String,
      default: 'text-primary',
    },
  },
  data() {
    return {
      previewCount: 3,
      showHidden: false,
    }
  },
  computed: {
    visibleAttachments(): Array<AudioAttachment> {
      return this.attachments.audioAttachments.slice(0, this.previewCount)
    },
    hiddenCount() {
      const count = this.attachments.audioAttachmentsCount

      return count > this.previewCount ? count - this.previewCount : 0
    },
  },
}
</script>

<template>
  <div class="space-y-[15px]">
    <audio-entry
      v-for="attachment in visibleAttachments"
      :key="attachment.id"
      :attachment="attachment"
      :content-color="contentColor"
      :button-color="buttonColor"
    />

    <span
      v-if="hiddenCount > 0"
      class="inline-block cursor-pointer text-sm font-bold text-primary"
      @click="showHidden = !showHidden"
    >
      <span>
        {{ showHidden ? 'Show less' : `Show more (${hiddenCount})` }}
      </span>
    </span>
  </div>
</template>
