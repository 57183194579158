<script>
import { formatDate } from '/~/utils/format/date'
import { ensureNumber } from '/~/utils/format/numeric'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import CashbackPanel from '/~/components/cashback/cashback-panel.vue'
import CheckoutSummaryAddress from '/~/components/checkout/summary/checkout-summary-address.vue'
import CheckoutSummaryItems from '/~/components/checkout/summary/checkout-summary-items.vue'
import CheckoutSummaryPayWith from '/~/components/checkout/summary/checkout-summary-pay-with.vue'
import CheckoutConfirmationStatus from '/~/components/checkout/summary/checkout-summary-status.vue'
import CheckoutSummaryTotal from '/~/components/checkout/summary/checkout-summary-total.vue'
import PointsPanel from '/~/components/points/points-panel.vue'
import { useActivity } from '/~/composables/activity'
import { useCart } from '/~/composables/cart'
import { useCms } from '/~/composables/cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'cart-checkout-confirmation-v1',
  components: {
    BaseLink,
    BaseButton,
    BaseAsidePage,
    BaseLoader,
    CheckoutConfirmationStatus,
    CheckoutSummaryAddress,
    CheckoutSummaryItems,
    CheckoutSummaryPayWith,
    CheckoutSummaryTotal,
    PointsPanel,
    CashbackPanel,
  },
  props: {
    close: {
      type: Function,
      default: null,
    },
    meta: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { clearCart } = useCart()
    const { getPurchaseOrderItem } = useActivity()
    const { isPurchaseOrderPoints, calculatePointsEarnedForPurchase } =
      usePoints()
    const { isBillPaymentsTemplate, isUserAddressEnabled } = useProvider()
    const { ewalletLabels } = useCms()

    return {
      clearCart,
      getPurchaseOrderItem,
      isPurchaseOrderPoints,
      calculatePointsEarnedForPurchase,
      formatDate,
      PaymentMethodType,
      isBillPaymentsTemplate,
      ewalletLabels,
      isUserAddressEnabled,
    }
  },
  data() {
    return {
      isReady: false,
      order: null,
      failed: false,
    }
  },
  computed: {
    orderId() {
      const hashSplit = this.$route.hash.split('/')

      return hashSplit?.['1']
    },
    status() {
      return (this.order?.status ?? '').toLowerCase()
    },
    financialStatus() {
      return (this.order?.financialStatus ?? '').toLowerCase()
    },
    isCompleted() {
      return (
        this.financialStatus === 'paid' &&
        ['processing', 'completed'].includes(this.status)
      )
    },
    paid() {
      return this.financialStatus === 'paid'
    },
    orderItems() {
      return this.order?.items ?? []
    },
    orderFilteredItems() {
      return this.orderItems.filter((i) => i.type !== 'coupon')
    },
    eStoreItems() {
      return this.order?.e_store_items ?? []
    },
    hasPhysical() {
      return this.orderFilteredItems.some(
        (item) => item.physical && item.type !== 'estore'
      )
    },
    hasDigital() {
      return this.orderFilteredItems.some((item) => !item.physical)
    },
    hasBankAccountMethod() {
      return Boolean(this.bankAccount)
    },
    singleDigital() {
      if (this.orderFilteredItems.length === 1) {
        const item = this.orderFilteredItems[0]

        return !item.physical ? item : false
      }

      return false
    },
    hasESore() {
      return (
        this.eStoreItems.length > 0 ||
        this.orderFilteredItems.some((item) => item.type === 'estore')
      )
    },
    hasCinema() {
      return this.orderFilteredItems.some((item) => item.type === 'voucher')
    },
    isGift() {
      return this.orderFilteredItems?.[0]?.gift && this.singleDigital
    },
    items() {
      const items = this.orderFilteredItems.map((item) => {
        if (!item.type) {
          item.type = 'retailer'
        }
        return item
      })

      const eStoreItems = this.eStoreItems.map((item) => {
        item.type = 'product'
        return item
      })

      return [...items, ...eStoreItems]
    },
    ePurchasesRoute() {
      return { hash: '#profile-e-purchases' }
    },
    fees() {
      return this.order.fees?.items ?? []
    },
    transactionFee() {
      return Number(
        this.fees.find((item) => item.label === 'Processing Fee')?.amount ?? 0
      )
    },
    shippingFee() {
      return Number(
        this.fees.find((item) => item.label === 'Shipping Fee')?.amount ?? 0
      )
    },
    coupons() {
      const items = this.order.paymentMethods.filter(
        (i) => i?.type === this.PaymentMethodType.couponCode
      )

      return {
        items,
        total: items.reduce((acc, i) => acc - i.amount, 0),
      }
    },
    creditCard() {
      const card = this.order.paymentMethods.find(
        (i) => i.type === this.PaymentMethodType.creditCard
      )

      if (!card) {
        return
      }

      const details = {
        name: 'Credit Card',
        number: card.number,
      }

      return {
        details,
        total: Number(card.amount),
      }
    },
    points() {
      const points = this.order.paymentMethods.find(
        (i) => i.type === this.PaymentMethodType.points
      )

      if (!points) {
        return
      }

      const details = {
        usePoints: points.points,
      }

      return {
        details,
        total: Number(points.amount),
      }
    },
    ewallet() {
      const ewallet = this.order.paymentMethods.find(
        (i) => i.type === this.PaymentMethodType.eWallet
      )

      if (!ewallet) {
        return
      }

      const details = {
        // usePoints: 0,
      }

      return {
        details,
        total: Number(ewallet.amount),
      }
    },
    bankAccount() {
      const bankAccount = this.order.paymentMethods.find(
        (i) => i.type === this.PaymentMethodType.bankAccount
      )

      if (!bankAccount) {
        return
      }

      const details = {
        accountName: 'Bank Account',
        accountNumber: bankAccount.accountNumber || bankAccount.number,
        bsb: bankAccount.bsb || bankAccount.prefix,
      }

      return {
        details,
        total: Number(bankAccount.amount),
      }
    },
    pointsEarned() {
      const pointsTransactions = this.order.pointsTransactions || []
      const earnTransaction = pointsTransactions.filter(
        (t) => t.type === 'earn'
      )

      if (earnTransaction.length > 0) {
        return earnTransaction[0].allocatedValue
      }

      return this.calculatePointsEarnedForPurchase(this.order.items)
    },
    cashbackRebateTotal() {
      return ensureNumber(this.order.rebateTotal)
    },
  },
  mounted() {
    this.getPurchaseOrderItem(this.orderId)
      .then((order) => {
        this.order = order

        if (this.paid) {
          this.clearCart()
        }
      })
      .catch(() => {
        this.failed = true
      })
      .finally(() => {
        this.isReady = true
      })
  },
}
</script>

<template>
  <base-aside-page
    title="Confirmation"
    :back="{ hash: '#cart-home' }"
    class="h-full"
    no-padding
  >
    <base-loader v-if="!isReady" class="m-auto" fullwidth />

    <div
      v-else-if="failed"
      class="mx-5 py-[30px] text-center text-2xl font-bold"
    >
      Order information not found

      <div class="mt-[30px]">
        <base-button full-width @click="$router.push({ name: 'home' })">
          Back to Dashboard
        </base-button>
      </div>
    </div>

    <div v-else class="flex h-full flex-col">
      <div class="grow overflow-auto p-5">
        <checkout-confirmation-status :order="order" />

        <div class="mb-6 text-center">
          <div class="font-bold leading-relaxed text-eonx-neutral-600">
            Order No. #{{ order.number }}
          </div>
          <div class="mt-[5px] font-bold leading-relaxed text-eonx-neutral-600">
            {{ formatDate('daymonthyeartime', order.placedAt) }}
          </div>
        </div>

        <div class="mb-6 space-y-6">
          <p v-if="hasDigital" class="w-full text-center text-sm leading-6">
            <template v-if="hasBankAccountMethod">
              By selecting your bank account as the payment method, your order
              will not be fulfilled until the funds have cleared in our system.
              <b>This can take up to 3 business days.</b>
            </template>
            <template v-else>
              ePurchases will be delivered to your email and available in
              {{ ewalletLabels.yourEwallet }}
              instantly, however on occasion this may take up to 72 hours.
            </template>
          </p>

          <p v-if="hasPhysical" class="w-full text-center text-sm leading-6">
            Physical Gift Cards will be mailed to your delivery address within 5
            business days.
          </p>

          <p v-if="hasESore" class="w-full text-center text-sm leading-6">
            eStore Products will be sent to your delivery address within 5 to 15
            business days subject to stock levels. If you have any questions
            about your order,
            <base-link :to="{ name: 'help' }" class="font-bold">
              contact us
            </base-link>
            .
          </p>
        </div>

        <div class="mb-2.5 text-xl font-bold text-eonx-neutral-800">
          Summary
        </div>
        <div class="divide-y">
          <checkout-summary-address
            v-if="isUserAddressEnabled"
            :billing="order.address"
            :loading="!isReady"
            class="pb-5"
          />
          <checkout-summary-items :items="items" class="py-5" />
          <checkout-summary-pay-with
            class="pt-5"
            title="Paid with"
            :credit-card="creditCard"
            :points="points"
            :ewallet="ewallet"
            :bank-account="bankAccount"
            :coupons="coupons"
          />
          <checkout-summary-total
            :transaction-fees="transactionFee"
            :shipping-fees="shippingFee"
            :amount="+order.subtotal"
            :total="+order.total"
            class="pt-5"
          />
        </div>

        <points-panel
          v-if="isPurchaseOrderPoints"
          :total="pointsEarned"
          :completed="isCompleted"
          :loading="!isReady"
          class="-mx-5 mt-5"
        />
        <cashback-panel
          :total="cashbackRebateTotal"
          :completed="isCompleted"
          :loading="!isReady"
          class="-mx-5 mt-5"
        />
      </div>
    </div>
    <template #footer>
      <div class="mt-auto px-5 py-6">
        <div class="flex w-full space-x-6">
          <base-button
            v-if="paid && hasDigital && !isGift"
            class="flex-1"
            look="outlined-color"
            @click="$router.push(ePurchasesRoute)"
          >
            <template v-if="singleDigital">
              <template v-if="hasCinema">View eVoucher</template>
              <template v-else>View eGift Card</template>
            </template>
            <template v-else>View ePurchases</template>
          </base-button>

          <base-button class="flex-1" @click="$router.push({ name: 'home' })">
            Done
          </base-button>
        </div>
      </div>
    </template>
  </base-aside-page>
</template>
