<script>
import CategoryTag from '/~/extensions/health/components/section-category-tag.vue'
import { useRecipes } from '/~/extensions/health/composables'
import { formatDate } from '/~/utils/format/date'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'hlth-tile-recipe',
  components: {
    CategoryTag,
    ButtonIcon,
    BaseImage,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isBookmarked, toggleBookmark } = useRecipes()

    return {
      isBookmarked,
      toggleBookmark,
      formatDate,
    }
  },
  data() {
    return {
      categoryColors: {
        Snacks: 'var(--color-primary)',
        Snack: 'var(--color-primary)',
        Dessert: '#9F1239',
        Desserts: '#9F1239',
        Meals: '#5B21B6',
        Meal: '#5B21B6',
        Breakfasts: '#6C5CE7',
        Breakfast: '#6C5CE7',
        Salads: '#FDCB6E',
        Salad: '#FDCB6E',
      },
    }
  },
  computed: {
    excerpt() {
      const { excerpt = '' } = this.tile

      if (excerpt.length < 100) {
        return excerpt
      }

      return `${excerpt.substring(0, 100).trim()}...`
    },
  },
  methods: {
    onClick() {
      this.$emit('tile-click', this.tile)
    },
  },
}
</script>

<template>
  <div
    class="flex h-full cursor-pointer flex-col overflow-hidden rounded-lg bg-white shadow-sm"
    @click="onClick"
  >
    <div class="relative">
      <base-image :src="tile.image" :rounded="false" />
    </div>
    <div class="-mt-[15px] flex justify-end">
      <div
        class="z-1 mr-[15px] flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full bg-white p-[5px] text-primary shadow-sm hover:shadow"
      >
        <button-icon
          :icon="
            isBookmarked(tile.id) ? 'plain/bookmark-marked' : 'plain/bookmark'
          "
          size="md"
          @click.stop="toggleBookmark(tile.id)"
        />
      </div>
    </div>
    <div class="flex flex-col px-[15px] pb-6">
      <h3
        class="mb-[5px] min-h-14 w-full text-base font-bold leading-6 text-eonx-neutral-800"
      >
        {{ tile.title }}
      </h3>

      <div class="flex items-center">
        <category-tag
          :color="categoryColors[tile.category]"
          :text="tile.category"
          class="mr-2.5"
        />
      </div>

      <div class="mt-2.5 text-sm text-eonx-neutral-800 line-clamp-2">
        {{ excerpt }}
      </div>
    </div>
  </div>
</template>
