<script>
import ui from '/~/core/ui'
import DiningFilterDesktop from './dining-filter.desktop.vue'
import DiningFilterMobile from './dining-filter.mobile.vue'

export default {
  name: 'dining-filter-v2',
  functional: true,
  render(h, ctx) {
    return h(
      ui.mobile ? DiningFilterMobile : DiningFilterDesktop,
      ctx.data,
      ctx.props
    )
  },
}
</script>
