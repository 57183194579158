import api from '/~rec/core/api'
import { RecroomPerk } from './perk'

export class RecroomPerkRedeemed extends RecroomPerk {
  constructor(raw = {}) {
    super(raw.perk)
    this.raw = {
      ...this.raw,
      ...raw,
    }
  }

  get id() {
    return this.raw.id
  }

  get status() {
    return this.raw.status
  }

  get approvedAt() {
    return this.raw.approved_at
  }

  get createdAt() {
    return this.raw.created_at
  }

  get isPending() {
    return this.status === 'pending_approval'
  }

  static async fetchById(id) {
    const response = await api.get(`/gamification/redeemed-perks/${id}`)

    return new RecroomPerkRedeemed(response.data)
  }
}
