import get from 'lodash-es/get'
import api from '/~rec/core/api'
import { EntityProcessor } from '/~rec/core/entity-processor'
import { RecroomUser } from '/~rec/core/user'
import { RecroomGroup, RecroomEvent } from '/~rec/core'
import { RecroomComment } from '/~rec/core/comment'
import { RecroomAttachmentsProcessor } from '/~rec/core/attachments-processor'
import { RecroomEarnPost } from '/~rec/core/earn-post'
import { RecroomTransaction } from '/~rec/core/transaction'
import { ApiResponseData } from '/~/types/api'
import { PostBoxData } from '/~/types/extensions'
import { createDate } from '/~/utils/format/date'
import { useRecProfile } from '/~rec/composables/profile'

const { currency, myRecId } = useRecProfile()

export class RecroomPost {
  raw: PostBoxData
  loading: boolean
  attachments?: RecroomAttachmentsProcessor

  constructor(rawData: PostBoxData) {
    this.raw = rawData
    this.init(rawData)

    this.loading = false
  }

  get id() {
    return this.raw.id
  }

  get type() {
    return this.raw.type
  }

  get content() {
    return this.raw.content
  }

  get author() {
    return new RecroomUser(this.raw.author)
  }

  get createdAt() {
    return this.raw.created_at
  }

  get savedId() {
    return this.raw.saved_post_id
  }

  get reporters() {
    return this.raw.reporters || []
  }

  get savers() {
    return this.raw.savers_uuids || []
  }

  get commentators() {
    return this.raw.commentators || []
  }

  get expiresAt() {
    return this.raw.expires_at
  }

  get toEvent() {
    const event = get(this.raw, 'events.0')

    if (event) {
      return new RecroomEvent(event)
    }

    return null
  }

  get toGroup() {
    const group = get(this.raw, 'groups.0')

    if (group) {
      return new RecroomGroup(group)
    }

    return null
  }

  get likes() {
    return this.raw.likes || []
  }

  get totalLikes() {
    return this.likes.length
  }

  set totalLikes(value) {
    this.raw.likes.length = value
  }

  get totalComments() {
    return this.raw.total_comments || 0
  }

  set totalComments(value) {
    this.raw.total_comments = value
  }

  get totalSaved() {
    return this.raw.total_saved || 0
  }

  set totalSaved(value) {
    this.raw.total_saved = value
  }

  get isMeAuthor() {
    return this.author.id === myRecId.value
  }

  get isAnnouncement() {
    return !!this.raw.announcement
  }

  get isReportedByMe() {
    return this.reporters.indexOf(myRecId.value) >= 0
  }

  get isLikedByMe() {
    return (this.raw.likes || []).indexOf(myRecId.value) >= 0
  }

  get isSavedByMe() {
    return this.savers.indexOf(myRecId.value) >= 0
  }

  get hasEarnConfigs() {
    return this.raw.has_earn_config
  }

  get earnConfigs() {
    return (
      this.raw.earn_configs &&
      this.raw.earn_configs.map((earn) => new RecroomEarnPost(earn, this.raw))
    )
  }

  get postLikeCreate() {
    return this.raw.earn_configs.find(
      (earn) => earn.action === 'post_like_create'
    )
  }

  get currency() {
    return currency.value
  }

  get targetEarnLimit() {
    return this.raw.target_earn_limit
  }

  get canVote() {
    if (!this.targetEarnLimit) return true
    if (!this.postLikeCreate) return false
    return (
      this.targetEarnLimit.points_limit - this.targetEarnLimit.points_earned >=
      this.postLikeCreate.earn_value
    )
  }

  get earnedSoFar() {
    if (!this.targetEarnLimit) return null
    return this.targetEarnLimit[`${this.currency}_earned`]
  }

  get maxEarnValue() {
    if (!this.targetEarnLimit) return null
    return this.targetEarnLimit[`${this.currency}_limit`]
  }

  get earnConfigsEndsAt() {
    return this.raw.earn_configs_ends_at
  }

  get restrictedActions() {
    return this.raw.restricted_actions || []
  }

  get userEarnedPoints() {
    return get(this.raw, 'user_total_earned')
  }

  get updatedAt() {
    return this.raw.updated_at
  }

  get isPostUpdated() {
    const created = createDate(this.raw.created_at)
    const updated = createDate(this.raw.updated_at)

    return updated.diff(created) > 0
  }

  get mentions() {
    return get(this.raw, 'mentions')
  }

  isActionsAllow(...actions) {
    return !actions.every((action) => this.restrictedActions.includes(action))
  }

  init(rawData: PostBoxData) {
    this.raw = rawData
    this.attachments = new RecroomAttachmentsProcessor(
      rawData.attachments,
      rawData
    )

    this.comments = new EntityProcessor({
      entity: `posts/${this.id}/comments?order[createdAt]=desc`,
      mapping: (record) => new RecroomComment(record),
      perPage: 5,
      pagination: {
        total_items: this.totalComments,
        filter: 'post',
      },
    })
    this.earnStatus = new EntityProcessor({
      entity: `gamification/transaction-logs?earnTarget=${rawData.id}`,
      mapping: (record) => new RecroomTransaction(record),
    })
    this.previewInformation = rawData.preview_information
  }

  static async create(data) {
    const post = await api.post('/posts', data, { notify: false })

    return new RecroomPost(post)
  }

  async update(data) {
    const post = await api.put(`/posts/${this.id}`, data, { notify: false })

    this.init(post.data)
  }

  async markAnnouncement() {
    const post = await api.put(`/posts/${this.id}`, {
      announcement: true,
    })

    this.init(post.data)
  }

  async unmarkAnnouncement() {
    const post = await api.put(`/posts/${this.id}`, {
      announcement: false,
    })

    this.init(post.data)
  }

  async like() {
    this.raw.likes.push(myRecId.value)

    const response = await api.post(`posts/${this.id}/likes`)

    return response
  }

  async saveForMe() {
    if (!this.raw.savers_uuids) this.raw.savers_uuids = []

    this.raw.savers_uuids.push(myRecId.value)
    this.raw.total_saved += 1

    const response = await api.post('/saved-posts', {
      post: this.id,
    })

    this.raw.saved_post_id = response.data.id

    return response
  }

  async unsaveFromMe(callApi = true) {
    const idx = this.savers.indexOf(myRecId.value)

    this.savers.splice(idx, 1)
    this.raw.total_saved -= 1

    if (callApi) {
      return api.delete(`/saved-posts/${this.savedId}`)
    }
  }

  delete() {
    return api.delete(`/posts/${this.id}`)
  }

  async report() {
    const response = await api.post(`/posts/${this.id}/reports`)

    this.raw.reporters.push(myRecId.value)

    return response
  }

  async disregardReports() {
    return api.delete(`/reported-posts/${this.id}/discard`)
  }

  async fullPost() {
    const response = await api.get<ApiResponseData<PostBoxData>>(
      `/posts/${this.id}`
    )

    this.init(response.data)
  }

  updateTotalComments() {
    this.totalComments = this.comments.hits.reduce((acc, curr) => {
      acc += curr.totalComments
      return acc
    }, this.comments.total || 0)
  }
}
