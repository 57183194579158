<script>
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import AwardNominateUser from '/~rec/views/recognition/awards/award-nominate-user.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'

export default {
  name: 'award-nominate-user-mobile',
  components: {
    BaseMdl,
    HeaderMobile,
    AwardNominateUser,
  },
  props: {
    profile: {
      type: Object,
      default: null,
    },
  },
  methods: {
    close() {
      modal.hide()
      this.$router.push({ name: 'rec-awards' })
    },
  },
}
</script>

<template>
  <base-mdl transition="slide-bottom" fullscreen width="screen" :layout="false">
    <header-mobile title="Awards" @back="close" />
    <award-nominate-user :user="profile" class="px-6 pt-0" />
  </base-mdl>
</template>
