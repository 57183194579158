<script>
import BaseToggle from '/~/components/base/toggle/base-toggle.vue'
import PaymentMethodPlaceholder from './payments-method-placeholder.vue'
import { ref, watch } from 'vue'

export default {
  name: 'payments-methods-block',
  components: {
    BaseToggle,
    PaymentMethodPlaceholder,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isTogglable: {
      type: Boolean,
      default: false,
    },
    isToggledInitial: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggled-off', 'toggled-on'],
  setup(props, { emit }) {
    const isToggled = ref(props.isToggledInitial)

    function toggleOff() {
      isToggled.value = false
    }

    watch(isToggled, (isToggled) => {
      if (isToggled) {
        emit('toggled-on')
      } else {
        emit('toggled-off')
      }
    })

    return {
      isToggled,
      toggleOff,
    }
  },
}
</script>

<template>
  <div class="mb-[30px] w-full">
    <div class="mb-4">
      <div class="flex items-center font-bold text-eonx-neutral-600">
        <slot name="title">
          <span>
            {{ title }}
          </span>
        </slot>
        <base-toggle
          v-if="isTogglable"
          v-model="isToggled"
          :disabled="isDisabled"
          class="ml-auto"
        />
      </div>
      <slot name="description" />
    </div>
    <div
      v-show="!isTogglable || isToggled"
      class="flex shrink-0 flex-col justify-center space-y-[15px]"
    >
      <payment-method-placeholder v-if="loading" />
      <div v-show="!loading">
        <slot />
      </div>
    </div>
  </div>
</template>
