<script setup lang="ts">
import ui from '/~/core/ui'
import TravelCatalog from '/~/extensions/travel/components/catalogue.vue'
import TravelFilters from '/~/extensions/travel/components/travel-filters.vue'
import { useEditablePage } from '/~/composables/cms'

const { page } = useEditablePage()
</script>

<template>
  <div class="mx-auto w-full max-w-7xl">
    <travel-filters v-if="ui.mobile" />
    <div v-else class="px-5 pt-2 pb-3">
      <travel-filters />
    </div>
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
    <div class="p-2.5 sm:p-5">
      <travel-catalog />
    </div>
  </div>
</template>
