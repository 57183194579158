<script>
import { nextTick } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

let timer
const ANIMATION_DELAY = 300

export default {
  name: 'base-clamp-line',
  components: {
    BaseIcon,
  },
  props: {
    lineHeight: {
      type: Number,
      default: 20,
    },
    rows: {
      type: Number,
      default: 10,
    },
    gradientClass: {
      type: String,
      default: 'bg-gradient-to-b from-zinc-100/0 to-zinc-100',
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
      showExpander: false,
      wrapperHeight: 0,
      contentHeight: false,
      transitionDelay: 0,
    }
  },
  computed: {
    maxHeight() {
      return this.rows * this.lineHeight
    },
    $_contentHeight() {
      const { contentHeight } = this

      if (!this.showExpander) {
        return 'auto'
      } else if (typeof contentHeight === 'number') {
        return contentHeight + 'px'
      } else {
        return contentHeight
      }
    },
    expanderText() {
      return this.expanded ? 'Show less' : 'Show more'
    },
    expanderIcon() {
      return this.expanded ? 'plain/chevron-top' : 'plain/chevron-bottom'
    },
  },
  updated() {
    this.checkContentHeight()
  },
  mounted() {
    this.checkContentHeight()
    this.contentHeight = this.maxHeight
    if (this.isExpanded) {
      this.expanded = true
      this.contentHeight = 'auto'
    }
  },
  methods: {
    checkContentHeight() {
      const wrapperHeight = this.$refs.wrapper.clientHeight

      this.wrapperHeight = wrapperHeight
      this.showExpander = wrapperHeight > this.maxHeight
    },
    toggle() {
      if (timer) {
        clearTimeout(timer)
      }

      if (this.expanded) {
        this.contentHeight = this.$refs.wrapper.clientHeight
        nextTick(() => {
          this.transitionDelay = ANIMATION_DELAY
          this.contentHeight = this.maxHeight
        })
      } else {
        this.transitionDelay = ANIMATION_DELAY
        this.contentHeight = this.wrapperHeight

        setTimeout(() => {
          this.contentHeight = 'auto'
          this.transitionDelay = 0
        }, ANIMATION_DELAY)
      }

      this.expanded = !this.expanded
      this.$emit('expand', this.expanded)
    },
  },
}
</script>

<template>
  <div>
    <div
      :class="(expanded || !showExpander) && 'is-expanded'"
      :style="{
        height: $_contentHeight,
        lineHeight: lineHeight + 'px',
        transitionDuration: transitionDelay + 'ms',
      }"
      class="relative overflow-hidden transition-height ease-in-out"
    >
      <div ref="wrapper">
        <slot />
      </div>
      <div
        v-if="!expanded && showExpander"
        class="absolute bottom-0 left-0 right-0 z-10 h-10"
        :class="gradientClass"
      />
    </div>
    <div v-if="showExpander">
      <div ref="expander" class="cursor-pointer" @click.prevent="toggle">
        <slot name="title" :expanded="expanded">
          <div class="flex h-10 items-center font-bold text-primary">
            <span>
              {{ expanderText }}
            </span>
            <base-icon
              class="ml-2.5 text-eonx-neutral-600"
              :size="12"
              :svg="expanderIcon"
            />
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
