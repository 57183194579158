<script>
import get from 'lodash-es/get'
import { formatDate } from '/~/utils/format/date'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useQrConnect } from '/~/extensions/qr-connect/composables'
import QrLayout from '../../components/qr-layout.vue'

export default {
  name: 'qr-building-level',
  components: {
    QrLayout,
    BaseIcon,
    BaseLoader,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { map, serachAvailableBusinessAssets } = useQrConnect()

    return {
      map,
      searchAssets: serachAvailableBusinessAssets,
    }
  },
  data() {
    return {
      loading: false,
      levelList: [],
    }
  },
  computed: {
    startsAt() {
      return formatDate('daymonthyearnumeric', this.data.dates?.start)
    },
    endsAt() {
      return formatDate('daymonthyearnumeric', this.data.dates?.end)
    },
  },
  async mounted() {
    this.loading = true
    const response = await this.searchAssets({
      parent: get(this.data, 'building.id'),
      startsAt: this.startsAt,
      endsAt: this.endsAt,
    })

    if (response) {
      this.levelList = response.items
    }

    this.loading = false
  },
  methods: {
    setLevel(level) {
      if (level.disabled) return
      this.map = level.map

      const data =
        this.data.level && level.id === this.data.level.id
          ? this.data
          : {
              dates: this.data.dates,
              building: this.data.building,
              level,
            }

      this.$router.push({
        name: 'qr-reserve-workspace',
        params: {
          data,
          showModal: true,
        },
      })
    },
  },
}
</script>

<template>
  <qr-layout :reserve-workspace="data" hide-footer>
    <div v-if="loading" class="py-20 text-center">
      <base-loader />
    </div>
    <div v-else class="sm:grid sm:grid-cols-2 sm:gap-y-6 sm:gap-x-[30px]">
      <div
        v-for="(item, key) in levelList"
        :key="key"
        class="mb-[15px] flex items-center sm:mb-0"
        :class="!item.disabled && 'cursor-pointer'"
        @click="setLevel(item)"
      >
        <div
          class="mr-[15px] flex h-12 w-12 items-center justify-center rounded-full"
          :class="[
            item.disabled && 'bg-white text-neutral-400',
            !item.disabled && 'bg-amber-300 text-blue-500',
          ]"
        >
          <base-icon svg="qr/building" size="md" />
        </div>
        <div class="font-bold">
          {{ item.name }}
        </div>
      </div>
    </div>
  </qr-layout>
</template>
