<script setup lang="ts">
import CheckoutSummaryAddress from '/~/components/checkout/summary/checkout-summary-address.vue'
import CheckoutSummaryItems from '/~/components/checkout/summary/checkout-summary-items.vue'
import CheckoutSummaryPayWith from '/~/components/checkout/summary/checkout-summary-pay-with.vue'
import { useCart } from '/~/composables/cart'
import { useQuickBuyCheckout } from '/~/composables/checkout'
import { useProvider } from '/~/composables/provider'

const {
  quickBuyProducts,
  payment,
  selectedCard,
  payWithCard,
  selectedPoints,
  payWithPoints,
  selectedEwallet,
  payWithEwallet,
  selectedBankAccount,
  payWithBankAccount,
  selectedCoupons,
  payWithCoupons,
} = useQuickBuyCheckout()
const { isUserAddressEnabled } = useProvider()
const { cartOrderAddresses } = useCart()
</script>

<template>
  <div v-if="quickBuyProducts.length > 0">
    <div
      v-for="(item, index) in quickBuyProducts"
      :key="index"
      class="w-full space-y-6"
      @click="$emit('item', item)"
    >
      <checkout-summary-address
        v-if="isUserAddressEnabled"
        :billing="cartOrderAddresses.billing"
        :shipping="
          payment.hasDeliverableItems ? cartOrderAddresses.shipping : null
        "
        :loading="cartOrderAddresses.loading"
      />
      <checkout-summary-items :items="quickBuyProducts" />
      <checkout-summary-pay-with
        :credit-card="{ details: selectedCard, total: payWithCard }"
        :points="{ details: selectedPoints, total: payWithPoints }"
        :ewallet="{ details: selectedEwallet, total: payWithEwallet }"
        :bank-account="{
          details: selectedBankAccount,
          total: payWithBankAccount,
        }"
        :coupons="{ items: selectedCoupons, total: payWithCoupons }"
      />
    </div>
  </div>
</template>
