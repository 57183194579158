<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EmptyState from '/~/components/empty-state/empty-state.vue'
import { useProvider } from '/~/composables/provider'
import { useStatementAccounts } from '/~/composables/statements'
import StatementsListItemDesk from './statements-list-item.desk.vue'
import StatementsListItemMobile from './statements-list-item.mobile.vue'

const router = useRouter()
const {
  statementAccounts,
  isStatementAccountsEmpty,
  fetchStatementAccounts,
  isStatementAccountsLoading,
  allowMultipleStatementAccounts,
} = useStatementAccounts()
const { providerClientName } = useProvider()

const enabledAddNewButton = allowMultipleStatementAccounts

fetchStatementAccounts({
  includeAutoPayments: true,
  includeNextDueStatementOrders: true,
})

function onAddAccountClicked() {
  router.push({
    name: 'statements-new-account',
    params: {
      type: 'new-account',
    },
  })
}

const component = computed(() => {
  return ui.mobile ? StatementsListItemMobile : StatementsListItemDesk
})
</script>

<template>
  <div class="h-full w-full">
    <div
      v-if="isStatementAccountsLoading"
      class="flex-center flex items-center pt-6"
    >
      <base-loader fullwidth size="xl" />
    </div>
    <div
      v-else-if="!isStatementAccountsEmpty"
      class="w-full"
      :class="ui.mobile ? 'h-auto' : 'h-full'"
    >
      <div class="h-full w-full overflow-y-auto overflow-x-hidden">
        <div class="overflow-hidden sm:mt-0 sm:space-y-0 sm:rounded-lg">
          <div v-for="account in statementAccounts" :key="account.id">
            <component
              :is="component"
              v-if="account.statementOrders.length === 0"
              :account="account"
            />
            <template v-else>
              <component
                :is="component"
                v-for="statementOrder in account.statementOrders"
                :key="statementOrder.id"
                :item="statementOrder"
                :account="account"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <empty-state
      v-else
      icon="heroicons/solid/calendar"
      class="flex h-full flex-col justify-center"
    >
      <template #title>No accounts available</template>
      <template #description>
        Add accounts to make payments on this platform
      </template>
      <template #button>
        <base-button
          v-if="enabledAddNewButton"
          data-testid="add-statement-account"
          full-width
          :size="ui.desktop ? 'sm' : null"
          @click="onAddAccountClicked"
        >
          <span>+ Add a {{ providerClientName }} account</span>
        </base-button>
      </template>
    </empty-state>
  </div>
</template>
