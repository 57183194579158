<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useCheckoutReactive } from '/~/composables/checkout'
import { useFlowPaymentMethods } from '/~/composables/payment-methods'
import PaymentSummary from './components/checkout/checkout-summary.vue'

const route = useRoute()
const { currentFlowType, payment } = useCheckoutReactive()
const {
  fetchPaymentMethods,
  initPaymentMethodsUpdates,
  stopPaymentMethodsUpdates,
} = useFlowPaymentMethods(currentFlowType.value, (payment.value as any).payeeId)

onMounted(() => {
  fetchPaymentMethods()
  initPaymentMethodsUpdates()
})

onBeforeUnmount(() => {
  stopPaymentMethodsUpdates()
})

type TransitionType =
  | 'enter-class'
  | 'enter-active-class'
  | 'leave-active-class'

const transitionArgs = ref<{ [K in TransitionType]?: string }>({
  'enter-class': '',
  'enter-active-class': 'absolute',
})

watch(
  () => ({ ...route }),
  (to, from) => {
    transitionArgs.value =
      from.meta?.index < to.meta?.index
        ? {
            'enter-class': 'translate-x-full opacity-0',
            'leave-active-class': 'absolute -translate-x-full opacity-0',
          }
        : {
            'enter-class': '-translate-x-full opacity-0',
            'leave-active-class': 'absolute translate-x-full opacity-0',
          }
  }
)

const hideSummary = computed(() => route.meta?.hideSummary ?? false)
</script>

<template>
  <div class="h-full w-full sm:flex">
    <div
      class="relative flex h-full w-full flex-col overflow-hidden bg-eonx-neutral-50 sm:rounded-t-[40px]"
    >
      <transition v-bind="transitionArgs">
        <router-view
          class="w-full shrink-0 transform transition duration-500"
        />
      </transition>
    </div>

    <div v-if="!hideSummary" class="hidden h-full w-full sm:block">
      <payment-summary />
    </div>
  </div>
</template>
