<script setup lang="ts">
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  ChartData,
  Plugin,
  ChartOptions,
  DefaultDataPoint,
} from 'chart.js'
import { Line as LineChartComponent } from 'vue-chartjs'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
)

withDefaults(
  defineProps<{
    chartData: ChartData<'line', DefaultDataPoint<'line'>, unknown>
    options?: ChartOptions<'line'>
    plugins?: Plugin<'line'>[]
  }>(),
  {
    options: () => ({}),
    plugins: () => [],
  }
)
</script>

<template>
  <line-chart-component
    :data="chartData"
    :options="options"
    :plugins="plugins"
  />
</template>
