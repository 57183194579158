<script setup lang="ts">
import { computed } from 'vue'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import { useCheckoutReactive } from '/~/composables/checkout'
import PaymentsMakeSuccessful from './payments-make-successful.vue'

const { payment } = useCheckoutReactive()

const title = computed(() => {
  if (!payment.value.isConfirmationLoaded) {
    return 'Payment'
  }

  switch (payment.value?.confirmation?.order?.status) {
    case 'scheduled':
      return 'Payment scheduled'
    case 'completed':
    case 'completed_with_remittance_failures':
      return 'Payment successful'

    case 'pending':
    case 'remittance_pending':
      return 'Payment pending'
    case 'cancelled':
      return 'Payment cancelled'
    default:
      return 'Payment failed'
  }
})
</script>

<template>
  <div v-if="!ui.mobile" class="mx-auto max-w-2xl">
    <payments-make-successful />
  </div>
  <base-aside-page v-else :title="title" no-padding shadow :back="false">
    <div class="sm:pb-7">
      <payments-make-successful />
    </div>
  </base-aside-page>
</template>
