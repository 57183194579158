import { RecroomUser } from './user'

export class RecroomChatMember extends RecroomUser {
  get id() {
    return this.raw.user_id
  }

  get memberId() {
    return this.raw.id
  }

  get typing() {
    return this.raw.typing
  }
}
