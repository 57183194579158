<script>
import Offer from './community-hub-offer.vue'
import BaseBox from '/~/components/base/box/base-box.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useUI } from '/~/composables/ui'
import { computed } from 'vue'

export default {
  name: 'community-hub-retailer-offers',
  components: {
    Offer,
    BaseBox,
    BaseLink,
  },
  props: {
    retailer: {
      type: Object,
      default: () => ({}),
    },
    offers: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { titleRef } = useUI()

    const offersAvailable = computed(
      () => props.retailer.active && props.offers.length > 0
    )
    const hasHtmlOffers = computed(
      () => props.offers.find((o) => o.type === 'html')?.length > 0
    )

    return {
      titleRef,
      offersAvailable,
      hasHtmlOffers,
    }
  },
}
</script>

<template>
  <base-box hide-header>
    <div class="mx-5 mt-[5px] sm:mx-0">
      <h2
        ref="titleRef"
        class="mb-[15px] block text-center text-3xl font-light leading-10 !outline-none sm:text-left"
        tabindex="-1"
      >
        {{ retailer.name }}
      </h2>

      <div class="text-eonx-neutral-800">
        {{ retailer.description }}
      </div>

      <div
        class="mt-[15px] block overflow-hidden truncate text-primary hover:text-primary-darken sm:hidden"
      >
        <base-link
          v-if="retailer.website"
          :href="retailer.website"
          class="font-bold leading-8 !underline underline-offset-4"
        >
          {{ retailer.website.replace(/^https?:\/\/|\/$/g, '') }}
        </base-link>
      </div>

      <h3
        v-if="!hasHtmlOffers"
        class="mt-[30px] text-xl font-bold text-eonx-neutral-800"
      >
        Offers
      </h3>

      <template v-if="offersAvailable">
        <offer
          v-for="offer in offers"
          :key="offer.id"
          :retailer="retailer"
          :offer="offer"
          class="mt-[15px]"
        />
      </template>

      <div v-else class="leading-6 text-eonx-neutral-600">
        <hr class="my-5" />
        <div>
          Sorry, offers are currently unavailable for this retailer.
          <br />
          Please try again later.
        </div>
      </div>
    </div>
  </base-box>
</template>
