<script>
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { formatBsb } from '/~/utils/format/string'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'

export default {
  name: 'activity-details-item-payee',
  components: {
    PayeeIcon,
  },
  props: {
    payee: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { formatBusinessNumber, translate } = useLocalization()
    const { accountPresentation } = useBankAccountForm()

    const payeeName = computed(() => {
      const { accountName, name } = props.payee

      return name || accountName
    })

    return {
      formatDollar,
      payeeName,
      formatBsb,
      formatBusinessNumber,
      translate,
      accountPresentation,
    }
  },
}
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center">
      <payee-icon :payee="payee" />
      <div class="ml-[15px] flex flex-col">
        <div class="font-bold text-eonx-neutral-800">
          {{ payeeName }}
        </div>
        <div
          v-if="payeeName !== payee.accountName"
          class="text-sm text-eonx-neutral-600"
        >
          {{ payee.accountName }}
        </div>
        <div v-if="payee.reference" class="text-sm text-eonx-neutral-600">
          <div>Ref: {{ payee.reference }}</div>
          <template v-if="payee.type === 'bankAccount'">
            <div v-if="accountPresentation.bsb && payee.bsb">
              BSB: {{ formatBsb(payee.bsb) }}
            </div>
            <div v-if="payee.accountNumber">Acc: {{ payee.accountNumber }}</div>
            <div v-if="payee.abn">
              {{ translate('payment.businessNumber') }}:
              {{ formatBusinessNumber(payee.abn) }}
            </div>
          </template>
          <template v-else-if="payee.type === 'bpay'">
            <div v-if="payee.billerCode">
              BPAY Biller Code: {{ payee.billerCode }}
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="text-eonx-neutral-600">
      {{ formatDollar(payee.subtotal) }}
    </div>
  </div>
</template>
