<script>
import get from 'lodash-es/get'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'
import PointsPartnerPlaceholder from '../../components/points-partner-placeholder.vue'
import PointsPartner from '../../components/points-partner.vue'

export default {
  name: 'fly-business-list',
  components: {
    PointsPartner,
    PointsPartnerPlaceholder,
  },
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { accounts, fetchAccountsLoading, partners, fetchAccounts } =
      usePointsPrograms()

    return {
      accounts,
      fetchAccountsLoading,
      partners,
      fetchAccounts,
    }
  },
  computed: {
    hasAccounts() {
      return this.accounts && this.accounts.length
    },
    partnerType() {
      return get(this.$route, 'query.type')
    },
    selectedPartner() {
      return this.partners.find((partner) => partner.type === this.partnerType)
    },
    filterAccounts() {
      if (!this.hasAccounts) return
      const accounts = this.accounts
        .filter((account) => account.type === this.partnerType)
        .map((account) => {
          return {
            ...account,
            icon: this.selectedPartner.icon,
          }
        })

      return accounts
    },
  },
  watch: {
    hasAccounts(data) {
      if (!data) this.$router.push({ name: 'fly-partners-list' })
    },
  },
  created() {
    if (!this.hasAccounts) {
      this.fetchAccounts()
    }
  },
  methods: {
    onClickHandle(account) {
      this.$router.push({
        name: 'fly-points-transfer',
        query: {
          ...this.$route.query,
          accountId: account.id,
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <points-partner-placeholder v-if="fetchAccountsLoading" :rows="3" />
    <div v-else class="space-y-[15px]">
      <points-partner
        v-for="(account, index) in filterAccounts"
        :key="`account-${index}`"
        :partner="account"
        :fill="fill"
        :arrow="false"
        show-menu
        @click="onClickHandle(account)"
      />
    </div>
  </div>
</template>
