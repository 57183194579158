<script>
import BaseImage from '/~/components/base/image/base-image.vue'

export default {
  name: 'details-view-service-logo',
  components: {
    BaseImage,
  },
  props: {
    alt: {
      type: String,
      default: 'Retailer Logo',
    },
    image: {
      type: String,
      default: '',
    },
  },
  computed: {
    defaultImage() {
      return 'https://i.ewalletcdn.com/45d490c5-96ca-43bc-99c6-621a63646755/'
    },
    imageUrl() {
      return this.cdn(this.image).resize('400x').url()
    },
  },
}
</script>

<template>
  <div class="mx-5 sm:mx-0">
    <div class="mx-auto my-2.5 block h-auto max-w-sm">
      <base-image
        :src="imageUrl"
        :default-src="defaultImage"
        :alt="alt"
        :ratio="1"
        shadow
      />
    </div>
  </div>
</template>
