<script>
import DefaultCheckoutPay from '/~/views/checkout/components/pay/checkout-pay.vue'

export default {
  extends: DefaultCheckoutPay,
}
</script>

<template>
  <base-button
    :loading="isLoading || processing"
    :disabled="payDisabled"
    class="sm:mx-5 sm:max-w-xs"
    full-width
    @click="onPayButtonClicked"
  >
    <div class="flex items-end">
      <span class="text-sm leading-5">Pay</span>
    </div>
  </base-button>
</template>
