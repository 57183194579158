<script setup lang="ts">
import { computed } from 'vue'
import { OnlineOffersAlgoliaHit } from '/~/types/api/algolia/online-offers'
import OoReturn from '/~/extensions/online-offers/components/oo-return.vue'
import cdn, { getProviderDefaultUrl } from '/~/utils/cdn'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { useProvider } from '/~/composables/provider'

const props = defineProps<{
  tile: OnlineOffersAlgoliaHit
}>()

const { isBupaTemplate } = useProvider()

const merchant = computed(() => props.tile.payload)
const cashbackRate = computed(() => merchant.value.cashback_rate)
const defaultImage = computed(() =>
  isBupaTemplate.value ? getProviderDefaultUrl('bupa') : undefined
)
const image = computed(() => cdn(merchant.value.logo).url())
</script>

<template>
  <base-link
    :to="{
      name: 'online-offers-retailer',
      params: { slug: tile.merchant_id },
    }"
  >
    <base-image
      :src="image"
      :default-src="defaultImage"
      :alt="merchant.name"
      :ratio="1"
      :fit="false"
      :shadow="false"
      :rounded="false"
      :aria-hidden="true"
      top-rounded
    />
    <div class="space-y-1 border-t p-[15px] text-center font-bold text-default">
      <h3
        class="truncate text-sm text-eonx-neutral-600"
        data-cy="ew-title-name"
      >
        {{ merchant.name }}
      </h3>
      <oo-return class="mx-auto w-[80%]" :cashback-rate="cashbackRate" />
    </div>
  </base-link>
</template>
