<script setup lang="ts">
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { useProvider } from '/~/composables/provider'

const { payment } = useCheckoutReactive()
const { taxationLabel } = useProvider()

const programFeesLabel = computed(() => {
  return `Program Fee (${payment.value.programFeesPercentage}% inc. ${taxationLabel.value})`
})
const paymentProcessingFeesLabel = computed(() => {
  const method =
    payment.value.selectedCard || payment.value.selectedBankAccount || {}
  const label =
    method.percentageFeeRate === 0
      ? `inc. ${taxationLabel.value}`
      : `${method.percentageFeeRate}% inc. ${taxationLabel.value}`

  return `Processing Fee (${label})`
})
</script>

<template>
  <div
    v-if="payment.totalFees"
    class="border-t py-4 text-sm text-eonx-neutral-600"
  >
    <div class="flex justify-between text-base font-bold text-eonx-neutral-800">
      <div class="leading-7">Fees</div>
      <div>
        {{ formatDollar(payment.totalFees) }}
      </div>
    </div>
    <div class="mt-3 space-y-1 sm:space-y-1.5">
      <div v-if="payment.shippingFees" class="flex justify-between">
        <div>Shipping Fee</div>
        <div class="pl-4">
          {{ formatDollar(payment.shippingFees) }}
        </div>
      </div>
      <div v-if="payment.programFees" class="flex justify-between">
        <div data-testid="program-fee">{{ programFeesLabel }}</div>
        <div class="pl-4">
          {{ formatDollar(payment.programFees) }}
        </div>
      </div>
      <div v-if="payment.transactionFees" class="flex justify-between">
        <div data-testid="processing-fee">{{ paymentProcessingFeesLabel }}</div>
        <div class="pl-4">
          <base-loader v-if="payment.loadingTransactionFees" size="xxs" />
          <template v-else>
            {{ formatDollar(payment.transactionFees) }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
