<script>
import DefaultEwProductType from '/~/components/ewallet/product/ew-product-type.vue'

export default {
  extends: DefaultEwProductType,
  props: {
    digitalIcon: {
      type: String,
      default: 'australiapost/profile',
    },
    physicalIcon: {
      type: String,
      default: 'australiapost/truck2',
    },
  },
}
</script>

<template>
  <div class="flex items-center text-sm leading-tight text-eonx-neutral-600">
    <template v-if="physical">
      <template v-if="hasGift">
        <base-icon :size="iconSize || 'md'" class="mr-2.5" :svg="giftIcon" />
        <slot name="physical">
          <span>
            {{ title }}
          </span>
        </slot>
      </template>
      <template v-else-if="showDelivery">
        <base-icon
          :size="iconSize || 'md'"
          :svg="physicalIcon"
          class="mr-2.5"
        />
        <span>
          {{ title }}
        </span>
      </template>
    </template>
    <template v-else>
      <template v-if="hasGift">
        <base-icon :size="iconSize || 'sm'" :svg="giftIcon" class="mr-[15px]" />
        <slot name="digital">
          <span>
            {{ title }}
          </span>
        </slot>
      </template>
      <template v-else-if="showDelivery">
        <base-icon :size="iconSize || 'md'" :svg="digitalIcon" class="mr-2.5" />
        <span>
          {{ title }}
        </span>
      </template>
    </template>
  </div>
</template>
