import { computed } from 'vue'
import { type RouteConfig } from 'vue-router'
import defaultCartMenuRoutes from '/~/components/cart-menu/menu-routes'
import defaultEwalletMenuRoutes from '/~/components/ewallet-menu/menu-routes'
import { useProvider } from '/~/composables/provider'
import australiapostCartMenuRoutes from '/~/templates/australiapost/components/cart-menu/menu-routes'
import australiapostEwalletMenuRoutes from '/~/templates/australiapost/components/profile-menu/profile-menu-routes'
import billPaymentsEwalletMenuRoutes from '/~/templates/bill-payments/components/profile-menu/profile-menu-routes'

const { isBillPaymentsTemplate, isAustraliapostTemplate } = useProvider()

const ewalletMenuRoutes = computed<Array<RouteConfig>>(() => {
  if (isAustraliapostTemplate.value) {
    return [...australiapostEwalletMenuRoutes]
  } else if (isBillPaymentsTemplate.value) {
    return [...billPaymentsEwalletMenuRoutes]
  } else {
    return [...defaultEwalletMenuRoutes]
  }
})

const cartMenuRoutes = computed(() => {
  if (isAustraliapostTemplate.value) {
    return [...australiapostCartMenuRoutes]
  } else {
    return [...defaultCartMenuRoutes]
  }
})

function getMatchingRoute(routes: RouteConfig[], hash: string) {
  let props: Record<string, string> | undefined

  let menuRoute = routes.find((route) => {
    const parts = route.path.split('/')
    const regex = new RegExp(
      '^' +
        parts
          .map((part) =>
            part.startsWith(':')
              ? `(?:/(?<${part.substr(1)}>[a-zA-Z0-9_-]*))?`
              : `/${part}`
          )
          .join('')
          .substr(1) +
        '$',
      'gi'
    )

    const result = regex.exec(hash)

    props = result?.groups

    return result
  })

  if (!menuRoute) {
    const path = hash.split('/')?.[0]

    menuRoute = routes.find((route) => route.path === path)
  }

  if (!menuRoute) {
    return null
  }

  return {
    ...menuRoute,
    props: {
      ...(menuRoute?.props && typeof menuRoute.props === 'object'
        ? menuRoute.props
        : undefined),
      ...(props && typeof props === 'object' ? props : undefined),
    },
  }
}

export function useDrawerMenu() {
  return {
    ewalletMenuRoutes,
    cartMenuRoutes,
    getMatchingRoute,
  }
}
