<script>
import DefaultGiftCardsCatalog from '/~/extensions/gift-cards-cinema/components/gc-catalog.vue'
import GcTile from './gc-tile.vue'

export default {
  name: 'giftcards-catalog',
  components: {
    GcTile,
  },
  extends: DefaultGiftCardsCatalog,
}
</script>
