<script>
export default {
  name: 'rec-optus-modal',
}
</script>

<template>
  <div
    class="absolute top-1/2 left-1/2 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center"
  >
    <div
      class="absolute top-1/2 left-1/2 z-10 w-xs max-w-full -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-white p-5 text-center shadow-md"
    >
      <div class="mb-5 text-2xl font-bold leading-tight">
        Welcome to the Optus
        <br />
        News Room
      </div>
      <div class="mb-2.5">
        Connect with colleagues, read up on the
        <br />
        latest group posts and engage with
        <br />
        relevant company content.
      </div>
      <div class="mb-2.5 text-eonx-neutral-600">No content posted</div>
      <div class="mb-2.5">
        <img
          src="/images/recroom/optus/modal.png"
          alt="recroom"
          class="mx-auto h-56 w-56"
        />
      </div>
    </div>
    <div class="fixed inset-0 z-0 bg-overlay" />
  </div>
</template>
