<script>
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseState from '/~/components/base/state/base-state.vue'

export default {
  name: 'recroom-layout-init',
  components: {
    BaseState,
    BaseLoader,
  },
}
</script>

<template>
  <div class="my-[30px]">
    <base-state>
      <template #header>
        <base-loader class="mb-5" />
      </template>
      Connecting to Rec Room...
    </base-state>
  </div>
</template>
