<script>
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'
import BaseAction from '/~/components/base/action/base-action.vue'
import WgtBgText from '../../wgt/wgt-bg-text.vue'

export default {
  name: 'widget-tiles-optus-content',
  components: {
    BaseAction,
    WgtBgText,
  },
  props: {
    tile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.tile.buttonColor,
        color: this.tile.buttonTextColor,
      }
    },
  },
}
</script>

<template>
  <div class="relative text-black">
    <div class="flex items-end justify-start font-bold">
      <div
        v-if="tile.content.title"
        class="absolute mx-5 my-[30px]"
        :style="{ fontSize: ui.mobile ? '1.3rem' : '1.49rem' }"
      >
        <wgt-bg-text
          :content="tile.content.title"
          :text-color="tile.titleColor"
          :background-color="tile.titleBackgroundColor"
          :style="{ padding: '4px' }"
          class="mt-2.5"
        />
      </div>
      <img
        :src="
          cdn(tile.image.desktop)
            .preview('1496x808')
            .format('auto')
            .quality('smart')
            .url()
        "
        :alt="tile.image.alt"
        class="w-full"
      />
    </div>
    <div
      class="px-5 pt-[30px] pb-5"
      :style="{ backgroundColor: tile.contentBackgroundColor }"
    >
      <p
        v-if="tile.content.text"
        class="mb-5 text-base"
        :style="{ color: tile.contentColor }"
        v-html="tile.content.text"
      />
      <base-action
        v-if="tile.tileLink && tile.tileLink.enabled"
        v-bind="tile.tileLink"
        origin="banner"
        appearance="link"
      >
        <button
          type="button"
          class="button h-12 w-full font-bold"
          :style="buttonStyle"
          v-html="tile.tileLink.label"
        />
      </base-action>
    </div>
  </div>
</template>
