<script>
export default {
  name: 'card-find-out-more',

  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
    clickAction: {
      type: Function,
      default: null,
    },
  },

  methods: {
    handleButtonClick() {
      if (typeof this.clickAction === 'function') {
        this.clickAction()
      }
    },
  },
}
</script>

<template>
  <div class="relative w-full overflow-hidden bg-sky-900 px-6 pt-6">
    <slot />
    <button
      v-if="showButton"
      class="relative z-10 my-[30px] rounded border-2 border-solid border-white bg-sky-900 px-10 py-2.5 text-sm font-bold text-white transition-colors duration-150 hover:bg-sky-800"
      @click="handleButtonClick"
    >
      Find out more
    </button>
    <slot name="footer">
      <img
        class="absolute bottom-0 left-0 ml-2.5"
        src="/images/symbion/dental-card-curls.svg"
      />
    </slot>
  </div>
</template>
