import { ValidationProvider } from 'vee-validate'
import { useInput } from '/~/composables/base'

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: [String, Number],
      default: 'md',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: [String, Array],
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredAsterisk: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    nolabel: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      default: null,
    },
    floated: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: null,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      isFocused: false,
    }
  },
  setup(props, { emit }) {
    const {
      validationProviderRef,
      validateOnBlur,
      validateOnInput,
      validate,
      validateSilent,
      reset,
      syncValue,
    } = useInput(props, emit)

    return {
      validationProviderRef,
      validateOnBlur,
      validateOnInput,
      validate,
      validateSilent,
      reset,
      syncValue,
    }
  },
  computed: {
    id() {
      return `${this.name || 'input'}-${this._uid}`
    },
  },
  methods: {
    onFocus() {
      this.isFocused = true
      if (!this.disabled) {
        this.$emit('focus')
      }
    },
    onBlur($event) {
      this.isFocused = false

      this.validateOnBlur($event)
      this.$emit('blur', $event)
    },
    onInput(value) {
      this.validateOnInput(value)
      this.$emit('input', value)
    },
  },
}
