<script>
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'question-numeric',
  components: {
    BaseButton,
  },
  props: {
    value: {
      type: String,
      default: '00',
    },
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      number: '00',
    }
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 8) this.number = '00'
    })
    document.addEventListener('keypress', (e) => {
      if (isNaN(String.fromCharCode(e.which))) e.preventDefault()
      else if (parseInt(this.number) > 10000) return null
      else if (parseInt(this.number) === 0) {
        this.number = '0' + e.key
      } else if (parseInt(this.number) < 10) {
        this.number = parseInt(this.number) + '' + e.key
      } else {
        this.number = this.number + e.key
      }
    })
  },
  methods: {
    confirm() {
      this.$emit('input', this.number)
      this.$emit('next')
      this.number = '00'
    },
  },
}
</script>

<template>
  <div class="flex flex-col items-center">
    <h2 class="text-6xl text-eonx-neutral-600">
      <span ref="input">
        {{ number }}
      </span>
      {{ content.scale }}
    </h2>
    <div class="flex justify-center">
      <base-button
        class="mx-[5px] mb-[15px] w-40"
        :disabled="parseInt(number) === 0"
        @click="confirm"
      >
        Confirm
      </base-button>
    </div>
  </div>
</template>
