<script setup lang="ts">
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useBatchOrderStatusHandler } from '/~/composables/batch-order'

const { batchOrderStatusHandler } = useBatchOrderStatusHandler()
</script>

<template>
  <base-mdl
    :closable="false"
    width="xs"
    layout="v2"
    border-radius="xs"
    :show-close-icon="false"
  >
    <div
      class="my-20 flex flex-col items-center justify-center space-y-8 text-center text-3xl font-bold"
    >
      <base-loader size="2xl" />
      <div>{{ batchOrderStatusHandler.batchOrder.statusMessage }}</div>
    </div>
  </base-mdl>
</template>
