import { computed, readonly, ref } from 'vue'
import type { ApiResponseData, ApiResponseList } from '/~/types/api'
import api from '/~/core/api'
import {
  PhotoIdTransaction,
  type PhotoIdTransactionRaw,
  type NewPhotoIdTransaction,
} from './core/photo-id-transaction'

const isPhotoIdsTransactionsLoading = ref(false)
const isPhotoIdsTransactionsError = ref('')
const photoIdsTransactions = ref<Array<PhotoIdTransaction>>([])

const photoIdsIncompleteTransactions = computed(() => {
  return photoIdsTransactions.value.filter((transaction) => {
    return !transaction.isCompleted
  })
})

const photoIdsPendingTransactions = computed(() => {
  return photoIdsIncompleteTransactions.value.filter((transaction) => {
    return transaction.isPending
  })
})

const hasPhotoIdsPendingTransactions = computed(() => {
  return photoIdsPendingTransactions.value.length > 0
})

const hasNoPhotoIdsTransactions = computed(() => {
  return (
    !isPhotoIdsTransactionsLoading.value &&
    photoIdsTransactions.value.length === 0
  )
})

async function fetchPhotoIdsTransactions() {
  isPhotoIdsTransactionsLoading.value = true
  await fetchPhotoIdsTransactionsSilent()
  isPhotoIdsTransactionsLoading.value = false
}

async function fetchPhotoIdsTransactionsSilent() {
  isPhotoIdsTransactionsError.value = ''

  try {
    const response = await api.get<ApiResponseList<PhotoIdTransactionRaw>>(
      '/v3/identification/transactions?perPage=100'
    )

    photoIdsTransactions.value = (response.data?.items ?? [])
      .map((transaction) => {
        return new PhotoIdTransaction(transaction)
      })
      .sort((a) => {
        return a.isPending ? -1 : 0
      })
  } catch (error: any) {
    isPhotoIdsTransactionsError.value = error.data?.message || error.message
  }
}

async function createPhotoIdsTransaction() {
  isPhotoIdsTransactionsLoading.value = true

  try {
    const response = await api.post<ApiResponseData<NewPhotoIdTransaction>>(
      '/v3/identification/transactions'
    )

    document.location.replace(response.data.idvUrl)
  } catch (error) {
    console.error('identification', error)
    throw error
  } finally {
    isPhotoIdsTransactionsLoading.value = false
  }
}

async function deletePhotoIdsTransaction(transaction: PhotoIdTransaction) {
  transaction.isDeleting = true

  try {
    await api.delete(`/v3/identification/transactions/${transaction.id}`)
    await fetchPhotoIdsTransactionsSilent()
  } catch (error) {
    console.error('identification', error)
    transaction.isDeleting = false
  }
}

export function usePhotoIdsTransactions() {
  return {
    isPhotoIdsTransactionsLoading: readonly(isPhotoIdsTransactionsLoading),
    hasNoPhotoIdsTransactions,
    hasPhotoIdsPendingTransactions,
    photoIdsTransactions: readonly(photoIdsTransactions),
    photoIdsIncompleteTransactions,
    photoIdsPendingTransactions,
    fetchPhotoIdsTransactions,
    fetchPhotoIdsTransactionsSilent,
    createPhotoIdsTransaction,
    deletePhotoIdsTransaction,
  }
}
