import { reactive } from 'vue'
import { BatchOrderLoader, BatchOrderLoaderParams } from './BatchOrderLoader'
import { BatchOrderPagination } from './BatchOrderPagination'
import { BatchOrderPayee } from './BatchOrderPayee'
import { BatchOrderPayeeList } from './BatchOrderPayeeList'

export class BatchOrderUnsupportedPayees {
  payeeList: BatchOrderPayeeList
  pagination: BatchOrderPagination
  loader: BatchOrderLoader

  constructor(params: BatchOrderLoaderParams) {
    this.payeeList = reactive(new BatchOrderPayeeList())
    this.pagination = reactive(new BatchOrderPagination())
    this.loader = reactive(new BatchOrderLoader(params))
  }

  async load(page: number) {
    const { pagination, items } = (await this.loader.load(page)) as {
      pagination: BatchOrderPagination
      items: Array<{ payee: BatchOrderPayee }>
    }

    this.pagination.setItems(pagination)
    this.payeeList.setItems(
      items.map((item) => {
        return { ...item, name: item.payee?.name }
      })
    )
  }
}
