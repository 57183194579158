<script setup lang="ts">
import { useDining } from '/~/extensions/dining/composables'
import BaseButton from '/~/components/base/button/base-button'

const { mapState } = useDining()

const emit = defineEmits<{
  (event: 'change', value: boolean): void
}>()

function toggle() {
  mapState.toggleVisibility()

  emit('change', mapState.isEnabled)
}
</script>

<template>
  <base-button v-if="mapState.isEnabled" size="sm" @click="toggle">
    {{ mapState.visible ? 'Hide map' : 'Show map' }}
  </base-button>
</template>
