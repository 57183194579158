<script>
import BaseImage from '/~/components/base/image/base-image.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-sidebar-top-banner',
  components: {
    BaseImage,
  },
  setup() {
    const { sidebarBannerImage, profile } = useRecProfile()

    return { sidebarBannerImage, me: profile.me }
  },
  computed: {
    imageBindings() {
      const normalLink = this.cdn(this.sidebarBannerImage)
        .scaleCrop('325x378/smart')
        .url()
      const retinaLink = this.cdn(this.sidebarBannerImage)
        .scaleCrop('650x756/smart')
        .url()

      return {
        src: normalLink,
        srcset: `${normalLink} 1x, ${retinaLink} 2x`,
        ratio: 325 / 378,
      }
    },
  },
}
</script>

<template>
  <div class="relative select-none">
    <base-image v-bind="imageBindings" class="rounded-lg bg-gray-300" />
    <div class="absolute top-0 mt-32 ml-5 max-w-full font-bold text-white">
      <div
        class="mb-5 mt-[5px] flex w-40 items-end bg-primary px-0.5 text-3xl leading-9"
        style="height: 21px; font-size: 34px"
      >
        <span>Welcome</span>
      </div>
      <div
        class="mb-[15px] inline-flex max-w-full items-end bg-primary px-0.5 leading-9"
        style="height: 21px; font-size: 34px"
      >
        <span class="truncate" style="max-width: 18rem">
          {{ me.firstName }}
        </span>
      </div>
      <br />
      <div class="leading-5">
        <div
          class="inline-flex items-end leading-4"
          style="height: 13px; font-size: 17px; background: #0e2437"
        >
          Access exclusive offers and
        </div>

        <div
          class="inline-flex items-end leading-4"
          style="height: 13px; font-size: 17px; background: #0e2437"
        >
          enjoy everyday savings across
        </div>

        <div
          class="inline-flex items-end leading-4"
          style="height: 13px; font-size: 17px; background: #0e2437"
        >
          all your favourite brands
        </div>
      </div>
    </div>
  </div>
</template>
