<script setup lang="ts">
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import {
  getBrandImage,
  PaymentMethodType,
} from '/~/composables/payment-methods'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import {
  useStatementsListItem,
  StatementAccount,
} from '/~/composables/statements'

const props = withDefaults(
  defineProps<{
    account?: StatementAccount | null
  }>(),
  {
    account: null,
  }
)

const { accountPresentation } = useBankAccountForm()

const {
  title,
  clientName,
  autoPayDay,
  paymentMethod,
  isAutoPayToggled,
  statementAccount,
  disableDirectDebit,
} = useStatementsListItem({ account: props.account })
</script>

<template>
  <div class="block rounded-xl bg-white p-6 text-neutral-400">
    <div class="mb-5 flex justify-between space-x-[30px]">
      <div class="flex min-w-0 items-center space-x-[30px]">
        <h3
          class="inline-block divide-x truncate text-lg text-eonx-neutral-800"
        >
          <span v-if="clientName" class="pr-2">
            {{ clientName }}
          </span>
          <span
            v-if="statementAccount && statementAccount.number"
            class="whitespace-nowrap"
            :class="{ 'pl-2': clientName }"
          >
            Account no. {{ statementAccount.number }}
          </span>
        </h3>
      </div>
      <base-button
        icon="heroicons/solid/trash"
        :size="24"
        :padding="2"
        class="text-red-700"
        alt="disable direct debit"
        @click="disableDirectDebit"
      />
    </div>
    <div
      class="flex items-center border-t pt-5 transition-colors duration-150 ease-in-out"
    >
      <payee-icon />
      <div
        class="ml-3 flex h-12 w-1/12 grow flex-col justify-center truncate lg:w-2/12 lg:min-w-32"
      >
        <h4 class="text-base font-bold text-eonx-neutral-800" :title="title">
          {{ title }}
        </h4>
      </div>
      <div
        class="mr-14 flex h-12 min-w-[120px] shrink-0 flex-col items-start justify-between"
      >
        <div class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
          Set payment date
        </div>
        <div class="whitespace-nowrap text-eonx-neutral-800">
          {{ autoPayDay }}
        </div>
      </div>
      <div
        v-if="isAutoPayToggled"
        class="flex h-12 flex-col justify-between text-sm"
      >
        <p class="text-eonx-neutral-600">Payment method</p>
        <div v-if="paymentMethod">
          <p
            v-if="paymentMethod.type === PaymentMethodType.creditCard"
            class="text-eonx-neutral-800"
          >
            <img
              :src="getBrandImage(paymentMethod.brand)"
              :alt="paymentMethod.brand"
              class="mr-[5px] inline w-6"
            />
            <span>**** **** **** {{ paymentMethod.number.slice(-4) }}</span>
          </p>
          <p
            v-if="paymentMethod.type === PaymentMethodType.bankAccount"
            class="flex items-center text-eonx-neutral-800"
          >
            <base-icon
              svg="plain/bank-account-circle"
              :size="16"
              class="mr-[5px] text-eonx-neutral-600"
            />
            <span>
              <template v-if="accountPresentation.bsb">
                {{ paymentMethod.bsb }},
              </template>
              *** *** *{{ paymentMethod.accountNumber.slice(-2) }}
            </span>
          </p>
        </div>
        <div v-else>-</div>
      </div>
    </div>
  </div>
</template>
