import { RecroomUser } from './user'

export class RecroomEventMember extends RecroomUser {
  constructor(raw = {}) {
    super(raw.member)
    this.raw = {
      ...this.raw,
      ...raw,
    }
  }

  get status() {
    return this.raw.status
  }
}
