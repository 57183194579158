import { computed } from 'vue'
import { useExtensions } from '/~/composables/extensions'

const { getManifestByName } = useExtensions()

const PAYROLL_MODULE_NAME = 'payroll'

const module = computed(() => getManifestByName(PAYROLL_MODULE_NAME))

const isPayrollEnabled = computed(() => Boolean(module.value))

export function usePayroll() {
  return {
    isPayrollEnabled,
  }
}
