function fromIdToMention(text, mentions, isChat = false) {
  if (!text) return ''
  let result = text

  if (
    !mentions ||
    (Array.isArray(mentions) && !mentions.length) ||
    !Object.keys(mentions).length
  )
    return result

  const parseString = () => {
    for (const idx in mentions) {
      const user = mentions[idx]
      const fullName = `${user.first_name} ${user.last_name}`

      result = result.replace(
        `[@${user.id}]`,
        `<span data-user-id="${user.id}" contenteditable="false" class="${
          isChat ? '' : 'text-blue-500'
        } font-bold cursor-pointer">@${fullName}</span>`
      )
    }
    if (/\[@\w+/.test(result)) {
      parseString()
    }
  }

  parseString()

  return result
}

function fromIdToMentionAndroid(text, mentions = []) {
  if (!text) {
    return {
      result: '',
      mentioned: [],
    }
  }
  let result = text
  const mentioned = []

  if (
    (Array.isArray(mentions) && !mentions.length) ||
    !Object.keys(mentions).length
  ) {
    return {
      result,
      mentioned,
    }
  }

  const parseString = () => {
    for (const idx in mentions) {
      const user = mentions[idx]
      const nanoId = user.id.slice(0, 5)
      const fullName = `${user.first_name} ${user.last_name}`
      const index = result.indexOf(`[@${user.id}]`)

      if (index !== -1) {
        mentioned.push({
          ...user,
          nanoId,
        })
        result = result.replace(`[@${user.id}]`, `@${fullName}#${nanoId}#`)
      }
    }
    if (/\[@\w+/.test(result)) {
      parseString()
    }
  }

  parseString()

  return {
    result,
    mentioned,
  }
}

function fromMentionToId(text, mentions = []) {
  if (!text) return ''
  let result = text

  for (const idx in mentions) {
    const mention = mentions[idx]
    const name = `${mention.first_name} ${mention.last_name}`
    const mentionElem = `<span data-user-id=\\"${mention.id}\\".+?>@${name}</span>`
    const elemRE = new RegExp(mentionElem, 'g')

    result = result.replace(elemRE, `[@${mention.id}]`)
  }

  return result
}

function fromMentionToIdAndriod(text, mentions = []) {
  if (!text) return ''
  let result = text

  for (const idx in mentions) {
    const mention = mentions[idx]
    const {
      name,
      id,
      first_name: firstName,
      last_name: lastName,
      nanoId,
    } = mention
    let userName = null

    if (name) userName = name
    if (firstName) userName = `${firstName} ${lastName}`
    result = result.replace(`@${userName}#${nanoId}#`, `[@${id}]`)
  }

  return result
}

export {
  fromIdToMention,
  fromMentionToId,
  fromMentionToIdAndriod,
  fromIdToMentionAndroid,
}
