export default ({ ewalletEnabled, ewalletLabels }) => [
  {
    title: 'Personalised',
    image: '/images/hiw/personalised.svg',
    description: `It’s all about you. Manage your profile and control your payment methods ${
      ewalletEnabled ? `and your ${ewalletLabels.ewalletCashBalance}` : ''
    }.`,
  },
  {
    title: 'Convenient',
    image: '/images/hiw/convenient.svg',
    description:
      'Instantly access all of your purchased digital eGift Cards and eVouchers anytime from any device.',
  },
  {
    title: 'Secure',
    image: '/images/hiw/secure.svg',
    description:
      'Rest assured knowing that our encryption technology has your personal information and activity always protected.',
  },
]
