<script>
import ui from '/~/core/ui'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import WgtBgText from '../wgt/wgt-bg-text.vue'

export default {
  name: 'widget-sda-home-banner',
  components: {
    BaseAction,
    BaseIcon,
    WgtBgText,
  },
  props: {
    fullHeightContentBackground: {
      type: Boolean,
      default: false,
    },
    bannerTitle: {
      type: Object,
      default: () => ({}),
    },
    titleColor: {
      type: String,
      default: '',
    },
    titleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerSubtitle: {
      type: Object,
      default: () => ({}),
    },
    subtitleColor: {
      type: String,
      default: '',
    },
    subtitleBackgroundColor: {
      type: String,
      default: '',
    },
    bannerContent: {
      type: Object,
      default: () => ({}),
    },
    contentColor: {
      type: String,
      default: '',
    },
    contentBackgroundColor: {
      type: String,
      default: '',
    },
    bannerButton: {
      type: Object,
      default: () => ({}),
    },
    buttonIcon: {
      type: Boolean,
      default: true,
    },
    buttonColor: {
      type: String,
      default: '',
    },
    buttonTextColor: {
      type: String,
      default: '',
    },
    rightImage: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    isMobile() {
      return ui.width < 768
    },
    topImageStyle() {
      return {
        backgroundImage: `url(${this.rightImage.desktop})`,
        height: '11.25rem',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }
    },
    rightImageStyle() {
      return {
        backgroundImage: `url(${this.rightImage.desktop})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }
    },
    infoStyle() {
      return {
        backgroundColor: this.backgroundColor,
      }
    },
    buttonStyle() {
      return {
        backgroundColor: this.buttonColor,
        color: this.buttonTextColor,
      }
    },
  },
}
</script>
<template>
  <section class="rounded-lg shadow">
    <div v-if="isMobile" class="rounded-t-lg" :style="topImageStyle" />
    <div class="flex items-stretch">
      <div
        class="rounded-bl-lg p-5 md:p-12"
        :class="{
          'w-full rounded-br-lg': isMobile,
          'w-1/2 rounded-tl-lg': !isMobile,
        }"
        :style="infoStyle"
      >
        <div
          v-if="bannerTitle.text"
          class="pb-2.5 text-2xl font-bold leading-tight md:text-3xl"
        >
          <wgt-bg-text
            :content="bannerTitle.text"
            :text-color="titleColor"
            :background-color="titleBackgroundColor"
            :full-height-background="fullHeightContentBackground"
          />
        </div>
        <div
          v-if="bannerSubtitle.text"
          class="pb-[15px] text-2xl font-bold leading-tight md:text-3xl"
        >
          <wgt-bg-text
            :content="bannerSubtitle.text"
            :text-color="subtitleColor"
            :background-color="subtitleBackgroundColor"
            :full-height-background="fullHeightContentBackground"
          />
        </div>
        <div
          v-if="bannerContent.text"
          class="text-xl leading-tight md:mr-24 md:leading-normal"
        >
          <wgt-bg-text
            :content="bannerContent.text"
            :text-color="contentColor"
            :background-color="contentBackgroundColor"
            :full-height-background="fullHeightContentBackground"
          />
        </div>
        <div>
          <base-action
            v-if="bannerButton.enabled"
            v-bind="bannerButton"
            class="inline-block"
            origin="banner"
            appearance="link"
          >
            <button
              type="button"
              class="button mt-[30px] flex h-12 items-center rounded px-5 font-bold"
              :style="buttonStyle"
            >
              <span>
                {{ bannerButton.label }}
              </span>
              <base-icon
                v-if="buttonIcon"
                class="ml-5"
                svg="plain/chevron-right"
                size="sm"
              />
            </button>
          </base-action>
        </div>
      </div>
      <div
        v-if="!isMobile"
        class="w-1/2 rounded-r-lg"
        :style="rightImageStyle"
      />
    </div>
  </section>
</template>
