<script>
import ActivityDetailsAddressesItem from '/~/templates/bill-payments/views/activity/details/components/activity-details-addresses-item.vue'
import { computed } from 'vue'

export default {
  name: 'summary-addresses-item',
  components: {
    ActivityDetailsAddressesItem,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const billingAddress = computed(() => {
      return props.order.billingAddress
    })
    const shippingAddress = computed(() => {
      return props.order.shippingAddress
    })

    return {
      billingAddress,
      shippingAddress,
    }
  },
}
</script>

<template>
  <div>
    <activity-details-addresses-item
      v-if="billingAddress"
      :address="billingAddress"
      title="Billing"
    />
    <activity-details-addresses-item
      v-if="shippingAddress"
      :address="shippingAddress"
      title="Shipping"
      class="mt-4"
    />
  </div>
</template>
