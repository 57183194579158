import { RecroomHi5 } from '/~rec/core/hi5'
import { ref, computed } from 'vue'
import { useRecProfile } from '/~rec/composables/profile'

const { myRecId } = useRecProfile()

type PropsType = {
  hi5Id: string
}

const loading = ref(false)
const hi5 = ref<RecroomHi5>()

const isSender = computed(() => {
  return hi5.value?.sender?.id === myRecId.value
})

const userDetails = computed(() => {
  return isSender.value ? hi5.value?.recipient : hi5.value?.sender || {}
})

export function useHi5Component(props: PropsType) {
  async function init() {
    loading.value = true
    try {
      hi5.value = await RecroomHi5.fetch(props.hi5Id)
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }
  return {
    init,
    isSender,
    userDetails,
    hi5,
    loading,
  }
}
