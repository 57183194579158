<script>
import { onBeforeUnmount, computed, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import DrawerCancelTransaction from '/~/components/ewallet-menu/components/menu-pay-cancel-transaction.vue'
import PaymentMethodsList from '/~/components/payments/list/split.v2.vue'
import { usePayeeCheckout } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'profile-pay-from',
  components: {
    BaseAsidePage,
    BaseButton,
    DrawerCancelTransaction,
    PaymentMethodsList,
  },
  setup() {
    const router = useRouter()
    const { isDarkThemeForEwallet, ewalletLabels } = useCms()
    const {
      subTotalWithProgramFees,
      readyToPay,
      selectedPoints,
      selectedEwallet,
      selectedCard,
      selectedBankAccount,
      selectedAmount,
      loadingFees,
    } = usePayeeCheckout()
    const { isMethodAvailable, eWallets } = usePaymentMethods()
    const { isSplitEnabled } = useProvider()

    const drawerCancelTransactionRef = ref(null)

    const isPointsAvailable = computed(() => {
      return isMethodAvailable(PaymentMethodType.points, FlowType.payment)
    })
    const isEwalletAvailable = computed(() => {
      return eWallets.value?.length > 0
    })
    const isCreditCardsAvailable = computed(() =>
      isMethodAvailable(PaymentMethodType.creditCard, FlowType.payment)
    )
    const isBankAccountsAvailable = computed(() =>
      isMethodAvailable(PaymentMethodType.bankAccount, FlowType.payment)
    )

    const payUsingText = computed(() => {
      const availableMethodsLabels = [
        isPointsAvailable.value && 'Points',
        isEwalletAvailable.value && ewalletLabels.value.ewalletCash,
        isCreditCardsAvailable.value && 'Credit/Debit Card',
        isBankAccountsAvailable.value && 'Bank Account',
      ].filter(Boolean)

      if (availableMethodsLabels.length > 2) {
        return (
          availableMethodsLabels
            .slice(0, availableMethodsLabels.length - 1)
            .join(isSplitEnabled.value ? ' + ' : ', ') +
          ' or ' +
          availableMethodsLabels[availableMethodsLabels.length - 1]
        )
      } else {
        return availableMethodsLabels.join(' or ')
      }
    })

    const amountToPay = computed(() => {
      return subTotalWithProgramFees.value - selectedAmount.value
    })

    const isLoading = computed(() => {
      return loadingFees.value
    })

    const isContinueDisabled = computed(() => {
      return !readyToPay.value
    })

    function onCancelClick() {
      drawerCancelTransactionRef.value.show()
    }

    function onAddPaymentMethodClick() {
      router.push({
        hash: '#profile-pay-from-add',
      })
    }

    function onContinueClick() {
      if (selectedPoints.value?.calculatedAmount === 0) {
        selectedPoints.value = null
      }

      if (selectedEwallet.value?.calculatedAmount === 0) {
        selectedEwallet.value = null
      }

      if (selectedCard.value?.calculatedAmount === 0) {
        selectedCard.value = null
      }

      if (selectedBankAccount.value?.calculatedAmount === 0) {
        selectedBankAccount.value = null
      }

      router.push({
        hash: '#profile-pay-summary',
      })
    }

    onBeforeUnmount(() => {
      selectedPoints.value = null
      selectedEwallet.value = null
      selectedCard.value = null
      selectedBankAccount.value = null
    })

    return {
      isDarkThemeForEwallet,
      payUsingText,
      amountToPay,
      readyToPay,
      selectedAmount,
      loadingFees,
      drawerCancelTransactionRef,
      isLoading,
      isContinueDisabled,
      onCancelClick,
      onAddPaymentMethodClick,
      onContinueClick,
      formatDollar,
      FlowType,
    }
  },
}
</script>

<template>
  <base-aside-page
    title="Select Payment Method(s)"
    no-padding
    :action="onAddPaymentMethodClick"
    :back="{ hash: '#profile-pay-details' }"
  >
    <div class="flex min-h-full flex-col">
      <div class="grow overflow-auto px-5">
        <div
          class="rounded-full p-2.5 text-center font-bold"
          :class="{
            'text-eonx-neutral-800': isDarkThemeForEwallet,
          }"
          :style="{
            'background-color': '#e2f5ea',
          }"
        >
          Amount to pay {{ formatDollar(amountToPay) }}
        </div>
        <div class="mt-6">
          Make a split payment by selecting to pay using your
          {{ payUsingText }}.
        </div>
        <payment-methods-list class="mt-[30px]" :flow-type="FlowType.payment" />
      </div>
      <div class="w-full shrink-0 p-5">
        <div class="grid grid-cols-2 gap-5">
          <base-button
            look="outlined-color"
            class="w-full"
            @click="onCancelClick"
          >
            Cancel
          </base-button>
          <base-button
            :disabled="isContinueDisabled"
            :loading="isLoading"
            class="w-full"
            @click="onContinueClick"
          >
            Continue
          </base-button>
        </div>
      </div>
    </div>
    <drawer-cancel-transaction ref="drawerCancelTransactionRef" />
  </base-aside-page>
</template>
