<script>
import get from 'lodash-es/get'
import DetailsCashPrint from '/~/components/activity/details/cash-print/details-cash-print.vue'
import DetailsCashbackPrint from '/~/components/activity/details/cashback-print/details-cashback-print.vue'
import DetailsOrderPrint from '/~/components/activity/details/order-print/details-order-print.vue'
import DetailsPayToPrint from '/~/components/activity/details/pay-to-print/details-pay-to-print.v2.vue'
import AppLogo from '/~/components/app/app-logo.vue'

export default {
  name: 'dental-tax-invoice',
  components: {
    AppLogo,
    DetailsOrderPrint,
    DetailsCashbackPrint,
    DetailsCashPrint,
    DetailsPayToPrint,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    isTypePurchaseOrder: {
      type: Boolean,
      default: false,
    },
    isTypePaymentOrder: {
      type: Boolean,
      default: false,
    },
    isTypeCashbackReconciliationFeed: {
      type: Boolean,
      default: false,
    },
    isTypeStatementOrder: {
      type: Boolean,
      default: false,
    },
    isTypeBatchOrder: {
      type: Boolean,
      default: false,
    },
    isTypeCashTransaction: {
      type: Boolean,
      default: false,
    },
    showBusinessNumber: {
      type: Boolean,
      default: true,
    },
    businessNumberLabel: {
      type: String,
      required: false,
      default: 'ABN',
    },
    businessNumber: {
      type: String,
      required: false,
      default: '39 615 958 873',
    },
    businessName: {
      type: String,
      default: 'Processed by EonX Services Pty Ltd'
    },
    businessAddress: {
      type: String,
      required: false,
    },
    businessEmail: {
      type: String,
      required: false,
    },
    businessPhone: {
      type: String,
      required: false,
    },
  },
  computed: {
    showOrderDetails() {
      return this.isTypePurchaseOrder
    },
    showCashBackDetails() {
      return this.isTypeCashbackReconciliationFeed
    },
    showCashDetails() {
      return this.isTypeCashTransaction
    },
    showPayToDetails() {
      return (
        this.isTypePaymentOrder ||
        this.isTypeStatementOrder ||
        this.isTypeBatchOrder
      )
    },
  },
  created() {
    const number = get(this.content, 'number', '')

    document.title = `Tax invoice ${number}`
  },
}
</script>

<template>
  <portal to="print-destination">
    <div class="mb-11 flex w-full items-start">
      <div class="flex w-1/2 justify-start">
        <div class="mr-auto">
          <app-logo :max-width="300" :min-height="70" />
        </div>
      </div>
      <div class="w-1/2 text-right">
        <p data-testid="business-name">{{ businessName }}</p>
        <p v-if="businessAddress" data-testid="business-address">
          {{ businessAddress }}
        </p>
        <p v-if="businessPhone" data-testid="business-phone">
          {{ businessPhone }}
        </p>
        <p v-if="businessEmail" data-testid="business-email">
          {{ businessEmail }}
        </p>
        <p v-if="showBusinessNumber">
          <span data-testid="lbl-business-number">
            {{ businessNumberLabel }}
          </span>
          :
          <span data-testid="business-number">{{ businessNumber }}</span>
        </p>
      </div>
    </div>
    <div class="mb-11 text-center text-3xl">
      TAX INVOICE
      <span v-if="showPayToDetails">/ PAYMENT RECEIPT</span>
    </div>
    <details-order-print v-if="showOrderDetails" :content="content" />
    <details-cashback-print
      v-else-if="showCashBackDetails"
      :content="content"
    />
    <details-cash-print v-else-if="showCashDetails" :content="content" />
    <details-pay-to-print v-else-if="showPayToDetails" :content="content" />
  </portal>
</template>
