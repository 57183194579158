<script>
import PaymentsOverview from '/~/templates/bill-payments/components/payments-overview/payments-overview.vue'

export default {
  name: 'widget-payments-overview',
  components: {
    PaymentsOverview,
  },
  props: {
    title: {
      type: Object,
      default: () => ({}),
    },
    defaultFilter: {
      type: String,
      default: '30d',
    },
    showChart: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <payments-overview
    :title="title.text"
    :default-filter="defaultFilter"
    :show-chart="showChart"
  />
</template>
