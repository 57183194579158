import get from 'lodash-es/get'
import { computed } from 'vue'
import api from '/~/core/api'
import { useExtensions } from '/~/composables/extensions'
import { useUser } from '/~/composables/user'

const isQrConnectEnabled = computed(() => {
  const { getConfigByName } = useExtensions()
  const config = getConfigByName('qrconnect')

  return get(config, 'scanQr.enabled', false)
})

const isQrPayEnabled = computed(() => {
  const { getConfigByName } = useExtensions()
  const config = getConfigByName('qr-pay')

  return !!config
})

function qrPayBegin(qrString) {
  return api.post(
    '/v3/qr-pay/merchant/transaction/cscanb/begin',
    {
      qrData: qrString,
    },
    {
      notify: false,
    }
  )
}

function getPayFee(payload) {
  return api.post('/v3/payment-methods/fees/ewallet-pay', payload, {
    notify: false,
  })
}

function getCashInFee(payload) {
  return api.post('/v3/payment-methods/fees/cash-in', payload, {
    notify: false,
  })
}

function qrCashInCheckout(payload) {
  return api.post('/v3/qr-pay-orders/cash-in/checkout', payload, {
    notify: false,
  })
}

function qrEwalletPayCheckout(payload) {
  return api.post('/v3/qr-pay-orders/ewallet-pay/checkout', payload, {
    notify: false,
  })
}

function qrPayFail(payload) {
  return qrPayConfirm({
    ...payload,
    status: 'FAILED',
    technicalSwitchTransactionId: null,
  })
}

function qrPayConfirm(payload = {}) {
  const { user } = useUser()

  return api.post(
    '/v3/qr-pay/merchant/transaction/confirm',
    {
      technicalSwitchTransactionId: 10,
      customerWalletTransactionId: 'set-your-own',
      customerWalletLastFourDigitMobile: user.value.mobile.slice(-4),
      foreignExchangeRate: '1',
      status: 'PAID',
      ...payload,
    },
    { notify: false }
  )
}

function getQrPayOrder(number) {
  return api.get(`/v3/qr-pay-orders/${number}`)
}

export function useQrPay() {
  return {
    qrPayBegin,
    getPayFee,
    getCashInFee,
    getQrPayOrder,
    qrCashInCheckout,
    qrEwalletPayCheckout,
    qrPayConfirm,
    qrPayFail,
    isQrConnectEnabled,
    isQrPayEnabled,
  }
}
