<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { Activity } from '/~/composables/activity'
import { useAustraliapostActivityListItem } from '/~/templates/australiapost/composables/activity'

const props = defineProps<{ item: Activity }>()

const {
  canSeeDetails,
  date,
  formattedAmountInPoints,
  icon,
  iconColor,
  isPointsOnlyPurchase,
  paymentMethodLabel,
  pointsTransactions,
  productsInterested,
  subtitle,
  title,
  typeIcon,
  showPoints,
} = useAustraliapostActivityListItem(props)
</script>

<template>
  <base-link
    :to="{ name: 'activity-details', params: { id: item.id } }"
    :active="canSeeDetails"
    class="block"
    :class="{
      'text-neutral-400': !canSeeDetails,
    }"
  >
    <div
      class="flex items-center space-x-5 bg-white px-[15px] py-5 pl-5 transition-colors duration-150 ease-in-out hover:bg-blue-100"
    >
      <div class="h-12 w-12 shrink-0">
        <div
          class="relative flex h-full w-full items-center justify-center rounded-full text-white"
          :class="{
            'bg-primary':
              item.isTypePointsTransaction || item.isTypeMigratedPoints,
            'bg-red-700': item.isTypeCampaign,
            'bg-white text-red-700': item.isTypeMigratedOrder,
            'bg-gray-500':
              !item.isTypePointsTransaction &&
              !item.isTypeCampaign &&
              !item.isTypeMigratedOrder &&
              !item.isTypeMigratedPoints,
          }"
        >
          <base-icon
            :svg="typeIcon.svg"
            :size="typeIcon.size"
            :class="typeIcon.classes"
          />
          <div
            class="absolute h-5 w-5 rounded-full bg-white"
            :class="iconColor"
            :style="{
              right: '-10px',
              bottom: '-2px',
            }"
          >
            <base-icon :svg="icon" />
          </div>
        </div>
      </div>
      <template v-if="item.isTypeUserCommunication">
        <div class="w-1/4 min-w-32 grow truncate">
          <h3 class="text-base font-bold text-eonx-neutral-800" :title="title">
            {{ title }}
          </h3>
          <div class="text-sm text-eonx-neutral-600">
            {{ subtitle }}
          </div>
        </div>
      </template>

      <template v-if="item.isTypePointsTransferRequest">
        <div class="w-1/4 min-w-32 grow truncate" data-testid="title-column">
          <h3 class="text-base font-bold text-eonx-neutral-800" :title="title">
            {{ title }}
          </h3>
          <div class="text-sm font-semibold text-eonx-neutral-600">
            <span :class="[item.statusLabel.color]">
              {{ item.statusLabel.text }}
            </span>
          </div>
        </div>

        <div class="w-2/12" data-testid="date-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">Date</p>
          <p class="text-eonx-neutral-800">
            {{ date }}
          </p>
        </div>

        <div class="w-2/12" data-testid="points-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">Points Amount</p>
          <p class="text-eonx-neutral-800">
            {{ props.item.points }}
          </p>
        </div>

        <div class="w-2/12" data-testid="source-destination-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">
            {{ props.item.pointsTransferRequest?.descriptionLabel }}
          </p>
          <p class="text-eonx-neutral-800">
            {{ props.item.pointsTransferRequest?.descriptionValue }}
          </p>
        </div>

        <div class="w-2/12" data-testid="reason-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">Reason</p>
          <p class="leading-none text-eonx-neutral-800">
            {{ props.item.pointsTransferRequest?.reason }}
          </p>
        </div>
      </template>

      <template v-else>
        <div class="w-1/4 min-w-32 grow truncate">
          <h3 class="text-base font-bold text-eonx-neutral-800" :title="title">
            {{ title }}
          </h3>
          <div
            class="flex flex-wrap items-center text-sm text-eonx-neutral-600"
          >
            {{ subtitle }}
          </div>
        </div>
        <div class="w-2/12">
          <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
            Date
          </p>
          <p class="text-eonx-neutral-800">
            {{ date }}
          </p>
        </div>
        <div class="w-2/12" :class="{ 'order-1': !showPoints }">
          <template v-if="showPoints">
            <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
              Points
            </p>
            <p class="flex flex-col text-eonx-neutral-800">
              <template v-if="isPointsOnlyPurchase">
                {{ formattedAmountInPoints }}
              </template>
              <template v-else>
                <span
                  v-for="(pointsTransaction, key) in pointsTransactions"
                  :key="key"
                >
                  {{ pointsTransaction }}
                </span>
              </template>
            </p>
          </template>
        </div>
        <div class="w-3/12">
          <template v-if="paymentMethodLabel">
            <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
              Payment method
            </p>
            <p class="text-eonx-neutral-800">
              {{ paymentMethodLabel }}
            </p>
          </template>
          <template v-else-if="item.isTypeCampaign && productsInterested">
            <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
              Product Details
            </p>
            <p class="text-eonx-neutral-800">{{ productsInterested }}</p>
          </template>
        </div>
        <div class="w-1/12"></div>
      </template>
      <div class="order-5 ml-2.5 w-5 shrink-0 self-center">
        <base-icon
          v-if="canSeeDetails"
          size="sm"
          svg="plain/chevron-right"
          class="m-auto justify-self-center text-sm"
        />
      </div>
    </div>
  </base-link>
</template>
