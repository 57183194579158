import ui from '/~/core/ui'
import MainDesktop from '/~/layouts/main/desktop/main-desktop.vue'
import MainMobile from './mobile/main-mobile.vue'

export default {
  name: 'layout-main',
  functional: true,
  render(h, ctx) {
    return h(ui.mobile ? MainMobile : MainDesktop, ctx)
  },
}
