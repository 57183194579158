<script>
import DrawerEPurchase from '/~/templates/australiapost/components/drawer/views/e-purchases/drawer-e-purchase.vue'

export default {
  name: 'profile-e-purchase-details',
  components: {
    DrawerEPurchase,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    onDelete() {
      this.$router.replace({ hash: '#profile-e-purchases' })
    },
    showTerms() {
      this.$router.push({ hash: '#profile-e-purchase-terms' })
    },
    showLoyaltyCard(id) {
      this.$router.push({
        hash: `#profile-loyalty-card-details/${id}`,
      })
    },
    showLoyaltyBrands() {
      this.$router.push({ hash: '#profile-loyalty-brands' })
    },
  },
}
</script>

<template>
  <drawer-e-purchase
    :id="id"
    to="menu-modal"
    @delete="onDelete"
    @terms="showTerms"
    @loyalty-card="showLoyaltyCard"
    @loyalty-brands="showLoyaltyBrands"
  />
</template>
