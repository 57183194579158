<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseCard from '/~/components/base/card/base-card.vue'

export default {
  name: 'fly-card',
  components: {
    BaseIcon,
    BaseCard,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    membershipId: {
      type: [String, Number],
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div class="m-auto mb-6 w-4/5 sm:w-1/2" :class="[fullWidth && 'min-w-full']">
    <base-card
      class="relative h-full rounded-lg bg-gradient-to-r from-red-600 to-red-800 text-white"
    >
      <base-icon class="absolute ml-5" :size="60" svg="velocity" />

      <div class="absolute bottom-0 mb-5 ml-5 leading-5">
        <div class="truncate">
          {{ name }}
        </div>
        <div>{{ membershipId }}</div>
      </div>
    </base-card>
  </div>
</template>
