<script>
import BaseRange from '/~/components/base/range/base-range.vue'

export default {
  name: 'ew-catalog-range',
  components: {
    BaseRange,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    value: {
      type: Array,
      default: () => [this.min, this.max],
    },
    interval: {
      type: Number,
      default: 1,
    },
    formatter: {
      type: [String, Function],
      default: '{value}',
    },
    mergeFormatter: {
      type: [String, Function],
      default: '{value1} ~ {value2}',
    },
    currency: {
      type: [String, Object],
      default: () => ({
        prefix: '$',
      }),
    },
  },
  data() {
    return {
      localValue: this.getValue(this.value),
    }
  },
  computed: {
    isRangeSelected() {
      const [min, max] = this.localValue

      return min !== this.min || max !== this.max
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.localValue = this.getValue(newValue)
      },
      deep: true,
    },
  },
  methods: {
    getValue(newValue) {
      let [min, max] = newValue

      if (max < this.min || max > this.max) {
        max = this.max
      }

      if (min > this.max || min < this.min) {
        min = this.min
      }

      return [min, max]
    },
    clearFilter() {
      this.localValue = [this.min, this.max]
      this.$refs.minInput.setValue(this.localValue[0])
      this.$refs.maxInput.setValue(this.localValue[1])
      this.$emit('update', this.localValue)
    },
  },
}
</script>

<template>
  <div class="flex grow flex-col overflow-hidden border-t">
    <div
      class="flex h-10 shrink-0 items-center justify-between border-b bg-dark px-5"
    >
      <span class="font-bold">
        {{ title }}
      </span>
      <button
        v-if="isRangeSelected"
        class="text-sm font-bold leading-none text-primary hover:underline"
        @click="clearFilter"
      >
        Clear
      </button>
    </div>
    <div class="p-5">
      <base-range
        :value="localValue"
        :min="min"
        :max="max"
        :interval="interval"
        tooltip="none"
        :formatter="formatter"
        :merge-formatter="mergeFormatter"
        @input="$emit('update', $event)"
      />
      <div class="mt-2.5 flex justify-between space-x-5 text-xs">
        <div class="rounded border">
          <currency-input
            ref="minInput"
            v-model="localValue[0]"
            :value-range="{ min, max }"
            class="appearance-none px-2.5 py-[5px]"
            :precision="0"
            :currency="currency"
          />
        </div>

        <div class="rounded border">
          <currency-input
            ref="maxInput"
            v-model="localValue[1]"
            :value-range="{ min, max }"
            :precision="0"
            :currency="currency"
            class="appearance-none px-2.5 py-[5px] text-right"
          />
        </div>
      </div>
    </div>
  </div>
</template>
