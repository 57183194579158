<script>
import { getMenuActiveItem } from '/~/utils/menu'
import { useUI } from '/~/composables/ui'
import LayoutView from '/~/templates/bill-payments/layouts/view/layout-view.vue'

export default {
  name: 'payment-v3',
  components: {
    LayoutView,
  },
  setup() {
    const { leftMenu, uiReady } = useUI()

    return {
      leftMenu,
      uiReady,
    }
  },
  computed: {
    activeTopMenuItem() {
      if (!this.uiReady || !this.leftMenu) return

      return getMenuActiveItem(this.$route, this.leftMenu)
    },
    description() {
      return this.activeTopMenuItem?.description
    },
    title() {
      return this.activeTopMenuItem?.label
    },
    menu() {
      return this.activeTopMenuItem?.children ?? []
    },
  },
}
</script>

<template>
  <layout-view :heading="title" :menu="menu">
    <template v-if="description" #description>
      <div v-html="description" />
    </template>
    <router-view />
  </layout-view>
</template>
