<script setup lang="ts">
import { ref } from 'vue'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'
import { useUser } from '/~/composables/user'
import { useConfirmComponent } from '/~/modals/confirm/confirm'

const props = defineProps<{
  onConfirm?: () => Promise<void>
  onCancel?: () => Promise<void>
}>()

const { autopayModal } = useUser()
const { enableDirectDebit } = useCms()
const isDontShowAgain = ref<boolean>(false)
const { translate } = useLocalization()

const { loading, confirming, cancelling, onConfirm, onCancel } =
  useConfirmComponent({
    onConfirm: async () => {
      autopayModal.value = isDontShowAgain.value
      await props.onConfirm?.()
      hide()
    },
    onCancel: async () => {
      autopayModal.value = isDontShowAgain.value
      await props.onCancel?.()
      hide()
    },
  })

function hide() {
  modal.hide()
}
</script>

<template>
  <base-mdl
    width="sme"
    border-radius="2xl"
    :layout="false"
    :closable="!loading"
  >
    <div class="relative flex flex-col items-center p-8">
      <span class="text-primary">
        <base-icon svg="billpay/auto-pay" :size="80" class="mx-auto mb-5" />
      </span>

      <h2 class="w-full text-center text-2xl text-eonx-neutral-800">
        {{ enableDirectDebit.title }}
      </h2>
      <base-button
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: `Enable ${translate('directDebit.text')}`,
          cta: 'Close',
        }"
        icon="heroicons/outline/x-mark"
        :size="32"
        class="!absolute top-6 right-6"
        :disabled="loading"
        @click="hide"
      />
    </div>
    <div class="p-8">
      <div
        class="text-eonx-neutral-600"
        v-html="enableDirectDebit.content"
      ></div>
    </div>
    <div class="flex flex-col border-t border-gray-200 p-8">
      <base-checkbox
        v-model="isDontShowAgain"
        v-analytics:change="
          () => ({
            pageGroup: 'Make a payment',
            page: `Enable ${translate('directDebit.text')}`,
            label: `${
              isDontShowAgain ? 'Tick' : 'Untick'
            } Don't remind me again`,
          })
        "
        look="v4"
        class="mb-7"
      >
        <span class="font-normal text-eonx-neutral-800">
          Don’t remind me again
        </span>
      </base-checkbox>
      <div class="flex gap-4">
        <base-button
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: `Enable ${translate('directDebit.text')}`,
            cta: 'Not now',
          }"
          class="!h-14 !w-[118px] flex-auto !px-7"
          look="outlined-color"
          :loading="cancelling"
          :disabled="loading"
          @click="onCancel"
        >
          Not now
        </base-button>
        <base-button
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: `Enable ${translate('directDebit.text')}`,
            cta: 'Let’s do it',
          }"
          class="!h-14 !w-[118px] flex-auto !px-7"
          :loading="confirming"
          :disabled="loading"
          @click="onConfirm"
        >
          Let’s do it
        </base-button>
      </div>
    </div>
  </base-mdl>
</template>
