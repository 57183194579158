import modal from '/~/core/mdl'

const PROVIDER_NAME = eonx.provider.subdomain

// EH Benefits
if (PROVIDER_NAME === 'easternhealth') {
  window.showEhAuthorityForm = () => {
    modal.show('eh-authority-form')
  }
}

/**
 * Professional Australia (pa), Valued Benefits and AB Countrywide
 * are sharing forms
 */
const VALUED_GROUP = ['pa', 'valued', 'abcountrywide']

if (VALUED_GROUP.includes(PROVIDER_NAME)) {
  // Corporate Travel Insurance
  window.showPaCorporateTravelInsuranceForm = () => {
    modal.show('pa-corporate-travel-insurance-form')
  }

  // Critical Illness Insurance Form
  window.showPaCriticallIlnessInsuranceForm = () => {
    modal.show('pa-critical-illness-insurance-form')
  }

  // Indemnity Form
  window.showPaIndemnityForm = () => {
    modal.show('pa-indemnity-form')
  }

  // Life Insurance Form
  window.showPalifeInsuranceForm = () => {
    modal.show('pa-life-insurance-form')
  }

  // Prestige Home Contents Insurance Form
  window.showPaPrestigeHomeContentInsuranceform = () => {
    modal.show('pa-prestige-home-content-insurance-form')
  }

  // Prestige Public Liability Insurance Form
  window.showPublicLiabilityInsuranceForm = () => {
    modal.show('pa-public-liability-insurance-form')
  }
}
